import { GroupBudgetStatus } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { ChartInCarouselWrapper } from "pages/reporting/components/chart_in_carousel_wrapper";
import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

type WrapperProps = {
  status?: GroupBudgetStatus | null;
  children: ReactNode;
};
export function ChartWrapper({ children, status }: WrapperProps) {
  const mq = useMediaQuery();

  return (
    <View
      style={[
        styles.chart,
        mq.deviceQuery.mobile && { padding: 4 },
        // @ts-ignore - override style of web for View of react native
        status === GroupBudgetStatus.Inactive && {
          filter: "grayscale(1)",
        },
      ]}
    >
      {mq.deviceQuery.mobile ? (
        <ChartInCarouselWrapper>{children}</ChartInCarouselWrapper>
      ) : (
        <>{children}</>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  chart: {
    // @ts-ignore remove the default zIndex of View at here
    zIndex: "unset",
  },
});
