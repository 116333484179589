import { formatDate } from "lib/date_utils";
import { Day, parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { Icon } from "components/iconv2";
import { SelectButtonWithIcon } from "components/select/select_button_with_icon";
import {
  DatePickerChildrenType,
  DatePickerDropdown,
} from "components/date_picker/date_picker_dropdown";
import { useMediaQuery } from "lib/media_query";
import { DatePickerScrollableDialog } from "components/date_picker/date_picker_scrollable_dialog";

interface HeaderDatePickerProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
}

export function HeaderDatePicker(props: HeaderDatePickerProps) {
  const { value, onChange } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  const renderButton = ({ onToggleOpen }: DatePickerChildrenType) => (
    <SelectButtonWithIcon
      testID="header-date-picker"
      onPress={onToggleOpen}
      title={
        value
          ? formatDate(parseDay(value), getSystemLocale(), {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "Any day"
      }
      leftIcon={<Icon name="calendar" size="sm" filled />}
    />
  );

  return isMobile ? (
    <DatePickerScrollableDialog value={value} onChange={onChange}>
      {renderButton}
    </DatePickerScrollableDialog>
  ) : (
    <DatePickerDropdown value={value} onChange={onChange}>
      {renderButton}
    </DatePickerDropdown>
  );
}
