import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { useMediaQuery } from "lib/media_query";
import { Fragment } from "react";
import { StyleSheet, View } from "react-native";

interface FooterProps {
  withTermsAndPrivacy?: boolean;
  showFullText?: boolean;
}
export function Footer(props: FooterProps) {
  const { withTermsAndPrivacy = false, showFullText = false } = props;
  const mq = useMediaQuery();
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text color="black-70" size="micro">
          {(!mq.deviceQuery.mobile || showFullText) &&
            "© 2023 Flexspace Technologies Inc."}{" "}
          {withTermsAndPrivacy && (
            <Fragment>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: tokens.colors.utility.regular }}
                href="https://flexspace-ds-static.s3.us-west-1.amazonaws.com/flexspace-teams-tc.pdf"
              >
                <Text color="black-70" size="micro">
                  Terms
                </Text>
              </a>{" "}
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: tokens.colors.utility.regular }}
                href="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-privacy.pdf"
              >
                <Text color="black-70" size="micro">
                  Privacy
                </Text>
              </a>
            </Fragment>
          )}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    marginTop: "auto",
    paddingBottom: 41,
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
});
