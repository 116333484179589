import React, { useEffect, useState } from "react";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { InputContainer } from "components/input_container";
import { tokens } from "components/tokens";
import { Container } from "components/container";

interface CreditCardInputProps {
  onChange?: (complete: boolean, error?: string) => void;
  disabled?: boolean;
  label?: string;
  invalid?: boolean;
  invalidText?: string;
}

export function CreditCardInput(props: CreditCardInputProps) {
  const { onChange, disabled, invalid, label, invalidText } = props;
  const [focused, setFocused] = useState(false);
  const elements = useElements();
  const [hasValue, setHasValue] = useState(false);

  useEffect(() => {
    if (!elements) {
      return;
    }
    const card = elements.getElement(CardElement);

    if (!card) {
      return;
    }

    card.on("change", function (event) {
      if (hasValue !== !event.empty) {
        setHasValue(!event.empty);
      }

      if (onChange !== undefined) {
        onChange(event.complete, event.error?.message);
      }
    });
  }, [elements, hasValue, onChange]);

  const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    disabled: !!disabled,
    style: {
      base: {
        color: tokens.colors.utility.regular,
        fontFamily: `"Helvetica Neue", Helvetica, sans-serif`,
        fontSmoothing: "antialiased",
        fontSize: "15px",
        lineHeight: "40px",
        "::placeholder": {
          color: tokens.colors.utility.lighter,
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <InputContainer
      focused={focused}
      hasValue={hasValue}
      label={label}
      invalid={invalid}
      invalidText={invalidText}
    >
      <Container
        testID="credit-card-input-container"
        paddingTop={4}
        paddingHorizontal={8}
      >
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </Container>
    </InputContainer>
  );
}
