import { colors } from "components/colors";
import { Icon } from "components/icon";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Fragment } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { SelectInputOptionType } from "./select_input";

interface SelectInputOptionAmenitiesVariantProps {
  option: SelectInputOptionType;
  selected?: boolean;
  testID?: string;
  onSelect?: (option: SelectInputOptionType) => void;
  withCheckbox?: boolean;
  labelProp?: string;
}

const noop = () => {};

export function SelectInputOptionAmenitiesVariant(
  props: SelectInputOptionAmenitiesVariantProps,
) {
  const {
    testID,
    option,
    selected,
    onSelect = noop,
    withCheckbox,
    labelProp = "label",
  } = props;

  return (
    <Pressable testID={testID} onPress={() => onSelect(option)}>
      <View style={styles.option}>
        <View style={styles.optionContainer}>
          {withCheckbox && (
            <Fragment>
              <View style={[styles.checkbox, selected && styles.selected]}>
                {selected && <Icon name="check" size="sm" color="white" />}
              </View>
              <Spacer direction="row" size={16} />
            </Fragment>
          )}
          <Text size="xs">{option[labelProp]}</Text>
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  option: {
    paddingVertical: 8,
  },
  checkbox: {
    width: 20,
    height: 20,
    backgroundColor: colors.brand.whitecore,
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
    borderRadius: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  selected: {
    backgroundColor: colors.brand.eggplantcore,
  },
  optionContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
});
