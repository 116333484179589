import { ReactNode, useCallback, useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Day } from "lib/day_utils";
import { Text } from "components/text_v2";
import { DatePickerScrollable } from "./date_picker_scrollable";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { DatePickerChildrenType } from "./date_picker_dropdown";
import { DialogModal } from "components/dialog_modal";

interface DatePickerScrollableDialogProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
  children: (arg: DatePickerChildrenType) => ReactNode;
  withOutClearBtnMode?: boolean;
}

export function DatePickerScrollableDialog(
  props: DatePickerScrollableDialogProps,
) {
  const { value, onChange, children, withOutClearBtnMode = false } = props;
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState<Day>(value!);
  useEffect(() => {
    setNewValue(value!);
  }, [value]);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClear = useCallback(() => {
    onChange("");
    setNewValue(value!);
    handleClose();
  }, [handleClose, onChange, value]);
  const handleSave = useCallback(() => {
    setOpen(false);
    onChange(newValue);
  }, [onChange, newValue]);

  return (
    <>
      {children({
        isOpen: open,
        onClose: handleClose,
        onToggleOpen: open ? handleClose : handleOpen,
        onOpen: handleOpen,
      })}
      <DialogModal
        isVisible={!!open}
        onClose={handleClose}
        noScroll
        title="Select date"
      >
        <View style={styles.dialogContent}>
          <DatePickerScrollable value={newValue} onChange={setNewValue} />
          <View style={styles.dialogFooter}>
            {withOutClearBtnMode ? (
              <View style={styles.singleButtonView}>
                <Button
                  testID="date-picker-show-results-button"
                  text="Done"
                  size={"large"}
                  onPress={handleSave}
                />
              </View>
            ) : (
              <>
                <Pressable onPress={handleClear}>
                  <Text color="eggplant-core" size="xs" weight="semibold">
                    Clear
                  </Text>
                </Pressable>
                <Button
                  testID="date-picker-show-results-button"
                  text="Show results"
                  onPress={handleSave}
                />
              </>
            )}
          </View>
        </View>
      </DialogModal>
    </>
  );
}

const styles = StyleSheet.create({
  dialogFooter: {
    padding: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContent: {
    flexShrink: 1,
    minHeight: 0,
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
  },
  button: {
    paddingVertical: 11,
    paddingLeft: 8,
    paddingRight: 8,
  },
  singleButtonView: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  picker: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
