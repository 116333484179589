import {
  ApolloError,
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import * as Sentry from "@sentry/react";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import {
  format,
  isAfter,
  isBefore,
  isSameMonth,
  parseISO,
  subDays,
  subHours,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { FormErrors, useForm } from "hooks/use_form";
import { startOfDay } from "lib/date_utils";
import {
  addMonths,
  Day,
  getLocalToday,
  isSameDay,
  parseDay,
  setSameDay,
  today,
  toDay,
} from "lib/day_utils";
import { isEmail } from "lib/string_utils";
import { parseTime, Time } from "lib/time_utils";
import { useAnalytics } from "providers/analytics";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DailyDateRange,
  DateRange,
  HourlyDateRange,
} from "./booking_date_range_picker";
import {
  BookingConfig,
  BookingType,
  BookingUpdateUserMutation,
  BookingUpdateUserMutationVariables,
  BookingUtilsOrderDetailsQuery,
  BookingUtilsOrderDetailsQueryVariables,
  CheckoutMutation,
  CheckoutMutationVariables,
  DailyBooking,
  HourlyBooking,
  Order,
  OrderItemInput,
  OrderItemType,
  OrderStatus,
  OrderSubSource,
  Pricing,
  PricingType,
  SpaceAvailableDaysQuery,
  SpaceAvailableDaysQueryVariables,
  SpaceAvailableTimeSlotsQuery,
  SpaceAvailableTimeSlotsQueryVariables,
  SpaceBookingPolicy,
  SpaceCheckoutCurrentUserInfoQuery,
  SpaceDetails__AllDetailsFragment,
  SpaceDetailsQuery,
  SpaceLayout,
} from "./graphql.generated";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";

export interface SearchValue {
  dateRange?: DateRange;
  promoCode?: string;
  physicalSpaceID?: string;
  quoteId?: string;
}

export function useSearchValue(queryString: any): SearchValue {
  const {
    date,
    startTime,
    endTime,
    startDate,
    endDate,
    physicalSpaceID,
    promoCode,
    quoteId,
  } = queryString;

  return useMemo(() => {
    const searchValue: SearchValue = {};

    if (typeof date === "string") {
      searchValue.dateRange = {
        type: BookingType.HourlyBooking,
        date,
        startTime,
        endTime,
      };
    } else if (typeof startDate === "string" && typeof endDate === "string") {
      searchValue.dateRange = {
        type: BookingType.DailyBooking,
        startDate,
        endDate,
      };
    }

    if (promoCode && typeof promoCode === "string") {
      searchValue.promoCode = promoCode;
    }

    if (physicalSpaceID && typeof physicalSpaceID === "string") {
      searchValue.physicalSpaceID = physicalSpaceID;
    }
    if (quoteId && typeof quoteId === "string") {
      searchValue.quoteId = quoteId;
    }

    return searchValue;
  }, [
    date,
    endTime,
    startTime,
    startDate,
    endDate,
    promoCode,
    physicalSpaceID,
    quoteId,
  ]);
}

export function toQueryString(searchValue: SearchValue): string {
  const { dateRange, physicalSpaceID, promoCode, quoteId } = searchValue;
  const searchParams = new URLSearchParams();

  if (dateRange) {
    if (
      dateRange.type === BookingType.DailyBooking &&
      dateRange.startDate &&
      dateRange.endDate
    ) {
      searchParams.set("startDate", dateRange.startDate);
      searchParams.set("endDate", dateRange.endDate);
    } else if (
      dateRange.type === BookingType.HourlyBooking &&
      dateRange.date &&
      dateRange.endTime &&
      dateRange.startTime
    ) {
      searchParams.set("date", dateRange.date);
      searchParams.set("startTime", dateRange.startTime);
      searchParams.set("endTime", dateRange.endTime);
    }
  }

  if (physicalSpaceID) {
    searchParams.set("physicalSpaceID", physicalSpaceID);
  }

  if (promoCode) {
    searchParams.set("promoCode", promoCode);
  }

  if (quoteId) {
    searchParams.set("quoteId", quoteId);
  }

  if (searchParams.toString() === "") {
    return "";
  }

  return `?${searchParams.toString()}`;
}

export function getBookingDateRangeLabel(
  value?: DateRange,
  fallback?: string,
): string {
  if (!value) {
    return fallback || "";
  }

  if (value.type === BookingType.HourlyBooking && value.date) {
    const { date } = value;

    if (value.startTime && value.endTime) {
      return `${format(parseISO(date), "MMM do")}, ${format(
        parseTime(value.startTime),
        "h:mm a",
      )} → ${format(parseTime(value.endTime), "h:mm a")}`;
    }

    return `${format(parseISO(date), "MMMM")} ${format(parseISO(date), "do")}`;
  }

  if (
    value.type === BookingType.DailyBooking &&
    value.startDate &&
    value.endDate
  ) {
    const { startDate, endDate } = value;

    let daysLabel = "";

    if (isSameMonth(parseTime(startDate), parseTime(endDate))) {
      if (isSameDay(startDate, endDate)) {
        daysLabel = `${format(parseISO(startDate), "MMMM")} ${format(
          parseISO(startDate),
          "do",
        )}`;
      } else {
        daysLabel = `${format(parseISO(startDate), "MMMM")} ${format(
          parseISO(startDate),
          "do",
        )} - ${format(parseISO(endDate), "do")}`;
      }
    } else {
      daysLabel = `${format(parseISO(startDate), "MMM do")} → ${format(
        parseISO(endDate),
        "MMM do",
      )}`;
    }

    return `${daysLabel}`;
  }

  return fallback || "";
}

export const priceTypeLabel: { [key in PricingType]: string } = {
  [PricingType.Hourly]: "Hour",
  [PricingType.Daily]: "Day",
};

export function getPricingLabel(pricing: Pricing): string {
  return `$${pricing.price}/${priceTypeLabel[pricing.type]}`;
}

export interface ReservationDetailsInput {
  meetingName: string;
  arrivalTime: string;
  meetingStartTime: string;
  layoutID: string;
}

interface UseReservationDetailsFormProps {
  initialValues: ReservationDetailsInput;
  space: {
    id: string;
    pricings: Pricing[];
    bookingPolicy: SpaceBookingPolicy;
  };
  maxCapacity: number;
  onSubmit: (values: ReservationDetailsInput) => void;
}

export function useReservationDetailsForm(
  props: UseReservationDetailsFormProps,
) {
  const { initialValues, onSubmit } = props;

  const { setFieldValue, values, errors, submit } = useForm<
    ReservationDetailsInput,
    keyof ReservationDetailsInput
  >({
    initialValues,
    onSubmit: async (v) => {
      onSubmit(v);
    },
  });

  const disabled = false;

  return {
    setFieldValue,
    values,
    submit,
    errors,
    disabled,
  };
}

export interface CheckoutInput extends ReservationDetailsInput {
  quoteId: string;
  promoCode: string;
  dateRange?: DateRange;
  physicalSpaceID?: string;

  guestContactFullName: string;
  guestContactEmail: string;
  guestContactCompanyName: string;
  guestContactPhoneNumber: string;

  specialRequests: boolean;
  bookingOnBehalf: boolean;
  meetingContactFullName: string;
  meetingContactPhoneNumber: string;
  meetingContactEmail: string;

  memo?: string;
  paymentComplete: boolean;
  subSource: OrderSubSource;
}

interface UseCheckoutProps {
  initialValues: Partial<CheckoutInput>;
  requirePayment: boolean;
  spaceID: string;
  onComplete: (orderID: string) => void;
  stripe: Stripe | null;
  savedPaymentMethodId?: string | null;
}

export function useCheckout(props: UseCheckoutProps) {
  const {
    requirePayment,
    spaceID,
    stripe,
    onComplete: onCompleteProp,
    initialValues,
    savedPaymentMethodId,
  } = props;
  const [orderId, setOrderId] = useState<string>();
  const elements = useElements();
  const pollOrder = usePollOrderUntilOrderComplete();
  const apolloClient = useApolloClient();
  // Queries
  const { data: spaceQuery } = useQuery<SpaceDetailsQuery>(spaceGQLQuery, {
    variables: { id: spaceID },
  });
  const { data: currentUserQuery } =
    useQuery<SpaceCheckoutCurrentUserInfoQuery>(currentUserInfoGQLQuery);

  // Mutations
  const [updateCurrentUser] = useMutation<
    BookingUpdateUserMutation,
    BookingUpdateUserMutationVariables
  >(updateUserGQLMutation);
  const [checkout] = useMutation<CheckoutMutation, CheckoutMutationVariables>(
    checkoutGQLMutation,
  );

  // Analytics
  const analytics = useAnalytics();
  const onComplete = useCallback(
    (order: Order) => {
      setTimeout(() => {
        // 10s refetch everything, because partners-api may need time to process the order
        apolloClient.resetStore();
      }, 10000);

      if (order.orderItems[0].details.__typename === "DailyBooking") {
        const orderItemDetails = order.orderItems[0].details;
        analytics.event("Book Space", {
          "Order UUID": order.id,
          "Start Date": orderItemDetails.startDate,
          "End Date": orderItemDetails.endDate,
          "Start Time": orderItemDetails.time,
          "End Time": orderItemDetails.time,
          "Space UUID": orderItemDetails.space.id,
          "Location ": orderItemDetails.space.locationName,
          "Space Name": orderItemDetails.space.name,
          "Order Total": order.totalPrice,
          Currency: order.currency,
          City: orderItemDetails.space.city,
          Country: orderItemDetails.space.country,
          memo: !!order.memo,
        });
      } else if (order.orderItems[0].details.__typename === "HourlyBooking") {
        const orderItemDetails = order.orderItems[0].details;
        analytics.event("Book Space", {
          "Order UUID": order.id,
          "Start Date": orderItemDetails.date,
          "End Date": orderItemDetails.date,
          "Start Time": orderItemDetails.startTime,
          "End Time": orderItemDetails.endTime,
          "Space UUID": orderItemDetails.space.id,
          "Location ": orderItemDetails.space.locationName,
          "Space Name": orderItemDetails.space.name,
          "Order Total": order.totalPrice,
          Currency: order.currency,
          City: orderItemDetails.space.city,
          Country: orderItemDetails.space.country,
          memo: !!order.memo,
        });
      }
      return onCompleteProp(order.id);
    },
    [analytics, apolloClient, onCompleteProp],
  );

  const currentUser = currentUserQuery?.currentUser;

  const hasSavedUserDetails = !!(
    currentUser?.fullName &&
    currentUser?.email &&
    currentUser?.organization?.name &&
    currentUser?.phoneNumber
  );
  const {
    handleChange,
    setFieldValue,
    setFieldValues,
    setFieldError,
    setErrors,
    changed,
    submit,
    submitting,
    status,
    values,
    errors,
    submitCount,
  } = useForm<CheckoutInput, keyof CheckoutInput>({
    initialValues: {
      quoteId: initialValues.quoteId || "",
      promoCode: initialValues.promoCode || "",
      dateRange: initialValues.dateRange,
      physicalSpaceID: initialValues.physicalSpaceID,
      layoutID: initialValues.layoutID || "",
      meetingName: initialValues.meetingName || "",
      arrivalTime: initialValues.arrivalTime || "",
      meetingStartTime: initialValues.meetingStartTime || "",
      guestContactFullName: "",
      guestContactEmail: "",
      guestContactCompanyName: "",
      guestContactPhoneNumber: "",
      specialRequests: !!initialValues.specialRequests,
      bookingOnBehalf: initialValues.bookingOnBehalf || false,
      meetingContactFullName: initialValues.meetingContactFullName || "",
      meetingContactEmail: initialValues.meetingContactEmail || "",
      meetingContactPhoneNumber: initialValues.meetingContactPhoneNumber || "",
      memo: initialValues.memo || "",
      paymentComplete: !!savedPaymentMethodId || !requirePayment,
      subSource: OrderSubSource.Web,
    },
    validate: (_values) => {
      if (!spaceQuery || !spaceQuery.space) {
        return {};
      }

      const errors: FormErrors<CheckoutInput> = {};

      if (!hasSavedUserDetails) {
        if (!values.guestContactFullName) {
          errors.guestContactFullName = "Please add your full name";
        }

        if (!values.guestContactPhoneNumber) {
          errors.guestContactPhoneNumber =
            "We use this number to contact you about your reservation.";
        } else if (values.guestContactPhoneNumber.length < 7) {
          errors.guestContactPhoneNumber = "Please input phone number";
        }

        if (!values.guestContactEmail) {
          errors.guestContactEmail =
            "We use this to send you emails like your reservation confirmation.";
        } else if (!isEmail(values.guestContactEmail)) {
          errors.guestContactEmail = "Please enter valid email";
        }
      }

      if (!values.paymentComplete) {
        errors.paymentComplete = "Please complete payment information";
      }

      if (values.bookingOnBehalf) {
        if (!values.meetingContactFullName) {
          errors.meetingContactFullName = "Please enter the contacts' name";
        }

        if (!values.meetingContactEmail) {
          errors.meetingContactEmail = "Please enter the contact's email";
        } else if (!isEmail(values.meetingContactEmail)) {
          errors.meetingContactEmail = "Please enter valid email";
        }
      }

      return errors;
    },
    onSubmit: async (_values, { setStatus: _setStatus }) => {
      if (!spaceQuery || !spaceQuery.space) {
        return;
      }

      const input = toCheckoutMutationInput(spaceQuery.space, _values);

      if (!input) {
        _setStatus("Invalid input");
        return;
      }

      if (!currentUser) {
        _setStatus("Need to authenticate before checkout");
        return;
      }

      if (!hasSavedUserDetails) {
        // we should not update email if user signed up with social provider
        if (currentUser.hasSignedUpWithSocialProvider) {
          await updateCurrentUser({
            variables: {
              input: {
                id: currentUser.id,
                phone: values.guestContactPhoneNumber,
              },
            },
          });
        } else {
          await updateCurrentUser({
            variables: {
              input: {
                id: currentUser.id,
                email: values.guestContactEmail,
                fullName: values.guestContactFullName,
                phone: values.guestContactPhoneNumber,
              },
            },
          });
        }
      }

      try {
        const { data } = await checkout({
          variables: { input: { ...input, orderId } },
        });
        const order = data?.checkout;

        if (!order) {
          throw new Error("Could not create order");
        }

        if (!requirePayment || order.totalPrice === 0) {
          await pollOrder(order.id, onComplete);
          return;
        }

        if (!stripe || !elements) {
          throw new Error("Credit card processor not loaded");
        }

        const card = elements.getElement(CardElement);

        if (!savedPaymentMethodId && !card) {
          throw new Error("Payment method not found");
        }

        if (!order.stripeClientSecret) {
          throw new Error("Order has not stripe client secret");
        }

        const result = await stripe.confirmCardPayment(
          order.stripeClientSecret,
          savedPaymentMethodId
            ? { payment_method: savedPaymentMethodId }
            : {
                payment_method: {
                  card: card as StripeCardElement,
                  billing_details: {
                    name: _values.guestContactFullName,
                  },
                },
                setup_future_usage: "off_session",
              },
        );

        if (result.error) {
          setOrderId(order.id);
          Sentry.setExtras(result);
          throw new Error("Payment confirmation has failed");
        }

        if (result.paymentIntent) {
          // The payment has been processed!
          if (result.paymentIntent.status === "succeeded") {
            await pollOrder(order.id, onComplete);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        if (error instanceof ApolloError) {
          const msg = extractFirstGraphQLErrorMessage(error);
          if (msg) {
            _setStatus(msg);
          } else {
            Sentry.captureException(error);
            _setStatus("There was an error processing your order");
          }
        } else {
          _setStatus(error.message);
        }
      }
    },
  });

  const handleCreditCardChange = useCallback(
    (complete: boolean, error?: string) => {
      if (complete) {
        setFieldValue("paymentComplete", true);
      } else {
        setFieldValue("paymentComplete", false);
      }

      if (error) {
        setFieldError("paymentComplete", error);
      } else {
        setFieldError("paymentComplete", undefined);
      }
    },
    [setFieldError, setFieldValue],
  );

  const paymentInfoIsIncomplete = !values.paymentComplete;

  // Initialize available user contact info values
  useEffect(() => {
    if (currentUser) {
      setFieldValue("guestContactEmail", currentUser.email ?? "");
      setFieldValue("guestContactFullName", currentUser.fullName);
      setFieldValue("guestContactPhoneNumber", currentUser.phoneNumber ?? "");
      setFieldValue("guestContactCompanyName", currentUser.organization?.name);
    }
  }, [currentUser, setFieldValue]);
  useEffect(() => {
    if (initialValues.quoteId) {
      setFieldValue("quoteId", initialValues.quoteId);
    }
  }, [initialValues.quoteId, setFieldValue]);

  return {
    handleChange,
    onCreditCardChange: handleCreditCardChange,
    setFieldValue,
    setFieldValues,
    setFieldError,
    setErrors,
    submit,
    status,
    paymentInfoIsIncomplete,
    submitting,
    values,
    errors,
    changed,
    submitCount,
  };
}

function usePollOrderUntilOrderComplete() {
  const [pollOrder] = useLazyQuery<
    BookingUtilsOrderDetailsQuery,
    BookingUtilsOrderDetailsQueryVariables
  >(bookingUtilsOrderDetailsGQLQuery);
  const completedRef = useRef(false);

  return useCallback(
    async (orderID: string, onComplete: (order: Order) => void) => {
      await new Promise((resolve, reject) => {
        let count = 0;

        // Start polling
        const interval = setInterval(async () => {
          // poll for maximum 60 seconds
          if (count < 60) {
            const { data } = await pollOrder({
              variables: { id: orderID },
              fetchPolicy: "network-only",
            });
            count++;

            const polledOrder = data?.order;

            if (!polledOrder) {
              clearInterval(interval);
              reject("Wrong order");
              return;
            }

            const completeStatus =
              polledOrder.status === OrderStatus.Paid ||
              polledOrder.status === OrderStatus.Confirmed;

            if (completeStatus && polledOrder.orderItems.length > 0) {
              clearInterval(interval);
              if (completedRef.current === false) {
                onComplete(polledOrder as Order);
                completedRef.current = true;
              }
              resolve(polledOrder);
            }
          } else {
            clearInterval(interval);
            reject("Order creation unsuccessful");
          }
        }, 1000);
      });
    },
    [pollOrder],
  );
}

export function isDateRangeDisabled(dateRange?: DateRange) {
  let disabled = !dateRange;

  if (dateRange !== undefined) {
    if (dateRange.type === BookingType.DailyBooking) {
      disabled = !dateRange.startDate || !dateRange.endDate;
    } else {
      disabled = !dateRange.date || !dateRange.startTime || !dateRange.endTime;
    }
  }

  return disabled;
}

export function getDateRangeForNextBookingType(
  dateRange?: DateRange,
  bookingType?: BookingType,
): DateRange | undefined {
  let prevDateRange = dateRange;

  if (prevDateRange !== undefined) {
    if (
      prevDateRange.type === BookingType.DailyBooking &&
      bookingType === BookingType.HourlyBooking
    ) {
      return {
        type: BookingType.HourlyBooking,
        date: prevDateRange.startDate,
      };
    } else if (
      prevDateRange.type === BookingType.HourlyBooking &&
      bookingType === BookingType.DailyBooking
    ) {
      return {
        type: BookingType.DailyBooking,
        startDate: prevDateRange.date,
        endDate: prevDateRange.date,
      };
    }
  }

  return dateRange;
}

export function toCheckoutMutationInput(
  space: SpaceDetails__AllDetailsFragment,
  values: CheckoutInput,
): CheckoutMutationVariables["input"] | null {
  const {
    quoteId,
    dateRange,
    physicalSpaceID,
    layoutID,
    meetingName,
    meetingStartTime,
    arrivalTime,
    meetingContactEmail,
    meetingContactFullName,
    meetingContactPhoneNumber,
    bookingOnBehalf,
    promoCode,
    subSource,
    memo,
  } = values;

  if (dateRange === null || dateRange === undefined) {
    return null;
  }
  const orderItems: OrderItemInput[] = [];

  const bookingBase = {
    layoutID,
    attendees: space.maxCapacity,
    meetingName,
    arrivalTime,
    meetingContact: bookingOnBehalf
      ? {
          email: meetingContactEmail,
          fullName: meetingContactFullName,
          phoneNumber: meetingContactPhoneNumber,
          companyName: "",
        }
      : undefined,
  };

  if (dateRange.type === BookingType.HourlyBooking) {
    if (!dateRange.startTime || !dateRange.endTime || !dateRange.date) {
      return null;
    }

    orderItems.push({
      type: OrderItemType.HourlyBooking,
      startTime: dateRange.startTime,
      endTime: dateRange.endTime,
      date: dateRange.date,
      spaceID: space.id,
      physicalSpaceID,
      ...bookingBase,
    });
  } else if (dateRange.type === BookingType.DailyBooking) {
    if (!dateRange.startDate || !dateRange.endDate) {
      return null;
    }

    orderItems.push({
      type: OrderItemType.DailyBooking,
      startTime: meetingStartTime || arrivalTime,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      spaceID: space.id,
      physicalSpaceID,
      ...bookingBase,
    });
  }

  return {
    guest: {
      fullName: values.guestContactFullName,
      companyName: values.guestContactCompanyName,
      phoneNumber: values.guestContactPhoneNumber,
      email: values.guestContactEmail,
    },
    promoCode,
    orderItems,
    partnerID: space.partnerID,
    subSource,
    quoteId,
    memo,
  };
}

export function getCancellation1DayBeforeText(
  cancellationTime1DayBefore: string,
  locationTimezone: string,
  now: Date,
  bookingDate: string,
): string {
  const cancellableUntilDate = subDays(parseISO(bookingDate), 1);
  const cancellableUntilTime = setSameDay(
    parseTime(cancellationTime1DayBefore),
    toDay(cancellableUntilDate),
  );
  if (
    zonedTimeToUtc(cancellableUntilTime, locationTimezone).getTime() <=
    now.getTime()
  ) {
    return "Non-refundable";
  }
  return `Free cancellation before ${format(
    cancellableUntilTime,
    "hh:mm a",
  )} on ${format(cancellableUntilDate, "MMM do")}`;
}

export function getCancellationHoursBeforeText(
  cancellationHours: number,
  locationTimezone: string,
  now: Date,
  bookingDate: string,
  bookingTime?: string | null,
): string {
  if (bookingTime) {
    const time = setSameDay(parseTime(bookingTime), bookingDate);
    const cancellableUntilTime = subHours(time, cancellationHours);
    if (
      zonedTimeToUtc(cancellableUntilTime, locationTimezone).getTime() <=
      now.getTime()
    ) {
      return "Non-refundable";
    }
  }

  return `Cancel for free up to ${cancellationHours} hours before your reservation.`;
}

export function getCancellationDaysBeforeText(
  days: number,
  bookingDate: string,
): string {
  const cancellableUntilDate = subDays(parseISO(bookingDate), days);

  return `Free cancellation before ${format(cancellableUntilDate, "MMM do")}`;
}

export function getCancellationBeforeText(
  cancellationHours: number,
  locationTimezone: string,
  now: Date,
  bookingDate: string,
  bookingTime?: string | null,
): string {
  if (bookingTime) {
    const time = setSameDay(parseTime(bookingTime), bookingDate);
    const cancellableUntilTime = subHours(time, cancellationHours);
    if (
      zonedTimeToUtc(cancellableUntilTime, locationTimezone).getTime() <=
      now.getTime()
    ) {
      return "Non-refundable";
    }
  }

  return `Cancel for free up to ${cancellationHours} hours before your reservation.`;
}

export function getCheckoutCancellationPolicyText(
  spaceBookingPolicy: SpaceBookingPolicy,
  locationTimezone: string,
  minStartTime: string,
  dateRange?: DateRange,
  now: Date = new Date(),
): string {
  if (!dateRange) {
    return "";
  }

  const bookingDate = getBookingDate(dateRange);
  if (!bookingDate) {
    return "";
  }

  const { cancellationTime1DayBefore, cancellationHours, cancellationDays } =
    spaceBookingPolicy;

  // cancellation exact 1 date before
  if (cancellationTime1DayBefore) {
    return getCancellation1DayBeforeText(
      cancellationTime1DayBefore,
      locationTimezone,
      now,
      bookingDate,
    );
  }

  // cancellation certain number of hours before
  if (cancellationHours) {
    return getCancellationHoursBeforeText(
      cancellationHours,
      locationTimezone,
      now,
      bookingDate,
      minStartTime,
    );
  }

  // cancellation days before
  if (cancellationDays) {
    return getCancellationDaysBeforeText(cancellationDays, bookingDate);
  }

  // cancellation not configured
  return "Non-refundable";
}

export function isCancelable(
  spaceBookingPolicy: SpaceBookingPolicy,
  locationTimezone: string,
  minStartTime: string,
  dateRange?: DateRange,
  now: Date = new Date(),
) {
  if (!dateRange) {
    return false;
  }

  const date = getBookingDate(dateRange);
  if (!date) {
    return false;
  }

  const { cancellationTime1DayBefore, cancellationHours } = spaceBookingPolicy;

  if (cancellationTime1DayBefore) {
    const cancellableUntilDate = subDays(parseISO(date), 1);
    const cancellableUntilTime = setSameDay(
      parseTime(cancellationTime1DayBefore),
      toDay(cancellableUntilDate),
    );
    if (
      zonedTimeToUtc(cancellableUntilTime, locationTimezone).getTime() <=
      now.getTime()
    ) {
      return false;
    }

    return true;
  }

  if (cancellationHours) {
    if (minStartTime) {
      const time = setSameDay(parseTime(minStartTime), date);
      const cancellableUntilTime = subHours(time, cancellationHours);
      if (
        zonedTimeToUtc(cancellableUntilTime, locationTimezone).getTime() <=
        now.getTime()
      ) {
        return false;
      }
    }

    return true;
  }

  return false;
}

function getBookingDate(dateRange: DateRange): string | undefined {
  let bookingDate;
  if (dateRange.type === BookingType.HourlyBooking && dateRange.date) {
    bookingDate = dateRange.date;
  } else if (
    dateRange.type === BookingType.DailyBooking &&
    dateRange.startDate
  ) {
    bookingDate = dateRange.startDate;
  }
  return bookingDate;
}

interface UseBookingDateRangePickerHelperProps {
  selectedDay?: Day;
  physicalSpaceID?: string;
  space: {
    id: string;
    pricings: Pricing[];
    bookingPolicy: SpaceBookingPolicy;
    bookingConfig: BookingConfig;
    location: {
      timezone: string;
    };
  };
}

export function useBookingDateRangePickerHelper(
  props: UseBookingDateRangePickerHelperProps,
) {
  const { space, selectedDay, physicalSpaceID } = props;

  const { data: availableDaysData, error: availableDaysError } = useQuery<
    SpaceAvailableDaysQuery,
    SpaceAvailableDaysQueryVariables
  >(spaceAvailableDaysGQLQuery, {
    variables: {
      id: space.id,
      input: {
        startDate: getLocalToday(space.location.timezone),
        endDate: addMonths(getLocalToday(space.location.timezone), 6),
        physicalSpaceID,
      },
    },
    fetchPolicy: "network-only",
  });

  const { data: availableTimeSlotsData, error: availableTimeSlotsError } =
    useQuery<
      SpaceAvailableTimeSlotsQuery,
      SpaceAvailableTimeSlotsQueryVariables
    >(spaceAvailableTimeSlotsGQLQuery, {
      skip: !selectedDay,
      variables: {
        id: space.id,
        input: { date: selectedDay! },
      },
      fetchPolicy: "network-only",
    });

  // we block dates until we know about availability
  const isBlockedDate = (day: Day, bookingType: BookingType) => {
    if (availableDaysData) {
      const availableDay = availableDaysData.space?.availableDays.find(
        (d) => d.date === day,
      );

      if (!availableDay) {
        return true;
      }

      if (
        bookingType === BookingType.DailyBooking &&
        availableDay.availableFullDay === false
      ) {
        return true;
      }

      return false;
    }

    return true;
  };

  const isOutsideRange = (date: Day) => {
    return (
      isBefore(
        parseDay(date),
        parseDay(getLocalToday(space.location.timezone)),
      ) ||
      isAfter(
        parseDay(date),
        parseDay(addMonths(getLocalToday(space.location.timezone), 6)),
      )
    );
  };

  let openAt: Time = "0600";
  let closedAt: Time = "2300";

  if (availableDaysData && selectedDay) {
    const day = availableDaysData.space?.availableDays.find((d) =>
      isSameDay(d.date, selectedDay),
    );

    if (day) {
      openAt = day.openAt;
    }
  }

  if (availableDaysData && selectedDay) {
    const day = availableDaysData.space?.availableDays.find((d) =>
      isSameDay(d.date, selectedDay),
    );

    if (day) {
      closedAt = day.closedAt;
    }
  }

  const bookingTypesAllowed = space.bookingConfig.enabled;
  const showSegmentedControl = bookingTypesAllowed.length > 1;
  const initialBookingType = showSegmentedControl
    ? BookingType.DailyBooking
    : bookingTypesAllowed[0] === BookingType.DailyBooking
    ? BookingType.DailyBooking
    : BookingType.HourlyBooking;
  const multiDayBookingAllowed =
    space.bookingConfig.multiDayBookingAllowed === undefined
      ? bookingTypesAllowed.includes(BookingType.DailyBooking)
      : space.bookingConfig.multiDayBookingAllowed;

  return {
    openAt,
    closedAt,
    availableDaysError,
    availableTimeSlotsError,
    showSegmentedControl,
    multiDayBookingAllowed,
    initialBookingType,
    isBlockedDate,
    isOutsideRange,
    loadingAvailability: !availableTimeSlotsData || !availableDaysData,
    timeslots: availableTimeSlotsData?.space?.availableTimeSlots
      ? availableTimeSlotsData.space?.availableTimeSlots
      : [],
  };
}

const spaceAvailableDaysGQLQuery = gql`
  query SpaceAvailableDays($id: ID!, $input: SpaceAvailableDaysInput!) {
    space(id: $id) {
      id
      availableDays(input: $input) {
        date
        openAt
        closedAt
        availableFullDay
      }
    }
  }
`;

const spaceAvailableTimeSlotsGQLQuery = gql`
  query SpaceAvailableTimeSlots(
    $id: ID!
    $input: SpaceAvailableTimeSlotsInput!
  ) {
    space(id: $id) {
      id
      availableTimeSlots(input: $input) {
        startTime
        endTimes
      }
    }
  }
`;

export function hasReservationDetails(
  space: SpaceDetails__AllDetailsFragment,
): boolean {
  return space.layouts.length > 1;
}

export function getSelectedDay(dateRange?: DateRange): Day | undefined {
  if (dateRange?.type === BookingType.HourlyBooking) {
    return dateRange.date;
  } else if (dateRange?.type === BookingType.DailyBooking) {
    return dateRange.startDate;
  }
}

export function getDefaultLayoutID(space: {
  defaultLayoutID: string;
  layouts: SpaceLayout[];
}): string {
  const layouts = space.layouts;
  const defaultLayout = layouts.find((l) => l.id === space.defaultLayoutID);

  if (defaultLayout !== undefined) {
    return defaultLayout.id;
  }

  return layouts[0].id;
}

export function getDateRangeFromBooking(
  booking: DailyBooking | HourlyBooking,
): DailyDateRange | HourlyDateRange {
  if (booking.__typename === "DailyBooking") {
    return {
      type: BookingType.DailyBooking,
      startDate: booking.startDate,
      endDate: booking.endDate,
    };
  } else if (booking.__typename === "HourlyBooking") {
    return {
      type: BookingType.HourlyBooking,
      startTime: booking.startTime,
      endTime: booking.endTime,
      date: booking.date,
    };
  }

  throw new Error("Could not get date range from booking");
}

export function isUpcomingBooking(booking: DailyBooking | HourlyBooking) {
  if (booking.__typename === "DailyBooking") {
    return (
      isAfter(parseDay(booking.startDate), new Date()) ||
      isSameDay(booking.startDate, today())
    );
  }

  if (booking.__typename === "HourlyBooking") {
    return (
      isAfter(parseDay(booking.date), new Date()) ||
      isSameDay(booking.date, today())
    );
  }

  return false;
}

export function isPastBooking(booking: DailyBooking | HourlyBooking) {
  if (booking.__typename === "DailyBooking") {
    return isBefore(parseDay(booking.startDate), startOfDay(new Date()));
  }

  if (booking.__typename === "HourlyBooking") {
    return isBefore(parseDay(booking.date), startOfDay(new Date()));
  }

  return false;
}

const bookingUtilsOrderDetailsGQLQuery = gql`
  query BookingUtilsOrderDetails($id: ID!) {
    order(id: $id) {
      id
      totalPrice
      currency
      status
      memo
      guest {
        fullName
        companyName
        email
        phoneNumber
      }
      orderItems {
        id
        details {
          __typename
          ... on DailyBooking {
            startDate
            endDate
            time
            space {
              id
              locationName
              name
              city
              country
            }
          }
          ... on HourlyBooking {
            date
            startTime
            endTime
            space {
              id
              locationName
              name
              city
              country
            }
          }
        }
      }
    }
  }
`;

const checkoutGQLMutation = gql`
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      id
      stripeClientSecret
      totalPrice
    }
  }
`;

const updateUserGQLMutation = gql`
  mutation BookingUpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      hasBookedBefore
      fullName
      email
      phoneNumber
    }
  }
`;

const currentUserInfoGQLQuery = gql`
  query SpaceCheckoutCurrentUserInfo {
    currentUser {
      id
      hasBookedBefore
      hasSignedUpWithSocialProvider
      fullName
      email
      phoneNumber
      organization {
        id
        name
      }
    }
  }
`;

const spaceGQLQuery = gql`
  query SpaceCheckoutSpaceInfo($id: ID!) {
    space(id: $id) {
      id
      partnerID
      maxCapacity
    }
  }
`;
