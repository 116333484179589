import React from "react";
import { StripeProvider } from "providers/stripe";

export function withStripe<P extends object>(
  WrappedComponent: React.ComponentType<P>,
) {
  return (props: P) => (
    <StripeProvider>
      <WrappedComponent {...props} />
    </StripeProvider>
  );
}
