import { AcceptInvitePage } from "pages/accept_invite/accept_invite";
import { BookingDetails } from "pages/booking_details/booking_details";
import { BookingRequests } from "pages/booking_requests/booking_requests";
import { Bookings } from "pages/bookings/bookings";
import { BudgetsPage } from "pages/budgets/budgets";
import { GroupBudgetCreate } from "pages/budgets/group_budget/group_budget_create";
import { GroupBudgetDetail } from "pages/budgets/group_budget/group_budget_detail";
import { GroupBudgetUpdate } from "pages/budgets/group_budget/group_budget_update";
import { OrganizationBudgetCreate } from "pages/budgets/organization_budget/organization_budget_create";
import { OrganizationBudgetDetail } from "pages/budgets/organization_budget/organization_budget_detail";
import { OrganizationBudgetUpdate } from "pages/budgets/organization_budget/organization_budget_update";
import { PolicyCreate } from "pages/budgets/policy/policy_create";
import { PolicyEdit } from "pages/budgets/policy/policy_edit";
import { CustomOrgSignIn } from "pages/custom_org_sign_in/custom_org_sign_in";
import { HelpPage } from "pages/help";
import { HomePage } from "pages/homev2/home";
import { HomePageV3 } from "pages/homev3/home";
import { LocationDetails } from "pages/location_details/location_details";
import { OrganizationAuthenticationMobile } from "pages/manage_organization/organization_authentication_mobile";
import { OrganizationMobile } from "pages/manage_organization/organization_mobile";
import { PlanDetailsPage } from "pages/manage_organization/plan_details_mobile";
import { ManageUsersPage } from "pages/manage_users/manage_users";
import { OfficeSpacePage } from "pages/office_space";
import { OffsiteOrderPage } from "pages/offsite_order/offsite_order";
import { OffsiteSpaceCheckoutPage } from "pages/offsite_space_checkout/offsite_space_checkout";
import { OffsiteSpaceDetailsPageV2 } from "pages/offsite_space_details_v2/offsite_space_details_v2";
import { OrganizationProfilePage } from "pages/organization_profile/organization_profile";
import { ReportingPage } from "pages/reporting/reporting_page";
import { SavedPlaces } from "pages/saved_places/saved_places";
import { SignInStepPassword } from "pages/sign_in/components/step_password";
import { SignInPage } from "pages/sign_in/sign_in";
import { SignUpPage } from "pages/sign_up/sign_up";
import { SignUpGcalPage } from "pages/sign_up_gcal/sign_up_gcal";
import { TeamSpacesPageV2 } from "pages/team_spaces/team_spaces_v2";
import { TodayPage } from "pages/today/today";
import { UserProfilePage } from "pages/user_profile/user_profile";
import { UserProfileBookingPolicy } from "pages/user_profile_booking_policy";
import { UserProfileDetailsPage } from "pages/user_profile_details";
import { UserProfilePaymentsPage } from "pages/user_profile_payments";
import { UserProfileReservationDetailsPage } from "pages/user_profile_reservation_details";
import { UserProfileReservationsPage } from "pages/user_profile_reservations";

export interface CreateRoutesOptions {
  reBookFeatureFlag?: boolean;
  favoritesFeatureFlag?: boolean;
  sSOFeatureFlag?: boolean;
  openDirectoryFeatureFlag?: boolean;
}

export interface RouteItem {
  pageName: string;
  path: string;
  children: JSX.Element;
  public?: boolean;
  exact: boolean;
}

export const ROUTES = {
  SIGNUP: {
    pageName: "Sign up",
    path: "/sign-up",
    children: <SignUpPage />,
    public: true,
    exact: false,
  },
  HOME: {
    pageName: "Home",
    path: "/",
    children: <HomePage />,
    exact: true,
  },

  SIGNUP_GCAL: {
    pageName: "Signup GCAL",
    path: "/sign-up-gcal",
    children: <SignUpGcalPage />,
    public: true,
    exact: false,
  },
  SIGN_IN: {
    pageName: "Sign in",
    path: "/sign-in",
    children: <SignInPage />,
    public: true,
    exact: true,
  },
  SIGN_IN_STEP_PASS_WORD: {
    pageName: "Sign in",
    path: "/sign-in/password",
    children: <SignInStepPassword />,
    public: true,
    exact: true,
  },
  TEAM_SPACES: {
    pageName: "Team",
    path: "/team-spaces",
    children: <TeamSpacesPageV2 />,
    exact: true,
  },
  ORDER_DETAIL: {
    pageName: "Order detail",
    path: "/orders/:orderID",
    children: <OffsiteOrderPage />,
    exact: true,
  },
  OFFICE_DETAIL: {
    pageName: "Office detail",
    path: "/offices/:officeSpaceID",
    children: <OfficeSpacePage />,
    exact: true,
  },
  CHECKOUT: {
    pageName: "Checkout",
    path: "/offsite_spaces/:offsiteSpaceID/checkout",
    children: <OffsiteSpaceCheckoutPage />,
    exact: true,
  },
  SPACE_DETAIL: {
    pageName: "Space detail",
    path: "/offsite_spaces/:offsiteSpaceID",
    children: <OffsiteSpaceDetailsPageV2 />,
    exact: true,
  },
  ANALYTICS: {
    pageName: "Analytics",
    path: "/admin/analytics",
    children: <ReportingPage />,
    exact: true,
  },
  USER_PROFILE: {
    pageName: "User profile",
    path: "/user-profile",
    children: <UserProfilePage />,
    exact: true,
  },
  USER_PROFILE_DETAIL: {
    pageName: "User profile detail",
    path: "/user-profile/details",
    children: <UserProfileDetailsPage />,
    exact: true,
  },
  RESERVATIONS: {
    pageName: "Reservations",
    path: "/user-profile/reservations",
    children: <UserProfileReservationsPage />,
    exact: true,
  },
  RESERVATION_DETAIL: {
    pageName: "Reservation detail",
    path: "/user-profile/reservations/:reservationID",
    children: <UserProfileReservationDetailsPage />,
    exact: true,
  },
  PAYMENTS: {
    pageName: "Payments",
    path: "/user-profile/payments",
    children: <UserProfilePaymentsPage />,
    exact: true,
  },
  HELP: {
    pageName: "Help",
    path: "/help",
    children: <HelpPage />,
    exact: true,
  },
  BOOKING_POLICY: {
    pageName: "Booking policy",
    path: "/user-profile/booking-policy",
    children: <UserProfileBookingPolicy />,
    exact: true,
  },
  ACCEPT_INVITE: {
    pageName: "Accept Invite",
    path: "/accept-invite",
    children: <AcceptInvitePage />,
    public: true,
    exact: false,
  },
  TODAY: {
    pageName: "Today",
    path: "/admin/today",
    children: <TodayPage />,
    exact: true,
  },
  MANAGE_USERS: {
    pageName: "Manage Users",
    path: "/admin/manage-users",
    children: <ManageUsersPage />,
    exact: true,
  },
  ORGANIZATION_PROFILE: {
    pageName: "Organization Profile",
    path: "/admin/organization-profile",
    children: <OrganizationProfilePage />,
    exact: true,
  },
  PLAN_DETAILS_MOBILE: {
    pageName: "Plan Details Mobile",
    path: "/admin/plan-details",
    children: <PlanDetailsPage />,
    exact: true,
  },
  ORGANIZATION_MOBILE: {
    pageName: "Organization Mobile",
    path: "/admin/organization",
    children: <OrganizationMobile />,
    exact: true,
  },
  SSO_MOBILE: {
    pageName: "SSO Mobile",
    path: "/admin/sso",
    children: <OrganizationAuthenticationMobile />,
    exact: true,
  },
  BUDGETS: {
    pageName: "Budgets",
    path: "/admin/budgets",
    children: <BudgetsPage />,
    exact: true,
  },
  CREATE_ORGANIZATION_BUDGET: {
    pageName: "Create organization budget",
    path: "/admin/budgets/organization/new",
    children: <OrganizationBudgetCreate />,
    exact: true,
  },
  EDIT_ORGANIZATION_BUDGET: {
    pageName: "Edit organization budget",
    path: "/admin/budgets/organization/edit",
    children: <OrganizationBudgetUpdate />,
    exact: true,
  },
  CREATE_GROUP_BUDGET: {
    pageName: "Create group budget",
    path: "/admin/budgets/group-budget/new",
    children: <GroupBudgetCreate />,
    exact: true,
  },
  EDIT_GROUP_BUDGET: {
    pageName: "Edit group budget",
    path: "/admin/budgets/:groupBudgetId/edit",
    children: <GroupBudgetUpdate />,
    exact: true,
  },
  ORGANIZATION_BUDGET_DETAIL: {
    pageName: "Organization budget detail",
    path: "/admin/budgets/organization",
    children: <OrganizationBudgetDetail />,
    exact: true,
  },
  GROUP_BUDGET_DETAIL: {
    pageName: "Group budget detail",
    path: "/admin/budgets/:groupBudgetId",
    children: <GroupBudgetDetail />,
    exact: true,
  },
  CREATE_POLICY: {
    pageName: "Create policy",
    path: "/admin/budgets/policy/new",
    children: <PolicyCreate />,
    exact: true,
  },
  EDIT_POLICY: {
    pageName: "Edit policy",
    path: "/admin/budgets/policy/:policyId/edit",
    children: <PolicyEdit />,
    exact: true,
  },
  BOOKING_REQUESTS: {
    pageName: "Booking Requests",
    path: "/admin/booking-requests",
    children: <BookingRequests />,
    exact: true,
  },
  BOOKINGS: {
    pageName: "Bookings",
    path: "/bookings",
    children: <Bookings />,
    exact: true,
  },
  BOOKING_DETAILS: {
    pageName: "Booking details",
    path: "/bookings/:orderId",
    children: <BookingDetails />,
    exact: true,
  },
  LOCATION_DETAILS: {
    pageName: "Location details",
    path: "/location/:locationId",
    children: <LocationDetails />,
    exact: true,
  },
};

type RouteNameKey = keyof typeof ROUTES;

export type OptionalRouteNameKey = "SEARCH";

export type RoutesList = Record<RouteNameKey, RouteItem> &
  Partial<Record<OptionalRouteNameKey, RouteItem>>;

export const createRoutes = ({
  reBookFeatureFlag,
  favoritesFeatureFlag,
  sSOFeatureFlag,
  openDirectoryFeatureFlag,
}: CreateRoutesOptions = {}): RoutesList => {
  const routes = {
    ...ROUTES,
    ...(reBookFeatureFlag
      ? {
          SEARCH: {
            pageName: "Search",
            path: "/search",
            children: <HomePage />,
            exact: true,
            public: openDirectoryFeatureFlag,
          },
          HOME: {
            pageName: "Home",
            path: "/",
            children: <HomePageV3 />,
            exact: true,
            public: openDirectoryFeatureFlag,
          },
        }
      : {}),
    ...(favoritesFeatureFlag
      ? {
          SAVED_PLACES: {
            pageName: "Saved places",
            path: "/saved-places",
            children: <SavedPlaces />,
            exact: true,
          },
        }
      : {}),
    ...(sSOFeatureFlag
      ? {
          SLUG: {
            pageName: "Custom org sign in",
            path: "/:orgSlug",
            children: <CustomOrgSignIn />,
            exact: true,
            public: true,
          },
        }
      : {}),
    ...(openDirectoryFeatureFlag
      ? {
          CHECKOUT: {
            pageName: "Checkout",
            path: "/offsite_spaces/:offsiteSpaceID/checkout",
            children: <OffsiteSpaceCheckoutPage />,
            exact: true,
            public: openDirectoryFeatureFlag,
          },
          SPACE_DETAIL: {
            pageName: "Space detail",
            path: "/offsite_spaces/:offsiteSpaceID",
            children: <OffsiteSpaceDetailsPageV2 />,
            exact: true,
            public: openDirectoryFeatureFlag,
          },
          LOCATION_DETAILS: {
            pageName: "Location details",
            path: "/location/:locationId",
            children: <LocationDetails />,
            exact: true,
            public: openDirectoryFeatureFlag,
          },
          BOOKINGS: {
            pageName: "Bookings",
            path: "/bookings",
            children: <Bookings />,
            exact: true,
            public: openDirectoryFeatureFlag,
          },
          TEAM_SPACES: {
            pageName: "Team",
            path: "/team-spaces",
            children: <TeamSpacesPageV2 />,
            exact: true,
            public: openDirectoryFeatureFlag,
          },
        }
      : {}),
  };
  return routes;
};
