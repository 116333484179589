import { Auth0Error } from "auth0-js";
import { AuthPageContainer } from "components/auth_page_container";
import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { PasswordField } from "components/password_field";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Footer } from "core/new_footer";
import { OnboardingHeader } from "core/onboarding_header";
import { ROUTES } from "core/routes";
import { useForm } from "hooks/use_form";
import { useMediaQuery } from "lib/media_query";
import { ResetPasswordForm } from "pages/sign_in/components/reset_password_form";
import { EmailPasswordLoginFormValues } from "pages/sign_in/sign_in";
import { useAuthV2 } from "providers/authv2";
import { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";

type SignInStepPasswordState = {
  email: string;
};
export function SignInStepPassword() {
  const { logInWithEmailAndPassword } = useAuthV2();
  const {
    state: { email },
  } = useLocation<SignInStepPasswordState>();
  const mq = useMediaQuery();
  const history = useHistory();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const { values, changed, errors, setFieldValue, submit, submitting } =
    useForm({
      initialValues: { email: "", password: "", authenticationError: "" },
      validate: ({ password }: EmailPasswordLoginFormValues) => {
        const errors: { [field: string]: string } = {};
        if (!password) {
          errors.password = "Please input a password";
        }
        return errors;
      },
      onSubmit: async (
        { password }: EmailPasswordLoginFormValues,
        { setSubmitting, setErrors }: any,
      ) => {
        setSubmitting(true);
        try {
          await logInWithEmailAndPassword(email, password);
        } catch (error) {
          setErrors({
            authenticationError: (error as Auth0Error).description,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });

  if (!email) {
    history.replace(ROUTES.SIGN_IN.path);
  }

  const goToSignIn = () => history.push(ROUTES.SIGN_IN.path);

  return (
    <AuthPageContainer
      header={
        <OnboardingHeader buttonTitle={"Log in"} onButtonClick={goToSignIn} />
      }
      footer={<Footer />}
    >
      <>
        {showResetPassword ? (
          <Content maxWidth={343}>
            <ResetPasswordForm
              onComplete={() => setShowResetPassword(false)}
              onGoBack={() => setShowResetPassword(false)}
            />
          </Content>
        ) : (
          <View
            style={[
              styles.mainContainer,
              mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
            ]}
          >
            <Content maxWidth={728}>
              <Heading
                size={mq.deviceQuery.mobile ? "lg" : "mega"}
                align="center"
              >
                Enter the password for your Flexspace account
              </Heading>
              <Spacer size={40} />
              <View
                style={{
                  flex: 1,
                  width: mq.deviceQuery.mobile ? "100%" : 343,
                  margin: "auto",
                }}
              >
                <PasswordField
                  testID="login-password"
                  value={values.password}
                  onChange={(password) =>
                    setFieldValue("password", password.trim())
                  }
                  placeholder="Password"
                  invalid={changed.password && !!errors.password}
                  invalidText={changed.password ? errors.password : ""}
                  withRevealButton
                  onSubmitEditing={submit}
                />
                <Spacer size={15} />
                {!!errors.authenticationError && (
                  <>
                    <Text color="grapefruit-core" size="xs">
                      {errors.authenticationError}
                    </Text>
                    <Spacer size={16} />
                  </>
                )}
                <Pressable onPress={() => setShowResetPassword(true)}>
                  <Text weight="bold" size="xs" color="eggplant-core">
                    I forgot my password
                  </Text>
                </Pressable>
                <Spacer size={16} />
              </View>
            </Content>
          </View>
        )}

        {!showResetPassword && (
          <View
            style={[
              styles.buttonContainer,
              mq.deviceQuery.mobile && styles.buttonContainerMobile,
            ]}
          >
            <Button
              text="Continue"
              onPress={submit}
              testID="sign-up-password-submit-button"
              loading={submitting}
            />
          </View>
        )}
      </>
    </AuthPageContainer>
  );
}

const styles = StyleSheet.create({
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: "95%",
    maxWidth: 220,
    alignSelf: "center",
  },
  buttonContainerMobile: {
    maxWidth: 343,
  },
});
