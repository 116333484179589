import { useCallback, useRef, useState } from "react";
import { Spacer } from "components/spacer";
import { formatDate } from "lib/date_utils";
import { Day, parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { FilterDatePicker } from "core/filter_date_picker";
import { Divider } from "components/divider";
import { Icon } from "components/icon";
import { colors } from "components/colors";

interface DatePickerMobileProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
}

export function EmptySpacesMobileDatePicker(props: DatePickerMobileProps) {
  const { value, onChange } = props;
  const [open, setOpen] = useState(false);
  const datePickerElemRef = useRef<View>(null);
  const handleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      setTimeout(() => {
        // @ts-ignore
        datePickerElemRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 0);
    }
  }, [open]);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleSave = useCallback(
    (v: Day) => {
      setOpen(false);
      onChange(v);
    },
    [onChange],
  );

  return (
    <>
      <View style={styles.wrapper}>
        <Text size="xs" weight="semibold">
          Date
        </Text>
        <Spacer size={4} />
        <Pressable style={styles.button} onPress={handleOpen}>
          <View style={styles.picker}>
            <Text size="xs">
              {value
                ? formatDate(parseDay(value), getSystemLocale(), {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : "Any date"}
            </Text>
            <Icon name={open ? "feather-chevron-up" : "feather-chevron-down"} />
          </View>
        </Pressable>
      </View>

      {open && (
        <>
          <Spacer size={7} />
          <Divider />
          <Spacer size={11} />
          <FilterDatePicker value={value} onChange={handleSave} />
          <Spacer size={6} />
          <Pressable
            onPress={() => {
              onChange("");
              handleClose();
            }}
          >
            <Text
              align="center"
              size="xs"
              color="eggplant-core"
              weight="semibold"
            >
              Clear date
            </Text>
          </Pressable>
          <View ref={datePickerElemRef} />
          <Spacer size={4} />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  dialogContent: {
    flex: 1,
    padding: 24,
  },
  wrapper: {
    display: "flex",
  },
  button: {
    padding: 8,
    backgroundColor: colors.brand.whitecore,
  },
  picker: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
