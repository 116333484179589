import "./pay_as_you_go_promo.web.css";
import React from "react";
import { Text } from "components/text_v2";
import { Button } from "components/button_v2";
import { Spacer } from "components/spacer";
import { View } from "react-native";

interface ReportingPayAsYouGoPromoProps {
  onPress: () => void;
}

export function ReportingPayAsYouGoPromo(props: ReportingPayAsYouGoPromoProps) {
  const { onPress } = props;
  return (
    <div className="reporting-on-demand-promo">
      <View>
        <Text weight="semibold" align="center" color="white-core" size="md">
          Upgrade to get more and save!
        </Text>
      </View>
      <Spacer size={16} />
      <View>
        <Text size="xs" align="center" color="white-core">
          Streamline your operations with budget spend control and monthly
          organization billing to improve employee experience.
        </Text>
      </View>
      <Spacer size={48} />
      <Button onPress={onPress} appearance="secondary" text="Get Started" />
    </div>
  );
}
