import { HorizontalList } from "components/horizontal_list";
import {
  OrganizationUser,
  OrgBookingFragment,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { useCurrentUserGeoCoordinatesQuery } from "pages/homev2/hooks/use_current_user_geo_coordinates";
import { useInitialPlace } from "pages/homev2/hooks/use_initial_place";
import { toCoordinates } from "pages/homev2/mapbox";
import { ListSpacesSkeleton } from "pages/homev3/components/list_spaces_skeleton";
import { LocationCard } from "pages/homev3/components/location_card";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useLocationNearByQuery } from "pages/homev3/hooks/use_location_nearby_query";
import { useOrgBookingQuery } from "pages/homev3/hooks/use_org_booking_query";
import { useAnalytics } from "providers/analytics";
import React, { useCallback } from "react";
import { useUserType } from "../hooks/use_user_type";

export enum homeUserType {
  returning = "Returning User",
  new = "New User",
}

export function WorkNearBy() {
  const { data: userData } = useCurrentUserGeoCoordinatesQuery();
  const initialPlaceQuery = useInitialPlace();
  const coordinates = initialPlaceQuery?.data
    ? toCoordinates(initialPlaceQuery?.data?.center)
    : null;

  const { data: locationData, loading: locationLoading } =
    useLocationNearByQuery({
      location: {
        lat: (userData?.lat || coordinates?.lat)!,
        lng: (userData?.lng || coordinates?.lng)!,
      },
    });
  const { data: orgData } = useOrgBookingQuery({});

  if (locationLoading) {
    return <ListSpacesSkeleton />;
  }

  if (!locationData || locationData.locations.length === 0) {
    return null;
  }

  return (
    <Content
      locations={locationData.locations}
      orgBookings={orgData.bookings}
    />
  );
}

type ContentProps = {
  locations: SpaceLocationDetailFragment[];
  orgBookings: OrgBookingFragment[];
};
function Content({ locations, orgBookings }: ContentProps) {
  const analytics = useAnalytics();
  const userType = useUserType();

  const onCardPress = (locationName: string, locationId: string) => {
    analytics.event("Click Nearby Spaces Card", {
      "Location UUID": locationId,
      "Location Name": locationName,
      Source: userType,
      category: "Rebooking",
    });
  };
  const orgGroupedBooking = groupBy(orgBookings, (b) => b.space.location.id);
  const renderItem = (location: SpaceLocationDetailFragment) => {
    const locationId = location.id;
    let peoples: Partial<OrganizationUser>[] = [];
    if (orgGroupedBooking[locationId]) {
      peoples = orgGroupedBooking[locationId].map((b) => b.user);
    }
    return (
      <LocationCard
        onPress={() => onCardPress(location.name, location.id)}
        key={location.id}
        location={location}
        peoples={peoples}
      />
    );
  };

  const onNavigate = useCallback(() => {
    analytics.event("Navigate Horizontal List", {
      Section: "Nearby Spaces",
      category: "Rebooking",
    });
  }, [analytics]);

  return (
    <HorizontalList
      title={
        <TitleSpaceList
          title={"Work nearby"}
          subtitle={"Get out of the house for a change of scenery"}
        />
      }
      data={locations}
      renderItem={renderItem}
      onNavigate={onNavigate}
    />
  );
}
