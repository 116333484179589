import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Pressable, ScrollView } from "react-native";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { Icon } from "components/icon";
import { Badge } from "components/badge";
import { DialogContent } from "./dialog_content";
import {
  OffsiteDetailsWhosHereQuery,
  OffsiteSpaceDetailsWhosHere__BookingDetailsV2Fragment,
  SpaceType,
} from "./graphql.generated";
import { getPeopleFromBookings } from "./offsite_location_whos_here";
import { People } from "./people";
import { ResponsiveGrid } from "./responsive_grid";
import {
  DayInterval,
  formatDayWithTodayAndTomorrowLabel,
  getDays,
  getLocalToday,
  isWithinDayInterval,
} from "lib/day_utils";
import { isEmpty } from "lib/lang_utils";
import { useMediaQuery } from "lib/media_query";
import { Heading } from "components/heading";
import { Dialog } from "components/dialog";
import { Avatar } from "components/avatar";

interface WhosHereProps {
  dayInterval: DayInterval;
  bookings: OffsiteSpaceDetailsWhosHere__BookingDetailsV2Fragment[];
  timeZone: string;
  capacity: number;
  spaceType: SpaceType;
}

export function WhosHere(props: WhosHereProps) {
  const { bookings, dayInterval, capacity, timeZone, spaceType } = props;

  if (!bookings || isEmpty(bookings)) {
    return null;
  }

  const days = getDays(dayInterval);
  const today = getLocalToday(timeZone);

  return (
    <Container>
      <ResponsiveGrid horizontalSpacing={48} itemsPerRow={1}>
        {days.map((day) => {
          const dayBookings = bookings.filter((b) => {
            return isWithinDayInterval(day, {
              start: b.startDate,
              end: b.endDate,
            });
          });

          if (isEmpty(dayBookings)) {
            return null;
          }

          return (
            <Container key={day}>
              <WhosHereDay
                day={day}
                bookings={dayBookings}
                today={today}
                capacity={capacity}
                spaceType={spaceType}
              />
            </Container>
          );
        })}
      </ResponsiveGrid>
    </Container>
  );
}

interface WhosHereDayProps {
  today: string;
  day: string;
  capacity: number;
  bookings: OffsiteSpaceDetailsWhosHere__BookingDetailsV2Fragment[];
  spaceType: SpaceType;
}

export function WhosHereDay(props: WhosHereDayProps) {
  const { day, capacity, bookings, today, spaceType } = props;
  const [open, setOpen] = useState(false);
  const mq = useMediaQuery();
  const full =
    capacity && bookings.length >= capacity && spaceType !== SpaceType.DayPass;
  const { data: whosHere } =
    useQuery<OffsiteDetailsWhosHereQuery>(whosHereGQLQuery);
  const currentUser = whosHere?.currentUser;
  let content = null;
  const people = getPeopleFromBookings(bookings, currentUser?.id || "");
  if (isEmpty(bookings)) {
    content = (
      <div className="offsite-space-booking-row">
        <Heading size="h4">
          {formatDayWithTodayAndTomorrowLabel(day, today)}
        </Heading>
        <Spacer size={8} />
        <Text color="muted">Nobody booked.</Text>
      </div>
    );
  } else {
    content = (
      <div
        role="button"
        tabIndex={0}
        className="offsite-space-booking-row offsite-space-booking-row-hoverable"
        onClick={() => setOpen(true)}
      >
        <Container>
          <Heading size="h4">
            {formatDayWithTodayAndTomorrowLabel(day, today)}
          </Heading>
          <Spacer size={8} />
          <Row alignItems="center">
            <Container flex={1}>
              <People people={people} />
            </Container>
            <Row alignItems="center" spacing={16}>
              <Pressable onPress={() => setOpen(true)}>
                <Icon name="dots" />
              </Pressable>
            </Row>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <Container key={day}>
      {content}
      <Dialog
        animationType="slide"
        visible={open}
        onRequestClose={() => setOpen(false)}
      >
        <DialogContent
          headerLeftIcon="x-circle"
          headerTitle="Who's here"
          onHeaderLeftIconPress={() => setOpen(false)}
          desktopWidth={728}
        >
          <ScrollView>
            <Container flex={1} padding={16}>
              <Row>
                <Heading size="h3">
                  {formatDayWithTodayAndTomorrowLabel(day, today)}
                </Heading>
                <Spacer size={16} />
                {full && <Badge title="Full" />}
              </Row>
              <Spacer size={16} />
              <ResponsiveGrid itemsPerRow={mq.sizeQuery.mdAndUp ? 2 : 1}>
                {people.map((p) => (
                  <Container key={p.userID}>
                    <Row>
                      <Container>
                        <Avatar
                          name={p.name}
                          size="xl"
                          source={{ uri: p.pictureURL }}
                        />
                        {p.status === "on" && (
                          <div className="office-space-status-circle" />
                        )}
                      </Container>
                      <Spacer size={16} />
                      <Container flex={1}>
                        <Text weight="600">{p.name}</Text>
                        <Text>{p.title}</Text>
                      </Container>
                    </Row>
                    <Spacer size={16} />
                  </Container>
                ))}
              </ResponsiveGrid>
            </Container>
          </ScrollView>
          {mq.sizeQuery.mdAndUp ? (
            <Container
              borderColor={tokens.colors.neutral.darker}
              borderTopWidth={1}
              shadow
              paddingHorizontal={16}
              paddingVertical={8}
            >
              <Row justifyContent="flex-end" alignItems="center">
                <Container width={206}>
                  <Button onPress={() => setOpen(false)} text="Close" />
                </Container>
              </Row>
            </Container>
          ) : (
            <Container
              borderColor={tokens.colors.neutral.darker}
              borderTopWidth={1}
              shadow
              paddingHorizontal={16}
              paddingVertical={8}
            >
              <Row alignItems="center">
                <Container flex={1}>
                  <Button onPress={() => setOpen(false)} text="Close" />
                </Container>
              </Row>
            </Container>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export const whosHereGQLQuery = gql`
  query OffsiteDetailsWhosHere {
    currentUser {
      id
    }
  }
`;
