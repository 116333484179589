import { prepend, take, uniqueBy } from "lib/array_utils";
import { useCurrentUserGeoPlaceQuery } from "pages/homev2/hooks/use_current_user_geo_place";
import { useCallback, useState } from "react";
import { MapboxFeature } from "../mapbox";
import { calculateBboxFromCenter } from "../../../lib/map_utils";

export const SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY = "search-place-history";
export const SEARCH_HISTORY_NUM_OF_ITEMS = 5;

export function useSearchHistory() {
  const [searchHistory, setSearchHistory] = useState<MapboxFeature[]>(
    readSearchHistory(),
  );
  const currentUserGeoPlace = useCurrentUserGeoPlaceQuery();
  const addSearchHistory = useAddSearchHistory();

  const handleAddToSearchHistory = useCallback(
    (place: MapboxFeature) => {
      const nextSearchHistory = addSearchHistory(place);
      setSearchHistory(nextSearchHistory);
    },
    [addSearchHistory],
  );

  return {
    /*Normally we only save the normal location of the user chose in the history search,
     but in some cases, we need to check whether the last action of the user is choosing "current location" or not
     so we still add the "current location" to the history
     but will be removed at here to guaranty the flow correct.*/
    searchHistory: searchHistory.filter(
      (place) => place.id !== currentUserGeoPlace.data?.id,
    ),
    addToSearchHistory: handleAddToSearchHistory,
  };
}

function readSearchHistory(): MapboxFeature[] {
  const searchPlaceHistoryJson = localStorage.getItem(
    SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY,
  );

  if (!searchPlaceHistoryJson) {
    return [];
  }

  // TODO: add some validation
  const data: MapboxFeature[] = JSON.parse(searchPlaceHistoryJson);
  if (data && data.length > 0) {
    data.forEach((feature) => {
      if (!feature.bbox) {
        feature.bbox = calculateBboxFromCenter(feature.center);
      }
    });
  }
  return data;
}

function useAddSearchHistory() {
  const searchHistory = readSearchHistory();

  return useCallback(
    (place: MapboxFeature) => {
      const nextSearchHistory = take(
        uniqueBy(prepend(searchHistory, place), (p) => p.id),
        SEARCH_HISTORY_NUM_OF_ITEMS,
      );

      const json = JSON.stringify(nextSearchHistory);

      localStorage.setItem(SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY, json);

      return nextSearchHistory;
    },
    [searchHistory],
  );
}
