import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Icon } from "../icon";
import { Icon as IconV2 } from "../iconv2";
import { TextField } from "../text_field";

type SearchProps = {
  placeholder?: string;
  onSearch: (searchValue: string) => void;
};
export const Search = ({ placeholder, onSearch }: SearchProps) => {
  const [searchValue, setSearchValue] = useState("");
  const onChange = (value: string) => {
    setSearchValue(value);
    onSearch(value);
  };
  return (
    <View style={styles.searchInputWrapper}>
      <TextField
        value={searchValue}
        onChange={onChange}
        leftIcon={<Icon name="magnifying-glass" />}
        rightIcon={searchValue && <IconV2 name="cross-sign" />}
        onRightIconClick={() => onChange("")}
        inputPlaceholder={placeholder || "Search"}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  searchInputWrapper: {
    flexGrow: 1,
  },
});
