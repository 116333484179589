import { DialogContent } from "core/dialog_content";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { colors } from "./colors";
import { Dialog } from "./dialog";
import { Heading } from "./heading_v2";
import { IconName } from "./icon";
import { Spacer } from "./spacer";
import { Text } from "./text_v2";

interface DialogModalProps {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  headerTitle?: string;
  bottomButtons?: React.ReactNode;
  isVisible?: boolean;
  onClose?: () => void;
  desktopWidth?: number;
  mobileOffsetBottom?: number;
  noScroll?: boolean;
  showCloseButton?: boolean;
  testID?: string;
  headerLeftIcon?: IconName;
  onHeaderLeftIconPress?: () => void;
  center?: boolean;
  onShowAnimationEnd?: () => void;
  onDissmissAnimationEnd?: () => void;
}

export function DialogModal(props: DialogModalProps) {
  const {
    children,
    title,
    subtitle,
    headerTitle,
    bottomButtons,
    isVisible = false,
    mobileOffsetBottom,
    onClose,
    desktopWidth = 600,
    noScroll = false,
    showCloseButton = true,
    testID,
    headerLeftIcon,
    onHeaderLeftIconPress,
    center = false,
    onDissmissAnimationEnd,
    onShowAnimationEnd,
  } = props;
  const mq = useMediaQuery();

  return (
    <Dialog
      animationType="slide"
      visible={isVisible}
      center={center}
      onRequestClose={onClose}
      mobileOffsetBottom={mobileOffsetBottom}
      testID={testID}
      onShowAnimationEnd={onShowAnimationEnd}
      onDissmissAnimationEnd={onDissmissAnimationEnd}
    >
      <DialogContent
        headerRightIcon={showCloseButton ? "x-circle" : undefined}
        onHeaderRightIconPress={onClose}
        desktopWidth={desktopWidth}
        headerTitle={headerTitle}
        headerLeftIcon={headerLeftIcon}
        onHeaderLeftIconPress={onHeaderLeftIconPress}
      >
        <View
          style={[
            styles.wrapperBase,
            mq.deviceQuery.mobile
              ? styles.wrapperMobile
              : styles.wrapperDesktop,
          ]}
        >
          {title && (
            <View>
              <Heading
                size={mq.deviceQuery.mobile ? "sm" : "md"}
                align="center"
                testID="dialog-modal-title"
              >
                {title}
              </Heading>
              <Spacer size={16} />
            </View>
          )}
          {subtitle && (
            <View>
              <Text size="xs" align="center" color="black-core">
                {subtitle}
              </Text>
              <Spacer size={16} />
            </View>
          )}
        </View>
        {noScroll ? (
          children
        ) : (
          <>
            <ScrollView
              style={[
                mq.deviceQuery.mobile
                  ? styles.wrapperMobile
                  : styles.wrapperDesktop,
              ]}
            >
              {children}
            </ScrollView>
            <Spacer size={16} />
          </>
        )}
        {bottomButtons && (
          <View
            style={[
              mq.deviceQuery.mobile
                ? styles.bottomContentMobile
                : styles.bottomContentDesktop,
            ]}
          >
            {bottomButtons}
          </View>
        )}
        <Spacer size={40} />
      </DialogContent>
    </Dialog>
  );
}

const styles = StyleSheet.create({
  bottomContentDesktop: {
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 16,
    gap: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    paddingTop: 24,
  },
  bottomContentMobile: {
    flexDirection: "column",
    paddingHorizontal: 16,
    gap: 8,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    paddingTop: 16,
  },
  wrapperBase: {
    paddingTop: 24,
  },
  wrapperDesktop: {
    paddingHorizontal: 24,
  },
  wrapperMobile: {
    paddingHorizontal: 16,
  },
});
