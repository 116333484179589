import React from "react";
import { Animated, Pressable, StyleSheet } from "react-native";
import { colors } from "./colors";
import { tokens } from "./tokens";

interface SwitchProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  testID?: string;
}

export function Switch(props: SwitchProps): JSX.Element {
  const { value, onChange, testID } = props;
  const checked = React.useRef(new Animated.Value(0)).current;

  const handlePress = React.useCallback(() => {
    if (onChange) {
      onChange(!value);
    }
  }, [value, onChange]);

  React.useEffect(() => {
    Animated.spring(checked, {
      toValue: value ? 1 : 0,
      useNativeDriver: false,
      bounciness: 0,
      speed: 40,
    }).start();
  }, [checked, value]);

  return (
    <Pressable onPress={handlePress} style={styles.root} testID={testID}>
      <Animated.View
        style={[
          styles.wrapper,
          {
            backgroundColor: checked.interpolate({
              inputRange: [0, 1],
              outputRange: [
                colors.brand.blackMinus70,
                tokens.colors.primary.regular,
              ],
            }),
          },
        ]}
      >
        <Animated.View
          style={[
            styles.slider,
            tokens.shadow.elevation1,
            {
              transform: [
                {
                  translateX: checked.interpolate({
                    inputRange: [0, 1],
                    outputRange: [2, 15],
                  }),
                },
              ],
            },
          ]}
        />
      </Animated.View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 999,
    width: 34,
    height: 20,
    borderWidth: 1,
    borderColor: tokens.colors.neutral.darker,
  },
  wrapper: {
    borderRadius: 999,
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  slider: {
    borderRadius: 999,
    top: 2,
    width: 14,
    height: 14,
    backgroundColor: "white",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
  },
});
