import { useReactiveVar } from "@apollo/client";
import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { LinkWrapper } from "components/link_wrapper";
import { PreferredBadge } from "components/preferred_badge";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  SavedLocations__LocationDetailsFragment,
  SavedSpaces__SpaceDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { favoriteLocations, favoriteSpaces } from "hooks/use_reactive_favs";
import { useMediaQuery } from "lib/media_query";
import { useFavoritesMutation } from "pages/offsite_space_details_v2/hooks/use_favorites_mutation";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  View,
} from "react-native";

interface SavedSpacesCardProps {
  space: SavedSpaces__SpaceDetailsFragment;
  onRemove?: (e: GestureResponderEvent) => void;
}

interface CardProps {
  testID?: string;
  onRemove?: (e: GestureResponderEvent) => void;
  url: string;
  name: string;
  img: string;
  address: string;
  preferred: boolean;
  numberOfVisitsByCurrentUser: number;
}

interface LocationCardProps {
  location: SavedLocations__LocationDetailsFragment;
}

export function SavedSpacesCard(props: SavedSpacesCardProps) {
  const { space } = props;
  const [, , { removeFavorite }] = useFavoritesMutation();
  const favorites = useReactiveVar(favoriteSpaces);

  const onRemove = async (e: GestureResponderEvent) => {
    e.preventDefault();
    favoriteSpaces(favorites.filter((id) => id !== space.id));
    await removeFavorite(space.id);
  };

  return (
    <SavedCard
      testID={"saved-space-card"}
      onRemove={onRemove}
      url={`/offsite_spaces/${space.id}`}
      img={space.images?.[0]?.small?.url}
      name={`${space.locationName} · ${
        space.spaceType === SpaceType.DayPass ? "Day pass" : space.name
      }`}
      address={`${space.streetAddress}, ${space.city}`}
      preferred={!!space.preferred}
      numberOfVisitsByCurrentUser={space.numberOfVisitsByCurrentUser}
    />
  );
}

export function SavedLocationsCard(props: LocationCardProps) {
  const { location } = props;
  const favorites = useReactiveVar(favoriteLocations);

  const [, , { removeFavorite }] = useFavoritesMutation();

  const onRemove = async (e: GestureResponderEvent) => {
    e.preventDefault();
    favoriteLocations(favorites.filter((id) => id !== location.id));
    await removeFavorite(location.id);
  };
  return (
    <SavedCard
      testID="saved-location-card"
      onRemove={onRemove}
      url={`/location/${location.id}`}
      img={location.images?.[0]?.small?.url}
      name={location.name}
      address={`${location.address.streetAddress}, ${location.address.city}`}
      preferred={!!location.preferred}
      numberOfVisitsByCurrentUser={location.numberOfVisitsByCurrentUser}
    />
  );
}

function SavedCard(props: CardProps) {
  const {
    testID,
    onRemove,
    url,
    img,
    name,
    address,
    preferred,
    numberOfVisitsByCurrentUser,
  } = props;
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <LinkWrapper
      data-testid={testID}
      to={url}
      style={{ flex: 1, display: "flex" }}
    >
      <View style={isMobile ? styles.containerMobile : styles.container}>
        <View style={styles.relativeContainer}>
          <img
            alt="Saved space card"
            style={{
              objectFit: "cover",
              height: isMobile ? 160 : "100%",
              width: isMobile ? "100%" : 122,
              borderTopLeftRadius: 4,
              borderTopRightRadius: isMobile ? 4 : 0,
              borderBottomLeftRadius: isMobile ? 0 : 4,
              borderBottomRightRadius: 0,
            }}
            src={img}
          />
          <View style={styles.savedIconWrapper}>
            <Pressable onPress={onRemove}>
              <Icon name="favorites-saved" />
            </Pressable>
          </View>
        </View>

        <View style={isMobile ? styles.contentMobile : styles.content}>
          <Text size="md" weight={"semibold"}>
            {name}
          </Text>
          <Text size="xs">{address}</Text>
          {isPreferredFlag && preferred && (
            <PreferredBadge variant={"preferredOnly"} />
          )}
          <Spacer size={8} />
          {numberOfVisitsByCurrentUser ? (
            <Text size="xs" color="black-70">
              Visited {numberOfVisitsByCurrentUser}{" "}
              {numberOfVisitsByCurrentUser > 1 ? "times" : "time"}
            </Text>
          ) : (
            <Text size="xs" color="black-70">
              Never visited
            </Text>
          )}
        </View>
      </View>
    </LinkWrapper>
  );
}

const styles = StyleSheet.create({
  contentMobile: {
    padding: 16,
    flex: 1,
    gap: 8,
    justifyContent: "space-around",
  },
  content: {
    padding: 24,
    flex: 1,
    gap: 8,
    height: "100%",
    justifyContent: "space-around",
  },
  contentTopView: {
    flex: 1,
    gap: 8,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 4,
    height: "100%",
    backgroundColor: colors.brand.whitecore,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  containerMobile: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  relativeContainer: {
    position: "relative",
  },
  savedIconWrapper: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  savedIconWrapperMobile: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  infoWrapper: {
    flex: 1,
    justifyContent: "space-between",
    paddingHorizontal: 9,
  },
});
