import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";

import {
  BudgetPolicyAddressesQuery,
  BudgetPolicyLocationItemDistanceType,
  CreatePolicyMutation,
  CreatePolicyMutationVariables,
} from "core/graphql.generated";
import { useProximityPolicyFeatureFlag } from "providers/splitio";
import { useToast } from "providers/toast";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  budgetPolicyAddressesQuery,
  FormSubmitValues,
  FormValueType,
  PolicyLocationItem,
  PolicyMutationForm,
} from "./policy_mutation_form";

export function PolicyCreate() {
  const history = useHistory();
  const toast = useToast();
  const isPPFlag = useProximityPolicyFeatureFlag();
  const apolloClient = useApolloClient();
  const [createPolicy, { loading }] = useMutation<
    CreatePolicyMutation,
    CreatePolicyMutationVariables
  >(createPolicyMutation);

  const {
    data: orgAddressData,
    loading: orgLoading,
    error: orgError,
  } = useQuery<BudgetPolicyAddressesQuery>(budgetPolicyAddressesQuery);

  const initialValues: FormValueType = useMemo(() => {
    let locationItems: PolicyLocationItem[] = [];
    if (orgAddressData?.budgetPolicyAddresses) {
      const { budgetPolicyAddresses } = orgAddressData;
      locationItems = budgetPolicyAddresses.map((orgAdd) => ({
        addressId: orgAdd.id,
        street: orgAdd.street,
        latitude: orgAdd.latitude,
        longitude: orgAdd.longitude,
        distance: 25,
        distanceUnit: BudgetPolicyLocationItemDistanceType.Mi,
        active: false,
      }));
    }

    const defaultValues = {
      name: "",
      spaceTypes: {
        meetingRoom: true,
        privateOffice: true,
        dayPass: true,
      },
      isAppliedToNewBudget: false,
      locationItems,
    };
    return defaultValues;
  }, [orgAddressData]);

  const handleSubmit = async (values: FormSubmitValues) => {
    try {
      await createPolicy({
        variables: {
          input: values,
        },
      });
      await apolloClient.resetStore();
      toast.notify({
        message: "Policy has been successfully created",
      });

      history.goBack();
    } catch (error) {
      toast.notify({
        message: "There has been an error when creating the policy",
      });
    }
  };

  if (isPPFlag) {
    return (
      <AsyncRenderer
        loading={orgLoading}
        error={orgError}
        data={orgAddressData}
      >
        {() => (
          <PolicyMutationForm
            title="Create new policy"
            secondaryHeadingText="Set controls for where and how people can book spaces. To book out-of-policy spaces, an administrator needs to approve the booking."
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={loading}
          />
        )}
      </AsyncRenderer>
    );
  }

  return (
    <PolicyMutationForm
      title="Create new policy"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export const createPolicyMutation = gql`
  mutation CreatePolicy($input: CreateBudgetPolicyInput!) {
    createBudgetPolicy(input: $input) {
      id
      name
    }
  }
`;
