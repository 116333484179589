import { useMediaQuery } from "lib/media_query";
import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";

export function MapSkeleton() {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <>
      <ContentLoader
        speed={2}
        width="100%"
        height={isMobile ? 240 : 600}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height={isMobile ? 240 : 600} />
      </ContentLoader>
    </>
  );
}
