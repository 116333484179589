import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { first } from "lib/array_utils";
import React, { useRef } from "react";
import { useAuthV2 } from "./authv2";
import { appConfig } from "./config";

// Log any GraphQL errors or network error that occurred
const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    Sentry.withScope((scope) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
          const error = new Error(`[GraphQL error]: Message: ${err.message}`);
          Sentry.captureException(error, scope);
        });
      }

      if (networkError) {
        const error = new Error(`[GraphQL Network error]: ${networkError}`);
        Sentry.captureException(error, scope);
      }
    });

    return forward(operation);
  },
);

function createAuthLink(getAccessToken: () => Promise<string>) {
  return setContext(async () => {
    const token = await getAccessToken();

    return {
      headers: {
        ...(token && { authorization: `Bearer ${token}` }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  });
}
const httpLink = new HttpLink({ uri: `${appConfig.apiOrigin}/graphql` });

const cache = new InMemoryCache({
  typePolicies: {
    CurrentUser: {
      keyFields: [],
      merge: true,
      fields: {
        organization: {
          read(_, { toReference }) {
            return toReference({
              __typename: "Organization",
            });
          },
          merge: true,
        },
        favoriteLocations: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
        favoriteSpaces: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Organization: {
      keyFields: [],
      merge: true,
    },
    Query: {
      fields: {
        currentUser: {
          read(_, { toReference }) {
            return toReference({
              __typename: "CurrentUser",
            });
          },
        },
      },
    },
  },
});

interface GraphQLV2ProviderProps {
  children: React.ReactNode;
}

export function GraphQLV2Provider(props: GraphQLV2ProviderProps) {
  const { children } = props;
  const { getAccessToken } = useAuthV2();

  const client = useRef(
    new ApolloClient({
      connectToDevTools: appConfig.environment === "development",
      link: ApolloLink.from([
        errorLink,
        createAuthLink(getAccessToken),
        httpLink,
      ]),
      cache,
    }),
  );

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
}

export function extractFirstGraphQLErrorMessage(
  quoteError: ApolloError | undefined,
) {
  const errors = extractGraphQLErrors(quoteError);

  const error = first(errors);
  if (!error) {
    return;
  }

  return error.message;
}

export function extractGraphQLErrors(
  quoteError: ApolloError | undefined,
): Error[] {
  // @ts-ignore
  return quoteError?.networkError?.result.errors || [];
}
