import { gql, useMutation, useQuery } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";

import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Content } from "components/content";
import { Dialog } from "components/dialog";
import { Divider } from "components/divider";
import { Heading } from "components/heading";
import { Icon } from "components/icon";
import { PageContainer } from "components/page_container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text";
import { TextInput } from "components/text_input";
import { DialogContent, DialogFooter } from "core/dialog_content";
import { Footer } from "core/footer";
import {
  ProfileCurrentUserQuery,
  ProfileUpdateUserMutation,
  ProfileUpdateUserMutationVariables,
} from "core/graphql.generated";
import { ResponsiveGrid } from "core/responsive_grid";
import { useForm } from "hooks/use_form";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import { isNumberString } from "lib/number_utils";
import { isEmail } from "lib/string_utils";
import React, { useCallback, useState } from "react";
import { Pressable, ScrollView } from "react-native";
import { ROUTES } from "../core/routes";

export function UserProfileDetailsPage() {
  const goBack = useGoBack(ROUTES.USER_PROFILE.path);
  const { data, loading } = useQuery<ProfileCurrentUserQuery>(
    currentUserInfoGQLQuery,
  );
  const [edit, setEdit] = useState(false);
  const [updateCurrentUser] = useMutation<
    ProfileUpdateUserMutation,
    ProfileUpdateUserMutationVariables
  >(updateUserGQLMutation);

  const currentUser = data?.currentUser;

  const handleSave = useCallback(
    async (values: UserDetailsValue) => {
      if (!currentUser) {
        return;
      }

      // don't update email if user sign up
      if (currentUser.hasSignedUpWithSocialProvider) {
        await updateCurrentUser({
          variables: {
            input: {
              id: currentUser?.id,
              fullName: values.fullName,
              phone: values.phoneNumber,
              title: values.title,
            },
          },
        });
      } else {
        await updateCurrentUser({
          variables: {
            input: {
              id: currentUser?.id,
              email: values.email,
              fullName: values.fullName,
              phone: values.phoneNumber,
              title: values.title,
            },
          },
        });
      }

      setEdit(false);
    },
    [updateCurrentUser, currentUser],
  );

  if (!currentUser || loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Spacer size={32} />
        <Content>
          <Row alignItems="center">
            <Pressable onPress={goBack}>
              <Icon size="lg" name="arrow-left-circle" />
            </Pressable>
            <Spacer size={10} />
            <Heading size="h1">My details</Heading>
          </Row>
          <Spacer size={16} />
          <Row>
            <Container
              testID="user-profile-details"
              paddingHorizontal={8}
              flex={1}
            >
              <Text>{currentUser?.fullName}</Text>
              <Text>{currentUser?.title}</Text>
              <Text>{currentUser?.email}</Text>
              <Text>{currentUser?.phoneNumber}</Text>
            </Container>
          </Row>
          <Spacer size={16} />
          <Row>
            <Container paddingHorizontal={8} flex={1}>
              <Pressable
                testID="update-user-profile-details"
                onPress={() => setEdit(true)}
              >
                <Text size="sm" decoration="underline">
                  Change
                </Text>
              </Pressable>
            </Container>
          </Row>
          <Spacer size={16} />
          <Divider color="darker" />
          <Spacer size={16} />
          <Row>
            <Container paddingHorizontal={8} flex={1}>
              <Text color="muted">
                We use this information to serve you better and communicate with
                you about your reservations.
              </Text>
            </Container>
          </Row>
          <Spacer size={170} />
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <Footer />
      <Dialog onRequestClose={() => setEdit(false)} visible={edit}>
        {edit && (
          <UserDetailsEditDialogContent
            currentUser={currentUser}
            onClose={() => setEdit(false)}
            onSave={handleSave}
          />
        )}
      </Dialog>
    </Container>
  );
}

interface UserDetailsValue {
  fullName: string;
  title: string;
  email: string;
  phoneNumber: string;
}

interface UserDetailsEditProps {
  currentUser: NonNullable<ProfileCurrentUserQuery["currentUser"]>;
  onClose: () => void;
  onSave: (value: UserDetailsValue) => void;
}

function UserDetailsEditDialogContent(props: UserDetailsEditProps) {
  const { currentUser, onSave, onClose } = props;
  const mq = useMediaQuery();
  const { submit, submitting, setFieldValue, values, errors, handleChange } =
    useForm<UserDetailsValue, keyof UserDetailsValue>({
      validate: (v) => {
        const e: { [field: string]: string } = {};

        if (!v.fullName) {
          e.fullName = "Please add your full name";
        }

        if (!v.email) {
          e.email =
            "We use this to send you emails like your reservation confirmation.";
        } else if (!isEmail(v.email)) {
          e.email = "Please enter valid email";
        }

        return e;
      },
      initialValues: {
        fullName: currentUser.fullName,
        title: currentUser.title || "",
        email: currentUser.email || "",
        phoneNumber: currentUser.phoneNumber || "",
      },
      onSubmit: async (v) => {
        await onSave(v);
      },
    });

  const { fullName, title, email, phoneNumber } = values;

  return (
    <DialogContent
      headerLeftIcon={mq.sizeQuery.smAndDown ? "x-circle" : undefined}
      headerTitle={mq.sizeQuery.mdAndUp ? "My details" : undefined}
      onHeaderLeftIconPress={() => onClose()}
    >
      <Container
        flex={1}
        paddingTop={28}
        paddingHorizontal={24}
        paddingBottom={8}
      >
        <Container flex={1}>
          <ScrollView>
            <ResponsiveGrid itemsPerRow={1} verticalSpacing={8}>
              <TextInput
                testID="user-profile-full-name-input"
                invalid={!!errors.fullName}
                invalidText={errors.fullName}
                textContentType="givenName"
                value={fullName}
                onChange={handleChange("fullName")}
                label="Name"
              />
              <TextInput
                testID="user-profile-job-title-input"
                textContentType="jobTitle"
                value={title}
                onChange={handleChange("title")}
                label="What you do"
              />
              <TextInput
                testID="user-profile-email-input"
                invalid={!!errors.email}
                invalidText={errors.email}
                textContentType="emailAddress"
                value={email}
                disabled={currentUser.hasSignedUpWithSocialProvider}
                description="We use this for your login and send you emails like your reservation confirmation."
                keyboardType="email-address"
                onChange={handleChange("email")}
                label="Email"
              />
              <TextInput
                testID="user-profile-phone-number-input"
                invalid={!!errors.phoneNumber}
                invalidText={errors.phoneNumber}
                value={phoneNumber}
                textContentType="telephoneNumber"
                keyboardType="number-pad"
                onChange={(value) => {
                  if (!isNumberString(value)) {
                    return;
                  }
                  setFieldValue("phoneNumber", value);
                }}
                label="Phone Number"
              />
            </ResponsiveGrid>
          </ScrollView>
          {mq.sizeQuery.smAndDown && (
            <Button
              loading={submitting}
              onPress={submit}
              text="Save"
              testID="save-user-profile"
            />
          )}
        </Container>
      </Container>
      {mq.sizeQuery.mdAndUp && (
        <DialogFooter
          loading={submitting}
          onCancel={onClose}
          onSave={submit}
          noBorderTop
          noBoxShadow
          paddingBottom={22}
          testID="save-user-profile"
        />
      )}
    </DialogContent>
  );
}

const updateUserGQLMutation = gql`
  mutation ProfileUpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      title
      hasBookedBefore
      fullName
      email
      phoneNumber
    }
  }
`;

const currentUserInfoGQLQuery = gql`
  query ProfileCurrentUser {
    currentUser {
      id
      title
      hasBookedBefore
      hasSignedUpWithSocialProvider
      fullName
      email
      phoneNumber
      organization {
        id
        name
      }
    }
  }
`;
