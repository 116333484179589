import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";
import { useMediaQuery } from "lib/media_query";
import { EmptyStateGroupBTile } from "../empty_states/empty_state_tiles";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";
import { useReverseGeocodingQuery } from "../hooks/use_reserve_geocoding";
import { EmptyStatesOtherSpacesQuery, SpaceType } from "core/graphql.generated";
import { SpaceTypeCard } from "../components/space_type_card";
import { useMemo } from "react";

interface EmptyStateOtherTypesHaveSpacesProps {
  spaceList: EmptyStatesOtherSpacesQuery | undefined;
  onFindMeASpacePress?: () => void;
}

export function EmptyStateOtherTypesHaveSpaces(
  props: EmptyStateOtherTypesHaveSpacesProps,
) {
  const { spaceList, onFindMeASpacePress } = props;
  const mq = useMediaQuery();
  const { coordinates, spaceType } = useHomeSearchParamsQuery();
  const { data } = useReverseGeocodingQuery(coordinates);

  const dayPassCount = useMemo(
    () => spaceList?.[SpaceType.DayPass]?.data.length || 0,
    [spaceList],
  );
  const dayOfficeCount = useMemo(
    () =>
      spaceList?.[SpaceType.DayOffice]?.data.reduce(
        (sum, office) => sum + office.physicalSpacesCount,
        0,
      ) || 0,
    [spaceList],
  );
  const meetingRoomCount = useMemo(
    () => spaceList?.[SpaceType.MeetingRoom]?.data.length || 0,
    [spaceList],
  );
  const spaceTypeCards = useMemo(
    () => [
      {
        uri: "/images/daypasses.png",
        count: dayPassCount,
        type: SpaceType.DayPass,
        title: "Day Passes",
        lowerTitle: "Day passes",
        content: "Access to shared workspaces and amenities for the day.",
      },
      {
        uri: "/images/meetingrooms.png",
        count: meetingRoomCount,
        type: SpaceType.MeetingRoom,
        title: "Meeting Rooms",
        lowerTitle: "Meeting rooms",
        content: "Rooms available by the hour for collaborating with others.",
      },
      {
        uri: "/images/dayoffices.png",
        count: dayOfficeCount,
        type: SpaceType.DayOffice,
        title: "Day Offices",
        lowerTitle: "Day offices",
        content: "Full-day private offices for just you or your whole team.",
      },
    ],
    [dayOfficeCount, dayPassCount, meetingRoomCount],
  );

  const selectedSpaceTypeCard = spaceTypeCards.find(
    (card) => card.type === spaceType,
  );

  return (
    <View testID="empty-spaces-other-space-types-have-spaces">
      <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
        {selectedSpaceTypeCard?.lowerTitle} are coming to{" "}
        {data?.place_name || ""}!
      </Text>
      <Spacer size={16} />
      <Text size="xs">
        We’re working on adding {selectedSpaceTypeCard?.title.toLowerCase()} in{" "}
        {data?.place_name || ""}. For now, look below to explore other types of
        spaces available in your city.
      </Text>
      <Spacer size={24} />
      <View style={styles.spaceTypeCardsContainer}>
        {spaceTypeCards
          .filter(
            (spaceTypeCard) =>
              spaceTypeCard.type !== spaceType && spaceTypeCard.count > 0,
          )
          .map((card) => (
            <SpaceTypeCard card={card} />
          ))}
      </View>
      <Spacer size={24} />
      <EmptyStateGroupBTile onFindMeASpacePress={onFindMeASpacePress} />
    </View>
  );
}

const styles = StyleSheet.create({
  spaceTypeCardsContainer: {
    display: "flex",
    gap: 24,
  },
});
