import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";

import { useMediaQuery } from "lib/media_query";
import { Button } from "components/button_v2";
import { EmptyStateGroupBTile } from "./empty_state_tiles";
import {
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
} from "core/graphql.generated";
import { spaceNamesPlural } from "../components/space_list_container";
import { Fragment, useCallback } from "react";
import { SpaceListCards } from "../components/space_list_cards";

interface EmptyStateWithDateAndCapacityOrAmenitiesProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (location: HomePage__LocationDetailsFragment) => void;
  onFindMeASpacePress?: () => void;
}

export function EmptyStateWithDateAndCapacityOrAmenities(
  props: EmptyStateWithDateAndCapacityOrAmenitiesProps,
) {
  const { spaces, onPreviewLocation, onFindMeASpacePress } = props;
  const {
    spaceType,
    startTime,
    endTime,
    maxCapacity,
    minCapacity,
    amenities,
    date,
  } = useHomeSearchParamsQuery();
  const spaceCount = spaces?.length || 0;

  const spaceName = spaceNamesPlural[spaceType || "any"];
  const handleHomeSearchParamsChange = useUpdateHomeSearchParamsMutation();
  const mq = useMediaQuery();

  const handleClearCapacityPress = useCallback(() => {
    handleHomeSearchParamsChange({
      maxCapacity: null,
      minCapacity: null,
    });
  }, [handleHomeSearchParamsChange]);

  const handleClearDatePress = useCallback(() => {
    handleHomeSearchParamsChange({
      date: null,
      startTime: null,
      endTime: null,
    });
  }, [handleHomeSearchParamsChange]);

  const handleStartTimePress = useCallback(() => {
    handleHomeSearchParamsChange({
      startTime: null,
      endTime: null,
    });
  }, [handleHomeSearchParamsChange]);

  const handleEndTimePress = useCallback(() => {
    handleHomeSearchParamsChange({
      endTime: null,
    });
  }, [handleHomeSearchParamsChange]);

  const handleClearAmentiesPress = useCallback(() => {
    handleHomeSearchParamsChange({
      amenities: null,
    });
  }, [handleHomeSearchParamsChange]);

  if (spaceCount > 0) {
    return (
      <Fragment>
        <SpaceListCards onPreviewLocation={onPreviewLocation} spaces={spaces} />
        <Spacer size={24} />
        <View testID="empty-state-with-date-and-capacity-less-than-four-spaces">
          <EmptyStateGroupBTile
            title="Can’t find what you’re looking for?"
            subTitle="Our team will personally find a space for you, even if they aren't on Flexspace yet."
            onFindMeASpacePress={onFindMeASpacePress}
          />
        </View>
      </Fragment>
    );
  }
  return (
    <View testID="empty-state-with-date-and-capacity">
      <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
        No {spaceName} found
      </Text>
      <Spacer size={16} />
      <Text size="xs">
        Try changing or clearing some of your search criteria.
      </Text>
      <Spacer size={16} />
      <View style={styles.button}>
        {!!date && (
          <Button
            onPress={handleClearDatePress}
            appearance="secondary"
            text={"Clear date"}
          />
        )}

        {!!startTime && (
          <Button
            onPress={handleStartTimePress}
            appearance="secondary"
            text={"Clear start time"}
          />
        )}
        {!!endTime && (
          <Button
            onPress={handleEndTimePress}
            appearance="secondary"
            text={"Clear end time"}
          />
        )}
        {(!!maxCapacity || !!minCapacity) && (
          <Button
            onPress={handleClearCapacityPress}
            appearance="secondary"
            text={"Clear number of attendees"}
          />
        )}
        {!!amenities?.length && (
          <Button
            onPress={handleClearAmentiesPress}
            appearance="secondary"
            text={"Clear amenities"}
          />
        )}
      </View>

      <Spacer size={24} />
      <EmptyStateGroupBTile onFindMeASpacePress={onFindMeASpacePress} />
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 8,
  },
});
