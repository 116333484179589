import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { Icon } from "components/iconv2";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { Image, StyleSheet, View } from "react-native";
import { Link } from "react-router-dom";

interface LocationSpacePrice {
  normal: string;
  discount?: string;
}

export interface LocationSpacePricings {
  dayPricing?: LocationSpacePrice;
  hourlyPricing?: LocationSpacePrice;
}

export interface LocationDetailsAvailableSpace {
  spaceId: string;
  imageSrc: string;
  name: string;
  capacity: number;
  amenities: string[];
  pricings: LocationSpacePricings;
  outOfPolicy: boolean;
}

interface LocationDetailsAvailableSpacesModalProps {
  title: string;
  isVisible: boolean;
  spaces: LocationDetailsAvailableSpace[];
  onClose: () => void;
}

export function LocationDetailsAvailableSpacesModal({
  title,
  isVisible,
  spaces,
  onClose,
}: LocationDetailsAvailableSpacesModalProps) {
  const mq = useMediaQuery();

  return (
    <DialogModal isVisible={isVisible} headerTitle={title} onClose={onClose}>
      <View>
        {spaces.map(
          (
            {
              spaceId,
              imageSrc,
              name,
              capacity,
              amenities,
              pricings,
              outOfPolicy,
            },
            index,
          ) => (
            <View key={spaceId}>
              <Link
                to={`/offsite_spaces/${spaceId}`}
                style={{
                  textDecorationColor: "transparent",
                }}
              >
                <View style={styles.flexRow}>
                  <Image
                    source={{ uri: imageSrc }}
                    style={[
                      styles.image,
                      mq.deviceQuery.mobile
                        ? styles.imageMobile
                        : styles.imageDesktop,
                    ]}
                  />
                  <Spacer size={16} direction="row" />
                  <View style={styles.detailsWrapper}>
                    <View style={[styles.flexRow, styles.spaceHeaderWrapper]}>
                      <Text size={"xs"} weight="semibold">
                        {name}
                      </Text>
                      <Spacer size={8} direction="row" />
                      <Icon name="person" size="sm" />
                      <Text size={"xs"} weight="semibold">
                        {capacity}
                      </Text>
                      {outOfPolicy && (
                        <>
                          <Spacer size={8} direction="row" />
                          <OutOfPolicyTag />
                        </>
                      )}
                    </View>
                    <Spacer size={4} />
                    <Text size="xs">{amenities.join(" · ")}</Text>
                    <Spacer size={4} />
                    {pricings.dayPricing && (
                      <View key={pricings.dayPricing.normal}>
                        <Text size="xs" weight="semibold">
                          {pricings.dayPricing.normal}
                        </Text>
                      </View>
                    )}
                    {pricings.hourlyPricing && (
                      <View key={pricings.hourlyPricing.normal}>
                        <Text size="xs" weight="semibold">
                          {pricings.hourlyPricing.normal}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </Link>
              {index < spaces.length - 1 && (
                <View>
                  <Spacer size={24} />
                  <Divider />
                  <Spacer size={24} />
                </View>
              )}
            </View>
          ),
        )}
      </View>
    </DialogModal>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
  },
  spaceHeaderWrapper: {
    alignItems: "center",
    flexWrap: "wrap",
  },
  image: {
    borderRadius: 4,
  },
  imageDesktop: {
    width: 60,
    height: 60,
  },
  imageMobile: {
    width: 60,
    height: 60,
  },
  detailsWrapper: {
    flex: 1,
  },
});
