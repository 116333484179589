import { useCallback } from "react";
import { useHistory } from "react-router";

type urlType = string | undefined;
export function useGoBack(defaultUrl: urlType = "/") {
  const history = useHistory();
  const goBack = useCallback(() => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace(defaultUrl);
    }
  }, [history, defaultUrl]);

  return goBack;
}
