import React, { forwardRef, useState } from "react";
import {
  KeyboardTypeOptions,
  StyleSheet,
  TextInput as RNTextInput,
} from "react-native";
import { colors } from "./colors";

import { InputContainer } from "./input_container";
import { tokens } from "./tokens";
import { typography } from "./typography";

interface TextInputProps {
  label?: string;
  value: string;
  textContentType?:
    | "none"
    | "URL"
    | "addressCity"
    | "addressCityAndState"
    | "addressState"
    | "countryName"
    | "creditCardNumber"
    | "emailAddress"
    | "familyName"
    | "fullStreetAddress"
    | "givenName"
    | "jobTitle"
    | "location"
    | "middleName"
    | "name"
    | "namePrefix"
    | "nameSuffix"
    | "nickname"
    | "organizationName"
    | "postalCode"
    | "streetAddressLine1"
    | "streetAddressLine2"
    | "sublocality"
    | "telephoneNumber"
    | "username"
    | "password"
    | "newPassword"
    | "oneTimeCode";

  name?: string;
  placeholder?: string;
  testID?: string;
  numberOfLines?: number;
  invalid?: boolean;
  disabled?: boolean;
  invalidText?: string;
  description?: string;
  keyboardType?: KeyboardTypeOptions;
  onChange?: (value: string) => void;
  onSubmitEditing?: () => void;
  secureTextEntry?: boolean;
  autoFocus?: boolean;
}

export const TextInput = forwardRef<RNTextInput, TextInputProps>(
  function TextInput(props, ref) {
    const {
      value,
      label,
      name,
      onChange,
      invalid,
      testID,
      disabled,
      placeholder,
      numberOfLines,
      invalidText,
      description,
      textContentType,
      keyboardType,
      onSubmitEditing,
      secureTextEntry,
      autoFocus,
    } = props;
    const [focused, setFocused] = useState(false);

    return (
      <InputContainer
        focused={focused}
        hasValue={value !== ""}
        label={label}
        invalid={invalid}
        description={description}
        invalidText={invalidText}
        height={numberOfLines ? numberOfLines * 32 : undefined}
      >
        <RNTextInput
          // @ts-ignore
          name={name}
          ref={ref}
          style={[
            styles.input,
            invalid && !focused && styles.invalid,
            numberOfLines !== undefined && styles.multiline,
          ]}
          placeholder={placeholder}
          value={value}
          testID={testID}
          disabled={disabled}
          onChangeText={onChange}
          textContentType={textContentType}
          numberOfLines={numberOfLines}
          multiline={!!numberOfLines}
          keyboardType={keyboardType}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onSubmitEditing={onSubmitEditing}
          secureTextEntry={secureTextEntry}
          autoFocus={autoFocus}
        />
      </InputContainer>
    );
  },
);

const styles = StyleSheet.create({
  invalid: {
    color: tokens.colors.secondary.dark,
  },
  input: {
    height: "100%",
    borderRadius: tokens.radius,
    paddingHorizontal: 12,
    fontFamily: typography.textTextFontFamily,
    color: colors.brand.blackcore,
    backgroundColor: "transparent",
    outlineStyle: "none",
    fontSize: 15,
    lineHeight: 18,
  },
  multiline: {
    paddingTop: 8,
  },
});
