import { useQuery } from "@apollo/client";
import { desktopHeaderQuery } from "components/app_header_v3/desktop_header";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { InAppInvite } from "components/in_app_invite";
import { LinkWrapper } from "components/link_wrapper";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  AppHeaderV3DesktopQuery,
  GroupBudgetRole,
  Plan,
  UserRole,
} from "core/graphql.generated";
import { ProfileDropDownMenu } from "core/profile_dropdown_menu";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { signInModalState } from "providers/app_loader";
import { useAuthV2 } from "providers/authv2";
import React, { useCallback, useState } from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { useRecoilState } from "recoil";
import { HomeSearchBar } from "../components/home_search_bar";
import { useUserType } from "../hooks/use_user_type";

function HomeDesktopHeader() {
  const [showAppInvite, setShowAppInvite] = useState(false);
  const [, setShowLoginModal] = useRecoilState(signInModalState);
  const analytics = useAnalytics();
  const { data: currentUserQuery } =
    useQuery<AppHeaderV3DesktopQuery>(desktopHeaderQuery);
  const currentUser = currentUserQuery?.currentUser;
  const { authenticated } = useAuthV2();
  const isAdmin = currentUser?.role === UserRole.Admin;
  const isBudgetManager =
    currentUser?.groupBudgetRole === GroupBudgetRole.Manager;
  const isPro = currentUser?.organization?.plan === Plan.Pro;
  const showLoginModal = () => {
    analytics.event("Click Header Login");
    setShowLoginModal(true);
  };
  return (
    <View style={styles.stickyHeaderWrapper}>
      <View style={styles.header}>
        <View style={styles.headerInnerWrapper}>
          <LinkWrapper to="/">
            <img
              alt="flexspace-logo"
              src={"images/logo-white.svg"}
              style={{ height: 36 }}
            />
          </LinkWrapper>
          <View style={styles.headerRightView}>
            {!authenticated && (
              <Button
                text={"Login or sign up"}
                onPress={showLoginModal}
                appearance={"secondary"}
              />
            )}
            <Spacer direction={"row"} size={16} />

            <View style={{ minWidth: authenticated ? 189 : 0 }}>
              <ProfileDropDownMenu
                currentUser={currentUser}
                isAdmin={isAdmin}
                isBudgetManager={isBudgetManager}
                onInviteClick={setShowAppInvite}
                isPro={isPro}
                transparent={true}
              />
            </View>
          </View>
        </View>
      </View>
      <InAppInvite
        isVisible={showAppInvite}
        onClose={() => setShowAppInvite(false)}
        analyticsFrom="Primary navigation"
        onSuccess={() => setShowAppInvite(false)}
      />
    </View>
  );
}
export function HeroSearch() {
  const analytics = useAnalytics();
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const userType = useUserType();

  const onSearch = useCallback(
    (field: string) => {
      analytics.event("Spaces Search", {
        Source: userType,
        category: "Rebooking",
        Field: field,
      });
    },
    [analytics, userType],
  );

  return (
    <ImageBackground
      source={{ uri: "/images/hero_search_bg.jpg" }}
      style={styles.imageStyle}
    >
      <View
        style={isMobile ? styles.gradientLayoutMobile : styles.gradientLayout}
      >
        {!isMobile && <HomeDesktopHeader />}
        <View style={styles.container}>
          <View style={isMobile ? styles.contentMobile : styles.content}>
            <View style={styles.textView}>
              <Text size={"mega"} weight={"bold"} color={"white-core"}>
                Find your perfect work space
              </Text>
            </View>
            <Spacer size={isMobile ? 24 : 32} />
            <HomeSearchBar onFilterChange={onSearch} />
          </View>
        </View>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    width: "100%",
  },
  content: {
    flex: 1,
    marginTop: 63,
    marginBottom: 176,
    width: 1088,
    paddingHorizontal: 24,
    paddingBottom: 8,
  },
  contentMobile: {
    flex: 1,
    marginTop: 63,
    marginBottom: 176,
    paddingHorizontal: 24,
    paddingBottom: 8,
  },
  textView: {
    width: 343,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  headerWithBorder: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.brand.whitecore,
    borderBottomWidth: 1,
    borderBottomColor: colors.brand.blackMinus90,
  },
  headerInnerWrapper: {
    // @ts-ignore
    boxSizing: "border-box",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    height: 72,
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    zIndex: 2,
  },
  headerRightView: {
    flexDirection: "row",
  },
  // @ts-ignore
  unTouchAbleView: { pointerEvents: "none" },
  stickyHeaderWrapper: {
    // @ts-ignore
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  imageStyle: {
    flex: 1,
  },
  gradientLayout: {
    width: "100%",
    height: 546,
    borderRadius: 4,
    backgroundColor:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%)",
  },
  gradientLayoutMobile: {
    width: "100%",
    height: 272,
    borderRadius: 4,
    backgroundColor:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%)",
  },
});
