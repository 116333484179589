import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { CreateEditBudgetCard } from "pages/budgets/components/create_edit_budget_card";
import { CreateEditBudgetFormValues } from "../organization_budget_create";

interface CreateEditBudgetFormErrors {
  limit?: string;
  individualLimit?: string;
}

interface CreateEditBudgetFormProps {
  onSubmit: () => Promise<void>;
  values: CreateEditBudgetFormValues;
  setFieldValue: (
    field: "limit" | "individualLimit",
    value: number | undefined,
  ) => void;
  errors: CreateEditBudgetFormErrors;
}

export function CreateEditBudgetForm(props: CreateEditBudgetFormProps) {
  const { values, errors, setFieldValue } = props;

  return (
    <CreateEditBudgetCard title="Spend limits">
      <TextField
        testID="org-budget-limit-input"
        value={values.limit || values.limit === 0 ? String(values.limit) : ""}
        label="What is the monthly spend limit for this organization?*"
        topDescription="Set an amount that can be spent by people in this organization. All members of your organization can spend from all available money in the spend limit."
        leftIcon={
          <Text color={errors.limit ? "grapefruit-core" : "black-core"}>$</Text>
        }
        onChange={(value) => setFieldValue("limit", parseInt(value, 10))}
        keyboardType="numeric"
        invalid={!!errors.limit}
        invalidText={errors.limit}
      />
      <Spacer size={24} />
      <TextField
        testID="org-budget-individual-limit-input"
        value={
          values.individualLimit || values.individualLimit === 0
            ? String(values.individualLimit)
            : ""
        }
        label="How much can each person in this organization spend each month?"
        topDescription="New members who join your organization will have this monthly spend limit. This only applies to people who aren’t assigned to another budget within your organization."
        leftIcon={
          <Text
            color={errors.individualLimit ? "grapefruit-core" : "black-core"}
          >
            $
          </Text>
        }
        onChange={(value) =>
          setFieldValue("individualLimit", parseInt(value, 10))
        }
        keyboardType="numeric"
        invalid={!!errors.individualLimit}
        invalidText={errors.individualLimit}
      />
    </CreateEditBudgetCard>
  );
}
