import { ReactNode, useCallback, useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { DropdownV2 } from "components/dropdown_v2";
import { Button } from "components/button_v2";
import {
  TimeRangePickerHours,
  TimeRangePickerHoursProps,
} from "./time_range_picker_hours";
import { tokens } from "components/tokens";
import { colors } from "components/colors";
import { TimeInterval } from "lib/time_utils";

export interface TimeRangePickerHoursChildrenType {
  isOpen: boolean;
  onClose: () => void;
  onToggleOpen: () => void;
  onOpen: () => void;
}

interface TimeRangePickerHoursDropdownProps
  extends Omit<TimeRangePickerHoursProps, "timeslots"> {
  children: (arg: TimeRangePickerHoursChildrenType) => ReactNode;
  onChange?: (value: Partial<TimeInterval> | null) => void;
}

export function TimeRangePickerHoursDropdown(
  props: TimeRangePickerHoursDropdownProps,
) {
  const { value, onChange, children, appearance = "v3" } = props;
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState(value || null);
  useEffect(() => {
    setNewValue(value!);
  }, [value]);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClear = useCallback(() => {
    onChange?.(null);
    setNewValue(value!);
    handleClose();
  }, [handleClose, onChange, value]);
  const handleSave = useCallback(() => {
    setOpen(false);
    onChange?.(newValue);
  }, [onChange, newValue]);

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      placement="bottom-end"
      sameWidth={false}
      offsetVertical={8}
      content={
        <View style={styles.dialogWrapper}>
          <View style={styles.dialogContent}>
            <TimeRangePickerHours
              value={newValue}
              onChange={setNewValue}
              appearance={appearance}
            />
          </View>
          <View style={styles.dialogFooter}>
            <Pressable onPress={handleClear}>
              <Text color="eggplant-core" size="xs" weight="semibold">
                Clear
              </Text>
            </Pressable>
            <Button
              text="Done"
              onPress={handleSave}
              testID="time-picker-done-button"
            />
          </View>
        </View>
      }
    >
      {children({
        isOpen: open,
        onClose: handleClose,
        onToggleOpen: open ? handleClose : handleOpen,
        onOpen: handleOpen,
      })}
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dialogFooter: {
    padding: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogWrapper: {
    ...tokens.shadow.elevation2,
    backgroundColor: tokens.colors.base.white,
    borderRadius: 4,
  },
  dialogContent: {
    flex: 1,
    padding: 16,
  },
});
