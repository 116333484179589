import "./picker.css";
import React from "react";
import { PickerProps } from "./picker";
import { Text } from "./text";
import { Icon } from "./icon";
import { tokens } from "./tokens";
import { InputContainer } from "./input_container";

export function Picker<T extends string | number>(props: PickerProps<T>) {
  const {
    label,
    placeholder,
    options,
    value,
    onChange,
    disabled,
    description,
    invalidText,
    testID,
    invalid,
    iconLeft,
  } = props;
  const selected = options.find((opt) => opt.value === value);
  const firstOption = options[0];

  return (
    <InputContainer
      hasLeftIcon={!!iconLeft}
      hasValue={!!selected}
      label={label}
      invalid={invalid}
      invalidText={invalidText}
      description={description}
    >
      {iconLeft && (
        <div className="picker-icon-wrapper">
          <Icon name={iconLeft} />
        </div>
      )}
      <div className={`picker-root ${iconLeft ? "picker-root-icon" : ""}`}>
        <Text
          numberOfLines={1}
          color={invalid ? "error" : disabled ? "muted" : "default"}
        >
          {selected?.label || (!label && placeholder)}
        </Text>
        <div
          className={`picker-arrow ${
            selected && !!label ? "picker-arrow-has-value" : ""
          } `}
        >
          <Icon name="chevron-down" color={tokens.colors.neutral.darker} />
        </div>
      </div>
      <select
        disabled={disabled}
        className={`picker-select ${disabled ? "picker-disabled" : ""}`}
        data-testid={testID}
        value={value || ""}
        onChange={(event) => {
          if (typeof firstOption.value === "number") {
            onChange(Number(event.currentTarget.value) as T);
          } else {
            onChange(event.currentTarget.value as T);
          }
        }}
      >
        {label && (
          <option disabled value="">
            {label}
          </option>
        )}
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </InputContainer>
  );
}
