import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { Text } from "components/text_v2";
import { StyleSheet, View } from "react-native";

type OutOfPolicyTagProps = {
  appearance?: "grey" | "yellow";
  onlyIcon?: boolean;
};
export const OutOfPolicyTag = ({
  appearance = "grey",
  onlyIcon = false,
}: OutOfPolicyTagProps) => {
  const isYellow = appearance === "yellow";

  return (
    <View
      style={[
        styles.cardTitle,
        isYellow && { backgroundColor: colors.brand.bananaMinus90 },
        !onlyIcon && { padding: 12 },
      ]}
      testID="out-of-policy-tag"
    >
      <Icon
        name="guard"
        color={isYellow ? "bananacore" : "blackMinus50"}
        filled={isYellow}
        size={"sm"}
      />
      {!onlyIcon && (
        <Text size="xs" color={isYellow ? "banana-core" : "black-50"}>
          Out-of-policy
        </Text>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  cardTitle: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    borderRadius: 100,
  },
});
