import { Text } from "components/text_v2";
import { priceTypeLabel } from "core/booking_utils";
import { PricingType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { formatPrice } from "pages/homev2/components/space_card_v2/space_card_template";
import { usePriceDiscoverabilityFeatureFlag } from "providers/splitio";
import { StyleSheet, View } from "react-native";

type DynamicPriceTextProps = {
  priceType: PricingType;
  basePrice: number;
  currency: string;
  unitPrice: number;
  showMultiDayDPModal?: boolean;
  setShowMultiDayDPModal?: (value: boolean) => void;
};

export function DynamicPriceText({
  priceType,
  basePrice,
  unitPrice,
  currency,
  showMultiDayDPModal = false,
  setShowMultiDayDPModal,
}: DynamicPriceTextProps) {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const discountedPrice = `${formatPrice(unitPrice, currency)}/${priceTypeLabel[
    priceType
  ].toLowerCase()}`;
  const normalPrice = `${formatPrice(basePrice, currency)}/${priceTypeLabel[
    priceType
  ].toLowerCase()}`;
  const isMultiDayDP = usePriceDiscoverabilityFeatureFlag();

  const textOnPress = () => {
    if (isMultiDayDP && showMultiDayDPModal && setShowMultiDayDPModal) {
      setShowMultiDayDPModal(true);
    }
  };

  if (unitPrice && unitPrice < basePrice) {
    return (
      <View style={styles.horizontalView}>
        <Text
          size={isMobile ? "xs" : "md"}
          weight="semibold"
          color="eggplant-core"
          onPress={showMultiDayDPModal ? textOnPress : undefined}
          decoration={
            isMultiDayDP && showMultiDayDPModal ? "underline" : "none"
          }
        >
          {discountedPrice}
        </Text>
        <Text
          size={isMobile ? "xs" : "micro"}
          color="black-30"
          decoration={"line-through"}
        >
          {normalPrice}
        </Text>
      </View>
    );
  }

  return (
    <View style={styles.priceView}>
      <Text size={isMobile ? "xs" : "md"} weight="semibold" color="black-core">
        {normalPrice}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  priceView: {
    width: "100%",
    marginBottom: 4,
  },
  horizontalView: {
    flexDirection: "row",
    gap: 8,
    alignItems: "flex-end",
    marginBottom: 4,
  },
});
