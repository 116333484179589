import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  OrgUserDetailsFragment,
  SpaceType,
  TeamsLocationDetailsFragment,
  TeamsSpaceDetailsFragment,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { UserInfoV2 } from "pages/team_spaces/components/user_info_v2";
import { LocationBox } from "pages/team_spaces/components/where_my_team/where_my_team_list/user_card_location_box";
import { useAnalytics } from "providers/analytics";
import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";

type UserCardProps = {
  user: OrgUserDetailsFragment;
  setLocationPreviewData: (data: TeamsLocationDetailsFragment) => void;
};
export const UserCard = ({ user, setLocationPreviewData }: UserCardProps) => {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const analytics = useAnalytics();
  const dayPassBookings = user.bookings.filter(
    (b) => b.space.spaceType === SpaceType.DayPass,
  );
  const officeBookings = user.bookings.filter(
    (b) => b.space.spaceType === SpaceType.DayOffice,
  );

  const meetingRoomBookings = user.bookings.filter(
    (b) => b.space.spaceType === SpaceType.MeetingRoom,
  );

  let space: TeamsSpaceDetailsFragment | undefined;
  let startTime, endTime;

  if (dayPassBookings.length > 0) {
    space = dayPassBookings[0].space;
  } else if (officeBookings.length > 0) {
    space = officeBookings[0].space;
  } else if (meetingRoomBookings.length > 0) {
    space = meetingRoomBookings[0].space;
    startTime = meetingRoomBookings[0].startTime;
    endTime = meetingRoomBookings[0].endTime;
  }

  const onPress = useCallback(() => {
    if (space) {
      setLocationPreviewData(space.location);
      analytics.event("Click Explore Spaces Here", {
        Category: "Where is everyone",
        Source: "List",
        "Space ID": space.id,
        "Space Name": space.name,
      });
    }
  }, [space, setLocationPreviewData, analytics]);

  if (isMobile) {
    return (
      <View style={styles.containerMobile}>
        <UserInfoV2 user={user} />
        <Divider />
        {space ? (
          <>
            <LocationBox
              space={space}
              startTime={startTime}
              endTime={endTime}
            />
            <Button
              text={"Explore spaces here"}
              appearance={"secondary"}
              onPress={onPress}
              testID="explore-spaces-button"
            />
          </>
        ) : (
          <View style={styles.remoteViewMobile}>
            <Text size={"xs"} weight={"semibold"}>
              Working remotely
            </Text>
            {user.city && <Text size={"xs"}>{user.city.displayName}</Text>}
          </View>
        )}
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <UserInfoV2 user={user} />
      {space ? (
        <LocationBox space={space} />
      ) : (
        <View style={styles.remoteView}>
          <Text size={"xs"} weight={"semibold"}>
            Working remotely
          </Text>
          {user.city && <Text size={"xs"}>{user.city.displayName}</Text>}
        </View>
      )}
      <View style={styles.btnContainer}>
        {space ? (
          <Button
            text={"Explore spaces here"}
            appearance={"secondary"}
            onPress={() => space && setLocationPreviewData(space.location)}
          />
        ) : (
          <Spacer size={170} direction={"row"} />
        )}
      </View>
      <View />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.brand.blackMinus90,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  containerMobile: {
    flexDirection: "column",
    boxShadow: "0px 1px 10px rgba(6, 0, 30, 0.1)",
    borderRadius: 4,
    marginBottom: 16,
    margin: 2,
    padding: 16,
    gap: 16,
  },
  remoteViewMobile: {
    flex: 1,
    gap: 4,
  },
  remoteView: {
    flex: 1,
    paddingLeft: 54,
    gap: 4,
  },
  btnContainer: {
    height: 40,
  },
});
