import { colors } from "components/colors";
import { Cell } from "components/data_table/components_v2/cell";
import { Row } from "components/data_table/components_v2/row";
import { Column, DataTableRow } from "components/data_table/data_table_base";

import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";

interface BudgetTransactionDataTablePropsType {
  columns: Column[];
  rows: DataTableRow[];
}

interface BudgetTransactionTableRowPropsType {
  row: DataTableRow;
  columns: Column[];
}

const BudgetTransactionTableRow: React.FC<
  BudgetTransactionTableRowPropsType
> = ({ row, columns }) => {
  const [extendRow, setExtendRow] = useState(false);
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <View
      style={
        extendRow ? styles.extendRowWrapper : isMobile && styles.rowWrapper
      }
    >
      <Row noHover noBorder={isMobile}>
        {columns.map((column) => {
          const rowItem = row[column.id];
          return (
            <Cell width={column.width} key={`${row.id}-${column.id}`}>
              <View style={styles.cellWrapper}>
                {rowItem.render ? (
                  rowItem.render({
                    onChangeExtend: () => setExtendRow((value) => !value),
                    isExtended: extendRow,
                  })
                ) : (
                  <Text customColor={colors.brand.blackcore} size="xs">
                    {rowItem}
                  </Text>
                )}
              </View>
            </Cell>
          );
        })}
      </Row>
      {extendRow && <Row noHover>{row.extension}</Row>}
    </View>
  );
};

export const BudgetTransactionTableRaw: React.FC<
  BudgetTransactionDataTablePropsType
> = (props) => {
  const { rows, columns } = props;

  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  const renderTableHeader = useCallback(
    () => (
      <Row isHeader>
        {columns.map((column) => (
          <Cell key={`th-${column.id}`} isHeader width={column.width}>
            {column.renderTitle ? (
              column.renderTitle(column)
            ) : (
              <Text
                customColor={colors.brand.blackMinus30}
                size="xs"
                weight="semibold"
              >
                {column.title}
              </Text>
            )}
          </Cell>
        ))}
      </Row>
    ),
    [columns],
  );

  const renderTableRows = useCallback(
    () =>
      rows.map((row) => (
        <BudgetTransactionTableRow key={row.id} row={row} columns={columns} />
      )),
    [rows, columns],
  );

  return (
    <View style={isMobile ? styles.tableWrapperMobile : styles.tableWrapper}>
      {!isMobile && renderTableHeader()}
      {renderTableRows()}
    </View>
  );
};

const styles = StyleSheet.create({
  tableWrapper: {
    backgroundColor: colors.brand.whitecore,
    paddingVertical: 24,
    borderRadius: 4,
  },
  tableWrapperMobile: {
    borderRadius: 4,
  },
  extendRowWrapper: {
    backgroundColor: colors.brand.eggplantMinus90,
    borderBottomWidth: 1,
    borderColor: colors.brand.eggplantMinus70,
  },
  rowWrapper: {
    boxShadow: "0px 1px 10px rgba(6, 0, 30, 0.1)",
    marginBottom: 16,
  },
  approvingRow: {
    backgroundColor: colors.brand.spinachMinus90,
  },
  decliningRow: {
    backgroundColor: colors.brand.grapefruitMinus90,
  },
  cellWrapper: {
    paddingRight: 20,
  },
});
