import { AsyncRenderer } from "components/AsyncRenderer";
import { SavedPlacesSkeleton } from "./saved_places_skeleton";
import { useSavedSpacesQuery } from "../hooks/use_saved_places_query";
import { GridV2 } from "components/gridv2";
import { SavedSpacesCard } from "./saved_places_cards";
import { EmptySavedPlaces } from "./saved_places_empty_state";

import { favoriteSpaces } from "hooks/use_reactive_favs";
import { useReactiveVar } from "@apollo/client";

export const SavedSpacesList = () => {
  const { data, loading, error } = useSavedSpacesQuery();
  const favorites = useReactiveVar(favoriteSpaces);

  return (
    <AsyncRenderer
      error={error}
      loading={loading}
      data={data}
      loadingHandler={() => <SavedPlacesSkeleton />}
    >
      {(data) => {
        const spaces = data?.currentUser?.favoriteSpaces?.filter((space) =>
          favorites.includes(space.id),
        );
        return (
          <GridV2 columns={1} rowGap={24}>
            {spaces && spaces.length > 0 ? (
              spaces.map((space) => (
                <SavedSpacesCard key={space.id} space={space} />
              ))
            ) : (
              <EmptySavedPlaces
                title="No spaces saved yet"
                description="Tap the heart to save it for later. Saved spaces will show up here so you can easily find them again."
              />
            )}
          </GridV2>
        );
      }}
    </AsyncRenderer>
  );
};
