import { format } from "date-fns";
import { parseDay } from "lib/day_utils";
import { parseTime } from "lib/time_utils";

export function convertDateTimeRange(
  startDate: string,
  endDate: string,
  startTime?: string | null,
  endTime?: string | null,
): string {
  const formatStartDate = format(parseDay(startDate), "MMM d");
  const formatEndDate = format(parseDay(endDate), "MMM d");
  const isOneDay = formatStartDate === formatEndDate;

  let timeRange: string | null = null;
  if (!!startTime && !!endTime) {
    const formatStartTime = format(parseTime(startTime), "h:mm a");
    const formatEndTime = format(parseTime(endTime), "h:mm a");
    timeRange = `${formatStartTime} - ${formatEndTime}`;
  }

  if (isOneDay) {
    if (timeRange) {
      return `on ${formatStartDate}, ${timeRange}`;
    }
    return `on ${formatStartDate}`;
  }

  if (timeRange) {
    return `${formatStartDate} - ${formatEndDate}, ${timeRange}`;
  }
  return `${formatStartDate} - ${formatEndDate}`;
}
