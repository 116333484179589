import { StyleSheet, View } from "react-native";

import { useMediaQuery } from "lib/media_query";
import { Bookings__OrderDetailsFragment } from "core/graphql.generated";

import { UpcomingBookingThumbnail } from "./upcoming_booking_thumbnail";
import { transformUpcomingBooking } from "../bookings_utils";
import { UpcomingBookingDetails } from "./upcoming_booking_details";
import { BookingCard } from "./booking_card";

export interface UpcomingBookingProps {
  details: Bookings__OrderDetailsFragment;
}

export function UpcomingBooking({ details }: UpcomingBookingProps) {
  const mq = useMediaQuery();

  const {
    orderId,
    thumbnailUrl,
    upcomingInDays,
    dateRange,
    spaceName,
    spaceType,
    confirmationId,
    capacity,
    invitedPeople,
    spaceUrl,
    location,
    remainingCapacity,
  } = transformUpcomingBooking(details);

  return (
    <BookingCard>
      <View style={[styles.root, mq.deviceQuery.mobile && styles.rootMobile]}>
        <View
          style={
            mq.deviceQuery.mobile
              ? styles.thumbnailWrapperMobile
              : styles.thumbnailWrapper
          }
        >
          <UpcomingBookingThumbnail
            url={thumbnailUrl}
            upcomingInDays={upcomingInDays}
          />
        </View>

        <View style={!mq.deviceQuery.mobile && styles.detailsWrapper}>
          <UpcomingBookingDetails
            orderId={orderId}
            dateRange={dateRange}
            spaceName={spaceName}
            spaceType={spaceType}
            confirmationId={confirmationId}
            remainingCapacity={remainingCapacity}
            capacity={capacity}
            invitedPeople={invitedPeople}
            location={location}
            spaceUrl={spaceUrl}
          />
        </View>
      </View>
    </BookingCard>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 292,
  },
  rootMobile: {
    flexDirection: "column",
    height: "fit-content",
  },
  thumbnailWrapper: {
    width: "35%",
    height: "100%",
  },
  thumbnailWrapperMobile: { width: "100%" },
  detailsWrapper: {
    width: "65%",
  },
});
