import { colors } from "components/colors";
import { Dialog } from "components/dialog";
import { Heading } from "components/heading_v2";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { DialogContent } from "core/dialog_content";
import { useEffect, useState } from "react";
import { StyleSheet, View, Image, Pressable } from "react-native";
import { Button } from "components/button_v2";
import { Column } from "components/column";
import { Amenity } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";

export const tourData = [
  {
    uri: "https://flexspace-ds-static.s3.us-west-1.amazonaws.com/tour1.png",
    title: "Welcome to Flexspace!",
    content:
      "Browse and book hundreds of inspiring spaces for you or your team.",
  },
  {
    uri: "https://flexspace-ds-static.s3.us-west-1.amazonaws.com/tour2.png",
    title: "Collaborate IRL with your team",
    content:
      "See where your teammates work from and book a space to work together in-person.",
  },
  {
    uri: "https://flexspace-ds-static.s3.us-west-1.amazonaws.com/tour3.png",
    title: "Support any time, anywhere",
    content:
      "No matter where you are, our team is dedicated to finding you the space to thrive.",
  },
  {
    uri: "https://flexspace-ds-static.s3.us-west-1.amazonaws.com/tour4.png",
    title: "Get started now",
    content:
      "In a world of hybrid and remote work, it’s time to work like a team of the future with Flexspace.",
  },
];

export interface TourSpace {
  id: string;
  name: string;
  adressName: string;
  adress: string;
  amenities: Amenity[];
  image: string;
  maxCapacity: number;
}

interface TourProps {
  isVisible: boolean;
  setLayoutVisible: (visible: boolean) => void;
}

export function Tour(props: TourProps) {
  const analytics = useAnalytics();
  const { isVisible = true, setLayoutVisible } = props;
  useEffect(() => {
    if (isVisible) {
      analytics.screen("Onboarding tour");
    }
  }, [analytics, isVisible]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleNext = () => {
    if (selectedIndex < tourData.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    } else {
      setLayoutVisible(false);
    }
  };

  const mq = useMediaQuery();

  return (
    <Dialog
      animationType="slide"
      visible={isVisible}
      onRequestClose={() => setLayoutVisible(false)}
      style={mq.deviceQuery.mobile && styles.dialog}
      center
      hasTransParentOverlay
    >
      <DialogContent
        headerRightIcon="tour-close"
        onHeaderRightIconPress={() => setLayoutVisible(false)}
        desktopWidth={600}
        borderBottomLeftRadius={16}
        borderBottomRightRadius={16}
      >
        <View
          style={{
            maxWidth: mq.deviceQuery.mobile ? 311 : 343,
            margin: "auto",
          }}
        >
          <View style={styles.heading}>
            <Heading size="lg" align="center">
              {tourData[selectedIndex].title}
            </Heading>
          </View>
          <Spacer size={16} />
          <View style={styles.tour}>
            <Text size="xs" align="center">
              {tourData[selectedIndex].content}
            </Text>
            <Spacer size={mq.deviceQuery.mobile ? 28 : 16} />
            <View style={styles.imageContainer}>
              <Image
                source={{
                  uri: tourData[selectedIndex].uri,
                  width: mq.deviceQuery.mobile ? 233 : 343,
                  height: mq.deviceQuery.mobile ? 200 : 300,
                }}
              />
            </View>

            <Spacer size={16} />
            <Row alignItems="center" justifyContent="center" spacing={8}>
              {tourData?.map((_, index) => (
                <Pressable
                  key={index}
                  style={[
                    styles.tourButton,
                    index === selectedIndex && styles.tourButtonSelected,
                  ]}
                  onPress={() => setSelectedIndex(index)}
                />
              ))}
            </Row>
            <Spacer size={24} />
            <Row justifyContent="center">
              <Column>
                <View style={{ width: 114 }}>
                  <Button
                    text={
                      selectedIndex === tourData.length - 1
                        ? "Let's go"
                        : "Next"
                    }
                    testID="tour-next-button"
                    onPress={handleNext}
                  />
                </View>

                <Spacer size={12} />
                <Pressable
                  onPress={() => {
                    analytics.event("Skip Onboarding Tour", {
                      Step: selectedIndex + 1,
                    });
                    setLayoutVisible(false);
                  }}
                  testID="skip-tour-button"
                >
                  <Text
                    size="sm"
                    color="eggplant-core"
                    weight="semibold"
                    align="center"
                  >
                    Skip
                  </Text>
                </Pressable>
              </Column>
            </Row>
          </View>
        </View>
      </DialogContent>
    </Dialog>
  );
}

const styles = StyleSheet.create({
  tour: {
    paddingBottom: 40,
    alignSelf: "center",
  },
  tourButton: {
    width: 8,
    height: 8,
    borderRadius: 50,
    backgroundColor: colors.brand.blackMinus80,
  },
  tourButtonSelected: {
    backgroundColor: colors.brand.blackMinus30,
  },

  imageContainer: {
    alignItems: "center",
  },
  dialog: {
    width: "90%",
    height: "80%",
    alignItems: "center",
  },
  dialogContent: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  heading: {
    minHeight: 67,
  },
});
