import { createContext, useCallback, useContext, useRef } from "react";

interface ScrollToBudgetCardContextProps {
  budgetCardRef: any;
  scrollToBudgetCard: () => void;
}

const ScrollToBudgetCardContext = createContext<ScrollToBudgetCardContextProps>(
  {
    budgetCardRef: null,
    scrollToBudgetCard: () => {},
  },
);

interface ScrollToBudgetCardContextProviderProps {
  children: React.ReactNode;
}

export const ScrollToBudgetCardContextProvider: React.FunctionComponent<
  ScrollToBudgetCardContextProviderProps
> = ({ children }) => {
  const budgetCardRef = useRef<HTMLDivElement>(null);
  const scrollToBudgetCard = useCallback(() => {
    budgetCardRef.current?.focus();
  }, [budgetCardRef]);

  return (
    <ScrollToBudgetCardContext.Provider
      value={{
        budgetCardRef,
        scrollToBudgetCard,
      }}
    >
      {children}
    </ScrollToBudgetCardContext.Provider>
  );
};

export const useScrollToBudgetCardContext = () =>
  useContext(ScrollToBudgetCardContext);
