import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Heading } from "components/heading_v2";
import { InAppInvite } from "components/in_app_invite";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import React, { useState } from "react";
import { Image, StyleSheet, View } from "react-native";

export function InviteYourCoWorkers() {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  return (
    <>
      <Spacer size={40} />
      <Heading size={"lg"} color="black-core">
        Team
      </Heading>
      <Spacer size={40} />
      <View style={styles.container}>
        <Image
          source={{
            uri: "/images/team.svg",
            width: 240,
            height: 240,
          }}
        />
        <Spacer size={16} />
        <Text size={"md"} weight={"semibold"} color={"black-70"}>
          Flexspace is better together
        </Text>
        <Spacer size={16} />
        <Text size={"xs"} color={"black-70"}>
          To get the full collaborative experience, invite your coworkers to
          your team.
        </Text>
        <Spacer size={16} />
        <Button
          text={"Invite coworkers"}
          size={"medium"}
          onPress={() => setIsInviteModalOpen(true)}
        />
        <InAppInvite
          isVisible={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
          analyticsFrom="Team spaces"
          onSuccess={() => {
            setIsInviteModalOpen(false);
          }}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 417,
    alignItems: "center",
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
    padding: 16,
  },
});
