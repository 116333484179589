import { ScrollView, View, StyleSheet } from "react-native";
import {
  DialogContent,
  DialogFooterWrapper,
} from "../../../core/dialog_content";
import { Heading } from "components/heading";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { Divider } from "components/divider";
import { TextInput } from "components/text_input";
import { useCallback, useState } from "react";
import { Button } from "components/button_v2";
import { Icon } from "components/icon";
import { Row } from "components/row";
import { tokens } from "components/tokens";
import {
  RequestBudgetIncreaseMutation,
  RequestBudgetIncreaseMutationVariables,
  RequestToBookQuery,
  RequestToBookQueryVariables,
  UserStatus,
} from "core/graphql.generated";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Spinner } from "components/spinner";

export interface RequestBookFormDialogContentProps {
  onClose: () => void;
}

export function RequestBookFormDialogContent(
  props: RequestBookFormDialogContentProps,
) {
  const { onClose } = props;
  const { data: requestToBookData, loading: loadingAdmin } = useQuery<
    RequestToBookQuery,
    RequestToBookQueryVariables
  >(requestToBookGQLQuery, {
    variables: {
      role: "admin",
      status: UserStatus.Active,
    },
  });
  const [note, setNote] = useState<string>("");

  const [requestBudgetIncrease, { loading, data }] = useMutation<
    RequestBudgetIncreaseMutation,
    RequestBudgetIncreaseMutationVariables
  >(requestBudgetIncreaseMutation);

  const firstAdminContactInfo =
    requestToBookData?.currentUser?.organization?.users[0];

  const sendBudgetIncreaseRequest = useCallback(() => {
    if (firstAdminContactInfo?.email) {
      requestBudgetIncrease({
        variables: {
          input: {
            note: note ?? undefined,
          },
        },
      });
    }
  }, [firstAdminContactInfo, requestBudgetIncrease, note]);

  return (
    <DialogContent headerRightIcon="x" onHeaderRightIconPress={onClose}>
      <ScrollView>
        <View style={styles.layout}>
          <Spacer size={8} />
          <Heading size="h1">Request to book</Heading>
          <Spacer size={8} />
          <Text align="left" color="darker" size="md">
            This space is locked because it is out of your budget policy.
          </Text>
          <Spacer size={24} />
          <Text align="left" color="darker" size="md">
            To request a new booking, please click the button below to contact
            your organization’s administrator.
          </Text>
          <Spacer size={24} />
          <Divider color="darker" />
          <Spacer size={24} />
          <Text align="left" color="darker" size="md" weight="600">
            Administrator Contact Details
          </Text>
          {loadingAdmin ? (
            <Spinner />
          ) : (
            <View>
              <Text align="left" color="darker" size="md">
                {firstAdminContactInfo?.fullName}
              </Text>
              <Text align="left" color="darker" size="md">
                {firstAdminContactInfo?.email}
              </Text>
              <Text align="left" color="darker" size="md">
                {firstAdminContactInfo?.phoneNumber ?? "-"}
              </Text>
            </View>
          )}
          <Spacer size={48} />
          {!data ? (
            <>
              <Text align="left" color="muted" size="md">
                Add a note for your administrator (optional)
              </Text>
              <TextInput
                numberOfLines={6}
                value={note}
                testID="request-budget-increase-note"
                onChange={(e) => setNote(e)}
              />
              <Spacer size={64} />
            </>
          ) : (
            <View style={styles.successWrapper}>
              <Row alignItems="center" spacing={4}>
                <Icon name="success-check" />
                <Text size="md" weight="700" color="success-green">
                  Request successfully sent!
                </Text>
              </Row>
              <Spacer size={8} />
              <Text size="md" color="success-green">
                You will receive an email if your request is approved by your
                administrator.
              </Text>
            </View>
          )}
        </View>
      </ScrollView>
      {!data && (
        <DialogFooterWrapper>
          <Button
            loading={loading}
            text="Submit"
            testID="request-budget-submit"
            onPress={sendBudgetIncreaseRequest}
          />
        </DialogFooterWrapper>
      )}
    </DialogContent>
  );
}

const styles = StyleSheet.create({
  successWrapper: {
    backgroundColor: tokens.colors.success.light,
    padding: 8,
  },
  layout: {
    minHeight: "70vh",
    padding: 16,
  },
});

const requestBudgetIncreaseMutation = gql`
  mutation RequestBudgetIncrease($input: RequestBudgetIncreaseInput!) {
    requestBudgetIncrease(input: $input)
  }
`;

const requestToBookGQLQuery = gql`
  query requestToBook($role: String!, $status: UserStatus) {
    currentUser {
      organization {
        users(role: $role, status: $status) {
          userId
          role
          fullName
          phoneNumber
          email
        }
      }
    }
  }
`;
