import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Divider } from "components/divider";
import { Heading } from "components/heading";
import { MobileHeader } from "components/layout/mobile_header";
import { LoginToBook } from "components/login_to_book";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { BookingCheckout, CheckoutTerms } from "core/booking_checkout";
import { BookingPreviewV2 } from "core/booking_preview";
import {
  getCheckoutCancellationPolicyText,
  useBookingDateRangePickerHelper,
} from "core/booking_utils";
import { CheckoutPaymentDetails } from "core/checkout_payment_details";
import { BookingType } from "core/graphql.generated";
import { OrderBreakdown } from "core/order_breakdown";
import { PromotionDetails } from "core/promotion_details";
import { useAuthV2 } from "providers/authv2";
import React, { Fragment } from "react";
import { ScrollView } from "react-native";
import { BookingCheckoutProps } from "../offsite_space_checkout";

export function BookingCheckoutMobile(props: BookingCheckoutProps) {
  const {
    requirePayment,
    space,
    values,
    errors,
    submitCount,
    handleChange,
    setFieldValue,
    submitting,
    disabled,
    submit,
    status,
    onCreditCardChange,
    setLayoutVisible,
    setBookingDateRangeVisible,
    stripe,
    quoteQuery,
    quoteError,
    monthlyBilling,
  } = props;
  const { authenticated } = useAuthV2();
  let startDate = "";
  let endDate = "";
  if (
    values?.dateRange?.type === BookingType.DailyBooking &&
    values?.dateRange.startDate &&
    values?.dateRange.endDate
  ) {
    startDate = values?.dateRange?.startDate;
    endDate = values?.dateRange?.endDate;
  } else if (
    values?.dateRange?.type === BookingType.HourlyBooking &&
    values?.dateRange?.date
  ) {
    startDate = values?.dateRange?.date;
    endDate = values?.dateRange?.date;
  }

  const { openAt } = useBookingDateRangePickerHelper({
    space,
  });
  return (
    <Container flex={1}>
      <Container flex={1}>
        <ScrollView>
          <Container>
            <MobileHeader />

            <Container paddingLeft={16} paddingBottom={16}>
              <Heading size="h1">Confirmation</Heading>
            </Container>

            <Container paddingHorizontal={16} paddingBottom={40}>
              <BookingPreviewV2
                onPressChange={() => setBookingDateRangeVisible(true)}
                space={space}
                searchValue={values}
              />
              <Spacer size={35} />

              {authenticated && (
                <>
                  <Divider />
                  <Spacer size={8} />
                  <BookingCheckout
                    onPressLayoutChange={() => setLayoutVisible(true)}
                    space={space}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    submitCount={submitCount}
                  />
                  <Spacer size={24} />
                </>
              )}

              <Divider />
              <Spacer size={16} />
              <OrderBreakdown
                currency={space.currency}
                quote={quoteQuery?.quote}
                quoteError={quoteError}
              />
              {quoteQuery && (
                <Fragment>
                  <Spacer size={16} />
                  <Divider />
                  <Spacer size={16} />
                  <PromotionDetails
                    offsiteSpaceID={space.id}
                    promoCode={values.promoCode}
                    onChange={(pc) => setFieldValue("promoCode", pc)}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Fragment>
              )}
              <Spacer size={16} />
              <Divider />

              {authenticated ? (
                <>
                  <Spacer size={16} />
                  {requirePayment && (
                    <>
                      <CheckoutPaymentDetails
                        stripe={stripe}
                        onChange={onCreditCardChange}
                        paymentCompleteError={errors.paymentComplete}
                      />
                      <Spacer size={16} />
                    </>
                  )}
                  {monthlyBilling && (
                    <Fragment>
                      <Container paddingBottom={16}>
                        <Row justifyContent="space-between">
                          <Text weight="bold">Payment</Text>
                          <Text>Invoiced to your organization.</Text>
                        </Row>
                      </Container>
                      <Divider />
                    </Fragment>
                  )}

                  <Container paddingVertical={16}>
                    <Text size="sm">
                      {getCheckoutCancellationPolicyText(
                        space.bookingPolicy,
                        space.location.timezone,
                        openAt,
                        values.dateRange,
                      )}
                    </Text>
                  </Container>
                  <Divider />
                  <Spacer size={24} />
                  <Button
                    loading={submitting}
                    disabled={disabled}
                    onPress={submit}
                    text={requirePayment ? "Confirm and pay" : "Confirm"}
                  />
                  <Spacer size={8} />
                  <CheckoutTerms />
                  {status !== "" && (
                    <Fragment>
                      <Spacer size={8} />
                      <Text color="error">{status}</Text>
                    </Fragment>
                  )}
                </>
              ) : (
                <>
                  <Container paddingVertical={16}>
                    <Text size="sm">
                      {getCheckoutCancellationPolicyText(
                        space.bookingPolicy,
                        space.location.timezone,
                        openAt,
                        values.dateRange,
                      )}
                    </Text>
                  </Container>
                  <Divider />
                  <LoginToBook />
                </>
              )}
            </Container>
          </Container>
        </ScrollView>
      </Container>
    </Container>
  );
}
