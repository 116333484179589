import { Spacer } from "components/spacer";
import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";

export function AmenitiesSekeleton() {
  return (
    <>
      <Spacer size={12} />
      <ContentLoader
        speed={2}
        width={"100%"}
        height={160}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={"100%"} height={160} />
      </ContentLoader>
      <Spacer size={12} />
    </>
  );
}
