import { View, Image, StyleSheet } from "react-native";

import { Card } from "components/card";
import { useMediaQuery } from "lib/media_query";
import { Text } from "components/text_v2";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";

interface LocationDetailsAvailableSpaceCardProps {
  imageSrc: string;
  spaceType: string;
  isOutOfPolicy: boolean;
  description: string;
  actionText: string;
}

export function LocationDetailsAvailableSpaceCard({
  imageSrc,
  spaceType,
  isOutOfPolicy,
  description,
  actionText,
}: LocationDetailsAvailableSpaceCardProps) {
  const mq = useMediaQuery();

  return (
    <Card>
      <View
        style={[
          styles.cardContent,
          mq.deviceQuery.mobile && styles.cardContentMobile,
        ]}
      >
        <View
          style={
            mq.deviceQuery.mobile
              ? styles.thumbnailWrapperMobile
              : styles.thumbnailWrapper
          }
        >
          <Image style={styles.thumbnail} source={{ uri: imageSrc }} />
        </View>
        <View style={styles.detailsWrapper}>
          <View style={styles.detailsHeaderWrapper}>
            <Text size="xs" weight="semibold">
              {spaceType}
            </Text>
            {isOutOfPolicy && (
              <>
                <Spacer size={8} direction="row" />
                <OutOfPolicyTag />
              </>
            )}
          </View>
          <Text size="xs" color="black-70">
            {description}
          </Text>
          <View style={styles.actionWrapper}>
            <Text size="xs" color="eggplant-core" weight="semibold">
              {actionText}
            </Text>
            <Spacer size={8} direction="row" />
            <Icon name="chevron-feather-right" color="eggplantcore" />
          </View>
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  cardContent: {
    flexDirection: "row",
    height: 100,
  },
  cardContentMobile: {
    height: 116,
  },
  thumbnailWrapper: {
    width: 160,
  },
  thumbnailWrapperMobile: {
    width: 92,
  },
  thumbnail: {
    width: "100%",
    height: "100%",
  },
  detailsWrapper: {
    padding: 16,
    justifyContent: "center",
    gap: 8,
    flex: 1,
  },
  detailsHeaderWrapper: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  actionWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
});
