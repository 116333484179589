import { AsyncRenderer } from "components/AsyncRenderer";
import { Carousel } from "components/carousel";
import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { CurrentUserOrderFragment } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { useUserOrderQuery } from "pages/homev3/hooks/use_user_order_query";
import { HeroCarouselSkeleton } from "pages/homev3/return_user/hero_carousel/hero_carousel_skeleton";
import { HeroLocationCard } from "pages/homev3/return_user/hero_carousel/hero_location_card";
import { NewLocation } from "pages/homev3/return_user/hero_carousel/new_location";
import { SimilarLocation } from "pages/homev3/return_user/hero_carousel/similar_location";
import { IconButton } from "pages/team_spaces/components/buttons/IconButton";
import { useAnalytics } from "providers/analytics";
import React, { ReactNode, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

export function HeroCarousel() {
  const { data, loading, error } = useUserOrderQuery({});
  return (
    <AsyncRenderer
      error={error}
      data={data}
      loadingHandler={() => <HeroCarouselSkeleton />}
      loading={loading}
    >
      {(data) => {
        return <Content userOrders={data.currentUser?.orders || []} />;
      }}
    </AsyncRenderer>
  );
}

type HeroCarouselProps = {
  userOrders: CurrentUserOrderFragment[];
};
function Content({ userOrders }: HeroCarouselProps) {
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const [showSimilarLocation, setShowSimilarLocation] = useState<boolean>(true);
  const [showNewLocation, setShowNewLocation] = useState<boolean>(true);
  if (userOrders.length === 0) {
    return null;
  }
  const lastOrder = userOrders[userOrders.length - 1];
  const lastSpace = lastOrder.orderItems[0].details.space;
  const lastLocation = lastSpace.location;
  const isMobile = mq.deviceQuery.mobile;

  const renderArrowNext = (clickHandler: () => void) => (
    <View style={styles.arrowNextView}>
      <IconButton
        iconName={"chevron-right"}
        onPress={() => {
          analytics.event("Navigate Carousel", {
            category: "Rebooking",
          });
          clickHandler();
        }}
        color={"whitecore"}
        hoveredColor="blackMinus70"
      />
    </View>
  );
  const renderArrowPrev = (clickHandler: () => void) => (
    <View style={styles.arrowPrevView}>
      <IconButton
        iconName={"chevron-left"}
        onPress={() => {
          analytics.event("Navigate Carousel", {
            category: "Rebooking",
          });
          clickHandler();
        }}
        color={"whitecore"}
        hoveredColor="blackMinus70"
      />
    </View>
  );

  const renderIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number,
  ) => (
    <Pressable
      // @ts-ignore
      onPress={(e: React.MouseEvent | React.KeyboardEvent) => {
        analytics.event("Navigate Carousel", {
          category: "Rebooking",
        });
        clickHandler(e);
      }}
      style={[
        styles.indicator,
        { right: `calc(50% - ${index} * 16px)` },
        isSelected && { opacity: 1 },
      ]}
    />
  );

  const onCardPress = (cardType: string) => {
    analytics.event("Click Carousel Card", {
      "Card Type": cardType,
      category: "Rebooking",
    });
  };

  const renderCarousel = (
    <>
      <HeroLocationCard
        location={lastLocation}
        title={`Like working at ${lastLocation.name}?`}
        subtitle={"Book it again"}
        onPress={() => onCardPress("Repeat booking")}
      />

      {showSimilarLocation && (
        <SimilarLocation
          lastSpace={lastSpace}
          setShowSimilarLocation={setShowSimilarLocation}
          onPress={() => onCardPress("Similar last booking")}
        />
      )}
      {showNewLocation && (
        <NewLocation
          userOrders={userOrders}
          setShowNewLocation={setShowNewLocation}
          onPress={() => onCardPress("Nearby spaces")}
        />
      )}
    </>
  );

  const renderCarouselMobile = (
    <>
      <View style={styles.cardWrapper}>
        <HeroLocationCard
          location={lastLocation}
          title={`Like working at ${lastLocation.name}?`}
          subtitle={"Book it again"}
          onPress={() => onCardPress("Repeat booking")}
        />
        <Spacer direction="row" size={16} />
      </View>

      {showSimilarLocation && (
        <View style={styles.cardWrapper}>
          <SimilarLocation
            lastSpace={lastSpace}
            setShowSimilarLocation={setShowSimilarLocation}
            onPress={() => onCardPress("Similar last booking")}
          />
          <Spacer direction="row" size={16} />
        </View>
      )}
      {showNewLocation && (
        <NewLocation
          userOrders={userOrders}
          setShowNewLocation={setShowNewLocation}
          onPress={() => onCardPress("Nearby spaces")}
        />
      )}
    </>
  );

  return (
    <Carousel
      showArrows={!isMobile}
      showIndicators={!isMobile}
      renderArrowNext={renderArrowNext}
      renderArrowPrev={renderArrowPrev}
      renderIndicator={renderIndicator}
      centerSlidePercentage={isMobile ? 93 : undefined}
      centerMode={isMobile ? true : undefined}
    >
      {(isMobile ? renderCarouselMobile : renderCarousel).props.children.filter(
        (c: ReactNode) => !!c,
      )}
    </Carousel>
  );
}

const styles = StyleSheet.create({
  arrowNextView: {
    position: "absolute",
    top: "50%",
    right: 16,
    zIndex: 2,
    transform: [{ translateY: -16 }],
  },
  arrowPrevView: {
    position: "absolute",
    top: "50%",
    left: 16,
    zIndex: 2,
    transform: [{ translateY: -16 }],
  },
  indicator: {
    position: "absolute",
    bottom: 14,
    width: 8,
    height: 8,
    backgroundColor: colors.brand.whitecore,
    opacity: 0.3,
    borderRadius: 999,
  },
  cardWrapper: {
    flexDirection: "row",
  },
});
