import pluralize from "pluralize";

import { Text } from "components/text_v2";
import { useAnalytics } from "providers/analytics";

import { UpcomingBookingAction } from "./upcoming_booking_action";
import { getInvitedPeopleTitle } from "../bookings_utils";
import { useBookingInvitation } from "../hooks/useBookingInvitation";

interface InviteCoworkersProps {
  remainingCapacity: number;
  invitedPeople: string[];
  orderId: string;
}

export function InviteCoworkers({
  remainingCapacity,
  invitedPeople,
  orderId,
}: InviteCoworkersProps) {
  const analytics = useAnalytics();
  const title = getInvitedPeopleTitle(invitedPeople);

  const { openDialog } = useBookingInvitation();

  if (remainingCapacity === 0) {
    return (
      <Text size="xs" weight="semibold">
        {title}
      </Text>
    );
  }

  const handleClickInvite = () => {
    openDialog(orderId, remainingCapacity);
    analytics.event("Click Invite Coworkers", {
      category: "Post booking",
    });
  };

  return (
    <>
      <UpcomingBookingAction
        title={title}
        description={`${remainingCapacity} ${pluralize(
          "spot",
          remainingCapacity,
        )} remaining`}
        iconName="userPlus"
        actionText="Invite coworkers"
        onClick={handleClickInvite}
      />
    </>
  );
}
