import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { SearchValue, toQueryString } from "core/booking_utils";
import { usePrevious } from "hooks/use_previous";

interface UseSyncSearchValueToURLProps {
  searchValue: SearchValue;
  offsiteSpaceID: string;
  baseURL: string;
}

export function useSyncSearchValueToURL(props: UseSyncSearchValueToURLProps) {
  const { searchValue, offsiteSpaceID, baseURL } = props;
  const history = useHistory();
  const prevOffsiteID = usePrevious(offsiteSpaceID);
  const prevQsRef = useRef("");

  useEffect(() => {
    const qs = toQueryString(searchValue);

    if (offsiteSpaceID !== prevOffsiteID || prevQsRef.current !== qs) {
      prevQsRef.current = qs;

      history.replace(`${baseURL}${qs}`);
    }
  }, [baseURL, history, offsiteSpaceID, prevOffsiteID, prevQsRef, searchValue]);
}
