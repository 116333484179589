import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Text } from "components/text_v2";
import { signInModalState } from "providers/app_loader";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useRecoilState } from "recoil";
import { useAnalytics } from "../providers/analytics";
import { useLocation } from "react-router-dom";

type LoginToSeeProps = {
  headerText: string;
  contentText: string;
};
export function LoginToSee({ headerText, contentText }: LoginToSeeProps) {
  const [, setShowLoginModal] = useRecoilState(signInModalState);
  const location = useLocation();
  const analytics = useAnalytics();
  const showLoginModal = () => {
    setShowLoginModal(true);
    analytics.event(
      `Click ${
        location.pathname.includes("bookings") ? "Bookings" : "Team"
      } Login Button`,
    );
  };
  return (
    <View style={styles.container} testID="login-to-see">
      <Text size={"md"} weight={"semibold"}>
        {headerText}
      </Text>
      <Text size={"xs"} align={"center"}>
        {contentText}
      </Text>
      <View style={styles.buttonWrapper}>
        <Button text={"Login"} size={"medium"} onPress={showLoginModal} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    gap: 16,
    padding: 24,
  },
  buttonWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
