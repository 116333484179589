import { useQuery } from "@apollo/client";
import React, { Fragment } from "react";
import { Spacer } from "components/spacer";
import {
  ReportingPageOrganizationReportingQuery,
  ReportingPageOrganizationReportingQueryVariables,
  ReportingPageReportingDataFragment,
} from "core/graphql.generated";
import { ColumnGrid, ColumnGridCol, ColumnGridRow } from "core/grid";
import { ReportingBookings } from "./bookings";
import { ReportingEngagement } from "./engagement";
import { ReportingFrequency } from "./frequency";
import { ReportingPayAsYouGoOverview } from "./pay_as_you_go_overview";
import { ReportingPayAsYouGoPromo } from "./pay_as_you_go_promo";
import { RecentActivity } from "./recent_activity";
import { ReportingSpend } from "./spend";
import { DayInterval } from "lib/day_utils";
import { reportingPageOrganizationReportingGQLQuery } from "../gql_queries";
import { ReportingContentSkeletonDesktop } from "./content_skeleton_desktop";
import { getLastUpdatedLabel } from "pages/reporting/utils/get_last_updated_label";

interface ReportingPagePayAsYouGoDesktopProps {
  dayInterval: DayInterval;
  onPressGetStartedSubscription: () => void;
}

export function ReportingPayAsYouGoDesktop(
  props: ReportingPagePayAsYouGoDesktopProps,
) {
  const { dayInterval, onPressGetStartedSubscription } = props;

  const { loading: reportingLoading, data: reportingData } = useQuery<
    ReportingPageOrganizationReportingQuery,
    ReportingPageOrganizationReportingQueryVariables
  >(reportingPageOrganizationReportingGQLQuery, {
    variables: {
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
  });

  return (
    <ColumnGrid>
      <ColumnGridRow>
        <ColumnGridCol style={{ zIndex: 1 }} cols={8}>
          {reportingLoading ||
          !reportingData?.currentUser?.organization?.reporting ? (
            <ReportingContentSkeletonDesktop />
          ) : (
            <ReportingContent
              reporting={reportingData?.currentUser?.organization?.reporting}
              onPressGetStartedSubscription={onPressGetStartedSubscription}
            />
          )}
        </ColumnGridCol>
        <ColumnGridCol cols={4}>
          <RecentActivity />
        </ColumnGridCol>
      </ColumnGridRow>
    </ColumnGrid>
  );
}

interface ReportingContentProps {
  reporting: ReportingPageReportingDataFragment;
  onPressGetStartedSubscription: () => void;
}

function ReportingContent(props: ReportingContentProps) {
  const { reporting, onPressGetStartedSubscription } = props;
  const labelUpdatedAt = getLastUpdatedLabel(reporting.lastUpdatedDate);

  return (
    <Fragment>
      <ColumnGridRow>
        <ColumnGridCol cols={6} style={{ zIndex: 1 }}>
          <ReportingPayAsYouGoOverview
            totalSpend={reporting.spend.totalSpend}
          />
        </ColumnGridCol>
        <ColumnGridCol cols={6}>
          <ReportingPayAsYouGoPromo onPress={onPressGetStartedSubscription} />
        </ColumnGridCol>
      </ColumnGridRow>
      <Spacer size={40} />

      <ColumnGridRow>
        <ColumnGridCol style={{ zIndex: 1 }} cols={6}>
          <ReportingSpend
            dayPasses={reporting.spend.dayPasses}
            dayOffices={reporting.spend.dayOffices}
            meetingRooms={reporting.spend.meetingRooms}
            totalSpend={reporting.spend.totalSpend}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ColumnGridCol>
        <ColumnGridCol cols={6}>
          <ReportingBookings
            dayPasses={reporting.bookings.dayPasses}
            dayOffices={reporting.bookings.dayOffices}
            meetingRooms={reporting.bookings.meetingRooms}
            totalBookings={reporting.bookings.totalBookings}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ColumnGridCol>
      </ColumnGridRow>
      <Spacer size={40} />
      <ColumnGridRow>
        <ColumnGridCol style={{ zIndex: 1 }} cols={6}>
          <ReportingEngagement
            soloBookings={reporting.engagement.soloBookings}
            teamBookings={reporting.engagement.teamBookings}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ColumnGridCol>
        <ColumnGridCol cols={6}>
          <ReportingFrequency
            zeroOrSingleBookingPerWeek={
              reporting.frequency.zeroOrSingleBookingPerWeek
            }
            twoOrThreeBookingsPerWeek={
              reporting.frequency.twoOrThreeBookingsPerWeek
            }
            fourOrMoreBookingsPerWeek={
              reporting.frequency.fourOrMoreBookingsPerWeek
            }
            totalEmployees={reporting.frequency.totalEmployees}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ColumnGridCol>
      </ColumnGridRow>
    </Fragment>
  );
}
