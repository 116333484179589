import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { View } from "react-native";
import { EmptyStateGroupBTile } from "./empty_state_tiles";
import { Divider } from "components/divider";
import { GridV2 } from "components/gridv2";
import { CityCard } from "../components/city_card";
import { useMediaQuery } from "lib/media_query";

interface EmptyStateWithNoFilterWithSearchNoSpaceProps {
  onFindMeASpacePress?: () => void;
}

export function EmptyStateWithNoFilterWithSearchNoSpace(
  props: EmptyStateWithNoFilterWithSearchNoSpaceProps,
) {
  const { onFindMeASpacePress } = props;
  return (
    <View testID="empty-spaces-no-filter-with-search">
      <EmptyStateGroupBTile onFindMeASpacePress={onFindMeASpacePress} />
    </View>
  );
}

export function ExploreCity() {
  const mq = useMediaQuery();

  return (
    <View>
      <Spacer size={24} />
      <Divider />
      <Spacer size={24} />
      <Text weight="bold" size="md">
        Explore a city
      </Text>
      <Spacer size={24} />
      <GridV2 columns={mq.deviceQuery.mobile ? 2 : 3}>
        <CityCard name="New York" imageUrl="/images/daypasses.png" />
        <CityCard name="Istanbul" imageUrl="/images/meetingrooms.png" />
        <CityCard name="Los Angeles" imageUrl="/images/dayoffices.png" />
        <CityCard name="Madrid" imageUrl="/images/daypasses.png" />
      </GridV2>
    </View>
  );
}
