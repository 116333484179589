import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";
import { Container } from "components/container";
import { Content } from "components/content";
import { Divider } from "components/divider";
import { Heading } from "components/heading";
import { Icon } from "components/icon";
import { Modal } from "components/modal";
import { PageContainer } from "components/page_container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { BookingPreview } from "core/booking_preview";
import { StickyBookingPreviewPanel } from "core/booking_preview_panel";
import {
  getCheckoutCancellationPolicyText,
  getDateRangeFromBooking,
  isPastBooking,
  SearchValue,
  useBookingDateRangePickerHelper,
} from "core/booking_utils";
import { Footer } from "core/footer";
import {
  CancelOrderMutation,
  CancelOrderMutationVariables,
  DailyBooking,
  HourlyBooking,
  OffsiteOrderPage__OrderDetailsQuery,
  OffsiteOrderPage__OrderDetailsQueryVariables,
  OrderStatus,
  ReservationDetailsCurrentUserQuery,
  Space,
} from "core/graphql.generated";
import { OrderBreakdown } from "core/order_breakdown";
import { ROUTES } from "core/routes";
import { OrderInvites } from "core/send_invites";
import { useGoBack } from "hooks/use_go_back";
import { formatCurrency } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";
import { BookingDetailPaymentInfo } from "pages/booking_details/components/booking_detail_payment_info";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { ActivityIndicator, Image, Pressable, View } from "react-native";
import { useParams } from "react-router";
import { getBooking } from "./offsite_order/offsite_order";
import { offsiteOrderPageGQLQuery } from "./offsite_order/offsite_order.queries";

interface UserProfileReservationDetailsPageParams {
  reservationID: string;
}

export function UserProfileReservationDetailsPage() {
  const goBack = useGoBack(ROUTES.USER_PROFILE.path);
  const { reservationID } =
    useParams<UserProfileReservationDetailsPageParams>();
  const {
    data: orderQuery,
    loading,
    refetch: refetchOrder,
  } = useQuery<
    OffsiteOrderPage__OrderDetailsQuery,
    OffsiteOrderPage__OrderDetailsQueryVariables
  >(offsiteOrderPageGQLQuery, { variables: { id: reservationID } });

  const order = orderQuery?.order;

  if (loading || !order) {
    return (
      <View style={{ padding: 120 }}>
        <Spinner />
      </View>
    );
  }

  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Spacer size={32} />
        <UserProfileReservationDetails
          onBack={goBack}
          order={order}
          refetchOrder={refetchOrder}
        />

        <Spacer size={40} />
      </PageContainer>
      <Footer />
    </Container>
  );
}

interface UserProfileReservationDetailsProps {
  order: NonNullable<OffsiteOrderPage__OrderDetailsQuery["order"]>;
  onBack: () => void;
  refetchOrder: () => void;
}

export function UserProfileReservationDetails(
  props: UserProfileReservationDetailsProps,
) {
  const { order, onBack, refetchOrder } = props;
  const [cancelOrder] = useMutation<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >(cancelOrderGQLMutation);
  const mq = useMediaQuery();
  const [loading, setLoading] = useState(false);
  const booking = getBooking(order.orderItems);
  const space = booking.space;
  const apolloClient = useApolloClient();
  const { data } = useQuery<ReservationDetailsCurrentUserQuery>(
    reservationDetailsCurrentUserGQLQuery,
  );
  const monthlyBilling = !!data?.currentUser?.organization?.monthlyBilling;
  const handleCancelReservation = useCallback(async () => {
    setLoading(true);
    await cancelOrder({ variables: { orderID: order.id } });
    await apolloClient.resetStore();
    setLoading(false);
  }, [order, apolloClient, cancelOrder]);

  return (
    <Fragment>
      {mq.sizeQuery.lgAndUp ? (
        <OrderDetailsDesktop
          onCancelReservation={handleCancelReservation}
          booking={booking}
          order={order}
          space={space}
          onSendInvitesComplete={refetchOrder}
          onBack={onBack}
          monthlyBilling={monthlyBilling}
        />
      ) : (
        <OrderDetailsMobile
          onCancelReservation={handleCancelReservation}
          booking={booking}
          order={order}
          space={space}
          onSendInvitesComplete={refetchOrder}
          onBack={onBack}
          monthlyBilling={monthlyBilling}
        />
      )}
      <Modal visible={loading} transparent>
        <Container customColor="rgba(255, 255, 255, 0.7)" expanded center>
          <Text weight="bold" size="lg" color="primary">
            Canceling your booking
          </Text>
          <Spacer size={16} />
          <ActivityIndicator size="large" color="rgba(82,68,134,1)" />
        </Container>
      </Modal>
    </Fragment>
  );
}

interface OrderDetailsProps {
  order: NonNullable<OffsiteOrderPage__OrderDetailsQuery["order"]>;
  space: Space;
  booking: HourlyBooking | DailyBooking;
  onCancelReservation: () => void;
  onSendInvitesComplete: () => void;
  onBack: () => void;
  monthlyBilling: boolean;
}

function OrderDetailsDesktop(props: OrderDetailsProps) {
  const {
    monthlyBilling,
    order,
    space,
    booking,
    onCancelReservation,
    onSendInvitesComplete,
    onBack,
  } = props;
  const searchValue = useMemo((): SearchValue => {
    return {
      dateRange: getDateRangeFromBooking(booking),
    };
  }, [booking]);

  const layout = space.layouts.find((l) => booking.layoutID === l.id);
  const hasLeftColumn =
    (space.layouts.length > 1 && layout) || booking.meetingContactInfo;

  return (
    <Content>
      <Container paddingHorizontal={16}>
        <Row alignItems="center">
          <Pressable onPress={onBack}>
            <Icon size="lg" name="arrow-left-circle" />
          </Pressable>
          <Spacer size={10} />
          <Heading size="h1">Your reservation</Heading>
        </Row>
      </Container>
      <Container paddingLeft={16}>
        {space.maxCapacity > 1 &&
          order.status !== OrderStatus.Canceled &&
          !isPastBooking(booking) && (
            <Fragment>
              <Spacer size={16} />
              <OrderInvites
                order={order}
                maxCapacity={space.maxCapacity}
                onComplete={onSendInvitesComplete}
              />
            </Fragment>
          )}
        <Spacer size={16} />
      </Container>
      <div className="row">
        {hasLeftColumn && (
          <div className="left-column">
            <Spacer size={16} />
            <BookingDetails order={order} space={space} booking={booking} />
          </div>
        )}
        <div className="right-column">
          <div className="sticky-panel">
            <StickyBookingPreviewPanel
              cancellable={order.cancellable}
              unCancellableReason={order.uncancellable_reason}
              paymentDetail={order.paymentDetail}
              searchValue={searchValue}
              currency={order.currency}
              monthlyBilling={monthlyBilling}
              quote={{
                totalPrice: order.totalPrice,
                subTotal: order.subTotal,
                promotionLine: order.promotionLine,
                taxLine: order.taxLine,
                processingFee: order.processingFee,
                orderItems: order.orderItems,
              }}
              space={space}
              hidePromotionForm={true}
            />
          </div>
        </div>
      </div>
      <Spacer size={16} />
      {order.status === OrderStatus.Canceled && (
        <Container paddingHorizontal={16}>
          <Text weight="600" customColor={tokens.colors.secondary.darker}>
            Cancelled
          </Text>
        </Container>
      )}

      {order.cancellable && (
        <Container paddingHorizontal={16}>
          <Pressable
            testID="cancel-reservation-link"
            onPress={onCancelReservation}
          >
            <Text decoration="underline">Cancel reservation</Text>
          </Pressable>
          <Spacer size={24} />
        </Container>
      )}

      <style jsx>{`
        .left-column {
          flex: 1;
          padding-right: 16px;
        }

        .right-column {
          flex-basis: 44%;
        }

        .sticky-panel {
          position: sticky;
          top: 0px;
          padding-top: 24px;
        }
      `}</style>
    </Content>
  );
}

function OrderDetailsMobile(props: OrderDetailsProps) {
  const {
    order,
    space,
    booking,
    onCancelReservation,
    onSendInvitesComplete,
    onBack,
    monthlyBilling,
  } = props;
  const searchValue = useMemo((): SearchValue => {
    return {
      dateRange: getDateRangeFromBooking(booking),
    };
  }, [booking]);

  const { openAt } = useBookingDateRangePickerHelper({
    space,
  });

  return (
    <Container>
      <Container paddingHorizontal={16}>
        <Row alignItems="center">
          <Pressable onPress={onBack}>
            <Icon size="lg" name="arrow-left-circle" />
          </Pressable>
          <Spacer size={10} />
          <Heading size="h1">Your reservation</Heading>
        </Row>
      </Container>
      {space.maxCapacity > 1 && (
        <Fragment>
          <Spacer size={16} />
          <OrderInvites
            order={order}
            maxCapacity={space.maxCapacity}
            onComplete={onSendInvitesComplete}
          />
        </Fragment>
      )}
      <Spacer size={16} />
      <Divider />
      <BookingPreview searchValue={searchValue} space={space} />
      <Divider />
      <Spacer size={16} />
      <Container paddingHorizontal={16}>
        <OrderBreakdown
          currency={order.currency}
          quote={{
            totalPrice: order.totalPrice,
            subTotal: order.subTotal,
            promotionLine: order.promotionLine,
            taxLine: order.taxLine,
            processingFee: order.processingFee,
            orderItems: order.orderItems,
          }}
        />
      </Container>
      <Spacer size={16} />
      <Divider />
      {monthlyBilling && (
        <Fragment>
          <Container padding={16}>
            <Row justifyContent="space-between">
              <Text weight="bold">Payment</Text>
              <Text align="right">Invoiced to your organization.</Text>
            </Row>
          </Container>
        </Fragment>
      )}
      <Spacer size={16} />
      {order.paymentDetail?.paymentMethodDetails && !monthlyBilling && (
        <Container paddingHorizontal={16}>
          <BookingDetailPaymentInfo
            monthlyBilling={monthlyBilling}
            paymentDetail={order.paymentDetail}
            totalPrice={formatCurrency(
              order.totalPrice,
              getSystemLocale(),
              order.currency,
            )}
          />
        </Container>
      )}

      <Divider />
      <Spacer size={16} />
      <Container paddingHorizontal={16}>
        <Text size="sm">
          {!order.cancellable && order.uncancellable_reason
            ? order.uncancellable_reason
            : getCheckoutCancellationPolicyText(
                space.bookingPolicy,
                space.location.timezone,
                openAt,
                searchValue.dateRange,
              )}
        </Text>
      </Container>
      <Spacer size={24} />
      <BookingDetails order={order} space={space} booking={booking} />
      {order.status === OrderStatus.Canceled && (
        <Container paddingHorizontal={16}>
          <Text weight="600" customColor={tokens.colors.secondary.darker}>
            Cancelled
          </Text>
        </Container>
      )}
      {order.cancellable && (
        <Container paddingHorizontal={16}>
          <Pressable
            testID="cancel-reservation-link"
            onPress={onCancelReservation}
          >
            <Text decoration="underline">Cancel reservation</Text>
          </Pressable>
          <Spacer size={24} />
        </Container>
      )}
    </Container>
  );
}

interface BookingDetailsProps {
  order: NonNullable<OffsiteOrderPage__OrderDetailsQuery["order"]>;
  booking: HourlyBooking | DailyBooking;
  space: Space;
}

function BookingDetails(props: BookingDetailsProps) {
  const { booking, space } = props;

  const layout = space.layouts.find((l) => booking.layoutID === l.id);

  return (
    <Container>
      <Container paddingHorizontal={16}>
        {space.layouts.length > 1 && layout && (
          <Fragment>
            <Heading size="h4">Room layout</Heading>
            <Spacer size={4} />
            <Row alignItems="center">
              <Image
                source={{
                  uri: layout.imageURL,
                  width: 40,
                  height: 40,
                }}
                style={{
                  borderRadius: tokens.radius,
                  borderWidth: 1,
                  borderColor: tokens.colors.neutral.dark,
                }}
              />
              <Spacer size={8} />
              <Text>{layout.name}</Text>
            </Row>
            <Spacer size={24} />
          </Fragment>
        )}
        {booking.meetingContactInfo && (
          <Fragment>
            <Container>
              <Row alignItems="center">
                <Heading size="h4">Booking for</Heading>
              </Row>
              <Spacer size={4} />
              <Container>
                <Text>{booking.meetingContactInfo.fullName}</Text>
                <Text>{booking.meetingContactInfo.companyName}</Text>
                <Text>{booking.meetingContactInfo.email}</Text>
                <Text>{booking.meetingContactInfo.phoneNumber}</Text>
              </Container>
            </Container>
            <Spacer size={24} />
          </Fragment>
        )}
      </Container>
    </Container>
  );
}

// Access token required in header authorization
const cancelOrderGQLMutation = gql`
  mutation cancelOrder($orderID: ID!) {
    cancelOrder(orderID: $orderID)
  }
`;

const reservationDetailsCurrentUserGQLQuery = gql`
  query ReservationDetailsCurrentUser {
    currentUser {
      id
      organization {
        monthlyBilling
      }
    }
  }
`;
