import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { LoginGoogleOrEmail } from "components/login_google_or_email";
import { TermsAndPolicy } from "components/sign_up_shared/terms_and_policy";
import { Text } from "components/text_v2";
import { useAuthEmailForm } from "components/use_auth_email_form";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import { StyleSheet, View } from "react-native";

interface SignInModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export function SignInModal(props: SignInModalProps) {
  const { isVisible = false, onClose } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const form = useAuthEmailForm();
  const { submit, submitting } = form;

  return (
    <DialogModal
      isVisible={isVisible}
      onClose={onClose}
      headerTitle={`Log in or sign up`}
      desktopWidth={780}
      mobileOffsetBottom={444}
      bottomButtons={
        <View style={{ width: isMobile ? "100%" : 229 }}>
          <Button loading={submitting} text="Continue" onPress={submit} />
        </View>
      }
    >
      <View style={isMobile ? styles.containerMobile : styles.container}>
        <View style={styles.content}>
          <Text size={"md"} weight={"semibold"} align={"center"}>
            Welcome to Flexspace
          </Text>
          <LoginGoogleOrEmail form={form} />
        </View>
        <TermsAndPolicy />
      </View>
    </DialogModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-around",
    gap: 120,
    alignItems: "center",
  },
  containerMobile: {
    flex: 1,
    gap: 16,
    alignItems: "center",
  },
  content: {
    width: 343,
    gap: 16,
  },
});
