import { colors } from "components/colors";
import { Icon, IconName } from "components/iconv2";
import { Text } from "components/text_v2";
import { Pressable, StyleSheet, View } from "react-native";

interface WhatFlexspaceOfferItemButtonProps {
  text?: string;
  size?: "large" | "medium" | "small" | "fit" | "auto";
  iconName?: IconName;
  iconPosition?: "left" | "right";
  iconSize?: "sm" | "md" | "lg";
  onPress: () => void;
  testID?: string;
}

export function WhatFlexspaceOfferItemButton(
  props: WhatFlexspaceOfferItemButtonProps,
) {
  const {
    text,
    size = "auto",
    iconName,
    iconPosition = "left",
    iconSize = "sm",
    onPress,
    testID,
  } = props;

  const renderIconContent = () => {
    if (iconName) {
      return <Icon name={iconName} size={iconSize} />;
    }
  };

  return (
    <Pressable
      style={({ hovered }: any) => [
        buttonStyles.base,
        buttonStyles[size],
        hovered && buttonStyles.hovered,
      ]}
      onPress={onPress}
      testID={testID}
    >
      <View
        style={[
          buttonContentWrapperStyles.base,
          buttonContentWrapperStyles[iconPosition],
        ]}
      >
        {renderIconContent()}
        {text && (
          <Text weight="semibold" size="xs">
            {text}
          </Text>
        )}
      </View>
    </Pressable>
  );
}

const buttonStyles = StyleSheet.create({
  base: {
    minHeight: 40,
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.brand.whitecore,
    borderWidth: 1,
  },
  large: {
    minWidth: 343,
    width: "fit-content",
  },
  medium: {
    minWidth: 229,
    width: "fit-content",
  },
  small: {
    minWidth: 114,
    width: "fit-content",
  },
  fit: {
    width: "fit-content",
  },
  auto: {
    width: "initial",
  },
  hovered: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
});

const buttonContentWrapperStyles = StyleSheet.create({
  base: {
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  left: {
    flexDirection: "row",
  },
  right: {
    flexDirection: "row-reverse",
  },
});
