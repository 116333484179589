import { Reducer, useCallback, useEffect, useMemo, useReducer } from "react";
import { isEmpty } from "lib/lang_utils";
import { useMounted } from "./use_mounted";
import { usePrevious } from "./use_previous";

export interface UseFormProps<
  T,
  K extends keyof T,
  U extends T[K] extends (infer E)[] ? E : never = any,
> {
  initialValues: T;
  initialStatus?: string;
  validate?: (values: T) => FormErrors<T>;
  validateOnChange?: boolean;
  validateOnMount?: boolean;
  onSubmit: (values: T, helpers: FormSetters<T, K, U>) => Promise<void>;
  /** Reset the form when new initialValues are passed */
  reinitialize?: boolean;
}

export interface UseFormData<
  T,
  K extends keyof T,
  U extends T[K] extends (infer E)[] ? E : never = any,
> extends FormSetters<T, K, U> {
  submit: () => Promise<void>;
  reset: () => void;
  validate: () => void;
  values: T;
  /** Often used for errors from server or other sources */
  status: string;
  errors: FormErrors<T>;
  changed: FormChanged<T>;
  submitting: boolean;
  submitCount: number;
  isDirty: boolean;
}

export interface FormSetters<
  T,
  K extends keyof T,
  U extends T[K] extends (infer E)[] ? E : never = any,
> {
  handleChange: (field: K) => (value: T[K]) => void;
  setFieldError: (field: K, message?: string) => void;
  setFieldValue: (field: K, value: T[K]) => void;
  setArrayFieldValue: (
    field: K,
    index: number,
    innerField: keyof U,
    value: T[K],
  ) => void;
  setFieldValues: (values: Partial<T>) => void;
  setStatus: (status: string) => void;
  setSubmitting: (submitting: boolean) => void;
  setErrors: (errors: FormErrors<T>) => void;
}

interface FormState<T> {
  values: T;
  errors: FormErrors<T>;
  changed: FormChanged<T>;
  status: string;
  submitting: boolean;
  submitCount: number;
  isDirty: boolean;
}
type DeepString<T> = {
  [K in keyof T]?: T[K] extends object
    ? DeepString<T[K]>
    : T[K] extends string
    ? T[K]
    : string;
};

export type FormErrors<T> = DeepString<T>;
export type FormChanged<T> = { [key in keyof T]?: boolean };

type Action<
  T,
  K extends keyof T,
  U extends T[K] extends (infer E)[] ? E : never = any,
> =
  | {
      type: "SET_FIELD_VALUE";
      field: K;
      value: T[K];
    }
  | {
      type: "SET_ARRAY_FIELD_VALUE";
      field: K;
      index: number;
      innerField: keyof U;
      value: T[K];
    }
  | {
      type: "SET_FIELD_VALUES";
      values: Partial<T>;
    }
  | {
      type: "SET_FIELD_ERROR";
      field: K;
      message?: string;
    }
  | {
      type: "SET_ERRORS";
      errors: FormErrors<T>;
    }
  | {
      type: "SET_STATUS";
      status: string;
    }
  | {
      type: "SUBMIT";
    }
  | {
      type: "SET_SUBMITTING";
      submitting: boolean;
    }
  | {
      type: "RESET";
      initialValues: T;
    };

function reducer<
  T,
  K extends keyof T,
  U extends T[K] extends (infer E)[] ? E : never = any,
>(prevState: FormState<T>, action: Action<T, K, U>): FormState<T> {
  switch (action.type) {
    case "SET_FIELD_VALUE":
      delete prevState.errors[action.field];

      return {
        ...prevState,
        isDirty: true,
        values: {
          ...prevState.values,
          [action.field]: action.value,
        },
        errors: {
          ...prevState.errors,
        },
        changed: {
          ...prevState.changed,
          [action.field]: true,
        },
      };
    case "SET_ARRAY_FIELD_VALUE":
      const arr = prevState.values[action.field];

      if (!Array.isArray(arr)) {
        console.error("useForm", action.field, "is not an array");

        return {
          ...prevState,
        };
      }

      const clonedArr = arr.slice();

      clonedArr[action.index] = {
        ...clonedArr[action.index],
        [action.innerField]: action.value,
      };

      return {
        ...prevState,
        values: {
          ...prevState.values,
          [action.field]: clonedArr,
        },
        errors: {
          ...prevState.errors,
        },
        changed: {
          ...prevState.changed,
          [action.field]: true,
        },
      };
    case "SET_FIELD_VALUES":
      return {
        ...prevState,
        values: {
          ...prevState.values,
          ...action.values,
        },
        changed: {
          ...prevState.changed,
          ...action.values,
        },
      };
    case "SET_ERRORS":
      return {
        ...prevState,
        errors: {
          ...action.errors,
        },
      };
    case "SET_FIELD_ERROR":
      if (action.message === undefined) {
        delete prevState.errors[action.field];

        return {
          ...prevState,
          errors: {
            ...prevState.errors,
          },
        };
      }

      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          [action.field]: action.message,
        },
      };
    case "SET_STATUS":
      return {
        ...prevState,
        status: action.status,
      };
    case "SET_SUBMITTING":
      return {
        ...prevState,
        submitting: action.submitting,
      };
    case "SUBMIT":
      return {
        ...prevState,
        submitCount: prevState.submitCount + 1,
      };
    case "RESET":
      return {
        isDirty: false,
        values: action.initialValues,
        errors: {},
        changed: {},
        status: "",
        submitting: false,
        submitCount: 0,
      };
    default:
      throw new Error("Action type not resolved");
  }
}

export function useForm<
  T,
  K extends keyof T,
  U extends T[K] extends (infer E)[] ? E : never = any,
>(props: UseFormProps<T, K, U>): UseFormData<T, K, U> {
  const {
    initialValues,
    initialStatus = "",
    validate,
    validateOnChange,
    validateOnMount,
    onSubmit,
    reinitialize,
  } = props;
  const initialState = useMemo(
    (): FormState<T> => ({
      values: initialValues,
      errors: {},
      changed: {},
      status: initialStatus,
      submitting: false,
      submitCount: 0,
      isDirty: false,
    }),
    [initialValues, initialStatus],
  );
  const [state, dispatch] = useReducer<Reducer<FormState<T>, Action<T, K, U>>>(
    reducer,
    initialState,
  );

  const { values, errors, changed, submitting, status, submitCount, isDirty } =
    state;
  const prevValues = usePrevious(values);
  const mounted = useMounted();

  useEffect(() => {
    if (reinitialize === true) {
      dispatch({ type: "RESET", initialValues });
    }
  }, [reinitialize, initialValues]);

  const setErrors = useCallback((nextErrors: FormErrors<T>) => {
    dispatch({ type: "SET_ERRORS", errors: nextErrors });
  }, []);

  const setFieldError = useCallback((field: K, message?: string): void => {
    dispatch({ type: "SET_FIELD_ERROR", field, message });
  }, []);

  const setStatus = useCallback((newStatus: string) => {
    dispatch({ type: "SET_STATUS", status: newStatus });
  }, []);

  const setSubmitting = useCallback((newSubmitting: boolean) => {
    dispatch({ type: "SET_SUBMITTING", submitting: newSubmitting });
  }, []);

  const setFieldValue = useCallback((field: K, value: T[K]): void => {
    dispatch({ type: "SET_FIELD_VALUE", field, value });
  }, []);

  const setArrayFieldValue = useCallback(
    (field: K, index: number, innerField: keyof U, value: T[K]): void => {
      dispatch({
        type: "SET_ARRAY_FIELD_VALUE",
        field,
        index,
        innerField,
        value,
      });
    },
    [],
  );

  const setFieldValues = useCallback((newValues: Partial<T>): void => {
    dispatch({ type: "SET_FIELD_VALUES", values: newValues });
  }, []);

  useEffect(() => {
    if (validateOnChange && validate !== undefined && values !== prevValues) {
      const _errors = validate(values);

      const changedKeys = Object.keys(changed) as (keyof T)[];

      if (isEmpty(_errors) === false) {
        const nextErrors: FormErrors<T> = {};

        changedKeys.forEach((key) => {
          if (_errors[key] !== undefined) {
            nextErrors[key] = _errors[key];
          }
        });

        setErrors(nextErrors);
      }
    }
  }, [validateOnChange, validate, values, prevValues, changed, setErrors]);

  useEffect(() => {
    if (validateOnMount && !mounted && validate !== undefined) {
      const _errors = validate(values);

      setErrors(_errors);
    }
  }, [validateOnMount, mounted, validate, values, setErrors]);

  const handleValidate = useCallback((): void => {
    if (validate !== undefined) {
      const _errors = validate(values);

      setErrors(_errors);
    }
  }, [validate, values, setErrors]);

  const handleChange = useCallback(
    (field: K) =>
      (value: T[K]): void => {
        setFieldValue(field, value);
      },
    [setFieldValue],
  );

  const formHelpers = useMemo(
    (): FormSetters<T, K, U> => ({
      handleChange,
      setStatus,
      setFieldError,
      setFieldValue,
      setArrayFieldValue,
      setFieldValues,
      setErrors,
      setSubmitting,
    }),
    [
      setFieldValues,
      setArrayFieldValue,
      handleChange,
      setStatus,
      setFieldError,
      setFieldValue,
      setErrors,
      setSubmitting,
    ],
  );

  const submit = useCallback(async () => {
    dispatch({ type: "SUBMIT" });
    if (validate !== undefined) {
      const nextErrors = validate(values);

      if (isEmpty(nextErrors) === false) {
        setErrors(nextErrors);
        return;
      }
    }

    dispatch({ type: "SET_SUBMITTING", submitting: true });
    await onSubmit(values, formHelpers);
    dispatch({ type: "SET_SUBMITTING", submitting: false });
  }, [onSubmit, validate, setErrors, values, formHelpers]);

  const reset = useCallback(() => {
    dispatch({ type: "RESET", initialValues });
  }, [initialValues]);

  return {
    setArrayFieldValue,
    setFieldValues,
    handleChange,
    setFieldValue,
    setFieldError,
    setErrors,
    setSubmitting,
    setStatus,
    submit,
    reset,
    values,
    errors,
    changed,
    submitting,
    status,
    validate: handleValidate,
    submitCount,
    isDirty,
  };
}
