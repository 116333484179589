import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useConfig } from "./config";
import { useTeamsStripeMigrationFeatureFlag } from "./splitio";

interface StripeProps {
  children?: React.ReactNode;
}

export function StripeProvider(props: StripeProps) {
  const { children } = props;
  const config = useConfig();
  const [stripe, setStripe] = useState<Stripe | null>();
  const teamsStripeMigrationFeatureFlag = useTeamsStripeMigrationFeatureFlag();
  useEffect(() => {
    const publishableKey = teamsStripeMigrationFeatureFlag
      ? config.teamsStripePublishableKey
      : config.stripePublishableKey;
    loadStripe(publishableKey).then(setStripe);
  }, [
    config.stripePublishableKey,
    config.teamsStripePublishableKey,
    teamsStripeMigrationFeatureFlag,
  ]);

  if (!stripe) {
    return null;
  }

  return <Elements stripe={stripe}>{children}</Elements>;
}
