import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";
import { colors } from "../../../components/colors";
import { BookingDetailsDesktopComponents } from "./booking_details_content_desktop";

export interface BookingDetailsMobileComponents
  extends BookingDetailsDesktopComponents {
  action?: JSX.Element;
}

interface BookingDetailsContentMobileProps {
  components: BookingDetailsMobileComponents;
}

export function BookingDetailsContentMobile({
  components,
}: BookingDetailsContentMobileProps) {
  const {
    header,
    dateRangeBar,
    images,
    inviteOrShare,
    aboutSpace,
    preferred,
    amenties,
    priceBreakdown,
    location,
    map,
    directions,
    hours,
    action,
  } = components;

  return (
    <View>
      {header}
      <Spacer size={16} />
      {images}
      {inviteOrShare && (
        <>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          {inviteOrShare}
        </>
      )}
      {aboutSpace && (
        <>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          {aboutSpace}
        </>
      )}
      {preferred && (
        <>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <View style={{ marginHorizontal: -24 }}>{preferred}</View>
        </>
      )}
      {amenties && (
        <>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          {amenties}
        </>
      )}
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      {location}
      <Spacer size={16} />
      {map}
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      {directions}
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      {priceBreakdown}
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      {hours}
      <Spacer size={70} />
      <View style={styles.fixedBottom}>
        {dateRangeBar}
        {action ? <View style={styles.actionView}>{action}</View> : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  fixedBottom: {
    // @ts-ignore
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.background.main,
  },
  actionView: {
    padding: 16,
  },
});
