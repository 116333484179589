import { SIGN_UP_STEPS_REDIRECT_URL_KEY } from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import { useCallback, useEffect, useState } from "react";
import { SignUpCredentials } from "../sign_up";
import { useHistory } from "react-router-dom";
import { StepCodeUI } from "components/sign_up_shared/step_code_ui";

interface SignUpStepCodeProps {
  onGoNext: () => void;
  onGoBack: () => void;
  credentials: SignUpCredentials;
}

export function SignUpStepCode(props: SignUpStepCodeProps) {
  const { credentials } = props;
  const { verifyPasswordless, startPasswordless } = useAuthV2();
  const history = useHistory();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error && code.length < 6) {
      setError("");
    }
  }, [code.length, error]);

  useEffect(() => {
    if (!credentials.email) {
      history.replace("/sign-up/get-started");
    }
  }, [credentials.email, history]);

  const onSubmit = useCallback(
    (code) => {
      setLoading(true);
      localStorage.setItem(SIGN_UP_STEPS_REDIRECT_URL_KEY, "/sign-up/password");
      verifyPasswordless(credentials.email, code)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setError(
            "This code is invalid or expired. Try again or resend the code.",
          );
          setLoading(false);
        });
    },
    [verifyPasswordless, credentials],
  );

  const resendCode = () => startPasswordless(credentials.email);

  return (
    <StepCodeUI
      error={error}
      code={code}
      onSubmit={onSubmit}
      setCode={setCode}
      credentials={credentials}
      resendCode={resendCode}
      loading={loading}
    />
  );
}
