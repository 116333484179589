import { Pressable, StyleSheet, View } from "react-native";
import { Icon } from "components/icon";
import { Icon as IconV2 } from "components/iconv2";
import { SelectInput } from "components/select/select_input";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { Fragment, useCallback, useEffect, useState } from "react";
import { GridV2 } from "components/gridv2";
import {
  GroupBudgetOverviewCard,
  GroupBudgetOverviewCardProps,
} from "pages/budgets/group_budget/components/group_budget_overview_card";
import { ErrorBoundary } from "core/error_boundary";
import { GroupBudget } from "core/graphql.generated";
import { useHistory } from "react-router-dom";
import { useDebounce } from "hooks/use_debounce";
import { useAnalytics } from "providers/analytics";

const sortableBy = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "limit",
    label: "Limit",
  },
  {
    value: "spend",
    label: "Spent",
  },
];

export const searchSortBudgets = (
  budgets: GroupBudget[],
  currentSort: keyof GroupBudget,
  searchInput: string,
) => {
  let result = [...budgets];

  if (searchInput) {
    result = budgets.filter((budget: GroupBudget) =>
      budget.name.toLowerCase().includes(searchInput.toLowerCase()),
    );
  }

  // todo inactive always at the bottom

  if (currentSort) {
    result = result.sort((budget1: GroupBudget, budget2: GroupBudget) => {
      // if sortable property is a string,
      // lowercase it before comparing to preserve proper order
      if (typeof budget1[currentSort] === "number") {
        return budget1[currentSort]! < budget2[currentSort]! ? 1 : -1;
      }

      return String(budget1[currentSort]).toLowerCase() >
        String(budget2[currentSort]).toLowerCase()
        ? 1
        : -1;
    });
  }

  return result;
};

export const GroupBudgetsList = ({
  groupBudgets,
}: {
  groupBudgets: GroupBudget[];
}) => {
  const [search, setSearch] = useState("");
  const analytics = useAnalytics();
  const debouncedSearch = useDebounce(search, 500);
  const [currentSort, setCurrentSort] = useState<string | undefined>("name");
  const mq = useMediaQuery();
  const history = useHistory();
  const isMobile = mq.deviceQuery.mobile;

  const createGroupBudget = useCallback(() => {
    history.push("/admin/budgets/group-budget/new");
  }, [history]);

  useEffect(() => {
    if (debouncedSearch) {
      analytics.event("Search Group Budgets", {
        Term: debouncedSearch,
      });
    }
  }, [analytics, debouncedSearch]);

  return (
    <>
      <View style={styles.tableHeader}>
        <View
          style={[
            styles.searchInputWrapper,
            isMobile && styles.searchInputWrapperMobile,
          ]}
        >
          <TextField
            leftIcon={<Icon name="magnifying-glass" />}
            inputPlaceholder="Search by budget name"
            onChange={setSearch}
            testID="group-budget-search-input"
            value={search}
          />
        </View>

        {!isMobile ? (
          <View style={styles.sortBy}>
            <Spacer direction="row" size={40} />
            <Text size="xs">Sort by</Text>
            <Spacer direction="row" size={8} />
            <SelectInput
              options={sortableBy}
              onChange={(option) => {
                setCurrentSort(option);
                analytics.event("Sort Group Budgets", {
                  Sort: option,
                });
              }}
              value={currentSort}
              contentSameWidth={false}
              contentSameMinWidth
              appearance="outlined"
              testID="group-budget-sort-input"
            />
          </View>
        ) : (
          <Fragment>
            <Spacer size={16} direction="row" />
            <Pressable
              onPress={createGroupBudget}
              testID="create-new-group-budget-button"
            >
              <IconV2 size="lg" name="plus" color="eggplantcore" filled />
            </Pressable>
          </Fragment>
        )}
      </View>
      <Spacer size={24} />
      <GridV2
        columns={mq.deviceQuery.mobile ? 1 : 3}
        columnGap={24}
        rowGap={mq.deviceQuery.mobile ? 16 : 24}
      >
        {searchSortBudgets(
          groupBudgets,
          currentSort as keyof GroupBudget,
          search,
        ).map((gB, i) => (
          <ErrorBoundary>
            <Pressable
              testID={`group-budget-card`}
              onPress={() => history.push(`/admin/budgets/${gB.id}`)}
            >
              <GroupBudgetOverviewCard
                key={`${gB.name}-${i}`}
                name={gB.name}
                color={gB.color as GroupBudgetOverviewCardProps["color"]}
                limit={gB.limit}
                spend={gB.spend}
                remaining={gB.remaining}
                status={gB.status}
              />
            </Pressable>
          </ErrorBoundary>
        ))}
      </GridV2>
    </>
  );
};

const styles = StyleSheet.create({
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-between",

    alignItems: "center",
  },
  searchInputWrapper: {
    flexGrow: 1,
  },
  searchInputWrapperMobile: {
    flex: 1,
  },
  sortBy: {
    flexDirection: "row",
    alignItems: "center",
  },
});
