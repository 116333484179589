import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Heading } from "components/heading";
import * as renderComponents from "components/month_view/components/components";
import { MonthView } from "components/month_view/month_view";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { TimeRangePicker } from "components/time_range_picker/time_range_picker";
import { addMonths, eachMonthOfInterval, format } from "date-fns";
import { formatISODate } from "lib/date_utils";
import { useMediaQuery } from "lib/media_query";
import { formatPrice } from "pages/homev2/components/space_card_v2/space_card_template";
import { usePriceDiscoverabilityFeatureFlag } from "providers/splitio";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import {
  BookingDateRangePickerProps,
  DailyBookingDateRangePickerProps,
  DatePickerDisplayProps,
  DatePickerProps,
  DateRangePickerProps,
  HourlyBookingDateRangePickerProps,
  HourlyDateRange,
  TimeSlot,
  useDailyBookingDateRangePickerHelpers,
  useHourlyBookingDateRangePickerHelpers,
  useSelectDayDateRangeHandler,
} from "./booking_date_range_picker";
import { BookingType, PricingType } from "./graphql.generated";

export function BookingDateRangePickerMobile(
  props: BookingDateRangePickerProps,
) {
  const {
    onChange,
    value,
    bookingType = BookingType.HourlyBooking,
    onChangeBookingType,
    timeslots,
    showSegmentedControl = true,
    multiDayBookingAllowed = true,
    isBlockedDate,
    isOutsideRange,
    pricings,
    dynamicPrices,
    currency,
    spaceId,
    priceRanges,
    onChangeBetterRateTimeSlots,
  } = props;

  const _isBlockedDate = useCallback(
    (date: Date, _bookingType: BookingType) => {
      if (isBlockedDate !== undefined) {
        return isBlockedDate(formatISODate(date), _bookingType);
      }

      return false;
    },
    [isBlockedDate],
  );
  const _isOutsideRange = useCallback(
    (date: Date) => {
      if (isOutsideRange !== undefined) {
        return isOutsideRange(formatISODate(date));
      }

      return false;
    },
    [isOutsideRange],
  );

  const getBasePrice = (pricingType: PricingType) => {
    const price = pricings.find((p) => p.type === pricingType)?.price;
    return price;
  };

  const hasHourPriceRange = priceRanges?.hourly.max !== priceRanges?.hourly.min;
  const showPriceInHourPicker = hasHourPriceRange || dynamicPrices.length;

  return (
    <Container>
      {bookingType === BookingType.HourlyBooking && (
        <HourlyBookingDateRangePickerMobile
          showSegmentedControl={showSegmentedControl}
          onChangeBookingType={onChangeBookingType}
          value={value?.type === BookingType.HourlyBooking ? value : undefined}
          onChange={onChange}
          timeslots={timeslots}
          isBlockedDate={_isBlockedDate}
          isOutsideRange={_isOutsideRange}
          basePrice={
            showPriceInHourPicker ? getBasePrice(PricingType.Hourly) : undefined
          }
          dynamicPrices={showPriceInHourPicker ? dynamicPrices : undefined}
          spaceId={spaceId}
          currency={currency}
          onChangeBetterRateTimeSlots={onChangeBetterRateTimeSlots}
          withDaysOfTheWeek={false}
        />
      )}
      {bookingType === BookingType.DailyBooking && (
        <DailyBookingDateRangePickerMobile
          showSegmentedControl={showSegmentedControl}
          multiDayBookingAllowed={multiDayBookingAllowed}
          onChangeBookingType={onChangeBookingType}
          value={value?.type === BookingType.DailyBooking ? value : undefined}
          onChange={onChange}
          isBlockedDate={_isBlockedDate}
          isOutsideRange={_isOutsideRange}
          basePrice={
            dynamicPrices.length ? getBasePrice(PricingType.Daily) : undefined
          }
          dynamicPrices={dynamicPrices.length ? dynamicPrices : undefined}
          currency={currency}
          withDaysOfTheWeek={false}
        />
      )}
    </Container>
  );
}

function HourlyBookingDateRangePickerMobile(
  props: HourlyBookingDateRangePickerProps,
) {
  const {
    value,
    isBlockedDate,
    isOutsideRange,
    onChange,
    basePrice,
    dynamicPrices,
    spaceId,
    currency,
    timeslots,
    withDaysOfTheWeek,
    onChangeBetterRateTimeSlots,
  } = props;
  const { date, handleDateChange, betterRateTimeSlots, betterDynamicPrices } =
    useHourlyBookingDateRangePickerHelpers({
      value,
      onChange,
      spaceId,
      timeSlots: timeslots,
      dynamicPrices,
      basePrice,
    });

  useEffect(() => {
    if (betterRateTimeSlots.length) {
      onChangeBetterRateTimeSlots?.(betterRateTimeSlots);
    }
  }, [betterRateTimeSlots, onChangeBetterRateTimeSlots]);

  const priceWithCurrency = basePrice ? formatPrice(basePrice, currency) : "";
  const dynamicPricesWithCurrency = betterDynamicPrices?.map(
    ({ date, price }) => ({
      date,
      price: formatPrice(price, currency),
      isDiscountRate: basePrice ? price < basePrice : false,
    }),
  );

  const handlePickerChangeDate = (date: Date) => {
    const unitPrice =
      dynamicPrices?.find((p) => p.date === format(date, "yyyy-MM-dd"))
        ?.price || basePrice;
    handleDateChange(date, unitPrice);
    onChangeBetterRateTimeSlots?.([]);
  };

  return (
    <DatePicker
      isBlocked={(d) =>
        isBlockedDate ? isBlockedDate(d, BookingType.HourlyBooking) : false
      }
      value={date}
      onChange={handlePickerChangeDate}
      isOutsideRange={isOutsideRange}
      basePrice={priceWithCurrency}
      dynamicPrices={dynamicPricesWithCurrency}
      withDaysOfTheWeek={withDaysOfTheWeek}
    />
  );
}

function DailyBookingDateRangePickerMobile(
  props: DailyBookingDateRangePickerProps,
) {
  const {
    value,
    onChange,
    isBlockedDate,
    isOutsideRange,
    basePrice,
    dynamicPrices,
    currency,
    withDaysOfTheWeek,
  } = props;

  const { dateRange, handleDateRangeChange } =
    useDailyBookingDateRangePickerHelpers({ value, onChange });

  const priceWithCurrency = basePrice ? formatPrice(basePrice, currency) : "";
  const dynamicPricesWithCurrency = dynamicPrices?.map(({ date, price }) => ({
    date,
    price: formatPrice(price, currency),
    isDiscountRate: basePrice ? price < basePrice : false,
  }));

  return (
    <Container>
      <DateRangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        isOutsideRange={isOutsideRange}
        isBlocked={(date) =>
          isBlockedDate ? isBlockedDate(date, BookingType.DailyBooking) : false
        }
        basePrice={priceWithCurrency}
        dynamicPrices={dynamicPricesWithCurrency}
        withDaysOfTheWeek={withDaysOfTheWeek}
      />
    </Container>
  );
}

function DatePicker(props: DatePickerProps) {
  const {
    value,
    onChange,
    isOutsideRange,
    isBlocked,
    basePrice,
    dynamicPrices,
    withDaysOfTheWeek,
  } = props;

  return (
    <DatePickerDisplay
      selected={value ? { start: value, end: value } : null}
      onSelectDay={onChange}
      isOutsideRange={isOutsideRange}
      isBlocked={isBlocked}
      basePrice={basePrice}
      dynamicPrices={dynamicPrices}
      withDaysOfTheWeek={withDaysOfTheWeek}
    />
  );
}

function DateRangePicker(props: DateRangePickerProps) {
  const {
    value,
    onChange,
    isOutsideRange,
    isBlocked,
    basePrice,
    dynamicPrices,
    withDaysOfTheWeek,
  } = props;

  return (
    <DatePickerDisplay
      selected={value}
      onSelectDay={useSelectDayDateRangeHandler({ value, onChange, isBlocked })}
      isOutsideRange={isOutsideRange}
      isBlocked={isBlocked}
      basePrice={basePrice}
      dynamicPrices={dynamicPrices}
      withDaysOfTheWeek={withDaysOfTheWeek}
    />
  );
}

function DatePickerDisplay(props: DatePickerDisplayProps) {
  const {
    selected,
    onSelectDay,
    isOutsideRange,
    isBlocked,
    basePrice,
    dynamicPrices,
    withDaysOfTheWeek,
  } = props;
  const priceDiscoverabilityFlag = usePriceDiscoverabilityFeatureFlag();
  const mq = useMediaQuery();

  const [startMonth] = useState(selected ? selected.start : new Date());
  const [monthsCount, setMonthsCount] = useState(2);

  const handlePressMore = useCallback(() => {
    setMonthsCount(monthsCount + 3);
  }, [monthsCount]);

  const months = eachMonthOfInterval({
    start: startMonth,
    end: addMonths(startMonth, monthsCount),
  });

  return (
    <Container>
      {months.map((month) => (
        <Container key={formatISODate(month)}>
          <Spacer size={24} />
          <Heading align={mq.deviceQuery.mobile ? "left" : "center"} size="h3">
            {format(month, mq.deviceQuery.mobile ? "MMMM yyyy" : "MMM yyyy")}
          </Heading>
          <Spacer size={8} />
          <MonthView
            selectedInterval={selected}
            month={month}
            onSelectDay={onSelectDay}
            isOutsideRange={isOutsideRange}
            isBlocked={isBlocked}
            basePrice={basePrice}
            dynamicPrices={dynamicPrices}
            withDaysOfWeek={withDaysOfTheWeek}
            renderDay={
              priceDiscoverabilityFlag
                ? renderComponents.renderDayV2
                : renderComponents.renderDay
            }
          />
        </Container>
      ))}
      <Spacer size={24} />
      <Container paddingHorizontal={24}>
        <Button
          appearance="secondary"
          text="More dates"
          onPress={handlePressMore}
          testID="picker-next-month-button"
        />
      </Container>
    </Container>
  );
}

interface BookingTimeRangePickerProps {
  value?: HourlyDateRange;
  onChange: (value: HourlyDateRange) => void;
  timeslots: TimeSlot[];
  spaceId: string;
  basePrice?: number;
}

export function BookingTimeRangePicker(props: BookingTimeRangePickerProps) {
  const { value, onChange, timeslots, spaceId, basePrice } = props;
  const { handleTimeRangeChange, start, end, recommendation } =
    useHourlyBookingDateRangePickerHelpers({
      value,
      onChange,
      spaceId,
      timeSlots: timeslots,
      basePrice,
    });

  return (
    <View>
      {recommendation && (
        <View>
          <Spacer size={8} />
          <Text
            key={recommendation}
            size="xs"
            color="eggplant-core"
            align="center"
          >
            {recommendation}
          </Text>
          <Spacer size={16} />
        </View>
      )}
      <TimeRangePicker
        value={{ start, end }}
        onChange={handleTimeRangeChange}
        timeslots={timeslots}
        disabled={!value?.date || !timeslots.length}
      />
    </View>
  );
}
