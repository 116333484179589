import { colors } from "components/colors";
import {
  RenderDayOfWeekProps,
  RenderDayProps,
  RenderOtherMonthProps,
  RenderWeekProps,
} from "components/month_renderer";
import { format } from "date-fns";
import React, { Fragment } from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { Text } from "../../text_v2";

interface DayViewProps {
  day: Date;
  withinSelected: boolean;
  selected: boolean;
  selectedStart: boolean;
  selectedEnd: boolean;
  today: boolean;
  outsideRange: boolean;
  blocked: boolean;
  onSelect: (day: Date) => void;
  basePrice?: string;
  dynamicPrice?: string;
  isDiscountRate?: boolean;
}

export function DayView(props: DayViewProps) {
  const {
    day,
    withinSelected,
    selected,
    selectedStart,
    selectedEnd,
    today,
    outsideRange,
    blocked,
    onSelect,
  } = props;

  if (outsideRange || blocked) {
    return (
      <View style={styles.dayRoot}>
        <View style={styles.dayWrapper}>
          <Text decoration="line-through" color="black-50">
            {format(day, "d")}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.dayRoot, withinSelected && styles.withinSelected]}>
      <Pressable
        testID="date-picker-day-button"
        onPress={() => onSelect(day)}
        style={styles.dayButton}
      >
        {({ hovered }: { hovered: boolean }) => (
          <Fragment>
            {selectedStart && !selectedEnd && (
              <View style={[styles.selectedEdge, styles.selectedStart]} />
            )}
            {selectedEnd && !selectedStart && (
              <View style={[styles.selectedEdge, styles.selectedEnd]} />
            )}
            <View
              style={[
                styles.dayWrapper,
                hovered && styles.hoveredDay,
                selected && styles.selectedDay,
              ]}
            >
              <Text color={selected ? "white-core" : "black-core"}>
                {format(day, "d")}
              </Text>
              {today && <View style={styles.todayMarker} />}
            </View>
          </Fragment>
        )}
      </Pressable>
    </View>
  );
}

export function DayViewV2(props: DayViewProps) {
  const {
    day,
    withinSelected,
    selected,
    selectedStart,
    selectedEnd,
    outsideRange,
    blocked,
    onSelect,
    basePrice,
    dynamicPrice,
    isDiscountRate,
  } = props;

  if (outsideRange || blocked) {
    return (
      <View style={styles.dayRoot}>
        <View style={styles.dayWrapperV2}>
          <Text color="black-50" size="sm">
            {format(day, "d")}
          </Text>
        </View>
      </View>
    );
  }

  let priceElement: JSX.Element;
  if (dynamicPrice) {
    priceElement = (
      <Text
        size="nano"
        color={
          selected
            ? "white-core"
            : isDiscountRate
            ? "eggplant-core"
            : "black-core"
        }
      >
        {dynamicPrice}
      </Text>
    );
  } else if (basePrice) {
    priceElement = (
      <Text size="nano" color={selected ? "white-core" : "black-core"}>
        {basePrice}
      </Text>
    );
  }

  return (
    <View style={[styles.dayRoot, withinSelected && styles.withinSelected]}>
      <Pressable
        testID="date-picker-day-button"
        onPress={() => onSelect(day)}
        style={styles.dayButton}
      >
        {({ hovered }: { hovered: boolean }) => (
          <Fragment>
            {selectedStart && !selectedEnd && (
              <View style={[styles.selectedEdge, styles.selectedStart]} />
            )}
            {selectedEnd && !selectedStart && (
              <View style={[styles.selectedEdge, styles.selectedEnd]} />
            )}
            <View
              style={[
                styles.dayWrapperV2,
                hovered && styles.hoveredDay,
                selected && styles.selectedDay,
              ]}
            >
              <Text
                color={selected ? "white-core" : "black-core"}
                size="sm"
                weight="semibold"
              >
                {format(day, "d")}
              </Text>
              {priceElement}
            </View>
          </Fragment>
        )}
      </Pressable>
    </View>
  );
}

export function renderDayV2(p: RenderDayProps, onSelect: (day: Date) => void) {
  return <DayViewV2 {...p} onSelect={onSelect} />;
}

export function renderDay(p: RenderDayProps, onSelect: (day: Date) => void) {
  return <DayView {...p} onSelect={onSelect} />;
}

interface OtherMonthDayProps {
  withinSelected: boolean;
  day: Date;
}

export function OtherMonthDay(props: OtherMonthDayProps) {
  const { withinSelected } = props;

  return (
    <View style={[styles.dayRoot, withinSelected && styles.withinSelected]} />
  );
}
export function renderOtherMonthDay(p: RenderOtherMonthProps) {
  return <OtherMonthDay {...p} />;
}

interface DayOfweekProps {
  date: Date;
}

export function DayOfWeek(props: DayOfweekProps) {
  const { date } = props;

  return <Text>{format(date, "EEEEEE")}</Text>;
}

export function DayOfWeekV2(props: DayOfweekProps) {
  const { date } = props;

  return (
    <Text size={"micro"} color={"black-70"}>
      {format(date, "EEE").toUpperCase()}
    </Text>
  );
}

export function renderDayOfWeekV2(p: RenderDayOfWeekProps) {
  return <DayOfWeekV2 {...p} />;
}

export function renderDayOfWeek(p: RenderDayOfWeekProps) {
  return <DayOfWeek {...p} />;
}

interface WeekProps {
  children: React.ReactNode;
}

export function Week(props: WeekProps) {
  const { children } = props;

  return <View style={styles.week}>{children}</View>;
}

export function renderWeek(p: RenderWeekProps) {
  return <Week {...p} />;
}

const styles = StyleSheet.create({
  dayRoot: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dayButton: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    padding: 0,
  },
  dayWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 999,
    justifyContent: "center",
    textAlign: "center",
    height: 40,
    width: 40,
  },
  dayWrapperV2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 999,
    justifyContent: "center",
    textAlign: "center",
    height: 40,
    width: 40,
  },
  withinSelected: {
    backgroundColor: "rgba(82,68,134,0.15)",
  },
  todayMarker: {
    position: "absolute",
    bottom: 2,
    width: 4,
    height: 4,
    paddingTop: 4,
    borderRadius: 999,
    backgroundColor: "black",
  },
  selectedEdge: {
    backgroundColor: "rgba(82,68,134,0.15)",
    position: "absolute",
    zIndex: -1,
    width: "50%",
    right: 0,
    height: "100%",
  },
  selectedStart: {
    right: 0,
  },
  selectedEnd: {
    left: 0,
  },
  week: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  selectedDay: {
    backgroundColor: colors.brand.eggplantcore,
  },
  hoveredDay: {
    borderWidth: 1,
    borderColor: colors.brand.eggplantcore,
  },
});
