import { isAfter, isSameDay } from "date-fns";

import { OrderStatus } from "core/graphql.generated";
import { parseDay } from "lib/day_utils";

export function isArchivedBooking(startDate: string, status: OrderStatus) {
  return (
    (isAfter(new Date(), parseDay(startDate)) &&
      !isSameDay(new Date(), parseDay(startDate))) ||
    status === OrderStatus.Canceled
  );
}
