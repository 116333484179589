import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import { Fragment, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

export function CreateEditBudgetLayout({
  header,
  children,
}: {
  header: ReactNode;
  children: ReactNode;
}) {
  const mq = useMediaQuery();

  return (
    <View style={styles.layout} testID="edit-budget-layout">
      {!!header && (
        <Fragment>
          <Spacer size={40} />
          {header}
          <Spacer size={19} />
          <Divider color="lighter" />
          <Spacer size={16} />
        </Fragment>
      )}
      <View
        testID="edit-budget-layout-content"
        style={[
          styles.layoutContent,
          mq.deviceQuery.mobile && styles.layoutContentMobile,
        ]}
      >
        {children}
      </View>
    </View>
  );
}

export function CreateEditBudgetFormColumn({
  children,
}: {
  children: ReactNode;
}) {
  const mq = useMediaQuery();

  return (
    <View
      style={[styles.formCol, mq.deviceQuery.mobile && styles.formColMobile]}
    >
      {children}
    </View>
  );
}

export function CreateEditBudgetSummaryColumn({
  children,
}: {
  children: ReactNode;
}) {
  const mq = useMediaQuery();

  return (
    <View
      style={[
        styles.summaryCol,
        mq.deviceQuery.mobile && styles.summaryColMobile,
      ]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  layout: {
    flexShrink: 1,
  },
  layoutContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexShrink: 1,
    overflow: "scroll",
    marginHorizontal: -30,
    paddingHorizontal: 30,
  },
  layoutContentMobile: {
    flexDirection: "column",
  },
  formCol: {
    flexBasis: "64%",
  },
  formColMobile: {
    flexBasis: "initial",
  },
  summaryCol: {
    flexBasis: "33%",
    // @ts-ignore
    position: "sticky",
    top: 0,
  },
  summaryColMobile: {
    position: "relative",
    flexBasis: "initial",
  },
});
