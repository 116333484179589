import { useCallback, useState } from "react";
import { FilterDatePickerDesktop as FilterDatePickerDesktopUI } from "core/filter_date_picker_desktop";
import { formatDate } from "lib/date_utils";
import { Day, parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/icon";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { colors } from "components/colors";

interface DatePickerDesktopProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
}

export function EmptySpacesDatePicker(props: DatePickerDesktopProps) {
  const { value, onChange } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleSave = useCallback(
    (v: Day) => {
      setOpen(false);
      onChange(v);
    },
    [onChange],
  );

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      placement="bottom-end"
      sameWidth={false}
      offsetVertical={8}
      content={
        <View style={styles.dialogWrapper}>
          <View style={styles.dialogHeader}>
            <Pressable onPress={handleClose}>
              <Icon name="x-circle" />
            </Pressable>
            <Text weight="bold" size="md">
              Date
            </Text>
            <Pressable onPress={() => onChange("")}>
              <Text color="eggplant-core">Clear</Text>
            </Pressable>
          </View>

          <Divider />

          <View style={styles.dialogContent}>
            <FilterDatePickerDesktopUI value={value} onChange={handleSave} />
          </View>
        </View>
      }
    >
      <View style={styles.wrapper}>
        <Text size="xs" weight="semibold">
          Date
        </Text>
        <Spacer size={4} />
        <Pressable style={styles.button} onPress={handleOpen}>
          <View style={styles.picker}>
            <Text size="xs">
              {value
                ? formatDate(parseDay(value), getSystemLocale(), {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : "Any date"}
            </Text>
            <Icon name={open ? "feather-chevron-up" : "feather-chevron-down"} />
          </View>
        </Pressable>
      </View>
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dialogHeader: {
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogWrapper: {
    width: 400,
    ...tokens.shadow.elevation2,
    backgroundColor: tokens.colors.base.white,
    borderRadius: 16,
  },
  dialogContent: {
    flex: 1,
    padding: 24,
  },
  wrapper: {
    display: "flex",
  },
  button: {
    padding: 8,
    backgroundColor: colors.brand.whitecore,
  },
  picker: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
