import { DateRange } from "core/booking_date_range_picker";
import { BookingType } from "core/graphql.generated";

export function validateDateRange(value?: DateRange) {
  let err = "";

  if (value?.type === BookingType.DailyBooking) {
    if (!value.startDate || !value.endDate) {
      err = "Please select valid dates";
    }
  } else if (value?.type === BookingType.HourlyBooking) {
    if (!value.date) {
      err = "Please select date";
    } else if (!value.startTime || !value.endTime) {
      err = "Please select time";
    }
  }

  return err;
}
