import { useHomeSearchParamsQuery } from "./use_home_search_params";
import { useReverseGeocodingQuery } from "./use_reserve_geocoding";

export function useHomeSearchParamsPlaceQuery() {
  const searchParams = useHomeSearchParamsQuery();

  const result = useReverseGeocodingQuery(searchParams.coordinates);

  return result;
}
