import { ApolloError, gql, useQuery } from "@apollo/client";
import {
  BookingSpaceDetailFragment,
  GetSpaceNearByQuery,
  GetSpaceNearByQueryVariables,
  SpacesSort,
  SpaceType,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { useInitialPlace } from "pages/homev2/hooks/use_initial_place";
import {
  bookingSpaceDetailFragment,
  spaceLocationDetailFragment,
} from "pages/homev3/fragment";
import { useEffect, useMemo, useState } from "react";
import { useUserCurrentLocation } from "./use_user_current_location";
import { toCoordinates } from "../../homev2/mapbox";

interface SpaceNearByQueryData {
  __typename?: "Query";
  spaces: BookingSpaceDetailFragment[];
  cityName?: string;
}
interface SpaceNearByQueryReturn {
  data: SpaceNearByQueryData;
  loading: boolean;
  error: ApolloError | undefined;
}

export type useSpaceNearByQueryProps = {
  location?: {
    lat: number;
    lng: number;
  };
  filter?: any;
  fixedRadius?: number;
  spaceType?: SpaceType;
  spaceLimit?: number;
  locationLimit?: number;
  skip?: boolean;
};

export const MAX_FETCH_MORE_COUNT = 2;
export const RADIUS_INCREASE = 4828; // 3miles
export function useSpaceNearByQuery({
  location,
  filter = {},
  fixedRadius,
  spaceType,
  spaceLimit = 15,
  locationLimit,
  skip = false,
}: useSpaceNearByQueryProps): SpaceNearByQueryReturn {
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useUserCurrentLocation();
  const {
    data: placeData,
    loading: placeLoading,
    initialized,
  } = useInitialPlace();
  const [fetchCount, setFetchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const variables: GetSpaceNearByQueryVariables | null = useMemo(() => {
    if (
      skip ||
      !currentUserCoordinates ||
      currentUserLoading ||
      (!location && (!placeData || placeLoading || !initialized))
    ) {
      return null;
    }
    return {
      centerMapCoordinates: {
        lat: location?.lat || placeData?.geometry.coordinates[1],
        lng: location?.lng || placeData?.geometry.coordinates[0],
      },
      currentUserCoordinates: toCoordinates(currentUserCoordinates.center),
      filter: filter,
      limit: 8000,
      offset: 0,
      spaceType: spaceType || SpaceType.DayPass,
      hasSpaceType: !!spaceType,
      sort: SpacesSort.Distance,
      /*If the radius is not fixed.
      Increase radius based on fetch count, each time 3 miles ~ 4828 meters*/
      radius: fixedRadius || RADIUS_INCREASE * (fetchCount + 1),
    };
  }, [
    currentUserCoordinates,
    currentUserLoading,
    fetchCount,
    filter,
    fixedRadius,
    initialized,
    location,
    placeData,
    placeLoading,
    skip,
    spaceType,
  ]);

  const { data, error } = useQuery<
    GetSpaceNearByQuery,
    GetSpaceNearByQueryVariables
  >(getSpaceNearBy, {
    variables: variables!,
    skip: !variables,
    notifyOnNetworkStatusChange: true,
  });

  const spaces: BookingSpaceDetailFragment[] = useMemo(() => {
    let spaceArray: BookingSpaceDetailFragment[] = [];
    const { spaces, dayPasses, meetingRooms, dayOffices } = data || {};
    if (spaceType) {
      if (spaces && spaces.data.length > 0) {
        spaceArray.push(...spaces.data);
      }
    } else {
      if (dayPasses && dayPasses.data.length > 0) {
        spaceArray.push(...dayPasses.data);
      }
      if (meetingRooms && meetingRooms.data.length > 0) {
        spaceArray.push(...meetingRooms.data);
      }
      if (dayOffices && dayOffices.data.length > 0) {
        spaceArray.push(...dayOffices.data);
      }
    }
    return spaceArray;
  }, [data, spaceType]);

  useEffect(() => {
    if (data) {
      const groupedByLocation = groupBy(spaces, (s) => s.location.id);
      const isLimitReached = locationLimit
        ? Object.values(groupedByLocation).length <= locationLimit
        : spaces.length <= spaceLimit;
      if (!fixedRadius && isLimitReached && fetchCount < MAX_FETCH_MORE_COUNT) {
        setFetchCount((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
      }
    }
    if (error) {
      setIsLoading(false);
    }
  }, [
    data,
    fetchCount,
    fixedRadius,
    spaceType,
    spaces,
    spaces.length,
    locationLimit,
    spaceLimit,
    error,
  ]);

  return useMemo(
    () => ({
      data: {
        __typename: "Query",
        spaces,
        cityName: placeData?.text,
      },
      loading: isLoading,
      error,
    }),
    [error, isLoading, placeData?.text, spaces],
  );
}

const getSpaceNearBy = gql`
  ${spaceLocationDetailFragment}
  ${bookingSpaceDetailFragment}
  query GetSpaceNearBy(
    $spaceType: SpaceType!
    $hasSpaceType: Boolean!
    $filter: SpacesFilter
    $centerMapCoordinates: SpaceSearchCoordinates
    $currentUserCoordinates: SpaceSearchCoordinates
    $limit: Int
    $offset: Int
    $radius: Int
    $sort: SpacesSort
  ) {
    spaces: spaces(
      spaceType: $spaceType
      filter: $filter
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      offset: $offset
      radius: $radius
      sort: $sort
    ) @include(if: $hasSpaceType) {
      data {
        ...BookingSpaceDetail
      }
    }
    dayPasses: spaces(
      spaceType: DayPass
      filter: $filter
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      offset: $offset
      radius: $radius
    ) @skip(if: $hasSpaceType) {
      data {
        ...BookingSpaceDetail
      }
    }
    meetingRooms: spaces(
      spaceType: MeetingRoom
      filter: $filter
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      offset: $offset
      radius: $radius
    ) @skip(if: $hasSpaceType) {
      data {
        ...BookingSpaceDetail
      }
    }
    dayOffices: spaces(
      spaceType: DayOffice
      filter: $filter
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      offset: $offset
      radius: $radius
    ) @skip(if: $hasSpaceType) {
      data {
        ...BookingSpaceDetail
      }
    }
  }
`;
