import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";
import { Avatar } from "components/avatar";
import { Badge } from "components/badge";

import { Button } from "components/button_v2";
import { Column } from "components/column";
import { Container } from "components/container";
import { Content } from "components/content";
import { Dialog } from "components/dialog";
import { Divider } from "components/divider";
import { Heading } from "components/heading";
import { Icon } from "components/icon";
import { PageContainer } from "components/page_container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { CheckinButton } from "core/checkin_button";
import { DialogContent } from "core/dialog_content";
import { Footer } from "core/footer";
import {
  BookOfficeSpaceOnDayMutation,
  BookOfficeSpaceOnDayMutationVariables,
  CheckinOfficeSpaceTodayMutation,
  CheckinOfficeSpaceTodayMutationVariables,
  OfficePage__OfficeSpaceBookingDetailsFragment,
  OfficePage__OfficeSpaceCheckinDetailsFragment,
  OfficePage__OfficeSpaceDetailsFragment,
  OfficePageBookingsQuery,
  OfficePageBookingsQueryVariables,
  OfficePageDetailsCurrentUserQuery,
  OfficePageDetailsQuery,
  OfficePageDetailsQueryVariables,
  OfficeSpace,
  UnbookOfficeSpaceMutation,
  UnbookOfficeSpaceMutationVariables,
} from "core/graphql.generated";
import { ImageGalleryDesktop, ImageGalleryMobile } from "core/image_gallery";
import { officeCheckinAndBookingPeople, People } from "core/people";
import { ResponsiveGrid } from "core/responsive_grid";
import { useGoBack } from "hooks/use_go_back";
import { groupBy } from "lib/array_utils";
import {
  addDays,
  DayInterval,
  formatDayWithTodayAndTomorrowLabel,
  getDays,
  getLocalToday,
  isBefore,
  parseDay,
} from "lib/day_utils";
import { isEmpty } from "lib/lang_utils";
import { useMediaQuery } from "lib/media_query";
import { DEFAULT_FIRST_DAY_OF_WEEK, FirstDayOfWeek } from "lib/week_utils";
import { useToast } from "providers/toast";
import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { ActivityIndicator, Pressable, ScrollView, View } from "react-native";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import "./office_space.css";

interface OfficeSpacePageParams {
  officeSpaceID: string;
}

export const OfficeSpacePage = function OfficeSpacePage() {
  const { officeSpaceID } = useParams<OfficeSpacePageParams>();
  const goBack = useGoBack();

  const { data: officePageQuery } = useQuery<
    OfficePageDetailsQuery,
    OfficePageDetailsQueryVariables
  >(officePageDetailsGQLQuery, {
    variables: {
      officeSpaceID,
    },
  });

  const officeSpace = officePageQuery?.officeSpace!;

  if (!officeSpace) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <AppHeader />
      <PageContainer>
        <Spacer size={32} />
        <Content>
          <Row>
            <Pressable onPress={goBack}>
              <Icon size="lg" name="arrow-left-circle" />
            </Pressable>
          </Row>
          <Spacer size={8} />
          <Heading size="h1">{officeSpace.content.title}</Heading>
          <Spacer size={16} />
        </Content>
        <OfficeImageGallery officeSpace={officeSpace} />
        <Spacer size={24} />
        <Content>
          {officeSpace.capacity && (
            <Fragment>
              <Row alignItems="center">
                <Icon name="users" />
                <Spacer size={8} direction="row" />
                <Text>{officeSpace.capacity} max</Text>
              </Row>
              <Spacer size={12} />
            </Fragment>
          )}
          <Row>
            <Column flex={1}>
              <div
                className="office-space-description"
                dangerouslySetInnerHTML={{
                  __html: officeSpace.content.description,
                }}
              />
              <Spacer size={24} />
            </Column>
            <Spacer size={16} />
          </Row>
          <OfficeSpaceBookings
            timeZone={officeSpace.office.timeZone}
            officeSpaceID={officeSpaceID}
          />
        </Content>
      </PageContainer>
      <Spacer size={24} />
      <Footer />
    </div>
  );
};

interface OfficeImageGalleryProps {
  officeSpace: OfficePage__OfficeSpaceDetailsFragment;
}

function OfficeImageGallery(props: OfficeImageGalleryProps) {
  const { officeSpace } = props;

  return (
    <div>
      <div style={{ position: "relative", zIndex: 1 }} className="hidden-md">
        <ImageGalleryMobile
          images={officeSpace.content.images}
          name={officeSpace.content.title}
        />
      </div>
      <div style={{ position: "relative", zIndex: 1 }} className="visible-md">
        <Content>
          <ImageGalleryDesktop
            images={officeSpace.content.images}
            name={officeSpace.content.title}
          />
        </Content>
      </div>
    </div>
  );
}

interface OfficeSpaceBookingsProps {
  officeSpaceID: string;
  timeZone: string;
}

function OfficeSpaceBookings(props: OfficeSpaceBookingsProps) {
  const { officeSpaceID, timeZone } = props;
  const { data } = useQuery<OfficePageDetailsCurrentUserQuery>(
    officePageDetailsCurrentUserGQLQuery,
  );
  const toast = useToast();
  const [increment, setIncrement] = useState(1);
  const today = useMemo(() => getLocalToday(timeZone), [timeZone]);
  const dayRange = useMemo(() => {
    return {
      start: today,
      end: addDays(today, increment * 7),
    };
  }, [increment, today]);
  const mq = useMediaQuery();
  const apolloClient = useApolloClient();
  const { data: officePageQuery, refetch } = useQuery<
    OfficePageBookingsQuery,
    OfficePageBookingsQueryVariables
  >(officePageBookingsGQLQuery, {
    variables: {
      officeSpaceID,
      startDate: dayRange.start,
      endDate: dayRange.end,
    },
  });
  const refetchAfterCheckin = useCallback(async () => {
    await apolloClient.refetchQueries({ include: "all" });
  }, [apolloClient]);
  const previousOfficeSpaceRef = useRef<OfficeSpace>();
  const [checkinOfficeSpaceTodayMutation, { loading }] = useMutation<
    CheckinOfficeSpaceTodayMutation,
    CheckinOfficeSpaceTodayMutationVariables
  >(checkinOfficeSpaceTodayGQLMutation, { onCompleted: refetchAfterCheckin });

  const officeSpace = useMemo(() => {
    if (!officePageQuery && previousOfficeSpaceRef.current) {
      return previousOfficeSpaceRef.current;
    }

    if (officePageQuery?.officeSpace) {
      // @ts-ignore
      previousOfficeSpaceRef.current = officePageQuery.officeSpace;
    }

    return officePageQuery?.officeSpace;
  }, [officePageQuery]);

  const bookings = useMemo(() => officeSpace?.bookings || [], [officeSpace]);
  const checkins = useMemo(() => officeSpace?.checkins || [], [officeSpace]);
  const handleCheckin = useCallback(async () => {
    await checkinOfficeSpaceTodayMutation({
      variables: { spaceID: officeSpaceID },
    });
    toast.notify({
      message: "Checked in for today.",
    });
  }, [checkinOfficeSpaceTodayMutation, officeSpaceID, toast]);
  const days = useMemo(() => getDays(dayRange), [dayRange]);
  const bookingsGroupedByDay = useMemo(
    () => groupBy(bookings, (b) => b.day),
    [bookings],
  );
  const fullToday = bookings.length === officeSpace?.capacity;
  const checkedIn = !!checkins.find((c) => c.user.id === data?.currentUser?.id);
  const bookingToday = bookings.find(
    (b) => b.user.id === data?.currentUser?.id && b.day === today,
  );
  const scheduledToday = !!bookingToday;
  const weeks = separateToWeeks(dayRange, 0);

  return (
    <Container>
      {mq.sizeQuery.mdAndUp && (
        <Fragment>
          <Row>
            <CheckinButton
              full={fullToday && !scheduledToday}
              loading={loading}
              checkedIn={checkedIn}
              onPress={handleCheckin}
            />
          </Row>
          <Spacer size={16} />
        </Fragment>
      )}
      <Row alignItems="center" justifyContent="space-between">
        <Heading size="h2">Who's here</Heading>
        {!mq.sizeQuery.mdAndUp && (
          <CheckinButton
            full={fullToday && !scheduledToday}
            loading={loading}
            checkedIn={checkedIn}
            onPress={handleCheckin}
          />
        )}
      </Row>
      <Spacer size={24} />
      {increment > 1 ? (
        weeks.map((days) => (
          <Container>
            <ResponsiveGrid
              horizontalSpacing={48}
              itemsPerRow={mq.sizeQuery.mdAndUp ? 2 : 1}
            >
              {days.map((day) => (
                <Container key={day}>
                  <OfficeSpaceUserBookings
                    day={day}
                    bookings={bookingsGroupedByDay[day] || []}
                    checkins={day === today ? checkins : []}
                    officeSpaceID={officeSpaceID}
                    capacity={officeSpace?.capacity}
                    refetch={refetch}
                    today={today}
                  />
                  <Spacer size={24} />
                </Container>
              ))}
            </ResponsiveGrid>
            <Fragment>
              <Divider />
              <Spacer size={24} />
            </Fragment>
          </Container>
        ))
      ) : (
        <ResponsiveGrid
          horizontalSpacing={48}
          itemsPerRow={mq.sizeQuery.mdAndUp ? 2 : 1}
        >
          {days.map((day) => (
            <Container key={day}>
              <OfficeSpaceUserBookings
                day={day}
                bookings={bookingsGroupedByDay[day] || []}
                checkins={day === today ? checkins : []}
                officeSpaceID={officeSpaceID}
                capacity={officeSpace?.capacity}
                refetch={refetch}
                today={today}
              />
              <Spacer size={24} />
            </Container>
          ))}
        </ResponsiveGrid>
      )}
      <Row justifyContent="center">
        <View style={mq.sizeQuery.mdAndUp ? { width: 400 } : { width: "100%" }}>
          <Button
            loading={!officePageQuery}
            onPress={() => setIncrement(increment + 1)}
            appearance="secondary"
            text="More"
          />
        </View>
      </Row>
    </Container>
  );
}

interface OfficeSpaceUserBookingsProps {
  day: string;
  checkins: OfficePage__OfficeSpaceCheckinDetailsFragment[];
  bookings: OfficePage__OfficeSpaceBookingDetailsFragment[];
  officeSpaceID: string;
  capacity: number | null | undefined;
  today: string;
  refetch: () => Promise<any>;
}

function OfficeSpaceUserBookings(props: OfficeSpaceUserBookingsProps) {
  const { day, capacity, today, bookings, checkins, officeSpaceID, refetch } =
    props;
  const { data } = useQuery<OfficePageDetailsCurrentUserQuery>(
    officePageDetailsCurrentUserGQLQuery,
  );
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const mq = useMediaQuery();
  const [
    bookOfficeSpaceOnDayMutation,
    { loading: bookOfficeSpaceOnDayLoading },
  ] = useMutation<
    BookOfficeSpaceOnDayMutation,
    BookOfficeSpaceOnDayMutationVariables
  >(bookOfficeSpaceOnDayGQLMutation);
  const [unbookOfficeSpaceMutation, { loading: unbookOfficeSpaceLoading }] =
    useMutation<UnbookOfficeSpaceMutation, UnbookOfficeSpaceMutationVariables>(
      unbookOfficeSpaceGQLMutation,
      { onCompleted: refetch },
    );
  const handleSchedule = useCallback(async () => {
    await bookOfficeSpaceOnDayMutation({
      variables: { spaceID: officeSpaceID, day },
    });
    toast.notify({
      message: "Added to your schedule.",
    });
  }, [officeSpaceID, bookOfficeSpaceOnDayMutation, day, toast]);

  const booking = bookings.find((b) => b.user.id === data?.currentUser?.id);
  const full = capacity && bookings.length >= capacity;
  const scheduled = !!booking;

  const handleUnschedule = useCallback(async () => {
    if (!booking) {
      return;
    }
    await unbookOfficeSpaceMutation({
      variables: {
        spaceID: officeSpaceID,
        bookingID: booking.id,
      },
    });
    toast.notify({
      message: "Removed from your schedule.",
    });
  }, [officeSpaceID, unbookOfficeSpaceMutation, booking, toast]);

  const loading = bookOfficeSpaceOnDayLoading || unbookOfficeSpaceLoading;
  const people = data?.currentUser
    ? officeCheckinAndBookingPeople(checkins, bookings, data?.currentUser?.id)
    : [];
  const empty = isEmpty(bookings) && isEmpty(checkins);

  let content = null;

  if (loading) {
    content = (
      <div className="office-space-booking-row">
        <Heading size="h4">
          {formatDayWithTodayAndTomorrowLabel(day, today)}
        </Heading>
        <Spacer size={8} />
        <ActivityIndicator color="rgba(82,68,134,1)" />
      </div>
    );
  } else if (empty) {
    content = (
      <div className="office-space-booking-row">
        <Heading size="h4">
          {formatDayWithTodayAndTomorrowLabel(day, today)}
        </Heading>
        <Spacer size={8} />
        <Row>
          <Text color="muted">Nobody scheduled.</Text>
          <Spacer size={5} />
          <Pressable
            testID="whoshere-empty-day-schedule"
            onPress={handleSchedule}
          >
            <Text weight="500" decoration="underline">
              Schedule
            </Text>
          </Pressable>
        </Row>
      </div>
    );
  } else {
    content = (
      <div
        role="button"
        tabIndex={0}
        className="office-space-booking-row office-space-booking-row-hoverable"
        onClick={() => setOpen(true)}
      >
        <Container>
          <Heading size="h4">
            {formatDayWithTodayAndTomorrowLabel(day, today)}
          </Heading>
          <Spacer size={8} />
          <Row alignItems="center">
            <Container flex={1}>
              {data?.currentUser && (
                <People
                  people={officeCheckinAndBookingPeople(
                    checkins,
                    bookings,
                    data?.currentUser.id,
                  )}
                />
              )}
            </Container>
            <Row alignItems="center" spacing={16}>
              {scheduled ? (
                <Pressable
                  style={{
                    height: 32,
                    width: 32,
                    padding: 6,
                    borderRadius: 999,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1,
                    backgroundColor: "#ECDEFF",
                  }}
                  testID="whoshere-day-unschedule"
                  onPress={handleUnschedule}
                >
                  <Icon size={18} name="schedule" />
                </Pressable>
              ) : full ? null : (
                <Pressable
                  style={{
                    height: 32,
                    width: 32,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 999,
                    zIndex: 1,
                    opacity: 0,
                  }}
                  testID="whoshere-day-schedule"
                  onPress={handleSchedule}
                >
                  <Icon name="schedule" />
                </Pressable>
              )}
              <Pressable onPress={() => setOpen(true)}>
                <Icon name="dots" />
              </Pressable>
            </Row>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <Container key={day}>
      {content}
      <Dialog
        animationType="slide"
        visible={open}
        onRequestClose={() => setOpen(false)}
      >
        <DialogContent
          headerLeftIcon="x-circle"
          headerTitle="Who's here"
          onHeaderLeftIconPress={() => setOpen(false)}
          desktopWidth={728}
        >
          <ScrollView>
            <Container flex={1} padding={16}>
              <Row>
                <Heading size="h3">
                  {formatDayWithTodayAndTomorrowLabel(day, today)}
                </Heading>
                <Spacer size={16} />
                {full && <Badge title="Full" />}
              </Row>
              <Spacer size={16} />
              <ResponsiveGrid itemsPerRow={mq.sizeQuery.mdAndUp ? 2 : 1}>
                {people.map((p) => (
                  <Container key={p.userID}>
                    <Row>
                      <Container>
                        <Avatar
                          name={p.name}
                          size="xl"
                          source={{ uri: p.pictureURL }}
                        />
                        {p.status === "on" && (
                          <div className="office-space-status-circle" />
                        )}
                      </Container>
                      <Spacer size={16} />
                      <Container flex={1}>
                        <Text weight="600">{p.name}</Text>
                        <Text>{p.title}</Text>
                      </Container>
                    </Row>
                    <Spacer size={16} />
                  </Container>
                ))}
              </ResponsiveGrid>
            </Container>
          </ScrollView>
          {mq.sizeQuery.mdAndUp ? (
            <Container
              borderColor={tokens.colors.neutral.darker}
              borderTopWidth={1}
              shadow
              paddingHorizontal={16}
              paddingVertical={8}
            >
              <Row justifyContent="flex-end" alignItems="center">
                <Container>
                  {loading ? (
                    <ActivityIndicator color="rgba(82,68,134,1)" />
                  ) : scheduled ? (
                    <Pressable
                      style={{ paddingLeft: 24 }}
                      onPress={handleUnschedule}
                      testID="whoshere-dialog-unschedule"
                    >
                      <Text weight="500" decoration="underline">
                        Unschedule
                      </Text>
                    </Pressable>
                  ) : full ? null : (
                    <Pressable
                      style={{ paddingLeft: 24 }}
                      onPress={handleSchedule}
                      testID="whoshere-dialog-schedule"
                    >
                      <Text weight="500" decoration="underline">
                        Schedule
                      </Text>
                    </Pressable>
                  )}
                </Container>
                <Spacer size={100} />
                <Container minWidth={206}>
                  <Button
                    disabled={loading}
                    onPress={() => setOpen(false)}
                    testID="whoshere-dialog-close"
                    text="Close"
                  />
                </Container>
              </Row>
            </Container>
          ) : (
            <Container
              borderColor={tokens.colors.neutral.darker}
              borderTopWidth={1}
              shadow
              paddingHorizontal={16}
              paddingVertical={8}
            >
              <Row alignItems="center">
                {loading ? (
                  <Container flex={1}>
                    <ActivityIndicator color="rgba(82,68,134,1)" />
                  </Container>
                ) : scheduled ? (
                  <Container flex={1}>
                    <Pressable
                      style={{ paddingLeft: 24 }}
                      onPress={handleUnschedule}
                    >
                      <Text weight="500" decoration="underline">
                        Unschedule
                      </Text>
                    </Pressable>
                  </Container>
                ) : full ? null : (
                  <Container flex={1}>
                    <Pressable
                      style={{ paddingLeft: 24 }}
                      onPress={handleSchedule}
                    >
                      <Text weight="500" decoration="underline">
                        Schedule
                      </Text>
                    </Pressable>
                  </Container>
                )}
                <Container flex={1}>
                  <Button
                    disabled={loading}
                    onPress={() => setOpen(false)}
                    text="Close"
                  />
                </Container>
              </Row>
            </Container>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}

function separateToWeeks(
  dayRange: DayInterval,
  firstDayOfWeek: FirstDayOfWeek = DEFAULT_FIRST_DAY_OF_WEEK,
): string[][] {
  const result: string[][] = [];

  let currentDay = dayRange.start;
  let currentWeek = 0;

  while (isBefore(currentDay, dayRange.end)) {
    if (isEmpty(result)) {
      result.push([currentDay]);
    } else if (firstDayOfWeek === parseDay(currentDay).getDay()) {
      result.push([currentDay]);
      currentWeek += 1;
    } else {
      result[currentWeek].push(currentDay);
    }

    currentDay = addDays(currentDay, 1);
  }

  return result;
}

const officePageBookingsGQLQuery = gql`
  query OfficePageBookings(
    $officeSpaceID: ID!
    $startDate: String!
    $endDate: String!
  ) {
    officeSpace(id: $officeSpaceID) {
      ...OfficePage__OfficeSpaceBookings
    }
  }

  fragment OfficePage__OfficeSpaceBookings on OfficeSpace {
    id
    capacity
    bookings(startDate: $startDate, endDate: $endDate) {
      ...OfficePage__OfficeSpaceBookingDetails
    }
    checkins {
      ...OfficePage__OfficeSpaceCheckinDetails
    }
  }

  fragment OfficePage__OfficeSpaceBookingDetails on OfficeSpaceBooking {
    id
    day
    bookableID
    bookableType
    user {
      id
      name
      title
      avatar
    }
  }

  fragment OfficePage__OfficeSpaceCheckinDetails on OfficeSpaceCheckin {
    id
    moment
    checkinableID
    checkinableType
    user {
      id
      name
      title
      avatar
    }
  }
`;

const officePageDetailsCurrentUserGQLQuery = gql`
  query OfficePageDetailsCurrentUser {
    currentUser {
      id
    }
  }
`;

const officePageDetailsGQLQuery = gql`
  query OfficePageDetails($officeSpaceID: ID!) {
    officeSpace(id: $officeSpaceID) {
      ...OfficePage__OfficeSpaceDetails
    }
  }

  fragment OfficePage__OfficeSpaceDetails on OfficeSpace {
    id
    capacity
    office {
      address {
        latitude
        longitude
      }
      timeZone
    }
    content {
      id
      title
      description
      images {
        large {
          url
          width
          height
        }
        small {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
      }
    }
  }
`;

const bookOfficeSpaceOnDayGQLMutation = gql`
  mutation BookOfficeSpaceOnDay($spaceID: String!, $day: String!) {
    bookOfficeSpaceOnDay(spaceID: $spaceID, day: $day) {
      day
      bookableID
      bookableType
    }
  }
`;

const unbookOfficeSpaceGQLMutation = gql`
  mutation UnbookOfficeSpace($spaceID: ID!, $bookingID: ID!) {
    unbookOfficeSpace(spaceID: $spaceID, bookingID: $bookingID)
  }
`;

const checkinOfficeSpaceTodayGQLMutation = gql`
  mutation CheckinOfficeSpaceToday($spaceID: String!) {
    checkinOfficeSpaceToday(spaceID: $spaceID) {
      id
      moment
      checkinableID
      checkinableType
    }
  }
`;
