import { Placement } from "@popperjs/core";
import { colors } from "components/colors";
import { Divider } from "components/divider";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/icon";
import { tokens } from "components/tokens";
import { ReactNode, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { DropdownMenuItem, DropdownMenuItemProps } from "./dropdown_menu_item";

export type DropdownMenuOptionType = DropdownMenuItemProps | "-" | false;

interface DropdownMenuProps {
  items: DropdownMenuOptionType[];
  disabled?: boolean;
  renderAnchor?: (arg: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
  }) => ReactNode;
  placement?: Placement;
  sameWidth?: boolean;
  sameMinWidth?: boolean;
  testID?: string;
  maxHeight?: number | string;
}

export function DropdownMenu(props: DropdownMenuProps) {
  const {
    items,
    disabled = false,
    renderAnchor,
    sameWidth,
    sameMinWidth,
    placement,
    testID,
    maxHeight,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownV2
      open={isOpen}
      onRequestClose={() => setIsOpen(false)}
      sameWidth={sameWidth}
      sameMinWidth={sameMinWidth}
      placement={placement}
      content={
        <View
          style={[styles.dropdownContent, { maxHeight: maxHeight || "50vh" }]}
        >
          {items.map((option, index) => {
            if (option === "-") {
              return <Divider key={`divider-${index}`} />;
            } else if (option === false) {
              return null;
            } else {
              return (
                <DropdownMenuItem
                  {...option}
                  key={index}
                  onClick={() => {
                    if (option?.onClick) {
                      option.onClick();
                    }
                    setIsOpen(false);
                  }}
                />
              );
            }
          })}
        </View>
      }
    >
      {renderAnchor ? (
        renderAnchor({ isOpen, setIsOpen })
      ) : (
        <Pressable
          onPress={() => setIsOpen(!isOpen)}
          disabled={disabled}
          style={styles.contextButton}
          testID={testID}
        >
          <Icon name="dots" color={colors.brand.blackMinus30} />
        </Pressable>
      )}
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dropdownContent: {
    minWidth: "fit-content",
    overflow: "hidden",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: tokens.colors.base.white,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    paddingVertical: 8,
  },
  contextButton: {
    width: 24,
    height: 24,
  },
});
