import {
  TimeRangePickerHoursChildrenType,
  TimeRangePickerHoursDropdown,
} from "components/time_range_picker/time_range_picker_hours_dropdown";
import { format } from "date-fns";
import { parseTime } from "lib/time_utils";
import { useAnalytics } from "providers/analytics";
import { useCallback } from "react";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";
import { FilterChip } from "./filter_chip";

const formatLabelTime = (time: string) => format(parseTime(time), "h:mm a");

export function StartEndTimePicker() {
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const analytics = useAnalytics();
  const { startTime, endTime } = useHomeSearchParamsQuery();
  const handleChangeStartEndTime = useCallback(
    (newTimes) => {
      updateHomeSearchParams({
        startTime: newTimes?.start || null,
        endTime: newTimes?.end || null,
      });
      analytics.event("Select Time Range", {
        "Start Time": newTimes?.start,
        "End Time": newTimes?.end,
      });
    },
    [analytics, updateHomeSearchParams],
  );

  return (
    <TimeRangePickerHoursDropdown
      value={{ start: startTime, end: endTime }}
      onChange={handleChangeStartEndTime}
    >
      {({ onToggleOpen, isOpen }: TimeRangePickerHoursChildrenType) => (
        <FilterChip
          title={
            startTime && endTime
              ? `${formatLabelTime(startTime)} - ${formatLabelTime(endTime)}`
              : "Start / end time"
          }
          onPress={onToggleOpen}
          testID="header-time-picker"
          isSelected={!!startTime || !!endTime}
          isContentOpen={isOpen}
        />
      )}
    </TimeRangePickerHoursDropdown>
  );
}
