import { colors } from "components/colors";
import { Divider } from "components/divider";
import { Text } from "components/text_v2";
import { SpaceType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { BudgetTransactionRow } from "pages/budgets/components/budget_transaction_row";
import React from "react";
import { StyleSheet, View } from "react-native";

export type Order = {
  hostFullName: string;
  hostTitle?: string | null;
  locationName: string;
  locationAddress: string;
  spaceName: string;
  spaceType?: SpaceType | null;
  bookingDate: string;
  bookingStartTime?: string | null;
  bookingEndTime?: string | null;
  orderCreatedAt: string;
  orderID: string;
  totalPrice: number;
  currency: string;
  order?: {
    memo?: string | null;
  } | null;
  groupBudget?: {
    id: string;
    name: string;
  } | null;
  bookingRequest?: {
    id: string;
  } | null;
};

export type BudgetInfo = {
  id: string;
  name: string;
};

type TableProps = {
  orders: Order[];
  orgInfo?: BudgetInfo;
  groupInfo?: BudgetInfo;
  refetch: () => void;
};

type ColumnProps = {
  children?: React.ReactNode;
  percent: number;
  alignItems?: "flex-start" | "center" | "flex-end";
};

export const Column = ({ children, percent, alignItems }: ColumnProps) => {
  // @ts-ignore
  return <View style={{ flex: percent, alignItems, gap: 4 }}>{children}</View>;
};

const Header = () => (
  <View style={styles.rowContainer}>
    <View style={styles.horizontalView}>
      <Column percent={35}>
        <Text color="black-70" size="xs" weight={"semibold"}>
          Space Booked
        </Text>
      </Column>
      <Column percent={20}>
        <Text size="xs" weight={"semibold"}>
          User
        </Text>
      </Column>
      <Column percent={15}>
        <Text size="xs" weight={"semibold"}>
          Total
        </Text>
      </Column>
      <Column percent={10}>
        <Text size="xs" weight={"semibold"}>
          Booked On
        </Text>
      </Column>
      <Column percent={15} />
    </View>
  </View>
);

export const BudgetTransactionTable = ({
  orders,
  orgInfo,
  groupInfo,
  refetch,
}: TableProps) => {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  if (!orders || orders.length === 0) {
    return null;
  }

  return (
    <View style={styles.container}>
      {!isMobile && (
        <>
          <Header />
          <Divider />
        </>
      )}
      {orders.map((order) => (
        <BudgetTransactionRow
          order={order}
          orgInfo={orgInfo}
          groupInfo={groupInfo}
          key={order.orderID}
          refetch={refetch}
        />
      ))}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    backgroundColor: colors.brand.whitecore,
    paddingBottom: 24,
  },
  horizontalView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    gap: 24,
  },
  rowContainer: {
    paddingHorizontal: 24,
  },
});
