import { useApolloClient } from "@apollo/client";
import { Auth0Error } from "auth0-js";
import { AuthPageContainer } from "components/auth_page_container";
import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { PasswordField } from "components/password_field";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { GoogleButton } from "core/google_button";
import {
  AuthMethod,
  OrgByDomainQuery,
  OrgByDomainQueryVariables,
} from "core/graphql.generated";
import { Footer } from "core/new_footer";
import { OnboardingHeader } from "core/onboarding_header";
import { useForm } from "hooks/use_form";
import { useMediaQuery } from "lib/media_query";
import { isEmail } from "lib/string_utils";
import { SourceFrom } from "pages/custom_org_sign_in/custom_org_sign_in";
import { useAnalytics } from "providers/analytics";
import { orgByDomainQuery } from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import { useCustomSSOFeatureFlag } from "providers/splitio";
import { useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { ResetPasswordForm } from "./components/reset_password_form";

export type EmailPasswordLoginFormValues = {
  email: string;
  password: string;
};

export const LAST_SIGN_IN_LOCALSTORAGE_KEY = "lastSignIn";

export function SignInPage() {
  const mq = useMediaQuery();
  const { logInWithEmailAndPassword, logInWithGoogle } = useAuthV2();
  const sSOFeatureFlag = useCustomSSOFeatureFlag();
  const apolloClient = useApolloClient();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const { values, changed, errors, setFieldValue, submit, submitting } =
    useForm({
      initialValues: { email: "", password: "", authenticationError: "" },
      validate: ({ email, password }: EmailPasswordLoginFormValues) => {
        const errors: { [field: string]: string } = {};

        if (!isEmail(email)) {
          errors.email = "Please input a valid email address";
        }
        if (!password) {
          errors.password = "Please input a password";
        }

        return errors;
      },
      onSubmit: async (
        { email, password }: EmailPasswordLoginFormValues,
        { setSubmitting, setErrors }: any,
      ) => {
        setSubmitting(true);

        if (sSOFeatureFlag) {
          const result = await apolloClient.query<
            OrgByDomainQuery,
            OrgByDomainQueryVariables
          >({
            query: orgByDomainQuery,
            variables: {
              domain: email.split("@").pop() || "",
            },
          });

          const orgData = result.data.organizationByDomain;
          const isLoginMethodValid = orgData?.ssoProvider.find(
            (provider) => provider === AuthMethod.Password,
          );
          if (orgData?.requireSSO && orgData.slug && !isLoginMethodValid) {
            history.replace(`/${orgData.slug}`, {
              orgData,
              sourceFrom: SourceFrom.LOG_IN,
            });
          } else {
            try {
              await logInWithEmailAndPassword(email, password);
            } catch (error) {
              setErrors({
                authenticationError: (error as Auth0Error).description,
              });
            } finally {
              setSubmitting(false);
            }
          }
        } else {
          try {
            await logInWithEmailAndPassword(email, password);
          } catch (error) {
            setErrors({
              authenticationError: (error as Auth0Error).description,
            });
          } finally {
            setSubmitting(false);
          }
        }
      },
    });

  const history = useHistory();
  const goToSignUp = () => history.push("/sign-up");
  const analytics = useAnalytics();

  useEffect(() => {
    const lastDay = localStorage.getItem(LAST_SIGN_IN_LOCALSTORAGE_KEY);

    if (lastDay) {
      const lastHours = (Date.now() - Number(lastDay)) / 1000 / 3600;

      analytics.event("Last Sign In", {
        "Last Visit Date": lastDay,
        "Last Visit Hours": lastHours,
      });

      return;
    }

    localStorage.setItem(LAST_SIGN_IN_LOCALSTORAGE_KEY, Date.now().toString());
  }, [analytics]);

  return (
    <AuthPageContainer
      header={
        <OnboardingHeader buttonTitle="Sign up" onButtonClick={goToSignUp} />
      }
      footer={<Footer withTermsAndPrivacy />}
    >
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Heading size="mega" align="center">
          Log in
        </Heading>
        <Spacer size={24} />
        {showResetPassword ? (
          <Content maxWidth={343}>
            <ResetPasswordForm
              onComplete={() => setShowResetPassword(false)}
              onGoBack={() => setShowResetPassword(false)}
            />
          </Content>
        ) : (
          <Content maxWidth={383}>
            <GoogleButton onPress={logInWithGoogle} />
            <Spacer size={16} />
            <Text weight="bold" size="md" align="center">
              or
            </Text>
            <Spacer size={16} />
            <TextField
              testID="login-email"
              value={values.email}
              onChange={(email) => setFieldValue("email", email.trim())}
              placeholder="Work email"
              invalid={changed.email && !!errors.email}
              invalidText={changed.email ? errors.email : ""}
              onSubmitEditing={submit}
            />
            <Spacer size={8} />
            <PasswordField
              testID="login-password"
              value={values.password}
              onChange={(password) =>
                setFieldValue("password", password.trim())
              }
              placeholder="Password"
              invalid={changed.password && !!errors.password}
              invalidText={changed.password ? errors.password : ""}
              withRevealButton
              onSubmitEditing={submit}
            />
            <Spacer size={15} />
            {!!errors.authenticationError && (
              <>
                <Text color="grapefruit-core" size="xs">
                  {errors.authenticationError}
                </Text>
                <Spacer size={16} />
              </>
            )}
            <Pressable onPress={() => setShowResetPassword(true)}>
              <Text weight="bold" size="xs" color="eggplant-core">
                I forgot my password
              </Text>
            </Pressable>
            <Spacer size={16} />
          </Content>
        )}
      </View>
      {!showResetPassword && (
        <View>
          <View
            style={[
              styles.buttonWrapper,
              mq.sizeQuery.mdAndUp && styles.buttonDesktopWrapper,
            ]}
          >
            <Button
              testID="login-button"
              appearance="primary"
              text="Log in"
              onPress={submit}
              loading={submitting}
            />
          </View>
        </View>
      )}
    </AuthPageContainer>
  );
}

const styles = StyleSheet.create({
  buttonWrapper: {
    width: "95%",
    maxWidth: 343,
    alignSelf: "center",
  },
  buttonDesktopWrapper: {
    width: 220,
  },
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
});
