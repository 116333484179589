import React from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "./colors";

import { tokens } from "./tokens";

export interface DividerProps {
  color?: "darker" | "darkest" | "dark" | "lighter";
  direction?: "vertical" | "horizontal";
}

/**
 * Visual separator between components.
 */
export function Divider(props: DividerProps) {
  const { color = "darker", direction = "horizontal" } = props;
  let backgroundColor;

  switch (color) {
    case "darker":
      backgroundColor = styles.darkerBackgroundColor;
      break;
    case "darkest":
      backgroundColor = styles.darkestBackgroundColor;
      break;
    case "lighter":
      backgroundColor = styles.lighterBackgroundColor;
      break;
    default:
      backgroundColor = styles.darkBackgroundColor;
      break;
  }

  return <View style={[styles.base, styles[direction], backgroundColor]} />;
}

const styles = StyleSheet.create({
  base: {},
  horizontal: {
    width: "100%",
    height: 1,
  },
  vertical: {
    width: 1,
    height: "100%",
  },
  darkBackgroundColor: {
    backgroundColor: tokens.colors.neutral.dark,
  },
  darkerBackgroundColor: {
    backgroundColor: tokens.colors.neutral.darker,
  },
  darkestBackgroundColor: {
    backgroundColor: tokens.colors.neutral.darkest,
  },
  lighterBackgroundColor: {
    backgroundColor: colors.brand.blackMinus90,
  },
});
