import {
  CurrentUserOrderFragment,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import { useLocationNearByQuery } from "pages/homev3/hooks/use_location_nearby_query";
import { usePopularLocationQuery } from "pages/homev3/hooks/use_popular_location_query";
import { HeroCarouselSkeleton } from "pages/homev3/return_user/hero_carousel/hero_carousel_skeleton";
import { HeroLocationCard } from "pages/homev3/return_user/hero_carousel/hero_location_card";
import React from "react";

type NewLocationProps = {
  userOrders: CurrentUserOrderFragment[];
  setShowNewLocation: (show: boolean) => void;
  onPress?: () => void;
};
export function NewLocation({
  userOrders,
  setShowNewLocation,
  onPress,
}: NewLocationProps) {
  const { data: newLocationData, loading: newLocationLoading } =
    useLocationNearByQuery({
      fixedRadius: 16093,
    });
  //Display a location within 10 miles that the user has never booked before
  let validNewLocation;
  if (newLocationData && newLocationData.locations.length > 0) {
    // filter out the location that user has never booked before
    const newLocations = newLocationData.locations
      .filter((location) =>
        userOrders.every(
          (od) => od.orderItems[0].details.space.location.id !== location.id,
        ),
      )
      .sort(
        (a, b) => (a?.currentUserDistance || 0) - (b?.currentUserDistance || 0),
      );
    if (newLocations.length > 0) {
      validNewLocation = newLocations[0];
    }
  }
  const skip = newLocationLoading || !!validNewLocation;
  const { data: popularLocationData, loading: popularLocationLoading } =
    usePopularLocationQuery({ limit: 1, skip });

  if (newLocationLoading || (!skip && popularLocationLoading)) {
    return <HeroCarouselSkeleton />;
  }

  if (!validNewLocation) {
    if (
      popularLocationData?.locations &&
      popularLocationData.locations.length > 0
    ) {
      //if in 10miles there is no new location, display the most popular location
      validNewLocation = popularLocationData.locations[0];
    } else {
      setShowNewLocation(false);
      return null;
    }
  }
  return <Content onPress={onPress} location={validNewLocation} />;
}

type ContentProps = {
  location?: SpaceLocationDetailFragment;
  onPress?: () => void;
};
function Content({ location, onPress }: ContentProps) {
  if (!location) {
    return null;
  }
  return (
    <HeroLocationCard
      location={location}
      title={"Need a change of scenery?"}
      subtitle={`Try the ${location.name}`}
      onPress={onPress}
    />
  );
}
