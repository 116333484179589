import { gql, useQuery } from "@apollo/client";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { Content } from "components/content";
import { PageContainer } from "components/page_container";
import { Spacer } from "components/spacer";
import { useConfig } from "providers/config";
import { useAnalytics } from "providers/analytics";
import {
  OrganizationBudgetState,
  PaymentMode,
  ReportingPageCurrentUserQuery,
  ReportingPageOrganizationBudgetQuery,
  ReportingPageOrganizationBudgetQueryVariables,
} from "core/graphql.generated";
import { ColumnGrid, ColumnGridCol, ColumnGridRow } from "core/grid";
import {
  getDayIntervalFromBudgetsDateRangePickerOption,
  budgetsDateRangePickerOptions,
} from "components/budgets_date_range_picker/budgets_date_range_picker_common";
import { DayInterval } from "lib/day_utils";
import { useMediaQuery } from "lib/media_query";
import { useQueryString } from "lib/query_string";
import "./reporting_page.css";
import { ReportingOnDemandDesktop } from "./components/on_demand_desktop";
import { ReportingOnDemandMobile } from "./components/on_demand_mobile";
import { ReportingPayAsYouGoDesktop } from "./components/pay_as_you_go_desktop";
import { ReportingPayAsYouGoMobile } from "./components/pay_as_you_go_mobile";
import { ReportingSubscriptionDesktop } from "./components/subscription_desktop";
import { ReportingSubscriptionMobile } from "./components/subscription_mobile";
import { ReportingBudgetWarningDesktop } from "./components/budget_warning_desktop";
import {
  ScrollToBudgetCardContextProvider,
  useScrollToBudgetCardContext,
} from "./hooks/scroll_to_budget_card_context";
import { ReportingBudgetWarningMobile } from "./components/budget_warning_mobile";
import { reportingPageOrganizationBudgetGQLQuery } from "./gql_queries";
import { Heading } from "components/heading_v2";
import { BudgetsDateRangePickerDesktop } from "components/budgets_date_range_picker/budgets_date_range_picker_desktop";
import { BudgetsDateRangePickerMobile } from "components/budgets_date_range_picker/budgets_date_range_picker_mobile";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { BOTTOM_BAR_HEIGHT } from "core/bottom_bar_navigation";
import { AdminHeader } from "components/admin_header";

export interface ReportingPageSearchParams {
  start?: string;
  end?: string;
}

export function ReportingPage() {
  const sp = useQueryString<ReportingPageSearchParams>();
  const analytics = useAnalytics();
  const history = useHistory();
  const config = useConfig();
  const { data } = useQuery<ReportingPageCurrentUserQuery>(
    reportingPageCurrentUserGQLQuery,
  );

  const initialDayInterval: DayInterval = useMemo(() => {
    if (sp.start && sp.end) {
      return {
        start: sp.start,
        end: sp.end,
      };
    }

    return getDayIntervalFromBudgetsDateRangePickerOption(
      budgetsDateRangePickerOptions[0].value,
    );
  }, [sp]);
  const [dayInterval, setDayInterval] =
    useState<DayInterval>(initialDayInterval);

  const handleChangeDayInterval = useCallback((v: DayInterval) => {
    setDayInterval(v);
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("start", dayInterval.start);
    newSearchParams.set("end", dayInterval.end);

    history.replace(`/admin/analytics?${newSearchParams.toString()}`);
  }, [history, dayInterval]);
  const mq = useMediaQuery();

  const handlePressGetStartedSubscription = useCallback(() => {
    analytics.event("Upgrade Payment Promo", {
      category: "Payment Promo",
      label: "PAYG Reporting Page",
    });
    // @ts-ignore calendly is available as we include their JS in the helmet
    window.Calendly.initPopupWidget({
      url: config.subscriptionCalendlyLink,
    });
  }, [analytics, config.subscriptionCalendlyLink]);
  const currentUser = data?.currentUser;
  const paymentMode = currentUser?.organization?.paymentMode;

  const { data: budgetData } = useQuery<
    ReportingPageOrganizationBudgetQuery,
    ReportingPageOrganizationBudgetQueryVariables
  >(reportingPageOrganizationBudgetGQLQuery, {
    skip: !paymentMode || paymentMode === PaymentMode.PayAsYouGo,
  });

  const shouldShowBudgetWarning =
    budgetData?.currentUser?.organization?.budget?.state ===
    OrganizationBudgetState.AboveWarningThreshold;

  const organizationCreatedDay = new Date(
    currentUser?.organization?.createdAt || "",
  );

  return (
    <ScrollToBudgetCardContextProvider>
      <div className="reporting-page">
        <Helmet>
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
          <script
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
            async
          />
        </Helmet>

        <AdminHeader />
        <Spacer size={16} />
        <PageContainer offsetFooter={false}>
          {!!paymentMode && (
            <>
              {mq.sizeQuery.mdAndUp ? (
                <ReportingPageDesktop
                  showBudgetWarning={shouldShowBudgetWarning}
                  paymentMode={paymentMode}
                  dayInterval={dayInterval}
                  onChangeDayInterval={handleChangeDayInterval}
                  onPressGetStartedSubscription={
                    handlePressGetStartedSubscription
                  }
                  organizationCreatedDay={organizationCreatedDay}
                />
              ) : (
                <ReportingPageMobile
                  showBudgetWarning={shouldShowBudgetWarning}
                  paymentMode={paymentMode}
                  dayInterval={dayInterval}
                  onChangeDayInterval={handleChangeDayInterval}
                  onPressGetStartedSubscription={
                    handlePressGetStartedSubscription
                  }
                  organizationCreatedDay={organizationCreatedDay}
                />
              )}
            </>
          )}
        </PageContainer>
      </div>
    </ScrollToBudgetCardContextProvider>
  );
}

interface ReportingPageProps {
  paymentMode: PaymentMode;
  dayInterval: DayInterval;
  onPressGetStartedSubscription: () => void;
  onChangeDayInterval: (dayInterval: DayInterval) => void;
  showBudgetWarning?: boolean;
  organizationCreatedDay: Date;
}

function ReportingPageDesktop(props: ReportingPageProps) {
  const {
    paymentMode,
    dayInterval,
    onChangeDayInterval,
    onPressGetStartedSubscription,
    showBudgetWarning,
    organizationCreatedDay,
  } = props;

  const { scrollToBudgetCard } = useScrollToBudgetCardContext();

  return (
    <div>
      {showBudgetWarning && (
        <ColumnGrid style={{ zIndex: 1 }}>
          <ColumnGridRow>
            <ColumnGridCol cols={12}>
              <ReportingBudgetWarningDesktop onClick={scrollToBudgetCard} />
              <Spacer size={24} />
            </ColumnGridCol>
          </ColumnGridRow>
        </ColumnGrid>
      )}
      <ColumnGrid style={{ zIndex: 2 }}>
        <ColumnGridRow>
          <ColumnGridCol cols={12}>
            <Heading size="lg">Analytics</Heading>
            <Spacer size={16} />
            <BudgetsDateRangePickerDesktop
              value={dayInterval}
              onChange={onChangeDayInterval}
              earliestAllowedDate={organizationCreatedDay}
            />
          </ColumnGridCol>
        </ColumnGridRow>
      </ColumnGrid>
      <Spacer size={16} />
      {paymentMode === PaymentMode.Subscription && (
        <ReportingSubscriptionDesktop dayInterval={dayInterval} />
      )}
      {paymentMode === PaymentMode.OnDemand && (
        <ReportingOnDemandDesktop dayInterval={dayInterval} />
      )}
      {paymentMode === PaymentMode.PayAsYouGo && (
        <ReportingPayAsYouGoDesktop
          dayInterval={dayInterval}
          onPressGetStartedSubscription={onPressGetStartedSubscription}
        />
      )}
      <Spacer size={40} />
    </div>
  );
}

function ReportingPageMobile(props: ReportingPageProps) {
  const {
    paymentMode,
    dayInterval,
    onChangeDayInterval,
    onPressGetStartedSubscription,
    showBudgetWarning,
    organizationCreatedDay,
  } = props;

  const { scrollToBudgetCard } = useScrollToBudgetCardContext();

  return (
    <Fragment>
      <View style={styles.contentContainer}>
        <Content>
          {showBudgetWarning && (
            <>
              <ReportingBudgetWarningMobile onClick={scrollToBudgetCard} />
              <Spacer size={16} />
            </>
          )}
        </Content>
        <Content>
          <Heading size="lg">Analytics</Heading>
          <Spacer size={16} />
          <BudgetsDateRangePickerMobile
            value={dayInterval}
            onChange={onChangeDayInterval}
            earliestAllowedDate={organizationCreatedDay}
          />
        </Content>
        <Spacer size={16} />
        {paymentMode === PaymentMode.Subscription && (
          <ReportingSubscriptionMobile dayInterval={dayInterval} />
        )}
        {paymentMode === PaymentMode.OnDemand && (
          <ReportingOnDemandMobile dayInterval={dayInterval} />
        )}
        {paymentMode === PaymentMode.PayAsYouGo && (
          <ReportingPayAsYouGoMobile
            dayInterval={dayInterval}
            onPressGetStartedSubscription={onPressGetStartedSubscription}
          />
        )}
        <Spacer size={40} />
      </View>
      <BottomBarAdminNavigation />
    </Fragment>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: BOTTOM_BAR_HEIGHT,
  },
});

const reportingPageCurrentUserGQLQuery = gql`
  query ReportingPageCurrentUser {
    currentUser {
      id
      organization {
        monthlyBilling
        paymentMode
        createdAt
      }
    }
  }
`;
