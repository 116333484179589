import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { StyleSheet, View } from "react-native";
import { Icon } from "components/iconv2";

interface BookingBannerProps {
  spaceCapacity: number;
}

export function BookingBanner(props: BookingBannerProps) {
  const { spaceCapacity } = props;
  const isSoloBooking = spaceCapacity === 1;

  return (
    <View style={styles.container}>
      <View style={styles.tagContainer}>
        <Icon
          size="sm"
          name={isSoloBooking ? "me" : "team"}
          color="blackcore"
          filled
        />
        <Spacer size={8} direction="row" />
        <Text size="micro" weight="semibold">
          {isSoloBooking ? "Solo booking" : "Group booking"}
        </Text>
      </View>
      <Spacer size={24} />
      <Text size="md" weight="semibold">
        How to collaborate here
      </Text>
      <Spacer size={24} />
      <Text size="xs">
        {isSoloBooking
          ? "This space is just for 1 person. After booking, you can share the link to this space with your coworkers so they can book their own spot and collaborate in-person."
          : `This space can hold up to ${spaceCapacity} people. After reserving the space, you can invite your coworkers to join you.`}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    backgroundColor: colors.brand.eggplantMinus90,
  },
  tagContainer: {
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.brand.eggplantMinus70,
    borderRadius: 4,
    width: "fit-content",
  },
});
