import { HorizontalList } from "components/horizontal_list";
import {
  OrganizationUser,
  OrgBookingFragment,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { LocationCard } from "pages/homev3/components/location_card";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useOrgBookingQuery } from "pages/homev3/hooks/use_org_booking_query";
import React from "react";
import { useRecentlyViewedLocations } from "../hooks/use_recently_viewed_locations";

export function RecentlyViewed() {
  const { locations } = useRecentlyViewedLocations();

  const { data: orgData } = useOrgBookingQuery({});

  if (!locations.length) {
    return null;
  }

  return <Content locations={locations} orgBookings={orgData?.bookings} />;
}

type ContentProps = {
  locations: SpaceLocationDetailFragment[];
  orgBookings: OrgBookingFragment[];
};
function Content({ locations, orgBookings }: ContentProps) {
  const orgGroupedBooking = groupBy(orgBookings, (b) => b.space.location.id);

  const renderItem = (location: SpaceLocationDetailFragment) => {
    const locationId = location.id;
    let peoples: Partial<OrganizationUser>[] = [];

    if (orgGroupedBooking[locationId]) {
      peoples = orgGroupedBooking[locationId].map((b) => b.user);
    }

    return (
      <LocationCard key={location.id} location={location} peoples={peoples} />
    );
  };

  return (
    <HorizontalList
      title={
        <TitleSpaceList
          title={"Recently viewed"}
          subtitle={"Pick up where you left off"}
        />
      }
      data={locations}
      renderItem={renderItem}
    />
  );
}
