import { DayOfWeek, BookingHour } from "core/graphql.generated";

export function operatingHoursContent(bookingHours: BookingHour[]) {
  return readableOperatingHours(
    compactOperatingHours(sortAndfillUpMissingDays(bookingHours)),
  );
}

// Sunday first by default
export function sortAndfillUpMissingDays(
  bookingHours: BookingHour[],
): BookingHour[] {
  const orderedWeekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const sortedBookingHours = bookingHours
    .slice(0)
    .sort((a: BookingHour, b: BookingHour): number => {
      if (
        orderedWeekDays.indexOf(a.dayOfWeek) >
        orderedWeekDays.indexOf(b.dayOfWeek)
      ) {
        return 1;
      }
      if (
        orderedWeekDays.indexOf(a.dayOfWeek) <
        orderedWeekDays.indexOf(b.dayOfWeek)
      ) {
        return -1;
      }

      return 0;
    });

  const results: BookingHour[] = [];
  let tempSortedBookingHours = sortedBookingHours;
  for (let i = 0; i < orderedWeekDays.length; i++) {
    const dayOfWeek = orderedWeekDays[i];
    const bookingHour = tempSortedBookingHours[0];

    if (!bookingHour || dayOfWeek !== bookingHour.dayOfWeek) {
      results.push({
        dayOfWeek: dayOfWeek as DayOfWeek,
        openAt: "0000",
        closedAt: "0000",
      });
    } else {
      results.push(bookingHour);
      tempSortedBookingHours = tempSortedBookingHours.slice(1);
    }
  }

  return results;
}

export interface ReadableOperatingHours {
  label: string;
  open: string;
  close: string;
}

export function readableOperatingHours(
  operatingHours: CompactOperatingHours[],
): ReadableOperatingHours[] {
  const result: ReadableOperatingHours[] = [];
  operatingHours.forEach((operatingHour) => {
    if (
      operatingHour.startDayLabel === operatingHour.endDayLabel &&
      operatingHour.open === operatingHour.close
    ) {
      return;
    }

    const startDayPrefix = operatingHour.startDayLabel.substr(0, 3);
    const endDayPrefix = operatingHour.endDayLabel.substr(0, 3);

    const openHour = Number(operatingHour.open.substr(0, 2));
    const openMinute = operatingHour.open.substr(2, 4);
    const openMeridiem = openHour < 12 ? "AM" : "PM";
    const meridiemOpenHour =
      openMeridiem === "AM" || openHour === 12
        ? openHour === 0
          ? 12
          : openHour
        : openHour % 12;

    const closeHour = Number(operatingHour.close.substr(0, 2));
    const closeMinute = operatingHour.close.substr(2, 4);
    const closeMeridiem = closeHour < 12 ? "AM" : "PM";
    const meridiemCloseHour =
      closeMeridiem === "AM" || closeHour === 12
        ? closeHour === 0
          ? 12
          : closeHour
        : closeHour % 12;

    result.push({
      label:
        startDayPrefix === endDayPrefix
          ? startDayPrefix
          : `${startDayPrefix} - ${endDayPrefix}`,
      open: `${meridiemOpenHour}${
        Number(openMinute) === 0 ? "" : `:${openMinute}`
      } ${openMeridiem}`,
      close: `${meridiemCloseHour}${
        Number(closeMinute) === 0 ? "" : `:${closeMinute}`
      } ${closeMeridiem}`,
    });
  });
  return result;
}

export interface CompactOperatingHours {
  startDayLabel: string;
  endDayLabel: string;
  open: string;
  close: string;
}

export function compactOperatingHours(
  bookingHours: BookingHour[],
): CompactOperatingHours[] {
  const result: CompactOperatingHours[] = [];
  let startDay: string = "";
  let endDay: string = "";
  let prevDayOpen: string = "";
  let prevDayClose: string = "";
  bookingHours.forEach((bookingHour, i) => {
    if (!startDay) {
      startDay = bookingHour.dayOfWeek;
      endDay = bookingHour.dayOfWeek;
      prevDayOpen = bookingHour.openAt;
      prevDayClose = bookingHour.closedAt;
      if (i === bookingHours.length - 1) {
        result.push({
          startDayLabel: startDay,
          endDayLabel: endDay,
          open: prevDayOpen,
          close: prevDayClose,
        });
      }
      return;
    }

    if (
      prevDayOpen === bookingHour.openAt &&
      prevDayClose === bookingHour.closedAt &&
      bookingHour.openAt !== "0000" &&
      bookingHour.closedAt !== "0000"
    ) {
      endDay = bookingHour.dayOfWeek;
      if (i === bookingHours.length - 1) {
        result.push({
          startDayLabel: startDay,
          endDayLabel: endDay,
          open: prevDayOpen,
          close: prevDayClose,
        });
      }
      return;
    }

    result.push({
      startDayLabel: startDay,
      endDayLabel: endDay,
      open: prevDayOpen,
      close: prevDayClose,
    });
    startDay = bookingHour.dayOfWeek;
    endDay = bookingHour.dayOfWeek;
    prevDayOpen = bookingHour.openAt;
    prevDayClose = bookingHour.closedAt;

    if (i === bookingHours.length - 1) {
      result.push({
        startDayLabel: startDay,
        endDayLabel: endDay,
        open: prevDayOpen,
        close: prevDayClose,
      });
    }
  });

  return result;
}
