import React, { ReactNode, useEffect, useState } from "react";
import { View, ViewStyle } from "react-native";

interface DynamicHeightContainerProps {
  children: ReactNode;
  style?: ViewStyle;
}

// This one fixes the issue of the minHeight: 100vh did not work correct in ios.
const Dynamic_height_container: React.FC<DynamicHeightContainerProps> = ({
  children,
  style,
}) => {
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <View style={{ ...style, minHeight: containerHeight }}>{children}</View>
  );
};

export default Dynamic_height_container;
