import { format } from "date-fns";

import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";

import { ArchivedRequest } from "./archived_requests_table";
import { StatusText } from "./status_text";
import { BookingRequestsMobile } from "./booking_requests_mobile";
import { parseDay } from "lib/day_utils";

interface PropsType {
  requests: ArchivedRequest[];
}

export const ArchivedRequestsTableMobile: React.FC<PropsType> = ({
  requests,
}) => {
  const mappedRequests = requests.map(
    ({
      id,
      requestUsername,
      spaceName,
      spaceType,
      spaceLocation,
      amount,
      requestedOn,
      reviewedBy,
      reviewedOn,
      status,
    }) => ({
      id,
      data: [
        { id: "name", label: "Name", value: { text: requestUsername } },
        {
          id: "spaceRequested",
          label: "Space requested",
          value: {
            text: spaceName,
            element: (
              <>
                <Text size="xs">{spaceName}</Text>
                <Spacer size={4} />
                <Text size="xs">{spaceType}</Text>
                <Spacer size={4} />
                <Text size="xs">{spaceLocation}</Text>
              </>
            ),
          },
        },
        { id: "amount", label: "Amount", value: { text: amount } },
        {
          id: "requestedOn",
          label: "Requested on",
          value: { text: format(parseDay(requestedOn), "MMM d") },
        },
        {
          id: "reviewedBy",
          label: "Reviewed by",
          value: { text: reviewedBy || "-" },
        },
        {
          id: "reviewedOn",
          label: "Reviewed on",
          value: {
            text: reviewedOn ? format(parseDay(reviewedOn), "MMM d") : "-",
          },
        },
        {
          id: "status",
          label: "Status",
          value: { text: status, element: <StatusText status={status} /> },
        },
      ],
    }),
  );
  return <BookingRequestsMobile requests={mappedRequests} />;
};
