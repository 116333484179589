import { useQuery } from "@apollo/client";
import { Stripe } from "@stripe/stripe-js";
import { Container } from "components/container";
import { Dialog } from "components/dialog";
import { DateRange } from "core/booking_date_range_picker";
import {
  getDefaultLayoutID,
  hasReservationDetails,
  ReservationDetailsInput,
  SearchValue,
} from "core/booking_utils";
import { useSavedReservationDetails } from "core/cart";
import {
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  QuoteDetailsQuery,
  QuoteDetailsQueryVariables,
} from "core/graphql.generated";
import { quoteGQLQuery } from "core/queries";
import { useQuoteVariables } from "core/use_quote_variables";
import { OffsiteOrderMobile } from "pages/offsite_order_mobile";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";
import { useCallback, useEffect, useState } from "react";
import { validateDateRange } from "../utils/validate_date_range";
import { BookingDateRangePickerDialog } from "./booking_date_range_picker_dialog";
import { ConfirmAndPayDialog } from "./confirm_and_pay_dialog";
import { ReservationDetailsDialog } from "./reservation_details_dialog";
import { SpaceQuoteDetailsMobile } from "./space_quote_details_mobile";

interface BottomBarBookSpaceProps {
  status?: string;
  monthlyBilling: boolean;
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  stripe: Stripe | null;
  searchValue: SearchValue;
  onChange: (value: SearchValue) => void;
  onChangePromoCode: (promoCode: string) => void;
}

export function BottomBarBookSpace(props: BottomBarBookSpaceProps) {
  const {
    space,
    monthlyBilling,
    stripe,
    searchValue,
    onChange,
    onChangePromoCode,
  } = props;
  const [visible, setVisible] = useState(false);
  const [orderId, setOrderId] = useState("");

  const { dateRange } = searchValue;

  const { setSavedReservationDetails, savedReservationDetails } =
    useSavedReservationDetails(space.id);
  const [reservationDetails, setReservationDetails] =
    useState<ReservationDetailsInput>({
      layoutID: getDefaultLayoutID(space),
      meetingName: savedReservationDetails.meetingName,
      meetingStartTime: savedReservationDetails.meetingStartTime,
      arrivalTime: savedReservationDetails.arrivalTime,
    });
  const quoteVariables = useQuoteVariables({
    searchValue,
    spaceID: space.id,
    layoutID: getDefaultLayoutID(space),
    partnerID: space.partnerID,
    attendees: space.maxCapacity,
  });
  const {
    data: quoteQuery,
    loading: quoteLoading,
    error: quoteError,
  } = useQuery<QuoteDetailsQuery, QuoteDetailsQueryVariables>(quoteGQLQuery, {
    fetchPolicy: "network-only",
    variables: quoteVariables!,
    skip: !quoteVariables,
  });
  const [error, setError] = useState("");
  const [view, setView] = useState("calendar");

  useEffect(() => {
    if (
      quoteQuery?.quote.quoteId &&
      quoteQuery?.quote.quoteId !== searchValue.quoteId
    ) {
      onChange({
        ...searchValue,
        quoteId: quoteQuery?.quote.quoteId,
      });
    }
  }, [onChange, quoteQuery, searchValue]);

  useEffect(() => {
    setSavedReservationDetails(reservationDetails);
  }, [setSavedReservationDetails, reservationDetails]);

  const handleClose = useCallback(() => {
    setVisible(false);
    if (view === "confirmed") {
      onChange({
        physicalSpaceID: searchValue.physicalSpaceID,
      });
    } else {
      onChange(searchValue);
      setView("calendar");
    }
  }, [onChange, searchValue, view]);

  const handleCloseCalendar = useCallback(() => {
    setVisible(false);
    setView("calendar");
  }, []);

  const openCalendar = () => {
    setView("calendar");
    setVisible(true);
  };

  const handleChangeDateRange = useCallback(
    (newDateRange?: DateRange) => {
      onChange({
        ...searchValue,
        dateRange: newDateRange,
      });
      setError("");
    },
    [onChange, searchValue],
  );

  const handleReserve = useCallback(() => {
    if (
      dateRange === undefined ||
      validateDateRange(dateRange) ||
      !!quoteError
    ) {
      setView("calendar");
      setVisible(true);
      return;
    }

    if (hasReservationDetails(space)) {
      setView("reservation");
      setVisible(true);
      return;
    }

    setView("confirm");
    setVisible(true);
  }, [dateRange, space, quoteError]);

  const handlePressEdit = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSaveReservationDetails = useCallback(
    (details: ReservationDetailsInput) => {
      setReservationDetails(details);
      setView("confirm");
    },
    [],
  );

  const handleComplete = useCallback((orderId: string) => {
    setOrderId(orderId);
    setView("confirmed");
  }, []);

  const quoteErrorText = extractFirstGraphQLErrorMessage(quoteError);

  return (
    <Container>
      <SpaceQuoteDetailsMobile
        quoteError={quoteError}
        quoteLoading={quoteLoading}
        quoteQuery={quoteQuery}
        space={space}
        searchValue={searchValue}
        onReserve={handleReserve}
        onOpenCalendar={openCalendar}
        dateRange={dateRange}
      />
      <Dialog
        animationType="slide"
        visible={visible}
        onRequestClose={handleClose}
      >
        {view === "calendar" && (
          <BookingDateRangePickerDialog
            space={space}
            quoteQuery={quoteQuery}
            quoteError={quoteErrorText}
            error={error}
            searchValue={searchValue}
            onChangeDateRange={handleChangeDateRange}
            onClose={handleCloseCalendar}
            onReserve={handleReserve}
          />
        )}
        {view === "reservation" && (
          <ReservationDetailsDialog
            quoteQuery={quoteQuery}
            quoteError={quoteErrorText}
            space={space}
            initialValues={reservationDetails}
            onClose={handleClose}
            onReserve={handleSaveReservationDetails}
          />
        )}
        {view === "confirm" && (
          <ConfirmAndPayDialog
            monthlyBilling={monthlyBilling}
            reservationDetails={reservationDetails}
            space={space}
            onChangePromoCode={onChangePromoCode}
            stripe={stripe}
            quoteQuery={quoteQuery}
            quoteLoading={quoteLoading}
            quoteError={quoteErrorText}
            searchValue={searchValue}
            onClose={handleClose}
            onPressEdit={handlePressEdit}
            onComplete={handleComplete}
          />
        )}

        {view === "confirmed" && orderId && (
          <OffsiteOrderMobile orderId={orderId} onClose={handleClose} />
        )}
      </Dialog>
    </Container>
  );
}
