import { gql, useQuery } from "@apollo/client";
import {
  SavedLocationsQuery,
  SavedLocationsQueryVariables,
  SavedSpacesQuery,
  SavedSpacesQueryVariables,
} from "core/graphql.generated";

export const useSavedSpacesQuery = () => {
  const { data, loading, error } = useQuery<
    SavedSpacesQuery,
    SavedSpacesQueryVariables
  >(savedSpacesQuery, {
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
    error,
  };
};

export const useSavedLocationsQuery = () => {
  const { data, loading, error } = useQuery<
    SavedLocationsQuery,
    SavedLocationsQueryVariables
  >(savedLocationsQuery, {
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
    error,
  };
};

export const savedSpacesQuery = gql`
  query SavedSpaces {
    currentUser {
      id
      favoriteSpaces {
        ...SavedSpaces__SpaceDetails
      }
    }
  }

  fragment SavedSpaces__SpaceDetails on Space {
    id
    spaceType
    savedByCurrentUser
    name
    streetAddress
    city
    preferred
    locationName
    numberOfVisitsByCurrentUser
    images {
      small {
        url
        width
        height
      }
    }
  }
`;

export const savedLocationsQuery = gql`
  query SavedLocations {
    currentUser {
      id
      favoriteLocations {
        ...SavedLocations__LocationDetails
      }
    }
  }

  fragment SavedLocations__LocationDetails on Location {
    id
    savedByCurrentUser
    name
    preferred
    numberOfVisitsByCurrentUser
    address {
      streetAddress
      city
      latitude
      longitude
    }
    images {
      small {
        url
        width
        height
      }
      medium {
        url
        width
        height
      }
    }
  }
`;
