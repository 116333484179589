import { useQuery } from "@apollo/client";
import React, { Fragment, useState } from "react";
import { StyleSheet, View } from "react-native";
import { InAppInvite } from "components/in_app_invite";
import { LinkWrapper } from "components/link_wrapper";
import { colors } from "components/colors";
import {
  AppHeaderV3DesktopQuery,
  GroupBudgetRole,
  Plan,
  UserRole,
} from "core/graphql.generated";
import { ProfileDropDownMenu } from "core/profile_dropdown_menu";
import { useMediaQuery } from "lib/media_query";
import { NavigationMenuButton } from "./app_header_v3/app_header";
import { desktopHeaderQuery } from "./app_header_v3/desktop_header";
import "../core/header.css";

export const ADMIN_HEADER_HEIGHT = 72;

export function AdminHeader() {
  const mq = useMediaQuery();
  const [showAppInvite, setShowAppInvite] = useState(false);
  const { data: currentUserQuery } =
    useQuery<AppHeaderV3DesktopQuery>(desktopHeaderQuery);
  const currentUser = currentUserQuery?.currentUser;

  const isAdmin = currentUser?.role === UserRole.Admin;
  const isPro = currentUser?.organization?.plan === Plan.Pro;
  const isBudgetManager =
    currentUser?.groupBudgetRole === GroupBudgetRole.Manager;

  if (mq.deviceQuery.mobile) {
    return null;
  }

  return (
    <>
      <View style={styles.header}>
        <View style={styles.headerInnerWrapper}>
          <LinkWrapper to="/">
            <img
              alt="flexspace-logo"
              src="https://meet-production.s3.us-west-1.amazonaws.com/logos/flexspace-logo-default.svg"
              style={{ height: 36 }}
            />
          </LinkWrapper>
          <div className="header-navigation-menu">
            {isAdmin && (
              <Fragment>
                {isPro && (
                  <NavigationMenuButton to="/admin/today" title="Today" />
                )}
                {isPro && (
                  <NavigationMenuButton to={"/admin/budgets"} title="Budgets" />
                )}
                {!isPro && (
                  <NavigationMenuButton
                    to={"/admin/analytics"}
                    title="Analytics"
                  />
                )}
                <NavigationMenuButton to="/admin/manage-users" title="Users" />
                <NavigationMenuButton
                  to="/admin/organization-profile"
                  title="Organization settings"
                />
              </Fragment>
            )}
          </div>
          <View style={{ minWidth: 189 }}>
            {currentUser && (
              <ProfileDropDownMenu
                currentUser={currentUser}
                isAdmin={isAdmin}
                onInviteClick={setShowAppInvite}
                isPro={isPro}
                isBudgetManager={isBudgetManager}
              />
            )}
          </View>
        </View>
      </View>
      <InAppInvite
        isVisible={showAppInvite}
        onClose={() => setShowAppInvite(false)}
        analyticsFrom="Primary navigation"
        onSuccess={() => setShowAppInvite(false)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  buttonsAndAvatar: {
    flexDirection: "row",
    alignItems: "center",
    gap: 24,
  },
  button: {
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
    height: 40,
  },
  searchAndFilter: {
    minWidth: 400,
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: colors.brand.blackMinus90,
  },
  headerInnerWrapper: {
    boxSizing: "border-box",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    height: ADMIN_HEADER_HEIGHT,
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    zIndex: 2,
    backgroundColor: colors.brand.whitecore,
  },
});
