import "./text_field.web.css";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { TextField } from "components/text_field";
import { TextLink } from "components/text_link";
import { Checkbox } from "components/checkbox";
import { Icon } from "./icon";
import { Spacer } from "./spacer";
import { Text } from "./text_v2";

interface Field {
  value: string;
  invalidText?: string;
  invalid?: boolean;
  isChecked?: boolean;
  placeholder?: string;
}

interface PropTypes {
  initialNumOfFields?: number;
  withCheckbox?: boolean;
  fields: Field[];
  onChange?: (arg: Field[]) => void;
  onSubmit?: (arg: Field[]) => void;
  canAddMore?: boolean;
  invalidText?: string;
  label?: string;
  checkboxLabel?: string;
}

const defaultPlaceholder = "name@company.com";

const defaultField = {
  value: "",
  invalidText: "",
  invalid: false,
  isChecked: false,
  placeholder: defaultPlaceholder,
};

export const MultiInputsField = (props: PropTypes) => {
  const {
    initialNumOfFields = 3,
    withCheckbox,
    fields: fieldsProp,
    onChange,
    onSubmit,
    canAddMore,
    invalidText,
    label = "Enter emails",
    checkboxLabel = "Admin",
  } = props;

  const [fields, setFields] = useState(
    fieldsProp || new Array(initialNumOfFields).fill(defaultField),
  );

  const submit = () => onSubmit?.(fields);

  return (
    <View>
      <View style={styles.rowWrapper}>
        <View style={styles.fieldWrapper}>
          <Text size="xs" weight="semibold">
            {label}
          </Text>
        </View>
        {withCheckbox && (
          <View style={styles.checkboxWrapper}>
            <Text size="xs" weight="semibold">
              {checkboxLabel}
            </Text>
          </View>
        )}
      </View>
      <Spacer size={8} />
      {fields.map((field, fieldIndex) => (
        <View key={fieldIndex}>
          <View style={styles.rowWrapper} key={`${fieldIndex}-input`}>
            <View style={styles.fieldWrapper}>
              <TextField
                value={field.value}
                onChange={(email) => {
                  const newFields = [...fields];
                  newFields[fieldIndex].value = email.trim();
                  setFields(newFields);
                  onChange?.(newFields);
                }}
                testID={`invite-email-${fieldIndex}`}
                inputPlaceholder={field.placeholder || defaultPlaceholder}
                invalid={field.invalid}
                invalidText={field.invalidText}
                onSubmitEditing={submit}
              />
            </View>
            {withCheckbox && (
              <View style={styles.checkboxWrapper}>
                <Checkbox
                  appearance="box"
                  value={field.isChecked}
                  size="sm"
                  testId={`admin-checkbox-${fieldIndex}`}
                  onChange={() => {
                    const newFields = [...fields];
                    newFields[fieldIndex].isChecked = !field.isChecked;
                    setFields(newFields);
                    onChange?.(newFields);
                  }}
                />
              </View>
            )}
          </View>
          <Spacer size={16} />
        </View>
      ))}

      {invalidText && (
        <View>
          <Text color="grapefruit-core" size="sm">
            {invalidText}
          </Text>
          <Spacer size={16} />
        </View>
      )}

      {canAddMore && (
        <View style={{ flexDirection: "row" }}>
          <TextLink
            onPress={() => {
              setFields([...fields, defaultField]);
            }}
            text={
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Icon name="plus-circle" />
                <Spacer direction="row" size={8} />
                Add another
              </View>
            }
            inline
            size="xs"
            decoration="none"
            weight="bold"
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  rowWrapper: {
    flexDirection: "row",
  },
  fieldWrapper: {
    flex: 8,
  },
  checkboxWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
