import { CardsSaveIcon } from "components/cards_save_icon";
import { colors } from "components/colors";
import { LinkWrapper } from "components/link_wrapper";
import { PreferredBadge } from "components/preferred_badge";
import { HomePageLocations__LocationDetailsFragment } from "core/graphql.generated";
import { uniqueBy } from "lib/array_utils";
import { sortAmenities } from "lib/sort_amenities";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { placeholderLocationImage } from "pages/location_details/utils/get_placeholder_location_image";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";

import {
  LocationListCardAddressWithDistance,
  SpaceCardAmenities,
  SpaceCardContent,
  SpaceCardPhoto,
} from "./space_card_v2/space_card_template";

interface LocationCardProps {
  location: HomePageLocations__LocationDetailsFragment;
}

export function LocationCard(props: LocationCardProps) {
  const { location } = props;
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  const { amenities } = useHomeSearchParamsQuery();
  const sortedAmenities = useMemo(
    () =>
      amenities
        ? uniqueBy(
            sortAmenities([], location.amenities, amenities),
            (item) => item.name,
          )
        : [],
    [amenities, location.amenities],
  );
  return (
    <View style={[styles.mainWrapper]} testID="space-card-template">
      <LinkWrapper to={`/location/${location.id}`}>
        <View style={styles.relativeContainer}>
          <SpaceCardPhoto
            imageSrc={location.images[0]?.small.url || placeholderLocationImage}
          />
          <CardsSaveIcon
            favoriteId={location.id}
            favoriteType={FavoriteType.Location}
          />
        </View>
        <SpaceCardContent>
          <LocationListCardAddressWithDistance
            currentUserDistance={null}
            locationLat={location.address.latitude}
            locationLng={location.address.longitude}
            shortAddress={location.address.city}
            fullAddress={location.address.streetAddress}
            locationName={location.name}
          />
          {sortedAmenities.length > 0 && (
            <SpaceCardAmenities amenities={sortedAmenities} />
          )}
          {isPreferredFlag && location.preferred && (
            <PreferredBadge variant={"preferredOnly"} />
          )}
        </SpaceCardContent>
      </LinkWrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  relativeContainer: {
    position: "relative",
  },
});
