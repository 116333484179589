import { gql, useQuery } from "@apollo/client";
import { Container } from "components/container";
import { Heading } from "components/heading";
import { LabeledSwitch } from "components/labeled_switch";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { TextInput } from "components/text_input";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { usePrevious } from "hooks/use_previous";
import { useMediaQuery } from "lib/media_query";
import { isNumberString } from "lib/number_utils";
import { useOrderMemoFeatureFlag } from "providers/splitio";
import React, { Fragment, useEffect, useRef } from "react";
import { Image, TextInput as RNTextInput } from "react-native";
import { CheckoutInput } from "./booking_utils";
import {
  CurrentUserInfoQuery,
  Plan,
  SpaceDetails__AllDetailsFragment,
} from "./graphql.generated";
import { ResponsiveGrid } from "./responsive_grid";

interface BookingCheckoutProps {
  space: SpaceDetails__AllDetailsFragment;
  onPressLayoutChange: () => void;
  values: CheckoutInput;
  submitCount: number;
  setFieldValue: <K extends keyof CheckoutInput>(
    field: keyof CheckoutInput,
    value: CheckoutInput[K],
  ) => void;
  errors: {
    [key in keyof CheckoutInput]?: string;
  };
  handleChange: (
    field: keyof CheckoutInput,
  ) => <K extends keyof CheckoutInput>(value: CheckoutInput[K]) => void;
}

export function BookingCheckout(props: BookingCheckoutProps) {
  const {
    space,
    values,
    setFieldValue,
    errors,
    handleChange,
    onPressLayoutChange,
    submitCount,
  } = props;
  const isMemoFlag = useOrderMemoFeatureFlag();
  const mq = useMediaQuery();
  const prevSubmitCount = usePrevious(submitCount);
  const contactNameRef = useRef<RNTextInput>(null);
  const contactEmailRef = useRef<RNTextInput>(null);
  const contactPhoneNumberRef = useRef<RNTextInput>(null);
  const guestNameRef = useRef<RNTextInput>(null);
  const companyNameRef = useRef<RNTextInput>(null);
  const guestEmailRef = useRef<RNTextInput>(null);
  const guestPhoneNumberRef = useRef<RNTextInput>(null);
  const { data, loading } = useQuery<CurrentUserInfoQuery>(
    currentUserInfoGQLQuery,
  );
  const currentUser = data?.currentUser;
  const isPro = currentUser?.organization?.plan === Plan.Pro;
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (!currentUser.hasBookedBefore) {
      if (currentUser.hasSignedUpWithSocialProvider) {
        guestNameRef.current?.focus();
      } else {
        companyNameRef.current?.focus();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (submitCount === prevSubmitCount) {
      return;
    }

    if (errors.guestContactFullName) {
      guestNameRef.current?.focus();
    } else if (errors.guestContactEmail) {
      guestEmailRef.current?.focus();
    } else if (errors.guestContactPhoneNumber) {
      guestPhoneNumberRef.current?.focus();
    } else if (errors.meetingContactFullName) {
      contactNameRef.current?.focus();
    } else if (errors.meetingContactEmail) {
      contactEmailRef.current?.focus();
    } else if (errors.meetingContactPhoneNumber) {
      contactPhoneNumberRef.current?.focus();
    }
  }, [prevSubmitCount, contactNameRef, errors, submitCount]);

  const {
    guestContactFullName,
    guestContactCompanyName,
    guestContactEmail,
    guestContactPhoneNumber,
    bookingOnBehalf,
    meetingContactFullName,
    meetingContactEmail,
    meetingContactPhoneNumber,
    layoutID,
    memo,
  } = values;

  const selectedLayout = space.layouts.find((l) => l.id === layoutID);
  const hasSavedUserDetails = !!(
    currentUser?.fullName &&
    currentUser?.email &&
    currentUser?.organization?.name &&
    currentUser?.phoneNumber
  );

  if (!currentUser || loading) {
    return <Spinner />;
  }

  return (
    <Container>
      {space.layouts.length > 1 && selectedLayout && (
        <Fragment>
          <Container>
            <Row alignItems="center" justifyContent="space-between">
              <Heading size="h3">Your room layout</Heading>
              <TextLink size="xs" onPress={onPressLayoutChange} text="Change" />
            </Row>
            <Spacer size={4} />
            <Container>
              <Row alignItems="center">
                <Image
                  source={{
                    uri: selectedLayout.imageURL,
                    width: 40,
                    height: 40,
                  }}
                  style={{
                    borderRadius: tokens.radius,
                    borderWidth: 1,
                    borderColor: tokens.colors.neutral.dark,
                  }}
                />
                <Spacer size={8} />
                <Text>{selectedLayout.name}</Text>
                <Spacer size={8} />
                <Text>(up to {selectedLayout.capacity} people)</Text>
              </Row>
            </Container>
          </Container>
          <Spacer size={24} />
        </Fragment>
      )}
      {!hasSavedUserDetails && (
        <Fragment>
          <Heading size="h3">Your details</Heading>
          <Spacer size={16} />
          <ResponsiveGrid itemsPerRow={mq.sizeQuery.lgAndUp ? 2 : 1}>
            {!currentUser.hasSignedUpWithSocialProvider && (
              <TextInput
                name="fullName"
                ref={guestNameRef}
                invalid={!!errors.guestContactFullName}
                invalidText={errors.guestContactFullName}
                textContentType="givenName"
                value={guestContactFullName}
                testID="input-guest-full-name"
                onChange={handleChange("guestContactFullName")}
                label="Name"
              />
            )}
            <TextInput
              name="companyName"
              ref={companyNameRef}
              textContentType="organizationName"
              testID="input-guest-company-name"
              value={guestContactCompanyName}
              onChange={handleChange("guestContactCompanyName")}
              label="Company"
            />
            {!currentUser.hasSignedUpWithSocialProvider && (
              <TextInput
                name="email"
                ref={guestEmailRef}
                invalid={!!errors.guestContactEmail}
                invalidText={errors.guestContactEmail}
                textContentType="emailAddress"
                value={guestContactEmail}
                keyboardType="email-address"
                testID="input-guest-email-address"
                onChange={handleChange("guestContactEmail")}
                label="Email"
                description={
                  !errors.guestContactEmail
                    ? "We use this to send you emails like your reservation confirmation."
                    : ""
                }
              />
            )}
            <TextInput
              name="phoneNumber"
              ref={guestPhoneNumberRef}
              invalid={!!errors.guestContactPhoneNumber}
              invalidText={errors.guestContactPhoneNumber}
              value={guestContactPhoneNumber}
              textContentType="telephoneNumber"
              testID="input-guest-phone-number"
              keyboardType="number-pad"
              onChange={(value) => {
                if (!isNumberString(value)) {
                  return;
                }
                setFieldValue("guestContactPhoneNumber", value);
              }}
              label="Phone Number"
              description={
                !errors.guestContactPhoneNumber
                  ? "We use this number to contact you about your reservation."
                  : ""
              }
            />
          </ResponsiveGrid>
          <Spacer size={24} />
        </Fragment>
      )}
      <Spacer size={16} />
      <Row>
        <LabeledSwitch
          value={bookingOnBehalf}
          onChange={(value) => setFieldValue("bookingOnBehalf", value)}
          label="Are you booking for someone else?"
        />
      </Row>
      {bookingOnBehalf && (
        <Fragment>
          <Spacer size={16} />
          <ResponsiveGrid itemsPerRow={mq.sizeQuery.lgAndUp ? 2 : 1}>
            <TextInput
              invalid={!!errors.meetingContactFullName}
              invalidText={errors.meetingContactFullName}
              textContentType="givenName"
              value={meetingContactFullName}
              onChange={handleChange("meetingContactFullName")}
              label="Contact name"
              ref={contactNameRef}
            />
            <TextInput
              invalid={!!errors.meetingContactEmail}
              invalidText={errors.meetingContactEmail}
              textContentType="emailAddress"
              value={meetingContactEmail}
              keyboardType="email-address"
              onChange={handleChange("meetingContactEmail")}
              label="Email"
              ref={contactEmailRef}
            />
            <TextInput
              invalid={!!errors.meetingContactPhoneNumber}
              invalidText={errors.meetingContactPhoneNumber}
              value={meetingContactPhoneNumber}
              textContentType="telephoneNumber"
              keyboardType="number-pad"
              onChange={(value) => {
                if (!isNumberString(value)) {
                  return;
                }
                setFieldValue("meetingContactPhoneNumber", value);
              }}
              label="Phone Number"
              ref={contactPhoneNumberRef}
            />
          </ResponsiveGrid>
        </Fragment>
      )}

      {isMemoFlag && isPro && (
        <>
          <Spacer size={24} />
          <Text weight="semibold" size="xs">
            Memo
          </Text>
          <Spacer size={8} />
          <TextInput
            name="memo"
            value={memo || ""}
            placeholder={"What was this booking for?"}
            onChange={(value) => setFieldValue("memo", value)}
            numberOfLines={2}
            testID="memo-input"
          />
        </>
      )}
    </Container>
  );
}

export function CheckoutTerms() {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  return (
    <Text size="sm" align={isMobile ? "center" : "left"}>
      By continuing, I agree to the Flexspace{" "}
      <a
        target="_blank"
        rel="noreferrer"
        style={{ color: tokens.colors.utility.regular }}
        href="https://flexspace-ds-static.s3.us-west-1.amazonaws.com/flexspace-teams-tc.pdf"
      >
        <Text size="sm">Terms of Service</Text>
      </a>{" "}
      and{" "}
      <a
        target="_blank"
        rel="noreferrer"
        style={{ color: tokens.colors.utility.regular }}
        href="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-privacy.pdf"
      >
        <Text size="sm">Privacy Policy</Text>
      </a>
    </Text>
  );
}

export const currentUserInfoGQLQuery = gql`
  query CurrentUserInfo {
    currentUser {
      id
      hasBookedBefore
      hasSignedUpWithSocialProvider
      fullName
      email
      phoneNumber
      organization {
        id
        name
        plan
      }
    }
  }
`;
