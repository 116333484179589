import { Button } from "components/button_v2";
import { Fragment, useCallback, useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { Container } from "components/container";
import { Divider } from "components/divider";
import { Icon } from "components/icon";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Tabs } from "components/tab/tabs";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { WeekDates } from "components/week_dates";
import { DateRange } from "core/booking_date_range_picker";
import {
  BookingDateRangePickerMobile,
  BookingTimeRangePicker,
} from "core/booking_date_range_picker_mobile";
import {
  getDateRangeForNextBookingType,
  isDateRangeDisabled,
  SearchValue,
  useBookingDateRangePickerHelper,
} from "core/booking_utils";
import {
  BookingType,
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  PricingType,
  QuoteDetailsQuery,
} from "core/graphql.generated";
import { useBestDayRatesQuery } from "../hooks/use_best_day_rates_query";

interface BookingDateRangePickerDialogProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  quoteQuery?: QuoteDetailsQuery;
  quoteError?: string;
  searchValue: SearchValue;
  error?: string;
  onChangeDateRange: (value?: DateRange) => void;
  onClose: () => void;
  onReserve: () => void;
}

export function BookingDateRangePickerDialog(
  props: BookingDateRangePickerDialogProps,
) {
  const { space, onChangeDateRange, searchValue, quoteError, onClose } = props;
  const { dateRange, physicalSpaceID } = searchValue;
  const [newDateRange, setNewDateRange] = useState<DateRange | undefined>(
    dateRange,
  );

  /*  Old flow: When ever user just pick the date on calendar we call onChangeDateRange immediately.
  But for new flow (Dynamic Pricing) I created the temp state/setState at here
   for user temporary set the Date first -> when they click Done we will call the onChangeDateRange*/
  const tempDateRange = newDateRange;
  const setTempDateRange = setNewDateRange;

  const {
    initialBookingType,
    showSegmentedControl,
    timeslots,
    isBlockedDate,
    isOutsideRange,
    availableTimeSlotsError,
    availableDaysError,
  } = useBookingDateRangePickerHelper({
    space,
    physicalSpaceID,
    selectedDay:
      tempDateRange?.type === BookingType.HourlyBooking
        ? tempDateRange.date
        : undefined,
  });
  const [betterRateTimeSlots, setBetterRateTimeSlots] = useState(timeslots);
  const [bookingType, setBookingType] =
    useState<BookingType>(initialBookingType);

  const bestDayRates = useBestDayRatesQuery(
    space.id,
    bookingType,
    space.location.timezone,
  );

  const handleChangeBookingType = useCallback(
    (b: BookingType) => {
      setBookingType(b);

      onChangeDateRange(getDateRangeForNextBookingType(tempDateRange, b));
    },
    [tempDateRange, onChangeDateRange],
  );
  const handleClear = () => {
    onChangeDateRange(undefined);
    setTempDateRange(undefined);
  };

  const onDone = () => {
    if (tempDateRange) {
      onChangeDateRange(tempDateRange);
      onClose();
    }
  };

  return (
    <Container
      color="content"
      flex={1}
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
    >
      <Container padding={20}>
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Pressable
            style={{
              position: "absolute",
              right: 0,
            }}
            onPress={onClose}
          >
            <Icon color={tokens.colors.base.black} name="x-circle" />
          </Pressable>
          <Spacer size={8} />
          <Text weight="600" size="sm">
            When do you need the space?
          </Text>
        </View>
      </Container>
      <Divider />
      <Container paddingHorizontal={20} paddingVertical={12}>
        {showSegmentedControl === true && (
          <Fragment>
            <Tabs
              value={bookingType}
              onChange={handleChangeBookingType}
              options={[
                { value: BookingType.DailyBooking, label: "Daily" },
                { value: BookingType.HourlyBooking, label: "Hourly" },
              ]}
            />
            <Spacer size={8} />
          </Fragment>
        )}

        <WeekDates />
      </Container>
      <Divider />
      <ScrollView>
        <Container paddingVertical={8} paddingHorizontal={20}>
          <BookingDateRangePickerMobile
            bookingType={bookingType}
            onChangeBookingType={handleChangeBookingType}
            showSegmentedControl={showSegmentedControl}
            value={tempDateRange}
            onChange={setTempDateRange}
            isOutsideRange={isOutsideRange}
            isBlockedDate={isBlockedDate}
            timeslots={timeslots}
            pricings={space.pricings}
            dynamicPrices={bestDayRates}
            currency={space.currency}
            spaceId={space.id}
            priceRanges={space.priceRanges}
            onChangeBetterRateTimeSlots={setBetterRateTimeSlots}
          />
        </Container>
      </ScrollView>
      <Divider />
      <Container padding={8}>
        {bookingType === BookingType.HourlyBooking && (
          <BookingTimeRangePicker
            value={
              tempDateRange?.type === BookingType.HourlyBooking
                ? tempDateRange
                : undefined
            }
            onChange={setTempDateRange}
            timeslots={betterRateTimeSlots}
            spaceId={space.id}
            basePrice={
              space.pricings.find(
                (pricing) => pricing.type === PricingType.Hourly,
              )?.price
            }
          />
        )}
        {quoteError && (
          <Fragment>
            <Spacer size={16} />
            <Text color="error">{quoteError}</Text>
          </Fragment>
        )}
        {(availableDaysError || availableTimeSlotsError) && (
          <Fragment>
            <Spacer size={16} />
            <Text color="error">
              There are issues getting space's availability. Please try again
              later.
            </Text>
          </Fragment>
        )}
        <Spacer size={16} />
        <Row alignItems="center" justifyContent="space-between">
          <Button
            disabled={isDateRangeDisabled(tempDateRange)}
            text="Clear all"
            appearance={"textLink"}
            onPress={handleClear}
            testID="date-picker-clear-button"
            size="small"
          />

          <Spacer size={24} />
          <View style={{ paddingRight: 20 }}>
            <Button
              disabled={isDateRangeDisabled(tempDateRange)}
              text="Done"
              onPress={onDone}
              testID="date-picker-save-button"
              size="small"
            />
          </View>
        </Row>
      </Container>
    </Container>
  );
}
