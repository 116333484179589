import { gql, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { Spacer } from "components/spacer";

import { Text } from "components/text_v2";
import { PaymentDetail, UserInfoQuery } from "core/graphql.generated";
import React from "react";
import { Image, StyleSheet, View } from "react-native";

interface BookingDetailPaymentInfoProps {
  monthlyBilling?: boolean;
  paymentDetail?: PaymentDetail | null;
  totalPrice?: string;
}

export function BookingDetailPaymentInfo({
  monthlyBilling,
  paymentDetail,
  totalPrice,
}: BookingDetailPaymentInfoProps) {
  const { data } = useQuery<UserInfoQuery>(userInfoQuery);
  const [openModal, setOpenModal] = React.useState(false);
  if (monthlyBilling) {
    return (
      <View>
        <Text size="md" weight="semibold">
          Payment
        </Text>
        <Spacer size={16} />
        <Text size="xs">Billed to your organization</Text>
      </View>
    );
  }
  const { paymentMethodDetails, billingDetails } = paymentDetail || {};
  if (paymentMethodDetails && billingDetails) {
    const { networkLogoUrl, last4 } = paymentMethodDetails;
    const {
      name,
      phone,
      email,
      address: { line1, line2 },
    } = billingDetails;
    return (
      <View>
        <Text size="md" weight="semibold">
          Payment
        </Text>
        <Spacer size={16} />
        <View style={styles.cardView}>
          <Image source={{ uri: networkLogoUrl, width: 38, height: 24 }} />
          <Text size="xs">Ending with {last4}</Text>
          <Button
            appearance={"textLink"}
            onPress={() => setOpenModal(true)}
            text={"Billing detail"}
          />
        </View>

        <DialogModal
          isVisible={openModal}
          onClose={() => setOpenModal(false)}
          headerTitle={"Billing detail"}
          desktopWidth={600}
        >
          <View style={styles.paymentRow}>
            <View style={styles.leftElement}>
              <Text color={"black-50"}>Payment method</Text>
            </View>
            <View style={styles.rightElement}>
              <Text color={"black-50"}>Total paid</Text>
            </View>
          </View>
          <Spacer size={4} />
          <View style={styles.paymentRow}>
            <View style={styles.leftElement}>
              <View style={styles.cardView}>
                <Image
                  source={{ uri: networkLogoUrl, width: 38, height: 24 }}
                />
                <Text size="xs">Ending with {last4}</Text>
              </View>
            </View>

            <View style={styles.rightElement}>
              <Text size="xs">{totalPrice}</Text>
            </View>
          </View>
          <Spacer size={16} />
          <View style={styles.paymentRow}>
            <View style={styles.leftElement}>
              <Text color={"black-50"}>Contact details</Text>
            </View>
            {(line1 || line2 || data?.currentUser?.city?.displayName) && (
              <View style={styles.rightElement}>
                <Text color={"black-50"}>Billing details</Text>
              </View>
            )}
          </View>
          <Spacer size={4} />
          <View style={styles.paymentRow}>
            <View style={styles.leftElement}>
              <Text size="xs">{name || data?.currentUser?.fullName}</Text>
              <Text size="xs">{phone || data?.currentUser?.phoneNumber}</Text>
              <Text size="xs">{email || data?.currentUser?.email}</Text>
            </View>
            <View style={styles.rightElement}>
              <Text size="xs">
                {line1 || line2 || data?.currentUser?.city?.displayName}
              </Text>
            </View>
          </View>
        </DialogModal>
      </View>
    );
  }

  return null;
}

const styles = StyleSheet.create({
  cardView: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  paymentRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  leftElement: {
    flex: 3,
  },
  rightElement: {
    flex: 2,
  },
});

const userInfoQuery = gql`
  query UserInfo {
    currentUser {
      fullName
      email
      phoneNumber
      city {
        displayName
      }
    }
  }
`;
