import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import { StyleSheet, View } from "react-native";

type TitleSpaceListProps = {
  title: string;
  subtitle?: string;
};
export function TitleSpaceList({ title, subtitle }: TitleSpaceListProps) {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <View style={styles.titleView}>
      <Text size={isMobile ? "md" : "lg"} weight={"semibold"}>
        {title}
      </Text>
      {subtitle && (
        <Text size={isMobile ? "micro" : "xs"} color={"black-70"}>
          {subtitle}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  titleView: {
    flexDirection: "column",
    gap: 4,
  },
});
