import { ScrollView } from "react-native";
import { Button } from "components/button_v2";
import { Column } from "components/column";
import { Container } from "components/container";
import { Row } from "components/row";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import {
  ReservationDetailsInput,
  useReservationDetailsForm,
} from "core/booking_utils";
import { DialogContent } from "core/dialog_content";
import {
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  QuoteDetailsQuery,
} from "core/graphql.generated";
import { ReservationDetailsForm } from "core/reservation_details";
import { formatCurrency } from "lib/currency";
import { getSystemLocale } from "lib/locale";

export interface ReservationDetailsDialogProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  quoteQuery?: QuoteDetailsQuery;
  quoteError?: string;
  initialValues: ReservationDetailsInput;
  onClose: () => void;
  onReserve: (input: ReservationDetailsInput) => void;
}

export function ReservationDetailsDialog(props: ReservationDetailsDialogProps) {
  const { space, initialValues, quoteQuery, onClose, onReserve } = props;

  const { setFieldValue, values, disabled, submit } = useReservationDetailsForm(
    {
      space,
      initialValues,
      maxCapacity: space.maxCapacity,
      onSubmit: (v) => {
        onReserve(v);
      },
    },
  );

  return (
    <DialogContent
      headerLeftIcon="x-circle"
      headerTitle="Reservation Details"
      onHeaderLeftIconPress={onClose}
    >
      <ScrollView>
        <Container padding={16}>
          <ReservationDetailsForm
            space={space}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Container>
      </ScrollView>
      <Container
        borderColor={tokens.colors.neutral.darker}
        borderTopWidth={1}
        shadow
        padding={16}
      >
        <Row justifyContent="space-between">
          {quoteQuery && (
            <Column justifyContent="center">
              <Text size="sm" weight="600">
                {formatCurrency(
                  quoteQuery.quote.totalPrice,
                  getSystemLocale(),
                  space.currency,
                )}
              </Text>
              <Text align="right" size="sm" color="muted">
                Total
              </Text>
            </Column>
          )}
          <Button disabled={disabled} onPress={submit} text="Reserve" />
        </Row>
      </Container>
    </DialogContent>
  );
}
