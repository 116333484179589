import { gql } from "@apollo/client";

export const quoteGQLQuery = gql`
  query QuoteDetails($input: QuoteInput!) {
    quote(input: $input) {
      quoteId
      totalPrice
      subTotal
      processingFee
      outOfPolicyMessage
      promotionLine {
        amount
        description
        code
        discount
      }
      taxLine {
        amount
        description
        percentage
      }
      orderItems {
        ...QuoteDetail__OrderItem
      }
      quoteItems {
        quoteId
        unitPrice
        totalPrice
        originalTotalPrice
        quoteItemPrices {
          quoteItemId
          unitPrice
          unitCount
          unitType
          originalUnitPrice
          startDate
          endDate
          dynamicPriceRate {
            id
          }
        }
        dynamicPriceRate {
          id
        }
      }
    }
  }

  fragment QuoteDetail__OrderItem on OrderItem {
    id
    totalPrice
    subTotal
    unitPrice
    quantity
    details {
      __typename
      ...QuoteDetails__DailyBookingDetails
      ...QuoteDetails__HourlyBookingDetails
    }
  }

  fragment QuoteDetails__HourlyBookingDetails on HourlyBooking {
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    date
    startTime
    endTime
    space {
      id
      name
      spaceType
    }
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
  }

  fragment QuoteDetails__DailyBookingDetails on DailyBooking {
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    startDate
    endDate
    days
    time
    space {
      id
      name
      spaceType
    }
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
  }
`;

export const spaceGQLQuery = gql`
  query SpaceDetails($id: ID!) {
    space(id: $id) {
      id
      ...SpaceDetails__AllDetails
    }
  }

  fragment SpaceDetails__AllDetails on Space {
    id
    partnerID
    name
    spaceType
    description
    currency
    defaultLayoutID
    savedByCurrentUser
    bookingConfig {
      enabled
      multiDayBookingAllowed
    }
    categories {
      code
      name
      description
    }
    location {
      id
      name
      logo {
        url
      }
      timezone
      address {
        fullAddress
        streetAddress
        postalCode
        country
        city
        state
        latitude
        longitude
      }
      pricingInclusion
      directions
    }
    images {
      thumb {
        url
        width
        height
      }
      small {
        url
        width
        height
      }
      medium {
        url
        width
        height
      }
      large {
        url
        width
        height
      }
    }
    amenities {
      id
      name
      iconURL
      code
    }
    layouts {
      id
      name
      description
      bestFor
      capacity
      imageURL
    }
    inventoryCapacity
    area
    maxCapacity
    pricings {
      type
      price
    }
    bookingHours {
      dayOfWeek
      closedAt
      openAt
    }
    bookingPolicy {
      minDuration
      cancellationHours
      cancellationTime1DayBefore
      cancellationDays
    }
    locationID
    locationTimeZone
    spacePerks {
      code
      name
      description
      iconURL
    }
  }
`;

export const currentUserGQLQuery = gql`
  query CurrentUser {
    currentUser {
      ...CurrentUser__MainDetails
      ...CurrentUser__Promotions
      ...CurrentUser__Organization
    }
  }

  fragment CurrentUser__MainDetails on CurrentUser {
    id
    role
    fullName
    email
    phoneNumber
    title
    auth0PrimaryEmail
    auth0PrimaryEmailVerified
    paymentCustomerID
    picture
    hasSignedUpWithSocialProvider
    hasBookedBefore
    createdWith
  }

  fragment CurrentUser__Promotions on CurrentUser {
    promotions {
      id
      promotionCode
      promotionKind
      description
      longDescription
    }
  }

  fragment CurrentUser__Organization on CurrentUser {
    organization {
      id
      name
      domain
      monthlyBilling
      createdAt
      paymentMode
      createdWith
    }
  }
`;

// Access token required in header authorization
export const personGQLQuery = gql`
  query Person {
    person {
      id
      userId
      organizationId
    }
  }
`;

export const sideBarGQLQuery = gql`
  query SideBar {
    currentUser {
      id
      role
      fullName
      email
      phoneNumber
      organization {
        id
      }
      picture
    }
  }
`;
