import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";
import { StyleSheet, View } from "react-native";

export function HomeV3Skeleton() {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <Homev3SkeletonMobile />;
  }

  return <HomeV3SkeletonDesktop />;
}

function HomeV3SkeletonDesktop() {
  return (
    <View style={styles.container}>
      <ContentLoader
        speed={2}
        width={"100%"}
        height={72}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={"100%"} height={72} />
      </ContentLoader>
      <Spacer size={40} />
      <ContentLoader
        speed={2}
        width={1088}
        height={428}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={1088} height={428} />
      </ContentLoader>
      <Spacer size={40} />
      <ContentLoader
        speed={2}
        width={1088}
        height={300}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={1088} height={300} />
      </ContentLoader>
      <Spacer size={24} />

      <ContentLoader
        speed={2}
        width={1088}
        height={300}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={1088} height={300} />
      </ContentLoader>
    </View>
  );
}

function Homev3SkeletonMobile() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width={"100%"}
        height={275}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={"100%"} height={275} />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width={"100%"}
        height={250}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={"100%"} height={250} />
      </ContentLoader>
      <Spacer size={24} />

      <ContentLoader
        speed={2}
        width={"100%"}
        height={250}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={"100%"} height={250} />
      </ContentLoader>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
});
