import { GridProps } from "./gridv2";

export function GridV2(props: GridProps) {
  const { children, columns, columnGap = 16, rowGap = 16 } = props;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        columnGap,
        rowGap,
      }}
    >
      {children}
    </div>
  );
}
