import { gql, useQuery } from "@apollo/client";
import { postAuthRedirectUrl } from "providers/auth_loader";
import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { StepFinalQuery } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { Fragment, useEffect } from "react";
import { Image, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

export function SignUpStepFinal() {
  const analytics = useAnalytics();
  const mq = useMediaQuery();
  const history = useHistory();

  const { data } = useQuery<StepFinalQuery>(stepFinalGQLQuery);

  useEffect(() => {
    if (data?.currentUser) {
      analytics.event("Sign Up", {
        Step: "Sign up complete",
      });
    }
  }, [analytics, data]);

  function onNextClick() {
    const postAuthenticationRedirectUrl = postAuthRedirectUrl.get();
    if (postAuthenticationRedirectUrl) {
      const redirectUrl = new URL(
        window.location.origin + postAuthenticationRedirectUrl,
      );
      redirectUrl.searchParams.set("showTour", "1");
      history.push(redirectUrl.pathname + redirectUrl.search);
      return postAuthRedirectUrl.reset();
    }

    history.push("/?showTour=1");
  }

  return (
    <Fragment>
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Content maxWidth={630}>
          <Heading size={mq.deviceQuery.mobile ? "lg" : "mega"} align="center">
            You’re all set!
          </Heading>
          <Spacer size={16} />
          <Text align="center" size="xs">
            Now that you and your team are all set up, it’s time to explore
            everything Flexspace has to offer you and your team.
          </Text>
          <Spacer size={50} />

          <View style={{ alignItems: "center" }}>
            <Image
              source={{
                uri: "/images/step_final_img.png",
                width: 260,
                height: 260,
              }}
            />
          </View>

          <Spacer size={16} />
        </Content>
      </View>
      <View
        style={[
          styles.buttonContainer,
          mq.deviceQuery.mobile && styles.buttonContainerMobile,
        ]}
      >
        <Button
          appearance="primary"
          text="Explore Flexspace"
          onPress={onNextClick}
          testID="sign-up-final-submit-button"
        />
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: "95%",
    maxWidth: 220,
    alignSelf: "center",
  },
  buttonContainerMobile: {
    maxWidth: 343,
  },
});

const stepFinalGQLQuery = gql`
  query StepFinal {
    currentUser {
      id
      createdWith
      createdAt
      role
      auth0PrimaryEmail
      fullName
      email
      organization {
        id
        name
        createdAt
        createdWith
      }
    }
  }
`;
