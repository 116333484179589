import { SpaceType } from "core/graphql.generated";

export const getSpaceType = (spaceType: SpaceType) => {
  if (spaceType === SpaceType.MeetingRoom) {
    return "Meeting room";
  }
  if (spaceType === SpaceType.DayPass) {
    return "Day pass";
  }
  if (spaceType === SpaceType.DayOffice) {
    return "Private office";
  }
  return "Unknown space type";
};
