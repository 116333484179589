import { Column } from "components/column";
import { Container } from "components/container";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { Row } from "components/row";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { DateRange } from "core/booking_date_range_picker";
import {
  BookingType,
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  PricingType,
  QuoteDetailsQuery,
} from "core/graphql.generated";
import { format } from "date-fns";
import { formatCurrency } from "lib/currency";
import { parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { usePriceDiscoverabilityFeatureFlag } from "providers/splitio";
import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { DynamicPriceText } from "./dynamic_price_text";

interface MobileSpacePricingsProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  quoteQuery?: QuoteDetailsQuery;
  dateRange?: DateRange;
  openCalendar?: () => void;
}

const formatDate = (startDate?: string, endDate?: string) => {
  if (!startDate) {
    return "";
  }
  if (endDate) {
    return `${parseDay(startDate).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    })} -  ${parseDay(endDate).getDate()}`;
  }
  return parseDay(startDate).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
};

const getDateString = (dateRange?: DateRange) => {
  if (dateRange?.type === BookingType.DailyBooking) {
    if (dateRange.startDate === dateRange.endDate) {
      return formatDate(dateRange.startDate);
    } else {
      return formatDate(dateRange.startDate, dateRange.endDate);
    }
  } else if (dateRange?.type === BookingType.HourlyBooking) {
    return formatDate(dateRange.date);
  }
  return "";
};
export function MobileSpaceDynamicPricings(props: MobileSpacePricingsProps) {
  const isMultiDayDP = usePriceDiscoverabilityFeatureFlag();
  const [showMultiDayDPModal, setShowMultiDayDPModal] = useState(false);
  const { space, quoteQuery, dateRange, openCalendar } = props;
  const sortedPricings = [...space.pricings].sort((a, b) =>
    a.type.localeCompare(b.type),
  );

  if (dateRange) {
    const pricing = space.pricings.find((price) =>
      dateRange?.type === BookingType.DailyBooking
        ? price.type === PricingType.Daily
        : price.type === PricingType.Hourly,
    );

    const quoteItems = quoteQuery?.quote.quoteItems;
    let quoteItemPrices, quoteItem;
    if (quoteItems && quoteItems[0]) {
      quoteItem = quoteItems[0];
      quoteItemPrices = quoteItem.quoteItemPrices;
    }

    if (
      isMultiDayDP &&
      quoteItem &&
      quoteItemPrices &&
      quoteItemPrices.length > 0
    ) {
      return (
        <View testID="price-edit-calendar">
          {pricing && (
            <Container
              key={pricing.type}
              borderColor={tokens.colors.neutral.darker}
              flex={1}
            >
              <Column alignItems="flex-start">
                <DynamicPriceText
                  basePrice={pricing.price}
                  priceType={pricing.type}
                  currency={space.currency}
                  unitPrice={quoteQuery?.quote.orderItems[0].unitPrice || 0}
                  showMultiDayDPModal={false}
                  setShowMultiDayDPModal={setShowMultiDayDPModal}
                />
              </Column>
            </Container>
          )}
          <Text
            size="micro"
            color="black-core"
            decoration={"underline"}
            onPress={openCalendar}
          >
            {getDateString(dateRange)}
          </Text>
          <DialogModal
            headerTitle={"Base price break down"}
            onClose={() => setShowMultiDayDPModal(false)}
            showCloseButton={true}
            mobileOffsetBottom={320}
            isVisible={showMultiDayDPModal}
          >
            <View style={styles.multiPricesContainer}>
              {quoteItemPrices.map((quoteItemPrice) => (
                <View style={styles.multiPricesRow}>
                  <Text size="xs" color={"black-70"}>
                    {quoteItemPrice.startDate &&
                      format(
                        parseDay(quoteItemPrice.startDate),
                        "dd MMMM yyyy",
                      )}
                  </Text>
                  <Text size="xs" color={"black-70"}>
                    {formatCurrency(
                      quoteItemPrice.unitPrice,
                      getSystemLocale(),
                      space.currency,
                    )}
                  </Text>
                </View>
              ))}
              <Divider />
              <View style={styles.multiPricesRow}>
                <Text size="xs" weight={"semibold"}>
                  Total base price
                </Text>
                <Text size="xs" weight={"semibold"}>
                  {formatCurrency(
                    quoteItem.totalPrice,
                    getSystemLocale(),
                    space.currency,
                  )}
                </Text>
              </View>
            </View>
          </DialogModal>
        </View>
      );
    }

    return (
      <Pressable
        testID="price-edit-calendar"
        onPress={openCalendar}
        style={styles.buttonCalendar}
      >
        {pricing && (
          <Container
            key={pricing.type}
            borderColor={tokens.colors.neutral.darker}
            flex={1}
          >
            <Column alignItems="flex-start">
              <DynamicPriceText
                basePrice={pricing.price}
                priceType={pricing.type}
                currency={space.currency}
                unitPrice={quoteQuery?.quote.orderItems[0].unitPrice || 0}
              />
            </Column>
          </Container>
        )}
        <Text size="micro" color="black-core" decoration={"underline"}>
          {getDateString(dateRange)}
        </Text>
      </Pressable>
    );
  }

  return (
    <>
      <Row>
        {sortedPricings.map((pricing, i) => {
          return (
            <Container
              key={pricing.type}
              borderColor={tokens.colors.neutral.darker}
              borderRightWidth={space.pricings.length > 1 && i === 0 ? 1 : 0}
              paddingLeft={i === 0 ? 0 : 12}
              flex={1}
            >
              <Column alignItems="flex-start">
                <Pressable onPress={openCalendar} style={styles.buttonCalendar}>
                  <DynamicPriceText
                    basePrice={pricing.price}
                    priceType={pricing.type}
                    currency={space.currency}
                    unitPrice={quoteQuery?.quote.orderItems[0].unitPrice || 0}
                  />
                </Pressable>
              </Column>
            </Container>
          );
        })}
      </Row>
      <Text size="micro" color="black-50" decoration={"underline"}>
        Add date for exact pricing
      </Text>
    </>
  );
}

const styles = StyleSheet.create({
  buttonCalendar: {
    width: "100%",
  },
  multiPricesRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  multiPricesContainer: {
    flex: 1,
    gap: 8,
  },
});
