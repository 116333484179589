import { Spacer } from "components/spacer";
import { SpaceType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { SearchInput } from "pages/homev2/components/search_input";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "pages/homev2/hooks/use_home_search_params";
import { useAnalytics } from "providers/analytics";
import { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import { HeaderDatePicker } from "./header_date_picker";
import { HeaderSpaceTypePicker } from "./header_space_type_picker";

interface SearchBarProps {
  handleSpaceTypeChange: (value: SpaceType) => void;
  handleDateChange: (value: string) => void;
  linkToSearchPage?: boolean;
  onSearch?: (field: string) => void;
}

type SearchBarInputProps = {
  linkToSearchPage?: boolean;
  onSearch?: (field: string) => void;
};
export function SearchBar({
  linkToSearchPage = false,
  onSearch,
}: SearchBarInputProps) {
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const history = useHistory();
  const location = useLocation();

  const handleSpaceTypeChange = useCallback(
    (value: SpaceType) => {
      if (linkToSearchPage) {
        history.push(`/search${location.search}`);
      }
      updateHomeSearchParams({
        spaceType: value || null,
        startTime: null,
        endTime: null,
        minCapacity: null,
        maxCapacity: null,
        amenities: null,
      });
      analytics.event("Select Space Type", {
        "Space Type": value || "any",
      });

      if (onSearch) {
        onSearch("Space type");
      }
    },
    [
      analytics,
      history,
      linkToSearchPage,
      location.search,
      onSearch,
      updateHomeSearchParams,
    ],
  );

  const handleDateChange = useCallback(
    (value: string) => {
      if (linkToSearchPage) {
        const { search } = location;
        history.push(`/search${search}`);
      }
      updateHomeSearchParams({
        date: value || null,
      });
      analytics.event("Select Date", {
        Date: value,
      });

      if (onSearch) {
        onSearch("Date");
      }
    },
    [
      analytics,
      history,
      linkToSearchPage,
      location,
      onSearch,
      updateHomeSearchParams,
    ],
  );

  return mq.deviceQuery.mobile ? (
    <SearchBarMobile
      handleSpaceTypeChange={handleSpaceTypeChange}
      handleDateChange={handleDateChange}
      linkToSearchPage={linkToSearchPage}
      onSearch={onSearch}
    />
  ) : (
    <SearchBarDesktop
      handleSpaceTypeChange={handleSpaceTypeChange}
      handleDateChange={handleDateChange}
      linkToSearchPage={linkToSearchPage}
      onSearch={onSearch}
    />
  );
}

function SearchBarDesktop(props: SearchBarProps) {
  const { spaceType, date } = useHomeSearchParamsQuery();
  const {
    handleSpaceTypeChange,
    handleDateChange,
    linkToSearchPage = false,
    onSearch,
  } = props;

  return (
    <View style={styles.container}>
      <View style={styles.searchInput}>
        <SearchInput onSearch={onSearch} linkToSearchPage={linkToSearchPage} />
      </View>
      <Spacer direction="row" size={8} />
      <View style={styles.dateAndSpaceTypeFilters}>
        <HeaderSpaceTypePicker
          onChange={handleSpaceTypeChange}
          value={spaceType}
        />
        <Spacer direction="row" size={8} />
        <HeaderDatePicker value={date} onChange={handleDateChange} />
      </View>
    </View>
  );
}

function SearchBarMobile(props: SearchBarProps) {
  const { spaceType, date } = useHomeSearchParamsQuery();
  const {
    handleSpaceTypeChange,
    handleDateChange,
    linkToSearchPage = false,
    onSearch,
  } = props;

  return (
    <View style={styles.containerMobile}>
      <View style={styles.searchInputMobile}>
        <SearchInput onSearch={onSearch} linkToSearchPage={linkToSearchPage} />
      </View>
      <Spacer size={8} />
      <View style={styles.dateAndSpaceTypeFilters}>
        <View style={styles.pickerContainer}>
          <HeaderSpaceTypePicker
            onChange={handleSpaceTypeChange}
            value={spaceType}
            linkToSearchPage={linkToSearchPage}
          />
        </View>
        <Spacer direction="row" size={8} />
        <View style={styles.pickerContainer}>
          <HeaderDatePicker value={date} onChange={handleDateChange} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  containerMobile: {
    width: "100%",
  },
  searchInput: {
    width: 240,
  },
  searchInputMobile: {
    width: "100%",
  },
  dateAndSpaceTypeFilters: {
    flexDirection: "row",
  },
  pickerContainer: {
    width: "calc(50% - 4px)",
  },
});
