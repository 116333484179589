import { useMutation, gql } from "@apollo/client";

import {
  CancelBookingMutation,
  CancelBookingMutationVariables,
} from "core/graphql.generated";
import { useToast } from "providers/toast";

import { useBookingDetailsQuery } from "./useBookingDetailsQuery";

export function useCancelBooking() {
  const toast = useToast();

  const [cancelBooking, { loading }] = useMutation<
    CancelBookingMutation,
    CancelBookingMutationVariables
  >(cancelBookingMutation);
  const { refetch } = useBookingDetailsQuery();

  const handleCancelBooking = async (id: string) => {
    try {
      await cancelBooking({ variables: { orderID: id } });
      toast.notify({
        message: "Your booking has been successfully canceled.",
        testID: "booking-cancel-success-toast",
      });
      refetch();
    } catch {
      toast.notify({ message: "Error happened! Please try later." });
    }
  };

  return { cancelBooking: handleCancelBooking, loading };
}

const cancelBookingMutation = gql`
  mutation cancelBooking($orderID: ID!) {
    cancelOrder(orderID: $orderID)
  }
`;
