import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";

export interface OffsiteSpaceDetailsDesktopComponents {
  header: JSX.Element;
  images: JSX.Element;
  spaceAttributes: JSX.Element;
  aboutSpace?: JSX.Element;
  preferred?: JSX.Element;
  whosHere?: JSX.Element;
  perks?: JSX.Element;
  roomLayouts?: JSX.Element;
  amenties?: JSX.Element;
  pricePannel: JSX.Element;
  location: JSX.Element;
  map: JSX.Element;
  directions?: JSX.Element;
  collaborateBanner: JSX.Element;
  hours: JSX.Element;
}

interface OffsiteSpaceDetailsContentDesktopProps {
  components: OffsiteSpaceDetailsDesktopComponents;
}

const SPACER_SIZE = 24;

export function OffsiteSpaceDetailsContentDesktop({
  components,
}: OffsiteSpaceDetailsContentDesktopProps) {
  const {
    header,
    images,
    spaceAttributes,
    aboutSpace,
    preferred,
    whosHere,
    perks,
    roomLayouts,
    amenties,
    pricePannel,
    location,
    map,
    directions,
    collaborateBanner,
    hours,
  } = components;

  return (
    <View>
      {header}
      <Spacer size={SPACER_SIZE} />
      {images}
      {spaceAttributes}
      <Spacer size={SPACER_SIZE} />
      <Divider />
      <Spacer size={SPACER_SIZE} />
      <View style={styles.flexRow}>
        <View style={styles.aboutAndAmentiesWrapper}>
          {aboutSpace && (
            <>
              {aboutSpace}
              <Spacer size={SPACER_SIZE} />
              <Divider />
              <Spacer size={SPACER_SIZE} />
            </>
          )}
          {preferred && (
            <>
              {preferred}
              <Spacer size={SPACER_SIZE} />
              <Divider />
              <Spacer size={SPACER_SIZE} />
            </>
          )}
          {whosHere && (
            <>
              {whosHere}
              <Spacer size={8} />
              <Divider />
              <Spacer size={SPACER_SIZE} />
            </>
          )}
          {perks && (
            <>
              {perks}
              <Spacer size={8} />
              <Divider />
              <Spacer size={SPACER_SIZE} />
            </>
          )}
          {roomLayouts && (
            <>
              {roomLayouts}
              <Spacer size={8} />
              <Divider />
              <Spacer size={SPACER_SIZE} />
            </>
          )}
          {amenties && (
            <>
              {amenties}
              <Spacer size={SPACER_SIZE} />
              <Divider />
              <Spacer size={SPACER_SIZE} />
            </>
          )}
        </View>
        <View>{pricePannel}</View>
      </View>
      {location}
      <Spacer size={SPACER_SIZE} />
      {map}
      <Spacer size={SPACER_SIZE} />
      <Divider />
      <Spacer size={SPACER_SIZE} />
      {directions && (
        <>
          {directions}
          <Spacer size={SPACER_SIZE} />
          <Divider />
          <Spacer size={SPACER_SIZE} />
        </>
      )}
      {collaborateBanner}
      <Spacer size={SPACER_SIZE} />
      <Divider />
      <Spacer size={SPACER_SIZE} />
      {hours}
    </View>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    gap: 40,
  },
  aboutAndAmentiesWrapper: {
    flex: 1,
  },
});
