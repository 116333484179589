import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  LocationPreviewCardLocationSpaces__SpaceDetailsFragment,
  Pricing,
  PricingType,
  SpaceType,
} from "core/graphql.generated";
import { uniqueBy } from "lib/array_utils";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { sortAmenities } from "lib/sort_amenities";
import pluralize from "pluralize";
import { StyleSheet, View } from "react-native";
import { Link } from "react-router-dom";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";
import {
  SpaceCardAmenities,
  SpaceCardDynamicPrice,
} from "./space_card_v2/space_card_template";

interface LocationPreviewSpaceItemProps {
  space: LocationPreviewCardLocationSpaces__SpaceDetailsFragment;
  physicalSpaceId?: string;
  name: string;
}

export function LocationPreviewSpaceItem(props: LocationPreviewSpaceItemProps) {
  const { space, name, physicalSpaceId } = props;
  const {
    amenities: paramsAmenities,
    spaceType,
    date,
    startTime,
    endTime,
  } = useHomeSearchParamsQuery();
  let forwardSearch = ``;

  if (spaceType === SpaceType.DayOffice) {
    forwardSearch = `?physicalSpaceID=${physicalSpaceId}`;

    if (date) {
      forwardSearch += `&startDate=${date}&endDate=${date}`;
    }
  } else if (spaceType === SpaceType.MeetingRoom) {
    if (date) {
      forwardSearch += `?date=${date}`;

      if (startTime) {
        forwardSearch += `&startTime=${startTime}`;
      }
      if (endTime) {
        forwardSearch += `&endTime=${endTime}`;
      }
    }
  } else if (spaceType === SpaceType.DayPass) {
    if (date) {
      forwardSearch += `?startDate=${date}&endDate=${date}`;
    }
  }

  const url = `/offsite_spaces/${space.id}${forwardSearch}`;

  return (
    <Link
      data-testid={`day-office-ps`}
      to={url}
      style={{ textDecoration: "none" }}
    >
      <View key={space.id} style={styles.wrapper}>
        <View style={styles.outerWrapper}>
          <View>
            <img
              alt={name}
              style={{
                objectFit: "cover",
                height: 60,
                width: 60,
                borderRadius: 4,
              }}
              src={space.images[0].small.url}
            />
          </View>
          <View style={styles.infoWrapper}>
            <View>
              <Row alignItems="center">
                <Text size="xs">
                  <b>{name} &middot; </b>
                  {space.maxCapacity} {pluralize("person", space.maxCapacity)}
                </Text>
                <Spacer direction="row" size={8} />
                {space.locked && <OutOfPolicyTag />}
              </Row>
            </View>
            <Spacer size={4} />

            {!!spaceType && (
              <SpaceCardDynamicPrice
                spaceType={spaceType}
                pricings={space.pricings}
                currency={space.currency}
                priceRanges={space.priceRanges}
                isPickedDate={!!date}
                outOfPolicy={space.locked}
              />
            )}
            <Spacer size={4} />
            {(!!space.amenities || !!space.location.amenities) && (
              <SpaceCardAmenities
                amenities={uniqueBy(
                  sortAmenities(
                    space.amenities,
                    space.location.amenities,
                    paramsAmenities,
                  ),
                  (item) => item.name,
                )}
              />
            )}
          </View>
        </View>
      </View>
    </Link>
  );
}

interface DayOfficePricingProps {
  pricing: Pricing;
  currency?: string;
}

export function DayOfficePricing(props: DayOfficePricingProps) {
  const { pricing, currency } = props;

  return (
    <Text size="xs">
      <Text size="xs" weight="bold">
        {formatCurrencyWithoutDecimal(
          pricing.price,
          getSystemLocale(),
          currency,
        )}
      </Text>{" "}
      {pricing.type === PricingType.Daily ? "/day" : "/hr"}
    </Text>
  );
}

const styles = StyleSheet.create({
  outerWrapper: {
    flexDirection: "row",
  },
  lockedBadgeWrapper: {
    position: "absolute",
    right: 0,
    top: 5,
  },
  wrapper: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: "rgba(6, 0, 30, 0.1)",
  },
  infoWrapper: {
    flex: 1,
    justifyContent: "space-between",
    paddingHorizontal: 9,
  },
});
