type Coordinate = {
  latitude: number;
  longitude: number;
};

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

// in meters
export function calculateDistance(
  coord1: Coordinate,
  coord2: Coordinate,
): number {
  const R = 6371e3; // Earth's radius in meters
  const lat1 = toRadians(coord1.latitude);
  const lat2 = toRadians(coord2.latitude);
  const deltaLat = toRadians(coord2.latitude - coord1.latitude);
  const deltaLon = toRadians(coord2.longitude - coord1.longitude);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.round(R * c);
}

type BoundingBox = [number, number, number, number];

// in meters
export function calculateBoundingBoxRadius(bounds: BoundingBox): number {
  // Extract the coordinates of the bounding box corners
  const [swLon, swLat, neLon, neLat] = bounds;

  // Calculate the center of the bounding box
  const center: Coordinate = {
    latitude: (swLat + neLat) / 2,
    longitude: (swLon + neLon) / 2,
  };

  // Calculate the distance between the center and the southwestern corner
  const radius = calculateDistance(center, {
    latitude: swLat,
    longitude: swLon,
  });

  return radius;
}

export function calculateBboxFromCenter(
  center: [number, number],
): [number, number, number, number] {
  const widthInMeters = 5000; // Desired width of the bbox in meters
  const heightInMeters = 5000; // Desired height of the bbox in meters
  const earthRadius = 6371000; // Earth's radius in meters

  const lat1 = (center[1] * Math.PI) / 180; // Convert latitude from degrees to radians

  // Calculate the latitude change (delta) based on the desired height
  const latDelta = (heightInMeters / earthRadius) * (180 / Math.PI);

  // Calculate the longitude change (delta) based on the desired width
  const lonDelta =
    ((widthInMeters / earthRadius) * (180 / Math.PI)) / Math.cos(lat1);

  // Calculate the minimum and maximum latitude and longitude values
  const minLat = center[1] - latDelta / 2;
  const maxLat = center[1] + latDelta / 2;
  const minLon = center[0] - lonDelta / 2;
  const maxLon = center[0] + lonDelta / 2;

  return [minLon, minLat, maxLon, maxLat];
}
