import React from "react";
import { DayInterval } from "lib/day_utils";
import { useMediaQuery } from "lib/media_query";
import { BudgetsDateRangePickerMobile } from "./budgets_date_range_picker_mobile";
import { BudgetsDateRangePickerDesktop } from "./budgets_date_range_picker_desktop";
import { Spacer } from "../spacer";
import { View, StyleSheet } from "react-native";
import { Text } from "../text_v2";

export interface BudgetsDateRangePickerProps {
  value: DayInterval;
  onChange: (value: DayInterval) => void;
  earliestAllowedDate?: Date;
  labelUpdatedAt?: string;
}

export function BudgetsDateRangePicker({
  value,
  onChange,
  earliestAllowedDate,
  labelUpdatedAt,
}: BudgetsDateRangePickerProps) {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return (
      <BudgetsDateRangePickerMobile
        value={value}
        onChange={onChange}
        earliestAllowedDate={earliestAllowedDate}
      />
    );
  }

  return (
    <View style={styles.filterWrapper}>
      <BudgetsDateRangePickerDesktop
        value={value}
        onChange={onChange}
        earliestAllowedDate={earliestAllowedDate}
      />
      <Spacer direction="row" size={16} />
      <Text color="black-70" size="xs">
        {labelUpdatedAt}
      </Text>
    </View>
  );
}
const styles = StyleSheet.create({
  filterWrapper: {
    zIndex: 99,
    flexDirection: "row",
    alignItems: "center",
  },
});
