import {
  ActivityIndicator,
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { colors } from "./colors";
import { Icon, IconName } from "./iconv2";
import { LinkWrapper } from "./link_wrapper";

type ColorType = keyof typeof colors.brand;

interface ButtonProps {
  text?: string;
  appearance?: "primary" | "secondary" | "textLink" | "danger";
  size?: "large" | "medium" | "small" | "fit" | "auto";
  disabled?: boolean;
  loading?: boolean;
  iconName?: IconName;
  iconPosition?: "left" | "right";
  iconSpecialColor?: ColorType;
  iconSize?: "sm" | "md" | "lg";
  onPress?: (e?: GestureResponderEvent) => void;
  href?: string;
  testID?: string;
}

interface ButtonLinkWrapperProps {
  children: JSX.Element;
  href?: string;
}

const ButtonLinkWrapper = (props: ButtonLinkWrapperProps) => {
  const { href, children } = props;
  if (!href) {
    return children;
  }

  return <LinkWrapper to={href}>{children}</LinkWrapper>;
};

export function Button(props: ButtonProps) {
  const {
    text,
    appearance = "primary",
    size = "auto",
    disabled,
    loading,
    iconName,
    iconPosition = "left",
    iconSize = "sm",
    onPress,
    testID,
    href,
    iconSpecialColor,
  } = props;

  const renderIconContent = () => {
    if (loading) {
      return (
        <ActivityIndicator
          size="small"
          color={colors.brand[iconDisabledColor[appearance]]}
        />
      );
    } else if (iconName) {
      return (
        <Icon
          name={iconName}
          size={iconSize}
          color={
            disabled
              ? iconDisabledColor[appearance]
              : iconSpecialColor
              ? iconSpecialColor
              : iconColor[appearance]
          }
        />
      );
    }
  };

  return (
    <ButtonLinkWrapper href={href}>
      <Pressable
        accessible={!href}
        disabled={disabled || loading}
        style={({ pressed, hovered }: any) => [
          buttonStyles.base,
          buttonStyles[appearance],
          buttonStyles[size],
          hovered && buttonHoverStyles[appearance],
          pressed && buttonPressStyles[appearance],
          (disabled || loading) && buttonDisabledStyles[appearance],
        ]}
        onPress={href ? undefined : onPress}
        testID={testID}
      >
        <View
          style={[
            buttonContentWrapperStyles.base,
            buttonContentWrapperStyles[iconPosition],
          ]}
        >
          {renderIconContent()}
          {text && (
            <Text
              style={[
                textStyles.base,
                textStyles[appearance],
                (disabled || loading) && textDisabledStyles[appearance],
              ]}
            >
              {text}
            </Text>
          )}
        </View>
      </Pressable>
    </ButtonLinkWrapper>
  );
}

const buttonStyles = StyleSheet.create({
  base: {
    minHeight: 40,
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  primary: {
    backgroundColor: colors.brand.eggplantcore,
  },
  secondary: {
    backgroundColor: colors.brand.whitecore,
    borderWidth: 1,
    borderColor: colors.brand.eggplantcore,
  },
  textLink: {
    minWidth: "fit-content",
  },
  danger: {
    backgroundColor: colors.brand.grapefruitcore,
  },
  large: {
    minWidth: 343,
    width: "fit-content",
  },
  medium: {
    minWidth: 229,
    width: "fit-content",
  },
  small: {
    minWidth: 114,
    width: "fit-content",
  },
  fit: {
    width: "fit-content",
  },
  auto: {
    width: "initial",
  },
});

const buttonDisabledStyles = StyleSheet.create({
  primary: {
    backgroundColor: colors.brand.eggplantMinus50,
  },
  secondary: {
    borderColor: colors.brand.eggplantMinus50,
  },
  textLink: {},
  danger: {
    backgroundColor: colors.brand.grapefruitMinus50,
  },
});

const buttonHoverStyles = StyleSheet.create({
  primary: {
    backgroundColor: colors.brand.eggplant10,
  },
  secondary: {
    backgroundColor: colors.brand.eggplantMinus90,
    borderColor: colors.brand.eggplantcore,
  },
  textLink: {},
  danger: { backgroundColor: colors.brand.grapefruit10 },
});

const buttonPressStyles = StyleSheet.create({
  primary: {
    backgroundColor: colors.brand.eggplant20,
  },
  secondary: {
    backgroundColor: colors.brand.eggplantMinus80,
  },
  textLink: {},
  danger: {
    backgroundColor: colors.brand.grapefruit20,
  },
});

const textStyles = StyleSheet.create({
  base: {
    fontWeight: "600",
  },
  primary: {
    color: colors.brand.whitecore,
  },
  secondary: {
    color: colors.brand.eggplantcore,
  },
  textLink: {
    color: colors.brand.eggplantcore,
    fontWeight: "600",
  },
  danger: {
    color: colors.brand.whitecore,
    fontWeight: "600",
  },
});

const textDisabledStyles = StyleSheet.create({
  primary: {
    color: colors.brand.whitecore,
  },
  secondary: {
    color: colors.brand.eggplantMinus50,
  },
  textLink: {
    color: colors.brand.eggplantMinus50,
    fontWeight: "600",
  },
  danger: {
    color: colors.brand.whitecore,
  },
});

const buttonContentWrapperStyles = StyleSheet.create({
  base: {
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  left: {
    flexDirection: "row",
  },
  right: {
    flexDirection: "row-reverse",
  },
});

const iconColor: {
  primary: ColorType;
  secondary: ColorType;
  textLink: ColorType;
  danger: ColorType;
} = {
  primary: "whitecore",
  secondary: "eggplantcore",
  textLink: "eggplantcore",
  danger: "whitecore",
};

const iconDisabledColor: {
  primary: ColorType;
  secondary: ColorType;
  textLink: ColorType;
  danger: ColorType;
} = {
  primary: "whitecore",
  secondary: "eggplantMinus50",
  textLink: "eggplantMinus50",
  danger: "whitecore",
};
