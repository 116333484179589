import { useMediaQuery } from "lib/media_query";
import React from "react";
import {
  Animated,
  Pressable,
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  ViewStyle,
} from "react-native";
import { Modal, ModalProps } from "./modal";

const TABLET_OFFSET_TOP = 64;
const MOBILE_OFFSET_TOP = 16;

interface DialogProps extends ModalProps {
  style?: StyleProp<ViewStyle>;
  /**
   * On mobile, instead of covering full screen, show dialog at the bottom of the screen at specified height
   */
  mobileOffsetBottom?: number;
  center?: boolean;
  hasTransParentOverlay?: boolean;
  testID?: string;
  onShowAnimationEnd?: () => void;
  onDissmissAnimationEnd?: () => void;
  offsetTop?: number;
}

export function Dialog(props: DialogProps): JSX.Element {
  const {
    visible,
    onRequestClose,
    onShow,
    onDismiss,
    style,
    children,
    mobileOffsetBottom = 0,
    center,
    hasTransParentOverlay = false,
    testID,
    onShowAnimationEnd,
    onDissmissAnimationEnd,
    offsetTop,
  } = props;
  const { height } = useWindowDimensions();
  const mq = useMediaQuery();
  const [internalVisible, setInternalVisible] = React.useState(visible);
  let offset = 0;
  if (offsetTop) {
    offset = offsetTop;
  } else if (mq.sizeQuery.mdAndUp) {
    offset = TABLET_OFFSET_TOP;
  } else if (mobileOffsetBottom) {
    offset = height - mobileOffsetBottom;
  } else {
    offset = MOBILE_OFFSET_TOP;
  }
  const slide = React.useRef(new Animated.Value(height)).current;
  const overlayFade = React.useRef(new Animated.Value(visible ? 1 : 0)).current;
  const opacity = React.useRef(new Animated.Value(visible ? 1 : 0)).current;

  React.useEffect(() => {
    if (visible) {
      setInternalVisible(visible);
      Animated.parallel([
        Animated.spring(opacity, {
          toValue: 1,
          bounciness: 0,
          useNativeDriver: true,
        }),
        Animated.spring(slide, {
          toValue: offset,
          bounciness: 0,
          useNativeDriver: true,
        }),
        Animated.spring(overlayFade, {
          toValue: 1,
          bounciness: 0,
          useNativeDriver: true,
        }),
      ]).start(onShowAnimationEnd);
    } else {
      Animated.parallel([
        Animated.spring(opacity, {
          toValue: 0,
          bounciness: 0,
          useNativeDriver: true,
        }),
        Animated.spring(overlayFade, {
          toValue: 0,
          bounciness: 0,
          useNativeDriver: true,
        }),
        Animated.spring(slide, {
          toValue: height,
          bounciness: 0,
          useNativeDriver: true,
        }),
      ]).start(() => {
        setInternalVisible(false);
        onDissmissAnimationEnd && onDissmissAnimationEnd();
      });
    }
  }, [
    opacity,
    overlayFade,
    visible,
    internalVisible,
    offsetTop,
    slide,
    height,
    onRequestClose,
    onShowAnimationEnd,
    onDissmissAnimationEnd,
    offset,
  ]);

  const handlePressBackground = React.useCallback(() => {
    if (onRequestClose) {
      onRequestClose();
    }
  }, [onRequestClose]);

  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      onShow={onShow}
      animationType="fade"
      transparent
      onDismiss={onDismiss}
    >
      <Animated.View
        testID={testID}
        style={[
          styles.base,
          {
            backgroundColor: overlayFade.interpolate({
              inputRange: [0, 1],
              outputRange: ["rgba(0, 0, 0, 0)", "rgba(0, 0, 34, 0.7)"],
            }),
          },
          center && styles.center,
        ]}
      >
        {visible && mq.sizeQuery.smAndDown && !mobileOffsetBottom && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: !hasTransParentOverlay
                ? "radial-gradient(70.5% 102.16% at 80.27% 7.17%, #8D4E93 0%, #524486 100%)"
                : "",
            }}
          />
        )}
        <Pressable
          accessible={false}
          style={styles.background}
          onPress={handlePressBackground}
        />
        <Animated.View
          style={[
            styles.dialog,
            mq.sizeQuery.mdAndUp ? styles.tablet : styles.mobile,
            style,
            {
              opacity,
              transform: [{ translateY: slide }],
              paddingBottom: offset,
            },
          ]}
        >
          {children}
        </Animated.View>
      </Animated.View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  base: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  dialog: {
    overflow: "hidden",
  },
  tablet: {
    borderRadius: 16,
    maxHeight: "95%",
    maxWidth: "100%",
  },
  mobile: {
    height: "100%",
    width: "100%",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
});
