import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { ACCEPT_INVITE } from "pages/accept_invite/accept_invite";
import { SIGN_UP_STEPS_REDIRECT_URL_KEY } from "providers/auth_loader";
import { Image, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

export function InvalidInvite() {
  const mq = useMediaQuery();
  const history = useHistory();

  function onLoginClick() {
    localStorage.removeItem(ACCEPT_INVITE);
    localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
    history.replace("/sign-in");
  }

  function onGetStartedClick() {
    localStorage.removeItem(ACCEPT_INVITE);
    localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
    history.replace("/sign-up");
  }

  return (
    <Content maxWidth={470}>
      <Heading size={mq.deviceQuery.mobile ? "lg" : "mega"} align="center">
        Invite invalid
      </Heading>
      <Spacer size={16} />
      <Text align="center" size="xs">
        This invite is no longer valid as it may have been revoked by the
        administrator of your organization or you have already joined. Please
        request a new link from your administrator or log in.
      </Text>
      <Spacer size={50} />

      <View style={styles.imageContainer}>
        <Image
          source={{
            uri: "https://flexspace-ds-static.s3.us-west-1.amazonaws.com/invalid_invite.png",
            width: 260,
            height: 260,
          }}
        />
      </View>

      <Spacer size={24} />
      <View
        style={[styles.button, mq.sizeQuery.mdAndUp && styles.buttonBigScreen]}
      >
        <Button text="Log in" onPress={onLoginClick} />
      </View>
      <Spacer size={16} />

      <Text align="center" size="xs">
        Want to create your own team?{" "}
        <Text onPress={onGetStartedClick} size="xs" color="eggplant-core">
          Get started.
        </Text>
      </Text>
    </Content>
  );
}

const styles = StyleSheet.create({
  button: {
    width: "100%",
    marginHorizontal: "auto",
    alignItems: "stretch",
  },
  buttonBigScreen: {
    width: 220,
  },
  imageContainer: {
    alignItems: "center",
  },
  text: {
    textAlign: "center",
  },
});
