import { useMutation } from "@apollo/client";
import { StepPasswordUI } from "components/sign_up_shared/step_password_ui";
import {
  PostAuthMutation,
  PostAuthMutationVariables,
} from "core/graphql.generated";
import { logger } from "lib/logger";
import {
  postAuthRedirectUrl,
  SIGN_UP_STEPS_REDIRECT_URL_KEY,
} from "providers/auth_loader";
import { postAuthMutation } from "providers/auth_loaderv1";
import { useAuthV2 } from "providers/authv2";
import { useOpenDirectoryFeatureFlag } from "providers/splitio";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SignUpCredentials } from "../sign_up";

interface SignUpStepPasswordProps {
  onGoNext: () => void;
  onGoBack: () => void;
  credentials: SignUpCredentials;
}

export function SignUpStepPassword(props: SignUpStepPasswordProps) {
  const { onGoNext, credentials } = props;
  const { signUpWithEmailAndPassword } = useAuthV2();
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  const [postAuth] = useMutation<PostAuthMutation, PostAuthMutationVariables>(
    postAuthMutation,
  );

  useEffect(() => {
    if (!credentials.email || !credentials.fullName) {
      history.replace("/sign-up/get-started");
    }
  }, [credentials.email, credentials.fullName, history]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    if (!openDirectoryFeatureFlag) {
      localStorage.setItem(
        SIGN_UP_STEPS_REDIRECT_URL_KEY,
        "/sign-up/team-name",
      );
    }

    try {
      await signUpWithEmailAndPassword(
        credentials.fullName,
        credentials.email,
        value,
      );

      if (openDirectoryFeatureFlag) {
        const postAuthResult = await postAuth();
        logger.debug(
          `[SignUpStepPassword] postAuthResult: ${JSON.stringify(
            postAuthResult?.data?.postAuth,
          )}`,
        );

        if (postAuthResult?.data?.postAuth.orgRegistered) {
          history.replace(postAuthRedirectUrl.get() || "/");
          postAuthRedirectUrl.reset();
        } else {
          localStorage.setItem(
            SIGN_UP_STEPS_REDIRECT_URL_KEY,
            "/sign-up/team-name",
          );
          onGoNext();
        }
      } else {
        onGoNext();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  }, [
    signUpWithEmailAndPassword,
    credentials.fullName,
    credentials.email,
    value,
    openDirectoryFeatureFlag,
    postAuth,
    history,
    onGoNext,
  ]);

  return (
    <StepPasswordUI
      title="Set up your password"
      subtitle="Enter a password for your Flexspace account."
      password={value}
      onPasswordChange={setValue}
      onSubmit={handleSubmit}
      error={error}
      loading={loading}
    />
  );
}
