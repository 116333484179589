import { addMonths, format, isBefore, subMonths } from "date-fns";
import React, { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { MonthView } from "components/month_view/month_view";
import { startOfDay } from "lib/date_utils";
import { Day, parseDay, toDay } from "lib/day_utils";
import { Text } from "components/text_v2";
import { Icon } from "components/iconv2";
import {
  renderDay,
  renderDayOfWeek,
  renderOtherMonthDay,
  renderWeek,
} from "components/month_view/components/components_v2";

interface DatePickerProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
  showPreviousIcon?: (date: Date) => boolean;
}

export function DatePicker(props: DatePickerProps) {
  const { value, onChange, showPreviousIcon } = props;

  const [month, setMonth] = useState(value ? parseDay(value) : new Date());
  const handlePressPrevious = useCallback(() => {
    setMonth(subMonths(month, 1));
  }, [month, setMonth]);

  const handlePressNext = useCallback(() => {
    setMonth(addMonths(month, 1));
  }, [month, setMonth]);

  const handleSelectDate = useCallback(
    (date: Date) => {
      onChange(toDay(date));
    },
    [onChange],
  );

  const shouldShowPreviousIcon = showPreviousIcon
    ? showPreviousIcon(month)
    : true;

  return (
    <View>
      <View style={styles.monthNavigatorWrapper}>
        <View>
          {shouldShowPreviousIcon && (
            <Pressable
              style={styles.arrowWrapper}
              onPress={handlePressPrevious}
            >
              <Icon name="arrow-left" />
            </Pressable>
          )}
        </View>
        <Text align="center" size="xs" weight="semibold">
          {format(month, "MMMM yyyy")}
        </Text>
        <Pressable style={styles.arrowWrapper} onPress={handlePressNext}>
          <Icon name="arrow-right" />
        </Pressable>
      </View>
      <MonthView
        renderDay={renderDay}
        renderDayOfWeek={renderDayOfWeek}
        renderOtherMonthDay={renderOtherMonthDay}
        renderWeek={renderWeek}
        selectedInterval={
          value ? { start: parseDay(value), end: parseDay(value) } : null
        }
        month={month}
        onSelectDay={handleSelectDate}
        topOffset={16}
        isOutsideRange={(d) => isBefore(d, startOfDay(new Date()))}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  arrowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: 24,
    width: 24,
  },
  monthNavigatorWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
