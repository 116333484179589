import { View, Image, StyleSheet } from "react-native";
import pluralize from "pluralize";

import { Text } from "components/text_v2";
import { colors } from "components/colors";
import { useMediaQuery } from "lib/media_query";

interface UpcomingBookingThumbnailProps {
  url: string;
  upcomingInDays: number;
}

export function UpcomingBookingThumbnail({
  url,
  upcomingInDays,
}: UpcomingBookingThumbnailProps) {
  const mq = useMediaQuery();

  return (
    <View style={[styles.root, mq.deviceQuery.mobile && styles.rootMobile]}>
      <Image
        style={
          mq.deviceQuery.mobile ? styles.thumbnailMobile : styles.thumbnail
        }
        source={{ uri: url }}
      />
      <View style={styles.upcomingDaysWrapper}>
        <Text size="micro" weight="semibold">
          {upcomingInDays > 0
            ? `In ${upcomingInDays} ${pluralize("day", upcomingInDays)}`
            : "Today"}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: "100%",
    height: "100%",
  },
  rootMobile: {
    height: 160,
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  thumbnailMobile: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  upcomingDaysWrapper: {
    position: "absolute",
    top: 12,
    left: 12,
    paddingHorizontal: 8,
    paddingVertical: 5,
    backgroundColor: colors.brand.whitecore,
    borderRadius: 4,
  },
});
