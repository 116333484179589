import { gql, useMutation, useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { TabsV3 } from "components/tab/tabs_v3";
import { Text } from "components/text_v2";
import {
  AddCityMutation,
  City,
  GetOrgDataQueryVariables,
  OrgUsersQuery,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { useTab } from "hooks/use_tab";
import { Coordinates } from "pages/homev2/coordinates";
import { MapboxFeature, toCoordinates } from "pages/homev2/mapbox";
import { MapSkeleton } from "pages/team_spaces/components/skeleton_loading/map_skeleton";
import { TeamSearchInput } from "pages/team_spaces/components/team_search_input";
import { TeamList } from "pages/team_spaces/components/where_my_team/where_my_team_list/team_list";
import { TeamMap } from "pages/team_spaces/components/where_my_team/where_my_team_map/team_map";
import {
  cityDetailsFragment,
  teamsBookingDetailsFragment,
  teamsLocationDetailsFragment,
  teamsSpaceDetailsFragment,
  teamsUserDetailsFragment,
} from "pages/team_spaces/fragment";
import { useMyTeamQueryParamVariables } from "pages/team_spaces/hooks/use_my_team_query_param_variable";
import {
  useMyTeamSearchParamsMutation,
  useMyTeamSearchParamsQuery,
} from "pages/team_spaces/hooks/use_my_team_search_params";
import { useAnalytics } from "providers/analytics";
import React, { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useToast } from "providers/toast";

export const getCurrentLocation = (
  coordinates: Coordinates | undefined,
  currentUserCity: City | null | undefined,
) => {
  if (coordinates) {
    return coordinates;
  } else if (
    currentUserCity &&
    currentUserCity.latitude &&
    currentUserCity.longitude
  ) {
    return { lat: currentUserCity.latitude, lng: currentUserCity.longitude };
  } else {
    return undefined;
  }
};

export enum MapTabType {
  MapView = "Map view",
  ListView = "List view",
}

export function WhereMyTeam() {
  const toast = useToast();

  const [addCityModalVisible, setAddCityModalVisible] = useState(false);
  const updateTeamSearchParams = useMyTeamSearchParamsMutation();
  const { coordinates } = useMyTeamSearchParamsQuery();
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const [place, setPlace] = useState<MapboxFeature>();
  const [selectedTab, setSelectedTab] = useTab([
    MapTabType.MapView,
    MapTabType.ListView,
  ]);
  const { variables, currentUserLoading } = useMyTeamQueryParamVariables();
  const { data, loading, error, refetch } = useQuery<
    OrgUsersQuery,
    GetOrgDataQueryVariables
  >(orgUsers, {
    variables: variables!,
    skip: !variables,
    notifyOnNetworkStatusChange: true,
  });

  const [addCity, { loading: cityLoading }] =
    useMutation<AddCityMutation>(addCityMutation);

  const currentLocation = getCurrentLocation(
    coordinates,
    data?.currentUser?.city,
  );

  const updateCityTitle = `${currentLocation ? "Change" : "Add"} my city`;

  const onDone = async () => {
    if (place) {
      const coordinates = toCoordinates(place.center);
      updateTeamSearchParams({
        coordinates,
        bbox: place.bbox,
        placeId: place.id,
      });

      const parts = place.place_name.split(", ");
      await addCity({
        variables: {
          input: {
            name: place.place_name,
            state: parts[parts.length - 2],
            country: parts[parts.length - 1],
            latitude: coordinates.lat,
            longitude: coordinates.lng,
            displayName: place.place_name,
          },
        },
      });
      await refetch();
      setAddCityModalVisible(false);
      toast.notify({
        message: currentLocation
          ? "Your city has been updated on the map!"
          : "You’ve been added to the map!",
      });
    }
  };

  const addMyCityPress = () => {
    setAddCityModalVisible(true);
  };

  const setSelectedTabPress = useCallback(
    (tab: MapTabType) => {
      setSelectedTab(tab);
      if (tab === MapTabType.MapView) {
        analytics.event("View Team Map", {
          View: "Map",
        });
      } else {
        analytics.event("View Team List", {
          View: "List",
        });
      }
    },
    [analytics, setSelectedTab],
  );

  return (
    <View style={styles.container}>
      <View
        style={mq.deviceQuery.mobile ? styles.mapViewMobile : styles.mapView}
      >
        <View style={styles.whereMyTeamView}>
          <Heading size={"md"} weight="semibold" color="black-core">
            Where's my team?
          </Heading>
          <Button
            testID="team-spaces-add-change-my-city-button"
            loading={loading}
            text={updateCityTitle}
            onPress={addMyCityPress}
          />
        </View>

        <TabsV3
          value={selectedTab}
          onChange={setSelectedTabPress}
          options={[
            {
              value: MapTabType.MapView,
              label: "Map view",
              testID: "team-spaces-map-view-tab",
            },
            {
              value: MapTabType.ListView,
              label: "List view",
              testID: "team-spaces-list-view-tab",
            },
          ]}
        />
      </View>

      <Spacer size={24} />
      <AsyncRenderer
        error={error}
        data={data}
        loadingHandler={() => <MapSkeleton />}
        loading={loading || currentUserLoading}
      >
        {(data) =>
          selectedTab === MapTabType.MapView ? (
            <TeamMap
              users={data.currentUser?.organization?.users || []}
              currentUserCity={data.currentUser?.city}
              addMyCityPress={addMyCityPress}
            />
          ) : (
            <TeamList users={data.currentUser?.organization?.users || []} />
          )
        }
      </AsyncRenderer>

      <DialogModal
        isVisible={addCityModalVisible}
        headerTitle={updateCityTitle}
        onClose={() => setAddCityModalVisible(false)}
        desktopWidth={780}
        bottomButtons={
          <>
            {mq.deviceQuery.mobile && (
              <Text size={"micro"}>
                Your exact location will never be shared with your team, unless
                you’re working from a flex space.{" "}
              </Text>
            )}
            <Button
              testID="add-change-my-city-done-button"
              text="Done"
              loading={loading || cityLoading}
              onPress={onDone}
              size={mq.deviceQuery.mobile ? "large" : "medium"}
            />
          </>
        }
      >
        <Spacer size={16} />
        <Text size={"xs"}>
          Where can your coworkers can find you right now?
        </Text>
        <Spacer size={16} />
        <TeamSearchInput getPlace={setPlace} />
        <Spacer size={8} />
        {mq.deviceQuery.desktop && (
          <>
            <Text size={"micro"}>
              Your exact location will never be shared with your team, unless
              you’re working from a flex space.{" "}
            </Text>
            <Spacer size={270} />
          </>
        )}
      </DialogModal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  mapView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
  },
  mapViewMobile: {
    flexDirection: "column",
    gap: 24,
  },
  whereMyTeamView: {
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  groupBtnView: {},
});

const addCityMutation = gql`
  mutation AddCity($input: AddCityInput!) {
    addCity(input: $input) {
      name
    }
  }
`;
const orgUsers = gql`
  ${cityDetailsFragment}
  ${teamsLocationDetailsFragment}
  ${teamsSpaceDetailsFragment}
  ${teamsUserDetailsFragment}
  ${teamsBookingDetailsFragment}
  query OrgUsers(
    $startDate: String!
    $endDate: String!
    $currentUserCoordinates: SpaceSearchCoordinates
    $status: UserStatus
  ) {
    currentUser {
      city {
        ...CityDetails
      }
      organization {
        users(status: $status) {
          ...OrgUserDetails
        }
      }
    }
  }

  fragment OrgUserDetails on OrganizationUser {
    ...TeamsUserDetails
    bookings(startDate: $startDate, endDate: $endDate) {
      ...TeamsBookingDetails
    }
  }
`;
