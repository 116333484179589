import { SelectInputButton } from "components/select/select_input_button";
import { format, getMonth } from "date-fns";
import {
  DayInterval,
  endOfMonth,
  parseDay,
  startOfMonth,
  startOfYear,
  subMonths,
  today,
} from "lib/day_utils";
import { ReportingPageSearchParams } from "pages/reporting/reporting_page";
import React from "react";

interface BudgetsDateRangePickerButtonProps {
  value: DayInterval;
  selectedPredeterminedOption: { label: string; value: string } | undefined;
  onPress: () => void;
  isOpen: boolean;
}

export function BudgetsDateRangePickerButton(
  props: BudgetsDateRangePickerButtonProps,
) {
  const { value, selectedPredeterminedOption, onPress, isOpen } = props;

  return (
    <SelectInputButton
      testID="date-range-picker-button"
      isOpen={isOpen}
      onPress={onPress}
      title={
        selectedPredeterminedOption
          ? selectedPredeterminedOption?.label
          : `Custom (${getDateRangeSubtitle(value)})`
      }
    />
  );
}

export const budgetsDateRangePickerOptions = [
  {
    label: "This Month",
    value: "this_month",
  },
  {
    label: "Last Month",
    value: "last_month",
  },
  {
    label: "Last 3 Months",
    value: "last3months",
  },
  {
    label: "Last 6 Months",
    value: "last6months",
  },
  {
    label: "Year to Date",
    value: "year2date",
  },
];

export function getSelectedPredeterminedOption(value: DayInterval) {
  return budgetsDateRangePickerOptions.find((o) => {
    const optionValue = getDayIntervalFromBudgetsDateRangePickerOption(o.value);
    return optionValue.start === value.start && optionValue.end === value.end;
  });
}

export function getDateRangeSubtitle(value: DayInterval) {
  const selectedPredeterminedOption = getSelectedPredeterminedOption(value);

  if (selectedPredeterminedOption) {
    const dayInterval = getDayIntervalFromBudgetsDateRangePickerOption(
      selectedPredeterminedOption.value,
    );

    if (
      getMonth(parseDay(dayInterval.start)) ===
      getMonth(parseDay(dayInterval.end))
    ) {
      return format(parseDay(value.start), "MMMM yyyy");
    }
    return `${format(parseDay(value.start), "MMMM")} - ${format(
      parseDay(value.end),
      "MMMM yyyy",
    )}`;
  }

  return `${format(parseDay(value.start), "MM/dd/yyyy")} - ${format(
    parseDay(value.end),
    "MM/dd/yyyy",
  )}`;
}

export function getDayIntervalFromBudgetsDateRangePickerOption(
  value: string,
): DayInterval {
  if (value === "this_month") {
    return {
      start: startOfMonth(today()),
      end: endOfMonth(today()),
    };
  } else if (value === "last_month") {
    return {
      start: startOfMonth(subMonths(today(), 1)),
      end: endOfMonth(subMonths(today(), 1)),
    };
  } else if (value === "last3months") {
    return {
      start: startOfMonth(subMonths(today(), 2)),
      end: endOfMonth(today()),
    };
  } else if (value === "last6months") {
    return {
      start: startOfMonth(subMonths(today(), 5)),
      end: endOfMonth(today()),
    };
  } else if (value === "year2date") {
    return {
      start: startOfYear(today()),
      end: today(),
    };
  }

  throw new Error(`Expected DayInterval from value=${value}`);
}

export const getInitialDayInterval = (
  sp: ReportingPageSearchParams,
): DayInterval => {
  if (sp?.start && sp?.end) {
    return {
      start: sp.start,
      end: sp.end,
    };
  }
  return getDayIntervalFromBudgetsDateRangePickerOption(
    budgetsDateRangePickerOptions[0].value,
  );
};
