import { gql, useMutation } from "@apollo/client";

import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { DialogModal } from "components/dialog_modal";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import { DateRange } from "core/booking_date_range_picker";
import {
  BookingRequestStatus,
  BookingType,
  CancelBookingRequestMutation,
  CancelBookingRequestMutationVariables,
  CreateBookingRequestMutation,
  CreateBookingRequestMutationVariables,
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  QuoteDetailsQuery,
  UserBookingRequestsQuery,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { useToast } from "providers/toast";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { convertDateTimeRange } from "../utils/convert_date_time_range";

import {
  BookingRequestDialog,
  BookingResquestDataType,
} from "./booking_request_dialog";
import { MobileSpaceDynamicPricings } from "./mobile_space_dynamic_pricing";

interface PropsType {
  dateRange: DateRange;
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  quoteId: string;
  bookingAmount: number;
  outOfPolicyMessagge: string;
  bookingRequestsQuery: UserBookingRequestsQuery;
  refetchBookingRequests: () => void;
  quoteQuery?: QuoteDetailsQuery;
  onOpenCalendar?: () => void;
}

export const OutOfPolicyDetails: React.FC<PropsType> = ({
  dateRange,
  quoteId,
  bookingAmount,
  outOfPolicyMessagge,
  bookingRequestsQuery,
  refetchBookingRequests,
  space,
  quoteQuery,
  onOpenCalendar,
}) => {
  const toast = useToast();
  const mq = useMediaQuery();
  const analytics = useAnalytics();

  const [showCreateRequestDialog, setShowCreateRequestDialog] = useState(false);
  const [showCancelRequestDialog, setShowCancelRequestDialog] = useState(false);
  const [values, setValues] = useState<BookingResquestDataType | null>(null);
  const [canceledRequestId, setCanceledRequestId] = useState<string>("");

  const [createBookingRequest] = useMutation<
    CreateBookingRequestMutation,
    CreateBookingRequestMutationVariables
  >(createBookingRequestMutation);

  const [cancelBookingRequest] = useMutation<
    CancelBookingRequestMutation,
    CancelBookingRequestMutationVariables
  >(cancelBookingRequestMutation);

  const handleCreateBookingRequest = async ({
    startDate,
    endDate,
    startTime,
    endTime,
    reason,
  }: BookingResquestDataType) => {
    analytics.event("Submit Booking Request", {
      category: "Approval Workflow",
    });

    try {
      await createBookingRequest({
        variables: {
          input: {
            spaceId: space.id,
            startDate,
            endDate: endDate,
            startTime,
            endTime,
            reason,
            quoteId,
            amount: bookingAmount,
          },
        },
      });

      toast.notify({
        message:
          "Submitted! You’ll receive an email once your request has been reviewed.",
      });
      refetchBookingRequests();
    } catch (error) {
      toast.notify({ message: "Error happened! Please try later!" });
    }
  };

  const handleCancelBookingRequest = async () => {
    analytics.event("Cancel Booking Request", {
      category: "Approval Workflow",
    });

    setShowCancelRequestDialog(false);
    try {
      await cancelBookingRequest({
        variables: {
          input: { id: canceledRequestId },
        },
      });

      toast.notify({
        message:
          "Request cancelled! You’ll receive an email confirming this cancellation.",
      });
      refetchBookingRequests();
    } catch (error) {
      toast.notify({ message: "Error happened! Please try later!" });
    }
  };

  const handleClickCancelRequest = (requestId: string) => {
    setCanceledRequestId(requestId);
    setShowCancelRequestDialog(true);
  };

  const handleClickSubmitNewRequest = () => {
    const selectedDateRange = {
      startDate:
        dateRange.type === BookingType.DailyBooking
          ? dateRange.startDate
          : dateRange.date,
      endDate:
        dateRange.type === BookingType.DailyBooking
          ? dateRange.endDate
          : dateRange.date,
      startTime:
        dateRange.type === BookingType.HourlyBooking
          ? dateRange.startTime
          : undefined,
      endTime:
        dateRange.type === BookingType.HourlyBooking
          ? dateRange.endTime
          : undefined,
    };
    const { startDate, endDate, startTime, endTime } = selectedDateRange;
    if (startDate && endDate) {
      setShowCreateRequestDialog(true);
      setValues({
        reason: "",
        startDate,
        endDate,
        startTime,
        endTime,
      });
    }
  };

  const renderContent = () => {
    const bookingRequests =
      bookingRequestsQuery?.space?.bookingRequests?.filter(
        (bR) => bR.status === BookingRequestStatus.Pending,
      ) || [];

    const requestButton = (
      <Button
        testID="request-to-book-button"
        text={
          bookingRequests.length === 0
            ? "Request to book"
            : "Submit a new request"
        }
        onPress={handleClickSubmitNewRequest}
      />
    );

    return (
      <View>
        <View
          style={[
            styles.card,
            mq.deviceQuery.mobile && styles.cardMobile,
            styles.outOfPolicyCard,
          ]}
        >
          <View>
            <View style={styles.cardTitle}>
              <Icon name="guard" />
              <Text weight="semibold" size="xs">
                Out-of-policy
              </Text>
            </View>
            <Text size="xs">{outOfPolicyMessagge}</Text>
          </View>
        </View>

        <Spacer size={mq.deviceQuery.mobile ? 0 : 14} />

        {bookingRequests.map(
          ({ id, startDate, endDate, startTime, endTime }) => (
            <View>
              <View key={id} style={[styles.card, styles.greyCard]}>
                <Text size="xs">
                  You’ve requested to book this space{" "}
                  {convertDateTimeRange(startDate, endDate, startTime, endTime)}
                  .{" "}
                  <TextLink
                    text="Cancel this request."
                    size="xs"
                    inline
                    onPress={() => handleClickCancelRequest(id)}
                  />
                </Text>
              </View>
              <Spacer size={mq.deviceQuery.mobile ? 5 : 14} />
            </View>
          ),
        )}

        {mq.deviceQuery.mobile ? (
          <View style={styles.pricingWrapperMobile}>
            <View>
              <MobileSpaceDynamicPricings
                space={space}
                quoteQuery={quoteQuery}
                dateRange={dateRange}
                openCalendar={onOpenCalendar}
              />
            </View>
            {requestButton}
          </View>
        ) : (
          requestButton
        )}
      </View>
    );
  };

  return (
    <View>
      {renderContent()}
      {values && (
        <BookingRequestDialog
          isVisible={showCreateRequestDialog}
          onClose={() => setShowCreateRequestDialog(false)}
          onSubmit={handleCreateBookingRequest}
          initialValues={values}
          submitText={"Submit"}
        />
      )}
      <DialogModal
        isVisible={showCancelRequestDialog}
        showCloseButton={true}
        onClose={() => setShowCancelRequestDialog(false)}
        headerTitle="Are you sure you want to cancel?"
        bottomButtons={
          <View style={styles.cancelDialogBottomButtonsWrapper}>
            <Button
              text="Not now"
              appearance="textLink"
              onPress={() => setShowCancelRequestDialog(false)}
            />
            <Button text="Yes, cancel" onPress={handleCancelBookingRequest} />
          </View>
        }
      >
        <Text size="xs" align="center">
          This request won’t be able to be reviewed by your administrators. This
          can’t be undone.
        </Text>
      </DialogModal>
    </View>
  );
};

const styles = StyleSheet.create({
  cardTitle: {
    flexDirection: "row",
    alignItems: "center",
    gap: 9,
    marginBottom: 9,
  },
  card: {
    padding: 12,
    borderRadius: 4,
  },
  cardMobile: {
    padding: 12,
  },
  greyCard: {
    backgroundColor: colors.brand.blackMinus90,
  },
  outOfPolicyCard: {
    backgroundColor: colors.brand.bananaMinus70,
  },
  cancelDialogBottomButtonsWrapper: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  pricingWrapperMobile: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    paddingTop: 16,
    paddingBottom: 32,
  },
});

const createBookingRequestMutation = gql`
  mutation CreateBookingRequest($input: CreateBookingRequestInput!) {
    createBookingRequest(input: $input) {
      id
    }
  }
`;

const cancelBookingRequestMutation = gql`
  mutation CancelBookingRequest($input: CancelBookingRequestInput!) {
    cancelBookingRequest(input: $input) {
      id
    }
  }
`;
