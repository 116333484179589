import { gql, useQuery } from "@apollo/client";
import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  Amenity,
  PriceRangesType,
  PricingType,
  SpaceCardCurrentUserCountryQuery,
  SpaceType,
} from "core/graphql.generated";
import { getSystemLocale } from "lib/locale";
import { getDistanceText } from "pages/homev2/utils/get_distance_text";
import { getDynamicPriceTagText } from "pages/homev2/utils/get_price_tag_text";
import {
  useMapImprovementFeatureFlag,
  usePriceDiscoverabilityFeatureFlag,
} from "providers/splitio";
import React, { ReactNode } from "react";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import { Link } from "react-router-dom";
import { useGetDistance } from "../../hooks/use_get_distance";

interface PropTypes {
  linkTo?: string;
  children: ReactNode;
  testId?: string;
  appearance?: "compact" | "noShadow" | undefined;
  onPress?: () => void;
}

export const SpaceCardTemplate = ({
  testId,
  linkTo,
  children,
  appearance,
  onPress,
}: PropTypes) => {
  const content = (
    <View
      style={[
        styles.mainWrapper,
        appearance === "compact" && styles.mainWrapperCompact,
        appearance === "noShadow" && styles.noShadow,
      ]}
      testID="SpaceCardTemplate"
    >
      {children}
    </View>
  );

  if (linkTo) {
    return (
      <Link
        data-testid={testId}
        style={{
          display: "flex",
          textDecoration: "none",
          flex: 1,
          minWidth: 0,
        }}
        to={linkTo}
      >
        {content}
      </Link>
    );
  }

  if (onPress) {
    return (
      <Pressable testID={testId} onPress={onPress}>
        {content}
      </Pressable>
    );
  }

  return content;
};

export const SpaceCardPhoto = ({
  imageSrc,
  appearance,
  hasOverlay,
}: {
  imageSrc: string;
  appearance?: "compact" | undefined;
  hasOverlay?: boolean;
}) => {
  return (
    <View
      style={[
        styles.photoContainer,
        appearance === "compact" && styles.photoContainerCompact,
      ]}
    >
      <img
        src={imageSrc}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        alt={"cover 1"}
      />
      {hasOverlay && <View style={styles.photoOverlay} />}
    </View>
  );
};

export const SpaceCardContent = ({ children }: { children: ReactNode }) => {
  return <View style={styles.contentContainer}>{children}</View>;
};

export const SpaceCardWhoIsHere = ({ children }: { children: ReactNode }) => {
  return <View style={styles.whoIsHere}>{children}</View>;
};

export function SpaceCardTitleWithCapacity(props: {
  title: React.ReactNode;
  minCapacity?: number;
  maxCapacity?: number;
  outOfPolicy?: boolean;
}) {
  const { title, minCapacity, maxCapacity, outOfPolicy } = props;

  return (
    <View
      style={{
        // flex: 1,
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View style={styles.outOfPolicy}>
        {outOfPolicy && (
          <View
            style={{
              width: 16,
            }}
          >
            <Icon name="out-of-policy-black" />
          </View>
        )}
        <Text size="xs" color="black-core" weight="semibold">
          {title}
        </Text>
      </View>
      {minCapacity && maxCapacity && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Icon name="person" size="sm" />
          <Text size="xs" weight="regular" color="black-core" align="center">
            {getCapacityLabel(minCapacity, maxCapacity)}
          </Text>
        </View>
      )}
    </View>
  );
}

function getCapacityLabel(minCapacity: number, maxCapacity: number) {
  if (minCapacity === maxCapacity) {
    return minCapacity;
  }

  return `${minCapacity} - ${maxCapacity}`;
}

export function SpaceCardAddress({ children }: { children: ReactNode }) {
  return (
    <View>
      <Text size="xs" color="black-70" weight="regular">
        {children}
      </Text>
    </View>
  );
}

interface CardAddressWithDistance {
  locationLat?: number | null;
  locationLng?: number | null;
  currentUserDistance: number | undefined | null;
  shortAddress: string;
  fullAddress: string;
  locationName?: string;
}

export function SpaceCardAddressWithDistance({
  locationLat,
  locationLng,
  currentUserDistance,
  shortAddress,
  fullAddress,
  locationName,
}: CardAddressWithDistance) {
  const { data: currentUser } = useQuery<SpaceCardCurrentUserCountryQuery>(
    spaceCardCurrentUserCountryGQLQuery,
  );

  const isMapImprovement = useMapImprovementFeatureFlag();

  const { distance } = useGetDistance({
    lat: locationLat || 0,
    lng: locationLng || 0,
  });

  let distanceText = getDistanceText(
    currentUserDistance,
    currentUser?.currentUser?.ipPlace?.country,
  );

  if (isMapImprovement) {
    distanceText = distance
      ? getDistanceText(distance, currentUser?.currentUser?.ipPlace?.country)
      : "";
  }

  const subTitle = distanceText
    ? `${shortAddress} · ${distanceText}. away`
    : `${fullAddress.trim()}, ${shortAddress}`;

  return (
    <View>
      {!!locationName && <SpaceCardAddress>{locationName}</SpaceCardAddress>}
      <Spacer size={4} />
      <SpaceCardAddress>{subTitle}</SpaceCardAddress>
    </View>
  );
}

export function LocationListCardAddressWithDistance({
  locationLat,
  locationLng,
  shortAddress,
  fullAddress,
  locationName,
}: CardAddressWithDistance) {
  const { data: currentUser } = useQuery<SpaceCardCurrentUserCountryQuery>(
    spaceCardCurrentUserCountryGQLQuery,
  );

  const { distance } = useGetDistance({
    lat: locationLat || 0,
    lng: locationLng || 0,
  });

  let distanceText = distance
    ? getDistanceText(distance, currentUser?.currentUser?.ipPlace?.country)
    : "";

  const subTitle = distanceText
    ? `${shortAddress} · ${distanceText}. away`
    : `${fullAddress.trim()}, ${shortAddress}`;

  return (
    <View>
      {!!locationName && (
        <View>
          <Text size="xs" weight="semibold">
            {locationName}
          </Text>
        </View>
      )}
      <Spacer size={4} />
      <SpaceCardAddress>{subTitle}</SpaceCardAddress>
    </View>
  );
}

export function SpaceCardAmenities({ amenities }: { amenities: Amenity[] }) {
  const amenitiesList = amenities?.slice(0, 3);

  return (
    <View style={[styles.amenities]}>
      <Text size="xs" color="black-core" weight="regular">
        {amenitiesList?.map((amenity) => amenity.name).join(" · ")}
        {amenities?.length > 3 && ` + ${amenities.length - 3}`}
      </Text>
    </View>
  );
}

export function SpaceCardPrice({ children }: { children: ReactNode }) {
  return (
    <View>
      <Text size="xs" weight="semibold" color="black-core">
        {children}
      </Text>
    </View>
  );
}

type SpaceCardDynamicPriceProps = {
  pricings: {
    type: PricingType;
    price: number;
  }[];
  currency: string;
  priceRanges: PriceRangesType;
  spaceType: SpaceType;
  isPickedDate: boolean;
  outOfPolicy?: boolean;
};
export function SpaceCardDynamicPrice({
  pricings,
  currency,
  priceRanges,
  spaceType,
  isPickedDate,
  outOfPolicy = false,
}: SpaceCardDynamicPriceProps) {
  const isMTRoom = spaceType === SpaceType.MeetingRoom;
  const priceType = isMTRoom ? PricingType.Hourly : PricingType.Daily;
  const basePrice = pricings.filter((p) => p.type === priceType);
  const dynamicPrice = isMTRoom ? priceRanges.hourly : priceRanges.daily;
  const isMultiDayDP = usePriceDiscoverabilityFeatureFlag();
  /*Only show the 2 prices dynamic price + base price(with line-through text) if
  the date had been chosen and min price in dynamicPrice is lower than the basePrice.
  */
  if (
    isPickedDate &&
    dynamicPrice.min &&
    dynamicPrice.max &&
    basePrice[0]?.price &&
    /* In normal case basePrice is array of single element, so we compare min price of dynamicPrice with basePrice of that element
    In the case one location had multiple office the basePrice is array of 2 element lowest basePrice and highest basePrice (sorted).
    so we take the compared between dynamicPrice vs basePrice of only the lowest basePrice element.
    */
    dynamicPrice.min < basePrice[0].price
  ) {
    return (
      <View style={styles.horizontalView}>
        <Text
          size="xs"
          weight="semibold"
          color={isMultiDayDP && !outOfPolicy ? "eggplant-core" : "black-core"}
        >
          {getDynamicPriceTagText({
            pricings:
              spaceType === SpaceType.DayPass
                ? [{ type: priceType, price: dynamicPrice.min }]
                : [
                    { type: priceType, price: dynamicPrice.min },
                    { type: priceType, price: dynamicPrice.max },
                  ],
            currency,
          })}
        </Text>
        <Text size="xs" color="black-70" decoration={"line-through"}>
          {getDynamicPriceTagText({ pricings: basePrice, currency })}
        </Text>
      </View>
    );
  }

  return (
    <Text size="xs" weight="semibold" color="black-core">
      {getDynamicPriceTagText({ pricings: basePrice, currency })}
    </Text>
  );
}

export const formatPrice = (price: number, currency: string) =>
  `${new Intl.NumberFormat(getSystemLocale(), {
    style: "currency",
    currency: currency,
    minimumSignificantDigits: 1,
  }).format(price)}`;

export const savedPrice = (
  basePrice: number,
  currency: string,
  unitPrice?: number,
  totalPrice?: number | null,
  originalTotalPrice?: number,
) => {
  let savedPriceText = "";
  if (
    totalPrice &&
    unitPrice &&
    totalPrice < (basePrice * totalPrice) / unitPrice
  ) {
    let savedPrice = Number(
      ((basePrice * totalPrice) / unitPrice - totalPrice).toFixed(2),
    );
    if (originalTotalPrice && originalTotalPrice > totalPrice) {
      savedPrice = Number((originalTotalPrice - totalPrice).toFixed(2));
    }
    if (totalPrice <= 100) {
      const percentageSaved = Math.ceil(
        ((basePrice - unitPrice) * 100) / basePrice,
      );
      savedPriceText = percentageSaved + "%";
    } else {
      const roundedSavedPrice = Math.ceil(savedPrice);
      savedPriceText = formatPrice(roundedSavedPrice, currency);
    }
  }

  return savedPriceText;
};

export function SpaceCardOutOfPolicyBadge() {
  return (
    <View style={styles.outOfPolicyBadgeWrapper} testID="out-of-policy">
      <Icon name="guard" color="whitecore" filled />
      <Text color="white-core" weight="semibold" size="xs">
        Out-of-policy
      </Text>
    </View>
  );
}

export function SpaceCardDetailsLink({
  children,
  onPress,
}: {
  children: ReactNode;
  onPress?: (e: GestureResponderEvent) => void;
}) {
  const content = (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text color="eggplant-core" size="xs" weight="semibold">
        {children}
      </Text>{" "}
      <Spacer direction="row" size={8} />
      <Icon name="chevron-feather-right" />
    </View>
  );

  if (!onPress) {
    return content;
  }

  return <Pressable onPress={onPress}>{content}</Pressable>;
}

// export function SpaceCardBottom({ children }: { children: ReactNode }) {
//   return <View style={styles.bottomSection}>{children}</View>;
// }

const BORDER_RADIUS = 4;

const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
    borderRadius: BORDER_RADIUS,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  mainWrapperCompact: {
    flexDirection: "row",
  },
  noShadow: {
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0,
    borderBottomWidth: 1,
    borderColor: colors.brand.blackMinus90,
    marginHorizontal: 24,
  },
  photoContainer: {
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    height: 148,
    overflow: "hidden",
  },
  photoContainerCompact: {
    maxWidth: 114,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: BORDER_RADIUS,
    height: "100%",
  },
  photoOverlay: {
    backgroundColor:
      "linear-gradient(0deg, rgba(6, 0, 30, 0.4), rgba(6, 0, 30, 0.4))",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  whoIsHere: {
    position: "absolute",
    top: 12,
    left: 12,
  },
  contentContainer: {
    flex: 1,
    padding: 16,
    gap: 8,
  },
  outOfPolicy: {
    flexDirection: "row",
    gap: 8,
    width: "fit-content",
  },
  bottomSection: {
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    backgroundColor: colors.brand.blackMinus90,
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  amenities: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  lockedBadgeWrapper: {
    position: "absolute",
    right: 7,
    top: 7,
  },
  lockedBadgeDesktopWrapper: {
    position: "absolute",
    right: 40,
    top: 8,
  },
  outOfPolicyBadgeWrapper: {
    position: "absolute",
    left: 14,
    top: 13,
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
  },
  horizontalView: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginBottom: 4,
  },
});

export const spaceCardCurrentUserCountryGQLQuery = gql`
  query SpaceCardCurrentUserCountry {
    currentUser {
      id
      ipPlace {
        country
      }
    }
  }
`;
