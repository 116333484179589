import { Fragment, useCallback, useReducer, useRef } from "react";
import { StyleSheet, View } from "react-native";

import { Spinner } from "components/spinner";
import { MapboxFeature, toCoordinates } from "pages/homev2/mapbox";
import { useForwardGeocoding } from "pages/homev2/hooks/use_forward_geocoding";
// import { useInitialPlace } from "pages/homev2/hooks/use_initial_place";
import { useSearchHistory } from "pages/homev2/hooks/use_search_history";
import {
  HomeSearchParamsChangeSet,
  // useHomeSearchParamsQuery,
} from "pages/homev2/hooks/use_home_search_params";
import {
  useCurrentUserGeoPlaceLazyQuery,
  useCurrentUserGeoPlaceQuery,
} from "pages/homev2/hooks/use_current_user_geo_place";
import { useToast } from "providers/toast";
import { TextField } from "components/text_field";
import {
  CurrentLocationOption,
  SearchOption,
} from "pages/homev2/components/search_bar_option";
import { Text } from "components/text_v2";
import { Divider } from "components/divider";
import { colors } from "components/colors";
import { Icon } from "components/icon";
import { useAnalytics } from "providers/analytics";
import { Spacer } from "components/spacer";

interface State {
  initialized: boolean;
  showAutocomplete: boolean;
  focused: boolean;
  search: string;
  selectedItemIndex: number;
}

type Action =
  | {
      type: "SET_SHOW_AUTOCOMPLETE";
      showAutocomplete: boolean;
    }
  | {
      type: "FOCUS";
    }
  | {
      type: "BLUR";
    }
  | {
      type: "FILL_SEARCH";
      search: string;
    }
  | {
      type: "UPDATE_SEARCH";
      search: string;
    }
  | {
      type: "INITIALIZE";
      search: string;
    }
  | {
      type: "SET_SELECTED_ITEM_INDEX";
      index: number;
    };

function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case "SET_SHOW_AUTOCOMPLETE":
      return {
        ...prevState,
        showAutocomplete: action.showAutocomplete,
      };
    case "UPDATE_SEARCH":
      return {
        ...prevState,
        search: action.search,
        showAutocomplete: true,
        selectedItemIndex: 0,
      };
    case "FILL_SEARCH":
      return {
        ...prevState,
        search: action.search,
        showAutocomplete: false,
        focused: false,
        selectedItemIndex: 0,
      };
    case "FOCUS":
      return {
        ...prevState,
        focused: true,
      };
    case "BLUR":
      return {
        ...prevState,
        focused: false,
        selectedItemIndex: 0,
      };
    case "INITIALIZE":
      return {
        ...prevState,
        search: action.search,
        initialized: true,
      };
    case "SET_SELECTED_ITEM_INDEX":
      return {
        ...prevState,
        selectedItemIndex: action.index,
      };

    default:
      throw new Error(`Action not handled ${action}`);
  }
}

interface SearchLocationMobileProps {
  searchPlace: string;
  onChange: (
    searchParamsChangeSet: HomeSearchParamsChangeSet,
    searchPlace: string,
  ) => void;
}

export function SearchLocationMobile({
  searchPlace,
  onChange,
}: SearchLocationMobileProps) {
  const initialState: State = {
    initialized: false,
    showAutocomplete: false,
    focused: false,
    search: searchPlace,
    selectedItemIndex: 0,
  };

  const { searchHistory, addToSearchHistory } = useSearchHistory();
  const currentUserGeoPlace = useCurrentUserGeoPlaceQuery();
  const [currentUserGeoPlaceLazyQuery] = useCurrentUserGeoPlaceLazyQuery();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search, showAutocomplete, selectedItemIndex } = state;

  const toast = useToast();
  const analytics = useAnalytics();

  const handleSelectCurrentLocation = useCallback(() => {
    if (currentUserGeoPlace && currentUserGeoPlace.data) {
      dispatch({
        type: "FILL_SEARCH",
        search: currentUserGeoPlace.data.place_name,
      });
      onChange(
        {
          coordinates: toCoordinates(currentUserGeoPlace.data.center),
          bbox: currentUserGeoPlace.data?.bbox,
        },
        currentUserGeoPlace.data.place_name,
      );
    } else {
      currentUserGeoPlaceLazyQuery()
        .then((place) => {
          dispatch({
            type: "FILL_SEARCH",
            search: place.text,
          });
          onChange(
            {
              coordinates: toCoordinates(place.center),
              bbox: place.bbox,
            },
            place.text,
          );
        })
        .catch(() => {
          toast.notify({
            message: "Please enable location permissions in browser settings",
          });
        });
    }
  }, [currentUserGeoPlace, currentUserGeoPlaceLazyQuery, toast, onChange]);

  const handleSelectPlace = useCallback(
    (place: MapboxFeature) => {
      addToSearchHistory(place);
      dispatch({
        type: "FILL_SEARCH",
        search: place.text,
      });
      onChange(
        {
          coordinates: toCoordinates(place.center),
          bbox: place.bbox,
          placeId: place.id,
        },
        place.text,
      );

      analytics.event("Search Place", {
        "Search Text": search,
        "Place Name": place.place_name,
      });
    },
    [addToSearchHistory, search, analytics, onChange],
  );

  const { data: autocompleteOptions, loading: autocompleteLoading } =
    useForwardGeocoding(search, {
      skip: !showAutocomplete,
    });

  const handleSearchChange = useCallback((nextSearch: string) => {
    dispatch({ type: "UPDATE_SEARCH", search: nextSearch });
  }, []);

  const handleFocus = useCallback(() => {
    dispatch({ type: "FOCUS" });
    inputRef.current?.select();
  }, []);
  const handleBlur = useCallback(() => {
    dispatch({ type: "BLUR" });
  }, []);

  const showingAutocomplete = showAutocomplete && search?.length;

  const onKeyPress = useCallback(
    (e) => {
      if (e.key === "Escape") {
        e.target?.blur?.();
        handleBlur();
      } else if (e.key === "Enter") {
        if (showingAutocomplete && autocompleteOptions?.[selectedItemIndex]) {
          handleSelectPlace(autocompleteOptions?.[selectedItemIndex]);
        } else if (!showingAutocomplete && selectedItemIndex < 2) {
          handleSelectCurrentLocation();
        } else {
          handleSelectPlace(searchHistory?.[selectedItemIndex - 2]);
        }
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        const searchResults = showingAutocomplete
          ? autocompleteOptions
          : [...searchHistory, null, null];
        return dispatch({
          type: "SET_SELECTED_ITEM_INDEX",
          index:
            selectedItemIndex < searchResults?.length! - 1
              ? selectedItemIndex + 1
              : 0,
        });
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        const searchResults = showingAutocomplete
          ? autocompleteOptions
          : [...searchHistory, null, null];
        return dispatch({
          type: "SET_SELECTED_ITEM_INDEX",
          index:
            selectedItemIndex > 0
              ? selectedItemIndex - 1
              : searchResults?.length! - 1,
        });
      }
    },
    [
      autocompleteOptions,
      handleBlur,
      handleSelectCurrentLocation,
      handleSelectPlace,
      searchHistory,
      selectedItemIndex,
      showingAutocomplete,
    ],
  );

  const renderedSearchResult = showingAutocomplete ? (
    autocompleteLoading ? (
      <Spinner />
    ) : autocompleteOptions && autocompleteOptions?.length > 0 ? (
      autocompleteOptions?.map((place, i) => (
        <Fragment key={`${place.id}-autocomplete`}>
          {i > 0 && (
            <View style={{ paddingHorizontal: 16 }}>
              <Divider />
            </View>
          )}
          <SearchOption
            place={place}
            onPress={handleSelectPlace}
            isSelected={selectedItemIndex === i}
          />
        </Fragment>
      ))
    ) : (
      <View style={styles.emptySearch}>
        <Text size="xs" weight="semibold">
          Hmm...we can’t find this location.
        </Text>
        <Spacer size={8} />
        <Text size="xs">
          Try double-checking your spelling or broadening your search to a city
          or region.
        </Text>
      </View>
    )
  ) : (
    <View>
      <CurrentLocationOption
        onPress={handleSelectCurrentLocation}
        label={currentUserGeoPlace.data?.place_name || ""}
        isSelected={selectedItemIndex === 1}
      />
      {searchHistory.length > 0 && (
        <>
          <View style={{ padding: 16, paddingBottom: 8 }}>
            <Text size="xs" customColor="#000" weight="semibold">
              Recent searches
            </Text>
          </View>
          {searchHistory.map((place, i) => (
            <Fragment key={`${place.id}-search-history`}>
              {i > 0 && (
                <View style={{ paddingHorizontal: 16 }}>
                  <Divider color="dark" />
                </View>
              )}
              <SearchOption
                place={place}
                onPress={handleSelectPlace}
                isSelected={selectedItemIndex === i + 2}
              />
            </Fragment>
          ))}
        </>
      )}
    </View>
  );

  const renderedTextField = (
    <TextField
      onKeyPress={onKeyPress}
      value={search}
      inputPlaceholder="Search for a city, region, or neighborhood"
      ref={inputRef}
      onFocus={handleFocus}
      onChange={handleSearchChange}
      leftIcon={<Icon name="magnifying-glass" />}
      rightIcon={
        !!search?.length && (
          <Icon size="sm" color={colors.brand.blackMinus30} name="x-circle" />
        )
      }
      onRightIconClick={() => {
        handleSearchChange("");
        inputRef.current?.focus();
      }}
    />
  );

  return (
    <View style={styles.root}>
      {renderedTextField}
      <View style={{ marginHorizontal: -16 }}>{renderedSearchResult}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
  },
  emptySearch: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
