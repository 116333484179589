import { Dialog } from "components/dialog";
import { HorizontalList } from "components/horizontal_list";
import { Text } from "components/text_v2";
import {
  TeamsLocationDetailsFragment,
  TeamsSpaceDetailsFragment,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { TeamLocationPreview } from "pages/team_spaces/components/team_location_preview";
import { TeamSpaceCard } from "pages/team_spaces/components/team_space_card";
import { useAnalytics } from "providers/analytics";
import React, { useState } from "react";

type TeamSpacesListProps = {
  topSpaces?: TeamsSpaceDetailsFragment[];
};

export function YourTeamLoveSpace({ topSpaces }: TeamSpacesListProps) {
  const mq = useMediaQuery();
  const [locationPreviewData, setLocationPreviewData] =
    useState<TeamsLocationDetailsFragment>();
  const analytics = useAnalytics();

  if (!topSpaces || topSpaces.length === 0) {
    return null;
  }
  const renderItem = (sp: TeamsSpaceDetailsFragment) => (
    <TeamSpaceCard
      key={sp.id}
      space={sp}
      peoples={sp.location.bookings.map((b) => b.user)}
      onPreviewLocation={(location) => {
        setLocationPreviewData(location);
        analytics.event("Click on Popular Space", {
          Category: "Where is everyone",
          "Space UUID": sp.id,
          "Space Name": sp.name,
          City: sp.location.address.city,
          State: sp.location.address.state,
          Country: sp.country,
          Location: sp.location.name,
        });
      }}
    />
  );

  return (
    <>
      <HorizontalList
        title={
          <Text size={"md"} weight={"semibold"}>
            Your team loves these spaces
          </Text>
        }
        data={Object.values(topSpaces)}
        renderItem={renderItem}
        itemGap={mq.deviceQuery.mobile ? 16 : 24}
      />
      {locationPreviewData && (
        <Dialog
          visible
          onRequestClose={() => setLocationPreviewData(undefined)}
        >
          <TeamLocationPreview
            locationPreviewData={locationPreviewData}
            onClose={() => setLocationPreviewData(undefined)}
          />
        </Dialog>
      )}
    </>
  );
}
