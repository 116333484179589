import React, { Fragment, useMemo } from "react";
import { View, StyleSheet } from "react-native";

import { chunk } from "lib/array_utils";
import {
  isBefore,
  isAfter,
  endOfDay,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  subDays,
  differenceInDays,
  addDays,
  isSameDay,
  isWithinDateInterval,
  DateInterval,
  DayOfWeek,
  formatISODate,
  getFirstDateOfWeek,
  getLastDateOfWeek,
} from "lib/date_utils";
import {
  DEFAULT_FIRST_DAY_OF_WEEK,
  eachDayOfWeek,
  FirstDayOfWeek,
} from "lib/week_utils";
import { Row } from "./row";
import { format } from "date-fns";
import { DynamicPrice } from "./month_view/month_view";

interface MonthRendererProps {
  /** Date with which we infer the month from */
  month: Date;
  selectedInterval?: DateInterval | null;
  firstDayOfWeek: DayOfWeek;
  isOutsideRange?: (day: Date) => boolean;
  isBlocked?: (day: Date) => boolean;
  renderOtherMonthDay: (props: RenderOtherMonthProps) => React.ReactNode;
  renderDay: (props: RenderDayProps) => React.ReactNode;
  renderWeek: (props: RenderWeekProps) => React.ReactNode;
  renderDayOfWeek: (props: RenderDayOfWeekProps) => React.ReactNode;
  withDaysOfWeek?: boolean;
  basePrice?: string;
  dynamicPrices?: DynamicPrice[];
}

export interface RenderDayState {
  withinSelected: boolean;
  selected: boolean;
  selectedStart: boolean;
  selectedEnd: boolean;
  today: boolean;
  outsideRange: boolean;
  blocked: boolean;
  basePrice?: string;
  dynamicPrice?: string;
  isDiscountRate?: boolean;
}

export interface RenderDayProps extends RenderDayState {
  day: Date;
}

export interface RenderOtherMonthProps {
  day: Date;
  withinSelected: boolean;
}

export interface RenderWeekProps {
  week: Week;
  children: React.ReactNode;
}

export function MonthRenderer(props: MonthRendererProps): JSX.Element {
  const {
    month,
    firstDayOfWeek,
    selectedInterval,
    isOutsideRange,
    isBlocked,
    renderDay,
    renderOtherMonthDay,
    renderDayOfWeek,
    renderWeek,
    withDaysOfWeek = true,
    basePrice,
    dynamicPrices,
  } = props;
  const weeks = getWeeks(month, firstDayOfWeek);

  return (
    <View>
      {!!withDaysOfWeek && (
        <DaysOfWeek
          renderDayOfWeek={renderDayOfWeek}
          firstDayOfWeek={firstDayOfWeek}
        />
      )}
      {weeks.map((week) => (
        <WeekView
          key={week.index}
          week={week}
          selectedInterval={selectedInterval}
          isOutsideRange={isOutsideRange}
          isBlocked={isBlocked}
          renderDay={renderDay}
          renderOtherMonthDay={renderOtherMonthDay}
          renderWeek={renderWeek}
          basePrice={basePrice}
          dynamicPrices={dynamicPrices}
        />
      ))}
    </View>
  );
}

export interface RenderDayOfWeekProps {
  date: Date;
}

interface DaysOfWeekProps {
  firstDayOfWeek?: FirstDayOfWeek;
  date?: Date;
  renderDayOfWeek: (props: RenderDayOfWeekProps) => React.ReactNode;
}

export const DaysOfWeek = (props: DaysOfWeekProps) => {
  const {
    date = new Date(),
    renderDayOfWeek,
    firstDayOfWeek = DEFAULT_FIRST_DAY_OF_WEEK,
  } = props;
  const dates = eachDayOfWeek(date, firstDayOfWeek);

  return (
    <Row flex={1}>
      {dates.map((date) => (
        <div
          key={date.toISOString()}
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            boxSizing: "border-box",
            justifyContent: "center",
            alignItems: "center",
            padding: 1,
          }}
        >
          {renderDayOfWeek({ date })}
        </div>
      ))}
    </Row>
  );
};

interface WeekViewProps {
  week: Week;
  selectedInterval?: DateInterval | null;
  isOutsideRange?: (day: Date) => boolean;
  isBlocked?: (day: Date) => boolean;
  renderOtherMonthDay: (props: RenderOtherMonthProps) => React.ReactNode;
  renderDay: (props: RenderDayProps) => React.ReactNode;
  renderWeek: (props: RenderWeekProps) => React.ReactNode;
  basePrice?: string;
  dynamicPrices?: DynamicPrice[];
}

function WeekView(props: WeekViewProps): JSX.Element {
  const {
    week,
    selectedInterval,
    isOutsideRange,
    isBlocked,
    renderOtherMonthDay,
    renderDay,
    renderWeek,
    basePrice,
    dynamicPrices,
  } = props;

  const children = useMemo(() => {
    const som = startOfMonth(week.month);
    const eom = endOfMonth(week.month);

    return (
      <View style={styles.weekWrapper}>
        {week.days.map((day) => {
          const {
            withinSelected,
            selected,
            selectedStart,
            selectedEnd,
            today,
            outsideRange,
            blocked,
            isWithinCurrentMonth,
          } = getRenderDayState({
            som,
            eom,
            day,
            selectedInterval,
            isBlocked,
            isOutsideRange,
          });

          if (!isWithinCurrentMonth) {
            const hasSelectionNextMonth = selectedInterval
              ? isAfter(day, eom) &&
                isAfter(selectedInterval.end, eom) &&
                (isSameDay(selectedInterval.start, eom) ||
                  isBefore(selectedInterval.start, eom))
              : false;

            const hasSelectionPreviousMonth = selectedInterval
              ? isBefore(day, som) &&
                isBefore(selectedInterval.start, som) &&
                (isSameDay(selectedInterval.end, som) ||
                  isAfter(selectedInterval.end, som))
              : false;

            return (
              <OtherMonthDayContainer
                key={formatISODate(day)}
                day={day}
                renderOtherMonthDay={renderOtherMonthDay}
                withinSelected={
                  hasSelectionNextMonth || hasSelectionPreviousMonth
                }
              />
            );
          }

          const dynamicPrice = dynamicPrices?.find(
            (p) => p.date === format(day, "yyyy-MM-dd"),
          );

          return (
            <DayView
              key={formatISODate(day)}
              day={day}
              withinSelected={withinSelected}
              selected={selected}
              selectedStart={selectedStart}
              selectedEnd={selectedEnd}
              today={today}
              outsideRange={outsideRange}
              blocked={blocked}
              renderDay={renderDay}
              basePrice={basePrice}
              dynamicPrice={dynamicPrice?.price}
              isDiscountRate={dynamicPrice?.isDiscountRate}
            />
          );
        })}
      </View>
    );
  }, [
    week,
    selectedInterval,
    isOutsideRange,
    isBlocked,
    renderOtherMonthDay,
    renderDay,
    basePrice,
    dynamicPrices,
  ]);

  return <Fragment>{renderWeek({ week, children })}</Fragment>;
}

interface GetRenderDayStateParams {
  day: Date;
  som: Date;
  eom: Date;
  selectedInterval?: DateInterval | null;
  isOutsideRange?: (day: Date) => boolean;
  isBlocked?: (day: Date) => boolean;
}

function getRenderDayState(params: GetRenderDayStateParams) {
  const { selectedInterval, day, som, eom, isOutsideRange, isBlocked } = params;

  const isWithinCurrentMonth = isWithinDateInterval(day, {
    start: som,
    end: eom,
  });
  const selectedStart = selectedInterval
    ? isSameDay(day, selectedInterval.start)
    : false;
  const selectedEnd = selectedInterval
    ? isSameDay(day, selectedInterval.end)
    : false;
  const selected = selectedStart || selectedEnd;
  const withinSelected =
    selectedInterval && !selected
      ? isWithinDateInterval(day, selectedInterval)
      : false;
  const outsideRange = isOutsideRange ? isOutsideRange(day) : false;
  const today = isSameDay(day, new Date());
  const blocked = isBlocked ? isBlocked(day) : false;

  return {
    withinSelected,
    selected,
    selectedStart,
    selectedEnd,
    today,
    outsideRange,
    blocked,
    isWithinCurrentMonth,
  };
}

interface DayContainerProps extends RenderDayState {
  day: Date;
  renderDay: (props: RenderDayProps) => React.ReactNode;
}

function DayView(props: DayContainerProps) {
  const {
    day,
    withinSelected,
    selected,
    selectedStart,
    selectedEnd,
    today,
    outsideRange,
    blocked,
    renderDay,
    basePrice,
    dynamicPrice,
    isDiscountRate,
  } = props;

  return (
    <Fragment>
      {renderDay({
        day,
        withinSelected,
        selected,
        selectedStart,
        selectedEnd,
        today,
        outsideRange,
        blocked,
        basePrice,
        dynamicPrice,
        isDiscountRate,
      })}
    </Fragment>
  );
}

interface OtherMonthDayContainerProps {
  day: Date;
  withinSelected: boolean;
  renderOtherMonthDay: (props: RenderOtherMonthProps) => React.ReactNode;
}

function OtherMonthDayContainer(props: OtherMonthDayContainerProps) {
  const { withinSelected, day, renderOtherMonthDay } = props;

  return (
    <Fragment>
      {renderOtherMonthDay({
        day,
        withinSelected,
      })}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  weekWrapper: {
    display: "flex",
    flexDirection: "row",
  },
});

export function getWeeks(month: Date, firstDayOfWeek: DayOfWeek): Week[] {
  const dates = getDays(month, firstDayOfWeek);

  return chunk(dates, 7).map((week, index) => ({
    month,
    index,
    days: week,
  }));
}

function getDays(month: Date, firstDayOfWeek: DayOfWeek): Date[] {
  const currentDates = getMonthDatesFromDate(month);
  const startOfMonthDate = currentDates[0];
  const endOfMonthDate = endOfDay(currentDates[currentDates.length - 1]);

  const beforeDates = getDatesBefore(startOfMonthDate, firstDayOfWeek);
  const afterDates = getDatesAfter(endOfMonthDate, firstDayOfWeek);

  return beforeDates.concat(currentDates, afterDates);
}

function getMonthInterval(date: Date): DateInterval {
  return {
    start: startOfMonth(date),
    end: endOfMonth(date),
  };
}

function getMonthDatesFromDate(date: Date): Date[] {
  return eachDayOfInterval(getMonthInterval(date));
}

function getDatesBefore(startOfMonthDate: Date, firstDayOfWeek: DayOfWeek) {
  let beforeDates: Date[] = [];

  let from = startOfMonthDate;

  const firstDateOfWeek = getFirstDateOfWeek(from, firstDayOfWeek);

  if (!isSameDay(from, firstDateOfWeek)) {
    const sub = differenceInDays(from, firstDateOfWeek);
    from = subDays(from, sub);
  }

  if (isBefore(from, startOfMonthDate)) {
    beforeDates = eachDayOfInterval({
      start: from,
      end: subDays(startOfMonthDate, 1),
    });
  }

  return beforeDates;
}

function getDatesAfter(endOfMonthDate: Date, firstDayOfWeek: DayOfWeek) {
  let afterDates: Date[] = [];

  let to = endOfMonthDate;

  const lastDateOfWeek = getLastDateOfWeek(to, firstDayOfWeek);
  if (!isSameDay(to, lastDateOfWeek)) {
    const add = differenceInDays(lastDateOfWeek, to);

    to = addDays(to, add);
  }

  if (isAfter(to, endOfMonthDate)) {
    afterDates = eachDayOfInterval({
      start: addDays(endOfMonthDate, 1),
      end: to,
    });
  }

  return afterDates;
}

interface Week {
  month: Date;
  index: number;
  days: Date[];
}
