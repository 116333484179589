import { postAuthRedirectUrl } from "providers/auth_loader";
import { logger } from "lib/logger";
import { useAuthV2 } from "providers/authv2";
import React, { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";

interface ProtectedRouteProps {
  children?: React.ReactNode;
  path: string;
  exact?: boolean;
  render?: RouteProps["render"];
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const { children, path, exact, render } = props;
  const { authenticated } = useAuthV2();
  const history = useHistory();

  useEffect(() => {
    if (!authenticated) {
      postAuthRedirectUrl.set(
        history.location.pathname + history.location.search,
      );
      logger.debug("not authenticated, redirecting to '/sign-in'");
      history.replace("/sign-in");
    }
  }, [history, authenticated]);

  if (!authenticated) {
    return null;
  }

  return (
    <Route path={path} exact={exact} render={render}>
      {children}
    </Route>
  );
}
