import { colors } from "components/colors";
import { Text } from "components/text_v2";
import { StyleSheet, View } from "react-native";

interface PropsType {
  title: string;
  detail: JSX.Element;
}

export const PendingDetailCard: React.FC<PropsType> = ({ title, detail }) => {
  return (
    <View style={styles.root}>
      <Text weight="semibold" size="xs" color="black-70">
        {title}
      </Text>
      <View style={styles.detailWrapper}>{detail}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.brand.eggplantMinus50,
    padding: 16,
    height: 90,
    justifyContent: "center",
  },
  detailWrapper: {
    marginTop: 12,
  },
});
