import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { TextLink } from "components/text_link";
import { tokens } from "components/tokens";
import { OffsiteSpacePage__SpaceDetailsV2Fragment } from "core/graphql.generated";
import { useState } from "react";
import { SpaceRoomLayoutsLearnMoreDialog } from "./space_room_layouts_learn_more_dialog";

interface SpaceRoomLayoutsProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
}

export function SpaceRoomLayouts(props: SpaceRoomLayoutsProps) {
  const { space } = props;
  const [learnMoreDialogOpen, setLearnMoreDialogOpen] = useState(false);

  return (
    <Container>
      <Text>
        Your room may be customized with any of the following sitting
        arrangements.{" "}
        <TextLink
          text="Learn more"
          onPress={() => setLearnMoreDialogOpen(true)}
        />
        .
      </Text>
      <SpaceRoomLayoutsLearnMoreDialog
        open={learnMoreDialogOpen}
        onRequestClose={() => setLearnMoreDialogOpen(false)}
        space={space}
      />
      <Spacer size={16} />
      <div className="row space-details-layout-wrapper">
        {space.layouts.map((layout) => (
          <div
            key={layout.id}
            style={{ borderColor: tokens.colors.neutral.dark }}
            className="space-details-layout-item"
          >
            <img
              className="space-details-layout-item-img"
              src={layout.imageURL}
              alt={layout.name}
            />
            <Spacer size={8} />
            <div className="space-details-layout-text">
              <Row>
                <Text weight="500" size="sm">
                  {layout.name}
                </Text>
              </Row>
              <Row>
                <Text size="xs">
                  Up to {layout.capacity}{" "}
                  {layout.capacity > 1 ? "people" : "person"}
                </Text>
              </Row>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
