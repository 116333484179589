import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { SelectInputOptionType } from "components/select/select_input";
import { Text } from "components/text_v2";
import { Pressable, StyleSheet, View } from "react-native";

interface SelectOptionProps {
  option: SelectInputOptionType;
  selected?: boolean;
  testID?: string;
  onSelect?: (option: SelectInputOptionType) => void;
}

const noop = () => {};

export function SelectOption(props: SelectOptionProps) {
  const { testID, option, selected, onSelect = noop } = props;

  return (
    <Pressable testID={testID} onPress={() => onSelect(option)}>
      <View style={[styles.option, selected && styles.optionSelected]}>
        <View style={styles.label}>
          <Text size="sm">{option.label}</Text>
          {selected && <Icon name="check-ui" />}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  option: {
    padding: 16,
  },
  optionSelected: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
  checkbox: {
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
  },
});
