import { Pressable, StyleSheet, View } from "react-native";
import { Button } from "components/button_v2";
import { Spacer } from "components/spacer";
import { tokens } from "components/tokens";
import { Icon } from "components/icon";
import { Divider } from "components/divider";
import { DialogFooterWrapper } from "core/dialog_content";
import React from "react";
import { Text } from "components/text_v2";
import { colors } from "components/colors";

interface HomeFilterDesktopPropTypes {
  onClose: () => void;
  handleResetFilter: () => void;
  handleSave: () => void;
  children: React.ReactNode;
}

export function FilterDesktop(props: HomeFilterDesktopPropTypes) {
  const { onClose, handleResetFilter, handleSave, children } = props;

  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <Pressable
          style={[
            {
              position: "absolute",
              zIndex: 1,
              right: 20,
              top: 28,
            },
          ]}
          onPress={onClose}
        >
          <Icon name="x-circle" />
        </Pressable>
        <Text weight="bold" align="center">
          Filters
        </Text>
      </View>
      <Divider color="darker" />
      <View style={styles.childrenContainer}>{children}</View>
      <Divider color="darker" />
      <DialogFooterWrapper>
        <View style={styles.buttonsWrapper}>
          <Pressable onPress={handleResetFilter}>
            <Text color="eggplant-core" size="xs" weight="semibold">
              Clear all
            </Text>
          </Pressable>
          <Spacer size={16} />
          <View style={styles.buttonContainer}>
            <Button
              testID="save-filter"
              onPress={handleSave}
              text="See results"
            />
          </View>
        </View>
      </DialogFooterWrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    overflow: "hidden",
    width: 571,
    backgroundColor: colors.brand.backgroundMain,
  },
  header: {
    borderTopLeftRadius: tokens.radius,
    borderTopRightRadius: tokens.radius,
    backgroundColor: colors.brand.backgroundMain,
    height: 75,
    paddingTop: 28,
    paddingBottom: 4,
  },
  childrenContainer: {
    flex: 1,
  },
  buttonsWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 16,
  },
  buttonContainer: {
    flex: 1,
    maxWidth: 215,
  },
});
