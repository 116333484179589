import { gql, useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";
import { LinkWrapper } from "components/link_wrapper";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Amenity, GetLocationAmenitiesQuery } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { AmenitiesSekeleton } from "pages/homev3/components/amenities_skeleton";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useAnalytics } from "providers/analytics";
import React from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { useUserType } from "../hooks/use_user_type";

export function Amenities() {
  const { data, loading, error } =
    useQuery<GetLocationAmenitiesQuery>(getLocationAmenities);

  return (
    <AsyncRenderer
      error={error}
      loading={loading}
      data={data}
      loadingHandler={() => <AmenitiesSekeleton />}
    >
      {({ amenities }) => (
        <View>
          <TitleSpaceList
            title={"The amenities you need"}
            subtitle={"Beyond the essentials"}
          />
          <Spacer size={24} />
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <Content amenities={amenities} />
          </ScrollView>
        </View>
      )}
    </AsyncRenderer>
  );
}

type ContentProps = {
  amenities: Amenity[];
};
function Content({ amenities }: ContentProps) {
  const mq = useMediaQuery();
  const userType = useUserType();
  const analytics = useAnalytics();
  const isMobile = mq.deviceQuery.mobile;

  if (!amenities || amenities.length === 0) {
    return null;
  }

  const listAmenities = [
    "phone_booths",
    "barista",
    "mother_room",
    "pet_friendly",
    "rooftop",
    "free_parking",
    "sparkling_water",
  ];

  const amenityBoxWidthAndHeight = isMobile ? 48 : 60;
  return (
    <View style={isMobile ? styles.containerMobile : styles.container}>
      {listAmenities.map((amenity_code, index) => (
        <LinkWrapper
          to={`/search?amenities=${amenity_code}`}
          onPress={() => {
            analytics.event("Click Amenity Search", {
              "Amenity UUID": amenity_code,
              Source: userType,
              category: "Rebooking",
              Amenity: amenities.find((a) => a.code === amenity_code)?.name,
            });
          }}
          key={index}
          style={{ flex: 1, width: "100%" }}
        >
          <View style={isMobile ? styles.amenityMobile : styles.amenity}>
            <Image
              source={{
                uri: `/images/${amenity_code}.svg`,
                width: amenityBoxWidthAndHeight,
                height: amenityBoxWidthAndHeight,
              }}
              width={amenityBoxWidthAndHeight}
              height={amenityBoxWidthAndHeight}
            />
            <Text
              size={isMobile ? "micro" : "xs"}
              weight={"semibold"}
              align={"center"}
            >
              {amenities.find((a) => a.code === amenity_code)?.name}
            </Text>
          </View>
        </LinkWrapper>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    marginBottom: 8,
    gap: 8,
  },
  containerMobile: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 8,
    gap: 20,
  },
  amenity: {
    flexDirection: "column",
    // flex: 1,
    width: 140,
    alignItems: "center",
  },
  amenityMobile: {
    flexDirection: "column",
    alignItems: "center",
    width: 87,
  },
});
const getLocationAmenities = gql`
  query GetLocationAmenities {
    amenities {
      id
      iconURL
      name
      code
    }
  }
`;
