import { useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "components/text_v2";
import { DropdownMenu } from "components/dropdown_menu/dropdown_menu";
import { colors } from "components/colors";
import { Cell } from "./components_v2/cell";
import { Row } from "./components_v2/row";
import { DataTableRow, Column } from "./data_table_base";

interface DataTableRawPropTypes {
  columns: Column[];
  rows: DataTableRow[];
}

export const DataTableRaw = (props: DataTableRawPropTypes) => {
  const { rows, columns } = props;

  const renderTableHeader = useCallback(
    () => (
      <Row isHeader>
        {columns.map((column) => (
          <Cell key={`th-${column.id}`} isHeader width={column.width}>
            {column.renderTitle ? (
              column.renderTitle(column)
            ) : (
              <Text
                customColor={colors.brand.blackMinus30}
                size="xs"
                weight="semibold"
              >
                {column.title}
              </Text>
            )}
          </Cell>
        ))}
      </Row>
    ),
    [columns],
  );

  const renderTableRows = useCallback(
    () =>
      rows.map((row: DataTableRow) => (
        <Row key={row.id}>
          {columns.map((column) => {
            const rowItem = row[column.id];

            return (
              <Cell width={column.width} key={`${row.id}-${column.id}`}>
                {rowItem.render ? (
                  rowItem.render(row)
                ) : (
                  <Text customColor={colors.brand.blackcore} size="xs">
                    {rowItem}
                  </Text>
                )}
              </Cell>
            );
          })}
          {row.contextMenu && (
            <Cell width="fit-content">
              <DropdownMenu
                items={row.contextMenu || []}
                disabled={!row.contextMenu?.filter(Boolean).length}
                sameMinWidth
                sameWidth={false}
                placement="bottom-end"
                testID="data-table-context-menu"
              />
            </Cell>
          )}
        </Row>
      )),
    [rows, columns],
  );

  return (
    <View style={styles.tableWrapper}>
      {renderTableHeader()}
      {renderTableRows()}
    </View>
  );
};

const styles = StyleSheet.create({
  tableWrapper: {
    backgroundColor: "white",
    paddingVertical: 24,
    borderRadius: 4,
  },
});
