import { ApolloError, gql, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { DateRange } from "core/booking_date_range_picker";
import { SearchValue } from "core/booking_utils";
import {
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  QuoteDetailsQuery,
  UserBookingRequestsQuery,
} from "core/graphql.generated";
import { OrderBreakdown } from "core/order_breakdown";

import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";
import { StyleSheet, View } from "react-native";
import { useAuthV2 } from "../../../providers/authv2";
import { validateDateRange } from "../utils/validate_date_range";

import { OutOfPolicyDetails } from "./out_of_policy_details";

interface PropsType {
  quoteError?: ApolloError;
  quoteQuery?: QuoteDetailsQuery;
  dateRange?: DateRange;
  quoteLoading: boolean;
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  searchValue: SearchValue;
  savePriceText: string;
  onReserve: () => void;
  handleOpenBookingDateRangePicker: () => void;
}

export function SpaceQuoteDetails({
  quoteError,
  quoteQuery,
  quoteLoading,
  space,
  dateRange,
  searchValue,
  savePriceText,
  onReserve,
  handleOpenBookingDateRangePicker,
}: PropsType) {
  const quoteErrorText = extractFirstGraphQLErrorMessage(quoteError);

  const { authenticated } = useAuthV2();
  const {
    data: bookingRequestsData,
    loading: bookingRequestsLoading,
    refetch: refetchBookingRequests,
  } = useQuery<UserBookingRequestsQuery>(userBookingRequestsQuery, {
    variables: { spaceId: space.id },
    skip: !authenticated,
  });

  if (quoteErrorText) {
    return (
      <View style={styles.greyCard}>
        <Text size="xs">{quoteErrorText}</Text>
      </View>
    );
  }

  if (
    quoteQuery?.quote.quoteId &&
    quoteQuery.quote.outOfPolicyMessage &&
    dateRange &&
    !!bookingRequestsData
  ) {
    return (
      <OutOfPolicyDetails
        dateRange={dateRange}
        space={space}
        quoteId={quoteQuery?.quote.quoteId}
        bookingAmount={quoteQuery.quote.totalPrice}
        outOfPolicyMessagge={quoteQuery.quote.outOfPolicyMessage}
        bookingRequestsQuery={bookingRequestsData}
        refetchBookingRequests={refetchBookingRequests}
      />
    );
  }

  const disabled = !!quoteError || !!quoteQuery?.quote.outOfPolicyMessage;
  return (
    <>
      {space.locked && !dateRange && (
        <View style={styles.outOfPolicyCard}>
          <View style={styles.cardTitle}>
            <Icon name="guard" />
            <Text weight="semibold" size="xs">
              Out-of-policy
            </Text>
          </View>
          <Text size="xs">You must submit a request to book this space</Text>
        </View>
      )}
      <Button
        disabled={disabled}
        loading={
          quoteQuery?.quote.outOfPolicyMessage
            ? quoteLoading || bookingRequestsLoading
            : quoteLoading
        }
        testID="sticky-panel-reserve-button"
        onPress={
          dateRange && !validateDateRange(dateRange)
            ? onReserve
            : handleOpenBookingDateRangePicker
        }
        text={
          dateRange && !validateDateRange(dateRange)
            ? "Reserve"
            : "Check availability"
        }
      />
      {searchValue.dateRange && (
        <>
          <Spacer size={24} />
          {quoteQuery && (
            <OrderBreakdown
              currency={space.currency}
              quote={quoteQuery.quote}
              quoteError={quoteError}
              savePriceText={savePriceText}
              showTextWontChargedYet={true}
            />
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  greyCard: {
    borderRadius: 4,
    padding: 12,
    backgroundColor: colors.brand.blackMinus90,
  },
  cardTitle: {
    flexDirection: "row",
    alignItems: "center",
    gap: 9,
    marginBottom: 9,
  },
  outOfPolicyCard: {
    padding: 12,
    borderRadius: 4,
    backgroundColor: colors.brand.bananaMinus70,
    marginBottom: 16,
  },
});

export const userBookingRequestsQuery = gql`
  query UserBookingRequests($spaceId: ID!) {
    space(id: $spaceId) {
      id
      bookingRequests {
        id
        startDate
        endDate
        startTime
        endTime
        reason
        status
      }
    }
  }
`;
