import { useMediaQuery } from "lib/media_query";
import { appConfig } from "providers/config";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MapRef, MarkerProps } from "react-map-gl";
import { Pressable, StyleSheet, View } from "react-native";
import ReactDOM from "react-dom";
import { colors } from "./colors";
import { Icon } from "./icon";
import { Icon as IconV2 } from "./iconv2";
import { MapProps } from "./map";
import { tokens } from "./tokens";

const ReactMapGL = React.lazy(() => import("react-map-gl"));
const Marker = React.lazy(() =>
  import("react-map-gl").then((module) => ({ default: module.Marker })),
) as React.ComponentType<MarkerProps>;

const DEFAULT_VIEWPORT_ZOOM = 15.0;

export function Map(props: MapProps) {
  const { height, latitude, longitude, fullScreenControl, onExpand } = props;
  const [fullScreen, setFullScreen] = useState(false);
  const mq = useMediaQuery();
  const ref = useRef<MapRef | null>(null);
  const mapContainerRef = useRef<View | null>(null);

  const handleFullScreen = useCallback(() => {
    if (fullScreen) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
      onExpand && onExpand();
    }
  }, [fullScreen, onExpand]);

  useEffect(() => {
    ref.current?.resize();
  }, [fullScreen]);

  const mapComp = useMemo(
    () => (
      <View
        ref={mapContainerRef}
        style={[
          { width: "100%", height: height },
          fullScreen && styles.fullScreenStyles,
        ]}
      >
        <ReactMapGL
          ref={ref}
          style={{
            height: "100%",
            width: "100%",
          }}
          initialViewState={{
            latitude: latitude || 37.7915,
            longitude: longitude || -122.4121,
            zoom: DEFAULT_VIEWPORT_ZOOM,
          }}
          mapStyle="mapbox://styles/flexspacedevops/cl56favx600bb14lljo2ft4fu"
          mapboxAccessToken={appConfig.mapboxApiAccessToken}
        >
          <Marker
            latitude={latitude || 37.7915}
            longitude={longitude || -122.4121}
          >
            <Icon
              size="lg"
              name="map-pin"
              color={tokens.colors.primary.light}
            />
          </Marker>
          {fullScreenControl && (
            <Pressable
              style={[
                styles.iconContainer,
                fullScreen &&
                  mq.deviceQuery.mobile &&
                  styles.iconPlaceFullScreenMobile,
              ]}
              onPress={handleFullScreen}
            >
              <IconV2 name={fullScreen ? "exit-full-screen" : "full-screen"} />
            </Pressable>
          )}
        </ReactMapGL>
      </View>
    ),
    [
      fullScreen,
      fullScreenControl,
      handleFullScreen,
      height,
      latitude,
      longitude,
      mq.deviceQuery.mobile,
    ],
  );

  if (fullScreen) {
    return ReactDOM.createPortal(mapComp, document.getElementById("root")!);
  }

  return mapComp;
}

const styles = StyleSheet.create({
  iconContainer: {
    width: 40,
    height: 40,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "#06001E",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center",
    top: 12,
    right: 12,
    position: "absolute",
    borderRadius: 4,
  },

  iconPlaceFullScreenMobile: {
    top: 36,
    right: 24,
  },

  fullScreenStyles: {
    // @ts-ignore
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "visible",
    zIndex: 10,
  },
});
