import { NativeModules, Platform } from "react-native";
import { canUseDOM } from "./execution_environment";

export function getSystemLocale(): string {
  if (Platform.OS === "ios") {
    return getSystemLocaleIOS();
  } else if (Platform.OS === "android" && NativeModules.I18nManager) {
    return getSystemLocaleAndroid();
  } else if (Platform.OS === "web" && canUseDOM) {
    return getSystemLocaleWeb();
  }

  return "en_US";
}

interface NativeModulesIOS {
  SettingsManager?: {
    settings?: {
      AppleLanguages?: string[];
    };
  };
}

function getSystemLocaleIOS() {
  const nativeModules = NativeModules as NativeModulesIOS;

  if (nativeModules.SettingsManager?.settings?.AppleLanguages) {
    const systemLocale = nativeModules.SettingsManager.settings.AppleLanguages;

    return systemLocale[0];
  }

  return "en_US";
}

interface NativeModulesAndroid {
  I18nManager?: {
    localeIdentifier?: string;
  };
}

function getSystemLocaleAndroid() {
  const nativeModules = NativeModules as NativeModulesAndroid;

  if (nativeModules.I18nManager?.localeIdentifier) {
    const systemLocale = nativeModules.I18nManager?.localeIdentifier;

    return systemLocale;
  }

  return "en_US";
}

function getSystemLocaleWeb() {
  if (window.navigator.language) {
    return window.navigator.language;
  } else if (window.navigator.languages) {
    return window.navigator.languages[0];
  }

  return "en_US";
}
