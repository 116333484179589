import { Button } from "components/button_v2";
import { useMediaQuery } from "lib/media_query";
import React, { useCallback, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Spacer } from "components/spacer";
import { TabOption } from "components/tab/tabs";
import { useQueryString } from "lib/query_string";
import { GroupBudgetDetailSpendAndReports } from "pages/budgets/group_budget/group_budget_detail_spend_and_reports";
import {
  GroupBudgetStatus,
  UserRole,
  UserRoleAndOrgInfoQuery,
  UserRoleAndOrgInfoQueryVariables,
} from "core/graphql.generated";
import { Tabs } from "components/tab/tabs_v2";
import {
  BudgetDetailHeaderDesktop,
  BudgetDetailHeaderMobile,
} from "../components/budget_detail_header_v1";
import { GroupBudgetDetailPeople } from "./group_budget_detail_people";
import { GroupBudgetDetailTransactions } from "./group_budget_detail_transactions";
import { BudgetMobileLayout } from "../budget_layout/buget_mobile_layout";
import { BudgetDesktopLayout } from "../budget_layout/buget_desktop_layout";
import { GroupBudgetDetailTabReplacerSkeleton } from "./components/group_budget_detail_tab_replacer_skeleton";

export type GroupBudgetDetailTab = "spendAndReports" | "activity" | "people";

export const groupBudgetDetailTabNames: Record<
  GroupBudgetDetailTab,
  GroupBudgetDetailTab
> = {
  spendAndReports: "spendAndReports",
  activity: "activity",
  people: "people",
};

export const groupBudgetDetailTabOptions: TabOption<GroupBudgetDetailTab>[] = [
  {
    label: "Spend and reports",
    value: groupBudgetDetailTabNames.spendAndReports,
    testID: groupBudgetDetailTabNames.spendAndReports,
  },
  {
    label: "People",
    value: groupBudgetDetailTabNames.people,
    testID: groupBudgetDetailTabNames.people,
  },
  {
    label: "Transactions",
    value: groupBudgetDetailTabNames.activity,
    testID: groupBudgetDetailTabNames.activity,
  },
];

const groupBudgetDetailTabInactiveOptions: TabOption<GroupBudgetDetailTab>[] = [
  {
    label: "Spend and reports",
    value: groupBudgetDetailTabNames.spendAndReports,
    testID: groupBudgetDetailTabNames.spendAndReports,
  },
  {
    label: "Transactions",
    value: groupBudgetDetailTabNames.activity,
    testID: groupBudgetDetailTabNames.activity,
  },
];

interface GroupBudgetDetailSearchParams {
  selectedTab: GroupBudgetDetailTab;
  start: string;
  end: string;
  page?: string;
  backButtonText?: string;
}

interface GroupBudgetDetailParams {
  groupBudgetId: string;
}

type GroupBudgetDetailContentProps = {
  currentTab: GroupBudgetDetailTab;
  groupBudgetId: string;
};
const GroupBudgetDetailContent = ({
  currentTab,
  groupBudgetId,
}: GroupBudgetDetailContentProps) => (
  <>
    <Spacer size={24} />

    {currentTab === groupBudgetDetailTabNames.spendAndReports && (
      <GroupBudgetDetailSpendAndReports />
    )}
    {currentTab === groupBudgetDetailTabNames.people && (
      <GroupBudgetDetailPeople groupId={groupBudgetId} />
    )}
    {currentTab === groupBudgetDetailTabNames.activity && (
      <GroupBudgetDetailTransactions />
    )}
  </>
);

export function GroupBudgetDetail() {
  const history = useHistory();
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const param = useParams<GroupBudgetDetailParams>();
  const {
    selectedTab = groupBudgetDetailTabNames.spendAndReports,
    start,
    end,
    page,
    backButtonText,
  } = useQueryString<GroupBudgetDetailSearchParams>();

  const handleTabChange = useCallback(
    (value: GroupBudgetDetailTab) => {
      const newSearchParams = new URLSearchParams();
      newSearchParams.set("selectedTab", value);
      if (start) {
        newSearchParams.set("start", start);
      }
      if (end) {
        newSearchParams.set("end", end);
      }
      if (backButtonText) {
        newSearchParams.set("backButtonText", backButtonText);
      }
      history.replace({ search: newSearchParams.toString() });
    },
    [end, history, start, backButtonText],
  );

  const { data, loading } = useQuery<
    UserRoleAndOrgInfoQuery,
    UserRoleAndOrgInfoQueryVariables
  >(userRoleAndOrgInfoQuery, {
    variables: {
      id: param.groupBudgetId,
    },
  });

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    if (start) {
      newSearchParams.set("start", start);
    }
    if (end) {
      newSearchParams.set("end", end);
    }

    if (page) {
      newSearchParams.set("page", page);
    }

    if (backButtonText) {
      newSearchParams.set("backButtonText", backButtonText);
    }

    if (
      data?.groupBudget?.status === GroupBudgetStatus.Inactive &&
      selectedTab === groupBudgetDetailTabNames.people
    ) {
      newSearchParams.set(
        "selectedTab",
        groupBudgetDetailTabNames.spendAndReports,
      );
    } else {
      newSearchParams.set("selectedTab", selectedTab);
    }

    history.replace({ search: newSearchParams.toString() });
  }, [
    data?.groupBudget?.status,
    end,
    history,
    page,
    selectedTab,
    start,
    backButtonText,
  ]);

  const onEditPress = useCallback(() => {
    history.push(`/admin/budgets/${param.groupBudgetId}/edit`);
  }, [history, param]);

  if (isMobile) {
    return (
      <BudgetMobileLayout
        header={
          <BudgetDetailHeaderMobile
            title={data?.groupBudget?.name}
            hideBackButton={data?.currentUser?.role !== UserRole.Admin}
            actions={
              <Button
                onPress={onEditPress}
                iconName={"pencil"}
                text={"Edit"}
                testID="edit-group-budget-button"
                appearance={"textLink"}
              />
            }
          />
        }
        body={
          <>
            {loading ? (
              <GroupBudgetDetailTabReplacerSkeleton />
            ) : (
              <Tabs
                onChange={handleTabChange}
                options={
                  data?.groupBudget?.status === GroupBudgetStatus.Inactive
                    ? groupBudgetDetailTabInactiveOptions
                    : groupBudgetDetailTabOptions
                }
                value={selectedTab}
              />
            )}
            <GroupBudgetDetailContent
              currentTab={selectedTab}
              groupBudgetId={param.groupBudgetId}
            />
          </>
        }
      />
    );
  }

  return (
    <BudgetDesktopLayout
      header={
        <BudgetDetailHeaderDesktop
          title={data?.groupBudget?.name}
          secondaryHeadingText={data?.groupBudget?.description}
          backButtonText={backButtonText || "See all budgets"}
          hideBackButton={data?.currentUser?.role !== UserRole.Admin}
          status={data?.groupBudget?.status}
          actions={
            <Button
              onPress={onEditPress}
              iconName={"pencil"}
              text={"Edit"}
              testID="edit-group-budget-button"
              appearance={"primary"}
            />
          }
        >
          {loading ? (
            <GroupBudgetDetailTabReplacerSkeleton />
          ) : (
            <Tabs
              onChange={handleTabChange}
              options={
                data?.groupBudget?.status === GroupBudgetStatus.Inactive
                  ? groupBudgetDetailTabInactiveOptions
                  : groupBudgetDetailTabOptions
              }
              value={selectedTab}
            />
          )}
        </BudgetDetailHeaderDesktop>
      }
      body={
        <GroupBudgetDetailContent
          currentTab={selectedTab}
          groupBudgetId={param.groupBudgetId}
        />
      }
    />
  );
}

const userRoleAndOrgInfoQuery = gql`
  query UserRoleAndOrgInfo($id: ID!) {
    currentUser {
      id
      role
      organization {
        id
        budget {
          id
          limit
        }
        groupBudgets {
          id
          name
        }
      }
    }
    groupBudget(id: $id) {
      id
      status
      name
      description
      color
      spend
      remaining
      limit
      individualLimit
      members {
        role
        user {
          id
          userId
          role
          fullName
          email
          picture
          title
          groupBudgetRole
        }
      }
    }
  }
`;
