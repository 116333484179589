import { Text } from "components/text_v2";

import { SpaceDetail } from "./space_detail";

interface SpaceDetailLocationProps {
  name: string;
  address: string;
}

export function SpaceDetailLocation({
  name,
  address,
}: SpaceDetailLocationProps) {
  return (
    <SpaceDetail title="Location">
      <Text size="xs">
        {name} is located at {address}
      </Text>
    </SpaceDetail>
  );
}
