import "./dropdown.css";
import React, { useEffect, useRef } from "react";

interface DropdownProps {
  open: boolean;
  content: React.ReactNode;
  children: React.ReactNode;
  onRequestClose: () => void;
}

export function Dropdown(props: DropdownProps) {
  const { content, open, onRequestClose, children } = props;

  const ref = useOuterClick(onRequestClose);

  return (
    <div ref={ref} className="dropdown-root">
      {children}
      {open && <div className="dropdown-content-wrapper">{content}</div>}
    </div>
  );
}

function useOuterClick(callback: (e: MouseEvent) => void) {
  const callbackRef = useRef<(e: MouseEvent) => void>(); // initialize mutable ref, which stores callback
  const innerRef = useRef<HTMLDivElement>(null); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e: globalThis.MouseEvent) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target as Node)
      ) {
        callbackRef.current(e);
      }
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
