import { Button } from "components/button_v2";
import { Checkbox } from "components/checkbox";
import { DialogModal } from "components/dialog_modal";
import { SelectInput } from "components/select/select_input";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { BudgetPolicyLocationItemDistanceType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { PolicyLocationItem } from "pages/budgets/policy/policy_mutation_form";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

type AddLocationCardProps = {
  locationItem: PolicyLocationItem;
  onRemove: (locationItem: PolicyLocationItem) => void;
  onUpdate: (locationItem: PolicyLocationItem) => void;
};
export const LocationAddressCard = ({
  locationItem,
  onRemove,
  onUpdate,
}: AddLocationCardProps) => {
  const [removeAddressVisible, setRemoveAddressVisible] = useState(false);

  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const onRemovePressed = () => {
    setRemoveAddressVisible(false);
    onRemove(locationItem);
  };
  return (
    <View>
      <Checkbox
        label={`${locationItem.street}:`}
        value={locationItem.active}
        onChange={(value) => onUpdate({ ...locationItem, active: value })}
        appearance="box"
        renderLabel={() => (
          <Text
            size="xs"
            color={locationItem.active ? "black-core" : "black-70"}
          >
            {`${locationItem.street}:`}
          </Text>
        )}
      />
      <View style={styles.container}>
        <View style={isMobile ? styles.updateViewMobile : styles.updateView}>
          <Text size="xs" color={locationItem.active ? "black-70" : "black-50"}>
            Employees cannot book spaces within
          </Text>

          <View style={styles.buttonView}>
            <View style={styles.distanceInputView}>
              <TextField
                value={locationItem.distance + ""}
                onChange={(value) =>
                  onUpdate({ ...locationItem, distance: Number(value) })
                }
                keyboardType="numeric"
                disabled={!locationItem.active}
                testID="policy-proximity-distance-input"
              />
            </View>
            <SelectInput
              options={[
                {
                  value: BudgetPolicyLocationItemDistanceType.Mi,
                  label: "mi.",
                },
                {
                  value: BudgetPolicyLocationItemDistanceType.Km,
                  label: "km.",
                },
              ]}
              value={locationItem.distanceUnit}
              onChange={(value) =>
                onUpdate({
                  ...locationItem,
                  distanceUnit: value as BudgetPolicyLocationItemDistanceType,
                })
              }
              disabled={!locationItem.active}
              testID="policy-proximity-distance-type-select"
            />
            <Text
              size="xs"
              color={locationItem.active ? "black-70" : "black-50"}
            >
              of this office.
            </Text>
          </View>
        </View>

        <Text
          size="xs"
          color={"eggplant-core"}
          weight={"semibold"}
          onPress={() => setRemoveAddressVisible(true)}
          testID="policy-proximity-remove-button"
        >
          Remove
        </Text>
      </View>
      <DialogModal
        isVisible={removeAddressVisible}
        headerTitle={"Are you sure you want to remove this address?"}
        onClose={() => setRemoveAddressVisible(false)}
        desktopWidth={540}
        mobileOffsetBottom={320}
        showCloseButton={false}
      >
        <View style={styles.confirmContainer}>
          <View style={styles.confirmTextMobile}>
            <Text size="xs" align={"center"}>
              By removing this address, you will remove it as a location
              restriction for all other policies.
            </Text>
          </View>

          <View
            style={
              isMobile
                ? styles.buttonConfirmViewMobile
                : styles.buttonConfirmView
            }
          >
            <Button
              appearance={"secondary"}
              text={"Cancel"}
              size={isMobile ? "large" : "medium"}
              onPress={() => setRemoveAddressVisible(false)}
            />
            <Button
              size={isMobile ? "large" : "medium"}
              text={"Yes, remove address"}
              onPress={onRemovePressed}
            />
          </View>
        </View>
      </DialogModal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingLeft: 40,
    marginBottom: 4,
  },
  updateView: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  updateViewMobile: {
    flexDirection: "column",
    gap: 8,
  },
  distanceInputView: {
    width: 80,
  },
  confirmContainer: {
    flex: 1,
    gap: 24,
  },
  confirmTextMobile: {
    paddingHorizontal: 8,
  },
  buttonConfirmView: {
    flexDirection: "row",
    alignItems: "center",
    gap: 24,
  },
  buttonConfirmViewMobile: {
    flexDirection: "column-reverse",
    alignItems: "center",
    gap: 12,
  },
  buttonView: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});
