import common from "email-providers/common.json";
import { getDomainFromEmail } from "lib/string_utils";

export function isCommonEmailProvider(email?: string | null) {
  if (!email) {
    return false;
  }
  const domain = getDomainFromEmail(email);

  return common.includes(domain);
}
