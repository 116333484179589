import { gql, useQuery } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Content } from "components/content";
import Dynamic_height_container from "components/dynamic_height_container";
import { Heading } from "components/heading";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { Text as TextV2 } from "components/text_v2";
import {
  BOTTOM_BAR_HEIGHT,
  BottomBarNavigation,
} from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import {
  GroupBudgetRole,
  Plan,
  UserProfileQuery,
  UserRole,
} from "core/graphql.generated";
import { ResponsiveGrid } from "core/responsive_grid";
import { useMediaQuery } from "lib/media_query";

import { useAuthV2 } from "providers/authv2";
import { useFavoritesFeatureFlag } from "providers/splitio";
import { Fragment, ReactNode } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory } from "react-router";
import { MenuCardList as MenuCardListMobile } from "./components/menu_card_list";

export function UserProfilePage() {
  const mq = useMediaQuery();
  const { data } = useQuery<UserProfileQuery>(userProfileQuery);
  const { logout } = useAuthV2();
  const history = useHistory();
  const isAdmin = data?.currentUser?.role === UserRole.Admin;
  const isPro = data?.currentUser?.organization?.plan === Plan.Pro;
  const isBudgetManager =
    data?.currentUser?.groupBudgetRole === GroupBudgetRole.Manager;

  return (
    <Dynamic_height_container>
      <AppHeader />
      <Spacer size={16} />
      <View
        style={[
          pageStyles.pageContainer,
          {
            paddingBottom: BOTTOM_BAR_HEIGHT + 8,
          },
        ]}
      >
        <View>
          <Content>
            <Heading size="h1">Profile</Heading>
            <Spacer size={32} />
            {mq.deviceQuery.mobile ? <MenuCardListMobile /> : <MenuCardList />}
          </Content>
        </View>

        <View style={pageStyles.menuButtons}>
          {mq.deviceQuery.mobile && (
            <View>
              {isAdmin && (
                <Fragment>
                  <Button
                    testID="profile-manager-admin-button"
                    onPress={() =>
                      history.push(
                        isPro ? "/admin/budgets" : "/admin/analytics",
                      )
                    }
                    appearance="secondary"
                    text="Manage organization"
                  />
                  <Spacer size={8} />
                </Fragment>
              )}
              {isBudgetManager && !isAdmin && (
                <Fragment>
                  <Button
                    testID="profile-manager-admin-button"
                    onPress={() =>
                      history.push(
                        `/admin/budgets/${data?.currentUser?.groupBudget?.id}`,
                      )
                    }
                    appearance="secondary"
                    text="Manage budget"
                  />
                  <Spacer size={8} />
                </Fragment>
              )}

              <Button text="Log out" onPress={logout} />
            </View>
          )}
        </View>
      </View>
      <Footer />
      <BottomBarNavigation />
    </Dynamic_height_container>
  );
}

function MenuCardList() {
  const { sizeQuery } = useMediaQuery();
  const favoritesFeatureFlag = useFavoritesFeatureFlag();
  const { logout: logoutV2 } = useAuthV2();

  return (
    <Fragment>
      <ResponsiveGrid
        horizontalSpacing={12}
        verticalSpacing={24}
        itemsPerRow={sizeQuery.mdAndUp ? 3 : 1}
      >
        <MenuCard
          title="Personal information"
          icon={<Icon name="user-list" size="lg" />}
          to="/user-profile/details"
        />
        {favoritesFeatureFlag && (
          <MenuCard
            title="Saved places"
            icon={<Icon name="favorite" size="lg" />}
            to="/saved-places"
          />
        )}
        <MenuCard
          title="Payments"
          icon={<Icon name="payment" size="lg" />}
          to="/user-profile/payments"
        />

        <MenuCard
          title="Booking policy"
          icon={<Icon name="guard" size="lg" />}
          to="/user-profile/booking-policy"
        />
      </ResponsiveGrid>
      <Spacer size={16} />
      <Pressable onPress={logoutV2}>
        <TextV2 weight="semibold" color={"eggplant-core"} size={"xs"}>
          Log out
        </TextV2>
      </Pressable>
    </Fragment>
  );
}

interface MenuCardProps {
  icon: ReactNode;
  title: string;
  to: string;
}

function MenuCard(props: MenuCardProps) {
  const { icon, title, to } = props;
  const history = useHistory();

  return (
    <Pressable onPress={() => history.push(to)} style={styles.card}>
      {icon}
      <Spacer size={24} />
      <TextV2 weight="semibold" size="md">
        {title}
      </TextV2>
    </Pressable>
  );
}

const styles = {
  card: {
    paddingVertical: 24,
    paddingLeft: 24,
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    elevation: 3,
    shadowColor: "rgba(6, 0, 30, 0.10)",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 10,
  },
};

const pageStyles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    overflowY: "scroll",
    justifyContent: "space-between",
  },
  menuButtons: {
    paddingHorizontal: 20,
  },
});

const userProfileQuery = gql`
  query UserProfile {
    currentUser {
      id
      role
      groupBudgetRole
      groupBudget {
        id
        name
      }
      organization {
        id
        paymentMode
        plan
      }
    }
  }
`;
