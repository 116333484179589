import { colors } from "components/colors";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  parentView: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    overflow: "hidden",
    borderRadius: 1000,
  },
  bar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "stretch",
    backgroundColor: colors.brand.blackMinus90,
    borderRadius: 1000,
    height: 40,
  },
  onStartProgressStyle: {
    borderTopLeftRadius: 1000,
    borderBottomLeftRadius: 1000,
  },
  onEndProgressStyle: {
    borderTopRightRadius: 1000,
    borderBottomRightRadius: 1000,
  },
});
