import "./help.css";

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Content } from "components/content";
import { Option, Picker } from "components/picker";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { TextInput } from "components/text_input";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import {
  SendSupportTicketMutation,
  SendSupportTicketMutationVariables,
} from "core/graphql.generated";
import { FormErrors, useForm } from "hooks/use_form";
import { isEmpty } from "lib/lang_utils";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { PageContainer } from "components/page_container";
import { useQueryString } from "lib/query_string";
import { Heading } from "components/heading_v2";
import { gql, useMutation } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";

interface HelpPageQueryString {
  value?: string;
}

export function HelpPage() {
  const [requireReply, setRequireReply] = useState<boolean | null>(null);

  const analytics = useAnalytics();
  const mq = useMediaQuery();
  const handleComplete = useCallback(
    (rr: boolean) => {
      setRequireReply(rr);
      analytics.event("Submit Ticket", {
        category: "Help",
        label: "Submit Ticket",
      });
    },
    [analytics],
  );

  const handleSubmitAnotherInquiry = useCallback(() => {
    setRequireReply(null);
  }, []);

  useEffect(() => {
    analytics.event("Help Page Viewed", {
      category: "Help",
      label: "Submit Ticket",
    });
  }, [analytics]);

  return (
    <Container>
      {mq.sizeQuery.mdAndUp ? (
        <HelpPageDesktop
          onComplete={handleComplete}
          requireReply={requireReply}
          onSubmitAnotherInquiry={handleSubmitAnotherInquiry}
        />
      ) : (
        <HelpPageMobile
          onComplete={handleComplete}
          requireReply={requireReply}
          onSubmitAnotherInquiry={handleSubmitAnotherInquiry}
        />
      )}
    </Container>
  );
}

interface HelpPageProps {
  requireReply: boolean | null;
  onSubmitAnotherInquiry: () => void;
  onComplete: (requireReply: boolean) => void;
  selectedTopic?: string;
}

function HelpPageMobile(props: HelpPageProps) {
  const { requireReply, onSubmitAnotherInquiry, onComplete } = props;

  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Content>
          {requireReply !== null ? (
            <MobileScreenFiller>
              <ThankYou
                requireReply={requireReply}
                onSubmitAnotherInquiry={onSubmitAnotherInquiry}
              />
              <Spacer size={40} />
            </MobileScreenFiller>
          ) : (
            <SendTicket onComplete={onComplete} />
          )}
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <BottomBarNavigation />
    </Container>
  );
}

function HelpPageDesktop(props: HelpPageProps) {
  const { requireReply, onSubmitAnotherInquiry, onComplete } = props;

  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Content>
          {requireReply !== null ? (
            <ThankYou
              requireReply={requireReply}
              onSubmitAnotherInquiry={onSubmitAnotherInquiry}
            />
          ) : (
            <SendTicket onComplete={onComplete} />
          )}
          <Spacer size={40} />
        </Content>
      </PageContainer>
      <Footer />
    </Container>
  );
}

interface ThankYouProps {
  requireReply: boolean;
  onSubmitAnotherInquiry: () => void;
}

function ThankYou(props: ThankYouProps) {
  const { requireReply, onSubmitAnotherInquiry } = props;
  return (
    <Container testID="help-thank-you" flex={1}>
      <Spacer size={32} />
      <Heading size={"lg"} color="black-core">
        Thank You
      </Heading>
      <Spacer size={16} />
      {requireReply ? (
        <Text>
          Thank you for submitting an inquiry. A Flexspace team member will
          respond to your message via email soon.
        </Text>
      ) : (
        <Text>
          Thank you for sharing your feedback. We appreciate you taking the time
          to help us improve Flexspace.
        </Text>
      )}
      <Spacer size={24} />
      <Spacer />
      <div className="support-page-button-wrapper">
        <Button
          text="Submit another inquiry"
          onPress={onSubmitAnotherInquiry}
        />
      </div>
    </Container>
  );
}

interface SendTicketProps {
  onComplete: (requireReply: boolean) => void;
}

function SendTicket(props: SendTicketProps) {
  const { onComplete } = props;

  return (
    <Container>
      <Spacer size={16} />
      <Heading size={"lg"} color="black-core">
        Help
      </Heading>
      <Spacer size={16} />
      <SupportForm onComplete={onComplete} />
    </Container>
  );
}

const topicOptions: Option<string>[] = [
  {
    value: "find_space",
    label: "Find a space",
  },
  {
    value: "product_question",
    label: "Product question",
  },
  {
    value: "product_feedback",
    label: "Product feedback",
  },
  {
    value: "bug_report",
    label: "Bug report",
  },
  {
    value: "other",
    label: "Other",
  },
];

interface SupportFormProps {
  onComplete: (requireReply: boolean) => void;
}

function SupportForm(props: SupportFormProps) {
  const { onComplete } = props;
  const [sendSupportTicketMutation] = useMutation<
    SendSupportTicketMutation,
    SendSupportTicketMutationVariables
  >(sendSupportTicketGQLMutation);

  const qs = useQueryString<HelpPageQueryString>();

  const { setFieldValue, values, submitting, errors, submit } = useForm({
    initialValues: {
      topic: qs.value || "",
      subject: "",
      content: "",
    },
    validate,
    onSubmit: async (values) => {
      await sendSupportTicketMutation({ variables: values });
      onComplete(
        values.topic === "find_space" ||
          values.topic === "product_question" ||
          values.topic === "bug_report",
      );
    },
  });

  const hasErrors = !isEmpty(validate(values));

  return (
    <Container testID="help-page">
      <Text color="muted">Topic</Text>
      <Spacer size={4} />
      <Picker
        testID="topic-picker"
        placeholder="Choose a topic"
        value={values.topic}
        onChange={(topic) => setFieldValue("topic", topic)}
        options={topicOptions}
        disabled={submitting}
      />
      {errors.topic && (
        <Fragment>
          <Spacer size={4} />
          <Text color="error">{errors.topic}</Text>
        </Fragment>
      )}
      <Spacer size={16} />
      <Text color="muted">Subject line</Text>
      <Spacer size={4} />
      <TextInput
        testID="subject-line"
        value={values.subject}
        onChange={(subject) => setFieldValue("subject", subject)}
        disabled={submitting}
      />
      {errors.subject && (
        <Fragment>
          <Spacer size={4} />
          <Text color="error">{errors.subject}</Text>
        </Fragment>
      )}
      <Spacer size={16} />
      <Text color="muted">How can we help you?</Text>
      <Spacer size={4} />
      <TextInput
        testID="help-content"
        numberOfLines={8}
        value={values.content}
        onChange={(content) => setFieldValue("content", content)}
        disabled={submitting}
      />
      {errors.content && (
        <Fragment>
          <Spacer size={4} />
          <Text color="error">{errors.content}</Text>
        </Fragment>
      )}
      <Spacer size={24} />
      <Spacer />
      <div className="support-page-button-wrapper">
        <Button
          testID="submit-help"
          disabled={hasErrors}
          loading={submitting}
          text="Submit"
          onPress={submit}
        />
      </div>
    </Container>
  );
}

function validate(values: SendSupportTicketMutationVariables) {
  const errors: FormErrors<SendSupportTicketMutationVariables> = {};

  if (!values.topic) {
    errors.topic = "Please select a topic";
  }

  if (!values.subject) {
    errors.subject = "Please fill a subject";
  }

  if (!values.content) {
    errors.content = "Please fill content";
  }

  return errors;
}
interface MobileScreenFillerProps {
  children: React.ReactNode;
}
function MobileScreenFiller(props: MobileScreenFillerProps) {
  const { children } = props;
  const bottomBarNavigation = 72;
  return (
    <div
      style={{
        height: `calc(100vh - ${bottomBarNavigation}px)`,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {children}
    </div>
  );
}

const sendSupportTicketGQLMutation = gql`
  mutation SendSupportTicket(
    $topic: String!
    $subject: String!
    $content: String!
  ) {
    sendSupportTicket(topic: $topic, subject: $subject, content: $content)
  }
`;
