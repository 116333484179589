import { useState } from "react";
import { View, Pressable, StyleSheet } from "react-native";

import { SpaceType, SpaceTypes } from "core/graphql.generated";
import { Spacer } from "components/spacer";
import { HomeSearchParamsChangeSet } from "pages/homev2/hooks/use_home_search_params";
import { Button } from "components/button_v2";
import { Text } from "components/text_v2";
import { colors } from "components/colors";

import { SpaceTypeCardMobile } from "./space_type_card_mobile";

interface SpaceTypePickerMobileProps {
  spaceTypePolicy?: SpaceTypes;
  value?: SpaceType;
  onContinue: (value: HomeSearchParamsChangeSet) => void;
}

export function SpaceTypePickerMobile({
  spaceTypePolicy,
  onContinue,
  value,
}: SpaceTypePickerMobileProps) {
  const [spaceTypeSelected, setSpaceTypeSelected] = useState<
    SpaceType | undefined
  >(value);

  const handlePressCard = (spaceType: SpaceType) => {
    setSpaceTypeSelected(spaceType);
  };

  const handleClickContinue = () => {
    onContinue({
      spaceType: spaceTypeSelected,
    });
  };

  const handleSearchAnySpaceType = () => {
    onContinue({
      spaceType: undefined,
    });
  };

  return (
    <View style={styles.root}>
      <View style={styles.contentWrapper}>
        <SpaceTypeCardMobile
          type={SpaceType.DayPass}
          title="Day Passes"
          content="Access to shared workspaces and amenities for the day."
          uri="/images/daypasses.png"
          onCardPress={handlePressCard}
          outOfPolicy={spaceTypePolicy && !spaceTypePolicy.dayPass}
          isPressed={spaceTypeSelected === SpaceType.DayPass}
        />
        <Spacer size={16} />
        <SpaceTypeCardMobile
          type={SpaceType.DayOffice}
          title="Private Offices"
          content="Full-day private offices for just you or your whole team."
          uri="/images/dayoffices.png"
          onCardPress={handlePressCard}
          outOfPolicy={spaceTypePolicy && !spaceTypePolicy.privateOffice}
          isPressed={spaceTypeSelected === SpaceType.DayOffice}
        />
        <Spacer size={16} />
        <SpaceTypeCardMobile
          type={SpaceType.MeetingRoom}
          title="Meeting Rooms"
          content="Rooms available by the hour for collaborating with others."
          uri="/images/meetingrooms.png"
          onCardPress={handlePressCard}
          outOfPolicy={spaceTypePolicy && !spaceTypePolicy.meetingRoom}
          isPressed={spaceTypeSelected === SpaceType.MeetingRoom}
        />
      </View>
      <View style={styles.dialogFooter}>
        {spaceTypeSelected ? (
          <>
            <Button text="Continue" onPress={handleClickContinue} />
            <Pressable onPress={() => setSpaceTypeSelected(undefined)}>
              <Text color="eggplant-core" size="xs" weight="semibold">
                Clear
              </Text>
            </Pressable>
          </>
        ) : (
          <Button
            text="Search for any space type"
            onPress={handleSearchAnySpaceType}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: "space-between",
  },
  contentWrapper: {
    paddingHorizontal: 16,
  },
  dialogFooter: {
    padding: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
