import { colors } from "components/colors";
import React, { memo } from "react";
import { View } from "react-native";
import { ProgressObject } from "../progress_bar_multicolor";
import { styles } from "./styles";

interface PropTypes {
  isFirst: boolean;
  isLast: boolean;
  item: ProgressObject;
}

const SingleBar = ({ isFirst = false, isLast = false, item }: PropTypes) => {
  const { color = colors.brand.blackcore, opacity = 1, value = 0 } = item;
  const getValue = (value: number) => {
    value = Math.min(Math.max(value, 0), 1);
    value = value * 100;
    const result = "" + value + "%";
    return result;
  };

  return (
    <View
      style={[
        isFirst && styles.onStartProgressStyle,
        isLast && styles.onEndProgressStyle,
        { backgroundColor: color, opacity, width: getValue(value) },
      ]}
    />
  );
};
export default memo(SingleBar);
