import { addMonths, format, isBefore } from "date-fns";
import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { MonthView } from "components/month_view/month_view";
import { startOfDay } from "lib/date_utils";
import { Day, parseDay, toDay } from "lib/day_utils";
import { Text } from "components/text_v2";
import { DaysOfWeek } from "components/month_renderer";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Button } from "components/button_v2";
import {
  renderDay,
  renderDayOfWeek,
  renderOtherMonthDay,
  renderWeek,
} from "components/month_view/components/components_v2";

interface DatePickerScrollableProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
}

export function DatePickerScrollable(props: DatePickerScrollableProps) {
  const { value, onChange } = props;

  const [month] = useState(value ? parseDay(value) : new Date());
  const [nOfMonthsToDisplay, setNOfMonthsToDisplay] = useState(5);

  const handleSelectDate = useCallback(
    (date: Date) => {
      onChange(toDay(date));
    },
    [onChange],
  );

  return (
    <View style={styles.wrapper}>
      <View style={{ flexShrink: 0, paddingHorizontal: 16 }}>
        <DaysOfWeek renderDayOfWeek={renderDayOfWeek} firstDayOfWeek={1} />
      </View>
      <Spacer size={7} />
      <Divider />
      <View style={styles.scrollableArea}>
        <Spacer size={16} />
        {new Array(nOfMonthsToDisplay).fill(null).map((_, i) => {
          const localMonth = addMonths(month, i);

          return (
            <View key={i}>
              <View style={styles.monthNavigatorWrapper}>
                <Text align="center" weight="semibold" size="xs">
                  {format(localMonth, "MMMM yyyy")}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 16,
                }}
              >
                <MonthView
                  selectedInterval={
                    value
                      ? { start: parseDay(value), end: parseDay(value) }
                      : null
                  }
                  renderDay={renderDay}
                  renderDayOfWeek={renderDayOfWeek}
                  renderOtherMonthDay={renderOtherMonthDay}
                  renderWeek={renderWeek}
                  month={localMonth}
                  onSelectDay={handleSelectDate}
                  isOutsideRange={(d) => isBefore(d, startOfDay(new Date()))}
                  withDaysOfWeek={false}
                  topOffset={10}
                />
              </View>
              <Spacer size={16} />
            </View>
          );
        })}
        <Spacer size={16} />
        <View style={{ padding: 16 }}>
          <Button
            text="Show more"
            onPress={() => setNOfMonthsToDisplay(nOfMonthsToDisplay + 2)}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: { overflow: "hidden", minHeight: 0, flexShrink: 1 },
  scrollableArea: {
    flexShrink: 1,
    minHeight: 0,
    overflow: "scroll",
  },
  monthNavigatorWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
