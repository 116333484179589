import { colors } from "components/colors";
import { StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";

export function OrganizationBudgetEmptyState() {
  return (
    <View style={styles.container}>
      <Text weight="semibold" size="md" color="black-70" align="center">
        No current budget.
      </Text>
      <Spacer size={20} />
      <Text align="center">
        <TextLink
          href="/admin/budgets/organization/new"
          decoration="none"
          text="Create an organization budget"
          size="xs"
          inline
          testID="create-org-budget-link"
          weight={"semibold"}
        />
        <Text color="black-70" size="xs">
          {" "}
          to track your company’s spending.
        </Text>
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    paddingHorizontal: 24,
    paddingVertical: 36,
    alignItems: "center",
  },
});
