import { getTimes } from "lib/time_utils";
import { TimeRangePicker, TimeRangePickerProps } from "./time_range_picker";

export interface TimeRangePickerHoursProps
  extends Omit<TimeRangePickerProps, "timeslots"> {
  earliestHour?: string;
  latestHour?: string;
}

export function TimeRangePickerHours(props: TimeRangePickerHoursProps) {
  const { earliestHour = "0700", latestHour = "1900" } = props;
  const hours = getTimes({ start: earliestHour, end: latestHour }, 30).slice(
    0,
    -1,
  );
  const timeslots = hours.map((hour) => ({
    startTime: hour,
    endTimes: getTimes({ start: hour, end: latestHour }, 30).slice(2),
  }));

  return <TimeRangePicker {...props} timeslots={timeslots} />;
}
