import { ReactNode } from "react";
import {
  BookingPreviewPanelSpace,
  StickyBookingPreviewPanel,
} from "core/booking_preview_panel";
import { SearchValue } from "core/booking_utils";
import { QuoteDetailsQuery } from "core/graphql.generated";

interface StickyBookingPreviewPanelWrapperProps {
  currency: string;
  space: BookingPreviewPanelSpace;
  onChangePromoCode?: (promoCode: string) => void;
  monthlyBilling: boolean;
  offsiteID?: string;
  promoCode?: string;
  quote?: QuoteDetailsQuery["quote"];
  quoteQuery?: QuoteDetailsQuery;
  quoteError?: string | null;
  searchValue: SearchValue;
  dateTimeDropDownPicker: ReactNode;
  setBookingDateRangeVisible: (visible: boolean) => void;
}

export function StickyBookingPreviewPanelWrapper(
  props: StickyBookingPreviewPanelWrapperProps,
) {
  const {
    promoCode,
    onChangePromoCode,
    currency,
    searchValue,
    space,
    monthlyBilling,
    offsiteID,
    quoteQuery,
    quoteError,
    dateTimeDropDownPicker,
    setBookingDateRangeVisible,
  } = props;

  return (
    <StickyBookingPreviewPanel
      promoCode={promoCode}
      onChangePromoCode={onChangePromoCode}
      searchValue={searchValue}
      offsiteID={offsiteID}
      monthlyBilling={monthlyBilling}
      quote={quoteQuery?.quote}
      quoteError={quoteError}
      space={space}
      currency={currency}
      setBookingDateRangeVisible={setBookingDateRangeVisible}
      dateTimeDropDownPicker={dateTimeDropDownPicker}
    />
  );
}
