import { useEffect, useState } from "react";
import { canUseDOM } from "components/hoverable";
import { ReservationDetailsInput } from "./booking_utils";

export interface SavedReservationDetails extends ReservationDetailsInput {}

const empty: SavedReservationDetails = {
  layoutID: "",
  meetingName: "",
  meetingStartTime: "",
  arrivalTime: "",
};

function getInitialSavedReservationDetails(
  spaceID: string,
): SavedReservationDetails {
  if (canUseDOM === false) {
    return empty;
  }

  const json = sessionStorage.getItem(`${spaceID}_savedReservationDetails`);

  if (json === null) {
    return {
      layoutID: "",
      meetingName: "",
      meetingStartTime: "",
      arrivalTime: "",
    };
  }

  return JSON.parse(json);
}

export function useSavedReservationDetails(spaceID: string) {
  const [savedReservationDetails, setSavedReservationDetails] =
    useState<SavedReservationDetails>(
      getInitialSavedReservationDetails(spaceID),
    );

  useEffect(() => {
    sessionStorage.setItem(
      `${spaceID}_savedReservationDetails`,
      JSON.stringify(savedReservationDetails),
    );
  }, [spaceID, savedReservationDetails]);

  return {
    savedReservationDetails,
    setSavedReservationDetails,
  };
}
