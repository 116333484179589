import { useEffect, useRef } from "react";

export const useMounted = (): boolean => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return function cleanup(): void {
      mountedRef.current = false;
    };
  }, []);

  return mountedRef.current;
};
