import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useAnalytics } from "providers/analytics";
import React from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useUserType } from "../hooks/use_user_type";
import { WhatFlexspaceOfferItemButton } from "./what_flexspace_offer_item_button";

const list = [
  {
    id: 1,
    title: "Meet with your team and get things done",
    btnText: "Explore meeting rooms",
    imageUrl: "/images/explore_meeting_rooms.jpg",
    linkTo: "/search?spaceType=MeetingRoom",
  },
  {
    id: 2,
    title: "Unlock your productivity in a private office for the day",
    btnText: "Explore private offices",
    imageUrl: "/images/explore_private_offices.png",
    linkTo: "/search?spaceType=DayOffice",
  },
  {
    id: 3,
    title: "Get a change of scenery and crush your to-do list",
    btnText: "Explore day passes",
    imageUrl: "/images/explore_day_passes.jpg",
    linkTo: "/search?spaceType=DayPass",
  },
];

export function WhatFlexSpaceOffer() {
  const mq = useMediaQuery();
  const userType = useUserType();
  const analytics = useAnalytics();
  const isMobile = mq.deviceQuery.mobile;
  const history = useHistory();
  return (
    <View>
      <TitleSpaceList
        title={"What Flexspace has to offer"}
        subtitle={"Explore our space types that best suit your needs"}
      />
      <Spacer size={isMobile ? 16 : 24} />
      <View style={isMobile ? styles.containerMobile : styles.container}>
        {list.map((item) => (
          <ImageBackground
            key={item.id}
            source={{ uri: item.imageUrl }}
            style={isMobile ? styles.imageMobileContent : styles.imageContent}
            imageStyle={styles.imageStyle}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                backgroundImage:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0) 100%)",
              }}
            >
              <View style={isMobile ? styles.contentMobile : styles.content}>
                <Text size={"md"} color={"white-core"} weight={"semibold"}>
                  {item.title}
                </Text>
                <WhatFlexspaceOfferItemButton
                  iconName={"arrow-right"}
                  iconPosition={"right"}
                  size={"small"}
                  onPress={() => {
                    analytics.event("Click Explore Space Types", {
                      Source: userType,
                      category: "Rebooking",
                    });
                    history.push(item.linkTo);
                  }}
                  text={item.btnText}
                />
              </View>
            </div>
          </ImageBackground>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: 24,
  },
  containerMobile: {
    flexDirection: "column",
    gap: 16,
  },
  content: {
    flex: 1,
    paddingTop: 30,
    padding: 18,
    gap: 20,
    borderRadius: 4,
  },
  contentMobile: {
    width: "100%",
    height: 216,
    padding: 26,
    gap: 24,
    borderRadius: 4,
  },

  imageMobileContent: {
    flex: 1,
    borderRadius: 4,
  },
  imageContent: {
    flex: 1,
    height: 360,
    borderRadius: 4,
  },
  imageStyle: {
    borderRadius: 4,
  },
});
