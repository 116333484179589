import { gql, useQuery } from "@apollo/client";
import { Spinner } from "components/spinner";
import {
  Invite,
  InviteByEmailQuery,
  Organization,
  OrganizationByDomainQuery,
  QueryInviteByEmailArgs,
  QueryOrganizationByDomainArgs,
  StepTeamQuery,
} from "core/graphql.generated";
import { useAnalytics } from "providers/analytics";
import { useEffect, useMemo } from "react";
import { StepTeamNameUI } from "../../../components/sign_up_shared/step_team_name_ui";
import { SignUpGcalStepTeamJoin } from "./step_team_join";

interface TeamProps {
  onGoNext: () => void;
  onGoStep: (index: number) => void;
}

// Look for invitation by invitee_email
// In case there's no invitation — try to find organization by domain
// If no organization found as well — suggest user to create a team
export function SignUpGcalStepTeam({ onGoNext, onGoStep }: TeamProps) {
  const analytics = useAnalytics();
  const { data: currentUserQuery } = useQuery<StepTeamQuery>(stepTeamGQLQuery);
  const currentUser = currentUserQuery?.currentUser;
  const auth0PrimaryEmail = currentUser?.auth0PrimaryEmail || "";
  const currentOrganization = currentUser?.organization;

  useEffect(() => {
    if (currentOrganization?.id) {
      onGoNext();
    }
  }, [currentOrganization, onGoNext]);

  const domain = useMemo(
    () => auth0PrimaryEmail?.split("@").pop() || null,
    [auth0PrimaryEmail],
  );

  useEffect(() => {
    analytics.event("GCal Sign Up", {
      Step: "Create Organization",
      Name: currentUser?.fullName,
      Email: auth0PrimaryEmail,
    });
  }, [analytics, currentUser?.fullName, auth0PrimaryEmail]);

  const { loading: inviteByEmailIsLoading, data: inviteByEmailResponse } =
    useQuery<InviteByEmailQuery, QueryInviteByEmailArgs>(
      inviteByEmailGQLQuery,
      {
        variables: {
          inviteeEmail: auth0PrimaryEmail,
          status: "pending",
        },
        skip: !auth0PrimaryEmail,
      },
    );

  const inviteIsLoadedAndPresent =
    !inviteByEmailIsLoading && !!inviteByEmailResponse?.inviteByEmail;

  const {
    loading: organizationByDomainIsLoading,
    data: organizationByDomainResponse,
  } = useQuery<OrganizationByDomainQuery, QueryOrganizationByDomainArgs>(
    organizationByDomainGQLQuery,
    {
      variables: { domain: domain as string },
      skip:
        !auth0PrimaryEmail ||
        !domain ||
        inviteByEmailIsLoading ||
        inviteIsLoadedAndPresent,
    },
  );

  const isLoading = inviteByEmailIsLoading || organizationByDomainIsLoading;

  if (isLoading) {
    return <Spinner />;
  }

  const organization =
    inviteByEmailResponse?.inviteByEmail?.organization ||
    organizationByDomainResponse?.organizationByDomain;

  return organization ? (
    <SignUpGcalStepTeamJoin
      onGoNext={onGoNext}
      organization={organization as Partial<Organization>}
      invite={inviteByEmailResponse?.inviteByEmail as Invite}
    />
  ) : (
    <StepTeamNameUI onGoStep={onGoStep} onGoNext={onGoNext} />
  );
}

const inviteByEmailGQLQuery = gql`
  query InviteByEmail($inviteeEmail: String!, $status: String) {
    inviteByEmail(inviteeEmail: $inviteeEmail, status: $status) {
      id
      status
      organization {
        id
        name
        users {
          id
          picture
          role
          userId
          fullName
        }
      }
    }
  }
`;

const organizationByDomainGQLQuery = gql`
  query OrganizationByDomain($domain: String!) {
    organizationByDomain(domain: $domain) {
      id
      name
      users {
        id
        picture
        userId
        role
        fullName
      }
    }
  }
`;

const stepTeamGQLQuery = gql`
  query StepTeam {
    currentUser {
      id
      auth0PrimaryEmail
      fullName
      organization {
        id
      }
    }
  }
`;
