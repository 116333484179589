import { colors } from "components/colors";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { ReactNode } from "react";
import { Pressable, StyleSheet, View } from "react-native";

interface SelectButtonWithIconProps {
  title: string;
  testID?: string;
  onPress: () => void;
  leftIcon?: ReactNode;
}

export function SelectButtonWithIconV1(props: SelectButtonWithIconProps) {
  const { testID, title, onPress, leftIcon } = props;
  const mq = useMediaQuery();

  const handlePress = () => {
    onPress();
  };

  return (
    <Pressable
      style={{ width: mq.deviceQuery.mobile ? "100%" : 300 }}
      onPress={handlePress}
    >
      {({ hovered, focused }: { hovered: boolean; focused: boolean }) => (
        <View testID={testID}>
          <View style={{ height: 64, width: "100%" }}>
            <View
              style={[
                styles.buttonRoot,
                hovered && styles.buttonRootHovered,
                focused && styles.buttonRootFocused,
              ]}
            >
              {!!leftIcon && <View style={styles.iconWrapper}>{leftIcon}</View>}
              <View style={styles.selectedLabel}>
                <Text numberOfLines={1} size="xs">
                  {title}
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  buttonRoot: {
    position: "relative",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: 8,
    paddingRight: "36px",
    borderRadius: 4,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.brand.whitecore,
    borderColor: colors.brand.blackMinus90,
  },
  buttonRootHovered: {
    borderColor: colors.brand.blackMinus30,
  },
  buttonRootFocused: {
    borderColor: colors.brand.eggplantcore,
  },
  iconWrapper: {
    position: "absolute",
    left: "8px",
    top: "50%",
    // @ts-ignore
    transform: "translateY(-50%)",
  },
  selectedLabel: {
    paddingLeft: 24,
  },
});
