import { AsyncRenderer } from "components/AsyncRenderer";
import { LinkWrapper } from "components/link_wrapper";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { OrderSort } from "core/graphql.generated";
import { toDay } from "lib/day_utils";
import { useMediaQuery } from "lib/media_query";
import { transformUpcomingBooking } from "pages/bookings/bookings_utils";
import { BookingDetails } from "pages/bookings/components/booking_details";
import { UpcomingBookingProps } from "pages/bookings/components/upcoming_booking";
import { UpcomingBookingTag } from "pages/bookings/components/upcoming_booking_tag";
import { UpcomingBookingThumbnail } from "pages/bookings/components/upcoming_booking_thumbnail";
import { useBookingQuery } from "pages/bookings/hooks/useBookingQuery";
import { NextBookingSkeleton } from "pages/homev3/components/next_booking_skeleton";
import { useAnalytics } from "providers/analytics";
import React from "react";
import { StyleSheet, View } from "react-native";

export function YourNextBooking() {
  const { data, error, loading } = useBookingQuery({
    limit: 1,
    sort: OrderSort.StartDateTimeDesc,
    startDate: toDay(new Date()),
  });
  return (
    <AsyncRenderer
      error={error}
      loading={loading}
      data={data}
      loadingHandler={() => <NextBookingSkeleton />}
    >
      {({ upcomingBookings }) => (
        <HomeUpcomingBooking details={upcomingBookings[0]} />
      )}
    </AsyncRenderer>
  );
}

function HomeUpcomingBooking({ details }: UpcomingBookingProps) {
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const isMobile = mq.deviceQuery.mobile;
  if (!details) {
    return null;
  }
  const {
    orderId,
    thumbnailUrl,
    upcomingInDays,
    dateRange,
    spaceName,
    spaceType,
    confirmationId,
    capacity,
    spaceId,
    locationName,
  } = transformUpcomingBooking(details);
  return (
    <View>
      <View style={styles.titleView}>
        <Text size={isMobile ? "md" : "lg"} weight={"semibold"}>
          Your next booking
        </Text>
        <LinkWrapper to={"/bookings"}>
          <Text size={"xs"} weight={"semibold"} color={"eggplant-core"}>
            See all
          </Text>
        </LinkWrapper>
      </View>
      <Spacer size={24} />
      <LinkWrapper
        onPress={() => {
          analytics.event("Click Booking Card", {
            "Space UUID": spaceId,
            "Space Name": spaceName,
            category: "Rebooking",
            "Location Name": locationName,
          });
        }}
        to={`/bookings/${orderId}`}
      >
        <View style={[styles.root, isMobile && styles.rootMobile]}>
          <View
            style={
              isMobile ? styles.thumbnailWrapperMobile : styles.thumbnailWrapper
            }
          >
            <UpcomingBookingThumbnail
              url={thumbnailUrl}
              upcomingInDays={upcomingInDays}
            />
          </View>
          <View
            style={[
              styles.contentWrapper,
              isMobile && styles.contentWrapperMobile,
            ]}
          >
            <UpcomingBookingTag capacity={capacity} />
            <Spacer size={8} />
            <BookingDetails
              spaceName={spaceName}
              spaceType={spaceType}
              dateRange={dateRange}
              confirmationId={confirmationId}
            />
          </View>
        </View>
      </LinkWrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  titleView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  root: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 170,
    borderRadius: 4,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    gap: 24,
  },
  rootMobile: {
    flexDirection: "column",
    height: "fit-content",
    width: "100%",
    borderRadius: 4,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    gap: 16,
  },
  thumbnailWrapper: {
    width: 400,
    height: "100%",
  },
  thumbnailWrapperMobile: { width: "100%" },
  detailsWrapper: {
    width: "65%",
  },
  contentWrapper: {
    flexDirection: "column",
  },
  contentWrapperMobile: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
});
