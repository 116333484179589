import { DateRange } from "core/booking_date_range_picker";
import { useAnalytics } from "providers/analytics";
import { useToast } from "providers/toast";

import { convertDateTimeRange } from "../bookings_utils";
import { UpcomingBookingAction } from "./upcoming_booking_action";

interface ShareThisSpaceProps {
  spaceName: string;
  dateRange: DateRange;
  url: string;
}

export function ShareThisSpace({
  url,
  spaceName,
  dateRange,
}: ShareThisSpaceProps) {
  const toast = useToast();
  const analytics = useAnalytics();

  const { dateRange: formatedDateRange, timeRange } = convertDateTimeRange(
    "MMM d",
    "-",
    dateRange,
  );

  let dateTimeRangeText = `on ${formatedDateRange}`;
  if (timeRange) {
    dateTimeRangeText = dateTimeRangeText.concat(`, ${timeRange}`);
  }

  const handleClickCopy = () => {
    navigator.clipboard.writeText(
      `Hi there! I’ve booked ${spaceName} on Flexspace and want you to come work with me on ${dateTimeRangeText}. ${url}`,
    );
    toast.notify({ message: "Link copied!" });
    analytics.event("Copy Share Link", {
      category: "Post booking",
      Source: "Booking List",
    });
  };

  return (
    <UpcomingBookingAction
      title="Share this space"
      description="Your coworkers can book a spot with the link"
      iconName="link"
      actionText="Copy share link"
      onClick={handleClickCopy}
    />
  );
}
