import { View } from "react-native";
import ContentLoader, { Rect } from "react-content-loader/native";

import { useMediaQuery } from "lib/media_query";
import { Spacer } from "components/spacer";

const SPACE_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT = 400;
const SPACE_DETAIL_SKELETON_SECTION_MOBILE_HEIGHT = 200;

export function SpaceDetailSkeleton() {
  const mq = useMediaQuery();

  return (
    <View>
      <Spacer size={mq.deviceQuery.mobile ? 16 : 40} />
      <ContentLoader
        speed={2}
        width="100%"
        height={
          mq.deviceQuery.mobile
            ? SPACE_DETAIL_SKELETON_SECTION_MOBILE_HEIGHT
            : SPACE_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT
        }
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect
          width="100%"
          height={
            mq.deviceQuery.mobile
              ? SPACE_DETAIL_SKELETON_SECTION_MOBILE_HEIGHT
              : SPACE_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT
          }
        />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={
          mq.deviceQuery.mobile
            ? SPACE_DETAIL_SKELETON_SECTION_MOBILE_HEIGHT
            : SPACE_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT
        }
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect
          width="100%"
          height={
            mq.deviceQuery.mobile
              ? SPACE_DETAIL_SKELETON_SECTION_MOBILE_HEIGHT
              : SPACE_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT
          }
        />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={
          mq.deviceQuery.mobile
            ? SPACE_DETAIL_SKELETON_SECTION_MOBILE_HEIGHT
            : SPACE_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT
        }
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect
          width="100%"
          height={
            mq.deviceQuery.mobile
              ? SPACE_DETAIL_SKELETON_SECTION_MOBILE_HEIGHT
              : SPACE_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT
          }
        />
      </ContentLoader>
    </View>
  );
}
