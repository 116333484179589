import {
  BookingRequests__CurrentUserFragment,
  BookingRequests__RequestDetailsFragment,
  BookingRequestStatus,
  UserRole,
} from "core/graphql.generated";
import { format, startOfMonth } from "date-fns";
import { getMonthlyLimit } from "lib/budget_utils";
import { parseDay } from "lib/day_utils";
import { getSpaceType } from "lib/space_type_utils";
import { formatCurrency } from "../../lib/currency";
import { getSystemLocale } from "../../lib/locale";

import { ArchivedRequest } from "./components/archived_requests_table";
import {
  AssociatedBudget,
  PendingRequest,
  RequestInfo,
} from "./components/pending_requests_table";

export const getExpireDays = (startDate: string) =>
  Math.ceil((parseDay(startDate).getTime() - Date.now()) / 86400000);

interface GetAsscociatedBudgetInput {
  groupBudgetName?: string;
  orgBudgetName?: string;
  groupBudgetId?: string;
  isAdmin?: boolean;
}

export const getAsscociatedBudget = ({
  groupBudgetId,
  groupBudgetName,
  orgBudgetName,
  isAdmin,
}: GetAsscociatedBudgetInput): AssociatedBudget | undefined => {
  if (groupBudgetName && groupBudgetId) {
    return {
      name: groupBudgetName,
      href: `/admin/budgets/${groupBudgetId}?backButtonText=${encodeURIComponent(
        "Back to requests",
      )}`,
    };
  }

  if (orgBudgetName) {
    if (isAdmin) {
      return {
        name: orgBudgetName,
        href: `/admin/budgets/organization`,
      };
    } else {
      return { name: orgBudgetName };
    }
  }

  return undefined;
};

interface SeperateBookingRequestsReturn {
  pendingRequests: PendingRequest[];
  archivedRequests: ArchivedRequest[];
}

export function seperateBookingRequests(
  allBookingRequests: BookingRequests__RequestDetailsFragment[],
  currentUser?: BookingRequests__CurrentUserFragment,
): SeperateBookingRequestsReturn {
  const pendingRequests: PendingRequest[] = [];
  const archivedRequests: ArchivedRequest[] = [];

  allBookingRequests.forEach(
    ({
      id,
      status,
      amount,
      currency,
      createdAt,
      startDate,
      reason,
      requester,
      reviewer,
      reviewerNote,
      space,
      reviewedAt,
      requesterTotalSpend,
      groupBudget,
      bookedAt,
    }) => {
      const baseRequestInfo: RequestInfo = {
        id,
        requestUsername: requester.fullName,
        spaceName: space.name,
        spaceType: getSpaceType(space.spaceType),
        spaceLocation: space.location.name,
        amount: amount
          ? formatCurrency(amount, getSystemLocale(), currency || "USD")
          : "",
        requestedOn: createdAt,
        reason,
      };
      const isAdmin = currentUser?.role === UserRole.Admin;
      if (status === BookingRequestStatus.Pending) {
        pendingRequests.push({
          ...baseRequestInfo,
          expireInDays: getExpireDays(startDate),
          associatedBudget: getAsscociatedBudget({
            groupBudgetId: groupBudget?.id,
            groupBudgetName: groupBudget?.name,
            orgBudgetName: currentUser?.organization?.name,
            isAdmin,
          }),
          userSpent: currentUser?.organization?.budget?.limit
            ? `$${requesterTotalSpend} of $${getMonthlyLimit({
                groupBudgetgIndividualLimit:
                  requester.groupBudget?.individualLimit || undefined,
                groupBudgetLimit: requester.groupBudget?.limit,
                organizationLimit: currentUser?.organization?.budget?.limit,
                organizationIndividualLimit:
                  currentUser?.organization?.budget?.individualLimit ||
                  undefined,
              })}`
            : `$${requesterTotalSpend}`,
          userApprovedRequests: requester.bookingRequests
            ? `${
                requester.bookingRequests.filter(
                  (r) =>
                    r.status === BookingRequestStatus.Approved ||
                    r.status === BookingRequestStatus.Booked,
                ).length
              } of ${requester.bookingRequests.length}`
            : "0",
        });
      } else {
        archivedRequests.push({
          ...baseRequestInfo,
          reviewedBy: reviewer?.fullName,
          reviewedOn: reviewedAt || undefined,
          bookedAt: bookedAt || undefined,
          status: status,
          declineReason: reviewerNote || undefined,
        });
      }
    },
  );

  return { pendingRequests, archivedRequests };
}

export function getFirstDateOfMonth() {
  const date = new Date();
  return format(startOfMonth(date), "yyyy-MM-dd");
}

export function getCurrentDate() {
  const date = new Date();
  return format(date, "yyyy-MM-dd");
}
