import { View } from "react-native";

import { OrderStatus } from "core/graphql.generated";

import { BookingDetailCancel } from "./booking_detail_cancel";
import { isArchivedBooking } from "../utils/isArchivedBooking";
import { BookingDetailBookAgain } from "./booking_detail_book_again";

interface BookingDetailMobileActionsProps {
  bookingId: string;
  spaceId: string;
  spaceName: string;
  startDate: string;
  status: OrderStatus;
  cancellable?: boolean;
}

export function BookingDetailMobileActions({
  bookingId,
  spaceId,
  spaceName,
  cancellable,
  startDate,
  status,
}: BookingDetailMobileActionsProps) {
  return (
    <View>
      {cancellable && <BookingDetailCancel bookingId={bookingId} />}
      {isArchivedBooking(startDate, status) && (
        <BookingDetailBookAgain spaceId={spaceId} spaceName={spaceName} />
      )}
    </View>
  );
}
