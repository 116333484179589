import { useHomeSearchParamsQuery } from "pages/homev2/hooks/use_home_search_params";
import { useHomeSearchParamsPlaceQuery } from "pages/homev2/hooks/use_home_search_params_place";
import { useAnalytics } from "providers/analytics";
import { useEffect } from "react";

export const useAmenitySearchFilterAnaltics = () => {
  const analytics = useAnalytics();
  const {
    spaceType,
    date,
    minCapacity,
    maxCapacity,
    amenities,
    startTime,
    endTime,
  } = useHomeSearchParamsQuery();
  const place = useHomeSearchParamsPlaceQuery();

  useEffect(() => {
    if (place.data?.place_name) {
      analytics.event("Discover Search", {
        "Place Name": place.data.place_name,
        "Space Type": spaceType,
        Date: date,
        Amenities: amenities,
        "Start Time": startTime,
        "End Time": endTime,
        "Min Capacity": minCapacity,
        "Max Capacity": maxCapacity,
      });
    }
  }, [
    amenities,
    analytics,
    date,
    endTime,
    maxCapacity,
    minCapacity,
    place.data?.place_name,
    spaceType,
    startTime,
  ]);
};
