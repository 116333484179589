import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "providers/toast";
import { SWRProvider } from "./providers/swr";

import { GraphQLV2Provider } from "./providers/graphqlv2";
import { RecoilRoot } from "recoil";
import { SplitProvider } from "providers/splitio";
import { RoutesV2 } from "routesv2";

export function TestApp() {
  return (
    <RecoilRoot>
      <ErrorBoundary>
        <BrowserRouter>
          <GraphQLV2Provider>
            <SWRProvider>
              <SplitProvider>
                <ToastProvider>
                  <RoutesV2 isProtected={false} />
                </ToastProvider>
              </SplitProvider>
            </SWRProvider>
          </GraphQLV2Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </RecoilRoot>
  );
}

interface ErrorBoundaryState {
  hasError: boolean;
  eventId: string;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, eventId: "" };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("error", error);
    console.error("errorInfo", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return "app error";
    }

    return this.props.children;
  }
}
