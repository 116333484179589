import { useMemo } from "react";

import { SearchValue } from "./booking_utils";
import {
  BookingType,
  OrderItemInput,
  OrderItemType,
  OrderSubSource,
  QuoteDetailsQueryVariables,
} from "./graphql.generated";

interface UseQuoteVariablesProps {
  searchValue: SearchValue;
  spaceID: string;
  layoutID: string;
  partnerID: string | undefined | null;
  attendees: number;
}

export function useQuoteVariables(
  props: UseQuoteVariablesProps,
): QuoteDetailsQueryVariables | null {
  const { searchValue, spaceID, layoutID, partnerID, attendees = 1 } = props;
  const { dateRange, promoCode, physicalSpaceID, quoteId } = searchValue;

  return useMemo(() => {
    // ensure that we don't send undefined values to the server by checking callers of this function
    if (dateRange === undefined) {
      return null;
    }
    const orderItems: OrderItemInput[] = [];
    const bookingBase = {
      layoutID,
      attendees,
    };
    if (dateRange.type === BookingType.HourlyBooking) {
      if (!dateRange.startTime || !dateRange.endTime || !dateRange.date) {
        return null;
      }

      orderItems.push({
        ...bookingBase,
        type: OrderItemType.HourlyBooking,
        startTime: dateRange.startTime,
        date: dateRange.date,
        endTime: dateRange.endTime,
        physicalSpaceID,
        spaceID,
      });
    } else if (dateRange.type === BookingType.DailyBooking) {
      if (!dateRange.startDate || !dateRange.endDate) {
        return null;
      }

      orderItems.push({
        ...bookingBase,
        type: OrderItemType.DailyBooking,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        physicalSpaceID,
        spaceID,
      });
    }

    return {
      input: {
        orderItems,
        promoCode,
        quoteId,
        partnerId: partnerID,
        subSource: OrderSubSource.Web,
      },
    };
  }, [
    dateRange,
    spaceID,
    layoutID,
    promoCode,
    physicalSpaceID,
    partnerID,
    quoteId,
    attendees,
  ]);
}
