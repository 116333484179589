import { formatDateRange } from "lib/date_utils";
import { formatTimeRange } from "lib/time_utils";

export function formatDateTimeRange(
  datePattern: string,
  seperator: string,
  startDate: string,
  endDate: string,
  startTime?: string,
  endTime?: string,
) {
  let dateTimeRange = formatDateRange(
    datePattern,
    seperator,
    startDate,
    endDate,
  );
  if (startTime && endTime) {
    dateTimeRange += `, ${formatTimeRange(
      "h:mm a",
      seperator,
      startTime,
      endTime,
    )}`;
  }
  return dateTimeRange;
}
