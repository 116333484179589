import React, { Fragment, useCallback, useState } from "react";
import { Pressable } from "react-native";
import { Carousel } from "components/carousel";
import { Column } from "components/column";
import { Container } from "components/container";
import { ImageGalleryModal } from "components/image_gallery_modal";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { ImageData } from "./graphql.generated";

interface ImageGalleryProps {
  name: string;
  images: {
    small: ImageData;
    medium: ImageData;
    large: ImageData;
  }[];
}

export function ImageGalleryMobile(props: ImageGalleryProps) {
  const { name, images } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const height = 260;

  const items = images.map((image) => ({
    src: image.large.url,
    w: image.large.width,
    h: image.large.height,
  }));

  const handleOpen = useCallback((newIndex) => {
    setIsOpen(true);
    setIndex(newIndex);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (images.length === 0) {
    return null;
  }

  return (
    <Container overflow="hidden" height={height} width="100%">
      {images.length > 1 && (
        <Container
          borderRadius={tokens.radius}
          customColor="rgba(245, 246, 247, 0.5)"
          padding={4}
          position="absolute"
          bottom={16}
          right={16}
          zIndex={1}
        >
          <Text size="xs">
            {index + 1} of {images.length}
          </Text>
        </Container>
      )}
      <Carousel>
        {images.map((image, i) => (
          <Pressable key={image.small.url} onPress={() => handleOpen(i)}>
            <Container height={height}>
              <img
                src={image.small.url}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt={`${name} cover 1`}
              />
            </Container>
          </Pressable>
        ))}
      </Carousel>
      <ImageGalleryModal
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </Container>
  );
}

export function ImageGalleryDesktop(props: ImageGalleryProps) {
  const { name, images } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const height = 440;

  const items = images.map((image) => ({
    src: image.large.url,
    w: image.large.width,
    h: image.large.height,
  }));

  const handleOpen = useCallback((i) => {
    setIsOpen(true);
    setIndex(i);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (images.length === 0) {
    return null;
  }

  return (
    <Container expanded>
      <Container height={height}>
        <Row>
          <Container height={height} flex={6} overflow="hidden">
            <Pressable style={{ height }} onPress={() => handleOpen(0)}>
              <img
                src={images[0].large.url}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt={`${name} cover 1`}
              />
            </Pressable>
          </Container>
          {images.length === 2 && (
            <Fragment>
              <Spacer size={16} />
              <Container height={height} flex={6} overflow="hidden">
                <Pressable onPress={() => handleOpen(1)}>
                  <img
                    src={images[1].large.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt={`${name} cover 2`}
                  />
                </Pressable>
              </Container>
            </Fragment>
          )}
          {images.length >= 3 && (
            <>
              <Spacer size={16} />
              <Container flex={3}>
                <Column>
                  <Container overflow="hidden">
                    <Pressable
                      style={{ height: (height - 16) / 2 }}
                      onPress={() => handleOpen(1)}
                    >
                      <img
                        src={images[1].medium.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={`${name} cover 2`}
                      />
                    </Pressable>
                  </Container>
                  <Spacer size={16} />
                  <Container overflow="hidden">
                    <Pressable
                      style={{ height: (height - 16) / 2 }}
                      onPress={() => handleOpen(2)}
                    >
                      <img
                        src={images[2].medium.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={`${name} cover 3`}
                      />
                    </Pressable>
                  </Container>
                </Column>
              </Container>
            </>
          )}
        </Row>
      </Container>
      {images.length > 3 && (
        <Container left={16} bottom={16} position="absolute">
          <Pressable onPress={() => handleOpen(0)}>
            <Container borderRadius={4} customColor="white" padding={8}>
              <Text size="sm">View all images</Text>
            </Container>
          </Pressable>
        </Container>
      )}
      <ImageGalleryModal
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </Container>
  );
}
