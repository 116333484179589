import { Spacer } from "components/spacer";
import { useMutation, gql, useQuery, useApolloClient } from "@apollo/client";
import {
  CreateEditBudgetFormColumn,
  CreateEditBudgetSummaryColumn,
} from "../components/create_edit_budget_layout";
import { useForm } from "hooks/use_form";
import { Button } from "components/button_v2";
import {
  CreateGroupBudgetMutation,
  CreateGroupBudgetOrganizationBudgetQuery,
  OrganizationUser,
} from "core/graphql.generated";
import { View } from "react-native";
import { CreateEditNavigationBlocker } from "../components/create_edit_navigation_blocker";
import { CreateEditBudgetForm } from "./components/create_edit_form";
import { CreateEditBudgetSummary } from "./components/create_edit_budget_summary";
import { useToast } from "providers/toast";
import { Spinner } from "components/spinner";
import { useMediaQuery } from "lib/media_query";
import React, { useMemo, useState } from "react";
import { pickBudgetColor } from "../budget_colors";
import { useHistory } from "react-router-dom";
import { isNotEmpty } from "lib/array_utils";
import { MovePeopleWaringModal } from "./components/add_budget_members";
import { BudgetMobileLayout } from "../budget_layout/buget_mobile_layout";
import {
  BudgetDetailHeaderDesktop,
  BudgetDetailHeaderMobile,
} from "../components/budget_detail_header_v1";
import { BudgetDesktopLayout } from "../budget_layout/buget_desktop_layout";
import { AttachPolicyCard } from "../policy/attach_policy_card";
import { useAnalytics } from "providers/analytics";

export interface GroupBudgetFormValues {
  name?: string;
  description?: string;
  limit?: number;
  managers?: OrganizationUser[];
  members?: OrganizationUser[];
  individualLimit?: number | null;
  policyId?: string;
}
export interface CreateEditBudgetFormErrors {
  limit?: string;
  name?: string;
  managers?: string;
  individualLimit?: string;
}

export function GroupBudgetCreate() {
  const toast = useToast();
  const mq = useMediaQuery();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const analytics = useAnalytics();

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isWarningModalLoading, setIsWarningModalLoading] = useState(false);

  const {
    loading: organizationBudgetDataLoading,
    data: organizationBudgetData,
  } = useQuery<CreateGroupBudgetOrganizationBudgetQuery>(
    createGrouBudgetOrganizationBudgetQuery,
  );

  const groupBudgets =
    organizationBudgetData?.currentUser?.organization?.groupBudgets;

  const initialValues: GroupBudgetFormValues = useMemo(
    () => ({
      name: "",
      description: "",
      limit: undefined,
      individualLimit: undefined,
      managers: [],
      members: [],
      policyId:
        organizationBudgetData?.currentUser?.organization?.policies.find(
          (p) => p.isAppliedToNewBudget,
        )?.id,
    }),
    [organizationBudgetData?.currentUser?.organization?.policies],
  );

  const [createGroupBudget, { loading }] =
    useMutation<CreateGroupBudgetMutation>(createGroupBudgetMutation);

  const { values, setFieldValue, errors, submit, isDirty } = useForm({
    initialValues,
    validate: (values) => {
      const errors: CreateEditBudgetFormErrors = {};
      if (!values.name) {
        errors.name = "Please enter name";
      }

      if (!values.limit && values.limit !== 0) {
        errors.limit = "Please enter amount";
      }

      if (
        (values.limit || values.limit === 0) &&
        (values.individualLimit || values.individualLimit === 0) &&
        values.individualLimit > values.limit
      ) {
        errors.limit =
          "Monthly limit cannot be lower that the individual limit";
      }

      if (
        values.name &&
        groupBudgets &&
        groupBudgets.some((budget) => budget.name === values.name)
      ) {
        errors.name = "Budget name must be unique within your organization.";
      }

      if (isNotEmpty(Object.values(errors))) {
        toast.notify({
          message: "Please check all the required fields",
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setShouldBlockNavigation(false);
        if (isWarningModalOpen) {
          setIsWarningModalOpen(false);
        } else if (movedPeople.length) {
          setIsWarningModalOpen(true);
          return;
        }
        const res = await createGroupBudget({
          variables: {
            input: {
              organizationId:
                organizationBudgetData?.currentUser?.organization?.id,
              limit: values.limit,
              individualLimit: values.individualLimit,
              name: values.name,
              description: values.description,
              managerIds: values.managers?.length
                ? values.managers.map((manager) => manager.id)
                : [],
              memberIds: values.members?.length
                ? values.members.map((member) => member.id)
                : [],
              policyId: values.policyId,
              color: pickBudgetColor(
                organizationBudgetData?.currentUser?.organization?.groupBudgets
                  ?.length || 0,
              ),
            },
          },
        });

        toast.notify({
          message: "Budget has been successfully created",
        });

        await apolloClient.resetStore();

        const savedBudget = res.data?.createGroupBudget;

        analytics.event("Save New Group Budget", {
          Name: savedBudget?.name,
          Description: savedBudget?.description,
          "Monthly Limit": savedBudget?.limit,
          "Individual Limit": savedBudget?.individualLimit,
          "Policy Name": savedBudget?.policy?.name,
          "Members Count": values.members?.length,
          "Managers Count": values.managers?.length,
        });

        if (res.data) {
          history.replace(`/admin/budgets/${res.data.createGroupBudget.id}`);
        } else {
          history.replace("/admin/budgets");
        }
      } catch (e) {
        toast.notify({
          message: "There has been an error when creating the budget",
        });
      }
    },
  });

  const movedPeople = [
    ...(values.managers?.filter((p) => !!p.groupBudgetRole) || []),
    ...(values.members?.filter((p) => !!p.groupBudgetRole) || []),
  ];

  const onWarningModalCancel = () => {
    setIsWarningModalOpen(false);
  };

  const onSavePress = () => {
    submit();
  };

  if (organizationBudgetDataLoading) {
    return (
      <View style={{ padding: 120 }}>
        <Spinner />
      </View>
    );
  }

  const groupBudgetUpdateContent = () => (
    <>
      {mq.deviceQuery.mobile && <Spacer size={16} />}
      <CreateEditNavigationBlocker
        isActive={isDirty && shouldBlockNavigation}
        description="It looks like you’re in the middle of creating a budget. All information entered will be lost if you leave this page."
      />
      {isWarningModalOpen && (
        <MovePeopleWaringModal
          onConfirm={submit}
          onCancel={onWarningModalCancel}
          people={movedPeople}
          onRequest={(loading) => setIsWarningModalLoading(loading)}
        />
      )}
      <CreateEditBudgetFormColumn>
        <CreateEditBudgetForm
          showPeopleCard={true}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <AttachPolicyCard
          defaultPolicyId={initialValues.policyId}
          onSelect={(policyId) => setFieldValue("policyId", policyId)}
        />
      </CreateEditBudgetFormColumn>
    </>
  );

  if (mq.deviceQuery.mobile) {
    return (
      <BudgetMobileLayout
        header={
          <BudgetDetailHeaderMobile
            title="Create new budget"
            actions={
              <Button
                text={"Save"}
                testID="group-budget-create-submit-button"
                onPress={onSavePress}
                appearance="textLink"
                disabled={!isDirty}
                loading={loading || isWarningModalLoading}
              />
            }
          />
        }
        body={groupBudgetUpdateContent()}
      />
    );
  }

  return (
    <BudgetDesktopLayout
      header={
        <BudgetDetailHeaderDesktop
          title="Create new budget"
          backButtonText="Back to budgets"
          hasDivider={true}
          actions={
            <Button
              testID="group-budget-create-submit-button"
              onPress={onSavePress}
              text={"Save"}
              disabled={!isDirty}
              loading={loading || isWarningModalLoading}
            />
          }
        />
      }
      body={groupBudgetUpdateContent()}
      extendedContent={
        <CreateEditBudgetSummaryColumn>
          <CreateEditBudgetSummary
            name={values.name}
            description={values.description}
            limit={values.limit}
            isEdit={false}
            members={values.members}
            managers={values.managers}
          />
        </CreateEditBudgetSummaryColumn>
      }
    />
  );
}

const createGroupBudgetMutation = gql`
  mutation CreateGroupBudget($input: CreateGroupBudgetInput!) {
    createGroupBudget(input: $input) {
      id
      name
      description
      limit
      individualLimit
      status
      policy {
        id
        name
      }
    }
  }
`;

const createGrouBudgetOrganizationBudgetQuery = gql`
  query CreateGroupBudgetOrganizationBudget {
    currentUser {
      id
      role
      organization {
        id
        budget {
          limit
        }
        groupBudgets {
          id
          name
        }
        policies {
          id
          name
          isAppliedToNewBudget
        }
      }
    }
  }
`;
