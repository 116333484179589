import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useQueryString } from "lib/query_string";
import { useAnalytics } from "providers/analytics";

interface ShowCancelAndExpireParam {
  showCancelAndExpire: string;
}

export function useShowCancelAndExpire(): [boolean, (value: boolean) => void] {
  const analytics = useAnalytics();
  const history = useHistory();
  const { showCancelAndExpire } = useQueryString<ShowCancelAndExpireParam>();
  const [showCanceledAndExpiredRequests, setShowCanceledAndExpiredRequests] =
    useState(showCancelAndExpire === "true");

  const handleSwitch = (value: boolean) => {
    analytics.event("Toggle Archived Requests", {
      category: "Approval Workflow",
      "Show Canceled & Expired": value,
    });

    const params = new URLSearchParams(location.search);
    params.set("showCancelAndExpire", value.toString());
    const queryString = `?${params.toString()}`;

    setShowCanceledAndExpiredRequests(value);
    history.replace(`${location.pathname}${queryString}`);
  };

  return [showCanceledAndExpiredRequests, handleSwitch];
}
