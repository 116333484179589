import React from "react";
import { StyleSheet, Text as RNText, TextStyle } from "react-native";
import { tokens } from "./tokens";

export type TextDecorationLine = "none" | "underline" | "line-through";
export type TextWeight =
  | "normal"
  | "bold"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900";

export interface TextProps {
  children?: React.ReactNode;
  size?: TextSize;
  color?: TextColor;
  customColor?: string;
  align?: "left" | "right" | "center";
  weight?: TextWeight;
  testID?: string;
  numberOfLines?: number;
  decoration?: TextDecorationLine;
  transform?: "none" | "uppercase" | "lowercase" | "capitalize";
  onPress?: () => void;
}

interface TextSizes {
  xl: TextStyle;
  lg: TextStyle;
  md: TextStyle;
  sm: TextStyle;
  xs: TextStyle;
}

export type TextSize = keyof TextSizes;

interface TextColors {
  default: string;
  primary: string;
  darker: string;
  link: string;
  muted: string;
  light: string;
  lightest: string;
  error: string;
  warning: string;
  success: string;
  contrast: string;
  tooltip: string;
  "neutral-darkest": string;
  "success-green": string;
}

export type TextColor = keyof TextColors;

/**
 * Run of text with a single style.
 */
export function Text(props: TextProps) {
  const {
    align = "left",
    children,
    color = "default",
    size = "md",
    testID,
    customColor,
    weight = "normal",
    numberOfLines,
    decoration = "none",
    transform = "none",
    onPress,
  } = props;

  return (
    <RNText
      testID={testID}
      style={[
        styles.base,
        styles[align],
        styles[size],
        styles[color],
        {
          fontWeight: weight,
          textDecorationLine: decoration,
          textTransform: transform,
          ...(customColor && { color: customColor }),
        },
      ]}
      onPress={onPress}
      numberOfLines={numberOfLines}
      ellipsizeMode="tail"
    >
      {children}
    </RNText>
  );
}

const styles = StyleSheet.create({
  base: {
    margin: 0,
    padding: 0,
    fontWeight: "normal",
    fontFamily: tokens.text.fontFamily,
  },
  default: {
    color: tokens.colors.base.black,
  },
  darker: {
    color: tokens.colors.primary.darker,
  },
  primary: {
    color: tokens.colors.primary.regular,
  },
  success: {
    color: tokens.colors.primary.regular,
  },
  link: {
    color: tokens.colors.neutral.link,
  },
  tooltip: {
    color: tokens.colors.neutral.darker,
  },
  muted: {
    color: tokens.colors.utility.lightest,
  },
  light: {
    color: tokens.colors.utility.lighter,
  },
  lightest: {
    color: tokens.colors.utility.lightest,
  },
  "neutral-darkest": {
    color: tokens.colors.neutral.darkest,
  },
  error: {
    color: tokens.colors.secondary.dark,
  },
  warning: {
    color: tokens.colors.secondary.regular,
  },
  contrast: {
    color: tokens.colors.base.white,
  },
  "success-green": {
    color: tokens.colors.success.regular,
  },
  xl: tokens.text.size.xl,
  lg: tokens.text.size.lg,
  md: tokens.text.size.md,
  sm: tokens.text.size.sm,
  xs: tokens.text.size.xs,
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
});
