import { Spacer } from "components/spacer";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { Redirect } from "react-router-dom";
import { useMediaQuery } from "lib/media_query";
import { MobileLayout } from "components/layout/mobile_layout";
import { BudgetDetailHeaderMobile } from "pages/budgets/components/budget_detail_header_v1";
import { useMutation, useQuery } from "@apollo/client";
import { CurrentUserOrganizationNameQuery } from "core/graphql.generated";
import {
  currentUserOrganizationNameQuery,
  updateOrganizationSSOMutation,
} from "pages/organization_profile/organization_profile";
import { Fragment } from "react";
import { OrganizationProfileCardWrapper } from "pages/organization_profile/components/organization_profile_card";
import { OrganizationProfileSkeleton } from "pages/organization_profile/components/organization_profile_skeleton";
import { StyleSheet, View } from "react-native";
import { useToast } from "providers/toast";
import { Switch } from "components/switch";
import { Spinner } from "components/spinner";
import { Text } from "components/text_v2";
import { capitalizeFirstLetter, getDomainFromEmail } from "lib/string_utils";
import { useCustomSSOFeatureFlag } from "providers/splitio";

export function OrganizationAuthenticationMobile() {
  const mq = useMediaQuery();
  const customSSOFeatureFlag = useCustomSSOFeatureFlag();
  const { data: currentUserQuery, loading: userDataLoading } =
    useQuery<CurrentUserOrganizationNameQuery>(
      currentUserOrganizationNameQuery,
    );
  const toast = useToast();

  const email = currentUserQuery?.currentUser?.email;
  const organization = currentUserQuery?.currentUser?.organization;

  const [updateOrganizationSSO, { loading: ssoLoading }] = useMutation(
    updateOrganizationSSOMutation,
  );

  const handleSSOChange = async (value: boolean) => {
    await updateOrganizationSSO({
      variables: {
        input: {
          sso: value,
        },
      },
    });
    toast.notify({ message: "Google Workspace SSO updated" });
  };

  if (!mq.deviceQuery.mobile) {
    return <Redirect to="/admin/organization-profile" />;
  }

  const { requireSSO, ssoProvider, slug } = organization || {};
  const isSSORequired = !userDataLoading && requireSSO && ssoProvider && slug;
  const userDomain = getDomainFromEmail(email);
  const showAuthMenu = customSSOFeatureFlag && isSSORequired;

  return (
    <MobileLayout
      header={<BudgetDetailHeaderMobile title="Authentication" />}
      footer={<BottomBarAdminNavigation />}
      body={
        <Fragment>
          <Spacer size={16} />
          {userDataLoading ? (
            <OrganizationProfileSkeleton />
          ) : (
            <OrganizationProfileCardWrapper title="Authentication">
              <View style={{ padding: 16 }}>
                <Text weight="semibold" size="xs">
                  {showAuthMenu && ssoProvider
                    ? `${capitalizeFirstLetter(ssoProvider[0])} SSO`
                    : "Associated domain"}
                </Text>
                <Spacer size={12} />
                <View style={styles.content}>
                  {showAuthMenu ? (
                    <Text size="xs" color="black-70">
                      Your organization requires SSO with{" "}
                      {capitalizeFirstLetter(ssoProvider ? ssoProvider[0] : "")}
                      . Employees should go to{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://app.flexspace.ai/${slug}`}
                      >
                        app.flexspace.ai/{slug}
                      </a>{" "}
                      to login
                    </Text>
                  ) : (
                    <>
                      {ssoLoading ? (
                        <Spinner size="small" />
                      ) : (
                        <Switch
                          value={!!organization?.domain}
                          onChange={(value) => handleSSOChange(value)}
                        />
                      )}
                      <Spacer direction="row" size={12} />
                      <Text size="xs" color="black-70">
                        Let anyone with an <strong>@{userDomain}</strong> email
                        join this team
                      </Text>
                    </>
                  )}
                </View>
              </View>
            </OrganizationProfileCardWrapper>
          )}
        </Fragment>
      }
    />
  );
}

const styles = StyleSheet.create({
  content: {
    flexDirection: "row",
  },
});
