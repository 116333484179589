import { PricingType } from "core/graphql.generated";
import { getSystemLocale } from "lib/locale";

interface PropTypes {
  pricings: {
    type: PricingType;
    price: number;
  }[];
  currency: string;
}

export function getPriceTagText(args: PropTypes): string {
  const { pricings, currency } = args;
  if (!pricings || pricings.length === 0) {
    return "";
  }
  const pricingValues = [
    ...new Map(pricings?.map((item) => [item.price, item])).values(),
  ];

  const type = pricingValues[0].type === PricingType.Daily ? " /day" : " /hr";

  return pricingValues
    .map((pricing, i) => {
      return `${i === 1 ? " - " : ""}${new Intl.NumberFormat(
        getSystemLocale(),
        {
          style: "currency",
          currency,
          minimumSignificantDigits: 1,
        },
      ).format(pricing.price)}${i === pricingValues.length - 1 ? type : ""}`;
    })
    .join("");
}

export function getDynamicPriceTagText(args: PropTypes): string {
  const { pricings, currency } = args;
  if (!pricings || pricings.length === 0) {
    return "";
  }
  const pricingValues = [
    ...new Map(pricings?.map((item) => [item.price, item])).values(),
  ];

  const type = pricingValues[0].type === PricingType.Daily ? " /day" : " /hour";

  return pricingValues
    .map((pricing, i) => {
      return `${i === 1 ? " - " : ""}${new Intl.NumberFormat(
        getSystemLocale(),
        {
          style: "currency",
          currency,
          minimumSignificantDigits: 1,
        },
      ).format(pricing.price)}${i === pricingValues.length - 1 ? type : ""}`;
    })
    .join("");
}
