import { gql, useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Button } from "components/button_v2";

import { Card } from "components/card";
import { Checkbox } from "components/checkbox";
import { MultiCheckbox } from "components/multi_checkbox";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  BudgetPoliciesQuery,
  BudgetPolicyLocationItemDistanceType,
  BudgetPolicyLocationItemInput,
  GroupBudgetsQuery,
  SpaceTypesInput,
} from "core/graphql.generated";
import { useForm } from "hooks/use_form";
import { isEmpty } from "lib/lang_utils";
import { useMediaQuery } from "lib/media_query";
import { policyFormValidate } from "pages/budgets/policy/policy_form_utils";
import { useAnalytics } from "providers/analytics";
import { useProximityPolicyFeatureFlag } from "providers/splitio";
import React, { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";

import { BudgetDesktopLayout } from "../budget_layout/buget_desktop_layout";
import { BudgetMobileLayout } from "../budget_layout/buget_mobile_layout";
import {
  BudgetDetailHeaderDesktop,
  BudgetDetailHeaderMobile,
} from "../components/budget_detail_header_v1";
import {
  CreateEditBudgetFormColumn,
  CreateEditBudgetSummaryColumn,
} from "../components/create_edit_budget_layout";
import { CreateEditNavigationBlocker } from "../components/create_edit_navigation_blocker";
import { EditBudgetSkeleton } from "../components/edit_budget_skeleton";
import { CreateFormSummary } from "./components/create_form_summary";
import { ExistingPolicyBudgetWarningDialog } from "./components/existing_policy_budget_warning_dialog";
import { PolicyBasicForm } from "./components/policy_basic_form";

export type PolicyLocationItem = {
  addressId?: string;
  street: string;
  latitude: number;
  longitude: number;
  distance: number;
  distanceUnit: BudgetPolicyLocationItemDistanceType;
  active: boolean;
};

export type FormValueType = {
  name: string;
  spaceTypes?: SpaceTypesInput;
  isAppliedToNewBudget: boolean;
  applyToBudgetIds?: string[];
  locationItems?: PolicyLocationItem[];
};

export interface FormSubmitValues {
  name: string;
  spaceTypes: SpaceTypesInput;
  isAppliedToNewBudget: boolean;
  applyToBudgetIds: string[];
  locationItems?: BudgetPolicyLocationItemInput[];
}

interface PolicyMutationPropTypes {
  isUpdate?: boolean;
  title: string;
  secondaryHeadingText?: string;
  initialValues: FormValueType;
  onSubmit: (values: FormSubmitValues) => Promise<void>;
  loading: boolean;
  isFetchingInitialValues?: boolean;
}

export const PolicyMutationForm: React.FC<PolicyMutationPropTypes> = ({
  title,
  secondaryHeadingText,
  initialValues: initialValuesProp,
  onSubmit: onSubmitProp,
  loading,
  isFetchingInitialValues,
  isUpdate,
}) => {
  const {
    deviceQuery: { mobile: isMobile },
  } = useMediaQuery();

  const analytics = useAnalytics();
  const isPPFlag = useProximityPolicyFeatureFlag();
  const [
    openExistingPolicyBudgetWarningDialog,
    setOpenExistingPolicyBudgetWarningDialog,
  ] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);

  const {
    data: groupBudgetsQueryData,
    loading: groupBudgetsQueryLoading,
    error: groupBudgetsQueryError,
  } = useQuery<GroupBudgetsQuery>(groupBudgetsQuery);
  const groupBudgets = useMemo(() => {
    return groupBudgetsQueryData?.currentUser?.organization?.groupBudgets || [];
  }, [groupBudgetsQueryData]);
  const { data: policiesQueryData } =
    useQuery<BudgetPoliciesQuery>(budgetPoliciesQuery);

  const { values, errors, setFieldValue, isDirty, submit } = useForm({
    initialValues: initialValuesProp,
    reinitialize: true,
    validateOnChange: true,
    validate: (values) =>
      policyFormValidate(
        values,
        policiesQueryData?.currentUser?.organization?.policies.reduce(
          (result: string[], policy) =>
            policy.name !== initialValuesProp.name
              ? result.concat(policy.name)
              : result,
          [],
        ),
      ),
    onSubmit: async () => {
      setShouldBlockNavigation(false);
      const {
        name,
        spaceTypes,
        isAppliedToNewBudget,
        applyToBudgetIds,
        locationItems,
      } = values;
      if (name && spaceTypes) {
        await onSubmitProp({
          name: name.trim(),
          spaceTypes,
          isAppliedToNewBudget,
          applyToBudgetIds: applyToBudgetIds || [],
          locationItems: locationItems
            ?.filter((item) => item.active)
            .map((item) => ({
              addressId: item.addressId,
              street: item.street,
              distance: item.distance,
              distanceUnit: item.distanceUnit,
              latitude: item.latitude,
              longitude: item.longitude,
            })),
        });

        analytics.event(
          isUpdate ? "Update Budget Policy" : "Save New Budget Policy",
          {
            Name: values.name,
            "Space Types": values.spaceTypes,
            "Budget Names": groupBudgets
              .filter((budget) => values.applyToBudgetIds?.includes(budget.id))
              .map((budget) => budget.name),
            Default: values.isAppliedToNewBudget,
          },
        );
      }
    },
  });

  const handleSaveClick = async () => {
    const budgetHasPolicy = groupBudgets
      .filter((b) => !!b.policy)
      .map((b) => b.id);
    const hasSelectAppliedPolicyBuget = values.applyToBudgetIds?.some((id) =>
      budgetHasPolicy.includes(id),
    );
    if (hasSelectAppliedPolicyBuget) {
      setOpenExistingPolicyBudgetWarningDialog(true);
      return;
    }
    await submit();
  };

  const renderBudgetSelectionAsync = () => {
    if (groupBudgets.length) {
      return (
        <MultiCheckbox
          title="Which budget(s) you would like this policy to apply to?"
          selectAllTestId="budget-select-all-checkbox"
          selectAllLabel="All budgets"
          options={groupBudgets.map(({ id, name, policy }) => ({
            id,
            isSelected: !!values.applyToBudgetIds?.includes(id),
            label: name,
            testId: `budget-${name}`,
            renderLabel: () => (
              <View
                style={
                  isMobile
                    ? styles.labelWrapperMobile
                    : styles.labelWrapperDesktop
                }
              >
                <View style={!isMobile && styles.budgetNameLabelDesktop}>
                  <Text size="xs">{name}</Text>
                </View>
                {policy && (
                  <Text size="xs" color="black-50">
                    {policy.name} policy already applied
                  </Text>
                )}
              </View>
            ),
          }))}
          onChange={(value) =>
            setFieldValue(
              "applyToBudgetIds",
              value.reduce<string[]>((filtered, option) => {
                if (option.isSelected) {
                  filtered.push(option.id);
                }
                return filtered;
              }, []),
            )
          }
        />
      );
    }
    return <></>;
  };

  const formContent = isFetchingInitialValues ? (
    <EditBudgetSkeleton />
  ) : (
    <CreateEditBudgetFormColumn>
      <PolicyBasicForm
        values={values}
        errors={errors}
        onChange={setFieldValue}
      />
      <Spacer size={24} />

      <Card title="Budget selection">
        <View>
          <View>
            <Text size="xs" weight="semibold">
              Is this the default policy you would like to apply to new budgets?
            </Text>
            <Checkbox
              label="Yes, apply this policy to all new budgets by default."
              value={values.isAppliedToNewBudget}
              onChange={(value) => setFieldValue("isAppliedToNewBudget", value)}
              appearance="box"
              testId="apply-to-new-budget-checkbox"
            />
          </View>

          <View style={styles.cardSection}>
            <AsyncRenderer
              loading={groupBudgetsQueryLoading}
              error={groupBudgetsQueryError}
              data={groupBudgetsQueryData}
            >
              {renderBudgetSelectionAsync}
            </AsyncRenderer>
          </View>
        </View>
      </Card>
    </CreateEditBudgetFormColumn>
  );

  const renderLayout = () => {
    let isSaveDisabled = !isDirty || !isEmpty(errors);

    if (isMobile) {
      return (
        <BudgetMobileLayout
          header={
            <BudgetDetailHeaderMobile
              title={title}
              secondaryHeadingText={isPPFlag ? secondaryHeadingText : null}
              actions={
                <Button
                  text="Save"
                  appearance="textLink"
                  onPress={handleSaveClick}
                  disabled={isSaveDisabled}
                  loading={loading}
                  testID="save-policy-button"
                />
              }
            />
          }
          body={formContent}
        />
      );
    }
    return (
      <BudgetDesktopLayout
        header={
          <BudgetDetailHeaderDesktop
            title={title}
            secondaryHeadingText={isPPFlag ? secondaryHeadingText : null}
            hasDivider={isPPFlag}
            backButtonText="Back to policies"
            actions={
              <Button
                onPress={handleSaveClick}
                text="Save"
                appearance="primary"
                disabled={isSaveDisabled}
                loading={loading}
                testID="save-policy-button"
              />
            }
          />
        }
        body={formContent}
        extendedContent={
          !isFetchingInitialValues && (
            <CreateEditBudgetSummaryColumn>
              <CreateFormSummary values={values} />
            </CreateEditBudgetSummaryColumn>
          )
        }
      />
    );
  };

  return (
    <>
      {renderLayout()}
      <CreateEditNavigationBlocker
        isActive={isDirty && shouldBlockNavigation}
        description="It looks like you’re in the middle of creating a policy. All information entered will be lost if you leave this page."
      />
      <ExistingPolicyBudgetWarningDialog
        open={openExistingPolicyBudgetWarningDialog}
        onCancel={() => setOpenExistingPolicyBudgetWarningDialog(false)}
        onConfirm={() => {
          setOpenExistingPolicyBudgetWarningDialog(false);
          submit();
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  cardSection: {
    marginTop: 24,
    gap: 12,
  },
  labelWrapperDesktop: {
    flexDirection: "row",
    width: "80%",
    alignItems: "center",
  },
  labelWrapperMobile: {
    flexDirection: "column",
    width: "80%",
  },
  budgetNameLabelDesktop: {
    width: 270,
  },
});

const groupBudgetsQuery = gql`
  query GroupBudgets {
    currentUser {
      organization {
        groupBudgets {
          id
          name
          policy {
            name
          }
        }
      }
    }
  }
`;

export const budgetPoliciesQuery = gql`
  query BudgetPolicies {
    currentUser {
      organization {
        policies {
          id
          name
        }
      }
    }
  }
`;

export const budgetPolicyAddressesQuery = gql`
  query BudgetPolicyAddresses {
    budgetPolicyAddresses {
      id
      street
      latitude
      longitude
    }
  }
`;
