import { colors } from "components/colors";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { GoogleButton } from "core/google_button";
import { useAuthV2 } from "providers/authv2";
import { useAnalytics } from "providers/analytics";
import { Image, StyleSheet, View } from "react-native";
import { useEffect } from "react";
import { SIGN_UP_STEPS_REDIRECT_URL_KEY } from "providers/auth_loader";
import { TermsAndPolicy } from "components/sign_up_shared/terms_and_policy";

export function SignUpGcalStepGetStarted() {
  const { continueWithGoogleCalendar } = useAuthV2();

  const signUp = () => {
    localStorage.setItem(SIGN_UP_STEPS_REDIRECT_URL_KEY, "/sign-up-gcal/team");
    continueWithGoogleCalendar();
  };

  const analytics = useAnalytics();

  useEffect(() => {
    analytics.event("GCal Sign Up", {
      Step: "Start",
    });
  }, [analytics]);

  return (
    <View>
      <Heading size="mega" align="center">
        Get started
      </Heading>
      <Spacer size={16} />
      <Content maxWidth={630}>
        <Text size="xs" customColor={colors.brand.blackcore} align="center">
          Flexspace on Google Calendar is the easiest way to coordinate a space
          for your next meeting. No more back and forth with your teammates, do
          everything from your calendar.
        </Text>

        <Spacer size={16} />

        <View style={styles.imageContainer}>
          <Image
            source={{
              uri: "/images/teammates.svg",
              width: 200,
              height: 200,
            }}
          />
        </View>

        <Spacer size={68} />

        <Content maxWidth={340}>
          <TermsAndPolicy />
        </Content>

        <Spacer size={16} />

        <View style={styles.googleButtonContainer}>
          <View style={styles.googleButtonInnerContainer}>
            <GoogleButton onPress={signUp} />
          </View>
        </View>
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  imageContainer: {
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
  },
  googleButtonContainer: {
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
  },
  googleButtonInnerContainer: {
    width: "220px",
  },
});
