import { Dialog } from "components/dialog";
import { HorizontalList } from "components/horizontal_list";
import { Text } from "components/text_v2";
import {
  TeamsBookingDetailsFragment,
  TeamsLocationDetailsFragment,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { TeamLocationPreview } from "pages/team_spaces/components/team_location_preview";
import { TeamSpaceCard } from "pages/team_spaces/components/team_space_card";
import { useAnalytics } from "providers/analytics";
import React, { useState } from "react";

type TeamSpacesListProps = {
  bookings?: TeamsBookingDetailsFragment[];
};

export function JoinYourTeam({ bookings }: TeamSpacesListProps) {
  const [locationPreviewData, setLocationPreviewData] =
    useState<TeamsLocationDetailsFragment>();

  const analytics = useAnalytics();

  if (!bookings || bookings.length === 0) {
    return null;
  }
  const groupedBooking = groupBy(bookings, (b) => b.space.location.id);
  const renderItem = (bookings: TeamsBookingDetailsFragment[]) => (
    <TeamSpaceCard
      key={bookings[0].space.location.id}
      space={bookings[0].space}
      peoples={bookings.map((b) => b.user)}
      onPreviewLocation={(location) => {
        setLocationPreviewData(location);
        analytics.event("Click on Teammate Space", {
          Category: "Where is everyone",
          "Space UUID": bookings[0].space.id,
          "Space Name": bookings[0].space.name,
          City: bookings[0].space.location.address.city,
          State: bookings[0].space.location.address.state,
          Country: bookings[0].space.country,
          Location: bookings[0].space.location.name,
        });
      }}
    />
  );

  return (
    <>
      <HorizontalList
        testID="join-your-team"
        title={
          <Text size={"md"} weight={"semibold"}>
            Join your team
          </Text>
        }
        data={Object.values(groupedBooking)}
        renderItem={renderItem}
      />
      {locationPreviewData && (
        <Dialog
          visible
          onRequestClose={() => setLocationPreviewData(undefined)}
        >
          <TeamLocationPreview
            locationPreviewData={locationPreviewData}
            onClose={() => setLocationPreviewData(undefined)}
          />
        </Dialog>
      )}
    </>
  );
}
