import { Card } from "components/card";
import { Checkbox } from "components/checkbox";
import { Divider } from "components/divider";
import { MultiCheckbox } from "components/multi_checkbox";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";

import { SpaceType, SpaceTypesInput } from "core/graphql.generated";
import { LocationSection } from "pages/budgets/policy/location_section";
import { PolicyLocationItem } from "pages/budgets/policy/policy_mutation_form";
import { useProximityPolicyFeatureFlag } from "providers/splitio";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useLocation } from "react-router-dom";

import { getSpaceTypesObj } from "../policy_form_utils";

interface BasicValues {
  name: string;
  spaceTypes?: SpaceTypesInput;
  locationItems?: PolicyLocationItem[];
}

interface Error {
  name?: string;
  spaceTypes?: string;
  locationItems?: string;
}

interface BasicFormPropType {
  values: BasicValues;
  errors: Error;
  onChange: (
    field: keyof BasicValues,
    value: string | SpaceTypesInput | PolicyLocationItem[],
  ) => void;
  useCard?: boolean;
}

export const PolicyBasicForm: React.FC<BasicFormPropType> = ({
  values,
  errors,
  onChange,
}) => {
  const isPPFlag = useProximityPolicyFeatureFlag();
  const location = useLocation();
  const spaceTypeOptions = [
    {
      id: SpaceType.DayOffice,
      isSelected: !!values.spaceTypes?.privateOffice,
      label: "Private offices",
      testId: "private-office-checkbox",
    },
    {
      id: SpaceType.DayPass,
      isSelected: !!values.spaceTypes?.dayPass,
      label: "Day passes",
      testId: "day-pass-checkbox",
    },
    {
      id: SpaceType.MeetingRoom,
      isSelected: !!values.spaceTypes?.meetingRoom,
      label: "Meeting rooms",
      testId: "meeting-rooms-checkbox",
    },
  ];

  const [showLocationList, setShowLocationList] = useState(
    location.pathname.includes("edit") &&
      values.locationItems &&
      values.locationItems.some((item) => item.active),
  );

  const handleSpaceTypeChange = (
    spaceTypes: {
      id: string;
      isSelected: boolean;
    }[],
  ) => {
    const updatedSpaceTypes = getSpaceTypesObj(spaceTypes);
    onChange("spaceTypes", updatedSpaceTypes);
  };

  const allPlacePress = () => {
    setShowLocationList(false);
    const { locationItems } = values;
    if (!locationItems || locationItems.length === 0) {
      return;
    }
    onChange(
      "locationItems",
      locationItems.map((item) => ({ ...item, active: false })),
    );
  };

  const somePlacePress = () => {
    setShowLocationList(true);
    const { locationItems } = values;
    if (!locationItems || locationItems.length === 0) {
      return;
    }
    onChange(
      "locationItems",
      locationItems.map((item) => ({ ...item, active: true })),
    );
  };

  if (isPPFlag) {
    return (
      <View style={styles.container}>
        <Card title="Basics">
          <TextField
            label="Policy name*"
            inputPlaceholder="e.g. Events policy"
            value={values.name}
            onChange={(value) => onChange("name", value)}
            invalid={!!errors.name}
            invalidText={errors.name}
            testID="policy-name"
          />
        </Card>
        <Card title="Space types">
          <MultiCheckbox
            title="What types of spaces can people book?*"
            options={spaceTypeOptions}
            selectAllTestId="select-all-space-types-checkbox"
            selectAllLabel="All types"
            onChange={handleSpaceTypeChange}
            error={errors.spaceTypes}
          />
        </Card>
        <Card title="Locations">
          <View>
            <Text size="xs" weight="semibold">
              Where can people book?*
            </Text>
            <Checkbox
              label="All places: People can book wherever they want to work"
              value={!showLocationList}
              onChange={allPlacePress}
              appearance="radio"
              testId="apply-to-all-places-radio"
            />
            <Checkbox
              label="Some places: Set limits for booking within proximity to your offices"
              value={showLocationList}
              onChange={somePlacePress}
              appearance="radio"
              testId="apply-to-some-places-radio"
            />

            {showLocationList && (
              <LocationSection
                locationItems={values.locationItems}
                onChange={(value) => onChange("locationItems", value)}
                error={errors.locationItems}
              />
            )}
          </View>
        </Card>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <Text color="black-core" size="md" weight="semibold">
          Basics
        </Text>
        <Spacer size={24} />
        <TextField
          label="Policy name*"
          inputPlaceholder="e.g. Events policy"
          value={values.name}
          onChange={(value) => onChange("name", value)}
          invalid={!!errors.name}
          invalidText={errors.name}
          testID="policy-name"
        />
      </View>
      <Divider />
      <View style={styles.card}>
        <Text color="black-core" size="md" weight="semibold">
          Space types
        </Text>
        <Spacer size={24} />
        <MultiCheckbox
          title="What types of spaces can people book?*"
          options={spaceTypeOptions}
          selectAllTestId="select-all-space-types-checkbox"
          onChange={handleSpaceTypeChange}
          error={errors.spaceTypes}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 24,
  },
  card: {
    borderRadius: 0,
  },
  withPadding: {
    padding: 24,
  },
});
