import { Card } from "components/card";
import { colors } from "components/colors";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { StyleSheet, View } from "react-native";

import {
  HandleReview,
  useBookingRequestReview,
} from "../hooks/use_booking_request_review";
import { ReviewStatus } from "./booking_request_table_raw";

interface ValueType {
  text: string;
  element?: JSX.Element;
}

interface Row {
  id: string;
  label: string;
  value: ValueType;
}

interface Actions {
  render: (
    onReview: HandleReview,
    loadingApproveRequest: boolean,
    loadingDeclineRequest: boolean,
  ) => JSX.Element;
  refetch: () => void;
}

interface BookingRequestMobileCardPropsType {
  requestId: string;
  rows: Row[];
  actions?: Actions;
}

const getCardBgColor = (reviewStatus: ReviewStatus | null) => {
  if (reviewStatus === ReviewStatus.Approving) {
    return colors.brand.spinachMinus90;
  }
  if (reviewStatus === ReviewStatus.Declining) {
    return colors.brand.grapefruitMinus90;
  }
  return colors.brand.whitecore;
};

const BookingRequestMobileCard: React.FC<BookingRequestMobileCardPropsType> = ({
  rows,
  requestId,
  actions,
}) => {
  const {
    handleReview,
    decliningDialog,
    reviewStatus,
    loadingApproveRequest,
    loadingDeclineRequest,
  } = useBookingRequestReview(
    requestId,
    actions?.refetch ? actions.refetch : () => {},
  );

  return (
    <Card backgroundColor={getCardBgColor(reviewStatus)}>
      <View style={bookingRequestMobileCardStyles.cardContent}>
        {rows.map(({ id, label, value }, index) => (
          <View key={id}>
            <View style={bookingRequestMobileCardStyles.rowWrapper}>
              <View style={bookingRequestMobileCardStyles.labelWrapper}>
                <Text size="xs" weight="semibold">
                  {label}
                </Text>
              </View>
              <View style={bookingRequestMobileCardStyles.valueWrapper}>
                {value.element ? (
                  value.element
                ) : (
                  <Text size="xs">{value.text}</Text>
                )}
              </View>
            </View>
            <Spacer size={16} />
            <Divider />
            {index !== rows.length - 1 && <Spacer size={16} />}
          </View>
        ))}
        {actions && (
          <View style={bookingRequestMobileCardStyles.rowWrapper}>
            <View style={bookingRequestMobileCardStyles.labelWrapper}>
              <></>
            </View>
            <View
              style={[
                bookingRequestMobileCardStyles.valueWrapper,
                bookingRequestMobileCardStyles.actionsWrapper,
              ]}
            >
              {actions.render(
                handleReview,
                loadingApproveRequest,
                loadingDeclineRequest,
              )}
            </View>
          </View>
        )}
        {decliningDialog}
      </View>
    </Card>
  );
};

interface Request {
  id: string;
  data: Row[];
  actions?: Actions;
}

interface BookingRequestsMobilePropsType {
  requests: Request[];
}

export const BookingRequestsMobile: React.FC<
  BookingRequestsMobilePropsType
> = ({ requests }) => {
  return (
    <View>
      {requests.map(({ id, data, actions }) => (
        <View key={id}>
          <BookingRequestMobileCard
            requestId={id}
            rows={data}
            actions={actions}
          />
          <Spacer size={24} />
        </View>
      ))}
    </View>
  );
};

const bookingRequestMobileCardStyles = StyleSheet.create({
  rowWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
  labelWrapper: {
    width: "40%",
  },
  valueWrapper: {
    flex: 1,
  },
  actionsWrapper: {
    marginTop: 16,
  },
  cardContent: {
    padding: 16,
  },
});
