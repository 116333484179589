import * as Sentry from "@sentry/react";
import { Auth0Error, WebAuth } from "auth0-js";
import { Spinner } from "components/spinner";
import { useInterval } from "hooks/use_interval";
import jwtDecode from "jwt-decode";
import { logger } from "lib/logger";
import { postAuthRedirectUrl } from "providers/auth_loader";
import { useConfig } from "providers/config";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { useHistory } from "react-router-dom";

interface AuthProviderV2Props {
  children: React.ReactNode;
}

export interface AuthContext {
  getAccessToken: () => Promise<string>;
  logout: () => void;
  logInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  signUpWithEmailAndPassword: (
    fullName: string,
    email: string,
    password: string,
  ) => Promise<void>;
  logInWithGoogle: () => void;
  logInWithOkta: (connection: string) => void;
  signUpWithGoogle: () => void;
  continueWithGoogleCalendar: () => void;
  startPasswordless: (email: string) => Promise<void>;
  verifyPasswordless: (
    email: string,
    verificationCode: string,
  ) => Promise<void>;
  accessToken: string;
  authenticated: boolean;
  resetPassword: (email: string) => Promise<any>;
}

export const AuthContext = createContext<AuthContext>({
  getAccessToken: async () => "",
  logout: () => {},
  logInWithEmailAndPassword: async () => {},
  signUpWithEmailAndPassword: async () => {},
  logInWithGoogle: async () => {},
  logInWithOkta: async () => {},
  signUpWithGoogle: async () => {},
  continueWithGoogleCalendar: async () => {},
  startPasswordless: async () => {},
  verifyPasswordless: async () => {},
  accessToken: "",
  authenticated: false,
  resetPassword: async () => {},
});

export const POST_AUTHENTICATION_KEY = "POST_AUTHENTICATION_KEY";

const GOOGLE_CALENDAR_SCOPE = "https://www.googleapis.com/auth/calendar";

export function AuthProviderV2(props: AuthProviderV2Props) {
  const { children } = props;
  const { state, getAccessToken } = useAuthState();
  const {
    logout,
    logInWithEmailAndPassword,
    signUpWithEmailAndPassword,
    startPasswordless,
    verifyPasswordless,
    logInWithGoogle,
    logInWithOkta,
    signUpWithGoogle,
    continueWithGoogleCalendar,
    resetPassword,
  } = useAuthFunctions();

  if (state.loading) {
    return (
      <View style={{ paddingVertical: 120 }}>
        <Spinner />
      </View>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: state.authenticated,
        accessToken: state.accessToken,
        getAccessToken,
        logout,
        logInWithEmailAndPassword,
        signUpWithEmailAndPassword,
        startPasswordless,
        verifyPasswordless,
        logInWithGoogle,
        logInWithOkta,
        signUpWithGoogle,
        continueWithGoogleCalendar,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthV2() {
  return useContext(AuthContext);
}

function useWebAuth() {
  const config = useConfig();
  const webAuth = useMemo(
    () =>
      new WebAuth({
        clientID: config.auth0ClientId,
        domain: config.auth0Domain,
        audience: config.auth0ApiIdentifier,
        redirectUri: `${window.location.origin}/callback`,
        responseType: "token",
      }),
    [config],
  );

  return webAuth;
}

const refreshTokenIntervalInMs = 1000 * 60 * 30; // 30min

function useAuthState() {
  const webAuth = useWebAuth();
  const history = useHistory();
  const [state, setState] = useState({
    error: "",
    authenticated: false,
    // Access token is used to communicate with GraphQL API which is authenticated with Auth0 API Application
    // It is not used for maintaining authentication/session on the browser!
    // Access token is renewed at 2 times
    // - when entering the application
    // - with refresh interval
    accessToken: "",
    loading: true,
  });

  // hash is appended when is redirected back to the app after auth0 signup/login
  const isParsingHash = !!window.location.hash;

  useEffect(() => {
    if (isParsingHash) {
      logger.debug(
        "[AuthV2] parsing hash after auth0 login/signup (redirected back to the app)",
      );
      webAuth.parseHash((error, result) => {
        if (error) {
          logger.debug("[AuthV2] parsed hash error", error);
          captureAuth0Error(error);
          return;
        }
        logger.debug("[AuthV2] parsed hash", result);
        if (!result?.accessToken) {
          logger.debug("[AuthV2] missing access token from hash");
          return;
        }
        localStorage.setItem(POST_AUTHENTICATION_KEY, "true");
        history.replace(postAuthRedirectUrl.get() || "/");
      });
    }
  }, [webAuth, history, isParsingHash]);

  // Get access token every interval stored in state and get a new one if it is close to expiry
  const getAccessToken = useCallback(async () => {
    if (state.accessToken) {
      const expired = isAccessTokenCloseToExpiry(state.accessToken);

      logger.debug("[AuthV2] access token is close to expiry");
      if (!expired) {
        return state.accessToken;
      }
    } else {
      logger.debug("[AuthV2] missing access token");
    }

    return new Promise<string>((resolve) => {
      logger.debug("[AuthV2] getting access token from auth0");

      webAuth.checkSession({}, async (error, result) => {
        if (error) {
          logger.debug("[AuthV2][getAccessToken] checkSession error", error);
          captureAuth0Error(error);
          resolve("");
          setState({
            authenticated: false,
            error: error.description || error.error_description || "",
            accessToken: "",
            loading: false,
          });
          return;
        }

        setState({
          authenticated: true,
          error: "",
          accessToken: result.accessToken,
          loading: false,
        });

        resolve(result.accessToken);
      });
    });
  }, [state.accessToken, webAuth]);

  // Refresh access token every interval
  useInterval(() => {
    webAuth.checkSession({}, async (error, result) => {
      if (error) {
        logger.debug("[AuthV2][useInterval] checkSession error", error);
        captureAuth0Error(error);
        return;
      }

      setState({
        authenticated: true,
        error: "",
        accessToken: result.accessToken,
        loading: false,
      });
    });
  }, refreshTokenIntervalInMs);

  useEffect(() => {
    webAuth.checkSession({}, (error, result) => {
      if (error) {
        logger.debug("[AuthV2][useEffect] checkSession error", error);
        captureAuth0Error(error);
        setState({
          error: error.description || error.code || error.error,
          authenticated: false,
          accessToken: "",
          loading: false,
        });
        return;
      }

      setState({
        authenticated: true,
        error: "",
        accessToken: result.accessToken,
        loading: false,
      });
    });
  }, [webAuth]);

  return { state, getAccessToken };
}

function useAuthFunctions() {
  const webAuth = useWebAuth();

  const logInWithEmailAndPassword = useCallback(
    (email: string, password: string) => {
      return new Promise<void>((resolve, reject) => {
        webAuth.login(
          {
            email,
            password,
            realm: "Username-Password-Authentication",
          },
          (error) => {
            if (error) {
              logger.debug("[AuthV2][logInWithEmailAndPassword] error", error);
              captureAuth0Error(error);
              reject(error);
              return;
            }

            resolve();
          },
        );
      });
    },
    [webAuth],
  );

  // Usually after sign up we want to login user immediately
  // hence we include login after signing up
  const signUpWithEmailAndPassword = useCallback(
    (fullName: string, email: string, password: string) => {
      return new Promise<void>((resolve, reject) => {
        webAuth.signup(
          {
            email,
            password,
            // @ts-ignore: there is such field https://auth0.github.io/auth0.js/WebAuth.html
            name: fullName,
            connection: "Username-Password-Authentication",
          },
          (error) => {
            if (error) {
              logger.debug("[AuthV2][signUpWithEmailAndPassword] error", error);
              captureAuth0Error(error);
              reject(error);
            }
            webAuth.login(
              {
                email,
                password,
                realm: "Username-Password-Authentication",
                state: "login",
              },
              (error) => {
                if (error) {
                  logger.debug(
                    "[AuthV2]login after signUpWithEmailAndPassword error",
                    error,
                  );
                  captureAuth0Error(error);
                  reject(error);
                  return;
                }
                resolve();
              },
            );
          },
        );
      });
    },
    [webAuth],
  );

  const startPasswordless = useCallback(
    (email: string) => {
      return new Promise<void>((resolve, reject) => {
        webAuth.passwordlessStart(
          {
            connection: "email",
            send: "code",
            email,
          },
          (error) => {
            if (error) {
              logger.debug("[AuthV2][passwordlessStart] error", error);
              captureAuth0Error(error);
              reject(error);
              return;
            }

            resolve();
          },
        );
      });
    },
    [webAuth],
  );

  const verifyPasswordless = useCallback(
    (email: string, verificationCode: string) => {
      return new Promise<void>((resolve, reject) => {
        webAuth.passwordlessLogin(
          {
            connection: "email",
            verificationCode: verificationCode,
            email,
          },
          (error) => {
            if (error) {
              logger.debug("[AuthV2][verifyPasswordless] error", error);
              captureAuth0Error(error);
              reject(error);
              return;
            }

            resolve();
          },
        );
      });
    },
    [webAuth],
  );

  const signUpWithGoogle = useCallback(() => {
    return webAuth.authorize({
      connection: "google-oauth2",
      state: "signup",
    });
  }, [webAuth]);

  const continueWithGoogleCalendar = useCallback(() => {
    return webAuth.authorize({
      approvalPrompt: "force",
      connection: "google-oauth2",
      accessType: "offline",
      connection_scope: GOOGLE_CALENDAR_SCOPE,
    });
  }, [webAuth]);

  const logInWithGoogle = useCallback(() => {
    return webAuth.authorize({
      connection: "google-oauth2",
      state: "login",
    });
  }, [webAuth]);

  const logInWithOkta = useCallback(
    (connection) => {
      return webAuth.authorize({
        connection,
        state: "login",
      });
    },
    [webAuth],
  );

  const logout = useCallback(() => {
    webAuth.logout({ returnTo: "" });
  }, [webAuth]);

  function resetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      webAuth.changePassword(
        {
          email,
          connection: "Username-Password-Authentication",
        },
        (error, result) => {
          if (error) {
            logger.debug("[AuthV2][resetPassword] error", error);
            captureAuth0Error(error);
            reject(error);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  return {
    logInWithGoogle,
    logInWithOkta,
    logInWithEmailAndPassword,
    signUpWithEmailAndPassword,
    signUpWithGoogle,
    continueWithGoogleCalendar,
    startPasswordless,
    verifyPasswordless,
    logout,
    resetPassword,
  };
}

export function useAccessToken() {
  const { accessToken: accessTokenV2 } = useAuthV2();
  const accessTokenRef = useRef(accessTokenV2);

  // This is a hack that should deem unnecessary when we want to switch HTTP client
  // The reason we need to do this is because, SWR does not update its fetcher when new access token is generated.
  // This hack ensures that the accessTokenV1 is not stale.
  useEffect(() => {
    accessTokenRef.current = accessTokenV2;
  }, [accessTokenV2]);

  return accessTokenRef;
}

const expirationThresholdInMs = 7200000; // 2 hours;

function isAccessTokenCloseToExpiry(accessToken: string): boolean {
  const decodedJwtToken = jwtDecode<{ exp: number }>(accessToken);
  const expiryDate = decodedJwtToken.exp;
  const timeToExpireInMs = expiryDate * 1000 - Date.now();

  return timeToExpireInMs < expirationThresholdInMs;
}

function captureAuth0Error(auth0Error: Auth0Error) {
  logger.debug("[AuthV2] auth0 error", auth0Error);
  if (auth0Error.code === "login_required") {
    return;
  }

  Sentry.withScope((scope) => {
    scope.setExtra("auth0Error", auth0Error);

    const error = new Error(
      `[Auth0 error]: ${
        auth0Error.description ||
        auth0Error.errorDescription ||
        auth0Error.error_description
      }`,
    );

    Sentry.captureException(error, scope);
  });
}
