import { DESKTOP_APP_HEADER_HEIGHT } from "components/app_header_v3/app_header";
import { colors } from "components/colors";
import { Divider } from "components/divider";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/icon";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { useMediaQuery } from "lib/media_query";
import { SearchOption } from "pages/homev2/components/search_bar_option";
import { useForwardGeocoding } from "pages/homev2/hooks/use_forward_geocoding";
import { useSearchHistory } from "pages/homev2/hooks/use_search_history";
import { MapboxFeature } from "pages/homev2/mapbox";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import "./search_input.css";

interface State {
  initialized: boolean;
  showAutocomplete: boolean;
  focused: boolean;
  search: string;
  selectedItemIndex: number;
}

const initialState: State = {
  initialized: false,
  showAutocomplete: false,
  focused: false,
  search: "",
  selectedItemIndex: 0,
};

type LocationSearchInputProps = {
  getPlace: (place: MapboxFeature) => void;
  initialSearch?: State;
};

export function LocationSearchInput({ getPlace }: LocationSearchInputProps) {
  const { searchHistory, addToSearchHistory } = useSearchHistory();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [state, setState] = useState<State>(initialState);
  const { search, focused, showAutocomplete, selectedItemIndex } = state;

  const { data: autocompleteOptions, loading: autocompleteLoading } =
    useForwardGeocoding(search, {
      skip: !showAutocomplete,
    });
  const mq = useMediaQuery();

  const handleSelectPlace = useCallback(
    (place: MapboxFeature) => {
      addToSearchHistory(place);
      setState((prevState) => ({
        ...prevState,
        search: place.text,
        showAutocomplete: false,
        focused: false,
        selectedItemIndex: 0,
      }));
      getPlace(place);
    },
    [addToSearchHistory, getPlace],
  );

  const handleSearchChange = useCallback(
    (nextSearch: string) => {
      setState((prevState) => ({
        ...prevState,
        search: nextSearch,
        showAutocomplete: true,
        selectedItemIndex: 0,
      }));
    },
    [setState],
  );

  const handleFocus = useCallback(() => {
    setState((prevState) => ({ ...prevState, focused: true }));
    inputRef.current?.select();
  }, [setState]);

  const handleBlur = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      focused: false,
      selectedItemIndex: 0,
    }));
  }, [setState]);

  const showingAutocomplete = showAutocomplete && search?.length;

  const onKeyPress = useCallback(
    (e) => {
      if (e.key === "Escape") {
        e.target?.blur?.();
        handleBlur();
      } else if (e.key === "Enter") {
        if (showingAutocomplete && autocompleteOptions?.[selectedItemIndex]) {
          handleSelectPlace(autocompleteOptions?.[selectedItemIndex]);
        } else {
          handleSelectPlace(searchHistory?.[selectedItemIndex - 2]);
        }
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        const searchResults = showingAutocomplete
          ? autocompleteOptions
          : [...searchHistory, null, null];
        setState((prevState) => ({
          ...prevState,
          selectedItemIndex:
            selectedItemIndex < searchResults?.length! - 1
              ? selectedItemIndex + 1
              : 0,
        }));
        return;
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        const searchResults = showingAutocomplete
          ? autocompleteOptions
          : [...searchHistory, null, null];
        setState((prevState) => ({
          ...prevState,
          selectedItemIndex:
            selectedItemIndex > 0
              ? selectedItemIndex - 1
              : searchResults?.length! - 1,
        }));
        return;
      }
    },
    [
      autocompleteOptions,
      handleBlur,
      handleSelectPlace,
      searchHistory,
      selectedItemIndex,
      showingAutocomplete,
    ],
  );

  useEffect(() => {
    if (focused && document.activeElement !== inputRef.current) {
      inputRef.current?.select();
    }
  }, [focused]);

  const renderedSearchResult = showingAutocomplete ? (
    autocompleteLoading ? (
      <Spinner />
    ) : autocompleteOptions && autocompleteOptions?.length > 0 ? (
      autocompleteOptions?.map((place, i) => (
        <Fragment key={`${place.id}-autocomplete`}>
          {i > 0 && (
            <View style={{ paddingHorizontal: 16 }}>
              <Divider />
            </View>
          )}
          <SearchOption
            key={place.id}
            place={place}
            onPress={handleSelectPlace}
            isSelected={selectedItemIndex === i}
          />
        </Fragment>
      ))
    ) : (
      <View style={styles.emptySearch}>
        <Text size="xs" weight="semibold">
          Hmm...we can’t find this location.
        </Text>
        <Spacer size={8} />
        <Text size="xs">
          Try double-checking your spelling or broadening your search to a city
          or region.
        </Text>
      </View>
    )
  ) : (
    <View>
      {searchHistory.length > 0 && (
        <>
          <View style={{ padding: 16, paddingBottom: 8 }}>
            <Text size="xs" customColor="#000" weight="semibold">
              Recent searches
            </Text>
          </View>
          {searchHistory.map((place, i) => (
            <Fragment key={`${place.id}-search-history`}>
              {i > 0 && (
                <View style={{ paddingHorizontal: 16 }}>
                  <Divider color="dark" />
                </View>
              )}
              <SearchOption
                key={place.id}
                place={place}
                onPress={handleSelectPlace}
                isSelected={selectedItemIndex === i + 2}
              />
            </Fragment>
          ))}
        </>
      )}
    </View>
  );

  const renderedTextField = focused ? (
    <TextField
      onKeyPress={onKeyPress}
      value={search}
      ref={inputRef}
      onFocus={handleFocus}
      onChange={handleSearchChange}
      leftIcon={<Icon name="magnifying-glass" />}
      rightIcon={
        !!search?.length &&
        document?.activeElement === inputRef.current && (
          <Icon size="sm" color={colors.brand.blackMinus30} name="x-circle" />
        )
      }
      onRightIconClick={() => {
        handleSearchChange("");
        inputRef.current?.focus();
      }}
      testID="location-proximity-search-bar-input"
    />
  ) : (
    <DisplayText
      onPress={handleFocus}
      searchText={search || "Search for a street address or business name"}
      testID="location-proximity-search-bar"
    />
  );

  if (mq.deviceQuery.mobile) {
    return (
      <>
        {renderedTextField}
        <View style={{ marginHorizontal: -16 }}>{renderedSearchResult}</View>
      </>
    );
  }

  return (
    <View style={styles.container}>
      {focused && (
        <div
          onClick={handleBlur}
          className="search_input__backdrop"
          style={{ top: DESKTOP_APP_HEADER_HEIGHT }}
        />
      )}
      <DropdownV2
        open={focused}
        onRequestClose={handleBlur}
        sameWidth={false}
        sameMinWidth={true}
        content={
          <View style={styles.dropdownContent}>{renderedSearchResult}</View>
        }
      >
        {renderedTextField}
      </DropdownV2>
    </View>
  );
}

interface DisplayTextProps {
  searchText: string;
  onPress: () => void;
  testID?: string;
}

const DisplayText = ({ searchText, onPress, testID }: DisplayTextProps) => {
  const mq = useMediaQuery();
  return (
    <Pressable testID={testID} onPress={onPress}>
      <View
        style={[
          styles.displayTextContainer,
          mq.deviceQuery.mobile && styles.displayTextContainerMobile,
        ]}
      >
        <Icon name="magnifying-glass" />
        <Spacer direction="row" size={12} />
        <View style={styles.searchText}>
          <Text numberOfLines={1} size="xs">
            {searchText}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 540,
  },
  displayTextContainer: {
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 40,
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  displayTextContainerMobile: {
    width: "100%",
  },
  searchText: {
    flex: 1,
  },
  dropdownContent: {
    overflow: "scroll",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: tokens.colors.base.white,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    paddingVertical: 8,
    minWidth: 400,
  },
  mobileBtnWrapper: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
    minWidth: 0,
    flexShrink: 1,
    maxWidth: "70%",
  },
  mobileBtnAmenityWrapper: {
    width: "100%",
    maxWidth: "100%",
  },
  mobileBtnTextWrapper: {
    minWidth: 0,
    flexShrink: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  emptySearch: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
