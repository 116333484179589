import { StyleSheet, View } from "react-native";

import { Icon } from "components/iconv2";
import { Text } from "components/text_v2";
import { colors } from "components/colors";

interface UpcomingBookingTagProps {
  capacity: number;
}

export function UpcomingBookingTag({ capacity }: UpcomingBookingTagProps) {
  return (
    <View style={styles.root}>
      <Icon
        name={capacity > 1 ? "team" : "me"}
        size="sm"
        filled
        color="blackcore"
      />
      <Text size="micro" weight="semibold">
        {capacity > 1 ? "Group booking" : "Solo booking"}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 12,
    borderRadius: 4,
    backgroundColor: colors.brand.eggplantMinus90,
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    width: "fit-content",
  },
});
