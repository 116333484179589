import { useState } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";

import { Button } from "components/button_v2";
import { useToast } from "providers/toast";
import {
  ReactivateGroupBudgetMutation,
  ReactivateGroupBudgetMutationVariables,
} from "core/graphql.generated";

import {
  CreateEditBudgetFormErrors,
  GroupBudgetFormValues,
} from "../group_budget_create";
import { MovePeopleWaringModal } from "./add_budget_members";
import { isNotEmpty } from "lib/array_utils";
import { useAnalytics } from "providers/analytics";

interface ReactivateBudgetButtonContainerPropTypes {
  groupBudgetId: string;
  values: GroupBudgetFormValues;
  disabled: boolean;
  isMobile: boolean;
  formValidator: () => CreateEditBudgetFormErrors;
}

export function ReactivateBudgetButtonContainer({
  groupBudgetId,
  values,
  disabled,
  isMobile,
  formValidator,
}: ReactivateBudgetButtonContainerPropTypes) {
  const apolloClient = useApolloClient();
  const analytics = useAnalytics();
  const movedMembers = values.members?.filter((m) => !!m.groupBudgetRole) || [];
  const movedManagers =
    values.managers?.filter((m) => !!m.groupBudgetRole) || [];
  const movedPeople = movedMembers.concat(movedManagers);

  const toast = useToast();

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isWarningModalLoading, setIsWarningModalLoading] = useState(false);

  const [reactivateGroupBudget, { loading: reactivateGroupBudgetLoading }] =
    useMutation<
      ReactivateGroupBudgetMutation,
      ReactivateGroupBudgetMutationVariables
    >(reactivateGroupBudgetMutation);

  const reactivate = async () => {
    try {
      const res = await reactivateGroupBudget({
        variables: {
          input: {
            id: groupBudgetId,
            limit: values.limit,
            individualLimit: values.individualLimit,
            name: values.name,
            description: values.description,
            managerIds: values.managers?.map((manager) => manager.id) || [],
            memberIds: values.members?.map((member) => member.id) || [],
            policyId: values.policyId,
          },
        },
      });

      const reactivatedBudget = res.data?.reactivateGroupBudget;

      analytics.event("Reactivate Group Budget", {
        Name: reactivatedBudget?.name,
        Description: reactivatedBudget?.description,
        "Monthly Limit": reactivatedBudget?.limit,
        "Individual Limit": reactivatedBudget?.individualLimit,
        "Policy Name": reactivatedBudget?.policy?.name,
        "Members Count": values.members?.length,
        "Managers Count": values.managers?.length,
      });

      toast.notify({
        message: "Budget has been successfully reactivated and updated",
      });

      await apolloClient.resetStore();
    } catch (e) {
      toast.notify({
        message: "There has been an error when reactivating the budget",
      });
    }
  };

  const handleClickSave = () => {
    const errors = formValidator();

    const hasErrors = isNotEmpty(Object.values(errors));
    if (movedPeople.length > 0 && !hasErrors) {
      setIsWarningModalOpen(true);
    } else if (hasErrors) {
      return;
    } else {
      reactivate();
    }
  };

  const handleConfirm = () => {
    setIsWarningModalOpen(false);
    reactivate();
  };

  return (
    <>
      <Button
        text={isMobile ? "Save" : "Save and reactivate budget"}
        appearance={isMobile ? "textLink" : "primary"}
        testID="group-budget-save-button"
        onPress={handleClickSave}
        disabled={disabled}
        loading={reactivateGroupBudgetLoading || isWarningModalLoading}
      />

      {isWarningModalOpen && (
        <MovePeopleWaringModal
          onCancel={() => setIsWarningModalOpen(false)}
          onConfirm={handleConfirm}
          people={movedPeople}
          onRequest={(loading) => setIsWarningModalLoading(loading)}
        />
      )}
    </>
  );
}

const reactivateGroupBudgetMutation = gql`
  mutation ReactivateGroupBudget($input: ReactivateGroupBudgetInput!) {
    reactivateGroupBudget(input: $input) {
      id
      name
      description
      limit
      individualLimit
      status
      policy {
        id
        name
      }
    }
  }
`;
