import { gql, useQuery } from "@apollo/client";
import { Avatar } from "components/avatar";
import { UserAvatarQuery } from "core/graphql.generated";
import { urlLocationSearchState } from "pages/homev2/utils/url_location_search_atom";
import { signInModalState } from "providers/app_loader";
import {
  useOpenDirectoryFeatureFlag,
  usePostBookingsFeatureFlag,
} from "providers/splitio";

import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import "./bottom_bar_navigation.css";
import { BottomBarNavigationButton } from "./bottom_bar_navigation_button";
import { ROUTES } from "./routes";

export const BOTTOM_BAR_HEIGHT = 72;

export function BottomBarNavigation() {
  const postBookingFeatureFlag = usePostBookingsFeatureFlag();
  const { homeUrl } = useRecoilValue(urlLocationSearchState);
  const { data: currentUserQuery } = useQuery<UserAvatarQuery>(userAvatarQuery);
  const currentUser = currentUserQuery?.currentUser;
  const [, setShowLoginModal] = useRecoilState(signInModalState);
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  const onPress = () => {
    if (!currentUser && openDirectoryFeatureFlag) {
      setShowLoginModal(true);
    }
  };
  return (
    <div className="bottom-bar-navigation-content">
      <BottomBarNavigationButton icon="home" label="Home" to={homeUrl} />
      <BottomBarNavigationButton
        icon="bookings"
        label="Bookings"
        to={
          postBookingFeatureFlag
            ? ROUTES.BOOKINGS.path
            : ROUTES.RESERVATIONS.path
        }
      />

      <BottomBarNavigationButton icon="team" label="Team" to="/team-spaces" />

      {currentUser && (
        <BottomBarNavigationButton
          icon="chatQuestion"
          label="Help"
          to="/help"
        />
      )}

      <BottomBarNavigationButton
        icon={currentUser ? "me" : "profile-picture"}
        label={currentUser ? "Me" : "Log in"}
        onPress={onPress}
        to={currentUser ? ROUTES.USER_PROFILE.path : ""}
        dynamicIcon={
          <Avatar
            size="md"
            name={currentUser?.fullName}
            source={{ uri: currentUser?.picture }}
            appearance={"outline"}
          />
        }
      />
    </div>
  );
}

const userAvatarQuery = gql`
  query UserAvatar {
    currentUser {
      id
      picture
      fullName
    }
  }
`;
