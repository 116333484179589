import { View } from "react-native";
import ContentLoader, { Rect } from "react-content-loader/native";

import { BookingTab } from "./booking_tabs";
import { useMediaQuery } from "lib/media_query";
import { Spacer } from "components/spacer";

interface BookingSkeletonProps {
  tab: BookingTab;
}

const UPCOMING_DESKTOP_HEIGHT = 292;
const UPCOMING_MOBILE_HEIGHT = 410;
const ARCHIVE_DESKTOP_HEIGHT = 120;
const ARCHIVE_MOBILE_HEIGHT = 350;

export function BookingSkeleton({ tab }: BookingSkeletonProps) {
  const mq = useMediaQuery();

  let skeletonHeight = UPCOMING_DESKTOP_HEIGHT;
  if (tab === BookingTab.Upcoming && mq.deviceQuery.mobile) {
    skeletonHeight = UPCOMING_MOBILE_HEIGHT;
  } else if (tab === BookingTab.Archive) {
    skeletonHeight = ARCHIVE_DESKTOP_HEIGHT;
    if (mq.deviceQuery.mobile) {
      skeletonHeight = ARCHIVE_MOBILE_HEIGHT;
    }
  }

  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={skeletonHeight}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height={skeletonHeight} />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={skeletonHeight}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height={skeletonHeight} />
      </ContentLoader>
    </View>
  );
}
