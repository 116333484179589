import { DataTableRow } from "../data_table_base";

export const searchSortRows = (
  rows: DataTableRow[],
  currentSort: string,
  searchInput: string,
  searchableBy: string[] | undefined = [],
) => {
  let result = [...rows];

  if (searchInput && searchableBy?.length) {
    result = rows.filter(
      (row: DataTableRow) =>
        !!searchableBy.find((searchableField: string) =>
          (row[searchableField]?.value ?? row[searchableField])
            ?.toLowerCase()
            ?.includes(searchInput.toLowerCase()),
        ),
    );
  }

  if (currentSort) {
    result = result.sort((row1: DataTableRow, row2: DataTableRow) =>
      String(row1[currentSort]?.value ?? row1[currentSort]).toLowerCase() >
      String(row2[currentSort]?.value ?? row2[currentSort]).toLowerCase()
        ? 1
        : -1,
    );
  }

  return result;
};
