import { useMediaQuery } from "lib/media_query";

import { BookingDetailsDesktop } from "./booking_details_desktop";
import { BookingDetailsMobile } from "./booking_details_mobile";
import { BookingDetailsMobileComponents } from "./booking_details_content_mobile";

export type BookingDetailsPageComponents = BookingDetailsMobileComponents;

interface BookingDetailsLayoutProps {
  children: JSX.Element;
}

export function BookingDetailsLayout({ children }: BookingDetailsLayoutProps) {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <BookingDetailsMobile>{children}</BookingDetailsMobile>;
  }

  return <BookingDetailsDesktop>{children}</BookingDetailsDesktop>;
}
