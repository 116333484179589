interface GetMonthlyLimitInput {
  groupBudgetgIndividualLimit?: number;
  organizationIndividualLimit?: number;
  groupBudgetLimit?: number;
  organizationLimit: number;
}

export const getMonthlyLimit = ({
  groupBudgetgIndividualLimit,
  organizationIndividualLimit,
  groupBudgetLimit,
  organizationLimit,
}: GetMonthlyLimitInput): number => {
  if (groupBudgetgIndividualLimit) {
    return groupBudgetgIndividualLimit;
  } else if (organizationIndividualLimit) {
    return organizationIndividualLimit;
  } else if (groupBudgetLimit) {
    return groupBudgetLimit;
  } else {
    return organizationLimit;
  }
};
