import { Button } from "components/button_v2";
import { Text } from "components/text_v2";
import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";

interface Props {
  refetchFunc?: () => void;
}

const SearchErrorContainer = (props: Props) => {
  const { refetchFunc } = props;
  const mq = useMediaQuery();

  return (
    <View
      style={[
        styles.container,
        mq.deviceQuery.mobile && styles.containerMobile,
      ]}
    >
      <View style={[styles.innerContainer]}>
        <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
          We had problems finding spaces for you
        </Text>
        <Spacer size={8} />
        <Text size="xs">Please try again by refreshing the browser.</Text>
        <Spacer size={24} />
        <View
          style={[styles.button, mq.deviceQuery.mobile && styles.buttonMobile]}
        >
          <Button text="Retry finding spaces" onPress={refetchFunc} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  containerMobile: {
    width: "100%",
  },
  innerContainer: {
    marginTop: 24,
    padding: 24,
    backgroundColor: colors.brand.eggplantMinus90,
  },
  button: {
    width: 240,
  },
  buttonMobile: {
    width: "100%",
  },
});

export { SearchErrorContainer };
