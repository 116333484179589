import * as Sentry from "@sentry/react";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";
import { gql } from "@apollo/client";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";

import { useMediaQuery } from "lib/media_query";
import { Button } from "components/button_v2";
import { EmptyStateGroupBTile } from "./empty_state_tiles";
import {
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
  LocationsFilter,
  RecommendedLocationListQuery,
  RecommendedLocationListQueryVariables,
  RecommendedSpaceListQuery,
  RecommendedSpaceListQueryVariables,
  SpacesFilter,
} from "core/graphql.generated";
import { Fragment, useCallback } from "react";
import { SpaceListCards } from "../components/space_list_cards";
import { Divider } from "components/divider";
import { AddBusinessDays } from "lib/date_utils";
import { useCurrentUserGeoCoordinatesQuery } from "../hooks/use_current_user_geo_coordinates";
import { GridV2 } from "components/gridv2";
import { HomePageLoadingComponent } from "../components/home_page_loading_component";
import { EmptyStateWithDateAndCapacityOrAmenities } from "./empty_state_with_date_and_capacity_or_amenities";
import { format } from "date-fns";
import { today } from "lib/day_utils";
import { useLazyQueryWithRetry } from "hooks/use_lazy_query_with_retry";
import { logger } from "lib/logger";

interface EmptyStateWithPartialMatchProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (location: HomePage__LocationDetailsFragment) => void;
  title: string;
  noSpaceTestID: string;
  fewSpacesTestID: string;
  noMatchWithRecommendationTestID: string;
  onFindMeASpacePress?: () => void;
}

export function EmptyStateWithPartialMatch(
  props: EmptyStateWithPartialMatchProps,
) {
  const {
    spaces,
    onPreviewLocation,
    title,
    noSpaceTestID,
    fewSpacesTestID,
    noMatchWithRecommendationTestID,
    onFindMeASpacePress,
  } = props;

  const mq = useMediaQuery();
  const { spaceType, startTime, endTime, date, coordinates, amenities, sort } =
    useHomeSearchParamsQuery();
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useCurrentUserGeoCoordinatesQuery();

  const spaceCount = spaces.length || 0;
  const skip =
    !coordinates ||
    !spaceType ||
    currentUserLoading ||
    (!date && !amenities) ||
    spaceCount >= 4;
  const MAX_RETRY = 5;

  const { result } = useLazyQueryWithRetry<
    RecommendedSpaceListQuery,
    RecommendedSpaceListQueryVariables
  >({
    maxRetry: MAX_RETRY,
    query: recommendedSpaceListQuery,
    isBreak: (data: RecommendedSpaceListQuery) => {
      if (skip) {
        return true;
      }

      const currentSpaces = data
        ? data?.spaces.data.filter(
            (space) =>
              !spaces.some(
                (currentSpace: { id: string }) => currentSpace.id === space.id,
              ),
          )
        : [];

      const isHasData = !!currentSpaces?.length;

      return isHasData;
    },
    buildVariables: (queryCount) => {
      let spacesFilter: SpacesFilter = {
        date,
        partialMatch: true,
      };

      logger.info(
        `[useLazyQueryWithRetry][RecommendedSpaceListQuery] buildVariables is called`,
      );

      if ((!startTime || !endTime) && date) {
        /**
         * Logic:
         * If the user selects they wanted the space on the 1st,
         * display options that are available +/- X number of days,
         * with options on 2nd and 31st at the top,
         * followed by 3rd and 30th
         */
        const dayArray = [1, -1, 2, -2, 3, -3];
        const days = dayArray[queryCount];

        spacesFilter = {
          ...spacesFilter,
          date: format(AddBusinessDays(new Date(date), days), "yyyy-MM-dd"),
        };
      }

      if (amenities) {
        spacesFilter = {
          ...spacesFilter,
          amenities,
        };
      }

      const variables = {
        centerMapCoordinates: coordinates
          ? {
              lat: coordinates?.lat,
              lng: coordinates?.lng,
            }
          : {},
        currentUserCoordinates: currentUserCoordinates
          ? {
              lat: currentUserCoordinates?.lat,
              lng: currentUserCoordinates?.lng,
            }
          : {},

        filter: spacesFilter,
        spaceType: spaceType!,
        limit: 16,
        offset: 0,
        sort,
        bookingsDate: date || today(),
      };

      return variables;
    },
    queryOptions: {
      onError: (error: any) => {
        logger.info(`[DEBUG] recommended spaces got error`);
        logger.debug(JSON.stringify(error));
        Sentry.captureException(error);
      },
    },
    disabled: !spaceType,
  });

  const { result: locationResult } = useLazyQueryWithRetry<
    RecommendedLocationListQuery,
    RecommendedLocationListQueryVariables
  >({
    maxRetry: MAX_RETRY,
    query: recommendedLocationListQuery,
    isBreak: (data: RecommendedLocationListQuery) => {
      const skip =
        !coordinates ||
        !!spaceType ||
        currentUserLoading ||
        (!date && !amenities) ||
        spaceCount >= 4;

      if (skip) {
        return true;
      }

      const locations = data ? data?.locations : [];
      const isHasData = !!locations?.length;

      logger.info(
        `[RecommendedLocationListQuery] isBreak is called, isHasData: ${isHasData}`,
      );

      return isHasData;
    },
    buildVariables: (queryCount) => {
      let filter: LocationsFilter = {
        date,
        partialMatch: true,
      };

      logger.info(
        `[RecommendedLocationListQuery] buildVariables is called, ${date}`,
      );

      if (date) {
        /**
         * Logic:
         * If the user selects they wanted the space on the 1st,
         * display options that are available +/- X number of days,
         * with options on 2nd and 31st at the top,
         * followed by 3rd and 30th
         */
        const dayArray = [1, -1, 2, -2, 3, -3];
        const days = dayArray[queryCount];

        filter = {
          ...filter,
          date: format(AddBusinessDays(new Date(date), days), "yyyy-MM-dd"),
        };
      }

      if (amenities) {
        filter = {
          ...filter,
          amenities,
        };
      }

      const variables = {
        centerMapCoordinates: {
          lat: coordinates?.lat || currentUserCoordinates?.lat,
          lng: coordinates?.lng || currentUserCoordinates?.lng,
        },
        filter,
      };

      logger.info(
        `[useLazyQueryWithRetry][RecommendedLocationListQuery][buildVariables] variables: ${JSON.stringify(
          variables,
        )}`,
      );

      return variables;
    },
    queryOptions: {
      onError: (error: any) => {
        logger.info(`[DEBUG] recommended locations got error`);
        logger.debug(JSON.stringify(error));
        Sentry.captureException(error);
      },
    },
    disabled: !!spaceType,
  });

  const { data: recommendedSpacesData, loading: recommendedSpacesLoading } =
    result;
  const {
    data: recommendedLocationsData,
    loading: recommendedLocationsLoading,
  } = locationResult;

  const handleHomeSearchParamsChange = useUpdateHomeSearchParamsMutation();

  const recommmendedSpaces = recommendedSpacesData
    ? recommendedSpacesData?.spaces.data.filter(
        (space) => !spaces.some((currentSpace) => currentSpace.id === space.id),
      )
    : [];

  const isRecommendedSpacesHaveSpace = !!recommmendedSpaces?.length;
  const isRecommendedLocationsHaveLocation =
    !!recommendedLocationsData?.locations?.length;
  const isLoading =
    recommendedSpacesLoading ||
    recommendedLocationsLoading ||
    currentUserLoading;
  const isHasRecommendedData = spaceType
    ? isRecommendedSpacesHaveSpace
    : isRecommendedLocationsHaveLocation;

  const handleClearDatePress = useCallback(() => {
    handleHomeSearchParamsChange({
      date: null,
      startTime: null,
      endTime: null,
    });
  }, [handleHomeSearchParamsChange]);

  const handleStartTimePress = useCallback(() => {
    handleHomeSearchParamsChange({
      startTime: null,
      endTime: null,
    });
  }, [handleHomeSearchParamsChange]);

  const handleEndTimePress = useCallback(() => {
    handleHomeSearchParamsChange({
      endTime: null,
    });
  }, [handleHomeSearchParamsChange]);

  const clearAllFilters = useCallback(() => {
    handleHomeSearchParamsChange({
      date: null,
      startTime: null,
      endTime: null,
      minCapacity: null,
      maxCapacity: null,
      amenities: null,
    });
  }, [handleHomeSearchParamsChange]);

  const handleClearAmenitiesPress = useCallback(() => {
    handleHomeSearchParamsChange({
      amenities: null,
    });
  }, [handleHomeSearchParamsChange]);

  if (isLoading) {
    return (
      <GridV2 columns={mq.deviceQuery.mobile ? 1 : 2}>
        <HomePageLoadingComponent />
      </GridV2>
    );
  }

  if (!isHasRecommendedData) {
    return (
      <View testID={noSpaceTestID}>
        <EmptyStateWithDateAndCapacityOrAmenities
          spaces={spaces}
          onPreviewLocation={onPreviewLocation}
          onFindMeASpacePress={onFindMeASpacePress}
        />
      </View>
    );
  }

  if (spaceCount > 0) {
    return (
      <Fragment>
        <SpaceListCards onPreviewLocation={onPreviewLocation} spaces={spaces} />
        <Spacer size={24} />
        <Divider />
        <Spacer size={24} />
        <View testID={fewSpacesTestID}>
          <Text weight="semibold" size="xs">
            No other spaces match all of your filters.
          </Text>
          <Text size="xs">
            <Text onPress={clearAllFilters} color="eggplant-core" size="xs">
              Clear all filters{" "}
            </Text>
            or see our recommendations below.
          </Text>
          <Spacer size={24} />
          {!!recommmendedSpaces && (
            <Fragment>
              <SpaceListCards
                spaces={recommmendedSpaces}
                onPreviewLocation={onPreviewLocation}
              />
              <Spacer size={24} />
            </Fragment>
          )}
          <EmptyStateGroupBTile onFindMeASpacePress={onFindMeASpacePress} />
        </View>
      </Fragment>
    );
  }

  return (
    <View testID={noMatchWithRecommendationTestID}>
      <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
        No exact matches
      </Text>
      <Spacer size={16} />
      <Text size="xs">{title}</Text>
      <Spacer size={16} />
      <Spacer size={16} />
      <View style={styles.button}>
        {!!date && (
          <Button
            onPress={handleClearDatePress}
            appearance="secondary"
            text={"Clear date"}
          />
        )}

        {!!startTime && (
          <Button
            onPress={handleStartTimePress}
            appearance="secondary"
            text={"Clear start time"}
          />
        )}
        {!!endTime && (
          <Button
            onPress={handleEndTimePress}
            appearance="secondary"
            text={"Clear end time"}
          />
        )}

        {!!amenities?.length && (
          <Button
            onPress={handleClearAmenitiesPress}
            appearance="secondary"
            text={"Clear amenities"}
          />
        )}
      </View>

      <Spacer size={24} />
      <Divider />
      <Spacer size={24} />
      {!!isHasRecommendedData && (
        <Fragment>
          <SpaceListCards
            spaces={recommmendedSpaces}
            locations={recommendedLocationsData?.locations}
            onPreviewLocation={onPreviewLocation}
          />
          <Spacer size={24} />
        </Fragment>
      )}
      <EmptyStateGroupBTile
        title="Can’t find what you’re looking for?"
        subTitle="Our team will personally find a space for you, even if they aren't on Flexspace yet."
        onFindMeASpacePress={onFindMeASpacePress}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 8,
  },
});

const recommendedSpaceListQuery = gql`
  query RecommendedSpaceList(
    $spaceType: SpaceType!
    $filter: SpacesFilter
    $centerMapCoordinates: SpaceSearchCoordinates
    $currentUserCoordinates: SpaceSearchCoordinates
    $limit: Int
    $offset: Int
    $sort: SpacesSort
    $bookingsDate: String!
  ) {
    spaces(
      spaceType: $spaceType
      filter: $filter
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      data {
        ...RecommendedSpaceList_Fragment
      }
    }
  }
  fragment RecommendedSpaceList_Fragment on Space {
    id
    name
    currency
    locked
    savedByCurrentUser
    maxCapacity
    amenities {
      id
      name
      iconURL
      code
    }
    location {
      ...RecommendedSpaceListLocationDetail_Fragment
    }
    physicalSpacesCount
    physicalSpaces(first: 1) {
      id
      name
      floor
      withWindow
    }
    images {
      small {
        url
        width
        height
      }
    }
    pricings {
      type
      price
    }
    priceRanges {
      daily {
        min
        max
      }
      hourly {
        min
        max
      }
    }
  }
  fragment RecommendedSpaceListLocationDetail_Fragment on Location {
    id
    name
    timezone
    centerMapDistance: distance(coordinates: $centerMapCoordinates)
    currentUserDistance: distance(coordinates: $currentUserCoordinates)
    amenities {
      id
      name
      iconURL
      code
    }
    bookings(startDate: $bookingsDate, endDate: $bookingsDate) {
      id
      startTime
      endTime
      user {
        id
        userId
        picture
        fullName
        title
        email
      }
    }
    images {
      small {
        url
        width
        height
      }
    }
    address {
      city
      streetAddress
      latitude
      longitude
    }
  }
`;

const recommendedLocationListQuery = gql`
  query RecommendedLocationList(
    $filter: LocationsFilter
    $centerMapCoordinates: SpaceSearchCoordinates
  ) {
    locations(filter: $filter, centerMapCoordinates: $centerMapCoordinates) {
      ...RecommendedLocationList_Fragment
    }
  }
  fragment RecommendedLocationList_Fragment on Location {
    id
    name
    timezone
    savedByCurrentUser
    amenities {
      id
      name
      iconURL
      code
    }
    images {
      small {
        url
        width
        height
      }
    }
    address {
      city
      streetAddress
      latitude
      longitude
    }
  }
`;
