import { gql, useQuery } from "@apollo/client";

import { AsyncRenderer } from "components/AsyncRenderer";
import { PoliciesQuery } from "core/graphql.generated";

import { BudgetPolicySkeleton } from "./budget_policy_sekeleton";
import { PolicyTable } from "./components/policies_table";
import { NoPolicy } from "./no_policy";

export function BudgetsPolicy() {
  const { loading, data, error } = useQuery<PoliciesQuery>(policiesQuery);

  if (
    data?.currentUser?.organization?.policies &&
    data?.currentUser?.organization?.policies.length === 0
  ) {
    return <NoPolicy />;
  }

  return (
    <AsyncRenderer
      loading={loading}
      error={error}
      data={data}
      loadingHandler={() => <BudgetPolicySkeleton />}
    >
      {(data) => {
        const policies = data.currentUser?.organization?.policies || [];
        return <PolicyTable rows={policies} />;
      }}
    </AsyncRenderer>
  );
}

const policiesQuery = gql`
  query Policies {
    currentUser {
      organization {
        policies {
          id
          name
          lastUpdated
        }
      }
    }
  }
`;
