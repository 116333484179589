import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { Pressable, StyleSheet, View } from "react-native";

interface FilterChipProps {
  title: string;
  onPress?: () => void;
  isContentOpen?: boolean;
  testID?: string;
  isSelected?: boolean;
}

export function FilterChip(props: FilterChipProps) {
  const {
    title,
    onPress = () => {},
    isContentOpen,
    testID,
    isSelected,
  } = props;
  const mq = useMediaQuery();
  return (
    <Pressable
      style={[
        styles.container,
        mq.deviceQuery.mobile && styles.containerMobile,
        isContentOpen && styles.contentOpen,
      ]}
      onPress={onPress}
      testID={testID}
    >
      <View style={styles.innerContainer}>
        <Text color={isSelected ? "black-core" : "black-70"} size="micro">
          {title}
        </Text>
        <Spacer direction="row" size={8} />
        <Icon name="chevron-down" />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
    borderRadius: 100,
    backgroundColor: colors.brand.whitecore,
    height: 40,
    padding: 12,
    justifyContent: "center",
    width: "max-content",
  },
  containerMobile: {
    padding: 8,
  },
  innerContainer: {
    borderRadius: 100,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentOpen: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
});
