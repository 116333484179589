import { colors } from "components/colors";
import { DropdownV2 } from "components/dropdown_v2";
import { SelectInputOption } from "components/select/select_input_option";
import { CapacityOption } from "core/header_filter/filter";
import { useAnalytics } from "providers/analytics";
import { Fragment, useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";
import { FilterChip } from "./filter_chip";

export const capacityOptions: CapacityOption[] = [
  { label: "Any", value: { min: 0 } },
  { label: "1-4 attendees", value: { min: 1, max: 4 } },
  { label: "5-9 attendees", value: { min: 5, max: 9 } },
  { label: "10-14 attendees", value: { min: 10, max: 14 } },
  { label: "15-19 attendees", value: { min: 15, max: 19 } },
  { label: "20+ attendees", value: { min: 20 } },
];

export function CapacityFilter() {
  const analytics = useAnalytics();
  const [open, setOpen] = useState(false);
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const { minCapacity } = useHomeSearchParamsQuery();
  const handleChangeCapacity = useCallback(
    (capacityOption: CapacityOption) => {
      if (capacityOption.value.min) {
        updateHomeSearchParams({
          minCapacity: capacityOption.value.min,
          maxCapacity: capacityOption.value.max || null,
        });
      } else {
        updateHomeSearchParams({
          minCapacity: null,
          maxCapacity: null,
        });
      }
      analytics.event("Select Attendees", {
        "Min Capacity": capacityOption.value.min,
        "Max Capacity": capacityOption.value.max,
      });
    },
    [analytics, updateHomeSearchParams],
  );
  const selectedOption = useMemo(
    () => capacityOptions.find((opt) => opt.value.min === minCapacity),
    [minCapacity],
  );

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      content={
        <View style={styles.dropdownContent}>
          {capacityOptions.map((option) => {
            const isSelected = selectedOption?.label === option.label;

            return (
              <Fragment key={option.label}>
                <SelectInputOption
                  selected={isSelected}
                  option={option}
                  testID={`select-option-${option.value.min}`}
                  onSelect={() => {
                    handleChangeCapacity(option);
                    setOpen(false);
                  }}
                  labelProp="label"
                />
              </Fragment>
            );
          })}
        </View>
      }
    >
      <FilterChip
        title={selectedOption?.label || "Attendees"}
        onPress={() => setOpen(true)}
        isContentOpen={open}
        testID="header-capacity-picker"
        isSelected={!!selectedOption}
      />
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dropdownContent: {
    maxHeight: "50vh",
    overflow: "scroll",
    marginTop: 8,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: colors.brand.whitecore,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    width: 160,
  },
});
