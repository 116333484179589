import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";

import {
  GroupBudgetRole,
  OrganizationUser,
  PeoplePickerQuery,
  PeoplePickerQueryVariables,
  PreMovingGroupBudgetPeopleMessageQueryQuery,
  PreMovingGroupBudgetPeopleMessageQueryQueryVariables,
  UpdateGroupBudgetAddMemberMutation,
  UpdateGroupBudgetAddMemberMutationVariables,
} from "core/graphql.generated";
import { logger } from "lib/logger";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { useToast } from "providers/toast";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import {
  AddUserModal,
  OpenModalButton,
  peoplePickerQuery,
} from "../../components/people_picker";

interface MovePeopleWaringModalPropTypes {
  people: OrganizationUser[];
  onCancel: () => void;
  onConfirm: () => void;
  addButtonLoading?: boolean;
  onRequest: (loading: boolean, hasData: boolean) => void;
}

export function MovePeopleWaringModal({
  people,
  onCancel,
  onConfirm,
  onRequest,
  addButtonLoading,
}: MovePeopleWaringModalPropTypes) {
  const mq = useMediaQuery();

  const memberIds: string[] = [];
  const managerIds: string[] = [];
  people.forEach((p) => {
    if (p.groupBudgetRole === "Manager") {
      managerIds.push(p.id);
    }
    if (p.groupBudgetRole === "Member") {
      memberIds.push(p.id);
    }
  });

  const { loading, data, error } = useQuery<
    PreMovingGroupBudgetPeopleMessageQueryQuery,
    PreMovingGroupBudgetPeopleMessageQueryQueryVariables
  >(preMovingGroupBudgetPeopleMessageQuery, {
    variables: { input: { memberIds, managerIds } },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    onRequest(loading, !!data);
  }, [loading, onRequest, data]);

  let modalContent: JSX.Element = <></>;

  if (loading) {
    return null;
  }
  if (error) {
    modalContent = <Text>Error happened. Please try later</Text>;
  }
  if (data?.preMovingGroupBudgetPeopleMessage) {
    const messageList = data?.preMovingGroupBudgetPeopleMessage.map(
      ({ personName, messages, previousBudgetName, newManagerName }) => {
        const warningMessages = messages.map((m) => {
          if (m === "MoveMember") {
            return (
              <Text key={m}>
                Remove their access to spend from the{" "}
                <Text color="black-core" weight="semibold">
                  {previousBudgetName}
                </Text>{" "}
                budget
              </Text>
            );
          }
          if (m === "MoveManager") {
            return (
              <Text key={m}>
                Remove their access to manage the{" "}
                <Text color="black-core" weight="semibold">
                  {previousBudgetName}
                </Text>{" "}
                budget
              </Text>
            );
          }
          if (m === "PromoteToManager" && newManagerName) {
            return (
              <Text key={m}>
                Grant{" "}
                <Text color="black-core" weight="semibold">
                  {newManagerName}
                </Text>{" "}
                manager access to the{" "}
                <Text color="black-core" weight="semibold">
                  {previousBudgetName}
                </Text>{" "}
                budget
              </Text>
            );
          }
          if (m === "DeactivateGroupBudget") {
            return (
              <Text key={m}>
                Deactivate the{" "}
                <Text color="black-core" weight="semibold">
                  {previousBudgetName}
                </Text>{" "}
                budget as they are the only member of that budget
              </Text>
            );
          }
        });
        return (
          <View key={personName}>
            <Spacer size={20} />
            <Text>
              Moving{" "}
              <Text color="black-core" weight="semibold">
                {personName}
              </Text>{" "}
              will:
            </Text>
            {warningMessages.map((wM) => (
              <View key={wM?.key} style={styles.warningContentWrapper}>
                <Text>·</Text>
                <View style={styles.warningMessageWrapper}>{wM}</View>
              </View>
            ))}
          </View>
        );
      },
    );
    modalContent = (
      <View>
        <Text>
          It looks like you’re adding people to this budget that are already in
          other budgets. People can only be in one budget at a time, so moving
          them means they will now only be able to spend from this budget.
        </Text>
        {messageList}
      </View>
    );
  }

  return (
    <DialogModal
      testID="move-people-modal"
      isVisible={true}
      headerTitle="Move people to this budget?"
      showCloseButton={false}
      bottomButtons={
        <View
          style={
            mq.deviceQuery.desktop
              ? styles.confirmActionsDesktop
              : styles.confirmActionsMobile
          }
        >
          <View style={styles.confirmBtn}>
            <Button text="Cancel" appearance="secondary" onPress={onCancel} />
          </View>
          {data?.preMovingGroupBudgetPeopleMessage && (
            <View style={styles.confirmBtn}>
              <Button
                testID="move-people-confirm-button"
                text="Yes, move these people"
                appearance="primary"
                loading={addButtonLoading}
                onPress={onConfirm}
              />
            </View>
          )}
        </View>
      }
    >
      {modalContent}
    </DialogModal>
  );
}

interface AddBudgetMembersPropTypes {
  groupBudgetMembers: { id: string; groupBudgetRole: GroupBudgetRole }[];
  groupId: string;
  onConfirm: () => void;
}

export function AddBudgetMembers({
  groupBudgetMembers,
  groupId,
  onConfirm,
}: AddBudgetMembersPropTypes) {
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const [members, setMembers] = useState<OrganizationUser[]>([]);
  const [isCofirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
  const toast = useToast();

  const { data: peoplePickerQueryResult } = useQuery<
    PeoplePickerQuery,
    PeoplePickerQueryVariables
  >(peoplePickerQuery);

  const [addMemberToGroup] = useMutation<
    UpdateGroupBudgetAddMemberMutation,
    UpdateGroupBudgetAddMemberMutationVariables
  >(updateGroupBudgetAddMemberMutation);

  const fetchedUsers =
    peoplePickerQueryResult?.currentUser?.organization?.users || [];
  const searchList = fetchedUsers.filter(
    (user) => !groupBudgetMembers.find((m) => m.id === user.id),
  );

  const handleConfirmMovePeople = async (
    people: OrganizationUser[],
    onClose?: () => void,
  ) => {
    try {
      setIsAddBtnLoading(true);
      await addMemberToGroup({
        variables: {
          input: {
            id: groupId,
            memberIds: groupBudgetMembers
              .filter((m) => m.groupBudgetRole === GroupBudgetRole.Member)
              .map((m) => m.id)
              .concat(people.map((m) => m.id)),
          },
        },
      });
      setIsAddBtnLoading(false);
      if (onClose) {
        onClose();
      }
      setIsConfirmModalOpen(false);
      analytics.event("Add Group Budget Members", {
        "Members Count": people.length,
      });
      toast.notify({ message: "Members successfully added to budget" });
      onConfirm();
    } catch (error) {
      toast.notify({ message: "Fail to add members" });
      logger.error("Cannot add members", error);
    }
  };

  const renderModal = (
    isModalOpen: boolean,
    onOpenAddBudgetMembersModal: () => void,
    onCloseAddBudgetMembersModal: () => void,
  ) => {
    const handleMembersChanged = async (members: OrganizationUser[]) => {
      if (!members.length) {
        onCloseAddBudgetMembersModal();
        return;
      }
      if (members.find((m) => !!m.groupBudgetRole)) {
        onCloseAddBudgetMembersModal();
        setIsConfirmModalOpen(true);
        setMembers(members);
      } else {
        await handleConfirmMovePeople(members, onCloseAddBudgetMembersModal);
      }
    };

    return (
      <>
        <AddUserModal
          isModalOpen={isModalOpen}
          label="Add budget members"
          value={members}
          searchList={searchList as OrganizationUser[]}
          onChange={handleMembersChanged}
          onClose={onCloseAddBudgetMembersModal}
          isAddBtnLoading={isAddBtnLoading}
          clearSelectedPeople
        />

        {isCofirmModalOpen && (
          <MovePeopleWaringModal
            people={members.filter((m) => !!m.groupBudgetRole)}
            onCancel={() => {
              onOpenAddBudgetMembersModal();
              setIsConfirmModalOpen(false);
            }}
            addButtonLoading={isAddBtnLoading}
            onConfirm={async () => {
              await handleConfirmMovePeople(members);
            }}
            onRequest={() => {}}
          />
        )}
      </>
    );
  };

  return (
    <View>
      <OpenModalButton
        buttonText="Add members"
        renderButton={(openModal) =>
          mq.deviceQuery.mobile ? (
            <Button
              appearance="textLink"
              iconName="userPlus"
              testID="add-budget-members-button"
              onPress={openModal}
              iconSize="md"
            />
          ) : (
            <Button
              text="Add members"
              appearance="secondary"
              iconName="userPlus"
              testID="add-budget-members-button"
              iconPosition="left"
              onPress={openModal}
            />
          )
        }
        renderModal={renderModal}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  confirmActionsDesktop: {
    flexDirection: "row",
    paddingLeft: 8,
    paddingRight: 8,
    width: "100%",
    gap: 24,
  },
  confirmActionsMobile: {
    flexDirection: "column-reverse",
    gap: 8,
  },
  confirmBtn: {
    flex: 1,
  },
  warningContentWrapper: {
    flexDirection: "row",
    marginLeft: 5,
    gap: 5,
  },
  warningMessageWrapper: {
    flex: 1,
  },
});

const preMovingGroupBudgetPeopleMessageQuery = gql`
  query PreMovingGroupBudgetPeopleMessageQuery(
    $input: PreMovingGroupBudgetPeopleInput!
  ) {
    preMovingGroupBudgetPeopleMessage(input: $input) {
      messages
      personName
      previousBudgetName
      newManagerName
    }
  }
`;

export const updateGroupBudgetAddMemberMutation = gql`
  mutation UpdateGroupBudgetAddMember($input: UpdateGroupBudgetInput!) {
    updateGroupBudget(input: $input) {
      id
      members {
        role
        user {
          id
        }
      }
    }
  }
`;
