import { LoggerLevel } from "lib/logger";
import React, { createContext, useContext } from "react";

export const appConfig: Config = {
  apiOrigin: process.env.REACT_APP_API_ORIGIN || "",
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  sentryDSN: process.env.REACT_APP_SENTRY_DSN || "",
  // Create a calendly account and paste your calendly link here for testing
  subscriptionCalendlyLink:
    process.env.REACT_APP_SUBSCRIPTION_CALENDLY_LINK || "",
  gaMeasurementID: process.env.REACT_APP_GA_MEASUREMENT_ID || "",
  hotjarTrackingID: Number(process.env.REACT_APP_HOTJAR_TRACKING_ID) || 0,
  hotjarSnippetVersion:
    Number(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION) || 0,
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
  teamsStripePublishableKey:
    process.env.REACT_APP_TEAMS_STRIPE_PUBLISHABLE_KEY || "",
  splitioBrowserApiKey: process.env.REACT_APP_SPLITIO_BROWSER_API_KEY || "",
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  auth0ApiIdentifier: process.env.REACT_APP_AUTH0_API_IDENTIFIER || "",
  mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN || "",
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || "",
  loggerLevel: (process.env.REACT_APP_LOGGER_LEVEL || "error") as LoggerLevel,
  gtmId: process.env.REACT_APP_GTM_ID || "",
  chromeWebstoreId: process.env.REACT_APP_CHROME_WEBSTORE_ID || "",
  chromeWebstoreUrl: process.env.REACT_APP_CHROME_WEBSTORE_URL || "",
};

const ConfigContext = createContext(appConfig);

interface ConfigProviderProps {
  children: React.ReactNode;
}

export function ConfigProvider(props: ConfigProviderProps) {
  const { children } = props;

  return (
    <ConfigContext.Provider value={appConfig}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  return useContext(ConfigContext);
}

export interface Config {
  apiOrigin: string;
  environment: string;
  sentryDSN: string;
  gaMeasurementID: string;
  hotjarTrackingID: number;
  hotjarSnippetVersion: number;
  stripePublishableKey: string; //TODO: remove after Teams Stripe migration done
  teamsStripePublishableKey: string;
  splitioBrowserApiKey: string;
  auth0Domain: string;
  auth0ClientId: string;
  loggerLevel: LoggerLevel;
  auth0ApiIdentifier: string;
  subscriptionCalendlyLink: string;
  mapboxApiAccessToken: string;
  mixpanelToken: string;
  gtmId: string;
  chromeWebstoreId: string;
  chromeWebstoreUrl: string;
}
