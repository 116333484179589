import { Avatar } from "components/avatar";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { OrgUserDetailsFragment } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";

interface UserProps {
  user: OrgUserDetailsFragment;
}

export function UserInfoV2(props: UserProps) {
  const { user } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  return (
    <View style={isMobile ? styles.containerMobile : styles.container}>
      <Avatar
        name={user.fullName ?? ""}
        size="lg"
        source={{ uri: user.picture ?? "" }}
      />
      <View>
        <View style={styles.name}>
          <Text size="xs" weight={"semibold"}>
            {user.fullName || user.email}
          </Text>
        </View>
        <Spacer size={4} />
        <Text size="xs">{user.title}</Text>
        <Spacer size={4} />
        <Text size="xs">{user.email}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerMobile: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  container: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    flex: 1,
  },
  name: {
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
  },
});
