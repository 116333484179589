import { AdminHeader } from "components/admin_header";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Footer } from "core/footer";
import { DesktopLayout } from "components/layout/desktop_layout";
import { gql, useQuery } from "@apollo/client";
import { Text } from "components/text_v2";
import { NavigationCards } from "./components/navigation_cards";
import {
  TodayCurrentUserQuery,
  TodayPage__RequestDetailsFragment,
} from "core/graphql.generated";
import { RequestCardsDesktop } from "./components/request_cards";
import { RequestCardsSkeletonDesktop } from "./components/request_cards_sekeleton";

interface TodayDesktopProps {
  requests: TodayPage__RequestDetailsFragment[];
  requestsLoading: boolean;
}

export function TodayDesktop(props: TodayDesktopProps) {
  const { requests, requestsLoading } = props;
  const { data } = useQuery<TodayCurrentUserQuery>(todayCurrentUserQuery);

  return (
    <DesktopLayout
      header={<AdminHeader />}
      body={
        <>
          <Spacer size={40} />
          <Heading size="lg">Hi {data?.currentUser?.fullName},</Heading>
          <Spacer size={16} />
          <Text size="xs">
            Review and manage how your organization uses Flexspace so you can
            help your teams succeed.
          </Text>
          <Spacer size={48} />
          {requestsLoading ? (
            <RequestCardsSkeletonDesktop />
          ) : (
            <RequestCardsDesktop requests={requests} />
          )}

          <Spacer size={40} />
          <NavigationCards />
        </>
      }
      footer={<Footer />}
    />
  );
}

export const todayCurrentUserQuery = gql`
  query TodayCurrentUser {
    currentUser {
      id
      fullName
    }
  }
`;
