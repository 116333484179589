import React from "react";
import { ActivityIndicator, Pressable } from "react-native";
import { Icon } from "components/icon";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { useTheme } from "components/theme";

interface CheckinButtonProps {
  onPress: () => void;
  checkedIn: boolean;
  loading: boolean;
  full?: boolean;
}

export function CheckinButton(props: CheckinButtonProps) {
  const { onPress, loading, full, checkedIn } = props;
  const theme = useTheme();

  const disabled = checkedIn || loading || full;

  return (
    <Pressable
      testID={checkedIn ? "checked-in" : "check-in"}
      disabled={disabled}
      onPress={onPress}
    >
      <div
        style={{
          borderRadius: 999,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 8,
          background: disabled ? theme.colors.neutral.darker : theme.gradient,
          display: "flex",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        {loading ? (
          <ActivityIndicator color="white" />
        ) : (
          <Row alignItems="center">
            <Icon color="white" name="checkin" />
            <Spacer size={8} />
            <Text color="contrast">
              {checkedIn ? "Checked in" : "Check in"}
            </Text>
          </Row>
        )}
      </div>
    </Pressable>
  );
}
