import { StyleSheet, View } from "react-native";

import { Spacer } from "components/spacer";
import { Divider } from "components/divider";
import { useMediaQuery } from "lib/media_query";
import { DateRange } from "core/booking_date_range_picker";

import { GetDirections } from "./get_directions";
import { InviteCoworkers } from "./invite_coworkers";
import { ShareThisSpace } from "./share_this_space";
import { UpcomingBookingTag } from "./upcoming_booking_tag";
import { BookingDetails } from "./booking_details";

export interface UpcomingBookingLocation {
  address: string;
  city: string;
  latitude?: number;
  longitude?: number;
}

interface UpcomingBookingDetailsProps {
  orderId: string;
  dateRange: DateRange;
  spaceName: string;
  spaceType: string;
  confirmationId?: string;
  capacity: number;
  remainingCapacity: number;
  invitedPeople: string[];
  location: UpcomingBookingLocation;
  spaceUrl: string;
}

export function UpcomingBookingDetails({
  orderId,
  dateRange,
  spaceName,
  spaceType,
  confirmationId,
  capacity,
  remainingCapacity,
  invitedPeople,
  location,
  spaceUrl,
}: UpcomingBookingDetailsProps) {
  const mq = useMediaQuery();

  return (
    <View style={mq.deviceQuery.mobile ? styles.rootMobile : styles.root}>
      <UpcomingBookingTag capacity={capacity} />
      <Spacer size={8} />
      <BookingDetails
        spaceName={spaceName}
        spaceType={spaceType}
        dateRange={dateRange}
        confirmationId={confirmationId}
      />

      {mq.deviceQuery.mobile ? (
        <>
          <Spacer size={16} />
          <Divider />
          <Spacer size={26} />
        </>
      ) : (
        <Spacer size={32} />
      )}

      <View
        style={[
          styles.actionDetailsWrapper,
          mq.deviceQuery.mobile && styles.actionDetailsWrapperMobile,
        ]}
      >
        <View
          style={[
            styles.actionWrapper,
            mq.deviceQuery.mobile && styles.actionWrapperMobile,
          ]}
        >
          {capacity > 1 ? (
            <InviteCoworkers
              remainingCapacity={remainingCapacity}
              invitedPeople={invitedPeople}
              orderId={orderId}
            />
          ) : (
            <ShareThisSpace
              spaceName={spaceName}
              dateRange={dateRange}
              url={spaceUrl}
            />
          )}
        </View>

        {!mq.deviceQuery.mobile && (
          <View style={styles.actionDividerWrapper}>
            <Divider direction="vertical" />
          </View>
        )}

        <View
          style={[
            styles.actionWrapper,
            mq.deviceQuery.mobile && styles.actionWrapperMobile,
          ]}
        >
          <GetDirections
            address={location.address}
            city={location.city}
            latitude={location.latitude}
            longitude={location.longitude}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: { paddingHorizontal: 24 },
  rootMobile: {
    padding: 16,
  },
  actionDetailsWrapper: {
    flexDirection: "row",
    width: "100%",
  },
  actionDetailsWrapperMobile: {
    justifyContent: "space-between",
  },
  actionWrapper: {
    width: "45%",
  },
  actionWrapperMobile: {
    width: "fit-content",
    maxWidth: "50%",
  },
  actionDividerWrapper: {
    width: "10%",
    alignItems: "center",
  },
});
