import { colors } from "components/colors";
import { Icon } from "components/icon";
// import { MapMobilePopup } from "components/map_mobile_popup";
import { Spinner } from "components/spinner";
import { tokens } from "components/tokens";
import {
  Amenity,
  HomePage__SpaceDetailsFragment,
  HomePageLocations__LocationDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { usePrevious } from "hooks/use_previous";
import { groupBy, uniqueBy } from "lib/array_utils";
import { logger } from "lib/logger";
import { useMediaQuery } from "lib/media_query";
import mapboxgl from "mapbox-gl";
import { useCurrentUserGeoPlaceQuery } from "pages/homev2/hooks/use_current_user_geo_place";
import { SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY } from "pages/homev2/hooks/use_search_history";
import { toCoordinates } from "pages/homev2/mapbox";
import { useAnalytics } from "providers/analytics";
import { appConfig } from "providers/config";
import { useMapImprovementFeatureFlag } from "providers/splitio";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  LngLatBoundsLike,
  Map,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  ViewState,
} from "react-map-gl";
import { StyleSheet, View } from "react-native";
import { OnPreviewLocation } from "../home";
import { useCurrentUserGeoCoordinatesQuery } from "../hooks/use_current_user_geo_coordinates";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";
import { useReverseGeocodingLazyQuery } from "../hooks/use_reserve_geocoding";
import "./map.web.css";
import { HomeMapLocationMarker } from "./map_location_marker";
import { HomeMapPreviewCardV2 } from "./map_preview_card_v2";
import { BottomDrawer } from "components/bottom_drawer/bottom_drawer";

// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface MapProps {
  dayPasses: HomePage__SpaceDetailsFragment[];
  meetingRooms: HomePage__SpaceDetailsFragment[];
  dayOffices: HomePage__SpaceDetailsFragment[];
  loading: boolean;
  onPreviewLocation: OnPreviewLocation;
  locations?: HomePageLocations__LocationDetailsFragment[];
}

interface LocationMarkerBase {
  locationID: string;
  lat: number;
  lng: number;
  numOfSpaces?: number;
  currentUserDistance?: number | undefined | null;
  cta: string;
  locked?: boolean;
  timeZone: string;
  locationName: string;
  locationStreetAddress: string;
  locationCity: string;
  imageSrc: string;
  amenities?: Amenity[];
  spaceId?: string;
}

interface LocationMarkerDayPass extends LocationMarkerBase {
  type?: SpaceType.DayPass;
}

interface LocationMarkerDayOffice extends LocationMarkerBase {
  type?: SpaceType.DayOffice;
}

interface LocationMarkerMeetingRoom extends LocationMarkerBase {
  type?: SpaceType.MeetingRoom;
}

export type LocationMarker =
  | LocationMarkerDayPass
  | LocationMarkerDayOffice
  | LocationMarkerMeetingRoom;

export function HomeMap(props: MapProps) {
  const {
    meetingRooms,
    dayPasses,
    dayOffices,
    onPreviewLocation,
    loading,
    locations,
  } = props;
  const isMapImprovement = useMapImprovementFeatureFlag();
  const { spaceType, coordinates, bbox } = useHomeSearchParamsQuery();
  const updateHomeSearchParam = useUpdateHomeSearchParamsMutation();
  const { data: currentUserCoordinates } = useCurrentUserGeoCoordinatesQuery();
  const currentUserGeoPlace = useCurrentUserGeoPlaceQuery();
  const prevCoordinates = usePrevious(coordinates);
  const analytics = useAnalytics();
  const mq = useMediaQuery();
  const mapRef = useRef<MapRef>(null);
  const containerRef = useRef<View>(null);
  const isMobile = mq.sizeQuery.smAndDown;
  const [selectedLocationID, setSelectedLocationID] = useState<string | null>(
    null,
  );

  const searchPlace = localStorage.getItem(
    SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY,
  );
  const searchHistory = searchPlace ? JSON.parse(searchPlace) : [];
  const lastSearchPlace = searchHistory[0];

  const meetingRoomsGroupedByLocationID = groupBy(
    meetingRooms,
    (mR) => mR.location.id,
  );
  const dayPassesGroupedByLocationID = groupBy(
    dayPasses,
    (dP) => dP.location.id,
  );
  let locationMarkers: LocationMarker[] = [];

  const handleLayout = useCallback(() => {
    mapRef.current?.resize();
  }, []);

  if (spaceType === SpaceType.DayPass) {
    for (const dayPass of dayPasses) {
      const numOfSpaces =
        dayPassesGroupedByLocationID[dayPass.location.id].length;

      locationMarkers.push({
        spaceId: dayPass.id,
        type: SpaceType.DayPass,
        locationID: dayPass.location.id,
        lat: dayPass.location.address.latitude!,
        lng: dayPass.location.address.longitude!,
        numOfSpaces: numOfSpaces,
        currentUserDistance: dayPass.location.currentUserDistance,
        timeZone: dayPass.location.timezone,
        locationName: dayPass.location.name,
        locationStreetAddress: dayPass.location.address.streetAddress,
        locationCity: dayPass.location.address.city,
        imageSrc:
          dayPass.location.images?.[0]?.small.url ||
          dayPass.images?.[0]?.small.url,
        amenities: dayPass.amenities,
        locked: dayPass.locked,
        cta: "See pass details",
      });
    }
    locationMarkers = uniqueBy(locationMarkers, (m) => m.locationID);
  } else if (spaceType === SpaceType.DayOffice) {
    const officesGroupedByLocations = groupBy(dayOffices, (o) => o.location.id);

    Object.keys(officesGroupedByLocations).map((locationID) => {
      const spaces = officesGroupedByLocations[locationID];
      const firstSpace = spaces[0];
      const location = spaces[0].location;

      let numOfSpaces = 0;
      for (const space of spaces) {
        numOfSpaces += space.physicalSpacesCount;
      }

      locationMarkers.push({
        type: SpaceType.DayOffice,
        locationID: location.id,
        lat: location.address.latitude!,
        lng: location.address.longitude!,
        numOfSpaces,
        currentUserDistance: location.currentUserDistance,
        timeZone: location.timezone,
        locationName: location.name,
        locationStreetAddress: location.address.streetAddress,
        locationCity: location.address.city,
        imageSrc:
          location.images?.[0]?.small.url || firstSpace.images?.[0]?.small.url,
        amenities: firstSpace.amenities,
        locked: firstSpace.locked,
        cta:
          numOfSpaces > 1 ? `See ${numOfSpaces} offices` : "See office details",
      });
    });

    locationMarkers = uniqueBy(locationMarkers, (m) => m.locationID);
  } else if (spaceType === SpaceType.MeetingRoom) {
    for (const meetingRoom of meetingRooms) {
      const numOfSpaces =
        meetingRoomsGroupedByLocationID[meetingRoom.location.id].length;

      locationMarkers.push({
        type: SpaceType.MeetingRoom,
        spaceId: numOfSpaces > 1 ? undefined : meetingRoom.id,
        locationID: meetingRoom.location.id,
        lat: meetingRoom.location.address.latitude!,
        lng: meetingRoom.location.address.longitude!,
        numOfSpaces: numOfSpaces,
        currentUserDistance: meetingRoom.location.currentUserDistance,
        timeZone: meetingRoom.location.timezone,
        locationName: meetingRoom.location.name,
        locationStreetAddress: meetingRoom.location.address.streetAddress,
        locationCity: meetingRoom.location.address.city,
        imageSrc:
          meetingRoom.location.images?.[0]?.small.url ||
          meetingRoom.images?.[0]?.small.url,
        amenities: meetingRoom.amenities,
        locked: meetingRoom.locked,
        cta: numOfSpaces > 1 ? `See ${numOfSpaces} rooms` : "See room details",
      });
    }

    locationMarkers = uniqueBy(locationMarkers, (m) => m.locationID);
  } else {
    locations?.forEach((location) => {
      locationMarkers.push({
        locationID: location.id,
        lat: location.address.latitude!,
        lng: location.address.longitude!,
        locationName: location.name,
        timeZone: location.timezone,
        locationStreetAddress: location.address.streetAddress,
        locationCity: location.address.city,
        imageSrc: location.images[0]?.small.url,
        cta: "See location details",
      });
    });
  }

  const selectedLocationMarker = locationMarkers.find(
    (m) => m.locationID === selectedLocationID,
  );

  const [initialViewState, setInitialViewState] =
    useState<Partial<ViewState & { bounds: LngLatBoundsLike }>>();

  // initial view state from useInitialHomeSearchParams
  useEffect(() => {
    if (!initialViewState && coordinates) {
      setInitialViewState({
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        bounds: bbox,
      });
      return;
    }
  }, [bbox, coordinates, initialViewState]);

  // change of coordinates
  useEffect(() => {
    if (
      coordinates &&
      initialViewState &&
      (prevCoordinates?.lat !== coordinates.lat ||
        prevCoordinates?.lng !== coordinates.lng)
    ) {
      mapRef.current?.flyTo({ center: [coordinates.lng, coordinates.lat] });

      if (bbox) {
        mapRef.current?.fitBounds(bbox, {
          linear: false,
        });
      }
    }
  }, [
    mapRef,
    coordinates,
    initialViewState,
    prevCoordinates?.lat,
    prevCoordinates?.lng,
    bbox,
  ]);

  const [reverseGeocodingQuery] = useReverseGeocodingLazyQuery();

  const onMapInteraction = useCallback(
    async (e) => {
      const center = mapRef.current?.getCenter();
      const bbox = mapRef.current?.getBounds();

      let placeId = null;
      const coordinates = center ? { lat: center.lat, lng: center.lng } : null;
      try {
        if (coordinates) {
          const place = await reverseGeocodingQuery(coordinates);
          placeId = place.id;
        }
      } catch (error) {
        logger.debug(`[onMapInteraction] reverseGeocodingQuery error`, error);
      } finally {
        // update the center coordinates to search params no matter how the calling of reverseGeocodingQuery goes successfully or not
        updateHomeSearchParam({
          coordinates,
          bbox: bbox
            ? [
                bbox.getNorthEast().lng,
                bbox.getNorthEast().lat,
                bbox.getSouthWest().lng,
                bbox.getSouthEast().lat,
              ]
            : null,
          placeId,
          mapSearch: true,
        });
      }

      analytics.event("Navigate Map", {
        Coordinates: `${e.viewState.latitude}, ${e.viewState.longitude}`,
        "Event Name": e.type,
      });
    },
    [analytics, reverseGeocodingQuery, updateHomeSearchParam],
  );

  return (
    <View ref={containerRef} onLayout={handleLayout} style={styles.container}>
      {/* @ts-ignore: underneath it's a div element */}
      {initialViewState && containerRef.current?.offsetHeight > 0 ? (
        <Map
          ref={mapRef}
          initialViewState={initialViewState}
          mapStyle="mapbox://styles/flexspacedevops/cl56favx600bb14lljo2ft4fu"
          mapboxAccessToken={appConfig.mapboxApiAccessToken}
          onDragEnd={onMapInteraction}
        >
          {loading && <LoadingOverlay />}
          <View style={styles.navControlWrapper}>
            <NavigationControl showZoom showCompass={false} />
          </View>
          {!isMapImprovement && currentUserCoordinates && (
            <Marker
              latitude={currentUserCoordinates.lat}
              longitude={currentUserCoordinates.lng}
            >
              <Icon
                size="lg"
                name="map-location-icon"
                color={tokens.colors.primary.light}
              />
            </Marker>
          )}
          {isMapImprovement &&
            (lastSearchPlace && lastSearchPlace.center ? (
              <Marker
                latitude={toCoordinates(lastSearchPlace.center).lat}
                longitude={toCoordinates(lastSearchPlace.center).lng}
              >
                <Icon
                  size="lg"
                  name={
                    lastSearchPlace.id === currentUserGeoPlace.data?.id
                      ? "map-location-icon"
                      : "map-searched-icon"
                  }
                  color={tokens.colors.primary.light}
                />
              </Marker>
            ) : (
              currentUserCoordinates && (
                <Marker
                  latitude={currentUserCoordinates.lat}
                  longitude={currentUserCoordinates.lng}
                >
                  <Icon
                    size="lg"
                    name="map-location-icon"
                    color={tokens.colors.primary.light}
                  />
                </Marker>
              )
            ))}
          {locationMarkers.map((m) => (
            <Marker key={m.locationID} latitude={m.lat} longitude={m.lng}>
              <HomeMapLocationMarker
                onClick={() => {
                  setSelectedLocationID(m.locationID);
                }}
                hideCountIfSingle={
                  spaceType === SpaceType.DayOffice ||
                  spaceType === SpaceType.DayPass ||
                  !spaceType
                }
                numOfSpaces={m.numOfSpaces}
                isSelected={selectedLocationID === m.locationID}
                outOfPolicy={m.locked}
              />
            </Marker>
          ))}
          {selectedLocationMarker && !mq.deviceQuery.mobile && (
            <Popup
              offset={8}
              closeButton={false}
              closeOnClick={true}
              longitude={selectedLocationMarker.lng}
              latitude={selectedLocationMarker.lat}
              maxWidth={isMobile ? "343px" : "240px"}
              onClose={() => {
                setSelectedLocationID(null);
              }}
            >
              <HomeMapPreviewCardV2
                data={selectedLocationMarker}
                onClose={() => setSelectedLocationID(null)}
                spaceType={selectedLocationMarker.type}
                onPreviewLocation={onPreviewLocation}
              />
            </Popup>
          )}
          {selectedLocationMarker && mq.deviceQuery.mobile && (
            <BottomDrawer
              dismissable
              onDismiss={() => setSelectedLocationID(null)}
              snapPoints={({ maxHeight }) => [maxHeight / 7]}
            >
              <View style={styles.cardWrapper}>
                <HomeMapPreviewCardV2
                  data={selectedLocationMarker}
                  onClose={() => setSelectedLocationID(null)}
                  spaceType={selectedLocationMarker.type}
                  onPreviewLocation={onPreviewLocation}
                />
              </View>
            </BottomDrawer>
          )}
        </Map>
      ) : (
        <View
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors.brand.eggplantMinus90,
          }}
        >
          <Spinner />
        </View>
      )}
    </View>
  );
}

function LoadingOverlay() {
  return (
    <View style={styles.loadingOverlay}>
      <Spinner />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  cardWrapper: {
    padding: 16,
  },
  navControlWrapper: {
    position: "absolute",
    right: 45,
    top: 16,
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    paddingTop: 160,
  },
});
