import { FlexSpaceForGoogleCalendar } from "pages/homev3/components/flexspace_for_google_calendar";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Amenities } from "../components/amenities";
import { ExploreWorkSpaceByCity } from "../components/explore_workspace_by_city";
import { InviteCoWorker } from "../components/invite_co_worker";
import { JoinYourTeam } from "../components/join_your_team";
import { PopularInCity } from "../components/popular_in_city";
import { RecentlyViewed } from "../components/recently_viewed";
import { SavedForLater } from "../components/saved_for_later";
import { WhatFlexSpaceOffer } from "../components/what_flexspace_offer";
import { WorkNearBy } from "../components/work_near_by";
import { HeroCarousel } from "./hero_carousel/hero_carousel";
import { SimilarToYourLastBooking } from "./similar_to_your_last_booking";
import { WorkThereAgain } from "./work_there_again";
import { YourNextBooking } from "./your_next_booking";

export function ReturnUser() {
  return (
    <View style={styles.container}>
      <HeroCarousel />
      <YourNextBooking />
      <RecentlyViewed />
      <SavedForLater />
      <WorkThereAgain />
      <SimilarToYourLastBooking />
      <Amenities />
      <JoinYourTeam />
      <InviteCoWorker />
      <WorkNearBy />
      <PopularInCity />
      <WhatFlexSpaceOffer />
      <FlexSpaceForGoogleCalendar />
      <ExploreWorkSpaceByCity />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 40,
    gap: 40,
  },
});
