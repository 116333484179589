import { Dialog } from "components/dialog";
import { Icon } from "components/icon";
import { tokens } from "components/tokens";
import { People } from "components/who_is_here";
import {
  City,
  OrgUserDetailsFragment,
  TeamsLocationDetailsFragment,
  TeamsSpaceDetailsFragment,
  TeamsUserDetailsFragment,
} from "core/graphql.generated";
import { useDebounceCallBack } from "hooks/use_debounce";
import { usePrevious } from "hooks/use_previous";
import { groupBy } from "lib/array_utils";
import { calculateDistance } from "lib/map_utils";
import { useMediaQuery } from "lib/media_query";
import mapboxgl from "mapbox-gl";
import "pages/team_spaces/components/map/map.web.css";
import { MapCTA } from "pages/team_spaces/components/map/map_cta";
import { TeamMapPreviewCard } from "pages/team_spaces/components/map/team_map_preview_card";
import { TeamLocationPreview } from "pages/team_spaces/components/team_location_preview";
import { getCurrentLocation } from "pages/team_spaces/components/where_my_team/where_my_team";
import { useMyTeamSearchParamsQuery } from "pages/team_spaces/hooks/use_my_team_search_params";
import { appConfig } from "providers/config";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  LngLatBoundsLike,
  Map,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  ViewState,
} from "react-map-gl";
import { Pressable, StyleSheet, View } from "react-native";
// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface MapProps {
  users: OrgUserDetailsFragment[];
  currentUserCity?: City | null;
  addMyCityPress: () => void;
}

const initialViewState = {
  bbox: [-74.259639922, 40.477399, -73.700272, 40.917577],
  zoom: 0,
  latitude: 40.7306,
  longitude: -73.9866,
};

type LocationMarker = {
  locationID?: string;
  lat: number;
  lng: number;
  space?: TeamsSpaceDetailsFragment;
  peoples: TeamsUserDetailsFragment[];
};

const clusterRadius = 1500000;
const getClusterMarkers = (markers: LocationMarker[], zoom: number) => {
  const clusteredMarkers: LocationMarker[] = [];
  markers.forEach((marker) => {
    let addedToCluster = false;
    for (let i = 0; i < clusteredMarkers.length; i++) {
      const cluster = clusteredMarkers[i];
      const distance = calculateDistance(
        { latitude: marker.lat, longitude: marker.lng },
        { latitude: cluster.lat, longitude: cluster.lng },
      );
      const clusterRadiusAdjusted = clusterRadius / Math.pow(2, zoom);
      if (distance <= clusterRadiusAdjusted) {
        cluster.peoples.push(...marker.peoples);
        addedToCluster = true;
        break;
      }
    }

    if (!addedToCluster) {
      clusteredMarkers.push(marker);
    }
  });
  return clusteredMarkers;
};

export function TeamMap(props: MapProps) {
  const { users, currentUserCity, addMyCityPress } = props;
  const { coordinates, bbox } = useMyTeamSearchParamsQuery();

  const prevCoordinates = usePrevious(coordinates);
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const mapRef = useRef<MapRef>(null);
  const containerRef = useRef<View>(null);
  const [selectedMarker, setSelectedMarker] = useState<LocationMarker | null>(
    null,
  );
  const [locationPreviewData, setLocationPreviewData] =
    useState<TeamsLocationDetailsFragment>();
  const currentLocation = getCurrentLocation(coordinates, currentUserCity);
  const [showMapCTA, setShowMapCTA] = useState<boolean>(!currentLocation);
  const bookings = users.flatMap((u) => u.bookings);
  const groupedBooking = groupBy(bookings, (b) => b.space.location.id);
  const [zoom, setZoom] = useState<number>(initialViewState.zoom);

  const handleZoom = useDebounceCallBack(() => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
    }
  }, 150);

  const locationMarkers: LocationMarker[] = users
    .filter(
      (u) =>
        u.city &&
        u.bookings.length === 0 &&
        u.city.latitude !== currentLocation?.lat &&
        u.city.longitude !== currentLocation?.lng,
    )
    .map((u) => ({
      lat: u.city?.latitude!,
      lng: u.city?.longitude!,
      peoples: [u],
    }));

  Object.values(groupedBooking).forEach((bookings) => {
    const location = bookings[0].space.location;
    locationMarkers.push({
      locationID: location.id,
      lat: location.address.latitude!,
      lng: location.address.longitude!,
      space: bookings[0].space,
      peoples: [...new Set(bookings.map((b) => b.user))],
    });
  });

  const handleLayout = useCallback(() => {
    mapRef.current?.resize();
  }, []);

  const [viewState, setViewState] =
    useState<Partial<ViewState & { bounds: LngLatBoundsLike }>>(
      initialViewState,
    );

  // initial view state from useInitialHomeSearchParams
  useEffect(() => {
    if (coordinates) {
      setViewState({
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        bounds: bbox,
      });
    }
  }, [bbox, coordinates]);

  // change of coordinates
  useEffect(() => {
    if (
      coordinates &&
      viewState &&
      (prevCoordinates?.lat !== coordinates.lat ||
        prevCoordinates?.lng !== coordinates.lng)
    ) {
      mapRef.current?.flyTo({ center: [coordinates.lng, coordinates.lat] });

      if (bbox) {
        mapRef.current?.fitBounds(bbox, {
          linear: false,
        });
      }
    }
  }, [
    mapRef,
    coordinates,
    viewState,
    prevCoordinates?.lat,
    prevCoordinates?.lng,
    bbox,
  ]);

  const addMyCity = () => {
    setShowMapCTA(false);
    addMyCityPress();
  };

  const closeMapCTA = () => {
    setShowMapCTA(false);
  };

  return (
    <View
      ref={containerRef}
      onLayout={handleLayout}
      style={isMobile ? styles.containerMobile : styles.container}
      testID="team-map-container"
    >
      <Map
        ref={mapRef}
        initialViewState={viewState}
        mapStyle="mapbox://styles/flexspacedevops/cl56favx600bb14lljo2ft4fu"
        mapboxAccessToken={appConfig.mapboxApiAccessToken}
        onLoad={(e) => e.target.on("zoom", handleZoom)}
      >
        <View style={styles.navControlWrapper}>
          <NavigationControl showZoom showCompass={false} />
        </View>
        {showMapCTA ? (
          <MapCTA
            addMyCityPress={addMyCity}
            skipMapCTAPress={closeMapCTA}
            peoples={[...new Set(bookings.map((b) => b.user))]}
          />
        ) : (
          <>
            {currentLocation && (
              <Marker
                latitude={currentLocation.lat}
                longitude={currentLocation.lng}
              >
                <Icon
                  size="lg"
                  name="map-location-icon"
                  color={tokens.colors.primary.light}
                />
              </Marker>
            )}

            {getClusterMarkers(locationMarkers, zoom).map((m, index) => (
              <Pressable
                onPress={() => setSelectedMarker(m)}
                key={m.lat + index}
              >
                <Marker latitude={m.lat} longitude={m.lng}>
                  <People
                    peoples={m.peoples}
                    showRemainingCount={true}
                    maxDisplay={2}
                    appearance={"outline"}
                  />
                </Marker>
              </Pressable>
            ))}
            {selectedMarker && (
              <Popup
                offset={8}
                closeButton={false}
                closeOnClick={true}
                longitude={selectedMarker.lng}
                latitude={selectedMarker.lat}
                maxWidth={isMobile ? "343px" : "262px"}
                onClose={() => setSelectedMarker(null)}
                anchor={"center"}
              >
                <TeamMapPreviewCard
                  space={selectedMarker.space}
                  peoples={selectedMarker.peoples}
                  setLocationPreviewData={setLocationPreviewData}
                />
              </Popup>
            )}
          </>
        )}
      </Map>

      {locationPreviewData && (
        <Dialog
          visible
          onRequestClose={() => setLocationPreviewData(undefined)}
        >
          <TeamLocationPreview
            locationPreviewData={locationPreviewData}
            onClose={() => setLocationPreviewData(undefined)}
          />
        </Dialog>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 600,
  },
  containerMobile: {
    width: "100%",
    height: 240,
  },
  navControlWrapper: {
    position: "absolute",
    right: 45,
    top: 8,
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    paddingTop: 160,
  },
});
