import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  AuthLoaderQuery,
  OrgByDomainQuery,
  OrgByDomainQueryVariables,
  PostAuthMutation,
  PostAuthMutationVariables,
} from "core/graphql.generated";
import { useFavoriteIdsVariable } from "hooks/use_reactive_favs";
import { logger } from "lib/logger";
import { getDomainFromEmail } from "lib/string_utils";
import { ACCEPT_INVITE } from "pages/accept_invite/accept_invite";
import { SourceFrom } from "pages/custom_org_sign_in/custom_org_sign_in";
import { useAnalytics } from "providers/analytics";
import {
  orgByDomainQuery,
  postAuthRedirectUrl,
  SIGN_UP_STEPS_REDIRECT_URL_KEY,
} from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import {
  useCustomSSOFeatureFlag,
  useOpenDirectoryFeatureFlag,
} from "providers/splitio";
import { useToast } from "providers/toast";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface AuthLoaderProps {
  children: React.ReactNode;
}
export function AuthLoaderV1(props: AuthLoaderProps) {
  const { children } = props;
  const { authenticated } = useAuthV2();
  const analytics = useAnalytics();
  const sSOFeatureFlag = useCustomSSOFeatureFlag();
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  const history = useHistory();
  // We need to know where the user should land next before we render the application
  const [resolved, setResolved] = useState(false);
  // Default we will navigate to the homepage after successful auth
  // but with the new flow user can log in from other page like checkout so we restore to that page
  let targetPage = "/";
  if (postAuthRedirectUrl.get()) {
    targetPage = postAuthRedirectUrl.get() || "/";
  }

  const {
    data: authLoaderQuery,
    loading,
    refetch,
  } = useQuery<AuthLoaderQuery>(authLoaderGQLQuery, {
    skip: !authenticated,
  });

  const emailDomain =
    getDomainFromEmail(authLoaderQuery?.currentUser?.email as string) || "";
  const { data: orgData, loading: orgDataLoading } = useQuery<
    OrgByDomainQuery,
    OrgByDomainQueryVariables
  >(orgByDomainQuery, {
    variables: {
      domain: emailDomain,
    },
    skip:
      !authLoaderQuery?.currentUser?.email || !emailDomain || !sSOFeatureFlag,
  });

  // this will create initial state for favorite space or locations
  useFavoriteIdsVariable(authenticated);

  const toast = useToast();

  const [postAuth] = useMutation<PostAuthMutation, PostAuthMutationVariables>(
    postAuthMutation,
  );

  const currentUser = authLoaderQuery?.currentUser;

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email || "",
        name: currentUser.fullName,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (loading || orgDataLoading) {
      return;
    }
    if (!currentUser || !authenticated) {
      logger.debug(`[AuthLoader] unauthenticated user`);
      return setResolved(true);
    }
    logger.debug(
      `[AuthLoader] currentUser: ${
        currentUser ? JSON.stringify(currentUser) : "null"
      }`,
    );

    if (sSOFeatureFlag && orgData?.organizationByDomain?.requireSSO) {
      logger.debug(`[AuthLoader] organization require SSO`, orgData);
      const isLoginMethodValid = orgData.organizationByDomain.ssoProvider.find(
        (provider) => provider === currentUser?.authMethod,
      );

      if (!isLoginMethodValid && orgData.organizationByDomain.slug) {
        history.replace(`/${orgData.organizationByDomain.slug}`, {
          orgData: orgData.organizationByDomain,
          sourceFrom: SourceFrom.LOG_IN,
        });
        return setResolved(true);
      }
    }

    const authLogin = async () => {
      // if there was sign-up steps redirect_path previously, it should be handled first
      const signUpStepsRedirectPath = localStorage.getItem(
        SIGN_UP_STEPS_REDIRECT_URL_KEY,
      );
      // navigate to next page, during onboarding process
      if (signUpStepsRedirectPath) {
        logger.debug(
          `[AuthLoader] signUpStepsRedirectPath: ${signUpStepsRedirectPath}`,
        );
        localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
        history.replace(signUpStepsRedirectPath);
        return setResolved(true);
      }
      if (!currentUser.organization) {
        logger.debug(`[AuthLoader] user does not belong to organization yet`);
        let inviteId;
        const acceptInvite = localStorage.getItem(ACCEPT_INVITE);
        if (acceptInvite) {
          inviteId = JSON.parse(acceptInvite).inviteId;
          logger.debug(`[AuthLoader] inviteId ${inviteId}`);
          localStorage.removeItem(ACCEPT_INVITE);
        }
        const postAuthResult = await postAuth({ variables: { inviteId } });
        logger.debug(
          `[AuthLoader] postAuthResult: ${JSON.stringify(
            postAuthResult?.data?.postAuth,
          )}`,
        );

        if (postAuthResult?.data?.postAuth.orgRegistered) {
          history.replace(targetPage);
          postAuthRedirectUrl.reset();
        } else {
          if (postAuthResult?.data?.postAuth.linked) {
            window.location.reload();
          } else {
            const path = window.location.pathname.startsWith("/sign-up-gcal")
              ? "/sign-up-gcal/team"
              : "/sign-up/team-name";
            history.replace(path);
          }
        }
      }
      setResolved(true);
    };

    authLogin();
  }, [
    history,
    currentUser,
    refetch,
    loading,
    analytics,
    toast,
    orgData,
    orgDataLoading,
    sSOFeatureFlag,
    openDirectoryFeatureFlag,
    targetPage,
    postAuth,
    authenticated,
  ]);

  // Flash emptiness
  if (!resolved) {
    return null;
  }
  return <Fragment>{children}</Fragment>;
}

export const postAuthMutation = gql`
  mutation postAuth($inviteId: String) {
    postAuth(inviteId: $inviteId) {
      linked
      orgRegistered
      acceptedInvite
    }
  }
`;

const authLoaderGQLQuery = gql`
  query AuthLoader {
    currentUser {
      id
      authMethod
      fullName
      email
      auth0PrimaryEmail
      organization {
        id
        name
      }
    }
  }
`;
