import { Pressable, View } from "react-native";
import { useHistory } from "react-router-dom";

import { EmptyData } from "components/empty_data";
import { Bookings__OrderDetailsFragment } from "core/graphql.generated";

interface BookingsContentProps {
  bookings: Bookings__OrderDetailsFragment[];
  card: React.FC<{ details: Bookings__OrderDetailsFragment }>;
}

export function BookingsContent({
  bookings,
  card: BookingCard,
}: BookingsContentProps) {
  const history = useHistory();

  if (bookings.length === 0) {
    return (
      <View testID="no-bookings">
        <EmptyData
          title="Ready for a change of scenery?"
          description="Book a space and look forward to working from your new favourite spot. Your upcoming bookings will appear here once confirmed."
          buttonText="Find your next space"
          onClick={() => history.push("/")}
        />
      </View>
    );
  }

  return (
    <View testID="booking-list">
      {bookings.map((booking) => (
        <Pressable
          key={booking.id}
          testID={"booking-card"}
          onPress={() => history.push(`/bookings/${booking.id}`)}
        >
          <BookingCard details={booking} />
        </Pressable>
      ))}
    </View>
  );
}
