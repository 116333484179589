import { useReactiveVar } from "@apollo/client";
import { favoriteLocations, favoriteSpaces } from "hooks/use_reactive_favs";
import { useFavoritesMutation } from "pages/offsite_space_details_v2/hooks/use_favorites_mutation";
import { useCallback } from "react";
import { GestureResponderEvent } from "react-native";

export const enum FavoriteType {
  Location = "Location",
  Space = "Space",
}

export function useSaveFavorite(
  favoriteId: string,
  favoriteType: FavoriteType,
) {
  const [
    { saveFavoriteSpace, saveFavoriteSpaceLoading },
    { saveFavoriteLocation, saveFavoriteLocationLoading },
    { removeFavorite, removeFavoriteLoading },
  ] = useFavoritesMutation();

  const isLocation = favoriteType === FavoriteType.Location;

  const favorites = useReactiveVar(
    isLocation ? favoriteLocations : favoriteSpaces,
  );

  const isSaved = favorites.includes(favoriteId);

  const saveFavorite = useCallback(
    async (e?: GestureResponderEvent) => {
      if (e) {
        e.preventDefault();
      }

      if (!favoriteId) {
        return;
      }

      if (!isSaved) {
        if (isLocation) {
          favoriteLocations([favoriteId, ...favorites]);
          await saveFavoriteLocation(favoriteId);
        } else {
          favoriteSpaces([favoriteId, ...favorites]);
          await saveFavoriteSpace(favoriteId);
        }
      } else {
        if (isLocation) {
          favoriteLocations(favorites.filter((id) => id !== favoriteId));
        } else {
          favoriteSpaces(favorites.filter((id) => id !== favoriteId));
        }
        await removeFavorite(favoriteId);
      }
    },
    [
      favoriteId,
      favorites,
      isLocation,
      isSaved,
      removeFavorite,
      saveFavoriteLocation,
      saveFavoriteSpace,
    ],
  );

  return {
    saveFavorite,
    isSaved,
    loading:
      saveFavoriteSpaceLoading ||
      saveFavoriteLocationLoading ||
      removeFavoriteLoading,
  };
}
