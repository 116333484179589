import { gql, useQuery } from "@apollo/client";
import { SplitFactory, useTreatments } from "@splitsoftware/splitio-react";
import { CurrentUserIdQuery } from "core/graphql.generated";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useAuthV2 } from "./authv2";
import { useConfig } from "./config";

interface SplitProviderProps {
  children: JSX.Element | null;
}

export function SplitProvider(props: SplitProviderProps) {
  const { children } = props;
  const config = useConfig();

  if (!config.splitioBrowserApiKey) {
    return <Fragment>{children}</Fragment>;
  }

  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: config.splitioBrowserApiKey,
      key: "generic",
    },
  };

  return <SplitFactory config={sdkConfig}>{children}</SplitFactory>;
}

export function useNormalFeatureFlag(name: string, treatValue = "on") {
  const { authenticated } = useAuthV2();
  const { data: currentUserQuery } = useQuery<CurrentUserIdQuery>(
    currentUserIdGQLQuery,
    {
      skip: !authenticated,
    },
  );
  const treatments = useTreatments([name], {
    organizationId: currentUserQuery?.currentUser?.organization?.id ?? "",
  });
  const location = useLocation();

  if (sessionStorage && sessionStorage.getItem(name)) {
    return sessionStorage.getItem(name) === "false" ? false : true;
  }

  const searchParams = new URLSearchParams(location.search);
  const flagOnUrl = searchParams.get(name);

  if (flagOnUrl) {
    if (sessionStorage) {
      sessionStorage.setItem(name, `${treatValue === flagOnUrl}`);
    }
    return flagOnUrl === treatValue;
  }

  return treatments[name].treatment === treatValue;
}

export function useABTestFeatureFlag(name: string, treatValue: string) {
  const { data: currentUserQuery } = useQuery<CurrentUserIdQuery>(
    currentUserIdGQLQuery,
  );
  const treatments = useTreatments(
    [name],
    { organizationId: currentUserQuery?.currentUser?.organization?.id ?? "" },
    currentUserQuery?.currentUser?.id,
  );
  const location = useLocation();

  if (sessionStorage && sessionStorage.getItem(name)) {
    return sessionStorage.getItem(name) === treatValue;
  }

  const searchParams = new URLSearchParams(location.search);
  const flagOnUrl = searchParams.get(name);

  if (flagOnUrl) {
    if (sessionStorage) {
      sessionStorage.setItem(name, treatValue);
    }
    return flagOnUrl === treatValue;
  }

  return treatments[name].treatment === treatValue;
}

export function useGCalBannerFeatureFlag() {
  return useABTestFeatureFlag("CH16478_GCAL_IN_APP_CTA", "banner");
}

export function useGCalTileFeatureFlag() {
  return useABTestFeatureFlag("CH16478_GCAL_IN_APP_CTA", "tile");
}

export function useEmptyStateGroupAFeatureFlag() {
  return useABTestFeatureFlag("CH_18134_EMPTY_STATES", "group_A");
}

export function useCustomSSOFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_CUSTOM_SSO");
}

export function useOptimizeSearchFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_OPTIMIZE_SEARCH");
}

export function useReBookFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_REBOOK");
}

export function usePostBookingsFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_POST_BOOKINGS");
}

export function useTeamsStripeMigrationFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_STRIPE_MIGRATION");
}

export function useFavoritesFeatureFlag() {
  return useNormalFeatureFlag("CH_14238_TEAMS_FAVORITES");
}

export function usePriceDiscoverabilityFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_PRICE_DISCOVERABILITY");
}

export function useMapImprovementFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_MAP_DISCOVERY_IMPROVEMENTS");
}

export function useOrderMemoFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_ORDERS_MEMO");
}
export function useOpenDirectoryFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_OPEN_DIRECTORY");
}
export function useProximityPolicyFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_PROXIMITY_POLICY");
}
export function usePreferredSpaceFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_PREFERRED_SPACES");
}

export function useBudgetAttributionFeatureFlag() {
  return useNormalFeatureFlag("TEAMS_BUDGET_ATTRIBUTION");
}

const currentUserIdGQLQuery = gql`
  query CurrentUserId {
    currentUser {
      id
      organization {
        id
      }
    }
  }
`;
