import { HorizontalList } from "components/horizontal_list";
import {
  OrganizationUser,
  OrgBookingFragment,
  SavedLocations__LocationDetailsFragment,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { ListSpacesSkeleton } from "pages/homev3/components/list_spaces_skeleton";
import { LocationCard } from "pages/homev3/components/location_card";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useOrgBookingQuery } from "pages/homev3/hooks/use_org_booking_query";
import { useSavedLocationsQuery } from "pages/saved_places/hooks/use_saved_places_query";

export function SavedForLater() {
  const { data, loading } = useSavedLocationsQuery();

  const { data: orgData } = useOrgBookingQuery({});

  const favoriteLocationList = data?.currentUser?.favoriteLocations;

  if (loading) {
    return <ListSpacesSkeleton />;
  }

  if (!favoriteLocationList || !favoriteLocationList.length) {
    return null;
  }

  return (
    <Content locations={favoriteLocationList} orgBookings={orgData.bookings} />
  );
}

type ContentProps = {
  locations: SavedLocations__LocationDetailsFragment[];
  orgBookings: OrgBookingFragment[];
};
function Content({ locations, orgBookings }: ContentProps) {
  const orgGroupedBooking = groupBy(orgBookings, (b) => b.space.location.id);
  const renderItem = (location: SavedLocations__LocationDetailsFragment) => {
    const locationId = location.id;
    let peoples: Partial<OrganizationUser>[] = [];
    if (orgGroupedBooking[locationId]) {
      peoples = orgGroupedBooking[locationId].map((b) => b.user);
    }
    return (
      <LocationCard key={location.id} location={location} peoples={peoples} />
    );
  };

  return (
    <HorizontalList
      title={
        <TitleSpaceList
          title={"Saved for later"}
          subtitle={"Keep track of the spaces that inspire you"}
        />
      }
      data={locations}
      renderItem={renderItem}
    />
  );
}
