import { colors } from "components/colors";
import { Text } from "components/text_v2";

export function TermsAndPolicy() {
  return (
    <Text size="xs" customColor={colors.brand.blackMinus30} align="center">
      By continuing, I agree to the Flexspace{" "}
      <a
        target="_blank"
        rel="noreferrer"
        style={{ color: colors.brand.blackMinus30 }}
        href="https://flexspace-ds-static.s3.us-west-1.amazonaws.com/flexspace-teams-tc.pdf"
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        target="_blank"
        rel="noreferrer"
        style={{ color: colors.brand.blackMinus30 }}
        href="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-privacy.pdf"
      >
        Privacy Policy.
      </a>
    </Text>
  );
}
