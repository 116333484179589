import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import ContentLoader, { Rect } from "react-content-loader/native";
import { StyleSheet, View } from "react-native";

export function BudgetSkeleton() {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <BudgetSkeletonMobile />;
  }

  return <BudgetSkeletonDesktop />;
}

function BudgetSkeletonDesktop() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={235}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="235px" />
      </ContentLoader>
      <Spacer size={48} />
      <ContentLoader
        speed={2}
        width="100%"
        height={40}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="40px" />
      </ContentLoader>
      <Spacer size={24} />

      <View style={styles.groupBudgets}>
        <ContentLoader
          speed={2}
          width={330}
          height={211}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="211px" />
        </ContentLoader>
        <Spacer direction="row" size={24} />
        <ContentLoader
          speed={2}
          width={330}
          height={211}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="211px" />
        </ContentLoader>
        <Spacer direction="row" size={24} />
        <ContentLoader
          speed={2}
          width={330}
          height={211}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="211px" />
        </ContentLoader>
      </View>
    </View>
  );
}

function BudgetSkeletonMobile() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={327}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="327px" />
      </ContentLoader>
      <Spacer size={16} />
      <ContentLoader
        speed={2}
        width="100%"
        height={40}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="40px" />
      </ContentLoader>
      <Spacer size={16} />
      <View>
        <ContentLoader
          speed={2}
          width="100%"
          height={211}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="211px" />
        </ContentLoader>
        <Spacer size={16} />
        <ContentLoader
          speed={2}
          width="100%"
          height={211}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="211px" />
        </ContentLoader>
        <Spacer size={16} />
        <ContentLoader
          speed={2}
          width="100%"
          height={211}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="211px" />
        </ContentLoader>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  groupBudgets: {
    flexDirection: "row",
  },
});
