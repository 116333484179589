import { useApolloClient, useLazyQuery } from "@apollo/client";
import {
  AuthMethod,
  OrgByDomainQuery,
  OrgByDomainQueryVariables,
} from "core/graphql.generated";
import { ROUTES } from "core/routes";
import { useForm, UseFormData } from "hooks/use_form";
import { isEmail } from "lib/string_utils";
import { SourceFrom } from "pages/custom_org_sign_in/custom_org_sign_in";
import { isEmailTakenGQLQuery } from "pages/sign_up/components/step_get_started";
import { signInModalState } from "providers/app_loader";
import { orgByDomainQuery, postAuthRedirectUrl } from "providers/auth_loader";
import { useCustomSSOFeatureFlag } from "providers/splitio";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

interface EmailLoginFormValues {
  email: string;
}

export type EmailFormResponseType = UseFormData<
  { email: string; authenticationError: string },
  "email" | "authenticationError",
  never
>;
export function useAuthEmailForm(): EmailFormResponseType {
  const history = useHistory();
  const sSOFeatureFlag = useCustomSSOFeatureFlag();
  const apolloClient = useApolloClient();
  const [, setShowLoginModal] = useRecoilState(signInModalState);
  const [fetchIsEmailTaken] = useLazyQuery(isEmailTakenGQLQuery);

  return useForm({
    initialValues: { email: "", authenticationError: "" },
    validate: ({ email }: EmailLoginFormValues) => {
      const errors: { [field: string]: string } = {};
      if (!isEmail(email)) {
        errors.email = "Please input a valid email address";
      }
      return errors;
    },
    onSubmit: async (
      { email }: EmailLoginFormValues,
      { setSubmitting, setErrors }: any,
    ) => {
      if (!email) {
        return;
      }
      setSubmitting(true);
      const { data, error } = await fetchIsEmailTaken({
        variables: { email },
      });
      if (error) {
        return setErrors({ email: "This email is invalid" });
      }

      if (sSOFeatureFlag) {
        const result = await apolloClient.query<
          OrgByDomainQuery,
          OrgByDomainQueryVariables
        >({
          query: orgByDomainQuery,
          variables: {
            domain: email.split("@").pop() || "",
          },
        });

        const orgData = result.data.organizationByDomain;
        const isLoginMethodValid = orgData?.ssoProvider.find(
          (provider) => provider === AuthMethod.Password,
        );
        if (orgData?.requireSSO && orgData.slug && !isLoginMethodValid) {
          history.replace(`/${orgData.slug}`, {
            orgData,
            sourceFrom: SourceFrom.LOG_IN,
          });
        } else {
          setSubmitting(false);
          const currentLocation = history.location;
          const urlWithParams = `${currentLocation.pathname}${currentLocation.search}`;
          postAuthRedirectUrl.set(urlWithParams);
          if (data?.isEmailTaken) {
            history.push(ROUTES.SIGN_IN_STEP_PASS_WORD.path, { email });
          } else {
            localStorage.setItem("sign-up", JSON.stringify({ email }));
            history.push("/sign-up/full-name");
          }
        }
      } else {
        setSubmitting(false);
        const currentLocation = history.location;
        const urlWithParams = `${currentLocation.pathname}${currentLocation.search}`;
        postAuthRedirectUrl.set(urlWithParams);
        if (data?.isEmailTaken) {
          history.push(ROUTES.SIGN_IN_STEP_PASS_WORD.path, { email });
        } else {
          localStorage.setItem("sign-up", JSON.stringify({ email }));
          history.push("/sign-up/full-name");
        }
      }
      setShowLoginModal(false);
    },
  });
}
