import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { EmailFormResponseType } from "components/use_auth_email_form";
import { GoogleButton } from "core/google_button";
import { postAuthRedirectUrl } from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "../providers/analytics";

type GoogleOrEmailProps = {
  form: EmailFormResponseType;
};
export function LoginGoogleOrEmail({ form }: GoogleOrEmailProps) {
  const history = useHistory();
  const currentLocation = history.location;
  const { logInWithGoogle } = useAuthV2();
  const analytics = useAnalytics();
  const { values, changed, errors, setFieldValue, submit } = form;
  const loginWithGG = () => {
    const urlWithParams = `${currentLocation.pathname}${currentLocation.search}`;
    postAuthRedirectUrl.set(urlWithParams);
    logInWithGoogle();
    if (currentLocation.pathname.includes("checkout")) {
      analytics.event(`Click Checkout Login`, {
        "Login Type": "Google",
      });
    }
  };

  const onSubmit = () => {
    submit();
    if (currentLocation.pathname.includes("checkout")) {
      analytics.event(`Click Checkout Login`, {
        "Login Type": "Email",
      });
    }
  };

  return (
    <View testID="login-to-see" style={styles.container}>
      <View style={styles.fullWidthView}>
        <GoogleButton onPress={loginWithGG} />
      </View>
      <Text size={"md"} weight={"semibold"} align={"center"}>
        or
      </Text>

      <View style={styles.fullWidthView}>
        <TextField
          testID="login-email"
          value={values.email}
          onChange={(email) => setFieldValue("email", email.trim())}
          placeholder="Work email"
          invalid={changed.email && !!errors.email}
          invalidText={changed.email ? errors.email : ""}
          onSubmitEditing={onSubmit}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    gap: 16,
  },
  fullWidthView: {
    width: "100%",
  },
});
