import { useManager } from "@splitsoftware/splitio-react";
import { SignInModal } from "components/sign_in_modal";
import { Spinner } from "components/spinner";
import { useOpenDirectoryFeatureFlag } from "providers/splitio";
import React, { Fragment, useEffect, useState } from "react";
import { View } from "react-native";
import { atom, useRecoilState } from "recoil";

interface AppLoaderProps {
  children: React.ReactNode;
}

export const signInModalState = atom<boolean>({
  key: "signInModalState",
  default: false,
});
// This component should contain all loading logic for the app before rendering items
// TODO: there is auth, graphql and other stuff that should be included here
export function AppLoader(props: AppLoaderProps) {
  const { children } = props;
  const manager = useManager();
  const [ready, setReady] = useState(false);
  const [showLoginModal, setShowLoginModal] = useRecoilState(signInModalState);
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  useEffect(() => {
    manager?.ready().then(() => setReady(true));
  }, [manager]);

  if (!ready) {
    return (
      <View style={{ paddingVertical: 120 }}>
        <Spinner />
      </View>
    );
  }

  return (
    <Fragment>
      {children}
      {openDirectoryFeatureFlag && showLoginModal && (
        <SignInModal
          isVisible={true}
          onClose={() => setShowLoginModal(false)}
        />
      )}
    </Fragment>
  );
}
