import { useState } from "react";
import { View, StyleSheet } from "react-native";

import { Text } from "components/text_v2";
import { DialogModal } from "components/dialog_modal";
import { TextInput } from "components/text_input";
import { Spacer } from "components/spacer";
import { Button } from "components/button_v2";
import { useMediaQuery } from "lib/media_query";

interface PropsType {
  username: string;
  isVisible: boolean;
  onClose: () => void;
  onClickDecline: (reason: string) => void;
}

export const DeclineRequestDialog: React.FC<PropsType> = ({
  username,
  isVisible,
  onClose,
  onClickDecline,
}) => {
  const {
    deviceQuery: { mobile: isMobile },
  } = useMediaQuery();

  const [reasonValue, setReasonValue] = useState("");

  return (
    <DialogModal
      headerTitle="Decline booking request"
      isVisible={isVisible}
      desktopWidth={780}
      bottomButtons={
        <Button
          testID="request-decline-and-send-button"
          text="Decline and send"
          size={isMobile ? "auto" : "medium"}
          disabled={reasonValue.trim().length === 0}
          onPress={() => onClickDecline(reasonValue.trim())}
        />
      }
      onClose={onClose}
    >
      <View>
        <View style={styles.descriptionWrapper}>
          <Text
            weight={isMobile ? "regular" : "semibold"}
            size="xs"
            color={isMobile ? "black-core" : "black-70"}
          >
            To decline a booking request, please add a note for {username}.
          </Text>
        </View>
        <View>
          <Text weight="semibold" size="xs">
            Reason for decline
          </Text>
          <Spacer size={8} />
          <TextInput
            testID="decline-request-reason-textarea"
            placeholder="Please add a note here."
            numberOfLines={4}
            value={reasonValue}
            onChange={setReasonValue}
          />
        </View>
        <Spacer size={200} />
      </View>
    </DialogModal>
  );
};

const styles = StyleSheet.create({
  descriptionWrapper: {
    marginVertical: 24,
  },
});
