import { Icon } from "components/icon";
import { Row } from "components/row";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { Pressable, StyleSheet, View } from "react-native";
import { colors } from "components/colors";
import { SelectInputOptionType } from "./select_input";

interface SelectInputOptionProps {
  option: SelectInputOptionType;
  selected?: boolean;
  testID?: string;
  onSelect?: (option: SelectInputOptionType) => void;
  withCheckbox?: boolean;
  labelProp?: string;
}

const noop = () => {};

export function SelectInputOption(props: SelectInputOptionProps) {
  const {
    testID,
    option,
    selected,
    onSelect = noop,
    withCheckbox,
    labelProp = "label",
  } = props;

  return (
    <Pressable testID={testID} onPress={() => onSelect(option)}>
      <View style={[styles.option, selected && styles.optionSelected]}>
        <Row alignItems="center" justifyContent="space-between">
          <View style={styles.labelWrapper}>
            <Text
              size="xs"
              color={option.highlight ? "eggplant-core" : "black-core"}
            >
              {option[labelProp]}
            </Text>
            {option.highlight && <View style={styles.dot} />}
          </View>
          {withCheckbox && (
            <View style={[styles.checkbox, selected && styles.selected]}>
              {selected && <Icon name="check" size="sm" color="white" />}
            </View>
          )}
        </Row>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  option: {
    padding: 8,
  },
  optionSelected: {
    backgroundColor: tokens.colors.primary["eggplant-10"],
  },
  checkbox: {
    width: 20,
    height: 20,
    backgroundColor: tokens.colors.neutral.dark,
    borderColor: tokens.colors.neutral.darker,
    borderWidth: 1,
    borderRadius: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  selected: {
    backgroundColor: tokens.colors.primary.regular,
  },
  labelWrapper: {
    flexDirection: "row",
    gap: 2,
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: colors.brand.eggplantcore,
  },
});
