import React from "react";
import { TimePicker } from "components/time_picker";
import { TimeInterval, Time, parseTime } from "lib/time_utils";
import { format } from "date-fns";
import { Spacer } from "components/spacer";
import { Row } from "components/row";
import { Text } from "components/text";
import { Container } from "components/container";
import { TimeSlot } from "core/booking_date_range_picker";
import { TimePickerV3 } from "components/time_picker_v3";
import { Icon } from "components/iconv2";

export interface TimeRangePickerProps {
  value?: Partial<TimeInterval> | null;
  onChange?: (value: Partial<TimeInterval>) => void;
  timeslots: TimeSlot[];
  disabled?: boolean;
  appearance?: "v3" | "v4";
}

export function TimeRangePicker(props: TimeRangePickerProps) {
  const { onChange, value, disabled, timeslots, appearance } = props;
  const { start, end } = value || {};

  const handleStartTimeChange = React.useCallback(
    (time: Time) => {
      if (!onChange) {
        return;
      }

      const startTimeSlots = timeslots.find((t) => t.startTime === time);
      const hasMatchingEndTime = startTimeSlots?.endTimes.find(
        (e) => e === end,
      );

      onChange({
        start: time,
        end: hasMatchingEndTime ? end : startTimeSlots?.endTimes[0],
      });
    },
    [end, onChange, timeslots],
  );

  const handleEndTimeChange = React.useCallback(
    (time: Time) => {
      if (!onChange) {
        return;
      }

      onChange({
        start,
        end: time,
      });
    },
    [start, onChange],
  );

  const startTimes = timeslots.map((ts) => ts.startTime);
  const betterRateHours = timeslots
    .filter((s) => s.hasBetterRate)
    .map((s) => s.startTime);
  let endTimes: Time[] = [];
  if (start) {
    const startTimeslots = timeslots.find((ts) => ts.startTime === start);
    if (startTimeslots) {
      endTimes = startTimeslots.endTimes;
    }
  }

  if (appearance === "v3") {
    return (
      <Row alignItems="center">
        <TimePickerV3
          value={start}
          placeholder="Start time"
          hours={startTimes}
          onChange={handleStartTimeChange}
          formatter={(time) => format(parseTime(time), "h:mm a")}
          disabled={disabled}
          testID="start-time"
        />
        <Spacer size={19} />
        <Icon name="big-arrow-right" />
        <Spacer size={19} />
        <TimePickerV3
          value={end}
          placeholder="End time"
          disabled={!start}
          hours={endTimes}
          testID="end-time"
          formatter={(time) => format(parseTime(time), "h:mm a")}
          onChange={handleEndTimeChange}
        />
      </Row>
    );
  }

  return (
    <Row alignItems="center">
      <Container flex={1}>
        <TimePicker
          value={start}
          label="Start time"
          hours={startTimes}
          onChange={handleStartTimeChange}
          formatter={(time) => format(parseTime(time), "h:mm a")}
          disabled={disabled}
          testID="start-time"
          betterRateHours={betterRateHours}
        />
      </Container>
      <Spacer size={8} />
      {appearance === "v4" ? <Icon name="big-arrow-right" /> : <Text>to</Text>}
      <Spacer size={8} />
      <Container flex={1}>
        <TimePicker
          value={end}
          label="End time"
          disabled={!start}
          hours={endTimes}
          testID="end-time"
          formatter={(time) => format(parseTime(time), "h:mm a")}
          onChange={handleEndTimeChange}
        />
      </Container>
    </Row>
  );
}
