import { Fragment, useCallback, useState } from "react";
import { Pressable, View, StyleSheet } from "react-native";

import { Carousel } from "components/carousel";
import { Column } from "components/column";
import { ImageGalleryModal } from "components/image_gallery_modal";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { SpaceType, Image } from "core/graphql.generated";
import { Icon } from "components/iconv2";
import { useMediaQuery } from "lib/media_query";
import { colors } from "components/colors";

interface SpaceDetailImageSize {
  large: Image["large"];
  medium: Image["medium"];
  small: Image["small"];
}

interface SpaceDetailImageGalleryProps {
  images: SpaceDetailImageSize[];
  spaceName: string;
  spaceType: SpaceType;
  partnerId?: string | null;
}

export const iWGSpacePartnerId = "69c4ef94-c6c1-4888-811b-88c76589c4b0";
export const iWGRegusPartnerId = "3bbd4142-3a0a-410c-8042-a015dab175a2";

const IndicativeLabel = () => (
  <View style={styles.labelContainer}>
    <Icon name={"camera"} />
    <Spacer size={4} direction={"row"} />
    <Text color={"white-core"} weight={"semibold"} size={"xs"}>
      Photo is a representation of the space
    </Text>
  </View>
);

export function SpaceDetailImageGallery({
  images,
  spaceName,
  spaceType,
  partnerId,
}: SpaceDetailImageGalleryProps) {
  const mq = useMediaQuery();

  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const isStockPhoto =
    spaceType !== SpaceType.DayPass &&
    (partnerId === iWGSpacePartnerId || partnerId === iWGRegusPartnerId);

  const items = images.map((image) => ({
    src: image.large.url,
    w: image.large.width,
    h: image.large.height,
  }));

  const handleOpen = useCallback((newIndex) => {
    setIsOpen(true);
    setIndex(newIndex);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (images.length === 0) {
    return null;
  }

  let height = 260;

  if (mq.deviceQuery.mobile) {
    return (
      <View style={styles.root}>
        <View style={{ overflow: "hidden", height, width: "100%" }}>
          {images.length > 1 && (
            <View
              style={{
                borderRadius: tokens.radius,
                backgroundColor: "rgba(245, 246, 247, 0.5)",
                padding: 4,
                position: "absolute",
                bottom: 16,
                right: 16,
                zIndex: 1,
              }}
            >
              <Text size="xs">
                {index + 1} of {images.length}
              </Text>
            </View>
          )}
          <Carousel>
            {images.map((image, i) => (
              <Pressable key={image.small.url} onPress={() => handleOpen(i)}>
                <View style={{ height }}>
                  {isStockPhoto && i === 0 && <IndicativeLabel />}
                  <img
                    src={image.small.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt={`${spaceName} cover 1`}
                  />
                </View>
              </Pressable>
            ))}
          </Carousel>
          <ImageGalleryModal
            isOpen={isOpen}
            index={index}
            items={items}
            onClose={handleClose}
          />
        </View>
      </View>
    );
  }

  height = 440;
  return (
    <View style={styles.root}>
      <View style={{ height }}>
        <Row>
          <View style={{ height, flex: 6, overflow: "hidden" }}>
            <Pressable style={{ height }} onPress={() => handleOpen(0)}>
              {isStockPhoto && <IndicativeLabel />}
              <img
                src={images[0].large.url}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt={`${spaceName} cover 1`}
              />
            </Pressable>
          </View>
          {images.length === 2 && (
            <Fragment>
              <Spacer size={16} />
              <View style={{ height, flex: 6, overflow: "hidden" }}>
                <Pressable style={{ height }} onPress={() => handleOpen(1)}>
                  <img
                    src={images[1].large.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt={`${spaceName} cover 2`}
                  />
                </Pressable>
              </View>
            </Fragment>
          )}
          {images.length >= 3 && (
            <>
              <Spacer size={16} />
              <View style={{ flex: 3 }}>
                <Column>
                  <View style={{ overflow: "hidden" }}>
                    <Pressable
                      style={{ height: (height - 16) / 2 }}
                      onPress={() => handleOpen(1)}
                    >
                      <img
                        src={images[1].medium.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={`${spaceName} cover 2`}
                      />
                    </Pressable>
                  </View>
                  <Spacer size={16} />
                  <View style={{ overflow: "hidden" }}>
                    <Pressable
                      style={{ height: (height - 16) / 2 }}
                      onPress={() => handleOpen(2)}
                    >
                      <img
                        src={images[2].medium.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={`${spaceName} cover 3`}
                      />
                    </Pressable>
                  </View>
                </Column>
              </View>
            </>
          )}
        </Row>
      </View>
      {images.length > 3 && (
        <View style={{ left: 16, bottom: 16, position: "absolute" }}>
          <Pressable onPress={() => handleOpen(0)}>
            <View
              style={{
                borderRadius: 4,
                backgroundColor: colors.brand.whitecore,
                padding: 8,
              }}
            >
              <Text size="sm">View all images</Text>
            </View>
          </Pressable>
        </View>
      )}
      <ImageGalleryModal
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: "relative",
    zIndex: 1,
    borderRadius: 4,
    overflow: "hidden",
  },
  labelContainer: {
    display: "flex",
    position: "absolute",
    top: 16,
    left: 18,
    flexDirection: "row",
    alignItems: "center",
  },
});
