import { CopyShareLinkButton } from "components/copy_share_link_button";
import { DateRange } from "core/booking_date_range_picker";
import { BookingType } from "core/graphql.generated";
import { formatDateRange } from "lib/date_utils";
import { formatTimeRange } from "lib/time_utils";

interface SpaceDetailCopyShareLinkButtonProps {
  spaceName: string;
  spaceId: string;
  dateRange?: DateRange;
}

export function SpaceDetailCopyShareLinkButton({
  spaceId,
  spaceName,
  dateRange,
}: SpaceDetailCopyShareLinkButtonProps) {
  const onCopyPress = () => {
    let dateTimeRange: string | null = null;
    if (dateRange) {
      if (
        dateRange.type === BookingType.DailyBooking &&
        dateRange.startDate &&
        dateRange.endDate
      ) {
        dateTimeRange = formatDateRange(
          "MMM d",
          "-",
          dateRange.startDate,
          dateRange.endDate,
        );
      } else if (
        dateRange.type === BookingType.HourlyBooking &&
        dateRange.date
      ) {
        dateTimeRange = formatDateRange(
          "MMM d",
          "-",
          dateRange.date,
          dateRange.date,
        );
        if (dateRange.startTime && dateRange.endTime) {
          dateTimeRange += `, ${formatTimeRange(
            "h:mm a",
            "-",
            dateRange.startTime,
            dateRange.endTime,
          )}`;
        }
      }
    }

    navigator.clipboard.writeText(
      `Hi there! I’ve booked ${spaceName} on Flexspace and want you to come work with me${
        dateTimeRange ? ` on ${dateTimeRange}` : ""
      }. ${location.origin}/offsite_spaces/${spaceId}`,
    );
  };

  return <CopyShareLinkButton title="Copy link" onPress={onCopyPress} />;
}
