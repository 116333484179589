import { LinkWrapper } from "components/link_wrapper";
import { Text } from "components/text_v2";
import { SpaceLocationDetailFragment } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { LocationCard } from "pages/homev3/components/location_card";
import { placeholderLocationImage } from "pages/location_details/utils/get_placeholder_location_image";
import { ImageBackground, StyleSheet, View } from "react-native";

type HeroLocationCardProps = {
  location: SpaceLocationDetailFragment;
  title: string;
  subtitle: string;
  onPress?: () => void;
};
export function HeroLocationCard({
  location,
  title,
  subtitle,
  onPress,
}: HeroLocationCardProps) {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  let uri;

  if (isMobile) {
    uri = location.images[0]?.small.url || placeholderLocationImage;
  } else {
    uri = location.images[0]?.medium.url || placeholderLocationImage;
  }

  return (
    <LinkWrapper
      style={{ flex: 1 }}
      onPress={onPress}
      to={`/location/${location.id}`}
    >
      <ImageBackground
        source={{ uri }}
        style={styles.container}
        imageStyle={styles.imageStyle}
      >
        <View style={isMobile ? styles.overlayMobile : styles.overlay}>
          <View style={isMobile ? styles.titleMobile : styles.title}>
            <Text size={isMobile ? "xs" : "md"} color={"white-core"}>
              {title}
            </Text>

            <View style={styles.linkContainer}>
              <Text
                color={"white-core"}
                size={isMobile ? "lg" : "mega"}
                weight={"bold"}
                align={"left"}
              >
                {subtitle}
              </Text>
            </View>
          </View>
          {!isMobile && <LocationCard location={location} peoples={[]} />}
        </View>
      </ImageBackground>
    </LinkWrapper>
  );
}

const styles = StyleSheet.create({
  title: {
    flex: 1,
    gap: 8,
    maxWidth: 462,
  },
  titleMobile: {
    flex: 1,
    gap: 8,
  },
  overlay: {
    flex: 1,
    height: 391,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 72,
    paddingVertical: 64,
    borderRadius: 4,
    backgroundColor:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
  },
  overlayMobile: {
    height: 240,
    flexDirection: "row",
    alignItems: "center",
    padding: 24,
    borderRadius: 4,
    backgroundColor:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
  },
  container: {
    flex: 1,
    borderRadius: 4,
  },
  imageStyle: {
    borderRadius: 4,
  },
  linkContainer: {
    flex: 1,
    width: "100%",
  },
});
