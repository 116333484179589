import { useQueryString } from "lib/query_string";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

export type BudgetTab = "current" | "policy";

interface BudgetsQueryParams {
  currentTab: BudgetTab;
}

export function useBudgetTabs(): [BudgetTab, (value: BudgetTab) => void] {
  const { currentTab } = useQueryString<BudgetsQueryParams>();
  const history = useHistory();

  const updateCurrentTab = useCallback(
    (value: BudgetTab) => {
      const params = new URLSearchParams();

      params.set("currentTab", value);
      const qs = `?${params.toString()}`;

      history.replace(`/admin/budgets${qs}`);
    },
    [history],
  );

  useEffect(() => {
    if (!currentTab) {
      updateCurrentTab("current");
    }
  }, [currentTab, updateCurrentTab]);

  return [currentTab || "current", updateCurrentTab];
}
