import React from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "./colors";
import { Spacer } from "./spacer";

interface PageContainerProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  progressBar?: React.ReactNode;
}

export function AuthPageContainer(props: PageContainerProps) {
  const { header, footer, children, progressBar } = props;

  return (
    <View style={styles.pageWrapper}>
      {header}
      {progressBar}
      <Spacer size={51} />
      {children}
      {footer}
    </View>
  );
}

const styles = StyleSheet.create({
  pageWrapper: {
    backgroundColor: colors.brand.backgroundMain,
    flex: 1,
    minHeight: "100vh",
  },
});
