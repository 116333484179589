import React from "react";
import { Link } from "react-router-dom";

interface LinkWrapperProps {
  children: React.ReactNode;
  to: string;
  testID?: string;
  onPress?: () => void;
  style?: React.CSSProperties;
}

export function LinkWrapper(props: LinkWrapperProps) {
  const { testID, children, to, onPress, style } = props;

  return (
    <Link
      onClick={onPress}
      data-testid={testID}
      style={{ textDecoration: "none", ...style }}
      to={to}
    >
      {children}
    </Link>
  );
}
