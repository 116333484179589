import {
  DatePickerChildrenType,
  DatePickerDropdown,
} from "components/date_picker/date_picker_dropdown";
import { DatePickerScrollableDialog } from "components/date_picker/date_picker_scrollable_dialog";
import { formatDate } from "lib/date_utils";
import { parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";
import { SelectDateTab } from "pages/team_spaces/components/tabs/select_date_tab";
import { useMyTeamSearchParamsMutation } from "pages/team_spaces/hooks/use_my_team_search_params";
import React, { useCallback, useEffect, useState } from "react";

interface HeaderDatePickerProps {
  onPress: () => void;
  isPicked: boolean;
}

export function SelectDatePicker(props: HeaderDatePickerProps) {
  const { isPicked, onPress } = props;
  const [pickedDate, setPickedDate] = useState<string | null>(null);
  const searchParam = useMyTeamSearchParamsMutation();
  const onchange = useCallback(
    (value: string) => {
      searchParam({
        date: value || null,
      });
      setPickedDate(value);
    },
    [searchParam],
  );
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  useEffect(() => {
    if (!isPicked) {
      setPickedDate(null);
    }
  }, [isPicked]);
  const renderButton = ({ onToggleOpen }: DatePickerChildrenType) => (
    <SelectDateTab
      text={
        isPicked && pickedDate
          ? formatDate(parseDay(pickedDate), getSystemLocale(), {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "Select date"
      }
      isPicked={isPicked}
      onPress={() => {
        onToggleOpen();
        onPress();
      }}
      iconName={"calendar"}
    />
  );

  if (isMobile) {
    return (
      <DatePickerScrollableDialog
        value={pickedDate}
        onChange={onchange}
        withOutClearBtnMode={true}
      >
        {renderButton}
      </DatePickerScrollableDialog>
    );
  }

  return (
    <DatePickerDropdown
      value={pickedDate}
      onChange={onchange}
      confirmMode={false}
    >
      {renderButton}
    </DatePickerDropdown>
  );
}
