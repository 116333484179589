import { CopyShareLinkButton } from "components/copy_share_link_button";

interface LocationDetailCopyShareLinkButtonProps {
  locationId: string;
}

export function LocationDetailCopyShareLinkButton({
  locationId,
}: LocationDetailCopyShareLinkButtonProps) {
  const onCopyPress = () => {
    navigator.clipboard.writeText(`${location.origin}/location/${locationId}`);
  };

  return <CopyShareLinkButton title="Copy link" onPress={onCopyPress} />;
}
