import { createRoutes, CreateRoutesOptions } from "core/routes";
import { matchPathname } from "./pathname";

export const createRoutesForAnalytics = ({
  reBookFeatureFlag,
  favoritesFeatureFlag,
}: CreateRoutesOptions = {}) =>
  Object.values(
    createRoutes({ reBookFeatureFlag, favoritesFeatureFlag }),
  ).reduce<Record<string, { pageName: string }>>((acc, item) => {
    acc[item.path] = { pageName: item.pageName };
    return acc;
  }, {});

export function getPageNameForAnalytics(
  pathname: string,
  options: CreateRoutesOptions = {},
): string | null {
  const routes = createRoutesForAnalytics(options);
  for (let path of Object.keys(routes)) {
    const match = matchPathname(path, pathname);
    if (match) {
      return routes[path].pageName;
    }
  }

  return null;
}
