import { useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { TabsV3 } from "components/tab/tabs_v3";
import { tokens } from "components/tokens";
import { DialogContent } from "core/dialog_content";
import {
  LocationPreviewSpacesQuery,
  LocationPreviewSpacesQueryVariables,
  SpaceType,
} from "core/graphql.generated";
import { locationPreviewQuery } from "pages/homev2/components/location_preview";
import { PreviewDayPassList } from "pages/team_spaces/components/preview_daypass_item";
import { PreviewMeetingRoomList } from "pages/team_spaces/components/preview_meeting_room_item";
import { PreviewPrivateOfficeList } from "pages/team_spaces/components/preview_private_office_item";
import { useMyTeamSearchParamsQuery } from "pages/team_spaces/hooks/use_my_team_search_params";
import React, { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

interface LocationPreviewProps {
  locationPreviewData: {
    timezone: string;
    id: string;
    name: string;
    address: {
      streetAddress: string;
      city: string;
    };
  };
  onClose: () => void;
}

const spaceTabOptions = [
  { value: SpaceType.DayPass, label: "Day passes" },
  { value: SpaceType.DayOffice, label: "Private office" },
  { value: SpaceType.MeetingRoom, label: "Meeting rooms" },
];
export function TeamLocationPreview(props: LocationPreviewProps) {
  const { locationPreviewData, onClose } = props;
  const { date } = useMyTeamSearchParamsQuery();
  const [currentTab, setCurrentTab] = useState<SpaceType>(SpaceType.DayPass);
  const handleTabChange = (tab: SpaceType) => {
    setCurrentTab(tab);
  };

  const { data, loading, error } = useQuery<
    LocationPreviewSpacesQuery,
    LocationPreviewSpacesQueryVariables
  >(locationPreviewQuery, {
    variables: {
      filter: { date },
      dayPassFilter: { date },
      offsiteLocationID: locationPreviewData.id,
    },
  });

  return (
    <DialogContent
      headerRightIcon="x-circle"
      onHeaderRightIconPress={onClose}
      headerTitle={`Select a space at this location`}
      desktopWidth={720}
    >
      <View
        testID="team-spaces-location-preview-container"
        style={styles.dialogInfo}
      >
        <Spacer size={16} />
        <TabsV3
          value={currentTab}
          options={spaceTabOptions}
          onChange={handleTabChange}
        />
      </View>
      <Spacer size={16} />
      <Divider />
      <View style={styles.dialogContentWrapper}>
        <AsyncRenderer error={error} data={data} loading={loading}>
          {(data) => (
            <ScrollView>
              {currentTab === SpaceType.DayPass && (
                <PreviewDayPassList spaces={data.dayPasses?.spaces || []} />
              )}
              {currentTab === SpaceType.DayOffice && (
                <PreviewPrivateOfficeList
                  spaces={data.dayOffices?.spaces || []}
                />
              )}
              {currentTab === SpaceType.MeetingRoom && (
                <PreviewMeetingRoomList
                  spaces={data.meetingRooms?.spaces || []}
                />
              )}
            </ScrollView>
          )}
        </AsyncRenderer>
      </View>
    </DialogContent>
  );
}

const styles = StyleSheet.create({
  dialogContentWrapper: {
    flex: 1,
  },
  dialogInfo: {
    paddingHorizontal: 24,
  },
  dialogFooter: {
    padding: 16,
    borderTopWidth: 1,
    borderColor: tokens.colors.neutral.darker,
    alignItems: "flex-end",
  },
  emptyPlaceholder: {
    paddingVertical: 44,
  },
});
