import {
  ReportingPageOrganizationBudgetQuery,
  ReportingPageOrganizationTotalSpendQuery,
  ReportingPageOrganizationTotalSpendQueryVariables,
  UpdateOrganizationBudgetReportingBudgetCardHookMutation,
  UpdateOrganizationBudgetReportingBudgetCardHookMutationVariables,
} from "core/graphql.generated";
import { useCallback, useEffect, useMemo, useState } from "react";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  reportingPageOrganizationBudgetGQLQuery,
  reportingPageOrganizationReportingTotalSpendGQLQuery,
} from "../gql_queries";
import { useQueryString } from "lib/query_string";
import { DayInterval } from "lib/day_utils";
import {
  getDayIntervalFromBudgetsDateRangePickerOption,
  budgetsDateRangePickerOptions,
} from "components/budgets_date_range_picker/budgets_date_range_picker_common";

interface ReportingPageSearchParams {
  start?: string;
  end?: string;
}

interface PropTypes {
  fetchTotalSpent: boolean;
}

export function useReportingBudgetCard({ fetchTotalSpent }: PropTypes) {
  const sp = useQueryString<ReportingPageSearchParams>();
  const dayInterval: DayInterval = useMemo(() => {
    if (sp.start && sp.end) {
      return {
        start: sp.start,
        end: sp.end,
      };
    }

    return getDayIntervalFromBudgetsDateRangePickerOption(
      budgetsDateRangePickerOptions[0].value,
    );
  }, [sp]);
  const apolloClient = useApolloClient();
  const { loading, data } = useQuery<ReportingPageOrganizationBudgetQuery>(
    reportingPageOrganizationBudgetGQLQuery,
  );

  const { data: totalSpendData } = useQuery<
    ReportingPageOrganizationTotalSpendQuery,
    ReportingPageOrganizationTotalSpendQueryVariables
  >(reportingPageOrganizationReportingTotalSpendGQLQuery, {
    skip: !fetchTotalSpent,
    variables: {
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
    fetchPolicy: "network-only",
  });

  const budget = data?.currentUser?.organization?.budget;

  const [
    updateBudget,
    { loading: isUpdateBudgetLoading, error: updateBudgetError },
  ] = useMutation<
    UpdateOrganizationBudgetReportingBudgetCardHookMutation,
    UpdateOrganizationBudgetReportingBudgetCardHookMutationVariables
  >(updateBudgetGQLMutation);
  const [editing, setEditing] = useState(false);
  const [confirmationModalState, setConfirmationModalState] = useState(false);
  const [budgetInputValue, setBudgetInputValue] = useState(
    String(budget?.budget || 0),
  );

  const onBudgetInputChange = useCallback(
    (value) => setBudgetInputValue(value.replace(/[^0-9]/g, "")),
    [],
  );
  const cancelEditing = useCallback(() => {
    setBudgetInputValue(String(budget?.budget || 0));
    setEditing(false);
  }, [budget]);
  const onBudgetInputSubmit = useCallback(() => {
    setConfirmationModalState(true);
  }, []);

  const submitNewBudget = async () => {
    const { data } = await updateBudget({
      variables: {
        input: {
          limit: +budgetInputValue,
        },
      },
    });

    if (data?.updateOrganizationBudget) {
      setEditing(false);
      setBudgetInputValue(String(budget?.budget || 0));
      setConfirmationModalState(false);
      apolloClient.resetStore();
    }
  };

  // update initial input value after budget fetching
  useEffect(() => setBudgetInputValue(String(budget?.budget || 0)), [budget]);

  return {
    editing,
    isLoading: loading,
    isUpdateBudgetLoading,
    confirmationModalState,
    onBudgetInputChange,
    cancelEditing,
    onBudgetInputSubmit,
    submitNewBudget,
    budgetInputValue,
    setConfirmationModalState,
    setEditing,
    budget,
    updateBudgetError: (updateBudgetError as any)?.message || "",
    totalSpendByDate:
      totalSpendData?.currentUser?.organization?.reporting?.spend?.totalSpend ||
      0,
  };
}

export const updateBudgetGQLMutation = gql`
  mutation UpdateOrganizationBudgetReportingBudgetCardHook(
    $input: UpdateOrganizationBudgetInput!
  ) {
    updateOrganizationBudget(input: $input) {
      budget
    }
  }
`;
