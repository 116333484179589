import { AuthPageContainer } from "components/auth_page_container";
import { SignUpProgressBar } from "components/sign_up_shared/progress_bar";
import { StepTeamNameUI } from "components/sign_up_shared/step_team_name_ui";
import { Footer } from "core/new_footer";
import { OnboardingHeader } from "core/onboarding_header";
import { useMediaQuery } from "lib/media_query";
import { SignUpStepFullName } from "pages/sign_up/components/step_full_name";
import { ProtectedRoute } from "protected_route";
import { SIGN_UP_STEPS_REDIRECT_URL_KEY } from "providers/auth_loader";
import { useOpenDirectoryFeatureFlag } from "providers/splitio";
import { useCallback, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { SignUpStepCode } from "./components/step_code";
// import { SignUpStepPickLocation } from "./components/step_pick_location";
import { SignUpStepFinal } from "./components/step_final";
import { SignUpStepGetStarted } from "./components/step_get_started";
import { SignUpStepInviteOthers } from "./components/step_invite_others";
import { SignUpStepPassword } from "./components/step_password";

export interface SignUpCredentials {
  email: string;
  fullName: string;
  authenticationError?: string;
}

const SIGN_UP_OLD_STEPS = [
  {
    path: "/sign-up/get-started",
    component: SignUpStepGetStarted,
  },
  {
    path: "/sign-up/code",
    component: SignUpStepCode,
  },
  {
    path: "/sign-up/password",
    component: SignUpStepPassword,
  },
  {
    path: "/sign-up/team-name",
    component: StepTeamNameUI,
  },
  {
    path: "/sign-up/invite-others",
    component: SignUpStepInviteOthers,
    canBeAccessedAnytime: true,
  },
  // {
  //   path: "/sign-up/location",
  //   component: SignUpStepPickLocation,
  // },
  {
    path: "/sign-up/final",
    component: SignUpStepFinal,
    canBeAccessedAnytime: true,
  },
];

const OPEN_DIRECTORY_SIGN_UP_STEPS = [
  {
    path: "/sign-up/get-started",
    component: SignUpStepGetStarted,
  },
  {
    path: "/sign-up/full-name",
    component: SignUpStepFullName,
  },
  {
    path: "/sign-up/code",
    component: SignUpStepCode,
  },
  {
    path: "/sign-up/password",
    component: SignUpStepPassword,
  },
  {
    path: "/sign-up/team-name",
    component: StepTeamNameUI,
  },
  {
    path: "/sign-up/invite-others",
    component: SignUpStepInviteOthers,
    canBeAccessedAnytime: true,
  },
  // {
  //   path: "/sign-up/location",
  //   component: SignUpStepPickLocation,
  // },
  {
    path: "/sign-up/final",
    component: SignUpStepFinal,
    canBeAccessedAnytime: true,
  },
];

export function SignUpPage() {
  const history = useHistory();
  const savedCreds = localStorage.getItem("sign-up");
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  const [credentials, setCredentialsState] = useState<SignUpCredentials>(
    savedCreds ? JSON.parse(savedCreds) : { fullName: "", email: "" },
  );

  const SIGN_UP_STEPS = openDirectoryFeatureFlag
    ? OPEN_DIRECTORY_SIGN_UP_STEPS
    : SIGN_UP_OLD_STEPS;
  const setCredentials = (creds: SignUpCredentials) => {
    localStorage.setItem("sign-up", JSON.stringify(creds));
    setCredentialsState(creds);
  };
  const mq = useMediaQuery();

  const goBack = useCallback(() => {
    const path = history.location.pathname;
    const currentStepIndex = SIGN_UP_STEPS.findIndex(
      (step) => step.path === path,
    );

    if (currentStepIndex > 0) {
      history.push(SIGN_UP_STEPS[currentStepIndex - 1].path);
    }
  }, [SIGN_UP_STEPS, history]);

  const goNext = useCallback(() => {
    const path = history.location.pathname;
    const currentStepIndex = SIGN_UP_STEPS.findIndex(
      (step) => step.path === path,
    );

    history.push(
      currentStepIndex < SIGN_UP_STEPS.length - 1
        ? SIGN_UP_STEPS[currentStepIndex + 1].path
        : "/",
    );
  }, [SIGN_UP_STEPS, history]);

  const onGoStep = useCallback(
    (index) => {
      history.push(SIGN_UP_STEPS[index].path);
    },
    [SIGN_UP_STEPS, history],
  );

  const path = history.location.pathname;
  const currentStepIndex = SIGN_UP_STEPS.findIndex(
    (step) => step.path === path,
  );
  const isFirstStep = path === SIGN_UP_STEPS[0].path;

  const shouldShowHeader =
    !mq.deviceQuery.mobile || (isFirstStep && mq.deviceQuery.mobile);

  const goToSignIn = () => {
    localStorage.removeItem("sign-up");
    localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
    history.push("/sign-in");
  };
  const progress =
    currentStepIndex >= 0 ? currentStepIndex / (SIGN_UP_STEPS.length - 1) : 0;

  return (
    <AuthPageContainer
      header={
        shouldShowHeader && (
          <OnboardingHeader buttonTitle={"Log in"} onButtonClick={goToSignIn} />
        )
      }
      footer={<Footer />}
      progressBar={<SignUpProgressBar progress={progress} />}
    >
      <Switch>
        {SIGN_UP_STEPS.map((step, index) => {
          const { component: Component, ...data } = step;

          // from team name step forward, user should have been logged in
          if (index >= 3) {
            return (
              <ProtectedRoute key={data.path} path={data.path}>
                <Component
                  onGoBack={goBack}
                  onGoNext={goNext}
                  onGoStep={onGoStep}
                  setCredentials={setCredentials}
                  credentials={credentials}
                />
              </ProtectedRoute>
            );
          }

          return (
            <Route key={data.path} path={data.path}>
              <Component
                onGoBack={goBack}
                onGoNext={goNext}
                onGoStep={onGoStep}
                setCredentials={setCredentials}
                credentials={credentials}
              />
            </Route>
          );
        })}

        <Redirect to={SIGN_UP_STEPS[0].path} />
      </Switch>
    </AuthPageContainer>
  );
}
