import React from "react";
import { StyleSheet, View } from "react-native";

import { tokens } from "./tokens";

type Shape = "rounded" | "square" | "pill";

type Direction = "column" | "row";
interface ContainerContext {
  direction: Direction;
}

const ContainerContext = React.createContext<ContainerContext>({
  direction: "column",
});

interface ContainerProviderProps {
  children?: React.ReactNode;
  direction: Direction;
}

export function ContainerProvider(props: ContainerProviderProps) {
  const { children, direction } = props;

  return (
    <ContainerContext.Provider value={{ direction }}>
      {children}
    </ContainerContext.Provider>
  );
}

export function useParentContainer() {
  return React.useContext(ContainerContext);
}

interface ContainerProps {
  children?: React.ReactNode;
  maxWidth?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  color?: BackgroundColor;
  customColor?: string;
  borderRadius?: number;
  borderTopRightRadius?: number;
  borderTopLeftRadius?: number;
  borderBottomRightRadius?: number;
  borderBottomLeftRadius?: number;
  flex?: number;
  width?: number | string;
  height?: number | string;
  borderWidth?: number;
  borderTopWidth?: number;
  borderRightWidth?: number;
  borderLeftWidth?: number;
  borderBottomWidth?: number;
  borderColor?: string;
  paddingRight?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  padding?: number;
  position?: "absolute" | "relative";
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  shadow?: boolean;
  center?: boolean;
  overflow?: "visible" | "hidden" | "scroll";
  expanded?: boolean;
  testID?: string;
  shape?: Shape;
  zIndex?: number;
}

interface BackgroundColors {
  default: string;
  content: string;
  tint: string;
  primary: string;
  error: string;
  infoNotification: string;
  tooltip: string;
}

export type BackgroundColor = keyof BackgroundColors;

/**
 * Provides padding, background decorations, border decorations, sizing and other box styles.
 */
export function Container(props: ContainerProps) {
  const {
    children,
    expanded,
    color,
    customColor,
    width,
    flex,
    minHeight,
    maxWidth,
    minWidth,
    borderWidth,
    borderTopWidth,
    borderRightWidth,
    borderLeftWidth,
    borderBottomWidth,
    borderColor,
    borderRadius,
    borderTopRightRadius,
    borderTopLeftRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    paddingRight,
    paddingLeft,
    paddingTop,
    paddingBottom,
    paddingVertical,
    paddingHorizontal,
    padding,
    position,
    top,
    right,
    bottom,
    left,
    overflow,
    height,
    shape,
    testID,
    shadow,
    center,
    zIndex,
  } = props;

  const effectiveWidth = width ?? (expanded ? "100%" : undefined);
  const effectiveHeight = height ?? (expanded ? "100%" : undefined);

  return (
    <ContainerProvider direction="column">
      <View
        testID={testID}
        style={[
          color && styles[color],
          customColor && {
            backgroundColor: customColor,
          },
          {
            borderWidth,
            width: effectiveWidth,
            flex,
            maxWidth,
            minWidth,
            minHeight,
            borderTopRightRadius,
            borderTopLeftRadius,
            borderBottomRightRadius,
            borderBottomLeftRadius,
            borderRadius,
            height: effectiveHeight,
            paddingRight,
            paddingLeft,
            paddingTop,
            paddingBottom,
            paddingVertical,
            paddingHorizontal,
            padding,
            position,
            top,
            right,
            bottom,
            left,
            borderTopWidth,
            borderRightWidth,
            borderLeftWidth,
            borderBottomWidth,
            borderColor,
            overflow,
            zIndex,
          },
          borderWidth && !borderColor && styles.borderColor,
          shadow && styles.shadow,
          center && styles.center,
          shape && styles[shape],
        ]}
      >
        {children}
      </View>
    </ContainerProvider>
  );
}

const styles = StyleSheet.create({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  default: {
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
  content: {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  tint: {
    backgroundColor: "rgba(245, 246, 247, 1)",
  },
  primary: {
    backgroundColor: "rgba(82,68,134,1)",
  },
  error: {
    backgroundColor: "rgb(255, 248, 246)",
  },
  square: {},
  rounded: {
    borderRadius: tokens.radius,
  },
  pill: {
    borderRadius: 999,
  },
  shadow: tokens.shadow.elevation3,
  borderColor: {
    borderColor: tokens.colors.neutral.dark,
  },
  infoNotification: {
    backgroundColor: "rgba(185, 171, 238, 0.9)",
  },
  tooltip: {
    backgroundColor: "rgba(65, 69, 80, 0.9)",
  },
});
