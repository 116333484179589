import { AppHeader } from "components/app_header_v3/app_header";
import { DesktopLayout } from "components/layout/desktop_layout";
import { Footer } from "core/footer";

interface OffsiteDesktopLayoutProps {
  children: JSX.Element;
}

export function OffsiteSpaceDetailsDesktop({
  children,
}: OffsiteDesktopLayoutProps) {
  return (
    <DesktopLayout
      header={<AppHeader />}
      body={children}
      footer={<Footer />}
      stickyHeader={false}
    />
  );
}
