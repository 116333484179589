import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { Spacer } from "components/spacer";

interface ReportingBudgetWarningProps {
  onClick: () => void;
}

export function ReportingBudgetWarningMobile(
  props: ReportingBudgetWarningProps,
) {
  const { onClick } = props;

  return (
    <View style={styles.container}>
      <Text
        testID="monthly-budget-warning"
        color="white-core"
        weight="bold"
        align="center"
      >
        You’ve almost hit your monthly budget.
      </Text>
      <Spacer size={34} />
      <Pressable
        onPress={onClick}
        style={({ hovered, pressed }: any) => [
          styles.button,
          (hovered || pressed) && styles.buttonHoveredPressed,
        ]}
      >
        {() => (
          <Text size="xs" color="grapefruit-core">
            Increase Budget
          </Text>
        )}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: tokens.colors.secondary.regular,
    borderRadius: 6,
    color: "white",
    ...tokens.shadow.elevation1,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(245, 246, 247, 1)",
    height: 42,
    maxHeight: 42,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 4,
  },
  buttonHoveredPressed: {
    backgroundColor: "rgba(218, 222, 229, 1)",
  },
});
