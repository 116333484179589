import { gql, useQuery } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Divider } from "components/divider";
import { LoginToSee } from "components/login_to_see";
import { Spacer } from "components/spacer";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import {
  GetOrgDataQuery,
  GetOrgDataQueryVariables,
} from "core/graphql.generated";
import { PageLayout } from "pages/page_layout";
import { InviteYourCoWorkers } from "pages/team_spaces/components/invite_your_coworkers";
import { JoinYourTeam } from "pages/team_spaces/components/join_your_team";
import { ListSpacesSkeleton } from "pages/team_spaces/components/skeleton_loading/list_spaces_skeleton";
import { SelectDateTabsBar } from "pages/team_spaces/components/tabs/select_date_tabs_bar";
import { WhereMyTeam } from "pages/team_spaces/components/where_my_team/where_my_team";
import { YourTeamLoveSpace } from "pages/team_spaces/components/your_team_love_space";
import {
  cityDetailsFragment,
  teamsBookingDetailsFragment,
  teamsLocationDetailsFragment,
  teamsSpaceDetailsFragment,
  teamsUserDetailsFragment,
} from "pages/team_spaces/fragment";
import { useMyTeamQueryParamVariables } from "pages/team_spaces/hooks/use_my_team_query_param_variable";
import { useAnalytics } from "providers/analytics";
import { useAuthV2 } from "providers/authv2";
import React, { Fragment, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { BeFirstOneToBook } from "./components/be_first_one_to_book";

export function TeamSpacesPageV2() {
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.event("View Team", { Category: "Views" });
  }, [analytics]);
  return (
    <Fragment>
      <PageLayout
        header={<AppHeader />}
        body={<Team />}
        footer={
          <>
            <BottomBarNavigation />
            <Footer />
          </>
        }
      />
    </Fragment>
  );
}

function Team() {
  const { variables, currentUserLoading } = useMyTeamQueryParamVariables();

  const { authenticated } = useAuthV2();
  const {
    data: orgData,
    loading: orgLoading,
    error,
  } = useQuery<GetOrgDataQuery, GetOrgDataQueryVariables>(getOrgData, {
    variables: variables!,
    skip: !variables || !authenticated,
  });

  if (orgData?.currentUser?.organization?.users?.length === 1) {
    return <InviteYourCoWorkers />;
  }

  return (
    <View style={styles.container}>
      <SelectDateTabsBar />
      {authenticated ? (
        <>
          <Spacer size={16} />
          <Divider />
          <Spacer size={12} />
          <AsyncRenderer
            error={error}
            data={orgData}
            loadingHandler={() => <ListSpacesSkeleton />}
            loading={orgLoading || currentUserLoading}
          >
            {(data) => (
              <>
                {data.currentUser?.organization?.bookings.length === 0 && (
                  <>
                    <BeFirstOneToBook />
                    <Spacer size={30} />
                  </>
                )}
                <JoinYourTeam
                  bookings={data.currentUser?.organization?.bookings}
                />
                <Spacer size={8} />
                <YourTeamLoveSpace
                  topSpaces={data.currentUser?.organization?.mostBookedSpaces}
                />
                <Spacer size={8} />
              </>
            )}
          </AsyncRenderer>
          <WhereMyTeam />
        </>
      ) : (
        <>
          <Spacer size={24} />
          <LoginToSee
            headerText={"Log in to see your team"}
            contentText={
              "You can see where your team is working once you’ve logged in."
            }
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
    height: "100%",
    paddingVertical: 16,
  },
});

const getOrgData = gql`
  ${cityDetailsFragment}
  ${teamsLocationDetailsFragment}
  ${teamsSpaceDetailsFragment}
  ${teamsUserDetailsFragment}
  ${teamsBookingDetailsFragment}
  query GetOrgData(
    $startDate: String!
    $endDate: String!
    $currentUserCoordinates: SpaceSearchCoordinates
    $status: UserStatus
  ) {
    currentUser {
      organization {
        users(status: $status) {
          id
        }
        bookings(startDate: $startDate, endDate: $endDate) {
          ...TeamsBookingDetails
        }
        mostBookedSpaces {
          ...TeamsSpaceDetails
        }
      }
    }
  }
`;
