import { AuthLoader } from "providers/auth_loader";
import { AuthLoaderV1 } from "providers/auth_loaderv1";
import { useOpenDirectoryFeatureFlag } from "providers/splitio";
import React from "react";

interface AuthLoaderProps {
  children: React.ReactNode;
}
export function AuthLoaderWrapper(props: AuthLoaderProps) {
  const { children } = props;
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  if (openDirectoryFeatureFlag) {
    return <AuthLoaderV1>{children}</AuthLoaderV1>;
  }
  return <AuthLoader>{children}</AuthLoader>;
}
