import { Avatar } from "components/avatar";
import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import pluralize from "pluralize";
import { useAnalytics } from "providers/analytics";
import { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { AcceptInvitePageProps } from "../accept_invite";

export function InviteMemberStepGetStarted(props: AcceptInvitePageProps) {
  const { onGoNext, organization, credentials } = props;
  const mq = useMediaQuery();
  const analytics = useAnalytics();

  useEffect(() => {
    if (credentials?.inviteId) {
      analytics.event("Accept Invite", {
        "Invite UUID": credentials?.inviteId,
        Step: "Start",
      });
    }
  }, [analytics, credentials?.inviteId]);

  if (!organization) {
    return <Spinner />;
  }

  const users = organization?.users || [];

  return (
    <Content maxWidth={590}>
      <Heading size="mega" align="center">
        Collaborate with {organization?.name} with Flexspace
      </Heading>
      <Spacer size={16} />
      <Text size="xs" align="center">
        Flexspace provides exclusive on-demand access to hundreds of inspiring
        workspaces for you and your team to work IRL.
      </Text>
      <Spacer size={56} />
      <View style={styles.peopleContainer}>
        {organization?.users
          .filter((user) => user.userId)
          .map((user) => (
            <View style={styles.peopleItemContainer}>
              <Avatar
                size="lg"
                source={{ uri: user.picture || "" }}
                name={user?.fullName || ""}
              />
            </View>
          ))}
      </View>
      <Spacer size={24} />
      <Text size="xs" align="center">
        {users
          .slice(0, 2)
          .map((user) => user?.fullName)
          .join(", ")}{" "}
        {users.length - 2 > 0
          ? `and ${users.length - 2} ${pluralize("other", users.length - 2)} `
          : ""}
        have already joined
      </Text>
      <Spacer size={24} />
      <View
        style={{
          width: mq.sizeQuery.mdAndUp ? 220 : "100%",
          margin: "auto",
          justifyContent: mq.deviceQuery.mobile ? "flex-end" : "center",
        }}
      >
        <Button
          testID="accept-invite-get-started-button"
          appearance="primary"
          text="Get started"
          onPress={onGoNext}
        />
      </View>
    </Content>
  );
}

const styles = StyleSheet.create({
  peopleItemContainer: {
    width: 48,
    height: 48,
    position: "relative",
    marginLeft: -5,
  },
  peopleContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
