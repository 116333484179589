import { format } from "date-fns";
import { parseTime } from "lib/time_utils";
import { DropdownV2 } from "./dropdown_v2";
import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "./text_v2";
import { tokens } from "./tokens";
import { Time } from "lib/time_utils";
import { useState } from "react";
import { SelectInputOption } from "components/select/select_input_option";
import { colors } from "./colors";

interface TimePickerProps {
  testID?: string;
  value?: Time | null;
  onChange?: (value: Time) => void;
  /** Minimum difference in minutes between time options. */
  formatter?: (time: Time) => string;
  isBlocked?: (time: Time) => boolean;
  hours: Time[];
  label?: string;
  description?: string;
  invalidText?: string;
  disabled?: boolean;
  invalid?: boolean;
  withIcon?: boolean;
  placeholder?: string;
}

// todo use InputContainerV2 here to display error messages
// and validtion error (?)
export function TimePickerV3(props: TimePickerProps) {
  const {
    value,
    hours,
    onChange = () => {},
    testID,
    placeholder,
    formatter = (time: Time) => format(parseTime(time), "HH:mm"),
    disabled = false,
    label,
    // description,
    // invalidText,
    // invalid,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      content={
        <View style={styles.dropdownContent}>
          {hours.map((hour) => {
            const isSelected = value === hour;

            return (
              <SelectInputOption
                key={hour}
                selected={isSelected}
                option={{ value: hour, label: formatter(hour) }}
                testID={`select-option-${hour}`}
                onSelect={() => {
                  onChange(hour);
                  setOpen(false);
                }}
              />
            );
          })}
        </View>
      }
    >
      <View style={styles.wrapper}>
        <Text size="xs">{label}</Text>
        <Pressable
          testID={testID}
          disabled={disabled}
          style={[styles.button, disabled && styles.buttonDisabled]}
          onPress={() => setOpen(!open)}
        >
          <Text size="xs" color={disabled ? "black-60" : "black-core"}>
            {value ? formatter(value) : placeholder}
          </Text>
        </Pressable>
      </View>
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "white",
    borderRadius: 4,
    paddingVertical: 7,
    paddingHorizontal: 16,
    border: `1px solid ${colors.brand.blackMinus90}`,
  },
  buttonDisabled: {
    backgroundColor: colors.brand.blackMinus90,
  },
  dropdownContent: {
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: tokens.colors.base.white,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    maxHeight: 500,
    overflowY: "scroll",
  },
});
