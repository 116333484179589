import { colors } from "components/colors";
import Dynamic_height_container from "components/dynamic_height_container";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { BOTTOM_BAR_HEIGHT } from "core/bottom_bar_navigation";
import { ReactNode, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

interface PageLayoutProps {
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
  title?: string;
  stickyHeader?: boolean;
  hasContentPadding?: boolean;
}
export function MobileLayout(props: PageLayoutProps) {
  const {
    header,
    body,
    footer,
    title,
    stickyHeader = true,
    hasContentPadding = true,
  } = props;
  const history = useHistory();
  useEffect(() => {
    if (window && history.action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [history.action]);
  return (
    <Dynamic_height_container>
      <View
        style={[
          styles.headerWrapper,
          stickyHeader && styles.stickyHeaderWrapper,
        ]}
      >
        {header}
      </View>
      <View style={styles.contentWrapper} testID="page-layout-content-wrapper">
        <View
          style={[styles.content, hasContentPadding && styles.contentPadding]}
        >
          {title && (
            <>
              <Spacer size={16} />
              <Heading size="lg" color="black-core">
                {title}
              </Heading>
              <Spacer size={32} />
            </>
          )}
          {body}
        </View>
      </View>
      {footer}
    </Dynamic_height_container>
  );
}

const styles = StyleSheet.create({
  headerWrapper: {
    backgroundColor: colors.brand.backgroundMain,
  },
  stickyHeaderWrapper: {
    // @ts-ignore
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  contentWrapper: {
    flexDirection: "row",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fafafa",
  },
  content: {
    flex: 1,
    maxWidth: 1088,
    paddingBottom: BOTTOM_BAR_HEIGHT + 8,
    flexShrink: 1,
  },
  contentPadding: {
    paddingHorizontal: 24,
  },
});
