import { createRoutes } from "core/routes";
import { ProtectedRoute } from "protected_route";
import {
  useCustomSSOFeatureFlag,
  useFavoritesFeatureFlag,
  useOpenDirectoryFeatureFlag,
  useReBookFeatureFlag,
} from "providers/splitio";
import { Route, Switch } from "react-router-dom";

interface RoutesV2Props {
  isProtected?: boolean;
}

export const RoutesV2 = (props: RoutesV2Props) => {
  const { isProtected = true } = props;

  const isReBook = useReBookFeatureFlag();
  const favoritesFeatureFlag = useFavoritesFeatureFlag();
  const sSOFeatureFlag = useCustomSSOFeatureFlag();
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();

  return (
    <Switch>
      {Object.values(
        createRoutes({
          reBookFeatureFlag: isReBook,
          favoritesFeatureFlag,
          sSOFeatureFlag,
          openDirectoryFeatureFlag,
        }),
      ).map((route) => {
        const RouteComponent =
          !isProtected || route.public ? Route : ProtectedRoute;
        return (
          <RouteComponent
            key={route.pageName}
            exact={route.exact}
            path={route.path}
          >
            {route.children}
          </RouteComponent>
        );
      })}
    </Switch>
  );
};
