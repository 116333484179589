import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { Spacer } from "components/spacer";
import {
  GroupBudgetRole,
  GroupBudgetStatus,
  OrganizationUser,
  UpdateGroupBudgetMutation,
  UpdateGroupBudgetOrganizationBudgetQuery,
  UpdateGroupBudgetOrganizationBudgetQueryVariables,
} from "core/graphql.generated";
import { useForm } from "hooks/use_form";
import { isNotEmpty } from "lib/array_utils";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { useToast } from "providers/toast";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import { BudgetDesktopLayout } from "../budget_layout/buget_desktop_layout";
import { BudgetMobileLayout } from "../budget_layout/buget_mobile_layout";
import {
  BudgetDetailHeaderDesktop,
  BudgetDetailHeaderMobile,
} from "../components/budget_detail_header_v1";
import {
  CreateEditBudgetFormColumn,
  CreateEditBudgetSummaryColumn,
} from "../components/create_edit_budget_layout";
import { CreateEditNavigationBlocker } from "../components/create_edit_navigation_blocker";
import { EditBudgetSkeleton } from "../components/edit_budget_skeleton";
import { AttachPolicyCard } from "../policy/attach_policy_card";
import { CreateEditBudgetSummary } from "./components/create_edit_budget_summary";
import { CreateEditBudgetForm } from "./components/create_edit_form";
import { DeactivateGroupBudgetDialog } from "./components/group_budget_deactivate_dialog";
import { ReactivateBudgetButtonContainer } from "./components/reactivate_budget_button_container";
import {
  CreateEditBudgetFormErrors,
  GroupBudgetFormValues,
} from "./group_budget_create";

interface GroupBudgetUpdateParams {
  groupBudgetId: string;
}

export function GroupBudgetUpdate() {
  const { groupBudgetId } = useParams<GroupBudgetUpdateParams>();
  const apolloClient = useApolloClient();
  const toast = useToast();
  const mq = useMediaQuery();
  const history = useHistory();
  const analytics = useAnalytics();

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const {
    loading: organizationBudgetDataLoading,
    data: organizationBudgetData,
  } = useQuery<
    UpdateGroupBudgetOrganizationBudgetQuery,
    UpdateGroupBudgetOrganizationBudgetQueryVariables
  >(updateGrouBudgetOrganizationBudgetQuery, {
    variables: {
      id: groupBudgetId,
    },
  });

  const groupBudgets =
    organizationBudgetData?.currentUser?.organization?.groupBudgets;

  const groupBudgetStatus = organizationBudgetData?.groupBudget?.status;

  const [updateGroupBudget, { loading }] =
    useMutation<UpdateGroupBudgetMutation>(updateGroupBudgetMutation);

  const [deactivateGroupBudget, { loading: deactivateLoading }] = useMutation(
    deactivateGroupBudgetMutation,
  );

  const initialValues: GroupBudgetFormValues = useMemo(() => {
    const orgBudget = organizationBudgetData?.groupBudget;

    const members =
      orgBudget?.members
        ?.filter((member) => member.role === GroupBudgetRole.Member)
        .map((member) => member.user) || [];

    const managers =
      orgBudget?.members
        ?.filter((member) => member.role === GroupBudgetRole.Manager)
        .map((member) => member.user) || [];

    return {
      name: orgBudget?.name,
      limit: orgBudget?.limit,
      individualLimit: orgBudget?.individualLimit || undefined,
      description: orgBudget?.description || "",
      members: members as OrganizationUser[],
      managers: managers as OrganizationUser[],
      policyId: orgBudget?.policy?.id,
    };
  }, [organizationBudgetData]);

  const findErrors = useCallback(
    (values: GroupBudgetFormValues) => {
      const errors: CreateEditBudgetFormErrors = {};

      if (!values.name) {
        errors.name = "Please enter name";
      }

      if (!values.limit && values.limit !== 0) {
        errors.limit = "Please enter amount";
      }

      if (
        (values.limit || values.limit === 0) &&
        (values.individualLimit || values.individualLimit === 0) &&
        values.individualLimit > values.limit
      ) {
        errors.limit =
          "Monthly limit cannot be lower that the individual limit";
      }

      if (
        values.name &&
        groupBudgets &&
        groupBudgets
          .filter((budget) => budget.id !== groupBudgetId)
          .some((budget) => budget.name === values.name)
      ) {
        errors.name = "Budget name must be unique within your organization.";
      }

      return errors;
    },
    [groupBudgetId, groupBudgets],
  );

  const { values, setFieldValue, errors, submit, isDirty, validate } = useForm({
    reinitialize: true,
    initialValues,
    validate: () => {
      const errors = findErrors(values);
      if (isNotEmpty(Object.values(errors))) {
        toast.notify({
          message: "Please check all the required fields",
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setShouldBlockNavigation(false);
        const res = await updateGroupBudget({
          variables: {
            input: {
              id: groupBudgetId,
              limit: values.limit,
              individualLimit: values.individualLimit,
              name: values.name,
              description: values.description,
              managerIds: values.managers?.length
                ? values.managers.map((manager) => manager.id)
                : [],
              memberIds: values.members?.length
                ? values.members.map((member) => member.id)
                : [],
              policyId: values.policyId,
            },
          },
        });

        toast.notify({
          message: "Budget has been successfully updated",
        });

        const savedBudget = res.data?.updateGroupBudget;

        analytics.event("Update Group Budget", {
          Name: savedBudget?.name,
          Description: savedBudget?.description,
          "Monthly Limit": savedBudget?.limit,
          "Individual Limit": savedBudget?.individualLimit,
          "Policy Name": savedBudget?.policy?.name,
          "Members Count": values.members?.length,
          "Managers Count": values.managers?.length,
        });

        history.goBack();

        await apolloClient.resetStore();
      } catch (e) {
        toast.notify({
          message: "There has been an error when updating the budget",
        });
      }
    },
  });

  const checkValidationBeforReactivate = useCallback(() => {
    const errors = findErrors(values);
    validate();
    return errors;
  }, [findErrors, validate, values]);

  const deactivate = () => {
    setDeactivateOpen(true);
  };

  const onDeactivateConfirm = async () => {
    try {
      await deactivateGroupBudget({ variables: { id: groupBudgetId } });
      toast.notify({
        message: "Budget has been successfully deactivated",
      });
      analytics.event("Deactivate Group Budget", {
        Name: values.name,
      });
      setDeactivateOpen(false);
      await apolloClient.resetStore();
    } catch (e) {
      toast.error({
        message: "There has been an error when deactivating budget",
      });
      setDeactivateOpen(false);
    }
  };
  const onDeactivateCancel = () => {
    setDeactivateOpen(false);
  };

  const renderHeaderButtonDesktop = () => {
    if (groupBudgetStatus === "Inactive") {
      return (
        <ReactivateBudgetButtonContainer
          groupBudgetId={groupBudgetId}
          values={values}
          disabled={false}
          isMobile={false}
          formValidator={checkValidationBeforReactivate}
        />
      );
    }

    return (
      <View style={{ flexDirection: "row" }}>
        <Button
          onPress={deactivate}
          text="Deactivate"
          appearance="danger"
          disabled={loading}
          testID="group-budget-deactivate-button"
        />
        <Spacer size={16} direction="row" />

        <Button
          testID="group-budget-update-submit-button"
          onPress={submit}
          text="Save changes"
          disabled={!isDirty}
          loading={loading}
        />
      </View>
    );
  };

  const renderHeaderButtonMobile = () => {
    if (groupBudgetStatus === "Inactive") {
      return (
        <ReactivateBudgetButtonContainer
          groupBudgetId={groupBudgetId}
          values={values}
          disabled={false}
          isMobile={mq.deviceQuery.mobile}
          formValidator={checkValidationBeforReactivate}
        />
      );
    }

    return (
      <Button
        testID="group-budget-update-submit-button"
        text="Save"
        appearance="textLink"
        onPress={submit}
        disabled={!isDirty}
        loading={loading}
      />
    );
  };

  const groupBudgetUpdateContent = () => (
    <>
      <DeactivateGroupBudgetDialog
        open={deactivateOpen}
        onClose={onDeactivateCancel}
        onConfirm={onDeactivateConfirm}
        loading={deactivateLoading}
      />

      {mq.deviceQuery.mobile && <Spacer size={16} />}
      <CreateEditNavigationBlocker
        isActive={isDirty && shouldBlockNavigation}
        description="It looks like you’re in the middle of editing a budget. All information entered will be lost if you leave this page."
      />
      <CreateEditBudgetFormColumn>
        <CreateEditBudgetForm
          showPeopleCard={groupBudgetStatus === "Inactive"}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <AttachPolicyCard
          defaultPolicyId={initialValues.policyId}
          onSelect={(policyId) => setFieldValue("policyId", policyId)}
        />
        {mq.deviceQuery.mobile && (
          <Fragment>
            <Spacer size={16} />
            {groupBudgetStatus === GroupBudgetStatus.Inactive ? (
              <ReactivateBudgetButtonContainer
                groupBudgetId={groupBudgetId}
                values={values}
                disabled={false}
                isMobile={false}
                formValidator={checkValidationBeforReactivate}
              />
            ) : (
              <Button
                onPress={deactivate}
                text="Deactivate"
                appearance="danger"
                disabled={loading}
                testID="group-budget-deactivate-button"
              />
            )}
          </Fragment>
        )}
      </CreateEditBudgetFormColumn>
    </>
  );

  if (mq.deviceQuery.mobile) {
    return (
      <BudgetMobileLayout
        header={
          <BudgetDetailHeaderMobile
            title="Edit budget"
            actions={renderHeaderButtonMobile()}
          />
        }
        body={
          organizationBudgetDataLoading ? (
            <EditBudgetSkeleton />
          ) : (
            groupBudgetUpdateContent()
          )
        }
      />
    );
  }

  return (
    <BudgetDesktopLayout
      header={
        <BudgetDetailHeaderDesktop
          title="Edit budget"
          backButtonText="Back to budget details"
          actions={renderHeaderButtonDesktop()}
          hasDivider={true}
          status={groupBudgetStatus}
        />
      }
      body={
        organizationBudgetDataLoading ? (
          <EditBudgetSkeleton />
        ) : (
          groupBudgetUpdateContent()
        )
      }
      extendedContent={
        organizationBudgetDataLoading ? null : (
          <CreateEditBudgetSummaryColumn>
            <CreateEditBudgetSummary
              name={values.name}
              description={values.description}
              limit={values.limit}
              isEdit={true}
              members={values.members}
              managers={values.managers}
              individualLimit={values.individualLimit}
            />
          </CreateEditBudgetSummaryColumn>
        )
      }
    />
  );
}

const updateGroupBudgetMutation = gql`
  mutation UpdateGroupBudget($input: UpdateGroupBudgetInput!) {
    updateGroupBudget(input: $input) {
      id
      name
      description
      limit
      individualLimit
      status
      policy {
        id
        name
      }
    }
  }
`;

const updateGrouBudgetOrganizationBudgetQuery = gql`
  query UpdateGroupBudgetOrganizationBudget($id: ID!) {
    currentUser {
      id
      role
      organization {
        id
        budget {
          id
          limit
        }
        groupBudgets {
          id
          name
        }
      }
    }
    groupBudget(id: $id) {
      id
      status
      name
      description
      color
      spend
      remaining
      limit
      individualLimit
      members {
        role
        user {
          id
          userId
          role
          fullName
          email
          picture
          title
          groupBudgetRole
        }
      }
      policy {
        id
      }
    }
  }
`;

const deactivateGroupBudgetMutation = gql`
  mutation DeactivateGroupBudget($id: ID!) {
    deactivateGroupBudget(id: $id) {
      id
      status
      name
      description
      spend
      remaining
      limit
      members {
        role
        user {
          id
          userId
          role
          fullName
          email
          picture
          title
          groupBudgetRole
        }
      }
    }
  }
`;
