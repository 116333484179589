import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { InAppInvite } from "components/in_app_invite";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useUserType } from "../hooks/use_user_type";

export function InviteCoWorker() {
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const userType = useUserType();
  const isMobile = mq.deviceQuery.mobile;
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  return (
    <View style={styles.container}>
      <Text size={isMobile ? "md" : "lg"} weight={"semibold"}>
        Flexspace is better together
      </Text>
      <Text size={isMobile ? "micro" : "xs"} align={"center"}>
        To get the full collaborative experience, invite your coworkers to your
        team.
      </Text>
      <Spacer size={8} />
      <View style={isMobile && styles.buttonMobileWrapper}>
        <Button
          text={"Invite coworkers"}
          size={isMobile ? "auto" : "medium"}
          onPress={() => {
            setIsInviteModalOpen(true);
            analytics.event("Click Invite Coworkers", {
              Source: userType,
              category: "Rebooking",
            });
          }}
        />
      </View>
      <InAppInvite
        isVisible={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        analyticsFrom="Team spaces"
        onSuccess={() => {
          setIsInviteModalOpen(false);
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: colors.brand.eggplantMinus90,
    borderRadius: 4,
    gap: 8,
    padding: 24,
  },
  buttonMobileWrapper: {
    width: "100%",
  },
});
