import { Avatar } from "components/avatar";
import { colors } from "components/colors";

import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  OffsiteDetailsWhosHereQuery,
  OrganizationUser,
} from "core/graphql.generated";
import { format } from "date-fns";
import { isNotEmpty, uniqueBy } from "lib/array_utils";
import { isAfter } from "lib/date_utils";
import { Day, parseDay } from "lib/day_utils";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useQuery } from "@apollo/client";
import { whosHereGQLQuery } from "../core/offsite_space_details_whos_here";

interface WhoIsHereProps {
  peoples: Partial<OrganizationUser>[];
  date?: Day;
}

export function WhoIsHere(props: WhoIsHereProps) {
  const { peoples, date } = props;
  const { data, loading, error } =
    useQuery<OffsiteDetailsWhosHereQuery>(whosHereGQLQuery);

  if (loading || error) {
    return null;
  }

  return (
    <WhoIsHereContent
      peoples={peoples.filter((p) => p.userId !== data?.currentUser?.id)}
      date={date}
    />
  );
}
function WhoIsHereContent(props: WhoIsHereProps) {
  const { peoples, date } = props;
  let uniqPeople = uniqueBy(peoples, (p) => p.id || "");
  if (uniqPeople.length === 0) {
    return null;
  }

  let label = "";
  const future = isAfter(parseDay(date?.toString() || ""), new Date());
  if (uniqPeople.length === 1) {
    label = `${uniqPeople[0].fullName} ${
      future && date
        ? `will be here ${format(parseDay(date), "EEEE")}`
        : "is here"
    }`;
  } else {
    label = `${uniqPeople.length} teammates ${
      future && date
        ? `will be here ${format(parseDay(date), "EEEE")}`
        : "are here"
    }`;
  }

  return (
    <View style={styles.peopleWrapper}>
      {isNotEmpty(uniqPeople) && <People peoples={uniqPeople} />}
      <Spacer size={4} direction="row" />
      <Text weight="semibold" size="micro">
        {label}
      </Text>
    </View>
  );
}

interface PeopleProps {
  peoples: Partial<OrganizationUser>[];
  showRemainingCount?: boolean;
  maxDisplay?: number;
  appearance?: "default" | "outline";
}

export function People(props: PeopleProps) {
  const {
    peoples,
    showRemainingCount = false,
    maxDisplay = 3,
    appearance,
  } = props;
  const peopleToDisplay = peoples.slice(0, maxDisplay);
  const peopleRemainingCount = peoples.length - maxDisplay;
  return (
    <View style={styles.wrapper}>
      <Row justifyContent="flex-start" alignItems="center">
        {peopleToDisplay.map((person) => (
          <View style={styles.peoplePersonContainer} key={person.id}>
            <Avatar
              size="sm"
              name={person.fullName || ""}
              source={{
                uri: person.picture || undefined,
              }}
              appearance={appearance}
            />
          </View>
        ))}
        {peopleRemainingCount > 0 && showRemainingCount && (
          <View style={styles.counting}>
            <Text color={"eggplant-core"} size={"xs"}>
              +{peopleRemainingCount}
            </Text>
          </View>
        )}
      </Row>
    </View>
  );
}

const styles = StyleSheet.create({
  peopleWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
    backgroundColor: colors.brand.whitecore,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
  peopleWrapperCompact: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  wrapper: {
    paddingLeft: 5,
  },
  peoplePersonContainer: {
    width: 24,
    height: 24,
    position: "relative",
    marginLeft: -5,
  },
  counting: {
    width: 24,
    height: 24,
    position: "relative",
    backgroundColor: colors.brand.whitecore,
    borderWidth: 1,
    borderColor: colors.brand.eggplantcore,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 999,
    marginLeft: -5,
  },
});
