import { AdminHeader } from "components/admin_header";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { useQuery } from "@apollo/client";
import { Text } from "components/text_v2";
import { NavigationCards } from "./components/navigation_cards";
import {
  TodayCurrentUserQuery,
  TodayPage__RequestDetailsFragment,
} from "core/graphql.generated";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { todayCurrentUserQuery } from "./today_desktop";
import { RequestCardsMobile } from "./components/request_cards";
import { MobileLayout } from "components/layout/mobile_layout";
import { RequestCardsSkeletonMobile } from "./components/request_cards_sekeleton";

interface TodayMobileProps {
  requests: TodayPage__RequestDetailsFragment[];
  requestsLoading: boolean;
}

export function TodayMobile(props: TodayMobileProps) {
  const { requests, requestsLoading } = props;

  const { data } = useQuery<TodayCurrentUserQuery>(todayCurrentUserQuery);

  return (
    <MobileLayout
      header={<AdminHeader />}
      body={
        <>
          <Spacer size={16} />
          <Heading size="lg">Hi {data?.currentUser?.fullName},</Heading>
          <Spacer size={16} />
          <Text size="xs">
            Review and manage how your organization uses Flexspace so you can
            help your teams succeed.
          </Text>
          <Spacer size={48} />
          {requestsLoading ? (
            <RequestCardsSkeletonMobile />
          ) : (
            <RequestCardsMobile requests={requests} />
          )}

          <Spacer size={40} />
          <NavigationCards />
          <Spacer size={8} />
        </>
      }
      footer={<BottomBarAdminNavigation />}
    />
  );
}
