import React, { Fragment } from "react";
import { Image, Pressable } from "react-native";

import { Column } from "components/column";
import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { Icon } from "components/icon";
import { Heading } from "components/heading";
import { SpaceLayout } from "./graphql.generated";

interface RoomLayoutPickerProps {
  maxCapacity?: number;
  space: { layouts: SpaceLayout[] };
  selectedLayoutID?: string;
  onSelect?: (layoutID: string) => void;
}

export function RoomLayoutPicker(props: RoomLayoutPickerProps) {
  const { space, onSelect, maxCapacity, selectedLayoutID } = props;
  const { layouts } = space;

  return (
    <Container>
      {layouts
        .filter((l) => {
          if (maxCapacity === undefined) {
            return true;
          }

          return l.capacity <= maxCapacity;
        })
        .map((layout) => {
          const selected = layout.id === selectedLayoutID;

          return (
            <Fragment key={layout.name}>
              <Pressable onPress={() => onSelect && onSelect(layout.id)}>
                <Container
                  borderRadius={tokens.radius}
                  borderWidth={selected ? 2 : 1}
                  borderColor={
                    selected
                      ? tokens.colors.primary.lighter
                      : tokens.colors.neutral.darker
                  }
                  shadow
                >
                  {selected && (
                    <Container right={4} top={4} position="absolute">
                      <Icon
                        name="check-circle"
                        color={tokens.colors.primary.light}
                      />
                    </Container>
                  )}
                  <Row>
                    <Container
                      borderColor={tokens.colors.neutral.darker}
                      borderRightWidth={1}
                      paddingTop={16}
                    >
                      <Image
                        source={{
                          uri: layout.imageURL,
                          width: 100,
                          height: 100,
                        }}
                      />
                    </Container>
                    <Container flex={1} padding={16}>
                      <Column flex={1}>
                        <Heading size="h4">{layout.name}</Heading>
                        <Spacer size={1} />
                        <Text>{layout.bestFor}</Text>
                      </Column>
                    </Container>
                  </Row>
                </Container>
              </Pressable>
              <Spacer size={16} />
            </Fragment>
          );
        })}
    </Container>
  );
}
