import { colors } from "components/colors";
import React from "react";
import { BudgetColor } from "../../budgets/budget_colors";
import { ReportingPieChart } from "./pie_chart";

interface ReportingBookingsProps {
  dayPasses: { value: number; percentage: number };
  dayOffices: { value: number; percentage: number };
  meetingRooms: { value: number; percentage: number };
  totalBookings: number;
  labelUpdatedAt: string;
  color?: BudgetColor;
}

export function ReportingBookings(props: ReportingBookingsProps) {
  const {
    labelUpdatedAt,
    dayPasses,
    dayOffices,
    meetingRooms,
    totalBookings,
    color = "other",
  } = props;

  return (
    <ReportingPieChart
      data={[
        {
          label: "Day Passes",
          value: dayPasses.value,
          percentage: dayPasses.percentage,
          color: colors.budget[color].core,
        },
        {
          label: "Offices",
          value: dayOffices.value,
          percentage: dayOffices.percentage,
          color: colors.budget[color].lighter,
        },
        {
          label: "Rooms",
          value: meetingRooms.value,
          percentage: meetingRooms.percentage,
          color: colors.budget[color].lightest,
        },
      ]}
      total={totalBookings}
      item="Bookings"
      empty={
        dayPasses.value === 0 &&
        dayOffices.value === 0 &&
        meetingRooms.value === 0
      }
      emptyColor={colors.budget[color].lightest}
      tooltip="This chart shows a breakdown of the number of bookings by each space type."
      labelUpdatedAt={labelUpdatedAt}
      title="Bookings"
    />
  );
}
