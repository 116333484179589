import { Spacer } from "components/spacer";
import { BudgetColor } from "pages/budgets/budget_colors";
import React, { useEffect, useState } from "react";
import {
  GroupBudgetDetailQuery,
  GroupBudgetDetailQueryVariables,
} from "core/graphql.generated";
import { ReportingEngagement } from "pages/reporting/components/engagement";
import { ReportingFrequency } from "pages/reporting/components/frequency";
import { DayInterval } from "lib/day_utils";
import { ReportingBookings } from "pages/reporting/components/bookings";
import { ReportingSpend } from "pages/reporting/components/spend";
import { getDataAsLabel } from "pages/reporting/utils/get_last_updated_label";
import { AsyncRenderer } from "components/AsyncRenderer";
import { useHistory, useParams } from "react-router";
import { useQueryString } from "lib/query_string";
import { gql, useQuery } from "@apollo/client";
import { BudgetsDateRangePicker } from "components/budgets_date_range_picker/budgets_date_range_picker";
import { getInitialDayInterval } from "components/budgets_date_range_picker/budgets_date_range_picker_common";
import { ChartWrapper } from "../organization_budget/components/chart_wrapper";
import { ChartContainerWrapper } from "../organization_budget/components/chart_container_wrapper";
import { GroupBudgetOverviewCard } from "./components/group_budget_overview_card";
import { GroupBudgetDetailSkeletonForTabs } from "./components/group_budget_detail_skeleton_for_tabs";
import { GroupBudgetDetailTab } from "./group_budget_detail";

interface GroupBudgetSearchParams {
  start?: string;
  end?: string;
  selectedTab?: GroupBudgetDetailTab;
  backButtonText?: string;
}

interface GroupBudgetDetailParams {
  groupBudgetId: string;
}
export function GroupBudgetDetailSpendAndReports() {
  const history = useHistory();
  const param = useParams<GroupBudgetDetailParams>();
  const sp = useQueryString<GroupBudgetSearchParams>();
  const [dayInterval, setDayInterval] = useState<DayInterval>(
    getInitialDayInterval(sp),
  );
  const { loading, data, error } = useQuery<
    GroupBudgetDetailQuery,
    GroupBudgetDetailQueryVariables
  >(groupBudgetDetailQuery, {
    variables: {
      id: param.groupBudgetId,
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
  });

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("start", dayInterval.start);
    newSearchParams.set("end", dayInterval.end);
    if (sp.selectedTab) {
      newSearchParams.set("selectedTab", sp.selectedTab);
    }
    if (sp.backButtonText) {
      newSearchParams.set("backButtonText", sp.backButtonText);
    }
    history.replace({ search: newSearchParams.toString() });
  }, [dayInterval, history, sp.selectedTab, sp.backButtonText]);

  return (
    <AsyncRenderer
      loadingHandler={() => <GroupBudgetDetailSkeletonForTabs />}
      loading={loading}
      error={error}
      data={data}
    >
      {(data) => {
        const { reporting, remaining, limit, spend, color, status } =
          data.groupBudget || {};

        const hasSpendLimitCardValues =
          limit !== undefined &&
          spend !== undefined &&
          remaining !== undefined &&
          limit >= 0 &&
          spend >= 0 &&
          remaining >= 0;

        return (
          <>
            <BudgetsDateRangePicker
              value={dayInterval}
              onChange={setDayInterval}
              labelUpdatedAt={getDataAsLabel(reporting?.lastUpdatedDate)}
            />
            <Spacer size={24} />

            {hasSpendLimitCardValues && (
              <GroupBudgetOverviewCard
                name={"Spend limit"}
                color={color as BudgetColor}
                limit={limit}
                spend={spend}
                remaining={remaining}
                status={status}
                isProgressBar={true}
              />
            )}
            <Spacer size={24} />

            {!!reporting && (
              <ChartContainerWrapper>
                <ChartWrapper status={status}>
                  <ReportingSpend
                    dayPasses={reporting.spend.dayPasses}
                    dayOffices={reporting.spend.dayOffices}
                    meetingRooms={reporting.spend.meetingRooms}
                    totalSpend={reporting.spend.totalSpend}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                    color={color as BudgetColor}
                  />
                </ChartWrapper>
                <ChartWrapper status={status}>
                  <ReportingBookings
                    dayPasses={reporting.bookings.dayPasses}
                    dayOffices={reporting.bookings.dayOffices}
                    meetingRooms={reporting.bookings.meetingRooms}
                    totalBookings={reporting.bookings.totalBookings}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                    color={color as BudgetColor}
                  />
                </ChartWrapper>
                <ChartWrapper status={status}>
                  <ReportingFrequency
                    zeroOrSingleBookingPerWeek={
                      reporting.frequency.zeroOrSingleBookingPerWeek
                    }
                    twoOrThreeBookingsPerWeek={
                      reporting.frequency.twoOrThreeBookingsPerWeek
                    }
                    fourOrMoreBookingsPerWeek={
                      reporting.frequency.fourOrMoreBookingsPerWeek
                    }
                    totalEmployees={reporting.frequency.totalEmployees}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                    color={color as BudgetColor}
                  />
                </ChartWrapper>
                <ChartWrapper status={status}>
                  <ReportingEngagement
                    soloBookings={reporting.engagement.soloBookings}
                    teamBookings={reporting.engagement.teamBookings}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                    color={color as BudgetColor}
                  />
                </ChartWrapper>
              </ChartContainerWrapper>
            )}
          </>
        );
      }}
    </AsyncRenderer>
  );
}

const groupBudgetDetailQuery = gql`
  query GroupBudgetDetail($id: ID!, $startDate: String!, $endDate: String!) {
    groupBudget(id: $id) {
      id
      status
      color
      spend
      remaining
      limit
      reporting(startDate: $startDate, endDate: $endDate) {
        ...BudgetDetail__Reporting
      }
    }
  }

  fragment BudgetDetail__Reporting on Reporting {
    spend {
      totalSpend
      dayPasses {
        value
        percentage
      }
      dayOffices {
        value
        percentage
      }
      meetingRooms {
        value
        percentage
      }
    }
    bookings {
      totalBookings
      dayPasses {
        value
        percentage
      }
      dayOffices {
        value
        percentage
      }
      meetingRooms {
        value
        percentage
      }
    }
    frequency {
      totalEmployees
      zeroOrSingleBookingPerWeek {
        value
        percentage
      }
      twoOrThreeBookingsPerWeek {
        value
        percentage
      }
      fourOrMoreBookingsPerWeek {
        value
        percentage
      }
    }
    engagement {
      soloBookings {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      teamBookings {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
    lastUpdatedDate
  }
`;
