import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";

export interface BookingDetailsDesktopComponents {
  header: JSX.Element;
  dateRangeBar: JSX.Element;
  images: JSX.Element;
  inviteOrShare?: JSX.Element;
  aboutSpace?: JSX.Element;
  preferred?: JSX.Element;
  amenties?: JSX.Element;
  priceBreakdown: JSX.Element;
  location: JSX.Element;
  map: JSX.Element;
  directions?: JSX.Element;
  hours: JSX.Element;
}

interface BookingDetailsContentDesktopProps {
  components: BookingDetailsDesktopComponents;
}

export function BookingDetailsContentDesktop({
  components,
}: BookingDetailsContentDesktopProps) {
  const {
    header,
    dateRangeBar,
    images,
    inviteOrShare,
    aboutSpace,
    preferred,
    amenties,
    priceBreakdown,
    location,
    map,
    directions,
    hours,
  } = components;

  return (
    <View>
      {header}
      <Spacer size={24} />
      {dateRangeBar}
      <Spacer size={24} />
      {images}
      <Spacer size={24} />
      <Divider />
      <Spacer size={24} />
      <View style={styles.flexRow}>
        <View style={styles.aboutAndAmentiesWrapper}>
          {aboutSpace && (
            <>
              {aboutSpace}
              <Spacer size={24} />
              <Divider />
              <Spacer size={24} />
            </>
          )}
          {preferred && (
            <>
              {preferred}
              <Spacer size={24} />
              <Divider />
              <Spacer size={24} />
            </>
          )}
          {amenties && (
            <>
              {amenties}
              <Spacer size={24} />
              <Divider />
              <Spacer size={24} />
            </>
          )}
          {location}
        </View>
        <View style={styles.priceWrapper}>
          {inviteOrShare && (
            <>
              {inviteOrShare}
              <Spacer size={24} />
            </>
          )}
          {priceBreakdown}
        </View>
      </View>
      <Spacer size={24} />
      {map}
      {directions && (
        <>
          <Spacer size={24} />
          <Divider />
          <Spacer size={24} />
          {directions}
        </>
      )}
      <Spacer size={24} />
      <Divider />
      <Spacer size={24} />
      {hours}
    </View>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    gap: "5%",
  },
  aboutAndAmentiesWrapper: {
    width: "60%",
  },
  priceWrapper: {
    width: "35%",
  },
});
