import "./text_field.web.css";
import { forwardRef, useState } from "react";
import { TextInput as RNTextInput } from "react-native";
import { PasswordInputProps } from "./password_field";
import { TextField } from "./text_field.web";
import { Icon } from "./icon";

// NEEDS TO MATCH NATIVE IMPLEMENTATION
export const PasswordField = forwardRef<RNTextInput, PasswordInputProps>(
  function PasswordField({ withRevealButton, ...restProps }, ref) {
    const [revealing, setRevealing] = useState(false);

    return (
      <TextField
        {...restProps}
        ref={ref}
        secureTextEntry={!revealing}
        onRightIconClick={() => setRevealing(!revealing)}
        rightIcon={
          withRevealButton && (
            <Icon name={revealing ? "eye-open" : "eye-closed"} />
          )
        }
      />
    );
  },
);
