import { Fragment, useCallback, useMemo, useState } from "react";
import { ScrollView } from "react-native";
import { Button } from "components/button_v2";
import { Column } from "components/column";
import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { TextLink } from "components/text_link";
import { TextInput } from "components/text_input";
import { Heading } from "components/heading";
import { useForm } from "hooks/use_form";
import { useMediaQuery } from "lib/media_query";
import { isEmail } from "lib/string_utils";
import { DialogContent } from "./dialog_content";

import {
  InvitesInput,
  OffsiteOrderPage__OrderDetailsQuery,
  SendInvitesMutation,
  SendInvitesMutationVariables,
} from "./graphql.generated";
import { Dialog } from "components/dialog";
import { gql, useMutation } from "@apollo/client";

interface OrderInvitesProps {
  order: NonNullable<OffsiteOrderPage__OrderDetailsQuery["order"]>;
  maxCapacity: number;
  onComplete?: () => void;
}

export function OrderInvites(props: OrderInvitesProps) {
  const { order, maxCapacity, onComplete } = props;
  const [visible, setVisible] = useState(false);

  const existingRecipientsCount = order.invites.reduce((a, b) => {
    return a + b.recipients.length;
  }, 0);

  const initialView = existingRecipientsCount > 0 ? "view" : "invite";
  const [view, setView] = useState<"invite" | "view">(initialView);

  const [sendInvitesMutation] = useMutation<
    SendInvitesMutation,
    SendInvitesMutationVariables
  >(sendInvitesGQLMutation);

  const handleComplete = useCallback(() => {
    setView("view");
    if (onComplete) {
      onComplete();
    }
  }, [onComplete]);

  const handleOpenSendInvite = useCallback(() => {
    setView("invite");
    setVisible(true);
  }, []);

  const handleOpenViewInvite = useCallback(() => {
    setView("view");
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setView("view");
    setVisible(false);
  }, []);

  const numberOfInvites = maxCapacity - existingRecipientsCount;
  const handleSendInvites = useCallback(
    async (params: SendInvitesMutationVariables) => {
      await sendInvitesMutation({
        variables: {
          orderID: params.orderID,
          input: params.input,
        },
      });
    },
    [sendInvitesMutation],
  );
  return (
    <Container>
      <InviteBanner
        existingRecipientsCount={existingRecipientsCount}
        onOpenSendInvite={handleOpenSendInvite}
        onOpenViewInvite={handleOpenViewInvite}
      />
      <Dialog
        visible={visible}
        onDismiss={handleClose}
        onRequestClose={handleClose}
      >
        <DialogContent
          desktopWidth={630}
          headerLeftIcon="x-circle"
          onHeaderLeftIconPress={handleClose}
        >
          {view === "view" ? (
            <ViewInvitationDetails
              onPressInviteMore={() => setView("invite")}
              onClose={handleClose}
              invites={order.invites}
              numberOfInvites={numberOfInvites}
            />
          ) : (
            <SendInvitesForm
              orderID={order.id}
              sendInvite={handleSendInvites}
              onComplete={handleComplete}
              numberOfInvites={numberOfInvites}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}

interface InviteBannerProps {
  existingRecipientsCount: number;
  onOpenSendInvite: () => void;
  onOpenViewInvite: () => void;
}

function InviteBanner(props: InviteBannerProps) {
  const { existingRecipientsCount, onOpenSendInvite, onOpenViewInvite } = props;
  const mq = useMediaQuery();
  const text = (
    <Text>
      Invitation sent to {existingRecipientsCount}{" "}
      {existingRecipientsCount > 1 ? "recipients" : "recipient"}
    </Text>
  );

  if (existingRecipientsCount) {
    return (
      <Container padding={16} color="tint">
        {mq.sizeQuery.mdAndUp ? (
          <Row justifyContent="center" alignItems="center">
            {text}
            <Spacer size={24} />
            <Button onPress={onOpenViewInvite} text="View invitation" />
          </Row>
        ) : (
          <Column justifyContent="center" alignItems="center">
            {text}
            <Spacer size={24} />
            <Button onPress={onOpenViewInvite} text="View invitation" />
          </Column>
        )}
      </Container>
    );
  }

  return (
    <Container padding={16} color="tint">
      {mq.sizeQuery.mdAndUp ? (
        <Row justifyContent="center" alignItems="center">
          <Text>Will there be others joining you?</Text>
          <Spacer size={24} />
          <Button onPress={onOpenSendInvite} text="Invite attendees" />
        </Row>
      ) : (
        <Column justifyContent="center" alignItems="center">
          <Text>Will there be others joining you?</Text>
          <Spacer size={24} />
          <Button onPress={onOpenSendInvite} text="Invite attendees" />
        </Column>
      )}
    </Container>
  );
}

interface ViewInvitationDetailsProps {
  invites: Invite[];
  onClose: () => void;
  onPressInviteMore: () => void;
  numberOfInvites: number;
}

function ViewInvitationDetails(props: ViewInvitationDetailsProps) {
  const { invites, onClose, numberOfInvites, onPressInviteMore } = props;

  return (
    <Container flex={1} paddingHorizontal={16} paddingBottom={16}>
      <ScrollView>
        <Heading size="h3">Invitation sent!</Heading>
        <Spacer size={8} />
        <Text>
          An email invitation has been sent to your attendees with details and
          directions.
        </Text>
        <Spacer size={16} />
        {invites.map((i) => (
          <Fragment key={i.id}>
            <InviteDetails invite={i} />
            <Spacer size={16} />
          </Fragment>
        ))}
        {numberOfInvites > 0 && (
          <Fragment>
            <Spacer size={16} />
            <TextLink
              onPress={onPressInviteMore}
              text="Invite more attendees"
            />
          </Fragment>
        )}
        <Spacer size={24} />
      </ScrollView>
      <Button onPress={onClose} text="Done" />
    </Container>
  );
}

interface Invite {
  id: string;
  message: string;
  recipients: Array<{
    name: string;
    email: string;
    phoneNumber?: string | null;
  }>;
}

interface InviteDetailsProps {
  invite: Invite;
}

function InviteDetails(props: InviteDetailsProps) {
  const { invite } = props;
  const mq = useMediaQuery();

  return (
    <Fragment>
      <Text color="muted">Message</Text>
      <Spacer size={4} />
      <Text>{invite.message}</Text>
      <Spacer size={8} />
      <Text color="muted">Recipients</Text>
      <Spacer size={4} />
      <Container>
        {invite.recipients.map((r) => (
          <Container>
            {mq.sizeQuery.mdAndUp ? (
              <Row>
                <Text weight="bold">{r.name} </Text>
                <Text>{r.email} </Text>
                {r.phoneNumber && <Text>/ {r.phoneNumber}</Text>}
              </Row>
            ) : (
              <Column>
                <Text weight="bold">{r.name} </Text>
                <Text>{r.email} </Text>
                {r.phoneNumber && <Text>{r.phoneNumber}</Text>}
              </Column>
            )}
            <Spacer size={8} />
          </Container>
        ))}
      </Container>
    </Fragment>
  );
}
interface SendInvitesFormProps {
  orderID: string;
  numberOfInvites: number;
  onComplete?: () => void;
  sendInvite: (params: SendInvitesMutationVariables) => Promise<void>;
}

export function SendInvitesForm(props: SendInvitesFormProps) {
  const { orderID, sendInvite, numberOfInvites, onComplete } = props;

  const form = useSendInvites({
    sendInvitesMutation: async (vars) => {
      sendInvite({
        orderID: vars.orderID,
        input: vars.input,
      });
    },
    numberOfInvites,
    orderID,
    onComplete,
  });

  const {
    values,
    errors,
    setFieldValue,
    setArrayFieldValue,
    submit,
    submitting,
  } = form;

  return (
    <Container flex={1} paddingHorizontal={16} paddingBottom={16}>
      <Heading size="h3">Invite attendees</Heading>
      <Spacer size={8} />
      <Text>
        Fill in the information below to send an email invitation to your
        recipients with details and directions.
      </Text>
      <Spacer size={16} />

      <Container flex={1}>
        <ScrollView>
          <TextInput
            numberOfLines={3}
            placeholder="Message"
            value={values.message}
            onChange={(v) => setFieldValue("message", v)}
          />
          {errors.message !== "" && <Text color="error">{errors.message}</Text>}
          <Spacer size={16} />
          {values.recipients.map((attendee, i) => (
            <Container key={i}>
              <Row flex={1}>
                <Container flex={1}>
                  <TextInput
                    placeholder="Name"
                    value={attendee.name}
                    onChange={(v) =>
                      setArrayFieldValue("recipients", i, "name", v)
                    }
                  />
                  {errors.recipients?.[i]?.name !== "" && (
                    <Text color="error">{errors.recipients?.[i]?.name}</Text>
                  )}
                </Container>
                <Spacer size={8} />
                <Container flex={1}>
                  <TextInput
                    placeholder="Email"
                    value={attendee.email}
                    onChange={(v) =>
                      setArrayFieldValue("recipients", i, "email", v)
                    }
                  />
                  {errors.recipients?.[i]?.email !== "" && (
                    <Text color="error">{errors.recipients?.[i]?.email}</Text>
                  )}
                </Container>
                <Spacer size={8} />
                <Container flex={1}>
                  <TextInput
                    placeholder="Phone (optional)"
                    value={attendee.phoneNumber}
                    onChange={(v) =>
                      setArrayFieldValue("recipients", i, "phoneNumber", v)
                    }
                  />
                </Container>
              </Row>
              <Spacer size={16} />
            </Container>
          ))}
          <Spacer size={24} />
        </ScrollView>
        <Button loading={submitting} onPress={submit} text="Invite attendees" />
      </Container>
    </Container>
  );
}

interface UseSendInvitesParams {
  orderID: string;
  numberOfInvites: number;
  onComplete?: () => void;
  sendInvitesMutation: (variables: {
    orderID: string;
    input: InvitesInput;
  }) => Promise<void>;
}

function useSendInvites(params: UseSendInvitesParams) {
  const { sendInvitesMutation, numberOfInvites, orderID, onComplete } = params;

  const initialAttendees = useMemo(() => {
    const attendees = [];

    for (let i = 0; i < numberOfInvites; i++) {
      attendees.push({
        name: "",
        email: "",
        phoneNumber: "",
      });
    }

    return attendees;
  }, [numberOfInvites]);

  const initialValues = useMemo(
    () => ({
      message: "",
      recipients: initialAttendees,
    }),
    [initialAttendees],
  );

  return useForm({
    initialValues,
    reinitialize: true,
    validate: (values) => {
      const errors: any = {
        message: "",
        recipients: initialAttendees,
      };

      if (!values.message) {
        errors.message = "Please enter a message";
        return errors;
      }

      let hasError = false;

      if (values.message) {
        const filledNameOrEmail = values.recipients.some(
          (a) => a.name || a.email,
        );

        if (!filledNameOrEmail) {
          errors.message = "Please enter at least one attendee.";
          return errors;
        }
      }

      values.recipients.forEach((a, i) => {
        if (a.name || a.email) {
          if (!a.name) {
            hasError = true;
            errors.recipients[i].name = "Please add name";
          } else {
            errors.recipients[i].name = "";
          }

          if (!a.email) {
            hasError = true;
            errors.recipients[i].email = "Please add email";
          } else {
            if (!isEmail(a.email)) {
              hasError = true;
              errors.recipients[i].email = "Please enter valid email";
            } else {
              errors.recipients[i].email = "";
            }
          }
        }
      });

      if (!hasError) {
        return {};
      }

      return errors;
    },
    onSubmit: async (v) => {
      await sendInvitesMutation({
        orderID,
        input: {
          message: v.message,
          recipients: v.recipients.filter((r) => r.name && r.email),
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
  });
}

export const sendInvitesGQLMutation = gql`
  mutation SendInvites($orderID: ID!, $input: InvitesInput!) {
    sendInvites(orderID: $orderID, input: $input) {
      id
      message
      recipients {
        name
        email
        phoneNumber
      }
    }
  }
`;
