import "./footer.css";
import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { colors } from "components/colors";

export function Footer() {
  const mq = useMediaQuery();

  return !mq.deviceQuery.mobile ? (
    <div className="footer">
      <View style={styles.container}>
        <View>
          <View style={styles.links}>
            <FooterLink href="mailto:hello@flexspace.ai" text="Contact Us" />
            <View style={styles.dot}>
              <b> &middot; </b>
            </View>
            <FooterLink
              href="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-privacy.pdf"
              text="Privacy Policy"
            />
            <View style={styles.dot}>
              <b> &middot; </b>
            </View>
            <FooterLink
              href="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-terms.pdf"
              text="Terms"
            />
          </View>
          <Spacer size={8} />
          <Text color="white-core" size="xs" weight="regular">
            © {new Date().getFullYear()} Flexspace Technologies Inc.{" "}
          </Text>
        </View>
        <View>
          <Image
            source={{
              uri: "https://meet-production.s3.us-west-1.amazonaws.com/logos/flexspace-logo-white.svg",
              height: 36,
              width: 113,
            }}
          />
        </View>
      </View>
    </div>
  ) : null;
}

interface FooterLinkProps {
  href: string;
  text: string;
}

function FooterLink(props: FooterLinkProps) {
  const { href, text } = props;

  return (
    <a
      style={{ textDecoration: "none" }}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Text weight="semibold" color="white-core">
        {text}
      </Text>
    </a>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingBottom: 26,
    paddingTop: 16,
    height: 83,
  },
  dot: {
    height: 4,
    color: colors.brand.whitecore,
  },
  links: {
    flexDirection: "row",
    gap: 8,
  },
});
