import React, { Fragment, useCallback } from "react";
import { Pressable, StyleSheet } from "react-native";
import { Spacer } from "../spacer";
import { Row } from "../row";
import { Text } from "../text";
import { tokens } from "../tokens";

export interface TabOption<T> {
  label: string;
  value: T;
  testID?: string;
}

export interface TabsProps<T> {
  value?: T | null;
  onChange: (value: T) => void;
  options: TabOption<T>[];
  fitContent?: boolean;
  spacing?: number;
}

export function Tabs<T>(props: TabsProps<T>) {
  const { options, value, onChange, spacing = 0, fitContent } = props;

  return (
    <Row>
      {options.map((option, index) => (
        <Fragment key={option.label}>
          <Tab
            testID={option.testID}
            fitContent={fitContent}
            onSelect={onChange}
            option={option}
            active={option.value === value}
          />
          {spacing !== 0 && index !== options.length - 1 && (
            <Spacer size={spacing} />
          )}
        </Fragment>
      ))}
    </Row>
  );
}

export interface TabProps<T> {
  active: boolean;
  option: TabOption<T>;
  onSelect: (value: T) => void;
  fitContent?: boolean;
  testID?: string;
}

function Tab<T>(props: TabProps<T>) {
  const { active, option, onSelect, testID, fitContent } = props;

  const handlePress = useCallback(() => {
    onSelect(option.value);
  }, [onSelect, option]);

  return (
    <Pressable
      style={[
        styles.tab,
        fitContent && styles.fitContent,
        active ? styles.active : styles.default,
      ]}
      testID={testID}
      onPress={handlePress}
    >
      <Text numberOfLines={1}>{option.label}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  tab: {
    flex: 1,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  fitContent: {
    flex: undefined,
  },
  active: {
    backgroundColor: tokens.colors.primary.xlighter,
  },
  default: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
});
