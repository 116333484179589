import { useQuery } from "@apollo/client";
import { BookingDateRangeDropDownDesktop } from "components/booking_date_range_dropdown_desktop";
import { Container } from "components/container";
import { Divider } from "components/divider";
import { PickerButton } from "components/picker_button";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text as TextV2 } from "components/text_v2";
import { DateRange } from "core/booking_date_range_picker";
import {
  getBookingDateRangeLabel,
  getDateRangeForNextBookingType,
  getDefaultLayoutID,
  hasReservationDetails,
  SearchValue,
  toQueryString,
  useBookingDateRangePickerHelper,
} from "core/booking_utils";
import {
  BookingType,
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  PricingType,
  QuoteDetailsQuery,
  QuoteDetailsQueryVariables,
} from "core/graphql.generated";
import { quoteGQLQuery } from "core/queries";
import { useQuoteVariables } from "core/use_quote_variables";
import { savedPrice } from "pages/homev2/components/space_card_v2/space_card_template";
import { Fragment, useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useBestDayRatesQuery } from "../hooks/use_best_day_rates_query";
import { validateDateRange } from "../utils/validate_date_range";
import { DynamicPriceText } from "./dynamic_price_text";
import { SpaceQuoteDetails } from "./space_quote_details";

interface StickyPanelBookSpaceProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  searchValue: SearchValue;
  onChangeSearchValue: (value: SearchValue) => void;
}

export function StickyPanelBookSpace(props: StickyPanelBookSpaceProps) {
  const { searchValue, space, onChangeSearchValue } = props;
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const [error, setError] = useState("");
  const { dateRange } = searchValue;

  const quoteVariables = useQuoteVariables({
    searchValue,
    spaceID: space.id,
    layoutID: getDefaultLayoutID(space),
    partnerID: space.partnerID,
    attendees: space.maxCapacity,
  });
  const {
    data: quoteQuery,
    loading: quoteLoading,
    error: quoteError,
  } = useQuery<QuoteDetailsQuery, QuoteDetailsQueryVariables>(quoteGQLQuery, {
    fetchPolicy: "network-only",
    variables: quoteVariables!,
    skip: !quoteVariables,
  });

  useEffect(() => {
    if (
      quoteQuery?.quote.quoteId &&
      quoteQuery?.quote.quoteId !== searchValue.quoteId
    ) {
      onChangeSearchValue({
        ...searchValue,
        quoteId: quoteQuery.quote.quoteId,
      });
    }
  }, [onChangeSearchValue, quoteQuery, searchValue]);

  const {
    initialBookingType,
    showSegmentedControl,
    timeslots,
    isBlockedDate,
    isOutsideRange,
    multiDayBookingAllowed,
    availableTimeSlotsError,
    availableDaysError,
  } = useBookingDateRangePickerHelper({
    space,
    physicalSpaceID: searchValue.physicalSpaceID,
    selectedDay:
      dateRange?.type === BookingType.HourlyBooking
        ? dateRange.date
        : undefined,
  });

  const [bookingType, setBookingType] =
    useState<BookingType>(initialBookingType);

  const bestDayRates = useBestDayRatesQuery(
    space.id,
    bookingType,
    space.location.timezone,
  );

  const handleOpenBookingDateRangePicker = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);
  const handleClear = useCallback(() => {
    onChangeSearchValue({
      ...searchValue,
      dateRange: undefined,
    });
  }, [searchValue, onChangeSearchValue]);

  const handleChangeDateRange = useCallback(
    (newDateRange?: DateRange) => {
      setError("");

      onChangeSearchValue({
        ...searchValue,
        dateRange: newDateRange,
      });
    },
    [onChangeSearchValue, searchValue],
  );

  const handleSaveDateRange = useCallback(() => {
    const dateRangeError = validateDateRange(dateRange);
    if (dateRangeError) {
      setError(dateRangeError);
    } else {
      setVisible(false);
    }
  }, [dateRange]);

  const handleChangeBookingType = useCallback(
    (b: BookingType) => {
      setBookingType(b);

      onChangeSearchValue({
        ...searchValue,
        dateRange: getDateRangeForNextBookingType(dateRange, b),
      });
    },
    [dateRange, onChangeSearchValue, searchValue],
  );
  const onReserve = () => {
    const qs = toQueryString(searchValue);
    history.push(
      hasReservationDetails(space)
        ? `/offsite_spaces/${space.id}/reservation${qs}`
        : `/offsite_spaces/${space.id}/checkout${qs}`,
    );
  };

  let pricings;
  if (dateRange) {
    pricings = space.pricings.filter((price) =>
      dateRange?.type === BookingType.DailyBooking
        ? price.type === PricingType.Daily
        : price.type === PricingType.Hourly,
    );
  } else {
    // daily pricing before hourly pricing
    pricings = [...space.pricings].sort((a, b) => a.type.localeCompare(b.type));
  }

  const { totalPrice, unitPrice } = quoteQuery?.quote.orderItems[0] || {};
  let originalTotalPrice;
  if (quoteQuery?.quote.quoteItems && quoteQuery?.quote.quoteItems[0]) {
    originalTotalPrice = quoteQuery.quote.quoteItems[0].originalTotalPrice;
  }
  let savePriceText = savedPrice(
    pricings[0].price,
    space.currency,
    unitPrice,
    totalPrice,
    originalTotalPrice,
  );

  return (
    <Container
      width={312}
      borderRadius={4}
      color="content"
      padding={24}
      shadow={true}
    >
      <Row expanded>
        <View style={styles.fullWidth}>
          {pricings.map((pricing, i) => {
            return (
              <View
                style={[
                  styles.priceContainer,
                  pricings.length < 2 && styles.singlePrice,
                ]}
                key={pricing.type}
              >
                <View style={styles.fullWidth}>
                  <DynamicPriceText
                    basePrice={pricing.price}
                    priceType={pricing.type}
                    currency={space.currency}
                    unitPrice={quoteQuery?.quote.orderItems[0].unitPrice || 0}
                  />
                  {pricings.length > 1 && i === 0 && (
                    <Fragment>
                      <Divider /> <Spacer size={8} />
                    </Fragment>
                  )}
                </View>
              </View>
            );
          })}
        </View>
      </Row>
      {!dateRange && (
        <TextV2 size="micro" color="black-50">
          Add date for exact pricing
        </TextV2>
      )}
      <Spacer size={14} />
      <Divider color="darker" />
      <Spacer size={24} />
      <View>
        <BookingDateRangeDropDownDesktop
          openDropDown={visible}
          closeDropDown={handleClose}
          handleClear={handleClear}
          bookingType={bookingType}
          handleChangeBookingType={handleChangeBookingType}
          showSegmentedControl={showSegmentedControl}
          multiDayBookingAllowed={multiDayBookingAllowed}
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
          handleSaveDateRange={handleSaveDateRange}
          isOutsideRange={isOutsideRange}
          isBlockedDate={isBlockedDate}
          timeslots={timeslots}
          error={error}
          availableDaysError={availableDaysError}
          availableTimeSlotsError={availableTimeSlotsError}
          pricings={space.pricings}
          dynamicPrices={bestDayRates}
          currency={space.currency}
          spaceId={space.id}
          priceRanges={space.priceRanges}
        >
          <PickerButton
            onPress={handleOpenBookingDateRangePicker}
            iconLeft="calendar"
            testID="sticky-panel-date-picker-button"
            title={getBookingDateRangeLabel(dateRange, "Add date")}
          />
        </BookingDateRangeDropDownDesktop>

        <Spacer size={24} />
        <SpaceQuoteDetails
          quoteError={quoteError}
          quoteLoading={quoteLoading}
          quoteQuery={quoteQuery}
          space={space}
          dateRange={dateRange}
          searchValue={searchValue}
          savePriceText={savePriceText}
          onReserve={onReserve}
          handleOpenBookingDateRangePicker={handleOpenBookingDateRangePicker}
        />
      </View>
    </Container>
  );
}

const styles = StyleSheet.create({
  priceContainer: {
    flex: 1,
  },
  singlePrice: {
    paddingBottom: 10,
  },
  fullWidth: {
    width: "100%",
  },
});
