import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { Text } from "components/text_v2";
import { take } from "lib/array_utils";
import { useMediaQuery } from "lib/media_query";
import React, { useState } from "react";
import { Image, ImageURISource, StyleSheet, View } from "react-native";
import { TextSize } from "./text";

export type AvatarSize = "sm" | "md" | "lg" | "xl";

interface AvatarProps {
  size?: AvatarSize;
  source?: ImageURISource;
  name?: string;
  appearance?: "default" | "outline";
}

export function Avatar(props: AvatarProps): JSX.Element {
  const { source, size = "md", name, appearance } = props;
  const [imageError, setImageError] = useState(false);

  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  if (!name) {
    return (
      <Icon
        name={isMobile ? "user" : "profile-picture"}
        color={appearance === "outline" ? "blackcore" : "whitecore"}
      />
    );
  }

  if (!source || imageError) {
    return (
      <View
        style={[
          styles.base,
          styles.initials,
          styles[size],
          appearance && styles[appearance],
        ]}
      >
        <Text size={getTextSize(size)}>{getInitials(name, size)}</Text>
      </View>
    );
  }

  return (
    <Image
      style={[styles.base, styles[size], appearance && styles[appearance]]}
      source={{
        uri: source.uri,
        width: sizeMap[size],
        height: sizeMap[size],
      }}
      onError={() => setImageError(true)}
      width={sizeMap[size]}
      height={sizeMap[size]}
    />
  );
}

function getTextSize(size: AvatarSize): TextSize {
  switch (size) {
    case "xl":
      return "lg";
    case "lg":
      return "md";
    case "md":
      return "sm";
    case "sm":
      return "xs";
    default:
      return "sm";
  }
}

function getInitials(name: string, size: AvatarSize): string {
  let initials = 2;

  if (size === "sm") {
    initials = 1;
  }

  return take(name.split(" "), initials)
    .map((c) => c.charAt(0).toUpperCase())
    .join("");
}

const sizeMap: { [size in AvatarSize]: number } = {
  sm: 24,
  md: 32,
  lg: 48,
  xl: 56,
};

const styles = StyleSheet.create({
  base: {
    borderRadius: 999,
    justifyContent: "center",
    alignItems: "center",
  },
  default: {},
  outline: {
    borderWidth: 1,
    borderColor: colors.brand.eggplantcore,
  },
  initials: {
    backgroundColor: "grey",
  },
  sm: {
    width: 24,
    height: 24,
  },
  md: {
    width: 32,
    height: 32,
  },
  lg: {
    width: 48,
    height: 48,
  },
  xl: {
    width: 56,
    height: 56,
  },
});
