import { colors } from "components/colors";
import {
  GroupBudgetStatus,
  OrganizationBudgetState,
} from "core/graphql.generated";
import {
  BudgetCardContainer,
  BudgetCardLegend,
  BudgetCardProgressBar,
  BudgetCardStatusChip,
  BudgetCardSubtitle,
  BudgetCardSubtitleLabelWithAmount,
  BudgetCardTitle,
} from "../../components/budget_card_template";
import { BudgetColor } from "../../budget_colors";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";

export interface GroupBudgetOverviewCardProps {
  name: string;
  color: BudgetColor;
  limit: number;
  spend: number;
  remaining: number;
  status?: GroupBudgetStatus | null;
  state?: OrganizationBudgetState;
  isProgressBar?: boolean;
}

export function GroupBudgetOverviewCard(props: GroupBudgetOverviewCardProps) {
  const {
    name,
    limit,
    spend,
    remaining,
    status,
    state,
    color: colorProp,
    isProgressBar = false,
  } = props;
  const shouldShowWarning =
    state === OrganizationBudgetState.AboveWarningThreshold;
  const color = shouldShowWarning
    ? colors.budget.over
    : colors.budget[colorProp] || colors.budget.other;

  return (
    <BudgetCardContainer inactive={status === GroupBudgetStatus.Inactive}>
      <BudgetCardTitle warning={shouldShowWarning} title={name}>
        {!isProgressBar && status && (
          <BudgetCardStatusChip
            status={status}
            dimmed={status === GroupBudgetStatus.Inactive}
          />
        )}
      </BudgetCardTitle>
      <BudgetCardSubtitle>
        <BudgetCardSubtitleLabelWithAmount
          label="Monthly limit:"
          amount={limit}
        />
      </BudgetCardSubtitle>
      <BudgetCardProgressBar
        height={16}
        items={[
          {
            color: color.core,
            value: spend / limit,
          },
        ]}
        backgroundColor={color.lightest}
      />
      <BudgetCardLegend
        isProgressBar={isProgressBar}
        items={[
          {
            color: color.core,
            legendLabel: "Spent",
            legendValue: formatCurrencyWithoutDecimal(
              spend,
              getSystemLocale(),
              "USD",
            ),
            legendValueColor: shouldShowWarning && "grapefruit-core",
          },
          {
            color: color.lightest,
            legendLabel: "Remaining",
            legendValue: formatCurrencyWithoutDecimal(
              remaining,
              getSystemLocale(),
              "USD",
            ),
            legendValueColor: shouldShowWarning && "grapefruit-core",
          },
        ]}
        columns={1}
      />
    </BudgetCardContainer>
  );
}
