import { gql, useQuery } from "@apollo/client";
import { useStripe } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Dialog } from "components/dialog";
import { GridV2 } from "components/gridv2";
import { Heading } from "components/heading_v2";
import { Icon } from "components/iconv2";
import { Map } from "components/map";
import { PreferredPartnerBanner } from "components/preferred_partner_banner";
import { IconType, SaveFavoriteButton } from "components/save_favorite_button";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import { SearchValue, useSearchValue } from "core/booking_utils";
import {
  OffsiteSpaceDetailsWhosHereV2Query,
  OffsiteSpaceDetailsWhosHereV2QueryVariables,
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  OffsiteSpacePageV2Query,
  OffsiteSpacePageV2QueryVariables,
  SpaceDetailsCurrentUserMonthlyBillingV2Query,
  SpaceType,
} from "core/graphql.generated";
import { WhosHere } from "core/offsite_space_details_whos_here";
import { withStripe } from "core/with_stripe";
import { useGoBack } from "hooks/use_go_back";
import { addDays, getLocalToday } from "lib/day_utils";
import { isEmpty } from "lib/lang_utils";
import { useMediaQuery } from "lib/media_query";
import { operatingHoursContent } from "lib/operating_hours";
import { useQueryString } from "lib/query_string";
import { getSpaceType } from "lib/space_type_utils";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { useRecentlyViewedLocations } from "pages/homev3/hooks/use_recently_viewed_locations";
import { useAnalytics } from "providers/analytics";
import {
  usePostBookingsFeatureFlag,
  usePreferredSpaceFeatureFlag,
} from "providers/splitio";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { Amenities } from "../office_space_details/components/amenities";
// todo investigate ../office_space_details
import { RequestBookFormDialogContent } from "../office_space_details/components/request_book_form_dialog_content";
import { BookingBanner } from "./components/booking_banners";
import { BottomBarBookSpace } from "./components/bottom_bar_book_space";
import { OffsiteSpaceDetailsSection } from "./components/offsite_space_details_section";
import { OffsiteSpaceDetailsSkeleton } from "./components/offsite_space_details_skeleton";
import { SpaceAttributes } from "./components/space_attributes";
import { SpaceDetailCopyShareLinkButton } from "./components/space_detail_copy_share_link_button";
import {
  iWGRegusPartnerId,
  iWGSpacePartnerId,
  SpaceImageGallery,
} from "./components/space_image_gallery";
import { SpacePerks } from "./components/space_perks";
import { SpaceRoomLayouts } from "./components/space_room_layouts";
import { StickyPanelBookSpace } from "./components/sticky_panel_bookspace";
import { useSyncSearchValueToURL } from "./hooks/use_sync_search_value_to_url";
import {
  OffsiteSpaceDetailsContentDesktop,
  OffsiteSpaceDetailsDesktopComponents,
} from "./layout/offsite_space_details_content_desktop";
import { OffsiteSpaceDetailsContentMobile } from "./layout/offsite_space_details_content_mobile";
import { OffsiteSpaceDetailsLayout } from "./layout/offsite_space_details_layout";
import "./offsite_space_details_v2.css";

interface OffsiteSpaceDetailsPageParams {
  offsiteSpaceID: string;
}

interface OffsiteSpaceDetailsPageQueryString {
  physicalSpaceID: string | undefined;
}

export const OffsiteSpaceDetailsPageV2 = withStripe(
  function OffsiteSpaceDetailsPage() {
    const { offsiteSpaceID } = useParams<OffsiteSpaceDetailsPageParams>();
    const { saveLocationToRecentlyViewed, isAlreadyInRecentlySaved } =
      useRecentlyViewedLocations();
    const {
      data: offsiteSpacePageQuery,
      loading,
      error,
    } = useQuery<OffsiteSpacePageV2Query, OffsiteSpacePageV2QueryVariables>(
      offsitePageGQLQuery,
      {
        variables: {
          id: offsiteSpaceID,
        },
      },
    );

    const queryString = useQueryString<OffsiteSpaceDetailsPageQueryString>();
    const initialSearchValue = useSearchValue(queryString);
    const [searchValue, setSearchValue] =
      useState<SearchValue>(initialSearchValue);

    const stripe = useStripe();

    const space = offsiteSpacePageQuery?.space;

    useEffect(() => {
      if (
        space &&
        space.location &&
        !isAlreadyInRecentlySaved(space.location.id)
      ) {
        saveLocationToRecentlyViewed({
          id: space.location.id,
          name: space.location.name,
          images: space.location.images,
          address: space.location.address,
        });
      }
    }, [isAlreadyInRecentlySaved, saveLocationToRecentlyViewed, space]);

    return (
      <OffsiteSpaceDetailsLayout
        actions={
          !!space && (
            <Fragment>
              <SpaceDetailCopyShareLinkButton
                spaceId={space.id}
                spaceName={space.name}
                dateRange={searchValue.dateRange}
              />
              <SaveFavoriteButton
                favoriteId={space.id || offsiteSpaceID}
                favoriteType={FavoriteType.Space}
                onlyIcon={true}
                iconType={IconType.filled}
              />
            </Fragment>
          )
        }
      >
        <AsyncRenderer
          error={error}
          loading={loading}
          data={offsiteSpacePageQuery}
          loadingHandler={() => <OffsiteSpaceDetailsSkeleton />}
        >
          {(offsiteSpaceData) => {
            if (!offsiteSpaceData.space) {
              return <Redirect to="/" />;
            }
            return (
              <OffsiteSpaceDetails
                space={offsiteSpaceData.space}
                stripe={stripe}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            );
          }}
        </AsyncRenderer>
      </OffsiteSpaceDetailsLayout>
    );
  },
);

interface OffsiteSpaceDetailsProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  stripe: Stripe | null;
  searchValue: SearchValue;
  setSearchValue: Dispatch<SetStateAction<SearchValue>>;
}

function OffsiteSpaceDetails(props: OffsiteSpaceDetailsProps) {
  const { space, stripe, searchValue, setSearchValue } = props;
  const analytics = useAnalytics();
  const offsiteSpaceID = space.id;
  const goBack = useGoBack();
  const postBookingFeatureFlag = usePostBookingsFeatureFlag();
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  const { data } = useQuery<SpaceDetailsCurrentUserMonthlyBillingV2Query>(
    currentUserMonthlyBillingGQLQuery,
  );
  const monthlyBilling = !!data?.currentUser?.organization?.monthlyBilling;
  const mq = useMediaQuery();
  const [openBookModal, setOpenBookModal] = useState<boolean>(false);

  const handleCloseBookModal = useCallback(() => {
    setOpenBookModal(false);
  }, []);

  const bookingsDayInterval = useMemo(() => {
    const localToday = getLocalToday(space.location.timezone);
    return {
      start: localToday,
      end: addDays(localToday, 7),
    };
  }, [space.location.timezone]);
  const { data: OffsiteSpaceDetailsWhosHereV2Query } = useQuery<
    OffsiteSpaceDetailsWhosHereV2Query,
    OffsiteSpaceDetailsWhosHereV2QueryVariables
  >(offsiteSpaceDetailsWhosHereGQLQuery, {
    variables: {
      offsiteLocationID: space.location.id,
      startDate: bookingsDayInterval.start,
      endDate: bookingsDayInterval.end,
    },
  });
  const offsiteLocationBookings =
    OffsiteSpaceDetailsWhosHereV2Query?.offsiteLocation?.bookings;
  useSyncSearchValueToURL({
    searchValue,
    offsiteSpaceID,
    baseURL: `/offsite_spaces/${offsiteSpaceID}`,
  });
  const handleChangeSearchValue = useCallback(
    (newValue: SearchValue) => {
      setSearchValue(newValue);
    },
    [setSearchValue],
  );
  const handleChangePromoCode = useCallback(
    (promoCode: string) => {
      setSearchValue((prevValue) => ({
        ...prevValue,
        promoCode,
      }));
    },
    [setSearchValue],
  );

  const isStockPhoto =
    space.spaceType !== SpaceType.DayPass &&
    (space.partnerID === iWGSpacePartnerId ||
      space.partnerID === iWGRegusPartnerId);

  useEffect(() => {
    analytics.event("View Space", {
      "Space UUID": space.id,
      "Space Name": space.name,
      City: space.location.address.city,
      State: space.location.address.state,
      Country: space.location.address.country,
      Location: space.location.name,
    });
  }, [
    analytics,
    space.id,
    space.location.address.city,
    space.location.address.country,
    space.location.address.state,
    space.location.name,
    space.name,
  ]);

  const components: OffsiteSpaceDetailsDesktopComponents = {
    header: (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <View style={{ width: mq.deviceQuery.mobile ? "100%" : undefined }}>
          {!mq.deviceQuery.mobile && (
            <Fragment>
              <Spacer size={40} />
              <Pressable onPress={goBack}>
                <Icon name="arrow-left" size="md" />
              </Pressable>
              <Spacer size={16} />
            </Fragment>
          )}

          <Heading size={mq.deviceQuery.mobile ? "md" : "lg"}>
            {space.name}
          </Heading>
          <Spacer size={8} />
          <Text size={mq.deviceQuery.mobile ? "sm" : "md"}>
            {`${getSpaceType(space.spaceType)} at `}
            <TextLink
              href={`/location/${space.location.id}`}
              text={space.location.name}
              inline
              size={mq.deviceQuery.mobile ? "sm" : "md"}
            />
          </Text>
        </View>

        {!mq.deviceQuery.mobile && postBookingFeatureFlag && (
          <View style={styles.buttons}>
            <SpaceDetailCopyShareLinkButton
              spaceId={space.id}
              spaceName={space.name}
              dateRange={searchValue.dateRange}
            />
            <Spacer direction="row" size={8} />
            <SaveFavoriteButton
              favoriteId={space.id}
              favoriteType={FavoriteType.Space}
            />
          </View>
        )}
      </View>
    ),
    images: <SpaceImageGallery space={space} />,
    spaceAttributes: (
      <SpaceAttributes
        space={space}
        physicalSpaceID={searchValue.physicalSpaceID}
      />
    ),
    aboutSpace: space.description ? (
      <OffsiteSpaceDetailsSection title="About this space">
        <div
          className="space-detail__description"
          dangerouslySetInnerHTML={{
            __html: isStockPhoto
              ? space.description.concat(
                  `<br/><br/>Photo(s) are for illustrative purposes, and are representative of the actual space.`,
                )
              : space.description,
          }}
        />
      </OffsiteSpaceDetailsSection>
    ) : undefined,
    preferred:
      isPreferredFlag && space.preferred ? (
        <PreferredPartnerBanner
          locationName={`${space.name} is a Preferred Partner`}
        />
      ) : undefined,
    whosHere:
      !!offsiteLocationBookings && !isEmpty(offsiteLocationBookings) ? (
        <OffsiteSpaceDetailsSection title="Who's Here">
          <WhosHere
            dayInterval={bookingsDayInterval}
            bookings={offsiteLocationBookings}
            timeZone={space.location.timezone}
            capacity={space.maxCapacity}
            spaceType={space.spaceType}
          />
        </OffsiteSpaceDetailsSection>
      ) : undefined,
    perks:
      space.spacePerks && space.spacePerks.length > 0 ? (
        <OffsiteSpaceDetailsSection title="Perks">
          <SpacePerks space={space} />
        </OffsiteSpaceDetailsSection>
      ) : undefined,
    roomLayouts:
      space.layouts.length > 1 ? (
        <OffsiteSpaceDetailsSection title="Room layouts">
          <SpaceRoomLayouts space={space} />
        </OffsiteSpaceDetailsSection>
      ) : undefined,
    amenties:
      space.amenities.length > 0 || space.location.amenities.length > 0 ? (
        <Amenities
          spaceAmenities={space.amenities}
          locationAmenities={space.location.amenities}
        />
      ) : undefined,
    pricePannel: mq.sizeQuery.mdAndUp ? (
      <div className="space-details-sticky-panel">
        <StickyPanelBookSpace
          space={space}
          searchValue={searchValue}
          onChangeSearchValue={handleChangeSearchValue}
        />
      </div>
    ) : (
      <div className="hidden-md space-details-bottom-bar">
        <BottomBarBookSpace
          monthlyBilling={monthlyBilling}
          space={space}
          onChangePromoCode={handleChangePromoCode}
          stripe={stripe}
          searchValue={searchValue}
          onChange={handleChangeSearchValue}
        />
      </div>
    ),
    location: (
      <OffsiteSpaceDetailsSection title="Location">
        <Text size="xs">
          {space.location.name} is located at{" "}
          {space.location.address.fullAddress}
        </Text>
      </OffsiteSpaceDetailsSection>
    ),
    map: (
      <Suspense fallback={<Spinner />}>
        <Map
          height={315}
          latitude={space.location.address.latitude}
          longitude={space.location.address.longitude}
          fullScreenControl
        />
      </Suspense>
    ),
    directions: space.location.directions ? (
      <OffsiteSpaceDetailsSection title="How to get there">
        <div
          dangerouslySetInnerHTML={{
            __html: space.location.directions,
          }}
        />
      </OffsiteSpaceDetailsSection>
    ) : undefined,
    collaborateBanner: postBookingFeatureFlag ? (
      <BookingBanner spaceCapacity={space.maxCapacity} />
    ) : (
      <></>
    ),
    hours: (
      <OffsiteSpaceDetailsSection title="Hours">
        <View>
          {operatingHoursContent(space.bookingHours).map((operatingHour) => (
            <GridV2 columns={2} columnGap={8}>
              <Text size="xs">{operatingHour.label}</Text>
              <Text size="xs" color="black-70">
                {operatingHour.open} - {operatingHour.close}
              </Text>
            </GridV2>
          ))}
        </View>
      </OffsiteSpaceDetailsSection>
    ),
  };

  return (
    <View>
      {mq.deviceQuery.mobile ? (
        <OffsiteSpaceDetailsContentMobile components={components} />
      ) : (
        <OffsiteSpaceDetailsContentDesktop components={components} />
      )}
      <Dialog visible={openBookModal} onRequestClose={handleCloseBookModal}>
        <RequestBookFormDialogContent onClose={handleCloseBookModal} />
      </Dialog>
    </View>
  );
}

const styles = StyleSheet.create({
  buttons: {
    flexDirection: "row",
  },
});

const offsitePageGQLQuery = gql`
  query OffsiteSpacePageV2($id: ID!) {
    space(id: $id) {
      id
      ...OffsiteSpacePage__SpaceDetailsV2
    }
  }

  fragment OffsiteSpacePage__SpaceDetailsV2 on Space {
    id
    name
    partnerID
    spaceType
    description
    defaultLayoutID
    currency
    locked
    preferred
    savedByCurrentUser
    bookingConfig {
      enabled
      multiDayBookingAllowed
    }
    physicalSpaces {
      ...OffsitePage__PhysicalSpaceDetailsV2
    }
    categories {
      code
      name
      description
    }
    location {
      id
      name
      amenities {
        id
        name
        iconURL
        code
      }
      logo {
        url
      }
      images {
        thumb {
          url
          width
          height
        }
        small {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
        large {
          url
          width
          height
        }
      }
      timezone
      address {
        fullAddress
        streetAddress
        postalCode
        country
        city
        state
        latitude
        longitude
      }
      pricingInclusion
      directions
    }
    images {
      thumb {
        url
        width
        height
      }
      small {
        url
        width
        height
      }
      medium {
        url
        width
        height
      }
      large {
        url
        width
        height
      }
    }
    amenities {
      id
      name
      iconURL
      code
    }
    layouts {
      id
      name
      description
      bestFor
      capacity
      imageURL
    }
    inventoryCapacity
    area
    maxCapacity
    pricings {
      type
      price
    }
    priceRanges {
      hourly {
        min
        max
      }
      daily {
        min
        max
      }
    }
    bookingHours {
      dayOfWeek
      closedAt
      openAt
    }
    bookingPolicy {
      minDuration
      cancellationHours
      cancellationTime1DayBefore
      cancellationDays
    }
    locationID
    locationTimeZone
    spacePerks {
      code
      name
      description
      iconURL
    }
  }

  fragment OffsitePage__PhysicalSpaceDetailsV2 on PhysicalSpace {
    id
    name
    floor
    withWindow
  }
`;

const offsiteSpaceDetailsWhosHereGQLQuery = gql`
  query OffsiteSpaceDetailsWhosHereV2(
    $offsiteLocationID: ID!
    $startDate: String!
    $endDate: String!
  ) {
    offsiteLocation(id: $offsiteLocationID) {
      id
      bookings(startDate: $startDate, endDate: $endDate) {
        ...OffsiteSpaceDetailsWhosHere__BookingDetailsV2
      }
    }
  }
  fragment OffsiteSpaceDetailsWhosHere__BookingDetailsV2 on Booking {
    id
    startDate
    endDate
    user {
      id
      fullName
      title
      picture
    }
  }
`;

const currentUserMonthlyBillingGQLQuery = gql`
  query SpaceDetailsCurrentUserMonthlyBillingV2 {
    currentUser {
      id
      organization {
        monthlyBilling
      }
    }
  }
`;
