import { gql, useQuery } from "@apollo/client";

import { AsyncRenderer } from "components/AsyncRenderer";
import { IconType, SaveFavoriteButton } from "components/save_favorite_button";
import { Text } from "components/text_v2";
import {
  LocationDetailsQuery,
  LocationDetailsQueryVariables,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { useRecentlyViewedLocations } from "pages/homev3/hooks/use_recently_viewed_locations";
import { useFavoritesFeatureFlag } from "providers/splitio";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LocationDetailsDayPass } from "./components/location_details_available_spaces";
import { LocationDetailsAvailableSpace } from "./components/location_details_available_spaces_modal";
import { LocationDetailCopyShareLinkButton } from "./components/location_details_copy_share_link_button";
import { LocationDetailsSkeletonDesktop } from "./components/location_details_skeleton_desktop";
import { LocationDetailsSkeletonMobile } from "./components/location_details_skeleton_mobile";

import { LocationDetailsLayout } from "./layout/location_details_layout";
import { LocationDetailsDesktopContent } from "./location_details_desktop";
import { LocationDetailsMobileContent } from "./location_details_mobile";
import { getSpacePricings } from "./utils/getSpacePricings";

interface LocationDetailsPageParam {
  locationId: string;
}

export function LocationDetails() {
  const mq = useMediaQuery();
  const { locationId } = useParams<LocationDetailsPageParam>();
  const favoritesFeatureFlag = useFavoritesFeatureFlag();
  const { saveLocationToRecentlyViewed, isAlreadyInRecentlySaved } =
    useRecentlyViewedLocations();

  const { data, error, loading } = useQuery<
    LocationDetailsQuery,
    LocationDetailsQueryVariables
  >(locationDetailsQuery, {
    variables: { locationId },
  });

  useEffect(() => {
    const location = data?.location;
    if (location && !isAlreadyInRecentlySaved(location.id)) {
      saveLocationToRecentlyViewed({
        id: location.id,
        name: location.name,
        images: location.images,
        address: location.address,
        currentUserDistance: location.distance,
        preferred: location.preferred,
      });
    }
  }, [data?.location, isAlreadyInRecentlySaved, saveLocationToRecentlyViewed]);

  const renderLocationDetails = (data: LocationDetailsQuery) => {
    if (!data.location) {
      return <Text>No data available</Text>;
    }

    let privateOffices: LocationDetailsAvailableSpace[] | undefined;
    if (data.dayOffices?.spaces.length) {
      privateOffices = data.dayOffices.spaces.map((s) => ({
        spaceId: s.id,
        imageSrc: s.images[0].small.url,
        name: s.name,
        outOfPolicy: s.locked,
        capacity: s.maxCapacity,
        amenities: s.amenities.map((a) => a.name),
        pricings: getSpacePricings({
          currency: s.currency,
          priceRanges: s.priceRanges,
          pricings: s.pricings,
        }),
      }));
    }
    let meetingRooms: LocationDetailsAvailableSpace[] | undefined;
    if (data.meetingRooms?.spaces.length) {
      meetingRooms = data.meetingRooms.spaces.map((s) => ({
        spaceId: s.id,
        imageSrc: s.images[0].small.url,
        name: s.name,
        outOfPolicy: s.locked,
        capacity: s.maxCapacity,
        amenities: s.amenities.map((a) => a.name),
        pricings: getSpacePricings({
          currency: s.currency,
          priceRanges: s.priceRanges,
          pricings: s.pricings,
        }),
      }));
    }
    let dayPass: LocationDetailsDayPass | undefined;
    if (data.dayPasses?.spaces[0]?.id) {
      dayPass = {
        spaceId: data.dayPasses.spaces[0].id,
        imageSrc: data.dayPasses.spaces[0].images[0].small.url,
      };
    }

    const spaceTypePolicy =
      data.currentUser?.groupBudget?.policy?.spaceTypes ||
      data.currentUser?.organization?.budget?.policy?.spaceTypes;

    if (mq.deviceQuery.mobile) {
      return (
        <LocationDetailsMobileContent
          location={data.location}
          privateOffices={privateOffices}
          meetingRooms={meetingRooms}
          dayPass={dayPass}
          spaceTypePolicy={spaceTypePolicy}
        />
      );
    }

    return (
      <LocationDetailsDesktopContent
        location={data.location}
        privateOffices={privateOffices}
        meetingRooms={meetingRooms}
        dayPass={dayPass}
        spaceTypePolicy={spaceTypePolicy}
      />
    );
  };

  return (
    <LocationDetailsLayout
      actions={
        favoritesFeatureFlag &&
        data?.location?.id && (
          <Fragment>
            <LocationDetailCopyShareLinkButton locationId={data.location.id} />
            <SaveFavoriteButton
              favoriteType={FavoriteType.Location}
              favoriteId={data?.location?.id}
              onlyIcon={true}
              iconType={IconType.filled}
            />
          </Fragment>
        )
      }
    >
      <AsyncRenderer
        loading={loading}
        error={error}
        data={data}
        loadingHandler={() =>
          mq.deviceQuery.mobile ? (
            <LocationDetailsSkeletonMobile />
          ) : (
            <LocationDetailsSkeletonDesktop />
          )
        }
      >
        {renderLocationDetails}
      </AsyncRenderer>
    </LocationDetailsLayout>
  );
}

const locationDetailsQuery = gql`
  query LocationDetails($locationId: ID!) {
    currentUser {
      organization {
        budget {
          id
          policy {
            id
            spaceTypes {
              ...LocationDetails__SpaceTypePolicy
            }
          }
        }
      }
      groupBudget {
        id
        policy {
          id
          spaceTypes {
            ...LocationDetails__SpaceTypePolicy
          }
        }
      }
    }
    location: offsiteLocation(id: $locationId) {
      ...LocationDetails__Location
    }
    meetingRooms: offsiteLocation(id: $locationId) {
      id
      spaces(spaceType: MeetingRoom) {
        ...LocationDetails__SpaceDetails
      }
    }
    dayPasses: offsiteLocation(id: $locationId) {
      id
      spaces(spaceType: DayPass) {
        ...LocationDetails__SpaceDetails
      }
    }
    dayOffices: offsiteLocation(id: $locationId) {
      id
      spaces(spaceType: DayOffice) {
        ...LocationDetails__SpaceDetails
      }
    }
  }

  fragment LocationDetails__SpaceDetails on Space {
    id
    name
    locked
    maxCapacity
    images {
      small {
        url
      }
    }
    amenities {
      id
      name
    }
    ...LocationDetails__SpacePriceDetails
  }

  fragment LocationDetails__SpacePriceDetails on Space {
    currency
    pricings {
      type
      price
    }
    priceRanges {
      daily {
        min
        max
      }
      hourly {
        min
        max
      }
    }
  }

  fragment LocationDetails__Location on Location {
    id
    name
    directions
    description
    savedByCurrentUser
    preferred
    distance
    images {
      small {
        url
        width
        height
      }
      medium {
        url
        width
        height
      }
      large {
        url
        width
        height
      }
    }
    amenities {
      id
      name
      iconURL
      code
    }
    address {
      fullAddress
      latitude
      longitude
      city
      streetAddress
    }
    bookingHours {
      dayOfWeek
      closedAt
      openAt
    }
  }

  fragment LocationDetails__SpaceTypePolicy on SpaceTypes {
    meetingRoom
    dayPass
    privateOffice
  }
`;
