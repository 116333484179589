import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { DialogModal } from "components/dialog_modal";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { TextInput } from "components/text_input";
import { TextLink } from "components/text_link";
import { Text as TextV2 } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import {
  useOrderMemoFeatureFlag,
  usePostBookingsFeatureFlag,
} from "providers/splitio";
import { useToast } from "providers/toast";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { Image as RNImage, View, TextInput as RNTextInput } from "react-native";
import { useHistory } from "react-router-dom";
import { getBookingDateRangeLabel, SearchValue } from "./booking_utils";
import {
  CurrentUserInfoQuery,
  ImageData,
  Plan,
  UpdateOrderMemoMutation,
  UpdateOrderMemoMutationVariables,
} from "./graphql.generated";
import { currentUserInfoGQLQuery } from "./booking_checkout";

interface BookingPreviewProps {
  searchValue: SearchValue;
  space: {
    name: string;
    images: {
      small: ImageData;
    }[];
    location: {
      name: string;
    };
  };
  onPressChange?: () => void;
  dateTimeDropDownPicker?: React.ReactNode;
  confirmationId?: string;
  memo?: string | null;
  bookingID?: string;
}

export function BookingPreview(props: BookingPreviewProps) {
  const { space, searchValue, onPressChange, dateTimeDropDownPicker } = props;
  const { dateRange } = searchValue;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  return (
    <Container padding={isMobile ? 16 : 0}>
      <Row expanded>
        <RNImage
          source={{
            uri: space.images[0].small.url,
            width: isMobile ? 80 : 120,
            height: isMobile ? 80 : 120,
          }}
        />
        <Spacer size={16} />
        <Container flex={1}>
          <TextV2 size="md" weight={"semibold"}>
            {space.name} at {space.location.name}
          </TextV2>
          {}
          <Spacer size={4} />
          <TextV2 size="xs" color={"black-30"}>
            {getBookingDateRangeLabel(dateRange)}
          </TextV2>
          <Spacer size={4} />
          {isMobile && onPressChange ? (
            <TextLink
              onPress={onPressChange}
              text="Change details"
              size="xs"
              weight={"semibold"}
            />
          ) : (
            dateTimeDropDownPicker
          )}
        </Container>
      </Row>
    </Container>
  );
}

export function BookingPreviewV2(props: BookingPreviewProps) {
  const {
    space,
    searchValue,
    onPressChange,
    dateTimeDropDownPicker,
    confirmationId,
    memo,
    bookingID,
  } = props;
  const { dateRange } = searchValue;
  const isMemoFlag = useOrderMemoFeatureFlag();
  const analytics = useAnalytics();
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const postBookingFeatureFlag = usePostBookingsFeatureFlag();
  const history = useHistory();
  const toast = useToast();
  const [editMemoVisible, setEditMemoVisible] = useState(false);
  const inputRef = useRef<RNTextInput>(null);
  const [newMemo, setNewMemo] = useState(memo);
  const { data } = useQuery<CurrentUserInfoQuery>(currentUserInfoGQLQuery);
  const isPro = data?.currentUser?.organization?.plan === Plan.Pro;
  const [updateOrderMemo] = useMutation<
    UpdateOrderMemoMutation,
    UpdateOrderMemoMutationVariables
  >(updateOrderMemoMutation);

  const onSavedMemo = async () => {
    setEditMemoVisible(false);
    if (!bookingID) {
      return;
    }
    try {
      await updateOrderMemo({
        variables: { orderID: bookingID, memo: newMemo },
      });
      analytics.event("Update transaction", {
        memo: !!newMemo,
      });
      toast.notify({ message: "Memo successfully saved" });
    } catch {
      toast.notify({ message: "Error happened! Please try later." });
    }
  };

  const onShowAnimationEnd = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <View>
      <RNImage
        source={{
          uri: space.images[0].small.url,
          height: 160,
        }}
      />
      <Spacer size={12} />
      <TextV2 size="xs" weight="semibold">
        {getBookingDateRangeLabel(dateRange)}
      </TextV2>
      <Spacer size={8} />
      <TextV2 size="md" weight={"semibold"}>
        {space.location.name} &middot; {space.name}
      </TextV2>
      {confirmationId ? (
        <View>
          <Spacer size={8} />
          <View style={{ flexDirection: "row" }}>
            <TextV2 size="xs" color="black-70">
              Confirmation:
            </TextV2>
            <Spacer size={2} direction="row" />
            <TextV2 size="xs" color="black-70" weight="semibold">
              {confirmationId}
            </TextV2>
            <Spacer size={2} direction="row" />
            {isMemoFlag && isPro && (
              <TextV2
                size="xs"
                color={"eggplant-core"}
                weight={"bold"}
                onPress={() => setEditMemoVisible(true)}
                testID="memo-modal-button"
              >
                &middot; Memo
              </TextV2>
            )}
          </View>
          <Spacer size={16} />
          {bookingID && postBookingFeatureFlag && (
            <Fragment>
              <Button
                text="See booking details"
                onPress={() => history.push(`/bookings/${bookingID}`)}
              />
            </Fragment>
          )}
        </View>
      ) : (
        <Spacer size={16} />
      )}
      {isMobile && onPressChange ? (
        <TextLink
          onPress={onPressChange}
          text="Change details"
          size="xs"
          weight={"semibold"}
        />
      ) : (
        dateTimeDropDownPicker
      )}
      <DialogModal
        isVisible={editMemoVisible}
        headerTitle="Edit memo"
        onClose={() => setEditMemoVisible(false)}
        desktopWidth={500}
        onShowAnimationEnd={onShowAnimationEnd}
        center={mq.deviceQuery.desktop}
        bottomButtons={
          <View style={{ width: isMobile ? "100%" : 229 }}>
            <Button
              testID="memo-save-button"
              text="Save changes"
              onPress={onSavedMemo}
            />
          </View>
        }
      >
        <TextInput
          name="memo"
          value={newMemo || ""}
          placeholder={"What was this booking for?"}
          onChange={setNewMemo}
          numberOfLines={2}
          ref={inputRef}
          testID="memo-input"
        />
      </DialogModal>
    </View>
  );
}

export const updateOrderMemoMutation = gql`
  mutation updateOrderMemo($orderID: ID!, $memo: String) {
    updateOrderMemo(orderID: $orderID, memo: $memo)
  }
`;
