import { gql, useMutation, useQuery } from "@apollo/client";
import { BudgetsDateRangePicker } from "components/budgets_date_range_picker/budgets_date_range_picker";
import { getInitialDayInterval } from "components/budgets_date_range_picker/budgets_date_range_picker_common";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { DataTableRow } from "components/data_table/data_table_base";
import { DataTableRaw } from "components/data_table/data_table_raw";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { EmptyData } from "components/empty_data";
import ExportCSV from "components/export_csv/export_csv.web";
import { Icon } from "components/iconv2";
import Pagination from "components/pagination/pagination";
import { Spacer } from "components/spacer";
import { TextInput } from "components/text_input";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2.web";
import { updateOrderMemoMutation } from "core/booking_preview";
import {
  OrganizationBudgetDetailTransactionsQuery,
  OrganizationBudgetDetailTransactionsQueryVariables,
  UpdateOrderMemoMutation,
  UpdateOrderMemoMutationVariables,
} from "core/graphql.generated";
import { format } from "date-fns";
import { formatCurrency } from "lib/currency";
import { DayInterval, parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";

import { useQueryString } from "lib/query_string";
import { TableRowActionPropsType } from "pages/booking_requests/components/pending_requests_table";
import { BudgetTransactionTable } from "pages/budgets/components/budget_transaction_table";
import { BudgetTransactionTableRaw } from "pages/budgets/components/budget_transaction_table_raw";
import { getDataAsLabel } from "pages/reporting/utils/get_last_updated_label";
import { useAnalytics } from "providers/analytics";
import {
  useBudgetAttributionFeatureFlag,
  useOrderMemoFeatureFlag,
} from "providers/splitio";
import { useToast } from "providers/toast";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  Pressable,
  StyleSheet,
  TextInput as RNTextInput,
  View,
} from "react-native";
import { useHistory } from "react-router";
import { usePagination } from "../hooks/use_pagination";
import { OrganizationBudgetDetailSkeleton } from "./organization_budget_detail_skeleton";

const mobileCols = [
  {
    id: "transaction",
    title: "Transaction",
    width: "100%",
  },
];

const mobileColsWithOutTitle = [
  {
    id: "transaction",
    title: "",
    width: "100%",
  },
];

const desktopCols = [
  {
    id: "transaction",
    title: "Transaction",
    width: "35%",
  },

  {
    id: "bookTotal",
    title: "Total",
    width: "15%",
  },
  {
    id: "bookFor",
    title: "Booked for",
    width: "15%",
  },
  {
    id: "bookOn",
    title: "Booked on",
    width: "15%",
  },
  {
    id: "budget",
    title: "Associated budget",
    width: "20%",
  },
];

const desktopColsWithAction = [
  ...desktopCols,
  {
    id: "actions",
    title: "",
    width: "5%",
  },
];

interface OrgBudgetSearchParams {
  start?: string;
  end?: string;
}

type BookingRequestId = {
  id: string;
};
type GroupBudget = {
  id: string;
  name: string;
};

type OrderMemo = {
  memo?: string | null;
};

export type OrderSummaryResponse = {
  orderID: string;
  hostFullName: string;
  locationName: string;
  bookingDate: string;
  orderCreatedAt: string;
  totalPrice: number;
  currency: string;
  spaceName: string;
  bookingRequest?: BookingRequestId | null;
  locationAddress: string;
  groupBudget?: GroupBudget | null;
  order: OrderMemo;
};

export function OrganizationBudgetDetailTransactions() {
  const mq = useMediaQuery();
  const toast = useToast();
  const history = useHistory();
  const analytics = useAnalytics();
  const isBudgetAttribution = useBudgetAttributionFeatureFlag();
  const sp = useQueryString<OrgBudgetSearchParams>();
  const [dayInterval, setDayInterval] = useState<DayInterval>(
    getInitialDayInterval(sp),
  );
  const [currentPage, setCurrentPage] = usePagination();
  const isMemoFlag = useOrderMemoFeatureFlag();

  const [editMemoVisible, setEditMemoVisible] = useState(false);
  const [newMemo, setNewMemo] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState("");
  const inputRef = useRef<RNTextInput>(null);

  const [updateOrderMemo] = useMutation<
    UpdateOrderMemoMutation,
    UpdateOrderMemoMutationVariables
  >(updateOrderMemoMutation);

  const { loading, error, data, refetch } = useQuery<
    OrganizationBudgetDetailTransactionsQuery,
    OrganizationBudgetDetailTransactionsQueryVariables
  >(orgBudgetDetailTransactionQuery, {
    variables: {
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
    notifyOnNetworkStatusChange: true,
  });
  const onSavedMemo = async () => {
    if (!currentOrderId) {
      return;
    }
    try {
      await updateOrderMemo({
        variables: { orderID: currentOrderId, memo: newMemo },
      });
      analytics.event("Update transaction", {
        memo: !!newMemo,
      });
      toast.notify({ message: "Memo successfully saved" });
      await refetch();
    } catch {
      toast.notify({ message: "Error happened! Please try later." });
    } finally {
      setEditMemoVisible(false);
    }
  };

  const onShowAnimationEnd = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("page", `${currentPage}`);
    newSearchParams.set("start", dayInterval.start);
    newSearchParams.set("end", dayInterval.end);
    history.replace({ search: newSearchParams.toString() });
  }, [currentPage, dayInterval, history]);

  if (loading) {
    return <OrganizationBudgetDetailSkeleton />;
  }
  if (error) {
    return <Text>Error</Text>;
  }
  if (!data?.currentUser?.organization?.ordersSummary) {
    return <Text>Data is unvailable</Text>;
  }

  const { orders, lastUpdatedDate } =
    data?.currentUser?.organization?.ordersSummary;
  let rowsPerPage = 6;
  if (isBudgetAttribution && mq.deviceQuery.mobile) {
    rowsPerPage = 8;
  }
  const totalPages = Math.ceil(orders.length / rowsPerPage);

  const nameCell = (orderSummary: OrderSummaryResponse) => {
    const {
      orderID,
      hostFullName,
      locationName,
      bookingDate,
      orderCreatedAt,
      totalPrice,
      currency,
      spaceName,
      bookingRequest,
      locationAddress,
      order,
    } = orderSummary;
    const outOfPolicy = !!bookingRequest?.id;
    const content = (
      <View style={styles.nameWrapper}>
        <View style={styles.nameContainer}>
          <Text
            weight="semibold"
            customColor={colors.brand.blackcore}
            size="xs"
          >
            {hostFullName}
          </Text>
          {outOfPolicy && (
            <Fragment>
              <Spacer direction="row" size={4} />
              <Icon name="guard" size="sm" />
            </Fragment>
          )}
        </View>
        <Spacer size={4} />
        <View>
          <Text color="black-70" size="xs">
            {locationName} {spaceName && `· ${spaceName}`}
          </Text>
          <Spacer size={4} />
          <Text color="black-70" size="xs">
            {locationAddress}
          </Text>
        </View>
      </View>
    );

    if (isMemoFlag && mq.deviceQuery.mobile) {
      return (
        <View>
          {content}
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <View style={styles.mobileContent}>
            <View style={styles.horizontalView}>
              <View style={styles.textContent}>
                <Text size={"xs"} weight={"semibold"}>
                  Total
                </Text>
              </View>
              <Text size={"xs"}>
                {formatCurrency(totalPrice, getSystemLocale(), currency)}
              </Text>
            </View>
            <View style={styles.horizontalView}>
              <View style={styles.textContent}>
                <Text size={"xs"} weight={"semibold"}>
                  Booked for
                </Text>
              </View>
              <Text size={"xs"}>{format(parseDay(bookingDate), "MMM d")}</Text>
            </View>
            <View style={styles.horizontalView}>
              <View style={styles.textContent}>
                <Text size={"xs"} weight={"semibold"}>
                  Booked on
                </Text>
              </View>
              <Text size={"xs"}>
                {parseDay(orderCreatedAt).getFullYear() ===
                new Date().getFullYear()
                  ? format(parseDay(orderCreatedAt), "MMM d")
                  : format(parseDay(orderCreatedAt), "MMM d, yyyy")}
              </Text>
            </View>
            <View style={styles.horizontalView}>
              <View style={styles.headerExtension}>
                <Text weight="semibold" size="xs">
                  Memo
                </Text>

                <Pressable
                  onPress={() => {
                    newMemo !== order?.memo && setNewMemo(order?.memo || "");
                    currentOrderId !== orderID && setCurrentOrderId(orderID);
                    setEditMemoVisible(true);
                  }}
                >
                  <Icon color="eggplantcore" name="compose" size="sm" />
                </Pressable>
              </View>
              <Text color={order.memo ? "black-core" : "black-50"} size="xs">
                {order?.memo || "No memo added yet"}
              </Text>
            </View>
          </View>
        </View>
      );
    }
    return content;
  };
  const renderRows = () => {
    const renderedRows = orders
      .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
      .map((orderSummary) => {
        const {
          orderID,
          bookingDate,
          orderCreatedAt,
          totalPrice,
          currency,
          groupBudget,
          order,
        } = orderSummary;
        let result: DataTableRow = {
          id: orderID,
          transaction: {
            render: () => nameCell(orderSummary),
          },
          budget: {
            render: () => {
              if (groupBudget?.id) {
                return (
                  <View style={styles.associatedBudgetContainer}>
                    <TextLink
                      text={groupBudget.name}
                      size="xs"
                      href={`/admin/budgets/${
                        groupBudget.id
                      }?backButtonText=${encodeURIComponent(
                        "Back to organization transactions",
                      )}`}
                    />
                  </View>
                );
              }
              return (
                <View style={styles.associatedBudgetContainer}>
                  <Text size="xs">{data?.currentUser?.organization?.name}</Text>
                </View>
              );
            },
          },
          bookFor: format(parseDay(bookingDate), "MMM d"),
          bookOn:
            parseDay(orderCreatedAt).getFullYear() === new Date().getFullYear()
              ? format(parseDay(orderCreatedAt), "MMM d")
              : format(parseDay(orderCreatedAt), "MMM d, yyyy"),
          bookTotal: formatCurrency(totalPrice, getSystemLocale(), currency),
        };

        if (isMemoFlag) {
          result = {
            ...result,
            actions: {
              render: ({
                onChangeExtend,
                isExtended,
              }: TableRowActionPropsType) => (
                <Pressable onPress={onChangeExtend}>
                  <Icon
                    name={isExtended ? "chevron-up" : "chevron-down"}
                    size="md"
                  />
                </Pressable>
              ),
            },
            extension: (
              <View style={styles.extensionWrapper}>
                <View style={styles.headerExtension}>
                  <Text weight="semibold" size="xs">
                    Memo
                  </Text>
                  <Pressable
                    onPress={() => {
                      newMemo !== order?.memo && setNewMemo(order?.memo || "");
                      currentOrderId !== orderID && setCurrentOrderId(orderID);
                      setEditMemoVisible(true);
                    }}
                  >
                    <Icon color="eggplantcore" name="compose" size="sm" />
                  </Pressable>
                </View>
                <Text color={order.memo ? "black-core" : "black-50"} size="xs">
                  {order?.memo || "No memo added yet"}
                </Text>
              </View>
            ),
          };
        }

        return result;
      });

    return renderedRows;
  };

  const csvOrders = orders.map(
    ({
      hostFullName,
      locationName,
      spaceType,
      bookingDate,
      orderCreatedAt,
      totalPrice,
      currency,
      order: { memo },
    }) => {
      return {
        "Full name": hostFullName,
        Location: locationName,
        "Space type": spaceType,
        "Booking total": totalPrice,
        Currency: currency,
        "Booked for": bookingDate,
        "Booked on": orderCreatedAt,
        Memo: memo || "",
      };
    },
  );

  const table = isBudgetAttribution ? (
    <BudgetTransactionTable
      orders={orders.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage,
      )}
      orgInfo={data?.currentUser?.organization}
      refetch={refetch}
    />
  ) : isMemoFlag ? (
    <BudgetTransactionTableRaw
      rows={renderRows()}
      columns={
        mq.deviceQuery.desktop ? desktopColsWithAction : mobileColsWithOutTitle
      }
    />
  ) : (
    <DataTableRaw
      rows={renderRows()}
      columns={mq.deviceQuery.desktop ? desktopCols : mobileCols}
    />
  );

  return (
    <View>
      <View style={styles.tableActions}>
        <BudgetsDateRangePicker
          value={dayInterval}
          onChange={setDayInterval}
          labelUpdatedAt={getDataAsLabel(lastUpdatedDate)}
        />
        <ExportCSV
          onExport={() =>
            analytics.event("Export Organization Budget Transactions")
          }
          data={csvOrders}
          filename="flexspace-transactions"
        />
      </View>
      {orders.length === 0 ? (
        <EmptyData
          title="No transactions yet"
          description="As soon as someone in this budget makes a booking, we’ll show it here."
        />
      ) : (
        table
      )}
      {totalPages > 1 && (
        <View style={styles.paginationWrapper}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChangePage={(page) => setCurrentPage(page)}
          />
        </View>
      )}
      <DialogModal
        isVisible={editMemoVisible}
        headerTitle="Edit memo"
        onClose={() => setEditMemoVisible(false)}
        desktopWidth={500}
        onShowAnimationEnd={onShowAnimationEnd}
        center={mq.deviceQuery.desktop}
        bottomButtons={
          <View style={{ width: mq.deviceQuery.mobile ? "100%" : 229 }}>
            <Button
              text="Save changes"
              onPress={onSavedMemo}
              loading={loading}
            />
          </View>
        }
      >
        <TextInput
          name="memo"
          ref={inputRef}
          value={newMemo || ""}
          placeholder={"What was this booking for?"}
          onChange={setNewMemo}
          numberOfLines={2}
        />
      </DialogModal>
    </View>
  );
}

const styles = StyleSheet.create({
  tableActions: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
    zIndex: 1,
  },
  paginationWrapper: {
    marginTop: 24,
    marginBottom: 30,
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  associatedBudgetContainer: {
    paddingRight: 24,
  },
  nameWrapper: {
    paddingRight: 24,
  },
  horizontalView: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },

  mobileContent: {
    flex: 1,
    gap: 16,
  },
  textContent: {
    width: 120,
  },
  extensionWrapper: {
    width: "100%",
    gap: 4,
  },
  headerExtension: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
});

const orgBudgetDetailTransactionQuery = gql`
  query OrganizationBudgetDetailTransactions(
    $startDate: String!
    $endDate: String!
  ) {
    currentUser {
      organization {
        id
        name
        ordersSummary(startDate: $startDate, endDate: $endDate) {
          lastUpdatedDate
          orders {
            order {
              memo
            }
            hostFullName
            hostTitle
            locationName
            locationAddress
            spaceName
            spaceType
            bookingDate
            bookingStartTime
            bookingEndTime
            orderCreatedAt
            orderID
            totalPrice
            currency
            groupBudget {
              id
              name
            }
            bookingRequest {
              id
            }
          }
        }
      }
    }
  }
`;
