import { Button } from "components/button_v2";
import { Checkbox } from "components/checkbox";
import { colors } from "components/colors";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/iconv2";
import { PreferredBadge } from "components/preferred_badge";
import { SelectInputOptionType } from "components/select/select_input";
import { SelectInputOptionAmenitiesVariant } from "components/select/select_input_option_amenities_variant";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import { Amenity } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { FilterChip } from "pages/homev2/components/filter_chip";
import { useAnalytics } from "providers/analytics";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";

interface AmeniesPickerProps {
  offsetVertical?: number;
  offsetHorizontal?: number;
  spaceTypeAmenities?: Amenity[];
  locationAmenities?: Amenity[];
}

export function AmenitiesPicker(props: AmeniesPickerProps) {
  const {
    spaceTypeAmenities,
    locationAmenities,
    offsetHorizontal,
    offsetVertical,
  } = props;
  const analytics = useAnalytics();
  const { amenities: paramsAmenities, preferred } = useHomeSearchParamsQuery();
  const [selectedAmenities, setSelectedAmenities] = useState(paramsAmenities);
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const [open, setOpen] = useState(false);
  const mq = useMediaQuery();
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  const [onlyPreferred, setOnlyPreferred] = useState(preferred);
  const isMobile = mq.deviceQuery.mobile;

  const handleChangeAmenity = useCallback(
    (option: SelectInputOptionType) => {
      let newAmenities: string[];

      if (selectedAmenities?.includes(option.value)) {
        newAmenities = selectedAmenities.filter(
          (amenityCode) => amenityCode !== option.value,
        );
      } else {
        newAmenities = selectedAmenities
          ? [...selectedAmenities, option.value]
          : [option.value];
      }

      setSelectedAmenities(newAmenities);
    },
    [selectedAmenities],
  );

  const onApplyPress = useCallback(() => {
    if (isPreferredFlag) {
      updateHomeSearchParams({
        amenities: selectedAmenities || null,
        preferred: onlyPreferred || null,
      });
    } else {
      updateHomeSearchParams({
        amenities: selectedAmenities || null,
      });
    }
    setOpen(false);
    analytics.event("Select Amenities", {
      Amenities: selectedAmenities,
    });
  }, [
    isPreferredFlag,
    updateHomeSearchParams,
    selectedAmenities,
    onlyPreferred,
    analytics,
  ]);

  const onClearPress = useCallback(() => {
    setSelectedAmenities(undefined);
    if (isPreferredFlag) {
      updateHomeSearchParams({
        amenities: null,
        preferred: null,
      });
      setOnlyPreferred(false);
    } else {
      updateHomeSearchParams({
        amenities: null,
      });
      setOpen(false);
    }
  }, [isPreferredFlag, updateHomeSearchParams]);

  const onRequestClose = useCallback(() => {
    if (selectedAmenities?.length !== paramsAmenities?.length) {
      setSelectedAmenities(paramsAmenities);
    }

    setOpen(false);
  }, [paramsAmenities, selectedAmenities?.length]);

  // This is to clear local state when amenities are cleared from another component
  useEffect(() => {
    if (!paramsAmenities) {
      setSelectedAmenities(undefined);
    }
  }, [paramsAmenities]);

  if (isPreferredFlag) {
    const amenityCount = selectedAmenities?.length || 0;
    const badgeNumber = onlyPreferred ? amenityCount + 1 : amenityCount;
    return (
      <>
        <Pressable
          style={styles.container}
          onPress={() => setOpen(true)}
          testID="amenities-picker"
        >
          <View style={styles.innerContainer}>
            <Icon name="equilizerA" size={"sm"} />
            <Spacer direction="row" size={8} />
            <Text color={"black-70"} size="micro">
              Filters
            </Text>
            {badgeNumber > 0 && (
              <View style={styles.badgeNumber}>
                <Text color={"white-core"} size="nano" weight="semibold">
                  {badgeNumber}
                </Text>
              </View>
            )}
          </View>
        </Pressable>
        <DialogModal
          isVisible={open}
          onClose={onRequestClose}
          headerTitle={"Filter"}
          desktopWidth={780}
          bottomButtons={
            <View style={styles.groupButton}>
              <Button
                appearance={"textLink"}
                text="Clear all"
                onPress={onClearPress}
              />
              <Button
                text="Done"
                size={"small"}
                testID="amenities-picker-apply-button"
                onPress={onApplyPress}
              />
            </View>
          }
        >
          <View style={styles.modalContainer}>
            <Text weight={"bold"} size={"md"}>
              Flexspace Preferred
            </Text>

            <Checkbox
              appearance={"box"}
              value={onlyPreferred}
              onChange={setOnlyPreferred}
              label={"Preferred Only"}
              renderLabel={() => (
                <View style={styles.checkBoxView}>
                  <View style={styles.showOnlyView}>
                    <Text size={"xs"} weight={"semibold"}>
                      Show only
                    </Text>
                    <PreferredBadge variant={"preferredOnly"} />
                  </View>
                  <Text size={"xs"} color={"black-50"}>
                    Coworking convenience, facilitated requests, and zero
                    booking fees.
                  </Text>
                </View>
              )}
            />

            <Divider />
            <Text weight={"bold"} size={"md"}>
              Amenities
            </Text>
            <View>
              {["Essentials", "Location Features"].map((groupKey) => {
                const shownAmneities = (
                  groupKey === "Essentials"
                    ? spaceTypeAmenities
                    : locationAmenities
                )?.filter((amenity) => !!amenity.code);

                if (!shownAmneities?.length) {
                  return null;
                }

                return (
                  <Fragment key={groupKey}>
                    <Text size="xs" weight="semibold">
                      {groupKey}
                    </Text>
                    <Spacer size={16} />
                    <View
                      style={
                        isMobile
                          ? styles.amenityContainerMobile
                          : styles.amenityContainer
                      }
                    >
                      {shownAmneities.map((amenity) => {
                        const isSelected =
                          !!amenity.code &&
                          selectedAmenities?.includes(amenity.code);
                        const option = {
                          label: amenity.name,
                          value: amenity.code,
                        };
                        return (
                          <View key={option.label} style={styles.amenityItem}>
                            <SelectInputOptionAmenitiesVariant
                              selected={isSelected}
                              withCheckbox
                              option={option}
                              testID={`select-option-${amenity.code}`}
                              onSelect={() => {
                                handleChangeAmenity(option);
                              }}
                            />
                          </View>
                        );
                      })}
                    </View>
                    <Spacer size={24} />
                  </Fragment>
                );
              })}
            </View>
          </View>
        </DialogModal>
      </>
    );
  }

  return (
    <DropdownV2
      open={open}
      onRequestClose={onRequestClose}
      offsetVertical={offsetVertical}
      offsetHorizontal={offsetHorizontal}
      content={
        <View style={styles.dropdownContent}>
          <View style={styles.contentWrapper}>
            {["Essentials", "Location Features"].map((groupKey) => {
              const shownAmneities = (
                groupKey === "Essentials"
                  ? spaceTypeAmenities
                  : locationAmenities
              )?.filter((amenity) => !!amenity.code);

              if (!shownAmneities?.length) {
                return null;
              }

              return (
                <Fragment key={groupKey}>
                  <View>
                    <Text size="xs" weight="semibold">
                      {groupKey}
                    </Text>
                    <Spacer size={24} />
                    {shownAmneities.map((amenity) => {
                      const isSelected =
                        !!amenity.code &&
                        selectedAmenities?.includes(amenity.code);
                      const option = {
                        label: amenity.name,
                        value: amenity.code,
                      };
                      return (
                        <View key={option.label}>
                          <SelectInputOptionAmenitiesVariant
                            selected={isSelected}
                            withCheckbox
                            option={option}
                            testID={`select-option-${amenity.code}`}
                            onSelect={() => {
                              handleChangeAmenity(option);
                            }}
                          />
                        </View>
                      );
                    })}
                  </View>
                  <Spacer size={40} />
                </Fragment>
              );
            })}
          </View>
          <View style={styles.buttons}>
            <View style={styles.button}>
              {!!selectedAmenities?.length && (
                <TextLink
                  onPress={onClearPress}
                  decoration="none"
                  text="Clear all"
                />
              )}
            </View>
            <View style={styles.button}>
              <Button
                testID="amenities-picker-apply-button"
                onPress={onApplyPress}
                text="Apply"
              />
            </View>
          </View>
        </View>
      }
    >
      <FilterChip
        title={
          paramsAmenities?.length
            ? `Amenities (${paramsAmenities.length})`
            : "Amenities"
        }
        onPress={() => setOpen(true)}
        isContentOpen={open}
        testID="amenities-picker"
        isSelected={!!paramsAmenities}
      />
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dropdownContent: {
    height: "50vh",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: colors.brand.whitecore,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    width: 240,
    padding: 16,
    paddingBottom: 32,
    position: "relative",
  },
  buttons: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    height: 72,
    backgroundColor: colors.brand.whitecore,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
  },
  contentWrapper: {
    overflow: "scroll",
    maxHeight: "100%",
  },
  button: {
    width: 72,
  },
  container: {
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    height: 40,
    padding: 12,
    justifyContent: "center",
    width: "max-content",
  },
  modalContainer: {
    flex: 1,
    gap: 24,
  },
  groupButton: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  checkBoxView: {
    flex: 1,
  },
  showOnlyView: {
    flexDirection: "row",
    gap: 4,
  },
  badgeNumber: {
    position: "absolute",
    bottom: 20,
    right: -20,
    backgroundColor: colors.brand.blackMinus30,
    borderRadius: 100,
    width: 16,
    height: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  amenityContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  amenityContainerMobile: {
    flexDirection: "column",
  },
  amenityItem: {
    width: "50%",
  },
  innerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 4,
  },
  contentOpen: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
});
