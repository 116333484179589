import { useQuery, gql } from "@apollo/client";
import { GetUserTypeQuery } from "core/graphql.generated";
import { homeUserType } from "../components/work_near_by";

export function useUserType() {
  const { data } = useQuery<GetUserTypeQuery>(getUserType);

  return data?.currentUser?.hasBookedBefore
    ? homeUserType.returning
    : homeUserType.new;
}

const getUserType = gql`
  query GetUserType {
    currentUser {
      id
      hasBookedBefore
    }
  }
`;
