import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { OrganizationUser } from "core/graphql.generated";
import {
  CreateEditBudgetCard,
  CreateEditBudgetCardDescriptionList,
  CreateEditBudgetCardDivider,
} from "pages/budgets/components/create_edit_budget_card";
import { Fragment } from "react";

interface CreateEditBudgetSummaryProps {
  name?: string;
  description?: string;
  limit?: number;
  managers?: OrganizationUser[];
  members?: OrganizationUser[];
  isEdit?: boolean;
  individualLimit?: number | null;
}
export function CreateEditBudgetSummary(props: CreateEditBudgetSummaryProps) {
  const {
    name,
    description,
    limit,
    managers,
    members,
    isEdit,
    individualLimit,
  } = props;
  return (
    <CreateEditBudgetCard title="Summary">
      {(!!name || !!description) && (
        <>
          <CreateEditBudgetCardDescriptionList
            label={name}
            text={description}
          />
          <CreateEditBudgetCardDivider />
        </>
      )}
      {limit || limit === 0 ? (
        <CreateEditBudgetCardDescriptionList
          label="Spend limits"
          text={`$${limit} per month`}
        />
      ) : (
        <CreateEditBudgetCardDescriptionList
          label="Spend limits"
          text={`No limit entered yet`}
        />
      )}
      {!!individualLimit && individualLimit !== 0 && (
        <Fragment>
          <Spacer size={8} />
          <Text color="black-70" size="xs" weight="regular">
            ${individualLimit} per month for each person in this budget
          </Text>
        </Fragment>
      )}
      {individualLimit === 0 && (
        <Fragment>
          <Spacer size={8} />
          <Text color="black-70" size="xs" weight="regular">
            No member will be able to spend from this budget because individual
            limit is set to 0
          </Text>
        </Fragment>
      )}
      {!isEdit && (
        <>
          <CreateEditBudgetCardDivider />
          <CreateEditBudgetCardDescriptionList
            label="People"
            text={
              <>
                {managers?.length || 0} managers
                <Spacer size={8} />
                {members?.length || 0} members
              </>
            }
          />
        </>
      )}
    </CreateEditBudgetCard>
  );
}
