import { useMediaQuery } from "lib/media_query";
import { View } from "react-native";
import { Icon } from "./iconv2";
import "./preferred_partner_banner.css";
import { Spacer } from "./spacer";
import { Text } from "./text_v2";

interface PreferredBadgeProps {
  locationName: string;
}

export function PreferredPartnerBanner(props: PreferredBadgeProps) {
  const { locationName } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  return (
    <div className="preferred-partner-banner">
      <Icon name="preferred" size={isMobile ? "md" : "lg"} />
      <Spacer direction="row" size={16} />
      <View style={{ flex: 1, width: "100%" }}>
        <div className="text preferred-text text-size-sm text-weight-semibold">
          {locationName}
        </div>
        <Spacer direction="row" size={2} />
        <Text size="xs">
          Preferred Partners offer coworking convenience and can facilitate your
          requests. You also pay zero service fees when you book.
        </Text>
      </View>
    </div>
  );
}
