import { useQuery, gql, useMutation } from "@apollo/client";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMemo, useState } from "react";
import { Pressable, View } from "react-native";
import { Button } from "components/button_v2";
import { useMediaQuery } from "lib/media_query";
import { useToast } from "providers/toast";
import { useAnalytics } from "providers/analytics";
import {
  InAppInviteCurrentUserQuery,
  InviteToTeamMutation,
  InviteToTeamMutationVariables,
  UserRole,
  UserToInvite,
} from "core/graphql.generated";
import { ChipsField } from "components/chips_field";
import { MultiInputsField } from "components/multi_inputs_field";
import { DialogModal } from "components/dialog_modal";
import { Spinner } from "components/spinner";
import { StyleSheet } from "react-native";
import { colors } from "components/colors";
import { isEmail } from "lib/string_utils";

interface InAppInviteProps {
  isVisible: boolean;
  onClose: () => void;
  onSuccess?: (invites: UserToInvite[]) => void;
  analyticsFrom?: string;
}

export function InAppInvite(props: InAppInviteProps) {
  const { isVisible = true, onClose, onSuccess, analyticsFrom } = props;
  const toast = useToast();
  const analytics = useAnalytics();
  const { data } = useQuery<InAppInviteCurrentUserQuery>(
    inappInviteCurrentUserGQLQuery,
  );
  const [addMemberIndividually, setAddMemberIndividually] = useState(true);
  const initialState = useMemo<UserToInvite[]>(
    () => [
      {
        email: "",
        isAdmin: false,
      },
      {
        email: "",
        isAdmin: false,
      },
      {
        email: "",
        isAdmin: false,
      },
    ],
    [],
  );
  const [error, setError] = useState("");
  const [invites, setInvites] = useState(initialState);
  const onChange = (invites: UserToInvite[]) => {
    setError("");
    setInvites(invites);
  };
  const isAdmin = data?.currentUser?.role === UserRole.Admin;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  const multiInputsFieldValue = isMobile
    ? Object.assign([...initialState.map((i) => ({ ...i }))], invites)
    : Object.assign(
        [...initialState.map((i) => ({ ...i }))],
        invites.slice(0, 3),
      );

  const [inviteToTeamMutation, { loading, error: errorResponse }] = useMutation<
    InviteToTeamMutation,
    InviteToTeamMutationVariables
  >(inviteToTeamGQLMutation);

  const handleSuccess = (invites: UserToInvite[]) => {
    onSuccess?.(invites);
    toast.notify({
      message: "Invitation sent",
    });
    const invitations = invites.map((inv) => inv.email).filter((e) => e);
    analytics.event("Send Invite", {
      "Organization UUID": data?.currentUser?.organization?.id,
      Invitations: invitations,
      "Invitation Count": invitations.length,
      From: analyticsFrom,
      "Have admin": !!invites.find((inv) => inv.isAdmin),
    });
  };

  const onSubmit = async () => {
    if (
      invites
        .filter((invite) => !!invite.email)
        .some((invite) => !isEmail(invite.email))
    ) {
      return setError("Please enter correct emails");
    }

    const res = await inviteToTeamMutation({
      variables: {
        input: {
          users: invites.filter((invite) => !!invite.email),
        },
      },
    });
    if (res?.data?.inviteToTeam === true) {
      handleSuccess(invites);
      setInvites(initialState);
    }
  };

  return (
    <DialogModal
      isVisible={isVisible}
      onClose={onClose}
      title={`Invite others to the ${data?.currentUser?.organization?.name} team`}
      desktopWidth={600}
      bottomButtons={
        <View style={{ width: isMobile ? "100%" : 215 }}>
          <Button
            disabled={!invites.some((inv) => !!inv.email)}
            text="Send invitations"
            testID="inapp-invite-submit-button"
            onPress={onSubmit}
          />
        </View>
      }
    >
      {addMemberIndividually ? (
        <>
          <MultiInputsField
            fields={multiInputsFieldValue.map(({ email }) => ({
              value: email,
            }))}
            onChange={(fields) => {
              onChange(
                fields.map((field) => ({
                  email: field.value,
                  isAdmin: !!field.isChecked,
                })),
              );
            }}
            canAddMore={isMobile}
            withCheckbox={isAdmin}
          />
        </>
      ) : (
        <View>
          <ChipsField
            label="Enter emails"
            initialValue={invites.map((invite) => invite.email)}
            placeholder="You can copy and paste a list of emails"
            onChange={(values) => {
              onChange(
                values.map((value) => ({
                  email: value,
                  isAdmin: false,
                })),
              );
            }}
          />
          <Spacer size={20} />
        </View>
      )}

      {loading && (
        <View style={styles.spinner}>
          <Spinner />
        </View>
      )}
      {!mq.deviceQuery.mobile && (
        <>
          <Pressable
            onPress={() => setAddMemberIndividually(!addMemberIndividually)}
          >
            <Text weight="semibold" color="eggplant-core">
              {addMemberIndividually
                ? "Add many members at once"
                : "Add members individually"}
            </Text>
          </Pressable>
        </>
      )}
      {(errorResponse || error) && (
        <>
          <Spacer size={8} />
          <Text customColor={colors.brand.grapefruitcore}>
            {errorResponse || error}
          </Text>
          <Spacer size={8} />
        </>
      )}
      <Spacer size={25} />
    </DialogModal>
  );
}

const inviteToTeamGQLMutation = gql`
  mutation InviteToTeamInApp($input: InviteToTeamInput!) {
    inviteToTeam(input: $input)
  }
`;

const inappInviteCurrentUserGQLQuery = gql`
  query InAppInviteCurrentUser {
    currentUser {
      role
      organization {
        id
        name
      }
    }
  }
`;

const styles = StyleSheet.create({
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
});
