import React, { Fragment } from "react";
import { View, StyleSheet } from "react-native";
import { ContainerProvider } from "./container";
import { Spacer } from "./spacer";

interface RowProps {
  flex?: number;
  children?: React.ReactNode;
  justifyContent?: "flex-start" | "flex-end" | "space-between" | "center";
  alignItems?: "flex-start" | "flex-end" | "center";
  wrap?: boolean;
  spacing?: number;
  expanded?: boolean;
}

/**
 * Displays its children in a vertical array.
 */
export function Row(props: RowProps) {
  const {
    children,
    flex,
    wrap,
    justifyContent,
    alignItems,
    expanded,
    spacing,
  } = props;

  const childCount = React.Children.count(children);

  return (
    <ContainerProvider direction="row">
      <View
        style={[
          styles.root,
          expanded && styles.expanded,
          wrap && styles.wrap,
          {
            justifyContent,
            alignItems,
            flex,
          },
        ]}
      >
        {React.Children.map(children, (child, index) => {
          const last = index === childCount - 1;

          return (
            <Fragment>
              {child}
              {spacing !== undefined && spacing !== 0 && !last && (
                <Spacer size={spacing} />
              )}
            </Fragment>
          );
        })}
      </View>
    </ContainerProvider>
  );
}

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  expanded: {
    width: "100%",
    height: "100%",
  },
  wrap: {
    flexWrap: "wrap",
  },
});
