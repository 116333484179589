import { AppHeader } from "components/app_header_v3/app_header";
import { DesktopLayout } from "components/layout/desktop_layout";
import { Footer } from "core/footer";
import { ROUTES } from "core/routes";

interface BookingDesktopLayoutProps {
  children: JSX.Element;
}

export function BookingDesktopLayout({ children }: BookingDesktopLayoutProps) {
  return (
    <DesktopLayout
      header={<AppHeader />}
      body={children}
      footer={<Footer />}
      title={ROUTES.BOOKINGS.pageName}
    />
  );
}
