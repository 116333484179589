import { Divider } from "components/divider";
import { Heading } from "components/heading_v2";
import { HourDetails } from "components/hour_details";
import { Icon } from "components/iconv2";
import { ImageGallery } from "components/image_gallery";
import { PreferredPartnerBanner } from "components/preferred_partner_banner";
import { SaveFavoriteButton } from "components/save_favorite_button";

import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  LocationDetails__LocationFragment,
  LocationDetails__SpaceTypePolicyFragment,
} from "core/graphql.generated";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { Amenities } from "pages/office_space_details/components/amenities";
import {
  useFavoritesFeatureFlag,
  usePreferredSpaceFeatureFlag,
} from "providers/splitio";
import { Pressable, StyleSheet, View } from "react-native";
import {
  LocationDetailsAvailableSpaces,
  LocationDetailsDayPass,
} from "./components/location_details_available_spaces";

import { LocationDetailsAvailableSpace } from "./components/location_details_available_spaces_modal";
import { LocationDetailCopyShareLinkButton } from "./components/location_details_copy_share_link_button";
import { LocationDetailsMap } from "./components/location_details_map";
import { LocationDetailsSection } from "./components/location_details_section";
import { getPlaceholderLocationImage } from "./utils/get_placeholder_location_image";

export interface LocationDetailsProps {
  location: LocationDetails__LocationFragment;
  privateOffices?: LocationDetailsAvailableSpace[];
  meetingRooms?: LocationDetailsAvailableSpace[];
  dayPass?: LocationDetailsDayPass;
  spaceTypePolicy?: LocationDetails__SpaceTypePolicyFragment;
}

const SPACER_SIZE = 24;

export function LocationDetailsDesktopContent({
  location,
  dayPass,
  meetingRooms,
  privateOffices,
  spaceTypePolicy,
}: LocationDetailsProps) {
  const mq = useMediaQuery();
  const favoritesFeatureFlag = useFavoritesFeatureFlag();
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  const goBack = useGoBack();

  return (
    <View>
      <View style={styles.headerRow}>
        <View>
          <Spacer size={40} />
          <Pressable onPress={goBack}>
            <Icon name="arrow-left" size="md" />
          </Pressable>
          <Spacer size={16} />

          <Heading size={mq.deviceQuery.mobile ? "md" : "lg"}>
            {location.name}
          </Heading>
        </View>

        {favoritesFeatureFlag && (
          <View style={styles.buttons}>
            <LocationDetailCopyShareLinkButton locationId={location.id} />
            <Spacer direction="row" size={8} />
            <SaveFavoriteButton
              favoriteId={location.id}
              favoriteType={FavoriteType.Location}
            />
          </View>
        )}
      </View>

      <Spacer size={SPACER_SIZE} />
      <View style={styles.imagesWrapper}>
        <ImageGallery
          images={
            location.images[0]
              ? location.images
              : [getPlaceholderLocationImage()]
          }
          imageName={location.name}
        />
      </View>
      <Spacer size={SPACER_SIZE} />
      <Divider />
      <Spacer size={SPACER_SIZE} />
      {location.description && (
        <>
          <LocationDetailsSection title="About this space">
            <div
              dangerouslySetInnerHTML={{
                __html: location.description,
              }}
            />
          </LocationDetailsSection>
          <Spacer size={SPACER_SIZE} />
        </>
      )}
      {isPreferredFlag && location.preferred && (
        <>
          <PreferredPartnerBanner
            locationName={`${location.name} is a Preferred Partner`}
          />
          <Spacer size={SPACER_SIZE} />
          <Divider />
          <Spacer size={SPACER_SIZE} />
        </>
      )}
      <LocationDetailsAvailableSpaces
        dayPass={dayPass}
        privateOffices={privateOffices}
        meetingRooms={meetingRooms}
        spaceTypePolicy={spaceTypePolicy}
      />
      <Spacer size={SPACER_SIZE} />
      <Divider />
      <Spacer size={SPACER_SIZE} />
      {location.amenities.length > 0 && (
        <>
          <Amenities
            spaceAmenities={[]}
            locationAmenities={location.amenities}
          />
          <Spacer size={SPACER_SIZE} />
          <Divider />
          <Spacer size={SPACER_SIZE} />
        </>
      )}

      <LocationDetailsSection title="Location">
        <Text size="xs">
          {location.name} is located at {location.address.fullAddress}
        </Text>
      </LocationDetailsSection>
      <Spacer size={SPACER_SIZE} />
      <LocationDetailsMap
        latitude={location.address.latitude || undefined}
        longitude={location.address.longitude || undefined}
      />
      <Spacer size={SPACER_SIZE} />
      {location.directions && (
        <LocationDetailsSection title="How to get there">
          <div
            dangerouslySetInnerHTML={{
              __html: location.directions,
            }}
          />
        </LocationDetailsSection>
      )}
      <Spacer size={SPACER_SIZE} />
      <Divider />
      <Spacer size={SPACER_SIZE} />
      <LocationDetailsSection title="Hours">
        <HourDetails hours={location.bookingHours} />
      </LocationDetailsSection>
      <Spacer size={SPACER_SIZE} />
      <Divider />
    </View>
  );
}

const styles = StyleSheet.create({
  imagesWrapper: {
    borderRadius: 4,
    overflow: "hidden",
    zIndex: 1,
  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
  },
});
