import { MobileHeader } from "components/layout/mobile_header";
import { MobileLayout } from "components/layout/mobile_layout";

interface BookingMobileLayoutProps {
  children: JSX.Element;
}

export function BookingDetailsMobile({ children }: BookingMobileLayoutProps) {
  return (
    <MobileLayout
      header={<MobileHeader title="" />}
      body={children}
      footer={<></>}
    />
  );
}
