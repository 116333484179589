import {
  gql as apolloGql,
  useApolloClient,
  useLazyQuery,
} from "@apollo/client";
import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { TermsAndPolicy } from "components/sign_up_shared/terms_and_policy";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { GoogleButton } from "core/google_button";
import {
  AuthMethod,
  OrgByDomainQuery,
  OrgByDomainQueryVariables,
} from "core/graphql.generated";
import { useForm } from "hooks/use_form";
import { useMediaQuery } from "lib/media_query";
import { isEmail } from "lib/string_utils";
import { SourceFrom } from "pages/custom_org_sign_in/custom_org_sign_in";
import { useAnalytics } from "providers/analytics";
import { orgByDomainQuery } from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import { useCustomSSOFeatureFlag } from "providers/splitio";
import { Fragment, useEffect } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import { SignUpCredentials } from "../sign_up";

interface PropTypes {
  onGoNext: () => void;
  onGoBack: () => void;
  setCredentials: (formValues: SignUpCredentials) => void;
}

export function SignUpStepGetStarted({ onGoNext, setCredentials }: PropTypes) {
  const analytics = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  const mq = useMediaQuery();
  const sSOFeatureFlag = useCustomSSOFeatureFlag();
  const { startPasswordless, signUpWithGoogle } = useAuthV2();
  const apolloClient = useApolloClient();
  const [fetchIsEmailTaken] = useLazyQuery(isEmailTakenGQLQuery);
  const searchParams = new URLSearchParams(location.search);
  const prefilledEmail = searchParams.get("email");
  const prefilledFullName = searchParams.get("name");

  useEffect(() => {
    analytics.event("Sign Up", {
      Step: "Start",
    });
  }, [analytics]);
  const {
    values,
    changed,
    errors,
    setFieldValue,
    setFieldValues,
    submit,
    submitting,
    submitCount,
  } = useForm({
    initialValues: {
      email: prefilledEmail || "",
      fullName: prefilledFullName || "",
    },
    validate: ({ email, fullName }: SignUpCredentials) => {
      const errors: { [field: string]: string } = {};

      if (!isEmail(email)) {
        errors.email = "Please input a valid email address";
      }
      if (!fullName) {
        errors.fullName = "Please input your full name";
      }
      if (fullName && fullName.split(" ").length < 2) {
        errors.fullName = "Please input your full name";
      }

      return errors;
    },
    onSubmit: async (
      { email, fullName }: SignUpCredentials,
      { setSubmitting, setErrors }: any,
    ) => {
      const trimmedFullname = fullName.trim();
      setSubmitting(true);

      if (sSOFeatureFlag) {
        const result = await apolloClient.query<
          OrgByDomainQuery,
          OrgByDomainQueryVariables
        >({
          query: orgByDomainQuery,
          variables: {
            domain: email.split("@").pop() || "",
          },
        });

        const orgData = result.data.organizationByDomain;
        const isLoginMethodValid = orgData?.ssoProvider.find(
          (provider) => provider === AuthMethod.Password,
        );
        if (orgData?.requireSSO && orgData.slug && !isLoginMethodValid) {
          history.replace(`/${orgData.slug}`, {
            orgData,
            sourceFrom: SourceFrom.SIGN_UP,
          });
        } else {
          try {
            const { data, error } = await fetchIsEmailTaken({
              variables: { email },
            });

            if (data?.isEmailTaken) {
              return setErrors({ email: "taken" });
            }
            if (error) {
              return setErrors({ email: "This email is invalid" });
            }
            await startPasswordless(email);
            setCredentials({ email, fullName: trimmedFullname });
            analytics.event("Sign Up", {
              Step: "Submit email",
              Email: email,
              Name: trimmedFullname,
              "Auth Type": "Password",
            });
            onGoNext();
          } catch (error) {
            setErrors({
              authenticationError: error.message || error.description,
            });
          } finally {
            setSubmitting(false);
          }
        }
      } else {
        try {
          const { data, error } = await fetchIsEmailTaken({
            variables: { email },
          });

          if (data?.isEmailTaken) {
            return setErrors({ email: "taken" });
          }
          if (error) {
            return setErrors({ email: "This email is invalid" });
          }
          await startPasswordless(email);
          setCredentials({ email, fullName: trimmedFullname });
          analytics.event("Sign Up", {
            Step: "Submit email",
            Email: email,
            Name: trimmedFullname,
            "Auth Type": "Password",
          });
          onGoNext();
        } catch (error) {
          setErrors({
            authenticationError: error.message || error.description,
          });
        } finally {
          setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    if (submitCount === 0 && prefilledEmail && prefilledFullName) {
      submit();
    }
  }, [submitCount, prefilledEmail, prefilledFullName, setFieldValues, submit]);

  return (
    <Fragment>
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Heading size="mega" align="center">
          Get started
        </Heading>
        <Spacer size={24} />
        <Content maxWidth={343}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <GoogleButton onPress={signUpWithGoogle} />
            <Spacer size={16} />
            <Text weight="bold" size="md" align="center">
              or
            </Text>
            <Spacer size={16} />
          </div>
          <TextField
            value={values.fullName}
            onChange={(fullName) => setFieldValue("fullName", fullName)}
            placeholder="First and last name"
            testID="sign-up-name"
            invalid={changed.fullName && !!errors.fullName}
            onSubmitEditing={submit}
          />
          <Spacer size={8} />
          <TextField
            value={values.email}
            onChange={(email) => setFieldValue("email", email.trim())}
            placeholder="Work email"
            testID="sign-up-email"
            invalid={changed.email && !!errors.email}
            onSubmitEditing={submit}
          />
          <Spacer size={15} />
          {!!errors.authenticationError && (
            <Fragment>
              <Text color="grapefruit-core">{errors.authenticationError}</Text>
              <Spacer size={15} />
            </Fragment>
          )}
          {errors.email === "taken" && (
            <Fragment>
              <Text color="grapefruit-core">
                Looks like you already have an account.{" "}
                <Pressable onPress={() => history.replace("/sign-in")}>
                  <Text decoration="underline" color="grapefruit-core">
                    Click here to login.
                  </Text>
                </Pressable>
              </Text>
            </Fragment>
          )}
          {errors.email !== "taken" && errors.email && (
            <Fragment>
              <Text color="grapefruit-core">{errors.email}</Text>
            </Fragment>
          )}
          {errors.fullName && (
            <Fragment>
              <Text color="grapefruit-core">{errors.fullName}</Text>
            </Fragment>
          )}
        </Content>
      </View>
      <View style={styles.footerWrapper}>
        <TermsAndPolicy />
        <Spacer size={16} />
        <View
          style={[
            styles.buttonWrapper,
            mq.sizeQuery.mdAndUp && styles.buttonDesktopWrapper,
          ]}
        >
          <Button
            appearance="primary"
            text="Get started"
            onPress={submit}
            testID="sign-up-getstarted-submit-button"
            loading={submitting}
          />
        </View>
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
  footerWrapper: {
    maxWidth: 343,
    alignItems: "center",
    alignSelf: "center",
  },
  buttonWrapper: {
    width: "95%",
  },
  buttonDesktopWrapper: {
    width: 220,
  },
});

export const isEmailTakenGQLQuery = apolloGql`
  query IsEmailTaken($email: String!) {
    isEmailTaken(email: $email)
  }
`;
