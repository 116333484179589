import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { ChipsField } from "components/chips_field";
import { Content } from "components/content";
import { DialogModal } from "components/dialog_modal";
import { Heading } from "components/heading_v2";
import { MultiInputsField } from "components/multi_inputs_field";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import {
  InviteToTeamMutation,
  InviteToTeamMutationVariables,
  StepInviteOthersQuery,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { Fragment, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useAnalytics } from "providers/analytics";
import { isEmail } from "lib/string_utils";
import { colors } from "components/colors";

interface PropTypes {
  onGoNext: () => void;
  onGoBack: () => void;
}

export function SignUpStepInviteOthers({ onGoNext }: PropTypes) {
  const [error, setError] = useState("");
  const [emails, setEmails] = useState<string[]>(["", "", ""]);
  const [loading, setLoading] = useState(false);
  const [isSkipModalOpen, setIsSkipModalOpen] = useState<boolean>(false);
  const onEmailsChange = (emails: string[]) => {
    setError("");
    setEmails(emails.map((email) => email.trim()).filter(Boolean));
  };
  const [mode, setMode] = useState<"separately" | "chips">("separately");
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const [inviteToTeamMutation] = useMutation<
    InviteToTeamMutation,
    InviteToTeamMutationVariables
  >(inviteToTeamGQLMutation);
  const analytics = useAnalytics();

  const { data } = useQuery<StepInviteOthersQuery>(stepInviteOthersGQLQuery);
  const multiInputsFieldValue = isMobile
    ? Object.assign(["", "", ""], emails)
    : Object.assign(["", "", ""], emails.slice(0, 3));

  useEffect(() => {
    // at this step, it is not necessary to store sign-up credentials anymore
    localStorage.removeItem("sign-up");
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (!emails.filter(Boolean).every(isEmail)) {
        setLoading(false);
        return setError("Please enter correct emails");
      }

      const res = await inviteToTeamMutation({
        variables: {
          input: {
            users: emails
              .filter(Boolean)
              .map((email) => ({ email, isAdmin: false })),
          },
        },
      });

      if (res.data?.inviteToTeam === true) {
        const invitations = emails.filter((e) => !!e);

        analytics.event("Sign Up", {
          Step: mode === "separately" ? "Invite individually" : "Invite many",
          Emails: invitations,
        });

        analytics.event("Send Invite", {
          "Organization UUID": data?.currentUser?.organization?.id,
          Invitations: invitations,
          "Invitation Count": invitations.length,
          From: "Sign-up",
          "Have admin": false,
        });
        setLoading(false);
        onGoNext();
      }
    } catch (err) {
      // TODO: handle error
      console.error("Error while sending invites: ", err);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Content maxWidth={630}>
          <Heading size={mq.deviceQuery.mobile ? "lg" : "mega"} align="center">
            Invite others to the {data?.currentUser?.organization?.name} team
          </Heading>
          <Spacer size={16} />
          <Text align="center" size="xs">
            To get the full collaborative Flexspace experience, invite your
            coworkers to your team.
          </Text>
          <Spacer size={40} />

          {mode === "separately" ? (
            <>
              <MultiInputsField
                fields={multiInputsFieldValue.map((email) => ({
                  value: email,
                }))}
                onChange={(fields) =>
                  onEmailsChange(fields.map((field) => field.value))
                }
                canAddMore={isMobile}
              />
              <Spacer size={6} />
            </>
          ) : (
            <>
              <ChipsField
                label="Enter emails"
                initialValue={emails}
                onChange={onEmailsChange}
                placeholder="You can copy and paste a list of emails"
              />
              <Spacer size={20} />
            </>
          )}

          {!isMobile && (
            <View>
              {mode === "separately" ? (
                <TextLink
                  onPress={() => {
                    setMode("chips");
                  }}
                  text="Add many members at once"
                  inline
                  size="xs"
                  decoration="none"
                  weight="bold"
                />
              ) : (
                <TextLink
                  onPress={() => {
                    setMode("separately");
                  }}
                  text="Add members individually"
                  inline
                  size="xs"
                  decoration="none"
                  weight="bold"
                />
              )}
            </View>
          )}
          {error && (
            <>
              <Spacer size={8} />
              <Text customColor={colors.brand.grapefruitcore}>{error}</Text>
              <Spacer size={8} />
            </>
          )}
          <Spacer size={16} />

          <DialogModal
            isVisible={isSkipModalOpen}
            onClose={() => setIsSkipModalOpen(false)}
            title="Skip without inviting your team?"
            desktopWidth={552}
            mobileOffsetBottom={320}
            bottomButtons={
              <>
                <View style={{ width: isMobile ? "100%" : 114 }}>
                  <Button
                    appearance="secondary"
                    text="Cancel"
                    onPress={() => setIsSkipModalOpen(false)}
                  />
                </View>
                <View style={{ width: isMobile ? "100%" : 114 }}>
                  <Button
                    appearance="primary"
                    text="Skip for now"
                    testID="sign-up-invite-skip-confirm-button"
                    onPress={() => {
                      analytics.event("Sign Up", {
                        Step: "Skip Invites",
                      });
                      onGoNext();
                    }}
                  />
                </View>
              </>
            }
          >
            <Text align="center" size="xs">
              To bring the full benefits of Flexspace to your team and see how
              it can help you collaborate better, you’ll need to add a few team
              members.
            </Text>
          </DialogModal>
        </Content>
      </View>
      <View
        style={[
          styles.buttonContainer,
          mq.deviceQuery.mobile && styles.buttonContainerMobile,
        ]}
      >
        <Button
          appearance="primary"
          text="Send invitations"
          onPress={
            emails?.filter(Boolean)?.length
              ? onSubmit
              : () => setIsSkipModalOpen(true)
          }
          testID="sign-up-send-invitation-submit-button"
          loading={loading}
        />
        <Spacer size={8} direction="column" />
        <TextLink
          onPress={() => setIsSkipModalOpen(true)}
          text="Skip for now"
          size="xs"
          decoration="none"
          weight="semibold"
          align="center"
        />
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  mainContainerDesktop: {
    maxHeight: 480,
  },
  mainContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: "95%",
    maxWidth: 220,
    alignSelf: "center",
  },
  buttonContainerMobile: {
    maxWidth: 343,
  },
});

const inviteToTeamGQLMutation = gql`
  mutation InviteToTeam($input: InviteToTeamInput!) {
    inviteToTeam(input: $input)
  }
`;

const stepInviteOthersGQLQuery = gql`
  query StepInviteOthers {
    currentUser {
      id
      organization {
        id
        name
      }
    }
  }
`;
