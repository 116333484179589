import { colors } from "components/colors";

export function LearnMoreLink() {
  return (
    <a
      style={{
        textDecoration: "none",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: `-appleSystem, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sansSerif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        color: colors.brand.eggplantcore,
      }}
      href="https://flexspace.ai/pricing/"
      target="__blank"
      rel="noopener noreferrer"
    >
      Learn more
    </a>
  );
}
