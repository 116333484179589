import { View } from "react-native";
import { styles } from "./components/styles";
import SingleBar from "./components/single_bar";

export interface ProgressObject {
  color: string;
  opacity?: number;
  value: number;
}

interface ProgressBarMulticolorPropTypes {
  progressObjects: ProgressObject[];
  height?: number;
  roundLastItem?: boolean;
  backgroundColor?: string;
}

export const ProgressBarMulticolor = ({
  progressObjects = [],
  height = 40,
  roundLastItem = false,
  backgroundColor,
}: ProgressBarMulticolorPropTypes) => {
  return (
    <View style={[styles.parentView]}>
      <View
        style={[
          styles.bar,
          { height },
          !!backgroundColor && { backgroundColor },
        ]}
      >
        {progressObjects.map((item: ProgressObject, index: number) => (
          <SingleBar
            key={"" + index}
            item={item}
            isLast={progressObjects.length === index + 1 && roundLastItem}
            isFirst={index === 0}
          />
        ))}
      </View>
    </View>
  );
};
