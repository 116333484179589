import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { Integrations } from "@sentry/tracing";
import React, { Fragment, useEffect } from "react";

import { useConfig } from "./config";

interface SentryProviderProps {
  children: React.ReactNode;
}

export function SentryProvider(props: SentryProviderProps) {
  const { children } = props;
  const config = useConfig();

  useEffect(() => {
    Sentry.init({
      dsn: config.sentryDSN,
      environment: config.environment,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      // @ts-ignore: This is a valid option for Replay integration
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [new Replay(), new Integrations.BrowserTracing()],
    });
  }, [config]);

  return <Fragment>{children}</Fragment>;
}
