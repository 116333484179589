import { StyleSheet, View } from "react-native";

import { Text, TextColor } from "./text_v2";
import { colors } from "./colors";

interface PropsType {
  text: string;
  appearance: Appearance;
}

type Appearance = "danger";

type ChipColors = {
  [key in Appearance]: {
    backgroundColor: string;
    textColorName: TextColor;
  };
};

const chipColors: ChipColors = {
  danger: {
    backgroundColor: colors.brand.grapefruitMinus80,
    textColorName: "grapefruit-core",
  },
};

export function Chip({ text, appearance }: PropsType) {
  return (
    <View style={[styles.root, styles[appearance]]}>
      <Text
        size="xs"
        weight="semibold"
        color={chipColors[appearance].textColorName}
      >
        {text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 4,
    width: "fit-content",
  },
  danger: {
    backgroundColor: chipColors.danger.backgroundColor,
  },
});
