import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

const colors = {
  primary: {
    track: "rgba(89, 65, 176, 0.1)",
    bar: "#5941B0",
  },
  warning: {
    track: "rgba(255, 89, 90, 0.1)",
    bar: "#FF595A",
  },
};

interface ProgressBarProps {
  progress: number;
  color: "primary" | "warning";
  containerStyles?: StyleProp<ViewStyle>;
}

export function ProgressBar(props: ProgressBarProps) {
  const { progress = 0, color = "primary", containerStyles = {} } = props;

  return (
    <View style={[styles.progressBarContainer, containerStyles]}>
      <View
        style={[
          styles.track,
          {
            backgroundColor: colors[color]?.track,
          },
        ]}
      >
        <View
          style={[
            styles.bar,
            { width: `${progress}%`, backgroundColor: colors[color]?.bar },
          ]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  progressBarContainer: {
    width: "100%",
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  track: {
    height: 24,
    position: "relative",
    borderRadius: 40,
    overflow: "hidden",
  },
  bar: {
    height: "100%",
  },
});
