import { colors } from "components/colors";
import { Divider } from "components/divider";
import { Drawer } from "components/drawer";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";
import { TextLink } from "components/text_link";

import { Text } from "components/text_v2";
import { format } from "date-fns";
import { formatCurrency } from "lib/currency";
import { parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";
import { parseTime } from "lib/time_utils";
import { Column } from "pages/budgets/components/budget_transaction_table";
import {
  TransactionProps,
  TransactionRightPanel,
} from "pages/budgets/components/transaction_right_panel";
import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

export const BudgetTransactionRow = ({
  order,
  orgInfo,
  groupInfo,
  refetch,
}: TransactionProps) => {
  const {
    hostFullName,
    hostTitle,
    locationName,
    locationAddress,
    spaceName,
    bookingDate,
    bookingStartTime,
    bookingEndTime,
    orderCreatedAt,
    totalPrice,
    currency,
    groupBudget,
    bookingRequest,
  } = order;

  const [openModal, setOpenModal] = useState(false);
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  const space = (
    <>
      <Text color="black-70" size="xs" weight={"semibold"}>
        {locationName} {spaceName && `· ${spaceName}`}
      </Text>
      <Text customColor={colors.brand.blackcore} size="xs">
        {format(parseDay(bookingDate), "MMM d")}
        {bookingStartTime &&
          bookingEndTime &&
          ` · ${format(parseTime(bookingStartTime), "h:mma")} - ${format(
            parseTime(bookingEndTime),
            "h:mma",
          )}`}
      </Text>
      <Text color="black-70" size="xs">
        {locationAddress.split(",")[0].trim()}
      </Text>
    </>
  );

  const userFullName = (
    <Text size="xs" weight={"semibold"}>
      {hostFullName}
    </Text>
  );
  const userTitle = <Text size="xs">{hostTitle}</Text>;

  const total = (
    <Text size="xs" weight={"semibold"}>
      {formatCurrency(totalPrice, getSystemLocale(), currency)}
    </Text>
  );

  const bookedOn = (
    <Text size="xs" weight={isMobile ? "regular" : "semibold"}>
      {format(parseDay(orderCreatedAt), "MM-dd-yyyy")}
    </Text>
  );

  const budgetLink = groupBudget?.id ? (
    <TextLink
      text={groupBudget.name}
      size="xs"
      href={`/admin/budgets/${
        groupBudget.id
      }?backButtonText=${encodeURIComponent(
        "Back to organization transactions",
      )}`}
    />
  ) : (
    <Text color="black-70" size="xs">
      {orgInfo?.name}
    </Text>
  );

  const dialog = (
    <Drawer visible={openModal} onRequestClose={() => setOpenModal(false)}>
      <TransactionRightPanel
        order={order}
        orgInfo={orgInfo}
        groupInfo={groupInfo}
        refetch={refetch}
        onClose={() => setOpenModal(false)}
      />
    </Drawer>
  );

  if (isMobile) {
    return (
      <>
        <Pressable onPress={() => setOpenModal(true)}>
          <View style={styles.rowContainer}>
            <View style={styles.horizontalView}>
              <Column percent={60}>
                {userFullName}
                {bookedOn}
              </Column>
              <Column percent={40} alignItems={"flex-end"}>
                <View style={styles.oopMobile}>
                  {!!bookingRequest?.id && (
                    <OutOfPolicyTag appearance={"yellow"} onlyIcon={true} />
                  )}
                  {total}
                </View>
                {budgetLink}
              </Column>
            </View>
            <Divider />
          </View>
        </Pressable>
        {dialog}
      </>
    );
  }

  return (
    <>
      <Pressable onPress={() => setOpenModal(true)}>
        <View style={styles.rowContainer}>
          <View style={styles.horizontalView}>
            <Column percent={35}>{space}</Column>
            <Column percent={20}>
              {userFullName}
              {userTitle}
            </Column>
            <Column percent={15}>
              {total}
              {budgetLink}
            </Column>
            <Column percent={10}>{bookedOn}</Column>
            <Column percent={15}>
              {!!bookingRequest?.id && <OutOfPolicyTag appearance={"yellow"} />}
            </Column>
          </View>
          <Divider />
        </View>
      </Pressable>
      {dialog}
    </>
  );
};

const styles = StyleSheet.create({
  horizontalView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    gap: 24,
  },
  rowContainer: {
    paddingHorizontal: 24,
  },
  dialog: {
    borderRadius: 0,
    alignSelf: "flex-end",
    height: "100%",
    maxHeight: "100%",
  },
  oopMobile: {
    flexDirection: "row",
    gap: 4,
  },
});
