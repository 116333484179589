import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Dropdown } from "components/dropdown";
import { DayInterval } from "lib/day_utils";
import {
  getDayIntervalFromBudgetsDateRangePickerOption,
  getSelectedPredeterminedOption,
  BudgetsDateRangePickerButton,
  budgetsDateRangePickerOptions,
} from "./budgets_date_range_picker_common";
import { SelectOption } from "components/select/select_option_header_variant";
import { colors } from "components/colors";
import { DateRangeView } from "./budgets_date_range_view";
import { tokens } from "components/tokens";

interface DateRangePickerDesktopProps {
  value: DayInterval;
  onChange: (value: DayInterval) => void;
  earliestAllowedDate?: Date;
}

export function BudgetsDateRangePickerDesktop(
  props: DateRangePickerDesktopProps,
) {
  const { value, onChange, earliestAllowedDate } = props;
  const [open, setOpen] = useState(false);
  const [customOpen, setCustomOpen] = useState(false);

  const selectedPredeterminedOption = getSelectedPredeterminedOption(value);

  const handleClose = useCallback(() => {
    setOpen(false);
    setCustomOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClear = useCallback(() => {
    onChange(getDayIntervalFromBudgetsDateRangePickerOption("this_month"));
    setOpen(false);
    setCustomOpen(false);
  }, [onChange]);

  const handleSelectCustomOption = useCallback(() => {
    setCustomOpen(true);
  }, []);

  const handleSelectPredeterminedOption = useCallback(
    (value: string) => {
      onChange(getDayIntervalFromBudgetsDateRangePickerOption(value));
      handleClose();
    },
    [onChange, handleClose],
  );

  const handleApplyCustomDateRange = useCallback(
    (value: DayInterval) => {
      setOpen(false);
      setCustomOpen(false);
      onChange(value);
    },
    [onChange],
  );

  return (
    <View style={styles.container}>
      <Dropdown
        open={open}
        onRequestClose={handleClose}
        content={
          <View
            style={[styles.contentWrapper, customOpen && styles.contentOpen]}
          >
            <View style={styles.optionWrapper}>
              {budgetsDateRangePickerOptions.map((o) => {
                const isSelected =
                  o.value === selectedPredeterminedOption?.value;
                return (
                  <View style={styles.option} key={o.value}>
                    <SelectOption
                      selected={isSelected}
                      option={o}
                      testID={o.value}
                      onSelect={() => handleSelectPredeterminedOption(o.value)}
                    />
                  </View>
                );
              })}
              <View style={styles.option}>
                <SelectOption
                  selected={!selectedPredeterminedOption && !!value}
                  option={{ label: "Custom" }}
                  testID="reports-date-range-picker-desktop-custom-button"
                  onSelect={handleSelectCustomOption}
                />
              </View>
            </View>

            {customOpen && (
              <View style={styles.customPickerWrapper}>
                <DateRangeView
                  value={value}
                  onClear={handleClear}
                  onChange={handleApplyCustomDateRange}
                  earliestAllowedDate={earliestAllowedDate}
                  onCancel={handleClose}
                  open={open}
                />
              </View>
            )}
          </View>
        }
      >
        <View style={styles.pickerWrapper}>
          <BudgetsDateRangePickerButton
            value={value}
            selectedPredeterminedOption={selectedPredeterminedOption}
            onPress={handleOpen}
            isOpen={open}
          />
        </View>
      </Dropdown>
    </View>
  );
}

const styles = StyleSheet.create({
  customPickerWrapper: {
    width: 336,
    borderLeftWidth: 1,
    borderLeftColor: colors.brand.blackMinus90,
    backgroundColor: "white",
  },
  contentWrapper: {
    flexDirection: "row",
    backgroundColor: colors.brand.whitecore,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    width: 160,
    ...tokens.shadow.elevation1,
  },
  contentOpen: {
    width: 496,
  },
  optionWrapper: {
    width: 160,
    alignItems: "center",
    backgroundColor: "white",
  },
  option: {
    width: "100%",
  },
  pickerWrapper: {
    width: "max-content",
  },
  container: {
    zIndex: 2,
    width: "max-content",
  },
});
