import { useQuery } from "@apollo/client";
import { View } from "react-native";
import { Carousel } from "react-responsive-carousel";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import {
  ReportingPageOrganizationReportingQuery,
  ReportingPageOrganizationReportingQueryVariables,
  ReportingPageReportingDataFragment,
} from "core/graphql.generated";
import { ReportingBookings } from "./bookings";
import { ReportingEngagement } from "./engagement";
import { ReportingFrequency } from "./frequency";
import { RecentActivity } from "./recent_activity";
import { ReportingSpend } from "./spend";
import { ReportingSubscriptionOverviewMobile } from "./subscription_overview_mobile";
import { DayInterval, isIntervalThisMonth } from "lib/day_utils";
import { ChartInCarouselWrapper } from "./chart_in_carousel_wrapper";
import { reportingPageOrganizationReportingGQLQuery } from "../gql_queries";
import { ReportingBudgetCardMobile } from "./budget_card_mobile";
import { ReportingContentSkeletonMobile } from "./content_skeleton_mobile";

interface ReportingPageSubscriptionMobileProps {
  dayInterval: DayInterval;
}

export function ReportingSubscriptionMobile(
  props: ReportingPageSubscriptionMobileProps,
) {
  const { dayInterval } = props;

  const { loading: reportingLoading, data: reportingData } = useQuery<
    ReportingPageOrganizationReportingQuery,
    ReportingPageOrganizationReportingQueryVariables
  >(reportingPageOrganizationReportingGQLQuery, {
    variables: {
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
  });

  return (
    <View>
      {reportingLoading ||
      !reportingData?.currentUser?.organization?.reporting ? (
        <ReportingContentSkeletonMobile />
      ) : (
        <ReportingContent
          reporting={reportingData?.currentUser?.organization?.reporting}
          dayInterval={dayInterval}
        />
      )}
      <Spacer size={16} />
      <Content>
        <RecentActivity />
      </Content>
    </View>
  );
}

interface ReportingContentProps {
  reporting: ReportingPageReportingDataFragment;
  dayInterval: DayInterval;
}

function ReportingContent(props: ReportingContentProps) {
  const { reporting, dayInterval } = props;

  return (
    <Content>
      <Heading size="xl">Usage</Heading>
      <Spacer size={16} />
      <ReportingSubscriptionOverviewMobile
        totalSpend={reporting.spend.totalSpend}
        totalBookings={reporting.bookings.totalBookings}
      />
      <Spacer size={16} />
      <Carousel showIndicators={true}>
        <ChartInCarouselWrapper>
          <ReportingEngagement
            soloBookings={reporting.engagement.soloBookings}
            teamBookings={reporting.engagement.teamBookings}
            labelUpdatedAt=""
          />
        </ChartInCarouselWrapper>
        <ChartInCarouselWrapper>
          <ReportingFrequency
            zeroOrSingleBookingPerWeek={
              reporting.frequency.zeroOrSingleBookingPerWeek
            }
            twoOrThreeBookingsPerWeek={
              reporting.frequency.twoOrThreeBookingsPerWeek
            }
            fourOrMoreBookingsPerWeek={
              reporting.frequency.fourOrMoreBookingsPerWeek
            }
            totalEmployees={reporting.frequency.totalEmployees}
            labelUpdatedAt=""
          />
        </ChartInCarouselWrapper>
      </Carousel>
      <Spacer size={24} />
      <Heading size="xl">Premium Bookings</Heading>
      <Spacer size={16} />
      <ReportingBudgetCardMobile
        editable
        showOnlyTotalSpent={!isIntervalThisMonth(dayInterval)}
      />
      <Spacer size={16} />
      <Carousel showIndicators={true}>
        <ChartInCarouselWrapper>
          <ReportingSpend
            dayPasses={reporting.spend.dayPasses}
            dayOffices={reporting.spend.dayOffices}
            meetingRooms={reporting.spend.meetingRooms}
            totalSpend={reporting.spend.totalSpend}
            labelUpdatedAt=""
          />
        </ChartInCarouselWrapper>
        <ChartInCarouselWrapper>
          <ReportingBookings
            dayPasses={reporting.bookings.dayPasses}
            dayOffices={reporting.bookings.dayOffices}
            meetingRooms={reporting.bookings.meetingRooms}
            totalBookings={reporting.bookings.totalBookings}
            labelUpdatedAt=""
          />
        </ChartInCarouselWrapper>
      </Carousel>
    </Content>
  );
}
