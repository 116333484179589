import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toCoordinates } from "../mapbox";
import {
  HomeSearchParamsChangeSet,
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "./use_home_search_params";
import { useInitialPlace } from "./use_initial_place";

export const SPACE_TYPE_TAB_LOCALSTORAGE_KEY = "spaceTypeTab";

export function useInitialHomeSearchParams() {
  const searchParams = useHomeSearchParamsQuery();
  const initialPlace = useInitialPlace();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const prevLocationSearch = useRef("");
  const location = useLocation();

  useEffect(() => {
    if (
      (prevLocationSearch.current &&
        prevLocationSearch.current === location.search) ||
      initialPlace.loading ||
      !initialPlace.data ||
      !initialPlace.initialized
    ) {
      return;
    }

    const searchParamsChangeSet: HomeSearchParamsChangeSet = {};

    if (!searchParams.coordinates) {
      searchParamsChangeSet.coordinates = toCoordinates(
        initialPlace.data?.center,
      );
    }

    if (!searchParams.bbox) {
      searchParamsChangeSet.bbox = initialPlace.data.bbox;
    }

    if (Object.keys(searchParamsChangeSet).length) {
      updateHomeSearchParams(searchParamsChangeSet);
    }
    prevLocationSearch.current = location.search;
  }, [
    location.search,
    prevLocationSearch,
    searchParams,
    updateHomeSearchParams,
    initialPlace,
  ]);
}
