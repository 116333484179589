import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { urlLocationSearchState } from "../utils/url_location_search_atom";
import { useHomeSearchParamsQuery } from "./use_home_search_params";

export function useLastLocationSearch() {
  const { coordinates, bbox, placeId } = useHomeSearchParamsQuery();
  const [locatinSearch, setLocationSearch] = useRecoilState(
    urlLocationSearchState,
  );

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (
      (coordinates &&
        (coordinates?.lat !== locatinSearch.coordinates?.lat ||
          coordinates?.lng !== locatinSearch.coordinates?.lng)) ||
      (bbox && !bbox.every((num) => locatinSearch.bbox?.includes(num))) ||
      placeId !== locatinSearch.placeId
    ) {
      if (coordinates) {
        searchParams.set(
          "coordinates",
          `${coordinates.lat},${coordinates.lng}`,
        );
      }

      if (bbox) {
        searchParams.set("bbox", `${bbox}`);
      }

      if (placeId) {
        searchParams.set("placeId", placeId);
      }

      const qs = `?${searchParams.toString()}`;
      setLocationSearch({
        coordinates,
        bbox,
        placeId,
        homeUrl: `/${qs}`,
      });
    }
  }, [
    bbox,
    coordinates,
    locatinSearch.bbox,
    locatinSearch.coordinates?.lat,
    locatinSearch.coordinates?.lng,
    locatinSearch.placeId,
    placeId,
    setLocationSearch,
  ]);
}
