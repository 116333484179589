import { useCallback, useEffect, useState } from "react";
import { appConfig } from "providers/config";

export function useGCalExtension() {
  const [version, setVersion] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const hasChromeApi = typeof chrome !== "undefined";

  useEffect(() => {
    if (hasChromeApi && appConfig.chromeWebstoreId) {
      // see https://developer.chrome.com/docs/extensions/mv3/messaging/#external-webpage
      chrome?.runtime?.sendMessage(
        appConfig.chromeWebstoreId,
        { message: "version" },
        (response) => {
          if (chrome.runtime.lastError || !response) {
            console.error(
              "Message sending to GCal extension failed: " +
                chrome.runtime.lastError?.message,
              { response },
            );
          } else {
            setVersion(response.version);
          }
        },
      );
    }

    setTimeout(() => setIsLoading(false), 10);
  }, [hasChromeApi]);

  const setExtensionPopup = useCallback(
    (isAuthenticated: boolean) => {
      if (!hasChromeApi || !version) {
        return;
      }

      chrome?.runtime?.sendMessage(appConfig.chromeWebstoreId, {
        message: "setPopup",
        payload: {
          popup: isAuthenticated
            ? "popup-get-started.html"
            : "popup-sign-in.html",
        },
      });
    },
    [hasChromeApi, version],
  );

  return { isLoading, version, setExtensionPopup };
}
