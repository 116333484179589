import { AsyncRenderer } from "components/AsyncRenderer";
import {
  BookingSpaceDetailFragment,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import { useSpaceNearByQuery } from "pages/homev3/hooks/use_space_nearby_query";
import { HeroCarouselSkeleton } from "pages/homev3/return_user/hero_carousel/hero_carousel_skeleton";
import { HeroLocationCard } from "pages/homev3/return_user/hero_carousel/hero_location_card";
import React from "react";

type SimilarLocationProps = {
  lastSpace: BookingSpaceDetailFragment;
  setShowSimilarLocation: (show: boolean) => void;
  onPress?: () => void;
};
export function SimilarLocation({
  lastSpace,
  setShowSimilarLocation,
  onPress,
}: SimilarLocationProps) {
  const { location } = lastSpace;
  const { data, loading, error } = useSpaceNearByQuery({
    location: {
      lat: location.address.latitude!,
      lng: location.address.longitude!,
    },
    skip: !location,
  });

  return (
    <AsyncRenderer
      error={error}
      data={data}
      loadingHandler={() => <HeroCarouselSkeleton />}
      loading={loading}
    >
      {(data) => {
        let validSimilarLocations: SpaceLocationDetailFragment[] = [];
        if (lastSpace?.amenities.length === 0) {
          validSimilarLocations = data.spaces.map((sp) => sp.location);
        } else {
          const requiredAmenities = lastSpace?.amenities.map(
            (amenity) => amenity.id,
          );
          // list spaces has amenities the same 50% or higher with the last space amenity
          validSimilarLocations = data.spaces
            .filter((space) => {
              const matchingAmenities = space.amenities.filter((amenity) =>
                requiredAmenities.includes(amenity.id),
              );
              const similarityPercentage =
                (matchingAmenities.length / requiredAmenities.length) * 100;
              return similarityPercentage >= 50;
            })
            .map((sp) => sp.location);
        }
        if (validSimilarLocations.length === 0) {
          setShowSimilarLocation(false);
        }
        return (
          <Content
            location={validSimilarLocations[0]}
            lastLocationName={lastSpace.location.name}
            onPress={onPress}
          />
        );
      }}
    </AsyncRenderer>
  );
}

type ContentProps = {
  location: SpaceLocationDetailFragment;
  lastLocationName: string;
  onPress?: () => void;
};
function Content({ location, lastLocationName, onPress }: ContentProps) {
  if (!location) {
    return null;
  }
  return (
    <HeroLocationCard
      location={location}
      title={`Want to try a space similar to ${lastLocationName}?`}
      subtitle={`Check out the ${location.name}`}
      onPress={onPress}
    />
  );
}
