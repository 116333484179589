import { ApolloError, gql, useQuery } from "@apollo/client";
import {
  GetLocationNearByQuery,
  GetLocationNearByQueryVariables,
  LocationsSort,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import { useInitialPlace } from "pages/homev2/hooks/use_initial_place";
import { spaceLocationDetailFragment } from "pages/homev3/fragment";
import {
  MAX_FETCH_MORE_COUNT,
  RADIUS_INCREASE,
  useSpaceNearByQueryProps,
} from "pages/homev3/hooks/use_space_nearby_query";
import { useEffect, useMemo, useState } from "react";
import { useUserCurrentLocation } from "./use_user_current_location";
import { toCoordinates } from "../../homev2/mapbox";

interface LocationNearByQueryData {
  __typename?: "Query";
  locations: SpaceLocationDetailFragment[];
  cityName?: string;
}

interface LocationNearByQueryReturn {
  data: LocationNearByQueryData;
  loading: boolean;
  error: ApolloError | undefined;
}
export function useLocationNearByQuery({
  location,
  filter = {},
  fixedRadius,
  locationLimit = 15,
  skip = false,
}: useSpaceNearByQueryProps): LocationNearByQueryReturn {
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useUserCurrentLocation();
  const { data: placeData, loading: placeLoading } = useInitialPlace();
  const [fetchCount, setFetchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const variables: GetLocationNearByQueryVariables | null = useMemo(() => {
    if (
      skip ||
      !currentUserCoordinates ||
      currentUserLoading ||
      (!location && (!placeData || placeLoading))
    ) {
      return null;
    }
    return {
      centerMapCoordinates: {
        lat: location?.lat || placeData?.geometry.coordinates[1],
        lng: location?.lng || placeData?.geometry.coordinates[0],
      },
      currentUserCoordinates: toCoordinates(currentUserCoordinates.center),
      filter: filter,
      limit: 15,
      sort: LocationsSort.Distance,
      /*If the radius is not fixed.
      Increase radius based on fetch count, each time 3 miles ~ 4828 meters*/
      radius: fixedRadius || RADIUS_INCREASE * (fetchCount + 1),
    };
  }, [
    currentUserCoordinates,
    currentUserLoading,
    fetchCount,
    filter,
    fixedRadius,
    location,
    placeData,
    placeLoading,
    skip,
  ]);

  const { data, error } = useQuery<
    GetLocationNearByQuery,
    GetLocationNearByQueryVariables
  >(getLocationNearBy, {
    variables: variables!,
    skip: !variables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      if (
        !fixedRadius &&
        data.locations.length <= locationLimit &&
        fetchCount < MAX_FETCH_MORE_COUNT
      ) {
        setFetchCount((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
      }
    }
    if (error) {
      setIsLoading(false);
    }
  }, [data, error, fetchCount, fixedRadius, locationLimit]);

  return useMemo(
    () => ({
      data: {
        __typename: "Query",
        locations: (data?.locations ? [...data.locations] : []).sort(
          (a, b) =>
            (a?.currentUserDistance || 0) - (b?.currentUserDistance || 0),
        ),
        cityName: placeData?.text,
      },
      loading: isLoading,
      error,
    }),
    [data?.locations, error, isLoading, placeData?.text],
  );
}

const getLocationNearBy = gql`
  ${spaceLocationDetailFragment}
  query GetLocationNearBy(
    $filter: LocationsFilter
    $centerMapCoordinates: SpaceSearchCoordinates
    $currentUserCoordinates: SpaceSearchCoordinates
    $limit: Int
    $radius: Int
    $sort: LocationsSort
  ) {
    locations(
      filter: $filter
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      radius: $radius
      sort: $sort
    ) {
      ...SpaceLocationDetail
    }
  }
`;
