import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import ContentLoader, { Rect } from "react-content-loader/native";
import { View } from "react-native";

export function SavedPlacesSkeleton() {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <SavedPlacesMobileSkeleton />;
  }

  return <SavedPlacesDesktopSkeleton />;
}

function SavedPlacesDesktopSkeleton() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={140}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="140px" />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={140}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="140px" />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={140}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="140px" />
      </ContentLoader>
    </View>
  );
}

function SavedPlacesMobileSkeleton() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={306}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="306px" />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={306}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="306px" />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={306}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="306px" />
      </ContentLoader>
    </View>
  );
}
