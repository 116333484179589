import { useQuery, gql } from "@apollo/client";
import { CurrentUserIpPlaceCoordinatesHookQuery } from "core/graphql.generated";

export function useCurrentUserIpCoordinates(options?: { skip?: boolean }) {
  const { data, loading, error } =
    useQuery<CurrentUserIpPlaceCoordinatesHookQuery>(
      currentUserIpPlaceCoordinatesGQLQuery,
      options,
    );

  return {
    data: data?.ipPlace
      ? {
          lat: data?.ipPlace.latitude,
          lng: data?.ipPlace.longitude,
        }
      : undefined,
    loading,
    error,
  };
}

const currentUserIpPlaceCoordinatesGQLQuery = gql`
  query CurrentUserIpPlaceCoordinatesHook {
    ipPlace {
      latitude
      longitude
      placeName
    }
  }
`;
