import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import { IconButton } from "pages/team_spaces/components/buttons/IconButton";
import React, { useEffect, useRef, useState } from "react";
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

type HorizontalListProps<T> = {
  data: T[];
  renderItem: (item: T) => React.ReactNode;
  title?: React.ReactNode;
  itemGap?: number;
  testID?: string;
  onNavigate?: () => void;
};

export function HorizontalList<T>({
  data,
  renderItem,
  title,
  itemGap = 24,
  testID,
  onNavigate,
}: HorizontalListProps<T>) {
  const mq = useMediaQuery();
  const scrollViewRef = useRef<ScrollView>(null);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);

  const listWidth = itemWidth * data.length - itemGap;
  let maxOffset = listWidth - itemWidth > 0 ? listWidth - itemWidth : 0;
  if (mq.deviceQuery.desktop && listWidth > 1040) {
    maxOffset = listWidth - 1040;
  }

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ x: currentOffset, animated: false });
    }
  }, [currentOffset]);
  if (!data || data.length === 0) {
    return null;
  }
  const handleItemLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    if (width && width !== itemWidth) {
      setItemWidth(width + itemGap);
    }
  };
  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const newOffset = event.nativeEvent?.contentOffset?.x;
    if (newOffset && newOffset !== currentOffset) {
      setCurrentOffset(newOffset);
    }
  };
  const handleScrollLeft = () => {
    if (scrollViewRef.current) {
      if (currentOffset > 0) {
        const newOffset =
          currentOffset - itemWidth > 0 ? currentOffset - itemWidth : 0;
        setCurrentOffset(newOffset);

        if (onNavigate) {
          onNavigate();
        }
      }
    }
  };

  const handleScrollRight = () => {
    if (scrollViewRef.current) {
      if (currentOffset < maxOffset) {
        const newOffset =
          currentOffset + itemWidth > maxOffset
            ? maxOffset
            : currentOffset + itemWidth;
        setCurrentOffset(newOffset);

        if (onNavigate) {
          onNavigate();
        }
      }
    }
  };

  const maxCardPerRow = mq.deviceQuery.mobile ? 1 : 3;
  const hideNavigationBtn =
    mq.deviceQuery.mobile || data.length <= maxCardPerRow;

  return (
    <View testID={testID}>
      <View style={styles.navigationBtnBar}>
        {title}
        {!hideNavigationBtn && (
          <View style={styles.navigationBtnView}>
            <IconButton
              iconName={"arrow-left"}
              onPress={handleScrollLeft}
              disabled={currentOffset <= 0}
            />
            <IconButton
              iconName={"arrow-right"}
              onPress={handleScrollRight}
              disabled={currentOffset >= maxOffset}
            />
          </View>
        )}
      </View>
      <Spacer size={24} />
      <ScrollView
        horizontal={true}
        ref={scrollViewRef}
        onScroll={onScroll}
        scrollEventThrottle={500}
        showsHorizontalScrollIndicator={false}
      >
        <View
          style={[
            styles.itemList,
            // @ts-ignore ignore the gap warning
            { gap: itemGap },
          ]}
        >
          {data.map((item, index) => (
            <View
              key={index}
              onLayout={index === 0 ? handleItemLayout : undefined}
              style={styles.itemView}
            >
              {renderItem(item)}
            </View>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  navigationBtnBar: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navigationBtnView: {
    flexDirection: "row",
    gap: 16,
  },
  itemList: {
    flexDirection: "row",
    alignItems: "stretch",
    paddingBottom: 8,
  },
  itemView: {
    flex: 1,
  },
});
