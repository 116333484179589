import React, { Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { VictoryPie } from "victory";
import { Icon } from "components/icon";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { Tooltip } from "components/tooltip";
import { useMediaQuery } from "lib/media_query";
import { colors } from "components/colors";

export interface ReportingData {
  label: string;
  percentage: number;
  value: number;
  color: string;
}

interface ReportingPieChartProps {
  empty: boolean;
  emptyColor: string;
  data: ReportingData[];
  formatValue?: (value: number) => string;
  total: number;
  item: string;
  title: string;
  tooltip: string;
  labelUpdatedAt: string;
}

export function ReportingPieChart(props: ReportingPieChartProps) {
  const {
    empty,
    labelUpdatedAt,
    emptyColor,
    title,
    data,
    total,
    item,
    tooltip,
    formatValue,
  } = props;
  const mq = useMediaQuery();

  return (
    <View style={styles.chart}>
      <View style={styles.chartHeader}>
        <Text size="md" weight="semibold">
          {title}
        </Text>
        <Tooltip
          position={mq.sizeQuery.mdAndUp ? "top-right" : "bottom-left"}
          offset={18}
          content={
            <View style={styles.tooltip}>
              <Text size="micro">{tooltip}</Text>
              <Spacer size={16} />
              <Text color="black-50" size="micro">
                {labelUpdatedAt}
              </Text>
            </View>
          }
        >
          <Icon name="info" color={colors.brand.blackMinus30} />
        </Tooltip>
      </View>

      {empty ? (
        <View style={styles.chartWrapper}>
          <VictoryPie
            data={[
              {
                x: "",
                y: 100,
              },
            ]}
            style={{
              data: {
                fill: emptyColor,
              },
              labels: {
                display: "none",
              },
            }}
            innerRadius={() => 90}
          />
          <View style={styles.middleLabel}>
            <Text size="md" align="center">
              No data available
            </Text>
          </View>
        </View>
      ) : (
        <View>
          <VictoryPie
            data={data
              .filter((d) => d.value !== 0)
              .map((d) => ({
                x: `${(d.percentage * 100).toFixed(0)}%`,
                y: d.value,
                fill: d.color,
              }))}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
              labels: {
                padding: 33,
                fontSize: 9,
              },
            }}
            innerRadius={() => 73}
            width={200}
            height={190}
          />
          <View style={styles.middleLabel}>
            <Text weight="semibold" size="md">
              {formatValue ? formatValue(total) : total}
            </Text>
            <Text size="xs" color="black-70">
              {item}
            </Text>
          </View>
        </View>
      )}
      <Spacer size={16} />
      <ChartLegend data={data} empty={empty} formatValue={formatValue} />
    </View>
  );
}

interface ChartLegendProps {
  data: ReportingData[];
  empty: boolean;
  formatValue?: (value: number) => string;
}

function ChartLegend(props: ChartLegendProps) {
  const { data, empty, formatValue } = props;

  return (
    <Fragment>
      {data.map((d) => (
        <Fragment key={d.label}>
          <ChartLabel empty={empty} data={d} formatValue={formatValue} />
          <Spacer size={8} />
        </Fragment>
      ))}
    </Fragment>
  );
}
interface ChartLabelProps {
  data: ReportingData;
  empty: boolean;
  formatValue?: (value: number) => string;
}

function ChartLabel(props: ChartLabelProps) {
  const { data, empty, formatValue } = props;
  const mq = useMediaQuery();

  return (
    <View
      style={[
        styles.legendLabelWrapper,
        mq.sizeQuery.smAndDown && styles.mobileLegendLabelWrapper,
      ]}
    >
      <View style={styles.legendLabel}>
        {empty && <Spacer direction="row" size={60} />}
        <View
          style={[styles.legendLabelColor, { backgroundColor: data.color }]}
        />
        <View style={styles.legendLabelText}>
          <Text size="xs" weight="bold">
            {data.label}
          </Text>
        </View>
      </View>
      {!empty && (
        <View>
          <Text size="xs">
            {(data.percentage * 100).toFixed(0)}% (
            {formatValue ? formatValue(data.value) : data.value})
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  chart: {
    flex: 1,
    padding: 24,
    backgroundColor: "white",
    borderRadius: 16,
    ...tokens.shadow.elevation1,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    marginLeft: 2,
    // @ts-ignore remove the default zIndex of View at here
    zIndex: "unset",
  },
  chartWrapper: {
    paddingHorizontal: 40,
  },
  tooltip: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "rgba(6, 0, 30, 0.1)",
    padding: 8,
    borderRadius: 6,
    minWidth: 240,
    ...tokens.shadow.elevation1,
  },
  chartHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 1,
  },
  middleLabel: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 100,
    margin: "auto",
  },
  legendLabelColor: {
    width: 16,
    height: 16,
  },
  legendLabelWrapper: {
    flexDirection: "row",
    paddingHorizontal: 16,
    justifyContent: "space-between",
  },
  mobileLegendLabelWrapper: {
    paddingHorizontal: 8,
  },
  legendLabel: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  legendLabelText: {
    paddingLeft: 8,
  },
});
