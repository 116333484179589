import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";

import {
  BudgetPolicyAddressesQuery,
  BudgetPolicyLocationItemDistanceType,
  BudgetPolicyQuery,
  BudgetPolicyQueryVariables,
  UpdateBudgetPolicyMutation,
  UpdateBudgetPolicyMutationVariables,
} from "core/graphql.generated";
import { useProximityPolicyFeatureFlag } from "providers/splitio";
import { useToast } from "providers/toast";
import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  budgetPolicyAddressesQuery,
  FormSubmitValues,
  FormValueType,
  PolicyMutationForm,
} from "./policy_mutation_form";

export const PolicyEdit = () => {
  const toast = useToast();
  const isPPFlag = useProximityPolicyFeatureFlag();
  const history = useHistory();
  const { policyId } = useParams<{ policyId: string }>();
  const apolloClient = useApolloClient();

  const {
    data: policyQueryData,
    loading: policyQueryLoading,
    error: policyQueryError,
  } = useQuery<BudgetPolicyQuery, BudgetPolicyQueryVariables>(
    budgetPolicyQuery,
    {
      variables: {
        id: policyId,
      },
    },
  );

  const {
    data: orgAddressData,
    loading: orgLoading,
    error: orgError,
  } = useQuery<BudgetPolicyAddressesQuery>(budgetPolicyAddressesQuery);

  const [updatePolicy, { loading: updatePolicyLoading }] = useMutation<
    UpdateBudgetPolicyMutation,
    UpdateBudgetPolicyMutationVariables
  >(updateBudgetPolicyMutation);

  const handleSubmit = async (values: FormSubmitValues) => {
    try {
      await updatePolicy({
        variables: {
          input: { ...values, id: policyId },
        },
      });
      toast.notify({
        message: "Policy has been successfully updated",
      });

      await apolloClient.resetStore();
      history.goBack();
    } catch (error) {
      toast.notify({
        message: "There has been an error when updating the policy",
      });
    }
  };

  const initialValues: FormValueType = useMemo(() => {
    if (
      policyQueryData?.budgetPolicy &&
      orgAddressData?.budgetPolicyAddresses
    ) {
      const { budgetPolicyAddresses } = orgAddressData;
      const {
        name,
        isAppliedToNewBudget,
        applyToBudgets,
        spaceTypes: { meetingRoom, dayPass, privateOffice },
        locationItems,
      } = policyQueryData?.budgetPolicy;

      return {
        name,
        isAppliedToNewBudget,
        applyToBudgetIds: applyToBudgets.map(({ id }) => id),
        spaceTypes: {
          meetingRoom,
          dayPass,
          privateOffice,
        },
        locationItems: budgetPolicyAddresses.map((orgAdd) => {
          const mappedItem = locationItems?.find(
            (item) => item.address.id === orgAdd.id,
          );
          return {
            addressId: orgAdd.id,
            street: orgAdd.street,
            latitude: orgAdd.latitude,
            longitude: orgAdd.longitude,
            distance: mappedItem ? mappedItem.distance : 25,
            distanceUnit: mappedItem
              ? mappedItem.distanceUnit
              : BudgetPolicyLocationItemDistanceType.Mi,
            active: !!mappedItem,
          };
        }),
      };
    }

    const defaultValues = {
      name: "",
      isAppliedToNewBudget: false,
      applyToBudgetIds: [],
      spaceTypes: { meetingRoom: false, dayPass: false, privateOffice: false },
      locationItems: [],
    };
    return defaultValues;
  }, [orgAddressData, policyQueryData?.budgetPolicy]);

  if (isPPFlag) {
    return (
      <AsyncRenderer
        loading={policyQueryLoading || orgLoading}
        error={policyQueryError || orgError}
        data={policyQueryData}
      >
        {() => (
          <PolicyMutationForm
            isUpdate
            title="Edit policy"
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={updatePolicyLoading}
            isFetchingInitialValues={policyQueryLoading}
          />
        )}
      </AsyncRenderer>
    );
  }

  return (
    <PolicyMutationForm
      isUpdate
      title="Edit policy"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      loading={updatePolicyLoading}
      isFetchingInitialValues={policyQueryLoading}
    />
  );
};

const budgetPolicyQuery = gql`
  query BudgetPolicy($id: ID!) {
    budgetPolicy(id: $id) {
      name
      isAppliedToNewBudget
      applyToBudgets {
        id
      }
      spaceTypes {
        meetingRoom
        privateOffice
        dayPass
      }
      locationItems {
        id
        distance
        distanceUnit
        address {
          id
          street
          latitude
          longitude
        }
      }
    }
  }
`;

const updateBudgetPolicyMutation = gql`
  mutation UpdateBudgetPolicy($input: UpdateBudgetPolicyInput!) {
    updateBudgetPolicy(input: $input) {
      id
    }
  }
`;
