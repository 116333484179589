import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { Text as TextV2 } from "components/text_v2";
import { formatCurrency } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";
import { BookingDetailPaymentInfo } from "pages/booking_details/components/booking_detail_payment_info";
import React, { ReactNode } from "react";
import { View } from "react-native";

import { BookingPreviewV2 } from "./booking_preview";
import {
  getCheckoutCancellationPolicyText,
  SearchValue,
  useBookingDateRangePickerHelper,
} from "./booking_utils";
import {
  BookingConfig,
  BookingType,
  ImageData,
  PaymentDetail,
  Pricing,
  QuoteDetailsQuery,
  SpaceBookingPolicy,
} from "./graphql.generated";
import { OrderBreakdown } from "./order_breakdown";
import { PromotionDetails } from "./promotion_details";

export interface BookingPreviewPanelSpace {
  id: string;
  bookingConfig: BookingConfig;
  pricings: Pricing[];
  location: {
    name: string;
    timezone: string;
  };
  bookingPolicy: SpaceBookingPolicy;
  name: string;
  images: {
    small: ImageData;
  }[];
}
interface StickyBookingPreviewPanelProps {
  currency: string;
  space: BookingPreviewPanelSpace;
  onChangePromoCode?: (promoCode: string) => void;
  offsiteID?: string;
  promoCode?: string;
  monthlyBilling: boolean;
  paymentDetail?: PaymentDetail | null;
  quote?: QuoteDetailsQuery["quote"];
  quoteError?: string | null;
  searchValue: SearchValue;
  hidePromotionForm?: boolean;
  cancellable?: boolean;
  unCancellableReason?: string | null;
  setBookingDateRangeVisible?: (visible: boolean) => void;
  dateTimeDropDownPicker?: ReactNode;
  confirmationId?: string;
  memo?: string | null;
  bookingID?: string;
}

export function StickyBookingPreviewPanel(
  props: StickyBookingPreviewPanelProps,
) {
  const {
    onChangePromoCode,
    promoCode,
    currency,
    searchValue,
    paymentDetail,
    space,
    monthlyBilling,
    quote,
    quoteError,
    hidePromotionForm,
    cancellable,
    unCancellableReason,
    setBookingDateRangeVisible,
    dateTimeDropDownPicker,
    confirmationId,
    memo,
    bookingID,
  } = props;
  const { openAt } = useBookingDateRangePickerHelper({
    space,
  });
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  let startDate = "";
  let endDate = "";
  if (
    searchValue?.dateRange?.type === BookingType.DailyBooking &&
    searchValue?.dateRange?.startDate &&
    searchValue?.dateRange?.endDate
  ) {
    startDate = searchValue?.dateRange?.startDate;
    endDate = searchValue?.dateRange?.endDate;
  } else if (
    searchValue?.dateRange?.type === BookingType.HourlyBooking &&
    searchValue?.dateRange?.date
  ) {
    startDate = searchValue?.dateRange?.date;
    endDate = searchValue?.dateRange?.date;
  }

  const onPressChange = setBookingDateRangeVisible
    ? () => setBookingDateRangeVisible(true)
    : undefined;

  return (
    <View
      style={{
        padding: isMobile ? 16 : 24,
        borderRadius: 4,
        // @ts-ignore for using flex gap style
        gap: 12,
        boxShadow: "0px 1px 10px rgba(6, 0, 30, 0.1)",
      }}
    >
      <BookingPreviewV2
        searchValue={searchValue}
        space={space}
        onPressChange={onPressChange}
        dateTimeDropDownPicker={dateTimeDropDownPicker}
        confirmationId={confirmationId}
        memo={memo}
        bookingID={bookingID}
      />
      <Spacer size={8} />
      <Divider />
      <TextV2 size="md" weight="semibold">
        Price breakdown
      </TextV2>
      <OrderBreakdown
        currency={currency}
        quote={quote}
        quoteError={quoteError}
      />
      <View style={{ zIndex: -1 }}>
        <Divider />
      </View>
      {space && !hidePromotionForm && quote && (
        <>
          <PromotionDetails
            offsiteSpaceID={space.id}
            promoCode={promoCode || ""}
            onChange={onChangePromoCode}
            startDate={startDate}
            endDate={endDate}
          />
          <Divider />
        </>
      )}
      <BookingDetailPaymentInfo
        monthlyBilling={monthlyBilling}
        paymentDetail={paymentDetail}
        totalPrice={
          quote && formatCurrency(quote.totalPrice, getSystemLocale(), currency)
        }
      />
      {(monthlyBilling || paymentDetail?.paymentMethodDetails) && <Divider />}

      <Text size="xs">
        {!cancellable && unCancellableReason
          ? unCancellableReason
          : getCheckoutCancellationPolicyText(
              space.bookingPolicy,
              space.location.timezone,
              openAt,
              searchValue.dateRange,
            )}
      </Text>
    </View>
  );
}
