import { colors } from "components/colors";
import { Divider } from "components/divider";
import React, { Fragment, useCallback } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Spacer } from "../spacer";
import { Text } from "../text_v2";

export interface TabOption<T> {
  label: string;
  value: T;
  testID?: string;
}

export interface TabsProps<T> {
  value?: T | null;
  onChange: (value: T) => void;
  options: TabOption<T>[];
  fitContent?: boolean;
  spacing?: number;
}

export function TabsV3<T>(props: TabsProps<T>) {
  const { options, value, onChange, spacing = 0, fitContent } = props;

  return (
    <View style={styles.container}>
      {options.map((option, index) => (
        <Fragment key={option.label}>
          <Tab
            testID={option.testID}
            fitContent={fitContent}
            onSelect={onChange}
            option={option}
            active={option.value === value}
          />
          {spacing !== 0 && index !== options.length - 1 && (
            <Spacer size={spacing} />
          )}
          <Divider direction={"vertical"} />
        </Fragment>
      ))}
    </View>
  );
}

export interface TabProps<T> {
  active: boolean;
  option: TabOption<T>;
  onSelect: (value: T) => void;
  fitContent?: boolean;
  testID?: string;
}

function Tab<T>(props: TabProps<T>) {
  const { active, option, onSelect, testID, fitContent } = props;
  const handlePress = useCallback(() => {
    onSelect(option.value);
  }, [onSelect, option]);

  return (
    <Pressable
      style={[
        styles.tab,
        fitContent && styles.fitContent,
        active ? styles.active : styles.default,
      ]}
      testID={testID}
      onPress={handlePress}
    >
      <Text numberOfLines={1} size={"xs"} weight={"semibold"}>
        {option.label}
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.brand.blackMinus90,
    padding: 2,
    flexDirection: "row",
    borderRadius: 4,
  },
  tab: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    padding: 10,
    minWidth: 110,
  },
  fitContent: {
    flex: undefined,
  },
  active: {
    backgroundColor: colors.brand.whitecore,
  },
  default: {
    backgroundColor: colors.brand.blackMinus90,
  },
});
