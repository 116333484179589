import { colors } from "components/colors";
import { Dialog } from "components/dialog";
import React from "react";
import { StyleSheet, View } from "react-native";
import { ModalProps } from "./modal";

interface DrawerProps extends ModalProps {
  testID?: string;
  position?: "left" | "right";
  width?: number;
}

export function Drawer(props: DrawerProps): JSX.Element {
  const {
    visible,
    onRequestClose,
    children,
    testID,
    position = "right",
    width = 400,
  } = props;
  return (
    <Dialog
      visible={visible}
      onRequestClose={onRequestClose}
      offsetTop={1}
      style={[
        styles.dialog,
        position === "left"
          ? { alignSelf: "flex-start" }
          : {
              alignSelf: "flex-end",
            },
      ]}
      testID={testID}
    >
      <View style={[styles.content, { width }]}>{children}</View>
    </Dialog>
  );
}

const styles = StyleSheet.create({
  dialog: {
    borderRadius: 0,
    height: "100%",
    maxHeight: "100%",
  },
  content: {
    flex: 1,
    height: "100%",
    backgroundColor: colors.brand.whitecore,
  },
});
