import { HorizontalList } from "components/horizontal_list";
import {
  BookingSpaceDetailFragment,
  CurrentUserOrderFragment,
} from "core/graphql.generated";
import { ListSpacesSkeleton } from "pages/homev3/components/list_spaces_skeleton";
import { SpaceCard } from "pages/homev3/components/space_card";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useSpaceNearByQuery } from "pages/homev3/hooks/use_space_nearby_query";
import { useUserOrderQuery } from "pages/homev3/hooks/use_user_order_query";
import { useAnalytics } from "providers/analytics";
import React, { useCallback } from "react";

export function SimilarToYourLastBooking() {
  const { data: orderData, loading: orderLoading } = useUserOrderQuery({
    limit: 1,
  });
  let lastSpace;
  const userValidOrders: CurrentUserOrderFragment[] =
    orderData?.currentUser?.orders || [];
  if (userValidOrders.length > 0) {
    lastSpace =
      userValidOrders[userValidOrders.length - 1].orderItems[0].details.space;
  }
  const { data: spaceData, loading: spaceLoading } = useSpaceNearByQuery({
    location: {
      lat: lastSpace?.location.address.latitude!,
      lng: lastSpace?.location.address.longitude!,
    },
    spaceType: lastSpace?.spaceType,
    locationLimit: 15,
    skip: !lastSpace,
  });

  if (orderLoading || spaceLoading) {
    return <ListSpacesSkeleton />;
  }

  if (userValidOrders.length === 0 || spaceData.spaces.length === 0) {
    return null;
  }

  return <Content spaces={spaceData.spaces} />;
}

type ContentProps = {
  spaces: BookingSpaceDetailFragment[];
};
function Content({ spaces }: ContentProps) {
  const analytics = useAnalytics();
  const renderItem = (space: BookingSpaceDetailFragment) => (
    <SpaceCard
      onCardPress={() => {
        analytics.event("Click Similar Last Booking Card", {
          "Space UUID": space.id,
          category: "Rebooking",
          "Location Name": space.location.name,
          "Space Name": space.name,
        });
      }}
      space={space}
      key={space.location.id}
    />
  );

  const onNavigate = useCallback(() => {
    analytics.event("Navigate Horizontal List", {
      Section: "Similar Last Booking",
      category: "Rebooking",
    });
  }, [analytics]);

  return (
    <HorizontalList
      title={
        <TitleSpaceList
          title={"Similar to your last booking"}
          subtitle={"Discover your new favorite workspace"}
        />
      }
      data={spaces.slice(0, 15)}
      renderItem={renderItem}
      onNavigate={onNavigate}
    />
  );
}
