import { unique } from "./array_utils";
import { countryList } from "./countries";

export function formatCurrency(
  amount: number,
  locale: string,
  currency: string = "USD",
): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amount);
}

export function formatCurrencyWithoutDecimal(
  amount: number,
  locale: string,
  currency: string = "USD",
) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}

export function getCurrencies(): Currency[] {
  return unique(countryList.map((country) => country.currency)).map(
    (currency) => ({
      code: currency,
      symbol: currencySymbols[currency],
    }),
  );
}

export interface Currency {
  code: string;
  symbol: string;
}

interface CurrencySymbolsByCurrencyCode {
  [currencyCode: string]: string;
}

// From https://gist.github.com/avaleriani/fc28b62d9a1a5c6092e7435d4fdc909d
export const currencySymbols: CurrencySymbolsByCurrencyCode = {
  EUR: "€",
  USD: "$",
  CAD: "CA$",
  AED: "AED",
  AFN: "Af",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "AR$",
  AUD: "AU$",
  AZN: "man.",
  BAM: "KM",
  BDT: "Tk",
  BGN: "BGN",
  BHD: "BD",
  BIF: "FBu",
  BND: "BN$",
  BMD: "$",
  BOB: "Bs",
  BRL: "R$",
  BTN: "Nu.",
  BWP: "BWP",
  BYR: "BYR",
  BZD: "BZ$",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CL$",
  CNY: "CN¥",
  COP: "CO$",
  CRC: "₡",
  CVE: "CV$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "Dkr",
  DOP: "RD$",
  DZD: "DA",
  EEK: "Ekr",
  EGP: "EGP",
  ERN: "Nfk",
  ETB: "Br",
  FKP: "£",
  GBP: "£",
  GEL: "GEL",
  GHS: "GH₵",
  GIP: "£",
  GNF: "FG",
  GTQ: "GTQ",
  HKD: "HK$",
  HNL: "HNL",
  HRK: "kn",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "Rs",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "Ikr",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "с",
  KHR: "KHR",
  KMF: "CF",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "KZT",
  LAK: "₭",
  LBP: "LB£",
  LKR: "SLRs",
  LRD: "$",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MOP: "MOP$",
  MUR: "MURs",
  MWK: "MK",
  MXN: "MX$",
  MYR: "RM",
  MZN: "MTn",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NOK: "Nkr",
  NPR: "NPRs",
  NZD: "NZ$",
  OMR: "OMR",
  PAB: "B/.",
  PEN: "S/.",
  PHP: "₱",
  PKR: "PKRs",
  PLN: "zł",
  PYG: "₲",
  QAR: "QR",
  RON: "RON",
  RSD: "din.",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SR",
  SBD: "$",
  SDG: "SDG",
  SEK: "Skr",
  SGD: "S$",
  SLL: "Le",
  SOS: "Ssh",
  SSP: "£",
  STN: "Db",
  SYP: "SY£",
  SZL: "L",
  THB: "฿",
  TJS: "ЅМ",
  TND: "DT",
  TOP: "T$",
  TRY: "TL",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  UYU: "$U",
  UZS: "UZS",
  VEF: "Bs.F.",
  VND: "₫",
  VUV: "Vt",
  XAF: "FCFA",
  XCD: "$",
  XOF: "CFA",
  XPF: "Fr",
  YER: "YR",
  ZAR: "R",
  ZMK: "ZK",
};
