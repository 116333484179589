import { gql, useQuery } from "@apollo/client";
import { AppHeaderV3Desktop } from "components/app_header_v3/desktop_header";
import { MobileHeader } from "components/app_header_v3/mobile_header";
import { AsyncRenderer } from "components/AsyncRenderer";
import { DesktopLayout } from "components/layout/desktop_layout";
import { MobileLayout } from "components/layout/mobile_layout";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import { GetUserInfoQuery } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { useQueryString } from "lib/query_string";
import { Tour } from "pages/homev2/components/tour";
import { HeroSearch } from "pages/homev3/new_user/hero_search";
import { NewUser } from "pages/homev3/new_user/new_user";
import { ReturnUser } from "pages/homev3/return_user/return_user";
import { useAnalytics } from "providers/analytics";
import React, { useCallback, useEffect, useState } from "react";
import { HomeV3Skeleton } from "./components/home_v3_skeleton";
import { useUserType } from "./hooks/use_user_type";

export function HomePageV3() {
  const { data, loading, error } = useQuery<GetUserInfoQuery>(getUserInfo);
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.event("Home", { Category: "Views" });
  }, [analytics]);

  return (
    <AsyncRenderer
      loadingHandler={() => <HomeV3Skeleton />}
      error={error}
      data={data}
      loading={loading}
    >
      {(data) => <HomeContent currentUser={data.currentUser} />}
    </AsyncRenderer>
  );
}

export type HomeContentProps = {
  currentUser: GetUserInfoQuery["currentUser"];
};
function HomeContent({ currentUser }: HomeContentProps) {
  const hasBookedBefore = currentUser?.hasBookedBefore;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const analytics = useAnalytics();
  const userType = useUserType();
  const qs = useQueryString();
  const [tourOpen, setTourOpen] = useState(!!qs.showTour);
  const onSearch = useCallback(
    (field: string) => {
      analytics.event("Spaces Search", {
        Source: userType,
        category: "Rebooking",
        Field: field,
      });
    },
    [analytics, userType],
  );

  if (isMobile) {
    if (hasBookedBefore) {
      return (
        <MobileLayout
          header={
            <MobileHeader
              onSearch={onSearch}
              withSearch={true}
              linkToSearchPage={true}
            />
          }
          body={<ReturnUser />}
          footer={<BottomBarNavigation />}
        />
      );
    }
    return (
      <>
        <Tour isVisible={tourOpen} setLayoutVisible={setTourOpen} />
        <HeroSearch />
        <MobileLayout
          header={null}
          body={<NewUser currentUser={currentUser} />}
          footer={<BottomBarNavigation />}
        />
      </>
    );
  }

  if (hasBookedBefore) {
    return (
      <DesktopLayout
        header={
          <AppHeaderV3Desktop
            onSearch={onSearch}
            withSearch={true}
            linkToSearchPage={true}
          />
        }
        body={<ReturnUser />}
        footer={<Footer />}
      />
    );
  }

  return (
    <>
      <Tour isVisible={tourOpen} setLayoutVisible={setTourOpen} />
      <HeroSearch />
      <DesktopLayout
        header={null}
        body={<NewUser currentUser={currentUser} />}
        footer={<Footer />}
      />
    </>
  );
}
const getUserInfo = gql`
  query GetUserInfo {
    currentUser {
      id
      hasBookedBefore
    }
  }
`;
