import { StyleSheet } from "react-native";
import { tokens } from "components/tokens";
import { Fragment, useCallback, useState } from "react";
import { View } from "react-native";
import { DropdownV2 } from "components/dropdown_v2";
import { SelectInputButton } from "./select_input_button";
import { SelectInputOption } from "./select_input_option";

// By default it expects { value: string, label: string }
// unless valueProp and/or labelProp are specified
export interface SelectInputOptionType {
  [key: string]: any;
}

interface SelectInputProps {
  options: SelectInputOptionType[];
  value?: string;
  onChange?: (option: string | undefined) => void;
  testID?: string;
  label?: string;
  topDescription?: string;
  placeholder?: string;
  valueProp?: string; // option's property to use as value/id
  labelProp?: string; // option's property to use as label
  disabled?: boolean;
  contentSameWidth?: boolean;
  contentSameMinWidth?: boolean;
  appearance?: "default" | "outlined";
  dropdownMaxHeight?: string;
}

const noop = () => {};

export function SelectInput(props: SelectInputProps) {
  const {
    options,
    value,
    onChange = noop,
    testID,
    label,
    topDescription,
    placeholder,
    valueProp = "value",
    labelProp = "label",
    disabled = false,
    contentSameWidth,
    contentSameMinWidth,
    appearance,
    dropdownMaxHeight = "50vh",
  } = props;
  const [open, setOpen] = useState(false);

  const handleSelect = useCallback(
    (option) => {
      const isAlreadySelected = value === option[valueProp];

      setOpen(false);

      return isAlreadySelected
        ? onChange(undefined)
        : onChange(option[valueProp]);
    },
    [onChange, valueProp, value],
  );

  const selectedOption = options.find((opt) => opt[valueProp] === value);

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      sameWidth={contentSameWidth}
      sameMinWidth={contentSameMinWidth}
      content={
        <View
          style={[styles.dropdownContent, { maxHeight: dropdownMaxHeight }]}
        >
          {options.map((option) => {
            const isSelected = value === option[valueProp];

            return (
              <Fragment key={option[valueProp]}>
                <SelectInputOption
                  selected={isSelected}
                  option={option}
                  testID={`select-option-${option[labelProp]}`}
                  onSelect={handleSelect}
                  labelProp={labelProp}
                />
              </Fragment>
            );
          })}
        </View>
      }
    >
      <SelectInputButton
        label={label}
        topDescription={topDescription}
        testID={testID}
        onPress={() => setOpen(!open)}
        isOpen={open}
        appearance={appearance}
        disabled={disabled}
        title={selectedOption ? selectedOption?.[labelProp] : placeholder}
      />
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dropdownContent: {
    overflow: "scroll",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: tokens.colors.base.white,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
});
