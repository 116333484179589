import React, { Fragment } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Divider } from "components/divider";
import { Icon, IconName } from "components/icon";
import { Row } from "components/row";
import { tokens } from "components/tokens";

import { useMediaQuery } from "lib/media_query";
import { Text } from "components/text_v2";

interface DialogHeaderProps {
  headerTitle?: string;
  headerLeftIcon?: IconName;
  onHeaderLeftIconPress?: () => void;
  headerRight?: React.ReactNode;
  headerRightIcon?: IconName;
  onHeaderRightIconPress?: () => void;
}

export function DialogHeader(props: DialogHeaderProps) {
  const {
    headerTitle,
    headerRight,
    headerLeftIcon,
    onHeaderLeftIconPress,
    headerRightIcon,
    onHeaderRightIconPress,
  } = props;

  return (
    <Fragment>
      <Container
        borderTopLeftRadius={tokens.radius}
        borderTopRightRadius={tokens.radius}
        color="content"
        height={headerTitle ? 75 : 48}
        paddingTop={headerTitle ? 28 : 16}
        paddingBottom={4}
      >
        {headerLeftIcon && (
          <Pressable
            style={[
              styles.icon,
              {
                left: headerTitle ? 20 : 12,
                top: headerTitle ? 28 : 12,
              },
            ]}
            onPress={(e) => {
              if (onHeaderLeftIconPress !== undefined) {
                e.preventDefault();
                e.stopPropagation();

                onHeaderLeftIconPress();
              }
            }}
          >
            <Icon name={headerLeftIcon} />
          </Pressable>
        )}
        {headerRightIcon && (
          <Pressable
            style={[
              styles.icon,
              {
                right: headerTitle ? 20 : 12,
                top: headerTitle ? 28 : 12,
              },
            ]}
            onPress={(e) => {
              if (onHeaderRightIconPress !== undefined) {
                e.preventDefault();
                e.stopPropagation();

                onHeaderRightIconPress();
              }
            }}
          >
            <Icon name={headerRightIcon} />
          </Pressable>
        )}
        <Text size="sm" align="center" weight="semibold">
          {headerTitle}
        </Text>
        <View style={styles.headerRight}>{headerRight}</View>
      </Container>
      {headerTitle && <Divider color="darker" />}
    </Fragment>
  );
}

interface DialogContentProps extends DialogHeaderProps {
  children: React.ReactNode;
  desktopWidth?: number;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
}

export function DialogContent(props: DialogContentProps) {
  const {
    children,
    headerTitle,
    headerLeftIcon,
    onHeaderLeftIconPress,
    headerRight,
    borderTopLeftRadius,
    borderTopRightRadius,
    desktopWidth = 460,
    headerRightIcon,
    onHeaderRightIconPress,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props;
  const mq = useMediaQuery();

  return (
    <Container
      color="content"
      flex={1}
      borderTopLeftRadius={borderTopLeftRadius || 16}
      borderTopRightRadius={borderTopRightRadius || 16}
      borderBottomLeftRadius={
        mq.sizeQuery.mdAndUp ? 16 : borderBottomLeftRadius || 0
      }
      borderBottomRightRadius={
        mq.sizeQuery.mdAndUp ? 16 : borderBottomRightRadius || 0
      }
      overflow="hidden"
      width={mq.sizeQuery.mdAndUp ? desktopWidth : "100%"}
    >
      <DialogHeader
        headerLeftIcon={headerLeftIcon}
        headerTitle={headerTitle}
        onHeaderLeftIconPress={onHeaderLeftIconPress}
        headerRight={headerRight}
        headerRightIcon={headerRightIcon}
        onHeaderRightIconPress={onHeaderRightIconPress}
      />
      <Container flex={1}>{children}</Container>
    </Container>
  );
}

interface DialogFooterProps {
  onCancel: () => void;
  onSave: () => void;
  disabled?: boolean;
  loading?: boolean;
  noBorderTop?: boolean;
  noBoxShadow?: boolean;
  paddingBottom?: number;
  testID?: string;
}

export function DialogFooter(props: DialogFooterProps) {
  const {
    onCancel,
    onSave,
    disabled,
    loading,
    noBorderTop,
    noBoxShadow,
    paddingBottom,
    testID,
  } = props;

  return (
    <Container
      borderColor={tokens.colors.neutral.darker}
      borderTopWidth={noBorderTop ? 0 : 1}
      shadow={noBoxShadow ? false : true}
      paddingHorizontal={16}
      paddingTop={8}
      paddingBottom={paddingBottom || 8}
    >
      <Row spacing={8} justifyContent="flex-end">
        <Button
          disabled={loading}
          appearance="secondary"
          onPress={() => onCancel()}
          text="Cancel"
        />
        <Button
          loading={loading}
          disabled={disabled}
          onPress={onSave}
          testID={testID}
          text="Save"
        />
      </Row>
    </Container>
  );
}

interface DialogFooterWrapperProps {
  border?: boolean;
  shadow?: boolean;
  children: React.ReactNode;
  paddingHorizontal?: number;
}

export function DialogFooterWrapper(props: DialogFooterWrapperProps) {
  const { border, shadow, children } = props;

  return (
    <Container
      borderColor={tokens.colors.neutral.darker}
      borderTopWidth={border ? 1 : 0}
      shadow={shadow ? true : false}
      paddingHorizontal={16}
      paddingTop={8}
      paddingBottom={8}
    >
      {children}
    </Container>
  );
}

const styles = StyleSheet.create({
  icon: {
    position: "absolute",
    zIndex: 1,
  },
  headerRight: {
    position: "absolute",
    height: "100%",
    top: 0,
    right: 16,
    zIndex: 1,
  },
});
