import { Divider } from "components/divider";
import { HourDetails } from "components/hour_details";
import { ImageGallery } from "components/image_gallery";
import { ContentHeader } from "components/layout/content_header";
import { PreferredPartnerBanner } from "components/preferred_partner_banner";

import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { Amenities } from "pages/office_space_details/components/amenities";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import { StyleSheet, View } from "react-native";

import { LocationDetailsAvailableSpaces } from "./components/location_details_available_spaces";
import { LocationDetailsMap } from "./components/location_details_map";
import { LocationDetailsSection } from "./components/location_details_section";
import { LocationDetailsProps } from "./location_details_desktop";
import { getPlaceholderLocationImage } from "./utils/get_placeholder_location_image";

const SPACER_SIZE = 16;

export function LocationDetailsMobileContent({
  location,
  dayPass,
  meetingRooms,
  privateOffices,
  spaceTypePolicy,
}: LocationDetailsProps) {
  const mq = useMediaQuery();
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  return (
    <View>
      <View style={styles.withPadding}>
        <ContentHeader
          title={location.name}
          hasBackButton={mq.deviceQuery.desktop}
        />
      </View>
      <Spacer size={SPACER_SIZE} />
      <ImageGallery
        images={
          location.images[0] ? location.images : [getPlaceholderLocationImage()]
        }
        imageName={location.name}
      />
      <View style={styles.withPadding}>
        <Spacer size={SPACER_SIZE} />
        <Divider />
        <Spacer size={SPACER_SIZE} />
        {location.description && (
          <>
            <LocationDetailsSection title="About this space">
              <div
                dangerouslySetInnerHTML={{
                  __html: location.description,
                }}
              />
            </LocationDetailsSection>
            <Spacer size={SPACER_SIZE} />
          </>
        )}
      </View>

      {isPreferredFlag && location.preferred && (
        <>
          <PreferredPartnerBanner
            locationName={`${location.name} is a Preferred Partner`}
          />
          <Spacer size={SPACER_SIZE} />
          <Divider />
          <Spacer size={SPACER_SIZE} />
        </>
      )}

      <View style={styles.withPadding}>
        <LocationDetailsAvailableSpaces
          dayPass={dayPass}
          privateOffices={privateOffices}
          meetingRooms={meetingRooms}
          spaceTypePolicy={spaceTypePolicy}
        />
        <Spacer size={SPACER_SIZE} />
        <Divider />
        <Spacer size={SPACER_SIZE} />
        {location.amenities.length > 0 && (
          <>
            <Amenities
              spaceAmenities={[]}
              locationAmenities={location.amenities}
            />
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
          </>
        )}
        <LocationDetailsSection title="Location">
          <Text size="xs">
            {location.name} is located at {location.address.fullAddress}
          </Text>
        </LocationDetailsSection>
        <Spacer size={SPACER_SIZE} />
        <LocationDetailsMap
          latitude={location.address.latitude || undefined}
          longitude={location.address.longitude || undefined}
        />
        {location.directions && (
          <>
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
            <LocationDetailsSection title="How to get there">
              <div
                dangerouslySetInnerHTML={{
                  __html: location.directions,
                }}
              />
            </LocationDetailsSection>
          </>
        )}
        <Spacer size={SPACER_SIZE} />
        <Divider />
        <Spacer size={SPACER_SIZE} />
        <LocationDetailsSection title="Hours">
          <HourDetails hours={location.bookingHours} />
        </LocationDetailsSection>
        <Spacer size={SPACER_SIZE} />
        <Divider />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  withPadding: {
    paddingHorizontal: SPACER_SIZE,
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
  },
});
