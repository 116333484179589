import { Fragment } from "react";
import { Image } from "react-native";
import { Column } from "components/column";
import { Container } from "components/container";
import { Divider } from "components/divider";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { OffsiteSpacePage__SpaceDetailsV2Fragment } from "core/graphql.generated";

interface SpacePerksProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
}

export function SpacePerks(props: SpacePerksProps) {
  const { space } = props;

  return (
    <Container>
      {space.spacePerks.map((spacePerk, i) => (
        <div key={spacePerk.code}>
          <Row alignItems="flex-start">
            <Image
              source={{
                uri: spacePerk.iconURL,
                width: 32,
                height: 32,
              }}
            />
            <Spacer size={8} />
            <Column flex={1}>
              <Text size="md">{spacePerk.name}</Text>
              <Spacer size={2} />
              <Text size="sm" color="light">
                {spacePerk.description}
              </Text>
            </Column>
          </Row>
          <Spacer size={16} />
          {i !== space.spacePerks.length - 1 && (
            <Fragment>
              <Divider />
              <Spacer size={16} />
            </Fragment>
          )}
        </div>
      ))}
    </Container>
  );
}
