import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { View } from "react-native";
import { useMediaQuery } from "lib/media_query";
import { useEmptyStateGroupAFeatureFlag } from "providers/splitio";
import {
  EmptyStateGroupATile,
  EmptyStateGroupBTile,
} from "../empty_states/empty_state_tiles";

interface EmptySpacesProps {
  onFindMeASpacePress?: () => void;
}

export function EmptySpaces(props: EmptySpacesProps) {
  const { onFindMeASpacePress } = props;
  const emptyStateGroupAFeatureFlag = useEmptyStateGroupAFeatureFlag();

  if (emptyStateGroupAFeatureFlag) {
    return <EmptyStateGroupATile />;
  } else {
    return <EmptySpacesGroupB onFindMeASpacePress={onFindMeASpacePress} />;
  }
}

function EmptySpacesGroupB(props: EmptySpacesProps) {
  const { onFindMeASpacePress } = props;

  const mq = useMediaQuery();

  return (
    <View testID="empty-spaces-container">
      <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
        Explore spaces around the world
      </Text>
      <Spacer size={16} />
      <Text size="xs">
        <b> &middot; </b>
        <Text size="xs" weight="semibold">
          Search
        </Text>{" "}
        for a city, region, or neighborhood
      </Text>
      <Text size="xs">
        <b> &middot; </b>
        <Text size="xs" weight="semibold">
          Move
        </Text>{" "}
        around on the map to explore nearby spaces
      </Text>
      <Text size="xs">
        <b> &middot; </b>
        <Text size="xs" weight="semibold">
          Filter
        </Text>{" "}
        to see workspaces available at specific times or with a specific
        capacity
      </Text>
      <Spacer size={16} />

      <EmptyStateGroupBTile onFindMeASpacePress={onFindMeASpacePress} />
    </View>
  );
}
