import { DateRange } from "core/booking_date_range_picker";
import {
  Bookings__OrderDetailsFragment,
  Bookings__OrderItemDetailsFragment,
  BookingType,
  OrderStatus,
} from "core/graphql.generated";
import { differenceInHours, format } from "date-fns";
import { parseDay } from "lib/day_utils";
import { getSpaceType } from "lib/space_type_utils";

import { parseTime } from "lib/time_utils";

import { UpcomingBookingLocation } from "./components/upcoming_booking_details";

export function convertDateTimeRange(
  dateFormat: string,
  seperator: string,
  dateRange: DateRange,
): { dateRange?: string; timeRange?: string } {
  if (
    dateRange.type === "DailyBooking" &&
    dateRange.startDate &&
    dateRange.endDate
  ) {
    const formatStartDate = format(parseDay(dateRange.startDate), dateFormat);
    const formatEndDate = format(parseDay(dateRange.endDate), dateFormat);
    if (dateRange.startDate === dateRange.endDate) {
      return { dateRange: formatStartDate };
    }
    return { dateRange: `${formatStartDate} ${seperator} ${formatEndDate}` };
  } else if (
    dateRange.type === "HourlyBooking" &&
    dateRange.date &&
    dateRange.startTime &&
    dateRange.endTime
  ) {
    const formatDate = format(parseDay(dateRange.date), dateFormat);
    const formatStartTime = format(parseTime(dateRange.startTime), "h:mm a");
    const formatEndTime = format(parseTime(dateRange.endTime), "h:mm a");
    const timeRange = `${formatStartTime} ${seperator} ${formatEndTime}`;
    return { dateRange: formatDate, timeRange };
  }

  return {};
}

export function getInvitedPeopleTitle(invitedPeople: string[]) {
  let title = "Invite you coworkers to join you";
  if (invitedPeople.length === 1) {
    title = `${invitedPeople[0]} has been invited`;
  }
  if (invitedPeople.length === 2) {
    title = `${invitedPeople[0]} and ${invitedPeople[1]} have been invited`;
  } else if (invitedPeople.length > 2) {
    title = `${invitedPeople[0]} and ${
      invitedPeople.length - 1
    } others have been invited`;
  }

  return title;
}

interface UpcomingBookingDetails {
  orderId: string;
  upcomingInDays: number;
  thumbnailUrl: string;
  dateRange: DateRange;
  spaceName: string;
  spaceType: string;
  capacity: number;
  remainingCapacity: number;
  location: UpcomingBookingLocation;
  spaceUrl: string;
  invitedPeople: string[];
  confirmationId?: string;
  spaceId?: string;
  locationName?: string;
}

export function transformUpcomingBooking(
  booking: Bookings__OrderDetailsFragment,
): UpcomingBookingDetails {
  const orderItem = booking.orderItems[0];

  const { space } = orderItem.details;
  const transformed: UpcomingBookingDetails = {
    orderId: booking.id,
    thumbnailUrl: space.images[0].medium.url,
    upcomingInDays: Math.ceil(
      differenceInHours(getOrderItemStartDate(orderItem), new Date()) / 24,
    ),
    dateRange: getOrderItemDateRange(orderItem),
    spaceName: space.name,
    spaceType: getSpaceType(space.spaceType),
    confirmationId: booking.confirmationID || undefined,
    capacity: space.maxCapacity,
    remainingCapacity: space.maxCapacity - booking.invitees.length,
    invitedPeople: booking.invitees.map((i) => i.name),
    location: {
      latitude: space.location.address.latitude || undefined,
      longitude: space.location.address.longitude || undefined,
      address: space.location.address.streetAddress,
      city: `${space.location.address.city}, ${space.location.address.state}`,
    },
    spaceUrl: `${location.origin}/offsite_spaces/${space.id}`,
    spaceId: space.id,
    locationName: space.location.name,
  };

  return transformed;
}

interface ArchiveBookingDetails {
  thumbnailUrl: string;
  dateRange: DateRange;
  spaceName: string;
  spaceType: string;
  confirmationId?: string;
  isCancelled: boolean;
  canBookAgain: boolean;
  spaceId: string;
}

export function transformArchiveBooking(
  booking: Bookings__OrderDetailsFragment,
): ArchiveBookingDetails {
  const orderItem = booking.orderItems[0];

  const { space } = orderItem.details;
  const transformed: ArchiveBookingDetails = {
    thumbnailUrl: space.images[0].medium.url,
    dateRange: getOrderItemDateRange(orderItem),
    spaceName: space.name,
    spaceType: getSpaceType(space.spaceType),
    confirmationId: booking.confirmationID || undefined,
    isCancelled: booking.status === OrderStatus.Canceled,
    canBookAgain: true,
    spaceId: space.id,
  };

  return transformed;
}

function getOrderItemDateRange(
  orderItem: Bookings__OrderItemDetailsFragment,
): DateRange {
  if (orderItem.details.__typename === "DailyBooking") {
    const { startDate, endDate } = orderItem.details;
    return { type: BookingType.DailyBooking, startDate, endDate };
  } else {
    const { date, startTime, endTime } = orderItem.details;
    return { type: BookingType.HourlyBooking, date, startTime, endTime };
  }
}

export function getOrderItemStartDate(
  orderItem: Bookings__OrderItemDetailsFragment,
): Date {
  if (orderItem.details.__typename === "DailyBooking") {
    return parseDay(orderItem.details.startDate);
  } else {
    return parseDay(orderItem.details.date);
  }
}
