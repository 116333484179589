import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQueryString<T extends object = any>(): T {
  const searchString = useLocation().search;

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(searchString);
    const result: { [key: string]: string } = {};
    const entries = searchParams.entries();

    let current = entries.next();

    while (current.value) {
      const [key, value] = current.value;
      result[key] = value;

      current = entries.next();
    }

    return result;
  }, [searchString]);

  // @ts-ignore
  return params;
}

export function toQueryString(params: { [param: string]: string | undefined }) {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((param) => {
    const value = params[param];

    if (value) {
      searchParams.set(param, value);
    }
  });

  return searchParams.toString();
}

export function concatQueryString(
  queryStrings: (string | undefined | false)[],
) {
  return queryStrings.filter((q) => !!q).join("&");
}
