import { colors } from "components/colors";
import React from "react";
import { BudgetColor } from "../../budgets/budget_colors";
import { ReportingPieChart } from "./pie_chart";

interface ReportingFrequencyProps {
  zeroOrSingleBookingPerWeek: { value: number; percentage: number };
  twoOrThreeBookingsPerWeek: { value: number; percentage: number };
  fourOrMoreBookingsPerWeek: { value: number; percentage: number };
  totalEmployees: number;
  labelUpdatedAt: string;
  color?: BudgetColor;
}

export function ReportingFrequency(props: ReportingFrequencyProps) {
  const {
    zeroOrSingleBookingPerWeek,
    twoOrThreeBookingsPerWeek,
    fourOrMoreBookingsPerWeek,
    totalEmployees,
    labelUpdatedAt,
    color = "other",
  } = props;

  return (
    <ReportingPieChart
      data={[
        {
          label: "0-1 bookings/week",
          value: zeroOrSingleBookingPerWeek.value,
          percentage: zeroOrSingleBookingPerWeek.percentage,
          color: colors.budget[color].core,
        },
        {
          label: "2-3 bookings/week",
          value: twoOrThreeBookingsPerWeek.value,
          percentage: twoOrThreeBookingsPerWeek.percentage,
          color: colors.budget[color].lighter,
        },
        {
          label: "4+ bookings/week",
          value: fourOrMoreBookingsPerWeek.value,
          percentage: fourOrMoreBookingsPerWeek.percentage,
          color: colors.budget[color].lightest,
        },
      ]}
      total={totalEmployees}
      empty={
        zeroOrSingleBookingPerWeek.value === 0 &&
        twoOrThreeBookingsPerWeek.value === 0 &&
        fourOrMoreBookingsPerWeek.value === 0
      }
      emptyColor={colors.budget[color].lightest}
      item="Employees"
      tooltip="Bookings per week are the number of days spaces are booked in weeks starting Sun and ending on Sat."
      labelUpdatedAt={labelUpdatedAt}
      title="Frequency"
    />
  );
}
