import { useApolloClient } from "@apollo/client";
import { Button } from "components/button_v2";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import {
  AuthMethod,
  OrgByDomainQuery,
  OrgByDomainQueryVariables,
} from "core/graphql.generated";
import { useForm } from "hooks/use_form";
import { isEmail } from "lib/string_utils";
import { SourceFrom } from "pages/custom_org_sign_in/custom_org_sign_in";
import { orgByDomainQuery } from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import { useCustomSSOFeatureFlag } from "providers/splitio";
import { Pressable, View } from "react-native";
import { useHistory } from "react-router-dom";

interface ResetPasswordFormProps {
  onComplete: (email: string) => void;
  onGoBack: () => void;
}

interface ResetPasswordCredentials {
  email: string;
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { onComplete, onGoBack } = props;
  const { resetPassword } = useAuthV2();
  const history = useHistory();
  const sSOFeatureFlag = useCustomSSOFeatureFlag();
  const apolloClient = useApolloClient();

  const { values, changed, errors, setFieldValue, submit, submitting } =
    useForm({
      initialValues: { email: "", resetPasswordError: "" },
      validate: ({ email }: ResetPasswordCredentials) => {
        const errors: { [field: string]: string } = {};

        if (!isEmail(email)) {
          errors.email = "Please input a valid email address";
        }

        return errors;
      },
      onSubmit: async (
        { email }: ResetPasswordCredentials,
        { setSubmitting, setErrors }: any,
      ) => {
        setSubmitting(true);

        if (sSOFeatureFlag) {
          const result = await apolloClient.query<
            OrgByDomainQuery,
            OrgByDomainQueryVariables
          >({
            query: orgByDomainQuery,
            variables: {
              domain: email.split("@").pop() || "",
            },
          });

          const orgData = result.data.organizationByDomain;
          const isLoginMethodValid = orgData?.ssoProvider.find(
            (provider) => provider === AuthMethod.Password,
          );
          if (orgData?.requireSSO && orgData.slug && !isLoginMethodValid) {
            history.replace(`/${orgData.slug}`, {
              orgData,
              sourceFrom: SourceFrom.LOG_IN,
            });
          } else {
            try {
              await resetPassword(email);
              onComplete(email);
            } catch (error) {
              setErrors({ resetPasswordError: error });
            } finally {
              setSubmitting(false);
            }
          }
        } else {
          try {
            await resetPassword(email);
            onComplete(email);
          } catch (error) {
            setErrors({ resetPasswordError: error });
          } finally {
            setSubmitting(false);
          }
        }
      },
    });

  return (
    <View>
      <Text>
        Please enter the email address associated with your account. We will
        email you a link to reset your password.
      </Text>
      <Spacer size={16} />
      <TextField
        value={values.email}
        placeholder="Email"
        onChange={(email) => setFieldValue("email", email)}
        invalid={changed.email && !!errors.email}
        invalidText={changed.email ? errors.email : ""}
        onSubmitEditing={submit}
      />
      <Spacer size={24} />
      {errors.resetPasswordError && (
        <>
          <Text color="grapefruit-core">{errors.resetPasswordError}</Text>
          <Spacer size={8} />
        </>
      )}
      <Button text="Send Link" onPress={submit} loading={submitting} />
      <Spacer size={24} />
      <Text size="sm">Remembered your password?</Text>
      <Spacer size={4} />
      <Pressable onPress={onGoBack}>
        <Text weight="bold" size="sm" color="eggplant-core">
          Log in
        </Text>
      </Pressable>
    </View>
  );
}
