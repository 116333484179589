import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";

export function NextBookingSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={267}
      backgroundColor="#f7f7f7"
      foregroundColor="#ecebeb"
    >
      <Rect width={"100%"} height={267} />
    </ContentLoader>
  );
}
