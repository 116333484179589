import { Amenity } from "core/graphql.generated";

export const sortAmenities = (
  spaceAmenities: Amenity[] = [],
  locationAmenities: Amenity[] = [],
  paramsAmenities: string[] = [],
) => {
  const res: Amenity[] = [];
  const amenities = [
    ...spaceAmenities.filter((am) => am.code),
    ...spaceAmenities.filter((am) => !am.code),
    ...locationAmenities.filter((am) => am.code),
    ...locationAmenities.filter((am) => !am.code),
  ];
  // show searched amenities with priority
  amenities.forEach((amenity) => {
    if (amenity.code && paramsAmenities.includes(amenity.code)) {
      res.unshift(amenity);
    } else {
      res.push(amenity);
    }
  });

  return res;
};
