import { Button } from "components/button_v2";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { colors } from "../colors";
import { Icon } from "../iconv2";
import { CSVProps } from "./export_csv";

const ExportCSV = ({ data, filename, onExport }: CSVProps) => {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const handleExportCSV = () => {
    const tableHeaders = Object.keys(data[0]).join(",");
    const csv =
      tableHeaders +
      "\n" +
      data.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${
        (filename || "").replace(/\W+/g, "_").trim().toLowerCase() || "export"
      }_${new Date().toISOString()}.csv`,
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    !!onExport && onExport();
  };

  if (!data || data.length === 0) {
    return null;
  }

  if (isMobile) {
    return (
      <Pressable
        style={styles.buttonMobile}
        onPress={handleExportCSV}
        disabled={!data || data.length === 0}
      >
        <Icon
          name={"share"}
          size={"md"}
          color={!data || data.length === 0 ? "blackMinus80" : "eggplantcore"}
        />
      </Pressable>
    );
  }

  return (
    <Button
      onPress={handleExportCSV}
      disabled={!data || data.length === 0}
      appearance={"secondary"}
      iconName={"share"}
      text={"Export"}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    width: 102,
    paddingHorizontal: 16,
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: colors.brand.eggplantcore,
    borderRadius: 4,
  },
  buttonMobile: {
    justifyContent: "center",
  },
});
export default ExportCSV;
