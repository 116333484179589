import { View } from "react-native";
import ContentLoader, { Rect } from "react-content-loader/native";
import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";

export function OrganizationProfileSkeleton() {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return (
      <View>
        <ContentLoader
          speed={2}
          width="100%"
          height={270}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="270px" />
        </ContentLoader>
      </View>
    );
  }
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={270}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="270px" />
      </ContentLoader>
      <Spacer size={40} />
      <ContentLoader
        speed={2}
        width="100%"
        height={90}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="90px" />
      </ContentLoader>
    </View>
  );
}
