import { useMutation } from "@apollo/client";
import { Button } from "components/button_v2";
import { TextInput } from "components/text_input";
import { Text } from "components/text_v2";
import { updateOrderMemoMutation } from "core/booking_preview";
import {
  UpdateOrderMemoMutation,
  UpdateOrderMemoMutationVariables,
} from "core/graphql.generated";
import { useAnalytics } from "providers/analytics";
import { useToast } from "providers/toast";
import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

type EditMemoProps = {
  memo: string;
  currentOrderId: string;
  refetch: () => void;
};
export const EditMemo = ({ memo, currentOrderId, refetch }: EditMemoProps) => {
  const [newMemo, setNewMemo] = useState("");
  const [editMemoVisible, setEditMemoVisible] = useState(false);
  const toast = useToast();
  const analytics = useAnalytics();
  const [updateOrderMemo] = useMutation<
    UpdateOrderMemoMutation,
    UpdateOrderMemoMutationVariables
  >(updateOrderMemoMutation);

  const onSavedMemo = async () => {
    try {
      await updateOrderMemo({
        variables: { orderID: currentOrderId, memo: newMemo },
      });
      analytics.event("Update transaction", {
        memo: !!newMemo,
      });
      toast.notify({ message: "Memo successfully saved" });
      await refetch();
    } catch {
      toast.notify({ message: "Error happened! Please try later." });
    } finally {
      setEditMemoVisible(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <Text size={"xs"} weight={"semibold"}>
          Memo
        </Text>
        <Pressable onPress={() => setEditMemoVisible((value) => !value)}>
          <Text size={"xs"} weight={"semibold"} color={"eggplant-core"}>
            {editMemoVisible ? "Cancel" : "Edit"}
          </Text>
        </Pressable>
      </View>
      {editMemoVisible ? (
        <TextInput
          name="memo"
          value={newMemo || ""}
          placeholder={"What was this booking for?"}
          onChange={setNewMemo}
          numberOfLines={2}
        />
      ) : (
        <Text size={"xs"} color={"black-50"}>
          {memo || "No memo added yet"}
        </Text>
      )}

      {editMemoVisible && (
        <View style={styles.saveButton}>
          <Button
            appearance={"secondary"}
            onPress={onSavedMemo}
            text={"Save"}
            size={"fit"}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    gap: 12,
  },
  labelContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  saveButton: {
    alignSelf: "flex-end",
  },
});
