import React, { Fragment, useCallback } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { colors } from "../colors";

import { Spacer } from "../spacer";
import { Text } from "../text_v2";
import { TabProps, TabsProps } from "./tabs";

export function Tabs<T>(props: TabsProps<T>) {
  const { options, value, onChange, spacing = 0, fitContent } = props;

  return (
    <View style={styles.container}>
      {options.map((option, index) => (
        <Fragment key={option.label}>
          <Tab
            testID={option.testID}
            fitContent={fitContent}
            onSelect={onChange}
            option={option}
            active={option.value === value}
          />
          {spacing !== 0 && index !== options.length - 1 && (
            <Spacer size={spacing} />
          )}
        </Fragment>
      ))}
    </View>
  );
}

function Tab<T>(props: TabProps<T>) {
  const { active, option, onSelect, testID, fitContent } = props;

  const handlePress = useCallback(() => {
    onSelect(option.value);
  }, [onSelect, option]);

  return (
    <Pressable
      style={[
        styles.tab,
        fitContent && styles.fitContent,
        active ? styles.active : styles.default,
      ]}
      testID={testID}
      onPress={handlePress}
    >
      <Text
        numberOfLines={1}
        size={"xs"}
        weight={active ? "semibold" : "regular"}
        customColor={active ? "black-core" : "black-30"}
      >
        {option.label}
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: colors.brand.blackMinus90,
    flexDirection: "row",
    gap: 32,
  },
  tab: {
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  fitContent: {
    flex: undefined,
  },
  active: {
    borderBottomWidth: 1,
    borderBottomColor: colors.brand.blackcore,
  },
  default: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
});
