import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { FilterOpenStateProvider } from "hooks/use_filter_open";
import { AnalyticsProvider } from "providers/analytics";
import { AppLoader } from "providers/app_loader";
import { AuthLoaderWrapper } from "providers/auth_loader_wrapper";
import { AuthProviderV2 } from "providers/authv2";
import { appConfig, ConfigProvider } from "providers/config";
import { GraphQLV2Provider } from "providers/graphqlv2";
import { SentryProvider } from "providers/sentry";
import { SplitProvider } from "providers/splitio";
import { SWRProvider } from "providers/swr";
import { ToastProvider } from "providers/toast";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { RoutesV2 } from "routesv2";
import { ErrorBoundary } from "./core/error_boundary";

Sentry.init({
  dsn: appConfig.sentryDSN,
  environment: appConfig.environment,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
});

export const App = Sentry.withProfiler(() => {
  return (
    <RecoilRoot>
      <ErrorBoundary>
        <BrowserRouter>
          <ConfigProvider>
            <SentryProvider>
              <ToastProvider>
                <AuthProviderV2>
                  <GraphQLV2Provider>
                    <SplitProvider>
                      <AnalyticsProvider>
                        <SWRProvider>
                          <AppLoader>
                            <AuthLoaderWrapper>
                              <FilterOpenStateProvider>
                                <RoutesV2 />
                              </FilterOpenStateProvider>
                            </AuthLoaderWrapper>
                          </AppLoader>
                        </SWRProvider>
                      </AnalyticsProvider>
                    </SplitProvider>
                  </GraphQLV2Provider>
                </AuthProviderV2>
              </ToastProvider>
            </SentryProvider>
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </RecoilRoot>
  );
});
