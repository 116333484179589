import { View, StyleSheet } from "react-native";

import { Text } from "components/text_v2";
import { Divider } from "components/divider";

import { Checkbox } from "components/checkbox";

interface Option {
  id: string;
  isSelected: boolean;
  label: string;
  testId?: string;
  renderLabel?: () => JSX.Element;
}

interface MultiCheckboxPropTypes {
  options: Option[];
  onChange: (value: Option[]) => void;
  title?: string;
  error?: string;
  selectAllTestId?: string;
  selectAllLabel?: string;
}

export function MultiCheckbox({
  title,
  options,
  onChange,
  error,
  selectAllTestId,
  selectAllLabel = "All types",
}: MultiCheckboxPropTypes) {
  const renderSelection = () => {
    const handleSelect = (id: string, isSelected: boolean) => {
      const updated = [...options];
      const index = updated.findIndex((e) => e.id === id);
      updated[index].isSelected = isSelected;
      onChange(updated);
    };

    return options.map(({ id, label, isSelected, renderLabel, testId }) => (
      <Checkbox
        key={id}
        appearance="box"
        label={label}
        value={isSelected}
        testId={testId}
        onChange={(value) => handleSelect(id, value)}
        renderLabel={renderLabel}
      />
    ));
  };

  const isAllTypesSelect = !options.some((o) => o.isSelected === false);

  const handleSelectAllType = (isSelected: boolean) => {
    const updated = [...options];
    if (isSelected) {
      updated.forEach((e) => (e.isSelected = true));
    } else {
      updated.forEach((e) => (e.isSelected = false));
    }
    onChange(updated);
  };

  return (
    <View>
      {title && (
        <Text size="xs" weight="semibold">
          {title}
        </Text>
      )}
      <View>
        <Checkbox
          label={selectAllLabel}
          appearance="box"
          testId={selectAllTestId}
          value={isAllTypesSelect}
          onChange={(value) => handleSelectAllType(value)}
        />
        {error && (
          <View style={styles.errorWrapper}>
            <Text color="grapefruit-core" size="sm">
              {error}
            </Text>
          </View>
        )}
        <View style={styles.dividerWrapper}>
          <Divider />
        </View>
        <View style={styles.childSelectWrapper}>{renderSelection()}</View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  childSelectWrapper: {
    paddingLeft: 14,
  },
  dividerWrapper: {
    marginTop: 12,
  },
  errorWrapper: {
    marginTop: 5,
  },
});
