import { gql, useMutation } from "@apollo/client";
import {
  ApproveBookingRequestMutation,
  ApproveBookingRequestMutationVariables,
  DeclineBookingRequestMutation,
  DeclineBookingRequestMutationVariables,
} from "core/graphql.generated";
import { useQueryString } from "lib/query_string";
import { useAnalytics } from "providers/analytics";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";

import { useToast } from "providers/toast";
import { useEffect, useState } from "react";
import { ReviewStatus } from "../components/booking_request_table_raw";

import { DeclineRequestDialog } from "../components/decline_request_dialog";

interface HandleReviewInput {
  status: ReviewStatus;
  declinedUsername?: string;
}

export type HandleReview = ({
  status,
  declinedUsername,
}: HandleReviewInput) => Promise<void>;

const useAutoApproveBookingRequest = (
  requestId: string,
  handleReview: HandleReview,
) => {
  const { approveRequestId } = useQueryString<{ approveRequestId: string }>();

  const [hasApproved, setHasApproved] = useState(false);

  useEffect(() => {
    if (!hasApproved && approveRequestId === requestId) {
      setHasApproved(true);
      (async () => {
        await handleReview({ status: ReviewStatus.Approving });
      })();
    }
  }, [handleReview, hasApproved, approveRequestId, requestId]);
};

export const useBookingRequestReview = (
  requestId: string,
  refetch: () => void,
) => {
  const toast = useToast();
  const analytics = useAnalytics();

  const [reviewStatus, setReviewStatus] = useState<ReviewStatus | null>(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [declinedUsername, setDeclinedUsername] = useState("");

  const [approveBookingRequest, { loading: loadingApproveRequest }] =
    useMutation<
      ApproveBookingRequestMutation,
      ApproveBookingRequestMutationVariables
    >(appoveBookingRequestMutation);

  const [declineBookingRequest, { loading: loadingDeclineRequest }] =
    useMutation<
      DeclineBookingRequestMutation,
      DeclineBookingRequestMutationVariables
    >(declineBookingRequestMutation);

  const handleReview = async ({
    status,
    declinedUsername,
  }: HandleReviewInput) => {
    if (status === ReviewStatus.Approving) {
      analytics.event("Approve Booking Request", {
        category: "Approval Workflow",
      });

      try {
        await approveBookingRequest({
          variables: { input: { id: requestId } },
        });
        setReviewStatus(status);
        toast.notify({
          message:
            "The request has been successfully approved. The employee will be notified.",
        });
        refetch();
      } catch (e) {
        const messageError = extractFirstGraphQLErrorMessage(e);
        if (
          messageError !== "unable to approve or decline non-pending requests"
        ) {
          setReviewStatus(null);
          toast.notify({
            message: "Error happened! Please try again!",
          });
        }
      }
    } else if (status === ReviewStatus.Declining && declinedUsername) {
      setOpenDeclineModal(true);
      setDeclinedUsername(declinedUsername);
    }
  };

  useAutoApproveBookingRequest(requestId, handleReview);

  const handleClickDecline = async (reason: string) => {
    analytics.event("Decline Booking Request", {
      category: "Approval Workflow",
    });

    setOpenDeclineModal(false);
    try {
      await declineBookingRequest({
        variables: { input: { id: requestId, reason } },
      });
      setReviewStatus(ReviewStatus.Declining);
      toast.notify({
        message:
          "The request has been successfully declined. The employee will be notified.",
      });
      refetch();
    } catch {
      setReviewStatus(null);
      toast.notify({
        message: "Error happened! Please try again!",
      });
    }
  };

  const decliningDialog = (
    <DeclineRequestDialog
      isVisible={openDeclineModal}
      onClose={() => setOpenDeclineModal(false)}
      username={declinedUsername}
      onClickDecline={handleClickDecline}
    />
  );

  return {
    handleReview,
    decliningDialog,
    reviewStatus,
    loadingApproveRequest,
    loadingDeclineRequest,
  };
};

const appoveBookingRequestMutation = gql`
  mutation ApproveBookingRequest($input: UpdateBookingRequestStatusInput!) {
    approveBookingRequest(input: $input) {
      id
    }
  }
`;

const declineBookingRequestMutation = gql`
  mutation DeclineBookingRequest($input: UpdateBookingRequestStatusInput!) {
    declineBookingRequest(input: $input) {
      id
    }
  }
`;
