import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  TeamsLocationDetailsFragment,
  TeamsSpaceDetailsFragment,
  TeamsUserDetailsFragment,
} from "core/graphql.generated";
import { useAnalytics } from "providers/analytics";
import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";

interface TeamMapPreviewCardCardProps {
  space?: TeamsSpaceDetailsFragment;
  peoples: TeamsUserDetailsFragment[];
  setLocationPreviewData: (location: TeamsLocationDetailsFragment) => void;
}

export function TeamMapPreviewCard(props: TeamMapPreviewCardCardProps) {
  const { space, peoples, setLocationPreviewData } = props;
  const analytics = useAnalytics();
  const onPress = useCallback(() => {
    if (space) {
      setLocationPreviewData(space.location);
      analytics.event("Click Explore Spaces Here", {
        Category: "Where is everyone",
        Source: "Map",
        "Space ID": space.id,
        "Space Name": space.name,
      });
    }
  }, [space, setLocationPreviewData, analytics]);
  return (
    <View style={styles.container}>
      <View style={styles.titleView}>
        <Text size={"xs"} weight={"semibold"}>
          {peoples
            .slice(0, 2)
            .map((p, index) =>
              index === 0 ? p.fullName : ` and ${p.fullName}`,
            )}
        </Text>
        <Text color={"black-70"} size={"xs"}>
          {space ? space?.name : peoples[0].city?.name}
        </Text>
      </View>

      {space ? (
        <Button
          onPress={onPress}
          text={"Explore spaces here"}
          iconPosition={"right"}
          iconName={"chevron-right"}
          appearance={"textLink"}
        />
      ) : (
        <Spacer size={16} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 32,
    left: -120,
    zIndex: 1,
    backgroundColor: colors.brand.whitecore,
    boxShadow: "0px 1px 10px rgba(6, 0, 30, 0.1)",
    borderRadius: 4,
    alignItems: "flex-start",
    width: 262,
  },
  titleView: {
    paddingTop: 16,
    gap: 4,
    paddingHorizontal: 16,
  },
});
