import { DialogModal } from "components/dialog_modal";
import { Icon } from "components/iconv2";
import { SelectButtonWithIcon } from "components/select/select_button_with_icon";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { SpaceType, SpaceTypes } from "core/graphql.generated";
import { SpaceTypeMobileCard } from "pages/homev2/components/space_type_mobile_card";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "pages/homev2/hooks/use_home_search_params";
import { SPACE_TYPE_TAB_LOCALSTORAGE_KEY } from "pages/homev2/hooks/use_initial_home_search_params";
import { useReBookFeatureFlag } from "providers/splitio";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Pressable, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";

interface PropsType {
  spacePolicy?: SpaceTypes;
  linkToSearchPage?: boolean;
}

export function HeaderSpaceTypePickerMobile({
  spacePolicy,
  linkToSearchPage = false,
}: PropsType) {
  const history = useHistory();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const { spaceType } = useHomeSearchParamsQuery();
  const reBookFeatureFlag = useReBookFeatureFlag();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onButtonPress = useCallback(() => {
    setVisible(true);
  }, []);

  const onCardPress = useCallback(
    (type: SpaceType | null) => {
      if (linkToSearchPage) {
        if (linkToSearchPage) {
          history.push(`/search${location.search}`);
        }
      }
      updateHomeSearchParams({
        spaceType: type,
        startTime: null,
        endTime: null,
        minCapacity: null,
        maxCapacity: null,
        amenities: null,
        page: null,
      });
      onClose();
    },
    [
      history,
      linkToSearchPage,
      location.search,
      onClose,
      updateHomeSearchParams,
    ],
  );

  const title = useMemo(
    () =>
      spaceType === SpaceType.MeetingRoom
        ? "Meeting room"
        : spaceType === SpaceType.DayOffice
        ? "Private office"
        : spaceType === SpaceType.DayPass
        ? "Day pass"
        : "Any space type",
    [spaceType],
  );

  const handleClear = () => {
    onCardPress(null);
    localStorage.setItem(SPACE_TYPE_TAB_LOCALSTORAGE_KEY, "");
  };

  return (
    <Fragment>
      <DialogModal
        onClose={onClose}
        isVisible={visible}
        headerTitle="Select space type"
        bottomButtons={
          reBookFeatureFlag &&
          !!spaceType && (
            <Pressable onPress={handleClear}>
              <Text color="eggplant-core" size="xs" weight="semibold">
                Clear
              </Text>
            </Pressable>
          )
        }
      >
        <View>
          <Spacer size={16} />
          <SpaceTypeMobileCard
            type={SpaceType.DayPass}
            selected={spaceType === SpaceType.DayPass}
            title="Day Passes"
            content="Access to shared workspaces and amenities for the day."
            uri="/images/daypasses.png"
            onCardPress={onCardPress}
            testID={`select-option-${SpaceType.DayPass}`}
            outOfPolicy={spacePolicy && !spacePolicy.dayPass}
          />
          <Spacer size={16} />
          <SpaceTypeMobileCard
            type={SpaceType.DayOffice}
            selected={spaceType === SpaceType.DayOffice}
            title="Private Offices"
            content="Full-day private offices for just you or your whole team."
            uri="/images/dayoffices.png"
            onCardPress={onCardPress}
            testID={`select-option-${SpaceType.DayOffice}`}
            outOfPolicy={spacePolicy && !spacePolicy.privateOffice}
          />
          <Spacer size={16} />
          <SpaceTypeMobileCard
            type={SpaceType.MeetingRoom}
            selected={spaceType === SpaceType.MeetingRoom}
            title="Meeting Rooms"
            content="Rooms available by the hour for collaborating with others."
            uri="/images/meetingrooms.png"
            onCardPress={onCardPress}
            testID={`select-option-${SpaceType.MeetingRoom}`}
            outOfPolicy={spacePolicy && !spacePolicy.meetingRoom}
          />
        </View>
      </DialogModal>
      <SelectButtonWithIcon
        testID="header-space-type-picker"
        onPress={onButtonPress}
        title={title}
        leftIcon={<Icon name="space-search" size="sm" />}
      />
    </Fragment>
  );
}
