import { makeVar, ReactiveVar, useQuery } from "@apollo/client";
import {
  SavedLocationsQuery,
  SavedLocationsQueryVariables,
  SavedSpacesQuery,
  SavedSpacesQueryVariables,
} from "core/graphql.generated";
import {
  savedLocationsQuery,
  savedSpacesQuery,
} from "pages/saved_places/hooks/use_saved_places_query";
import { useEffect } from "react";

export const favoriteSpaces: ReactiveVar<string[]> = makeVar<string[]>([]);
export const favoriteLocations: ReactiveVar<string[]> = makeVar<string[]>([]);

// this will be for loader and will update reactive var from the start of the app
// so that we will be able to use this array for comparison
export const useFavoriteIdsVariable = (authenticated: boolean) => {
  const {
    data: savedSpacesData,
    loading: savedSpacesLoading,
    error: savedSpacesError,
  } = useQuery<SavedSpacesQuery, SavedSpacesQueryVariables>(savedSpacesQuery, {
    skip: !authenticated,
    fetchPolicy: "network-only",
  });

  const {
    data: savedLocationsData,
    loading: savedLocationsLoading,
    error: savedLocationsError,
  } = useQuery<SavedLocationsQuery, SavedLocationsQueryVariables>(
    savedLocationsQuery,
    {
      skip: !authenticated,
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (savedSpacesData && !savedSpacesError && !savedSpacesLoading) {
      favoriteSpaces([
        ...(savedSpacesData.currentUser?.favoriteSpaces?.map(
          (space) => space.id,
        ) || []),
      ]);
    }
  }, [savedSpacesData, savedSpacesError, savedSpacesLoading]);

  useEffect(() => {
    if (!savedLocationsLoading && savedLocationsData && !savedLocationsError) {
      favoriteLocations([
        ...(savedLocationsData.currentUser?.favoriteLocations?.map(
          (location) => location.id,
        ) || []),
      ]);
    }
  }, [savedLocationsData, savedLocationsError, savedLocationsLoading]);
};
