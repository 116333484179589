import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { colors } from "components/colors";
import { Row } from "components/row";
import { Text } from "components/text_v2";
import { Icon, IconName } from "components/icon";
import { LinkWrapper } from "components/link_wrapper";

export interface DropdownMenuItemProps {
  label: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  iconName?: IconName;
  testID?: string;
  color?: string;
  linkTo?: string;
}

export function DropdownMenuItem(props: DropdownMenuItemProps) {
  const {
    label,
    onClick,
    disabled,
    iconName,
    testID,
    color = colors.brand.blackcore,
    linkTo,
  } = props;

  if (linkTo) {
    return (
      <LinkWrapper testID={testID} onPress={onClick} to={linkTo}>
        <View style={[styles.option]}>
          <View style={styles.row}>
            <Text
              size="sm"
              customColor={disabled ? colors.brand.blackMinus30 : color}
            >
              {label}
            </Text>
            {iconName && (
              <View style={styles.icon}>
                <Icon name={iconName} />
              </View>
            )}
          </View>
        </View>
      </LinkWrapper>
    );
  }

  return (
    <Pressable testID={testID} onPress={onClick} disabled={disabled}>
      <View style={[styles.option]}>
        <Row alignItems="center" justifyContent="space-between">
          <Text
            size="sm"
            customColor={disabled ? colors.brand.blackMinus30 : color}
          >
            {label}
          </Text>
          {iconName && (
            <View style={styles.icon}>
              <Icon name={iconName} />
            </View>
          )}
        </Row>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  option: {
    paddingVertical: 17,
    paddingHorizontal: 16,
  },
  icon: {
    marginLeft: 8,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
