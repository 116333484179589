import { useEffect, useState } from "react";
import { Text } from "components/text_v2";
import "./chips_input.css";
import { Icon } from "./icon";
import { unique } from "lib/array_utils";

interface PropTypes {
  initialValue?: string[];
  value?: string[];
  onChange?: (val: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
}

export const ChipsInput = (props: PropTypes) => {
  const { initialValue, value = [], onChange, disabled, placeholder } = props;
  const [chips, setChips] = useState<string[]>(initialValue || []);
  const [currentText, setCurrentText] = useState<string>("");
  const removeChipByIndex = (index: number) => {
    const newChips = [...chips.slice(0, index), ...chips.slice(index + 1)];
    setChips(newChips);
    onChange?.(newChips);
  };

  // if no initialValue provided, then this input becomes controlled
  useEffect(() => {
    if (!initialValue) {
      setChips(value);
    }
  }, [initialValue, value]);

  return (
    <div className="chips-input__wrapper">
      {chips.filter(Boolean).map((chip, chipIndex) => (
        <div className="chips-input__chip" key={`${chip}-${chipIndex}`}>
          <Text size="xs">{chip}</Text>{" "}
          <button
            className="chips-input__chip-delete-btn"
            onClick={() => {
              if (!disabled) {
                removeChipByIndex(chipIndex);
              }
            }}
          >
            <Icon name="x" size={16} />
          </button>
        </div>
      ))}
      <form
        className="chips-input__form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const newChips = unique([...chips, currentText]);
          setChips(newChips);
          onChange?.(newChips);
          setCurrentText("");
        }}
      >
        <input
          disabled={disabled}
          className="chips-input__input"
          type="text"
          value={currentText}
          onChange={(e) => {
            if (e.target.value.includes(",") || e.target.value.includes(" ")) {
              const formattedPaste = formatPaste(e.target.value);
              const newChips = unique([...chips, ...formattedPaste]);
              setChips(newChips);
              onChange?.(newChips);
              setTimeout(() => {
                setCurrentText("");
              }, 0);
              return;
            }
            setCurrentText(e.target.value);
          }}
          onBlur={() => {
            if (currentText?.trim()?.length) {
              const newChips = unique([...chips, ...[currentText.trim()]]);
              setChips(newChips);
              onChange?.(newChips);
              setTimeout(() => {
                setCurrentText("");
              }, 0);
            }
          }}
          placeholder={
            !chips.length || chips.every((chip) => !chip) ? placeholder : ""
          }
          onPaste={(e) => {
            const formattedPaste = formatPaste(e.clipboardData.getData("text"));
            const newChips = unique([...chips, ...formattedPaste]);
            setChips(newChips);
            onChange?.(newChips);
            setTimeout(() => {
              setCurrentText("");
            }, 0);
          }}
        />
      </form>
    </div>
  );
};

function formatPaste(text: string) {
  // split by space or comma and trim
  return text
    .split(/[, ]+/)
    .map((text) => text.trim())
    .filter(Boolean);
}
