import React, { useCallback, useState } from "react";

interface HoverableProps {
  children: React.ReactNode | ((hovered: boolean) => React.ReactNode);
  /** If true wraps the child with a `div` node that detects hover instead */
  useWrapper?: boolean;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
}

export function Hoverable(props: HoverableProps) {
  const { children, onHoverIn, onHoverOut, useWrapper = false } = props;
  const [state, setState] = useState({ hovered: false, showHover: true });
  const { hovered, showHover } = state;

  const handleMouseEnter = useCallback(() => {
    if (isHoverEnabled() && !hovered) {
      if (onHoverIn !== undefined) {
        onHoverIn();
      }
      setState({
        showHover,
        hovered: true,
      });
    }
  }, [showHover, hovered, onHoverIn]);

  const handleMouseLeave = useCallback(() => {
    if (hovered) {
      if (onHoverOut !== undefined) {
        onHoverOut();
      }
      setState({
        showHover,
        hovered: false,
      });
    }
  }, [hovered, showHover, onHoverOut]);

  const handleGrant = useCallback(() => {
    setState({ showHover: false, hovered });
  }, [hovered]);

  const handleRelease = useCallback(() => {
    setState({ showHover: true, hovered });
  }, [hovered]);

  const child =
    typeof children === "function" ? children(showHover && hovered) : children;

  if (useWrapper) {
    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {child}
      </div>
    );
  }

  return React.cloneElement(React.Children.only(child), {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    // prevent hover showing while responder
    onResponderGrant: handleGrant,
    onResponderRelease: handleRelease,
    // if child is Pressable
    onPressIn: handleGrant,
    onPressOut: handleRelease,
  });
}

export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

let isEnabled = false;

/**
 * Web browsers emulate mouse events (and hover states) after touch events.
 * This code infers when the currently-in-use modality supports hover
 * (including for multi-modality devices) and considers "hover" to be enabled
 * if a mouse movement occurs more than 1 second after the last touch event.
 * This threshold is long enough to account for longer delays between the
 * browser firing touch and mouse events on low-powered devices.
 */
const HOVER_THRESHOLD_MS = 1000;
let lastTouchTimestamp = 0;

function enableHover() {
  if (isEnabled || Date.now() - lastTouchTimestamp < HOVER_THRESHOLD_MS) {
    return;
  }
  isEnabled = true;
}

function disableHover() {
  lastTouchTimestamp = Date.now();
  if (isEnabled) {
    isEnabled = false;
  }
}

if (canUseDOM) {
  document.addEventListener("touchstart", disableHover, true);
  document.addEventListener("touchmove", disableHover, true);
  document.addEventListener("mousemove", enableHover, true);
}

export function isHoverEnabled(): boolean {
  return isEnabled;
}
