import { AsyncState } from "hooks/use_async_fn";
import { useCallback } from "react";
import { MapboxFeature } from "../mapbox";
import {
  useCurrentUserGeoCoordinatesQuery,
  useCurrentUserGeoCoordinatesLazyQuery,
} from "./use_current_user_geo_coordinates";
import {
  useReverseGeocodingLazyQuery,
  useReverseGeocodingQuery,
} from "./use_reserve_geocoding";

export function useCurrentUserGeoPlaceQuery() {
  const {
    data: geoCoordinates,
    loading: geoCoordinatesLoading,
    error: geoCoordinatesError,
  } = useCurrentUserGeoCoordinatesQuery();

  const {
    data: place,
    loading: placeLoading,
    error: placeError,
  } = useReverseGeocodingQuery(geoCoordinates);

  return {
    data: place,
    loading: geoCoordinatesLoading || placeLoading,
    error: geoCoordinatesError || placeError,
  };
}

export function useCurrentUserGeoPlaceLazyQuery(): [
  () => Promise<MapboxFeature>,
  AsyncState<MapboxFeature>,
] {
  const requestCurrentUserGeoCoordinates =
    useCurrentUserGeoCoordinatesLazyQuery();
  const [reverseGeocodingQuery, state] = useReverseGeocodingLazyQuery();

  const request = useCallback(async () => {
    return requestCurrentUserGeoCoordinates().then((coordinates) => {
      return reverseGeocodingQuery(coordinates);
    });
  }, [reverseGeocodingQuery, requestCurrentUserGeoCoordinates]);

  return [request, state];
}
