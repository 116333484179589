import { Spacer } from "components/spacer";
import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";

export function ListSpacesSkeleton() {
  return (
    <>
      <Spacer size={12} />
      <ContentLoader
        speed={2}
        width={"100%"}
        height={302}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={"100%"} height={302} />
      </ContentLoader>
      <Spacer size={28} />
      <ContentLoader
        speed={2}
        width={"100%"}
        height={302}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={"100%"} height={302} />
      </ContentLoader>
      <Spacer size={8} />
    </>
  );
}
