import { colors } from "components/colors";
import { Cell } from "components/data_table/components_v2/cell";
import { Row } from "components/data_table/components_v2/row";
import { Column, DataTableRow } from "components/data_table/data_table_base";

import { Text } from "components/text_v2";
import { useQueryString } from "lib/query_string";
import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";

import { useBookingRequestReview } from "../hooks/use_booking_request_review";

export enum ReviewStatus {
  Approving,
  Declining,
}

interface BookingRequestRow extends DataTableRow {
  extension: JSX.Element;
}

interface BookingRequestDataTablePropsType {
  columns: Column[];
  rows: BookingRequestRow[];
}

interface BookingRequestTableRowPropsType {
  row: BookingRequestRow;
  columns: Column[];
}

const BookingRequestTableRow: React.FC<BookingRequestTableRowPropsType> = ({
  row,
  columns,
}) => {
  const {
    handleReview,
    decliningDialog,
    reviewStatus,
    loadingApproveRequest,
    loadingDeclineRequest,
  } = useBookingRequestReview(row.id, row.actions.refetch);

  const { detailedRequestId, approveRequestId } = useQueryString<{
    detailedRequestId: string;
    approveRequestId: string;
  }>();

  const [extendRow, setExtendRow] = useState(
    detailedRequestId === row.id || approveRequestId === row.id,
  );

  return (
    <View
      style={[
        extendRow && styles.extendRowWrapper,
        reviewStatus === ReviewStatus.Approving && styles.approvingRow,
        reviewStatus === ReviewStatus.Declining && styles.decliningRow,
      ]}
    >
      <Row noHover>
        {columns.map((column) => {
          const rowItem = row[column.id];

          return (
            <Cell width={column.width} key={`${row.id}-${column.id}`}>
              <View style={styles.cellWrapper}>
                {rowItem.render ? (
                  rowItem.render({
                    onChangeExtend: () => setExtendRow((value) => !value),
                    isExtended: extendRow,
                    onReview: handleReview,
                    loadingApproveRequest,
                    loadingDeclineRequest,
                  })
                ) : (
                  <Text customColor={colors.brand.blackcore} size="xs">
                    {rowItem}
                  </Text>
                )}
              </View>
            </Cell>
          );
        })}
      </Row>
      {extendRow && <Row noHover>{row.extension}</Row>}
      {decliningDialog}
    </View>
  );
};

export const BookingRequestTableRaw: React.FC<
  BookingRequestDataTablePropsType
> = (props) => {
  const { rows, columns } = props;

  const renderTableHeader = useCallback(
    () => (
      <Row isHeader>
        {columns.map((column) => (
          <Cell key={`th-${column.id}`} isHeader width={column.width}>
            {column.renderTitle ? (
              column.renderTitle(column)
            ) : (
              <Text
                customColor={colors.brand.blackMinus30}
                size="xs"
                weight="semibold"
              >
                {column.title}
              </Text>
            )}
          </Cell>
        ))}
      </Row>
    ),
    [columns],
  );

  const renderTableRows = useCallback(
    () =>
      rows.map((row) => (
        <BookingRequestTableRow key={row.id} row={row} columns={columns} />
      )),
    [rows, columns],
  );

  return (
    <View style={styles.tableWrapper}>
      {renderTableHeader()}
      {renderTableRows()}
    </View>
  );
};

const styles = StyleSheet.create({
  tableWrapper: {
    backgroundColor: colors.brand.whitecore,
    paddingVertical: 24,
    borderRadius: 4,
  },
  extendRowWrapper: {
    backgroundColor: colors.brand.eggplantMinus90,
    borderBottomWidth: 1,
    borderColor: colors.brand.eggplantMinus70,
  },
  approvingRow: {
    backgroundColor: colors.brand.spinachMinus90,
  },
  decliningRow: {
    backgroundColor: colors.brand.grapefruitMinus90,
  },
  cellWrapper: {
    paddingRight: 20,
  },
});
