import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { useMediaQuery } from "lib/media_query";

interface DeactivateGroupBudgetProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export function DeactivateGroupBudgetDialog(props: DeactivateGroupBudgetProps) {
  const { open, onClose, onConfirm, loading } = props;
  const mq = useMediaQuery();
  return (
    <DialogModal
      isVisible={open}
      onClose={onClose}
      title="Are you sure you want to deactivate this budget?"
      subtitle="People will no longer be able to spend from this budget and will be removed from this budget. Reports and activity history will be retained, and the budget can be reactivated at any time."
      desktopWidth={540}
      bottomButtons={
        <>
          <Button
            size={mq.deviceQuery.mobile ? "auto" : "medium"}
            appearance="secondary"
            text="Cancel"
            onPress={onClose}
          />
          <Button
            size={mq.deviceQuery.mobile ? "auto" : "medium"}
            appearance="primary"
            text="Yes, deactivate"
            onPress={onConfirm}
            loading={loading}
            testID="group-budget-deactivate-confirm-button"
          />
        </>
      }
    />
  );
}
