import { Spacer } from "components/spacer";
import {
  CreateEditBudgetCard,
  CreateEditBudgetCardDescriptionList,
} from "pages/budgets/components/create_edit_budget_card";

interface CreateEditBudgetSummaryProps {
  limit?: number;
  individualLimit?: number | null;
}
export function CreateEditBudgetSummary(props: CreateEditBudgetSummaryProps) {
  const { limit, individualLimit } = props;
  let individualLimitText = "";

  if (!!individualLimit && individualLimit !== 0) {
    individualLimitText = `$${individualLimit} per month for each member who isn’t assigned to another budget`;
  } else if (individualLimit === 0) {
    individualLimitText = `No member will be able to spend from this budget because individual limit is set to 0`;
  } else if (limit) {
    individualLimitText = "No individual limit entered yet";
  }

  return (
    <CreateEditBudgetCard title="Summary">
      <CreateEditBudgetCardDescriptionList
        label="Spend limits"
        text={
          <>
            {limit || limit === 0
              ? `$${limit} per month for the entire organization`
              : "No limit entered yet"}
            <Spacer size={8} />
            {individualLimitText}
          </>
        }
      />
    </CreateEditBudgetCard>
  );
}
