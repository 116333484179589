import { Spacer } from "components/spacer";
import { useEffect, useState } from "react";
import {
  OrganizationBudgetDetailQuery,
  OrganizationBudgetDetailQueryVariables,
} from "core/graphql.generated";
import { OrganizationBudgetOverviewCard } from "./components/organization_budget_overview_card";
import { ReportingEngagement } from "pages/reporting/components/engagement";
import { ReportingFrequency } from "pages/reporting/components/frequency";
import { DayInterval } from "lib/day_utils";
import { ReportingBookings } from "pages/reporting/components/bookings";
import { ReportingSpend } from "pages/reporting/components/spend";
import { getDataAsLabel } from "pages/reporting/utils/get_last_updated_label";
import { AsyncRenderer } from "components/AsyncRenderer";
import { useHistory } from "react-router";
import { useQueryString } from "lib/query_string";
import { gql, useQuery } from "@apollo/client";

import { BudgetsDateRangePicker } from "components/budgets_date_range_picker/budgets_date_range_picker";
import { ChartContainerWrapper } from "./components/chart_container_wrapper";
import { ChartWrapper } from "./components/chart_wrapper";
import { getInitialDayInterval } from "components/budgets_date_range_picker/budgets_date_range_picker_common";
import { OrganizationBudgetDetailSkeleton } from "./organization_budget_detail_skeleton";
import { BudgetDetailParams } from "./organization_budget_detail";

export function OrganizationBudgetDetailSpendAndReports() {
  const history = useHistory();
  const sp = useQueryString<BudgetDetailParams>();
  const [dayInterval, setDayInterval] = useState<DayInterval>(
    getInitialDayInterval(sp),
  );

  const { loading, data, error } = useQuery<
    OrganizationBudgetDetailQuery,
    OrganizationBudgetDetailQueryVariables
  >(organizationBudgetDetailQuery, {
    variables: {
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
  });

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("start", dayInterval.start);
    newSearchParams.set("end", dayInterval.end);
    newSearchParams.set("tab", sp.tab);
    history.replace(
      `/admin/budgets/organization?${newSearchParams.toString()}`,
    );
  }, [history, dayInterval, sp.tab]);

  return (
    <AsyncRenderer
      loadingHandler={() => <OrganizationBudgetDetailSkeleton />}
      loading={loading}
      error={error}
      data={data}
    >
      {(data) => {
        const { budget, groupBudgets, reporting, name } =
          data.currentUser?.organization || {};
        return (
          <>
            <BudgetsDateRangePicker
              value={dayInterval}
              onChange={setDayInterval}
              labelUpdatedAt={getDataAsLabel(reporting?.lastUpdatedDate)}
            />
            <Spacer size={24} />
            {groupBudgets && budget && name && (
              <OrganizationBudgetOverviewCard
                state={budget.state}
                orgName={name}
                monthlyLimit={budget.limit}
                totalSpent={budget.spend}
                remaining={budget.remaining}
                groupBudgets={groupBudgets}
                interactiveLegend
              />
            )}
            <Spacer size={24} />

            {!!reporting && (
              <ChartContainerWrapper>
                <ChartWrapper>
                  <ReportingSpend
                    dayPasses={reporting.spend.dayPasses}
                    dayOffices={reporting.spend.dayOffices}
                    meetingRooms={reporting.spend.meetingRooms}
                    totalSpend={reporting.spend.totalSpend}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                  />
                </ChartWrapper>
                <ChartWrapper>
                  <ReportingBookings
                    dayPasses={reporting.bookings.dayPasses}
                    dayOffices={reporting.bookings.dayOffices}
                    meetingRooms={reporting.bookings.meetingRooms}
                    totalBookings={reporting.bookings.totalBookings}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                  />
                </ChartWrapper>
                <ChartWrapper>
                  <ReportingFrequency
                    zeroOrSingleBookingPerWeek={
                      reporting.frequency.zeroOrSingleBookingPerWeek
                    }
                    twoOrThreeBookingsPerWeek={
                      reporting.frequency.twoOrThreeBookingsPerWeek
                    }
                    fourOrMoreBookingsPerWeek={
                      reporting.frequency.fourOrMoreBookingsPerWeek
                    }
                    totalEmployees={reporting.frequency.totalEmployees}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                  />
                </ChartWrapper>
                <ChartWrapper>
                  <ReportingEngagement
                    soloBookings={reporting.engagement.soloBookings}
                    teamBookings={reporting.engagement.teamBookings}
                    labelUpdatedAt={getDataAsLabel(reporting.lastUpdatedDate)}
                  />
                </ChartWrapper>
              </ChartContainerWrapper>
            )}
          </>
        );
      }}
    </AsyncRenderer>
  );
}

const organizationBudgetDetailQuery = gql`
  query OrganizationBudgetDetail($startDate: String!, $endDate: String!) {
    currentUser {
      id
      organization {
        id
        name
        createdAt
        reporting(startDate: $startDate, endDate: $endDate) {
          ...BudgetDetail__Reporting
        }
        budget {
          id
          state
          remaining
          spend
          limit
          individualLimit
        }
        groupBudgets {
          id
          status
          name
          description
          color
          spend
          remaining
          limit
        }
      }
    }
  }

  fragment BudgetDetail__Reporting on Reporting {
    spend {
      totalSpend
      dayPasses {
        value
        percentage
      }
      dayOffices {
        value
        percentage
      }
      meetingRooms {
        value
        percentage
      }
    }
    bookings {
      totalBookings
      dayPasses {
        value
        percentage
      }
      dayOffices {
        value
        percentage
      }
      meetingRooms {
        value
        percentage
      }
    }
    frequency {
      totalEmployees
      zeroOrSingleBookingPerWeek {
        value
        percentage
      }
      twoOrThreeBookingsPerWeek {
        value
        percentage
      }
      fourOrMoreBookingsPerWeek {
        value
        percentage
      }
    }
    engagement {
      soloBookings {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      teamBookings {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
    lastUpdatedDate
  }
`;
