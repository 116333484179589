import { StyleSheet, View } from "react-native";
import { Icon } from "./iconv2";
import { Spacer } from "./spacer";
import { Text } from "./text_v2";
import "./preferred_badge.css";

interface PreferredBadgeProps {
  variant: "preferredOnly" | "preferredPartner" | "preferredSpotLight";
}

enum preferredText {
  preferredOnly = "Preferred",
  preferredPartner = "Preferred Partner",
  preferredSpotLight = "Preferred Partner Spotlight",
}

export function PreferredBadge(props: PreferredBadgeProps) {
  const { variant } = props;

  const isSpotlight = variant === "preferredSpotLight";

  if (isSpotlight) {
    return (
      <div className="preferred-spotlight-wrapper">
        <Icon name="preferred-white" size="sm" />
        <Spacer direction="row" size={8} />
        <Text weight="semibold" size="micro" color="white-core">
          {preferredText[variant]}
        </Text>
      </div>
    );
  }
  return (
    <View style={styles.root}>
      <Icon name="preferred" size="sm" />
      <Spacer direction="row" size={4} />
      <div className="text preferred-text text-size-xs text-weight-semibold">
        {preferredText[variant]}
      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "center",
  },
});
