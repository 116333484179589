import {
  ApolloError,
  ApolloQueryResult,
  gql,
  OperationVariables,
  useQuery,
} from "@apollo/client";

import {
  BookingRequests__CurrentUserFragment,
  BookingRequests__RequestDetailsFragment,
  BookingRequestsQuery,
  BookingRequestsQueryVariables,
  BudgetBookingRequests__CurrentUserFragment,
  BudgetBookingRequestsQuery,
  BudgetBookingRequestsQueryVariables,
} from "core/graphql.generated";
import { useUserRoleQuery } from "hooks/use_user_role_query";
import { useMemo } from "react";
import { getCurrentDate, getFirstDateOfMonth } from "../booking_requests_utils";

interface ReturnedData {
  __typename?: "Query";
  currentUser?:
    | BookingRequests__CurrentUserFragment
    | BudgetBookingRequests__CurrentUserFragment;
  bookingRequests: BookingRequests__RequestDetailsFragment[];
}

interface ReturnType {
  data: ReturnedData;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<
    ApolloQueryResult<BookingRequestsQuery | BudgetBookingRequestsQuery>
  >;
}

export const useBookingRequestsQuery = (): ReturnType => {
  const { isAdmin, isManager } = useUserRoleQuery();
  const { data, loading, error, refetch } = useQuery<
    BookingRequestsQuery,
    BookingRequestsQueryVariables
  >(bookingRequestsQuery, {
    variables: {
      requesterStartDate: getFirstDateOfMonth(),
      requesterEndDate: getCurrentDate(),
    },
    skip: !isAdmin,
  });

  const {
    data: managerData,
    loading: managerLoading,
    error: managerError,
    refetch: managerRefetch,
  } = useQuery<BudgetBookingRequestsQuery, BudgetBookingRequestsQueryVariables>(
    budgetBookingRequestsQuery,
    {
      variables: {
        requesterStartDate: getFirstDateOfMonth(),
        requesterEndDate: getCurrentDate(),
      },
      skip: isAdmin,
    },
  );

  const currentUser = data?.currentUser;

  const returnedData: ReturnedData = useMemo(() => {
    let bookingRequests: BookingRequests__RequestDetailsFragment[] = [];
    const orgBookingRequests = currentUser?.organization?.bookingRequests;
    const groupBudgetBookingRequests =
      managerData?.currentUser?.groupBudget?.bookingRequests;
    if (isAdmin && orgBookingRequests) {
      bookingRequests = orgBookingRequests;
    } else if (isManager && groupBudgetBookingRequests) {
      bookingRequests = groupBudgetBookingRequests;
    }

    return {
      __typename: "Query",
      currentUser: isAdmin
        ? currentUser || undefined
        : managerData?.currentUser || undefined,
      bookingRequests,
    };
  }, [currentUser, isAdmin, isManager, managerData?.currentUser]);

  return {
    data: returnedData,
    loading: isAdmin ? loading : managerLoading,
    error: isAdmin ? error : managerError,
    refetch: isAdmin ? refetch : managerRefetch,
  };
};

const budgetDetailFragment = gql`
  fragment BookingRequests__RequestDetails on BookingRequest {
    id
    amount
    currency
    createdAt
    bookedAt
    startDate
    status
    reason
    reviewerNote
    reviewedAt
    requesterTotalSpend(
      startDate: $requesterStartDate
      endDate: $requesterEndDate
    )
    requester {
      id
      fullName
      bookingRequests(
        startDate: $requesterStartDate
        endDate: $requesterEndDate
      ) {
        status
      }
      groupBudget {
        id
        limit
        individualLimit
      }
    }
    reviewer {
      fullName
    }
    space {
      id
      name
      location {
        name
      }
      spaceType
    }
    groupBudget {
      name
      id
    }
    organizationBudget {
      id
    }
  }
`;

const bookingRequestsQuery = gql`
  ${budgetDetailFragment}
  query BookingRequests(
    $requesterStartDate: String
    $requesterEndDate: String
  ) {
    currentUser {
      ...BookingRequests__CurrentUser
    }
  }

  fragment BookingRequests__CurrentUser on CurrentUser {
    id
    role
    organization {
      id
      name
      budget {
        limit
        individualLimit
      }
      bookingRequests {
        ...BookingRequests__RequestDetails
      }
    }
  }
`;

const budgetBookingRequestsQuery = gql`
  ${budgetDetailFragment}
  query BudgetBookingRequests(
    $requesterStartDate: String
    $requesterEndDate: String
  ) {
    currentUser {
      ...BudgetBookingRequests__CurrentUser
    }
  }

  fragment BudgetBookingRequests__CurrentUser on CurrentUser {
    id
    role
    organization {
      id
      name
    }
    groupBudget {
      id
      name
      bookingRequests {
        ...BookingRequests__RequestDetails
      }
    }
  }
`;
