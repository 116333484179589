import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import { BudgetPolicy } from "core/graphql.generated";
import { Button } from "components/button_v2";
import { DataTableRaw } from "components/data_table/data_table_raw";
import { useMediaQuery } from "lib/media_query";
import { Search } from "components/search/search";
import { searchSortRows } from "components/data_table/utils/search_sort_rows";
import { parseDay } from "lib/day_utils";

const mobileCols = [
  {
    id: "name",
    title: "Policy name",
    width: "85%",
  },
  {
    id: "editButton",
    title: "",
    width: "15%",
  },
];

const desktopCols = [
  {
    id: "name",
    title: "Policy name",
    width: "45%",
  },
  {
    id: "lastUpdated",
    title: "Last updated",
    width: "45%",
  },
  {
    id: "editButton",
    title: "",
    width: "10%",
  },
];

type PolicyRow = Pick<BudgetPolicy, "id" | "name" | "lastUpdated">;

interface PolicyTablePropType {
  rows: PolicyRow[];
}

export const PolicyTable: React.FC<PolicyTablePropType> = ({
  rows: rowsProp,
}) => {
  const {
    deviceQuery: { mobile: isMobile },
  } = useMediaQuery();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");

  const renderRows = () => {
    const renderedRows = rowsProp.map(({ id, name, lastUpdated }) => ({
      id,
      name,
      lastUpdated: format(parseDay(lastUpdated), "MMMM d, yyyy"),
      editButton: {
        render: () => (
          <Button
            iconName={"compose"}
            appearance={"textLink"}
            testID={`edit-budget-policy-button`}
            onPress={() => history.push(`/admin/budgets/policy/${id}/edit`)}
          />
        ),
      },
    }));

    return searchSortRows(renderedRows, "name", searchValue, ["name"]);
  };

  const handleClickCreate = () => {
    history.push("/admin/budgets/policy/new");
  };

  return (
    <View>
      <View style={isMobile ? styles.actionsMobile : styles.actionsDesktop}>
        <Search
          placeholder={"Search by policy name"}
          onSearch={setSearchValue}
        />
        {isMobile ? (
          <Button
            appearance="textLink"
            iconName="plus"
            iconSize="md"
            onPress={handleClickCreate}
            testID="create-budget-policy-button"
          />
        ) : (
          <Button
            text="Create new policy"
            iconName="plus"
            iconSize="md"
            onPress={handleClickCreate}
            testID="create-budget-policy-button"
          />
        )}
      </View>
      <DataTableRaw
        rows={renderRows()}
        columns={isMobile ? mobileCols : desktopCols}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  actionsDesktop: {
    flexDirection: "row",
    gap: 16,
    marginBottom: 24,
  },
  actionsMobile: { flexDirection: "row", marginBottom: 24 },
});
