import React, { useEffect, useRef } from "react";
import { Manager, Reference, Popper } from "react-popper";
import ReactDOM from "react-dom";
import { Placement } from "@popperjs/core";

const sameWidthModifier = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }: { state: any }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: { state: any }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

const sameMinWidthModifier = {
  name: "sameMinWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }: { state: any }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: { state: any }) => {
    state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
  },
};

interface GetModifiersProps {
  sameWidth: boolean;
  sameMinWidth: boolean;
  offsetVertical?: number;
  offsetHorizontal?: number;
}

const getModifiers = ({
  sameWidth,
  sameMinWidth,
  offsetVertical = 0,
  offsetHorizontal = 0,
}: GetModifiersProps) => {
  const result = [
    {
      name: "preventOverflow",
      options: {
        rootBoundary: "viewport",
        tether: false,
        altAxis: true,
      },
    },
  ] as any[];

  if (sameWidth) {
    result.push(sameWidthModifier);
  }

  if (sameMinWidth) {
    result.push(sameMinWidthModifier);
  }

  if (offsetHorizontal || offsetVertical) {
    result.push({
      name: "offset",
      options: {
        offset: [offsetHorizontal, offsetVertical],
      },
    });
  }

  return result;
};

interface DropdownProps {
  open: boolean;
  content: React.ReactNode;
  children: React.ReactNode;
  onRequestClose: () => void;
  placement?: Placement;
  sameWidth?: boolean;
  sameMinWidth?: boolean;
  offsetVertical?: number;
  offsetHorizontal?: number;
}

export function DropdownV2(props: DropdownProps) {
  const {
    content,
    open,
    onRequestClose,
    children,
    placement = "bottom-start",
    // indicates that dropdown should be same width as the reference elem
    sameWidth = true,
    sameMinWidth = false,
    offsetVertical = 0,
    offsetHorizontal = 0,
  } = props;
  const ref = useOuterClick(onRequestClose);

  return (
    <Manager>
      <div ref={ref}>
        <Reference>{({ ref }) => <div ref={ref}>{children}</div>}</Reference>
        {open &&
          ReactDOM.createPortal(
            <Popper
              // @ts-ignore
              modifiers={getModifiers({
                sameWidth,
                sameMinWidth,
                offsetVertical,
                offsetHorizontal,
              })}
              strategy="fixed"
              placement={placement}
            >
              {({ ref, style, placement }) => (
                <div
                  ref={ref}
                  style={{ ...style, zIndex: 999 }}
                  data-placement={placement}
                  onPointerUp={(e) => e.stopPropagation()}
                >
                  {content}
                </div>
              )}
            </Popper>,
            document.getElementById("root")!,
          )}
      </div>
    </Manager>
  );
}

export function useOuterClick(callback: (e: MouseEvent) => void) {
  const callbackRef = useRef<(e: MouseEvent) => void>(); // initialize mutable ref, which stores callback
  const innerRef = useRef<HTMLDivElement>(null); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("pointerup", handleClick);
    return () => {
      document.removeEventListener("pointerup", handleClick);
    };

    function handleClick(e: globalThis.MouseEvent) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target as Node)
      ) {
        callbackRef.current(e);
      }
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
