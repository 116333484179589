import { StyleSheet, View } from "react-native";
import { SpaceListV2 } from "./space_list_v2";
import {
  HomePageLocations__LocationDetailsFragment,
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { Spacer } from "components/spacer";
import { Fragment } from "react";
import { SpaceSortPicker } from "./space_sort_picker";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";

interface SpaceListContainerProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (location: HomePage__LocationDetailsFragment) => void;
  loading: boolean;
  totalCount: number | undefined;
  showSpaceTypeTabs?: boolean;
  totalPages: number | undefined;
  locations?: HomePageLocations__LocationDetailsFragment[];
}

export const spaceNamesSingular = {
  [SpaceType.DayOffice]: "office",
  [SpaceType.DayPass]: "pass",
  [SpaceType.MeetingRoom]: "room",
};

export const spaceNamesPlural = {
  [SpaceType.DayOffice]: "offices",
  [SpaceType.DayPass]: "passes",
  [SpaceType.MeetingRoom]: "rooms",
  any: "locations",
};

export function SpaceListContainer(props: SpaceListContainerProps) {
  const {
    spaces,
    loading,
    totalPages,
    totalCount,
    onPreviewLocation,
    showSpaceTypeTabs,
    locations,
  } = props;
  const { spaceType } = useHomeSearchParamsQuery();
  const mq = useMediaQuery();
  const spaceCount = spaces.length;
  const locationsCount = locations?.length || 0;

  return (
    <View
      style={[
        styles.container,
        loading && styles.containerLoading,
        mq.deviceQuery.mobile && styles.containerMobile,
      ]}
      pointerEvents={loading ? "none" : "auto"}
    >
      {showSpaceTypeTabs && (
        <Fragment>
          <Spacer size={mq.deviceQuery.mobile ? 8 : 20} />
        </Fragment>
      )}
      {spaceCount > 0 && !!spaceType && (
        <Fragment>
          <View style={styles.spaceSortContainer}>
            <Text weight="semibold" size="xs" color="black-70">
              {totalCount}{" "}
              {spaceType === SpaceType.DayOffice ? "locations" : "spaces"} found
            </Text>
            <Spacer size={8} />
            <SpaceSortPicker />
          </View>
          <Spacer size={16} />
        </Fragment>
      )}
      {locationsCount > 0 && !spaceType && (
        <Fragment>
          <View style={styles.spaceSortContainer}>
            <Text weight="semibold" size="xs" color="black-70">
              {locationsCount} locations found
            </Text>
          </View>
          <Spacer size={16} />
        </Fragment>
      )}
      <SpaceListV2
        onPreviewLocation={onPreviewLocation}
        spaces={spaces}
        loading={loading}
        totalPages={totalPages}
        totalCount={totalCount}
        locations={locations}
      />
      <Spacer size={40} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
  },
  containerLoading: {
    opacity: 0.35,
  },
  containerMobile: {
    paddingTop: 0,
  },
  tab: {
    maxWidth: 343,
    width: "100%",
  },
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabContainerMobile: {
    flexDirection: "column",
  },
  spaceSortContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
