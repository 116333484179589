import React from "react";
import { Animated, Pressable, StyleSheet, View } from "react-native";
import { colors } from "./colors";
import { Icon } from "./icon";
import { Icon as IconV2 } from "./iconv2";
import { Text } from "./text_v2";

import { tokens } from "./tokens";

interface CheckboxProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  appearance?: "box" | "round" | "radio";
  testId?: string;
  size?: "md" | "sm";
  label?: string;
  renderLabel?: () => JSX.Element;
}

export function Checkbox(props: CheckboxProps): JSX.Element {
  const {
    value,
    testId,
    onChange,
    appearance = "round",
    size = "md",
    label,
    renderLabel,
  } = props;
  const checked = React.useRef(new Animated.Value(0)).current;
  const handlePress = React.useCallback(() => {
    if (onChange) {
      onChange(!value);
    }
  }, [value, onChange]);
  React.useEffect(() => {
    Animated.spring(checked, {
      toValue: value ? 1 : 0,
      useNativeDriver: false,
      bounciness: 0,
      speed: 40,
    }).start();
  }, [checked, value]);

  return (
    <View style={styles.checkboxWrapper}>
      <Pressable
        onPress={handlePress}
        testID={testId}
        style={[
          styles.pressable,
          styles[`pressable-${appearance}`],
          styles[`pressable-${size}`],
        ]}
      >
        <Animated.View
          style={[
            styles.box,
            styles[`box-${appearance}`],
            {
              backgroundColor: checked.interpolate({
                inputRange: [0, 1],
                outputRange: [
                  tokens.colors.neutral.regular,
                  tokens.colors.primary.regular,
                ],
              }),
            },
          ]}
        >
          {value && (
            <View testID="checked" style={styles.checkmark}>
              {appearance === "radio" ? (
                <IconV2 name="radio" />
              ) : (
                <Icon name="check" size="sm" color="white" />
              )}
            </View>
          )}
        </Animated.View>
      </Pressable>
      {label && (renderLabel?.() || <Text size="xs">{label}</Text>)}
    </View>
  );
}

const styles = StyleSheet.create({
  checkboxWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    marginTop: 12,
  },
  pressable: {
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
  },
  "pressable-md": {
    width: 24,
    height: 24,
  },
  "pressable-sm": {
    width: 18,
    height: 18,
  },
  "pressable-round": {
    borderRadius: 999,
  },
  "pressable-box": {
    borderRadius: 2,
  },
  "pressable-radio": {
    borderRadius: 999,
  },
  box: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  "box-round": {
    borderRadius: 999,
  },
  "box-box": {
    borderRadius: 2,
  },
  "box-radio": {
    borderRadius: 999,
  },
  checkmark: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
