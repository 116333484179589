import "./heading_v2.web.css";

import React from "react";
import classNames from "classnames";
import { HeadingProps } from "./heading_v2";

// NEEDS TO MATCH NATIVE IMPLEMENTATION
export function Heading(props: HeadingProps) {
  const {
    size,
    children,
    color = "default",
    align = "left",
    weight = "bold",
    testID,
  } = props;

  const headingProps = {
    className: classNames(
      "heading",
      color && `heading-color-${color}`,
      size && `heading-size-${size}`,
      align && `heading-align-${align}`,
      weight && `heading-weight-${weight}`,
    ),
    ...(testID && { "data-testid": testID }),
    children,
  };

  switch (size) {
    case "mega": {
      return <h1 {...headingProps}> {headingProps.children} </h1>;
    }
    case "xl": {
      return <h2 {...headingProps}> {headingProps.children} </h2>;
    }
    case "lg": {
      return <h3 {...headingProps}> {headingProps.children} </h3>;
    }
    default: {
      return <h4 {...headingProps}> {headingProps.children} </h4>;
    }
  }
}
