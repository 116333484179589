import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";
import { useMediaQuery } from "../../../../lib/media_query";

export function HeroCarouselSkeleton() {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={isMobile ? 240 : 353}
      backgroundColor="#f7f7f7"
      foregroundColor="#ecebeb"
    >
      <Rect width={"100%"} height={isMobile ? 240 : 353} />
    </ContentLoader>
  );
}
