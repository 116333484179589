import ContentLoader, { Rect } from "react-content-loader/native";

export function RecentActivitySkeleton() {
  return (
    <>
      <ContentLoader height="880px">
        <Rect x="0" y="0" rx="15" ry="15" width="100%" height="880" />
      </ContentLoader>
    </>
  );
}
