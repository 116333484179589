import { gql, useQuery } from "@apollo/client";
import { useStripe } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { BookingDateRangeDropDownDesktop } from "components/booking_date_range_dropdown_desktop";
import { Container } from "components/container";
import { Dialog } from "components/dialog";
import { Modal } from "components/modal";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text";
import { TextLink } from "components/text_link";
import { DateRange } from "core/booking_date_range_picker";
import {
  CheckoutInput,
  getDateRangeForNextBookingType,
  getDefaultLayoutID,
  hasReservationDetails,
  SearchValue,
  toQueryString,
  useBookingDateRangePickerHelper,
  useCheckout,
  useSearchValue,
} from "core/booking_utils";
import { useSavedReservationDetails } from "core/cart";
import { DialogContent } from "core/dialog_content";
import {
  BookingType,
  QuoteDetailsQuery,
  QuoteDetailsQueryVariables,
  SpaceCheckoutCurrentUserPaymentMethodQuery,
  SpaceDetails__AllDetailsFragment,
  SpaceDetailsQuery,
  SpaceDetailsQueryVariables,
} from "core/graphql.generated";
import { quoteGQLQuery, spaceGQLQuery } from "core/queries";
import { RoomLayoutEdit } from "core/room_layout_edit";
import { ROUTES } from "core/routes";
import { useQuoteVariables } from "core/use_quote_variables";
import { withStripe } from "core/with_stripe";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import { useQueryString } from "lib/query_string";
import { differenceInHours } from "lib/time_utils";
import { useBestDayRatesQuery } from "pages/offsite_space_details_v2/hooks/use_best_day_rates_query";
import { useSyncSearchValueToURL } from "pages/offsite_space_details_v2/hooks/use_sync_search_value_to_url";
import { validateDateRange } from "pages/offsite_space_details_v2/utils/validate_date_range";
import { useAnalytics } from "providers/analytics";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { useHistory, useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { BookingCheckoutDesktop } from "./components/booking_checkout_desktop";
import { BookingCheckoutMobile } from "./components/booking_checkout_mobile";
import { BookingDateRangePickerDialogContent } from "./components/booking_date_range_picker_dialog_content";
import "./offsite_space_checkout.css";

interface OffsiteSpaceCheckoutPageParams {
  offsiteSpaceID: string;
}

export const OffsiteSpaceCheckoutPage = withStripe(
  function OffsiteSpaceCheckoutPage() {
    const { offsiteSpaceID } = useParams<OffsiteSpaceCheckoutPageParams>();
    const { data: spaceDetailsQuery, loading: spaceDetailsLoading } = useQuery<
      SpaceDetailsQuery,
      SpaceDetailsQueryVariables
    >(spaceGQLQuery, { variables: { id: offsiteSpaceID } });
    const { data: currentUserData, loading: currentUserLoading } =
      useQuery<SpaceCheckoutCurrentUserPaymentMethodQuery>(
        currentUserPaymentMethodGQLQuery,
      );
    const space = spaceDetailsQuery?.space!;
    const stripe = useStripe();

    if (spaceDetailsLoading || currentUserLoading) {
      return (
        <View style={{ padding: 120 }}>
          <Spinner />
        </View>
      );
    }

    if (!space) {
      return <Redirect to="/" />;
    }

    return (
      <Container>
        <OffsiteSpaceCheckout
          space={space}
          currentUser={currentUserData?.currentUser}
          stripe={stripe}
        />
      </Container>
    );
  },
);

interface OffsiteSpaceCheckoutProps {
  space: SpaceDetails__AllDetailsFragment;
  stripe: Stripe | null;
  currentUser: SpaceCheckoutCurrentUserPaymentMethodQuery["currentUser"];
}

function OffsiteSpaceCheckout(props: OffsiteSpaceCheckoutProps) {
  const { space, stripe, currentUser } = props;
  const offsiteSpaceID = space.id;
  const history = useHistory();
  const goBack = useGoBack(ROUTES.SPACE_DETAIL.path);
  const queryString = useQueryString();
  const analytics = useAnalytics();
  const initialSearchValue = useSearchValue(queryString);
  const [layoutVisible, setLayoutVisible] = useState(false);
  const [bookingDateRangeVisible, setBookingDateRangeVisible] = useState(false);
  const mq = useMediaQuery();
  const monthlyBilling = !!currentUser?.organization?.monthlyBilling;
  const [tempDateRange, setTempDateRange] = useState<DateRange | undefined>();
  const { savedReservationDetails } = useSavedReservationDetails(space.id);
  const {
    initialBookingType,
    showSegmentedControl,
    timeslots,
    isBlockedDate,
    isOutsideRange,
    multiDayBookingAllowed,
    availableTimeSlotsError,
    availableDaysError,
  } = useBookingDateRangePickerHelper({
    space,
    physicalSpaceID: initialSearchValue.physicalSpaceID,
    selectedDay:
      tempDateRange?.type === BookingType.HourlyBooking
        ? tempDateRange.date
        : undefined,
  });
  const [bookingType, setBookingType] =
    useState<BookingType>(initialBookingType);
  const bestDayRates = useBestDayRatesQuery(
    space.id,
    bookingType,
    space.location.timezone,
  );
  const handleComplete = useCallback(
    (orderID: string) => {
      history.replace(`/orders/${orderID}`);
    },
    [history],
  );
  const requirePayment =
    !monthlyBilling && !space.pricings.every((p) => p.price === 0);
  const {
    paymentInfoIsIncomplete,
    submit,
    submitting,
    values,
    status,
    errors,
    setFieldValues,
    handleChange,
    onCreditCardChange,
    setFieldValue,
    submitCount,
  } = useCheckout({
    requirePayment,
    initialValues: {
      quoteId: initialSearchValue.quoteId,
      dateRange: initialSearchValue.dateRange,
      physicalSpaceID: initialSearchValue.physicalSpaceID,
      promoCode: initialSearchValue.promoCode,
      layoutID: savedReservationDetails.layoutID,
      meetingName: savedReservationDetails.meetingName,
      meetingStartTime: savedReservationDetails.meetingStartTime,
      arrivalTime: savedReservationDetails.arrivalTime,
    },
    spaceID: space.id,
    onComplete: handleComplete,
    stripe,
    savedPaymentMethodId: currentUser?.paymentMethod?.id,
  });

  const searchValue: SearchValue = useMemo(() => {
    return {
      quoteId: initialSearchValue.quoteId,
      dateRange: values.dateRange,
      promoCode: values.promoCode,
      physicalSpaceID: values.physicalSpaceID,
    };
  }, [
    initialSearchValue.quoteId,
    values.dateRange,
    values.physicalSpaceID,
    values.promoCode,
  ]);
  const [error, setError] = useState("");
  const { dateRange } = searchValue;

  useEffect(() => {
    setTempDateRange(dateRange);
  }, [dateRange]);

  const quoteVariables = useQuoteVariables({
    searchValue,
    spaceID: space.id,
    layoutID: getDefaultLayoutID(space),
    partnerID: space.partnerID,
    attendees: space.maxCapacity,
  });

  const { data: quoteQuery, error: quoteErrorRaw } = useQuery<
    QuoteDetailsQuery,
    QuoteDetailsQueryVariables
  >(quoteGQLQuery, {
    fetchPolicy: "network-only",
    variables: quoteVariables!,
    skip: !quoteVariables,
  });
  const quote = quoteQuery?.quote;
  const quoteError = extractFirstGraphQLErrorMessage(quoteErrorRaw);

  const handleGoBack = useCallback(() => {
    const qs = toQueryString(searchValue);

    if (space && hasReservationDetails(space)) {
      history.replace(`/offsite_spaces/${offsiteSpaceID}/reservation${qs}`);
    } else {
      history.replace(`/offsite_spaces/${offsiteSpaceID}${qs}`);
    }
  }, [history, offsiteSpaceID, space, searchValue]);

  useEffect(() => {
    if (space?.defaultLayoutID && !values.layoutID) {
      setFieldValue("layoutID", space.defaultLayoutID);
    }
  }, [space, setFieldValue, values.layoutID]);

  useEffect(() => {
    if (currentUser?.paymentMethod) {
      setFieldValue("paymentComplete", !!currentUser.paymentMethod.id);
    }
  }, [currentUser?.paymentMethod, setFieldValue]);

  useSyncSearchValueToURL({
    searchValue,
    offsiteSpaceID,
    baseURL: `/offsite_spaces/${offsiteSpaceID}/checkout`,
  });

  const has100PercentPromo = quoteQuery?.quote?.totalPrice === 0;

  const requirePaymentWithPromo = requirePayment && !has100PercentPromo;

  // if a user doesn't have any payment methods saved
  // and there is a 100% promo applied, set field PaymentComplete to true
  // to avoid form validation error
  useEffect(() => {
    if (!requirePaymentWithPromo && !values.paymentComplete) {
      setFieldValue("paymentComplete", true);
    }
  }, [requirePaymentWithPromo, values.paymentComplete, setFieldValue]);

  const confirmationButtonDisabled = !!(
    (paymentInfoIsIncomplete && requirePaymentWithPromo) ||
    quoteError ||
    (quoteVariables && !quoteQuery)
  );

  const handleClose = useCallback(() => {
    setBookingDateRangeVisible(false);
  }, []);
  const handleClear = useCallback(() => {
    setTempDateRange(dateRange);
  }, [dateRange]);

  const handleChangeDateRange = useCallback((newDateRange?: DateRange) => {
    if (newDateRange) {
      setTempDateRange(newDateRange);
    }
  }, []);

  const handleSaveDateRange = useCallback(() => {
    const dateRangeError = validateDateRange(tempDateRange);
    if (dateRangeError) {
      setError(dateRangeError);
    } else {
      setFieldValues({
        dateRange: tempDateRange,
      });
      setError("");
      setBookingDateRangeVisible(false);
    }
  }, [setFieldValues, tempDateRange]);

  const handleChangeBookingType = useCallback(
    (b: BookingType) => {
      setBookingType(b);
      setFieldValues({
        ...searchValue,
        dateRange: getDateRangeForNextBookingType(searchValue.dateRange, b),
      });
    },
    [searchValue, setFieldValues],
  );

  useEffect(() => {
    if (!quote || !searchValue.dateRange) {
      return;
    }

    analytics.event("View Checkout", {
      "Space UUID": space.id,
      "Space Name": space.name,
      City: space.location.address.city,
      State: space.location.address.state,
      Country: space.location.address.country,
      Location: space.location.name,
      "Order Total": quote.totalPrice,
      "Space Currency": space.currency,
      "Start Date":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.date
          : searchValue.dateRange.startDate,
      "End Date":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.date
          : searchValue.dateRange.startDate,
      "Start Time":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.startTime
          : undefined,
      "End Time":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.endTime
          : undefined,
      "Hours Duration":
        searchValue.dateRange.type === BookingType.HourlyBooking &&
        searchValue.dateRange.endTime &&
        searchValue.dateRange.startTime
          ? differenceInHours(
              searchValue.dateRange.endTime,
              searchValue.dateRange.startTime,
            )
          : undefined,
    });
  }, [space, analytics, quote, searchValue]);

  const dateTimeDropDownPicker = (
    <>
      {mq.deviceQuery.desktop && (
        <BookingDateRangeDropDownDesktop
          openDropDown={bookingDateRangeVisible && mq.deviceQuery.desktop}
          closeDropDown={handleClose}
          handleClear={handleClear}
          bookingType={bookingType}
          handleChangeBookingType={handleChangeBookingType}
          showSegmentedControl={showSegmentedControl}
          multiDayBookingAllowed={multiDayBookingAllowed}
          dateRange={tempDateRange}
          handleChangeDateRange={handleChangeDateRange}
          handleSaveDateRange={handleSaveDateRange}
          isOutsideRange={isOutsideRange}
          isBlockedDate={isBlockedDate}
          timeslots={timeslots}
          error={error}
          availableDaysError={availableDaysError}
          availableTimeSlotsError={availableTimeSlotsError}
          pricings={space.pricings}
          dynamicPrices={bestDayRates}
          currency={space.currency}
          spaceId={space.id}
        >
          <TextLink
            onPress={goBack}
            text="Change details"
            size="xs"
            weight={"semibold"}
          />
        </BookingDateRangeDropDownDesktop>
      )}
    </>
  );

  return (
    <Container>
      {mq.sizeQuery.mdAndUp ? (
        <BookingCheckoutDesktop
          requirePayment={requirePaymentWithPromo}
          displayOrderBreakdown={requirePayment || has100PercentPromo}
          space={space}
          values={values}
          errors={errors}
          submitting={submitting}
          disabled={confirmationButtonDisabled}
          submit={submit}
          status={status}
          onCreditCardChange={onCreditCardChange}
          handleChange={handleChange}
          setFieldValues={setFieldValues}
          setFieldValue={setFieldValue}
          monthlyBilling={monthlyBilling}
          submitCount={submitCount}
          onBack={handleGoBack}
          layoutVisible={layoutVisible}
          setLayoutVisible={setLayoutVisible}
          bookingDateRangeVisible={bookingDateRangeVisible}
          setBookingDateRangeVisible={setBookingDateRangeVisible}
          dateTimeDropDownPicker={dateTimeDropDownPicker}
          stripe={stripe}
          quoteQuery={quoteQuery}
          quoteError={quoteError}
        />
      ) : (
        <BookingCheckoutMobile
          requirePayment={requirePaymentWithPromo}
          displayOrderBreakdown={requirePayment || has100PercentPromo}
          space={space}
          values={values}
          errors={errors}
          submitting={submitting}
          disabled={confirmationButtonDisabled}
          submit={submit}
          status={status}
          monthlyBilling={monthlyBilling}
          onCreditCardChange={onCreditCardChange}
          handleChange={handleChange}
          setFieldValues={setFieldValues}
          setFieldValue={setFieldValue}
          submitCount={submitCount}
          onBack={handleGoBack}
          layoutVisible={layoutVisible}
          setLayoutVisible={setLayoutVisible}
          bookingDateRangeVisible={bookingDateRangeVisible}
          setBookingDateRangeVisible={setBookingDateRangeVisible}
          stripe={stripe}
          quoteQuery={quoteQuery}
          quoteError={quoteError}
        />
      )}

      <Dialog
        animationType="slide"
        visible={layoutVisible}
        onRequestClose={() => setLayoutVisible(false)}
      >
        <DialogContent
          headerLeftIcon="x-circle"
          headerTitle="Room Layout"
          onHeaderLeftIconPress={() => setLayoutVisible(false)}
        >
          {layoutVisible && (
            <RoomLayoutEdit
              initialLayoutID={values.layoutID}
              space={space}
              maxCapacity={space.maxCapacity}
              onCancel={() => setLayoutVisible(false)}
              onSave={(lID) => {
                setFieldValue("layoutID", lID);
                setLayoutVisible(false);
              }}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        animationType="slide"
        visible={bookingDateRangeVisible && mq.deviceQuery.mobile}
        onRequestClose={() => setBookingDateRangeVisible(false)}
      >
        {bookingDateRangeVisible && (
          <BookingDateRangePickerDialogContent
            monthlyBilling={monthlyBilling}
            space={space}
            initialSearchValues={values}
            onClose={() => setBookingDateRangeVisible(false)}
            onSave={(searchValues) => {
              setFieldValues(searchValues);
              setBookingDateRangeVisible(false);
            }}
            dynamicPrices={bestDayRates}
          />
        )}
      </Dialog>

      <Modal visible={submitting} transparent>
        <Container customColor="rgba(255, 255, 255, 0.7)" expanded center>
          <Text weight="bold" size="lg" color="primary">
            Submitting your request
          </Text>
          <Spacer size={16} />
          <ActivityIndicator size="large" color="rgba(82,68,134,1)" />
        </Container>
      </Modal>
    </Container>
  );
}

export interface BookingCheckoutProps {
  requirePayment: boolean;
  space: SpaceDetails__AllDetailsFragment;
  values: CheckoutInput;
  setFieldValues: (input: Partial<CheckoutInput>) => void;
  setFieldValue: <K extends keyof CheckoutInput>(
    field: keyof CheckoutInput,
    value: CheckoutInput[K],
  ) => void;
  errors: {
    [key in keyof CheckoutInput]?: string;
  };
  handleChange: (
    field: keyof CheckoutInput,
  ) => <K extends keyof CheckoutInput>(value: CheckoutInput[K]) => void;
  submitting: boolean;
  submitCount: number;
  disabled: boolean;
  status?: string;
  submit: () => void;
  monthlyBilling: boolean;
  onCreditCardChange: (complete: boolean, error?: string) => void;
  onBack: () => void;
  layoutVisible: boolean;
  setLayoutVisible: (visible: boolean) => void;
  bookingDateRangeVisible: boolean;
  setBookingDateRangeVisible: (visible: boolean) => void;
  dateTimeDropDownPicker?: ReactNode;
  stripe: Stripe | null;
  quoteQuery?: QuoteDetailsQuery;
  quoteError?: string | null;
  displayOrderBreakdown: boolean;
}

const currentUserPaymentMethodGQLQuery = gql`
  query SpaceCheckoutCurrentUserPaymentMethod {
    currentUser {
      id
      organization {
        id
        monthlyBilling
      }
      paymentMethod {
        ... on CardPaymentMethod {
          id
          last4
          network
          networkLogoUrl
        }
      }
    }
  }
`;
