import { SelectInput } from "components/select/select_input";
import { SpaceType } from "./graphql.generated";

const spaceTypeOptions = [
  {
    label: "Room",
    value: SpaceType.MeetingRoom,
  },
  {
    label: "Pass",
    value: SpaceType.DayPass,
  },
  {
    label: "Office",
    value: SpaceType.DayOffice,
  },
];
interface SpaceTypePickerProps {
  value: string | undefined;
  onChange: (label: string | undefined) => void;
}

export function SpaceTypePicker(props: SpaceTypePickerProps) {
  const { onChange, value } = props;

  return (
    <SelectInput
      testID="space-type-picker"
      options={spaceTypeOptions}
      onChange={onChange}
      label="Space type"
      value={value}
      valueProp="value"
      placeholder="Any space type"
    />
  );
}
