import { IconType, SaveFavoriteButton } from "components/save_favorite_button";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { StyleSheet, View } from "react-native";

interface CardsSavedIconProps {
  favoriteId: string;
  favoriteType: FavoriteType;
}

export function CardsSaveIcon(props: CardsSavedIconProps) {
  const { favoriteId, favoriteType } = props;

  return (
    <View style={styles.absoluteContainer}>
      <SaveFavoriteButton
        favoriteId={favoriteId}
        favoriteType={favoriteType}
        onlyIcon={true}
        iconType={IconType.overlay}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  absoluteContainer: {
    position: "absolute",
    top: 12,
    right: 12,
  },
});
