import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Container } from "components/container";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { Icon } from "components/iconv2";
import { DaysOfWeek } from "components/month_renderer";
import {
  renderDay,
  renderDayOfWeek,
  renderOtherMonthDay,
  renderWeek,
} from "components/month_view/components/components_v2";
import { MonthView } from "components/month_view/month_view";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import { useSelectDayDateRangeHandler } from "core/booking_date_range_picker";
import { format } from "date-fns";
import { DateInterval, isBefore } from "lib/date_utils";
import {
  addMonths,
  DayInterval,
  parseDay,
  subMonths,
  toDateInterval,
  toDay,
  today,
} from "lib/day_utils";
import { useMediaQuery } from "lib/media_query";
import { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

interface DateRangeViewProps {
  open: boolean;
  value: DayInterval;
  onCancel: () => void;
  onChange: (dayInterval: DayInterval) => void;
  earliestAllowedDate?: Date;
  onClear: () => void;
}

export function DateRangeView(props: DateRangeViewProps) {
  const mq = useMediaQuery();
  const { value, onChange, earliestAllowedDate, onClear, onCancel, open } =
    props;
  const [localValue, setLocalValue] = useState(value);
  const [currentMonth, setCurrentMonth] = useState(
    value ? value?.start : today(),
  );

  const handlePressPrevious = useCallback(() => {
    setCurrentMonth(subMonths(currentMonth, 1));
  }, [currentMonth, setCurrentMonth]);

  const handlePressNext = useCallback(() => {
    setCurrentMonth(addMonths(currentMonth, 1));
  }, [currentMonth, setCurrentMonth]);
  const currentDate = parseDay(currentMonth);
  const selected = toDateInterval(localValue);

  const handleChangeDateInterval = useCallback((dateInterval: DateInterval) => {
    setLocalValue({
      start: toDay(dateInterval.start),
      end: toDay(dateInterval.end),
    });
  }, []);

  const handleChange = useSelectDayDateRangeHandler({
    value: toDateInterval(localValue),
    onChange: handleChangeDateInterval,
  });

  const handleSubmit = useCallback(() => {
    onChange(localValue);
  }, [localValue, onChange]);

  if (mq.deviceQuery.mobile) {
    return (
      <DateRangeViewMobile
        open={open}
        onCancel={onCancel}
        onChange={handleChange}
        earliestAllowedDate={earliestAllowedDate}
        onClear={onClear}
        month={currentMonth}
        localValue={localValue}
        handleSubmit={handleSubmit}
      />
    );
  }

  return (
    <View>
      <View style={{ padding: 16 }}>
        <View style={styles.monthNavigatorWrapper}>
          <Pressable style={styles.arrowWrapper} onPress={handlePressPrevious}>
            <Icon name="arrow-left" />
          </Pressable>
          <Text align="center">{format(currentDate, "MMMM yyyy")}</Text>
          <Pressable style={styles.arrowWrapper} onPress={handlePressNext}>
            <Icon name="arrow-right" />
          </Pressable>
        </View>
        <MonthView
          selectedInterval={selected}
          month={currentDate}
          onSelectDay={handleChange}
          renderDay={renderDay}
          renderDayOfWeek={renderDayOfWeek}
          renderOtherMonthDay={renderOtherMonthDay}
          renderWeek={renderWeek}
          isBlocked={(date) =>
            earliestAllowedDate ? isBefore(date, earliestAllowedDate) : false
          }
        />
      </View>
      <Divider />
      <Container padding={16}>
        <Row justifyContent="space-between" alignItems="center">
          <TextLink
            weight="semibold"
            decoration="none"
            text="Clear"
            onPress={onClear}
          />
          <Spacer size={16} />
          <Container width={114}>
            <Button text="Apply" onPress={handleSubmit} />
          </Container>
        </Row>
      </Container>
    </View>
  );
}
interface DateRangeViewMobileProps {
  open: boolean;
  onCancel: () => void;
  onChange: (day: Date) => void;
  earliestAllowedDate?: Date;
  onClear: () => void;
  month: string;
  localValue: DayInterval;
  handleSubmit: () => void;
}
export function DateRangeViewMobile(props: DateRangeViewMobileProps) {
  const {
    open,
    onCancel,
    onChange,
    earliestAllowedDate,
    onClear,
    month,
    localValue,
    handleSubmit,
  } = props;
  const [nOfMonthsToDisplay, setNOfMonthsToDisplay] = useState(5);
  const [currentMonth, setCurrentMonth] = useState(month);
  const selected = toDateInterval(localValue);

  const previousMonthPress = () => {
    setNOfMonthsToDisplay(nOfMonthsToDisplay + 2);
    setCurrentMonth(subMonths(currentMonth, 2));
  };

  return (
    <DialogModal
      isVisible={open}
      onClose={onCancel}
      noScroll
      title="Select date"
    >
      <View style={styles.dialogContent}>
        <View style={{ flexShrink: 0, paddingHorizontal: 16 }}>
          <DaysOfWeek renderDayOfWeek={renderDayOfWeek} firstDayOfWeek={1} />
        </View>
        <Spacer size={7} />
        <Divider />
        <View style={styles.scrollableArea}>
          <View style={styles.buttonView}>
            <Button text="Show previous" onPress={previousMonthPress} />
          </View>
          <Spacer size={16} />
          {new Array(nOfMonthsToDisplay).fill(null).map((_, i) => {
            const localMonth = addMonths(currentMonth, i);

            return (
              <View>
                <View style={styles.monthNavigatorWrapperMobile}>
                  <Text align="center" weight="semibold" size="xs">
                    {format(parseDay(localMonth), "MMMM yyyy")}
                  </Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 16,
                  }}
                >
                  <MonthView
                    selectedInterval={selected}
                    month={parseDay(localMonth)}
                    onSelectDay={onChange}
                    isBlocked={(date) =>
                      earliestAllowedDate
                        ? isBefore(date, earliestAllowedDate)
                        : false
                    }
                    renderDay={renderDay}
                    renderDayOfWeek={renderDayOfWeek}
                    renderOtherMonthDay={renderOtherMonthDay}
                    renderWeek={renderWeek}
                    withDaysOfWeek={false}
                    topOffset={10}
                  />
                </View>
                <Spacer size={16} />
              </View>
            );
          })}
          <Spacer size={16} />
          <View style={styles.buttonView}>
            <Button
              text="Show more"
              onPress={() => setNOfMonthsToDisplay(nOfMonthsToDisplay + 2)}
            />
          </View>
        </View>
      </View>
      <View style={styles.dialogFooter}>
        <Pressable onPress={onClear}>
          <Text color="eggplant-core" size="xs" weight="semibold">
            Clear
          </Text>
        </Pressable>
        <Button
          testID="date-picker-show-results-button"
          text="Apply"
          onPress={handleSubmit}
        />
      </View>
    </DialogModal>
  );
}

const styles = StyleSheet.create({
  option: {
    padding: 16,
    height: 48,
  },
  monthNavigatorWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  monthNavigatorWrapperMobile: {
    justifyContent: "center",
  },
  dialogContent: {
    flexShrink: 1,
    minHeight: 0,
    overflow: "hidden",
  },
  scrollableArea: {
    flexShrink: 1,
    minHeight: 0,
    overflow: "scroll",
  },
  dialogFooter: {
    padding: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  arrowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 999,
    textAlign: "center",
    height: 24,
    width: 24,
  },
  customPickerWrapper: {
    width: 336,
    borderLeftWidth: 1,
    borderLeftColor: colors.brand.blackMinus90,
  },
  buttonView: {
    padding: 16,
  },
});
