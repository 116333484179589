import { calculateDistance } from "lib/map_utils";
import { Coordinates } from "pages/homev2/coordinates";
import { useCurrentUserGeoCoordinatesQuery } from "pages/homev2/hooks/use_current_user_geo_coordinates";
import { useHomeSearchParamsQuery } from "pages/homev2/hooks/use_home_search_params";
import { SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY } from "pages/homev2/hooks/use_search_history";
import { MapboxFeature, toCoordinates } from "pages/homev2/mapbox";

const DISTANCE_SHOW_DISTANCE_TEXT = 32000; // 32 km or 20 miles

// get the distance between the spaceCoordinate to the current location or the center marker
export function useGetDistance(spaceCoordinate: Coordinates) {
  const searchPlace = localStorage.getItem(
    SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY,
  );
  const searchHistory = searchPlace ? JSON.parse(searchPlace) : [];
  const lastSearchPlace: MapboxFeature = searchHistory[0];
  const {
    data: currentUserCoordinates,
    loading,
    error,
  } = useCurrentUserGeoCoordinatesQuery();
  const { coordinates: centerMapCoordinate } = useHomeSearchParamsQuery();

  let targetCoordinates: Coordinates | undefined;
  let distance: number | undefined;
  if (lastSearchPlace?.center) {
    targetCoordinates = toCoordinates(lastSearchPlace.center);
  } else {
    if (currentUserCoordinates) {
      targetCoordinates = currentUserCoordinates;
    }
  }

  if (
    targetCoordinates &&
    centerMapCoordinate &&
    calculateDistance(
      {
        latitude: centerMapCoordinate.lat,
        longitude: centerMapCoordinate.lng,
      },
      { latitude: targetCoordinates.lat, longitude: targetCoordinates.lng },
    ) < DISTANCE_SHOW_DISTANCE_TEXT
  ) {
    distance = calculateDistance(
      { latitude: spaceCoordinate.lat, longitude: spaceCoordinate.lng },
      { latitude: targetCoordinates.lat, longitude: targetCoordinates.lng },
    );
  }

  return {
    loading,
    distance,
    error,
  };
}
