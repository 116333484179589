import * as Sentry from "@sentry/react";
import React from "react";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Content } from "components/content";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";

interface ErrorBoundaryState {
  hasError: boolean;
  eventId: string;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, eventId: "" };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras({ errorInfo });
      const eventId = Sentry.captureException(error, scope);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Content>
          <Container>
            <Spacer size={80} />
            <Text size="lg" align="center">
              Something went wrong :(
            </Text>
            <Spacer size={8} />
            <Text align="center">
              This crash has been reported to us and we will work on resolving
              the issue as quickly as possible.
            </Text>
            <Spacer size={24} />
            <Row justifyContent="center">
              <Button
                text="Report feedback"
                appearance="secondary"
                onPress={() => {
                  Sentry.showReportDialog({ eventId: this.state.eventId });
                }}
              />
            </Row>
          </Container>
        </Content>
      );
    }

    return this.props.children;
  }
}
