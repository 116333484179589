import { gql, useQuery } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Card } from "components/card";

import { Container } from "components/container";
import { Content } from "components/content";
import { Heading } from "components/heading";
import { Icon } from "components/icon";
import { PageContainer } from "components/page_container";
import { PersonInfo } from "components/person_info";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Footer } from "core/footer";
import { BookingPolicyQuery } from "core/graphql.generated";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import { useProximityPolicyFeatureFlag } from "providers/splitio";
import { Pressable, StyleSheet, View } from "react-native";
import { ROUTES } from "../core/routes";

type BookableSpaceTypes = "day passes" | "meeting rooms" | "private offices";

export const UserProfileBookingPolicy = () => {
  const isPPFlag = useProximityPolicyFeatureFlag();
  const goBack = useGoBack(ROUTES.USER_PROFILE.path);
  const {
    deviceQuery: { desktop: isDesktop },
  } = useMediaQuery();

  const { loading, data, error } =
    useQuery<BookingPolicyQuery>(bookingPolicyQuery);

  const renderContent = (data: BookingPolicyQuery) => {
    const { organization, groupBudget } = data.currentUser || {};

    if (!organization?.budget) {
      return <Text>There is no booking policy in the organization</Text>;
    }

    const individualLimit =
      groupBudget?.individualLimit || organization?.budget.individualLimit;
    const monthlyLimit = groupBudget?.limit || organization?.budget.limit;
    const spaceTypesPolicy =
      groupBudget?.policy?.spaceTypes ||
      organization?.budget.policy?.spaceTypes;

    const locationItems =
      groupBudget?.policy?.locationItems ||
      organization?.budget.policy?.locationItems;

    let spendLimitMessage = "";
    if (individualLimit && monthlyLimit) {
      spendLimitMessage = `You can spend up to $${individualLimit} per month, up to the budget's $${monthlyLimit} monthly limit.`;
    } else if (!individualLimit && monthlyLimit) {
      spendLimitMessage = `You can spend up to $${monthlyLimit} of the budget's monthly limit.`;
    } else {
      spendLimitMessage = "No data";
    }

    const bookableSpaceTypes: BookableSpaceTypes[] = [];
    if (!spaceTypesPolicy) {
      bookableSpaceTypes.push("day passes", "meeting rooms", "private offices");
    } else {
      const { dayPass, meetingRoom, privateOffice } = spaceTypesPolicy;
      if (dayPass) {
        bookableSpaceTypes.push("day passes");
      }
      if (meetingRoom) {
        bookableSpaceTypes.push("meeting rooms");
      }
      if (privateOffice) {
        bookableSpaceTypes.push("private offices");
      }
    }

    const renderAdminsAndManagers = () => {
      const admins = organization.users;
      const adminIds = admins.map((a) => a.id);
      const managers =
        groupBudget?.members
          .filter((m) => m.role === "Manager" && !adminIds.includes(m.user.id))
          .map((m) => m.user) || [];
      const adminsAndManagers = admins
        .concat(managers)
        .sort((a, b) => a.fullName.localeCompare(b.fullName));

      return adminsAndManagers.map(({ fullName, email, id, picture }) => (
        <PersonInfo
          key={id}
          fullName={fullName}
          email={email || ""}
          picture={picture || undefined}
        />
      ));
    };

    return (
      <View style={isDesktop && styles.desktopContentWrapper}>
        <View style={styles.contentColumn}>
          <Card title="Spend limit">
            <Text>{spendLimitMessage}</Text>
          </Card>
          <Spacer size={24} />
          <Card title="Space types">
            {bookableSpaceTypes.length ? (
              <Text>
                You can book{" "}
                {bookableSpaceTypes.map((sP, i) => (
                  <Text key={i}>
                    <Text weight="semibold">{sP}</Text>
                    {i === bookableSpaceTypes.length - 1 ? "." : " and "}
                  </Text>
                ))}
              </Text>
            ) : (
              <Text>You cannot book any space type</Text>
            )}
          </Card>

          {isPPFlag && locationItems && locationItems.length > 0 && (
            <>
              <Spacer size={24} />
              <Card title="Locations">
                <View>
                  <Text>You can book spaces that are at least:</Text>
                  <View>
                    {locationItems.map((location) => (
                      <Text key={location.id}>
                        <Text weight={"semibold"}>{` ∙ ${
                          location.distance
                        } ${location.distanceUnit.toLowerCase()} `}</Text>
                        {"away from " + location.address.street}
                      </Text>
                    ))}
                  </View>
                </View>
              </Card>
              <Spacer size={24} />
            </>
          )}
        </View>
        <View style={styles.contentColumn}>
          <Card title="Out-of-policy bookings">
            <>
              <Text>
                If you wish to book a space that is outside of this booking
                policy, you must submit a request. Requests are sent to the
                following team members who manage your organization:
              </Text>
              <View style={styles.adminListWrapper}>
                {renderAdminsAndManagers()}
              </View>
            </>
          </Card>
        </View>
      </View>
    );
  };

  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Spacer size={32} />
        <Content>
          <Row alignItems="center">
            <Pressable onPress={goBack}>
              <Icon size="lg" name="arrow-left-circle" />
            </Pressable>
            <Spacer size={10} />
            <Heading size="h1">Booking policy</Heading>
          </Row>
          <Spacer size={16} />

          <AsyncRenderer loading={loading} error={error} data={data}>
            {renderContent}
          </AsyncRenderer>
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <Footer />
    </Container>
  );
};

const styles = StyleSheet.create({
  desktopContentWrapper: {
    flexDirection: "row",
    gap: 24,
  },
  contentColumn: {
    flex: 1,
  },
  adminListWrapper: {
    marginTop: 24,
    gap: 24,
  },
});

const bookingPolicyQuery = gql`
  query BookingPolicy {
    currentUser {
      organization {
        users(role: "admin") {
          ...User
        }
        budget {
          limit
          individualLimit
          policy {
            ...Policy
          }
        }
      }
      groupBudget {
        members {
          role
          user {
            ...User
          }
        }
        limit
        individualLimit
        policy {
          ...Policy
        }
      }
    }
  }

  fragment Policy on BudgetPolicy {
    spaceTypes {
      meetingRoom
      dayPass
      privateOffice
    }
    locationItems {
      id
      distance
      distanceUnit
      address {
        id
        street
      }
    }
  }

  fragment User on OrganizationUser {
    id
    fullName
    email
    picture
  }
`;
