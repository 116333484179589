import { useQuery } from "@apollo/client";
import React from "react";
import { View } from "react-native";
import { Carousel } from "react-responsive-carousel";
import { Content } from "components/content";
import { Spacer } from "components/spacer";
import {
  ReportingPageOrganizationReportingQuery,
  ReportingPageOrganizationReportingQueryVariables,
  ReportingPageReportingDataFragment,
} from "core/graphql.generated";
import { ReportingBookings } from "./bookings";
import { ReportingEngagement } from "./engagement";
import { ReportingFrequency } from "./frequency";
import { ReportingPayAsYouGoOverview } from "./pay_as_you_go_overview";
import { ReportingPayAsYouGoPromo } from "./pay_as_you_go_promo";
import { RecentActivity } from "./recent_activity";
import { ReportingSpend } from "./spend";
import { DayInterval } from "lib/day_utils";
import { ChartInCarouselWrapper } from "./chart_in_carousel_wrapper";
import { reportingPageOrganizationReportingGQLQuery } from "../gql_queries";
import { ReportingContentSkeletonMobile } from "./content_skeleton_mobile";
import { getLastUpdatedLabel } from "pages/reporting/utils/get_last_updated_label";

interface ReportingPagePayAsYouGoMobileProps {
  dayInterval: DayInterval;
  onPressGetStartedSubscription: () => void;
}

export function ReportingPayAsYouGoMobile(
  props: ReportingPagePayAsYouGoMobileProps,
) {
  const { dayInterval, onPressGetStartedSubscription } = props;
  const { loading: reportingLoading, data: reportingData } = useQuery<
    ReportingPageOrganizationReportingQuery,
    ReportingPageOrganizationReportingQueryVariables
  >(reportingPageOrganizationReportingGQLQuery, {
    variables: {
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
  });

  return (
    <View>
      {reportingLoading ||
      !reportingData?.currentUser?.organization?.reporting ? (
        <ReportingContentSkeletonMobile />
      ) : (
        <ReportingContent
          reporting={reportingData?.currentUser?.organization?.reporting}
          onPressGetStartedSubscription={onPressGetStartedSubscription}
        />
      )}
      <Spacer size={16} />
      <Content>
        <RecentActivity />
      </Content>
    </View>
  );
}

interface ReportingContentProps {
  reporting: ReportingPageReportingDataFragment;
  onPressGetStartedSubscription: () => void;
}

function ReportingContent(props: ReportingContentProps) {
  const { reporting, onPressGetStartedSubscription } = props;
  const labelUpdatedAt = getLastUpdatedLabel(reporting.lastUpdatedDate);

  return (
    <Content>
      <View style={{ height: 142 }}>
        <ReportingPayAsYouGoOverview totalSpend={reporting.spend.totalSpend} />
      </View>
      <Spacer size={16} />
      <ReportingPayAsYouGoPromo onPress={onPressGetStartedSubscription} />
      <Spacer size={16} />
      <Carousel showIndicators={true}>
        <ChartInCarouselWrapper>
          <ReportingSpend
            dayPasses={reporting.spend.dayPasses}
            dayOffices={reporting.spend.dayOffices}
            meetingRooms={reporting.spend.meetingRooms}
            totalSpend={reporting.spend.totalSpend}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ChartInCarouselWrapper>
        <ChartInCarouselWrapper>
          <ReportingBookings
            dayPasses={reporting.bookings.dayPasses}
            dayOffices={reporting.bookings.dayOffices}
            meetingRooms={reporting.bookings.meetingRooms}
            totalBookings={reporting.bookings.totalBookings}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ChartInCarouselWrapper>
        <ChartInCarouselWrapper>
          <ReportingEngagement
            soloBookings={reporting.engagement.soloBookings}
            teamBookings={reporting.engagement.teamBookings}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ChartInCarouselWrapper>
        <ChartInCarouselWrapper>
          <ReportingFrequency
            zeroOrSingleBookingPerWeek={
              reporting.frequency.zeroOrSingleBookingPerWeek
            }
            twoOrThreeBookingsPerWeek={
              reporting.frequency.twoOrThreeBookingsPerWeek
            }
            fourOrMoreBookingsPerWeek={
              reporting.frequency.fourOrMoreBookingsPerWeek
            }
            totalEmployees={reporting.frequency.totalEmployees}
            labelUpdatedAt={labelUpdatedAt}
          />
        </ChartInCarouselWrapper>
      </Carousel>
    </Content>
  );
}
