import {
  defaultInitialPlace,
  InitialPlaceData,
} from "pages/homev2/hooks/use_initial_place";
import { useEffect, useState } from "react";
import { useCurrentUserGeoPlaceQuery } from "../../homev2/hooks/use_current_user_geo_place";
import { useCurrentUserIpPlaceQuery } from "../../homev2/hooks/use_current_user_ip_place";

/**
 * isDefault means if the current location is geoPlace, ipPlace
 */
export function useUserCurrentLocation() {
  const {
    data: geoPlace,
    loading: geoPlaceLoading,
    error: geoPlaceError,
  } = useCurrentUserGeoPlaceQuery();
  const {
    data: ipPlace,
    loading: ipPlaceLoading,
    error: ipPlaceError,
  } = useCurrentUserIpPlaceQuery({
    skip: !geoPlaceLoading && !!geoPlace,
  });

  const [currentLocation, setCurrentLocation] = useState<InitialPlaceData>(
    defaultInitialPlace as InitialPlaceData,
  );
  const [isLoading, setIsLoading] = useState(ipPlaceLoading);

  useEffect(() => {
    if (ipPlaceLoading) {
      return;
    }
    // 1. place from geo location
    /**
     * Geoplace may not resolve yet when user doesnt click accept or decline for geolocation
     * But ipPlace may already be set here so we wanna set the location when geolocation has resolved
     * But also geoPlace may be quicker than ipPlace this useEffect wil re run after ipPlace resolved
     * In that case geoPlace has already resolved and set so we are checking for id here to prevent extra rerender on the components
     * that uses this hook.
     */
    if (geoPlace && geoPlace.id !== currentLocation?.data?.id) {
      const geoCoordinatesData = {
        data: geoPlace,
        loading: geoPlaceLoading,
        error: geoPlaceError,
        isDefault: true,
      };
      setCurrentLocation(geoCoordinatesData);
      setIsLoading(false);
      return;
    }

    // 2. place from ip location
    /**
     * When ip place is used geoPlace may result with an error or rejection in that case
     * this useEffect will re-run. But we dont wanna set the place again since it is already resolved with ipPlace
     * That is why we are checking the currentLocation id here which will prevent extra re-renders on the components
     * That will use this hook.
     */
    if (ipPlace && ipPlace.id !== currentLocation.data?.id) {
      const ipPlaceData = {
        data: ipPlace,
        loading: ipPlaceLoading,
        error: ipPlaceError,
        isDefault: true,
      };
      setCurrentLocation(ipPlaceData);
      setIsLoading(false);
      return;
    }

    // 3. use default data
    setIsLoading(false);
  }, [
    geoPlace,
    geoPlaceError,
    geoPlaceLoading,
    ipPlace,
    ipPlaceError,
    ipPlaceLoading,
    currentLocation.data?.id,
  ]);

  return {
    data: currentLocation.data,
    loading: isLoading,
    error: geoPlaceError || ipPlaceError,
  };
}
