import { Avatar } from "components/avatar";
import { Button } from "components/button_v2";
import { gql, useQuery } from "@apollo/client";
import { colors } from "components/colors";
import { DialogModal } from "components/dialog_modal";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { useCallback, useEffect, useRef, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { OrganizationUser, PeoplePickerQuery } from "core/graphql.generated";
import { Divider } from "components/divider";
import { useMediaQuery } from "lib/media_query";
import { getSystemLocale } from "lib/locale";

const searchPeople = (people: OrganizationUser[], searchValue: string) =>
  people.filter(
    (person) =>
      person.fullName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      person.email?.toLowerCase().includes(searchValue.toLowerCase()),
  );

interface AddUserModalPropTypes {
  isModalOpen: boolean;
  label: string;
  value: OrganizationUser[];
  searchList: OrganizationUser[];
  onChange: (people: OrganizationUser[]) => Promise<void>;
  onClose: () => void;
  isAddBtnLoading?: boolean;
  clearSelectedPeople?: boolean;
}

export function AddUserModal({
  isModalOpen,
  label,
  searchList,
  value: membersValue,
  onChange: onChangeMembers,
  onClose,
  isAddBtnLoading,
  clearSelectedPeople = false,
}: AddUserModalPropTypes) {
  const mq = useMediaQuery();
  const [searchValue, setSearchValue] = useState("");
  const [showingSearchResults, setShowingSearchResults] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [currentSelection, setCurrentSelection] = useState(0);
  const [selectedPeople, setSelectedPeople] = useState<OrganizationUser[]>(
    membersValue ? [...membersValue] : [],
  );
  const searchResults = (
    searchValue ? searchPeople(searchList, searchValue) : searchList
  )
    .filter((user) => !selectedPeople.some((sP) => sP.id === user.id))
    .slice(0, 5)
    .sort((a, b) => {
      if (a.fullName && b.fullName) {
        return a.fullName.localeCompare(b.fullName, getSystemLocale());
      }
      return 0;
    });

  /**
   * TODO
   * This fixes the issue related to after deactivate, member stays
   * in AddUserModals local state of selectedPeople
   * I dont understand why after deactivate previous users are inside the state
   * secondly the users in that state has groupBudgetRole field but not groupBudget field
   * which shouldnt happen at all.
   */
  useEffect(() => {
    if (selectedPeople.some((p) => p.groupBudgetRole && !p.groupBudget)) {
      setSelectedPeople([]);
    }
  }, [selectedPeople]);
  const showSearchResults = useCallback(
    () => setShowingSearchResults(true),
    [],
  );
  const hideSearchResults = useCallback(
    () => setShowingSearchResults(false),
    [],
  );
  const onKeyPress = useCallback(
    (e) => {
      if (e.key === "Escape") {
        e.target?.blur?.();
        hideSearchResults();
      } else if (e.key === "Enter") {
        if (searchResults[currentSelection]) {
          setSelectedPeople((s) => [
            ...s,
            searchResults[currentSelection] as OrganizationUser,
          ]);
          hideSearchResults();
          setCurrentSelection(0);
        }
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setCurrentSelection((curSel) =>
          curSel + 1 < searchResults.length ? curSel + 1 : curSel,
        );
      } else if (e.key === "ArrowUp") {
        setCurrentSelection((curSel) => (curSel - 1 >= 0 ? curSel - 1 : 0));
      }
    },
    [currentSelection, hideSearchResults, searchResults],
  );

  const submit = useCallback(async () => {
    await onChangeMembers(selectedPeople);
    if (clearSelectedPeople) {
      setSelectedPeople([]);
    }
  }, [clearSelectedPeople, onChangeMembers, selectedPeople]);
  const removePerson = useCallback((id) => {
    setSelectedPeople((curSelPeople) =>
      curSelPeople.filter((sP) => sP.id !== id),
    );
  }, []);

  return (
    <DialogModal
      isVisible={isModalOpen}
      headerTitle={label}
      onClose={onClose}
      desktopWidth={780}
      bottomButtons={
        <View style={{ minWidth: 240 }}>
          <Button
            testID="people-picker-submit-button"
            text="Add"
            onPress={submit}
            loading={isAddBtnLoading}
          />
        </View>
      }
    >
      <View style={[!mq.deviceQuery.mobile && styles.content]}>
        <DropdownV2
          open={showingSearchResults && !!searchResults.length}
          onRequestClose={hideSearchResults}
          sameWidth
          content={
            <View style={styles.dropdownContent}>
              {searchResults?.map((sR, sRIndex) => (
                <View key={sR.id}>
                  <Pressable
                    testID={`people-picker-select-index-${sRIndex}`}
                    key={sR.id}
                    onPress={() => {
                      setSelectedPeople((s) => [...s, sR]);
                      hideSearchResults();
                      setCurrentSelection(0);
                    }}
                  >
                    <View
                      style={[
                        styles.searchOption,
                        currentSelection === sRIndex &&
                          styles.searchOptionSelected,
                      ]}
                    >
                      <Avatar
                        size="lg"
                        name={sR.fullName}
                        source={{ uri: sR.picture || "" }}
                      />
                      <Spacer size={8} direction="row" />
                      <View style={styles.searchOptionInfo}>
                        <Text color="black-core" size="xs">
                          {sR.fullName}
                        </Text>
                        <Spacer size={4} />
                        <Text color="black-70" size="xs">
                          {sR.email}
                        </Text>
                        {sR.groupBudgetRole && mq.deviceQuery.mobile && (
                          <>
                            <Spacer size={4} />
                            <Text color="black-50" size="xs">
                              {sR.groupBudgetRole} of the {sR.groupBudget?.name}{" "}
                              budget
                            </Text>
                          </>
                        )}
                      </View>
                      {sR.groupBudgetRole && !mq.deviceQuery.mobile && (
                        <>
                          <Spacer size={8} direction="row" />
                          <Text color="black-50" size="xs">
                            {sR.groupBudgetRole} of the {sR.groupBudget?.name}{" "}
                            budget
                          </Text>
                        </>
                      )}
                    </View>
                  </Pressable>
                  <Divider color="lighter" />
                </View>
              ))}
            </View>
          }
        >
          <TextField
            testID="people-picker-search-input"
            onFocus={showSearchResults}
            onKeyPress={onKeyPress}
            ref={inputRef}
            value={searchValue}
            onChange={setSearchValue}
            placeholder="Search by name or email"
          />
        </DropdownV2>
        <Spacer size={24} />
        <View style={styles.selectPeopleWrapper}>
          {selectedPeople.map((sP) => (
            <View key={sP.id}>
              <View style={styles.searchOption}>
                <Avatar
                  size="lg"
                  name={sP.fullName}
                  source={{ uri: sP.picture || "" }}
                />
                <Spacer size={8} direction="row" />
                <View style={styles.searchOptionInfo}>
                  <Text color="black-core" size="xs">
                    {sP.fullName}
                  </Text>
                  <Spacer size={4} />
                  <Text color="black-70" size="xs">
                    {sP.email}
                  </Text>
                  {sP.groupBudgetRole && mq.deviceQuery.mobile && (
                    <>
                      <Spacer size={4} />
                      <Text color="black-50" size="xs">
                        {sP.groupBudgetRole} of the {sP.groupBudget?.name}{" "}
                        budget
                      </Text>
                    </>
                  )}
                </View>
                {sP.groupBudgetRole && mq.deviceQuery.desktop && (
                  <>
                    <Spacer size={80} direction="row" />
                    <Text color="black-50" size="xs">
                      {sP.groupBudgetRole} of the {sP.groupBudget?.name} budget
                    </Text>
                    <Spacer size={80} direction="row" />
                  </>
                )}
                <Pressable onPress={() => removePerson(sP.id)}>
                  <Icon name="trash-bin" />
                </Pressable>
              </View>
              <Divider color="lighter" />
            </View>
          ))}
        </View>
      </View>
    </DialogModal>
  );
}

interface OpenModalButtonPropTypes {
  buttonText: string;
  renderModal: (
    isModalOpen: boolean,
    onOpen: () => void,
    onClose: () => void,
  ) => void;
  renderButton?: (openModal: () => void) => void;
  testID?: string;
}

export function OpenModalButton(props: OpenModalButtonPropTypes) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <View>
      {props.renderButton ? (
        props.renderButton(() => setIsModalOpen(true))
      ) : (
        <Pressable testID={props.testID} onPress={() => setIsModalOpen(true)}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Icon name="plus" size="sm" color="eggplantcore" />
            <Spacer size={10} direction="row" />
            <Text color="eggplant-core" size="xs" weight="semibold">
              {props.buttonText}
            </Text>
          </View>
        </Pressable>
      )}
      {props.renderModal(
        isModalOpen,
        () => setIsModalOpen(true),
        () => setIsModalOpen(false),
      )}
    </View>
  );
}

interface PeoplePickerPropTypes {
  label: string;
  value: OrganizationUser[];
  onChange: (people: OrganizationUser[]) => void;
  buttonText: string;
  testID?: string;
  onFilter?: (people: OrganizationUser[]) => OrganizationUser[];
}

export const PeoplePicker = (props: PeoplePickerPropTypes) => {
  const { data: peoplePickerQueryResult } = useQuery<PeoplePickerQuery>(
    peoplePickerQuery,
    {},
  );
  let fetchedUsers =
    peoplePickerQueryResult?.currentUser?.organization?.users || [];
  if (props.onFilter) {
    fetchedUsers = props.onFilter(fetchedUsers as OrganizationUser[]);
  }

  return (
    <View>
      {props.value?.map((person) => (
        <View key={person.id} style={styles.searchOption}>
          <Avatar
            size="lg"
            name={person.fullName}
            source={{ uri: person.picture || "" }}
          />
          <Spacer size={8} direction="row" />
          <View style={styles.searchOptionInfo}>
            <Text color="black-core" size="xs">
              {person.fullName}
            </Text>
            <Spacer size={4} />
            <Text color="black-70" size="xs">
              {person.email}
            </Text>
          </View>
        </View>
      ))}
      {!!props.value?.length && <Spacer size={11} />}

      <OpenModalButton
        testID={props.testID}
        buttonText={props.buttonText}
        renderModal={(isModalOpen, _onOpenModal, onClose) => (
          <AddUserModal
            isModalOpen={isModalOpen}
            label={props.label}
            value={props.value}
            searchList={fetchedUsers as OrganizationUser[]}
            onChange={async (people) => {
              props.onChange(people);
              onClose();
            }}
            onClose={onClose}
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    minHeight: 315,
  },
  dropdownContent: {
    overflow: "scroll",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: colors.brand.whitecore,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    padding: 24,
    minWidth: 400,
  },
  searchOption: {
    paddingVertical: 8,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    radius: 4,
  },
  searchOptionSelected: {
    backgroundColor: colors.brand.eggplantMinus90,
    padding: 4,
  },
  searchOptionInfo: {
    flexGrow: 1,
  },
  selectPeopleWrapper: {
    flexGrow: 1,
  },
});

export const peoplePickerQuery = gql`
  query PeoplePicker {
    currentUser {
      id
      groupBudgetRole
      organization {
        id
        users {
          id
          userId
          role
          fullName
          email
          picture
          groupBudgetRole
          groupBudget {
            id
            name
            members {
              role
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;
