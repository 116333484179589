import "./people.css";

import React, { Fragment } from "react";

import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { useMediaQuery } from "lib/media_query";
import { OfficeSpaceBooking, OfficeSpaceCheckin } from "./graphql.generated";
import { Avatar } from "components/avatar";

export interface Person {
  userID: string;
  name: string;
  title?: string | null;
  pictureURL: string;
  status: "on" | "off";
}

interface PeopleProps {
  people: Person[];
}

const MAX_DISPLAY = 7;

export function People(props: PeopleProps) {
  const { people } = props;
  const peopleToDisplay = people.slice(0, MAX_DISPLAY);
  const peopleRemainingCount = people.length - MAX_DISPLAY;
  const mq = useMediaQuery();

  return (
    <Container>
      <Row justifyContent="flex-start" alignItems="center">
        {peopleToDisplay.map((person, i) => (
          <Fragment key={person.userID}>
            <div className="people-person-container" key={i}>
              <Avatar
                name={person.name}
                source={{
                  uri: person.pictureURL,
                }}
              />
              {person.status === "on" && (
                <div className="people-status-circle" />
              )}
            </div>
            <Spacer size={mq.sizeQuery.mdAndUp ? 4 : 8} />
          </Fragment>
        ))}
        {peopleRemainingCount > 0 && (
          <div className="people-more-people-container">
            <Text weight="400">{peopleRemainingCount}+</Text>
          </div>
        )}
      </Row>
    </Container>
  );
}

export function officeCheckinAndBookingPeople(
  checkins: OfficeSpaceCheckin[],
  bookings: OfficeSpaceBooking[],
  currentUserId: string,
): Person[] {
  return checkinAndBookingPeople(checkins, bookings, currentUserId);
}

interface User {
  id: string;
  name: string;
  avatar: string;
  title?: string | null;
}

interface Checkin {
  user: User;
  moment: string;
}

interface Booking {
  user: User;
}

function checkinAndBookingPeople(
  checkins: Checkin[],
  bookings: Booking[],
  currentUserId: string,
): Person[] {
  const displayedUserIds = new Set();

  const filteredCheckins = checkins.filter((c) => c.user.id !== currentUserId);
  const filteredBookings = bookings.filter((b) => b.user.id !== currentUserId);
  const currentUserCheckin = checkins.find((c) => c.user.id === currentUserId);
  const currentUserBooking = bookings.find((b) => b.user.id === currentUserId);

  filteredCheckins.sort((a, b) => -a.moment.localeCompare(b.moment));
  const results: Person[] = [];
  if (currentUserCheckin) {
    results.push({
      userID: currentUserCheckin.user.id,
      name: currentUserCheckin.user.name,
      pictureURL: currentUserCheckin.user.avatar,
      title: currentUserCheckin.user.title,
      status: "on",
    });
  } else if (currentUserBooking) {
    results.push({
      userID: currentUserBooking.user.id,
      name: currentUserBooking.user.name,
      pictureURL: currentUserBooking.user.avatar,
      title: currentUserBooking.user.title,
      status: "off",
    });
  }

  for (const checkin of filteredCheckins) {
    results.push({
      userID: checkin.user.id,
      name: checkin.user.name,
      pictureURL: checkin.user.avatar,
      title: checkin.user.title,
      status: "on",
    });
    displayedUserIds.add(checkin.user.id);
  }

  for (const booking of filteredBookings) {
    if (!displayedUserIds.has(booking.user.id)) {
      results.push({
        userID: booking.user.id,
        name: booking.user.name,
        pictureURL: booking.user.avatar,
        title: booking.user.title,
        status: "off",
      });
      displayedUserIds.add(booking.user.id);
    }
  }

  return results;
}
