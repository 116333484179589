import { gql, useQuery } from "@apollo/client";
import { SearchBar } from "components/app_header_v3/search_bar";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { InAppInvite } from "components/in_app_invite";
import { LinkWrapper } from "components/link_wrapper";
import { Spacer } from "components/spacer";
import {
  AppHeaderV3DesktopQuery,
  GroupBudgetRole,
  Plan,
  UserRole,
} from "core/graphql.generated";
import { ProfileDropDownMenu } from "core/profile_dropdown_menu";
import { urlLocationSearchState } from "pages/homev2/utils/url_location_search_atom";
import { signInModalState } from "providers/app_loader";
import { useAuthV2 } from "providers/authv2";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useRecoilState, useRecoilValue } from "recoil";
import { useAnalytics } from "../../providers/analytics";

export const APP_HEADER_HEIGHT = 72;
export const CURRENT_URL = "CURRENT_URL";

interface AppHeaderV3DesktopProps {
  withSearch: boolean;
  linkToSearchPage?: boolean;
  onSearch?: (field: string) => void;
}

export function AppHeaderV3Desktop(props: AppHeaderV3DesktopProps) {
  const { withSearch, linkToSearchPage = false, onSearch } = props;
  const [showAppInvite, setShowAppInvite] = useState(false);
  const [, setShowLoginModal] = useRecoilState(signInModalState);
  const analytics = useAnalytics();
  const { authenticated } = useAuthV2();
  const { data: currentUserQuery } =
    useQuery<AppHeaderV3DesktopQuery>(desktopHeaderQuery);
  const currentUser = currentUserQuery?.currentUser;
  const isAdmin = currentUser?.role === UserRole.Admin;
  const isBudgetManager =
    currentUser?.groupBudgetRole === GroupBudgetRole.Manager;
  const isPro = currentUser?.organization?.plan === Plan.Pro;
  const { homeUrl } = useRecoilValue(urlLocationSearchState);

  const showLoginModal = () => {
    analytics.event("Click Header Login");
    setShowLoginModal(true);
  };

  return (
    <>
      <View style={styles.header}>
        <View style={styles.headerInnerWrapper}>
          <LinkWrapper to={homeUrl}>
            <img
              alt="flexspace-logo"
              src="https://meet-production.s3.us-west-1.amazonaws.com/logos/flexspace-logo-default.svg"
              style={{ height: 36 }}
            />
          </LinkWrapper>
          {withSearch && (
            <SearchBar
              onSearch={onSearch}
              linkToSearchPage={linkToSearchPage}
            />
          )}

          <View style={styles.headerRightView}>
            {!authenticated && (
              <Button
                testID="login-or-signup-header-button"
                text={"Login or sign up"}
                onPress={showLoginModal}
                appearance={"secondary"}
              />
            )}
            <Spacer direction={"row"} size={16} />

            <View style={{ minWidth: authenticated ? 189 : 0 }}>
              <ProfileDropDownMenu
                currentUser={currentUser}
                isAdmin={isAdmin}
                isBudgetManager={isBudgetManager}
                onInviteClick={setShowAppInvite}
                isPro={isPro}
              />
            </View>
          </View>
        </View>
      </View>
      <InAppInvite
        isVisible={showAppInvite}
        onClose={() => setShowAppInvite(false)}
        analyticsFrom="Primary navigation"
        onSuccess={() => setShowAppInvite(false)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: colors.brand.blackMinus90,
  },
  headerRightView: {
    flexDirection: "row",
  },
  headerInnerWrapper: {
    // @ts-ignore
    boxSizing: "border-box",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    height: APP_HEADER_HEIGHT,
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    zIndex: 2,
    backgroundColor: colors.brand.whitecore,
  },
  // @ts-ignore
  unTouchAbleView: { pointerEvents: "none" },
});

export const desktopHeaderQuery = gql`
  query AppHeaderV3Desktop {
    currentUser {
      id
      role
      picture
      fullName
      groupBudgetRole
      groupBudget {
        id
        name
      }
      organization {
        id
        paymentMode
        plan
      }
    }
  }
`;
