import { DESKTOP_APP_HEADER_HEIGHT } from "components/app_header_v3/app_header";
import { colors } from "components/colors";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/icon";
import { Icon as IconV2 } from "components/iconv2";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { useMediaQuery } from "lib/media_query";
import {
  CurrentLocationOption,
  SearchOption,
} from "pages/homev2/components/search_bar_option";
import {
  useCurrentUserGeoPlaceLazyQuery,
  useCurrentUserGeoPlaceQuery,
} from "pages/homev2/hooks/use_current_user_geo_place";
import { useForwardGeocoding } from "pages/homev2/hooks/use_forward_geocoding";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "pages/homev2/hooks/use_home_search_params";
import { useInitialPlace } from "pages/homev2/hooks/use_initial_place";
import { useSearchHistory } from "pages/homev2/hooks/use_search_history";
import { MapboxFeature, toCoordinates } from "pages/homev2/mapbox";
import { useAnalytics } from "providers/analytics";
import { useToast } from "providers/toast";
import { Fragment, useCallback, useEffect, useReducer, useRef } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import "./search_input.css";

interface State {
  initialized: boolean;
  showAutocomplete: boolean;
  focused: boolean;
  search: string;
  selectedItemIndex: number;
}

type Action =
  | {
      type: "SET_SHOW_AUTOCOMPLETE";
      showAutocomplete: boolean;
    }
  | {
      type: "FOCUS";
    }
  | {
      type: "BLUR";
    }
  | {
      type: "FILL_SEARCH";
      search: string;
    }
  | {
      type: "UPDATE_SEARCH";
      search: string;
    }
  | {
      type: "INITIALIZE";
      initialized: boolean;
      search: string;
    }
  | {
      type: "SET_SELECTED_ITEM_INDEX";
      index: number;
    };

function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case "SET_SHOW_AUTOCOMPLETE":
      return {
        ...prevState,
        showAutocomplete: action.showAutocomplete,
      };
    case "UPDATE_SEARCH":
      return {
        ...prevState,
        search: action.search,
        showAutocomplete: true,
        selectedItemIndex: 0,
      };
    case "FILL_SEARCH":
      return {
        ...prevState,
        search: action.search,
        showAutocomplete: false,
        focused: false,
        selectedItemIndex: 0,
      };
    case "FOCUS":
      return {
        ...prevState,
        focused: true,
      };
    case "BLUR":
      return {
        ...prevState,
        focused: false,
        selectedItemIndex: 0,
      };
    case "INITIALIZE":
      return {
        ...prevState,
        search: action.search,
        initialized: action.initialized,
      };
    case "SET_SELECTED_ITEM_INDEX":
      return {
        ...prevState,
        selectedItemIndex: action.index,
      };

    default:
      throw new Error(`Action not handled ${action}`);
  }
}

const initialState: State = {
  initialized: false,
  showAutocomplete: false,
  focused: false,
  search: "",
  selectedItemIndex: 0,
};

interface HomeSearchInputProps {
  onSearch?: (field: string) => void;
}

export function HomeSearchInput(props: HomeSearchInputProps) {
  const { onSearch } = props;
  const { searchHistory, addToSearchHistory } = useSearchHistory();
  const searchParams = useHomeSearchParamsQuery();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const initialPlaceQuery = useInitialPlace();
  const currentUserGeoPlace = useCurrentUserGeoPlaceQuery();
  const [currentUserGeoPlaceLazyQuery] = useCurrentUserGeoPlaceLazyQuery();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search, initialized, focused, showAutocomplete, selectedItemIndex } =
    state;
  const { data: searchParamsPlace, loading: searchParamsPlaceLoading } =
    useForwardGeocoding(searchParams.placeId, {
      skip: !searchParams.placeId,
    });

  const mq = useMediaQuery();
  const toast = useToast();
  const analytics = useAnalytics();

  useEffect(() => {
    // we preload search bar with data and only then
    // firstly we load from placeId if there exists one (which is used only in this component)
    // otherwise we reload from default initial place (which is used everywhere else)
    if (!initialized && !searchParamsPlaceLoading && initialPlaceQuery.data) {
      dispatch({
        type: "INITIALIZE",
        initialized: !!initialPlaceQuery.initialized,
        search:
          searchParamsPlace && searchParamsPlace[0]
            ? searchParamsPlace[0].text
            : initialPlaceQuery.data.text,
      });
    }
  }, [
    initialized,
    searchParamsPlaceLoading,
    initialPlaceQuery.data,
    searchParamsPlace,
    initialPlaceQuery.initialized,
  ]);

  useEffect(() => {
    if (initialized && !searchParamsPlaceLoading && searchParamsPlace) {
      dispatch({
        type: "FILL_SEARCH",
        search: searchParams.mapSearch ? "Map area" : searchParamsPlace[0].text,
      });
    }
  }, [
    initialized,
    searchParamsPlaceLoading,
    searchParamsPlace,
    searchParams.mapSearch,
  ]);

  const handleSelectCurrentLocation = useCallback(() => {
    if (currentUserGeoPlace && currentUserGeoPlace.data) {
      dispatch({
        type: "FILL_SEARCH",
        search: currentUserGeoPlace.data.place_name,
      });
      updateHomeSearchParams({
        coordinates: toCoordinates(currentUserGeoPlace.data.center),
        bbox: currentUserGeoPlace.data?.bbox,
      });

      if (onSearch) {
        onSearch("Location");
      }
    } else {
      currentUserGeoPlaceLazyQuery()
        .then((place) => {
          dispatch({
            type: "FILL_SEARCH",
            search: place.text,
          });
          updateHomeSearchParams({
            coordinates: toCoordinates(place.center),
            bbox: place.bbox,
          });

          if (onSearch) {
            onSearch("Location");
          }
        })
        .catch(() => {
          toast.notify({
            message: "Please enable location permissions in browser settings",
          });
        });
    }
  }, [
    currentUserGeoPlace,
    updateHomeSearchParams,
    onSearch,
    currentUserGeoPlaceLazyQuery,
    toast,
  ]);

  const handleSelectPlace = useCallback(
    (place: MapboxFeature) => {
      addToSearchHistory(place);
      dispatch({
        type: "FILL_SEARCH",
        search: place.text,
      });
      updateHomeSearchParams({
        coordinates: toCoordinates(place.center),
        bbox: place.bbox,
        placeId: place.id,
      });

      analytics.event("Search Place", {
        "Search Text": search,
        "Place Name": place.place_name,
      });

      if (onSearch) {
        onSearch("Location");
      }
    },
    [addToSearchHistory, updateHomeSearchParams, analytics, search, onSearch],
  );

  const { data: autocompleteOptions, loading: autocompleteLoading } =
    useForwardGeocoding(search, {
      skip: !showAutocomplete,
    });

  const handleSearchChange = useCallback((nextSearch: string) => {
    dispatch({ type: "UPDATE_SEARCH", search: nextSearch });
  }, []);

  const handleFocus = useCallback(() => {
    dispatch({ type: "FOCUS" });
    inputRef.current?.select();
  }, []);
  const handleBlur = useCallback(() => {
    dispatch({ type: "BLUR" });
  }, []);

  const showingAutocomplete = showAutocomplete && search?.length;

  const onKeyPress = useCallback(
    (e) => {
      if (e.key === "Escape") {
        e.target?.blur?.();
        handleBlur();
      } else if (e.key === "Enter") {
        if (showingAutocomplete && autocompleteOptions?.[selectedItemIndex]) {
          handleSelectPlace(autocompleteOptions?.[selectedItemIndex]);
        } else if (!showingAutocomplete && selectedItemIndex < 2) {
          handleSelectCurrentLocation();
        } else {
          handleSelectPlace(searchHistory?.[selectedItemIndex - 2]);
        }
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        const searchResults = showingAutocomplete
          ? autocompleteOptions
          : [...searchHistory, null, null];
        return dispatch({
          type: "SET_SELECTED_ITEM_INDEX",
          index:
            selectedItemIndex < searchResults?.length! - 1
              ? selectedItemIndex + 1
              : 0,
        });
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        const searchResults = showingAutocomplete
          ? autocompleteOptions
          : [...searchHistory, null, null];
        return dispatch({
          type: "SET_SELECTED_ITEM_INDEX",
          index:
            selectedItemIndex > 0
              ? selectedItemIndex - 1
              : searchResults?.length! - 1,
        });
      }
    },
    [
      autocompleteOptions,
      handleBlur,
      handleSelectCurrentLocation,
      handleSelectPlace,
      searchHistory,
      selectedItemIndex,
      showingAutocomplete,
    ],
  );

  const renderedSearchResult = showingAutocomplete ? (
    autocompleteLoading ? (
      <Spinner />
    ) : autocompleteOptions && autocompleteOptions?.length > 0 ? (
      autocompleteOptions?.map((place, i) => (
        <Fragment key={`${place.id}-autocomplete`}>
          {i > 0 && (
            <View style={{ paddingHorizontal: 16 }}>
              <Divider />
            </View>
          )}
          <SearchOption
            key={place.id}
            place={place}
            onPress={handleSelectPlace}
            isSelected={selectedItemIndex === i}
          />
        </Fragment>
      ))
    ) : (
      <View style={styles.emptySearch}>
        <Text size="xs" weight="semibold">
          Hmm...we can’t find this location.
        </Text>
        <Spacer size={8} />
        <Text size="xs">
          Try double-checking your spelling or broadening your search to a city
          or region.
        </Text>
      </View>
    )
  ) : (
    <View>
      <CurrentLocationOption
        onPress={handleSelectCurrentLocation}
        label={currentUserGeoPlace.data?.place_name || ""}
        isSelected={selectedItemIndex === 1}
      />
      {searchHistory.length > 0 && (
        <>
          <View style={{ padding: 16, paddingBottom: 8 }}>
            <Text size="xs" customColor="#000" weight="semibold">
              Recent searches
            </Text>
          </View>
          {searchHistory.map((place, i) => (
            <Fragment key={`${place.id}-search-history`}>
              {i > 0 && (
                <View style={{ paddingHorizontal: 16 }}>
                  <Divider color="dark" />
                </View>
              )}
              <SearchOption
                key={place.id}
                place={place}
                onPress={handleSelectPlace}
                isSelected={selectedItemIndex === i + 2}
              />
            </Fragment>
          ))}
        </>
      )}
    </View>
  );

  const renderedTextField = (
    <TextField
      testID="home-search-bar"
      onKeyPress={onKeyPress}
      value={search}
      ref={inputRef}
      onFocus={handleFocus}
      onChange={handleSearchChange}
      leftIcon={<IconV2 name="location-area" />}
      rightIcon={
        !!search?.length && (
          <Icon size="sm" color={colors.brand.blackMinus30} name="x-circle" />
        )
      }
      onRightIconClick={() => {
        handleSearchChange("");
        inputRef.current?.focus();
      }}
      borderless={true}
    />
  );

  if (mq.deviceQuery.mobile) {
    return (
      <>
        <Pressable
          testID="mobile-search-button"
          onPress={handleFocus}
          style={[styles.mobileBtnWrapper, styles.mobileBtnAmenityWrapper]}
        >
          <View style={styles.mobileBtnAmenityWrapper}>
            <TextField
              testID="home-search-bar-placeholder"
              value={search}
              leftIcon={<Icon name="magnifying-glass" />}
              rightIcon={
                !!search?.length && (
                  <Icon
                    size="sm"
                    color={colors.brand.blackMinus30}
                    name="x-circle"
                  />
                )
              }
            />
          </View>
        </Pressable>
        <DialogModal
          isVisible={!!focused}
          title="Set my location"
          onClose={handleBlur}
        >
          <View>
            {renderedTextField}
            <View style={{ marginHorizontal: -16 }}>
              {renderedSearchResult}
            </View>
          </View>
        </DialogModal>
      </>
    );
  }

  if (mq.deviceQuery.mobile) {
    return (
      <>
        <Pressable
          testID="mobile-search-button"
          onPress={handleFocus}
          style={styles.mobileBtnWrapper}
        >
          <Text numberOfLines={1} size="xs" weight="semibold">
            {search}
          </Text>
          <Spacer direction="row" size={5} />
          <Icon name="feather-chevron-down" />
        </Pressable>
        <DialogModal
          isVisible={!!focused}
          title="Set my location"
          onClose={handleBlur}
        >
          <View>
            {renderedTextField}
            <View style={{ marginHorizontal: -16 }}>
              {renderedSearchResult}
            </View>
          </View>
        </DialogModal>
      </>
    );
  }

  return (
    <View>
      {focused && (
        <div
          onClick={handleBlur}
          className="search_input__backdrop"
          style={{ top: DESKTOP_APP_HEADER_HEIGHT }}
        />
      )}
      <DropdownV2
        open={focused}
        onRequestClose={handleBlur}
        // sameWidth={false}
        // sameMinWidth={true}
        content={
          <View style={styles.dropdownContent}>{renderedSearchResult}</View>
        }
      >
        {renderedTextField}
      </DropdownV2>
    </View>
  );
}

const styles = StyleSheet.create({
  dropdownContent: {
    overflow: "scroll",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: tokens.colors.base.white,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    paddingVertical: 8,
    minWidth: 400,
  },
  mobileBtnWrapper: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
    minWidth: 0,
    flexShrink: 1,
    maxWidth: "70%",
  },
  mobileBtnAmenityWrapper: {
    width: "100%",
    maxWidth: "100%",
  },
  mobileBtnTextWrapper: {
    minWidth: 0,
    flexShrink: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  emptySearch: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
