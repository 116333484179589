import { AsyncRenderer } from "components/AsyncRenderer";
import { HorizontalList } from "components/horizontal_list";
import {
  OrganizationUser,
  OrgBookingFragment,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { ListSpacesSkeleton } from "pages/homev3/components/list_spaces_skeleton";
import { LocationCard } from "pages/homev3/components/location_card";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useOrgBookingQuery } from "pages/homev3/hooks/use_org_booking_query";
import { usePopularLocationQuery } from "pages/homev3/hooks/use_popular_location_query";
import { useAnalytics } from "providers/analytics";
import React, { useCallback } from "react";
import { useUserType } from "../hooks/use_user_type";

export function PopularInCity() {
  const { data, loading, error } = usePopularLocationQuery({});

  const { data: orgData, error: orgError } = useOrgBookingQuery({});

  return (
    <AsyncRenderer
      error={error || orgError}
      data={data}
      loadingHandler={() => <ListSpacesSkeleton />}
      loading={loading}
    >
      {(data) => (
        <Content locations={data.locations} orgBookings={orgData.bookings} />
      )}
    </AsyncRenderer>
  );
}

type ContentProps = {
  locations: SpaceLocationDetailFragment[];
  orgBookings: OrgBookingFragment[];
};
function Content({ locations, orgBookings }: ContentProps) {
  const analytics = useAnalytics();
  const userType = useUserType();

  const onNavigate = useCallback(() => {
    analytics.event("Navigate Horizontal List", {
      Section: "Popular Spaces By City",
      category: "Rebooking",
    });
  }, [analytics]);

  if (!locations || locations.length === 0) {
    return null;
  }
  const orgGroupedBooking = groupBy(orgBookings, (b) => b.space.location.id);

  const sortedLocations = [...locations].sort(
    (a, b) => (a.currentUserDistance || 0) - (b.currentUserDistance || 0),
  );
  const renderItem = (location: SpaceLocationDetailFragment) => {
    const locationId = location.id;
    let peoples: Partial<OrganizationUser>[] = [];
    if (orgGroupedBooking[locationId]) {
      peoples = orgGroupedBooking[locationId].map((b) => b.user);
    }
    return (
      <LocationCard
        onPress={() => {
          analytics.event("Click Popular Space By City Card", {
            "Location UUID": locationId,
            "Location name": location.name,
            Source: userType,
            category: "Rebooking",
            Location: location.address.city,
          });
        }}
        key={locationId}
        location={location}
        peoples={peoples}
      />
    );
  };

  return (
    <HorizontalList
      title={
        <TitleSpaceList
          title={`Popular in ${sortedLocations[0]?.address.city}`}
          subtitle={"Explore trending spaces"}
        />
      }
      data={sortedLocations}
      renderItem={renderItem}
      onNavigate={onNavigate}
    />
  );
}
