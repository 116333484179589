import { useQuery } from "@apollo/client";
import { AppHeader } from "components/app_header_v3/app_header";

import { Container } from "components/container";
import { Content } from "components/content";
import { Heading } from "components/heading";
import { Icon } from "components/icon";
import { PageContainer } from "components/page_container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text";
import { Text as TextV2 } from "components/text_v2";
import {
  DailyDateRange,
  HourlyDateRange,
} from "core/booking_date_range_picker";
import { StickyBookingPreviewPanel } from "core/booking_preview_panel";
import { SearchValue } from "core/booking_utils";
import { Footer } from "core/footer.web";
import {
  BookingType,
  DailyBooking,
  HourlyBooking,
  OffsiteOrderLocationSpaces__SpaceDetailsFragment,
  OffsiteOrderOtherSpacesQuery,
  OffsiteOrderOtherSpacesQueryVariables,
  OffsiteOrderPage__OrderDetailsQuery,
  OffsiteOrderPage__OrderDetailsQueryVariables,
  OffsiteOrderPage__OrderItemDetailsFragment,
  OrderPageQuery,
  ReservationPage__OrderItemDetailsFragment,
  SpaceDetails__AllDetailsFragment,
  SpaceDetailsQuery,
  SpaceDetailsQueryVariables,
} from "core/graphql.generated";
import { spaceGQLQuery } from "core/queries";
import { useMediaQuery } from "lib/media_query";
import { BookingDetailInviteBanner } from "pages/booking_details/components/booking_detail_invite_banner";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";
import { usePostBookingsFeatureFlag } from "providers/splitio";
import React, { useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory, useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { EnhanceYourWorkDaySpaceCard } from "./components/enhance_your_work_day_space_card";
import {
  offsiteOrderOtherSpacesQuery,
  offsiteOrderPageGQLQuery,
  orderPageCurrentUserGQLQuery,
} from "./offsite_order.queries";
import { getDateTime, getEnhanceWorkDaySpaces } from "./offsite_order_utils";

interface OffsiteOrderPageParams {
  orderID: string;
}

export function OffsiteOrderPage() {
  const { orderID } = useParams<OffsiteOrderPageParams>();

  const {
    data: offsiteOrderPageQuery,
    loading,
    refetch,
  } = useQuery<
    OffsiteOrderPage__OrderDetailsQuery,
    OffsiteOrderPage__OrderDetailsQueryVariables
  >(offsiteOrderPageGQLQuery, {
    variables: { id: orderID },
  });

  const order = offsiteOrderPageQuery?.order!;

  const { data, loading: otherSpacesLoading } = useQuery<
    OffsiteOrderOtherSpacesQuery,
    OffsiteOrderOtherSpacesQueryVariables
  >(offsiteOrderOtherSpacesQuery, {
    variables: {
      offsiteLocationID: order?.orderItems[0].details.space.location.id,
    },
    skip: !order,
  });

  const enhanceYourWorkDaySpaces = getEnhanceWorkDaySpaces(
    data,
    order?.orderItems[0].details.space.spaceType,
  );

  if (loading || otherSpacesLoading) {
    return (
      <View style={{ padding: 120 }}>
        <Spinner />
      </View>
    );
  }

  if (!order) {
    return <Redirect to="/" />;
  }

  return (
    <OffsiteOrder
      order={order}
      mutate={refetch}
      enhanceYourWorkDaySpaces={enhanceYourWorkDaySpaces}
    />
  );
}

interface OffsiteOrderProps {
  order: NonNullable<OffsiteOrderPage__OrderDetailsQuery["order"]>;
  mutate: () => void;
  enhanceYourWorkDaySpaces:
    | OffsiteOrderLocationSpaces__SpaceDetailsFragment[]
    | undefined;
}

function OffsiteOrder(props: OffsiteOrderProps) {
  const { order, mutate, enhanceYourWorkDaySpaces } = props;
  const mq = useMediaQuery();
  const booking = getBooking(order.orderItems);
  const spaceID = booking.space.id;
  const { data } = useQuery<OrderPageQuery>(orderPageCurrentUserGQLQuery);
  const monthlyBilling = !!data?.currentUser?.organization?.monthlyBilling;
  const {
    data: spaceDetailsQuery,
    error,
    loading,
  } = useQuery<SpaceDetailsQuery, SpaceDetailsQueryVariables>(spaceGQLQuery, {
    variables: { id: spaceID },
  });
  const space = spaceDetailsQuery?.space;

  if (loading) {
    return (
      <View style={{ padding: 120 }}>
        <Spinner />
      </View>
    );
  }

  if (error) {
    return <Text>{extractFirstGraphQLErrorMessage(error)}</Text>;
  }

  if (!space) {
    return <Text>Space not found</Text>;
  }

  if (mq.deviceQuery.mobile) {
    return null;
  }

  return (
    <View testID="order-confirmation">
      <OrderDetailsDesktop
        booking={booking}
        order={order}
        space={space}
        monthlyBilling={monthlyBilling}
        onSendInvitesComplete={mutate}
        enhanceYourWorkDaySpaces={enhanceYourWorkDaySpaces}
      />
    </View>
  );
}

export function getBooking(
  orderItems:
    | OffsiteOrderPage__OrderItemDetailsFragment[]
    | ReservationPage__OrderItemDetailsFragment[],
): HourlyBooking | DailyBooking {
  for (const orderItem of orderItems) {
    if (orderItem.details.__typename === "DailyBooking") {
      return orderItem.details as DailyBooking;
    } else if (orderItem.details.__typename === "HourlyBooking") {
      return orderItem.details as HourlyBooking;
    }
  }

  throw new Error("Booking in order items not found");
}

export function getDateRangeFromBooking(
  booking: DailyBooking | HourlyBooking,
): DailyDateRange | HourlyDateRange {
  if (booking.__typename === "DailyBooking") {
    return {
      type: BookingType.DailyBooking,
      startDate: booking.startDate,
      endDate: booking.endDate,
    };
  } else if (booking.__typename === "HourlyBooking") {
    return {
      type: BookingType.HourlyBooking,
      startTime: booking.startTime,
      endTime: booking.endTime,
      date: booking.date,
    };
  }

  throw new Error("Could not get date range from booking");
}

interface OrderDetailsProps {
  order: NonNullable<OffsiteOrderPage__OrderDetailsQuery["order"]>;
  space: SpaceDetails__AllDetailsFragment;
  booking: HourlyBooking | DailyBooking;
  monthlyBilling: boolean;
  onSendInvitesComplete: () => void;
  modalVisible?: boolean;
  enhanceYourWorkDaySpaces:
    | OffsiteOrderLocationSpaces__SpaceDetailsFragment[]
    | undefined;
}

function OrderDetailsDesktop(props: OrderDetailsProps) {
  const {
    order,
    space,
    booking,
    onSendInvitesComplete,
    monthlyBilling,
    enhanceYourWorkDaySpaces,
  } = props;
  const postBookingFeatureFlag = usePostBookingsFeatureFlag();
  const history = useHistory();
  const searchValue = useMemo((): SearchValue => {
    return {
      dateRange: getDateRangeFromBooking(booking),
    };
  }, [booking]);

  const { startDate, endDate, startTime, endTime } = getDateTime(
    searchValue.dateRange,
  );

  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Spacer size={40} />
        <Content>
          <Heading testID="order-confirmation" size="h2">
            Thanks for your booking!
          </Heading>
          <Spacer size={16} />
          <TextV2 size="xs">
            Your booking is confirmed. We’ve emailed the details to{" "}
            {order.guest?.email}.
          </TextV2>
          <Spacer size={24} />
          <View style={styles.flex}>
            <View style={styles.leftColumn}>
              <BookingDetailInviteBanner
                remainingInvites={space.maxCapacity - order.invitees.length}
                invitees={order.invitees}
                bookingId={order.id}
                spaceId={space.id}
                spaceName={space.name}
                spaceType={space.spaceType}
                startDate={startDate}
                endDate={endDate}
                startTime={startTime}
                endTime={endTime}
                onInviteComplete={onSendInvitesComplete}
              />
              {!!enhanceYourWorkDaySpaces?.length && postBookingFeatureFlag && (
                <View>
                  <Spacer size={24} />
                  <TextV2 size="md" weight="semibold">
                    Enhance your work day
                  </TextV2>
                  <Spacer size={24} />
                  {enhanceYourWorkDaySpaces.map((space) => (
                    <EnhanceYourWorkDaySpaceCard space={space} key={space.id} />
                  ))}
                </View>
              )}
            </View>
            <View style={styles.rightColumn}>
              <StickyBookingPreviewPanel
                confirmationId={order.confirmationID || undefined}
                memo={order.memo}
                bookingID={order.id}
                currency={order.currency}
                monthlyBilling={monthlyBilling}
                paymentDetail={order.paymentDetail}
                searchValue={searchValue}
                quote={{
                  totalPrice: order.totalPrice,
                  taxLine: order.taxLine,
                  promotionLine: order.promotionLine,
                  subTotal: order.subTotal,
                  processingFee: order.processingFee,
                  orderItems: order.orderItems,
                }}
                space={space}
                hidePromotionForm={true}
              />
            </View>
          </View>

          <Spacer size={24} />
          <Row alignItems="center">
            <Icon name="arrow-left" />
            <Spacer size={4} />
            <Pressable onPress={() => history.replace("/")}>
              <Text decoration="underline">Back to home</Text>
            </Pressable>
          </Row>
          <Spacer size={24} />
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <Footer />
    </Container>
  );
}

const styles = StyleSheet.create({
  flex: {
    flexDirection: "row",
  },
  leftColumn: {
    width: "60%",
    paddingRight: 40,
  },
  rightColumn: {
    width: "40%",
  },
});
