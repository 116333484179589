import { Suspense } from "react";
import { StyleSheet, View } from "react-native";

import { Map } from "components/map";
import { Spinner } from "components/spinner";
import { useAnalytics } from "providers/analytics";

interface SpaceDetailMapProps {
  latitude?: number;
  longitude?: number;
}

export function SpaceDetailMap({ latitude, longitude }: SpaceDetailMapProps) {
  const analytics = useAnalytics();

  return (
    <Suspense fallback={<Spinner />}>
      <View style={styles.mapWrapper}>
        <Map
          height={315}
          latitude={latitude}
          longitude={longitude}
          fullScreenControl
          onExpand={() =>
            analytics.event("Expand Maps", { category: "Post booking" })
          }
        />
      </View>
    </Suspense>
  );
}

const styles = StyleSheet.create({
  mapWrapper: {
    borderRadius: 4,
    overflow: "hidden",
  },
});
