import { SpaceLocationDetailFragment } from "core/graphql.generated";
import { useCallback, useState } from "react";

const RECENTLY_VIEWED_LOCALSTORAGE_KEY = "recentlyViewedLocations";
const RECENTLY_VIEWED_ITEM_LIMIT = 15;

interface RecentlyViewedLocationsResult {
  locations: SpaceLocationDetailFragment[];
  saveLocationToRecentlyViewed: (location: SpaceLocationDetailFragment) => void;
  isAlreadyInRecentlySaved: (locationId: string) => boolean;
}

export function useRecentlyViewedLocations(): RecentlyViewedLocationsResult {
  const localStorageLocations = localStorage.getItem(
    RECENTLY_VIEWED_LOCALSTORAGE_KEY,
  );

  const [locations, setLocations] = useState<SpaceLocationDetailFragment[]>(
    localStorageLocations ? JSON.parse(localStorageLocations) : [],
  );

  const saveLocation = useCallback(
    (location: SpaceLocationDetailFragment) => {
      if (locations.some((loc) => loc.id === location.id)) {
        return;
      }

      let newList;

      if (locations.length < RECENTLY_VIEWED_ITEM_LIMIT) {
        newList = [location, ...locations];
      } else {
        newList = [
          location,
          ...locations.filter(
            (_location, index) => index < RECENTLY_VIEWED_ITEM_LIMIT - 1,
          ),
        ];
      }

      setLocations(newList);
      localStorage.setItem(
        RECENTLY_VIEWED_LOCALSTORAGE_KEY,
        JSON.stringify(newList),
      );
    },
    [locations],
  );

  const isAlreadyInRecentlySaved = useCallback(
    (locationId: string) => {
      return locations.some((location) => location.id === locationId);
    },
    [locations],
  );

  return {
    locations,
    saveLocationToRecentlyViewed: saveLocation,
    isAlreadyInRecentlySaved,
  };
}
