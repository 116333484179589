import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import ContentLoader, { Rect } from "react-content-loader/native";
import { StyleSheet, View } from "react-native";

export function EditBudgetSkeleton() {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <EditBudgetSkeletonMobile />;
  }

  return <EditBudgetSkeletonDesktop />;
}

export function EditBudgetSkeletonDesktop() {
  return (
    <View>
      <Spacer size={24} />
      <View style={styles.desktopContainer}>
        <ContentLoader
          speed={2}
          width="66%"
          height={240}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="240px" />
        </ContentLoader>
        <Spacer direction="row" size={24} />
        <ContentLoader
          speed={2}
          width="33%"
          height={240}
          backgroundColor="#f7f7f7"
          foregroundColor="#ecebeb"
        >
          <Rect width="100%" height="240px" />
        </ContentLoader>
      </View>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="66%"
        height={310}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="310px" />
      </ContentLoader>
    </View>
  );
}

export function EditBudgetSkeletonMobile() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={240}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="2400px" />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={360}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="360px" />
      </ContentLoader>
    </View>
  );
}

const styles = StyleSheet.create({
  desktopContainer: {
    flexDirection: "row",
  },
});
