import { colors } from "components/colors";
import { Icon, IconName } from "components/iconv2";
import React from "react";
import { Pressable, StyleSheet } from "react-native";

type IconButtonProps = {
  iconName: IconName;
  disabled?: boolean;
  onPress: () => void;
  size?: "sm" | "md" | "lg";
  testID?: string;
  color?: keyof typeof colors.brand;
  disabledColor?: keyof typeof colors.brand;
  hoveredColor?: keyof typeof colors.brand;
};

export function IconButton({
  iconName,
  disabled,
  onPress,
  size = "md",
  testID,
  color,
  disabledColor = "blackMinus70",
  hoveredColor,
}: IconButtonProps) {
  return (
    <Pressable
      style={({ pressed, hovered }: any) => [
        styles.baseStyle,
        size && styles[size],
        color && { borderColor: colors.brand[color] },
        disabled && { borderColor: colors.brand[disabledColor] },
        hovered && styles.hoverStyles,
        pressed && styles.pressedStyles,
      ]}
      onPress={onPress}
      disabled={disabled}
      testID={testID}
    >
      {({ hovered }: any) => (
        <Icon
          name={iconName}
          color={
            disabled
              ? disabledColor
              : hovered && hoveredColor
              ? hoveredColor
              : color
          }
        />
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  baseStyle: {
    borderRadius: 999,
    justifyContent: "center",
    alignItems: "center",
    borderColor: colors.brand.blackMinus30,
    borderWidth: 1,
  },
  sm: {
    width: 24,
    height: 24,
  },
  md: {
    width: 32,
    height: 32,
  },
  lg: {
    width: 40,
    height: 40,
  },
  hoverStyles: {
    backgroundColor: colors.brand.blackMinus90,
  },
  pressedStyles: {
    backgroundColor: colors.brand.blackMinus70,
  },
});
