import React from "react";
import { Animated, StyleSheet, View } from "react-native";
import { Text } from "./text_v2";
import { tokens } from "./tokens";

interface FieldContainerProps {
  label?: string;
  invalid?: boolean;
  errorText?: string;
  description?: string;
  children: React.ReactNode;
  variant?: string;
  height?: number | "unset";
  borderless?: boolean;
  hasLeftIcon?: boolean;
  hasValue?: boolean;
  focused?: boolean;
  placeholder?: string;
  topDescription?: string;
}

export function FieldContainer(props: FieldContainerProps) {
  const {
    children,
    label,
    invalid,
    hasLeftIcon,
    hasValue,
    focused,
    errorText,
    description,
    variant,
    height = 48,
    borderless = false,
    placeholder,
    topDescription,
  } = props;

  return (
    <View>
      {label && label !== "" && (
        <View style={styles.labelWrapper}>
          <Text size="xs" weight="semibold">
            {label}
          </Text>
        </View>
      )}
      {!!topDescription && (
        <View style={styles.topDescriptionTextWrapper}>
          <Text color="black-70" size="xs">
            {topDescription}
          </Text>
        </View>
      )}
      <View
        style={[
          styles.wrapper,
          !borderless && styles.wrapperBorder,
          { height },
          variant === "outlined" && styles.wrapperVariantOutlined,
          invalid && styles.wrapperInvalid,
          !!placeholder && (hasValue || focused) && styles.wrapperPadLabel,
          focused && styles.wrapperFocused,
        ]}
      >
        {children}
        {placeholder && placeholder !== "" && (
          <Animated.Text
            style={[
              styles.placeholder,
              hasLeftIcon && styles.placeholderWithLeftIcon,
              hasValue && styles.hasValuePlaceholder,
              invalid && styles.invalidPlaceholder,
              focused && styles.focusedPlaceholder,
            ]}
          >
            {placeholder}
          </Animated.Text>
        )}
      </View>
      {!!errorText && (
        <View style={styles.errorTextWrapper}>
          <Text color="grapefruit-core" size="sm">
            {errorText}
          </Text>
        </View>
      )}
      {!!description && (
        <View style={styles.descriptionTextWrapper}>
          <Text color="black-80" size="xs">
            {description}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  labelWrapper: {
    paddingBottom: 4,
  },
  focusedPlaceholder: {
    color: tokens.colors.primary.regular,
    fontWeight: "normal",
    top: 1,
    ...tokens.text.size.xs,
  },
  hasValuePlaceholder: {
    color: tokens.colors.utility.lighter,
    fontWeight: "normal",
    top: 0,
    ...tokens.text.size.xs,
  },
  placeholder: {
    position: "absolute",
    left: 12,
    top: 10,
    color: "#514C61",
    zIndex: -1,
    fontSize: 15,
    lineHeight: 18,
  },
  invalidPlaceholder: {
    color: tokens.colors.secondary.dark,
  },
  placeholderWithLeftIcon: {
    left: 40,
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 4,
  },
  wrapperBorder: {
    borderWidth: 1,
    borderColor: tokens.colors.primary["eggplant-10"],
  },
  wrapperVariantOutlined: {
    backgroundColor: tokens.colors.base.white,
    borderColor: "#E6E5E8",
  },
  wrapperInvalid: {
    borderColor: tokens.colors.secondary.dark,
    backgroundColor: tokens.colors.neutral.regular,
  },
  wrapperPadLabel: {
    paddingTop: 15,
  },
  wrapperFocused: {
    borderColor: tokens.colors.primary.regular,
    backgroundColor: tokens.colors.base.white,
  },
  errorTextWrapper: {
    paddingTop: 3,
  },
  topDescriptionTextWrapper: {
    paddingTop: 3,
    paddingBottom: 8,
  },
  descriptionTextWrapper: {
    paddingTop: 3,
  },
});
