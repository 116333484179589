import { Card } from "components/card";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { FormValueType } from "pages/budgets/policy/policy_mutation_form";
import { useProximityPolicyFeatureFlag } from "providers/splitio";
import { View } from "react-native";

interface CreateFormSummaryPropTypes {
  values: FormValueType;
}

export const CreateFormSummary: React.FC<CreateFormSummaryPropTypes> = ({
  values,
}) => {
  const {
    name,
    spaceTypes,
    isAppliedToNewBudget,
    applyToBudgetIds,
    locationItems,
  } = values;

  const isPPFlag = useProximityPolicyFeatureFlag();

  const renderSpaceTypes = () => {
    if (spaceTypes) {
      const { meetingRoom, dayPass, privateOffice } = spaceTypes;
      const bookable = [];
      if (meetingRoom) {
        bookable.push("meeting rooms");
      }
      if (dayPass) {
        bookable.push("day passes");
      }
      if (privateOffice) {
        bookable.push("private offices");
      }

      if (bookable.length === 0) {
        return "No space types can be booked";
      }
      if (bookable.length > 0 && bookable.length < 3) {
        return `Only ${bookable.join(" and ")} can be booked`;
      }
      return "All space types can be booked";
    }
  };

  const activeLocations = locationItems?.filter((item) => item.active);

  return (
    <div>
      <Card title="Summary">
        <View>
          {name ? (
            <Text size="xs" weight="semibold">
              {name}
            </Text>
          ) : (
            <Text size="xs" color="black-70">
              No information entered yet
            </Text>
          )}

          <Spacer size={24} />
          <Divider />
          <Spacer size={24} />

          <Text size="xs" weight="semibold">
            Space types
          </Text>
          <Spacer size={8} />
          <Text size="xs" color="black-70">
            {renderSpaceTypes()}
          </Text>

          {isPPFlag && (
            <>
              <Spacer size={24} />
              <Divider />

              <Spacer size={24} />
              <Text size="xs" weight="semibold">
                Locations
              </Text>
              <Spacer size={8} />

              {activeLocations && activeLocations.length > 0 ? (
                <View>
                  <Text size="xs" color="black-70">
                    Bookings are restricted to spaces beyond these locations:
                  </Text>
                  {activeLocations.map((location) => (
                    <Text size="xs" color="black-70">
                      {` ∙ ${
                        location.distance
                      } ${location.distanceUnit.toLowerCase()}. from ${
                        location.street
                      }`}
                    </Text>
                  ))}
                </View>
              ) : (
                <Text size="xs" color="black-70">
                  All locations can be booked
                </Text>
              )}
            </>
          )}
          <Spacer size={24} />
          <Divider />
          <Spacer size={24} />

          <Text size="xs" weight="semibold">
            Budget selection
          </Text>
          <Spacer size={8} />
          <Text size="xs" color="black-70">
            {isAppliedToNewBudget
              ? "Apply to all new budgets by default "
              : "Do not apply to all new budgets by default"}
          </Text>
          <Spacer size={8} />
          {applyToBudgetIds && (
            <Text size="xs" color="black-70">
              Apply to {applyToBudgetIds?.length} current budget(s)
            </Text>
          )}
        </View>
      </Card>
    </div>
  );
};
