import { Button } from "components/button_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { People } from "components/who_is_here";
import { OrganizationUser } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import { StyleSheet, View } from "react-native";

interface TeamMapPreviewCardCardProps {
  addMyCityPress: () => void;
  skipMapCTAPress: () => void;
  peoples: Partial<OrganizationUser>[];
}

export function MapCTA({
  addMyCityPress,
  skipMapCTAPress,
  peoples,
}: TeamMapPreviewCardCardProps) {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <View style={isMobile ? styles.containerMobile : styles.container}>
      <View style={styles.content}>
        <People peoples={peoples} maxDisplay={4} appearance={"outline"} />
        <Spacer size={16} />
        <Text size={"md"} weight={"semibold"}>
          Put yourself on the map!
        </Text>
        <Spacer size={16} />
        <Text size={"xs"} weight={"semibold"} align={"center"} color="black-70">
          Share your city with your team to stay connected even when working
          apart.
        </Text>
        <Spacer size={16} />
        <Button text={"Add my city"} onPress={addMyCityPress} size={"medium"} />

        <Button
          onPress={skipMapCTAPress}
          text={"Skip for now"}
          appearance={"textLink"}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: "40%",
    right: 0,
    zIndex: 1,
    alignItems: "center",
    width: "100%",
  },
  containerMobile: {
    position: "absolute",
    top: "10%",
    right: 0,
    zIndex: 1,
    alignItems: "center",
    width: "100%",
  },
  content: {
    width: 344,
    alignItems: "center",
  },
  title: {},
});
