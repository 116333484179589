import { Divider } from "components/divider";

import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";

import { OffsiteSpaceDetailsDesktopComponents } from "./offsite_space_details_content_desktop";

export interface OffsiteSpaceDetailsMobileComponents
  extends OffsiteSpaceDetailsDesktopComponents {}

interface OffsiteSpaceDetailsContentMobileProps {
  components: OffsiteSpaceDetailsMobileComponents;
}

const SPACER_SIZE = 16;

export function OffsiteSpaceDetailsContentMobile({
  components,
}: OffsiteSpaceDetailsContentMobileProps) {
  const {
    header,
    images,
    spaceAttributes,
    aboutSpace,
    preferred,
    whosHere,
    perks,
    roomLayouts,
    amenties,
    pricePannel,
    location,
    map,
    directions,
    collaborateBanner,
    hours,
  } = components;

  return (
    <View>
      <View style={styles.withPadding}>{header}</View>
      <Spacer size={SPACER_SIZE} />
      {images}
      <View style={styles.withPadding}>
        {spaceAttributes}
        {aboutSpace && (
          <>
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
            {aboutSpace}
          </>
        )}
      </View>
      {preferred && (
        <>
          <Spacer size={SPACER_SIZE} />
          <Divider />
          <Spacer size={SPACER_SIZE} />
          {preferred}
        </>
      )}
      <View style={styles.withPadding}>
        {whosHere && (
          <>
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
            {whosHere}
          </>
        )}
        {perks && (
          <>
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
            {perks}
          </>
        )}
        {roomLayouts && (
          <>
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
            {roomLayouts}
          </>
        )}
        {amenties && (
          <>
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
            {amenties}
          </>
        )}
        <Spacer size={SPACER_SIZE} />
        <Divider />
        <Spacer size={SPACER_SIZE} />
        {location}
        <Spacer size={SPACER_SIZE} />
        {map}
        <Spacer size={SPACER_SIZE} />
        <Divider />
        <Spacer size={SPACER_SIZE} />
        {directions && (
          <>
            {directions}
            <Spacer size={SPACER_SIZE} />
            <Divider />
            <Spacer size={SPACER_SIZE} />
          </>
        )}
      </View>
      {collaborateBanner}
      <View style={styles.withPadding}>
        {collaborateBanner && <Spacer size={SPACER_SIZE} />}
        {collaborateBanner && <Divider />}
        <Spacer size={SPACER_SIZE} />
        {hours}
      </View>
      <Spacer size={50} />
      {pricePannel}
    </View>
  );
}

const styles = StyleSheet.create({
  withPadding: {
    paddingHorizontal: SPACER_SIZE,
  },
});
