import { useQuery, gql } from "@apollo/client";
import React, { Fragment, useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { parseDay } from "lib/day_utils";
import { Spacer } from "components/spacer";
import { Divider } from "components/divider";
import { Text } from "components/text_v2";
import { format } from "date-fns";
import { tokens } from "components/tokens";
import { isEmpty } from "lib/lang_utils";
import { RecentActivitySkeleton } from "./recent_activity_skeleton";
import {
  RecentActivityOrdersSummaryQuery,
  SpaceType,
} from "core/graphql.generated";
import { getLastUpdatedLabel } from "pages/reporting/utils/get_last_updated_label";
import { Tooltip } from "components/tooltip";
import { Icon } from "components/icon";
import { colors } from "components/colors";

export function RecentActivity() {
  const [displayCount, setDisplayCount] = useState(15);
  const { loading: ordersSummaryLoading, data: ordersSummaryData } =
    useQuery<RecentActivityOrdersSummaryQuery>(
      recentActivityOrdersSummaryGQLQuery,
    );

  const handleSeeMore = useCallback(() => {
    setDisplayCount(displayCount + 15);
  }, [displayCount]);

  if (
    ordersSummaryLoading ||
    !ordersSummaryData?.currentUser?.organization?.ordersSummary
  ) {
    return <RecentActivitySkeleton />;
  }

  const recentActivity =
    ordersSummaryData.currentUser.organization.ordersSummary.orders;

  const labelUpdatedAt = getLastUpdatedLabel(
    ordersSummaryData.currentUser.organization.ordersSummary.lastUpdatedDate,
  );

  return (
    <View testID="recent-activity" style={styles.rootContainer}>
      <View style={styles.headingContainer}>
        <Text size="md" weight="semibold">
          Recent Activity
        </Text>
        <Tooltip
          position="bottom-left"
          offset={18}
          content={
            <View style={styles.tooltip}>
              <Text color="black-50" size="xs">
                {labelUpdatedAt}
              </Text>
            </View>
          }
        >
          <Icon name="info" color={colors.brand.blackMinus30} />
        </Tooltip>
      </View>
      <Spacer size={8} />
      <Divider />
      {isEmpty(recentActivity) && (
        <View>
          <Spacer size={8} />
          <Text weight="bold">No activity yet</Text>
          <Text>As soon as your team uses Flexspace, we’ll show it here.</Text>
        </View>
      )}
      {recentActivity.slice(0, displayCount).map((ra) => (
        <Fragment key={ra.orderID}>
          <View style={styles.activityContainer}>
            <View style={styles.labelContainer}>
              <Text size="xs" weight="semibold">
                {ra.hostFullName}
              </Text>
              <Text size="xs" numberOfLines={1}>
                {ra.locationName}{" "}
                {ra.spaceType ? `• ${getSpaceTypeLabel(ra.spaceType)}` : ""}
              </Text>
            </View>
            <View>
              <Text size="xs">
                {format(parseDay(ra.orderCreatedAt), "MMM d")}
              </Text>
            </View>
          </View>
          <Divider />
        </Fragment>
      ))}
      {recentActivity.length > displayCount && (
        <View style={styles.seeMoreContainer}>
          <Text weight="bold" color="eggplant-core" onPress={handleSeeMore}>
            See More
          </Text>
        </View>
      )}
    </View>
  );
}

export function getSpaceTypeLabel(spaceType: SpaceType): string {
  switch (spaceType) {
    case SpaceType.DayOffice:
      return "Day Office";
    case SpaceType.DayPass:
      return "Day Pass";
    case SpaceType.MeetingRoom:
      return "Meeting Room";
    default:
      throw new Error(`spaceType=${spaceType} not recognized`);
  }
}

const styles = StyleSheet.create({
  rootContainer: {
    padding: 24,
    backgroundColor: "white",
    borderRadius: 16,
    ...tokens.shadow.elevation1,
  },
  activityContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 16,
    width: "100%",
  },
  labelContainer: {
    flex: 1,
  },
  seeMoreContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 24,
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 1,
  },
  tooltip: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "rgba(6, 0, 30, 0.1)",
    padding: 8,
    borderRadius: 6,
    minWidth: 150,
    ...tokens.shadow.elevation1,
  },
});

const recentActivityOrdersSummaryGQLQuery = gql`
  query RecentActivityOrdersSummary {
    currentUser {
      organization {
        ordersSummary {
          orders {
            ...ReportingPageOrderSummary
          }
          lastUpdatedDate
        }
      }
    }
  }

  fragment ReportingPageOrderSummary on OrderSummary {
    orderID
    hostFullName
    locationName
    spaceType
    bookingDate
    orderCreatedAt
  }
`;
