import pluralize from "pluralize";

export function getInvitedPeopleDescription(invitees: string[]): string {
  const numberOfInvitees = invitees.length;
  if (numberOfInvitees === 0) {
    return "Send an invitation to the people you want to join you.";
  }
  if (numberOfInvitees <= 2) {
    return `You've invited ${invitees.join(" and ")}.`;
  } else {
    return `You've invited ${invitees[0]}, ${invitees[1]} and ${
      numberOfInvitees - 2
    } ${pluralize("other", numberOfInvitees - 2)}.`;
  }
}
