import { Coordinates } from "./coordinates";

export interface MapboxFeatureCollection {
  attribution: string;
  features: MapboxFeature[];
  query: string[];
  type: string;
}

export interface MapboxFeature {
  bbox: [number, number, number, number];
  center: [number, number];
  geometry: { type: "Point"; coordinates: [number, number] };
  id: string;
  matching_place_name: string;
  matching_text: string;
  place_name: string;
  place_type: string[];
  properties: { wikidata: string; short_code: string };
  relevance: number;
  text: string;
  type: string;
}

export function toCoordinates(center: [number, number]): Coordinates {
  return {
    lng: center[0],
    lat: center[1],
  };
}
