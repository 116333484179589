import { SpaceType, SpaceTypesInput } from "core/graphql.generated";
import { PolicyLocationItem } from "pages/budgets/policy/policy_mutation_form";

export type PolicyFormErrors = {
  name?: string;
  spaceTypes?: string;
  locationItems?: string;
};

export interface PolicyFormValidateInput {
  name: string;
  spaceTypes?: SpaceTypesInput;
  locationItems?: PolicyLocationItem[];
}

export const policyFormValidate = (
  values: PolicyFormValidateInput,
  existPolicyNames?: string[],
) => {
  const errors: PolicyFormErrors = {};
  const { name, spaceTypes, locationItems } = values;
  const policyName = name.trim();
  if (policyName.trim().length === 0) {
    errors.name = "Please enter name";
  }
  if (existPolicyNames?.includes(policyName)) {
    errors.name = "Policy name must be unique within your organization";
  }
  const { dayPass, privateOffice, meetingRoom } = spaceTypes || {};
  if (!dayPass && !privateOffice && !meetingRoom) {
    errors.spaceTypes = "Please select at least 1 space type";
  }

  if (
    locationItems &&
    locationItems.length > 0 &&
    locationItems
      .filter((item) => item.active)
      .some((item) => !item.distance || Number(item.distance) <= 0)
  ) {
    errors.locationItems = "Please enter a number above 0";
  }

  return errors;
};

export const getSpaceTypesObj = (
  spaceTypes: {
    id: string;
    isSelected: boolean;
  }[],
) => {
  const spaceTypesObj: SpaceTypesInput = {
    meetingRoom: false,
    privateOffice: false,
    dayPass: false,
  };
  spaceTypes.forEach(({ id, isSelected }) => {
    if (id === SpaceType.DayOffice) {
      spaceTypesObj.privateOffice = isSelected;
    } else if (id === SpaceType.DayPass) {
      spaceTypesObj.dayPass = isSelected;
    } else if (id === SpaceType.MeetingRoom) {
      spaceTypesObj.meetingRoom = isSelected;
    }
  });

  return spaceTypesObj;
};
