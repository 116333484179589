import { StyleSheet, View } from "react-native";
import { Spacer } from "components/spacer";
import { MobileMenu } from "./components/mobile_menu";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { Button } from "components/button_v2";
import { Redirect, useHistory } from "react-router-dom";
import { useAuthV2 } from "providers/authv2";
import { useMediaQuery } from "lib/media_query";
import { MobileLayout } from "components/layout/mobile_layout";
import { Fragment } from "react";

export function OrganizationMobile() {
  const { logout } = useAuthV2();
  const mq = useMediaQuery();
  const history = useHistory();

  if (!mq.deviceQuery.mobile) {
    return <Redirect to="/admin/organization-profile" />;
  }

  return (
    <MobileLayout
      title="Organization"
      header={null}
      footer={<BottomBarAdminNavigation />}
      body={
        <Fragment>
          <MobileMenu />
          <Spacer size={16} />

          <View style={styles.menuButtons}>
            <View>
              <Button
                onPress={() => history.push("/")}
                appearance="secondary"
                text="Find workspaces"
              />
              <Spacer size={8} />
              <Button text="Log out" onPress={logout} />
            </View>
          </View>
        </Fragment>
      }
    />
  );
}

const styles = StyleSheet.create({
  menuButtons: {
    flex: 1,
    justifyContent: "flex-end",
  },
});
