import { ReactNode } from "react";
import { View } from "react-native";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";

interface MenuSectionProps {
  children: ReactNode;
  title: string;
}

export function MenuSection(props: MenuSectionProps) {
  const { title, children } = props;

  return (
    <View>
      <Text weight="bold" size="sm">
        {title}
      </Text>
      <Spacer size={16} />
      {children}
      <Spacer size={33} />
    </View>
  );
}
