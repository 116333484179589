import "./text_v2.web.css";

import React from "react";
import classNames from "classnames";
import { TextProps } from "./text_v2";

// NEEDS TO MATCH NATIVE IMPLEMENTATION
export function Text(props: TextProps) {
  const {
    align = "left",
    children,
    color = "black-core",
    size = "sm",
    testID,
    weight = "regular",
    numberOfLines,
    decoration = "none",
    customColor,
    onPress,
  } = props;

  return (
    <div
      data-testid={testID}
      className={classNames(
        "text",
        color && `text-color-${color}`,
        size && `text-size-${size}`,
        align && `text-align-${align}`,
        decoration && `text-decoration-${decoration}`,
        weight && `text-weight-${weight}`,
        numberOfLines === 1 && `text-single-line`,
        onPress && `text-has-press`,
      )}
      style={{ color: customColor }}
      onClick={onPress}
    >
      {children}
    </div>
  );
}
