import { SpaceDetail } from "./space_detail";

interface SpaceDetailAboutProps {
  description: string;
}

export function SpaceDetailAbout({ description }: SpaceDetailAboutProps) {
  return (
    <SpaceDetail title="About this space">
      <div
        className="space-detail__description"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </SpaceDetail>
  );
}
