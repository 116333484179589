import { useAnalytics } from "providers/analytics";

import { UpcomingBookingAction } from "./upcoming_booking_action";

interface GetDirectionsProps {
  address: string;
  city: string;
  latitude?: number;
  longitude?: number;
}

export function GetDirections({
  address,
  city,
  latitude,
  longitude,
}: GetDirectionsProps) {
  const analytics = useAnalytics();

  const handleClick = () => {
    const destinationParam =
      latitude && longitude ? `${latitude},${longitude}` : `${address}${city}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        destinationParam,
      )}`,
      "_blank",
    );
    analytics.event("Click Get Directions", { category: "Post booking" });
  };

  return (
    <UpcomingBookingAction
      title={address}
      description={city}
      iconName="direction"
      actionText="Get directions"
      onClick={handleClick}
    />
  );
}
