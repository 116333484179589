import { gql, useQuery } from "@apollo/client";
import { Stripe } from "@stripe/stripe-js";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Dialog } from "components/dialog";
import { Divider } from "components/divider";
import { LoginToBook } from "components/login_to_book";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { Text as TextV2 } from "components/text_v2";
import { BookingCheckout, CheckoutTerms } from "core/booking_checkout";
import { BookingPreviewV2 } from "core/booking_preview";
import {
  getCheckoutCancellationPolicyText,
  ReservationDetailsInput,
  SearchValue,
  useBookingDateRangePickerHelper,
  useCheckout,
} from "core/booking_utils";
import { CheckoutPaymentDetails } from "core/checkout_payment_details";
import { DialogContent, DialogHeader } from "core/dialog_content";
import {
  BookingType,
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  QuoteDetailsQuery,
  SpaceDetailsCurrentUserPaymentMethodQuery,
} from "core/graphql.generated";
import { OrderBreakdown } from "core/order_breakdown";
import { PromotionDetails } from "core/promotion_details";
import { RoomLayoutEdit } from "core/room_layout_edit";
import { differenceInHours } from "lib/time_utils";
import { useAnalytics } from "providers/analytics";
import { useAuthV2 } from "providers/authv2";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ScrollView, View } from "react-native";

interface ConfirmAndPayDialogProps {
  monthlyBilling: boolean;
  reservationDetails: ReservationDetailsInput;
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  quoteQuery?: QuoteDetailsQuery;
  quoteError?: string;
  quoteLoading?: boolean;
  onChangePromoCode: (promoCode: string) => void;
  stripe: Stripe | null;
  searchValue: SearchValue;
  onPressEdit: () => void;
  onClose: () => void;
  onComplete: (orderID: string) => void;
}

export function ConfirmAndPayDialog(props: ConfirmAndPayDialogProps) {
  const {
    monthlyBilling,
    space,
    stripe,
    searchValue,
    quoteQuery,
    onClose,
    quoteError,
    quoteLoading,
    onPressEdit,
    onComplete,
    reservationDetails,
    onChangePromoCode,
  } = props;
  const analytics = useAnalytics();
  const { authenticated } = useAuthV2();
  const [layoutVisible, setLayoutVisible] = useState(false);
  const requirePayment =
    !monthlyBilling && !space.pricings.every((p) => p.price === 0);
  const { data } = useQuery<SpaceDetailsCurrentUserPaymentMethodQuery>(
    currentUserPaymentMethodGQLQuery,
  );
  const { openAt } = useBookingDateRangePickerHelper({
    space,
  });
  const quote = quoteQuery?.quote;
  const {
    values,
    handleChange,
    onCreditCardChange,
    setFieldValue,
    status,
    errors,
    submit,
    paymentInfoIsIncomplete,
    submitting,
    submitCount,
  } = useCheckout({
    requirePayment,
    initialValues: {
      quoteId: searchValue.quoteId,
      dateRange: searchValue.dateRange,
      promoCode: searchValue.promoCode,
      layoutID: reservationDetails.layoutID,
      meetingName: reservationDetails.meetingName,
      arrivalTime: reservationDetails.arrivalTime,
      meetingStartTime: reservationDetails.meetingStartTime,
    },
    spaceID: space.id,
    onComplete,
    stripe,
    savedPaymentMethodId: data?.currentUser?.paymentMethod?.id,
  });

  const handleChangePromo = useCallback(
    (pc) => {
      setFieldValue("promoCode", pc);
      onChangePromoCode(pc);
    },
    [setFieldValue, onChangePromoCode],
  );

  const has100PercentPromo = quoteQuery?.quote?.totalPrice === 0;
  const requirePaymentWithPromo = requirePayment && !has100PercentPromo;

  // if a user doesn't have any payment methods saved
  // and there is a 100% promo applied, set field PaymentComplete to true
  // to avoid form validation error
  useEffect(() => {
    if (!requirePaymentWithPromo && !values.paymentComplete) {
      setFieldValue("paymentComplete", true);
    }
  }, [requirePaymentWithPromo, values.paymentComplete, setFieldValue]);

  useEffect(() => {
    if (data?.currentUser?.paymentMethod) {
      setFieldValue("paymentComplete", !!data?.currentUser.paymentMethod.id);
    }
  }, [data?.currentUser?.paymentMethod, setFieldValue]);

  const confirmationButtonDisabled = !!(
    (paymentInfoIsIncomplete && requirePaymentWithPromo) ||
    quoteError
  );

  let startDate = "";
  let endDate = "";
  if (
    values?.dateRange?.type === BookingType.DailyBooking &&
    values?.dateRange.startDate &&
    values?.dateRange.endDate
  ) {
    startDate = values?.dateRange?.startDate;
    endDate = values?.dateRange?.endDate;
  } else if (
    values?.dateRange?.type === BookingType.HourlyBooking &&
    values?.dateRange?.date
  ) {
    startDate = values?.dateRange?.date;
    endDate = values?.dateRange?.date;
  }

  useEffect(() => {
    if (!quote || !searchValue.dateRange) {
      return;
    }

    analytics.event("View Checkout", {
      "Space UUID": space.id,
      "Space Name": space.name,
      City: space.location.address.city,
      State: space.location.address.state,
      Country: space.location.address.country,
      Location: space.location.name,
      "Order Total": quote.totalPrice,
      "Space Currency": space.currency,
      "Start Date":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.date
          : searchValue.dateRange.startDate,
      "End Date":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.date
          : searchValue.dateRange.startDate,
      "Start Time":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.startTime
          : undefined,
      "End Time":
        searchValue.dateRange.type === BookingType.HourlyBooking
          ? searchValue.dateRange.endTime
          : undefined,
      "Hours Duration":
        searchValue.dateRange.type === BookingType.HourlyBooking &&
        searchValue.dateRange.endTime &&
        searchValue.dateRange.startTime
          ? differenceInHours(
              searchValue.dateRange.endTime,
              searchValue.dateRange.startTime,
            )
          : undefined,
    });
  }, [space, analytics, quote, searchValue]);

  return (
    <Container
      color="content"
      flex={1}
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
    >
      <DialogHeader
        headerRightIcon="x-circle"
        headerTitle={requirePayment ? "Confirm and pay" : "Review and confirm"}
        onHeaderRightIconPress={onClose}
      />
      <ScrollView>
        <View style={{ paddingHorizontal: 16, paddingTop: 16 }}>
          <BookingPreviewV2
            onPressChange={onPressEdit}
            space={space}
            searchValue={searchValue}
          />
          <Spacer size={16} />
        </View>

        <Container paddingHorizontal={16}>
          <Divider />
        </Container>
        <Container padding={16}>
          {authenticated && (
            <BookingCheckout
              onPressLayoutChange={() => setLayoutVisible(true)}
              space={space}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              values={values}
              errors={errors}
              submitCount={submitCount}
            />
          )}

          <Dialog
            animationType="slide"
            visible={layoutVisible}
            onRequestClose={() => setLayoutVisible(false)}
          >
            <DialogContent
              headerLeftIcon="x-circle"
              headerTitle="Room Layout"
              onHeaderLeftIconPress={() => setLayoutVisible(false)}
            >
              {layoutVisible && (
                <RoomLayoutEdit
                  initialLayoutID={values.layoutID}
                  space={space}
                  maxCapacity={space.maxCapacity}
                  onCancel={() => setLayoutVisible(false)}
                  onSave={(lID) => {
                    setFieldValue("layoutID", lID);
                    setLayoutVisible(false);
                  }}
                />
              )}
            </DialogContent>
          </Dialog>

          {authenticated && (
            <>
              <Spacer size={24} />
              <Divider />
              <Spacer size={16} />
            </>
          )}
          {authenticated && monthlyBilling && !requirePayment && (
            <Container>
              <TextV2 weight="semibold" size={"md"}>
                Payment details
              </TextV2>
              <Spacer size={16} />
              <TextV2 color={"black-50"} size={"xs"}>
                Invoiced to your organization.
              </TextV2>
              <Spacer size={24} />
              <Divider />
              <Spacer size={16} />
            </Container>
          )}
          {authenticated && requirePaymentWithPromo && (
            <>
              <CheckoutPaymentDetails
                stripe={stripe}
                onChange={onCreditCardChange}
                paymentCompleteError={errors.paymentComplete}
              />
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
            </>
          )}
          {quoteQuery && (
            <Fragment>
              <TextV2 weight="semibold" size={"md"}>
                Price breakdown
              </TextV2>
              <Spacer size={16} />
              <OrderBreakdown
                currency={space.currency}
                quote={quoteQuery.quote}
                quoteError={quoteError}
              />

              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              <PromotionDetails
                offsiteSpaceID={space.id}
                promoCode={values.promoCode}
                onChange={handleChangePromo}
                startDate={startDate}
                endDate={endDate}
              />
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              <>
                <TextV2 size="micro" color={"black-30"}>
                  {getCheckoutCancellationPolicyText(
                    space.bookingPolicy,
                    space.location.timezone,
                    openAt,
                    searchValue.dateRange,
                  )}
                </TextV2>
                <Spacer size={24} />
                <Divider />
              </>
            </Fragment>
          )}
          {quoteError && (
            <Fragment>
              <Spacer size={16} />
              <Text color="error">{quoteError}</Text>
            </Fragment>
          )}

          {authenticated ? (
            <>
              <Spacer size={16} />
              <CheckoutTerms />
              <Spacer size={16} />
              <Button
                loading={submitting || quoteLoading}
                disabled={confirmationButtonDisabled}
                onPress={submit}
                testID="checkout-confirm-and-pay-button"
                text={
                  requirePaymentWithPromo
                    ? "Confirm and pay"
                    : "Confirm reservation"
                }
              />
              {!!status && (
                <>
                  <Spacer size={8} />
                  <Text color="error">{status}</Text>
                </>
              )}
            </>
          ) : (
            <>
              <LoginToBook />
            </>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
}

const currentUserPaymentMethodGQLQuery = gql`
  query SpaceDetailsCurrentUserPaymentMethod {
    currentUser {
      id
      paymentMethod {
        ... on CardPaymentMethod {
          id
          last4
          network
          networkLogoUrl
        }
      }
    }
  }
`;
