import React, { forwardRef, useState } from "react";
import {
  Pressable,
  StyleSheet,
  TextInput as RNTextInput,
  View,
} from "react-native";
import { colors } from "./colors";

import { FieldContainer } from "./field_container";
import { TextFieldProps } from "./text_field";
import "./text_field.web.css";
import { tokens } from "./tokens";

// NEEDS TO MATCH NATIVE IMPLEMENTATION
export const TextField = forwardRef<RNTextInput, TextFieldProps>(
  function TextField(props, ref) {
    const {
      value,
      label,
      name,
      onChange,
      invalid,
      testID,
      disabled,
      placeholder,
      numberOfLines,
      invalidText,
      description,
      textContentType,
      keyboardType,
      onSubmitEditing,
      secureTextEntry,
      leftIcon,
      rightIcon,
      onRightIconClick,
      inputPlaceholder,
      onFocus,
      onBlur,
      onKeyPress,
      topDescription,
      borderless,
    } = props;
    const [focused, setFocused] = useState(false);

    return (
      <FieldContainer
        focused={focused}
        hasValue={!!value}
        borderless={borderless}
        variant="outlined"
        label={label}
        placeholder={placeholder}
        invalid={invalid}
        description={description}
        topDescription={topDescription}
        errorText={invalidText}
        height={borderless ? 64 : numberOfLines ? numberOfLines * 32 : 40}
      >
        {leftIcon && <View style={styles.leftIconContainer}>{leftIcon}</View>}
        <RNTextInput
          // @ts-ignore
          name={name}
          ref={ref}
          style={[
            styles.input,
            invalid && !focused && styles.invalid,
            numberOfLines !== undefined && styles.multiline,
          ]}
          // This is a workaround into adding custom CSS to RN components
          // Based on solution proposed by RNW author https://github.com/necolas/react-native-web/issues/1318#issuecomment-479043470
          dataSet={{ textInput: "class" }}
          value={value}
          testID={testID}
          disabled={disabled}
          onChangeText={onChange}
          textContentType={textContentType}
          numberOfLines={numberOfLines}
          multiline={!!numberOfLines}
          keyboardType={keyboardType}
          onKeyPress={onKeyPress}
          onFocus={(e) => {
            onFocus?.(e);
            setFocused(true);
          }}
          onBlur={(e) => {
            onBlur?.(e);
            setFocused(false);
          }}
          onSubmitEditing={onSubmitEditing}
          secureTextEntry={secureTextEntry}
          placeholder={inputPlaceholder}
        />

        {rightIcon && (
          <Pressable
            style={styles.rightIconContainer}
            onPress={onRightIconClick}
          >
            {rightIcon}
          </Pressable>
        )}
      </FieldContainer>
    );
  },
);

const styles = StyleSheet.create({
  invalid: {
    color: colors.brand.grapefruitcore,
  },
  input: {
    height: "100%",
    width: "100%",
    borderRadius: tokens.radius,
    paddingHorizontal: 12,
    backgroundColor: "transparent",
    outlineStyle: "none",
    color: tokens.colors.utility.regular,
    // Font styles are added in CSS
  },
  multiline: {
    paddingTop: 8,
  },
  leftIconContainer: {
    marginLeft: 8,
    flexShrink: 0,
  },
  rightIconContainer: {
    right: "8px",
  },
});
