import { SearchBar } from "components/app_header_v3/search_bar";
import React from "react";
import { StyleSheet, View } from "react-native";

interface MobileHeaderProps {
  withSearch: boolean;
  linkToSearchPage?: boolean;
  onSearch?: (field: string) => void;
}

export function MobileHeader(props: MobileHeaderProps) {
  const { withSearch, linkToSearchPage = false, onSearch } = props;

  if (!withSearch) {
    return null;
  }
  return (
    <View style={styles.mobileHeader}>
      <SearchBar onSearch={onSearch} linkToSearchPage={linkToSearchPage} />
    </View>
  );
}

const styles = StyleSheet.create({
  mobileHeader: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: "#fff",
  },
  // @ts-ignore
  unTouchAbleView: { pointerEvents: "none" },
});
