import { CardsSaveIcon } from "components/cards_save_icon";
import { PreferredBadge } from "components/preferred_badge";
import { WhoIsHere } from "components/who_is_here";
import {
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { uniqueBy } from "lib/array_utils";
import { today } from "lib/day_utils";

import { maxBy, minBy } from "lib/math_utils";
import { sortAmenities } from "lib/sort_amenities";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";
import {
  SpaceCardAddressWithDistance,
  SpaceCardAmenities,
  SpaceCardContent,
  SpaceCardDetailsLink,
  SpaceCardDynamicPrice,
  SpaceCardOutOfPolicyBadge,
  SpaceCardPhoto,
  SpaceCardTemplate,
  SpaceCardTitleWithCapacity,
  SpaceCardWhoIsHere,
} from "./space_card_v2/space_card_template";

interface ListingDayOfficeCardProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (locationId: HomePage__LocationDetailsFragment) => void;
}

export function ListingDayOfficeCardV2(props: ListingDayOfficeCardProps) {
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  const { spaces, onPreviewLocation } = props;
  const firstSpace = spaces[0];
  const firstPhysicalSpace = firstSpace.physicalSpaces[0];
  const filter = useHomeSearchParamsQuery();
  const amenities = useMemo(
    () =>
      filter.amenities
        ? sortAmenities(
            firstSpace.amenities,
            firstSpace.location.amenities,
            filter.amenities,
          )
        : [],
    [filter.amenities, firstSpace.amenities, firstSpace.location.amenities],
  );
  const physicalSpaces = [];
  for (const space of spaces) {
    for (const physicalSpace of space.physicalSpaces) {
      physicalSpaces.push(physicalSpace);
    }
  }

  const totalPhysicalSpaces = physicalSpaces.length;

  const officeSpacePrices = spaces
    .map((s) => ({
      pricings: s.pricings,
      currency: s.currency,
      priceRanges: s.priceRanges,
    }))
    .sort((a, b) => a.pricings[0].price - b.pricings[0].price);

  const priceTag = {
    pricings: [
      officeSpacePrices?.[0].pricings?.[0],
      officeSpacePrices?.[officeSpacePrices?.length - 1]?.pricings?.[0],
    ],
    /* Cause this officeSpacePrices list already sorted by basePrice,
    so for the dynamic price of this location, the min price is min price of element has lowest basePrice,
    and the max price is max price of element has highest basePrice;
    */
    priceRanges: {
      daily:
        officeSpacePrices?.[0].priceRanges &&
        officeSpacePrices?.[officeSpacePrices?.length - 1]?.priceRanges
          ? {
              min: officeSpacePrices?.[0].priceRanges?.daily.min,
              max: officeSpacePrices?.[officeSpacePrices?.length - 1]
                ?.priceRanges?.daily.max,
            }
          : { min: null, max: null },
      // This hourly null because with component/logic only run in the booking with office
      hourly: { min: null, max: null },
    },
    currency: officeSpacePrices?.[0]?.currency,
  };
  const imageSrc =
    firstSpace.location.images[0]?.small.url || firstSpace.images[0]?.small.url;

  const minCapacity = minBy(spaces, (s) => s.maxCapacity);
  const maxCapacity = maxBy(spaces, (s) => s.maxCapacity);

  let forwardSearch = `?physicalSpaceID=${firstPhysicalSpace?.id}`;

  if (filter.date) {
    forwardSearch += `&startDate=${filter.date}&endDate=${filter.date}`;
  }

  return (
    <SpaceCardTemplate
      testId={"day-office-card"}
      linkTo={
        physicalSpaces.length === 1
          ? `/offsite_spaces/${firstSpace.id}${forwardSearch}`
          : undefined
      }
      onPress={() => onPreviewLocation(firstSpace.location)}
    >
      <View style={styles.relativeContainer}>
        <SpaceCardPhoto imageSrc={imageSrc} hasOverlay={firstSpace.locked} />
        <CardsSaveIcon
          favoriteId={firstSpace.id}
          favoriteType={FavoriteType.Space}
        />
      </View>
      <SpaceCardContent>
        <SpaceCardTitleWithCapacity
          minCapacity={minCapacity}
          maxCapacity={maxCapacity}
          title={firstSpace.location.name}
        />
        <SpaceCardAddressWithDistance
          currentUserDistance={firstSpace.location.currentUserDistance}
          locationLat={firstSpace.location.address.latitude}
          locationLng={firstSpace.location.address.longitude}
          shortAddress={firstSpace.location.address.city}
          fullAddress={firstSpace.location.address.streetAddress}
        />
        {uniqueBy(amenities, (item) => item.name).length > 0 && (
          <SpaceCardAmenities
            amenities={uniqueBy(amenities, (item) => item.name)}
          />
        )}

        <SpaceCardDynamicPrice
          spaceType={SpaceType.DayOffice}
          pricings={priceTag.pricings}
          currency={priceTag.currency}
          priceRanges={priceTag.priceRanges}
          isPickedDate={!!filter.date}
          outOfPolicy={firstSpace.locked}
        />

        {isPreferredFlag && firstSpace.preferred && (
          <PreferredBadge variant={"preferredOnly"} />
        )}

        {totalPhysicalSpaces > 1 ? (
          <SpaceCardDetailsLink>
            See {totalPhysicalSpaces} offices
          </SpaceCardDetailsLink>
        ) : (
          <SpaceCardDetailsLink>See office details</SpaceCardDetailsLink>
        )}
      </SpaceCardContent>

      {firstSpace.locked ? (
        <SpaceCardOutOfPolicyBadge />
      ) : (
        <SpaceCardWhoIsHere>
          <WhoIsHere
            date={filter.date || today()}
            peoples={firstSpace.location.bookings.map((b) => b.user)}
          />
        </SpaceCardWhoIsHere>
      )}
    </SpaceCardTemplate>
  );
}

const styles = StyleSheet.create({
  relativeContainer: {
    position: "relative",
  },
});
