import {
  DatePickerChildrenType,
  DatePickerDropdown,
} from "components/date_picker/date_picker_dropdown";
import { Icon } from "components/iconv2";
import { SelectButtonWithIconV1 } from "components/select/select_button_with_icon_v1";
import { formatDate } from "lib/date_utils";
import { Day, parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";

interface HeaderDatePickerProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
}

export function HeaderDatePickerV1(props: HeaderDatePickerProps) {
  const { value, onChange } = props;
  const renderButton = ({ onToggleOpen }: DatePickerChildrenType) => (
    <SelectButtonWithIconV1
      testID="header-date-picker"
      onPress={onToggleOpen}
      title={
        value
          ? formatDate(parseDay(value), getSystemLocale(), {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "Any day"
      }
      leftIcon={<Icon name="calendar" size="sm" filled />}
    />
  );

  return (
    <DatePickerDropdown value={value} onChange={onChange} confirmMode={false}>
      {renderButton}
    </DatePickerDropdown>
  );
}
