import { DateInterval } from "lib/date_utils";
import { getFirstDateOfWeekLocale } from "lib/week_utils";
import { useCallback } from "react";
import { MonthRenderer, RenderDayProps } from "../month_renderer";
import { Spacer } from "../spacer";
import * as renderComponents from "./components/components";

export interface DynamicPrice {
  date: string;
  price: string;
  isDiscountRate: boolean;
}

interface MonthViewProps {
  selectedInterval?: DateInterval | null;
  month: Date;
  onSelectDay?: (day: Date) => void;
  isOutsideRange?: (day: Date) => boolean;
  isBlocked?: (day: Date) => boolean;
  topOffset?: number;
  withDaysOfWeek?: boolean;
  renderDay?: typeof renderComponents.renderDay;
  renderOtherMonthDay?: typeof renderComponents.renderOtherMonthDay;
  renderDayOfWeek?: typeof renderComponents.renderDayOfWeek;
  renderWeek?: typeof renderComponents.renderWeek;
  basePrice?: string;
  dynamicPrices?: DynamicPrice[];
}

export function MonthView(props: MonthViewProps) {
  const {
    selectedInterval,
    month,
    onSelectDay,
    isOutsideRange,
    isBlocked,
    topOffset = 24,
    withDaysOfWeek = true,
    renderDay = renderComponents.renderDay,
    renderOtherMonthDay = renderComponents.renderOtherMonthDay,
    renderDayOfWeek = renderComponents.renderDayOfWeek,
    renderWeek = renderComponents.renderWeek,
    basePrice,
    dynamicPrices,
  } = props;

  const handleSelectDay = useCallback(
    (day: Date) => {
      if (onSelectDay !== undefined) {
        onSelectDay(day);
      }
    },
    [onSelectDay],
  );

  const renderDayCustom = useCallback(
    (p: RenderDayProps) => renderDay(p, handleSelectDay),
    [handleSelectDay, renderDay],
  );

  return (
    <>
      <Spacer size={topOffset} />
      <MonthRenderer
        firstDayOfWeek={getFirstDateOfWeekLocale()}
        selectedInterval={selectedInterval}
        withDaysOfWeek={withDaysOfWeek}
        month={month}
        isOutsideRange={isOutsideRange}
        isBlocked={isBlocked}
        renderDay={renderDayCustom}
        renderOtherMonthDay={renderOtherMonthDay}
        renderWeek={renderWeek}
        renderDayOfWeek={renderDayOfWeek}
        basePrice={basePrice}
        dynamicPrices={dynamicPrices}
      />
    </>
  );
}
