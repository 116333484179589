import { differenceInMinutes } from "lib/date_utils";
import { parseDay } from "lib/day_utils";

export const getLastUpdatedLabel = (lastUpdatedDate: string) => {
  const minutes = differenceInMinutes(new Date(), parseDay(lastUpdatedDate));

  if (minutes === 0) {
    return "Last updated now";
  }

  return `Last updated ${minutes} mins ago`;
};
export const getDataAsLabel = (lastUpdatedDate?: string) => {
  if (!lastUpdatedDate) {
    return "Data updated 25 minutes ago";
  }
  const minutes = differenceInMinutes(new Date(), parseDay(lastUpdatedDate));

  if (minutes === 0) {
    return "Data updated now";
  }

  return `Data as of ${minutes} mins ago`;
};
