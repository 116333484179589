import { Spacer } from "components/spacer";
import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";
import { View } from "react-native";

export function HomeSkeletonMobile() {
  return (
    <View
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Spacer size={20} />
      <ContentLoader
        speed={2}
        width="100%"
        height={330}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect rx="8" ry="8" width="100%" height="328" />
      </ContentLoader>
      <Spacer size={15} />
      <ContentLoader
        speed={2}
        width="100%"
        height={330}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect rx="8" ry="8" width="100%" height="328" />
      </ContentLoader>
    </View>
  );
}
