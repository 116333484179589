import React, { Fragment } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { colors } from "./colors";

import { Container } from "./container";
import { tokens } from "./tokens";

interface InputContainerProps {
  label?: string;
  hasValue: boolean;
  hasLeftIcon?: boolean;
  invalid?: boolean;
  invalidText?: string;
  description?: string;
  focused?: boolean;
  height?: number;
  children: React.ReactNode;
}

export function InputContainer(props: InputContainerProps) {
  const {
    children,
    height,
    hasLeftIcon,
    hasValue,
    label,
    focused,
    invalid,
    invalidText,
    description,
  } = props;

  return (
    <Fragment>
      <View
        style={[
          styles.wrapper,
          invalid && styles.invalid,
          !!label && (hasValue || focused) && styles.padLabel,
          focused && styles.focused,
          height !== undefined && { height },
        ]}
      >
        {children}
        {label && (
          <Animated.Text
            style={[
              styles.label,
              hasLeftIcon && styles.leftIconWrapper,
              hasValue && styles.hasValueLabel,
              invalid && styles.invalidLabel,
              focused && styles.focusedLabel,
            ]}
          >
            {label}
          </Animated.Text>
        )}
      </View>
      {invalidText && (
        <Container>
          <Animated.Text style={styles.invalidText}>
            {invalidText}
          </Animated.Text>
        </Container>
      )}
      {description && (
        <Container>
          <Animated.Text style={styles.description}>
            {description}
          </Animated.Text>
        </Container>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    borderColor: tokens.colors.neutral.darker,
    borderWidth: 1,
    height: 48,
  },
  focused: {
    borderColor: "#534588",
    backgroundColor: tokens.colors.base.white,
  },
  padLabel: {
    paddingTop: 15,
  },
  leftIconWrapper: {
    left: 40,
  },
  invalid: {
    borderColor: tokens.colors.secondary.dark,
    backgroundColor: tokens.colors.neutral.regular,
  },
  invalidLabel: {
    color: tokens.colors.secondary.dark,
  },
  invalidText: {
    paddingHorizontal: 12,
    paddingTop: 3,
    color: tokens.colors.secondary.dark,
    ...tokens.text.size.xs,
  },
  description: {
    paddingHorizontal: 12,
    paddingTop: 3,
    color: tokens.colors.utility.lighter,
    ...tokens.text.size.xs,
  },
  focusedLabel: {
    color: tokens.colors.primary.regular,
    fontWeight: "normal",
    top: 4,
    ...tokens.text.size.xs,
  },
  hasValueLabel: {
    color: tokens.colors.utility.lighter,
    fontWeight: "normal",
    top: 4,
    ...tokens.text.size.xs,
  },
  label: {
    position: "absolute",
    left: 12,
    top: 13,
    color: tokens.colors.utility.lighter,
    zIndex: -1,
    fontSize: 15,
    lineHeight: 18,
  },
});
