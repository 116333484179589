import React from "react";
import { Carousel as LibCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CarouselProps } from "./carousel";
import "./carousel.web.css";

export function Carousel(props: CarouselProps) {
  const {
    children,
    showIndicators = false,
    showArrows = false,
    renderArrowNext,
    renderArrowPrev,
    renderIndicator,
    centerSlidePercentage,
    centerMode,
  } = props;

  return (
    <LibCarousel
      autoPlay={false}
      showArrows={showArrows}
      showThumbs={false}
      showStatus={false}
      renderArrowNext={showArrows ? renderArrowNext : undefined}
      renderArrowPrev={showArrows ? renderArrowPrev : undefined}
      renderIndicator={renderIndicator}
      showIndicators={showIndicators}
      centerSlidePercentage={centerSlidePercentage}
      centerMode={centerMode}
    >
      {children as any}
    </LibCarousel>
  );
}
