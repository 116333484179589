import { Container } from "components/container";
import { Icon as IconV2 } from "components/iconv2";
import * as renderComponents from "components/month_view/components/components";
import { MonthView } from "components/month_view/month_view";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Tabs } from "components/tab/tabs";
import { Text } from "components/text_v2";
import { TimeRangePicker } from "components/time_range_picker/time_range_picker";
import { addMonths, format, subMonths } from "date-fns";
import { formatISODate } from "lib/date_utils";
import React, { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import {
  BookingDateRangePickerProps,
  DailyBookingDateRangePickerProps,
  DatePickerDisplayProps,
  DatePickerProps,
  DateRangePickerProps,
  HourlyBookingDateRangePickerProps,
  useDailyBookingDateRangePickerHelpers,
  useHourlyBookingDateRangePickerHelpers,
  useSelectDayDateRangeHandler,
} from "./booking_date_range_picker";
import { BookingType, PricingType } from "./graphql.generated";
import { formatPrice } from "pages/homev2/components/space_card_v2/space_card_template";
import { usePriceDiscoverabilityFeatureFlag } from "providers/splitio";

export function BookingDateRangePickerDesktop(
  props: BookingDateRangePickerProps,
) {
  const {
    onChange,
    value,
    bookingType = BookingType.HourlyBooking,
    onChangeBookingType,
    showSegmentedControl = true,
    multiDayBookingAllowed = true,
    timeslots,
    isBlockedDate,
    isOutsideRange,
    pricings,
    dynamicPrices,
    currency,
    spaceId,
    priceRanges,
  } = props;
  const _isBlockedDate = useCallback(
    (date: Date, _bookingType: BookingType) => {
      if (isBlockedDate !== undefined) {
        return isBlockedDate(formatISODate(date), _bookingType);
      }

      return false;
    },
    [isBlockedDate],
  );
  const _isOutsideRange = useCallback(
    (date: Date) => {
      if (isOutsideRange !== undefined) {
        return isOutsideRange(formatISODate(date));
      }

      return false;
    },
    [isOutsideRange],
  );

  const getBasePrice = (pricingType: PricingType) => {
    const price = pricings.find((p) => p.type === pricingType)?.price;
    return price;
  };

  const hasHourPriceRange = priceRanges?.hourly.max !== priceRanges?.hourly.min;
  const showPriceInHourPicker = hasHourPriceRange || dynamicPrices.length;

  return (
    <Container testID="booking-date-range-picker-desktop">
      {bookingType === BookingType.HourlyBooking && (
        <HourlyBookingDateRangePickerDesktop
          showSegmentedControl={showSegmentedControl}
          onChangeBookingType={onChangeBookingType}
          value={value?.type === BookingType.HourlyBooking ? value : undefined}
          onChange={onChange}
          timeslots={timeslots}
          isBlockedDate={_isBlockedDate}
          isOutsideRange={_isOutsideRange}
          basePrice={
            showPriceInHourPicker ? getBasePrice(PricingType.Hourly) : undefined
          }
          dynamicPrices={showPriceInHourPicker ? dynamicPrices : undefined}
          spaceId={spaceId}
          currency={currency}
        />
      )}
      {bookingType === BookingType.DailyBooking && (
        <DailyBookingDateRangePickerDesktop
          showSegmentedControl={showSegmentedControl}
          multiDayBookingAllowed={multiDayBookingAllowed}
          onChangeBookingType={onChangeBookingType}
          value={value?.type === BookingType.DailyBooking ? value : undefined}
          onChange={onChange}
          isBlockedDate={_isBlockedDate}
          isOutsideRange={_isOutsideRange}
          basePrice={
            dynamicPrices.length ? getBasePrice(PricingType.Daily) : undefined
          }
          dynamicPrices={dynamicPrices.length ? dynamicPrices : undefined}
          currency={currency}
        />
      )}
    </Container>
  );
}

function HourlyBookingDateRangePickerDesktop(
  props: HourlyBookingDateRangePickerProps,
) {
  const {
    value,
    isBlockedDate,
    isOutsideRange,
    onChange,
    timeslots,
    showSegmentedControl,
    onChangeBookingType,
    basePrice,
    dynamicPrices,
    spaceId,
    currency,
  } = props;
  const {
    date,
    handleDateChange,
    handleTimeRangeChange,
    start,
    end,
    recommendation,
    betterRateTimeSlots,
    betterDynamicPrices,
  } = useHourlyBookingDateRangePickerHelpers({
    value,
    onChange,
    spaceId,
    timeSlots: timeslots,
    dynamicPrices,
    basePrice,
  });

  const priceWithCurrency = basePrice ? formatPrice(basePrice, currency) : "";
  const dynamicPricesWithCurrency = betterDynamicPrices?.map(
    ({ date, price }) => ({
      date,
      price: formatPrice(price, currency),
      isDiscountRate: basePrice ? price < basePrice : false,
    }),
  );

  const handlePickerChangeDate = (date: Date) => {
    const unitPrice =
      dynamicPrices?.find((p) => p.date === format(date, "yyyy-MM-dd"))
        ?.price || basePrice;
    handleDateChange(date, unitPrice);
  };

  return (
    <Container>
      <Row>
        <Container flex={1}>
          {showSegmentedControl === true && (
            <Tabs
              value={BookingType.HourlyBooking}
              onChange={onChangeBookingType}
              options={[
                { value: BookingType.DailyBooking, label: "Daily" },
                { value: BookingType.HourlyBooking, label: "Hourly" },
              ]}
            />
          )}
          <Spacer size={20} />
        </Container>
      </Row>

      <DatePicker
        isBlocked={(d) =>
          isBlockedDate ? isBlockedDate(d, BookingType.HourlyBooking) : false
        }
        value={date}
        onChange={handlePickerChangeDate}
        isOutsideRange={isOutsideRange}
        basePrice={priceWithCurrency}
        dynamicPrices={dynamicPricesWithCurrency}
      />
      {recommendation && (
        <View>
          <Spacer size={16} />

          <Text
            key={recommendation}
            size="xs"
            color="eggplant-core"
            align="center"
          >
            {recommendation}
          </Text>
        </View>
      )}
      <Spacer size={17} />
      <Container flex={1}>
        <TimeRangePicker
          disabled={!value?.date || !betterRateTimeSlots.length}
          value={{ start, end }}
          onChange={handleTimeRangeChange}
          timeslots={betterRateTimeSlots}
          appearance={"v4"}
        />
      </Container>
    </Container>
  );
}

function DailyBookingDateRangePickerDesktop(
  props: DailyBookingDateRangePickerProps,
) {
  const {
    value,
    onChange,
    isBlockedDate,
    isOutsideRange,
    showSegmentedControl,
    multiDayBookingAllowed,
    onChangeBookingType,
    basePrice,
    dynamicPrices,
    currency,
  } = props;

  const { dateRange, handleDateRangeChange, handleDateChange } =
    useDailyBookingDateRangePickerHelpers({ value, onChange });

  const priceWithCurrency = basePrice ? formatPrice(basePrice, currency) : "";
  const dynamicPricesWithCurrency = dynamicPrices?.map(({ date, price }) => ({
    date,
    price: formatPrice(price, currency),
    isDiscountRate: basePrice ? price < basePrice : false,
  }));

  return (
    <Container>
      <Row>
        <Container flex={1}>
          {showSegmentedControl === true && (
            <Tabs
              value={BookingType.DailyBooking}
              onChange={onChangeBookingType}
              options={[
                { value: BookingType.DailyBooking, label: "Daily" },
                { value: BookingType.HourlyBooking, label: "Hourly" },
              ]}
            />
          )}
          <Spacer size={20} />
        </Container>
      </Row>

      {multiDayBookingAllowed && (
        <DateRangePicker
          value={dateRange}
          onChange={handleDateRangeChange}
          isOutsideRange={isOutsideRange}
          isBlocked={(date) =>
            isBlockedDate
              ? isBlockedDate(date, BookingType.DailyBooking)
              : false
          }
          basePrice={priceWithCurrency}
          dynamicPrices={dynamicPricesWithCurrency}
        />
      )}
      {!multiDayBookingAllowed && (
        <DatePicker
          value={dateRange?.start}
          onChange={handleDateChange}
          isOutsideRange={isOutsideRange}
          isBlocked={(date) =>
            isBlockedDate
              ? isBlockedDate(date, BookingType.DailyBooking)
              : false
          }
          basePrice={priceWithCurrency}
          dynamicPrices={dynamicPricesWithCurrency}
        />
      )}
    </Container>
  );
}

function DatePicker(props: DatePickerProps) {
  const {
    value,
    onChange,
    isOutsideRange,
    isBlocked,
    basePrice,
    dynamicPrices,
  } = props;

  return (
    <DatePickerDisplay
      selected={value ? { start: value, end: value } : null}
      onSelectDay={onChange}
      isOutsideRange={isOutsideRange}
      isBlocked={isBlocked}
      basePrice={basePrice}
      dynamicPrices={dynamicPrices}
    />
  );
}

function DateRangePicker(props: DateRangePickerProps) {
  const {
    value,
    onChange,
    isOutsideRange,
    isBlocked,
    basePrice,
    dynamicPrices,
  } = props;

  return (
    <DatePickerDisplay
      selected={value}
      onSelectDay={useSelectDayDateRangeHandler({ value, onChange, isBlocked })}
      isOutsideRange={isOutsideRange}
      isBlocked={isBlocked}
      basePrice={basePrice}
      dynamicPrices={dynamicPrices}
    />
  );
}

function DatePickerDisplay(props: DatePickerDisplayProps) {
  const {
    selected,
    onSelectDay,
    isOutsideRange,
    isBlocked,
    basePrice,
    dynamicPrices,
  } = props;
  const discoverabilityFlag = usePriceDiscoverabilityFeatureFlag();

  const [leftMonth, setLeftMonth] = useState(
    selected ? selected.start : new Date(),
  );

  const handlePressPrevious = useCallback(() => {
    setLeftMonth(subMonths(leftMonth, 1));
  }, [leftMonth, setLeftMonth]);

  const handlePressNext = useCallback(() => {
    setLeftMonth(addMonths(leftMonth, 1));
  }, [leftMonth, setLeftMonth]);

  return (
    <Container>
      <Row>
        <Container flex={1}>
          <View style={styles.monthNavigatorWrapper}>
            <Pressable
              testID="picker-prev-month-button"
              onPress={handlePressPrevious}
            >
              <IconV2 name="chevron-left" />
            </Pressable>
            <Text align="center" weight={"semibold"}>
              {format(leftMonth, "MMMM yyyy")}
            </Text>
            <Pressable
              testID="picker-next-month-button"
              onPress={handlePressNext}
            >
              <IconV2 name="chevron-right" />
            </Pressable>
          </View>
          <MonthView
            selectedInterval={selected}
            month={leftMonth}
            onSelectDay={onSelectDay}
            isOutsideRange={isOutsideRange}
            isBlocked={isBlocked}
            topOffset={16}
            renderDay={
              discoverabilityFlag
                ? renderComponents.renderDayV2
                : renderComponents.renderDay
            }
            renderDayOfWeek={renderComponents.renderDayOfWeekV2}
            basePrice={basePrice}
            dynamicPrices={dynamicPrices}
          />
        </Container>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  arrowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 999,
    textAlign: "center",
    height: 24,
    width: 24,
    borderWidth: 1,
  },
  monthNavigatorWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
