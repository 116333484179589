import { StyleSheet, View } from "react-native";

import { colors } from "components/colors";
import { Text } from "components/text_v2";

interface PropsType {
  title: string;
  description: string;
}

export const EmptyRequests: React.FC<PropsType> = ({ title, description }) => {
  return (
    <View testID="empty-requests" style={styles.root}>
      <Text weight="semibold" size="md" color="black-50">
        {title}
      </Text>
      <Text size="xs" color="black-70" align="center">
        {description}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    padding: 20,
  },
});
