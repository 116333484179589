import { useCallback, useState } from "react";
import { useAuthV2 } from "providers/authv2";
import { SIGN_UP_STEPS_REDIRECT_URL_KEY } from "providers/auth_loader";
import { AcceptInviteCredentials } from "../accept_invite";
import { StepPasswordUI } from "components/sign_up_shared/step_password_ui";

interface InviteMemberStepPasswordProps {
  onGoNext: () => void;
  onGoBack: () => void;
  credentials: AcceptInviteCredentials;
}

export function InviteMemberStepPassword(props: InviteMemberStepPasswordProps) {
  const { onGoNext, credentials } = props;
  const { signUpWithEmailAndPassword } = useAuthV2();
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    localStorage.setItem(SIGN_UP_STEPS_REDIRECT_URL_KEY, "/accept-invite/code");
    // TODO: Use email saved from input
    signUpWithEmailAndPassword(name, credentials.email, password)
      .then(() => {
        // This actually is not called as auth0 does a redirect
        onGoNext();
      })
      .catch((error) => {
        // TODO: handle error
        console.error(error);
        setLoading(false);

        if (error) {
          setError(true);
        }
      });
  }, [signUpWithEmailAndPassword, name, credentials.email, password, onGoNext]);

  return (
    <StepPasswordUI
      title="Set up your account"
      subtitle="Enter your full name and a password for your Flexspace account."
      onNameChange={setName}
      name={name}
      password={password}
      onPasswordChange={setPassword}
      onSubmit={handleSubmit}
      withTOS
      error={error}
      loading={loading}
    />
  );
}
