import { useMediaQuery } from "lib/media_query";

import { OffsiteSpaceDetailsDesktop } from "./offsite_space_details_desktop";
import { OffsiteSpaceDetailsMobile } from "./offsite_space_details_mobile";
import { OffsiteSpaceDetailsMobileComponents } from "./offsite_space_details_content_mobile";
import { ReactNode } from "react";

export type BookingDetailsPageComponents = OffsiteSpaceDetailsMobileComponents;

interface OffsiteSpaceDetailsLayoutProps {
  children: JSX.Element;
  actions: ReactNode;
}

export function OffsiteSpaceDetailsLayout({
  children,
  actions,
}: OffsiteSpaceDetailsLayoutProps) {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return (
      <OffsiteSpaceDetailsMobile actions={actions}>
        {children}
      </OffsiteSpaceDetailsMobile>
    );
  }

  return <OffsiteSpaceDetailsDesktop>{children}</OffsiteSpaceDetailsDesktop>;
}
