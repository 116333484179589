import React from "react";
import { format } from "date-fns";
import { Time, parseTime } from "lib/time_utils";
import { useMemo } from "react";
import { SelectInput } from "./select/select_input";

interface TimePickerProps {
  testID?: string;
  value?: Time;
  onChange?: (value: Time) => void;
  /** Minimum difference in minutes between time options. */
  formatter?: (time: Time) => string;
  isBlocked?: (time: Time) => boolean;
  hours: Time[];
  label?: string;
  description?: string;
  invalidText?: string;
  disabled?: boolean;
  invalid?: boolean;
  withIcon?: boolean;
  betterRateHours?: Time[];
}

/**
 * Control for selecting a
 */
export function TimePicker(props: TimePickerProps) {
  const {
    value,
    hours,
    onChange = () => {},
    testID,
    formatter = (time: Time) => format(parseTime(time), "HH:mm"),
    label,
    disabled = false,
    betterRateHours,
  } = props;

  const options = useMemo(() => {
    return hours.map((h) => {
      return {
        label: formatter(h),
        value: h,
        highlight: betterRateHours?.includes(h),
      };
    });
  }, [hours, formatter, betterRateHours]);

  const handleChange = (value: string | undefined) => {
    if (value) {
      onChange(value);
    }
  };

  return (
    <SelectInput
      testID={testID}
      options={options}
      disabled={disabled}
      value={value}
      placeholder={label}
      onChange={handleChange}
      dropdownMaxHeight="250px"
    />
  );
}
