import React, { useMemo } from "react";

import { addDays, getLocalToday } from "lib/day_utils";
import {
  OrganizationUser,
  OffsiteLocationWhosHereQuery,
  OffsiteLocationWhosHereQueryVariables,
  OffsiteLocationWhosHere__BookingDetailsFragment,
} from "./graphql.generated";
import { People, Person } from "./people";
import { useQuery, gql } from "@apollo/client";

interface OffsiteLocationWhosHereProps {
  locationID: string;
  timeZone: string;
  userIDAppearFirst: string;
}

export function OffsiteLocationWhosHere(props: OffsiteLocationWhosHereProps) {
  const { locationID, timeZone, userIDAppearFirst } = props;

  const today = useMemo(() => getLocalToday(timeZone), [timeZone]);
  const dayRange = useMemo(() => {
    return {
      start: today,
      end: addDays(today, 7),
    };
  }, [today]);
  const { data: offsiteLocationWhosHereQuery } = useQuery<
    OffsiteLocationWhosHereQuery,
    OffsiteLocationWhosHereQueryVariables
  >(offsiteLocationWhosHereGQLQuery, {
    variables: {
      offsiteLocationID: locationID,
      startDate: dayRange.start,
      endDate: dayRange.end,
    },
  });
  const offsiteLocationBookings =
    offsiteLocationWhosHereQuery?.offsiteLocation?.bookings;

  if (!offsiteLocationBookings || offsiteLocationBookings.length === 0) {
    return null;
  }

  return (
    <People
      people={getPeopleFromBookings(offsiteLocationBookings, userIDAppearFirst)}
    />
  );
}

export function getPeopleFromBookings(
  bookings: OffsiteLocationWhosHere__BookingDetailsFragment[],
  userIDAppearFirst: string,
): Person[] {
  const whosHere = shiftUserToFirst(
    removeDuplicateUsers(bookings.map((b) => b.user)),
    userIDAppearFirst,
  );

  return whosHere.map((u) => ({
    userID: u.id || "",
    name: u.fullName || "",
    pictureURL: u.picture || "",
    title: u.title,
    status: "off",
  }));
}
function removeDuplicateUsers(users: Partial<OrganizationUser>[]) {
  return [...new Map(users.map((user) => [user.id, user])).values()];
}

function shiftUserToFirst(users: Partial<OrganizationUser>[], userID: string) {
  const results = [];
  for (let i = 0; i < users.length; i++) {
    if (users[i].id === userID) {
      results.unshift(users[i]);
    } else {
      results.push(users[i]);
    }
  }
  return results;
}

export const offsiteLocationWhosHereGQLQuery = gql`
  query OffsiteLocationWhosHere(
    $offsiteLocationID: ID!
    $startDate: String!
    $endDate: String!
  ) {
    offsiteLocation(id: $offsiteLocationID) {
      id
      bookings(startDate: $startDate, endDate: $endDate) {
        ...OffsiteLocationWhosHere__BookingDetails
      }
    }
  }

  fragment OffsiteLocationWhosHere__BookingDetails on Booking {
    id
    startDate
    endDate
    user {
      id
      fullName
      title
      picture
    }
  }
`;
