import { calculateBboxFromCenter } from "lib/map_utils";
import { useConfig } from "providers/config";
import { useMapImprovementFeatureFlag } from "providers/splitio";
import useSWR from "swr";
import { MapboxFeatureCollection } from "../mapbox";
import { useCurrentUserGeoCoordinatesQuery } from "./use_current_user_geo_coordinates";
import { useCurrentUserIpCoordinates } from "./use_current_user_ip_coordinates";

export function useForwardGeocoding(
  search: string | undefined,
  options?: { skip?: boolean },
) {
  const { mapboxApiAccessToken } = useConfig();
  const { data: geoCoordinates, loading: geoCoordinatesLoading } =
    useCurrentUserGeoCoordinatesQuery();
  const { data: ipCoordinates } = useCurrentUserIpCoordinates({
    skip: !!(options?.skip || geoCoordinates || geoCoordinatesLoading),
  });
  const mapImprovementFeatureFlag = useMapImprovementFeatureFlag();
  const searchParams = new URLSearchParams();

  searchParams.set(
    "types",
    "address,region,district,place,neighborhood,locality,poi",
  );

  if (!mapImprovementFeatureFlag) {
    if (geoCoordinates?.lng && geoCoordinates?.lng) {
      searchParams.set(
        "proximity",
        `${geoCoordinates?.lng},${geoCoordinates?.lat}`,
      );
    } else if (ipCoordinates?.lng && ipCoordinates.lat) {
      searchParams.set(
        "proximity",
        `${ipCoordinates?.lng},${ipCoordinates?.lat}`,
      );
    }
  }

  searchParams.set("access_token", mapboxApiAccessToken);
  searchParams.set("autocomplete", "true");

  const { data, error } = useSWR<MapboxFeatureCollection>(
    search && !options?.skip
      ? `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?${searchParams.toString()}`
      : null,
  );

  if (data?.features) {
    data.features.forEach((feature) => {
      if (!feature.bbox) {
        feature.bbox = calculateBboxFromCenter(feature.center);
      }
    });
  }

  return {
    loading: search && !data && !error,
    data: data?.features,
    error: error,
  };
}
