import "./components_v2/data_table_v2.css";
import { DropdownMenu } from "components/dropdown_menu/dropdown_menu";
import { useCallback } from "react";
import { View } from "react-native";
import { colors } from "components/colors";
import { Text } from "components/text_v2";
import { Cell } from "./components_v2/cell";
import { Row } from "./components_v2/row";
import { TableHeader } from "./components_v2/table_header";
import {
  DataTableBase,
  DataTableBasePropTypes,
  DataTableRow,
} from "./data_table_base";

interface PropTypes extends DataTableBasePropTypes {}

export const TABLE_PADDING = 24;

export const DataTableV2 = (props: PropTypes) => {
  const {
    columns,
    withContextMenu,
    searchPlaceholder,
    sortableBy,
    searchableBy,
  } = props;

  const renderTHRows = useCallback(
    () => (
      <Row isHeader>
        {columns.map((column) => (
          <Cell key={`th-${column.id}`} isHeader width={column.width}>
            {column.renderTitle ? (
              column.renderTitle(column)
            ) : (
              <Text
                customColor={colors.brand.blackMinus30}
                size="xs"
                weight="semibold"
              >
                {column.title}
              </Text>
            )}
          </Cell>
        ))}
        {withContextMenu && <Cell isHeader width={25} />}
      </Row>
    ),
    [columns, withContextMenu],
  );

  const renderTRRows = useCallback(
    ({ sortedFilteredRows }) =>
      sortedFilteredRows.map((row: DataTableRow) => (
        <Row key={row.id}>
          {columns.map((column) => {
            const rowItem = row[column.id];

            return (
              <Cell width={column.width} key={`${row.id}-${column.id}`}>
                {rowItem.render ? (
                  rowItem.render(row)
                ) : (
                  <Text customColor={colors.brand.blackcore} size="xs">
                    {rowItem}
                  </Text>
                )}
              </Cell>
            );
          })}
          {withContextMenu && row.contextMenu && (
            <Cell width="fit-content">
              <DropdownMenu
                items={row.contextMenu || []}
                disabled={!row.contextMenu?.filter(Boolean).length}
                sameMinWidth
                sameWidth={false}
                placement="bottom-end"
                testID="data-table-context-menu"
              />
            </Cell>
          )}
        </Row>
      )),
    [columns, withContextMenu],
  );

  return (
    <DataTableBase {...props}>
      {({
        sortedFilteredRows,
        searchInput,
        handleSearchChange,
        handleSortChange,
        currentSort,
      }) => (
        <View>
          <TableHeader
            searchInput={searchInput}
            handleSearchChange={handleSearchChange}
            handleSortChange={handleSortChange}
            currentSort={currentSort}
            searchPlaceholder={searchPlaceholder}
            sortableBy={sortableBy}
            searchableBy={searchableBy}
          />
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: TABLE_PADDING,
              borderRadius: 4,
            }}
          >
            {renderTHRows()}
            {renderTRRows({ sortedFilteredRows })}
          </View>
        </View>
      )}
    </DataTableBase>
  );
};
