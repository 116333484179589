import { SelectInput } from "components/select/select_input";
import { CapacityOption } from "./filter";

interface CapacityPickerProps {
  capacityOptions: CapacityOption[];
  value: string | undefined;
  onChange: (label: string | undefined) => void;
  appearance?: "outlined" | "default";
  testID?: string;
}

export function FilterCapacityPicker(props: CapacityPickerProps) {
  const {
    capacityOptions,
    onChange,
    value,
    appearance = "outlined",
    testID = "capacity-picker",
  } = props;

  return (
    <SelectInput
      testID={testID}
      options={[{ label: "Any" }].concat(capacityOptions)}
      valueProp="label"
      onChange={(newValue) =>
        onChange(newValue === "Any" ? undefined : newValue)
      }
      placeholder="Any"
      label="Number of Attendees"
      value={value}
      appearance={appearance}
    />
  );
}
