import { HorizontalList } from "components/horizontal_list";
import {
  CurrentUserOrderFragment,
  OrderStatus,
  OrganizationUser,
  OrgBookingFragment,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { ListSpacesSkeleton } from "pages/homev3/components/list_spaces_skeleton";
import { LocationCard } from "pages/homev3/components/location_card";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useOrgBookingQuery } from "pages/homev3/hooks/use_org_booking_query";
import { useUserOrderQuery } from "pages/homev3/hooks/use_user_order_query";
import { useAnalytics } from "providers/analytics";
import React, { useCallback } from "react";

export function WorkThereAgain() {
  const { data: orgData } = useOrgBookingQuery({});
  const { data: userData, loading: userLoading } = useUserOrderQuery({});

  const userValidOrders = (userData?.currentUser?.orders || []).filter(
    (o) => o.status !== OrderStatus.Canceled,
  );

  if (userLoading) {
    return <ListSpacesSkeleton />;
  }

  if (userValidOrders.length === 0) {
    return null;
  }

  return (
    <Content orgBookings={orgData.bookings} userOrders={userValidOrders} />
  );
}

type ContentProps = {
  orgBookings: OrgBookingFragment[];
  userOrders: CurrentUserOrderFragment[];
};

function Content({ orgBookings, userOrders }: ContentProps) {
  const analytics = useAnalytics();
  const orgGroupedBooking = groupBy(orgBookings, (b) => b.space.location.id);

  const userBookingsGrouped = groupBy(
    userOrders,
    (b) => b.orderItems[0].details.space.location.id,
  );
  const sortedUserBookings = Object.values(userBookingsGrouped)
    .sort(
      (a, b) =>
        (a[0].orderItems[0].details.space.location.currentUserDistance || 0) -
        (b[0].orderItems[0].details.space.location.currentUserDistance || 0),
    )
    .slice(0, 15);

  const renderItem = (orders: CurrentUserOrderFragment[]) => {
    const locationId = orders[0].orderItems[0].details.space.location.id;
    let peoples: Partial<OrganizationUser>[] = [];
    if (orgGroupedBooking[locationId]) {
      peoples = orgGroupedBooking[locationId].map((b) => b.user);
    }
    return (
      <LocationCard
        key={orders[0].orderItems[0].details.space.location.id}
        location={orders[0].orderItems[0].details.space.location}
        peoples={peoples}
        onPress={() => {
          const space = orders[0].orderItems[0].details.space;

          analytics.event("Click Repeat Booking Card", {
            "Location UUID": space.location.id,
            category: "Rebooking",
            "Location Name": space.location.name,
          });
        }}
      />
    );
  };

  const onNavigate = useCallback(() => {
    analytics.event("Navigate Horizontal List", {
      Section: "Repeat Booking",
      category: "Rebooking",
    });
  }, [analytics]);

  return (
    <HorizontalList
      title={
        <TitleSpaceList
          title={"Work there again"}
          subtitle={"Book again at a workspace you’ve been to"}
        />
      }
      data={sortedUserBookings}
      renderItem={renderItem}
      onNavigate={onNavigate}
    />
  );
}
