import { Spacer } from "components/spacer";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "lib/media_query";
import { Tabs } from "components/tab/tabs_v2";
import { TabOption } from "components/tab/tabs";
import { OrganizationBudgetDetailSpendAndReports } from "./organization_budget_detail_spend_and_reports";
import { OrganizationBudgetDetailTransactions } from "./organization_budget_detail_transactions";
import { BudgetMobileLayout } from "../budget_layout/buget_mobile_layout";
import { BudgetDetailHeader } from "../components/budget_detail_header_v1";
import { EditMobileButton } from "../components/button/budget_mobile_button";
import { BudgetDesktopLayout } from "../budget_layout/buget_desktop_layout";
import { EditDesktopButton } from "../components/button/budget_desktop_button";
import { useQueryString } from "lib/query_string";

export interface BudgetDetailParams {
  tab: OrganizationBudgetDetailTab;
  start?: string;
  end?: string;
  page: string;
}
export type OrganizationBudgetDetailTab = "spendAndReports" | "activity";

export const organizationBudgetDetailTabNames: Record<
  OrganizationBudgetDetailTab,
  OrganizationBudgetDetailTab
> = {
  spendAndReports: "spendAndReports",
  activity: "activity",
};

export const organizationBudgetDetailTabOptions: TabOption<OrganizationBudgetDetailTab>[] =
  [
    {
      label: "Spend and reports",
      value: organizationBudgetDetailTabNames.spendAndReports,
      testID: organizationBudgetDetailTabNames.spendAndReports,
    },
    {
      label: "Transactions",
      value: organizationBudgetDetailTabNames.activity,
      testID: organizationBudgetDetailTabNames.activity,
    },
  ];

export function OrganizationBudgetDetail() {
  const history = useHistory();
  const mq = useMediaQuery();
  const { tab, start, end, page } = useQueryString<BudgetDetailParams>();
  const [currentTab, setCurrentTab] = useState<OrganizationBudgetDetailTab>(
    tab || organizationBudgetDetailTabNames.spendAndReports,
  );

  const handleTabChange = useCallback((value: OrganizationBudgetDetailTab) => {
    setCurrentTab(value);
  }, []);

  const onEditPress = useCallback(() => {
    history.push("/admin/budgets/organization/edit");
  }, [history]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("tab", currentTab);
    if (start) {
      newSearchParams.set("start", start);
    }
    if (end) {
      newSearchParams.set("end", end);
    }

    if (page) {
      newSearchParams.set("page", page);
    }
    history.replace({ search: newSearchParams.toString() });
  }, [history, currentTab, start, end, page]);

  const content = (
    <>
      <Spacer size={24} />
      {currentTab === organizationBudgetDetailTabNames.spendAndReports && (
        <OrganizationBudgetDetailSpendAndReports />
      )}
      {currentTab === organizationBudgetDetailTabNames.activity && (
        <OrganizationBudgetDetailTransactions />
      )}
    </>
  );

  if (mq.deviceQuery.mobile) {
    return (
      <BudgetMobileLayout
        header={
          <BudgetDetailHeader
            actions={
              <EditMobileButton
                testID="edit-org-budget-button"
                onPress={onEditPress}
              />
            }
          />
        }
        body={
          <>
            <Tabs
              onChange={handleTabChange}
              options={organizationBudgetDetailTabOptions}
              value={currentTab}
            />
            {content}
          </>
        }
      />
    );
  }

  return (
    <BudgetDesktopLayout
      header={
        <BudgetDetailHeader
          backButtonText={"See all budgets"}
          actions={
            <EditDesktopButton
              testID="edit-org-budget-button"
              onPress={onEditPress}
            />
          }
        >
          <Tabs
            onChange={handleTabChange}
            options={organizationBudgetDetailTabOptions}
            value={currentTab}
          />
        </BudgetDetailHeader>
      }
      body={content}
    />
  );
}
