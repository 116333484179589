import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { colors } from "components/colors";
import { ReactNode } from "react";
import { useMediaQuery } from "lib/media_query";

interface SelectButtonWithIconProps {
  title: string;
  testID?: string;
  onPress: () => void;
  leftIcon?: ReactNode;
}

export function SelectButtonWithIcon(props: SelectButtonWithIconProps) {
  const { testID, title, onPress, leftIcon } = props;
  const mq = useMediaQuery();

  const handlePress = () => {
    onPress();
  };

  return (
    <Pressable
      style={[styles.wrapper, mq.deviceQuery.mobile && styles.wrapperMobile]}
      onPress={handlePress}
    >
      {({ hovered, focused }: { hovered: boolean; focused: boolean }) => (
        <View testID={testID}>
          <View style={{ height: 40, width: "100%" }}>
            <View
              style={[
                styles.buttonRoot,
                hovered && styles.buttonRootHovered,
                focused && styles.buttonRootFocused,
              ]}
            >
              {!!leftIcon && <View style={styles.iconWrapper}>{leftIcon}</View>}
              <View style={styles.selectedLabel}>
                <Text numberOfLines={1} size="xs">
                  {title}
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    minWidth: 160,
    width: "auto",
  },
  wrapperMobile: {
    width: "100%",
  },
  buttonRoot: {
    position: "relative",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: 8,
    paddingRight: "36px",
    borderRadius: 4,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.brand.whitecore,
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
  },
  buttonRootHovered: {
    borderColor: colors.brand.blackMinus30,
  },
  buttonRootFocused: {
    borderColor: colors.brand.eggplantcore,
  },
  iconWrapper: {
    position: "absolute",
    left: "8px",
    top: "50%",
    // @ts-ignore
    transform: "translateY(-50%)",
  },
  selectedLabel: {
    paddingLeft: 24,
  },
});
