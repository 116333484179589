import { Day, isDay } from "lib/day_utils";
import { isEmpty } from "lib/lang_utils";
import { keysOf } from "lib/object_utils";
import { Coordinates } from "pages/homev2/coordinates";
import { initialPlaceState } from "pages/homev2/hooks/use_initial_place";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

export interface TeamSearchParams {
  date: Day | undefined;
  /** `placeId` is used to retain location search input only, when users navigate back and forth between pages */
  placeId: string | undefined;
  coordinates: Coordinates | undefined;
  bbox: [number, number, number, number] | undefined;
  page: number;
  mapSearch: boolean | undefined;
}

export function useMyTeamSearchParamsQuery(): TeamSearchParams {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let date: Day | undefined;
  let placeId: string | undefined;
  let lat: number | undefined;
  let lng: number | undefined;
  let bboxTl: number | undefined;
  let bboxTr: number | undefined;
  let bboxBr: number | undefined;
  let bboxBl: number | undefined;
  let page: number = 1;
  let mapSearch: boolean | undefined;

  const dateParams = searchParams.get("date");
  if (dateParams && isDay(dateParams)) {
    date = dateParams;
  }

  const coordinatesParams = searchParams.get("coordinates");
  if (coordinatesParams) {
    const [latitude, longitude] = coordinatesParams.split(",");
    if (!Number.isNaN(Number(longitude)) && !Number.isNaN(Number(latitude))) {
      lat = Number(latitude);
      lng = Number(longitude);
    }
  }

  const bboxParams = searchParams.get("bbox");
  if (bboxParams) {
    const [tl, tr, br, bl] = bboxParams.split(",");
    if (
      !Number.isNaN(Number(tl)) &&
      !Number.isNaN(Number(tr)) &&
      !Number.isNaN(Number(br)) &&
      !Number.isNaN(Number(bl))
    ) {
      bboxTl = Number(tl);
      bboxTr = Number(tr);
      bboxBr = Number(br);
      bboxBl = Number(bl);
    }
  }

  const placeParams = searchParams.get("placeId");
  if (placeParams) {
    placeId = placeParams;
  }

  const pageParams = searchParams.get("page");
  if (pageParams) {
    page = Number(pageParams);
  }

  const mapSearchParam = searchParams.get("mapSearch");
  if (mapSearchParam) {
    mapSearch = true;
  }

  return useMemo(() => {
    return {
      date,
      placeId,
      coordinates:
        lat && lng
          ? {
              lat,
              lng,
            }
          : undefined,
      bbox:
        bboxTl && bboxTr && bboxBr && bboxBl
          ? [bboxTl, bboxTr, bboxBr, bboxBl]
          : undefined,
      page,
      mapSearch,
    };
  }, [
    date,
    placeId,
    lat,
    lng,
    bboxTl,
    bboxTr,
    bboxBr,
    bboxBl,
    page,
    mapSearch,
  ]);
}

type NullablePartial<T> = { [P in keyof T]?: T[P] | null };

export type TeamsSearchParamsChangeSet = NullablePartial<TeamSearchParams>;

export function useMyTeamSearchParamsMutation() {
  const history = useHistory();
  const searchParams = useMyTeamSearchParamsQuery();
  const [defaultLocation, setIsDefaultLocation] =
    useRecoilState(initialPlaceState);

  const handleTeamsSearchParamsChange = useCallback(
    (searchParamsChangeSet: TeamsSearchParamsChangeSet) => {
      if (isEmpty(searchParamsChangeSet)) {
        return;
      }

      const nextSearchParams = new URLSearchParams();
      const keys = keysOf(searchParams);

      for (const key of keys) {
        const prevParamValue = searchParams[key];
        const paramValueChangeSet = searchParamsChangeSet[key];

        // if a param was set to null, remove it
        if (paramValueChangeSet === null) {
          continue;
        }

        // if there is new change, apply it
        if (paramValueChangeSet) {
          if (key === "coordinates") {
            const coordinates = paramValueChangeSet as Coordinates;
            nextSearchParams.set(key, `${coordinates.lat},${coordinates.lng}`);
            // set default location to false if the new coordinates are different from initial placeId coordinates
            if (
              defaultLocation.data?.center[1] !== coordinates.lat ||
              defaultLocation.data?.center[0] !== coordinates.lng
            ) {
              setIsDefaultLocation((curVal) => ({
                ...curVal,
                isDefault: false,
              }));
            }
          } else {
            nextSearchParams.set(key, `${paramValueChangeSet}`);
          }
        } else if (prevParamValue) {
          // use previous value if there was one
          if (key === "coordinates") {
            const coordinates = prevParamValue as Coordinates;
            nextSearchParams.set(key, `${coordinates.lat},${coordinates.lng}`);
            // reset page to 1 if any other param is changed
          } else if (key === "page") {
            nextSearchParams.set(key, "1");
          } else if (
            key === "mapSearch" &&
            (Object.keys(searchParamsChangeSet).includes("coordinates") ||
              Object.keys(searchParamsChangeSet).includes("bbox") ||
              Object.keys(searchParamsChangeSet).includes("placeId"))
          ) {
            nextSearchParams.delete("mapSearch");
          } else {
            nextSearchParams.set(key, `${prevParamValue}`);
          }
        }
      }

      const qs = `?${nextSearchParams.toString()}`;
      history.replace({ search: qs });
    },
    [defaultLocation.data?.center, history, searchParams, setIsDefaultLocation],
  );

  return handleTeamsSearchParamsChange;
}
