import { gql } from "@apollo/client";

export const offsiteOrderPageGQLQuery = gql`
  query OffsiteOrderPage__OrderDetails($id: ID!) {
    order(id: $id) {
      id
      totalPrice
      subTotal
      processingFee
      confirmationID
      memo
      additionalNotes
      stripeClientSecret
      status
      cancellable
      uncancellable_reason
      currency
      invites {
        ...OffsiteOrderPage__OrderInvites
      }
      invitees {
        name
        email
      }
      taxLine {
        amount
        description
        percentage
      }
      promotionLine {
        amount
        description
        discount
        code
      }
      guest {
        fullName
        companyName
        email
        phoneNumber
      }
      paymentDetail {
        id
        paymentMethodDetails {
          last4
          network
          networkLogoUrl
        }
        billingDetails {
          name
          email
          phone
          address {
            line1
            line2
            city
            state
            postalCode
            country
          }
        }
        receiptUrl
      }
      orderItems {
        ...OffsiteOrderPage__OrderItemDetails
      }
    }
  }

  fragment OffsiteOrderPage__OrderItemDetails on OrderItem {
    id
    totalPrice
    subTotal
    unitPrice
    quantity
    details {
      __typename
      ...OrderDetails__DailyBookingDetails
      ...OrderDetails__HourlyBookingDetails
    }
  }

  fragment OffsiteOrderPage__OrderInvites on OrderInvites {
    id
    message
    recipients {
      name
      email
      phoneNumber
    }
  }

  fragment OrderDetails__HourlyBookingDetails on HourlyBooking {
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    date
    startTime
    endTime
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
    space {
      id
      name
      spaceType
      maxCapacity
      layouts {
        id
        name
        description
        bestFor
        capacity
        imageURL
      }
      bookingPolicy {
        minDuration
        cancellationHours
        cancellationTime1DayBefore
        cancellationDays
      }
      bookingConfig {
        enabled
        multiDayBookingAllowed
      }
      inventoryCapacity
      images {
        thumb {
          url
          width
          height
        }
        small {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
        large {
          url
          width
          height
        }
      }
      pricings {
        type
        price
      }
      location {
        id
        name
      }
    }
  }

  fragment OrderDetails__DailyBookingDetails on DailyBooking {
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    startDate
    endDate
    days
    time
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
    space {
      id
      name
      spaceType
      maxCapacity
      layouts {
        id
        name
        description
        bestFor
        capacity
        imageURL
      }
      inventoryCapacity
      bookingPolicy {
        minDuration
        cancellationHours
        cancellationTime1DayBefore
        cancellationDays
      }
      bookingConfig {
        enabled
        multiDayBookingAllowed
      }
      images {
        thumb {
          url
          width
          height
        }
        small {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
        large {
          url
          width
          height
        }
      }
      pricings {
        type
        price
      }
      location {
        id
        name
      }
    }
  }
`;

export const offsiteOrderOtherSpacesQuery = gql`
  query OffsiteOrderOtherSpaces($offsiteLocationID: ID!) {
    meetingRooms: offsiteLocation(id: $offsiteLocationID) {
      id
      name
      ...OffsiteOrderLocationSpaces__MeetingRooms
    }
    dayPasses: offsiteLocation(id: $offsiteLocationID) {
      id
      name
      ...OffsiteOrderLocationSpaces__DayPasses
    }
    privateOffices: offsiteLocation(id: $offsiteLocationID) {
      id
      name
      ...OffsiteOrderLocationSpaces__PrivateOffices
    }
  }

  fragment OffsiteOrderLocationSpaces__MeetingRooms on Location {
    spaces(spaceType: MeetingRoom) {
      ...OffsiteOrderLocationSpaces__SpaceDetails
    }
  }
  fragment OffsiteOrderLocationSpaces__DayPasses on Location {
    spaces(spaceType: DayPass) {
      ...OffsiteOrderLocationSpaces__SpaceDetails
    }
  }

  fragment OffsiteOrderLocationSpaces__PrivateOffices on Location {
    spaces(spaceType: DayOffice) {
      locked
      ...OffsiteOrderLocationSpaces__SpaceDetails
    }
  }

  fragment OffsiteOrderLocationSpaces__SpaceDetails on Space {
    id
    currency
    name
    locked
    spaceType
    physicalSpaces {
      ...OffsiteOrderLocationSpacesPhysicalSpaces__PhysicalSpaceDetails
    }
    amenities {
      id
      name
      iconURL
      code
    }
    ...OffisetOrderOtherSpaces_Space_Commons
  }

  fragment OffsiteOrderLocationSpacesPhysicalSpaces__PhysicalSpaceDetails on PhysicalSpace {
    id
    name
    floor
    withWindow
    space {
      id
      name
      currency
      locked
      spaceType
      amenities {
        id
        name
        iconURL
        code
      }
      ...OffisetOrderOtherSpaces_Space_Commons
    }
  }

  fragment OffisetOrderOtherSpaces_Space_Commons on Space {
    pricings {
      type
      price
    }
    priceRanges {
      daily {
        min
        max
      }
      hourly {
        min
        max
      }
    }
    layouts {
      capacity
    }
    maxCapacity
    images {
      small {
        url
        width
        height
      }
    }
    location {
      amenities {
        id
        name
        iconURL
        code
      }
    }
  }
`;

export const orderPageCurrentUserGQLQuery = gql`
  query OrderPage {
    currentUser {
      id
      organization {
        monthlyBilling
      }
    }
  }
`;
