import React, { Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup } from "victory";
import { Icon } from "components/icon";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { Tooltip } from "components/tooltip";
import { useMediaQuery } from "lib/media_query";
import { colors } from "components/colors";
import { BudgetColor } from "../../budgets/budget_colors";

interface BookingsWeekData {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}

interface ReportingEngagementProps {
  soloBookings: BookingsWeekData;
  teamBookings: BookingsWeekData;
  labelUpdatedAt: string;
  color?: BudgetColor;
}

export function ReportingEngagement(props: ReportingEngagementProps) {
  const { labelUpdatedAt, soloBookings, teamBookings, color = "other" } = props;

  const empty =
    soloBookings.monday === 0 &&
    soloBookings.tuesday === 0 &&
    soloBookings.wednesday === 0 &&
    soloBookings.thursday === 0 &&
    soloBookings.friday === 0 &&
    soloBookings.saturday === 0 &&
    soloBookings.sunday === 0 &&
    teamBookings.monday === 0 &&
    teamBookings.tuesday === 0 &&
    teamBookings.wednesday === 0 &&
    teamBookings.thursday === 0 &&
    teamBookings.friday === 0 &&
    teamBookings.saturday === 0 &&
    teamBookings.sunday === 0;

  return (
    <ReportingGroupBarChart
      data={[
        {
          color: colors.budget[color].core,
          label: "Solo bookings",
          data: bookingsWeekDataToReportingGroupData(soloBookings),
        },
        {
          color: colors.budget[color].lighter,
          label: "Team bookings",
          data: bookingsWeekDataToReportingGroupData(teamBookings),
        },
      ]}
      empty={empty}
      title="Engagement"
      tooltip="This chart shows the average number of solo and team bookings each day of the week."
      labelUpdatedAt={labelUpdatedAt}
    />
  );
}

function bookingsWeekDataToReportingGroupData(data: BookingsWeekData) {
  return [
    { label: "Su", value: data.sunday },
    { label: "Mo", value: data.monday },
    { label: "Tu", value: data.tuesday },
    { label: "We", value: data.wednesday },
    { label: "Th", value: data.thursday },
    { label: "Fr", value: data.friday },
    { label: "Sa", value: data.saturday },
  ];
}

export interface ReportingGroupData {
  label: string;
  value: number;
}

interface ReportingGroupBarData {
  label: string;
  data: ReportingGroupData[];
  color: string;
}
interface ReportingGroupBarChartProps {
  data: ReportingGroupBarData[];
  title: string;
  tooltip: string;
  labelUpdatedAt: string;
  empty: boolean;
}

export function ReportingGroupBarChart(props: ReportingGroupBarChartProps) {
  const { title, labelUpdatedAt, data, empty, tooltip } = props;
  const mq = useMediaQuery();

  return (
    <View style={styles.chart}>
      <View style={styles.chartHeader}>
        <Text size="md" weight="semibold">
          {title}
        </Text>
        <Tooltip
          position={mq.sizeQuery.mdAndUp ? "top-right" : "bottom-left"}
          offset={18}
          content={
            <View style={styles.tooltip}>
              <Text size="micro">{tooltip}</Text>
              <Spacer size={16} />
              <Text color="black-50" size="micro">
                {labelUpdatedAt}
              </Text>
            </View>
          }
        >
          <Icon name="info" color={colors.brand.blackMinus30} />
        </Tooltip>
      </View>
      <Spacer size={16} />
      <View style={styles.chartWrapper}>
        <VictoryChart height={384} domainPadding={{ x: 15 }}>
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: 21,
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            orientation="left"
            tickFormat={(t) => {
              if (t < 1) {
                return "";
              }

              return t.toFixed(0);
            }}
            style={{ tickLabels: { fontSize: empty ? 0 : 21 } }}
          />
          <VictoryGroup offset={11}>
            {data.map((d) => (
              <VictoryBar
                key={d.label}
                data={d.data.map((k) => ({
                  x: k.label,
                  y: k.value,
                }))}
                style={{
                  data: {
                    fill: d.color,
                  },
                }}
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
        {empty && (
          <View style={styles.emptyWrapper}>
            <Text size="md" align="center">
              No data available
            </Text>
          </View>
        )}
      </View>
      <Spacer size={24} />
      <View
        style={[
          styles.legendsWrapper,
          mq.sizeQuery.smAndDown && styles.legendsWrapperMobile,
        ]}
      >
        {data.map((d) => (
          <Fragment key={d.label}>
            <ChartLabel data={d} />
            <Spacer size={8} />
          </Fragment>
        ))}
      </View>
    </View>
  );
}

interface ChartLabelProps {
  data: ReportingGroupBarData;
}

function ChartLabel(props: ChartLabelProps) {
  const { data } = props;

  return (
    <View style={styles.legendLabelWrapper}>
      <View style={styles.legendLabel}>
        <View
          style={[styles.legendLabelColor, { backgroundColor: data.color }]}
        />
        <View style={styles.legendLabelText}>
          <Text size="xs" weight="bold">
            {data.label}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  chart: {
    flex: 1,
    padding: 24,
    backgroundColor: "white",
    borderRadius: 16,
    ...tokens.shadow.elevation1,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
  emptyWrapper: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 100,
    margin: "auto",
  },
  chartWrapper: {},
  legendsWrapper: {
    paddingLeft: 64,
  },
  legendsWrapperMobile: {
    paddingLeft: 32,
  },
  tooltip: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "rgba(6, 0, 30, 0.1)",
    padding: 8,
    borderRadius: 6,
    minWidth: 240,
    ...tokens.shadow.elevation1,
  },
  chartHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 1,
  },
  legendLabelColor: {
    width: 16,
    height: 16,
  },
  legendLabelWrapper: {
    flexDirection: "row",
    paddingHorizontal: 16,
    justifyContent: "space-between",
  },
  legendLabel: {
    flexDirection: "row",
    alignItems: "center",
  },
  legendLabelText: {
    paddingLeft: 8,
  },
});
