import { colors } from "components/colors";
import { Row } from "components/row";
import { Text } from "components/text_v2";
import { Image, Pressable, StyleSheet, View } from "react-native";

interface OktaButtonProps {
  onPress: () => void;
}

export function OktaButton(props: OktaButtonProps) {
  const { onPress } = props;
  return (
    <View>
      <Pressable
        testID="okta-button"
        style={styles.buttonWrapper}
        onPress={onPress}
      >
        <Row justifyContent="center" alignItems="center" spacing={8}>
          <Image
            source={{
              uri: "/images/okta_logo.png",
              width: 16,
              height: 16,
            }}
          />
          <Text
            customColor={colors.brand.blackcore}
            weight="semibold"
            size="xs"
          >
            Continue with Okta
          </Text>
        </Row>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonWrapper: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.brand.blackcore,
    backgroundColor: "white",
  },
});
