import React, { ReactNode } from "react";
import { DesktopLayout } from "components/layout/desktop_layout";
import { Footer } from "core/footer";
import { StyleSheet, View } from "react-native";
import { AdminHeader } from "components/admin_header";

interface PageLayoutProps {
  header: ReactNode;
  body: ReactNode;

  /*Extended content is a component that will be rendered on the right or left the main content
  Using it when the UI of the page is split in two parts */
  extendedContent?: ReactNode;
  extendedContentPosition?: "left" | "right";
}

export function BudgetDesktopLayout(props: PageLayoutProps) {
  const {
    header,
    body,
    extendedContent,
    extendedContentPosition = "right",
  } = props;
  return (
    <DesktopLayout
      header={
        <>
          <AdminHeader />
          {header}
        </>
      }
      body={
        extendedContent ? (
          <View
            style={[
              styles.bodyContainer,
              extendedContentPosition === "left" && styles.leftContent,
            ]}
          >
            {body}
            {extendedContent}
          </View>
        ) : (
          body
        )
      }
      footer={<Footer />}
    />
  );
}

const styles = StyleSheet.create({
  bodyContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 32,
  },
  leftContent: {
    flexDirection: "row-reverse",
  },
});
