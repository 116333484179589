import { Spacer } from "components/spacer";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { Redirect } from "react-router-dom";
import { useMediaQuery } from "lib/media_query";
import { MobileLayout } from "components/layout/mobile_layout";
import { BudgetDetailHeaderMobile } from "pages/budgets/components/budget_detail_header_v1";
import { useQuery } from "@apollo/client";
import { CurrentUserOrganizationNameQuery, Plan } from "core/graphql.generated";
import { currentUserOrganizationNameQuery } from "pages/organization_profile/organization_profile";
import { Fragment } from "react";
import {
  OrganizationProfileCard,
  OrganizationProfileCardWrapper,
} from "pages/organization_profile/components/organization_profile_card";
import { LearnMoreLink } from "pages/organization_profile/components/learn_more_link";
import { OrganizationProfileSkeleton } from "pages/organization_profile/components/organization_profile_skeleton";

export function PlanDetailsPage() {
  const mq = useMediaQuery();

  const { data: currentUserQuery, loading: userDataLoading } =
    useQuery<CurrentUserOrganizationNameQuery>(
      currentUserOrganizationNameQuery,
    );

  if (!mq.deviceQuery.mobile) {
    return <Redirect to="/admin/organization-profile" />;
  }

  return (
    <MobileLayout
      header={<BudgetDetailHeaderMobile title="Plan details" />}
      footer={<BottomBarAdminNavigation />}
      body={
        <Fragment>
          <Spacer size={16} />
          {userDataLoading ? (
            <OrganizationProfileSkeleton />
          ) : (
            <OrganizationProfileCardWrapper title="Plan details">
              <OrganizationProfileCard
                value={`Your organization is currently on Flexspace’s ${
                  currentUserQuery?.currentUser?.organization?.plan === Plan.Pro
                    ? "Pro"
                    : "Starter"
                } plan`}
                title="Current plan"
                action={<LearnMoreLink />}
              />
            </OrganizationProfileCardWrapper>
          )}
        </Fragment>
      }
    />
  );
}
