import { Button } from "components/button_v2";
import { Carousel } from "components/carousel";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { TodayPage__RequestDetailsFragment } from "core/graphql.generated";
import { EmptyRequests } from "pages/booking_requests/components/empty_requests";
import { Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { RequestCard } from "./request_card";

interface RequestCardsProps {
  requests: TodayPage__RequestDetailsFragment[];
}

export function RequestCardsDesktop(props: RequestCardsProps) {
  const { requests: allRequests } = props;
  const history = useHistory();

  const requests = allRequests.filter((_req, ind) => ind < 3);

  return (
    <View>
      <View style={styles.heading}>
        <Text weight="semibold" size="md">
          Pending Requests
        </Text>
        {allRequests.length > 0 && (
          <Button
            appearance="secondary"
            onPress={() => {
              history.push("/admin/booking-requests");
            }}
            text={`See all (${allRequests.length})`}
          />
        )}
      </View>
      <Spacer size={32} />
      <View style={styles.cards}>
        {requests.length > 0 ? (
          requests.map((request, ind) => (
            <Fragment>
              <RequestCard request={request} />
              {ind !== 2 && <Spacer direction="row" size={24} />}
            </Fragment>
          ))
        ) : (
          <View style={styles.emptyRequestView}>
            <EmptyRequests
              title="You're all caught up!"
              description="As soon as someone requests to book a space, we’ll show it here."
            />
          </View>
        )}
      </View>
    </View>
  );
}

export function RequestCardsMobile(props: RequestCardsProps) {
  const { requests: allRequests } = props;
  const requests = allRequests.filter((_req, ind) => ind < 3);

  return (
    <View>
      <View style={styles.heading}>
        <Text weight="semibold" size="md">
          Pending Requests
        </Text>
        {allRequests.length > 0 && (
          <Button
            href="/admin/booking-requests"
            text={`See all (${allRequests.length})`}
            appearance={"textLink"}
          />
        )}
      </View>
      <Spacer size={24} />
      {requests.length > 0 ? (
        <Carousel showIndicators={false}>
          {requests.map((request, ind) => (
            <Fragment>
              <RequestCard request={request} />
              {ind !== 2 && <Spacer direction="row" size={24} />}
            </Fragment>
          ))}
        </Carousel>
      ) : (
        <EmptyRequests
          title="You're all caught up!"
          description="As soon as someone requests to book a space, we’ll show it here."
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  cards: {
    flexDirection: "row",
    alignItems: "center",
  },
  heading: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  emptyRequestView: {
    flex: 1,
  },
});
