import { colors } from "components/colors";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text_v2";
import { GoogleButton } from "core/google_button";
import { OrganizationByInviteQueryQuery } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { SignUpCredentials } from "pages/sign_up/sign_up";
import { useAuthV2 } from "providers/authv2";
import { Image, View } from "react-native";

interface InviteMemberStepAccountSetupProps {
  onGoNext: () => void;
  onGoBack: () => void;
  organization?: OrganizationByInviteQueryQuery["organizationByInvite"]["organization"];
  credentials: SignUpCredentials;
}

export function InviteMemberStepAccountSetup(
  props: InviteMemberStepAccountSetupProps,
) {
  const { organization, credentials } = props;
  const { signUpWithGoogle } = useAuthV2();
  const mq = useMediaQuery();
  if (!organization) {
    return <Spinner />;
  }

  const onSignUp = () => {
    signUpWithGoogle();
  };

  return (
    <Content maxWidth={460}>
      <Heading size="mega" align="center">
        Set up your account
      </Heading>
      <Spacer size={16} />
      <Text size="xs" align="center">
        The {organization?.name} team allows you to sign up with your{" "}
        <Text size="xs" weight="semibold">
          {credentials.email}
        </Text>{" "}
        Google account.
      </Text>
      <Spacer size={16} />
      <View style={{ alignItems: "center", flexGrow: 1, width: "100%" }}>
        <Image
          style={{
            flexGrow: 1,
            width: "100%",
            overflow: "visible",
          }}
          source={{
            uri: "/images/unlocked.svg",
          }}
        />
      </View>
      <View
        style={{
          width: mq.sizeQuery.mdAndUp ? 343 : "100%",
          justifyContent: mq.deviceQuery.mobile ? "flex-end" : "center",
          flex: 1,
          margin: "auto",
        }}
      >
        <View>
          <Text
            size="xs"
            customColor={colors.brand.blackMinus30}
            align="center"
          >
            By signing up, I agree to the Flexspace{" "}
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: colors.brand.blackMinus30 }}
              href="https://flexspace-ds-static.s3.us-west-1.amazonaws.com/flexspace-teams-tc.pdf"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: colors.brand.blackMinus30 }}
              href="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-privacy.pdf"
            >
              Privacy Policy.
            </a>
          </Text>
          <Spacer size={16} />
          <Content maxWidth={260}>
            <GoogleButton onPress={onSignUp} />
          </Content>
        </View>
      </View>
    </Content>
  );
}
