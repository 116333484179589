import { Spacer } from "components/spacer";
import ContentLoader, { Rect } from "react-content-loader/native";
import { View } from "react-native";

export function GroupBudgetDetailTabReplacerSkeleton() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={30}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="30px" />
      </ContentLoader>
      <Spacer size={24} />
    </View>
  );
}
