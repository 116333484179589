import {
  OffsiteOrderLocationSpaces__SpaceDetailsFragment,
  OffsiteOrderOtherSpacesQuery,
  SpaceType,
  BookingType,
} from "core/graphql.generated";
import { DateRange } from "core/booking_date_range_picker";

export function getEnhanceWorkDaySpaces(
  data: OffsiteOrderOtherSpacesQuery | undefined,
  spaceType: SpaceType,
): OffsiteOrderLocationSpaces__SpaceDetailsFragment[] | undefined {
  if (spaceType === SpaceType.DayPass || spaceType === SpaceType.DayOffice) {
    return data?.meetingRooms?.spaces.filter((_space, index) => index < 3);
  } else if (spaceType === SpaceType.MeetingRoom) {
    // There is only one daypass for location
    const locationDayPass = data?.dayPasses?.spaces[0];
    const spaces = data?.privateOffices?.spaces
      .filter((space) => space.physicalSpaces.length === 1)
      .filter((_space, index) => index < (locationDayPass ? 2 : 3));

    return !locationDayPass && spaces
      ? spaces
      : spaces && locationDayPass
      ? [...spaces, locationDayPass]
      : locationDayPass
      ? [locationDayPass]
      : undefined;
  }
}

export function getDateTime(dateRange?: DateRange) {
  let startDate = "";
  let endDate = "";
  let startTime: string | undefined;
  let endTime: string | undefined;
  if (
    dateRange?.type === BookingType.DailyBooking &&
    dateRange?.startDate &&
    dateRange?.endDate
  ) {
    startDate = dateRange.startDate;
    endDate = dateRange.endDate;
  } else if (dateRange?.type === BookingType.HourlyBooking && dateRange?.date) {
    startDate = dateRange.date;
    endDate = dateRange.date;
    startTime = dateRange.startTime;
    endTime = dateRange.endTime;
  }

  return { startDate, endDate, startTime, endTime };
}
