import { StyleSheet, View } from "react-native";
import { useState } from "react";

import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";

import { useCancelBooking } from "../hooks/useCancelBooking";

interface BookingDetailCancelProps {
  bookingId: string;
}

export function BookingDetailCancel({ bookingId }: BookingDetailCancelProps) {
  const mq = useMediaQuery();
  const analytics = useAnalytics();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { cancelBooking, loading } = useCancelBooking();

  const handleClickConfirmCancel = () => {
    setIsDialogVisible(false);
    cancelBooking(bookingId);
    analytics.event("Cancel Booking", { category: "Post booking" });
  };

  return (
    <View>
      <View style={mq.deviceQuery.mobile && styles.actionWrapperMobile}>
        <Text
          size="micro"
          color="black-70"
          align={mq.deviceQuery.mobile ? "left" : "center"}
        >
          Cancel for free up to 24 hours before your reservation.
        </Text>
        {mq.deviceQuery.desktop && <Spacer size={12} />}
        <Button
          testID="booking-cancel-button"
          text="Cancel"
          appearance="secondary"
          onPress={() => setIsDialogVisible(true)}
          loading={loading}
        />
      </View>

      <DialogModal
        isVisible={isDialogVisible}
        showCloseButton={false}
        title="Are you sure you want to cancel this booking?"
        subtitle="Your booking will be canceled and refunded immediately. Your refund will be reflected in approximately 10 business days."
        onClose={() => setIsDialogVisible(false)}
        bottomButtons={
          <>
            <Button
              text="Not right now"
              appearance="secondary"
              onPress={() => setIsDialogVisible(false)}
              size={mq.deviceQuery.mobile ? "auto" : "medium"}
            />
            <Button
              testID="cancel-confirmation-button"
              text="Yes, cancel"
              size={mq.deviceQuery.mobile ? "auto" : "medium"}
              onPress={handleClickConfirmCancel}
            />
          </>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  actionWrapperMobile: {
    flexDirection: "row",
    gap: 70,
    alignItems: "center",
  },
});
