import { gql } from "@apollo/client";

export const reportingPageOrganizationReportingGQLQuery = gql`
  query ReportingPageOrganizationReporting(
    $startDate: String!
    $endDate: String!
  ) {
    currentUser {
      organization {
        reporting(startDate: $startDate, endDate: $endDate) {
          ...ReportingPageReportingData
        }
      }
    }
  }

  fragment ReportingPageReportingData on Reporting {
    spend {
      totalSpend
      dayPasses {
        value
        percentage
      }
      dayOffices {
        value
        percentage
      }
      meetingRooms {
        value
        percentage
      }
    }
    bookings {
      totalBookings
      dayPasses {
        value
        percentage
      }
      dayOffices {
        value
        percentage
      }
      meetingRooms {
        value
        percentage
      }
    }
    frequency {
      totalEmployees
      zeroOrSingleBookingPerWeek {
        value
        percentage
      }
      twoOrThreeBookingsPerWeek {
        value
        percentage
      }
      fourOrMoreBookingsPerWeek {
        value
        percentage
      }
    }
    engagement {
      soloBookings {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      teamBookings {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
    lastUpdatedDate
  }
`;

export const reportingPageOrganizationBudgetGQLQuery = gql`
  query ReportingPageOrganizationBudget {
    currentUser {
      organization {
        budget {
          ...ReportingPageBudgetData
        }
      }
    }
  }

  fragment ReportingPageBudgetData on OrganizationBudget {
    state
    totalSpend
    remaining
    budget
  }
`;

export const reportingPageOrganizationReportingTotalSpendGQLQuery = gql`
  query ReportingPageOrganizationTotalSpend(
    $startDate: String!
    $endDate: String!
  ) {
    currentUser {
      organization {
        reporting(startDate: $startDate, endDate: $endDate) {
          ...ReportingPageReportingTotal
        }
      }
    }
  }

  fragment ReportingPageReportingTotal on Reporting {
    spend {
      totalSpend
    }
  }
`;
