import { colors } from "components/colors";
import { GridV2 } from "components/gridv2";
import { ProgressBarMulticolor } from "components/progress_bar_multicolor/progress_bar_multicolor";
import { Spacer } from "components/spacer";
import { Text, TextColor } from "components/text_v2";
import { tokens } from "components/tokens";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";
import React, { ReactNode, useState } from "react";
import { StyleSheet, View } from "react-native";

export const BudgetCardContainer = ({
  children,
  inactive,
  testID,
}: {
  children: ReactNode;
  inactive?: boolean;
  testID?: string;
}) => (
  <View
    style={[
      styles.container,
      inactive &&
        ({
          filter: "grayscale()",
        } as {}),
    ]}
    testID={testID}
  >
    {children}
  </View>
);

export const BudgetCardTitle = ({
  children,
  warning,
  title,
}: {
  title?: string;
  children?: ReactNode;
  warning?: boolean;
}) => (
  <View style={styles.title}>
    <Text
      color={warning ? "grapefruit-core" : "black-core"}
      weight="semibold"
      size="md"
      align="left"
    >
      {title}
    </Text>
    {children}
  </View>
);

export const BudgetCardStatusChip = ({
  status,
  dimmed = false,
}: {
  status: string;
  dimmed?: boolean;
}) => (
  <View style={[styles.statusChip, dimmed && styles.statusChipDimmed]}>
    <Text color={"black-core"} weight="semibold" size="micro">
      {status.toUpperCase()}
    </Text>
  </View>
);

export const BudgetCardSubtitle = ({ children }: { children: ReactNode }) => (
  <View style={styles.subtitle}>{children}</View>
);

export const BudgetCardSubtitleLabelWithAmount = ({
  label,
  amount,
  warning,
}: {
  label: string;
  amount: number;
  warning?: boolean;
}) => (
  <View style={styles.subtitleItem}>
    <Text
      color={warning ? "grapefruit-core" : "black-core"}
      weight="regular"
      size="xs"
    >
      {label}{" "}
    </Text>
    <Text
      color={warning ? "grapefruit-core" : "black-core"}
      weight="semibold"
      size="xs"
    >
      {formatCurrencyWithoutDecimal(amount, getSystemLocale(), "USD")}
    </Text>
  </View>
);

export interface BudgetCardProgressBarItem {
  color: string;
  value: number;
}

export const BudgetCardProgressBar = ({
  items,
  height = 40,
  backgroundColor,
}: {
  items: BudgetCardProgressBarItem[];
  height?: number;
  backgroundColor?: string;
}) => {
  return (
    <View style={styles.progressBar}>
      <ProgressBarMulticolor
        progressObjects={items}
        roundLastItem
        height={height}
        backgroundColor={backgroundColor}
      />
    </View>
  );
};

export interface BudgetCardLegendItem {
  color: string;
  legendLabel: string;
  legendValue: string;
  legendValueColor?: TextColor | false;
}

export const BudgetCardLegend = ({
  isProgressBar = false,
  items,
  columns = 3,
  collapsibleList = false,
}: {
  items: BudgetCardLegendItem[];
  columns?: number;
  collapsibleList?: boolean;
  isProgressBar?: boolean;
}) => {
  const mq = useMediaQuery();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const renderedItems = items.map((item) => (
    <View
      style={[
        styles.legendItem,
        { maxWidth: isProgressBar && mq.deviceQuery.desktop ? 180 : "unset" },
      ]}
      key={`budget_card_legend-${item.legendLabel}`}
    >
      <View style={[styles.legendItemDot, { backgroundColor: item.color }]} />
      <Spacer size={8} direction="row" />
      <Text size="xs" weight="semibold" color="black-core">
        {item.legendLabel}
      </Text>
      <View style={styles.legendItemSpacer} />
      <Text
        size="xs"
        weight="semibold"
        color={item.legendValueColor || "black-core"}
      >
        {item.legendValue}
      </Text>
    </View>
  ));

  if (collapsibleList) {
    return (
      <View style={styles.legend}>
        <View style={{ height: isCollapsed ? 0 : "unset", overflow: "hidden" }}>
          {renderedItems}
        </View>
        {!isCollapsed && <Spacer size={16} />}
        <Text
          color="eggplant-core"
          weight="semibold"
          size="xs"
          onPress={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? "Show legend" : "Hide legend"}
        </Text>
      </View>
    );
  }

  return (
    <View style={styles.legend}>
      <GridV2 columns={columns} columnGap="8%" rowGap={0}>
        {renderedItems}
      </GridV2>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "white",
    borderRadius: 4,
    ...tokens.shadow.elevation1,
  },
  title: {
    marginBottom: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusChip: {
    paddingVertical: 5,
    paddingHorizontal: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    width: "fit-content",
  },
  statusChipDimmed: {
    opacity: 0.5,
  },
  subtitle: {
    marginBottom: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitleItem: {
    flexDirection: "row",
  },
  progressBar: {
    marginBottom: 24,
  },
  legend: {},
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  legendItemDot: {
    borderRadius: 100,
    width: 16,
    height: 16,
  },
  legendItemSpacer: {
    flexGrow: 1,
  },
});
