import { Spacer } from "components/spacer";
import { LocationDetails__SpaceTypePolicyFragment } from "core/graphql.generated";
import { useState } from "react";
import { Pressable, View } from "react-native";
import { useHistory } from "react-router-dom";

import { LocationDetailsAvailableSpaceCard } from "./location_details_available_space_card";
import {
  LocationDetailsAvailableSpace,
  LocationDetailsAvailableSpacesModal,
} from "./location_details_available_spaces_modal";
import { LocationDetailsSection } from "./location_details_section";

export interface LocationDetailsDayPass {
  spaceId: string;
  imageSrc: string;
}

interface LocationDetailsAvailableSpacesProps {
  dayPass?: LocationDetailsDayPass;
  privateOffices?: LocationDetailsAvailableSpace[];
  meetingRooms?: LocationDetailsAvailableSpace[];
  spaceTypePolicy?: LocationDetails__SpaceTypePolicyFragment;
}

export function LocationDetailsAvailableSpaces({
  dayPass,
  privateOffices,
  meetingRooms,
  spaceTypePolicy,
}: LocationDetailsAvailableSpacesProps) {
  const history = useHistory();

  const [isPrivateOfficesVisible, setIsPrivateOfficesVisible] = useState(false);
  const [isMeetingRoomsVisible, setIsMeetingRoomsVisible] = useState(false);

  if (!dayPass && !privateOffices?.length && !meetingRooms?.length) {
    return null;
  }

  const privateOfficePress = () => {
    if (privateOffices?.length === 1) {
      history.push(`/offsite_spaces/${privateOffices[0].spaceId}`);
    } else {
      setIsPrivateOfficesVisible(true);
    }
  };
  const meetingRoomPress = () => {
    if (meetingRooms?.length === 1) {
      history.push(`/offsite_spaces/${meetingRooms[0].spaceId}`);
    } else {
      setIsMeetingRoomsVisible(true);
    }
  };

  return (
    <LocationDetailsSection title="Available spaces">
      <View>
        {dayPass && (
          <View>
            <Pressable
              onPress={() => history.push(`/offsite_spaces/${dayPass.spaceId}`)}
            >
              <LocationDetailsAvailableSpaceCard
                imageSrc={dayPass.imageSrc || "/images/daypasses.png"}
                spaceType="Day Passes"
                isOutOfPolicy={!!spaceTypePolicy && !spaceTypePolicy.dayPass}
                description="Access to co-working workspaces, common areas and amenities for the day."
                actionText="See pass details"
              />
            </Pressable>
            <Spacer size={24} />
          </View>
        )}
        {privateOffices?.length && (
          <View>
            <Pressable onPress={privateOfficePress}>
              <LocationDetailsAvailableSpaceCard
                imageSrc={
                  privateOffices[0].imageSrc || "/images/dayoffices.png"
                }
                spaceType="Private Offices"
                isOutOfPolicy={
                  !!spaceTypePolicy && !spaceTypePolicy.privateOffice
                }
                description="Full-day private offices for just you or your whole team."
                actionText={`See ${
                  privateOffices.length > 1
                    ? `${privateOffices.length} offices`
                    : "office details"
                } `}
              />
            </Pressable>
            <LocationDetailsAvailableSpacesModal
              title="Offices"
              isVisible={isPrivateOfficesVisible}
              spaces={privateOffices}
              onClose={() => setIsPrivateOfficesVisible(false)}
            />
            <Spacer size={24} />
          </View>
        )}
        {meetingRooms?.length && (
          <View>
            <Pressable onPress={meetingRoomPress}>
              <LocationDetailsAvailableSpaceCard
                imageSrc={
                  meetingRooms[0].imageSrc || "/images/meetingrooms.png"
                }
                spaceType="Meeting Rooms"
                isOutOfPolicy={
                  !!spaceTypePolicy && !spaceTypePolicy.meetingRoom
                }
                description="Rooms available by the hour for collaborating with others."
                actionText={`See ${
                  meetingRooms.length > 1
                    ? `${meetingRooms.length} rooms`
                    : "room details"
                } `}
              />
            </Pressable>
            <LocationDetailsAvailableSpacesModal
              title="Rooms"
              isVisible={isMeetingRoomsVisible}
              spaces={meetingRooms}
              onClose={() => setIsMeetingRoomsVisible(false)}
            />
          </View>
        )}
      </View>
    </LocationDetailsSection>
  );
}
