import { useMediaQuery } from "lib/media_query";

import { BookingDesktopLayout } from "./booking_desktop_layout";
import { BookingMobileLayout } from "./booking_mobile_layout";

interface BookingPageLayoutProps {
  children: JSX.Element;
}

export function BookingPageLayout({ children }: BookingPageLayoutProps) {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <BookingMobileLayout>{children}</BookingMobileLayout>;
  }

  return <BookingDesktopLayout>{children}</BookingDesktopLayout>;
}
