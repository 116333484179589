import { colors } from "components/colors";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { ReactNode, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

interface PageLayoutProps {
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
  title?: string;
  stickyHeader?: boolean;
}

export function DesktopLayout(props: PageLayoutProps) {
  const { header, body, footer, title, stickyHeader = true } = props;
  const history = useHistory();

  useEffect(() => {
    if (window && history.action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [history.action]);

  return (
    <View style={styles.pageWrapper}>
      <View
        style={[
          styles.headerWrapper,
          stickyHeader && styles.stickyHeaderWrapper,
        ]}
      >
        {header}
      </View>
      <View style={styles.contentWrapper} testID="page-layout-content-wrapper">
        <View style={styles.content}>
          {title && (
            <>
              <Spacer size={40} />
              <Heading size="lg" color="black-core">
                {title}
              </Heading>
              <Spacer size={32} />
            </>
          )}
          <View>{body}</View>
        </View>
      </View>
      <Spacer size={56} />
      {footer}
    </View>
  );
}

const styles = StyleSheet.create({
  pageWrapper: {
    minHeight: "100vh",
    backgroundColor: colors.background.main,
  },
  headerWrapper: {
    backgroundColor: colors.brand.whitecore,
  },
  stickyHeaderWrapper: {
    // @ts-ignore
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  contentWrapper: {
    flexDirection: "row",
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  content: {
    flex: 1,
    maxWidth: 1088,
    paddingHorizontal: 24,
    paddingBottom: 8,
    flexShrink: 1,
  },
});
