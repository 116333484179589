import { View } from "react-native";

import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { DateRange } from "core/booking_date_range_picker";

import { convertDateTimeRange } from "../bookings_utils";

interface BookingDetailsProps {
  dateRange: DateRange;
  spaceName: string;
  spaceType: string;
  confirmationId?: string;
}

export function BookingDetails({
  dateRange,
  spaceName,
  spaceType,
  confirmationId,
}: BookingDetailsProps) {
  const { dateRange: formatedDateRange, timeRange } = convertDateTimeRange(
    "iiii, MMM d, yyyy",
    "to",
    dateRange,
  );

  return (
    <View>
      <Text size="xs" weight="semibold">
        {formatedDateRange}
        {timeRange && ` · ${timeRange}`}
      </Text>
      <Spacer size={8} />
      <Text size="md" weight="semibold">
        {spaceName} · {spaceType}
      </Text>
      {confirmationId && (
        <>
          <Spacer size={8} />
          <Text size="xs" color="black-70">
            Confirmation:{" "}
            <Text size="xs" color="black-70" weight="semibold">
              {confirmationId}
            </Text>
          </Text>
        </>
      )}
    </View>
  );
}
