import { gql } from "@apollo/client";

export const cityDetailsFragment = gql`
  fragment CityDetails on City {
    name
    state
    country
    latitude
    longitude
    displayName
  }
`;
export const teamsUserDetailsFragment = gql`
  fragment TeamsUserDetails on OrganizationUser {
    id
    userId
    picture
    fullName
    title
    email
    city {
      ...CityDetails
    }
  }
`;
export const teamsLocationDetailsFragment = gql`
  fragment TeamsLocationDetails on Location {
    id
    name
    timezone
    currentUserDistance: distance(coordinates: $currentUserCoordinates)
    amenities {
      id
      name
      iconURL
      code
    }
    images {
      small {
        url
        width
        height
      }
    }
    address {
      city
      state
      streetAddress
      latitude
      longitude
    }
    bookings(startDate: $startDate, endDate: $endDate) {
      id
      user {
        ...TeamsUserDetails
      }
    }
  }
`;
export const teamsSpaceDetailsFragment = gql`
  fragment TeamsSpaceDetails on Space {
    id
    name
    spaceType
    locked
    currency
    maxCapacity
    amenities {
      id
      iconURL
      name
      code
    }
    country
    location {
      ...TeamsLocationDetails
    }
    physicalSpacesCount
    physicalSpaces(first: 1) {
      id
      name
      floor
      withWindow
    }
    images {
      small {
        url
        width
        height
      }
    }
    pricings {
      type
      price
    }
    priceRanges {
      daily {
        min
        max
      }
      hourly {
        min
        max
      }
    }
  }
`;
export const teamsBookingDetailsFragment = gql`
  fragment TeamsBookingDetails on Booking {
    id
    user {
      ...TeamsUserDetails
    }
    space {
      ...TeamsSpaceDetails
    }
    startTime
    endTime
  }
`;
