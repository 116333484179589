import { StyleSheet, View } from "react-native";
import { Icon } from "components/icon";
import { SelectInput } from "components/select/select_input";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { DataTableBasePropTypes } from "../data_table_base";

export interface TableHeaderPropTypes {
  searchPlaceholder: DataTableBasePropTypes["searchPlaceholder"];
  handleSearchChange: DataTableBasePropTypes["onSearchChange"];
  searchInput: string;
  handleSortChange: (arg: string | undefined) => void;
  currentSort: string;
  sortableBy: DataTableBasePropTypes["sortableBy"];
  searchableBy: DataTableBasePropTypes["searchableBy"];
}

export function TableHeader({
  searchPlaceholder,
  handleSearchChange,
  searchInput,
  sortableBy,
  handleSortChange,
  currentSort,
  searchableBy,
}: TableHeaderPropTypes) {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const withSearch = searchableBy?.length;
  const withSort = sortableBy?.length;

  return withSearch || withSort ? (
    <View style={styles.tableHeader}>
      {withSearch && (
        <View
          style={[
            styles.searchInputWrapper,
            isMobile && styles.searchInputWrapperMobile,
          ]}
        >
          <TextField
            leftIcon={<Icon name="magnifying-glass" />}
            inputPlaceholder={searchPlaceholder}
            onChange={handleSearchChange}
            value={searchInput}
          />
        </View>
      )}
      {withSort && (
        <View style={styles.sortBy}>
          <Spacer direction="row" size={40} />
          <Text size="xs">Sort by</Text>
          <Spacer direction="row" size={8} />
          <SelectInput
            options={sortableBy || []}
            onChange={handleSortChange}
            value={currentSort}
            valueProp="field"
            contentSameWidth={false}
            contentSameMinWidth
            appearance="outlined"
          />
        </View>
      )}
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  searchInputWrapper: {
    // width: 400,
    flexGrow: 1,
  },
  searchInputWrapperMobile: {
    width: "100%",
  },
  sortBy: {
    flexDirection: "row",
    alignItems: "center",
  },
});
