import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  RemoveFavoriteMutation,
  RemoveFavoriteMutationVariables,
  SetFavoriteSpaceMutation,
  SetFavoriteSpaceMutationVariables,
  SetFavoriteLocationMutation,
  SetFavoriteLocationMutationVariables,
} from "core/graphql.generated";
import { useCallback } from "react";

interface SaveFavoriteSpace {
  saveFavoriteSpaceLoading: boolean;
  saveFavoriteSpace: (resourceId: string) => Promise<void>;
  setFavoriteSpaceError: ApolloError | undefined;
}

interface SaveFavoriteLocation {
  saveFavoriteLocationLoading: boolean;
  saveFavoriteLocation: (resourceId: string) => Promise<void>;
  setFavoriteLocationError: ApolloError | undefined;
}
interface RemoveFavorite {
  removeFavoriteLoading: boolean;
  removeFavorite: (resourceId: string) => Promise<void>;
  removeFavoriteError: ApolloError | undefined;
}

export const useFavoritesMutation = (): [
  SaveFavoriteSpace,
  SaveFavoriteLocation,
  RemoveFavorite,
] => {
  const [
    setFavoriteSpace,
    { loading: saveFavoriteSpaceLoading, error: setFavoriteSpaceError },
  ] = useMutation<SetFavoriteSpaceMutation, SetFavoriteSpaceMutationVariables>(
    setFavoriteSpaceMutation,
  );

  const [
    setFavoriteLocation,
    { loading: saveFavoriteLocationLoading, error: setFavoriteLocationError },
  ] = useMutation<
    SetFavoriteLocationMutation,
    SetFavoriteLocationMutationVariables
  >(setFavoriteLocationMutation);

  const [
    removeFavorite,
    { loading: removeFavoriteLoading, error: removeFavoriteError },
  ] = useMutation<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>(
    removeFavoriteMutation,
  );

  const saveFavoriteSpace = useCallback(
    async (resourceId: string) => {
      await setFavoriteSpace({
        variables: {
          input: {
            resourceId,
          },
        },
      });
    },
    [setFavoriteSpace],
  );

  const saveFavoriteLocation = useCallback(
    async (resourceId: string) => {
      await setFavoriteLocation({
        variables: {
          input: {
            resourceId,
          },
        },
      });
    },
    [setFavoriteLocation],
  );

  const removeFavoriteResource = useCallback(
    async (resourceId: string) => {
      await removeFavorite({
        variables: {
          input: {
            resourceId,
          },
        },
      });
    },
    [removeFavorite],
  );

  return [
    {
      saveFavoriteSpace: saveFavoriteSpace,
      saveFavoriteSpaceLoading,
      setFavoriteSpaceError,
    },
    {
      saveFavoriteLocation: saveFavoriteLocation,
      saveFavoriteLocationLoading,
      setFavoriteLocationError,
    },
    {
      removeFavorite: removeFavoriteResource,
      removeFavoriteLoading,
      removeFavoriteError,
    },
  ];
};

const setFavoriteSpaceMutation = gql`
  mutation setFavoriteSpace($input: CreateFavoriteSpaceInput!) {
    setFavoriteSpace(input: $input)
  }
`;

const setFavoriteLocationMutation = gql`
  mutation setFavoriteLocation($input: CreateFavoriteLocationInput!) {
    setFavoriteLocation(input: $input)
  }
`;

const removeFavoriteMutation = gql`
  mutation removeFavorite($input: RemoveFavoriteResourceInput!) {
    removeFavoriteResource(input: $input)
  }
`;
