import { Heading } from "components/heading_v2";
import { StyleSheet, View } from "react-native";
import { Content } from "components/content";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Avatar } from "components/avatar";
import { Button } from "components/button_v2";
import { useMediaQuery } from "lib/media_query";
import {
  Organization,
  Invite,
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
  RegisterGoogleAccountToOrganizationIfExistsMutation,
} from "core/graphql.generated";
import pluralize from "pluralize";
import { gql, useMutation } from "@apollo/client";

interface SignUpGcalStepTeamJoinProps {
  onGoNext: () => void;
  organization: Partial<Organization>;
  invite?: Partial<Invite> | null;
}

export function SignUpGcalStepTeamJoin(props: SignUpGcalStepTeamJoinProps) {
  const { onGoNext, organization, invite } = props;
  const mq = useMediaQuery();

  const [registerGoogleAccountToOrganizationIfExists] =
    useMutation<RegisterGoogleAccountToOrganizationIfExistsMutation>(
      registerGoogleAccountToOrganizationIfExistsGQLMutation,
    );

  const [acceptInviteMutation] = useMutation<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >(acceptInviteGQLMutation);

  const onContinue = async () => {
    if (invite?.id && invite?.status === "pending") {
      await acceptInviteMutation({
        variables: { inviteID: invite.id },
      });
    }

    if (!invite?.id && organization?.id) {
      await registerGoogleAccountToOrganizationIfExists();
    }

    onGoNext();
  };

  const users = organization?.users || [];
  return (
    <Content maxWidth={590}>
      <Heading size="mega" align="center">
        Join the {organization?.name} team on Flexspace
      </Heading>
      <Spacer size={16} />
      <Text size="xs" align="center">
        Looks like your organization is already using Flexspace! Continue to
        join your team.
      </Text>
      <Spacer size={56} />
      <View style={styles.peopleContainer}>
        {organization?.users?.map((user) => (
          <View style={styles.peopleItemContainer} key={user.id}>
            <Avatar
              size="lg"
              source={{ uri: user.picture || "" }}
              name={user?.fullName || ""}
            />
          </View>
        ))}
      </View>
      <Spacer size={24} />
      <Text size="xs" align="center">
        {users
          .slice(0, 2)
          .map((user) => user?.fullName)
          .join(", ")}{" "}
        {users.length - 2 > 0 &&
          `and ${users.length - 2} ${pluralize("other", users.length - 2)} `}
        {pluralize("have", users.length)} already joined
      </Text>
      <Spacer size={24} />
      <View
        style={{
          width: mq.sizeQuery.mdAndUp ? 220 : "100%",
          margin: "auto",
          justifyContent: mq.deviceQuery.mobile ? "flex-end" : "center",
        }}
      >
        <Button text="Continue" onPress={onContinue} />
      </View>
    </Content>
  );
}

const styles = StyleSheet.create({
  peopleItemContainer: {
    width: 48,
    height: 48,
    position: "relative",
    marginLeft: -5,
  },
  peopleContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
});

const acceptInviteGQLMutation = gql`
  mutation AcceptInvite($inviteID: String!) {
    acceptInvite(inviteID: $inviteID)
  }
`;

const registerGoogleAccountToOrganizationIfExistsGQLMutation = gql`
  mutation RegisterGoogleAccountToOrganizationIfExists {
    registerGoogleAccountToOrganizationIfExists
  }
`;
