import {
  setHours,
  setMinutes,
  addHours as dateFnsAddHours,
  addMinutes as dateFnsAddMinutes,
  subMinutes as dateFnsSubMinutes,
  subHours as dateFnsSubHours,
  differenceInMinutes as dateFnsDifferenceInMinutes,
  differenceInHours as dateFnsDifferenceInHours,
  isAfter as dateFnsIsAfter,
  isBefore as dateFnsIsBefore,
  setSeconds,
  setMilliseconds,
  format,
  isSameDay,
} from "date-fns";

export interface TimeInterval {
  start: Time;
  end: Time;
}

export type Minutes = number;
export type Hours = number;

export function toMinutes(hours: Hours) {
  return hours * 60;
}

export const TIME_HOUR_FORMAT = "HH";
export const TIME_MINUTE_FORMAT = "mm";
export const TIME_FORMAT = `${TIME_HOUR_FORMAT}${TIME_MINUTE_FORMAT}`;

export function formatTime(
  time: Time,
  locales?: string | string[],
  options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  },
) {
  return new Intl.DateTimeFormat(locales, options).format(parseTime(time));
}

/** Time is in `HHmm` format */
export type Time = typeof TIME_FORMAT;

export function newTime(hours: number, minutes: number): Time {
  let date = new Date();

  date = setHours(date, hours);
  date = setMinutes(date, minutes);
  date = setSeconds(date, 0);
  date = setMilliseconds(date, 0);

  return toTime(date);
}

export function setSameTime(date: Date, time: Time): Date {
  const [hours, minutes] = getTime(time);
  date = setHours(date, hours);
  date = setMinutes(date, minutes);
  date = setSeconds(date, 0);
  date = setMilliseconds(date, 0);

  return date;
}

export function isSameTime(timeLeft: Time, timeRight: Time): boolean {
  return timeLeft === timeRight;
}

export function subMinutes(
  time: Time,
  amount: Minutes,
  capAtStartOfDay = false,
): Time {
  const date = parseTime(time);
  const newDate = dateFnsSubMinutes(date, amount);

  if (isSameDay(date, newDate) === false) {
    if (capAtStartOfDay === true) {
      return "0000";
    }
    throw new Error("Adding amount moved to previous day");
  }

  return toTime(newDate);
}

export function addMinutes(
  time: Time,
  amount: Minutes,
  capAtEndOfDay = false,
): Time {
  const date = parseTime(time);
  const newDate = dateFnsAddMinutes(date, amount);

  if (isSameDay(date, newDate) === false) {
    if (capAtEndOfDay === true) {
      return "2359";
    }
    throw new Error("addMinutes moved to next day");
  }

  return toTime(newDate);
}

export function subHours(
  time: Time,
  amount: Hours,
  capAtStartOfDay = false,
): Time {
  const date = parseTime(time);
  const newDate = dateFnsSubHours(date, amount);

  if (isSameDay(date, newDate) === false) {
    if (capAtStartOfDay === true) {
      return "0000";
    }
    throw new Error("subHours moved to previous day");
  }

  return toTime(newDate);
}

export function addHours(
  time: Time,
  amount: Hours,
  capAtEndOfDay = false,
): Time {
  const date = parseTime(time);
  const newDate = dateFnsAddHours(date, amount);

  if (isSameDay(date, newDate) === false) {
    if (capAtEndOfDay === true) {
      return "2359";
    }
    throw new Error("addHours moved to next day");
  }

  return toTime(newDate);
}

export function differenceInMinutes(timeLeft: Time, timeRight: Time): Minutes {
  const dateLeft = parseTime(timeLeft);
  const dateRight = parseTime(timeRight);

  return Math.abs(dateFnsDifferenceInMinutes(dateLeft, dateRight));
}

export function differenceInHours(timeLeft: Time, timeRight: Time): Hours {
  const dateLeft = parseTime(timeLeft);
  const dateRight = parseTime(timeRight);

  return Math.abs(dateFnsDifferenceInHours(dateLeft, dateRight));
}

export function isBefore(timeLeft: Time, timeRight: Time): boolean {
  const dateLeft = parseTime(timeLeft);
  const dateRight = parseTime(timeRight);

  return dateFnsIsBefore(dateLeft, dateRight);
}

export function isAfter(timeLeft: Time, timeRight: Time): boolean {
  const dateLeft = parseTime(timeLeft);
  const dateRight = parseTime(timeRight);

  return dateFnsIsAfter(dateLeft, dateRight);
}

export function startOfDay(): Time {
  return "0000";
}

export function endOfDay(): Time {
  return "2359";
}

export function parseTime(time: Time): Date {
  const [hours, minutes] = getTime(time);

  let date = new Date();

  date = setHours(date, hours);
  date = setMinutes(date, minutes);
  date = setSeconds(date, 0);
  date = setMilliseconds(date, 0);

  return date;
}

export function toTime(date: Date): Time {
  return format(date, TIME_FORMAT);
}

export function isTime(value: unknown): value is Time {
  if (typeof value !== "string") {
    return false;
  }

  try {
    const date = parseTime(value);

    if (toTime(date) !== value) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}
export function getTime(time: Time): [number, number] {
  const hours = time.substring(0, 2);
  const minutes = time.substring(2);

  return [parseInt(hours, 10), parseInt(minutes, 10)];
}

export function getTimes(interval: TimeInterval, stepInMinutes = 60): Time[] {
  const endTime = interval.end;
  let currentTime = interval.start;

  const times: Time[] = [];

  while (isBefore(currentTime, endTime) || isSameTime(currentTime, endTime)) {
    times.push(currentTime);
    currentTime = addMinutes(currentTime, stepInMinutes);
  }

  return times;
}

export function formatTimeRange(
  pattern: string,
  seperator: string,
  startTime: string,
  endTime: string,
): string {
  return `${format(parseTime(startTime), pattern)} ${seperator} ${format(
    parseTime(endTime),
    pattern,
  )}`;
}
