import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useGCalExtension } from "hooks/use_gcal_extension";
import { useMediaQuery } from "lib/media_query";
import { appConfig } from "providers/config";
import { useGCalBannerFeatureFlag } from "providers/splitio";
import React from "react";
import { StyleSheet, View } from "react-native";

export function FlexSpaceForGoogleCalendar() {
  const mq = useMediaQuery();
  const gCalBannerFeatureFlag = useGCalBannerFeatureFlag();
  const { isLoading, version } = useGCalExtension();
  const isMobile = mq.deviceQuery.mobile;
  const isBannerHidden = !gCalBannerFeatureFlag || isLoading || version;
  const onClick = () => {
    window.open(appConfig.chromeWebstoreUrl, "_blank");
  };

  if (isBannerHidden) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text size={isMobile ? "md" : "lg"} weight={"semibold"}>
        Flexspace for Google Calendar
      </Text>
      <Text size={isMobile ? "micro" : "xs"} align={"center"}>
        The easiest way to coordinate a space for you next meeting.
      </Text>
      <Spacer size={8} />
      <Button
        text={"Install Google Calendar add-on"}
        size={"medium"}
        onPress={onClick}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: colors.brand.eggplantMinus90,
    borderRadius: 4,
    gap: 8,
    padding: 24,
  },
});
