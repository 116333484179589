import { View } from "react-native";
import { useHistory } from "react-router-dom";

import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { Button } from "components/button_v2";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";

interface BookingDetailBookAgainProps {
  spaceId: string;
  spaceName: string;
}

export function BookingDetailBookAgain({
  spaceId,
  spaceName,
}: BookingDetailBookAgainProps) {
  const mq = useMediaQuery();
  const history = useHistory();
  const analytics = useAnalytics();

  const handleClickBookAgain = () => {
    history.push(`/offsite_spaces/${spaceId}`);
    analytics.event("Click Book Again", {
      category: "Post booking",
      "Space UUID": spaceId,
      "Space Name": spaceName,
      Source: "Booking Details",
    });
  };

  return (
    <View>
      {mq.deviceQuery.desktop && (
        <>
          <Text size="xs" weight="semibold" align="center">
            Loved this space?
          </Text>
          <Spacer size={12} />
        </>
      )}
      <Button text="Book again" onPress={handleClickBookAgain} />
    </View>
  );
}
