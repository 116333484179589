import { useEffect, useMemo, useState } from "react";
import { useQuery, gql, useMutation, useApolloClient } from "@apollo/client";
import { View, StyleSheet } from "react-native";

import { useMediaQuery } from "lib/media_query";
import {
  GroupBudgetDetailPeopleQuery,
  GroupBudgetDetailPeopleQueryVariables,
  GroupBudgetRole,
  OrganizationUser,
  UpdateGroupBudgetAddMemberMutation,
  UpdateGroupBudgetAddMemberMutationVariables,
} from "core/graphql.generated";
import { Text } from "components/text";
import { TextField } from "components/text_field.web";
import { Icon } from "components/icon";
import { Avatar } from "components/avatar";
import { Spacer } from "components/spacer";
import { colors } from "components/colors";
import { DataTableRaw } from "components/data_table/data_table_raw";
import { useToast } from "providers/toast";
import { searchSortRows } from "components/data_table/utils/search_sort_rows";
import { logger } from "lib/logger";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";

import {
  AddBudgetMembers,
  updateGroupBudgetAddMemberMutation,
} from "./components/add_budget_members";
import { GroupBudgetDetailSkeletonForTabs } from "./components/group_budget_detail_skeleton_for_tabs";
import { useAnalytics } from "providers/analytics";
import { useDebounce } from "hooks/use_debounce";

interface renderNameProps {
  fullName?: string;
  picture?: string;
  email?: string;
}

interface GroupBudgetDetailPeoplePropTypes {
  groupId: string;
}

const mobileCols = [
  {
    id: "name",
    title: "Name",
    width: "100%",
  },
];

const desktopCols = [
  {
    id: "name",
    title: "Name",
    width: "50%",
  },
  {
    id: "role",
    title: "Budget role",
    width: "45%",
  },
];

export function GroupBudgetDetailPeople({
  groupId,
}: GroupBudgetDetailPeoplePropTypes) {
  const {
    loading: groupBudgetDetailPeopleLoading,
    data: groupBudgetDetailPeopleData,
  } = useQuery<
    GroupBudgetDetailPeopleQuery,
    GroupBudgetDetailPeopleQueryVariables
  >(groupBudgetDetailPeopleQuery, {
    variables: { groupBudgetId: groupId },
  });

  const groupBudgetMembers = useMemo(
    () => groupBudgetDetailPeopleData?.groupBudget?.members,
    [groupBudgetDetailPeopleData?.groupBudget?.members],
  );

  const mq = useMediaQuery();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebounce(searchValue, 500);

  const [updateGroupBudget] = useMutation<
    UpdateGroupBudgetAddMemberMutation,
    UpdateGroupBudgetAddMemberMutationVariables
  >(updateGroupBudgetAddMemberMutation);

  const toast = useToast();
  const analytics = useAnalytics();
  const apolloClient = useApolloClient();

  const renderNameCell = ({ fullName, picture, email }: renderNameProps) => (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Avatar name={fullName || ""} source={{ uri: picture }} size="lg" />
      <Spacer direction="row" size={8} />
      <View>
        <Text customColor={colors.brand.blackcore} size="xs">
          {fullName}
        </Text>
        <Spacer size={4} />
        <Text customColor={colors.brand.blackMinus30} size="xs">
          {email}
        </Text>
      </View>
    </View>
  );

  useEffect(() => {
    if (debouncedSearch) {
      analytics.event("Search Group Budget Members", {
        Term: debouncedSearch,
      });
    }
  }, [analytics, debouncedSearch]);

  const renderRows = () => {
    const handleContextMenuClick = (
      optionClick: "revokeManager" | "grantManager" | "remove",
      user: OrganizationUser,
    ) => {
      const memberOrgUserIds: string[] = [];
      const managerOrgUserIds: string[] = [];

      groupBudgetMembers?.forEach((m) => {
        if (m.role === GroupBudgetRole.Manager) {
          managerOrgUserIds.push(m.user.id);
        } else if (m.role === GroupBudgetRole.Member) {
          memberOrgUserIds.push(m.user.id);
        }
      });

      const handleUpdateGroupBudget = async (
        mutatationInputs: {
          memberOrgUserIds?: string[];
          managerOrgUserIds?: string[];
        },
        notifyMessage: string,
        type?: "grantManager" | "revokeManager" | "remove",
      ) => {
        try {
          await updateGroupBudget({
            variables: {
              input: {
                id: groupId,
                memberIds: mutatationInputs.memberOrgUserIds,
                managerIds: mutatationInputs.managerOrgUserIds,
              },
            },
          });

          if (type === "grantManager" || type === "revokeManager") {
            analytics.event(
              type === "grantManager"
                ? "Grant Group Budget Manager"
                : "Revoke Group Budget Manager",
            );
          }
          toast.notify({
            message: notifyMessage,
          });
          await apolloClient.refetchQueries({
            include: "active",
          });
        } catch (error) {
          toast.notify({
            message:
              extractFirstGraphQLErrorMessage(error) ||
              "Cannot update group budget with modified members",
          });
          logger.error(
            "Cannot update group budget with modified members",
            error,
          );
        }
      };

      if (optionClick === "revokeManager") {
        handleUpdateGroupBudget(
          {
            memberOrgUserIds: memberOrgUserIds.concat(user.id),
            managerOrgUserIds: managerOrgUserIds.filter((id) => id !== user.id),
          },
          `${user.fullName}'s budget manager access has been revoked`,
          "revokeManager",
        );
      } else if (optionClick === "grantManager") {
        handleUpdateGroupBudget(
          {
            memberOrgUserIds: memberOrgUserIds.filter((id) => id !== user.id),
            managerOrgUserIds: managerOrgUserIds.concat(user.id),
          },
          `${user.fullName}'s budget manager access has been granted`,
          "grantManager",
        );
      } else if (optionClick === "remove") {
        handleUpdateGroupBudget(
          {
            ...(user.groupBudgetRole === GroupBudgetRole.Manager && {
              managerOrgUserIds: managerOrgUserIds.filter(
                (id) => id !== user.id,
              ),
            }),
            ...(user.groupBudgetRole === GroupBudgetRole.Member && {
              memberOrgUserIds: memberOrgUserIds.filter((id) => id !== user.id),
            }),
          },
          `${user.fullName} has been successfully removed from budget`,
        );
      }
    };

    const renderRows =
      groupBudgetMembers?.map((m) => {
        const user = m.user as OrganizationUser;
        const budgetRole = m.role;
        return {
          id: user.id,
          name: {
            render: () =>
              renderNameCell({
                fullName: user.fullName,
                picture: user.picture || "",
                email: user.email || "",
              }),
            value: user.fullName,
          },
          role: budgetRole,
          email: user.email || "",
          contextMenu: [
            budgetRole === GroupBudgetRole.Manager && {
              label: "Revoke manager access",
              onClick: () => handleContextMenuClick("revokeManager", user),
            },
            budgetRole === GroupBudgetRole.Member && {
              label: "Grant manager access",
              onClick: () => handleContextMenuClick("grantManager", user),
            },
            {
              label: "Remove from budget",
              onClick: () => handleContextMenuClick("remove", user),
            },
          ],
        };
      }) || [];

    return searchSortRows(renderRows, "name", searchValue, ["name", "email"]);
  };

  if (groupBudgetDetailPeopleLoading) {
    return <GroupBudgetDetailSkeletonForTabs />;
  }

  return (
    <View>
      <View
        style={
          mq.deviceQuery.desktop
            ? styles.tableActionsDesktop
            : styles.tableActionsMobile
        }
      >
        <View style={styles.searchInputWrapper}>
          <TextField
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            leftIcon={<Icon name="magnifying-glass" />}
            inputPlaceholder="Search by name or email"
          />
        </View>
        <AddBudgetMembers
          groupBudgetMembers={
            groupBudgetMembers?.map((m) => ({
              id: m.user.id,
              groupBudgetRole: m.role,
            })) || []
          }
          groupId={groupId}
          onConfirm={() =>
            apolloClient.refetchQueries({
              include: "active",
            })
          }
        />
      </View>
      <View style={styles.tableWrapper}>
        {groupBudgetMembers?.length ? (
          <DataTableRaw
            rows={renderRows()}
            columns={mq.deviceQuery.desktop ? desktopCols : mobileCols}
          />
        ) : (
          <Text>There are no people in this budget</Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  tableActionsDesktop: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 40,
  },
  tableActionsMobile: {
    flexDirection: "row",
    gap: 0,
  },
  searchInputWrapper: {
    flexGrow: 1,
  },
  addMemberBtn: {
    width: "20%",
  },
  tableWrapper: {
    marginTop: 24,
  },
});

const groupBudgetDetailPeopleQuery = gql`
  query GroupBudgetDetailPeople($groupBudgetId: ID!) {
    groupBudget(id: $groupBudgetId) {
      id
      status
      members {
        role
        user {
          id
          fullName
          email
          picture
          groupBudgetRole
        }
      }
    }
  }
`;
