import {
  addDays,
  eachDayOfInterval,
  getDay,
  subDays,
  startOfDay,
  endOfDay,
  Interval,
} from "date-fns";
import { Day } from "./day_utils";
import { DayOfWeek } from "./date_utils";
import { getSystemLocale } from "./locale";

export const DEFAULT_FIRST_DAY_OF_WEEK = 1;

export enum WeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
}

export interface Week {
  month: number;
  index: number;
  days: Day[];
}

export function getWeekDaysOptions(): { value: WeekDay; label: string }[] {
  return [
    { value: WeekDay.Monday, label: "Monday" },
    { value: WeekDay.Tuesday, label: "Tuesday" },
    { value: WeekDay.Wednesday, label: "Wednesday" },
    { value: WeekDay.Thursday, label: "Thursday" },
    { value: WeekDay.Friday, label: "Friday" },
    { value: WeekDay.Saturday, label: "Saturday" },
    { value: WeekDay.Sunday, label: "Sunday" },
  ];
}

export type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const getFirstDateOfWeek = (
  date: Date,
  firstDayOfWeek: FirstDayOfWeek = DEFAULT_FIRST_DAY_OF_WEEK,
): Date => {
  const day = getDay(date);
  const diffDays = day - firstDayOfWeek;

  const sub = diffDays < 0 ? 7 + diffDays : diffDays;

  return subDays(date, sub);
};

export const getLastDateOfWeek = (
  date: Date,
  firstDayOfWeek: FirstDayOfWeek = DEFAULT_FIRST_DAY_OF_WEEK,
): Date => {
  const firstDateOfTheWeek = getFirstDateOfWeek(date, firstDayOfWeek);

  return addDays(firstDateOfTheWeek, 6);
};

export const getWeekInterval = (
  date: Date,
  firstDayOfWeek: FirstDayOfWeek = DEFAULT_FIRST_DAY_OF_WEEK,
): Interval => {
  return {
    start: startOfDay(getFirstDateOfWeek(date, firstDayOfWeek)),
    end: endOfDay(getLastDateOfWeek(date, firstDayOfWeek)),
  };
};

export const eachDayOfWeek = (
  date = new Date(),
  firstDayOfWeek: FirstDayOfWeek = DEFAULT_FIRST_DAY_OF_WEEK,
): Date[] => {
  return eachDayOfInterval(getWeekInterval(date, firstDayOfWeek));
};

export const getFirstDateOfWeekLocale = () => {
  const locale = new Intl.Locale(getSystemLocale());
  let firstDay = 7; //default to Sunday
  /*  Depend on the Browser compatibility, the weekInfo can be get from locale.getWeekInfo() or from locale.weekInfo
  ref from this link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo */

  // @ts-ignore => locale TS type return doest not have this prototype/method but when we run the code it works
  if (locale.getWeekInfo && locale.getWeekInfo()) {
    // @ts-ignore
    firstDay = locale.getWeekInfo().firstDay;
    // @ts-ignore
  } else if (locale.weekInfo) {
    // @ts-ignore
    firstDay = locale.weekInfo.firstDay;
  }

  //Our DayOfWeek type is 0 based (0 => 6, 0 = Sunday), but the locale.weekInfo.firstDay is 1 based (1-7, 1 = Monday
  const firstDayOfWeek = (firstDay === 7 ? 0 : firstDay) as DayOfWeek;

  return firstDayOfWeek;
};
