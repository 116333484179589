import { gql, useQuery } from "@apollo/client";
import {
  GetUserOrderQuery,
  GetUserOrderQueryVariables,
  OrderSort,
} from "core/graphql.generated";
import { Day } from "lib/day_utils";
import {
  bookingSpaceDetailFragment,
  spaceLocationDetailFragment,
} from "pages/homev3/fragment";
import { useMemo } from "react";
import { toCoordinates } from "../../homev2/mapbox";
import { useUserCurrentLocation } from "./use_user_current_location";

export type UseOrderQueryProps = {
  limit?: number;
  sort?: OrderSort;
  startDate?: Day;
  endDate?: Day;
};
export function useUserOrderQuery({
  limit,
  sort = OrderSort.CreatedTimeAsc,
  startDate,
  endDate,
}: UseOrderQueryProps) {
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useUserCurrentLocation();
  const variables: GetUserOrderQueryVariables | null = useMemo(() => {
    if (!currentUserCoordinates || currentUserLoading) {
      return null;
    }
    return {
      currentUserCoordinates: toCoordinates(currentUserCoordinates.center),
      limit,
      sort,
      startDate,
      endDate,
    };
  }, [
    currentUserCoordinates,
    currentUserLoading,
    endDate,
    limit,
    sort,
    startDate,
  ]);

  const {
    data,
    loading: orderLoading,
    error,
  } = useQuery<GetUserOrderQuery, GetUserOrderQueryVariables>(getUserOrder, {
    variables: variables!,
    skip: !variables,
  });

  return {
    data,
    loading: currentUserLoading || orderLoading,
    error,
  };
}

const getUserOrder = gql`
  ${spaceLocationDetailFragment}
  ${bookingSpaceDetailFragment}
  query GetUserOrder(
    $currentUserCoordinates: SpaceSearchCoordinates
    $limit: Int
    $sort: OrderSort
    $startDate: String
    $endDate: String
  ) {
    currentUser {
      id
      orders(
        limit: $limit
        sort: $sort
        startDate: $startDate
        endDate: $endDate
      ) {
        ...CurrentUserOrder
      }
    }
  }
  fragment CurrentUserOrder on Order {
    id
    status
    confirmationID
    invites {
      recipients {
        name
        email
      }
    }
    orderItems {
      details {
        __typename
        ...OrderItemDailyBookingDetail
        ...OrderItemHourlyBookingDetail
      }
    }
  }
  fragment OrderItemDailyBookingDetail on DailyBooking {
    startDate
    endDate
    space {
      ...BookingSpaceDetail
    }
  }
  fragment OrderItemHourlyBookingDetail on HourlyBooking {
    date
    startTime
    endTime
    space {
      ...BookingSpaceDetail
    }
  }
`;
