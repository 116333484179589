import { gql, useMutation, useQuery } from "@apollo/client";
import { AdminHeader } from "components/admin_header";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { Heading } from "components/heading_v2";
import { DesktopLayout } from "components/layout/desktop_layout";
import { MobileLayout } from "components/layout/mobile_layout";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Switch } from "components/switch";
import { TextField } from "components/text_field";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { Footer } from "core/footer";
import {
  AuthMethod,
  CurrentUserOrganizationNameQuery,
  CurrentUserOrganizationNameQueryVariables,
  Plan,
  UserStatus,
} from "core/graphql.generated";
import { format } from "date-fns";
import { logger } from "lib/logger";
import { useMediaQuery } from "lib/media_query";
import { capitalizeFirstLetter, getDomainFromEmail } from "lib/string_utils";
import { BudgetDetailHeaderMobile } from "pages/budgets/components/budget_detail_header_v1";
import { isCommonEmailProvider } from "pages/manage_organization/is_common_email_provider";
import { useCustomSSOFeatureFlag } from "providers/splitio";
import { useToast } from "providers/toast";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { StyleSheet, View } from "react-native";
import {
  OrganizationProfileCard,
  OrganizationProfileCardWrapper,
} from "./components/organization_profile_card";
import { OrganizationProfileSkeleton } from "./components/organization_profile_skeleton";

export function OrganizationProfilePage() {
  const mq = useMediaQuery();
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const [orgName, setOrgName] = useState<string | null | undefined>("");
  const toast = useToast();

  const { data: currentUserQuery, loading: userDataLoading } = useQuery<
    CurrentUserOrganizationNameQuery,
    CurrentUserOrganizationNameQueryVariables
  >(currentUserOrganizationNameQuery, {
    variables: {
      status: UserStatus.Active,
    },
  });

  const currentUser = currentUserQuery?.currentUser;
  const commonEmailProvider = isCommonEmailProvider(currentUser?.email);
  const organization = currentUser?.organization;

  logger.info(`[DEBUG] org: ${JSON.stringify(organization)}`);

  const isPro = currentUser?.organization?.plan === Plan.Pro;
  const [updateOrganizationName, { error, loading }] = useMutation(
    updateOrganizationNameMutation,
  );

  const [updateOrganizationSSO, { loading: ssoLoading }] = useMutation(
    updateOrganizationSSOMutation,
  );

  const handleSSOChange = async (value: boolean) => {
    await updateOrganizationSSO({
      variables: {
        input: {
          sso: value,
        },
      },
    });
    toast.notify({ message: "Google Workspace SSO updated" });
  };

  useEffect(() => {
    setOrgName(currentUserQuery?.currentUser?.organization?.name);
  }, [currentUserQuery?.currentUser?.organization?.name]);

  const handleSubmit = useCallback(async () => {
    await updateOrganizationName({
      variables: {
        input: {
          organizationName: orgName,
        },
      },
    });

    toast.notify({ message: "Organization name updated" });
    setOpen(false);
  }, [orgName, toast, updateOrganizationName]);

  if (!userDataLoading && !organization) {
    return null;
  }

  return (
    <Fragment>
      <Helmet>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
        />
      </Helmet>
      <DialogModal
        headerTitle="Update organization name"
        isVisible={open}
        onClose={onClose}
        center={true}
        bottomButtons={
          <View style={styles.bottomButtons}>
            <Button text="Cancel" appearance="textLink" />
            <Button
              testID="save-name-button"
              loading={loading}
              onPress={handleSubmit}
              text="Save"
            />
          </View>
        }
      >
        <Fragment>
          <View>
            <View style={styles.textInput}>
              <TextField
                label="Organization name"
                value={orgName ?? ""}
                onChange={(value) => setOrgName(value)}
                testID="orgprofile-save-name-input"
                invalid={
                  !orgName &&
                  !userDataLoading &&
                  !currentUserQuery?.currentUser?.organization?.name
                }
                invalidText={
                  !orgName && !userDataLoading
                    ? "Please enter your organization name"
                    : ""
                }
              />
            </View>
            <Spacer size={16} />
          </View>
          {error && (
            <View>
              <Spacer size={16} />
              <Text customColor={colors.brand.grapefruitcore}>
                {error.message}
              </Text>
            </View>
          )}
        </Fragment>
      </DialogModal>
      {!mq.deviceQuery.mobile ? (
        <DesktopLayout
          header={<AdminHeader />}
          footer={<Footer />}
          body={
            <Fragment>
              <Spacer size={40} />
              <Heading size="lg">Organization settings </Heading>
              <Spacer size={40} />
              {userDataLoading ? (
                <OrganizationProfileSkeleton />
              ) : (
                <OrganizationProfileDesktop
                  commonEmailProvider={commonEmailProvider}
                  openUpdateModal={() => setOpen(true)}
                  userNumber={currentUser?.organization?.users.length ?? 0}
                  date={
                    currentUserQuery?.currentUser?.organization?.createdAt
                      ? format(
                          new Date(
                            currentUserQuery?.currentUser?.organization?.createdAt,
                          ),
                          "MMMM d, YYY",
                        )
                      : "-"
                  }
                  organizationName={orgName || ""}
                  planDetailtext={isPro ? "Pro" : "Starter"}
                  userEmail={currentUser?.email}
                  orgDomain={currentUser?.organization?.domain}
                  handleSSOChange={handleSSOChange}
                  ssoLoading={ssoLoading}
                  authMethod={currentUser?.authMethod}
                  isRequiredSSO={!!organization?.requireSSO}
                  ssoProvider={organization?.ssoProvider}
                  slug={organization?.slug || ""}
                />
              )}
            </Fragment>
          }
        />
      ) : (
        <MobileLayout
          header={<BudgetDetailHeaderMobile title="Organization profile" />}
          footer={<BottomBarAdminNavigation />}
          body={
            userDataLoading ? (
              <OrganizationProfileSkeleton />
            ) : (
              <OrganizationProfileMobile
                openUpdateModal={() => setOpen(true)}
                userNumber={currentUser?.organization?.users.length ?? 0}
                date={
                  currentUserQuery?.currentUser?.organization?.createdAt
                    ? format(
                        new Date(
                          currentUserQuery?.currentUser?.organization?.createdAt,
                        ),
                        "MMMM d, YYY",
                      )
                    : "-"
                }
                organizationName={orgName || ""}
                planDetailtext={isPro ? "Pro" : "Starter"}
                handleSSOChange={handleSSOChange}
                isRequiredSSO={!!organization?.requireSSO}
                ssoProvider={organization?.ssoProvider}
                slug={organization?.slug || ""}
              />
            )
          }
        />
      )}
    </Fragment>
  );
}

interface OrganizationProfileProps {
  openUpdateModal: () => void;
  userNumber: number;
  date: string;
  organizationName: string;
  planDetailtext: string;
  userEmail?: string | null;
  orgDomain?: string | null;
  handleSSOChange: (value: boolean) => Promise<void>;
  ssoLoading?: boolean;
  authMethod?: AuthMethod;
  commonEmailProvider?: boolean;
  isRequiredSSO: boolean;
  ssoProvider?: string[];
  slug?: string;
}

export function OrganizationProfileDesktop(props: OrganizationProfileProps) {
  const {
    openUpdateModal,
    organizationName,
    date,
    userNumber,
    planDetailtext,
    orgDomain,
    userEmail,
    handleSSOChange,
    ssoLoading,
    isRequiredSSO,
    ssoProvider,
    slug,
  } = props;

  const customSSOFeatureFlag = useCustomSSOFeatureFlag();
  const isSSORequiredOrg = isRequiredSSO && ssoProvider && slug;

  const showDomainMenu = !!(userEmail && !isCommonEmailProvider(userEmail));
  const showAuthMenu = customSSOFeatureFlag && isSSORequiredOrg;

  return (
    <View>
      <OrganizationProfileCardWrapper title="Organization profile">
        <OrganizationProfileCard
          value={organizationName}
          title="Organization name"
          action={
            <TextLink
              decoration="none"
              text="Change name"
              testID="change-name"
              onPress={openUpdateModal}
              weight={"semibold"}
              size="xs"
            />
          }
        />
        <Divider />
        <OrganizationProfileCard value={userNumber} title="Total users" />
        <Divider />
        <OrganizationProfileCard value={date} title="Date created" />
      </OrganizationProfileCardWrapper>

      <Spacer size={40} />
      <OrganizationProfileCardWrapper title="Plan details">
        <OrganizationProfileCard
          value={`Your organization is currently on Flexspace’s ${planDetailtext} plan`}
          title="Current plan"
          action={
            <a
              style={{
                textDecoration: "none",
                fontSize: "14px",
                fontFamily: `-appleSystem, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sansSerif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                color: colors.brand.eggplantcore,
                fontWeight: 600,
              }}
              href="https://flexspace.ai/pricing/"
              target="__blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          }
        />
      </OrganizationProfileCardWrapper>

      {showAuthMenu && ssoProvider ? (
        <Fragment>
          <Spacer size={40} />
          <OrganizationProfileCardWrapper title="Authentication">
            <OrganizationProfileCard
              value={
                <Fragment>
                  Your organization requires SSO with{" "}
                  {capitalizeFirstLetter(ssoProvider[0])}. Employees should go
                  to{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://app.flexspace.ai/${slug}`}
                  >
                    app.flexspace.ai/{slug}
                  </a>{" "}
                  to login
                </Fragment>
              }
              title={`${capitalizeFirstLetter(ssoProvider[0])} SSO`}
            />
          </OrganizationProfileCardWrapper>
        </Fragment>
      ) : showDomainMenu ? (
        <Fragment>
          <Spacer size={40} />
          <OrganizationProfileCardWrapper title="Domain settings">
            <OrganizationProfileCard
              value={
                <Fragment>
                  Let anyone with an{" "}
                  <strong>@{getDomainFromEmail(userEmail)}</strong> email join
                  this team
                </Fragment>
              }
              title="Associated domain"
              action={
                ssoLoading ? (
                  <Spinner size="small" />
                ) : (
                  <Switch
                    value={!!orgDomain}
                    onChange={(value) => handleSSOChange(value)}
                    testID={"associated-domain-switch"}
                  />
                )
              }
            />
          </OrganizationProfileCardWrapper>
        </Fragment>
      ) : null}
    </View>
  );
}

export function OrganizationProfileMobile(props: OrganizationProfileProps) {
  const { openUpdateModal, organizationName, date, userNumber } = props;
  return (
    <Fragment>
      <Spacer size={16} />
      <OrganizationProfileCardWrapper title="Organization profile">
        <OrganizationProfileCard
          value={organizationName}
          title="Organization name"
          action={
            <TextLink
              decoration="none"
              text="Change name"
              testID="change-name"
              onPress={openUpdateModal}
              weight={"semibold"}
              size="xs"
            />
          }
        />
        <Divider />
        <OrganizationProfileCard value={userNumber} title="Total users" />
        <Divider />
        <OrganizationProfileCard value={date} title="Date created" />
      </OrganizationProfileCardWrapper>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
  },
  bottomButtons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 8,
    flex: 1,
  },
});

export const currentUserOrganizationNameQuery = gql`
  query CurrentUserOrganizationName($status: UserStatus) {
    currentUser {
      id
      role
      email
      authMethod
      organization {
        id
        name
        domain
        requireSSO
        ssoProvider
        logoUrl
        slug
        monthlyBilling
        plan
        createdAt
        users(status: $status) {
          userId
        }
      }
    }
  }
`;

const updateOrganizationNameMutation = gql`
  mutation updateOrganizationName($input: UpdateOrganizationNameInput!) {
    updateOrganizationName(input: $input) {
      id
      name
    }
  }
`;

export const updateOrganizationSSOMutation = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
      domain
    }
  }
`;
