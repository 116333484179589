import "./core/modal.css";
import "./index.css";
import "./responsive.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "photoswipe/dist/default-skin/default-skin.css";
import "photoswipe/dist/photoswipe.css";

import { App } from "app";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";
import { appConfig } from "providers/config";
import { TestApp } from "test_app";

ReactDOM.render(
  <React.StrictMode>
    {appConfig.environment === "test" ? <TestApp /> : <App />}
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
