import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { Spacer } from "components/spacer";
import { Icon } from "components/icon";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { format, endOfMonth as dateFnsEndOfMonth } from "date-fns";
import { Day, parseDay } from "lib/day_utils";
import { TextField } from "components/text_field";

function endOfMonthShort(day: Day): Day {
  return format(dateFnsEndOfMonth(parseDay(day)), "MM/dd/yy");
}

interface BudgetCardEditSectionPropTypes {
  editing: boolean;
  budgetInputValue: string;
  onBudgetInputChange: (arg: string) => void;
  onBudgetInputSubmit: () => void;
  isInvalid: boolean;
  invalidText: string | undefined;
  cancelEditing: () => void;
  isUpdateBudgetLoading: boolean;
  budget: number;
  setEditing: (arg: boolean) => void;
  editable: boolean;
}

export function BudgetCardEditSection({
  editing,
  budgetInputValue,
  onBudgetInputChange,
  onBudgetInputSubmit,
  isInvalid,
  cancelEditing,
  isUpdateBudgetLoading,
  budget,
  setEditing,
  editable,
  invalidText,
}: BudgetCardEditSectionPropTypes) {
  const handleChange = (arg: string) => {
    onBudgetInputChange(arg);
  };

  const handleSubmit = () => {
    onBudgetInputSubmit();
  };

  const handleCancel = () => {
    cancelEditing();
  };

  return (
    <View style={styles.numberContainer}>
      {editing ? (
        <View style={styles.budgetInputWrapper}>
          <TextField
            value={budgetInputValue}
            keyboardType="number-pad"
            onChange={handleChange}
            onSubmitEditing={onBudgetInputSubmit}
            autoFocus
            invalid={isInvalid}
            invalidText={invalidText}
            testID="budget-edit-input"
            leftIcon={
              <Text
                color={isInvalid ? "grapefruit-core" : "black-core"}
                size="md"
              >
                $
              </Text>
            }
          />
          <Spacer size={19} />
          <View style={styles.buttonsContainer}>
            {
              <View style={styles.button}>
                <Button
                  text="Cancel"
                  onPress={handleCancel}
                  appearance="secondary"
                  testID="budget-edit-cancel"
                />
              </View>
            }
            <Spacer direction="row" size={16} />
            <View style={styles.button}>
              <Button
                testID="budget-edit-update"
                text="Update"
                onPress={handleSubmit}
                loading={isUpdateBudgetLoading}
                disabled={isInvalid}
              />
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.budgetValueWrapper}>
          <Text testID="current-budget" size="mega" weight="bold">
            {formatCurrencyWithoutDecimal(budget, getSystemLocale(), "USD")}
          </Text>
          {!!editable && (
            <Pressable
              testID="budget-edit-button"
              style={styles.editButton}
              onPress={() => setEditing(true)}
            >
              {({ pressed, hovered, focused }: any) => (
                <Icon
                  color={
                    pressed || hovered
                      ? colors.brand.eggplantcore
                      : focused
                      ? colors.brand.eggplantMinus30
                      : undefined
                  }
                  name="pencil"
                />
              )}
            </Pressable>
          )}
        </View>
      )}
      <Spacer size={8} />
      {!isInvalid && (
        <Text size="xs" align="center">
          Monthly Budget ending {endOfMonthShort(new Date().toISOString())}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  numberContainer: {
    padding: 40,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  budgetInputWrapper: {
    width: "100%",
  },
  buttonsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  button: {
    flexGrow: 1,
  },
  budgetValueWrapper: {
    flexDirection: "row",
  },
  editButton: {
    marginLeft: 11,
    justifyContent: "center",
  },
});
