import { ContentHeader } from "components/layout/content_header";
import { SpaceType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { getSpaceType } from "lib/space_type_utils";

interface SpaceDetailContentHeaderProps {
  spaceName: string;
  spaceType: SpaceType;
  locationName: string;
}

export function SpaceDetailContentHeader({
  spaceName,
  spaceType,
  locationName,
}: SpaceDetailContentHeaderProps) {
  const mq = useMediaQuery();

  return (
    <ContentHeader
      title={spaceName}
      description={`${getSpaceType(spaceType)} at ${locationName}`}
      hasBackButton={mq.deviceQuery.desktop}
    />
  );
}
