import { useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Divider } from "components/divider";

import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text";
import { Text as TextV2 } from "components/text_v2";
import { BookingPreviewV2 } from "core/booking_preview";
import {
  getCheckoutCancellationPolicyText,
  SearchValue,
  useBookingDateRangePickerHelper,
} from "core/booking_utils";
import { DialogContent } from "core/dialog_content";
import {
  DailyBooking,
  HourlyBooking,
  OffsiteOrderOtherSpacesQuery,
  OffsiteOrderOtherSpacesQueryVariables,
  OffsiteOrderPage__OrderDetailsQuery,
  OffsiteOrderPage__OrderDetailsQueryVariables,
  OrderPageQuery,
  SpaceDetails__AllDetailsFragment,
  SpaceDetailsQuery,
  SpaceDetailsQueryVariables,
} from "core/graphql.generated";
import { OrderBreakdown } from "core/order_breakdown";
import { spaceGQLQuery } from "core/queries";
import { formatCurrency } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { BookingDetailPaymentInfo } from "pages/booking_details/components/booking_detail_payment_info";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";
import { usePostBookingsFeatureFlag } from "providers/splitio";
import React, { Fragment, useMemo } from "react";
import { ScrollView, View } from "react-native";
import { Redirect } from "react-router-dom";
import { BookingDetailInviteBanner } from "./booking_details/components/booking_detail_invite_banner";
import { EnhanceYourWorkDaySpaceCardMobile } from "./offsite_order/components/enhance_your_work_day_space_card";
import {
  getBooking,
  getDateRangeFromBooking,
} from "./offsite_order/offsite_order";
import {
  offsiteOrderOtherSpacesQuery,
  offsiteOrderPageGQLQuery,
  orderPageCurrentUserGQLQuery,
} from "./offsite_order/offsite_order.queries";
import {
  getDateTime,
  getEnhanceWorkDaySpaces,
} from "./offsite_order/offsite_order_utils";

interface OfsiteOrderMobileProps {
  orderId: string;
  onClose: () => void;
}

export function OffsiteOrderMobile(props: OfsiteOrderMobileProps) {
  const { orderId, onClose } = props;
  const {
    data: offsiteOrderPageQuery,
    loading,
    refetch,
  } = useQuery<
    OffsiteOrderPage__OrderDetailsQuery,
    OffsiteOrderPage__OrderDetailsQueryVariables
  >(offsiteOrderPageGQLQuery, {
    variables: { id: orderId },
  });

  const order = offsiteOrderPageQuery?.order!;
  const booking = order?.orderItems ? getBooking(order?.orderItems) : null;
  const spaceID = booking?.space.id;
  const { data } = useQuery<OrderPageQuery>(orderPageCurrentUserGQLQuery);
  const monthlyBilling = !!data?.currentUser?.organization?.monthlyBilling;
  const {
    data: spaceDetailsQuery,
    error,
    loading: spaceDetailLoading,
  } = useQuery<SpaceDetailsQuery, SpaceDetailsQueryVariables>(spaceGQLQuery, {
    variables: { id: spaceID || "" },
    skip: !spaceID,
  });
  const space = spaceDetailsQuery?.space;

  if (!order && !loading) {
    return <Redirect to="/" />;
  }

  if (error) {
    return <Text>{extractFirstGraphQLErrorMessage(error)}</Text>;
  }

  if (!space && !spaceDetailLoading && !loading) {
    return <Text>Space not found</Text>;
  }

  return (
    <DialogContent
      headerRightIcon="x-circle"
      headerTitle="Booking confirmed"
      onHeaderRightIconPress={onClose}
    >
      <ScrollView style={{ paddingHorizontal: 20 }}>
        {spaceDetailLoading || loading ? (
          <View style={{ padding: 120 }}>
            <Spinner />
          </View>
        ) : !!space && !!order && !!booking ? (
          <OrderDetailsMobile
            booking={booking}
            order={order}
            space={space}
            monthlyBilling={monthlyBilling}
            onSendInvitesComplete={refetch}
            spaceDetailLoading={spaceDetailLoading}
            orderDetailLoading={loading}
          />
        ) : null}
      </ScrollView>
    </DialogContent>
  );
}

interface OrderDetailsProps {
  order: NonNullable<OffsiteOrderPage__OrderDetailsQuery["order"]>;
  space: SpaceDetails__AllDetailsFragment;
  booking: HourlyBooking | DailyBooking;
  monthlyBilling: boolean;
  onSendInvitesComplete: () => void;
  isVisible?: boolean;
  spaceDetailLoading: boolean;
  orderDetailLoading: boolean;
}

function OrderDetailsMobile(props: OrderDetailsProps) {
  const { order, space, booking, monthlyBilling, onSendInvitesComplete } =
    props;
  const postBookingFeatureFlag = usePostBookingsFeatureFlag();

  const searchValue = useMemo((): SearchValue => {
    return {
      dateRange: getDateRangeFromBooking(booking),
    };
  }, [booking]);

  const { openAt } = useBookingDateRangePickerHelper({
    space,
  });

  const {
    data: otherSpacesData,
    loading: otherSpacesLoading,
    error: otherSpacesError,
  } = useQuery<
    OffsiteOrderOtherSpacesQuery,
    OffsiteOrderOtherSpacesQueryVariables
  >(offsiteOrderOtherSpacesQuery, {
    variables: {
      offsiteLocationID: order?.orderItems[0].details.space.location.id,
    },
    skip: !order,
  });

  const { startDate, endDate, startTime, endTime } = getDateTime(
    searchValue.dateRange,
  );

  return (
    <View testID="order-confirmation">
      <Spacer size={16} />
      <BookingPreviewV2
        confirmationId={order.confirmationID || undefined}
        memo={order.memo}
        searchValue={searchValue}
        space={space}
      />
      <Divider />
      <Spacer size={16} />

      <BookingDetailInviteBanner
        remainingInvites={space.maxCapacity - order.invitees.length}
        invitees={order.invitees}
        bookingId={order.id}
        spaceId={space.id}
        spaceName={space.name}
        spaceType={space.spaceType}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        onInviteComplete={onSendInvitesComplete}
      />
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      <View>
        <TextV2 size="md" weight="semibold">
          Price breakdown
        </TextV2>
        <Spacer size={10} />
        <OrderBreakdown
          currency={order.currency}
          quote={{
            totalPrice: order.totalPrice,
            subTotal: order.subTotal,
            processingFee: order.processingFee,
            promotionLine: order.promotionLine,
            orderItems: order.orderItems,
            taxLine: order?.taxLine,
          }}
        />
      </View>
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      {monthlyBilling && (
        <>
          <View>
            <TextV2 weight="semibold" size="md">
              Payment
            </TextV2>
            <Spacer size={16} />
            <Text>Invoiced to your organization.</Text>
          </View>
        </>
      )}

      {order.paymentDetail?.paymentMethodDetails && !monthlyBilling && (
        <Fragment>
          <View>
            <BookingDetailPaymentInfo
              monthlyBilling={monthlyBilling}
              paymentDetail={order.paymentDetail}
              totalPrice={formatCurrency(
                order.totalPrice,
                getSystemLocale(),
                order.currency,
              )}
            />
          </View>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <View>
            <Text size="sm">
              {getCheckoutCancellationPolicyText(
                space.bookingPolicy,
                space.location.timezone,
                openAt,
                searchValue.dateRange,
              )}
            </Text>
          </View>
        </Fragment>
      )}

      {postBookingFeatureFlag && (
        <AsyncRenderer
          error={otherSpacesError}
          loading={otherSpacesLoading}
          errorHandler={() => <></>}
          data={otherSpacesData}
        >
          {() => {
            const enhanceYourWorkDaySpaces = getEnhanceWorkDaySpaces(
              otherSpacesData,
              order?.orderItems[0].details.space.spaceType,
            );
            if (enhanceYourWorkDaySpaces?.length) {
              return (
                <View>
                  <Spacer size={24} />
                  <Divider />
                  <Spacer size={24} />
                  <TextV2 size="md" weight="semibold">
                    Enhance your work day
                  </TextV2>
                  <Spacer size={16} />
                  {enhanceYourWorkDaySpaces.map((space) => (
                    <EnhanceYourWorkDaySpaceCardMobile
                      space={space}
                      key={space.id}
                    />
                  ))}
                </View>
              );
            }
            return <></>;
          }}
        </AsyncRenderer>
      )}

      <Spacer size={40} />
    </View>
  );
}
