import { ApolloError, gql, useQuery } from "@apollo/client";
import {
  GetPopularLocationQuery,
  GetPopularLocationQueryVariables,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import { useHomeSearchParamsQuery } from "pages/homev2/hooks/use_home_search_params";
import { spaceLocationDetailFragment } from "pages/homev3/fragment";
import {
  MAX_FETCH_MORE_COUNT,
  RADIUS_INCREASE,
} from "pages/homev3/hooks/use_space_nearby_query";
import { useEffect, useMemo, useState } from "react";
import { toCoordinates } from "../../homev2/mapbox";
import { useUserCurrentLocation } from "./use_user_current_location";

interface PopularLocationData {
  __typename?: "Query";
  locations: SpaceLocationDetailFragment[];
}
interface PopularLocationReturn {
  data: PopularLocationData;
  loading: boolean;
  error: ApolloError | undefined;
}

type PopularLocationProps = {
  radius?: number;
  limit?: number;
  skip?: boolean;
};
export function usePopularLocationQuery({
  radius,
  limit = 15,
  skip = false,
}: PopularLocationProps): PopularLocationReturn {
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useUserCurrentLocation();

  const { coordinates } = useHomeSearchParamsQuery();
  const [fetchCount, setFetchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const variables: GetPopularLocationQueryVariables | null = useMemo(() => {
    if (skip || !currentUserCoordinates || currentUserLoading) {
      return null;
    }
    return {
      centerMapCoordinates:
        coordinates || toCoordinates(currentUserCoordinates.center),
      currentUserCoordinates: toCoordinates(currentUserCoordinates.center),
      limit,
      radius: radius || RADIUS_INCREASE * (fetchCount + 1),
    };
  }, [
    coordinates,
    currentUserCoordinates,
    currentUserLoading,
    fetchCount,
    limit,
    radius,
    skip,
  ]);
  const { data, loading, error } = useQuery<
    GetPopularLocationQuery,
    GetPopularLocationQueryVariables
  >(getPopularLocation, {
    variables: variables!,
    skip: !variables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      if (
        !radius &&
        data.popularLocations.length <= limit &&
        fetchCount < MAX_FETCH_MORE_COUNT
      ) {
        setFetchCount((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
      }
    }
    if (error) {
      setIsLoading(false);
    }
  }, [data, fetchCount, limit, radius, loading, skip, error]);

  return useMemo(
    () => ({
      data: {
        __typename: "Query",
        locations: data?.popularLocations || [],
      },
      loading: isLoading,
      error,
    }),
    [data?.popularLocations, error, isLoading],
  );
}

const getPopularLocation = gql`
  ${spaceLocationDetailFragment}
  query GetPopularLocation(
    $centerMapCoordinates: SpaceSearchCoordinates
    $currentUserCoordinates: SpaceSearchCoordinates
    $limit: Int
    $radius: Int
  ) {
    popularLocations(
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      radius: $radius
    ) {
      ...SpaceLocationDetail
    }
  }
`;
