import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { Fragment, ReactElement, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

interface OrganizationProfilePageCardProps {
  title: string;
  value: string | number | ReactElement;
  action?: ReactNode;
}

interface OrganizationProfileCardWrapperProps {
  title: string;
  children: ReactNode;
}

export function OrganizationProfileCardWrapper(
  props: OrganizationProfileCardWrapperProps,
) {
  const { title, children } = props;
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return (
      <View style={[styles.wrapper, styles.wrapperMobile]}>
        <View style={{ flex: 1 }}>{children}</View>
      </View>
    );
  }
  return (
    <View style={styles.wrapper}>
      <View style={{ minWidth: 180 }}>
        <Spacer size={24} />
        <Text weight="semibold" size="md">
          {title}
        </Text>
      </View>
      <Spacer direction="row" size={56} />
      <View style={{ flex: 1 }}>{children}</View>
    </View>
  );
}

export function OrganizationProfileCard(
  props: OrganizationProfilePageCardProps,
) {
  const { title, value, action } = props;
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return (
      <View style={styles.cardMobile}>
        <Text weight="semibold" size="xs">
          {title}
        </Text>
        <Spacer size={12} />

        <Text size="xs" color="black-70">
          {value}
        </Text>
        {!!action && (
          <Fragment>
            <Spacer size={12} />
            {action}
          </Fragment>
        )}
      </View>
    );
  }
  return (
    <View style={styles.card}>
      <Text weight="semibold" size="xs">
        {title}
      </Text>
      <Spacer size={8} />
      <View style={styles.valueActionContainer}>
        <Text size="xs" color="black-70">
          {value}
        </Text>
        {action}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  valueActionContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  wrapper: {
    backgroundColor: colors.brand.whitecore,
    borderRadius: 4,
    shadowColor: colors.brand.blackcore,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    paddingLeft: 24,
    flexDirection: "row",
  },
  wrapperMobile: {
    paddingLeft: 0,
  },
  card: {
    padding: 24,
  },
  cardMobile: {
    padding: 16,
  },
});
