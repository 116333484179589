import { Image } from "components/image";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { TeamsSpaceDetailsFragment } from "core/graphql.generated";
import { getSpaceType } from "lib/space_type_utils";
import { SpaceCardAddressWithDistance } from "pages/homev2/components/space_card_v2/space_card_template";
import React from "react";
import { StyleSheet, View } from "react-native";

type LocationBoxProps = {
  space: TeamsSpaceDetailsFragment;
  startTime?: string | null;
  endTime?: string | null;
};
export const LocationBox = ({
  space,
  startTime,
  endTime,
}: LocationBoxProps) => {
  let textSpaceTypeAtTime = `${getSpaceType(space.spaceType)}`;
  if (startTime && endTime) {
    textSpaceTypeAtTime = `${space.spaceType} · ${startTime} - ${endTime}`;
  }

  return (
    <View style={styles.locationView}>
      <Image
        source={{
          uri: "/images/flexspace_icon.svg",
          width: 16,
          height: 16,
        }}
      />
      <View>
        <Text size={"xs"} weight={"semibold"}>
          {space.name}
        </Text>
        <Spacer size={4} />
        <Text size={"xs"}>{textSpaceTypeAtTime}</Text>
        <Spacer size={4} />
        <SpaceCardAddressWithDistance
          currentUserDistance={space.location.currentUserDistance}
          shortAddress={space.location.address.city}
          fullAddress={space.location.address.streetAddress}
          locationName={space.location.name}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  locationView: {
    flexDirection: "row",
    gap: 8,
    flex: 1,
  },
});
