import { gql, useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";
import { AuthPageContainer } from "components/auth_page_container";
import { colors } from "components/colors";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { OktaButton } from "components/okta_button";
import { Row } from "components/row";
import { TermsAndPolicy } from "components/sign_up_shared/terms_and_policy";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { GoogleButton } from "core/google_button";
import {
  OrgBySlugQuery,
  OrgBySlugQueryVariables,
} from "core/graphql.generated";
import { Footer } from "core/new_footer";
import { OnboardingHeader } from "core/onboarding_header";
import { ROUTES } from "core/routes";
import { useMediaQuery } from "lib/media_query";
import { LAST_SIGN_IN_LOCALSTORAGE_KEY } from "pages/sign_in/sign_in";
import { useAuthV2 } from "providers/authv2";
import { useEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";

export enum SourceFrom {
  SIGN_UP = "SIGN_UP",
  LOG_IN = "LOG_IN",
}

type LocationState = {
  orgData?: OrgBySlugQuery["organizationBySlug"];
  sourceFrom?: SourceFrom;
};

export function CustomOrgSignIn() {
  const { state, pathname } = useLocation<LocationState>();

  const history = useHistory();
  const { data, loading, error } = useQuery<
    OrgBySlugQuery,
    OrgBySlugQueryVariables
  >(orgBySlugQuery, {
    variables: { slug: pathname?.split("/")[1] },
    skip: !pathname?.split("/")[1] || !!state?.orgData,
  });

  if (state && state?.orgData) {
    return (
      <CustomOrgSignInContent
        orgData={state.orgData}
        sourceFrom={state.sourceFrom}
      />
    );
  }

  if (!loading && !data) {
    // if with the slug, we can't find the org, redirect to sign in page
    history.replace(ROUTES.SIGN_IN.path);
    return null;
  }

  return (
    <AsyncRenderer error={error} loading={loading} data={data}>
      {(data) => <CustomOrgSignInContent orgData={data.organizationBySlug} />}
    </AsyncRenderer>
  );
}

type SlugContentProps = {
  orgData: OrgBySlugQuery["organizationBySlug"];
  sourceFrom?: SourceFrom;
};

function CustomOrgSignInContent({ orgData, sourceFrom }: SlugContentProps) {
  const mq = useMediaQuery();
  const { logInWithOkta, logInWithGoogle } = useAuthV2();

  const isMobile = mq.deviceQuery.mobile;

  const isUserComeback = localStorage.getItem(LAST_SIGN_IN_LOCALSTORAGE_KEY);
  const [isLoginPage, SetIsLoginPage] = useState<boolean>(!!isUserComeback);

  useEffect(() => {
    localStorage.setItem(LAST_SIGN_IN_LOCALSTORAGE_KEY, Date.now().toString());
  }, []);

  if (!orgData) {
    return null;
  }
  const hasGoogleLogin = orgData.ssoProvider?.includes("Google");
  const hasOktaLogin = orgData.ssoProvider?.includes("Okta");

  return (
    <AuthPageContainer
      header={<OnboardingHeader />}
      footer={
        <Footer
          showFullText
          withTermsAndPrivacy={
            !(!isMobile && (sourceFrom === SourceFrom.SIGN_UP || !isLoginPage))
          }
        />
      }
    >
      <View
        style={[styles.mainContainer, !isMobile && styles.mainContainerDesktop]}
      >
        <View>
          <Row alignItems={"center"} justifyContent={"center"}>
            {orgData.logoUrl && (
              <Image
                source={{
                  uri: orgData.logoUrl,
                  width: isMobile ? 120 : 400,
                  height: isMobile ? 32 : 120,
                }}
              />
            )}
          </Row>
          <Spacer size={40} />

          {sourceFrom ? (
            <>
              <Heading size={isMobile ? "lg" : "mega"} align="center">
                Looks like you’re part of {orgData.name}
              </Heading>
              <Spacer size={20} />
              <Text size="xs" align="center">
                {orgData.name} requires you to continue with{" "}
                {orgData.ssoProvider?.join(" or ")} to join your team on
                Flexspace.
              </Text>
            </>
          ) : (
            <View>
              <Heading size="mega" align="center">
                {isLoginPage
                  ? "Welcome back !"
                  : `Join ${orgData.name} on Flexspace`}
              </Heading>
              <Spacer size={20} />
              <View style={styles.contentView}>
                <Text size="xs" align="center">
                  Flexspace provides exclusive on-demand access to hundreds of
                  inspiring workspaces for you and your team to work IRL.
                </Text>
              </View>
            </View>
          )}
        </View>

        {!isMobile && <Spacer size={88} />}

        <View>
          {(!isLoginPage || sourceFrom === SourceFrom.SIGN_UP) && (
            <View style={styles.termPolicyView}>
              <TermsAndPolicy />
              <Spacer size={16} />
            </View>
          )}

          {hasOktaLogin && (
            <Content maxWidth={383}>
              <OktaButton
                onPress={() =>
                  logInWithOkta(orgData.connectionName || "FlexspaceConnect")
                }
              />
              <Spacer size={16} />
            </Content>
          )}
          {hasGoogleLogin && (
            <Content maxWidth={383}>
              <GoogleButton onPress={logInWithGoogle} />
              <Spacer size={16} />
            </Content>
          )}

          {!sourceFrom && (
            <Text size={"xs"} align={"center"}>
              {isLoginPage ? "New to Flexspace? " : "Already have an account? "}
              <Text
                color="eggplant-core"
                size="xs"
                weight={"semibold"}
                decoration={"underline"}
                onPress={() => SetIsLoginPage(!isLoginPage)}
              >
                {isLoginPage ? "Sign up" : "Log in"}
              </Text>
            </Text>
          )}
        </View>
      </View>
    </AuthPageContainer>
  );
}

const styles = StyleSheet.create({
  buttonWrapper: {
    width: "95%",
    maxWidth: 343,
    alignSelf: "center",
  },
  buttonDesktopWrapper: {
    width: 220,
  },
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 16,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  googleButtonWrapper: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.brand.googleBlue,
    backgroundColor: "white",
  },
  termPolicyView: {
    maxWidth: 343,
    alignSelf: "center",
  },
  contentView: {
    maxWidth: 444,
    alignSelf: "center",
  },
});

export const orgBySlugQuery = gql`
  query orgBySlug($slug: String!) {
    organizationBySlug(slug: $slug) {
      id
      name
      requireSSO
      ssoProvider
      logoUrl
      slug
      connectionName
    }
  }
`;
