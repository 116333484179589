import React, { useCallback, useEffect, Fragment, useReducer } from "react";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { View, StyleSheet } from "react-native";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";
import { colors } from "components/colors";
import { FilterCapacityPicker } from "core/header_filter/filter_capacity_picker";
import { capacityOptions } from "core/header_filter/filter";
import { SpaceTypePicker } from "core/space_type_picker";
import { useMediaQuery } from "lib/media_query";
import { Button } from "components/button_v2";
import { useReverseGeocodingQuery } from "../hooks/use_reserve_geocoding";
import { EmptySpacesDatePicker } from "core/header_filter/empty_spaces_date_picker";
import { EmptySpacesMobileDatePicker } from "core/header_filter/empty_spaces_mobile_date_picker";
import { usePrevious } from "hooks/use_previous";
import { useMutation, gql, useQuery } from "@apollo/client";
import {
  EmptyStateTileBQuery,
  SendSupportTicketMutation,
  SendSupportTicketMutationVariables,
  SpaceType,
} from "core/graphql.generated";
import { useEmptyStateGroupAFeatureFlag } from "providers/splitio";
import { useAnalytics } from "providers/analytics";
import { parseTime } from "lib/time_utils";
import { format, isBefore } from "date-fns";
import { TextField } from "components/text_field";
import { TextLink } from "components/text_link";
import { parseDay, toDay } from "lib/day_utils";

interface EmptyStateGrouBTileProps {
  title?: string;
  subTitle?: string;
  onFindMeASpacePress?: () => void;
}

interface EmptyStateTileProps {
  onFindMeASpacePress?: () => void;
}

export function EmptyStateTile(props: EmptyStateTileProps) {
  const { onFindMeASpacePress } = props;
  const emptyStateGroupAFeatureFlag = useEmptyStateGroupAFeatureFlag();

  if (emptyStateGroupAFeatureFlag) {
    return <EmptyStateGroupATile />;
  } else {
    return <EmptyStateGroupBTile onFindMeASpacePress={onFindMeASpacePress} />;
  }
}

interface EmptyStateFormState {
  submit: boolean;
  description: string;
  localFilter: any;
  userName: string;
  email: string;
  userNameError: boolean;
  emailError: boolean;
}

type Action =
  | { type: "SET_SUBMIT"; payload: boolean }
  | { type: "SET_DESCRIPTION"; payload: string }
  | { type: "SET_LOCAL_FILTER"; payload: any }
  | { type: "SET_USER_NAME"; payload: string }
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_USER_NAME_ERROR"; payload: boolean }
  | { type: "SET_EMAIL_ERROR"; payload: boolean }
  | {
      type: "SET_USER";
      payload: {
        userName: string;
        email: string;
      };
    };

function reducer(
  state: EmptyStateFormState,
  action: Action,
): EmptyStateFormState {
  switch (action.type) {
    case "SET_SUBMIT":
      return { ...state, submit: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "SET_LOCAL_FILTER":
      return { ...state, localFilter: action.payload };
    case "SET_USER":
      return {
        ...state,
        userName: action.payload.userName,
        email: action.payload.email,
      };
    case "SET_USER_NAME":
      return { ...state, userName: action.payload };
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_USER_NAME_ERROR":
      return { ...state, userNameError: action.payload };
    case "SET_EMAIL_ERROR":
      return { ...state, emailError: action.payload };
    default:
      return state;
  }
}

export function EmptyStateGroupBTile(props: EmptyStateGrouBTileProps) {
  const { title, subTitle, onFindMeASpacePress } = props;
  const searchParams = useHomeSearchParamsQuery();
  const previousSearchParams = usePrevious(searchParams);
  const analytics = useAnalytics();
  const { coordinates, amenities, startTime, endTime } = searchParams;
  const { data } = useReverseGeocodingQuery(coordinates);
  const { data: userData } = useQuery<EmptyStateTileBQuery>(emptyStateGqlQuery);

  const [state, dispatch] = useReducer(reducer, {
    submit: false,
    description: "",
    localFilter: searchParams,
    userName: "",
    email: "",
    userNameError: false,
    emailError: false,
  });

  const {
    submit,
    description,
    localFilter,
    userName,
    email,
    userNameError,
    emailError,
  } = state;

  const mq = useMediaQuery();

  const onDescriptionChange = useCallback((value: string) => {
    dispatch({ type: "SET_DESCRIPTION", payload: value });
  }, []);

  const onUserNameChange = useCallback(
    (value: string) => {
      dispatch({ type: "SET_USER_NAME", payload: value });
      if (userNameError) {
        dispatch({ type: "SET_USER_NAME_ERROR", payload: false });
      }
    },
    [userNameError],
  );

  const onEmailChange = useCallback(
    (value: string) => {
      dispatch({ type: "SET_EMAIL", payload: value });
      if (emailError) {
        dispatch({ type: "SET_EMAIL_ERROR", payload: false });
      }
    },
    [emailError],
  );

  useEffect(() => {
    const isParamsChanged = previousSearchParams !== searchParams;
    if (isParamsChanged && submit) {
      dispatch({ type: "SET_SUBMIT", payload: false });
    }
  }, [previousSearchParams, searchParams, submit]);

  useEffect(() => {
    if (
      userData?.currentUser &&
      userData.currentUser.fullName &&
      userData.currentUser.email
    ) {
      dispatch({
        type: "SET_USER",
        payload: {
          userName: userData.currentUser.fullName,
          email: userData.currentUser.email,
        },
      });
    }
  }, [userData?.currentUser]);

  const [sendSupportTicketMutation, { loading }] = useMutation<
    SendSupportTicketMutation,
    SendSupportTicketMutationVariables
  >(sendNoSpaceFoundSupportTicketMutation);

  const sendSupportTicket = useCallback(async () => {
    if (!userName || !email) {
      dispatch({ type: "SET_USER_NAME_ERROR", payload: !userName });
      dispatch({ type: "SET_EMAIL_ERROR", payload: !email });
      return;
    }

    if (
      onFindMeASpacePress &&
      localFilter.date &&
      isBefore(parseDay(localFilter.date), parseDay(toDay(new Date())))
    ) {
      onFindMeASpacePress();
      return;
    }

    analytics.event("Concierge click on Find me a space", {
      Type: "Tile",
    });

    dispatch({ type: "SET_SUBMIT", payload: true });

    let content = `Space Type: ${localFilter.spaceType || "Any"},`;
    content += `\nDate: ${localFilter.date ?? "Any"},`;

    if (localFilter.spaceType !== SpaceType.DayPass) {
      content += `\nAttendees: ${
        capacityOptions.find((co) => co.value.min === localFilter.minCapacity)
          ?.label ?? "Any"
      },`;
    }

    if (localFilter.spaceType === SpaceType.MeetingRoom) {
      content += `\nStart Time: ${
        startTime ? format(parseTime(startTime), "h:mm a") : "Not specified"
      },`;

      content += `\nEnd Time: ${
        endTime ? format(parseTime(endTime), "h:mm a") : "Not specified"
      },`;
    }

    if (amenities?.length) {
      content += `\nAmenities: ${amenities.join(", ")}`;
    }

    if (userName) {
      content += `\n\n\n\nUser Name: ${userName}`;
    }

    if (email) {
      content += `\nEmail: ${email}`;
    }

    if (description?.length) {
      content += `\nDescription: ${description}`;
    }

    await sendSupportTicketMutation({
      variables: {
        topic: "find_space",
        subject: `No space found in ${data?.place_name ?? "that place"}`,
        content,
      },
    });

    dispatch({ type: "SET_DESCRIPTION", payload: "" });
  }, [
    localFilter.date,
    localFilter.spaceType,
    localFilter.minCapacity,
    analytics,
    amenities,
    description,
    sendSupportTicketMutation,
    data?.place_name,
    onFindMeASpacePress,
    startTime,
    endTime,
    email,
    userName,
  ]);

  const handleChangeSpaceType = useCallback(
    (value) => {
      dispatch({
        type: "SET_LOCAL_FILTER",
        payload: { ...localFilter, spaceType: value },
      });
    },
    [localFilter],
  );

  const handleChangeDate = useCallback(
    (date: string) => {
      dispatch({
        type: "SET_LOCAL_FILTER",
        payload: {
          ...localFilter,
          date: date,
          startTime: date ? localFilter.startTime : undefined,
          endTime: date ? localFilter.endTime : undefined,
        },
      });
    },
    [localFilter],
  );

  const handleChangeCapacity = useCallback(
    (label: string | undefined) => {
      const selected = capacityOptions.find((co) => co.label === label);
      dispatch({
        type: "SET_LOCAL_FILTER",
        payload: {
          ...localFilter,
          minCapacity: selected?.value.min,
          maxCapacity: selected?.value.max,
        },
      });
    },
    [localFilter],
  );

  return (
    <View style={styles.innerContainer}>
      {!submit ? (
        <View>
          <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
            {title ||
              `We’re coming to ${data?.place_name ?? "that place"} soon!`}
          </Text>
          <Spacer size={8} />
          <Text size="xs">
            {subTitle ||
              "Need a space ASAP? Our team will personally find a space for you, even if they aren't on Flexspace yet."}
          </Text>
          <Spacer size={24} />
          <View style={styles.searchHelp}>
            <View
              style={[
                styles.filters,
                mq.deviceQuery.mobile && styles.filtersMobile,
              ]}
            >
              {userData && !userData.currentUser?.fullName && (
                <Fragment>
                  <TextField
                    value={userName}
                    onChange={onUserNameChange}
                    label="Your Name"
                    inputPlaceholder="John Doe"
                    invalid={userNameError}
                    invalidText={
                      userNameError ? "Please enter your name" : undefined
                    }
                  />
                  <Spacer size={24} />
                </Fragment>
              )}

              {userData && !userData.currentUser?.email && (
                <Fragment>
                  <TextField
                    value={email}
                    onChange={onEmailChange}
                    label="Your Email"
                    inputPlaceholder="john.doe@example.com"
                    invalid={emailError}
                    invalidText={
                      emailError ? "Please enter your email" : undefined
                    }
                  />
                  <Spacer size={24} />
                </Fragment>
              )}
              <SpaceTypePicker
                value={localFilter.spaceType || "any"}
                onChange={handleChangeSpaceType}
              />
              <Spacer size={24} />
              {mq.sizeQuery.mdAndUp ? (
                <EmptySpacesDatePicker
                  value={localFilter.date}
                  onChange={handleChangeDate}
                />
              ) : (
                <EmptySpacesMobileDatePicker
                  value={localFilter.date}
                  onChange={handleChangeDate}
                />
              )}
              {localFilter.spaceType !== SpaceType.DayPass && (
                <Fragment>
                  <Spacer size={24} />
                  <FilterCapacityPicker
                    value={
                      capacityOptions.find(
                        (co) => co.value.min === localFilter.minCapacity,
                      )?.label
                    }
                    capacityOptions={capacityOptions}
                    onChange={handleChangeCapacity}
                    appearance="default"
                    testID="empty-space-capacity-picker"
                  />
                </Fragment>
              )}
            </View>
            {mq.deviceQuery.desktop && (
              <View style={styles.image}>
                <img
                  style={{
                    objectFit: "cover",
                  }}
                  src="/images/empty-spaces.png"
                />
              </View>
            )}
          </View>
          <Spacer size={24} />
          <TextField
            value={description}
            onChange={onDescriptionChange}
            label="Tell us a bit more about what you’re looking for (optional)"
            inputPlaceholder="Add description"
            numberOfLines={3}
          />
          <Spacer size={24} />

          <View
            style={[
              styles.button,
              mq.deviceQuery.mobile && styles.buttonMobile,
            ]}
          >
            <Button
              loading={loading}
              onPress={sendSupportTicket}
              text="Find me a space"
            />
          </View>
        </View>
      ) : (
        <View>
          <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
            We’re on it! A Flexspace team member will reach out to you shortly.
          </Text>
          <Spacer size={16} />
          <Text size="xs">
            We’ll contact you at {userData?.currentUser?.email} and help you
            find a space in {data?.place_name ?? "your area"}.
          </Text>
        </View>
      )}
    </View>
  );
}

export function EmptyStateGroupATile() {
  const mq = useMediaQuery();

  return (
    <View testID="empty-spaces-container">
      <View testID="empty-spaces-tile-a" style={styles.innerContainer}>
        <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
          Explore spaces around the world
        </Text>
        <Spacer size={16} />
        <Text size="xs">
          <Text size="xs" weight="semibold">
            Search
          </Text>{" "}
          for a city, region, or neighborhood
        </Text>
        <Spacer size={16} />
        <Text size="xs">
          <Text size="xs" weight="semibold">
            Move
          </Text>{" "}
          around on the map to explore nearby spaces
        </Text>
        <Spacer size={16} />
        <Text size="xs">
          <Text size="xs" weight="semibold">
            Filter
          </Text>{" "}
          to see workspaces available at specific times or with a specific
          capacity
        </Text>
      </View>
    </View>
  );
}

interface EmptyStatePassedDateTileProps {
  onPress?: () => void;
}
export function EmptyStatePassedDateTile(props: EmptyStatePassedDateTileProps) {
  const { onPress } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <View testID="empty-spaces-container">
      <View
        testID="empty-spaces-passed-date-tile"
        style={styles.innerContainer}
      >
        <Text size="xs" weight="semibold">
          Looks like you are finding a space that is in the past.{" "}
          <TextLink
            size="xs"
            decoration="underline"
            text="Please click here to refresh your search."
            onPress={onPress}
            inline
          />
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  innerContainer: {
    padding: 24,
    backgroundColor: colors.brand.eggplantMinus90,
  },
  filters: {
    width: 280,
  },
  filtersMobile: {
    width: "100%",
  },
  searchHelp: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    width: 240,
  },
  buttonMobile: {
    width: "100%",
  },
  image: {
    flex: 1,
    maxWidth: 330,
  },
});

const sendNoSpaceFoundSupportTicketMutation = gql`
  mutation SendSupportTicket(
    $topic: String!
    $subject: String!
    $content: String!
  ) {
    sendSupportTicket(topic: $topic, subject: $subject, content: $content)
  }
`;

const emptyStateGqlQuery = gql`
  query EmptyStateTileB {
    currentUser {
      id
      fullName
      email
    }
  }
`;
