import { Image, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

import { Button } from "components/button_v2";
import { Chip } from "components/chip";
import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import { Bookings__OrderDetailsFragment } from "core/graphql.generated";
import { useAnalytics } from "providers/analytics";

import { BookingCard } from "./booking_card";
import { BookingDetails } from "./booking_details";
import { transformArchiveBooking } from "../bookings_utils";

export interface ArchiveBookingProps {
  details: Bookings__OrderDetailsFragment;
}

export function ArchiveBooking({ details }: ArchiveBookingProps) {
  const mq = useMediaQuery();
  const history = useHistory();
  const analytics = useAnalytics();

  const {
    thumbnailUrl,
    dateRange,
    spaceName,
    spaceType,
    confirmationId,
    canBookAgain,
    isCancelled,
    spaceId,
  } = transformArchiveBooking(details);

  const handleClickBookAgain = () => {
    history.push(`/offsite_spaces/${spaceId}`);
    analytics.event("Click Book Again", {
      category: "Post booking",
      "Space UUID": spaceId,
      "Space Name": spaceName,
      Source: "Booking List",
    });
  };

  return (
    <BookingCard>
      <View style={mq.deviceQuery.mobile ? styles.rootMobile : styles.root}>
        <View
          style={
            mq.deviceQuery.mobile
              ? styles.thumbnailWrapperMobile
              : styles.thumbnailWrapper
          }
        >
          <Image
            style={
              mq.deviceQuery.mobile ? styles.thumbnailMobile : styles.thumbnail
            }
            source={{ uri: thumbnailUrl }}
          />
        </View>
        <View
          style={
            mq.deviceQuery.mobile
              ? styles.detailsWrapperMobile
              : styles.detailsWrapper
          }
        >
          <View>
            {isCancelled && (
              <>
                <Chip text="Cancelled" appearance="danger" />
                <Spacer size={8} />
              </>
            )}
            <BookingDetails
              dateRange={dateRange}
              spaceName={spaceName}
              spaceType={spaceType}
              confirmationId={confirmationId}
            />
          </View>
          {canBookAgain && (
            <Button
              text="Book again"
              appearance="secondary"
              onPress={handleClickBookAgain}
            />
          )}
        </View>
      </View>
    </BookingCard>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
  },
  rootMobile: {
    flexDirection: "column",
  },
  thumbnailWrapper: {
    width: "12%",
    height: "inherit",
  },
  thumbnailWrapperMobile: {
    width: "100%",
    height: 160,
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  thumbnailMobile: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  detailsWrapper: {
    width: "88%",
    padding: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsWrapperMobile: {
    width: "100%",
    padding: 16,
    flexDirection: "column",
    gap: 16,
  },
});
