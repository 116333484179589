import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { format } from "date-fns";
import { parseTime } from "lib/time_utils";

import { DialogModal } from "components/dialog_modal";
import { Text } from "components/text_v2";
import { Button } from "components/button_v2";
import { useForm } from "hooks/use_form";
import { Spacer } from "components/spacer";
import { TextInput } from "components/text_input";
import { useMediaQuery } from "lib/media_query";
import { parseDay } from "lib/day_utils";

export interface BookingResquestDataType {
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  reason: string;
}

interface BookingRequestDialogFormErrors {
  reason?: string;
}

interface PropType {
  isVisible: boolean;
  onClose: () => void;
  initialValues: BookingResquestDataType;
  onSubmit: (values: BookingResquestDataType) => Promise<void>;
  submitText: string;
}

export const BookingRequestDialog: React.FC<PropType> = ({
  isVisible,
  onClose,
  initialValues,
  onSubmit: onSubmitProp,
  submitText,
}) => {
  const mq = useMediaQuery();

  const [loading, setLoading] = useState(false);
  const { values, setFieldValue, errors, isDirty, submit } = useForm({
    initialValues,
    reinitialize: true,
    validate: (values) => {
      const errors: BookingRequestDialogFormErrors = {};
      if (values.reason.trim().length === 0) {
        errors.reason = "Please enter a reason";
      }
      return errors;
    },
    validateOnChange: true,
    onSubmit: async () => {
      setLoading(true);
      await onSubmitProp(values);
      onClose();
      setLoading(false);
    },
  });

  return (
    <DialogModal
      isVisible={isVisible}
      onClose={onClose}
      headerTitle="Out-of-policy request"
      desktopWidth={780}
      bottomButtons={
        <Button
          testID="request-to-book-submit-button"
          text={submitText}
          size={mq.deviceQuery.mobile ? "auto" : "medium"}
          disabled={!!errors.reason || !isDirty}
          loading={loading}
          onPress={submit}
        />
      }
    >
      <View>
        <Text size="xs">
          Your organization requires that out-of-policy bookings need to be
          approved. Submit your request for your administrators to review.
        </Text>
        <View style={styles.section}>
          <Text size="xs" weight="semibold">
            Preferred booking date
          </Text>
          <Text size="xs" color="black-70">
            {format(parseDay(initialValues.startDate), "MMMM do y")}
            {initialValues.endDate !== initialValues.startDate &&
              ` - ${format(parseDay(initialValues.endDate), "MMMM do y")}`}
          </Text>
        </View>

        {!!initialValues.startTime && !!initialValues.endTime && (
          <View style={styles.section}>
            <Text size="xs" weight="semibold">
              Preferred booking time
            </Text>
            <Text size="xs" color="black-70">
              {format(parseTime(initialValues.startTime), "h:mm a")} -{" "}
              {format(parseTime(initialValues.endTime), "h:mm a")}
            </Text>
          </View>
        )}

        <View style={styles.section}>
          <Text size="xs" weight="semibold">
            Reason for request
          </Text>
          <TextInput
            testID="add-a-reason-textarea"
            value={values.reason}
            onChange={(value) => setFieldValue("reason", value)}
            numberOfLines={4}
            placeholder="Please add a reason why you want to book this space."
          />
        </View>
        <Spacer size={100} />
      </View>
    </DialogModal>
  );
};

const styles = StyleSheet.create({
  section: {
    marginTop: 24,
    gap: 8,
  },
});
