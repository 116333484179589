import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { OrganizationUser } from "core/graphql.generated";
import { CreateEditBudgetCard } from "pages/budgets/components/create_edit_budget_card";
import { PeoplePicker } from "pages/budgets/components/people_picker";
import { Fragment } from "react";
import {
  CreateEditBudgetFormErrors,
  GroupBudgetFormValues,
} from "../group_budget_create";

interface CreateEditBudgetFormProps {
  values: GroupBudgetFormValues;
  setFieldValue: (
    field: keyof GroupBudgetFormValues,
    value: string | number | OrganizationUser[] | undefined,
  ) => void;
  errors: CreateEditBudgetFormErrors;
  showPeopleCard: boolean;
}
export function CreateEditBudgetForm(props: CreateEditBudgetFormProps) {
  const { values, errors, setFieldValue, showPeopleCard } = props;

  return (
    <Fragment>
      <CreateEditBudgetCard title="Basics">
        <TextField
          value={values.name || ""}
          label="Budget name*"
          inputPlaceholder="e.g. Marketing"
          onChange={(value) => setFieldValue("name", value)}
          invalid={!!errors.name}
          invalidText={errors.name}
          testID="group-budget-name-input"
        />
        <Spacer size={24} />
        <TextField
          value={values.description || ""}
          label="Description"
          inputPlaceholder="e.g. This budget is used to track the Marketing team’s spend."
          onChange={(value) => setFieldValue("description", value)}
          testID="group-budget-description-input"
        />
      </CreateEditBudgetCard>
      <CreateEditBudgetCard title="Spend limits">
        <TextField
          value={values.limit || values.limit === 0 ? String(values.limit) : ""}
          label="What is the monthly spend limit for this budget?*"
          topDescription="Set an amount that can be spent by people in this budget. People can spend from all available money in the spend limit."
          leftIcon={
            <Text color={errors.limit ? "grapefruit-core" : "black-core"}>
              $
            </Text>
          }
          onChange={(value) => setFieldValue("limit", parseInt(value, 10))}
          keyboardType="numeric"
          invalid={!!errors.limit}
          invalidText={errors.limit}
          testID="group-budget-limit-input"
        />
        <Spacer size={24} />
        <TextField
          value={
            values.individualLimit || values.individualLimit === 0
              ? String(values.individualLimit)
              : ""
          }
          label="How much can each person in this budget spend each month?"
          topDescription="If this is left blank, people can continue to spend from all available money in the budget."
          leftIcon={
            <Text
              color={errors.individualLimit ? "grapefruit-core" : "black-core"}
            >
              $
            </Text>
          }
          onChange={(value) =>
            setFieldValue("individualLimit", parseInt(value, 10))
          }
          keyboardType="numeric"
          invalid={!!errors.individualLimit}
          invalidText={errors.individualLimit}
          testID="group-budget-individual-limit-input"
        />
        {/* <Spacer size={24} />
          <Text size="xs" weight="semibold" color="black-core">
            Is this budget exempt from the organization’s spend limit?*
          </Text>
          <Spacer size={8} />
          <Text size="xs" color="black-70">
            If you check the box below, people in this budget can continue to
            spend even if the organization has exceeded its spend limit.
          </Text>
          <Spacer size={24} />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Checkbox
              appearance="box"
              onChange={(val) => setFieldValue("exempt", val)}
              value={values.exempt}
            />
            <Spacer size={16} direction="row" />
            <Text size="xs" color="black-core">
              Yes, this budget is exempt from the organization’s spend limit.
            </Text>
          </View> */}
      </CreateEditBudgetCard>
      {showPeopleCard && (
        <CreateEditBudgetCard title="People">
          <Text size="xs" weight="semibold" color="black-core">
            Who will manage this budget?*
          </Text>
          <Spacer size={8} />
          <Text size="xs" color="black-70">
            Budget managers can add people to this budget, update the limit,
            edit budget information, and approve booking requests for members of
            this budget. At least one is required.
          </Text>
          <Spacer size={20} />
          <PeoplePicker
            testID="people-manager-picker"
            buttonText="Add budget manager"
            label="Add budget managers"
            value={values.managers || []}
            onChange={(people) => setFieldValue("managers", people)}
            onFilter={(people) =>
              people.filter((p) => !values.members?.find((m) => m.id === p.id))
            }
          />
          {!!errors.managers && (
            <Fragment>
              <Spacer size={8} />
              <Text
                testID="create-group-budget-manager-error-text"
                size="xs"
                color="grapefruit-core"
              >
                {errors.managers}
              </Text>
            </Fragment>
          )}
          <Spacer size={24} />
          <Text size="xs" weight="semibold" color="black-core">
            Who can spend from this budget?
          </Text>
          <Spacer size={8} />
          <Text size="xs" color="black-70">
            Budget members can spend from all available money in the spend
            limit.
          </Text>
          <Spacer size={20} />
          <PeoplePicker
            testID="people-member-picker"
            buttonText="Add budget members"
            label="Add budget member"
            value={values.members || []}
            onChange={(people) => setFieldValue("members", people)}
            onFilter={(people) =>
              people.filter((p) => !values.managers?.find((m) => m.id === p.id))
            }
          />
        </CreateEditBudgetCard>
      )}
    </Fragment>
  );
}
