import { gql, useLazyQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import React, { useCallback, useState } from "react";

import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { TextInput } from "components/text_input";
import {
  PromoCodeIsValidQuery,
  PromoCodeIsValidQueryVariables,
} from "./graphql.generated";
import { Day } from "lib/day_utils";

interface PromotionDetailsProps {
  offsiteSpaceID: string;
  promoCode: string;
  onChange?: (promoCode: string) => void;
  startDate: Day;
  endDate: Day;
}

export function PromotionDetails(props: PromotionDetailsProps) {
  const {
    onChange,
    offsiteSpaceID,
    promoCode: promoCodeProp,
    startDate,
    endDate,
  } = props;
  const [errorText, setErrorText] = useState("");
  const [promoCode, setPromoCode] = useState(promoCodeProp);
  const [promoCodeIsValid] = useLazyQuery<
    PromoCodeIsValidQuery,
    PromoCodeIsValidQueryVariables
  >(promoCodeIsValidGQLQuery);

  const checkPromoCode = useCallback(() => {
    if (!promoCode) {
      setErrorText("Promotion code cannot be empty");
      return;
    }
    promoCodeIsValid({
      variables: { offsiteSpaceID, code: promoCode, startDate, endDate },
    })
      .then((result) => {
        if (!result?.data?.promoCodeIsValid) {
          setErrorText("Promo code is either invalid or has expired.");
        } else if (onChange) {
          setErrorText("");
          onChange(promoCode);
        }
      })
      .catch(() => {
        setErrorText("Could not validate promo code.");
      });
  }, [
    promoCodeIsValid,
    promoCode,
    onChange,
    offsiteSpaceID,
    startDate,
    endDate,
  ]);

  return (
    <Container>
      <Row>
        <Container flex={1}>
          <TextInput
            testID="promo-code"
            placeholder="Promo code"
            value={promoCode}
            invalid={!!errorText}
            invalidText={errorText}
            onChange={setPromoCode}
            onSubmitEditing={checkPromoCode}
          />
        </Container>
        <Spacer size={16} direction="row" />

        <Button
          testID="promo-code-apply"
          onPress={checkPromoCode}
          appearance={"textLink"}
          text={"Apply"}
        />
      </Row>
    </Container>
  );
}

const promoCodeIsValidGQLQuery = gql`
  query PromoCodeIsValid(
    $code: String!
    $offsiteSpaceID: ID!
    $startDate: String!
    $endDate: String!
  ) {
    promoCodeIsValid(
      code: $code
      offsiteSpaceID: $offsiteSpaceID
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
