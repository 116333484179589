import { Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { Container } from "components/container";
import { Icon } from "components/icon";
import { Icon as IconV2, IconName } from "components/iconv2";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Link } from "react-router-dom";
import { colors } from "components/colors";

interface MenuCardProps {
  icon: IconName;
  title: string | JSX.Element;
  to: string;
  externalLink?: boolean;
}

export function MenuCard(props: MenuCardProps) {
  const { externalLink, icon, title, to } = props;

  return (
    <Link
      to={to}
      target={externalLink ? "_blank" : "_self"}
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <View style={styles.card}>
        <Fragment>
          <Container>
            <Row alignItems="center">
              <IconV2 name={icon} />
              <Spacer direction="row" size={16} />
              <Text size="xs">{title}</Text>
            </Row>
          </Container>
          <Icon
            name={externalLink ? "launch-icon" : "chevron-right"}
            color={colors.brand.blackMinus30}
          />
        </Fragment>
      </View>
    </Link>
  );
}

const styles = StyleSheet.create({
  card: {
    height: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
