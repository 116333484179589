import { gql } from "@apollo/client";

export const bookingSpaceDetailFragment = gql`
  fragment BookingSpaceDetail on Space {
    id
    name
    spaceType
    maxCapacity
    locked
    preferred
    amenities(mapNavigationFeatureFlag: true) {
      id
      iconURL
      name
      code
    }
    location {
      ...SpaceLocationDetail
    }
    physicalSpaces(first: 1) {
      id
    }
    images {
      small {
        url
      }
    }
    currency
    pricings {
      type
      price
    }
    priceRanges {
      daily {
        min
        max
      }
      hourly {
        min
        max
      }
    }
  }
`;
export const spaceLocationDetailFragment = gql`
  fragment SpaceLocationDetail on Location {
    id
    name
    preferred
    currentUserDistance: distance(coordinates: $currentUserCoordinates)
    images {
      small {
        url
      }
      medium {
        url
      }
    }
    address {
      city
      streetAddress
      latitude
      longitude
    }
  }
`;
