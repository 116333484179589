import { gql, useQuery } from "@apollo/client";
import { Container } from "components/container";
import { Content } from "components/content";
import { Heading } from "components/heading";
import { Icon } from "components/icon";
import { PageContainer } from "components/page_container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Tabs } from "components/tab/tabs";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import {
  getBookingDateRangeLabel,
  getDateRangeFromBooking,
  isPastBooking,
  isUpcomingBooking,
} from "core/booking_utils";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import {
  OrderStatus,
  ReservationPage__OrderDetailsFragment,
  ReservationsPageQuery,
  ReservationsPageQueryVariables,
} from "core/graphql.generated";
import { usePrevious } from "hooks/use_previous";
import { useQueryString } from "lib/query_string";
import React, { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AppHeader } from "components/app_header_v3/app_header";
import { getBooking } from "./offsite_order/offsite_order";

export function UserProfileReservationsPage() {
  return (
    <Container>
      <AppHeader />
      <Spacer size={16} />
      <PageContainer>
        <Content>
          <Row alignItems="center">
            <Heading size="h1">Bookings</Heading>
          </Row>
          <Spacer size={16} />
          <UserProfileReservations />
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <Footer />
      <BottomBarNavigation />
    </Container>
  );
}

function UserProfileReservations() {
  const { data: reservationPageData } = useQuery<
    ReservationsPageQuery,
    ReservationsPageQueryVariables
  >(reservationPageGQLQuery, { fetchPolicy: "network-only" });
  const queryString = useQueryString();
  const [tab, setTab] = useState(queryString?.filter === "past" ? 2 : 1);

  const prevTab = usePrevious(tab);
  const history = useHistory();

  useEffect(() => {
    if (prevTab === tab) {
      return;
    }

    if (tab === 1) {
      history.replace("/user-profile/reservations?filter=upcoming");
    } else {
      history.replace("/user-profile/reservations?filter=past");
    }
  }, [tab, prevTab, history]);

  if (!reservationPageData) {
    return (
      <Container expanded center>
        <ActivityIndicator size="large" color="rgba(82,68,134,1)" />
        <Spacer size={120} />
      </Container>
    );
  }

  let orders = reservationPageData.currentUser?.orders || [];
  let filteredOrdersByTab = [];

  if (tab === 1) {
    filteredOrdersByTab = orders.filter((order) => {
      const booking = getBooking(order.orderItems);

      return isUpcomingBooking(booking);
    });
  } else {
    filteredOrdersByTab = orders.filter((order) => {
      const booking = getBooking(order.orderItems);

      return isPastBooking(booking);
    });
  }

  return (
    <Container>
      {orders.length > 0 && (
        <Tabs
          value={tab}
          onChange={setTab}
          options={[
            { value: 1, label: "Upcoming" },
            { value: 2, label: "Past" },
          ]}
        />
      )}
      <Spacer size={16} />
      {tab === 1 && orders.length > 0 && filteredOrdersByTab.length === 0 && (
        <Container center paddingVertical={24}>
          <Text size="lg" color="neutral-darkest" weight="bold">
            No upcoming bookings.
          </Text>
          <Spacer size={120} />
        </Container>
      )}
      {tab === 2 && orders.length > 0 && filteredOrdersByTab.length === 0 && (
        <Container center paddingVertical={24}>
          <Text size="lg" color="neutral-darkest" weight="bold">
            No past bookings.
          </Text>
          <Spacer size={120} />
        </Container>
      )}
      {orders.length === 0 && (
        <Container center paddingVertical={24}>
          <Text size="lg" color="neutral-darkest" weight="bold">
            You have not made any offsite bookings yet.
          </Text>
          <Spacer size={120} />
        </Container>
      )}
      {orders.length > 0 &&
        filteredOrdersByTab.map((order) => (
          <Container key={order.id}>
            <ReservationItem order={order} />
            <Spacer size={16} />
          </Container>
        ))}
    </Container>
  );
}

interface ReservationItemProps {
  order: ReservationPage__OrderDetailsFragment;
}

function ReservationItem(props: ReservationItemProps) {
  const { order } = props;
  const booking = getBooking(order.orderItems);
  const { space } = booking;

  return (
    <Link
      data-testid={`reservation-item-${order.status}`}
      to={`/user-profile/reservations/${order.id}`}
      style={{ textDecoration: "none" }}
    >
      <Container
        padding={8}
        shape="rounded"
        borderRadius={1}
        borderWidth={1}
        borderColor={tokens.colors.neutral.darker}
      >
        <Row>
          <Container height={90} width={90}>
            <img
              src={space.images[0].medium.url}
              className="image"
              alt="cover image 1"
            />
          </Container>
          <Container paddingVertical={8} paddingHorizontal={12} flex={1}>
            <Text size="sm">
              <Text weight="600">{space.name}</Text> at {space.location.name}
            </Text>
            <Spacer size={8} />
            <Text size="xs">
              On {getBookingDateRangeLabel(getDateRangeFromBooking(booking))}
            </Text>
            <Spacer size={8} />
            <Spacer />
            {order.status === OrderStatus.Canceled && (
              <Text
                size="xs"
                weight="600"
                customColor={tokens.colors.secondary.darker}
              >
                Cancelled
              </Text>
            )}
          </Container>
          <Container center>
            <Icon color={tokens.colors.neutral.darkest} name="chevron-right" />
          </Container>
        </Row>
        <style jsx>{`
          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        `}</style>
      </Container>
    </Link>
  );
}

export const reservationPageGQLQuery = gql`
  query ReservationsPage {
    currentUser {
      orders {
        ...ReservationPage__OrderDetails
      }
    }
  }

  fragment ReservationPage__OrderDetails on Order {
    id
    status
    orderItems {
      ...ReservationPage__OrderItemDetails
    }
  }

  fragment ReservationPage__OrderItemDetails on OrderItem {
    id
    totalPrice
    unitPrice
    details {
      __typename
      ...ReservationPage__HourlyBookingDetails
      ...ReservationPage__DailyBookingDetails
    }
  }

  fragment ReservationPage__HourlyBookingDetails on HourlyBooking {
    space {
      id
      name
      maxCapacity
      images {
        thumb {
          url
          width
          height
        }
        small {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
        large {
          url
          width
          height
        }
      }
      pricings {
        type
        price
      }
      location {
        name
      }
    }
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    date
    startTime
    endTime
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
  }

  fragment ReservationPage__DailyBookingDetails on DailyBooking {
    space {
      id
      name
      maxCapacity
      images {
        thumb {
          url
          width
          height
        }
        small {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
        large {
          url
          width
          height
        }
      }
      pricings {
        type
        price
      }
      location {
        name
      }
    }
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    startDate
    endDate
    days
    time
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
  }
`;
