import { View, StyleSheet } from "react-native";

import { tokens } from "components/tokens";

import { Text } from "./text_v2";
import { Spacer } from "./spacer";
import { colors } from "./colors";

interface CardPropTypes {
  title?: string;
  children: JSX.Element;
  backgroundColor?: string;
}

export function Card({
  title,
  children,
  backgroundColor = colors.brand.whitecore,
}: CardPropTypes) {
  return (
    <View
      style={[styles.card, !!title && styles.withPadding, { backgroundColor }]}
    >
      {title && (
        <>
          <Text color="black-core" size="md" weight="semibold">
            {title}
          </Text>
          <Spacer size={24} />
        </>
      )}
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    ...tokens.shadow.elevation1,
    borderRadius: 4,
    overflow: "hidden",
  },
  withPadding: {
    padding: 24,
  },
});
