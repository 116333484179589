import { AppHeader } from "components/app_header_v3/app_header";
import { Icon } from "components/iconv2";
import { MobileHeader } from "components/layout/mobile_header";
import { MobileLayout } from "components/layout/mobile_layout";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import { PageLayout } from "pages/page_layout";
import { Pressable } from "react-native";

interface PropsType {
  children: JSX.Element;
}

export const SavedPlacesLayout: React.FC<PropsType> = ({ children }) => {
  const mq = useMediaQuery();

  const goBack = useGoBack();

  if (mq.deviceQuery.mobile) {
    return (
      <MobileLayout
        header={<MobileHeader title="Saved places" />}
        body={
          <>
            {children}
            <Spacer size={40} />
          </>
        }
        footer={<BottomBarNavigation />}
      />
    );
  }

  return (
    <PageLayout
      header={<AppHeader withSearch={false} />}
      body={
        <>
          <Spacer size={40} />
          <Pressable onPress={goBack}>
            <Icon name="chevron-left" size="md" />
          </Pressable>
          <Spacer size={16} />

          <Text weight="bold" size="lg">
            Saved places
          </Text>
          <Spacer size={40} />
          {children}
          <Spacer size={40} />
        </>
      }
      footer={<Footer />}
    />
  );
};
