import { AsyncRenderer } from "components/AsyncRenderer";
import { HorizontalList } from "components/horizontal_list";
import { OrgBookingFragment } from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { ListSpacesSkeleton } from "pages/homev3/components/list_spaces_skeleton";
import { LocationCard } from "pages/homev3/components/location_card";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useOrgBookingQuery } from "pages/homev3/hooks/use_org_booking_query";
import { useAnalytics } from "providers/analytics";
import React, { useCallback } from "react";
import { useUserType } from "../hooks/use_user_type";

export function JoinYourTeam() {
  const { data, loading, error } = useOrgBookingQuery({});
  return (
    <AsyncRenderer
      error={error}
      loading={loading}
      data={data}
      loadingHandler={() => <ListSpacesSkeleton />}
    >
      {(data) => <Content bookings={data.bookings} />}
    </AsyncRenderer>
  );
}

type ContentProps = {
  bookings?: OrgBookingFragment[];
};
function Content({ bookings }: ContentProps) {
  const analytics = useAnalytics();
  const userType = useUserType();

  const onNavigate = useCallback(() => {
    analytics.event("Navigate Horizontal List", {
      Section: "Teammate Spaces",
      category: "Rebooking",
    });
  }, [analytics]);

  if (!bookings || bookings.length < 2) {
    return null;
  }
  const groupedBooking = groupBy(bookings, (b) => b.space.location.id);
  const sortedGroupedBooking = Object.values(groupedBooking)
    .sort(
      (a, b) =>
        (a[0].space.location.currentUserDistance || 0) -
        (b[0].space.location.currentUserDistance || 0),
    )
    .slice(0, 15);
  const renderItem = (bookings: OrgBookingFragment[]) => (
    <LocationCard
      key={bookings[0].space.location.id}
      location={bookings[0].space.location}
      peoples={bookings.map((b) => b.user)}
      onPress={() => {
        const location = bookings[0].space.location;
        analytics.event("Click on Teammate Spaces Card", {
          "Location UUID": location.id,
          "Location Name": location.name,
          Source: userType,
          category: "Rebooking",
        });
      }}
    />
  );

  return (
    <HorizontalList
      title={
        <TitleSpaceList
          title={"Join your team"}
          subtitle={"Teamwork makes the dream work"}
        />
      }
      data={sortedGroupedBooking}
      renderItem={renderItem}
      onNavigate={onNavigate}
    />
  );
}
