import { MobileLayout } from "components/layout/mobile_layout";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { ROUTES } from "core/routes";

interface BookingMobileLayoutProps {
  children: JSX.Element;
}

export function BookingMobileLayout({ children }: BookingMobileLayoutProps) {
  return (
    <MobileLayout
      header={<></>}
      body={children}
      footer={<BottomBarNavigation />}
      title={ROUTES.BOOKINGS.pageName}
    />
  );
}
