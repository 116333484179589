import React, { useMemo } from "react";
import { View, StyleSheet, Pressable } from "react-native";
import { Icon } from "components/icon";
import { logger } from "lib/logger";
import { Text } from "../text_v2";
import { colors } from "../colors";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onChangePage: (page: number) => void;
  testID?: string;
};

type MixedArray = Array<string | number>;

export const getVisiblePages = (
  currentPage: number,
  totalPages: number,
): MixedArray => {
  if (currentPage < 1 || currentPage > totalPages) {
    logger.error("Current page is out of range");
    return [];
  }

  let pages: MixedArray;
  // If there are 5 or fewer pages, show all pages.
  if (totalPages <= 5) {
    pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    /*If the current page is near the beginning of the list, show the first 4 pages, after that show the three dot and finally show the last page.
    The UI will have the ... page if the total page higher than 5, so I add the three dots as a string to make it easier to render them later. */
    if (currentPage <= 3) {
      pages = [1, 2, 3, 4, "...", totalPages];
      // If the current page is near the end of the list, show the first page, after that show the three dot and finally show the last few pages.
    } else if (currentPage >= totalPages - 2) {
      pages = [
        1,
        "...",
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
      // Otherwise, show the first page + three dot, the current page and 2 other page near it, three dot + show the last page
    } else {
      pages = [1, "..."];
      pages.push(currentPage - 1, currentPage, currentPage + 1);
      pages.push("...", totalPages);
    }
  }
  return pages;
};

const Pagination = ({
  currentPage,
  totalPages,
  onChangePage,
  testID,
}: PaginationProps) => {
  // Create an array of visible pages using the useMemo hook.
  const visiblePages: MixedArray = useMemo(
    () => getVisiblePages(currentPage, totalPages),
    [currentPage, totalPages],
  );

  if (currentPage < 1 || currentPage > totalPages) {
    return null;
  }

  return (
    <View style={styles.pagination} testID={testID}>
      <Pressable
        style={[styles.iconButton, currentPage <= 1 && styles.disabled]}
        onPress={() => currentPage > 1 && onChangePage(currentPage - 1)}
        disabled={currentPage <= 1}
      >
        <Icon name="chevron-left" size={"md"} />
      </Pressable>
      {visiblePages.map((page, index) => (
        <Pressable
          key={index}
          style={[
            styles.button,
            typeof page === "string" && styles.threeDot,
            currentPage === page && styles.buttonActive,
          ]}
          onPress={() =>
            onChangePage(typeof page === "number" ? page : currentPage)
          }
        >
          <Text
            size={"xs"}
            weight={"semibold"}
            color={currentPage === page ? "eggplant-core" : "black-core"}
          >
            {page}
          </Text>
        </Pressable>
      ))}
      <Pressable
        style={[
          styles.iconButton,
          currentPage === totalPages && styles.disabled,
        ]}
        onPress={() =>
          currentPage < totalPages && onChangePage(currentPage + 1)
        }
        disabled={currentPage === totalPages}
      >
        <Icon name="chevron-right" size={"md"} />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  pagination: {
    flexDirection: "row",
    justifyContent: "center",
  },
  iconButton: {
    marginHorizontal: 24,
  },
  button: {
    width: 24,
    height: 24,
    marginHorizontal: 2,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    alignItems: "center",
    justifyContent: "center",
  },
  threeDot: {
    borderWidth: 0,
    justifyContent: "flex-end",
    paddingBottom: 4,
  },
  buttonActive: {
    borderColor: colors.brand.eggplantcore,
    backgroundColor: colors.brand.eggplantMinus90,
  },
  disabled: {
    opacity: 0.3,
  },
});

export default Pagination;
