import { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import {
  InviteesRemainingQuery,
  InviteesRemainingQueryVariables,
} from "core/graphql.generated";

export function useRemainingInvite(
  remainingInviteSpotsParam: number,
  isDialogVisible: boolean,
  orderId: string,
) {
  const [spots, setSpots] = useState(remainingInviteSpotsParam);

  const [getInviteesRemaining, { data: inviteesRemainingData, loading }] =
    useLazyQuery<InviteesRemainingQuery, InviteesRemainingQueryVariables>(
      inviteesRemainingQuery,
    );

  useEffect(() => {
    if (isDialogVisible) {
      if (remainingInviteSpotsParam) {
        setSpots(remainingInviteSpotsParam);
      } else if (orderId) {
        getInviteesRemaining({
          variables: {
            orderId,
          },
        });
        if (
          inviteesRemainingData?.order?.orderItems[0].details.space.maxCapacity
        ) {
          const remainingSpots =
            inviteesRemainingData.order.orderItems[0].details.space
              .maxCapacity - inviteesRemainingData.order.invitees.length;
          setSpots(remainingSpots);
        }
      }
    }
  }, [
    isDialogVisible,
    remainingInviteSpotsParam,
    getInviteesRemaining,
    inviteesRemainingData?.order?.invitees.length,
    inviteesRemainingData?.order?.orderItems,
    orderId,
  ]);

  return { remainingSpots: spots, loading };
}

const inviteesRemainingQuery = gql`
  query InviteesRemaining($orderId: ID!) {
    order(id: $orderId) {
      invitees {
        name
      }
      orderItems {
        details {
          ...InviteesRemaining__DailyBookingDetails
          ...InviteesRemaining__HourlyBookingDetails
        }
      }
    }
  }

  fragment InviteesRemaining__DailyBookingDetails on DailyBooking {
    space {
      maxCapacity
    }
  }

  fragment InviteesRemaining__HourlyBookingDetails on HourlyBooking {
    space {
      maxCapacity
    }
  }
`;
