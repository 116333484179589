import { StyleSheet, View } from "react-native";

import { colors } from "components/colors";
import { Text } from "components/text_v2";
import { Button } from "components/button_v2";
import { useHistory } from "react-router-dom";

interface EmptySavedPlacesProps {
  title: string;
  description: string;
}

export const EmptySavedPlaces = (props: EmptySavedPlacesProps) => {
  const { title, description } = props;
  const history = useHistory();

  const onPress = () => {
    history.push("/");
  };
  return (
    <View testID="empty-requests" style={styles.root}>
      <Text weight="semibold" size="md" color="black-70">
        {title}
      </Text>
      <Text size="xs" color="black-70" align="center">
        {description}
      </Text>
      <View style={styles.button}>
        <Button text="Find your next space" onPress={onPress} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    padding: 20,
  },
  button: {
    width: 234,
  },
});
