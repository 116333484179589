import { useMediaQuery } from "lib/media_query";
import VerificationInput, {
  VerificationInputProps,
} from "react-verification-input";
import "./code_input.web.css";

interface CodeInputProps extends VerificationInputProps {
  error?: string;
}

export function CodeInput(props: CodeInputProps) {
  const { error, value, onChange, autoFocus, length } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  const characterClassName = error
    ? `character character--error ${!isMobile ? "character-sm-up" : ""}`
    : `character ${!isMobile ? "character-sm-up" : ""}`;
  return (
    <VerificationInput
      placeholder=""
      removeDefaultStyles
      classNames={{
        container: "container",
        character: characterClassName,
        characterInactive: "character--inactive",
        characterSelected: "character--selected",
      }}
      validChars="0-9"
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      length={length}
      inputProps={{
        inputMode: "numeric",
      }}
    />
  );
}
