import { gql, useQuery } from "@apollo/client";

import {
  BestDayRatesQuery,
  BestDayRatesQueryVariables,
  BookingType,
} from "core/graphql.generated";
import { getLocalToday, addMonths } from "lib/day_utils";

export function useBestDayRatesQuery(
  spaceId: string,
  bookingType: BookingType,
  timezone: string,
) {
  const { data } = useQuery<BestDayRatesQuery, BestDayRatesQueryVariables>(
    bestDayRatesQuery,
    {
      variables: {
        spaceId,
        bookingType: bookingType,
        startDate: getLocalToday(timezone),
        endDate: addMonths(getLocalToday(timezone), 6),
      },
    },
  );
  return data?.space?.bestDayRates || [];
}

const bestDayRatesQuery = gql`
  query BestDayRates(
    $spaceId: ID!
    $bookingType: BookingType!
    $startDate: String!
    $endDate: String!
  ) {
    space(id: $spaceId) {
      bestDayRates(
        input: {
          bookingType: $bookingType
          startDate: $startDate
          endDate: $endDate
        }
      ) {
        date
        price
      }
    }
  }
`;
