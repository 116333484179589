import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import {
  ReportingPageOrganizationReportingQuery,
  ReportingPageOrganizationReportingQueryVariables,
  ReportingPageReportingDataFragment,
} from "core/graphql.generated";
import { ColumnGrid, ColumnGridRow, ColumnGridCol } from "core/grid";
import { ReportingBookings } from "./bookings";
import { ReportingEngagement } from "./engagement";
import { ReportingFrequency } from "./frequency";
import { RecentActivity } from "./recent_activity";
import { ReportingSpend } from "./spend";
import { ReportingSubscriptionOverviewDesktop } from "./subscription_overview_desktop";
import { DayInterval, isIntervalThisMonth } from "lib/day_utils";
import { reportingPageOrganizationReportingGQLQuery } from "../gql_queries";
import { ReportingBudgetCardDesktop } from "./budget_card_desktop";
import { ReportingContentSkeletonDesktop } from "./content_skeleton_desktop";

interface ReportingPageSubscriptionDesktopProps {
  dayInterval: DayInterval;
}

export function ReportingSubscriptionDesktop(
  props: ReportingPageSubscriptionDesktopProps,
) {
  const { dayInterval } = props;

  const { loading: reportingLoading, data: reportingData } = useQuery<
    ReportingPageOrganizationReportingQuery,
    ReportingPageOrganizationReportingQueryVariables
  >(reportingPageOrganizationReportingGQLQuery, {
    variables: {
      startDate: dayInterval.start,
      endDate: dayInterval.end,
    },
  });

  return (
    <ColumnGrid>
      <Heading size="xl">Usage</Heading>
      <Spacer size={16} />
      <ColumnGridRow>
        <ColumnGridCol style={{ zIndex: 1 }} cols={8}>
          {reportingLoading ||
          !reportingData?.currentUser?.organization?.reporting ? (
            <ReportingContentSkeletonDesktop />
          ) : (
            <ReportingContent
              reporting={reportingData?.currentUser?.organization?.reporting}
              dayInterval={dayInterval}
            />
          )}
        </ColumnGridCol>
        <ColumnGridCol cols={4}>
          <RecentActivity />
        </ColumnGridCol>
      </ColumnGridRow>
    </ColumnGrid>
  );
}

interface ReportingContentProps {
  reporting: ReportingPageReportingDataFragment;
  dayInterval: DayInterval;
}

function ReportingContent(props: ReportingContentProps) {
  const { reporting, dayInterval } = props;

  return (
    <Fragment>
      <ColumnGridRow>
        <ColumnGridCol cols={12}>
          <ReportingSubscriptionOverviewDesktop
            totalSpend={reporting.spend.totalSpend}
            totalBookings={reporting.bookings.totalBookings}
          />
        </ColumnGridCol>
      </ColumnGridRow>
      <Spacer size={40} />
      <ColumnGridRow>
        <ColumnGridCol style={{ zIndex: 1 }} cols={6}>
          <ReportingEngagement
            soloBookings={reporting.engagement.soloBookings}
            teamBookings={reporting.engagement.teamBookings}
            labelUpdatedAt=""
          />
        </ColumnGridCol>
        <ColumnGridCol cols={6}>
          <ReportingFrequency
            zeroOrSingleBookingPerWeek={
              reporting.frequency.zeroOrSingleBookingPerWeek
            }
            twoOrThreeBookingsPerWeek={
              reporting.frequency.twoOrThreeBookingsPerWeek
            }
            fourOrMoreBookingsPerWeek={
              reporting.frequency.fourOrMoreBookingsPerWeek
            }
            totalEmployees={reporting.frequency.totalEmployees}
            labelUpdatedAt=""
          />
        </ColumnGridCol>
      </ColumnGridRow>
      <Spacer size={40} />
      <Heading size="xl">Premium Bookings</Heading>
      <Spacer size={24} />
      <ColumnGridRow>
        <ColumnGridCol cols={12}>
          <ReportingBudgetCardDesktop
            editable
            showOnlyTotalSpent={!isIntervalThisMonth(dayInterval)}
          />
        </ColumnGridCol>
      </ColumnGridRow>
      <Spacer size={40} />

      <ColumnGridRow>
        <ColumnGridCol style={{ zIndex: 1 }} cols={6}>
          <ReportingSpend
            dayPasses={reporting.spend.dayPasses}
            dayOffices={reporting.spend.dayOffices}
            meetingRooms={reporting.spend.meetingRooms}
            totalSpend={reporting.spend.totalSpend}
            labelUpdatedAt=""
          />
        </ColumnGridCol>
        <ColumnGridCol cols={6}>
          <ReportingBookings
            dayPasses={reporting.bookings.dayPasses}
            dayOffices={reporting.bookings.dayOffices}
            meetingRooms={reporting.bookings.meetingRooms}
            totalBookings={reporting.bookings.totalBookings}
            labelUpdatedAt=""
          />
        </ColumnGridCol>
      </ColumnGridRow>
    </Fragment>
  );
}
