import { Heading } from "components/heading_v2";
import { Icon } from "components/iconv2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import { ReactNode } from "react";
import { Pressable, StyleSheet, View } from "react-native";

interface ContentHeaderProps {
  title: string;
  description?: ReactNode;
  hasBackButton?: boolean;
  onBackPress?: () => void;
  actions?: ReactNode;
}

export function ContentHeader({
  title,
  description,
  hasBackButton,
  onBackPress,
  actions,
}: ContentHeaderProps) {
  const goBack = useGoBack();
  const mq = useMediaQuery();

  const handleClickBack = () => {
    if (onBackPress) {
      onBackPress();
    } else {
      goBack();
    }
  };

  return (
    <View style={[styles.root, mq.deviceQuery.mobile && styles.rootMobile]}>
      {hasBackButton && (
        <View style={styles.backBtnWrapper}>
          <Pressable onPress={handleClickBack}>
            <Icon name="arrow-left" />
          </Pressable>
        </View>
      )}
      <View style={{ width: mq.deviceQuery.mobile ? "100%" : undefined }}>
        <Heading size={mq.deviceQuery.mobile ? "md" : "lg"}>{title}</Heading>
        {typeof description === "string" ? (
          <>
            <Spacer size={8} />
            <Text size={mq.deviceQuery.mobile ? "sm" : "md"}>
              {description}
            </Text>
          </>
        ) : (
          description
        )}
      </View>
      {actions}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    gap: 20,
    marginTop: 40,
  },
  rootMobile: {
    marginTop: 4,
  },
  backBtnWrapper: {
    paddingTop: 4,
  },
});
