// import { InviteMemberStepPickLocation } from "./components/step_pick_location";
import { gql, useQuery } from "@apollo/client";
import { AuthPageContainer } from "components/auth_page_container";
import { SignUpProgressBar } from "components/sign_up_shared/progress_bar";
import { Spinner } from "components/spinner";
import {
  OrganizationByInviteQueryQuery,
  QueryOrganizationByInviteArgs,
} from "core/graphql.generated";
import { Footer } from "core/new_footer";
import { OnboardingHeader } from "core/onboarding_header";
import { CustomOrgSignIn } from "pages/custom_org_sign_in/custom_org_sign_in";
import { SIGN_UP_STEPS_REDIRECT_URL_KEY } from "providers/auth_loader";
import { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { InviteMemberStepCodeVerified } from "./components/code_verified";
import { InvalidInvite } from "./components/invalid_invite";
import { InviteMemberStepAccountSetup } from "./components/step_account_setup";
import { InviteMemberStepCode } from "./components/step_code";
import { InviteMemberStepGetStarted } from "./components/step_get_started";
import { InviteMemberStepPassword } from "./components/step_password";

export interface AcceptInviteCredentials {
  email: string;
  fullName: string;
  authenticationError?: string;
  inviteId: string;
}

const STEPS_NON_SSO = [
  {
    path: "/accept-invite/get-started",
    component: InviteMemberStepGetStarted,
  },
  {
    path: "/accept-invite/password",
    component: InviteMemberStepPassword,
  },
  {
    path: "/accept-invite/code",
    component: InviteMemberStepCode,
  },
  // {
  //   path: "/accept-invite/pick-location",
  //   component: InviteMemberStepPickLocation,
  // },
  {
    path: "/accept-invite/code-verified",
    component: InviteMemberStepCodeVerified,
  },
];

export const ACCEPT_INVITE = "accept-invite";

export interface AcceptInvitePageProps {
  onGoNext: () => void;
  onGoBack: () => void;
  organization?: OrganizationByInviteQueryQuery["organizationByInvite"]["organization"];
  credentials: AcceptInviteCredentials;
}

export function AcceptInvitePage() {
  const history = useHistory();
  const [steps, setSteps] = useState<any[]>(STEPS_NON_SSO);
  const savedCreds = localStorage.getItem(ACCEPT_INVITE);
  const [credentials, setCredentialsState] = useState<AcceptInviteCredentials>(
    savedCreds
      ? JSON.parse(savedCreds)
      : { fullName: "", email: "", inviteId: "" },
  );
  const setCredentials = (creds: AcceptInviteCredentials) => {
    localStorage.setItem(ACCEPT_INVITE, JSON.stringify(creds));
    setCredentialsState(creds);
  };

  useLocation();

  const goBack = useCallback(() => {
    const path = history.location.pathname;
    const currentStepIndex = steps.findIndex((step) => step.path === path);

    if (currentStepIndex > 0) {
      history.push(steps[currentStepIndex - 1].path);
    }
  }, [history, steps]);

  const goNext = useCallback(() => {
    const path = history.location.pathname;
    const currentStepIndex = steps.findIndex((step) => step.path === path);

    history.push(steps[currentStepIndex + 1].path);
  }, [history, steps]);

  const path = history.location.pathname;
  const currentStepIndex = steps.findIndex((step) => step.path === path);
  const isFirstStep = path === steps[0].path;
  const goToSignIn = () => {
    localStorage.removeItem(ACCEPT_INVITE);
    localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
    history.push("/sign-in");
  };

  const query = new URLSearchParams(history.location.search);
  const inviteId = query.get("invite_id");

  const { data: orgByInvite, loading } = useQuery<
    OrganizationByInviteQueryQuery,
    QueryOrganizationByInviteArgs
  >(organizationByInviteGQLQuery, {
    variables: { inviteId: inviteId || credentials.inviteId || "" },
    skip: !credentials!.inviteId,
  });
  const org = orgByInvite?.organizationByInvite.organization;

  useEffect(() => {
    if (inviteId && credentials?.inviteId !== inviteId) {
      setCredentials({
        ...credentials,
        inviteId,
      });
    }
  }, [inviteId, credentials]);

  useEffect(() => {
    const inviteeEmail = orgByInvite?.organizationByInvite?.inviteeEmail;
    if (inviteeEmail && credentials?.email !== inviteeEmail) {
      setCredentials({
        ...credentials,
        email: inviteeEmail || "",
      });
    }
  }, [credentials, orgByInvite]);

  useEffect(() => {
    const org = orgByInvite?.organizationByInvite.organization;

    if (org?.domain) {
      const isSSORequired =
        org.requireSSO &&
        org.ssoProvider &&
        org.ssoProvider.length > 0 &&
        org.slug;

      if (isSSORequired) {
        setSteps([
          {
            path: "/accept-invite/get-started",
            component: InviteMemberStepGetStarted,
          },
          {
            path: `/${org.slug}`,
            component: CustomOrgSignIn,
          },
        ]);
      } else {
        setSteps([
          {
            path: "/accept-invite/get-started",
            component: InviteMemberStepGetStarted,
          },
          {
            path: "/accept-invite/account-setup",
            component: InviteMemberStepAccountSetup,
          },
        ]);
      }
    }
  }, [orgByInvite?.organizationByInvite.organization]);

  return (
    <AuthPageContainer
      header={
        <OnboardingHeader
          buttonTitle={isFirstStep ? "Log in" : ""}
          onButtonClick={goToSignIn}
        />
      }
      footer={<Footer />}
      progressBar={
        <SignUpProgressBar
          progress={
            currentStepIndex >= 0 ? currentStepIndex / (steps.length - 1) : 0
          }
        />
      }
    >
      {/**
       1.We are checking last step because when we completed non-sso
       flow, at last step invite status comes as "accepted" so screen stuck at invalid invite
       2.Also if status comes as "accepted" , it means that invite is expired or already completed. Also there
       is no chance of invalid invite can start from last step so this is the solution
       */}
      {orgByInvite?.organizationByInvite.status &&
      (orgByInvite?.organizationByInvite.status === "pending" ||
        currentStepIndex === steps.length - 1) ? (
        <Switch>
          {steps.map((step) => {
            const { component: Component, ...data } = step;
            return (
              <Route key={data.path} path={data.path}>
                <Component
                  onGoBack={goBack}
                  onGoNext={goNext}
                  credentials={credentials}
                  organization={org}
                />
              </Route>
            );
          })}

          <Redirect to={steps[0].path} />
        </Switch>
      ) : loading ? (
        <Spinner />
      ) : (
        <InvalidInvite />
      )}
    </AuthPageContainer>
  );
}

const organizationByInviteGQLQuery = gql`
  query OrganizationByInviteQuery($inviteId: String!) {
    organizationByInvite(inviteId: $inviteId) {
      id
      inviteeEmail
      status
      organization {
        name
        domain
        requireSSO
        ssoProvider
        logoUrl
        slug
        users {
          userId
          id
          role
          picture
          fullName
          email
        }
      }
    }
  }
`;
