import { colors } from "components/colors";
import { ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { TABLE_PADDING } from "../data_table_v2";

interface RowPropTypes {
  isHeader?: boolean;
  children: ReactNode;
  noHover?: boolean;
  noBorder?: boolean;
}

export function Row({
  children,
  isHeader,
  noHover,
  noBorder = false,
}: RowPropTypes) {
  return (
    <div
      className={`data_table_v2__row ${
        isHeader && "data_table_v2__row--header"
      } ${!noHover && "data_table_v2__row_has_hover"}`}
      style={{
        paddingLeft: TABLE_PADDING,
        paddingRight: TABLE_PADDING,
      }}
    >
      <View
        testID="data-table-row"
        style={[
          styles.row,
          isHeader && styles.rowHeader,
          noBorder && styles.noBorder,
        ]}
      >
        {children}
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    paddingVertical: 16,
    alignContent: "center",
    alignItems: "center",
    borderBottomColor: colors.brand.blackMinus90,
    borderBottomWidth: 1,
  },
  rowHeader: {
    paddingTop: 0,
  },
  noBorder: {
    borderBottomWidth: 0,
  },
});
