import React, { Fragment } from "react";
import { GestureResponderEvent, Pressable } from "react-native";

import { TextDecorationLine } from "./text";
import { TextSize } from "./text";
import { Text, TextColor, TextWeight } from "components/text_v2";
import { Link } from "react-router-dom";

interface TextLinkProps {
  /** Go to client side route. If supplied, `a` tag will be used. */
  href?: string;
  onPress?: (e: GestureResponderEvent) => void;
  text: string | React.ReactNode;
  size?: TextSize;
  decoration?: TextDecorationLine;
  color?: TextColor;
  weight?: TextWeight;
  testID?: string;
  inline?: boolean;
  target?: string;
  align?: "center" | "left" | "right";
}

export function TextLink(props: TextLinkProps) {
  const {
    href,
    onPress,
    size,
    text,
    decoration = "underline",
    color,
    weight,
    inline,
    target,
    align,
    testID,
  } = props;

  const styleObj = {
    display: inline ? "inline" : "flex",
  };

  const child = (
    <Fragment>
      <Pressable
        testID={testID}
        accessible={!href}
        onPress={onPress}
        // @ts-ignore
        style={[styleObj]}
      >
        <Text
          weight={weight}
          color={color || "eggplant-core"}
          decoration={decoration}
          size={size}
          align={align || "left"}
        >
          {text}
        </Text>
      </Pressable>
    </Fragment>
  );

  if (!href) {
    return child;
  }

  return (
    <Link
      data-testid={testID}
      to={href}
      target={target}
      style={{ ...styleObj, textDecoration: decoration }}
    >
      <Text
        weight={weight}
        color={color || "eggplant-core"}
        decoration={decoration}
        size={size}
        align={align || "left"}
      >
        {text}
      </Text>
    </Link>
  );
}
