import { StyleSheet, View } from "react-native";

import { Avatar } from "./avatar";
import { Spacer } from "./spacer";
import { Text } from "./text_v2";
import { colors } from "components/colors";

interface PersonInfoPropType {
  fullName: string;
  email: string;
  picture?: string;
}

export const PersonInfo: React.FC<PersonInfoPropType> = ({
  fullName,
  email,
  picture = "auto",
}) => {
  return (
    <View style={styles.root}>
      <Avatar name={fullName || ""} source={{ uri: picture }} size="lg" />
      <Spacer direction="row" size={8} />
      <View>
        <Text customColor={colors.brand.blackcore} size="xs">
          {fullName}
        </Text>
        <Spacer size={4} />
        <Text customColor={colors.brand.blackMinus30} size="xs">
          {email}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { flexDirection: "row", alignItems: "center" },
});
