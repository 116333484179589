import "./text_field.web.css";
import { FieldContainer } from "./field_container";
import { ChipsFieldProps } from "./chips_field";
import { ChipsInput } from "./chips_input";

// NEEDS TO MATCH NATIVE IMPLEMENTATION
export const ChipsField = (props: ChipsFieldProps) => {
  const {
    value,
    label,
    onChange,
    invalid,
    disabled,
    initialValue,
    placeholder,
    invalidText,
    description,
  } = props;

  return (
    <FieldContainer
      hasValue={!!value}
      variant="outlined"
      label={label}
      invalid={invalid}
      description={description}
      errorText={invalidText}
      height="unset"
    >
      <ChipsInput
        initialValue={initialValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FieldContainer>
  );
};
