import { Button } from "components/button_v2";
import { Icon } from "components/iconv2";
import {
  FavoriteType,
  useSaveFavorite,
} from "pages/homev2/hooks/use_save_favorite";
import { signInModalState } from "providers/app_loader";
import { useAuthV2 } from "providers/authv2";
import { useFavoritesFeatureFlag } from "providers/splitio";
import React from "react";
import { GestureResponderEvent, Pressable } from "react-native";
import { useRecoilState } from "recoil";
import { useAnalytics } from "../providers/analytics";

export const enum IconType {
  filled = "filled",
  overlay = "overlay",
}

type SaveFavoriteButtonProps = {
  favoriteId: string;
  favoriteType: FavoriteType;
  onlyIcon?: boolean;
  iconType?: IconType;
};
export function SaveFavoriteButton({
  favoriteId,
  favoriteType,
  onlyIcon = false,
  iconType = IconType.overlay,
}: SaveFavoriteButtonProps) {
  const favoritesFeatureFlag = useFavoritesFeatureFlag();
  const { authenticated } = useAuthV2();
  const analytics = useAnalytics();
  const [, setShowLoginModal] = useRecoilState(signInModalState);
  const { saveFavorite, isSaved, loading } = useSaveFavorite(
    favoriteId,
    favoriteType,
  );
  if (!favoritesFeatureFlag) {
    return null;
  }

  const onAddRemoveFavorites = (e?: GestureResponderEvent) => {
    if (e) {
      e.preventDefault();
    }
    analytics.event(`Click Favorite Button`, {
      "Logged In": authenticated,
    });
    if (authenticated) {
      saveFavorite();
    } else {
      setShowLoginModal(true);
    }
  };
  return (
    <>
      {onlyIcon ? (
        <Pressable onPress={onAddRemoveFavorites}>
          {iconType === IconType.filled ? (
            <Icon
              size="md"
              color={isSaved ? undefined : "eggplantcore"}
              name={isSaved ? "favorite-filled" : "favorite"}
            />
          ) : (
            <Icon
              color={isSaved ? "raspberrycore" : "backgroundOverlay"}
              name="favorites-saved"
            />
          )}
        </Pressable>
      ) : (
        <Button
          onPress={onAddRemoveFavorites}
          text={isSaved ? "Saved" : "Save"}
          loading={loading}
          appearance="secondary"
          size="small"
          iconName={isSaved ? "favorite-filled" : "favorite"}
          iconSpecialColor={isSaved ? "raspberrycore" : undefined}
        />
      )}
    </>
  );
}
