import { MobileHeader } from "components/layout/mobile_header";
import { MobileLayout } from "components/layout/mobile_layout";
import { Fragment, ReactNode } from "react";

interface OffsiteSpaceMobileLayoutProps {
  children: JSX.Element;
  actions: ReactNode;
}

export function OffsiteSpaceDetailsMobile({
  children,
  actions,
}: OffsiteSpaceMobileLayoutProps) {
  return (
    <MobileLayout
      header={<MobileHeader actions={actions} />}
      body={children}
      footer={<></>}
      stickyHeader={false}
      hasContentPadding={false}
    />
  );
}
