import { colors } from "components/colors";
import { Container } from "components/container";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { Icon } from "components/icon";
import { Icon as IconV2 } from "components/iconv2";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Tooltip } from "components/tooltip";
import { formatCurrency } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { differenceInMinutes } from "lib/time_utils";
import pluralize from "pluralize";
import { usePriceDiscoverabilityFeatureFlag } from "providers/splitio";
import React, { Fragment, useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import {
  BookingType,
  PromotionLine,
  QuoteDetail__OrderItemFragment,
  QuoteDetailsQuery,
  SpaceType,
  TaxLine,
} from "./graphql.generated";

export interface OrderBreakdownProps {
  currency: string;
  quote?: QuoteDetailsQuery["quote"];
  quoteError?: any;
  savePriceText?: string;
  showTextWontChargedYet?: boolean;
  showRefund?: boolean;
}

export function OrderBreakdown(props: OrderBreakdownProps) {
  const {
    currency,
    quote,
    quoteError,
    savePriceText,
    showTextWontChargedYet,
    showRefund,
  } = props;

  if (quoteError) {
    return null;
  }

  if (!quote) {
    return (
      <Container padding={16} center>
        <ActivityIndicator size="large" color="rgba(82,68,134,1)" />
      </Container>
    );
  }

  const { totalPrice, processingFee, taxLine, promotionLine, quoteItems } =
    quote;
  const finalPrice = showRefund ? 0 : totalPrice;

  return (
    <Container>
      {showTextWontChargedYet && (
        <Container paddingBottom={16}>
          <Text color={"black-50"} size={"micro"} align={"center"}>
            You won’t be charged yet
          </Text>
          <Spacer size={16} />
          <Divider />
        </Container>
      )}
      <OrderItems
        orderItems={quote.orderItems}
        currency={currency}
        quoteItems={quoteItems}
      />
      <Spacer size={8} />
      {promotionLine && (
        <Fragment>
          <PromotionLineItemView
            promotionLine={promotionLine}
            currency={currency}
          />
          <Spacer size={8} />
        </Fragment>
      )}
      {processingFee !== 0 && processingFee !== null && (
        <Container zIndex={1}>
          <Row alignItems="center" justifyContent="space-between">
            <Row alignItems="center">
              <Text size="xs">Service fee</Text>
              <Spacer size={8} />
              <Tooltip
                offset={8}
                content={
                  <View
                    style={{
                      backgroundColor: colors.brand.blackMinus30,
                      width: 230,
                      padding: 10,
                      borderRadius: 4,
                    }}
                  >
                    <Text color="white-core" size="xs">
                      This helps us run our platform and bring you the most
                      amazing curated spaces
                    </Text>
                  </View>
                }
              >
                <View>
                  <Icon name="help-circle" size="sm" />
                </View>
              </Tooltip>
            </Row>
            <Text size="xs">
              {formatCurrency(processingFee, getSystemLocale(), currency)}
            </Text>
          </Row>
          <Spacer size={8} />
        </Container>
      )}
      {taxLine && taxLine.percentage > 0 && (
        <View>
          <OrderTaxLineItemView currency={currency} taxLine={taxLine} />
          <Spacer size={8} />
        </View>
      )}
      {showRefund && (
        <View>
          <View style={styles.row}>
            <Text size="xs" color="spinach-core">
              Cancellation refund
            </Text>
            <Text size="xs" color="spinach-core">
              -{formatCurrency(totalPrice, getSystemLocale(), currency)}
            </Text>
          </View>
          <Spacer size={8} />
        </View>
      )}
      <Spacer size={8} />
      <Divider />
      <Spacer size={16} />
      <Row justifyContent="space-between">
        <Text weight="semibold" size={"xs"}>
          Total
        </Text>
        <Text testID="order-total-price" weight="semibold" size={"xs"}>
          {taxLine
            ? new Intl.NumberFormat(getSystemLocale(), {
                style: "currency",
                currency,
              }).format(finalPrice)
            : formatCurrency(finalPrice, getSystemLocale(), currency)}
        </Text>
      </Row>
      {savePriceText && (
        <Row justifyContent={"flex-end"}>
          <View style={styles.savedPriceView}>
            <Text color={"eggplant-core"} size={"xs"}>
              You{" "}
              <Text
                size={"xs"}
                color={"eggplant-core"}
                weight={"semibold"}
              >{`save ${savePriceText}`}</Text>{" "}
              off the usual price
            </Text>
          </View>
        </Row>
      )}
    </Container>
  );
}

interface OrderItemProps {
  orderItem: QuoteDetail__OrderItemFragment;
  currency: string;
}

const OrderItem = (props: OrderItemProps) => {
  const { orderItem, currency } = props;
  const hasMoreThanOneQuantity = orderItem.quantity > 1;

  if (!orderItem) {
    return null;
  }

  const booking = getBookingBreakdown(orderItem);
  const spaceType = orderItem.details.space.spaceType;

  const title =
    spaceType === SpaceType.MeetingRoom
      ? "Meeting room"
      : spaceType === SpaceType.DayPass
      ? "Day pass"
      : "Private office";

  return (
    <View>
      <Text size="xs" weight="semibold">
        {title}
      </Text>
      <Spacer size={4} />

      <View style={styles.row}>
        <Text size="xs">
          {formatCurrency(booking.unitPrice, getSystemLocale(), currency)} x{" "}
          {booking.duration}{" "}
          {booking.bookingType === BookingType.DailyBooking
            ? pluralize("day", booking.duration)
            : pluralize("hour", booking.duration)}
          {hasMoreThanOneQuantity ? ` x ${orderItem.quantity}` : ""}
        </Text>
        <Text size="xs">
          {booking.subTotal
            ? formatCurrency(booking.subTotal, getSystemLocale(), currency)
            : "-"}
        </Text>
      </View>
    </View>
  );
};

interface OrderItemsProps {
  orderItems: QuoteDetailsQuery["quote"]["orderItems"];
  quoteItems?: QuoteDetailsQuery["quote"]["quoteItems"];
  currency: string;
}

function OrderItems(props: OrderItemsProps) {
  const { orderItems, currency, quoteItems } = props;

  const [showMultiDayDPModal, setShowMultiDayDPModal] = useState(false);
  const isMultiDayDP = usePriceDiscoverabilityFeatureFlag();
  const hasMoreThanOneOrderItem = orderItems.length > 1;
  const singleOrderItem = orderItems[0];
  const booking = getBookingBreakdown(singleOrderItem);
  const hasMoreThanOneQuantity = singleOrderItem.quantity > 1;

  if (hasMoreThanOneOrderItem) {
    return (
      <View>
        {orderItems.map((item) => (
          <View>
            <OrderItem orderItem={item} currency={currency} />{" "}
            <Spacer size={8} />
          </View>
        ))}
      </View>
    );
  }

  let quoteItemPrices, quoteItem, hasDynamicPrice;
  if (quoteItems && quoteItems[0]) {
    quoteItem = quoteItems[0];
    quoteItemPrices = quoteItem.quoteItemPrices;
    hasDynamicPrice =
      quoteItemPrices?.some((p) => !!p.dynamicPriceRate) ||
      !!quoteItem.dynamicPriceRate;
  }

  if (
    isMultiDayDP &&
    quoteItem &&
    quoteItemPrices &&
    quoteItemPrices.length > 0
  ) {
    return (
      <View style={styles.orderItemContainer}>
        <View style={styles.priceAndDynamicIconContainer}>
          <Text
            decoration={"underline"}
            size="xs"
            onPress={() => setShowMultiDayDPModal(true)}
          >
            {formatCurrency(quoteItem.unitPrice, getSystemLocale(), currency)} x{" "}
            {booking.duration}{" "}
            {booking.bookingType === BookingType.DailyBooking
              ? pluralize("day", booking.duration)
              : pluralize("hour", booking.duration)}
            {hasMoreThanOneQuantity ? ` x ${singleOrderItem.quantity}` : ""}
          </Text>

          {hasDynamicPrice && (
            <Tooltip
              position={"top-right"}
              content={
                <View style={styles.tooltip}>
                  <Text size="xs">
                    We’ve chosen the best possible price for you based on your
                    selections.
                  </Text>
                </View>
              }
            >
              <View>
                <IconV2 name="alert-sign" />
              </View>
            </Tooltip>
          )}
        </View>
        <Text size="xs">
          {formatCurrency(
            booking.subTotal ?? booking.totalPrice,
            getSystemLocale(),
            currency,
          )}
        </Text>

        <DialogModal
          desktopWidth={560}
          mobileOffsetBottom={320}
          headerTitle={"Base price break down"}
          onClose={() => setShowMultiDayDPModal(false)}
          showCloseButton={true}
          isVisible={showMultiDayDPModal}
        >
          <View style={styles.multiPricesContainer}>
            {quoteItemPrices.map((quoteItemPrice) => (
              <View style={styles.multiPricesRow}>
                <Text
                  size="xs"
                  color={
                    quoteItemPrice.unitPrice < quoteItemPrice.originalUnitPrice
                      ? "eggplant-core"
                      : "black-70"
                  }
                >
                  {quoteItemPrice.startDate &&
                    new Intl.DateTimeFormat(getSystemLocale(), {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }).format(new Date(quoteItemPrice.startDate))}
                </Text>
                <Text
                  size="xs"
                  color={
                    quoteItemPrice.unitPrice < quoteItemPrice.originalUnitPrice
                      ? "eggplant-core"
                      : "black-70"
                  }
                >
                  {formatCurrency(
                    quoteItemPrice.unitPrice,
                    getSystemLocale(),
                    currency,
                  )}
                </Text>
              </View>
            ))}
            <Divider />
            <View style={styles.multiPricesRow}>
              <Text size="xs" weight={"semibold"}>
                Total base price
              </Text>
              <Text size="xs" weight={"semibold"}>
                {formatCurrency(
                  quoteItem.totalPrice,
                  getSystemLocale(),
                  currency,
                )}
              </Text>
            </View>
          </View>
        </DialogModal>
      </View>
    );
  }

  return (
    <View style={styles.row}>
      <View style={styles.priceAndDynamicIconContainer}>
        <Text size="xs">
          {formatCurrency(booking.unitPrice, getSystemLocale(), currency)} x{" "}
          {booking.duration}{" "}
          {booking.bookingType === BookingType.DailyBooking
            ? pluralize("day", booking.duration)
            : pluralize("hour", booking.duration)}
          {hasMoreThanOneQuantity ? ` x ${singleOrderItem.quantity}` : ""}
        </Text>
        {hasDynamicPrice && (
          <>
            <Spacer size={4} direction="row" />
            <Tooltip
              position={"top-right"}
              content={
                <View style={styles.tooltip}>
                  <Text size="xs">
                    We’ve chosen the best possible price for you based on your
                    selections.
                  </Text>
                </View>
              }
            >
              <View>
                <IconV2 name="alert-sign" />
              </View>
            </Tooltip>
          </>
        )}
      </View>
      <Text size="xs">
        {formatCurrency(
          booking.subTotal ?? booking.totalPrice,
          getSystemLocale(),
          currency,
        )}
      </Text>
    </View>
  );
}

interface OrderTaxLineItemViewProps {
  taxLine: TaxLine;
  currency: string;
}

function OrderTaxLineItemView(props: OrderTaxLineItemViewProps) {
  const { taxLine, currency } = props;

  return (
    <Row justifyContent="space-between">
      <Text size="xs">Taxes ({taxLine.percentage}%)</Text>
      <Text size="xs">
        {formatCurrency(taxLine.amount, getSystemLocale(), currency)}
      </Text>
    </Row>
  );
}

export interface PromotionLineItemViewProps {
  promotionLine: PromotionLine;
  currency: string;
}

export function PromotionLineItemView(props: PromotionLineItemViewProps) {
  const { promotionLine, currency } = props;

  return (
    <Container>
      <Row justifyContent="space-between">
        <Text size="xs">{promotionLine.description}</Text>
        <Text size="xs">
          -{" "}
          {formatCurrency(
            Math.abs(promotionLine.amount),
            getSystemLocale(),
            currency,
          )}
        </Text>
      </Row>
    </Container>
  );
}

export function getBookingBreakdown(
  orderItem: QuoteDetailsQuery["quote"]["orderItems"][0],
) {
  let bookingType = BookingType.HourlyBooking;
  let duration: number = 0;

  if (orderItem.details.__typename === "DailyBooking") {
    bookingType = BookingType.DailyBooking;
    duration = orderItem.details.days;
  } else if (orderItem.details.__typename === "HourlyBooking") {
    duration =
      differenceInMinutes(
        orderItem.details.endTime,
        orderItem.details.startTime,
      ) / 60;
  }

  if (duration === 0) {
    throw new Error("Expected booking order item in quote.");
  }

  return {
    bookingType,
    duration,
    totalPrice: orderItem.totalPrice,
    unitPrice: orderItem.unitPrice,
    subTotal: orderItem.subTotal,
  };
}
const styles = StyleSheet.create({
  savedPriceView: {
    backgroundColor: colors.brand.eggplantMinus90,
    width: "100%",
    borderRadius: 4,
    padding: 8,
    marginTop: 16,
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  orderItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  priceAndDynamicIconContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  multiPricesRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  multiPricesContainer: {
    flex: 1,
    gap: 8,
  },
  tooltip: {
    backgroundColor: colors.brand.whitecore,
    width: 230,
    padding: 10,
    borderRadius: 4,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
});
