import { CardsSaveIcon } from "components/cards_save_icon";
import { colors } from "components/colors";
import { LinkWrapper } from "components/link_wrapper";
import { BookingSpaceDetailFragment, SpaceType } from "core/graphql.generated";
import {
  SpaceCardContent,
  SpaceCardDynamicPrice,
  SpaceCardPhoto,
  SpaceCardTitleWithCapacity,
} from "pages/homev2/components/space_card_v2/space_card_template";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { LocationCardAddressWithDistance } from "pages/homev3/components/location_card_address_with_distance";
import React from "react";
import { StyleSheet, View } from "react-native";

interface ListingSpaceCardProps {
  space: BookingSpaceDetailFragment;
  onCardPress: () => void;
}

export function SpaceCard({ space, onCardPress }: ListingSpaceCardProps) {
  let url = `/offsite_spaces/${space.id}`;
  let minCapacity, maxCapacity;
  if (space.spaceType === SpaceType.DayOffice) {
    url += `?physicalSpaceID=${space.physicalSpaces[0]?.id}`;
  } else if (space.spaceType === SpaceType.MeetingRoom) {
    minCapacity = space.maxCapacity;
    maxCapacity = space.maxCapacity;
  }

  return (
    <View style={styles.card}>
      <LinkWrapper onPress={onCardPress} to={url}>
        <View style={styles.relativeContainer}>
          <SpaceCardPhoto imageSrc={space.images[0].small.url} />

          <CardsSaveIcon
            favoriteId={space.id}
            favoriteType={FavoriteType.Space}
          />
        </View>
        <SpaceCardContent>
          <SpaceCardTitleWithCapacity
            title={space.name}
            minCapacity={minCapacity}
            maxCapacity={maxCapacity}
          />
          <LocationCardAddressWithDistance
            currentUserDistance={space.location.currentUserDistance}
            shortAddress={space.location.address.city}
            locationName={space.location.name}
          />
          <SpaceCardDynamicPrice
            spaceType={space.spaceType}
            pricings={space.pricings}
            currency={space.currency}
            priceRanges={space.priceRanges}
            isPickedDate={false}
            outOfPolicy={space.locked}
          />
        </SpaceCardContent>
      </LinkWrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width: 300,
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    textDecoration: "none",
    display: "flex",
    minWidth: 0,
  },
  relativeContainer: {
    position: "relative",
  },
});
