import { appConfig } from "providers/config";

export type LoggerLevel = "fatal" | "error" | "warn" | "info" | "debug";

const levelValueMap: { [level in LoggerLevel]: number } = {
  fatal: 50,
  error: 40,
  warn: 30,
  info: 20,
  debug: 10,
};

interface Options {
  level?: LoggerLevel;
}

class Logger {
  private level: LoggerLevel;

  constructor(options: Options) {
    const { level = "info" } = options;
    this.level = level;
  }

  private log(level: LoggerLevel, message: string, data: unknown) {
    if (levelValueMap[level] >= levelValueMap[this.level]) {
      console.log(message, data ? JSON.stringify(data) : "");
    }
  }

  public fatal(message: string, data?: unknown) {
    this.log("error", message, data);
  }

  public error(message: string, data?: unknown) {
    this.log("error", message, data);
  }

  public warn(message: string, data?: unknown) {
    this.log("warn", message, data);
  }

  public info(message: string, data?: unknown) {
    this.log("info", message, data);
  }

  public debug(message: string, data?: unknown) {
    this.log("debug", message, data);
  }
}

export const logger = new Logger({
  level: appConfig.loggerLevel,
});

export function useLogger(): Logger {
  return logger;
}
