import { Avatar } from "components/avatar";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { TodayPage__RequestDetailsFragment } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { getExpireDays } from "pages/booking_requests/booking_requests_utils";
import { ExpireDaysBadge } from "pages/booking_requests/components/expire_days_badge";
import { StyleSheet, View } from "react-native";

interface RequestCardProps {
  request: TodayPage__RequestDetailsFragment;
}

export function RequestCard(props: RequestCardProps) {
  const { request } = props;

  const { reason, startDate, requester, id } = request;
  const mq = useMediaQuery();

  const expireDays = getExpireDays(startDate);

  return (
    <View
      style={[
        styles.container,
        mq.deviceQuery.mobile && styles.containerMobile,
      ]}
    >
      <View style={styles.profile}>
        <Avatar
          size="md"
          name={requester.fullName}
          source={requester.picture ? { uri: requester.picture } : undefined}
        />
        <Spacer size={8} direction="row" />
        <Text size="xs" weight="semibold">
          {requester.fullName}
        </Text>
      </View>
      <Spacer size={24} />
      <Text size="xs" weight="semibold">
        Reason for request
      </Text>
      <Spacer size={8} />
      <Text size="xs">{reason}</Text>
      <Spacer size={24} />
      <Text size="xs" weight="semibold">
        Expires in
      </Text>
      <Spacer size={8} />
      <ExpireDaysBadge days={expireDays} />

      <Spacer size={24} />

      <View style={styles.buttons}>
        <Button
          iconName="check-ui"
          href={`/admin/booking-requests?approveRequestId=${id}`}
          text="Approve"
        />
        <Spacer direction="row" size={8} />

        <Button
          href={`/admin/booking-requests?detailedRequestId=${id}`}
          appearance="textLink"
          text="See details"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    width: 330,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "#06001E1A",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 10,
  },

  containerMobile: {
    width: "100%",
  },
  profile: {
    flexDirection: "row",
    alignItems: "center",
  },
  expires: {
    paddingHorizontal: 3.5,
    paddingVertical: 4,
    backgroundColor: colors.brand.bananaMinus50,
    minWidth: 52,
    width: "fit-content",
    borderRadius: 4,
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
  },
});
