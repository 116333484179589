import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Dialog } from "components/dialog";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { DialogContent } from "core/dialog_content";
import { StyleSheet, View } from "react-native";

interface ReportingBudgetUpdateModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
  updateBudgetError?: string;
}

export function ReportingBudgetUpdateModal(
  props: ReportingBudgetUpdateModalProps,
) {
  const { onCancel, onConfirm, loading, updateBudgetError } = props;

  return (
    <Dialog visible mobileOffsetBottom={updateBudgetError ? 270 : 230}>
      <DialogContent>
        <Container center paddingHorizontal={24} paddingBottom={24}>
          <Text size="xl" align="center">
            Update monthly budget?
          </Text>
          <Spacer size={13} />
          <Text size="sm" align="center">
            This will change the maximum your organization can spend this month.
          </Text>
          <Spacer size={13} />
          <View style={styles.buttonsContainer}>
            <View style={styles.button}>
              <Button
                text="Cancel"
                onPress={onCancel}
                appearance="secondary"
                testID="budget-update-cancel"
              />
            </View>
            <Spacer direction="row" size={24} />
            <View style={styles.button}>
              <Button
                text="Update"
                onPress={onConfirm}
                loading={loading}
                testID="budget-update-confirm"
              />
            </View>
          </View>
          {!!updateBudgetError && (
            <>
              <Spacer size={16} />
              <Text color="grapefruit-core" size="sm" weight="bold">
                Could not update budget. Make sure the budget amount is greater
                than the total spend this month
              </Text>
              <Spacer size={5} />
            </>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  button: {
    flexGrow: 1,
  },
});
