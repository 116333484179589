import { useCurrentUserIpCoordinates } from "./use_current_user_ip_coordinates";
import { useReverseGeocodingQuery } from "./use_reserve_geocoding";

export function useCurrentUserIpPlaceQuery(options?: { skip?: boolean }) {
  const {
    data: ipCoordinates,
    loading: ipCoordinatesLoading,
    error: ipCoordinatesError,
  } = useCurrentUserIpCoordinates(options);

  const {
    data: place,
    loading: placeLoading,
    error: placeError,
  } = useReverseGeocodingQuery(ipCoordinates);

  if (options?.skip) {
    return {
      data: undefined,
      loading: false,
      error: undefined,
    };
  }

  return {
    data: place,
    loading: ipCoordinatesLoading || placeLoading,
    error: ipCoordinatesError || placeError,
  };
}
