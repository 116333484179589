import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { LocationPreviewCardLocationSpaces__SpaceDetailsFragment } from "core/graphql.generated";
import { uniqueBy } from "lib/array_utils";
import { sortAmenities } from "lib/sort_amenities";
import {
  SpaceCardAmenities,
  SpaceCardDynamicPrice,
  SpaceCardTemplate,
  SpaceCardTitleWithCapacity,
} from "pages/homev2/components/space_card_v2/space_card_template";
import { useMyTeamSearchParamsQuery } from "pages/team_spaces/hooks/use_my_team_search_params";
import React from "react";
import { Image, StyleSheet, View } from "react-native";

interface Props {
  space: LocationPreviewCardLocationSpaces__SpaceDetailsFragment;
}

interface PreviewMeetingRoomListProps {
  spaces: LocationPreviewCardLocationSpaces__SpaceDetailsFragment[];
}

export function PreviewMeetingRoomList({
  spaces,
}: PreviewMeetingRoomListProps) {
  if (spaces.length === 0) {
    return (
      <View style={styles.emptyPlaceholder}>
        <Text align="center">There are no meeting rooms found.</Text>
      </View>
    );
  }
  return (
    <>
      {spaces.map((space) => (
        <PreviewMeetingRoomItem key={space.id} space={space} />
      ))}
    </>
  );
}
function PreviewMeetingRoomItem({ space }: Props) {
  const { date } = useMyTeamSearchParamsQuery();

  let linkTo = `/offsite_spaces/${space.id}`;
  if (date) {
    linkTo += `?date=${date}`;
  }
  return (
    <SpaceCardTemplate
      key={space.id}
      data-testid={`meeting-room-ps`}
      linkTo={linkTo}
      appearance={"noShadow"}
    >
      <View style={styles.outerWrapper}>
        <Image
          source={{
            uri: space.images[0].small.url,
            width: 60,
            height: 60,
          }}
          style={styles.image}
        />
        <View style={styles.infoWrapper}>
          <View style={styles.titleWrapper}>
            <SpaceCardTitleWithCapacity
              minCapacity={space.maxCapacity}
              maxCapacity={space.maxCapacity}
              title={space.name}
            />
            {space.locked && <OutOfPolicyTag />}
          </View>
          <Spacer size={4} />

          {(!!space.amenities || !!space.location.amenities) && (
            <>
              <SpaceCardAmenities
                amenities={uniqueBy(
                  sortAmenities(space.amenities, space.location.amenities),
                  (item) => item.name,
                )}
              />
              <Spacer size={4} />
            </>
          )}
          <SpaceCardDynamicPrice
            spaceType={space.spaceType}
            pricings={space.pricings}
            currency={space.currency}
            priceRanges={space.priceRanges}
            isPickedDate={!!date}
            outOfPolicy={space.locked}
          />
        </View>
      </View>
    </SpaceCardTemplate>
  );
}

const styles = StyleSheet.create({
  outerWrapper: {
    flexDirection: "row",
    paddingVertical: 24,
  },
  titleWrapper: {
    flexDirection: "row",
    gap: 8,
  },
  image: {
    borderRadius: tokens.radius,
    borderWidth: 1,
    borderColor: tokens.colors.neutral.dark,
  },
  infoWrapper: {
    flex: 1,
    justifyContent: "space-between",
    paddingHorizontal: 9,
  },
  emptyPlaceholder: {
    paddingVertical: 44,
  },
});
