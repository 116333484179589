import {
  LocationDetails__SpacePriceDetailsFragment,
  PricingType,
} from "core/graphql.generated";
import { formatPrice } from "pages/homev2/components/space_card_v2/space_card_template";

import { LocationSpacePricings } from "../components/location_details_available_spaces_modal";

export function getSpacePricings(
  priceDetails: LocationDetails__SpacePriceDetailsFragment,
): LocationSpacePricings {
  const pricings: LocationSpacePricings = {};

  for (const pricing of priceDetails.pricings) {
    if (pricing.type === PricingType.Daily) {
      const normalPrice = `${formatPrice(
        pricing.price,
        priceDetails.currency,
      )}/day`;
      let discountPrice: string | undefined;
      if (
        priceDetails.priceRanges.daily.min &&
        pricing.price > priceDetails.priceRanges.daily.min
      ) {
        discountPrice = `${formatPrice(
          priceDetails.priceRanges.daily.min,
          priceDetails.currency,
        )}/day`;
      }
      pricings.dayPricing = { normal: normalPrice, discount: discountPrice };
    }
    if (pricing.type === PricingType.Hourly) {
      const normalPrice = `${formatPrice(
        pricing.price,
        priceDetails.currency,
      )}/hour`;
      let discountPrice: string | undefined;
      if (
        priceDetails.priceRanges.hourly.min &&
        pricing.price > priceDetails.priceRanges.hourly.min
      ) {
        discountPrice = `${formatPrice(
          priceDetails.priceRanges.hourly.min,
          priceDetails.currency,
        )}/hour`;
      }
      pricings.hourlyPricing = { normal: normalPrice, discount: discountPrice };
    }
  }

  return pricings;
}
