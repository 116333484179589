import "./page_container.css";
import { colors } from "components/colors";
import React from "react";

interface PageContainerProps {
  children: React.ReactNode;
  offsetFooter?: boolean;
  offsetHeader?: boolean;
  offsetHeaderNoScroll?: boolean;
  useNewBg?: boolean;
  noOffset?: boolean;
}

export function PageContainer(props: PageContainerProps) {
  const {
    children,
    offsetHeaderNoScroll = false,
    offsetFooter = true,
    offsetHeader = true,
    useNewBg = false,
    noOffset = false,
  } = props;

  let className = useNewBg ? "new-bg" : "";
  if (noOffset) {
    className += " page-container-no-offset";
  } else if (offsetHeaderNoScroll) {
    className += " page-container-offset-header-no-scroll";
  } else if (offsetFooter && offsetHeader) {
    className += " page-container-offset-footer-and-header";
  } else if (offsetFooter && !offsetHeader) {
    className += " page-container-offset-footer";
  } else if (!offsetFooter && offsetHeader) {
    className += " page-container-offset-header";
  } else if (noOffset) {
    className += " page-container-no-offset";
  }

  return (
    <div className={className} style={{ background: colors.background.main }}>
      {children}
    </div>
  );
}
