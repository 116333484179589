import { Spacer } from "components/spacer";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import { PageLayout } from "pages/page_layout";
import { useMediaQuery } from "lib/media_query";
import { Text } from "components/text_v2";
import { MobileLayout } from "components/layout/mobile_layout";
import { MobileHeader } from "components/layout/mobile_header";
import { AppHeader } from "components/app_header_v3/app_header";

interface PropsType {
  children: JSX.Element;
}

export const BookingRequestsLayout: React.FC<PropsType> = ({ children }) => {
  const {
    deviceQuery: { mobile: isMobile },
  } = useMediaQuery();

  if (isMobile) {
    return (
      <MobileLayout
        header={<MobileHeader title="Requests" />}
        body={
          <>
            {children}
            <Spacer size={40} />
          </>
        }
        footer={<BottomBarNavigation />}
      />
    );
  }

  return (
    <PageLayout
      header={<AppHeader />}
      body={
        <>
          <Spacer size={40} />
          <Text weight="bold" size="lg">
            Requests
          </Text>
          <Spacer size={40} />
          {children}
          <Spacer size={40} />
        </>
      }
      footer={
        <>
          <BottomBarNavigation />
          <Footer />
        </>
      }
    />
  );
};
