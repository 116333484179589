import { useMediaQuery } from "lib/media_query";
import { MobileHeader } from "./mobile_header";
import { AppHeaderV3Desktop } from "./desktop_header";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

export const DESKTOP_APP_HEADER_HEIGHT = 72;
export const MOBILE_APP_HEADER_HEIGHT = 120;

interface AppHeaderProps {
  withSearch?: boolean;
}

export function AppHeader(props: AppHeaderProps) {
  const { withSearch = false } = props;
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <MobileHeader withSearch={withSearch} />;
  }

  return <AppHeaderV3Desktop withSearch={withSearch} />;
}

interface NavigationMenuButtonProps {
  to: string;
  title: string;
}

export function NavigationMenuButton(props: NavigationMenuButtonProps) {
  const { to, title } = props;
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={classNames(
        "header-navigation-menu-button",
        isActive && "header-navigation-menu-button-selected",
      )}
    >
      {title}
    </Link>
  );
}
