import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { HistoryNavigationBlocker } from "components/history_navigation_blocker";

interface CreateEditBudgetNavigationBlockerProps {
  isActive: boolean;
  description?: string;
}
export function CreateEditNavigationBlocker(
  props: CreateEditBudgetNavigationBlockerProps,
) {
  const {
    description = "It looks like you’re in the middle of editing. All information entered will be lost if you leave this page.",
    isActive,
  } = props;
  return (
    <HistoryNavigationBlocker active={isActive}>
      {({ closePrompt, confirm }) => (
        <DialogModal
          testID="create-edit-budget-navigation-blocker-modal"
          isVisible
          onClose={closePrompt}
          title="Are you sure you want to leave?"
          subtitle={description}
          desktopWidth={552}
          bottomButtons={
            <>
              <Button
                appearance="secondary"
                text="Stay on this page"
                onPress={closePrompt}
              />
              <Button
                appearance="primary"
                text="Leave this page"
                onPress={confirm}
              />
            </>
          }
        />
      )}
    </HistoryNavigationBlocker>
  );
}
