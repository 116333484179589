import React from "react";
import { GestureResponderEvent } from "react-native";

import { Text, TextSize } from "./text";
import { tokens } from "./tokens";

export interface PressableTextProps {
  onPress: (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | GestureResponderEvent,
  ) => void;
  content: string;
  size?: TextSize;
}

// TODO: Remove this ans use Text component props
export const PressableText = ({
  onPress,
  content,
  size,
}: PressableTextProps) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Text size={size}>
      <span
        onClick={onPress}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        style={{
          color: hovered
            ? tokens.colors.primary.darker
            : tokens.colors.primary.dark,
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {content}
      </span>
    </Text>
  );
};
