import { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Container } from "components/container";
import { Dialog } from "components/dialog";
import { Divider } from "components/divider";
import { tokens } from "components/tokens";
import { DayInterval } from "lib/day_utils";

import {
  getDayIntervalFromBudgetsDateRangePickerOption,
  getSelectedPredeterminedOption,
  BudgetsDateRangePickerButton,
  budgetsDateRangePickerOptions,
} from "./budgets_date_range_picker_common";
import { Text } from "components/text_v2";
import { colors } from "components/colors";
import { DateRangeView } from "./budgets_date_range_view";
import { DialogContent } from "core/dialog_content";

interface BudgetsDateRangePickerMobileProps {
  value: DayInterval;
  onChange: (value: DayInterval) => void;
  earliestAllowedDate?: Date;
}

export function BudgetsDateRangePickerMobile(
  props: BudgetsDateRangePickerMobileProps,
) {
  const { value, onChange, earliestAllowedDate } = props;
  const [open, setOpen] = useState(false);
  const selectedPredeterminedOption = getSelectedPredeterminedOption(value);
  const [customOpen, setCustomOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseCustom = useCallback(() => {
    setCustomOpen(false);
  }, []);

  const handleSelectCustomOption = useCallback(() => {
    setCustomOpen(true);
  }, []);

  const handleSelectPredeterminedOption = useCallback(
    (value: string) => {
      onChange(getDayIntervalFromBudgetsDateRangePickerOption(value));
      setOpen(false);
    },
    [onChange],
  );

  const handleApplyCustomDateRange = useCallback(
    (value: DayInterval) => {
      setOpen(false);
      setCustomOpen(false);
      onChange(value);
    },
    [onChange],
  );

  const handleClearPress = useCallback(() => {
    setOpen(false);
    setCustomOpen(false);
    onChange(getDayIntervalFromBudgetsDateRangePickerOption("this_month"));
  }, [onChange]);

  return (
    <View>
      <BudgetsDateRangePickerButton
        value={value}
        selectedPredeterminedOption={selectedPredeterminedOption}
        onPress={handleOpen}
        isOpen={open}
      />
      <OptionSelectDialog
        open={open}
        onClose={handleClose}
        onSelectPredeterminedOption={handleSelectPredeterminedOption}
        onSelectCustomOption={handleSelectCustomOption}
      />
      <DateRangeView
        open={customOpen}
        onCancel={handleCloseCustom}
        value={value}
        onChange={handleApplyCustomDateRange}
        earliestAllowedDate={earliestAllowedDate}
        onClear={handleClearPress}
      />
    </View>
  );
}

interface OptionSelectDialogProps {
  open: boolean;
  onClose: () => void;
  onSelectPredeterminedOption: (value: string) => void;
  onSelectCustomOption: () => void;
}

function OptionSelectDialog(props: OptionSelectDialogProps) {
  const { onClose, open, onSelectCustomOption, onSelectPredeterminedOption } =
    props;

  return (
    <Dialog mobileOffsetBottom={368} visible={open} onRequestClose={onClose}>
      <Container
        borderRadius={tokens.radius}
        customColor="white"
        borderWidth={1}
        flex={1}
        shadow
      >
        <DialogContent headerTitle="Date Range" onHeaderLeftIconPress={onClose}>
          <View>
            {budgetsDateRangePickerOptions.map((o) => (
              <Pressable
                key={o.value}
                style={styles.option}
                testID={o.value}
                onPress={() => onSelectPredeterminedOption(o.value)}
              >
                <Text align="center">{o.label}</Text>
              </Pressable>
            ))}
            <Pressable
              testID="reports-date-range-picker-desktop-custom-button"
              style={styles.option}
              onPress={onSelectCustomOption}
            >
              <Text align="center">Custom</Text>
            </Pressable>
            <Divider />
            <Pressable style={styles.option} onPress={onClose}>
              <Text align="center" color="eggplant-core">
                Cancel
              </Text>
            </Pressable>
          </View>
        </DialogContent>
      </Container>
    </Dialog>
  );
}

const styles = StyleSheet.create({
  option: {
    padding: 16,
    height: 48,
  },
  monthNavigatorWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  dialogContent: {
    flexShrink: 1,
    minHeight: 0,
    overflow: "hidden",
  },
  scrollableArea: {
    flexShrink: 1,
    minHeight: 0,
    overflow: "scroll",
  },
  dialogFooter: {
    padding: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
