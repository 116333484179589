import { colors } from "components/colors";
import { OrganizationBudgetState } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { BudgetColor } from "pages/budgets/budget_colors";
import { useMemo } from "react";
import { View } from "react-native";
import {
  BudgetCardContainer,
  BudgetCardLegend,
  BudgetCardLegendItem,
  BudgetCardProgressBar,
  BudgetCardProgressBarItem,
  BudgetCardSubtitle,
  BudgetCardSubtitleLabelWithAmount,
  BudgetCardTitle,
} from "../../components/budget_card_template";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { Text } from "components/text_v2";

interface GroupBudget {
  name: string;
  color: string;
  limit: number;
  spend: number;
}

interface OrganizationBudgetOverviewCardProps {
  orgName?: string;
  monthlyLimit: number;
  totalSpent: number;
  groupBudgets?: GroupBudget[];
  remaining: number;
  inactive?: boolean;
  state?: OrganizationBudgetState;
  /**
   * We need to introduce this prop because this component on budgets dashboard redirects the user to the organization budget page
   * and we can't make it interactive here
   */
  interactiveLegend?: boolean;
}

export function OrganizationBudgetOverviewCard(
  props: OrganizationBudgetOverviewCardProps,
) {
  const {
    orgName,
    monthlyLimit,
    totalSpent,
    remaining,
    groupBudgets,
    state,
    interactiveLegend,
  } = props;
  const shouldShowWarning =
    state === OrganizationBudgetState.AboveWarningThreshold;

  const otherSpend = useMemo(() => {
    const spendFromGroups =
      groupBudgets?.reduce((acc, budget) => acc + budget.spend, 0) || 0;

    return (totalSpent || 0) > spendFromGroups
      ? totalSpent - spendFromGroups
      : 0;
  }, [totalSpent, groupBudgets]);
  const { progressBarItems, legendItems } =
    groupBudgets && groupBudgets.length > 0
      ? groupBudgets.reduce(
          (result, gB) => {
            result.progressBarItems.push({
              color: colors.budget[gB.color as BudgetColor].core,
              value: monthlyLimit > 0 ? gB.spend / monthlyLimit : gB.spend,
            });
            result.legendItems.push({
              color: colors.budget[gB.color as BudgetColor].core,
              legendLabel: gB.name,
              legendValue:
                monthlyLimit > 0
                  ? `${Math.floor((gB.spend / monthlyLimit) * 100)}%`
                  : `${formatCurrencyWithoutDecimal(
                      gB.spend,
                      getSystemLocale(),
                      "USD",
                    )}`,
            });

            return result;
          },
          { progressBarItems: [], legendItems: [] } as {
            progressBarItems: BudgetCardProgressBarItem[];
            legendItems: BudgetCardLegendItem[];
          },
        )
      : getDefaultItems(totalSpent, monthlyLimit, remaining, shouldShowWarning);

  if (groupBudgets && groupBudgets.length > 0 && otherSpend) {
    progressBarItems.push({
      color: colors.budget.other.core,
      value: monthlyLimit > 0 ? otherSpend / monthlyLimit : otherSpend,
    });

    legendItems.push({
      color: colors.budget.other.core,
      legendLabel: "Other",
      legendValue:
        monthlyLimit > 0
          ? `${Math.floor((otherSpend / monthlyLimit) * 100)}%`
          : `${formatCurrencyWithoutDecimal(
              otherSpend,
              getSystemLocale(),
              "USD",
            )}`,
    });
  }
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  return (
    <BudgetCardContainer testID="organization-budget-overview-card">
      <BudgetCardTitle warning={shouldShowWarning} title={orgName} />
      <BudgetCardSubtitle>
        <BudgetCardSubtitleLabelWithAmount
          label="Monthly limit:"
          amount={monthlyLimit}
        />
        <BudgetCardSubtitleLabelWithAmount
          warning={shouldShowWarning}
          label="Spent:"
          amount={totalSpent}
        />
      </BudgetCardSubtitle>
      <BudgetCardProgressBar
        height={isMobile ? 16 : 40}
        items={progressBarItems}
      />

      {interactiveLegend ? (
        groupBudgets && groupBudgets?.length > 0 ? (
          <BudgetCardLegend
            items={legendItems}
            columns={isMobile ? 1 : 3}
            collapsibleList={
              isMobile && groupBudgets && groupBudgets?.length > 0
            }
          />
        ) : (
          <View style={{ maxWidth: 400 }}>
            <BudgetCardLegend
              items={legendItems}
              columns={1}
              collapsibleList={
                isMobile && groupBudgets && groupBudgets?.length > 0
              }
            />
          </View>
        )
      ) : (
        <Text color="eggplant-core" weight="semibold" size="xs">
          View Details{" "}
        </Text>
      )}
    </BudgetCardContainer>
  );
}

function getDefaultItems(
  totalSpent: number,
  monthlyLimit: number,
  remaining: number,
  shouldShowWarning: boolean,
) {
  return {
    progressBarItems: [
      {
        color: shouldShowWarning
          ? colors.budget.over.core
          : colors.budget.other.core,
        value: monthlyLimit > 0 ? totalSpent / monthlyLimit : totalSpent,
      },
      {
        color: shouldShowWarning
          ? colors.budget.over.lightest
          : colors.budget.other.lightest,
        legendLabel: "Remaining",
        value:
          monthlyLimit > 0
            ? 100 - (totalSpent / monthlyLimit) * 100
            : totalSpent,
      },
    ],
    legendItems: [
      {
        color: shouldShowWarning
          ? colors.budget.over.core
          : colors.budget.other.core,
        legendLabel: "Spent",
        legendValue: formatCurrencyWithoutDecimal(
          totalSpent,
          getSystemLocale(),
          "USD",
        ),
      },
      {
        color: shouldShowWarning
          ? colors.budget.over.lightest
          : colors.budget.other.lightest,
        legendLabel: "Remaining",
        legendValue: formatCurrencyWithoutDecimal(
          remaining,
          getSystemLocale(),
          "USD",
        ),
      },
    ],
  };
}
