import { useMemo } from "react";
import { useHomeSearchParamsQuery } from "./use_home_search_params";

interface EmptyStateFilterTypes {
  onlyCapacity: boolean;
  onlyDateOrTime: boolean;
  dateAndCapacityOrAmenities: boolean;
  onlyAmenities: boolean;
  none: boolean;
}

export function useEmptyStateFilterTypes() {
  const params = useHomeSearchParamsQuery();

  const emptyStatefilterTypes: EmptyStateFilterTypes = useMemo(
    () => ({
      onlyCapacity:
        (!!params.minCapacity || !!params.maxCapacity) &&
        !params.date &&
        !params.startTime &&
        !params.endTime,
      onlyDateOrTime:
        !params.minCapacity &&
        !params.maxCapacity &&
        (!!params.date || !!params.startTime || !!params.endTime),
      onlyAmenities:
        !params.minCapacity &&
        !params.date &&
        !params.startTime &&
        !params.endTime &&
        !!params.amenities,
      dateAndCapacityOrAmenities:
        (!!params.date || !!params.startTime || !!params.endTime) &&
        (!!params.minCapacity || !!params.amenities?.length),
      none:
        !params.maxCapacity &&
        !params.minCapacity &&
        !params.endTime &&
        !params.startTime &&
        !params.date &&
        !params.amenities,
    }),
    [
      params.date,
      params.endTime,
      params.maxCapacity,
      params.minCapacity,
      params.startTime,
      params.amenities,
    ],
  );

  return emptyStatefilterTypes;
}
