import React from "react";
import { GridV2 } from "components/gridv2";
import { useMediaQuery } from "lib/media_query";
import { Carousel } from "components/carousel";

type WrapperProps = {
  children: React.ReactNode;
};
export function ChartContainerWrapper({ children }: WrapperProps) {
  const mq = useMediaQuery();
  if (mq.deviceQuery.mobile) {
    return <Carousel showIndicators={true}>{children}</Carousel>;
  }

  return (
    <GridV2 columns={2} columnGap={24} rowGap={24}>
      {children}
    </GridV2>
  );
}
