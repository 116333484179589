export const colors = {
  background: {
    main: "#FAFAFA",
    overlay: "#06001e66",
  },
  brand: {
    blackMinus10: "#1F1A35",
    blackMinus20: "#38334B",
    blackMinus30: "#514C61",
    blackMinus40: "#6A6678",
    blackMinus50: "#82808E",
    blackMinus60: "#9B99A5",
    blackMinus70: "#B4B3BC",
    blackMinus80: "#CDCCD2",
    blackMinus90: "#E6E5E8",

    blackcore: "#06001E",
    whitecore: "#FFFFFF",

    bananaMinus10: "#FFA82F",
    bananaMinus20: "#FFB146",
    bananaMinus30: "#FFBB5D",
    bananaMinus40: "#FFC574",
    bananaMinus50: "#FFCE8B",
    bananaMinus60: "#FFD8A3",
    bananaMinus70: "#FFE2BA",
    bananaMinus80: "#FFECD1",
    bananaMinus90: "#FFF5E8",
    bananacore: "#FF9E18",
    banana10: "#E58E16",
    banana20: "#CC7E13",
    banana30: "#B36F11",
    banana40: "#995F0E",
    banana50: "#804F0C",
    banana60: "#663F0A",
    banana70: "#4C2F07",
    banana80: "#332005",
    banana90: "#1A1002",

    eggplantMinus10: "#6A54B8",
    eggplantMinus20: "#7A67C0",
    eggplantMinus30: "#8B7AC8",
    eggplantMinus40: "#9B8DD0",
    eggplantMinus50: "#ACA0D8",
    eggplantMinus60: "#BDB3DF",
    eggplantMinus70: "#CDC6E7",
    eggplantMinus80: "#DED9EF",
    eggplantMinus90: "#EEECF7",
    eggplantcore: "#5941B0",
    eggplant10: "#503A9E",
    eggplant20: "#47348D",
    eggplant30: "#3E2E7B",
    eggplant40: "#35276A",
    eggplant50: "#2D2058",
    eggplant60: "#241A46",
    eggplant70: "#1B1335",
    eggplant80: "#120D23",
    eggplant90: "#090712",

    grapefruitMinus10: "#FF6A6B",
    grapefruitMinus20: "#FF7A7B",
    grapefruitMinus30: "#FF8B8C",
    grapefruitMinus40: "#FF9B9C",
    grapefruitMinus50: "#FFACAD",
    grapefruitMinus60: "#FFBDBD",
    grapefruitMinus70: "#FFCDCE",
    grapefruitMinus80: "#FFDEDE",
    grapefruitMinus90: "#FFEEEE",
    grapefruitcore: "#FF595A",
    grapefruit10: "#E55051",
    grapefruit20: "#CC4748",
    grapefruit30: "#B33E3F",
    grapefruit40: "#993536",
    grapefruit50: "#802D2D",
    grapefruit60: "#662424",
    grapefruit70: "#4C1B1B",
    grapefruit80: "#331212",
    grapefruit90: "#1A0909",

    raspberryMinus10: "#B45080",
    raspberryMinus20: "#BD638E",
    raspberryMinus30: "#C5779C",
    raspberryMinus40: "#CD8AAA",
    raspberryMinus50: "#D59EB9",
    raspberryMinus60: "#DEB1C7",
    raspberryMinus70: "#E6C5D5",
    raspberryMinus80: "#EED8E3",
    raspberryMinus90: "#F7EBF1",
    raspberrycore: "#AC3C72",
    raspberry10: "#9B3667",
    raspberry20: "#8A305B",
    raspberry30: "#782A50",
    raspberry40: "#672444",
    raspberry50: "#561E39",
    raspberry60: "#45182E",
    raspberry70: "#341222",
    raspberry80: "#220C17",
    raspberry90: "#11060B",

    spinachMinus10: "#1C8E46",
    spinachMinus20: "#359B5A",
    spinachMinus30: "#4FA86F",
    spinachMinus40: "#68B483",
    spinachMinus50: "#81C098",
    spinachMinus60: "#9ACDAD",
    spinachMinus70: "#B3DAC1",
    spinachMinus80: "#CDE6D6",
    spinachMinus90: "#E6F3EA",
    spinachcore: "#038231",
    spinach10: "#03752C",
    spinach20: "#026827",
    spinach30: "#025B22",
    spinach40: "#024E1D",
    spinach50: "#014118",
    spinach60: "#013414",
    spinach70: "#01270F",
    spinach80: "#011A0A",
    spinach90: "#000D05",

    googleBlue: "#4285F4",
    gradient: "linear-gradient(155.82deg, #5941B0 0%, #FF595A 100%)",
    backgroundMain: "#FAFAFA",
    backgroundOverlay: "rgba(6, 0, 30, 0.4)",
  },
  budget: {
    apricot: {
      core: "#F7996E",
      lighter: "#FBCCB6",
      lightest: "#FDE0D3",
    },
    banana: {
      core: "#FF9E18",
      lighter: "#FFCF8B",
      lightest: "#FFE2BA",
    },
    blueberry: {
      core: "#375492",
      lighter: "#9BA9C8",
      lightest: "#C3CCDE",
    },
    bubblegum: {
      core: "#C880B7",
      lighter: "#E3BFDB",
      lightest: "#EFD9E9",
    },
    cherry: {
      core: "#A30B37",
      lighter: "#D1859B",
      lightest: "#E3B6C3",
    },
    corn: {
      core: "#E5D352",
      lighter: "#F2E9A8",
      lightest: "#F7F2CB",
    },
    cucumber: {
      core: "#04A777",
      lighter: "#81D3BB",
      lightest: "#B4E5D6",
    },
    dragonfruit: {
      core: "#FF598B",
      lighter: "#FFB5CB",
      lightest: "#FFD7E3",
    },
    guava: {
      core: "#FFA3AF",
      lighter: "#FFD1D7",
      lightest: "#FFE3E7",
    },
    inactive: {
      core: "#514C61",
      lighter: "#83808E",
      lightest: "#CDCCD2",
    },
    kale: {
      core: "#107E7D",
      lighter: "#87BEBE",
      lightest: "#B7D8D8",
    },
    other: {
      core: "#5941B0",
      lighter: "#8B7AC8",
      lightest: "#CDC6E7",
    },
    over: {
      core: "#FF595A",
      lighter: "#C5C8EA",
      lightest: "#FFEEEE",
    },
    periwinkle: {
      core: "#8A91D4",
      lighter: "#D59DB8",
      lightest: "#DCDEF2",
    },
    raspberry: {
      core: "#AC3C72",
      lighter: "#D59DB8",
      lightest: "#E6C4D5",
    },
    sage: {
      core: "#8AA390",
      lighter: "#C5D1C7",
      lightest: "#DCE3DE",
    },
    seafoam: {
      core: "#84AFC9",
      lighter: "#C2D7E4",
      lightest: "#DAE7EF",
    },
    sky: {
      core: "#00A6ED",
      lighter: "#80D3F6",
      lightest: "#B2E4FA",
    },
    spinach: {
      core: "#038231",
      lighter: "#81C198",
      lightest: "#B3D9C1",
    },
  },
  shadow: {
    header: {
      shadowColor: "rgb(6, 0, 30)",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.1,
      shadowRadius: 20,
    },
  },
};
