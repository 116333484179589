import { Pressable, StyleSheet, View } from "react-native";
import { Button } from "components/button_v2";
import { tokens } from "components/tokens";
import { Icon } from "components/icon";
import { DialogFooterWrapper } from "core/dialog_content";
import { Divider } from "components/divider";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { colors } from "components/colors";

interface HomeFilterMobilePropTypes {
  onClose: () => void;
  handleResetFilter: () => void;
  handleSave: () => void;
  children: React.ReactNode;
}

export function FilterMobile(props: HomeFilterMobilePropTypes) {
  const { onClose, handleResetFilter, handleSave, children } = props;

  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <View style={styles.buttonsWrapper}>
          <Pressable
            style={[
              {
                paddingLeft: 16,
                flex: 1,
              },
            ]}
            onPress={onClose}
          >
            <Icon name="x-circle" />
          </Pressable>

          <View style={{ flex: 1 }}>
            <Text align="center" weight="bold">
              Filters
            </Text>
          </View>

          <Pressable
            onPress={handleResetFilter}
            style={{ flex: 1, padding: 12, paddingRight: 16 }}
          >
            <Text color="eggplant-core" align="right" weight="semibold">
              Clear all
            </Text>
          </Pressable>
        </View>
      </View>
      <Divider color="darker" />
      <View style={styles.childrenContainer}>{children}</View>
      <Divider color="darker" />
      <DialogFooterWrapper>
        <Spacer size={7} />
        <Button testID="save-filter" onPress={handleSave} text="See results" />
      </DialogFooterWrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.brand.backgroundMain,
    flex: 1,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden",
    width: "100%",
  },
  header: {
    borderTopLeftRadius: tokens.radius,
    borderTopRightRadius: tokens.radius,
    paddingTop: 16,
    paddingBottom: 4,
    backgroundColor: colors.brand.backgroundMain,
  },
  buttonsWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  childrenContainer: {
    flex: 1,
  },
});
