import { Button } from "components/button_v2";
import { LoginGoogleOrEmail } from "components/login_google_or_email";
import { TermsAndPolicy } from "components/sign_up_shared/terms_and_policy";
import { Text } from "components/text_v2";
import { useAuthEmailForm } from "components/use_auth_email_form";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import { StyleSheet, View } from "react-native";

export function LoginToBook() {
  const form = useAuthEmailForm();
  const { submit, submitting } = form;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <View style={isMobile ? styles.containerMobile : styles.container}>
      <Text
        size={"md"}
        weight={"semibold"}
        align={isMobile ? "left" : "center"}
      >
        Log in or sign up to book
      </Text>
      <LoginGoogleOrEmail form={form} />
      <Button
        text={"Continue"}
        size={"auto"}
        onPress={submit}
        loading={submitting}
        testID="signup-on-checkout-button"
      />
      <TermsAndPolicy />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 40,
    flex: 1,
    gap: 16,
    marginRight: 40,
  },
  containerMobile: {
    width: "100%",
    gap: 16,
    paddingTop: 24,
  },
});
