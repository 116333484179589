import { useMutation, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";

import { colors } from "components/colors";
import { DialogModal } from "components/dialog_modal";
import { Text as TextV2, Text } from "components/text_v2";
import { updateOrderMemoMutation } from "core/booking_preview";
import {
  CurrentUserInfoQuery,
  OrderStatus,
  Plan,
  UpdateOrderMemoMutation,
  UpdateOrderMemoMutationVariables,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { useOrderMemoFeatureFlag } from "providers/splitio";
import { useToast } from "providers/toast";
import React, { useCallback, useRef, useState } from "react";
import { StyleSheet, TextInput as RNTextInput, View } from "react-native";

import { formatDateTimeRange } from "../utils/formatDateTimeRange";
import { currentUserInfoGQLQuery } from "../../../core/booking_checkout";
import { TextInput } from "components/text_input";

interface BookingDetailDateRangeBarProps {
  status: OrderStatus;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  orderId: string;
  confirmationId?: string;
  memo?: string | null;
}

export function BookingDetailDateRangeBar({
  status,
  startDate,
  endDate,
  startTime,
  endTime,
  confirmationId,
  orderId,
  memo,
}: BookingDetailDateRangeBarProps) {
  const mq = useMediaQuery();
  const toast = useToast();
  const isMemoFlag = useOrderMemoFeatureFlag();
  const analytics = useAnalytics();
  const isCancelled = status === OrderStatus.Canceled;
  const [editMemoVisible, setEditMemoVisible] = useState(false);
  const [newMemo, setNewMemo] = useState(memo);
  const inputRef = useRef<RNTextInput | null>(null);

  const { data } = useQuery<CurrentUserInfoQuery>(currentUserInfoGQLQuery);
  const isPro = data?.currentUser?.organization?.plan === Plan.Pro;
  const [updateOrderMemo] = useMutation<
    UpdateOrderMemoMutation,
    UpdateOrderMemoMutationVariables
  >(updateOrderMemoMutation);

  const onSavedMemo = async () => {
    setEditMemoVisible(false);
    if (!orderId) {
      return;
    }
    try {
      await updateOrderMemo({
        variables: { orderID: orderId, memo: newMemo },
      });
      analytics.event("Update transaction", {
        memo: !!newMemo,
      });
      toast.notify({ message: "Memo successfully saved" });
    } catch {
      toast.notify({ message: "Error happened! Please try later." });
    }
  };

  const onShowAnimationEnd = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <View
      style={[
        styles.root,
        mq.deviceQuery.mobile && styles.rootMobile,
        isCancelled && styles.cancelled,
      ]}
    >
      <Text size={mq.deviceQuery.mobile ? "micro" : "md"} weight="semibold">
        {isCancelled && "Canceled: "}
        {formatDateTimeRange(
          "iiii, MMM d, yyyy",
          "to",
          startDate,
          endDate,
          startTime,
          endTime,
        )}
      </Text>
      {confirmationId && (
        <Text size={mq.deviceQuery.mobile ? "micro" : "xs"} color="black-70">
          Confirmation:{" "}
          <Text
            size={mq.deviceQuery.mobile ? "micro" : "xs"}
            color="black-70"
            weight="semibold"
          >
            {confirmationId}
          </Text>{" "}
          {isMemoFlag && isPro && (
            <TextV2
              size={mq.deviceQuery.mobile ? "micro" : "xs"}
              color={"eggplant-core"}
              weight={"bold"}
              onPress={() => setEditMemoVisible(true)}
              testID="memo-modal-button"
            >
              &middot; Memo
            </TextV2>
          )}
        </Text>
      )}
      <DialogModal
        isVisible={editMemoVisible}
        headerTitle="Edit memo"
        onClose={() => setEditMemoVisible(false)}
        desktopWidth={500}
        onShowAnimationEnd={onShowAnimationEnd}
        center={mq.deviceQuery.desktop}
        bottomButtons={
          <View style={{ width: mq.deviceQuery.mobile ? "100%" : 229 }}>
            <Button
              testID="memo-save-button"
              text="Save changes"
              onPress={onSavedMemo}
            />
          </View>
        }
      >
        <TextInput
          ref={inputRef}
          name="memo"
          value={newMemo || ""}
          placeholder={"What was this booking for?"}
          onChange={setNewMemo}
          numberOfLines={2}
          testID="memo-input"
        />
      </DialogModal>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 4,
    backgroundColor: colors.brand.blackMinus90,
    padding: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rootMobile: {
    padding: 16,
    flexDirection: "column",
    gap: 8,
    alignItems: "flex-start",
  },
  cancelled: {
    backgroundColor: colors.brand.grapefruitMinus70,
  },
});
