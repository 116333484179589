import { addMonths, format, isBefore, subMonths } from "date-fns";
import React, { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Container } from "components/container";
import { Icon } from "components/icon";
import { MonthView } from "components/month_view/month_view";
import { startOfDay } from "lib/date_utils";
import { Day, parseDay, toDay } from "lib/day_utils";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { Spacer } from "components/spacer";

interface FilterDatePickerProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
}

export function FilterDatePicker(props: FilterDatePickerProps) {
  const { value, onChange } = props;

  const [month, setMonth] = useState(value ? parseDay(value) : new Date());

  const handlePressPrevious = useCallback(() => {
    setMonth(subMonths(month, 1));
  }, [month, setMonth]);

  const handlePressNext = useCallback(() => {
    setMonth(addMonths(month, 1));
  }, [month, setMonth]);

  const handleSelectDate = useCallback(
    (date: Date) => {
      onChange(toDay(date));
    },
    [onChange],
  );

  return (
    <Container>
      <View style={styles.monthNavigatorWrapper}>
        <Text weight="bold" align="center">
          {format(month, "MMMM yyyy")}
        </Text>
        <View style={styles.monthNavigationButtons}>
          <Pressable style={styles.arrowWrapper} onPress={handlePressPrevious}>
            <Icon
              name="chevron-left"
              size={29}
              color={tokens.colors.primary["eggplant-core"]}
            />
          </Pressable>
          <Spacer direction="row" size={4} />
          <Pressable style={styles.arrowWrapper} onPress={handlePressNext}>
            <Icon
              name="chevron-right"
              size={29}
              color={tokens.colors.primary["eggplant-core"]}
            />
          </Pressable>
        </View>
      </View>
      <MonthView
        selectedInterval={
          value ? { start: parseDay(value), end: parseDay(value) } : null
        }
        month={month}
        topOffset={4}
        onSelectDay={handleSelectDate}
        isOutsideRange={(d) => isBefore(d, startOfDay(new Date()))}
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  arrowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    // height: 24,
    // width: 24,
  },
  monthNavigatorWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  monthNavigationButtons: {
    flexDirection: "row",
  },
});
