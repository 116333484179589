import { Dialog } from "components/dialog";
import { FilterDesktop } from "./filter_desktop";
import { FilterMobile } from "./filter_mobile";
import { useMediaQuery } from "lib/media_query";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spacer } from "components/spacer";
import { FilterCapacityPicker } from "./filter_capacity_picker";
import { FilterStartTimePicker } from "./filter_start_time_picker";
import { FilterDatePickerDesktop } from "./filter_datepicker_desktop";
import { FilterDatePickerMobile } from "./filter_datepicker_mobile";
import { Divider } from "components/divider";
import { FilterEndTimePicker } from "./filter_end_time_picker";
import { Text } from "components/text_v2";
import { useCallback, useEffect, useState } from "react";
import {
  HomeSearchParams,
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "pages/homev2/hooks/use_home_search_params";
import { useAnalytics } from "providers/analytics";

export interface CapacityOption {
  value: { min: number; max?: number };
  label: string;
}

export const capacityOptions: CapacityOption[] = [
  { label: "1 - 4", value: { min: 1, max: 4 } },
  { label: "5 - 9", value: { min: 5, max: 9 } },
  { label: "10 - 14", value: { min: 10, max: 14 } },
  { label: "15 - 20", value: { min: 15, max: 20 } },
  { label: "21+", value: { min: 21 } },
];

interface PropTypes {
  isOpen: boolean;
  onClose: () => void;
}

const emptyFilter = {
  date: null,
  startTime: null,
  endTime: null,
  minCapacity: null,
  maxCapacity: null,
};

export type NullablePartial<T> = { [P in keyof T]?: T[P] | null };

export function HeaderFilter({ isOpen, onClose }: PropTypes) {
  const mq = useMediaQuery();
  const filter = useHomeSearchParamsQuery();
  const updateSearchParams = useUpdateHomeSearchParamsMutation();
  const analytics = useAnalytics();
  const [localFilter, setLocalFilter] =
    useState<NullablePartial<HomeSearchParams>>(filter);
  useEffect(() => setLocalFilter(filter), [filter]);

  const handleChangeCapacity = useCallback(
    (label: string | null | undefined) => {
      const selected = capacityOptions.find((co) => co.label === label);
      setLocalFilter((prev) => ({
        ...prev,
        minCapacity: selected?.value.min || null,
        maxCapacity: selected?.value.max || null,
      }));
    },
    [],
  );

  const handleChangeDate = useCallback((date: string) => {
    setLocalFilter((prev) => ({
      ...prev,
      date: date || null,
      startTime: date ? prev.startTime : null,
      endTime: date ? prev.endTime : null,
    }));
  }, []);
  const handleChangeStartTime = useCallback((startTime: string) => {
    setLocalFilter((prev) => {
      let newEndTime = prev.endTime;

      if ((startTime && startTime >= prev.endTime!) || !startTime) {
        newEndTime = null;
      }

      return {
        ...prev,
        startTime,
        endTime: newEndTime,
      };
    });
  }, []);
  const handleChangeEndTime = useCallback((endTime: string) => {
    setLocalFilter((prev) => ({
      ...prev,
      endTime,
    }));
  }, []);

  const handleApplyFilter = useCallback(() => {
    updateSearchParams(Object.assign({}, emptyFilter, localFilter));

    analytics.event("Discover Filter", {
      Capacity: `${localFilter.minCapacity} - ${localFilter.maxCapacity}`,
      "Space Type": filter.spaceType,
    });
  }, [updateSearchParams, localFilter, analytics, filter.spaceType]);

  const handleResetLocalFilter = useCallback(() => {
    setLocalFilter(emptyFilter);
  }, []);

  const handleResetOriginal = useCallback(() => {
    setLocalFilter(filter);
  }, [filter]);

  const handleSave = useCallback(() => {
    handleApplyFilter();
    onClose();
  }, [handleApplyFilter, onClose]);
  const closeFilter = useCallback(() => {
    handleResetOriginal();
    onClose();
  }, [handleResetOriginal, onClose]);

  const filterContent = (
    <ScrollView>
      <View style={styles.contentWrapper}>
        <View style={styles.sectionWrapper}>
          <Spacer size={16} />
          <Text weight="bold">When</Text>
          <Spacer size={9} />
          {mq.sizeQuery.mdAndUp ? (
            <FilterDatePickerDesktop
              value={localFilter.date}
              onChange={handleChangeDate}
            />
          ) : (
            <FilterDatePickerMobile
              value={localFilter.date}
              onChange={handleChangeDate}
            />
          )}
          <Spacer size={8} />
          <Divider />
          <Spacer size={8} />
          <FilterStartTimePicker
            value={localFilter.startTime}
            onChange={handleChangeStartTime}
            disabled={!localFilter.date}
          />
          <Spacer size={8} />
          <Divider />
          <Spacer size={8} />
          <FilterEndTimePicker
            startTime={localFilter.startTime}
            value={localFilter.endTime}
            onChange={handleChangeEndTime}
            disabled={!localFilter.date || !localFilter.startTime}
          />
          <Spacer size={16} />
        </View>
        <Divider />
        <Spacer size={16} />
        <View style={styles.sectionWrapper}>
          <Text weight="bold">Additional filters for rooms and offices</Text>
          <Spacer size={9} />
          <FilterCapacityPicker
            value={
              capacityOptions.find(
                (co) => co.value.min === localFilter.minCapacity,
              )?.label
            }
            capacityOptions={capacityOptions}
            onChange={handleChangeCapacity}
          />
        </View>
      </View>
    </ScrollView>
  );

  return (
    <Dialog visible={!!isOpen} onRequestClose={closeFilter}>
      {mq.sizeQuery.mdAndUp ? (
        <FilterDesktop
          handleResetFilter={handleResetLocalFilter}
          handleSave={handleSave}
          onClose={closeFilter}
        >
          {filterContent}
        </FilterDesktop>
      ) : (
        <FilterMobile
          handleResetFilter={handleResetLocalFilter}
          handleSave={handleSave}
          onClose={closeFilter}
        >
          {filterContent}
        </FilterMobile>
      )}
    </Dialog>
  );
}

const styles = StyleSheet.create({
  contentWrapper: {
    paddingVertical: 16,
  },
  sectionWrapper: {
    paddingHorizontal: 16,
  },
});
