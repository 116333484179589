import { colors } from "components/colors";
import { Icon } from "components/iconv2";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import {
  OffsiteOrderLocationSpaces__SpaceDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { uniqueBy } from "lib/array_utils";
import { sortAmenities } from "lib/sort_amenities";
import { SpaceCardDynamicPrice } from "pages/homev2/components/space_card_v2/space_card_template";
import { Fragment } from "react";
import { StyleSheet, View } from "react-native";

interface EnhanceYourWorkDaySpaceCardProps {
  space: OffsiteOrderLocationSpaces__SpaceDetailsFragment;
}

export function EnhanceYourWorkDaySpaceCard(
  props: EnhanceYourWorkDaySpaceCardProps,
) {
  const { space } = props;
  const spaceTitle =
    space.spaceType === SpaceType.MeetingRoom ||
    space.spaceType === SpaceType.DayOffice
      ? space.name
      : "Day Pass";
  const amenities = uniqueBy(
    sortAmenities(space.amenities, space.location.amenities),
    (item) => item.name,
  ).filter((_amenity, index) => index < 3);
  return (
    <View key={space.id} style={styles.wrapper}>
      <View style={styles.outerWrapper}>
        <View>
          <img
            alt={space.name}
            style={{
              objectFit: "cover",
              height: 60,
              width: 60,
              borderRadius: 4,
            }}
            src={space.images[0].small.url}
          />
        </View>
        <Spacer direction="row" size={16} />
        <View style={styles.infoWrapper}>
          <View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text size="xs" weight="semibold">
                {spaceTitle}
              </Text>
              {space.locked && (
                <Fragment>
                  <Spacer direction="row" size={8} />
                  <OutOfPolicyTag />
                </Fragment>
              )}
              {space.spaceType !== SpaceType.DayPass && (
                <Fragment>
                  <Spacer direction="row" size={8} />
                  <Icon name="person" size="sm" />
                  <Text size="xs" align="center">
                    {space.maxCapacity}
                  </Text>
                </Fragment>
              )}
            </View>
            <Spacer size={4} />
            <View style={styles.amenities}>
              {amenities?.map((amenity, index) => (
                <Text key={amenity.id} size="xs">
                  {amenity.name}{" "}
                  {`${index < amenities?.length - 1 ? "· " : ""}`}
                </Text>
              ))}
            </View>
            <Spacer size={4} />
            <SpaceCardDynamicPrice
              spaceType={space.spaceType}
              pricings={space.pricings}
              currency={space.currency}
              priceRanges={space.priceRanges}
              isPickedDate={false}
              outOfPolicy={space.locked}
            />
          </View>
          <TextLink
            size="xs"
            weight="semibold"
            text="See details"
            decoration="none"
            href={
              space.spaceType !== SpaceType.DayOffice
                ? `/offsite_spaces/${space.id}`
                : `/offsite_spaces/${space.id}?physicalSpaceID=${space.physicalSpaces[0].id}`
            }
          />
        </View>
      </View>
    </View>
  );
}

export function EnhanceYourWorkDaySpaceCardMobile(
  props: EnhanceYourWorkDaySpaceCardProps,
) {
  const { space } = props;
  const spaceTitle =
    space.spaceType === SpaceType.MeetingRoom ||
    space.spaceType === SpaceType.DayOffice
      ? space.name
      : "Day Pass";
  const amenities = uniqueBy(
    sortAmenities(space.amenities, space.location.amenities),
    (item) => item.name,
  ).filter((_amenity, index) => index < 3);
  return (
    <Fragment>
      <View key={space.id} style={styles.outerWrapper}>
        <View>
          <img
            alt={space.name}
            style={{
              objectFit: "cover",
              height: 124,
              width: 80,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
            src={space.images[0].small.url}
          />
        </View>
        <View style={styles.infoWrapperMobile}>
          <View style={styles.rowCenter}>
            <Text size="xs" weight="semibold">
              {spaceTitle}
            </Text>
            {space.locked && (
              <Fragment>
                <Spacer direction="row" size={8} />
                <OutOfPolicyTag />
              </Fragment>
            )}
            {space.spaceType !== SpaceType.DayPass && (
              <Fragment>
                <Spacer direction="row" size={8} />
                <Icon name="person" size="sm" />
                <Text size="xs" align="center">
                  {space.maxCapacity}
                </Text>
              </Fragment>
            )}
          </View>
          <Spacer size={8} />
          <View style={styles.amenities}>
            {amenities?.map((amenity, index) => (
              <Text key={amenity.id} size="xs">
                {amenity.name} {`${index < amenities?.length - 1 ? "· " : ""}`}
              </Text>
            ))}
          </View>
          <Spacer size={8} />
          <SpaceCardDynamicPrice
            spaceType={space.spaceType}
            pricings={space.pricings}
            currency={space.currency}
            priceRanges={space.priceRanges}
            isPickedDate={false}
            outOfPolicy={space.locked}
          />
          <Spacer size={8} />
          <TextLink
            size="xs"
            weight="semibold"
            text="See details"
            decoration="none"
            href={
              space.spaceType !== SpaceType.DayOffice
                ? `/offsite_spaces/${space.id}`
                : `/offsite_spaces/${space.id}?physicalSpaceID=${space.physicalSpaces[0].id}`
            }
          />
        </View>
      </View>
      <Spacer size={24} />
    </Fragment>
  );
}

const styles = StyleSheet.create({
  outerWrapper: {
    flexDirection: "row",
  },
  wrapper: {
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderColor: "rgba(6, 0, 30, 0.1)",
  },
  infoWrapper: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
  },
  amenities: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  infoWrapperMobile: {
    flex: 1,
    borderRadius: 4,
    shadowColor: colors.brand.blackcore,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    justifyContent: "center",
    width: "100%",
    paddingLeft: 16,
  },
  rowCenter: { flexDirection: "row", alignItems: "center" },
});
