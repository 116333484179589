import { colors } from "components/colors";
import { Icon } from "components/icon";
import { Text } from "components/text_v2";
import { Pressable, View } from "react-native";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";

interface HomeMapLocationMarkerProps {
  onClick: () => void;
  isSelected: boolean;
  numOfSpaces?: number;
  hideCountIfSingle: boolean;
  outOfPolicy?: boolean;
}

export function HomeMapLocationMarker(props: HomeMapLocationMarkerProps) {
  const { onClick, hideCountIfSingle, isSelected, numOfSpaces, outOfPolicy } =
    props;

  const { spaceType } = useHomeSearchParamsQuery();

  const getIconColor = (hovered: boolean) => {
    if (isSelected) {
      return colors.brand.blackcore;
    }
    if (outOfPolicy) {
      return colors.brand.blackMinus30;
    }

    if (hovered) {
      return colors.brand.eggplant20;
    }
    return colors.brand.eggplantcore;
  };

  return (
    <Pressable onPress={onClick}>
      {({ hovered }: any) => {
        return (!hideCountIfSingle && numOfSpaces === 1) ||
          (spaceType && numOfSpaces && numOfSpaces > 1) ? (
          <View>
            <View
              style={{
                position: "absolute",
                top: "3.3px",
                margin: "0 auto",
                left: "0",
                right: "0",
              }}
            >
              <Text
                color="eggplant-core"
                size="micro"
                align="center"
                weight="semibold"
              >
                {numOfSpaces}
              </Text>
            </View>
            <Icon name="map-pin-filled" color={getIconColor(hovered)} />
          </View>
        ) : (
          <View style={{ height: 35 }}>
            <Icon name="map-pin" color={getIconColor(hovered)} />
          </View>
        );
      }}
    </Pressable>
  );
}
