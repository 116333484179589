import { colors } from "components/colors";
import { BOTTOM_BAR_HEIGHT } from "core/bottom_bar_navigation";
import { useMediaQuery } from "lib/media_query";
import { ReactNode, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

interface PageLayoutProps {
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
  noScroll?: boolean;
}

export function PageLayout(props: PageLayoutProps) {
  const { header, body, footer, noScroll = false } = props;
  const mq = useMediaQuery();
  const history = useHistory();
  useEffect(() => {
    if (window && history.action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [history.action]);

  return (
    <View
      style={[
        styles.pageWrapperDesktop,
        noScroll &&
          !mq.deviceQuery.mobile && {
            maxHeight: "100vh",
          },
      ]}
    >
      <View
        style={[
          styles.headerWrapper,
          mq.deviceQuery.mobile && styles.headerWrapperMobile,
        ]}
      >
        {header}
      </View>

      <View style={styles.contentWrapper} testID="page-layout-content-wrapper">
        <View
          style={[
            styles.content,
            mq.deviceQuery.mobile && styles.contentMobile,
          ]}
        >
          {body}
        </View>
      </View>
      {/* No need to wrap the footer since we dont have predefined styles */}
      {footer}
    </View>
  );
}

const styles = StyleSheet.create({
  pageWrapperDesktop: {
    minHeight: "100vh",
  },
  headerWrapper: {
    // @ts-ignore
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.brand.whitecore,
    zIndex: 2,
  },
  headerWrapperMobile: {
    backgroundColor: colors.brand.backgroundMain,
  },
  contentWrapper: {
    flexDirection: "row",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fafafa",
  },
  content: {
    flex: 1,
    maxWidth: 1088,
    paddingHorizontal: 24,
    flexShrink: 1,
  },
  contentMobile: {
    paddingBottom: BOTTOM_BAR_HEIGHT + 8,
  },
});
