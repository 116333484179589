import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { StyleSheet, View } from "react-native";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";

import { useMediaQuery } from "lib/media_query";
import { Button } from "components/button_v2";
import { EmptyStateGroupBTile } from "./empty_state_tiles";
import {
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { spaceNamesPlural } from "../components/space_list_container";
import { Fragment } from "react";
import { SpaceListCards } from "../components/space_list_cards";

interface EmptyStateWithOnlyCapacityFilterProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (location: HomePage__LocationDetailsFragment) => void;
  onFindMeASpacePress?: () => void;
}

export function EmptyStateWithOnlyCapacity(
  props: EmptyStateWithOnlyCapacityFilterProps,
) {
  const { spaces, onPreviewLocation, onFindMeASpacePress } = props;
  const mq = useMediaQuery();
  const { spaceType } = useHomeSearchParamsQuery();
  const spaceCount = spaces?.length || 0;
  const spaceName = spaceNamesPlural[spaceType || SpaceType.DayPass];
  const handleHomeSearchParamsChange = useUpdateHomeSearchParamsMutation();

  function handleClearPress() {
    handleHomeSearchParamsChange({
      maxCapacity: null,
      minCapacity: null,
    });
  }

  if (spaceCount > 0) {
    return (
      <Fragment>
        <SpaceListCards onPreviewLocation={onPreviewLocation} spaces={spaces} />
        <Spacer size={24} />
        <View testID="empty-state-with-capacity-with-spaces">
          <EmptyStateGroupBTile
            title="Can’t find what you’re looking for?"
            subTitle="Our team will personally find a space for you, even if they aren't on Flexspace yet."
            onFindMeASpacePress={onFindMeASpacePress}
          />
        </View>
      </Fragment>
    );
  }

  return (
    <View testID="empty-state-with-capacity-without-spaces">
      <Text size={mq.deviceQuery.mobile ? "md" : "lg"} weight="bold">
        No {spaceName} found
      </Text>
      <Spacer size={16} />
      <Text size="xs">
        Try changing or clearing some of your search criteria.
      </Text>
      <Spacer size={16} />
      <View style={styles.button}>
        <Button
          onPress={handleClearPress}
          appearance="secondary"
          text={`Clear number of ${
            spaceType === SpaceType.MeetingRoom ? "guests" : "attendees"
          }`}
        />
      </View>

      <Spacer size={24} />
      <EmptyStateGroupBTile onFindMeASpacePress={onFindMeASpacePress} />
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    maxWidth: 240,
  },
});
