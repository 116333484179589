import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Image, View } from "react-native";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useAnalytics } from "providers/analytics";
import { useAuthV2 } from "providers/authv2";
import { useGCalExtension } from "hooks/use_gcal_extension";
import { useMediaQuery } from "lib/media_query";

const SHOW_TOUR_PATH = "/?showTour=1";
const GOOGLE_CALENDAR_URL = "https://calendar.google.com/calendar";

const goToGoogleCalendar = () => {
  window.location.assign(GOOGLE_CALENDAR_URL);
};

export function SignUpGcalStepFinal() {
  const analytics = useAnalytics();
  const mq = useMediaQuery();
  const history = useHistory();
  const { authenticated } = useAuthV2();
  const { isLoading, setExtensionPopup } = useGCalExtension();

  useEffect(() => {
    if (!isLoading) {
      setExtensionPopup(authenticated);
    }
  }, [isLoading, setExtensionPopup, authenticated]);

  useEffect(() => {
    analytics.event("GCal Sign Up", {
      Step: "Success",
    });
  }, [analytics]);

  const onNextClick = () => {
    history.push(SHOW_TOUR_PATH);
  };

  return (
    <Content maxWidth={630}>
      <Heading size={mq.deviceQuery.mobile ? "lg" : "mega"} align="center">
        <div>You’re all set!</div>
        <div>Now, book your first room.</div>
      </Heading>
      <Spacer size={16} />
      <Text align="center" size="xs">
        Now that you’re all set up, it’s time to explore Flexspace for Google
        Calendar - the easiest way to book a room for your next meeting right
        from your calendar.
      </Text>

      <View style={{ alignItems: "center" }}>
        <Image
          source={{
            uri: "/images/gcal_step_final.svg",
            width: 240,
            height: 240,
          }}
        />
      </View>

      <Spacer size={40} />
      <View
        style={{
          width: mq.sizeQuery.mdAndUp ? 173 : "100%",
          marginHorizontal: "auto",
          alignItems: "stretch",
        }}
      >
        <View style={{ width: 220 }}>
          <Button
            appearance="primary"
            text="Go to Google Calendar"
            onPress={goToGoogleCalendar}
          />
        </View>
        <View style={{ width: 220 }}>
          <Button
            appearance="textLink"
            text="Explore Flexspace.ai"
            onPress={onNextClick}
          />
        </View>
      </View>
    </Content>
  );
}
