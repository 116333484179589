import { useMutation, useQuery } from "@apollo/client";
import { AsyncRenderer } from "components/AsyncRenderer";
import { Button } from "components/button_v2";

import { Card } from "components/card";
import { DialogModal } from "components/dialog_modal";
import { SelectInput } from "components/select/select_input";

import {
  BudgetPoliciesQuery,
  CreatePolicyMutation,
  CreatePolicyMutationVariables,
} from "core/graphql.generated";
import { useForm } from "hooks/use_form";
import { isEmpty } from "lib/lang_utils";
import { useMediaQuery } from "lib/media_query";
import { useToast } from "providers/toast";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { PolicyBasicForm } from "./components/policy_basic_form";
import { createPolicyMutation } from "./policy_create";
import {
  policyFormValidate,
  PolicyFormValidateInput,
} from "./policy_form_utils";
import { budgetPoliciesQuery } from "./policy_mutation_form";

interface Option {
  value: string;
  label: string;
}

interface AttachPolicyCardPropType {
  defaultPolicyId?: string;
  onSelect: (policyId: string) => void;
}

export const AttachPolicyCard: React.FC<AttachPolicyCardPropType> = ({
  defaultPolicyId,
  onSelect,
}) => {
  const {
    deviceQuery: { desktop: isDesktop },
  } = useMediaQuery();
  const toast = useToast();

  const [selectValue, setSelectValue] = useState<string | undefined>(
    defaultPolicyId,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const {
    data: policiesQueryData,
    error: policiesQueryError,
    loading: policiesQueryLoading,
  } = useQuery<BudgetPoliciesQuery>(budgetPoliciesQuery);
  const fetchedPolicies =
    policiesQueryData?.currentUser?.organization?.policies;
  const [createPolicy, { loading: createPolicyLoading }] = useMutation<
    CreatePolicyMutation,
    CreatePolicyMutationVariables
  >(createPolicyMutation);

  useEffect(() => {
    if (fetchedPolicies) {
      setOptions(
        fetchedPolicies.map(({ id, name }) => ({ value: id, label: name })),
      );
    }
  }, [fetchedPolicies]);

  const initialValues: PolicyFormValidateInput = {
    name: "",
    spaceTypes: {
      meetingRoom: true,
      privateOffice: true,
      dayPass: true,
    },
    locationItems: [],
  };

  const { values, errors, setFieldValue, isDirty, submit } = useForm({
    initialValues,
    validateOnChange: true,
    validate: (values) =>
      policyFormValidate(
        values,
        options.map((o) => o.label),
      ),
    onSubmit: async (values) => {
      try {
        const { name, spaceTypes } = values;
        if (spaceTypes) {
          const res = await createPolicy({
            variables: {
              input: { name, spaceTypes },
            },
          });
          const createdPolicy = res.data?.createBudgetPolicy;

          if (createdPolicy) {
            const { id, name } = createdPolicy;
            const newOption = {
              value: id,
              label: name,
            };
            setOptions((prev) => prev.concat(newOption));
            handleSelect(id);
          }

          setIsModalOpen(false);
        }
      } catch (error) {
        toast.notify({
          message: "There has been an error when creating the policy",
        });
      }
    },
  });

  const handleSelect = (policyId: string | undefined) => {
    if (policyId) {
      setSelectValue(policyId);
      onSelect(policyId);
    }
  };

  return (
    <Card title="Policy">
      <>
        <AsyncRenderer
          error={policiesQueryError}
          loading={policiesQueryLoading}
          data={policiesQueryData}
        >
          {() => {
            if (isEmpty(options)) {
              return <></>;
            }

            return (
              <SelectInput
                label="Policy name"
                topDescription="Apply a policy to set controls for where and how members can book spaces. Only admins can update policies."
                placeholder="Select"
                options={options}
                testID="policy-select"
                value={selectValue}
                onChange={handleSelect}
              />
            );
          }}
        </AsyncRenderer>
        <View style={styles.createPolicyBtnWrapper}>
          <Button
            appearance="textLink"
            iconName="plus"
            text="Create new policy"
            testID="create-new-policy-btn"
            onPress={() => setIsModalOpen(true)}
          />
          <DialogModal
            isVisible={isModalOpen}
            desktopWidth={780}
            headerTitle="Create new policy"
            onClose={() => setIsModalOpen(false)}
            bottomButtons={
              <Button
                text="Done"
                disabled={!isDirty || !isEmpty(errors)}
                size={isDesktop ? "medium" : "auto"}
                loading={createPolicyLoading}
                onPress={submit}
                testID="save-policy-button"
              />
            }
          >
            <PolicyBasicForm
              values={values}
              errors={errors}
              onChange={setFieldValue}
              useCard={false}
            />
          </DialogModal>
        </View>
      </>
    </Card>
  );
};

const styles = StyleSheet.create({
  createPolicyBtnWrapper: {
    flexDirection: "row",
    marginTop: 10,
    marginLeft: -10,
  },
});
