import * as Sentry from "@sentry/react";
import React from "react";
import { SWRConfig } from "swr";

interface SWRProviderProps {
  children?: React.ReactNode;
}

export function SWRProvider(props: SWRProviderProps) {
  const { children } = props;

  return (
    <SWRConfig
      value={{
        onError: (err, key) => {
          Sentry.captureException(err, {
            extra: { key },
          });
        },
        fetcher: apiFetcher,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        // see docs on https://github.com/vercel/swr/discussions/1400#discussioncomment-1249593
        // revalidateOnMount: false,
        revalidateIfStale: false,
      }}
    >
      {children}
    </SWRConfig>
  );
}

// this should replace default fetcher once we remove SWR for graphql requests
export async function apiFetcher(input: RequestInfo) {
  const res = await fetch(input);

  const body = await res.json();

  if (res.status >= 200 && res.status < 300) {
    return body;
  }

  throw new Error(body.message);
}
