import * as Sentry from "@sentry/react";
import React, { Fragment, useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { appConfig } from "providers/config";
import { usePrevious } from "hooks/use_previous";
import { hotjar } from "react-hotjar";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { gql, useQuery } from "@apollo/client";
import { useAuthV2 } from "./authv2";
import { CurrentUserAnalyticsQuery } from "core/graphql.generated";
import { getPageNameForAnalytics } from "lib/analytics_utils";

const hasHotjarAnalyticsCode = !!(
  appConfig.hotjarTrackingID && appConfig.hotjarSnippetVersion
);
const hasGAAnalyticsCode = !!appConfig.gaMeasurementID;
const hasMixpanelAnalyticsCode = !!appConfig.mixpanelToken;

if (hasHotjarAnalyticsCode) {
  hotjar.initialize(appConfig.hotjarTrackingID, appConfig.hotjarSnippetVersion);
}
if (appConfig.gaMeasurementID) {
  ReactGA.initialize(appConfig.gaMeasurementID);
}
if (appConfig.gtmId) {
  TagManager.initialize({ gtmId: appConfig.gtmId });
}
if (appConfig.mixpanelToken) {
  mixpanel.init(appConfig.mixpanelToken, {
    debug: process.env.NODE_ENV === "development",
  });
  mixpanel.register({
    Platform: "Web",
  });
}

export interface AnalyticsContext {
  group(groupID: string, traits?: GroupTraits): void;
  identify(userID: string, traits?: UserTraits): void;
  screen(name?: string, properties?: ScreenProperties): void;
  event(eventName: EcommerceEvent | string, properties?: TrackProperties): void;
}

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  group: () => {
    return;
  },
  identify: () => {
    return;
  },
  screen: () => {
    return;
  },
  event: () => {
    return;
  },
});

export function useAnalytics(): AnalyticsContext {
  return React.useContext(AnalyticsContext);
}

export interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export function AnalyticsProvider(props: AnalyticsProviderProps): JSX.Element {
  const { children } = props;
  const clients = useMemo(() => {
    const c: AnalyticsClient[] = [];

    if (hasGAAnalyticsCode) {
      c.push(gaClient);
    }

    if (hasHotjarAnalyticsCode) {
      c.push(hotjarClient);
    }

    if (hasMixpanelAnalyticsCode) {
      c.push(mixpanelClient);
    }

    c.push(sentryClient);

    if (appConfig.gtmId) {
      c.push(gtmClient);
    }

    if (appConfig.environment === "development") {
      c.push(logClient);
    }

    return c;
  }, []);

  const value = useMemo<AnalyticsContext>(() => {
    return {
      group: (...params) => {
        clients.forEach((client) => client.group(...params));
      },
      identify: (...params) => {
        clients.forEach((client) => client.identify(...params));
      },
      screen: (...params) => {
        clients.forEach((client) => client.screen(...params));
      },
      event: (...params) => {
        clients.forEach((client) => client.event(...params));
      },
    };
  }, [clients]);

  return (
    <AnalyticsContext.Provider value={value}>
      <AnalyticsSetup />
      {children}
    </AnalyticsContext.Provider>
  );
}

function AnalyticsSetup() {
  const analytics = useAnalytics();
  const location = useLocation();
  const currentPathname = location.pathname;
  const prevPathname = usePrevious(currentPathname);
  const { authenticated } = useAuthV2();

  const { data: currentUserQuery } = useQuery<CurrentUserAnalyticsQuery>(
    currentUserAnalyticsGQLQuery,
    {
      skip: !authenticated,
    },
  );
  const currentUser = currentUserQuery?.currentUser;
  const prevCurrentUser = usePrevious(currentUser);
  const currentUserId = currentUser?.id;
  const prevCurrentUserId = usePrevious(currentUser)?.id;
  const currentOrganizationId = currentUser?.organization?.id;
  const prevOrganizationId = usePrevious(currentOrganizationId);

  // Collect userID in analytics and update user properties
  useEffect(() => {
    if (
      currentUserId &&
      currentUser &&
      // we check if the user identity has changed
      (prevCurrentUserId !== currentUserId ||
        // we check if the organization identity has changed
        // after user created organization or accepted invite
        currentOrganizationId !== prevOrganizationId)
    ) {
      // during onboarding process, we do not want to identify this user yet after email verification
      // they should be identified after complete successful sign up. email in userId indicates he only did email verification
      if (currentUserId.includes("email")) {
        return;
      }

      // Custom requirement that we only want to identify users who have organizations
      if (!currentUser.organization) {
        return;
      }

      analytics.identify(currentUserId, {
        fullName: currentUser.fullName,
        email: currentUser.auth0PrimaryEmail,
        company: {
          name: currentUser.organization.name,
          id: currentUser.organization.id,
          source: currentUser.organization.createdWith || undefined,
        },
        createdAt: currentUser.createdAt
          ? new Date(currentUser.createdAt)
          : undefined,
        source: currentUser.createdWith,
        role: currentUser.role,
      });
    }
  }, [
    analytics,
    prevCurrentUserId,
    currentUserId,
    prevCurrentUser,
    currentUser,
    prevOrganizationId,
    currentOrganizationId,
  ]);

  // Collect pageviews automatically, so as to not instrument it per page
  useEffect(() => {
    if (currentPathname && prevPathname !== currentPathname) {
      analytics.screen();
    }
  }, [analytics, prevPathname, currentPathname]);

  return <Fragment />;
}

const gaClient: AnalyticsClient = {
  group: () => {},
  identify: (userID) => {
    ReactGA.gtag("config", appConfig.gaMeasurementID, { user_id: userID });
  },
  screen: () => {
    // GA4 automatically tracks history change
  },
  event: (name, properties) => {
    ReactGA.event(name, {
      category: properties?.category || "",
      label: properties?.label || "",
    });
  },
};

const gtmClient: AnalyticsClient = {
  group: () => {},
  identify: () => {},
  screen: (name) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: {
          ...(name && { title: name }),
          path: window.location.pathname,
          href: window.location.href,
        },
      },
    });
  },
  event: (name, props) => {
    TagManager.dataLayer({
      dataLayer: {
        event: name,
        ...props,
      },
    });
  },
};

const hotjarClient: AnalyticsClient = {
  group: () => {},
  identify: (userID, props) => {
    hotjar.identify(userID, { email: props?.email });
  },
  screen: () => {
    hotjar.stateChange(window.location.pathname);
  },
  event: () => {},
};

const sentryClient: AnalyticsClient = {
  group: () => {},
  identify: (_userID, traits) => {
    Sentry.setUser({ email: traits?.email });
  },
  screen: () => {},
  event: () => {},
};

const logClient: AnalyticsClient = {
  group: (groupID, traits) => {
    console.log("group", groupID, traits);
  },
  identify: (userID, traits) => {
    console.log("identify", userID, traits);
  },
  screen: (name, properties) => {
    console.log("screen", name, properties);
  },
  event: (name, properties) => {
    console.log("event", name, properties);
  },
};

const mixpanelClient: AnalyticsClient = {
  group: () => {},
  /**
   * Call identify when you know the identity of the current user, typically after login or signup.
   * We recommend against using identify for anonymous visitors to your site.
   */
  identify: (userID, traits) => {
    mixpanel.identify(userID);

    if (traits) {
      const props: { [key: string]: string | number | Date | undefined } = {
        // super props
        $name: traits.fullName,
        $email: traits.email,
        "Organization Name": traits.company?.name,
        "Organization UUID": traits.company?.id,
        "Organization Source": traits.company?.source,
        "Created At": traits.createdAt ? traits.createdAt : undefined,
        "User Source": traits.source,
        Role: traits.role,
        "auth0 ID": userID,
      };

      // clean up properties from undefined values
      Object.keys(props).map((key) => {
        if (props[key] === undefined) {
          delete props[key];
        }
      });

      mixpanel.people.set(props);
    }
  },
  screen: (screenName) => {
    const pageTitle = document.title;
    const pageURL = location.href;
    const pageName = getPageNameForAnalytics(window.location.pathname) || "";

    mixpanel.track("View Page", {
      "Page Name": screenName || pageName || pageURL,
      "Page Title": pageTitle,
      "Page URL": pageURL,
    });
  },
  event: (name, properties) => mixpanel.track(name, properties),
};

export interface AnalyticsClient {
  group(groupID: string, traits?: GroupTraits): void;
  identify(userID: string, traits?: UserTraits): void;
  screen(name?: string, properties?: ScreenProperties): void;
  event(eventName: EcommerceEvent | string, properties?: TrackProperties): void;
}

export interface Address {
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export interface UserTraits {
  address?: Address;
  age?: number;
  avatar?: string;
  birthday?: Date;
  company?: {
    name?: string;
    industry?: string;
    source?: string;
    id?: string | number;
    plan?: string;
    employeeCount?: number;
  };
  createdAt?: Date;
  description?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  id?: string;
  lastName?: string;
  fullName?: string;
  source?: string;
  role?: string;
  name?: string;
  phone?: string;
  title?: string;
  username?: string;
  website?: string;
}

export interface TrackProperties {
  /* Amount of revenue an event resulted in. This should be a decimal value, so a shirt worth $19.99 would result in a revenue of 19.99. */
  revenue?: number;
  /* Currency of the revenue an event resulted in. This should be sent in the ISO 4127 format. If this is not set, we assume the revenue to be in US dollars. */
  currency?: string;
  /* An abstract “value” to associate with an event. This is typically used in situations where the event doesn’t generate real-dollar revenue, but has an intrinsic value to a marketing team, like newsletter signups. */
  value?: number;

  /* Typically the object that was interacted with (e.g. 'Video') */
  category?: string;
  /* Useful for categorizing events (e.g. 'Fall Campaign') */
  label?: string;
  /* Any possible property */
  [key: string]: any;
}

export interface ScreenProperties {
  /* Name of the page. This is reserved for future use. */
  name?: string;
  /* Path portion of the URL of the page. Equivalent to canonical path which defaults to location.pathname from the DOM API. */
  path?: string;
  /* Full URL of the previous page. Equivalent to document.referrer from the DOM API. */
  referrer?: string;
  /* Query string portion of the URL of the page. Equivalent to location.search from the DOM API. */
  search?: string;
  /* Title of the page. Equivalent to document.title from the DOM API. */
  title?: string;
  /* Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. */
  url?: string;
  /* A list/array of kewords describing the content of the page. The keywords would most likely be the same as, or similar to, the keywords you would find in an html meta tag for SEO purposes. This property is mainly used by content publishers that rely heavily on pageview tracking. This is not automatically collected. */
  keywords?: string[];
}

export interface GroupTraits {
  /* Street address of a group. This should be a dictionary containing optional city, country, postalCode, state or street. */
  address?: Address;

  /* URL to an avatar image for the group */
  avatar?: string;

  /* Date the group’s account was first created. We recommend ISO-8601 date strings, but also accept Unix timestamps for convenience. */
  createdAt?: Date;

  /* Description of the group, like their personal bio */
  description?: string;

  /* Email address of group */
  email?: string;

  /* Number of employees of a group, typically used for companies */
  employees?: string;

  /* Unique ID in your database for a group */
  id?: string;

  /* Industry a user works in, or a group is part of */
  industry?: string;

  /* Name of a group */
  name?: string;

  /* Phone number of a group */
  phone?: string;

  /* Website of a group */
  website?: string;

  /* Plan that a group is in */
  plan?: string;
}

export type EcommerceEvent =
  /* Browsing Overview */
  | "Products Searched" // User searched for products
  | "Product List Viewed" // User viewed a product list or category
  | "Product List Filtered" // User filtered a product list or category

  /* Promotions Overview */
  | "Promotion Viewed" // User viewed promotion
  | "Promotion Clicked" // User clicked on promotion

  /* Core Ordering Overview */
  | "Product Clicked" // User clicked on a product
  | "Product Viewed" // User viewed a product details
  | "Product Added" // User added a product to their shopping cart
  | "Product Removed" // User removed a product from their shopping cart
  | "Cart Viewed" // User viewed their shopping cart
  | "Checkout Started" // User initiated the order process (a transaction is created)
  | "Checkout Step Viewed" // User viewed a checkout step
  | "Checkout Step Completed" // User completed a checkout step
  | "Payment Info Entered" // User added payment information
  | "Order Completed" // User completed the order
  | "Order Updated" // User updated the order
  | "Order Refunded" // User refunded the order
  | "Order Cancelled" // User cancelled the order

  /* Coupons Overview */
  | "Coupon Entered" // User entered a coupon on a shopping cart or order
  | "Coupon Applied" // Coupon was applied on a user’s shopping cart or order
  | "Coupon Denied" // Coupon was denied from a user’s shopping cart or order
  | "Coupon Removed" // User removed a coupon from a cart or order

  /* Wishlisting Overview */
  | "Product Added to Wishlist" // User added a product to the wish list
  | "Product Removed from Wishlist" // User removed a product from the wish list
  | "Wishlist Product Added to Cart" // User added a wishlist product to the cart

  /* Sharing Overview */
  | "Product Shared" // Shared a product with one or more friends
  | "Cart Shared" // Shared the cart with one or more friends

  /* Reviewing Overview */
  | "Product Reviewed"; // User reviewed a product

export interface Context {
  /* Whether a user is active. This is usually used to flag an .identify() call to just update the traits but not “last seen.”*/
  active?: boolean;
  /* dictionary of information about the current application, containing name, version and build. This is collected automatically from our mobile libraries when possible.*/
  app?: {
    name?: string;
    version?: string;
    build?: string;
  };
  /* Dictionary of information about the campaign that resulted in the API call, containing name, source, medium, term and content. This maps directly to the common UTM campaign parameters.*/
  campaign?: {
    name?: string;
    source?: string;
    medium?: string;
    term?: string;
    content?: string;
  };
  /* Dictionary of information about the device, containing id, manufacturer, model, name, type and version*/
  device?: {
    type?: string;
    id?: string;
    advertisingID?: string;
    adTrackingEnabled?: boolean;
    manufacturer?: string;
    model?: string;
    name?: string;
  };
  /* Current user’s IP address*/
  ip?: string;
  /* Dictionary of information about the library making the requests to the API, containing name and version*/
  library?: {
    name?: string;
    version?: string;
  };
  /* Locale string for the current user, for example en-US*/
  locale?: string;
  /* Dictionary of information about the user’s current location, containing city, country, latitude, longitude, region and speed*/
  location?: {
    latitude?: number;
    longitude?: number;
  };
  /* Dictionary of information about the current network connection, containing bluetooth, carrier, cellular and wifi*/
  network?: {
    bluetooth?: boolean;
    carrier?: string;
    cellular?: boolean;
    wifi?: boolean;
  };
  /* Dictionary of information about the operating system, containing name and version*/
  os?: {
    name?: string;
    version?: string;
  };
  /* Dictionary of information about the current page in the browser, containing hash, path, referrer, search, title and url. Automatically collected by Analytics.js.*/
  page?: ScreenProperties;
  /* Dictionary of information about the way the user was referred to the website or app, containing type, name, url and link*/
  referrer?: {
    type?: string;
    name?: string;
    url?: string;
    link?: string;
  };
  /* Dictionary of information about the device’s screen, containing density, height and width*/
  screen?: {
    density?: number;
    height?: number;
    width?: number;
  };
  /* Timezones are sent as tzdata strings to add user timezone information which might be stripped from the timestamp. Ex?: America/New_York*/
  timezone?: string;
  /* Group / Account ID. This is useful in B2B use cases where you need to attribute your non-group calls to a company or account. It is relied on by several Customer Success and CRM tools.*/
  groupID?: string;
  /* Dictionary of traits of the current user. This is useful in cases where you need to track an event, but also associate information from a previous identify call. You should fill this object the same way you would fill traits in an identify call.*/
  traits?: UserTraits;
  /* User agent of the device making the request*/
  userAgent?: string;
}

const currentUserAnalyticsGQLQuery = gql`
  query CurrentUserAnalytics {
    currentUser {
      id
      createdWith
      createdAt
      role
      auth0PrimaryEmail
      fullName
      email
      organization {
        id
        name
        createdAt
        createdWith
      }
    }
  }
`;
