import React from "react";
import { ActivityIndicator } from "react-native";

interface SpinnerProps {
  size?: "small" | "large";
}

export function Spinner(props: SpinnerProps) {
  const { size } = props;
  return <ActivityIndicator size={size || "large"} color="rgba(82,68,134,1)" />;
}
