import "./grid.web.css";

import React from "react";
import {
  ColumnGridColProps,
  ColumnGridProps,
  ColumnGridRowProps,
} from "./grid";

export function ColumnGrid(props: ColumnGridProps) {
  const { children, style } = props;

  return (
    <div style={style} className="column-grid">
      {children}
    </div>
  );
}

export function ColumnGridRow(props: ColumnGridRowProps) {
  const { children, style } = props;

  return (
    <div style={style} className="column-grid-row">
      {children}
    </div>
  );
}

export function ColumnGridCol(props: ColumnGridColProps) {
  const { children, cols, style } = props;

  return (
    <div style={style} className={`column-grid-col column-grid-col-${cols}`}>
      {children}
    </div>
  );
}
