import { AsyncState } from "./use_async_fn";

type Action<T = any> =
  | {
      type: "ASYNC_REQUEST";
    }
  | {
      type: "ASYNC_SUCCESS";
      data: T;
    }
  | {
      type: "ASYNC_FAILED";
      error: Error;
    };

type Reducer<T = any> = (
  prevState: AsyncState<T>,
  action: Action<T>,
) => AsyncState<T>;

function reducer<T = any>(
  prevState: AsyncState<T>,
  action: Action<T>,
): AsyncState<T> {
  switch (action.type) {
    case "ASYNC_REQUEST":
      return {
        ...prevState,
        loading: true,
      };
    case "ASYNC_SUCCESS":
      return {
        ...prevState,
        loading: false,
        data: action.data,
      };
    case "ASYNC_FAILED":
      return {
        ...prevState,
        loading: false,
        error: action.error,
      };
    default:
      throw new Error(`Unsupported action ${action}`);
  }
}

function createReducer<T>(): Reducer<T> {
  return reducer;
}

export function useAsyncReducer<T = any>() {
  return createReducer<T>();
}
