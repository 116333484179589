import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Switch } from "./switch";
import { Text } from "./text_v2";

interface LabeledSwitchProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  leftLabel?: boolean;
}

export function LabeledSwitch(props: LabeledSwitchProps) {
  const { label, value, onChange, leftLabel = false } = props;

  return (
    <Pressable onPress={() => onChange(!value)}>
      <View
        style={[
          styles.wrapper,
          {
            flexDirection: leftLabel ? "row-reverse" : "row",
            justifyContent: leftLabel ? "flex-end" : "flex-start",
          },
        ]}
      >
        <Switch value={value} onChange={onChange} />
        <Text size="xs">{label}</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: "center",
    gap: 8,
  },
});
