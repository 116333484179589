import { Pressable, View, StyleSheet } from "react-native";
import { Image } from "react-native";
import { Text } from "components/text_v2";
import { Row } from "components/row";
import { colors } from "components/colors";

interface GoogleButtonProps {
  onPress: () => void;
}

export function GoogleButton(props: GoogleButtonProps) {
  const { onPress } = props;
  return (
    <View>
      <Pressable
        testID="google-button"
        style={styles.googleButtonWrapper}
        onPress={onPress}
      >
        <Row justifyContent="center" alignItems="center" spacing={8}>
          <Image
            source={{
              uri: "/images/google-icon.svg",
              width: 16,
              height: 16,
            }}
          />
          <Text
            customColor={colors.brand.googleBlue}
            weight="semibold"
            size="xs"
          >
            Continue with Google
          </Text>
        </Row>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  googleButtonWrapper: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.brand.googleBlue,
    backgroundColor: "white",
  },
});
