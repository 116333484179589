import { AsyncRenderer } from "components/AsyncRenderer";
import { LoginToSee } from "components/login_to_see";
import { Spacer } from "components/spacer";

import { useTab } from "hooks/use_tab";
import { useAnalytics } from "providers/analytics";
import { useAuthV2 } from "providers/authv2";
import { useEffect } from "react";
import { View } from "react-native";
import { ArchiveBooking } from "./components/archive_booking";
import { BookingSkeleton } from "./components/booking_skeleton";
import { BookingTab, BookingTabs, tabOptions } from "./components/booking_tabs";
import { BookingsContent } from "./components/bookings_content";
import { InviteOthersDialog } from "./components/invite_others_dialog";
import { UpcomingBooking } from "./components/upcoming_booking";
import { useBookingQuery } from "./hooks/useBookingQuery";

import { BookingPageLayout } from "./layout/booking_page_layout";

export function Bookings() {
  const analytics = useAnalytics();

  const [tab, setTab] = useTab(tabOptions.map((o) => o.value));
  const { data, error, loading } = useBookingQuery({});

  const { authenticated } = useAuthV2();
  useEffect(() => {
    analytics.event("View Bookings", { category: "Post booking" });
  }, [analytics]);

  return (
    <BookingPageLayout>
      {authenticated ? (
        <View>
          <BookingTabs value={tab} onChange={setTab} />
          <Spacer size={24} />
          <AsyncRenderer
            error={error}
            loading={loading}
            data={data}
            loadingHandler={() => <BookingSkeleton tab={tab} />}
          >
            {({ upcomingBookings, archiveBookings }) => (
              <View>
                {tab === BookingTab.Upcoming && (
                  <BookingsContent
                    bookings={upcomingBookings}
                    card={UpcomingBooking}
                  />
                )}

                {tab === BookingTab.Archive && (
                  <BookingsContent
                    bookings={archiveBookings}
                    card={ArchiveBooking}
                  />
                )}
              </View>
            )}
          </AsyncRenderer>
          <InviteOthersDialog
            onInviteComplete={() =>
              analytics.event("Send Invitations", {
                category: "Post booking",
                Source: "Booking List",
              })
            }
          />
        </View>
      ) : (
        <LoginToSee
          headerText={"Log in to see bookings"}
          contentText={"You can book spaces once you've logged in."}
        />
      )}
    </BookingPageLayout>
  );
}
