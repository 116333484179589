import { CardsSaveIcon } from "components/cards_save_icon";
import { PreferredBadge } from "components/preferred_badge";
import { WhoIsHere } from "components/who_is_here";
import {
  HomePage__SpaceDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { uniqueBy } from "lib/array_utils";
import { today } from "lib/day_utils";
import { sortAmenities } from "lib/sort_amenities";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";
import { getPriceTagText } from "../utils/get_price_tag_text";
import {
  SpaceCardAddressWithDistance,
  SpaceCardAmenities,
  SpaceCardContent,
  SpaceCardDynamicPrice,
  SpaceCardOutOfPolicyBadge,
  SpaceCardPhoto,
  SpaceCardPrice,
  SpaceCardTemplate,
  SpaceCardTitleWithCapacity,
  SpaceCardWhoIsHere,
} from "./space_card_v2/space_card_template";

interface ListingSpaceCardProps {
  space: HomePage__SpaceDetailsFragment;
  spaceType: SpaceType;
}

export function ListingSpaceCardV2({
  space,
  spaceType,
}: ListingSpaceCardProps) {
  const filter = useHomeSearchParamsQuery();
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  const sortedAmenities = useMemo(
    () =>
      filter.amenities
        ? uniqueBy(
            sortAmenities(
              space.amenities,
              space.location.amenities,
              filter.amenities,
            ),
            (item) => item.name,
          )
        : [],
    [filter.amenities, space.amenities, space.location.amenities],
  );

  let forwardSearch = ``;

  if (spaceType === SpaceType.MeetingRoom) {
    if (filter.date) {
      forwardSearch += `?date=${filter.date}`;

      if (filter.startTime) {
        forwardSearch += `&startTime=${filter.startTime}`;
      }
      if (filter.endTime) {
        forwardSearch += `&endTime=${filter.endTime}`;
      }
    }
  } else if (spaceType === SpaceType.DayPass) {
    if (filter.date) {
      forwardSearch += `?startDate=${filter.date}&endDate=${filter.date}`;
    }
  }

  return (
    <SpaceCardTemplate
      key={space.id}
      testId={spaceType === SpaceType.MeetingRoom ? "meeting-room" : "day-pass"}
      linkTo={`/offsite_spaces/${space.id}${forwardSearch}`}
    >
      <View style={styles.relativeContainer}>
        <SpaceCardPhoto
          imageSrc={space.images[0].small.url}
          hasOverlay={space.locked}
        />
        <CardsSaveIcon
          favoriteId={space.id}
          favoriteType={FavoriteType.Space}
        />
      </View>

      <SpaceCardContent>
        <SpaceCardTitleWithCapacity
          title={
            spaceType === SpaceType.MeetingRoom
              ? space.name
              : space.location.name
          }
          minCapacity={
            spaceType === SpaceType.MeetingRoom ? space.maxCapacity : undefined
          }
          maxCapacity={
            spaceType === SpaceType.MeetingRoom ? space.maxCapacity : undefined
          }
        />
        <SpaceCardAddressWithDistance
          currentUserDistance={space.location.currentUserDistance}
          locationLat={space.location.address.latitude}
          locationLng={space.location.address.longitude}
          shortAddress={space.location.address.city}
          fullAddress={space.location.address.streetAddress}
          locationName={
            spaceType === SpaceType.MeetingRoom
              ? space.location.name
              : undefined
          }
        />
        {sortedAmenities.length > 0 && (
          <SpaceCardAmenities amenities={sortedAmenities} />
        )}
        {space.priceRanges ? (
          <SpaceCardDynamicPrice
            spaceType={spaceType}
            pricings={space.pricings}
            currency={space.currency}
            priceRanges={space.priceRanges}
            isPickedDate={!!filter.date}
            outOfPolicy={space.locked}
          />
        ) : (
          <SpaceCardPrice>
            {getPriceTagText({
              pricings: space.pricings,
              currency: space.currency,
            })}
          </SpaceCardPrice>
        )}
        {isPreferredFlag && space.preferred && (
          <PreferredBadge variant={"preferredOnly"} />
        )}
      </SpaceCardContent>

      {space.locked ? (
        <SpaceCardOutOfPolicyBadge />
      ) : (
        <SpaceCardWhoIsHere>
          <WhoIsHere
            date={filter.date || today()}
            peoples={space.location.bookings.map((b) => b.user)}
          />
        </SpaceCardWhoIsHere>
      )}
    </SpaceCardTemplate>
  );
}

const styles = StyleSheet.create({
  relativeContainer: {
    position: "relative",
  },
});
