import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import {
  InvitesInput,
  SendInvitesMutation,
  SendInvitesMutationVariables,
} from "core/graphql.generated";
import { sendInvitesGQLMutation } from "core/send_invites";
import { useToast } from "providers/toast";
import { useQueryString } from "lib/query_string";

import { useRemainingInvite } from "./useRemainingInvite";

const SEND_INVITE_ORDER_ID_PARAM = "sendInviteOrderId";
const NUMBER_OF_INVITEES = "remainingInviteSpots";

export interface InviteDialogParams {
  sendInviteOrderId: string;
  remainingInviteSpots: number;
}

export function useBookingInvitation() {
  const toast = useToast();
  const history = useHistory();

  const { sendInviteOrderId, remainingInviteSpots: remainingInviteSpotsParam } =
    useQueryString<InviteDialogParams>();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { remainingSpots, loading: remainingInviteLoading } =
    useRemainingInvite(
      remainingInviteSpotsParam,
      isDialogVisible,
      sendInviteOrderId,
    );

  useEffect(() => {
    if (sendInviteOrderId) {
      setIsDialogVisible(true);
    } else {
      setIsDialogVisible(false);
    }
  }, [sendInviteOrderId]);

  const [sendInvites] = useMutation<
    SendInvitesMutation,
    SendInvitesMutationVariables
  >(sendInvitesGQLMutation);

  const openDialog = (orderId: string, remainingInviteSpots: number) => {
    const query = new URLSearchParams(location.search);
    query.set(SEND_INVITE_ORDER_ID_PARAM, orderId);
    query.set(NUMBER_OF_INVITEES, remainingInviteSpots.toString());
    history.replace(`?${query.toString()}`);
  };

  const closeDialog = () => {
    const query = new URLSearchParams(location.search);
    query.delete(SEND_INVITE_ORDER_ID_PARAM);
    query.delete(NUMBER_OF_INVITEES);
    history.replace(`?${query.toString()}`);
  };

  const handleSendInvites = async (invites: InvitesInput) => {
    closeDialog();
    try {
      await sendInvites({
        variables: {
          orderID: sendInviteOrderId,
          input: invites,
        },
      });
      const numberOfInvitees = invites.recipients.length;
      toast.notify({
        message: `${numberOfInvitees} ${pluralize(
          "person",
          numberOfInvitees,
        )} ${pluralize("has", numberOfInvitees)} been invited!`,
      });
    } catch {
      toast.notify({ message: "Invitation was not sent successfully!" });
    }
  };

  return {
    isDialogVisible,
    remainingInviteSpots: remainingSpots,
    remainingInviteLoading,
    sendInvites: handleSendInvites,
    closeDialog,
    openDialog,
  };
}
