import { Button } from "components/button_v2";

type BudgetDesktopButtonProps = {
  onPress: () => void;
  text?: string;
  appearance?: "primary" | "secondary" | "textLink" | "danger";
  loading?: boolean;
  disabled?: boolean;
  testID?: string;
};

export const EditDesktopButton = ({
  onPress,
  testID,
}: BudgetDesktopButtonProps) => (
  <Button
    onPress={onPress}
    text={"Edit"}
    iconName={"pencil"}
    iconPosition="left"
    testID={testID}
  />
);
export const SaveDesktopButton = ({
  onPress,
  loading,
  disabled,
  testID,
}: BudgetDesktopButtonProps) => (
  <Button
    text={"Save"}
    onPress={onPress}
    loading={loading}
    disabled={disabled}
    testID={testID}
  />
);
