import { colors } from "components/colors";
import { Dialog } from "components/dialog";
import { Search } from "components/search/search";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  OrgUserDetailsFragment,
  TeamsLocationDetailsFragment,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { TeamLocationPreview } from "pages/team_spaces/components/team_location_preview";
import { UserCard } from "pages/team_spaces/components/where_my_team/where_my_team_list/user_card";
import React, { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

type WhereMyTeamListProps = {
  users: OrgUserDetailsFragment[];
};

export const ListUsersHeader = () => {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  if (isMobile) {
    return null;
  }
  return (
    <View style={styles.titleContainer}>
      <View style={styles.name}>
        <Text color={"black-70"} size={"xs"} weight={"semibold"}>
          Name
        </Text>
      </View>
      <View style={styles.location}>
        <Text color={"black-70"} size={"xs"} weight={"semibold"}>
          Location
        </Text>
      </View>
      <Spacer size={170} direction={"row"} />
    </View>
  );
};

export function TeamList({ users }: WhereMyTeamListProps) {
  const [searchValue, setSearchValue] = useState("");
  const [locationPreviewData, setLocationPreviewData] =
    useState<TeamsLocationDetailsFragment>();
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <View style={styles.container} testID="team-list-container">
      <Search placeholder={"Search by name"} onSearch={setSearchValue} />
      <View style={isMobile ? styles.contentMobile : styles.content}>
        <ListUsersHeader />
        <ScrollView>
          {users.length > 0 ? (
            users
              .filter((u) =>
                u.fullName.toLowerCase().includes(searchValue.toLowerCase()),
              )
              .map((user) => (
                <UserCard
                  key={user.id}
                  user={user}
                  setLocationPreviewData={setLocationPreviewData}
                />
              ))
          ) : (
            <Text>No space</Text>
          )}
        </ScrollView>
      </View>
      {locationPreviewData && (
        <Dialog
          visible
          onRequestClose={() => setLocationPreviewData(undefined)}
        >
          <TeamLocationPreview
            locationPreviewData={locationPreviewData}
            onClose={() => setLocationPreviewData(undefined)}
          />
        </Dialog>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  content: {
    marginTop: 24,
    borderRadius: 4,
    boxShadow: "0px 1px 10px rgba(6, 0, 30, 0.1)",
    padding: 16,
  },
  contentMobile: {
    marginTop: 24,
  },
  name: {
    flex: 1,
  },
  location: {
    flex: 1,
    paddingLeft: 54,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderColor: colors.brand.blackMinus90,
    paddingBottom: 24,
    flex: 1,
  },
});
