import { Pressable, View } from "react-native";
import { format } from "date-fns";

import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { Icon } from "components/iconv2";
import { BookingRequestStatus } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";

import { BookingRequestTableRaw } from "./booking_request_table_raw";
import { EmptyRequests } from "./empty_requests";
import {
  RequestInfo,
  renderRequestInfoCell,
  TableRowActionPropsType,
  styles,
} from "./pending_requests_table";
import { StatusText } from "./status_text";
import { ArchivedRequestsTableMobile } from "./archived_requests_table_mobile";
import { parseDay } from "lib/day_utils";

const archivedColsDesktop = [
  { id: "request", title: "Request", width: "25%" },
  { id: "amount", title: "Amount", width: "10%" },
  { id: "requestedOn", title: "Requested on", width: "15%" },
  { id: "reviewedBy", title: "Reviewed by", width: "15%" },
  { id: "reviewedOn", title: "Reviewed on", width: "15%" },
  { id: "status", title: "Status", width: "15%" },
  { id: "actions", title: "", width: "5%" },
];

export interface ArchivedRequest extends RequestInfo {
  reviewedBy?: string;
  reviewedOn?: string;
  bookedAt?: string;
  status: BookingRequestStatus;
  declineReason?: string;
}

interface PropsType {
  rows: ArchivedRequest[];
}

export const ArchivedRequestsTable: React.FC<PropsType> = ({ rows }) => {
  const {
    deviceQuery: { mobile: isMobile },
  } = useMediaQuery();

  if (rows.length === 0) {
    return (
      <EmptyRequests
        title="No requests yet."
        description="As soon as you or another approver reviews a request, we’ll show it here."
      />
    );
  }

  const sortedRows = rows.sort((a, b) => {
    if (b.reviewedOn && a.reviewedOn) {
      return (
        parseDay(b.reviewedOn).getTime() - parseDay(a.reviewedOn).getTime()
      );
    }
    return (
      parseDay(b.requestedOn).getTime() - parseDay(a.requestedOn).getTime()
    );
  });

  if (isMobile) {
    return <ArchivedRequestsTableMobile requests={sortedRows} />;
  }

  const renderedRows = sortedRows.map(
    ({
      id,
      requestUsername,
      spaceName,
      spaceType,
      spaceLocation,
      amount,
      requestedOn,
      reviewedBy,
      reviewedOn,
      status,
      reason,
      declineReason,
      bookedAt,
    }) => ({
      id,
      request: renderRequestInfoCell(
        requestUsername,
        spaceName,
        spaceType,
        spaceLocation,
      ),
      amount,
      requestedOn: format(parseDay(requestedOn), "MMM d"),
      reviewedBy: reviewedBy || "-",
      reviewedOn: reviewedOn ? format(parseDay(reviewedOn), "MMM d") : "-",
      status: <StatusText status={status} />,
      actions: {
        render: ({ onChangeExtend, isExtended }: TableRowActionPropsType) => (
          <Pressable onPress={onChangeExtend}>
            <Icon name={isExtended ? "chevron-up" : "chevron-down"} size="md" />
          </Pressable>
        ),
      },
      extension: (
        <View style={styles.extensionWrapper}>
          <Text>
            <Text weight="semibold" size="xs">
              Reason for request:
            </Text>
            <Text size="xs"> "{reason}"</Text>
          </Text>
          {declineReason && (
            <>
              <Spacer size={16} />
              <Text>
                <Text weight="semibold" size="xs">
                  Reason for decline:
                </Text>
                <Text size="xs"> "{declineReason}"</Text>
              </Text>
            </>
          )}
          {bookedAt && (
            <>
              <Spacer size={16} />
              <Text>
                <Text weight="semibold" size="xs">
                  Booked on:
                </Text>
                <Text size="xs">
                  {" "}
                  {format(parseDay(bookedAt), "MMM d yyyy")}
                </Text>
              </Text>
            </>
          )}
        </View>
      ),
    }),
  );

  return (
    <BookingRequestTableRaw columns={archivedColsDesktop} rows={renderedRows} />
  );
};
