import { Pressable, StyleSheet, View } from "react-native";

import { colors } from "components/colors";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";

export enum BookingRequestsTabName {
  Pending = "Pending",
  Archived = "Archived",
}

interface PropsType {
  value: BookingRequestsTabName;
  onChange: (value: BookingRequestsTabName) => void;
}

export const BookingRequestsTab: React.FC<PropsType> = ({
  value,
  onChange,
}) => {
  const {
    deviceQuery: { desktop: isDesktop },
  } = useMediaQuery();
  const analytics = useAnalytics();

  const handleChangeTab = (value: BookingRequestsTabName) => {
    analytics.event("View Booking Requests", {
      category: "Approval Workflow",
      View: value,
    });
    onChange(value);
  };

  return (
    <View style={[styles.root, isDesktop && styles.desktopWidth]}>
      <View
        style={[
          styles.tab,
          value === BookingRequestsTabName.Pending && styles.selectedTab,
        ]}
      >
        <Pressable
          onPress={() => handleChangeTab(BookingRequestsTabName.Pending)}
        >
          <View style={styles.textWrapper}>
            <Text size="xs" weight="semibold">
              Pending
            </Text>
          </View>
        </Pressable>
      </View>
      <View
        style={[
          styles.tab,
          value === BookingRequestsTabName.Archived && styles.selectedTab,
        ]}
      >
        <Pressable
          onPress={() => handleChangeTab(BookingRequestsTabName.Archived)}
        >
          <View style={styles.textWrapper}>
            <Text size="xs" weight="semibold">
              Archived
            </Text>
          </View>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.brand.blackMinus90,
    padding: 2,
    flexDirection: "row",
    borderRadius: 4,
  },
  desktopWidth: {
    width: 340,
  },
  tab: {
    borderRadius: 4,
    flex: 1,
  },
  selectedTab: {
    backgroundColor: colors.brand.whitecore,
  },
  textWrapper: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
});
