import { useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useQueryString } from "lib/query_string";

interface TabParam<T> {
  tab: T;
}

export const useTab = <T extends string>(
  tabs: T[],
): [T, (value: T) => void] => {
  const { tab } = useQueryString<TabParam<T>>();
  const history = useHistory();
  const searchString = useLocation().search;

  const updateTab = useCallback(
    (value: T) => {
      const params = new URLSearchParams(searchString);

      params.set("tab", value);
      const queryString = `?${params.toString()}`;

      history.replace(`${location.pathname}${queryString}`);
    },
    [history, searchString],
  );

  useEffect(() => {
    if (!tab) {
      updateTab(tabs[0]);
    }
  }, [tab, updateTab, tabs]);

  return [tab, updateTab];
};
