import { TabOption } from "components/tab/tabs";
import { Tabs } from "components/tab/tabs_v2";
import { useAnalytics } from "providers/analytics";

export enum SavedPlacesTabName {
  Spaces = "Spaces",
  Locations = "Locations",
}

interface SavedPlacesTabProps {
  value: SavedPlacesTabName;
  onChange: (value: SavedPlacesTabName) => void;
  options: TabOption<SavedPlacesTabName>[];
}

export const SavedPlacesTab = (props: SavedPlacesTabProps) => {
  const { value, onChange, options } = props;
  const analytics = useAnalytics();

  const handleChangeTab = (value: SavedPlacesTabName) => {
    analytics.event("View Saved Places", {
      category: "Fovorites",
      View: value,
    });
    onChange(value);
  };

  return <Tabs value={value} onChange={handleChangeTab} options={options} />;
};
