import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/iconv2";
import { SelectInputOption } from "components/select/select_input_option";
import { Text } from "components/text_v2";
import {
  OrgBudgetQuery,
  UpdateOrderBudgetMutation,
  UpdateOrderBudgetMutationVariables,
} from "core/graphql.generated";
import { BudgetInfo } from "pages/budgets/components/budget_transaction_table";
import { useToast } from "providers/toast";
import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

type EditBudgetProps = {
  currentBudget?: BudgetInfo;
  currentOrderId: string;
  refetch: () => void;
};
export const EditBudget = ({
  currentBudget,
  currentOrderId,
  refetch,
}: EditBudgetProps) => {
  const [updatedBudget, setUpdatedBudget] = useState<BudgetInfo>();
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toast = useToast();
  const { data } = useQuery<OrgBudgetQuery>(orgBudgetQuery);

  const [updateOrderBudget] = useMutation<
    UpdateOrderBudgetMutation,
    UpdateOrderBudgetMutationVariables
  >(updateOrderBudgetMutation);

  let listBudget: BudgetInfo[] = [];

  if (data?.currentUser?.organization) {
    listBudget = [
      {
        id: data.currentUser.organization.id,
        name: data.currentUser.organization.name + " (organization budget)",
      },
      ...data.currentUser.organization.groupBudgets,
    ];
    listBudget.sort((a, b) => a.name.localeCompare(b.name));
  }

  const onSavedBudget = async () => {
    try {
      if (currentOrderId && updatedBudget?.id) {
        await updateOrderBudget({
          variables: { orderId: currentOrderId, budgetId: updatedBudget?.id },
        });
        toast.notify({ message: "Budget successfully updated" });
        await refetch();
      }
    } catch {
      toast.notify({ message: "Error happened! Please try later." });
    } finally {
      setIsEdit(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <Text size={"xs"} weight={"semibold"}>
          Associated budget
        </Text>
        <Pressable onPress={() => setIsEdit((value) => !value)}>
          <Text size={"xs"} weight={"semibold"} color={"eggplant-core"}>
            {isEdit ? "Cancel" : "Edit"}
          </Text>
        </Pressable>
      </View>

      {!isEdit ? (
        <Text size={"xs"} color={"black-50"}>
          {updatedBudget?.name || currentBudget?.name}
        </Text>
      ) : (
        <DropdownV2
          open={dropdownVisible}
          onRequestClose={() => setDropdownVisible(false)}
          content={
            <View style={styles.dropdownContent}>
              {listBudget.map((budget) => (
                <SelectInputOption
                  key={budget.id}
                  selected={updatedBudget?.id === budget.id}
                  option={{ value: budget.id, label: budget.name }}
                  onSelect={() => {
                    setUpdatedBudget(budget);
                    setDropdownVisible(false);
                  }}
                />
              ))}
            </View>
          }
        >
          <View style={styles.groupBtnView}>
            <Pressable
              style={styles.dropdownButton}
              onPress={() => setDropdownVisible(!dropdownVisible)}
              disabled={listBudget.length === 0}
            >
              <View style={styles.dropdownBtnView}>
                <Text size="xs" numberOfLines={1}>
                  {updatedBudget?.name || currentBudget?.name}
                </Text>
              </View>
              <Icon name={dropdownVisible ? "chevron-up" : "chevron-down"} />
            </Pressable>
            <Button
              disabled={listBudget.length === 0}
              text={"Save"}
              size={"fit"}
              appearance={"secondary"}
              onPress={onSavedBudget}
            />
          </View>
        </DropdownV2>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    gap: 12,
  },
  labelContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  saveButton: {
    alignSelf: "flex-end",
  },
  dropdownButton: {
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    borderRadius: 4,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
    width: 248,
  },
  dropdownContent: {
    backgroundColor: colors.brand.whitecore,
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    marginTop: 8,
  },
  groupBtnView: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  dropdownBtnView: {
    maxWidth: 200,
  },
});

const orgBudgetQuery = gql`
  query OrgBudget {
    currentUser {
      organization {
        id
        name
        groupBudgets {
          id
          name
        }
      }
    }
  }
`;

export const updateOrderBudgetMutation = gql`
  mutation updateOrderBudget($orderId: ID!, $budgetId: ID!) {
    updateOrderBudget(orderId: $orderId, budgetId: $budgetId)
  }
`;
