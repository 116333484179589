import { ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { CellWidth } from "../data_table_base";

interface CellPropTypes {
  isHeader?: boolean;
  children?: ReactNode;
  width?: CellWidth;
}

export function Cell({ children, isHeader, width }: CellPropTypes) {
  return (
    <View
      style={[
        styles.cell,
        isHeader && styles.cellHeader,
        { width: width || "unset" },
      ]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  cell: {
    flexShrink: 1,
  },
  cellHeader: {},
});
