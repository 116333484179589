import { Spacer } from "components/spacer";
import ContentLoader, { Rect } from "react-content-loader/native";
import { StyleSheet, View } from "react-native";

export function RequestCardsSkeletonDesktop() {
  return (
    <View style={styles.container}>
      <ContentLoader
        speed={2}
        width={330}
        height={317}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={330} height={317} />
      </ContentLoader>
      <Spacer direction="row" size={24} />
      <ContentLoader
        speed={2}
        width={330}
        height={317}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={330} height={317} />
      </ContentLoader>
      <Spacer direction="row" size={24} />
      <ContentLoader
        speed={2}
        width={330}
        height={317}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width={330} height={317} />
      </ContentLoader>
    </View>
  );
}

export function RequestCardsSkeletonMobile() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={317}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="317px" />
      </ContentLoader>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});
