import { ApolloError } from "@apollo/client";
import React from "react";
import { Spinner } from "./spinner";
import { View } from "react-native";
import { Text } from "./text_v2";

interface AsyncRendererProps<T> {
  loading: boolean;
  error: ApolloError | undefined;
  data: T;
  children: (data: NonNullable<T>) => JSX.Element;
  loadingHandler?: (loading: boolean) => JSX.Element;
  errorHandler?: (error: ApolloError | undefined) => JSX.Element;
}

const defaultLoadingHandler = () => (
  <View style={{ padding: 120 }}>
    <Spinner />
  </View>
);

const defaultErrorHandler = (error: ApolloError | undefined) => (
  <View style={{ padding: 120 }}>
    <Text>Error: {error?.message || "Data is empty"}</Text>
  </View>
);

export const AsyncRenderer = <T extends { __typename?: "Query" } | undefined>({
  loading,
  loadingHandler = defaultLoadingHandler,
  error,
  errorHandler = defaultErrorHandler,
  data,
  children,
}: AsyncRendererProps<T>): JSX.Element => {
  if (loading) {
    return loadingHandler(loading);
  }

  if (error) {
    return errorHandler(error);
  }

  if (!data) {
    return errorHandler(undefined);
  }

  return children(data as NonNullable<T>);
};
