import { gql, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  BudgetQuery,
  BudgetQueryVariables,
  CurrentUserOrganizationNameQuery,
  CurrentUserOrganizationNameQueryVariables,
  GroupBudgetStatus,
  UserRole,
} from "core/graphql.generated";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useParams } from "react-router-dom";
import { ROUTES } from "../../../core/routes";
import { budgetQuery } from "../current_budgets";
import { BudgetCardStatusChip } from "./budget_card_template";

export interface BudgetDetailHeaderProps {
  secondaryHeadingText?: string | null;
  title?: string;
  hideBackButton?: boolean;
  backButtonText?: string;
  backButtonOnPress?: () => void;
  status?: GroupBudgetStatus | null;
  actions?: React.ReactNode;
  hasDivider?: boolean;
  children?: React.ReactNode;
}

export const BudgetDetailHeaderMobile = (props: BudgetDetailHeaderProps) => {
  const goBack = useGoBack(ROUTES.BUDGETS.path);
  const {
    title,
    hideBackButton,
    backButtonOnPress = goBack,
    actions,
    secondaryHeadingText,
  } = props;

  const { groupBudgetId } = useParams<{ groupBudgetId: string }>();

  const { data } = useQuery<BudgetQuery, BudgetQueryVariables>(budgetQuery, {
    skip: !!title || !groupBudgetId,
  });

  const foundBudget = data?.currentUser?.organization?.groupBudgets.find(
    (budget) => budget.id === groupBudgetId,
  );

  return (
    <View style={styles.mobileContainer}>
      <View style={styles.mobileContent}>
        {!hideBackButton || data?.currentUser?.role === UserRole.Admin ? (
          <Button
            onPress={backButtonOnPress}
            iconName={"chevron-left"}
            appearance={"textLink"}
            testID={"budget-detail-header-back-button"}
          />
        ) : (
          <Spacer size={64} direction="row" />
        )}
        <Text weight="bold">{title || foundBudget?.name}</Text>
        {actions ? (
          <View style={styles.actionsWrapper}>{actions}</View>
        ) : (
          <Spacer size={64} direction="row" />
        )}
      </View>
      <Divider />
      {secondaryHeadingText && (
        <View style={styles.secondaryHeadingTextMobile}>
          <Text size="xs">{secondaryHeadingText}</Text>
        </View>
      )}
    </View>
  );
};

export const BudgetDetailHeaderDesktop = (props: BudgetDetailHeaderProps) => {
  const goBack = useGoBack(ROUTES.BUDGETS.path);
  const {
    secondaryHeadingText,
    title,
    status,
    hideBackButton = false,
    backButtonText,
    backButtonOnPress = goBack,
    actions,
    hasDivider = false,
    children,
  } = props;

  const { groupBudgetId } = useParams<{ groupBudgetId: string }>();

  const { data } = useQuery<BudgetQuery, BudgetQueryVariables>(budgetQuery, {
    skip: !!title || !groupBudgetId,
  });

  const foundBudget = data?.currentUser?.organization?.groupBudgets.find(
    (budget) => budget.id === groupBudgetId,
  );
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        {(!hideBackButton || data?.currentUser?.role === UserRole.Admin) && (
          <Button
            onPress={backButtonOnPress}
            iconName={"chevron-left"}
            text={backButtonText}
            appearance={"textLink"}
            testID={"budget-detail-header-back-button"}
          />
        )}
        <View style={styles.titleContent}>
          <View style={styles.titleContainer}>
            <Text weight="bold" size="lg">
              {title || foundBudget?.name}
            </Text>
            <Spacer size={16} direction="row" />
            {(status || foundBudget?.status) === GroupBudgetStatus.Inactive && (
              <BudgetCardStatusChip
                status={
                  status
                    ? status
                    : foundBudget?.status
                    ? foundBudget.status
                    : GroupBudgetStatus.Active
                }
                dimmed
              />
            )}
          </View>
          <View style={styles.rightGroupBtnContainer}>{actions}</View>
        </View>
        {(!!secondaryHeadingText || foundBudget?.description) && (
          <View style={styles.secondaryHeadingText}>
            <Text size="xs">
              {secondaryHeadingText || foundBudget?.description}
            </Text>
          </View>
        )}
      </View>
      {hasDivider && <Divider />}
      <View style={styles.childrenView}>{children}</View>
    </View>
  );
};

export function BudgetDetailHeader(props: BudgetDetailHeaderProps) {
  const { title } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  const { loading, data } = useQuery<
    CurrentUserOrganizationNameQuery,
    CurrentUserOrganizationNameQueryVariables
  >(getOrgName, { skip: !!title });

  if (!title && loading) {
    return null;
  }

  if (isMobile) {
    return (
      <BudgetDetailHeaderMobile
        {...props}
        title={data?.currentUser?.organization?.name}
      />
    );
  }
  return (
    <BudgetDetailHeaderDesktop
      {...props}
      title={data?.currentUser?.organization?.name}
    />
  );
}

const styles = StyleSheet.create({
  mobileContent: {
    width: "100%",
    height: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 8,
  },
  mobileContainer: {
    marginBottom: 12,
  },
  titleContent: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: "row",
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
  },

  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rightGroupBtnContainer: {
    flexDirection: "row",
  },
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fafafa",
  },
  headerContainer: {
    width: "100%",
    maxWidth: 1088,
    paddingHorizontal: 8,
    marginTop: 24,
    marginBottom: 8,
    alignItems: "flex-start",
  },
  secondaryHeadingText: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  secondaryHeadingTextMobile: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  childrenView: {
    width: "100%",
    maxWidth: 1088,
    paddingHorizontal: 24,
  },
  actionsWrapper: {
    flexDirection: "row",
    gap: 5,
    marginRight: 10,
  },
});

const getOrgName = gql`
  query getOrgName {
    currentUser {
      organization {
        name
      }
    }
  }
`;
