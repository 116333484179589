import { Spacer } from "components/spacer";
import ContentLoader, { Rect } from "react-content-loader/native";

export function ReportingContentSkeletonDesktop() {
  return (
    <>
      <ContentLoader height="713px">
        <Rect x="0" y="0" rx="15" ry="15" width="100%" height="210" />
        <Rect x="0" y="250" rx="15" ry="15" width="386" height="463" />
        <Rect x="435" y="250" rx="15" ry="15" width="386" height="463" />
      </ContentLoader>
      <Spacer size={100} />
      <ContentLoader height="713px">
        <Rect x="0" y="0" rx="15" ry="15" width="100%" height="208" />
        <Rect x="0" y="250" rx="15" ry="15" width="386" height="463" />
        <Rect x="435" y="250" rx="15" ry="15" width="386" height="463" />
      </ContentLoader>
    </>
  );
}
