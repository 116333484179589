import { colors } from "components/colors";
import { isNotEmpty } from "lib/array_utils";
import React, { Fragment } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { Icon } from "../components/icon";
import { Spacer } from "../components/spacer";
import { Text } from "../components/text_v2";

interface ToastContextValue {
  error: (toastSettings: ToastSettings) => ToastInstance;
  notify: (toastSettings: ToastSettings) => ToastInstance;
  success: (toastSettings: ToastSettings) => ToastInstance;
  warning: (toastSettings: ToastSettings) => ToastInstance;
  removeToast: (id: ToastId) => void;
}

const defaultToastInstance: ToastInstance = {
  id: "1",
  onRemove: () => null,
  message: "",
};

const defaultToastContext: ToastContextValue = {
  error: () => defaultToastInstance,
  notify: () => defaultToastInstance,
  removeToast: () => {
    return;
  },
  success: () => defaultToastInstance,
  warning: () => defaultToastInstance,
};

const ToastContext = React.createContext(defaultToastContext);

export const useToast = () => {
  return React.useContext(ToastContext);
};

const hasCustomId = (toastSettings: ToastSettings) => !!toastSettings.id;

interface ToastProviderState {
  toasts: ToastInstance[];
}

const initialState: ToastProviderState = {
  toasts: [],
};

enum ActionType {
  ADD_TOAST = "ADD_TOAST",
  REMOVE_TOAST = "REMOVE_TOAST",
}

type Action =
  | { type: ActionType.ADD_TOAST; payload: { toast: ToastInstance } }
  | { type: ActionType.REMOVE_TOAST; payload: { id: ToastId } };

const reducer = (state: ToastProviderState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_TOAST:
      return { toasts: [...state.toasts, action.payload.toast] };
    case ActionType.REMOVE_TOAST:
      return {
        toasts: state.toasts.filter((toast) => toast.id !== action.payload.id),
      };
    default:
      throw new Error("Invalid action type");
  }
};

interface ToastProviderProps {
  children?: React.ReactNode;
}

export const ToastProvider = (props: ToastProviderProps) => {
  const { children } = props;
  const idCounterRef = React.useRef(0);
  // Use reducer because we want access previous value of state
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const createToastInstance = (toastSettings: ToastSettings): ToastInstance => {
    const uniqueId = ++idCounterRef.current;
    const id = hasCustomId(toastSettings)
      ? `${toastSettings.id}-${uniqueId}`
      : `${uniqueId}`;

    return {
      id,
      onRemove: () =>
        dispatch({ type: ActionType.REMOVE_TOAST, payload: { id } }),
      duration: toastSettings.duration,
      intent: toastSettings.intent,
      message: toastSettings.message,
      testID: toastSettings.testID,
    };
  };

  const notify = React.useCallback(
    (toastSettings: ToastSettings) => {
      const toastInstance = createToastInstance(toastSettings);

      // If there's a custom toast ID passed, close existing toasts with the same custom ID
      if (hasCustomId(toastSettings)) {
        for (const toast of state.toasts) {
          // Since unique ID is still appended to a custom ID, skip the unique ID and check only prefix
          if (String(toast.id).startsWith(String(toastSettings.id))) {
            dispatch({
              payload: { id: toast.id },
              type: ActionType.REMOVE_TOAST,
            });
          }
        }
      }

      dispatch({
        type: ActionType.ADD_TOAST,
        payload: { toast: toastInstance },
      });

      return toastInstance;
    },
    [state.toasts],
  );

  return (
    <ToastContext.Provider
      value={{
        error: (toastSettings: ToastSettings) =>
          notify({ ...toastSettings, intent: "error" }),
        notify: (toastSettings: ToastSettings) => notify({ ...toastSettings }),
        success: (toastSettings: ToastSettings) =>
          notify({ ...toastSettings, intent: "success" }),
        warning: (toastSettings: ToastSettings) =>
          notify({ ...toastSettings, intent: "warning" }),

        removeToast: (id: ToastId) =>
          dispatch({ type: ActionType.REMOVE_TOAST, payload: { id } }),
      }}
    >
      {children}
      {isNotEmpty(state.toasts) && (
        <View pointerEvents="none" style={[styles.root]}>
          {state.toasts.map((toast) => (
            <Toast
              key={toast.id}
              id={toast.id}
              duration={toast.duration}
              intent={toast.intent}
              message={toast.message}
              onRemove={toast.onRemove}
              testID={toast.testID}
            />
          ))}
        </View>
      )}
    </ToastContext.Provider>
  );
};

type ToastId = string;
type ToastIntent = "success" | "error" | "warning";

interface ToastSettings {
  /**
   * Duration for how long the toast should stay active.
   * @default 3000
   */
  duration?: number;

  /**
   * Assign an id to the toast so you can remove it later.
   */
  id?: ToastId;

  intent?: ToastIntent;

  message: string | React.ReactNode;

  testID?: string;
}

interface ToastInstance extends ToastSettings {
  /**
   * Assign an id to the toast so you can remove it later.
   */
  id: ToastId;

  /**
   * Callback invoked when the duration is up.
   */
  onRemove: () => void;
}

interface ToastProps extends ToastInstance {}

const Toast = (props: ToastProps) => {
  const { onRemove, duration = 5000, intent, message, testID } = props;
  const [offset, setOffset] = React.useState(-500);

  React.useEffect(() => {
    setTimeout(() => {
      setOffset(0);
    }, 0);

    const timer = setTimeout(() => {
      setOffset(-500);

      setTimeout(() => {
        onRemove();
      }, 100);
    }, duration - 100);

    return () => clearTimeout(timer);
  }, [duration, onRemove]);

  return (
    <Animated.View
      testID={testID}
      style={[styles.toast, { bottom: offset }]}
      pointerEvents="box-none"
    >
      {intent && (
        <Fragment>
          {getIconForIntent(intent)}
          <Spacer size={16} direction="row" />
        </Fragment>
      )}

      {typeof message === "string" ? (
        <Text
          align="center"
          weight={"semibold"}
          size={"xs"}
          color={"white-core"}
        >
          {message}
        </Text>
      ) : (
        message
      )}
    </Animated.View>
  );
};

function getIconForIntent(intent?: ToastIntent) {
  switch (intent) {
    case "error":
      return <Icon name="check-circle" />;
    case "success":
      return <Icon name="check-circle" />;
    default:
      return null;
  }
}

const styles = StyleSheet.create({
  root: {
    left: 32,
    marginBottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
    maxWidth: 400,
    // @ts-ignore
    position: "fixed",
    right: 32,
    zIndex: 1000,
    top: "unset",
    bottom: 75,
  },
  toast: {
    padding: 16,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.brand.blackcore,
    position: "relative",
    marginBottom: 16,
  },
  error: {
    backgroundColor: "#feecf0",
  },
  success: {
    backgroundColor: "#effaf3",
  },
  warning: {
    backgroundColor: "#fffbeb",
  },
});
