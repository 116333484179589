import ContentLoader, { Rect } from "react-content-loader/native";

export function ReportingContentSkeletonMobile() {
  return (
    <ContentLoader
      height="783px"
      style={{ padding: 21, paddingTop: 0, marginTop: 45 }}
    >
      <Rect x="0" y="0" rx="15" ry="15" width="100%" height="332" />
      <Rect x="0" y="352" rx="15" ry="15" width="100%" height="431" />
    </ContentLoader>
  );
}
