import { format } from "date-fns";

import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";

import { PendingRequest } from "./pending_requests_table";
import { BookingRequestsMobile } from "./booking_requests_mobile";
import { ExpireDaysBadge } from "./expire_days_badge";
import { HandleReview } from "../hooks/use_booking_request_review";
import { RequestActions } from "./request_actions";
import { parseDay } from "lib/day_utils";

interface PropsType {
  requests: PendingRequest[];
  refetch: () => void;
}

export const PendingRequestsTableMobile: React.FC<PropsType> = ({
  refetch,
  requests,
}) => {
  const mappedRequests = requests.map(
    ({
      id,
      requestUsername,
      spaceName,
      spaceType,
      spaceLocation,
      amount,
      expireInDays,
      requestedOn,
      reason,
    }) => ({
      id,
      data: [
        { id: "name", label: "Name", value: { text: requestUsername } },
        {
          id: "spaceRequested",
          label: "Space requested",
          value: {
            text: spaceName,
            element: (
              <>
                <Text size="xs">{spaceName}</Text>
                <Spacer size={4} />
                <Text size="xs">{spaceType}</Text>
                <Spacer size={4} />
                <Text size="xs">{spaceLocation}</Text>
              </>
            ),
          },
        },
        { id: "amount", label: "Amount", value: { text: amount } },
        {
          id: "expiresIn",
          label: "Expires in",
          value: {
            text: `${expireInDays}`,
            element: <ExpireDaysBadge days={expireInDays} />,
          },
        },
        {
          id: "requestedOn",
          label: "Requested on",
          value: { text: format(parseDay(requestedOn), "MMM d") },
        },
        {
          id: "reasonForRequest",
          label: "Reason for request",
          value: { text: `"${reason}"` },
        },
      ],
      actions: {
        render: (
          onReview: HandleReview,
          loadingApproveRequest: boolean,
          loadingDeclineRequest: boolean,
        ) => (
          <RequestActions
            onReview={onReview}
            loadingApproveRequest={loadingApproveRequest}
            loadingDeclineRequest={loadingDeclineRequest}
            username={requestUsername}
          />
        ),
        refetch,
      },
    }),
  );

  return <BookingRequestsMobile requests={mappedRequests} />;
};
