import { SpaceType } from "core/graphql.generated";
import { StyleSheet, View, Image, Pressable } from "react-native";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { colors } from "components/colors";
import { useCallback } from "react";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";

interface SpaceTypeMobileCardProps {
  uri: string;
  type: SpaceType;
  title: string;
  content: string;
  onCardPress: (type: SpaceType) => void;
  testID: string;
  outOfPolicy?: boolean;
  selected: boolean;
}

export function SpaceTypeMobileCard(props: SpaceTypeMobileCardProps) {
  const {
    uri,
    type,
    title,
    content,
    onCardPress,
    testID,
    outOfPolicy,
    selected,
  } = props;

  const onPress = useCallback(() => {
    onCardPress(type);
  }, [onCardPress, type]);

  return (
    <Pressable testID={testID} onPress={onPress}>
      <View key={uri} style={styles.container}>
        <Image
          source={{
            uri: uri,
            width: 92,
          }}
        />
        <View style={[styles.content, selected && styles.selected]}>
          <View style={styles.spaceLabelWrapper}>
            <Text weight="semibold" size="xs">
              {title}
            </Text>
            {outOfPolicy && <OutOfPolicyTag />}
          </View>
          <Spacer size={8} />
          <Text color="black-70" size="xs">
            {content}
          </Text>
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  content: {
    padding: 16,
    backgroundColor: colors.brand.whitecore,
    flex: 1,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  spaceLabelWrapper: {
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
  },
  selected: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
});
