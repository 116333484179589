import { gql, useQuery } from "@apollo/client";
import { GridV2 } from "components/gridv2";
import {
  EmptyStatesOtherSpacesQuery,
  EmptyStatesOtherSpacesQueryVariables,
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
  SpaceType,
} from "core/graphql.generated";
import { isBefore, toDay } from "lib/day_utils";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import { useEmptyStateGroupAFeatureFlag } from "providers/splitio";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { EmptySpaces } from "../components/empty_spaces";
import { HomePageLoadingComponent } from "../components/home_page_loading_component";
import { useCurrentUserGeoCoordinatesQuery } from "../hooks/use_current_user_geo_coordinates";
import {
  emptyStateStatusNumber,
  useEmptyStateAnalyticsStatus,
} from "../hooks/use_empty_state_analytics_status";
import { useEmptyStateFilterTypes } from "../hooks/use_empty_state_filter_type";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";
import { initialPlaceState } from "../hooks/use_initial_place";
import { EmptyStateWithNoFilterWithSearchNoSpace } from "./empty_state_no_filter_with_search";
import { EmptyStateOtherTypesHaveSpaces } from "./empty_state_other_types_have_spaces";
import { EmptyStatePassedDateTile } from "./empty_state_tiles";
import { EmptyStateWithDateAndCapacityOrAmenities } from "./empty_state_with_date_and_capacity_or_amenities";
import { EmptyStateWithOnlyCapacity } from "./empty_state_with_only_capacity_filter";
import { EmptyStateWithPartialMatch } from "./empty_state_with_partial_match";

interface EmptyStateProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (location: HomePage__LocationDetailsFragment) => void;
}

export function EmptyState(props: EmptyStateProps) {
  const { spaces, onPreviewLocation } = props;
  const mq = useMediaQuery();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const [showPassedDateTile, setShowPassedDateTile] = useState(false);
  const onPassedDateClearPress = () => {
    updateHomeSearchParams({
      date: null,
      startTime: undefined,
      endTime: undefined,
    });
    setShowPassedDateTile(false);
  };

  const emptyStateGroupAFeatureFlag = useEmptyStateGroupAFeatureFlag();
  const {
    spaceType,
    coordinates,
    date,
    startTime,
    endTime,
    minCapacity,
    maxCapacity,
    amenities,
    sort,
  } = useHomeSearchParamsQuery();
  const { loading, isDefault } = useRecoilValue(initialPlaceState);
  const {
    onlyCapacity,
    none,
    dateAndCapacityOrAmenities,
    onlyDateOrTime,
    onlyAmenities,
  } = useEmptyStateFilterTypes();

  const isPassedDateUsed = useMemo(
    () => date && isBefore(date, toDay(new Date())),
    [date],
  );

  const onFindmeASpacePressFunction = useCallback(() => {
    if (isPassedDateUsed) {
      setShowPassedDateTile(true);
    }
  }, [isPassedDateUsed]);

  const onFindMeASpacePress = useMemo(
    () => (isPassedDateUsed ? onFindmeASpacePressFunction : undefined),
    [isPassedDateUsed, onFindmeASpacePressFunction],
  );

  const analytics = useAnalytics();
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useCurrentUserGeoCoordinatesQuery();
  const { data: otherSpacesData, loading: otherSpacesLoading } = useQuery<
    EmptyStatesOtherSpacesQuery,
    EmptyStatesOtherSpacesQueryVariables
  >(emptyStatesOtherSpacesQuery, {
    variables: {
      centerMapCoordinates: coordinates
        ? {
            lat: coordinates?.lat,
            lng: coordinates?.lng,
          }
        : {},
      currentUserCoordinates: currentUserCoordinates
        ? {
            lat: currentUserCoordinates?.lat,
            lng: currentUserCoordinates?.lng,
          }
        : {},

      filter: {
        date,
        startTime,
        endTime,
        minCapacity,
        maxCapacity,
        amenities,
      },
      limit: 1000,
      offset: 0,
      sort,
    },
    skip: !coordinates || !none || currentUserLoading || !spaceType,
  });

  const isOtherTypesHaveSpaces = useMemo(
    () =>
      spaceType === SpaceType.DayPass
        ? !!otherSpacesData?.DayOffice.data?.length ||
          !!otherSpacesData?.MeetingRoom.data?.length
        : spaceType === SpaceType.DayOffice
        ? !!otherSpacesData?.DayPass.data?.length ||
          !!otherSpacesData?.MeetingRoom.data?.length
        : !!otherSpacesData?.DayOffice.data?.length ||
          !!otherSpacesData?.DayPass.data?.length,
    [otherSpacesData, spaceType],
  );

  const emptyStateAnalyticsStatusNumber = useEmptyStateAnalyticsStatus(
    spaces,
    isOtherTypesHaveSpaces,
    isDefault,
  );

  const numberOfSpaces = !spaceType ? 0 : spaces.length;

  useEffect(() => {
    // 0 means it is not an empty state
    if (
      emptyStateAnalyticsStatusNumber > 0 &&
      !loading &&
      !otherSpacesLoading
    ) {
      analytics.event("Display Empty State", {
        Type:
          emptyStateGroupAFeatureFlag &&
          emptyStateAnalyticsStatusNumber ===
            emptyStateStatusNumber.NoFilterNoSpace &&
          !isOtherTypesHaveSpaces
            ? "Instruction"
            : "Concierge",
        "Use Case": emptyStateAnalyticsStatusNumber,
        Matches: numberOfSpaces,
      });
    }
  }, [
    numberOfSpaces,
    analytics,
    emptyStateAnalyticsStatusNumber,
    emptyStateGroupAFeatureFlag,
    loading,
    isOtherTypesHaveSpaces,
    otherSpacesLoading,
  ]);

  if (loading || otherSpacesLoading) {
    return (
      <GridV2 columns={mq.deviceQuery.mobile ? 1 : 2}>
        <HomePageLoadingComponent />
      </GridV2>
    );
  }

  if (showPassedDateTile) {
    return <EmptyStatePassedDateTile onPress={onPassedDateClearPress} />;
  }

  if (onlyCapacity) {
    return (
      <EmptyStateWithOnlyCapacity
        onPreviewLocation={onPreviewLocation}
        spaces={spaces}
        onFindMeASpacePress={onFindMeASpacePress}
      />
    );
  } else if (onlyDateOrTime) {
    return (
      <EmptyStateWithPartialMatch
        onPreviewLocation={onPreviewLocation}
        spaces={spaces}
        title="We’ve found some spaces that don’t match your date and time criteria 100%, but still match everything else you’re looking for. You can also try changing or clearing your filters."
        noSpaceTestID="empty-state-with-date-or-time-no-recommendation"
        fewSpacesTestID="empty-state-with-date-or-time-with-recommendation-and-spaces"
        noMatchWithRecommendationTestID="empty-state-with-date-or-time-with-recommendation-and-no-spaces"
        onFindMeASpacePress={onFindMeASpacePress}
      />
    );
  } else if (onlyAmenities) {
    return (
      <EmptyStateWithPartialMatch
        onPreviewLocation={onPreviewLocation}
        spaces={spaces}
        title="We’ve found some spaces that don’t match your amenity criteria 100%, but still match everything else you’re looking for. You can also try changing or clearing your filters."
        noSpaceTestID="empty-state-with-amenities-no-recommendation"
        fewSpacesTestID="empty-state-with-amenities-with-recommendation-and-spaces"
        noMatchWithRecommendationTestID="empty-state-with-amenities-with-recommendation-and-no-spaces"
        onFindMeASpacePress={onFindMeASpacePress}
      />
    );
  } else if (dateAndCapacityOrAmenities) {
    return (
      <EmptyStateWithDateAndCapacityOrAmenities
        onPreviewLocation={onPreviewLocation}
        spaces={spaces}
        onFindMeASpacePress={onFindMeASpacePress}
      />
    );
  } else if (none && isDefault) {
    return <EmptySpaces onFindMeASpacePress={onFindMeASpacePress} />;
  } else if (
    none &&
    !isDefault &&
    isOtherTypesHaveSpaces &&
    numberOfSpaces === 0
  ) {
    return (
      <EmptyStateOtherTypesHaveSpaces
        onFindMeASpacePress={onFindMeASpacePress}
        spaceList={otherSpacesData}
      />
    );
  } else if (
    none &&
    !isDefault &&
    numberOfSpaces === 0 &&
    !isOtherTypesHaveSpaces
  ) {
    return (
      <EmptyStateWithNoFilterWithSearchNoSpace
        onFindMeASpacePress={onFindMeASpacePress}
      />
    );
  }

  return <EmptySpaces onFindMeASpacePress={onFindMeASpacePress} />;
}

const emptyStatesOtherSpacesQuery = gql`
  query EmptyStatesOtherSpaces(
    $filter: SpacesFilter
    $centerMapCoordinates: SpaceSearchCoordinates
    $currentUserCoordinates: SpaceSearchCoordinates
    $limit: Int
    $offset: Int
    $sort: SpacesSort
  ) {
    MeetingRoom: spaces(
      spaceType: MeetingRoom
      filter: $filter
      currentUserCoordinates: $currentUserCoordinates
      centerMapCoordinates: $centerMapCoordinates
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      data {
        id
      }
    }
    DayPass: spaces(
      spaceType: DayPass
      filter: $filter
      currentUserCoordinates: $currentUserCoordinates
      centerMapCoordinates: $centerMapCoordinates
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      data {
        id
      }
    }
    DayOffice: spaces(
      spaceType: DayOffice
      filter: $filter
      currentUserCoordinates: $currentUserCoordinates
      centerMapCoordinates: $centerMapCoordinates
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      data {
        id
        physicalSpacesCount
      }
    }
  }
`;
