import { Button } from "components/button_v2";
import { CodeInput } from "components/code_input";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";
import { colors } from "components/colors";
import { TextLink } from "components/text_link";
import { SignUpCredentials } from "pages/sign_up/sign_up";
import { Fragment } from "react";

interface PropTypes {
  error: string;
  code: string;
  onSubmit: (val: string) => void;
  setCode: (val: string) => void;
  credentials: SignUpCredentials;
  resendCode: () => void;
  loading: boolean;
}

export const StepCodeUI = ({
  error,
  code,
  onSubmit,
  setCode,
  credentials,
  resendCode,
  loading,
}: PropTypes) => {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  return (
    <Fragment>
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Content maxWidth={670}>
          <View style={styles.container}>
            <Heading align="center" size="xl">
              Check your email for a confirmation code
            </Heading>
            <Text color="black-core" size="xs" align="center">
              An email with a 6-digit code has been sent to{" "}
              <b>{credentials.email}</b>. This code expires shortly, so enter it
              soon.
            </Text>

            <View style={styles.codeInput}>
              <CodeInput
                value={code}
                onChange={(val: string) => {
                  setCode(val);
                  if (val.length === 6) {
                    onSubmit(val);
                  }
                }}
                autoFocus
                length={6}
                error={error}
              />
              {error && (
                <Text color="grapefruit-core" size="xs">
                  {error}
                </Text>
              )}
            </View>
            <Spacer size={16} />
          </View>
        </Content>
      </View>
      <View style={styles.buttonAndText}>
        <View
          style={[
            styles.buttonContainer,
            isMobile && styles.buttonContainerMobile,
          ]}
        >
          <Button
            appearance="primary"
            text="Continue"
            loading={loading}
            onPress={() => onSubmit(code)}
          />
        </View>
        <Text customColor={colors.brand.blackMinus30} size="xs" align="center">
          Can’t find your code? Check your spam folder or{" "}
          <TextLink
            onPress={resendCode}
            text="resend code"
            inline
            size="xs"
            decoration="none"
            weight="bold"
          />
          .
        </Text>
      </View>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
  container: {
    alignItems: "center",
    gap: 16,
  },
  codeInput: {
    alignItems: "center",
    marginTop: 24,
    flex: 1,
    gap: 8,
  },
  buttonContainer: {
    width: "95%",
    maxWidth: 220,
    alignSelf: "center",
  },
  buttonContainerMobile: {
    maxWidth: 343,
  },
  buttonAndText: {
    display: "flex",
    gap: 16,
  },
});
