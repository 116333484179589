import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";
import { TodayNavigationCard } from "./navigation_card";

export function NavigationCards() {
  const mq = useMediaQuery();

  const spaceDirection = mq.deviceQuery.mobile ? "column" : "row";

  return (
    <View>
      <Text weight="semibold" size="md">
        Manage your organization
      </Text>
      <Spacer size={mq.deviceQuery.mobile ? 16 : 24} />
      <View style={[styles.cards, mq.deviceQuery.mobile && styles.cardsMobile]}>
        <TodayNavigationCard
          title="Budgets"
          iconName="budget"
          text="Monitor and optimize budgets for all of your company’s teams."
          to="/admin/budgets"
        />
        <Spacer direction={spaceDirection} size={24} />
        <TodayNavigationCard
          title="Users"
          iconName="user-list"
          text="See who’s using Flexspace, invite your colleagues, and manage permissions."
          to="/admin/manage-users"
        />
        <Spacer direction={spaceDirection} size={24} />
        <TodayNavigationCard
          title="Organization settings"
          iconName="organization"
          text="Manage how your organization can book with Flexspace."
          to="/admin/organization"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  cards: {
    flexDirection: "row",
    alignItems: "center",
  },
  cardsMobile: {
    overflowX: "scroll",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
