import { useEffect } from "react";
import { View, StyleSheet } from "react-native";

import { Button } from "components/button_v2";
import { Spacer } from "components/spacer";
import { appConfig } from "providers/config";
import { colors } from "components/colors";
import { tokens } from "components/tokens";
import { useAnalytics } from "providers/analytics";
import { useGCalExtension } from "hooks/use_gcal_extension";
import { useGCalTileFeatureFlag } from "providers/splitio";

import { TitleWithCapacity } from "./card_title_with_capacity";

const IMAGE_URL = "images/gcal-card.png";

export function GcalCard() {
  const analytics = useAnalytics();
  const gCalTileFeatureFlag = useGCalTileFeatureFlag();
  const { isLoading, version } = useGCalExtension();

  const isTileHidden = !gCalTileFeatureFlag || isLoading || version;

  useEffect(() => {
    if (!isTileHidden) {
      analytics.event("Display GCAL CTA", {
        Type: "Tile",
      });
    }
  }, [analytics, isTileHidden]);

  if (isTileHidden) {
    return null;
  }

  const handleTileClick = () => {
    window.open(appConfig.chromeWebstoreUrl, "_blank");
    analytics.event("Clicked on GCAL CTA", {
      Type: "Tile",
    });
  };

  return (
    <View style={styles.mainWrapper}>
      <View style={styles.cardContainer}>
        <img
          src={IMAGE_URL}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt={"Flexspace for Google Calendar"}
        />
      </View>
      <View style={styles.contentContainer}>
        <TitleWithCapacity
          title={"Flexspace for Google Calendar"}
          subTitle={"The easiest way coordinate a space for your next meeting."}
        />
        <Spacer size={25} />
        <Button
          onPress={handleTileClick}
          iconName="outgoing"
          iconPosition="right"
          text="Install Google Calendar add-on"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.brand.eggplantcore,
  },
  mainWrapper: {
    flex: 1,
    backgroundColor: colors.brand.whitecore,
  },
  cardContainer: {
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: "#DADEE5",
    borderTopLeftRadius: tokens.radius,
    borderTopRightRadius: tokens.radius,
    height: 170,
    overflow: "hidden",
  },
  contentContainer: {
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomLeftRadius: tokens.radius,
    borderBottomRightRadius: tokens.radius,
    borderColor: "#DADEE5",
    minHeight: 144,
    flex: 1,
    padding: 16,
  },
});
