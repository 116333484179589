import { View } from "react-native";
import ContentLoader, { Rect } from "react-content-loader/native";

import { Spacer } from "components/spacer";

const LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT = 400;
const LOCATION_DETAIL_SKELETON_DESKTOP_SPACER_SIZE = 24;

export function LocationDetailsSkeletonDesktop() {
  return (
    <View>
      <Spacer size={40} />
      <ContentLoader
        speed={2}
        width="100%"
        height={80}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect
          width="100%"
          height={LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT}
        />
      </ContentLoader>
      <Spacer size={LOCATION_DETAIL_SKELETON_DESKTOP_SPACER_SIZE} />
      <ContentLoader
        speed={2}
        width="100%"
        height={LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect
          width="100%"
          height={LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT}
        />
      </ContentLoader>
      <Spacer size={LOCATION_DETAIL_SKELETON_DESKTOP_SPACER_SIZE} />
      <ContentLoader
        speed={2}
        width="100%"
        height={LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect
          width="100%"
          height={LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT}
        />
      </ContentLoader>
      <Spacer size={LOCATION_DETAIL_SKELETON_DESKTOP_SPACER_SIZE} />
      <ContentLoader
        speed={2}
        width="100%"
        height={LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect
          width="100%"
          height={LOCATION_DETAIL_SKELETON_SECTION_DESKTOP_HEIGHT}
        />
      </ContentLoader>
    </View>
  );
}
