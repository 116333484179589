import { useMediaQuery } from "lib/media_query";
import React from "react";
import { HomeSkeletonDesktop } from "./home_skeleton_desktop";
import { HomeSkeletonMobile } from "./home_skeleton_mobile";

export const HomePageLoadingComponent = () => {
  const mq = useMediaQuery();
  const isMobile = mq.sizeQuery.smAndDown;
  return isMobile ? <HomeSkeletonMobile /> : <HomeSkeletonDesktop />;
};
