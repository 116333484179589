import { Icon, IconName } from "components/iconv2";
import { LinkWrapper } from "components/link_wrapper";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";

interface TodayNavigationCardProps {
  iconName: IconName;
  title: string;
  text: string;
  to: string;
}

export function TodayNavigationCard(props: TodayNavigationCardProps) {
  const { iconName, title, text, to } = props;
  const mq = useMediaQuery();

  const linearBackground =
    title === "Budgets"
      ? "linear-gradient(252.45deg, #FF595A -0.08%, #FF9E18 111.51%)"
      : title === "Users"
      ? "linear-gradient(72.79deg, #FF595A -5.25%, #B45080 99.92%)"
      : "linear-gradient(72.46deg, #B45080 17.23%, #5941B0 100%)";

  return (
    <div
      style={{
        background: linearBackground,
        display: "flex",
        width: mq.deviceQuery.mobile ? "100%" : "auto",
      }}
    >
      <LinkWrapper to={to}>
        <View
          style={[
            styles.container,
            mq.deviceQuery.mobile && styles.containerMobile,
          ]}
        >
          <Icon filled color="whitecore" name={iconName} size="md" />
          <Spacer size={24} />
          <Text color="white-core" size="md" weight="semibold">
            {title}
          </Text>
          <Spacer size={24} />
          <Text color="white-core" size="xs">
            {text}
          </Text>
        </View>
      </LinkWrapper>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    width: 330,
  },

  containerMobile: {
    width: "100%",
    flex: 1,
  },
});
