import { View } from "react-native";

import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";

interface LocationDetailsSectionProps {
  title: string;
  children: JSX.Element;
}

export function LocationDetailsSection({
  title,
  children,
}: LocationDetailsSectionProps) {
  const mq = useMediaQuery();
  const spacerSize = mq.deviceQuery.mobile ? 16 : 24;

  return (
    <View>
      <Text size="md" weight="semibold">
        {title}
      </Text>
      <Spacer size={spacerSize} />
      {children}
    </View>
  );
}
