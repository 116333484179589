import { Coordinates } from "pages/homev2/coordinates";
import { atom } from "recoil";

interface UrLocationSearch {
  coordinates?: Coordinates;
  bbox?: [number, number, number, number];
  placeId?: string;
  homeUrl: string;
}

export const urlLocationSearchState = atom<UrLocationSearch>({
  key: "locationSearchState",
  default: {
    coordinates: undefined,
    bbox: undefined,
    placeId: undefined,
    homeUrl: "/",
  },
});
