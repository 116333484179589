import React, { Fragment } from "react";
import { Container } from "components/container";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { Heading } from "components/heading";
import { ReservationDetailsInput } from "./booking_utils";
import {
  BookingConfig,
  Pricing,
  BookingHour,
  SpaceBookingPolicy,
  SpaceLayout,
} from "./graphql.generated";
import { RoomLayoutPicker } from "./room_layout_picker";

export interface ReservationDetailsFormProps {
  space: {
    layouts: SpaceLayout[];
    id: string;
    pricings: Pricing[];
    bookingPolicy: SpaceBookingPolicy;
    bookingHours: BookingHour[];
    bookingConfig: BookingConfig;
    maxCapacity: number;
    location: {
      timezone: string;
    };
  };
  values: ReservationDetailsInput;
  setFieldValue: <K extends keyof ReservationDetailsInput>(
    field: keyof ReservationDetailsInput,
    value: ReservationDetailsInput[K],
  ) => void;
}

export function ReservationDetailsForm(props: ReservationDetailsFormProps) {
  const { space, values, setFieldValue } = props;

  return (
    <Container>
      {space.layouts.length > 1 && (
        <Fragment>
          <Heading size="h3">Room layout</Heading>
          <Spacer size={8} />
          <Text>
            Customize your seating arrangement to best fit your needs.{" "}
          </Text>
          <Spacer size={16} />
          <RoomLayoutPicker
            selectedLayoutID={values.layoutID}
            onSelect={(lid) => {
              setFieldValue("layoutID", lid);
            }}
            space={space}
          />
        </Fragment>
      )}
    </Container>
  );
}
