import { Button } from "components/button_v2";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useGoBack } from "hooks/use_go_back";
import { StyleSheet, View } from "react-native";

export interface PropsType {
  title?: string;
  hideBackButton?: boolean;
  onBackPress?: () => void;
  actions?: React.ReactNode;
}

export const MobileHeader: React.FC<PropsType> = (props) => {
  const goBack = useGoBack();
  const { title, hideBackButton, onBackPress = goBack, actions } = props;

  return (
    <View style={styles.container}>
      <View style={styles.contentWrapper}>
        {!hideBackButton ? (
          <Button
            onPress={onBackPress}
            iconName={"chevron-left"}
            appearance={"textLink"}
            testID={"budget-detail-header-back-button"}
          />
        ) : (
          <Spacer size={64} direction="row" />
        )}
        {title && <Text weight="bold">{title}</Text>}
        {actions ? (
          <View style={styles.actionsWrapper}>{actions}</View>
        ) : (
          <Spacer size={64} direction="row" />
        )}
      </View>
      <Divider />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 12,
  },
  contentWrapper: {
    width: "100%",
    height: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 8,
  },
  actionsWrapper: {
    flexDirection: "row",
    gap: 5,
    marginRight: 10,
    alignItems: "center",
  },
});
