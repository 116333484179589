import { StyleSheet, View } from "react-native";
import { tokens } from "components/tokens";
import { BudgetCardEditSection } from "./budget_card_edit_section";
import { ColumnGridCol, ColumnGridRow } from "core/grid";
import "./budget_card.web.css";
import { Text } from "components/text_v2";
import { Button } from "components/button_v2";
import { Spacer } from "components/spacer";
import { ReportingBudgetUpdateModal } from "./budget_update_modal";

interface ReportingBudgetCardDesktopEmptyPropTypes {
  editing: boolean;
  budgetInputValue: string;
  onBudgetInputChange: (arg: string) => void;
  onBudgetInputSubmit: () => void;
  isInvalid: boolean;
  invalidText: string | undefined;
  cancelEditing: () => void;
  isUpdateBudgetLoading: boolean;
  budget: number;
  setEditing: (arg: boolean) => void;
  editable: boolean;
  budgetCardElementRef: any;
  setConfirmationModalState: (arg: boolean) => void;
  submitNewBudget: () => void;
  confirmationModalState: boolean;
  updateBudgetError?: string;
}

export function ReportingBudgetCardDesktopEmpty({
  editing,
  budgetInputValue,
  onBudgetInputChange,
  onBudgetInputSubmit,
  isInvalid,
  invalidText,
  cancelEditing,
  isUpdateBudgetLoading,
  budget,
  setEditing,
  editable,
  budgetCardElementRef,
  setConfirmationModalState,
  submitNewBudget,
  confirmationModalState,
  updateBudgetError,
}: ReportingBudgetCardDesktopEmptyPropTypes) {
  return (
    <>
      <ColumnGridRow>
        <ColumnGridCol style={{ zIndex: 1 }} cols={6}>
          <View style={styles.container} ref={budgetCardElementRef}>
            <View style={styles.numberWrapper}>
              <BudgetCardEditSection
                editing={editing}
                budgetInputValue={budgetInputValue}
                onBudgetInputChange={onBudgetInputChange}
                onBudgetInputSubmit={onBudgetInputSubmit}
                isInvalid={isInvalid}
                invalidText={invalidText}
                cancelEditing={cancelEditing}
                isUpdateBudgetLoading={isUpdateBudgetLoading}
                budget={budget}
                setEditing={setEditing}
                editable={editable}
              />
            </View>
          </View>
        </ColumnGridCol>
        <ColumnGridCol cols={6}>
          <div className="reporting-budget-card-desktop">
            <Text color="white-core" size="md" weight="semibold">
              Let your team book spaces when they need it, as often as they
              need.
            </Text>
            <Spacer size={16} />
            <Text color="white-core" size="xs">
              Set a monthly budget to keep spend in control and enable your team
              to do their best work.
            </Text>
            <Spacer size={47} />
            <Button
              text="Set Budget"
              appearance="secondary"
              onPress={() => setEditing(true)}
            />
          </div>
        </ColumnGridCol>
      </ColumnGridRow>
      {!!confirmationModalState && (
        <ReportingBudgetUpdateModal
          onCancel={() => setConfirmationModalState(false)}
          onConfirm={submitNewBudget}
          loading={isUpdateBudgetLoading}
          updateBudgetError={updateBudgetError}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "white",
    borderRadius: 16,
    ...tokens.shadow.elevation1,
    height: "100%",
    justifyContent: "center",
  },
  numberWrapper: {
    flexDirection: "row",
  },
});
