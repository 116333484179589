import { first } from "lib/array_utils";
import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { MapboxFeature } from "../mapbox";
import { useCurrentUserGeoPlaceQuery } from "./use_current_user_geo_place";
import { useCurrentUserIpPlaceQuery } from "./use_current_user_ip_place";
import { useHomeSearchParamsPlaceQuery } from "./use_home_search_params_place";
import { useSearchHistory } from "./use_search_history";

export interface InitialPlaceData {
  data: MapboxFeature | undefined;
  loading: boolean;
  error: string | Error | undefined;
  isDefault: boolean;
  initialized?: boolean;
}

export const initialPlaceState = atom<InitialPlaceData>({
  key: "initialPlace",
  default: {
    data: undefined,
    loading: true,
    error: undefined,
    isDefault: false,
    initialized: false,
  },
});

export const defaultInitialPlace = {
  data: {
    id: "place.233720044",
    type: "Feature",
    place_type: ["place"],
    relevance: 1,
    properties: {
      wikidata: "Q60",
      short_code: "US-NY",
    },
    text: "New York",
    place_name: "New York, New York, United States",
    bbox: [-74.259639922, 40.477399, -73.700272, 40.917577],
    center: [-73.9866, 40.7306],
    geometry: {
      type: "Point",
      coordinates: [-73.9866, 40.7306],
    },
  },
  loading: false,
  isDefault: true,
  initialized: false,
};

/**
 * isDefault means if the current location is geoPlace, ipPlace or first search history place
 */
export function useInitialPlace() {
  const {
    data: searchParamsPlace,
    loading: searchParamsPlaceLoading,
    error: searchParamsPlaceError,
  } = useHomeSearchParamsPlaceQuery();
  const {
    data: geoPlace,
    loading: geoPlaceLoading,
    error: geoPlaceError,
  } = useCurrentUserGeoPlaceQuery();
  const { searchHistory } = useSearchHistory();
  const {
    data: ipPlace,
    loading: ipPlaceLoading,
    error: ipPlaceError,
  } = useCurrentUserIpPlaceQuery();

  const [initialPlace, setInitialPlace] = useRecoilState(initialPlaceState);

  useEffect(() => {
    if (initialPlace.initialized) {
      return;
    }
    let newPlaceData = null;

    if (
      searchParamsPlace ||
      searchParamsPlaceLoading ||
      searchParamsPlaceError
    ) {
      newPlaceData = {
        data: searchParamsPlace,
        loading: searchParamsPlaceLoading,
        error: searchParamsPlaceError,
        isDefault: false,
        initialized: !searchParamsPlaceLoading,
      };
    } else if (
      !searchParamsPlace &&
      !geoPlaceLoading &&
      !geoPlaceError &&
      geoPlace
    ) {
      newPlaceData = {
        data: geoPlace,
        loading: geoPlaceLoading,
        error: geoPlaceError,
        isDefault: true,
        initialized: true,
      };
    } else {
      const firstHistoryPlace = first(searchHistory);
      if (firstHistoryPlace) {
        newPlaceData = {
          data: firstHistoryPlace,
          loading: false,
          error: undefined,
          isDefault: true,
          initialized: !geoPlaceLoading,
        };
      } else if (ipPlace) {
        newPlaceData = {
          data: ipPlace,
          loading: ipPlaceLoading,
          error: ipPlaceError,
          isDefault: true,
          initialized: !geoPlaceLoading,
        };
      } else {
        newPlaceData = {
          ...defaultInitialPlace,
          initialized:
            !geoPlaceLoading &&
            !firstHistoryPlace &&
            !ipPlace &&
            !ipPlaceLoading,
        } as InitialPlaceData;
      }
    }

    /**
     * Update state only if new data is different from current state
     * Because after the code initialized we dont wanna set state which will cause infinite rerender
     * Also even though data is initialized with search place or ip place geoPlace may resolve later
     * In that case we need to set geoPlace data here. That is why we have id check.
     */
    if (
      newPlaceData &&
      (newPlaceData.data?.id !== initialPlace.data?.id ||
        (newPlaceData.initialized && !initialPlace.initialized))
    ) {
      setInitialPlace(newPlaceData as InitialPlaceData);
    }
  }, [
    searchParamsPlace,
    searchParamsPlaceLoading,
    searchParamsPlaceError,
    geoPlace,
    geoPlaceLoading,
    geoPlaceError,
    searchHistory,
    ipPlace,
    ipPlaceLoading,
    ipPlaceError,
    initialPlace,
    setInitialPlace,
  ]);

  return initialPlace;
}
