import { ApolloError, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Icon } from "components/iconv2";

import { Spinner } from "components/spinner";
import { Text } from "components/text_v2";
import { DateRange } from "core/booking_date_range_picker";
import { SearchValue } from "core/booking_utils";
import {
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  QuoteDetailsQuery,
  UserBookingRequestsQuery,
  UserBookingRequestsQueryVariables,
} from "core/graphql.generated";
import { extractFirstGraphQLErrorMessage } from "providers/graphqlv2";
import { StyleSheet, View } from "react-native";
import { useAuthV2 } from "../../../providers/authv2";
import { validateDateRange } from "../utils/validate_date_range";

import { MobileSpaceDynamicPricings } from "./mobile_space_dynamic_pricing";
import { OutOfPolicyDetails } from "./out_of_policy_details";
import { userBookingRequestsQuery } from "./space_quote_details";

interface SpaceQuoteDetailsMobileProps {
  quoteError?: ApolloError;
  quoteQuery?: QuoteDetailsQuery;
  quoteLoading: boolean;
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  searchValue: SearchValue;
  onReserve: () => void;
  onOpenCalendar: () => void;
  dateRange?: DateRange;
}

export function SpaceQuoteDetailsMobile({
  quoteError,
  quoteQuery,
  quoteLoading,
  space,
  searchValue,
  onReserve,
  onOpenCalendar,
  dateRange,
}: SpaceQuoteDetailsMobileProps) {
  const quoteErrorText = extractFirstGraphQLErrorMessage(quoteError);
  const { authenticated } = useAuthV2();
  const { data: bookingRequestsData, refetch: refetchBookingRequests } =
    useQuery<UserBookingRequestsQuery, UserBookingRequestsQueryVariables>(
      userBookingRequestsQuery,
      {
        variables: { spaceId: space.id },
        skip: !authenticated,
      },
    );

  if (
    quoteQuery?.quote.quoteId &&
    quoteQuery.quote.outOfPolicyMessage &&
    searchValue.dateRange &&
    !!bookingRequestsData
  ) {
    return (
      <OutOfPolicyDetails
        dateRange={searchValue.dateRange}
        space={space}
        quoteId={quoteQuery?.quote.quoteId}
        bookingAmount={quoteQuery.quote.totalPrice}
        outOfPolicyMessagge={quoteQuery.quote.outOfPolicyMessage}
        bookingRequestsQuery={bookingRequestsData}
        refetchBookingRequests={refetchBookingRequests}
        quoteQuery={quoteQuery}
        onOpenCalendar={onOpenCalendar}
      />
    );
  }

  return (
    <View>
      {space.locked && !dateRange && (
        <View style={styles.outOfPolicyCard}>
          <View style={styles.cardTitle}>
            <Icon name="guard" />
            <Text weight="semibold" size="xs">
              Out-of-policy
            </Text>
          </View>
          <Text size="xs">You must submit a request to book this space</Text>
        </View>
      )}
      {quoteErrorText && (
        <View style={styles.greyCard}>
          <Text size="xs">{quoteErrorText}</Text>
        </View>
      )}
      <View style={styles.priceWrapper}>
        <View style={{ flex: 1 }}>
          {quoteLoading ? (
            <Spinner />
          ) : (
            <MobileSpaceDynamicPricings
              space={space}
              quoteQuery={quoteQuery}
              dateRange={searchValue.dateRange}
              openCalendar={onOpenCalendar}
            />
          )}
        </View>
        {!quoteError &&
        searchValue.dateRange &&
        !validateDateRange(searchValue.dateRange) ? (
          <Button
            disabled={quoteLoading}
            onPress={onReserve}
            text="Reserve"
            size="small"
            testID="bottom-bar-date-picker-button"
          />
        ) : (
          <Button
            text="Check availability"
            size="small"
            onPress={onOpenCalendar}
            testID="bottom-bar-date-picker-button"
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  greyCard: {
    borderRadius: 4,
    padding: 12,
    backgroundColor: colors.brand.blackMinus90,
  },
  priceWrapper: {
    paddingHorizontal: 12,
    paddingBottom: 32,
    paddingTop: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardTitle: {
    flexDirection: "row",
    alignItems: "center",
    gap: 9,
    marginBottom: 9,
  },
  outOfPolicyCard: {
    padding: 12,
    backgroundColor: colors.brand.bananaMinus70,
  },
});
