import "./bottom_bar_navigation.css";
import { BottomBarNavigationButton } from "./bottom_bar_navigation_button";
import { gql, useQuery } from "@apollo/client";
import { BottomBarAdminNavigationQuery, Plan } from "./graphql.generated";

export function BottomBarAdminNavigation() {
  const { data: currentUserQuery } = useQuery<BottomBarAdminNavigationQuery>(
    bottomBarAdminNavigationQuery,
  );

  const isPro = currentUserQuery?.currentUser?.organization?.plan === Plan.Pro;

  return (
    <div className="bottom-bar-navigation-content">
      {isPro && (
        <BottomBarNavigationButton
          icon="today"
          label="Today"
          to="/admin/today"
        />
      )}
      {isPro && (
        <BottomBarNavigationButton
          icon="budget"
          label="Budgets"
          to="/admin/budgets"
        />
      )}

      {!isPro && (
        <BottomBarNavigationButton
          icon="analytics"
          label="Analytics"
          to="/admin/analytics"
        />
      )}

      <BottomBarNavigationButton
        icon="user-list"
        label="Users"
        to="/admin/manage-users"
      />

      <BottomBarNavigationButton
        icon="organization"
        label="Organization"
        to="/admin/organization"
      />
    </div>
  );
}

const bottomBarAdminNavigationQuery = gql`
  query BottomBarAdminNavigation {
    currentUser {
      id
      organization {
        id
        plan
      }
    }
  }
`;
