import classNames from "classnames";
import { Icon, IconName } from "components/iconv2";
import { Spacer } from "components/spacer";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import "./bottom_bar_navigation.css";

interface BottomBarNavigationButtonProps {
  icon: IconName;
  dynamicIcon?: ReactNode;
  onPress?: () => void;
  label: string;
  to: string;
}

export function BottomBarNavigationButton(
  props: BottomBarNavigationButtonProps,
) {
  const { icon, label, to, dynamicIcon, onPress } = props;
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      onClick={onPress}
      className={classNames(
        "bottom-bar-navigation-button",
        isActive && "bottom-bar-navigation-button-selected",
      )}
    >
      {dynamicIcon ? (
        dynamicIcon
      ) : (
        <Icon
          filled={isActive}
          color={isActive ? "eggplantcore" : undefined}
          name={icon}
        />
      )}

      <Spacer size={4} />
      {label}
    </Link>
  );
}
