import { colors } from "components/colors";
import { useEffect, useRef } from "react";
import { Animated, Easing, StyleSheet, View } from "react-native";

interface PropTypes {
  progress: number;
}

export const SignUpProgressBar = ({ progress = 0 }: PropTypes) => {
  const progressWidthRef = useRef(new Animated.Value(progress));

  useEffect(
    () =>
      Animated.timing(progressWidthRef.current, {
        toValue: progress,
        duration: 200,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(),
    [progress],
  );

  return (
    <View style={styles.wrapper}>
      <Animated.View
        style={[
          styles.inner,
          {
            width: progressWidthRef.current.interpolate({
              inputRange: [0, 1],
              outputRange: ["0%", "100%"],
            }),
          },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.brand.backgroundMain,
    flexGrow: 1,
    height: "12px",
    maxHeight: "12px",
  },
  inner: {
    height: 12,
    backgroundImage: colors.brand.gradient,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
  },
});
