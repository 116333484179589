import { Button } from "components/button_v2";
import { View, StyleSheet } from "react-native";
import { useMediaQuery } from "lib/media_query";
import { tokens } from "components/tokens";
import { colors } from "components/colors";

interface HeaderProps {
  isLogoPressable?: boolean;
  buttonTitle?: string;
  onButtonClick?: () => void;
}

function FlexspaceLogo() {
  return (
    <img
      alt="flexspace-logo"
      src="https://meet-production.s3.us-west-1.amazonaws.com/logos/flexspace-logo-default.svg"
      style={{ height: 36 }}
    />
  );
}

export function OnboardingHeader(props: HeaderProps) {
  const { buttonTitle, onButtonClick, isLogoPressable = true } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  return (
    <View>
      <View style={[styles.containerMob, !isMobile && styles.containerDesk]}>
        <View style={styles.content}>
          {isLogoPressable ? (
            <a href="https://flexspace.ai">
              <FlexspaceLogo />
            </a>
          ) : (
            <FlexspaceLogo />
          )}

          {buttonTitle ? (
            <Button
              appearance="secondary"
              text={buttonTitle}
              testID="header-sign-up-button"
              onPress={onButtonClick}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerMob: {
    paddingVertical: 14,
    backgroundColor: colors.brand.backgroundMain,
    paddingHorizontal: 16,
  },
  containerDesk: {
    paddingHorizontal: 40,
    backgroundColor: tokens.colors.base.white,
  },
  content: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
