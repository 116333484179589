import { gql, useQuery } from "@apollo/client";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import {
  GroupBudgetRole,
  MenuCardListQuery,
  Plan,
  UserRole,
} from "core/graphql.generated";
import { BookingRequestsLabel } from "core/profile_dropdown_menu";
import { appConfig } from "providers/config";
import { useFavoritesFeatureFlag } from "providers/splitio";
import { Fragment } from "react";
import { MenuCard } from "./menu_card";
import { MenuSection } from "./menu_section";

export function MenuCardList() {
  const favoritesFeatureFlag = useFavoritesFeatureFlag();
  const { data } = useQuery<MenuCardListQuery>(menuCardListQuery);
  const isAdmin = data?.currentUser?.role === UserRole.Admin;
  const isManager =
    data?.currentUser?.groupBudgetRole === GroupBudgetRole.Manager;
  const isPro = data?.currentUser?.organization?.plan === Plan.Pro;

  return (
    <Fragment>
      <MenuSection title="Account">
        <MenuCard
          title="Personal information"
          icon="personalInfo"
          to="/user-profile/details"
        />
        <MenuCardDivider />
        {favoritesFeatureFlag && (
          <Fragment>
            <MenuCard title="Saved places" icon="favorite" to="/saved-places" />
            <MenuCardDivider />
          </Fragment>
        )}

        <MenuCard title="Payment" icon="payment" to="/user-profile/payments" />

        <MenuCardDivider />
        <MenuCard
          title="Booking policy"
          icon="guard"
          to="/user-profile/booking-policy"
        />

        {(isAdmin || isManager) && isPro && (
          <>
            <MenuCardDivider />
            <MenuCard
              title={<BookingRequestsLabel size="xs" />}
              icon="page-tick"
              to="/admin/booking-requests"
            />
          </>
        )}
      </MenuSection>

      <MenuSection title="More">
        <MenuCard
          title="Google Calendar add-on"
          icon="calendar"
          to={appConfig.chromeWebstoreUrl}
          externalLink
        />
        <MenuCardDivider />
        <MenuCard
          title="Privacy policy"
          icon="docs"
          to="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-privacy.pdf"
          externalLink
        />
        <MenuCardDivider />
        <MenuCard
          title="Terms"
          icon="docs"
          to="https://flexspace-ds-static.s3.us-west-1.amazonaws.com/flexspace-teams-tc.pdf"
          externalLink
        />
      </MenuSection>
    </Fragment>
  );
}

export const MenuCardDivider = () => (
  <>
    <Spacer size={18} />
    <Divider />
    <Spacer size={18} />
  </>
);

const menuCardListQuery = gql`
  query MenuCardList {
    currentUser {
      id
      role
      groupBudgetRole
      organization {
        id
        paymentMode
        plan
      }
    }
  }
`;
