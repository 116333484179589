import { useQueryString } from "lib/query_string";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type BudgetTab = "current" | "policy";

interface UsePaginationParams {
  page: string;
}

export function usePagination(): [number, (value: number) => void] {
  const { page } = useQueryString<UsePaginationParams>();
  const history = useHistory();
  const location = useLocation();

  const updatePage = useCallback(
    (value: number) => {
      const params = new URLSearchParams(location.search);

      params.set("page", `${value}`);
      const qs = `?${params.toString()}`;

      history.replace(`${location.pathname}${qs}`);
    },
    [history, location.pathname, location.search],
  );

  useEffect(() => {
    if (!page) {
      updatePage(1);
    }
  }, [page, updatePage]);

  return [page ? +page : 1, updatePage];
}
