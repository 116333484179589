import { StyleSheet, View } from "react-native";
import { Divider } from "components/divider";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { ProgressBar } from "components/progress_bar";
import { ReportingBudgetUpdateModal } from "./budget_update_modal";
import { useReportingBudgetCard } from "../hooks/use_reporting_budget_card";
import { useImperativeHandle, useRef } from "react";
import { useScrollToBudgetCardContext } from "../hooks/scroll_to_budget_card_context";
import { ReportingBudgetCardMobileEmpty } from "./budget_card_mobile_empty";
import { BudgetCardEditSection } from "./budget_card_edit_section";

interface ReportingBudgetCardProps {
  editable?: boolean;
  showOnlyTotalSpent?: boolean;
}

export function ReportingBudgetCardMobile(props: ReportingBudgetCardProps) {
  const { editable = false, showOnlyTotalSpent = false } = props;
  const budgetCardElementRef = useRef(null);
  const { budgetCardRef } = useScrollToBudgetCardContext();

  const {
    editing,
    isUpdateBudgetLoading,
    confirmationModalState,
    onBudgetInputChange,
    cancelEditing,
    onBudgetInputSubmit,
    submitNewBudget,
    budgetInputValue,
    setConfirmationModalState,
    setEditing,
    budget: budgetProp,
    isLoading,
    updateBudgetError,
    totalSpendByDate,
  } = useReportingBudgetCard({ fetchTotalSpent: showOnlyTotalSpent });

  useImperativeHandle(budgetCardRef, () => ({
    focus: () => {
      // @ts-ignore
      budgetCardElementRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      // wait for scrollIntoView
      setTimeout(() => setEditing(true), 1000);
    },
  }));

  if (!budgetProp && !isLoading) {
    return (
      <ReportingBudgetCardMobileEmpty
        editing={editing}
        budgetInputValue={budgetInputValue}
        onBudgetInputChange={onBudgetInputChange}
        onBudgetInputSubmit={onBudgetInputSubmit}
        isInvalid={+budgetInputValue < 0}
        invalidText={
          +budgetInputValue < 0
            ? "Budget cannot be a negative number."
            : undefined
        }
        cancelEditing={cancelEditing}
        isUpdateBudgetLoading={isUpdateBudgetLoading}
        budget={0}
        setEditing={setEditing}
        editable={editable}
        budgetCardElementRef={budgetCardElementRef}
        setConfirmationModalState={setConfirmationModalState}
        submitNewBudget={submitNewBudget}
        confirmationModalState={confirmationModalState}
        updateBudgetError={updateBudgetError}
      />
    );
  }

  if (!budgetProp) {
    return null;
  }

  const { remaining, state, totalSpend, budget } = budgetProp;
  const isInputValueLessThanSpent = +budgetInputValue < totalSpend;

  return (
    <>
      <View
        testID="budget-card"
        style={styles.container}
        ref={budgetCardElementRef}
      >
        <View style={styles.numberWrapper}>
          {showOnlyTotalSpent ? (
            <View style={styles.numberContainer}>
              <View style={styles.onlyTotalSpent}>
                <Text testID="budget-spent-amount" weight="bold" size="mega">
                  {formatCurrencyWithoutDecimal(
                    totalSpendByDate,
                    getSystemLocale(),
                    "USD",
                  )}{" "}
                </Text>
                <Text size="xs">Total Spent</Text>
              </View>
            </View>
          ) : (
            <View style={styles.numberContainer}>
              <View style={styles.totalSpent}>
                <Text>
                  <Text testID="budget-spent-amount" weight="bold" size="lg">
                    {formatCurrencyWithoutDecimal(
                      totalSpend,
                      getSystemLocale(),
                      "USD",
                    )}{" "}
                  </Text>
                  <Text size="xs">Spent</Text>
                </Text>
              </View>
              <ProgressBar
                color={
                  state === "AboveWarningThreshold" ? "warning" : "primary"
                }
                progress={(totalSpend / budget) * 100}
              />
              <View style={styles.remaining}>
                <Text align="right">
                  <Text weight="bold" size="xs">
                    Remaining:{" "}
                  </Text>
                  <Text testID="budget-remaining-amount" size="xs">
                    {formatCurrencyWithoutDecimal(
                      remaining,
                      getSystemLocale(),
                      "USD",
                    )}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          <Divider />
          <BudgetCardEditSection
            editing={editing}
            budgetInputValue={budgetInputValue}
            onBudgetInputChange={onBudgetInputChange}
            onBudgetInputSubmit={onBudgetInputSubmit}
            isInvalid={isInputValueLessThanSpent}
            invalidText={
              isInputValueLessThanSpent
                ? "Budget cannot be less than the amount spent."
                : undefined
            }
            cancelEditing={cancelEditing}
            isUpdateBudgetLoading={isUpdateBudgetLoading}
            budget={budget}
            setEditing={setEditing}
            editable={editable}
          />
        </View>
        {!!confirmationModalState && (
          <ReportingBudgetUpdateModal
            onCancel={() => setConfirmationModalState(false)}
            onConfirm={submitNewBudget}
            loading={isUpdateBudgetLoading}
            updateBudgetError={updateBudgetError}
          />
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "white",
    borderRadius: 16,
    ...tokens.shadow.elevation1,
  },
  numberWrapper: {},
  numberContainer: {
    padding: 40,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  totalSpent: { alignSelf: "flex-start" },
  remaining: { alignSelf: "flex-end" },
  onlyTotalSpent: {
    alignItems: "center",
  },
});
