import { gql, useQuery } from "@apollo/client";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";
import { SelectInputHeaderVariant } from "components/select/select_input_header_variant";
import { Text } from "components/text_v2";
import { SpaceType, UserSpacePolicyQuery } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";
import { HeaderSpaceTypePickerMobile } from "./header_space_type_picker_mobile";

const getSpaceTypeOptions = (spacePolicy?: {
  meetingRoom: boolean;
  dayPass: boolean;
  privateOffice: boolean;
}) => [
  {
    label: (
      <View>
        <HeaderSpaceTypeLabel
          label="Meeting room"
          outOfPolicy={!!spacePolicy && !spacePolicy.meetingRoom}
        />
        <Text size="xs">
          Rooms available by the hour for collaborating with others.
        </Text>
      </View>
    ),
    selectedLabel: "Meeting room",
    value: SpaceType.MeetingRoom,
  },
  {
    label: (
      <View>
        <HeaderSpaceTypeLabel
          label="Day pass"
          outOfPolicy={!!spacePolicy && !spacePolicy.dayPass}
        />
        <Text size="xs">
          Access to shared spaces and amenities for the day.
        </Text>
      </View>
    ),
    selectedLabel: "Day pass",
    value: SpaceType.DayPass,
  },
  {
    label: (
      <View>
        <HeaderSpaceTypeLabel
          label="Private office"
          outOfPolicy={!!spacePolicy && !spacePolicy.privateOffice}
        />
        <Text size="xs">
          Full-day private offices for just you or your whole team.
        </Text>
      </View>
    ),
    selectedLabel: "Private office",
    value: SpaceType.DayOffice,
  },
];

interface HeaderSpaceTypeLabelPropsType {
  label: string;
  outOfPolicy: boolean;
}

function HeaderSpaceTypeLabel({
  outOfPolicy,
  label,
}: HeaderSpaceTypeLabelPropsType) {
  return (
    <View style={styles.spaceLabelWrapper}>
      <Text weight="semibold" size="xs">
        {label}
      </Text>
      {outOfPolicy && <OutOfPolicyTag />}
    </View>
  );
}
interface HeaderSpaceTypePickerProps {
  value: string | undefined;
  onChange: (value: any) => void;
  linkToSearchPage?: boolean;
}

export function HeaderSpaceTypePicker(props: HeaderSpaceTypePickerProps) {
  const { onChange, value, linkToSearchPage = false } = props;
  const mq = useMediaQuery();

  const { data } = useQuery<UserSpacePolicyQuery>(userSpacePolicyQuery);
  const spacePolicy =
    data?.currentUser?.groupBudget?.policy?.spaceTypes ||
    data?.currentUser?.organization?.budget?.policy?.spaceTypes;

  if (mq.deviceQuery.mobile) {
    return (
      <HeaderSpaceTypePickerMobile
        spacePolicy={spacePolicy}
        linkToSearchPage={linkToSearchPage}
      />
    );
  }

  return (
    <SelectInputHeaderVariant
      onChange={onChange}
      value={value}
      options={getSpaceTypeOptions(spacePolicy)}
    />
  );
}

const styles = StyleSheet.create({
  spaceLabelWrapper: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    marginBottom: 8,
  },
});

const userSpacePolicyQuery = gql`
  query userSpacePolicy {
    currentUser {
      organization {
        budget {
          policy {
            spaceTypes {
              meetingRoom
              dayPass
              privateOffice
            }
          }
        }
      }
      groupBudget {
        policy {
          spaceTypes {
            meetingRoom
            dayPass
            privateOffice
          }
        }
      }
    }
  }
`;
