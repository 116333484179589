import { ApolloError } from "@apollo/client";
import { Button } from "components/button_v2";
import { Divider } from "components/divider";
import { DropdownV2 } from "components/dropdown_v2";
import { Spacer } from "components/spacer";
import { DateRange } from "core/booking_date_range_picker";
import { BookingDateRangePickerDesktop } from "core/booking_date_range_picker_desktop";
import {
  AvailableTimeSlot,
  BestDayRates,
  BookingType,
  PriceRangesType,
  Pricing,
} from "core/graphql.generated";
import { Day } from "lib/day_utils";
import { Fragment, ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "./text";
import { Text as TextV2 } from "./text_v2";

interface DropDownProps {
  children: ReactNode;
  openDropDown: boolean;
  closeDropDown: (show: boolean) => void;
  handleSaveDateRange?: () => void;
  handleClear: () => void;
  handleChangeDateRange: (value?: DateRange) => void;
  handleChangeBookingType: (bookingType: BookingType) => void;
  dateRange?: DateRange;
  bookingType?: BookingType;
  showSegmentedControl?: boolean;
  multiDayBookingAllowed?: boolean;
  timeslots: AvailableTimeSlot[];
  isBlockedDate?: (day: Day, bookingType: BookingType) => boolean;
  isOutsideRange?: (day: Day) => boolean;
  error?: string;
  availableDaysError?: ApolloError | undefined;
  availableTimeSlotsError?: ApolloError | undefined;
  pricings: Pricing[];
  dynamicPrices: BestDayRates[];
  currency: string;
  spaceId: string;
  priceRanges?: PriceRangesType;
}

export function BookingDateRangeDropDownDesktop(props: DropDownProps) {
  const {
    children,
    openDropDown,
    closeDropDown,
    handleSaveDateRange,
    handleClear,
    handleChangeDateRange,
    dateRange,
    bookingType = BookingType.HourlyBooking,
    handleChangeBookingType,
    showSegmentedControl = true,
    multiDayBookingAllowed = true,
    timeslots,
    isBlockedDate,
    isOutsideRange,
    error,
    availableDaysError,
    availableTimeSlotsError,
    pricings,
    dynamicPrices,
    currency,
    spaceId,
    priceRanges,
  } = props;

  return (
    <DropdownV2
      open={openDropDown}
      onRequestClose={() => closeDropDown(false)}
      placement="bottom-end"
      sameWidth={false}
      offsetVertical={4}
      content={
        <View style={styles.container}>
          <View style={styles.datePicker}>
            <TextV2 size={"md"} weight={"semibold"}>
              When do you need the space?
            </TextV2>
            <Spacer size={4} />
            <TextV2 size={"micro"} color={"black-50"}>
              {`Add your preferred date${
                bookingType === BookingType.HourlyBooking ? " and time " : " "
              }for exact pricing`}
            </TextV2>
            <Spacer size={20} />
            <BookingDateRangePickerDesktop
              value={dateRange}
              onChange={handleChangeDateRange}
              bookingType={bookingType}
              onChangeBookingType={handleChangeBookingType}
              showSegmentedControl={showSegmentedControl}
              multiDayBookingAllowed={multiDayBookingAllowed}
              timeslots={timeslots}
              isOutsideRange={isOutsideRange}
              isBlockedDate={isBlockedDate}
              pricings={pricings}
              dynamicPrices={dynamicPrices}
              currency={currency}
              spaceId={spaceId}
              priceRanges={priceRanges}
            />
            {error !== "" && (
              <Fragment>
                <Spacer size={16} />
                <Text color="error">{error}</Text>
              </Fragment>
            )}
            {(availableDaysError || availableTimeSlotsError) && (
              <Fragment>
                <Spacer size={16} />
                <Text color="error">
                  There are issues getting space's availability. Please try
                  again later.
                </Text>
              </Fragment>
            )}
            <Spacer size={16} />

            <Spacer size={16} />
          </View>
          <Divider />
          <View style={styles.button}>
            <Button
              text={"Clear all"}
              appearance={"textLink"}
              onPress={handleClear}
            />
            <Button
              testID="date-picker-save-button"
              text={"Done"}
              onPress={handleSaveDateRange}
            />
          </View>
        </View>
      }
    >
      {children}
    </DropdownV2>
  );
}
const styles = StyleSheet.create({
  container: {
    width: 382,
    backgroundColor: "white",
    boxShadow: "0px 1px 10px rgba(6, 0, 30, 0.2)",
    borderRadius: 4,
  },
  datePicker: {
    display: "flex",
    padding: 24,
  },
  button: {
    position: "relative",
    zIndex: 99999,
    marginVertical: 16,
    marginRight: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
