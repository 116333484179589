import { gql, useQuery } from "@apollo/client";
import {
  BookingRequestStatus,
  TodayPage__RequestDetailsFragment,
  TodayRequestsQuery,
} from "core/graphql.generated";
import { useUserRoleQuery } from "hooks/use_user_role_query";
import { isBefore } from "lib/date_utils";
import { parseDay } from "lib/day_utils";
import { useMediaQuery } from "lib/media_query";
import { TodayDesktop } from "./today_desktop";
import { TodayMobile } from "./today_mobile";

export function TodayPage() {
  const mq = useMediaQuery();
  const { isAdmin, isManager } = useUserRoleQuery();
  const { data, loading: requestsLoading } =
    useQuery<TodayRequestsQuery>(todayRequestsQuery);

  let requests: TodayPage__RequestDetailsFragment[] = [];
  if (isAdmin && data?.currentUser?.organization?.bookingRequests) {
    requests = data?.currentUser?.organization?.bookingRequests;
  } else if (isManager && data?.currentUser?.groupBudget?.bookingRequests) {
    requests = data?.currentUser?.groupBudget?.bookingRequests;
  }

  const transformedRequests = requests
    .filter((req) => req.status === BookingRequestStatus.Pending)
    .sort((req1, req2) =>
      isBefore(parseDay(req1.startDate), parseDay(req2.startDate)) ? -1 : 1,
    );

  if (mq.deviceQuery.mobile) {
    return (
      <TodayMobile
        requests={transformedRequests}
        requestsLoading={requestsLoading}
      />
    );
  }

  return (
    <TodayDesktop
      requests={transformedRequests}
      requestsLoading={requestsLoading}
    />
  );
}

const todayRequestsQuery = gql`
  query TodayRequests {
    currentUser {
      organization {
        bookingRequests {
          ...TodayPage__RequestDetails
        }
      }
      groupBudget {
        bookingRequests {
          ...TodayPage__RequestDetails
        }
      }
    }
  }

  fragment TodayPage__RequestDetails on BookingRequest {
    id
    startDate
    reason
    status
    requester {
      picture
      fullName
    }
  }
`;
