// From https://github.com/annexare/Countries/blob/master/dist/countries.json

import { valuesOf } from "./object_utils";

export interface Country {
  /**
   * Currency alpha-3 codes, comma-separated.
   */
  currency: string;
  /**
   * Country flag Emoji.
   */
  emoji: string;
  /**
   * Country name in English.
   */
  name: string;
  /**
   * Country name written natively.
   */
  native: string;
  /**
   * Calling phone codes, comma-separated.
   */
  phone: string;
}

interface CountriesByAlpha2Code {
  [alpha2Code: string]: Country;
}

export const countriesByAlpha2Code: CountriesByAlpha2Code = {
  AD: {
    name: "Andorra",
    native: "Andorra",
    phone: "376",
    currency: "EUR",
    emoji: "🇦🇩",
  },
  AE: {
    name: "United Arab Emirates",
    native: "دولة الإمارات العربية المتحدة",
    phone: "971",
    currency: "AED",
    emoji: "🇦🇪",
  },
  AF: {
    name: "Afghanistan",
    native: "افغانستان",
    phone: "93",
    currency: "AFN",
    emoji: "🇦🇫",
  },
  AG: {
    name: "Antigua and Barbuda",
    native: "Antigua and Barbuda",
    phone: "1268",
    currency: "XCD",
    emoji: "🇦🇬",
  },
  AI: {
    name: "Anguilla",
    native: "Anguilla",
    phone: "1264",
    currency: "XCD",
    emoji: "🇦🇮",
  },
  AL: {
    name: "Albania",
    native: "Shqipëria",
    phone: "355",
    currency: "ALL",
    emoji: "🇦🇱",
  },
  AM: {
    name: "Armenia",
    native: "Հայաստան",
    phone: "374",
    currency: "AMD",
    emoji: "🇦🇲",
  },
  AO: {
    name: "Angola",
    native: "Angola",
    phone: "244",
    currency: "AOA",
    emoji: "🇦🇴",
  },
  AQ: {
    name: "Antarctica",
    native: "Antarctica",
    phone: "672",
    currency: "",
    emoji: "🇦🇶",
  },
  AR: {
    name: "Argentina",
    native: "Argentina",
    phone: "54",
    currency: "ARS",
    emoji: "🇦🇷",
  },
  AS: {
    name: "American Samoa",
    native: "American Samoa",
    phone: "1684",
    currency: "USD",
    emoji: "🇦🇸",
  },
  AT: {
    name: "Austria",
    native: "Österreich",
    phone: "43",
    currency: "EUR",
    emoji: "🇦🇹",
  },
  AU: {
    name: "Australia",
    native: "Australia",
    phone: "61",
    currency: "AUD",
    emoji: "🇦🇺",
  },
  AW: {
    name: "Aruba",
    native: "Aruba",
    phone: "297",
    currency: "AWG",
    emoji: "🇦🇼",
  },
  AX: {
    name: "Åland",
    native: "Åland",
    phone: "358",
    currency: "EUR",
    emoji: "🇦🇽",
  },
  AZ: {
    name: "Azerbaijan",
    native: "Azərbaycan",
    phone: "994",
    currency: "AZN",
    emoji: "🇦🇿",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    native: "Bosna i Hercegovina",
    phone: "387",
    currency: "BAM",
    emoji: "🇧🇦",
  },
  BB: {
    name: "Barbados",
    native: "Barbados",
    phone: "1246",
    currency: "BBD",
    emoji: "🇧🇧",
  },
  BD: {
    name: "Bangladesh",
    native: "Bangladesh",
    phone: "880",
    currency: "BDT",
    emoji: "🇧🇩",
  },
  BE: {
    name: "Belgium",
    native: "België",
    phone: "32",
    currency: "EUR",
    emoji: "🇧🇪",
  },
  BF: {
    name: "Burkina Faso",
    native: "Burkina Faso",
    phone: "226",
    currency: "XOF",
    emoji: "🇧🇫",
  },
  BG: {
    name: "Bulgaria",
    native: "България",
    phone: "359",
    currency: "BGN",
    emoji: "🇧🇬",
  },
  BH: {
    name: "Bahrain",
    native: "‏البحرين",
    phone: "973",
    currency: "BHD",
    emoji: "🇧🇭",
  },
  BI: {
    name: "Burundi",
    native: "Burundi",
    phone: "257",
    currency: "BIF",
    emoji: "🇧🇮",
  },
  BJ: {
    name: "Benin",
    native: "Bénin",
    phone: "229",
    currency: "XOF",
    emoji: "🇧🇯",
  },
  BL: {
    name: "Saint Barthélemy",
    native: "Saint-Barthélemy",
    phone: "590",
    currency: "EUR",
    emoji: "🇧🇱",
  },
  BM: {
    name: "Bermuda",
    native: "Bermuda",
    phone: "1441",
    currency: "BMD",
    emoji: "🇧🇲",
  },
  BN: {
    name: "Brunei",
    native: "Negara Brunei Darussalam",
    phone: "673",
    currency: "BND",
    emoji: "🇧🇳",
  },
  BO: {
    name: "Bolivia",
    native: "Bolivia",
    phone: "591",
    currency: "BOB,BOV",
    emoji: "🇧🇴",
  },
  BQ: {
    name: "Bonaire",
    native: "Bonaire",
    phone: "5997",
    currency: "USD",
    emoji: "🇧🇶",
  },
  BR: {
    name: "Brazil",
    native: "Brasil",
    phone: "55",
    currency: "BRL",
    emoji: "🇧🇷",
  },
  BS: {
    name: "Bahamas",
    native: "Bahamas",
    phone: "1242",
    currency: "BSD",
    emoji: "🇧🇸",
  },
  BT: {
    name: "Bhutan",
    native: "ʼbrug-yul",
    phone: "975",
    currency: "BTN,INR",
    emoji: "🇧🇹",
  },
  BV: {
    name: "Bouvet Island",
    native: "Bouvetøya",
    phone: "47",
    currency: "NOK",
    emoji: "🇧🇻",
  },
  BW: {
    name: "Botswana",
    native: "Botswana",
    phone: "267",
    currency: "BWP",
    emoji: "🇧🇼",
  },
  BY: {
    name: "Belarus",
    native: "Белару́сь",
    phone: "375",
    currency: "BYN",
    emoji: "🇧🇾",
  },
  BZ: {
    name: "Belize",
    native: "Belize",
    phone: "501",
    currency: "BZD",
    emoji: "🇧🇿",
  },
  CA: {
    name: "Canada",
    native: "Canada",
    phone: "1",
    currency: "CAD",
    emoji: "🇨🇦",
  },
  CC: {
    name: "Cocos [Keeling] Islands",
    native: "Cocos (Keeling) Islands",
    phone: "61",
    currency: "AUD",
    emoji: "🇨🇨",
  },
  CD: {
    name: "Democratic Republic of the Congo",
    native: "République démocratique du Congo",
    phone: "243",
    currency: "CDF",
    emoji: "🇨🇩",
  },
  CF: {
    name: "Central African Republic",
    native: "Ködörösêse tî Bêafrîka",
    phone: "236",
    currency: "XAF",
    emoji: "🇨🇫",
  },
  CG: {
    name: "Republic of the Congo",
    native: "République du Congo",
    phone: "242",
    currency: "XAF",
    emoji: "🇨🇬",
  },
  CH: {
    name: "Switzerland",
    native: "Schweiz",
    phone: "41",
    currency: "CHE,CHF,CHW",
    emoji: "🇨🇭",
  },
  CI: {
    name: "Ivory Coast",
    native: "Côte d'Ivoire",
    phone: "225",
    currency: "XOF",
    emoji: "🇨🇮",
  },
  CK: {
    name: "Cook Islands",
    native: "Cook Islands",
    phone: "682",
    currency: "NZD",
    emoji: "🇨🇰",
  },
  CL: {
    name: "Chile",
    native: "Chile",
    phone: "56",
    currency: "CLF,CLP",
    emoji: "🇨🇱",
  },
  CM: {
    name: "Cameroon",
    native: "Cameroon",
    phone: "237",
    currency: "XAF",
    emoji: "🇨🇲",
  },
  CN: {
    name: "China",
    native: "中国",
    phone: "86",
    currency: "CNY",
    emoji: "🇨🇳",
  },
  CO: {
    name: "Colombia",
    native: "Colombia",
    phone: "57",
    currency: "COP",
    emoji: "🇨🇴",
  },
  CR: {
    name: "Costa Rica",
    native: "Costa Rica",
    phone: "506",
    currency: "CRC",
    emoji: "🇨🇷",
  },
  CU: {
    name: "Cuba",
    native: "Cuba",
    phone: "53",
    currency: "CUC,CUP",
    emoji: "🇨🇺",
  },
  CV: {
    name: "Cape Verde",
    native: "Cabo Verde",
    phone: "238",
    currency: "CVE",
    emoji: "🇨🇻",
  },
  CW: {
    name: "Curacao",
    native: "Curaçao",
    phone: "5999",
    currency: "ANG",
    emoji: "🇨🇼",
  },
  CX: {
    name: "Christmas Island",
    native: "Christmas Island",
    phone: "61",
    currency: "AUD",
    emoji: "🇨🇽",
  },
  CY: {
    name: "Cyprus",
    native: "Κύπρος",
    phone: "357",
    currency: "EUR",
    emoji: "🇨🇾",
  },
  CZ: {
    name: "Czech Republic",
    native: "Česká republika",
    phone: "420",
    currency: "CZK",
    emoji: "🇨🇿",
  },
  DE: {
    name: "Germany",
    native: "Deutschland",
    phone: "49",
    currency: "EUR",
    emoji: "🇩🇪",
  },
  DJ: {
    name: "Djibouti",
    native: "Djibouti",
    phone: "253",
    currency: "DJF",
    emoji: "🇩🇯",
  },
  DK: {
    name: "Denmark",
    native: "Danmark",
    phone: "45",
    currency: "DKK",
    emoji: "🇩🇰",
  },
  DM: {
    name: "Dominica",
    native: "Dominica",
    phone: "1767",
    currency: "XCD",
    emoji: "🇩🇲",
  },
  DO: {
    name: "Dominican Republic",
    native: "República Dominicana",
    phone: "1809,1829,1849",
    currency: "DOP",
    emoji: "🇩🇴",
  },
  DZ: {
    name: "Algeria",
    native: "الجزائر",
    phone: "213",
    currency: "DZD",
    emoji: "🇩🇿",
  },
  EC: {
    name: "Ecuador",
    native: "Ecuador",
    phone: "593",
    currency: "USD",
    emoji: "🇪🇨",
  },
  EE: {
    name: "Estonia",
    native: "Eesti",
    phone: "372",
    currency: "EUR",
    emoji: "🇪🇪",
  },
  EG: {
    name: "Egypt",
    native: "مصر‎",
    phone: "20",
    currency: "EGP",
    emoji: "🇪🇬",
  },
  EH: {
    name: "Western Sahara",
    native: "الصحراء الغربية",
    phone: "212",
    currency: "MAD,DZD,MRU",
    emoji: "🇪🇭",
  },
  ER: {
    name: "Eritrea",
    native: "ኤርትራ",
    phone: "291",
    currency: "ERN",
    emoji: "🇪🇷",
  },
  ES: {
    name: "Spain",
    native: "España",
    phone: "34",
    currency: "EUR",
    emoji: "🇪🇸",
  },
  ET: {
    name: "Ethiopia",
    native: "ኢትዮጵያ",
    phone: "251",
    currency: "ETB",
    emoji: "🇪🇹",
  },
  FI: {
    name: "Finland",
    native: "Suomi",
    phone: "358",
    currency: "EUR",
    emoji: "🇫🇮",
  },
  FJ: {
    name: "Fiji",
    native: "Fiji",
    phone: "679",
    currency: "FJD",
    emoji: "🇫🇯",
  },
  FK: {
    name: "Falkland Islands",
    native: "Falkland Islands",
    phone: "500",
    currency: "FKP",
    emoji: "🇫🇰",
  },
  FM: {
    name: "Micronesia",
    native: "Micronesia",
    phone: "691",
    currency: "USD",
    emoji: "🇫🇲",
  },
  FO: {
    name: "Faroe Islands",
    native: "Føroyar",
    phone: "298",
    currency: "DKK",
    emoji: "🇫🇴",
  },
  FR: {
    name: "France",
    native: "France",
    phone: "33",
    currency: "EUR",
    emoji: "🇫🇷",
  },
  GA: {
    name: "Gabon",
    native: "Gabon",
    phone: "241",
    currency: "XAF",
    emoji: "🇬🇦",
  },
  GB: {
    name: "United Kingdom",
    native: "United Kingdom",
    phone: "44",
    currency: "GBP",
    emoji: "🇬🇧",
  },
  GD: {
    name: "Grenada",
    native: "Grenada",
    phone: "1473",
    currency: "XCD",
    emoji: "🇬🇩",
  },
  GE: {
    name: "Georgia",
    native: "საქართველო",
    phone: "995",
    currency: "GEL",
    emoji: "🇬🇪",
  },
  GF: {
    name: "French Guiana",
    native: "Guyane française",
    phone: "594",
    currency: "EUR",
    emoji: "🇬🇫",
  },
  GG: {
    name: "Guernsey",
    native: "Guernsey",
    phone: "44",
    currency: "GBP",
    emoji: "🇬🇬",
  },
  GH: {
    name: "Ghana",
    native: "Ghana",
    phone: "233",
    currency: "GHS",
    emoji: "🇬🇭",
  },
  GI: {
    name: "Gibraltar",
    native: "Gibraltar",
    phone: "350",
    currency: "GIP",
    emoji: "🇬🇮",
  },
  GL: {
    name: "Greenland",
    native: "Kalaallit Nunaat",
    phone: "299",
    currency: "DKK",
    emoji: "🇬🇱",
  },
  GM: {
    name: "Gambia",
    native: "Gambia",
    phone: "220",
    currency: "GMD",
    emoji: "🇬🇲",
  },
  GN: {
    name: "Guinea",
    native: "Guinée",
    phone: "224",
    currency: "GNF",
    emoji: "🇬🇳",
  },
  GP: {
    name: "Guadeloupe",
    native: "Guadeloupe",
    phone: "590",
    currency: "EUR",
    emoji: "🇬🇵",
  },
  GQ: {
    name: "Equatorial Guinea",
    native: "Guinea Ecuatorial",
    phone: "240",
    currency: "XAF",
    emoji: "🇬🇶",
  },
  GR: {
    name: "Greece",
    native: "Ελλάδα",
    phone: "30",
    currency: "EUR",
    emoji: "🇬🇷",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    native: "South Georgia",
    phone: "500",
    currency: "GBP",
    emoji: "🇬🇸",
  },
  GT: {
    name: "Guatemala",
    native: "Guatemala",
    phone: "502",
    currency: "GTQ",
    emoji: "🇬🇹",
  },
  GU: {
    name: "Guam",
    native: "Guam",
    phone: "1671",
    currency: "USD",
    emoji: "🇬🇺",
  },
  GW: {
    name: "Guinea-Bissau",
    native: "Guiné-Bissau",
    phone: "245",
    currency: "XOF",
    emoji: "🇬🇼",
  },
  GY: {
    name: "Guyana",
    native: "Guyana",
    phone: "592",
    currency: "GYD",
    emoji: "🇬🇾",
  },
  HK: {
    name: "Hong Kong",
    native: "香港",
    phone: "852",
    currency: "HKD",
    emoji: "🇭🇰",
  },
  HM: {
    name: "Heard Island and McDonald Islands",
    native: "Heard Island and McDonald Islands",
    phone: "61",
    currency: "AUD",
    emoji: "🇭🇲",
  },
  HN: {
    name: "Honduras",
    native: "Honduras",
    phone: "504",
    currency: "HNL",
    emoji: "🇭🇳",
  },
  HR: {
    name: "Croatia",
    native: "Hrvatska",
    phone: "385",
    currency: "HRK",
    emoji: "🇭🇷",
  },
  HT: {
    name: "Haiti",
    native: "Haïti",
    phone: "509",
    currency: "HTG,USD",
    emoji: "🇭🇹",
  },
  HU: {
    name: "Hungary",
    native: "Magyarország",
    phone: "36",
    currency: "HUF",
    emoji: "🇭🇺",
  },
  ID: {
    name: "Indonesia",
    native: "Indonesia",
    phone: "62",
    currency: "IDR",
    emoji: "🇮🇩",
  },
  IE: {
    name: "Ireland",
    native: "Éire",
    phone: "353",
    currency: "EUR",
    emoji: "🇮🇪",
  },
  IL: {
    name: "Israel",
    native: "יִשְׂרָאֵל",
    phone: "972",
    currency: "ILS",
    emoji: "🇮🇱",
  },
  IM: {
    name: "Isle of Man",
    native: "Isle of Man",
    phone: "44",
    currency: "GBP",
    emoji: "🇮🇲",
  },
  IN: {
    name: "India",
    native: "भारत",
    phone: "91",
    currency: "INR",
    emoji: "🇮🇳",
  },
  IO: {
    name: "British Indian Ocean Territory",
    native: "British Indian Ocean Territory",
    phone: "246",
    currency: "USD",
    emoji: "🇮🇴",
  },
  IQ: {
    name: "Iraq",
    native: "العراق",
    phone: "964",
    currency: "IQD",
    emoji: "🇮🇶",
  },
  IR: {
    name: "Iran",
    native: "ایران",
    phone: "98",
    currency: "IRR",
    emoji: "🇮🇷",
  },
  IS: {
    name: "Iceland",
    native: "Ísland",
    phone: "354",
    currency: "ISK",
    emoji: "🇮🇸",
  },
  IT: {
    name: "Italy",
    native: "Italia",
    phone: "39",
    currency: "EUR",
    emoji: "🇮🇹",
  },
  JE: {
    name: "Jersey",
    native: "Jersey",
    phone: "44",
    currency: "GBP",
    emoji: "🇯🇪",
  },
  JM: {
    name: "Jamaica",
    native: "Jamaica",
    phone: "1876",
    currency: "JMD",
    emoji: "🇯🇲",
  },
  JO: {
    name: "Jordan",
    native: "الأردن",
    phone: "962",
    currency: "JOD",
    emoji: "🇯🇴",
  },
  JP: {
    name: "Japan",
    native: "日本",
    phone: "81",
    currency: "JPY",
    emoji: "🇯🇵",
  },
  KE: {
    name: "Kenya",
    native: "Kenya",
    phone: "254",
    currency: "KES",
    emoji: "🇰🇪",
  },
  KG: {
    name: "Kyrgyzstan",
    native: "Кыргызстан",
    phone: "996",
    currency: "KGS",
    emoji: "🇰🇬",
  },
  KH: {
    name: "Cambodia",
    native: "Kâmpŭchéa",
    phone: "855",
    currency: "KHR",
    emoji: "🇰🇭",
  },
  KI: {
    name: "Kiribati",
    native: "Kiribati",
    phone: "686",
    currency: "AUD",
    emoji: "🇰🇮",
  },
  KM: {
    name: "Comoros",
    native: "Komori",
    phone: "269",
    currency: "KMF",
    emoji: "🇰🇲",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    native: "Saint Kitts and Nevis",
    phone: "1869",
    currency: "XCD",
    emoji: "🇰🇳",
  },
  KP: {
    name: "North Korea",
    native: "북한",
    phone: "850",
    currency: "KPW",
    emoji: "🇰🇵",
  },
  KR: {
    name: "South Korea",
    native: "대한민국",
    phone: "82",
    currency: "KRW",
    emoji: "🇰🇷",
  },
  KW: {
    name: "Kuwait",
    native: "الكويت",
    phone: "965",
    currency: "KWD",
    emoji: "🇰🇼",
  },
  KY: {
    name: "Cayman Islands",
    native: "Cayman Islands",
    phone: "1345",
    currency: "KYD",
    emoji: "🇰🇾",
  },
  KZ: {
    name: "Kazakhstan",
    native: "Қазақстан",
    phone: "76,77",
    currency: "KZT",
    emoji: "🇰🇿",
  },
  LA: {
    name: "Laos",
    native: "ສປປລາວ",
    phone: "856",
    currency: "LAK",
    emoji: "🇱🇦",
  },
  LB: {
    name: "Lebanon",
    native: "لبنان",
    phone: "961",
    currency: "LBP",
    emoji: "🇱🇧",
  },
  LC: {
    name: "Saint Lucia",
    native: "Saint Lucia",
    phone: "1758",
    currency: "XCD",
    emoji: "🇱🇨",
  },
  LI: {
    name: "Liechtenstein",
    native: "Liechtenstein",
    phone: "423",
    currency: "CHF",
    emoji: "🇱🇮",
  },
  LK: {
    name: "Sri Lanka",
    native: "śrī laṃkāva",
    phone: "94",
    currency: "LKR",
    emoji: "🇱🇰",
  },
  LR: {
    name: "Liberia",
    native: "Liberia",
    phone: "231",
    currency: "LRD",
    emoji: "🇱🇷",
  },
  LS: {
    name: "Lesotho",
    native: "Lesotho",
    phone: "266",
    currency: "LSL,ZAR",
    emoji: "🇱🇸",
  },
  LT: {
    name: "Lithuania",
    native: "Lietuva",
    phone: "370",
    currency: "EUR",
    emoji: "🇱🇹",
  },
  LU: {
    name: "Luxembourg",
    native: "Luxembourg",
    phone: "352",
    currency: "EUR",
    emoji: "🇱🇺",
  },
  LV: {
    name: "Latvia",
    native: "Latvija",
    phone: "371",
    currency: "EUR",
    emoji: "🇱🇻",
  },
  LY: {
    name: "Libya",
    native: "‏ليبيا",
    phone: "218",
    currency: "LYD",
    emoji: "🇱🇾",
  },
  MA: {
    name: "Morocco",
    native: "المغرب",
    phone: "212",
    currency: "MAD",
    emoji: "🇲🇦",
  },
  MC: {
    name: "Monaco",
    native: "Monaco",
    phone: "377",
    currency: "EUR",
    emoji: "🇲🇨",
  },
  MD: {
    name: "Moldova",
    native: "Moldova",
    phone: "373",
    currency: "MDL",
    emoji: "🇲🇩",
  },
  ME: {
    name: "Montenegro",
    native: "Црна Гора",
    phone: "382",
    currency: "EUR",
    emoji: "🇲🇪",
  },
  MF: {
    name: "Saint Martin",
    native: "Saint-Martin",
    phone: "590",
    currency: "EUR",
    emoji: "🇲🇫",
  },
  MG: {
    name: "Madagascar",
    native: "Madagasikara",
    phone: "261",
    currency: "MGA",
    emoji: "🇲🇬",
  },
  MH: {
    name: "Marshall Islands",
    native: "M̧ajeļ",
    phone: "692",
    currency: "USD",
    emoji: "🇲🇭",
  },
  MK: {
    name: "North Macedonia",
    native: "Северна Македонија",
    phone: "389",
    currency: "MKD",
    emoji: "🇲🇰",
  },
  ML: {
    name: "Mali",
    native: "Mali",
    phone: "223",
    currency: "XOF",
    emoji: "🇲🇱",
  },
  MM: {
    name: "Myanmar [Burma]",
    native: "မြန်မာ",
    phone: "95",
    currency: "MMK",
    emoji: "🇲🇲",
  },
  MN: {
    name: "Mongolia",
    native: "Монгол улс",
    phone: "976",
    currency: "MNT",
    emoji: "🇲🇳",
  },
  MO: {
    name: "Macao",
    native: "澳門",
    phone: "853",
    currency: "MOP",
    emoji: "🇲🇴",
  },
  MP: {
    name: "Northern Mariana Islands",
    native: "Northern Mariana Islands",
    phone: "1670",
    currency: "USD",
    emoji: "🇲🇵",
  },
  MQ: {
    name: "Martinique",
    native: "Martinique",
    phone: "596",
    currency: "EUR",
    emoji: "🇲🇶",
  },
  MR: {
    name: "Mauritania",
    native: "موريتانيا",
    phone: "222",
    currency: "MRU",
    emoji: "🇲🇷",
  },
  MS: {
    name: "Montserrat",
    native: "Montserrat",
    phone: "1664",
    currency: "XCD",
    emoji: "🇲🇸",
  },
  MT: {
    name: "Malta",
    native: "Malta",
    phone: "356",
    currency: "EUR",
    emoji: "🇲🇹",
  },
  MU: {
    name: "Mauritius",
    native: "Maurice",
    phone: "230",
    currency: "MUR",
    emoji: "🇲🇺",
  },
  MV: {
    name: "Maldives",
    native: "Maldives",
    phone: "960",
    currency: "MVR",
    emoji: "🇲🇻",
  },
  MW: {
    name: "Malawi",
    native: "Malawi",
    phone: "265",
    currency: "MWK",
    emoji: "🇲🇼",
  },
  MX: {
    name: "Mexico",
    native: "México",
    phone: "52",
    currency: "MXN",
    emoji: "🇲🇽",
  },
  MY: {
    name: "Malaysia",
    native: "Malaysia",
    phone: "60",
    currency: "MYR",
    emoji: "🇲🇾",
  },
  MZ: {
    name: "Mozambique",
    native: "Moçambique",
    phone: "258",
    currency: "MZN",
    emoji: "🇲🇿",
  },
  NA: {
    name: "Namibia",
    native: "Namibia",
    phone: "264",
    currency: "NAD,ZAR",
    emoji: "🇳🇦",
  },
  NC: {
    name: "New Caledonia",
    native: "Nouvelle-Calédonie",
    phone: "687",
    currency: "XPF",
    emoji: "🇳🇨",
  },
  NE: {
    name: "Niger",
    native: "Niger",
    phone: "227",
    currency: "XOF",
    emoji: "🇳🇪",
  },
  NF: {
    name: "Norfolk Island",
    native: "Norfolk Island",
    phone: "672",
    currency: "AUD",
    emoji: "🇳🇫",
  },
  NG: {
    name: "Nigeria",
    native: "Nigeria",
    phone: "234",
    currency: "NGN",
    emoji: "🇳🇬",
  },
  NI: {
    name: "Nicaragua",
    native: "Nicaragua",
    phone: "505",
    currency: "NIO",
    emoji: "🇳🇮",
  },
  NL: {
    name: "Netherlands",
    native: "Nederland",
    phone: "31",
    currency: "EUR",
    emoji: "🇳🇱",
  },
  NO: {
    name: "Norway",
    native: "Norge",
    phone: "47",
    currency: "NOK",
    emoji: "🇳🇴",
  },
  NP: {
    name: "Nepal",
    native: "नपल",
    phone: "977",
    currency: "NPR",
    emoji: "🇳🇵",
  },
  NR: {
    name: "Nauru",
    native: "Nauru",
    phone: "674",
    currency: "AUD",
    emoji: "🇳🇷",
  },
  NU: {
    name: "Niue",
    native: "Niuē",
    phone: "683",
    currency: "NZD",
    emoji: "🇳🇺",
  },
  NZ: {
    name: "New Zealand",
    native: "New Zealand",
    phone: "64",
    currency: "NZD",
    emoji: "🇳🇿",
  },
  OM: {
    name: "Oman",
    native: "عمان",
    phone: "968",
    currency: "OMR",
    emoji: "🇴🇲",
  },
  PA: {
    name: "Panama",
    native: "Panamá",
    phone: "507",
    currency: "PAB,USD",
    emoji: "🇵🇦",
  },
  PE: {
    name: "Peru",
    native: "Perú",
    phone: "51",
    currency: "PEN",
    emoji: "🇵🇪",
  },
  PF: {
    name: "French Polynesia",
    native: "Polynésie française",
    phone: "689",
    currency: "XPF",
    emoji: "🇵🇫",
  },
  PG: {
    name: "Papua New Guinea",
    native: "Papua Niugini",
    phone: "675",
    currency: "PGK",
    emoji: "🇵🇬",
  },
  PH: {
    name: "Philippines",
    native: "Pilipinas",
    phone: "63",
    currency: "PHP",
    emoji: "🇵🇭",
  },
  PK: {
    name: "Pakistan",
    native: "Pakistan",
    phone: "92",
    currency: "PKR",
    emoji: "🇵🇰",
  },
  PL: {
    name: "Poland",
    native: "Polska",
    phone: "48",
    currency: "PLN",
    emoji: "🇵🇱",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    native: "Saint-Pierre-et-Miquelon",
    phone: "508",
    currency: "EUR",
    emoji: "🇵🇲",
  },
  PN: {
    name: "Pitcairn Islands",
    native: "Pitcairn Islands",
    phone: "64",
    currency: "NZD",
    emoji: "🇵🇳",
  },
  PR: {
    name: "Puerto Rico",
    native: "Puerto Rico",
    phone: "1787,1939",
    currency: "USD",
    emoji: "🇵🇷",
  },
  PS: {
    name: "Palestine",
    native: "فلسطين",
    phone: "970",
    currency: "ILS",
    emoji: "🇵🇸",
  },
  PT: {
    name: "Portugal",
    native: "Portugal",
    phone: "351",
    currency: "EUR",
    emoji: "🇵🇹",
  },
  PW: {
    name: "Palau",
    native: "Palau",
    phone: "680",
    currency: "USD",
    emoji: "🇵🇼",
  },
  PY: {
    name: "Paraguay",
    native: "Paraguay",
    phone: "595",
    currency: "PYG",
    emoji: "🇵🇾",
  },
  QA: {
    name: "Qatar",
    native: "قطر",
    phone: "974",
    currency: "QAR",
    emoji: "🇶🇦",
  },
  RE: {
    name: "Réunion",
    native: "La Réunion",
    phone: "262",
    currency: "EUR",
    emoji: "🇷🇪",
  },
  RO: {
    name: "Romania",
    native: "România",
    phone: "40",
    currency: "RON",
    emoji: "🇷🇴",
  },
  RS: {
    name: "Serbia",
    native: "Србија",
    phone: "381",
    currency: "RSD",
    emoji: "🇷🇸",
  },
  RU: {
    name: "Russia",
    native: "Россия",
    phone: "7",
    currency: "RUB",
    emoji: "🇷🇺",
  },
  RW: {
    name: "Rwanda",
    native: "Rwanda",
    phone: "250",
    currency: "RWF",
    emoji: "🇷🇼",
  },
  SA: {
    name: "Saudi Arabia",
    native: "العربية السعودية",
    phone: "966",
    currency: "SAR",
    emoji: "🇸🇦",
  },
  SB: {
    name: "Solomon Islands",
    native: "Solomon Islands",
    phone: "677",
    currency: "SBD",
    emoji: "🇸🇧",
  },
  SC: {
    name: "Seychelles",
    native: "Seychelles",
    phone: "248",
    currency: "SCR",
    emoji: "🇸🇨",
  },
  SD: {
    name: "Sudan",
    native: "السودان",
    phone: "249",
    currency: "SDG",
    emoji: "🇸🇩",
  },
  SE: {
    name: "Sweden",
    native: "Sverige",
    phone: "46",
    currency: "SEK",
    emoji: "🇸🇪",
  },
  SG: {
    name: "Singapore",
    native: "Singapore",
    phone: "65",
    currency: "SGD",
    emoji: "🇸🇬",
  },
  SH: {
    name: "Saint Helena",
    native: "Saint Helena",
    phone: "290",
    currency: "SHP",
    emoji: "🇸🇭",
  },
  SI: {
    name: "Slovenia",
    native: "Slovenija",
    phone: "386",
    currency: "EUR",
    emoji: "🇸🇮",
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    native: "Svalbard og Jan Mayen",
    phone: "4779",
    currency: "NOK",
    emoji: "🇸🇯",
  },
  SK: {
    name: "Slovakia",
    native: "Slovensko",
    phone: "421",
    currency: "EUR",
    emoji: "🇸🇰",
  },
  SL: {
    name: "Sierra Leone",
    native: "Sierra Leone",
    phone: "232",
    currency: "SLL",
    emoji: "🇸🇱",
  },
  SM: {
    name: "San Marino",
    native: "San Marino",
    phone: "378",
    currency: "EUR",
    emoji: "🇸🇲",
  },
  SN: {
    name: "Senegal",
    native: "Sénégal",
    phone: "221",
    currency: "XOF",
    emoji: "🇸🇳",
  },
  SO: {
    name: "Somalia",
    native: "Soomaaliya",
    phone: "252",
    currency: "SOS",
    emoji: "🇸🇴",
  },
  SR: {
    name: "Suriname",
    native: "Suriname",
    phone: "597",
    currency: "SRD",
    emoji: "🇸🇷",
  },
  SS: {
    name: "South Sudan",
    native: "South Sudan",
    phone: "211",
    currency: "SSP",
    emoji: "🇸🇸",
  },
  ST: {
    name: "São Tomé and Príncipe",
    native: "São Tomé e Príncipe",
    phone: "239",
    currency: "STN",
    emoji: "🇸🇹",
  },
  SV: {
    name: "El Salvador",
    native: "El Salvador",
    phone: "503",
    currency: "SVC,USD",
    emoji: "🇸🇻",
  },
  SX: {
    name: "Sint Maarten",
    native: "Sint Maarten",
    phone: "1721",
    currency: "ANG",
    emoji: "🇸🇽",
  },
  SY: {
    name: "Syria",
    native: "سوريا",
    phone: "963",
    currency: "SYP",
    emoji: "🇸🇾",
  },
  SZ: {
    name: "Swaziland",
    native: "Swaziland",
    phone: "268",
    currency: "SZL",
    emoji: "🇸🇿",
  },
  TC: {
    name: "Turks and Caicos Islands",
    native: "Turks and Caicos Islands",
    phone: "1649",
    currency: "USD",
    emoji: "🇹🇨",
  },
  TD: {
    name: "Chad",
    native: "Tchad",
    phone: "235",
    currency: "XAF",
    emoji: "🇹🇩",
  },
  TF: {
    name: "French Southern Territories",
    native: "Territoire des Terres australes et antarctiques fr",
    phone: "262",
    currency: "EUR",
    emoji: "🇹🇫",
  },
  TG: {
    name: "Togo",
    native: "Togo",
    phone: "228",
    currency: "XOF",
    emoji: "🇹🇬",
  },
  TH: {
    name: "Thailand",
    native: "ประเทศไทย",
    phone: "66",
    currency: "THB",
    emoji: "🇹🇭",
  },
  TJ: {
    name: "Tajikistan",
    native: "Тоҷикистон",
    phone: "992",
    currency: "TJS",
    emoji: "🇹🇯",
  },
  TK: {
    name: "Tokelau",
    native: "Tokelau",
    phone: "690",
    currency: "NZD",
    emoji: "🇹🇰",
  },
  TL: {
    name: "East Timor",
    native: "Timor-Leste",
    phone: "670",
    currency: "USD",
    emoji: "🇹🇱",
  },
  TM: {
    name: "Turkmenistan",
    native: "Türkmenistan",
    phone: "993",
    currency: "TMT",
    emoji: "🇹🇲",
  },
  TN: {
    name: "Tunisia",
    native: "تونس",
    phone: "216",
    currency: "TND",
    emoji: "🇹🇳",
  },
  TO: {
    name: "Tonga",
    native: "Tonga",
    phone: "676",
    currency: "TOP",
    emoji: "🇹🇴",
  },
  TR: {
    name: "Turkey",
    native: "Türkiye",
    phone: "90",
    currency: "TRY",
    emoji: "🇹🇷",
  },
  TT: {
    name: "Trinidad and Tobago",
    native: "Trinidad and Tobago",
    phone: "1868",
    currency: "TTD",
    emoji: "🇹🇹",
  },
  TV: {
    name: "Tuvalu",
    native: "Tuvalu",
    phone: "688",
    currency: "AUD",
    emoji: "🇹🇻",
  },
  TW: {
    name: "Taiwan",
    native: "臺灣",
    phone: "886",
    currency: "TWD",
    emoji: "🇹🇼",
  },
  TZ: {
    name: "Tanzania",
    native: "Tanzania",
    phone: "255",
    currency: "TZS",
    emoji: "🇹🇿",
  },
  UA: {
    name: "Ukraine",
    native: "Україна",
    phone: "380",
    currency: "UAH",
    emoji: "🇺🇦",
  },
  UG: {
    name: "Uganda",
    native: "Uganda",
    phone: "256",
    currency: "UGX",
    emoji: "🇺🇬",
  },
  UM: {
    name: "U.S. Minor Outlying Islands",
    native: "United States Minor Outlying Islands",
    phone: "1",
    currency: "USD",
    emoji: "🇺🇲",
  },
  US: {
    name: "United States",
    native: "United States",
    phone: "1",
    currency: "USD,USN,USS",
    emoji: "🇺🇸",
  },
  UY: {
    name: "Uruguay",
    native: "Uruguay",
    phone: "598",
    currency: "UYI,UYU",
    emoji: "🇺🇾",
  },
  UZ: {
    name: "Uzbekistan",
    native: "O‘zbekiston",
    phone: "998",
    currency: "UZS",
    emoji: "🇺🇿",
  },
  VA: {
    name: "Vatican City",
    native: "Vaticano",
    phone: "379",
    currency: "EUR",
    emoji: "🇻🇦",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    native: "Saint Vincent and the Grenadines",
    phone: "1784",
    currency: "XCD",
    emoji: "🇻🇨",
  },
  VE: {
    name: "Venezuela",
    native: "Venezuela",
    phone: "58",
    currency: "VES",
    emoji: "🇻🇪",
  },
  VG: {
    name: "British Virgin Islands",
    native: "British Virgin Islands",
    phone: "1284",
    currency: "USD",
    emoji: "🇻🇬",
  },
  VI: {
    name: "U.S. Virgin Islands",
    native: "United States Virgin Islands",
    phone: "1340",
    currency: "USD",
    emoji: "🇻🇮",
  },
  VN: {
    name: "Vietnam",
    native: "Việt Nam",
    phone: "84",
    currency: "VND",
    emoji: "🇻🇳",
  },
  VU: {
    name: "Vanuatu",
    native: "Vanuatu",
    phone: "678",
    currency: "VUV",
    emoji: "🇻🇺",
  },
  WF: {
    name: "Wallis and Futuna",
    native: "Wallis et Futuna",
    phone: "681",
    currency: "XPF",
    emoji: "🇼🇫",
  },
  WS: {
    name: "Samoa",
    native: "Samoa",
    phone: "685",
    currency: "WST",
    emoji: "🇼🇸",
  },
  XK: {
    name: "Kosovo",
    native: "Republika e Kosovës",
    phone: "377,381,383,386",
    currency: "EUR",
    emoji: "🇽🇰",
  },
  YE: {
    name: "Yemen",
    native: "اليَمَن",
    phone: "967",
    currency: "YER",
    emoji: "🇾🇪",
  },
  YT: {
    name: "Mayotte",
    native: "Mayotte",
    phone: "262",
    currency: "EUR",
    emoji: "🇾🇹",
  },
  ZA: {
    name: "South Africa",
    native: "South Africa",
    phone: "27",
    currency: "ZAR",
    emoji: "🇿🇦",
  },
  ZM: {
    name: "Zambia",
    native: "Zambia",
    phone: "260",
    currency: "ZMW",
    emoji: "🇿🇲",
  },
  ZW: {
    name: "Zimbabwe",
    native: "Zimbabwe",
    phone: "263",
    currency: "USD,ZAR,BWP,GBP,AUD,CNY,INR,JPY",
    emoji: "🇿🇼",
  },
};

export const countryList: Country[] = valuesOf(countriesByAlpha2Code);
