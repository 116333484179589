import { View, Pressable, ActivityIndicator, StyleSheet } from "react-native";

import { Text } from "components/text_v2";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { useMediaQuery } from "lib/media_query";

import { ReviewStatus } from "./booking_request_table_raw";
import { HandleReview } from "../hooks/use_booking_request_review";

interface PropsType {
  onReview: HandleReview;
  username: string;
  loadingDeclineRequest: boolean;
  loadingApproveRequest: boolean;
}

export function RequestActions({
  onReview,
  username,
  loadingApproveRequest,
  loadingDeclineRequest,
}: PropsType) {
  const {
    deviceQuery: { desktop: isDesktop },
  } = useMediaQuery();

  return (
    <View style={styles.actionsWrapper}>
      <Pressable
        onPress={() =>
          onReview({
            status: ReviewStatus.Declining,
            declinedUsername: username,
          })
        }
        disabled={loadingDeclineRequest}
        testID="booking-request-decline-button"
      >
        <View style={styles.declineButtonWrapper}>
          {loadingDeclineRequest && (
            <ActivityIndicator
              size="small"
              color={colors.brand.grapefruitcore}
            />
          )}
          {(isDesktop || !loadingDeclineRequest) && (
            <Text color="grapefruit-core" weight="semibold" size="xs">
              Decline
            </Text>
          )}
        </View>
      </Pressable>
      <Button
        testID="booking-request-approve-button"
        text="Approve"
        iconName="check-ui"
        onPress={() => onReview({ status: ReviewStatus.Approving })}
        loading={loadingApproveRequest}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  actionsWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  declineButtonWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
});
