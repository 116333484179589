import "./tooltip.web.css";
import React from "react";
import { TooltipProps } from "./tooltip";

// TODO: measure content and set offset accordingly
export function Tooltip(props: TooltipProps) {
  const { position, content, children } = props;

  return (
    <div className="tooltip">
      <div className="tooltip-trigger">{children}</div>
      <div className={`tooltip-content tooltip-content-${position}`}>
        {content}
      </div>
    </div>
  );
}
