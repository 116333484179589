import {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from "@apollo/client";
import { useEffect, useState } from "react";

interface LazyQueryWithRetryProps<
  TData = any,
  TVariables = OperationVariables,
> {
  maxRetry: number;
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  queryOptions?: LazyQueryHookOptions<TData, TVariables>;
  disabled?: boolean;
  isBreak: (data: TData) => boolean;
  buildVariables: (queryCount: number) => TVariables;
}

/**
 * `useLazyQuery` with custom variables each time retrying.
 * `buildVariables` is suppose to output new `variables` upon each time retrying
 *
 * Use cases:
 * - Refetch the same query with custom variables after each time
 * - Refetch the same query multiple times (`buildVariables` output is a constant)
 *
 * Hook props:
 * - `maxRetry`: maximum number of requests can be made
 * - `query`: query passed to the `useLazyQuery`
 * - `queryOptions`: query options passed to the `useLazyQuery`
 * - `isBreak`: function called after each time to determine continue retrying or not
 * - `buildVariables`: output will be the variables used by `query`
 *
 * Return:
 * - `result`: result from the `useLazyQuery`
 * - `queryCount`: number of retry has been made
 */
const useLazyQueryWithRetry = <TData = any, TVariables = OperationVariables>(
  props: LazyQueryWithRetryProps<TData, TVariables>,
) => {
  const {
    maxRetry,
    query,
    queryOptions = {},
    disabled,
    isBreak,
    buildVariables,
  } = props;

  const [queryCount, setQueryCount] = useState(0);

  const variables = buildVariables(queryCount);

  const [loadFunc, result] = useLazyQuery(query, {
    ...queryOptions,
    variables,
    onCompleted: (data: any) => {
      if (isBreak(data)) {
        setQueryCount(maxRetry);

        if (queryOptions.onCompleted) {
          queryOptions.onCompleted(data);
        }
      } else {
        setQueryCount(queryCount + 1);
      }
    },
    onError: (error: any) => {
      /**
       * Set to max to let the consumer stop show loading indicator
       */
      setQueryCount(maxRetry);

      if (queryOptions && queryOptions.onError) {
        queryOptions.onError(error);
      }
    },
  });

  const isLoading = disabled ? false : queryCount < maxRetry;

  useEffect(() => {
    /**
     * Note:
     * Docs: https://www.apollographql.com/docs/react/api/react/hooks/#skip
     * `useLazyQuery` does **NOT** support skip
     * So we use this flag to avoid making unnecessary request
     */
    if (disabled) {
      return;
    }

    if (queryCount < maxRetry) {
      /**
       * For debug purpose, can use this code to get the queryName
       * // @ts-ignore
       * const queryName = query.definitions[0]?.name?.value;
       * logger.info(
       *  `[useLazyQueryWithRetry][${queryName}][useEffect] queryCount=${queryCount}, variables: ${JSON.stringify(
       *    variables,
       *  )}`,
       *);
       */
      loadFunc({
        variables,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCount, maxRetry]);

  const finalResult = {
    ...result,
    loading: isLoading,
  };

  return { result: finalResult, queryCount };
};

export { useLazyQueryWithRetry };
