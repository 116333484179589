import "./search_bar_option.css";
import { Icon } from "components/icon";
import { Icon as IconV2 } from "components/iconv2";
import { ReactNode, useCallback } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { colors } from "components/colors";
import { MapboxFeature } from "../mapbox";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";

interface CurrentLocationOptionProps {
  onPress: () => void;
  label: string;
  isSelected: boolean;
}

export function CurrentLocationOption(props: CurrentLocationOptionProps) {
  const { onPress, label, isSelected } = props;

  return (
    <Option
      testID="search-bar-current-location-option"
      onPress={onPress}
      textLine1="Current location"
      textLine2={label}
      isSelected={isSelected}
      icon={
        <View
          style={[
            styles.iconWrapper,
            { backgroundColor: colors.brand.eggplantMinus80 },
          ]}
        >
          <IconV2 name="location" filled />
        </View>
      }
    />
  );
}

interface SearchOptionProps {
  place: MapboxFeature;
  onPress: (place: MapboxFeature) => void;
  isSelected: boolean;
}

export function SearchOption(props: SearchOptionProps) {
  const { place, onPress, isSelected } = props;

  const handleOnPress = useCallback(() => {
    onPress(place);
  }, [place, onPress]);

  return (
    <Option
      onPress={handleOnPress}
      textLine1={place.text}
      textLine2={place.place_name}
      isSelected={isSelected}
      icon={
        <View
          style={[
            styles.iconWrapper,
            { backgroundColor: colors.brand.blackMinus90 },
          ]}
        >
          <Icon color="#83808E" name="map-pin" />
        </View>
      }
    />
  );
}

interface PropTypes {
  icon: ReactNode;
  textLine1: ReactNode;
  textLine2?: ReactNode;
  onPress: () => void;
  isSelected: boolean;
  testID?: string;
}

function Option({
  icon,
  textLine1,
  textLine2,
  onPress,
  isSelected,
  testID,
}: PropTypes) {
  return (
    <div className="search_bar__option">
      <Pressable
        style={[styles.searchOption, isSelected && styles.searchOptionSelected]}
        onPress={onPress}
        testID={testID}
      >
        {icon}
        <Spacer size={16} direction="row" />
        <View style={{ flexShrink: 1 }}>
          <View>
            <Text color="black-core" size="xs">
              {textLine1}
            </Text>
          </View>
          {!!textLine1 && !!textLine2 && <Spacer size={4} />}
          <View>
            <Text customColor={colors.brand.blackMinus30} size="xs">
              {textLine2}
            </Text>
          </View>
        </View>
      </Pressable>
    </div>
  );
}

const styles = StyleSheet.create({
  searchOption: {
    flexDirection: "row",
    padding: 16,
    alignItems: "center",
  },
  searchOptionSelected: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
  iconWrapper: {
    width: 24,
    height: 24,
    borderRadius: 9999,
    padding: 6,
    alignItems: "center",
    justifyContent: "center",
  },
});
