import React from "react";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { ReportingPieChart } from "./pie_chart";
import { BudgetColor } from "../../budgets/budget_colors";
import { colors } from "components/colors";

interface ReportingSpendProps {
  dayPasses: { value: number; percentage: number };
  dayOffices: { value: number; percentage: number };
  meetingRooms: { value: number; percentage: number };
  totalSpend: number;
  labelUpdatedAt: string;
  color?: BudgetColor;
}

export function ReportingSpend(props: ReportingSpendProps) {
  const {
    labelUpdatedAt,
    dayPasses,
    dayOffices,
    meetingRooms,
    totalSpend,
    color = "other",
  } = props;

  return (
    <ReportingPieChart
      data={[
        {
          label: "Day Passes",
          value: dayPasses.value,
          percentage: dayPasses.percentage,
          color: colors.budget[color].core,
        },
        {
          label: "Offices",
          value: dayOffices.value,
          percentage: dayOffices.percentage,
          color: colors.budget[color].lighter,
        },
        {
          label: "Rooms",
          value: meetingRooms.value,
          percentage: meetingRooms.percentage,
          color: colors.budget[color].lightest,
        },
      ]}
      empty={
        dayPasses.value === 0 &&
        dayOffices.value === 0 &&
        meetingRooms.value === 0
      }
      emptyColor={colors.budget[color].lightest}
      formatValue={(value) =>
        formatCurrencyWithoutDecimal(value, getSystemLocale(), "USD")
      }
      total={totalSpend}
      item="Spent"
      tooltip="This chart shows a breakdown of the amount spent by each space type."
      labelUpdatedAt={labelUpdatedAt}
      title="Spend"
    />
  );
}
