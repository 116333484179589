import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/button_v2";
import { Checkbox } from "components/checkbox";
import { colors } from "components/colors";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { UserDomainQuery } from "core/graphql.generated";
import { useForm } from "hooks/use_form";
import { logger } from "lib/logger";
import { useMediaQuery } from "lib/media_query";
import { getDomainFromEmail } from "lib/string_utils";
import { isCommonEmailProvider } from "pages/manage_organization/is_common_email_provider";
import { useAnalytics } from "providers/analytics";
import { postAuthRedirectUrl } from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import { Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

export interface TeamNameInput {
  teamName: string;
  hasDomain: boolean;
}

interface TeamNameProps {
  onGoNext: () => void;
  onGoStep: (index: number) => void;
}

export function StepTeamNameUI({ onGoNext, onGoStep }: TeamNameProps) {
  const mq = useMediaQuery();
  const history = useHistory();
  const previousUrl = postAuthRedirectUrl.get();
  const analytics = useAnalytics();
  const apolloClient = useApolloClient();
  const { accessToken } = useAuthV2();

  const { data } = useQuery<UserDomainQuery>(userDomain, {
    skip: !accessToken,
  });

  const email = getDomainFromEmail(data?.currentUser?.email);

  const [createOrganization, { error }] = useMutation(
    createOrganizationMutation,
  );

  const { values, setFieldValue, errors, submit, submitting, setSubmitting } =
    useForm({
      initialValues: {
        teamName: "",
        hasDomain: false,
      },
      validate: (values) => {
        const errors: { teamName?: string } = {};
        if (!values.teamName) {
          errors.teamName = "Please specify team name";
        }
        return errors;
      },
      onSubmit: async (values) => {
        analytics.event("Sign Up", {
          Step: "Submit organization",
          "Organization Name": values.teamName,
        });
        setSubmitting(true);
        try {
          await createOrganization({
            variables: {
              input: {
                organizationName: values.teamName,
                hasDomain: values.hasDomain,
              },
            },
          });
          await apolloClient.refetchQueries({ include: "all" });
        } catch (e) {
          logger.debug(`[StepTeamNameUI] refetchQueries error: ${e}`);
        } finally {
          setSubmitting(false);
          if (previousUrl) {
            history.replace(previousUrl);
            postAuthRedirectUrl.reset();
          } else {
            onGoNext();
          }
        }
      },
    });

  return (
    <Fragment>
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Content maxWidth={622}>
          <Heading size={mq.deviceQuery.mobile ? "lg" : "mega"} align="center">
            What's the name of your company or team?
          </Heading>
          <Spacer size={16} />
          <Text align="center">
            Your organization name will be displayed to all members of your team
            and usually is the name of your company.
          </Text>
          <Spacer size={40} />
          <View style={styles.content}>
            <View style={styles.viewWrapper}>
              <TextField
                value={values.teamName}
                onChange={(teamName) => setFieldValue("teamName", teamName)}
                placeholder="Ex. Acme Corporation"
                onSubmitEditing={submit}
                testID="sign-up-org-name"
                invalidText={errors.teamName}
                invalid={!!errors.teamName}
              />
            </View>

            {email && !isCommonEmailProvider(email) && (
              <>
                <Spacer size={16} />
                <View
                  style={
                    mq.deviceQuery.mobile
                      ? styles.checkboxWrapperMobile
                      : styles.checkboxWrapper
                  }
                >
                  <Checkbox
                    value={values.hasDomain}
                    onChange={() =>
                      setFieldValue("hasDomain", !values.hasDomain)
                    }
                    appearance="box"
                  />
                  <View style={styles.textViewWrapper}>
                    <Text color="black-70">
                      Let anyone with an{" "}
                      <Text weight="bold" color="black-70">
                        @{email}
                      </Text>{" "}
                      email join this team.
                    </Text>
                  </View>
                </View>
              </>
            )}

            {error && (
              <View>
                <Spacer size={16} />
                <Text customColor={colors.brand.grapefruitcore}>
                  {error.message}
                </Text>
              </View>
            )}
            <Spacer size={16} />
          </View>
        </Content>
      </View>
      <View style={styles.bottomContent}>
        <View
          style={[
            styles.buttonContainer,
            mq.deviceQuery.mobile && styles.buttonContainerMobile,
          ]}
        >
          <Button
            text={previousUrl ? "Done" : "Continue"}
            testID="sign-up-org-submit-button"
            onPress={submit}
            loading={submitting}
          />
        </View>
        <Spacer size={16} />
        <View style={styles.differentEmailText}>
          <Text size="xs" align="center" color="black-50">
            Were you expecting to join an existing team?{" "}
            <Text
              size="xs"
              color="eggplant-core"
              weight="semibold"
              onPress={() => {
                onGoStep(0);
              }}
            >
              Try a different email.
            </Text>
          </Text>
        </View>
        <Spacer size={16} />
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  differentEmailText: {
    paddingHorizontal: 16,
  },
  content: {
    justifyContent: "center",
    alignItems: "center",
  },
  checkboxWrapper: {
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  viewWrapper: {
    width: "100%",
  },
  checkboxWrapperMobile: {
    gap: 8,
    width: "100%",
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  textViewWrapper: {
    marginTop: 12,
    width: "100%",
  },
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: "95%",
    maxWidth: 220,
    alignSelf: "center",
  },
  buttonContainerMobile: {
    maxWidth: 343,
  },
  bottomContent: {
    width: "100%",
    maxWidth: 441,
    alignSelf: "center",
    alignItems: "center",
  },
});

const createOrganizationMutation = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      createdAt
      createdWith
    }
  }
`;

const userDomain = gql`
  query UserDomain {
    currentUser {
      email
    }
  }
`;
