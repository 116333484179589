import { Divider } from "components/divider";
import { Icon } from "components/icon";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";

import { Text } from "components/text_v2";
import { format } from "date-fns";
import { formatCurrency } from "lib/currency";
import { parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";
import {
  BudgetInfo,
  Order,
} from "pages/budgets/components/budget_transaction_table";
import { EditBudget } from "pages/budgets/components/edit_budget";
import { EditMemo } from "pages/budgets/components/edit_memo";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

type ElementProps = {
  label: string;
  values: string[];
};
const Element = ({ label, values }: ElementProps) => {
  return (
    <View style={styles.elementContainer}>
      <Text size={"xs"} weight={"semibold"}>
        {label}
      </Text>
      <View style={styles.elementContent}>
        {values.map((value, index) => (
          <Text
            key={index}
            size={"xs"}
            color={index === 0 ? "black-core" : "black-70"}
          >
            {value}
          </Text>
        ))}
      </View>
    </View>
  );
};

export type TransactionProps = {
  order: Order;
  orgInfo?: BudgetInfo;
  groupInfo?: BudgetInfo;
  refetch: () => void;
  onClose?: () => void;
};
export const TransactionRightPanel = ({
  order,
  orgInfo,
  groupInfo,
  refetch,
  onClose,
}: TransactionProps) => {
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;

  const {
    hostFullName,
    hostTitle,
    locationName,
    locationAddress,
    spaceName,
    bookingDate,
    orderCreatedAt,
    orderID,
    totalPrice,
    currency,
    groupBudget,
    bookingRequest,
  } = order;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {isMobile && (
          <Pressable onPress={onClose}>
            <Icon name={"chevron-left"} />
          </Pressable>
        )}
        <Text
          size={"md"}
          weight={"semibold"}
          align={isMobile ? "center" : "left"}
        >
          Transaction
        </Text>

        <Pressable onPress={onClose}>
          {!isMobile && <Icon name={"x-circle"} />}
        </Pressable>
      </View>
      {isMobile && <Divider />}
      <View style={styles.content}>
        <Element
          label={"Space booked"}
          values={[
            `${locationName} ${spaceName && `· ${spaceName}`}`,
            format(parseDay(bookingDate), "MMM d"),
            locationAddress || "",
          ]}
        />
        <Divider />
        <Element
          label={"User"}
          values={hostTitle ? [hostFullName, hostTitle] : [hostFullName]}
        />
        <Divider />
        <Element
          label={"Total"}
          values={[formatCurrency(totalPrice, getSystemLocale(), currency)]}
        />
        <Divider />
        <Element
          label={"Booked on"}
          values={[format(parseDay(orderCreatedAt), "MM-dd-yyyy")]}
        />
        <Divider />
        <EditBudget
          currentBudget={groupBudget || orgInfo || groupInfo}
          currentOrderId={orderID}
          refetch={refetch}
        />

        <Divider />
        <EditMemo
          memo={order?.order?.memo || ""}
          currentOrderId={orderID}
          refetch={refetch}
        />
        {!!bookingRequest?.id && (
          <>
            <Divider />
            <View style={styles.oopContainer}>
              <OutOfPolicyTag appearance={"yellow"} />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 24,
    gap: 24,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 24,
  },
  content: {
    paddingHorizontal: 24,
    gap: 16,
  },
  elementContainer: {
    display: "flex",
    gap: 12,
  },
  elementContent: {
    flex: 1,
    gap: 4,
  },
  memoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  oopContainer: {
    width: 138,
  },
});
