import { AppHeader } from "components/app_header_v3/app_header";
import { DesktopLayout } from "components/layout/desktop_layout";
import { Footer } from "core/footer";

interface LocationDetailsDesktopProps {
  children: JSX.Element;
}

export function LocationDetailsDesktop({
  children,
}: LocationDetailsDesktopProps) {
  return (
    <DesktopLayout header={<AppHeader />} body={children} footer={<Footer />} />
  );
}
