import { Spacer } from "components/spacer";
import ContentLoader, { Rect } from "react-content-loader/native";
import { View } from "react-native";

export function GroupBudgetDetailSkeletonForTabs() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={50}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="50px" />
      </ContentLoader>
      <Spacer size={24} />
      <ContentLoader
        speed={2}
        width="100%"
        height={500}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="500px" />
      </ContentLoader>
    </View>
  );
}
