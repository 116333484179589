import React, { ReactNode } from "react";
import { MobileLayout } from "components/layout/mobile_layout";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { useUserRoleQuery } from "hooks/use_user_role_query";

interface PageLayoutProps {
  header: ReactNode;
  body: ReactNode;
}
export function BudgetMobileLayout(props: PageLayoutProps) {
  const { header, body } = props;
  const { loading, isAdmin } = useUserRoleQuery();

  if (loading) {
    return null;
  }

  return (
    <MobileLayout
      header={header}
      body={body}
      footer={isAdmin ? <BottomBarAdminNavigation /> : <BottomBarNavigation />}
    />
  );
}
