import { AsyncRenderer } from "components/AsyncRenderer";
import { SavedPlacesSkeleton } from "./saved_places_skeleton";
import { useSavedLocationsQuery } from "../hooks/use_saved_places_query";
import { GridV2 } from "components/gridv2";
import { SavedLocationsCard } from "./saved_places_cards";
import { EmptySavedPlaces } from "./saved_places_empty_state";
import { favoriteLocations } from "hooks/use_reactive_favs";
import { useReactiveVar } from "@apollo/client";

export const SavedLocationsList = () => {
  const { data, loading, error } = useSavedLocationsQuery();
  const favorites = useReactiveVar(favoriteLocations);

  return (
    <AsyncRenderer
      error={error}
      loading={loading}
      data={data}
      loadingHandler={() => <SavedPlacesSkeleton />}
    >
      {(data) => {
        const locations = data?.currentUser?.favoriteLocations?.filter(
          (location) => favorites.includes(location.id),
        );

        return (
          <GridV2 columns={1} rowGap={24}>
            {locations && locations.length > 0 ? (
              locations.map((location) => (
                <SavedLocationsCard key={location.id} location={location} />
              ))
            ) : (
              <EmptySavedPlaces
                title="No locations saved yet"
                description="Tap the heart to save it for later. Saved locations will show up here so you can easily find them again."
              />
            )}
          </GridV2>
        );
      }}
    </AsyncRenderer>
  );
};
