import { FieldContainer } from "components/field_container";
import { Pressable, StyleSheet, View } from "react-native";
import { Icon } from "components/icon";
import { Text } from "components/text_v2";
import { colors } from "components/colors";

interface SelectInputButtonProps {
  label?: string;
  title?: string;
  testID?: string;
  errorText?: string;
  topDescription?: string;
  onPress?: () => void;
  disabled?: boolean;
  invalid?: boolean;
  isOpen?: boolean;
  appearance?: "default" | "outlined";
}

export function SelectInputButton(props: SelectInputButtonProps) {
  const {
    testID,
    title,
    topDescription,
    errorText,
    disabled,
    label,
    invalid,
    onPress,
    isOpen,
    appearance,
  } = props;

  const handlePress = () => {
    if (!disabled) {
      return onPress?.();
    }
  };

  return (
    <Pressable onPress={handlePress}>
      <View testID={testID}>
        <FieldContainer
          label={label}
          invalid={invalid}
          errorText={errorText}
          topDescription={topDescription}
          height={40}
        >
          <View
            style={[
              styles.buttonRoot,
              appearance && styles[`buttonRoot--${appearance}`],
              isOpen && styles.buttonRootOpened,
            ]}
          >
            <Text
              numberOfLines={1}
              size="sm"
              color={
                invalid ? "grapefruit-core" : disabled ? "black-50" : "black-70"
              }
            >
              {title}
            </Text>
            <View
              style={[
                styles.arrowIconWrapper,
                isOpen && styles.arrowIconWrapperOpened,
              ]}
            >
              <Icon
                name={isOpen ? "feather-chevron-up" : "feather-chevron-down"}
              />
            </View>
          </View>
        </FieldContainer>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  buttonRoot: {
    position: "relative",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: 8,
    paddingRight: "36px",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.brand.whitecore,
  },
  buttonRootOpened: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
  "buttonRoot--default": {},
  "buttonRoot--outlined": {
    backgroundColor: "white",
    borderColor: colors.brand.blackMinus90,
    borderWidth: 1,
  },
  arrowIconWrapper: {
    position: "absolute",
    right: "8px",
    top: "50%",
    // @ts-ignore
    transform: "translateY(-50%)",
  },
  arrowIconWrapperOpened: {},
});
