import {
  gql as graphql,
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useStripe } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { AppHeader } from "components/app_header_v3/app_header";

import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Content } from "components/content";
import { Dialog } from "components/dialog";
import { Divider } from "components/divider";
import { Heading } from "components/heading";
import { Icon } from "components/icon";
import { PageContainer } from "components/page_container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import {
  PaymentMethodDetailsView,
  useSavePaymentMethod,
} from "core/checkout_payment_details";
import { CreditCardInput } from "core/credit_card_input";
import { DialogContent, DialogFooter } from "core/dialog_content";
import { Footer } from "core/footer";
import {
  CardPaymentMethod,
  DetachPaymentMethodMutation,
  DetachPaymentMethodMutationVariables,
  PaymentMethodQuery,
  PaymentMethodQueryVariables,
} from "core/graphql.generated";
import { withStripe } from "core/with_stripe";
import { FormErrors, useForm } from "hooks/use_form";
import { useGoBack } from "hooks/use_go_back";
import { useMediaQuery } from "lib/media_query";
import React, { Fragment, useCallback, useState } from "react";
import { ActivityIndicator, Pressable, ScrollView, View } from "react-native";
import { ROUTES } from "../core/routes";

export function UserProfilePaymentsPage() {
  const goBack = useGoBack(ROUTES.USER_PROFILE.path);
  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Spacer size={32} />
        <Content>
          <Row alignItems="center">
            <Pressable onPress={goBack}>
              <Icon size="lg" name="arrow-left-circle" />
            </Pressable>
            <Spacer size={10} />
            <Heading size="h1">Payments</Heading>
          </Row>
          <Spacer size={16} />
          <UserProfilePayments />
          <Spacer size={16} />
          <Divider color="darker" />
          <Spacer size={16} />
          <Text color="muted">
            Payment information is encrypted and securely managed with Stripe.
          </Text>
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <Footer />
    </Container>
  );
}

const UserProfilePayments = withStripe(function () {
  const stripe = useStripe();
  const [edit, setEdit] = useState(false);
  const apolloClient = useApolloClient();

  const refetchPaymentMethods = () => {
    apolloClient.refetchQueries({
      include: "active",
    });
  };

  const { data: userPaymentData, loading: userPaymentLoading } = useQuery<
    PaymentMethodQuery,
    PaymentMethodQueryVariables
  >(getUserPaymentMethodGQLQuery);

  const [detachPaymentMethod, { loading: detachPaymentLoading }] = useMutation<
    DetachPaymentMethodMutation,
    DetachPaymentMethodMutationVariables
  >(detachPaymentMethodMutation);

  if (detachPaymentLoading || userPaymentLoading) {
    return (
      <Container expanded center>
        <ActivityIndicator size="large" color="rgba(82,68,134,1)" />
      </Container>
    );
  }

  const paymentMethod = userPaymentData?.paymentMethod;
  const paymentMethodID = paymentMethod?.id;

  async function onClear(paymentMethodID: string) {
    const result = await detachPaymentMethod({
      variables: {
        input: {
          paymentMethodID,
        },
      },
    });
    if (result?.data?.detachPaymentMethod.status === "succeeded") {
      refetchPaymentMethods();
    }
  }

  return (
    <Container>
      {paymentMethod && (
        <Fragment>
          <PaymentMethodDetailsView paymentMethod={paymentMethod} />
          <Spacer size={16} />
          <Row spacing={12}>
            <Pressable
              testID="change-payment-method"
              onPress={() => setEdit(true)}
            >
              <Text size="sm" decoration="underline">
                Change
              </Text>
            </Pressable>
            {paymentMethodID && (
              <Pressable
                testID="clear-payment-method"
                onPress={() => onClear(paymentMethodID)}
              >
                <Text size="sm" decoration="underline">
                  Clear
                </Text>
              </Pressable>
            )}
          </Row>
        </Fragment>
      )}
      {!paymentMethod && (
        <View>
          <Text>You currently do not have any credit card on file.</Text>
          <Pressable
            testID="change-payment-method"
            onPress={() => setEdit(true)}
          >
            <Text decoration="underline">Add payment method</Text>
          </Pressable>
        </View>
      )}
      <Dialog onRequestClose={() => setEdit(false)} visible={edit}>
        {edit && (
          <PaymentMethodDetailsEditDialogContent
            stripe={stripe}
            onClose={() => setEdit(false)}
            paymentMethod={paymentMethod}
            refetchPaymentMethods={refetchPaymentMethods}
          />
        )}
      </Dialog>
    </Container>
  );
});

interface PaymentMethodDetailsEditDialogContentProps {
  stripe: Stripe | null;
  onClose: () => void;
  paymentMethod?: CardPaymentMethod | null;
  refetchPaymentMethods: () => void;
}

function PaymentMethodDetailsEditDialogContent(
  props: PaymentMethodDetailsEditDialogContentProps,
) {
  const { onClose, stripe, paymentMethod, refetchPaymentMethods } = props;
  const savePaymentMethod = useSavePaymentMethod();
  const mq = useMediaQuery();
  const { submit, submitting, setFieldValue, setFieldError, errors, values } =
    useForm({
      initialValues: {
        paymentComplete: false,
      },
      validate: (_values) => {
        const _errors: FormErrors<{ paymentComplete: boolean }> = {};
        if (!_values.paymentComplete) {
          _errors.paymentComplete = "Please complete payment information";
        }

        return _errors;
      },
      onSubmit: async (_, { setStatus }) => {
        if (!stripe) {
          setStatus("Credit card processor not loaded");
          return;
        }

        try {
          await savePaymentMethod(stripe, paymentMethod?.id);
          refetchPaymentMethods();
          onClose();
        } catch (error) {
          setStatus(
            "Could not update payment details. Please try again later.",
          );
          console.error(error.message);
        }
      },
    });

  const handleCreditCardChange = useCallback(
    (complete: boolean, error?: string) => {
      if (complete) {
        setFieldValue("paymentComplete", true);
      } else {
        setFieldValue("paymentComplete", false);
      }

      if (error) {
        setFieldError("paymentComplete", error);
      } else {
        setFieldError("paymentComplete", undefined);
      }
    },
    [setFieldError, setFieldValue],
  );

  const disabled = !values.paymentComplete || !!errors.paymentComplete;
  return (
    <DialogContent
      headerLeftIcon={mq.sizeQuery.smAndDown ? "x-circle" : undefined}
      headerTitle={mq.sizeQuery.mdAndUp ? "Payments" : undefined}
      onHeaderLeftIconPress={() => onClose()}
    >
      <ScrollView>
        <Container flex={1} padding={16}>
          <CreditCardInput
            invalid={!!errors.paymentComplete}
            invalidText={errors.paymentComplete}
            onChange={handleCreditCardChange}
          />
          <Spacer size={16} />
          {mq.sizeQuery.smAndDown && (
            <Button
              disabled={disabled}
              loading={submitting}
              onPress={submit}
              testID="save-payment-method"
              text="Save"
            />
          )}
        </Container>
        {mq.sizeQuery.mdAndUp && (
          <DialogFooter
            disabled={disabled}
            loading={submitting}
            onCancel={onClose}
            onSave={submit}
            testID="save-payment-method"
          />
        )}
      </ScrollView>
    </DialogContent>
  );
}

const detachPaymentMethodMutation = graphql`
  mutation DetachPaymentMethod($input: DetachPaymentMethodInput!) {
    detachPaymentMethod(input: $input) {
      status
    }
  }
`;

const getUserPaymentMethodGQLQuery = graphql`
  query PaymentMethod {
    paymentMethod {
      ... on CardPaymentMethod {
        id
        last4
        network
        networkLogoUrl
      }
    }
  }
`;
