import { AsyncRenderer } from "components/AsyncRenderer";
import { PreferredPartnerBanner } from "components/preferred_partner_banner";
import { Text } from "components/text_v2";
import { BookingDetailsQuery } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { BookingDetailInviteBanner } from "pages/booking_details/components/booking_detail_invite_banner";
import { Amenities } from "pages/office_space_details/components/amenities";
import { useAnalytics } from "providers/analytics";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import { useEffect } from "react";
import { BookingDetailDateRangeBar } from "./components/booking_detail_date_range_bar";
import { BookingDetailMobileActions } from "./components/booking_detail_mobile_actions";
import { BookingDetailPriceBreakdown } from "./components/booking_detail_price_breadown";
import { SpaceDetailAbout } from "./components/space_detail_about";
import { SpaceDetailContentHeader } from "./components/space_detail_content_header";
import { SpaceDetailDirection } from "./components/space_detail_direction";
import { SpaceDetailHours } from "./components/space_detail_hours";
import { SpaceDetailImageGallery } from "./components/space_detail_image_gallery";
import { SpaceDetailLocation } from "./components/space_detail_location";
import { SpaceDetailMap } from "./components/space_detail_map";
import { SpaceDetailSkeleton } from "./components/space_detail_skeleton";
import { useBookingDetailsQuery } from "./hooks/useBookingDetailsQuery";
import { BookingDetailsContentDesktop } from "./layout/booking_details_content_desktop";
import { BookingDetailsContentMobile } from "./layout/booking_details_content_mobile";

import {
  BookingDetailsLayout,
  BookingDetailsPageComponents,
} from "./layout/booking_details_layout";
import { getBookingDetailDateTime } from "./utils/getBookingDetailDateTime";
import { isArchivedBooking } from "./utils/isArchivedBooking";

export function BookingDetails() {
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  useEffect(() => {
    analytics.event("View Booking Details", { category: "Post booking" });
  }, [analytics]);

  const { data, loading, error } = useBookingDetailsQuery();

  const renderBookingDetails = (data: BookingDetailsQuery) => {
    if (!data.order) {
      return <Text>No data available</Text>;
    }

    const orderItem = data.order.orderItems[0];
    const space = orderItem.details.space;
    const { startDate, endDate, startTime, endTime } =
      getBookingDetailDateTime(orderItem);

    const components: BookingDetailsPageComponents = {
      header: (
        <SpaceDetailContentHeader
          spaceName={space.name}
          spaceType={space.spaceType}
          locationName={space.location.name}
        />
      ),
      dateRangeBar: (
        <BookingDetailDateRangeBar
          status={data.order.status}
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          endTime={endTime}
          confirmationId={data.order.confirmationID || undefined}
          orderId={data.order.id}
          memo={data.order.memo}
        />
      ),
      images: (
        <SpaceDetailImageGallery
          spaceName={space.name}
          spaceType={space.spaceType}
          partnerId={space.partnerID}
          images={space.images.concat(space.location.images)}
        />
      ),
      inviteOrShare: !isArchivedBooking(startDate, data.order.status) ? (
        <BookingDetailInviteBanner
          remainingInvites={space.maxCapacity - data.order.invitees.length}
          invitees={data.order.invitees}
          bookingId={data.order.id}
          spaceId={space.id}
          spaceName={space.name}
          spaceType={space.spaceType}
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          endTime={endTime}
        />
      ) : undefined,
      aboutSpace: space.description ? (
        <SpaceDetailAbout description={space.description} />
      ) : undefined,
      preferred:
        isPreferredFlag && space.preferred ? (
          <PreferredPartnerBanner
            locationName={`${space.name} is a Preferred Partner`}
          />
        ) : undefined,
      amenties:
        space.amenities.length > 0 || space.location.amenities.length > 0 ? (
          <Amenities
            spaceAmenities={space.amenities}
            locationAmenities={space.location.amenities}
          />
        ) : undefined,
      priceBreakdown: (
        <BookingDetailPriceBreakdown
          bookingId={data.order.id}
          spaceId={space.id}
          currency={data.order.currency}
          quote={{
            totalPrice: data.order.totalPrice,
            subTotal: data.order.subTotal,
            promotionLine: data.order.promotionLine,
            taxLine: data.order.taxLine,
            processingFee: data.order.processingFee,
            orderItems: data.order.orderItems,
          }}
          monthlyBilling={!!data.currentUser?.organization?.monthlyBilling}
          paymentDetail={data.order.paymentDetail}
          cancellable={data.order.cancellable}
          startDate={startDate}
          status={data.order.status}
          spaceName={space.name}
        />
      ),
      location: (
        <SpaceDetailLocation
          name={space.location.name}
          address={space.location.address.fullAddress}
        />
      ),
      directions: space.location.directions ? (
        <SpaceDetailDirection directions={space.location.directions} />
      ) : undefined,
      map: (
        <SpaceDetailMap
          latitude={space.location.address.latitude || undefined}
          longitude={space.location.address.longitude || undefined}
        />
      ),

      hours: <SpaceDetailHours hours={space.bookingHours} />,
      action:
        data.order.cancellable ||
        isArchivedBooking(startDate, data.order.status) ? (
          <BookingDetailMobileActions
            bookingId={data.order.id}
            spaceId={space.id}
            cancellable={data.order.cancellable}
            startDate={startDate}
            status={data.order.status}
            spaceName={space.name}
          />
        ) : undefined,
    };

    return mq.deviceQuery.mobile ? (
      <BookingDetailsContentMobile components={components} />
    ) : (
      <BookingDetailsContentDesktop components={components} />
    );
  };

  return (
    <BookingDetailsLayout>
      <AsyncRenderer
        error={error}
        loading={loading}
        data={data}
        loadingHandler={() => <SpaceDetailSkeleton />}
      >
        {renderBookingDetails}
      </AsyncRenderer>
    </BookingDetailsLayout>
  );
}
