import { StyleSheet, View } from "react-native";
import { tokens } from "components/tokens";
import { ReactNode } from "react";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { Divider } from "components/divider";

export const CreateEditBudgetCard = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <View style={styles.card}>
      <Text color="black-core" size="md" weight="semibold">
        {title}
      </Text>
      <Spacer size={24} />
      {children}
    </View>
  );
};

export const CreateEditBudgetCardDescriptionList = ({
  label,
  text,
}: {
  label: ReactNode;
  text: ReactNode;
}) => {
  return (
    <View>
      <Text color="black-core" size="xs" weight="semibold">
        {label}
      </Text>
      <Spacer size={8} />
      <Text color="black-70" size="xs" weight="regular">
        {text}
      </Text>
    </View>
  );
};

export const CreateEditBudgetCardDivider = () => {
  return (
    <View>
      <Spacer size={24} />
      <Divider color="lighter" />
      <Spacer size={24} />
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    ...tokens.shadow.elevation1,
    padding: 24,
    backgroundColor: "#ffffff",
    borderRadius: 4,
    marginBottom: 24,
  },
});
