import React, { createContext, useContext, useState } from "react";

interface FilterOpenStateProviderProps {
  children: React.ReactNode;
}

export interface FilterOpenStateContext {
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterOpen: boolean;
}

export const FilterOpenStateContext = createContext<FilterOpenStateContext>({
  setIsFilterOpen: () => {},
  isFilterOpen: false,
});

export function FilterOpenStateProvider(props: FilterOpenStateProviderProps) {
  const { children } = props;
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <FilterOpenStateContext.Provider
      value={{
        isFilterOpen,
        setIsFilterOpen,
      }}
    >
      {children}
    </FilterOpenStateContext.Provider>
  );
}

export function useFilterOpenState() {
  return useContext(FilterOpenStateContext);
}
