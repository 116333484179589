import { Fragment } from "react";
import { ScrollView } from "react-native";
import { Column } from "components/column";
import { Container } from "components/container";
import { Dialog } from "components/dialog";
import { Heading } from "components/heading";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { DialogHeader } from "core/dialog_content";
import { OffsiteSpacePage__SpaceDetailsV2Fragment } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";

interface SpaceRoomLayoutsLearnMoreDialogProps {
  open: boolean;
  onRequestClose: Function;
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
}

export function SpaceRoomLayoutsLearnMoreDialog({
  open,
  onRequestClose,
  space,
}: SpaceRoomLayoutsLearnMoreDialogProps) {
  const mq = useMediaQuery();
  const { layouts } = space;

  return (
    <Dialog visible={open} onRequestClose={() => onRequestClose()}>
      <Container
        color="content"
        borderTopLeftRadius={tokens.radius}
        borderTopRightRadius={tokens.radius}
        width={mq.sizeQuery.mdAndUp ? 750 : "100%"}
        flex={1}
      >
        <DialogHeader
          headerTitle="Room layouts"
          headerLeftIcon="x-circle"
          onHeaderLeftIconPress={() => onRequestClose()}
        />
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
          <Container
            color="content"
            flex={1}
            paddingHorizontal={16}
            paddingVertical={16}
            borderBottomLeftRadius={mq.sizeQuery.mdAndUp ? tokens.radius : 0}
            borderBottomRightRadius={mq.sizeQuery.mdAndUp ? tokens.radius : 0}
          >
            <Container>
              {layouts.map((layout) => (
                <Fragment key={layout.name}>
                  <Row>
                    <div
                      style={{ borderColor: tokens.colors.neutral.darker }}
                      className="space-details-layout"
                    >
                      <img
                        alt={layout.name}
                        className="space-details-layout-img"
                        src={layout.imageURL}
                      />
                    </div>
                    <Spacer size={16} />
                    <Column flex={1}>
                      <Heading size="h4">{layout.name}</Heading>
                      <Spacer size={1} />
                      <Text>{layout.description}</Text>
                    </Column>
                  </Row>
                  <Spacer size={16} />
                </Fragment>
              ))}
            </Container>
          </Container>
        </ScrollView>
      </Container>
    </Dialog>
  );
}
