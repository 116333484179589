export const placeholderLocationImage =
  "https://flexspace-ds-static.s3.us-west-1.amazonaws.com/location_coworking_space_default_placeholder_text_compressed.jpg";

export function getPlaceholderLocationImage() {
  return {
    large: {
      url: placeholderLocationImage,
      width: 1600,
      height: 1130,
    },
    medium: {
      url: placeholderLocationImage,
      width: 1600,
      height: 1130,
    },
    small: {
      url: placeholderLocationImage,
      width: 1600,
      height: 1130,
    },
  };
}
