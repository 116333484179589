import React, { Fragment, useCallback } from "react";
import { Pressable, View, StyleSheet } from "react-native";
import { Carousel } from "components/carousel";
import { Column } from "components/column";
import { Container } from "components/container";
import { ImageGalleryModal } from "components/image_gallery_modal";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import {
  OffsiteSpacePage__SpaceDetailsV2Fragment,
  SpaceType,
} from "core/graphql.generated";
import { Icon } from "components/iconv2";

interface SpaceImageGalleryProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
}

export const iWGSpacePartnerId = "69c4ef94-c6c1-4888-811b-88c76589c4b0";
export const iWGRegusPartnerId = "3bbd4142-3a0a-410c-8042-a015dab175a2";

const IndicativeLabel = () => (
  <View style={styles.labelContainer}>
    <Icon name={"camera"} />
    <Spacer size={4} direction={"row"} />
    <Text color={"white-core"} weight={"semibold"} size={"xs"}>
      Photo is a representation of the space
    </Text>
  </View>
);

export function SpaceImageGallery(props: SpaceImageGalleryProps) {
  const { space } = props;

  return (
    <div>
      <div style={{ position: "relative", zIndex: 1 }} className="hidden-md">
        <SpaceImageGalleryMobile space={space} />
      </div>
      <div style={{ position: "relative", zIndex: 1 }} className="visible-md">
        <SpaceImageGalleryDesktop space={space} />
      </div>
    </div>
  );
}

function SpaceImageGalleryMobile(props: SpaceImageGalleryProps) {
  const { space } = props;
  const { name, images: spaceImages, location, partnerID } = space;
  const { images: locationImages } = location;
  const images = spaceImages.concat(locationImages);
  const [isOpen, setIsOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const height = 260;
  const isStockPhoto =
    space.spaceType !== SpaceType.DayPass &&
    (partnerID === iWGSpacePartnerId || partnerID === iWGRegusPartnerId);

  const items = images.map((image) => ({
    src: image.large.url,
    w: image.large.width,
    h: image.large.height,
  }));

  const handleOpen = useCallback((newIndex) => {
    setIsOpen(true);
    setIndex(newIndex);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (images.length === 0) {
    return null;
  }

  return (
    <Container overflow="hidden" height={height} width="100%">
      {images.length > 1 && (
        <Container
          borderRadius={tokens.radius}
          customColor="rgba(245, 246, 247, 0.5)"
          padding={4}
          position="absolute"
          bottom={16}
          right={16}
          zIndex={1}
        >
          <Text size="xs">
            {index + 1} of {images.length}
          </Text>
        </Container>
      )}
      <Carousel>
        {images.map((image, i) => (
          <Pressable key={image.small.url} onPress={() => handleOpen(i)}>
            <Container height={height}>
              {isStockPhoto && i === 0 && <IndicativeLabel />}
              <img
                src={image.small.url}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt={`${name} cover 1`}
              />
            </Container>
          </Pressable>
        ))}
      </Carousel>
      <ImageGalleryModal
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </Container>
  );
}

function SpaceImageGalleryDesktop(props: SpaceImageGalleryProps) {
  const { space } = props;
  const { images: spaceImages, location, partnerID } = space;
  const { images: locationImages } = location;
  const images = spaceImages.concat(locationImages);
  const [isOpen, setIsOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const height = 440;

  const isStockPhoto =
    space.spaceType !== SpaceType.DayPass &&
    (partnerID === iWGSpacePartnerId || partnerID === iWGRegusPartnerId);

  const items = images.map((image) => ({
    src: image.large.url,
    w: image.large.width,
    h: image.large.height,
  }));

  const handleOpen = useCallback((i) => {
    setIsOpen(true);
    setIndex(i);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (images.length === 0) {
    return null;
  }

  return (
    <Container expanded>
      <Container height={height}>
        <Row>
          <Container height={height} flex={6} overflow="hidden">
            <Pressable style={{ height }} onPress={() => handleOpen(0)}>
              {isStockPhoto && <IndicativeLabel />}
              <img
                src={images[0].large.url}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt={`${space.name} cover 1`}
              />
            </Pressable>
          </Container>
          {images.length === 2 && (
            <Fragment>
              <Spacer size={16} />
              <Container height={height} flex={6} overflow="hidden">
                <Pressable style={{ height }} onPress={() => handleOpen(1)}>
                  <img
                    src={images[1].large.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt={`${space.name} cover 2`}
                  />
                </Pressable>
              </Container>
            </Fragment>
          )}
          {images.length >= 3 && (
            <>
              <Spacer size={16} />
              <Container flex={3}>
                <Column>
                  <Container overflow="hidden">
                    <Pressable
                      style={{ height: (height - 16) / 2 }}
                      onPress={() => handleOpen(1)}
                    >
                      <img
                        src={images[1].medium.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={`${space.name} cover 2`}
                      />
                    </Pressable>
                  </Container>
                  <Spacer size={16} />
                  <Container overflow="hidden">
                    <Pressable
                      style={{ height: (height - 16) / 2 }}
                      onPress={() => handleOpen(2)}
                    >
                      <img
                        src={images[2].medium.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={`${space.name} cover 3`}
                      />
                    </Pressable>
                  </Container>
                </Column>
              </Container>
            </>
          )}
        </Row>
      </Container>
      {images.length > 3 && (
        <Container left={16} bottom={16} position="absolute">
          <Pressable onPress={() => handleOpen(0)}>
            <Container borderRadius={4} customColor="white" padding={8}>
              <Text size="sm">View all images</Text>
            </Container>
          </Pressable>
        </Container>
      )}
      <ImageGalleryModal
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  labelContainer: {
    display: "flex",
    position: "absolute",
    top: 16,
    left: 18,
    flexDirection: "row",
    alignItems: "center",
  },
});
