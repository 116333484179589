import {
  BudgetDetailHeaderDesktop,
  BudgetDetailHeaderMobile,
} from "../components/budget_detail_header_v1";
import {
  CreateEditBudgetFormColumn,
  CreateEditBudgetSummaryColumn,
} from "../components/create_edit_budget_layout";
import { useForm } from "hooks/use_form";
import { Button } from "components/button_v2";
import { useMutation, gql, useQuery, useApolloClient } from "@apollo/client";
import { useToast } from "providers/toast";
import {
  EditOrganizationBudgetQuery,
  UpdateOrganizationBudgetMutation,
} from "core/graphql.generated";
import { CreateEditBudgetForm } from "./components/create_edit_form";
import { CreateEditBudgetSummary } from "./components/create_edit_budget_summary";
import { useHistory } from "react-router-dom";
import { CreateEditNavigationBlocker } from "../components/create_edit_navigation_blocker";
import { useMediaQuery } from "lib/media_query";
import React, { useMemo, useState } from "react";
import { BudgetMobileLayout } from "../budget_layout/buget_mobile_layout";
import { BudgetDesktopLayout } from "../budget_layout/buget_desktop_layout";
import { EditBudgetSkeleton } from "../components/edit_budget_skeleton";
import { AttachPolicyCard } from "../policy/attach_policy_card";
import { useAnalytics } from "providers/analytics";

export function OrganizationBudgetUpdate() {
  const toast = useToast();
  const mq = useMediaQuery();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const analytics = useAnalytics();

  const [shouldBlocknavigation, setShouldBlockNavigation] = useState(true);

  const { loading: budgetDataLoading, data: budgetData } =
    useQuery<EditOrganizationBudgetQuery>(editOrganizationBudgetQuery);
  const initialValues = useMemo(() => {
    const orgBudget = budgetData?.currentUser?.organization?.budget;
    return {
      limit: orgBudget?.limit,
      individualLimit: orgBudget?.individualLimit ?? undefined,
      policyId: orgBudget?.policy?.id,
    };
  }, [budgetData]);

  const [updateOrganizationBudget, { loading: updateLoading }] =
    useMutation<UpdateOrganizationBudgetMutation>(
      updateOrganizationBudgetMutation,
    );

  const { values, setFieldValue, errors, submit, isDirty } = useForm({
    reinitialize: true,
    initialValues,
    validate: (values) => {
      const errors: { limit?: string; individualLimit?: string } = {};

      if (!values.limit && values.limit !== 0) {
        errors.limit = "Please enter amount";
      }

      if (
        (values.limit || values.limit === 0) &&
        (values.individualLimit || values.individualLimit === 0) &&
        values.individualLimit > values.limit
      ) {
        errors.limit =
          "Monthly limit cannot be lower that the individual limit";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setShouldBlockNavigation(false);
        const res = await updateOrganizationBudget({
          variables: {
            input: {
              limit: values.limit,
              individualLimit: values.individualLimit,
              policyId: values.policyId,
            },
          },
        });

        const savedBudget = res.data?.updateOrganizationBudget;

        analytics.event("Update Organization Budget", {
          "Monthly Limit": savedBudget?.limit,
          "Individual Limit": savedBudget?.individualLimit,
          "Policy Name": savedBudget?.policy?.name,
        });

        toast.notify({
          message: "Budget has been successfully edited",
        });

        history.goBack();
        await apolloClient.resetStore();
      } catch (e) {
        toast.notify({
          message: "There has been an error when editing the budget",
        });
      }
    },
  });

  const content = () => (
    <>
      <CreateEditNavigationBlocker
        description="It looks like you’re in the middle of editing a budget. All information entered will be lost if you leave this page."
        isActive={isDirty && shouldBlocknavigation}
      />
      <CreateEditBudgetFormColumn>
        <CreateEditBudgetForm
          onSubmit={submit}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
        />
        <AttachPolicyCard
          defaultPolicyId={initialValues.policyId}
          onSelect={(policyId) => setFieldValue("policyId", policyId)}
        />
      </CreateEditBudgetFormColumn>
    </>
  );

  if (mq.deviceQuery.mobile) {
    return (
      <BudgetMobileLayout
        header={
          <BudgetDetailHeaderMobile
            title="Edit budget"
            actions={
              <Button
                onPress={submit}
                disabled={!isDirty}
                loading={updateLoading}
                text={"Save"}
                appearance={"textLink"}
                testID="save-org-budget-button"
              />
            }
          />
        }
        body={budgetDataLoading ? <EditBudgetSkeleton /> : content()}
      />
    );
  }

  return (
    <BudgetDesktopLayout
      header={
        <BudgetDetailHeaderDesktop
          title="Edit organization budget"
          backButtonText="Back to budgets"
          hasDivider={true}
          actions={
            <Button
              onPress={submit}
              disabled={!isDirty}
              text={"Save changes"}
              loading={updateLoading}
              testID="save-org-budget-button"
            />
          }
        />
      }
      body={budgetDataLoading ? <EditBudgetSkeleton /> : content()}
      extendedContent={
        budgetDataLoading ? null : (
          <CreateEditBudgetSummaryColumn>
            <CreateEditBudgetSummary
              limit={values.limit}
              individualLimit={values.individualLimit}
            />
          </CreateEditBudgetSummaryColumn>
        )
      }
    />
  );
}

const updateOrganizationBudgetMutation = gql`
  mutation UpdateOrganizationBudget($input: UpdateOrganizationBudgetInput!) {
    updateOrganizationBudget(input: $input) {
      id
      remaining
      spend
      limit
      individualLimit
      policy {
        id
        name
      }
    }
  }
`;

const editOrganizationBudgetQuery = gql`
  query EditOrganizationBudget {
    currentUser {
      id
      organization {
        id
        budget {
          limit
          individualLimit
          policy {
            id
          }
        }
      }
    }
  }
`;
