import { GetOrgDataQueryVariables, UserStatus } from "core/graphql.generated";
import { toDay } from "lib/day_utils";
import { useCurrentUserGeoCoordinatesQuery } from "pages/homev2/hooks/use_current_user_geo_coordinates";
import { useMyTeamSearchParamsQuery } from "pages/team_spaces/hooks/use_my_team_search_params";
import { useMemo } from "react";

export function useMyTeamQueryParamVariables() {
  const { date } = useMyTeamSearchParamsQuery();
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useCurrentUserGeoCoordinatesQuery();

  const variables: GetOrgDataQueryVariables | null = useMemo(() => {
    if (!currentUserCoordinates && currentUserLoading) {
      return null;
    }
    return {
      currentUserCoordinates: currentUserCoordinates
        ? {
            lat: currentUserCoordinates?.lat,
            lng: currentUserCoordinates?.lng,
          }
        : {},
      startDate: date || toDay(new Date()),
      endDate: date || toDay(new Date()),
      status: UserStatus.Active,
    };
  }, [currentUserCoordinates, currentUserLoading, date]);

  return { variables, currentUserLoading };
}
