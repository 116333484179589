import { ApolloError, gql, useQuery } from "@apollo/client";

import {
  Bookings__OrderDetailsFragment,
  BookingsQuery,
  BookingsQueryVariables,
  OrderStatus,
} from "core/graphql.generated";
import { isAfter, isSameDay } from "date-fns";
import { UseOrderQueryProps } from "pages/homev3/hooks/use_user_order_query";

import { getOrderItemStartDate } from "../bookings_utils";

interface BookingQueryData {
  __typename?: "Query";
  archiveBookings: Bookings__OrderDetailsFragment[];
  upcomingBookings: Bookings__OrderDetailsFragment[];
}

interface BookingQueryReturn {
  data: BookingQueryData;
  loading: boolean;
  error: ApolloError | undefined;
}
export function useBookingQuery({
  limit,
  sort,
  startDate,
  endDate,
}: UseOrderQueryProps): BookingQueryReturn {
  const { data, loading, error } = useQuery<
    BookingsQuery,
    BookingsQueryVariables
  >(bookingsQuery, { variables: { limit, sort, startDate, endDate } });

  const upcoming: Bookings__OrderDetailsFragment[] = [];
  const archive: Bookings__OrderDetailsFragment[] = [];
  data?.currentUser?.orders.forEach((order) => {
    const startDate = getOrderItemStartDate(order.orderItems[0]);
    const isUpcomingBooking =
      (isAfter(startDate, new Date()) || isSameDay(startDate, new Date())) &&
      order.status !== OrderStatus.Canceled;
    if (isUpcomingBooking) {
      upcoming.push(order);
    } else {
      archive.push(order);
    }
  });

  return {
    data: {
      __typename: "Query",
      archiveBookings: archive,
      upcomingBookings: upcoming,
    },
    loading,
    error,
  };
}

const bookingsQuery = gql`
  query Bookings(
    $limit: Int
    $sort: OrderSort
    $startDate: String
    $endDate: String
  ) {
    currentUser {
      orders(
        limit: $limit
        sort: $sort
        startDate: $startDate
        endDate: $endDate
      ) {
        ...Bookings__OrderDetails
      }
    }
  }

  fragment Bookings__OrderDetails on Order {
    id
    status
    confirmationID
    invitees {
      name
    }
    orderItems {
      ...Bookings__OrderItemDetails
    }
  }

  fragment Bookings__OrderItemDetails on OrderItem {
    details {
      __typename
      ...Bookings__DailyBookingDetails
      ...Bookings__HourlyBookingDetails
    }
  }

  fragment Bookings__DailyBookingDetails on DailyBooking {
    startDate
    endDate
    space {
      id
      name
      spaceType
      maxCapacity
      preferred
      images {
        medium {
          url
        }
      }
      location {
        id
        address {
          streetAddress
          city
          state
          latitude
          longitude
        }
        name
      }
    }
  }

  fragment Bookings__HourlyBookingDetails on HourlyBooking {
    date
    startTime
    endTime
    space {
      id
      name
      spaceType
      maxCapacity
      preferred
      inventoryCapacity
      images {
        medium {
          url
        }
      }
      location {
        id
        address {
          streetAddress
          city
          state
          latitude
          longitude
        }
        name
      }
    }
  }
`;
