import { StyleSheet, View } from "react-native";

import { colors } from "components/colors";

interface BookingCardProps {
  children: JSX.Element;
}

export function BookingCard({ children }: BookingCardProps) {
  return <View style={styles.root}>{children}</View>;
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 4,
    filter: "drop-shadow(0px 1px 10px rgba(6, 0, 30, 0.1))",
    marginBottom: 24,
    backgroundColor: colors.brand.whitecore,
  },
});
