import {
  eachDayOfInterval,
  isBefore,
  isSameDay,
  parseISO,
  format,
} from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import { DateInterval, formatISODate } from "lib/date_utils";
import { Day } from "lib/day_utils";
import { Time, TimeInterval } from "lib/time_utils";
import {
  AvailableTimeSlot,
  BookingType,
  Pricing,
  BetterHourRatesQuery,
  BetterHourRatesQueryVariables,
  BestDayRates,
  PriceRangesType,
} from "./graphql.generated";
import { DynamicPrice } from "components/month_view/month_view";

export interface HourlyDateRange {
  type: BookingType.HourlyBooking;
  date?: Day;
  startTime?: Time;
  endTime?: Time;
}

export interface DailyDateRange {
  type: BookingType.DailyBooking;
  startDate?: Day;
  endDate?: Day;
}

export interface DatePickerProps {
  value?: Date | null;
  onChange?: (value: Date) => void;
  isOutsideRange?: (day: Date) => boolean;
  isBlocked?: (day: Date) => boolean;
  basePrice?: string;
  dynamicPrices?: DynamicPrice[];
  withDaysOfTheWeek?: boolean;
}

export interface DateRangePickerProps {
  value?: DateInterval | null;
  onChange?: (value: DateInterval) => void;
  isOutsideRange?: (day: Date) => boolean;
  isBlocked?: (day: Date) => boolean;
  basePrice?: string;
  dynamicPrices?: DynamicPrice[];
  withDaysOfTheWeek?: boolean;
}

export type DateRange = HourlyDateRange | DailyDateRange;

export interface BookingDateRangePickerProps {
  onChange: (value?: DateRange) => void;
  onChangeBookingType: (bookingType: BookingType) => void;
  value?: DateRange;
  bookingType?: BookingType;
  showSegmentedControl?: boolean;
  multiDayBookingAllowed?: boolean;
  timeslots: AvailableTimeSlot[];
  /**
   * Checks whether a date is available. Differs between booking types.
   * In DailyBooking, whole day needs to be available to not be blocked.
   * In HourlyBooking, there may be other bookings on the same date, but should still be available.
   */
  isBlockedDate?: (day: Day, bookingType: BookingType) => boolean;
  isOutsideRange?: (day: Day) => boolean;
  pricings: Pricing[];
  dynamicPrices: BestDayRates[];
  currency: string;
  spaceId: string;
  priceRanges?: PriceRangesType;
  onChangeBetterRateTimeSlots?: (value: TimeSlot[]) => void;
}

export interface HourlyBookingDateRangePickerProps {
  showSegmentedControl: boolean;
  onChangeBookingType: (bookingType: BookingType) => void;
  value?: HourlyDateRange;
  onChange: (value: HourlyDateRange) => void;
  timeslots: AvailableTimeSlot[];
  isBlockedDate?: (day: Date, bookingType: BookingType) => boolean;
  isOutsideRange?: (day: Date) => boolean;
  basePrice?: number;
  dynamicPrices?: BestDayRates[];
  spaceId: string;
  currency: string;
  onChangeBetterRateTimeSlots?: (value: TimeSlot[]) => void;
  withDaysOfTheWeek?: boolean;
}

export interface DailyBookingDateRangePickerProps {
  showSegmentedControl: boolean;
  multiDayBookingAllowed: boolean;
  onChangeBookingType: (bookingType: BookingType) => void;
  value?: DailyDateRange | null;
  onChange: (value: DailyDateRange) => void;
  isBlockedDate?: (day: Date, bookingType: BookingType) => boolean;
  isOutsideRange?: (day: Date) => boolean;
  basePrice?: number;
  dynamicPrices?: BestDayRates[];
  currency: string;
  withDaysOfTheWeek?: boolean;
}
interface UseHourlyBookingDateRangePickerHelpersProps {
  value?: HourlyDateRange;
  onChange: (value: HourlyDateRange) => void;
  spaceId: string;
  timeSlots: AvailableTimeSlot[];
  dynamicPrices?: BestDayRates[];
  basePrice?: number;
}

interface MinHourOption {
  minBookingUnit: string;
  unitPrice: number;
}

interface HourRangeOption {
  startTime: string;
  endTime: string;
  unitPrice: number;
}

export interface TimeSlot extends AvailableTimeSlot {
  hasBetterRate?: boolean;
}

export function useHourlyBookingDateRangePickerHelpers(
  props: UseHourlyBookingDateRangePickerHelpersProps,
) {
  const {
    value,
    onChange,
    spaceId,
    timeSlots,
    dynamicPrices,
    basePrice = 0,
  } = props;
  const [recommendation, setRecommendation] = useState<string>();
  const [hourRangeOptions, setHourRangeOptions] = useState<HourRangeOption[]>(
    [],
  );
  const [currentUnitPrice, setCurrentUnitPrice] = useState<number>();

  const [getBetterHourRates] = useLazyQuery<
    BetterHourRatesQuery,
    BetterHourRatesQueryVariables
  >(betterHourRatesQuery);

  const date = value?.date ? parseISO(value.date) : undefined;
  const start = value?.startTime;
  const end = value?.endTime;
  const showRecommendation = !!(start && end);

  const handleBetterHourRatesQuery = useCallback(
    async ({
      date,
      start,
      end,
      unitPrice,
    }: {
      date: Date;
      start?: string;
      end?: string;
      unitPrice?: number;
    }) => {
      if (start && end) {
        const { data } = await getBetterHourRates({
          variables: {
            spaceId,
            date: format(date, "yyyy-MM-dd"),
            startTime: start,
            endTime: end,
            unitPrice: undefined,
          },
        });
        if (data?.space?.betterHourRates) {
          const { currentUnitPrice, options } = data.space.betterHourRates;

          const minHourOptions: MinHourOption[] = [];
          if (options.length) {
            options.forEach((option) => {
              if (option.minBookingUnit) {
                minHourOptions.push({
                  minBookingUnit: option.minBookingUnit,
                  unitPrice: option.unitPrice,
                });
              }
            });
          }

          const minHourRate = getMinHourRate(
            basePrice || currentUnitPrice,
            minHourOptions,
            timeSlots,
            start,
          );
          setRecommendation(minHourRate.recommendation);
          setCurrentUnitPrice(currentUnitPrice);
        }
      } else if (unitPrice) {
        const { data } = await getBetterHourRates({
          variables: {
            spaceId,
            date: format(date, "yyyy-MM-dd"),
            unitPrice,
            startTime: undefined,
            endTime: undefined,
          },
        });
        if (data?.space?.betterHourRates) {
          const { options } = data.space.betterHourRates;

          const hourRangeOptions: HourRangeOption[] = [];
          if (options.length) {
            options.forEach((option) => {
              if (option.startTime && option.endTime) {
                hourRangeOptions.push({
                  startTime: option.startTime,
                  endTime: option.endTime,
                  unitPrice: option.unitPrice,
                });
              }
            });
          }

          setHourRangeOptions(hourRangeOptions);
        }
      }
    },
    [basePrice, getBetterHourRates, spaceId, timeSlots],
  );

  useEffect(() => {
    if (date && start && end) {
      handleBetterHourRatesQuery({ date, start, end });
    }
    // only listen on time changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  const betterRateTimeSlots = useMemo(
    () => getBetterRateTimeSlots(timeSlots, hourRangeOptions),
    [hourRangeOptions, timeSlots],
  );

  const handleDateChange = useCallback(
    (date: Date, unitPrice?: number) => {
      onChange({
        type: BookingType.HourlyBooking,
        date: formatISODate(date),
      });
      setRecommendation("");
      setCurrentUnitPrice(undefined);
      handleBetterHourRatesQuery({ date, unitPrice });
    },
    [onChange, handleBetterHourRatesQuery],
  );

  const handleTimeRangeChange = useCallback(
    (timeRange: Partial<TimeInterval>) => {
      onChange({
        type: BookingType.HourlyBooking,
        date: value?.date,
        startTime: timeRange.start,
        endTime: timeRange.end,
      });
      setRecommendation("");
    },
    [onChange, value?.date],
  );

  return {
    handleDateChange,
    handleTimeRangeChange,
    date,
    start,
    end,
    recommendation: showRecommendation ? recommendation : undefined,
    betterRateTimeSlots,
    betterDynamicPrices: getBetterDynamicPrices(
      dynamicPrices,
      date,
      currentUnitPrice,
    ),
  };
}

const betterHourRatesQuery = gql`
  query BetterHourRates(
    $spaceId: ID!
    $date: String!
    $startTime: String
    $endTime: String
    $unitPrice: Float
  ) {
    space(id: $spaceId) {
      betterHourRates(
        input: {
          date: $date
          startTime: $startTime
          endTime: $endTime
          unitPrice: $unitPrice
        }
      ) {
        currentUnitPrice
        options {
          minBookingUnit
          unitPrice
          startTime
          endTime
        }
      }
    }
  }
`;

function getBetterDynamicPrices(
  dynamicPrices?: BestDayRates[],
  date?: Date,
  currentUnitPrice?: number,
) {
  const updatedDynamicPrices = dynamicPrices?.map((dP) => ({ ...dP }));
  if (updatedDynamicPrices && date && currentUnitPrice) {
    const formatedDate = format(date, "yyyy-MM-dd");
    const currentDynamicPrice = updatedDynamicPrices.find(
      (dP) => dP.date === formatedDate,
    );
    if (currentDynamicPrice) {
      currentDynamicPrice.price = currentUnitPrice;
    } else {
      updatedDynamicPrices.push({
        date: formatedDate,
        price: currentUnitPrice,
      });
    }
  }
  return updatedDynamicPrices;
}

function getBetterRateTimeSlots(
  timeSlots: TimeSlot[],
  hourRangeOptions: HourRangeOption[],
) {
  const betterRateTimeSlots = timeSlots.map((s) => ({ ...s }));
  betterRateTimeSlots.forEach((slot) => {
    const startTime = Number(slot.startTime);
    hourRangeOptions.forEach((option) => {
      if (
        startTime >= Number(option.startTime) &&
        startTime <= Number(option.endTime)
      ) {
        slot.hasBetterRate = true;
      }
    });
  });

  return betterRateTimeSlots;
}

function getMinHourRate(
  currentPrice: number,
  options: MinHourOption[],
  timeSlots: TimeSlot[],
  startTime?: string,
) {
  let savingPercent: number | undefined;
  let recommendation: string | undefined;
  let unitPrice: number | undefined;
  const endTimeSlots = timeSlots[0].endTimes;
  const finalEndTimeSlot = endTimeSlots[endTimeSlots.length - 1];
  options.forEach((option) => {
    const currentSavingPercent = Math.round(
      ((currentPrice - option.unitPrice) / currentPrice) * 100,
    );
    const minBookingUnit = Number(option.minBookingUnit);
    const isWithinMinBookingUnit = startTime
      ? (Number(finalEndTimeSlot) - Number(startTime)) / 100 >= minBookingUnit
      : false;
    if (
      (!savingPercent || savingPercent > currentSavingPercent) &&
      isWithinMinBookingUnit
    ) {
      savingPercent = currentSavingPercent;
      unitPrice = option.unitPrice;
      recommendation = `Save ${currentSavingPercent}% when you book ${Number(
        option.minBookingUnit,
      )} hours or more`;
    }
  });

  return { recommendation, unitPrice };
}

interface UseDailyBookingDateRangePickerHelpersProps {
  value?: DailyDateRange | null;
  onChange: (value: DailyDateRange) => void;
}

export function useDailyBookingDateRangePickerHelpers(
  props: UseDailyBookingDateRangePickerHelpersProps,
) {
  const { value, onChange } = props;

  const handleDateRangeChange = useCallback(
    (dateRange: DateInterval) => {
      onChange({
        type: BookingType.DailyBooking,
        startDate: formatISODate(dateRange.start),
        endDate: formatISODate(dateRange.end),
      });
    },
    [onChange],
  );

  const handleDateChange = useCallback(
    (day: Date) => {
      onChange({
        type: BookingType.DailyBooking,
        startDate: formatISODate(day),
        endDate: formatISODate(day),
      });
    },
    [onChange],
  );

  const start = value?.startDate ? parseISO(value.startDate) : undefined;
  const end = value?.endDate ? parseISO(value.endDate) : undefined;
  const dateRange = start ? { start, end: end || start } : undefined;

  return {
    handleDateRangeChange,
    handleDateChange,
    dateRange,
  };
}

export interface DatePickerDisplayProps {
  selected?: DateInterval | null;
  onSelectDay?: (day: Date) => void;
  isOutsideRange?: (day: Date) => boolean;
  isBlocked?: (day: Date) => boolean;
  basePrice?: string;
  dynamicPrices?: DynamicPrice[];
  withDaysOfTheWeek?: boolean;
}

export interface UseSelectDayDateRangeHandlerProps {
  value?: DateInterval | null;
  onChange?: (value: DateInterval) => void;
  isBlocked?: (day: Date) => boolean;
}

export function useSelectDayDateRangeHandler(
  props: UseSelectDayDateRangeHandlerProps,
) {
  const { value, onChange, isBlocked } = props;

  return useCallback(
    (day: Date) => {
      if (onChange === undefined) {
        return;
      }

      if (value) {
        // When only start was selected, it means user just selected end
        if (isSameDay(value.start, value.end)) {
          if (isBefore(day, value.start)) {
            onChange({
              start: day,
              end: day,
            });
          } else if (
            hasBlockedDate({ start: value.start, end: day }, isBlocked)
          ) {
            onChange({
              start: day,
              end: day,
            });
          } else {
            onChange({
              start: value.start,
              end: day,
            });
          }

          // When there is already an interval, reset to start only
        } else {
          onChange({
            start: day,
            end: day,
          });
        }
      } else {
        onChange({
          start: day,
          end: day,
        });
      }
    },
    [onChange, value, isBlocked],
  );
}

function hasBlockedDate(
  interval: DateInterval,
  isBlocked?: (day: Date) => boolean,
) {
  if (!isBlocked) {
    return false;
  }

  const days = eachDayOfInterval(interval);

  return days.some(isBlocked);
}
