import { BookingRequestStatus } from "core/graphql.generated";
import { Text } from "components/text_v2";

interface PropsType {
  status: BookingRequestStatus;
}

export const StatusText: React.FC<PropsType> = ({ status }) => {
  return (
    <Text color={"black-70"} weight="semibold" size="xs">
      {status}
    </Text>
  );
};
