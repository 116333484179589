import { Suspense } from "react";
import { StyleSheet, View } from "react-native";

import { Map } from "components/map";
import { Spinner } from "components/spinner";

interface LocationDetailsMapProps {
  latitude?: number;
  longitude?: number;
}

export function LocationDetailsMap({
  latitude,
  longitude,
}: LocationDetailsMapProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <View style={styles.mapWrapper}>
        <Map
          height={315}
          latitude={latitude}
          longitude={longitude}
          fullScreenControl
        />
      </View>
    </Suspense>
  );
}

const styles = StyleSheet.create({
  mapWrapper: {
    borderRadius: 4,
    overflow: "hidden",
  },
});
