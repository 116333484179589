import { Button } from "components/button_v2";

type BudgetMobileButtonProps = {
  onPress: () => void;
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  testID?: string;
};
export const BudgetMobileButton = ({
  onPress,
  text,
  loading = false,
  disabled = false,
  testID,
}: BudgetMobileButtonProps) => (
  <Button
    appearance={"textLink"}
    text={text}
    onPress={onPress}
    loading={loading}
    disabled={disabled}
    testID={testID}
  />
);

export const EditMobileButton = ({
  onPress,
  testID,
}: BudgetMobileButtonProps) => (
  <BudgetMobileButton onPress={onPress} text={"Edit"} testID={testID} />
);

export const SaveMobileButton = ({
  onPress,
  loading,
  disabled,
  testID,
}: BudgetMobileButtonProps) => (
  <BudgetMobileButton
    onPress={onPress}
    text={"Save"}
    loading={loading}
    disabled={disabled}
    testID={testID}
  />
);
