import { CardsSaveIcon } from "components/cards_save_icon";
import { colors } from "components/colors";
import { LinkWrapper } from "components/link_wrapper";
import { PreferredBadge } from "components/preferred_badge";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { WhoIsHere } from "components/who_is_here";
import {
  OrganizationUser,
  SpaceLocationDetailFragment,
} from "core/graphql.generated";
import {
  SpaceCardPhoto,
  SpaceCardWhoIsHere,
} from "pages/homev2/components/space_card_v2/space_card_template";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { LocationCardAddressWithDistance } from "pages/homev3/components/location_card_address_with_distance";
import { placeholderLocationImage } from "pages/location_details/utils/get_placeholder_location_image";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import React from "react";
import { StyleSheet, View } from "react-native";

interface CardProps {
  location: SpaceLocationDetailFragment;
  peoples: Partial<OrganizationUser>[];
  onPress?: () => void;
}

export function LocationCard({ location, peoples, onPress }: CardProps) {
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  return (
    <View style={styles.card}>
      <LinkWrapper onPress={onPress} to={`/location/${location.id}`}>
        <View style={styles.relativeContainer}>
          <SpaceCardPhoto
            imageSrc={location.images[0]?.small.url || placeholderLocationImage}
          />
          <CardsSaveIcon
            favoriteId={location.id}
            favoriteType={FavoriteType.Location}
          />
        </View>
        <View style={styles.textView}>
          <Text
            size="xs"
            color="black-core"
            weight="semibold"
            numberOfLines={1}
          >
            {location.name}
          </Text>
          <LocationCardAddressWithDistance
            currentUserDistance={location.currentUserDistance}
            shortAddress={location.address.city}
          />
          {isPreferredFlag && location.preferred && (
            <>
              <Spacer size={8} />
              <PreferredBadge variant={"preferredOnly"} />
            </>
          )}
        </View>
        <SpaceCardWhoIsHere>
          <WhoIsHere peoples={peoples} />
        </SpaceCardWhoIsHere>
      </LinkWrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    width: 300,
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  relativeContainer: {
    position: "relative",
  },
  textView: {
    padding: 16,
  },
});
