import { gql, useQuery } from "@apollo/client";
import { Spacer } from "components/spacer";
import { Spinner } from "components/spinner";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { DialogContent } from "core/dialog_content";
import {
  LocationPreviewCardLocationPhysicalSpaces__PhysicalSpaceDetailsFragment,
  LocationPreviewCardLocationPhysicalSpaces__SpaceDetailsFragment,
  LocationPreviewCardLocationSpaces__SpaceDetailsFragment,
  LocationPreviewSpacesQuery,
  LocationPreviewSpacesQueryVariables,
  SpaceType,
} from "core/graphql.generated";
import { isEmpty } from "lib/lang_utils";
import { minBy } from "lib/math_utils";
import { useHomeSearchParamsQuery } from "pages/homev2/hooks/use_home_search_params";
import { Fragment, useMemo } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { LocationPreviewSpaceItem } from "./location_preview_space_item";

interface LocationPreviewProps {
  locationPreloaded: {
    timezone: string;
    id: string;
    name: string;
    address: {
      streetAddress: string;
      city: string;
    };
  };
  onClose: () => void;
}

export function LocationPreview(props: LocationPreviewProps) {
  const { locationPreloaded, onClose } = props;
  const {
    spaceType,
    date,
    startTime,
    endTime,
    minCapacity,
    maxCapacity,
    coordinates,
  } = useHomeSearchParamsQuery();
  const locationID = locationPreloaded.id;

  const filter = useMemo(() => {
    return {
      date,
      startTime,
      endTime,
      minCapacity,
      maxCapacity,
      lat: coordinates?.lat,
      lng: coordinates?.lng,
    };
  }, [
    coordinates?.lat,
    coordinates?.lng,
    date,
    endTime,
    maxCapacity,
    minCapacity,
    startTime,
  ]);

  const dayPassFilter = useMemo(
    () => ({
      ...filter,
      startTime: undefined,
      endTime: undefined,
    }),
    [filter],
  );

  const { data, loading } = useQuery<
    LocationPreviewSpacesQuery,
    LocationPreviewSpacesQueryVariables
  >(locationPreviewQuery, {
    variables: {
      filter,
      dayPassFilter,
      offsiteLocationID: locationID,
    },
  });

  const meetingRooms = data?.meetingRooms?.spaces;
  const dayOffices = data?.dayOffices?.spaces;
  const location = data?.locations;

  let content = <Fragment />;

  if (!location || loading) {
    content = (
      <View style={styles.emptyPlaceholder}>
        <Spinner />
      </View>
    );
  } else if (spaceType === SpaceType.MeetingRoom) {
    content = (
      <MapPreviewCardDialogSpaces
        spaces={meetingRooms || []}
        spaceType={SpaceType.MeetingRoom}
      />
    );
  } else if (spaceType === SpaceType.DayOffice) {
    content = <MapPreviewCardDialogDayOffices dayOffices={dayOffices || []} />;
  }

  return (
    <DialogContent
      headerRightIcon="x-circle"
      onHeaderRightIconPress={onClose}
      headerTitle={`All ${
        spaceType === SpaceType.DayOffice ? "offices" : "rooms"
      } at this location`}
      desktopWidth={600}
    >
      <Spacer size={16} />

      {content}
      <Spacer size={24} />
    </DialogContent>
  );
}

interface MapPreviewCardDialogSpacesProps {
  spaceType: SpaceType;
  spaces: LocationPreviewCardLocationSpaces__SpaceDetailsFragment[];
}

function MapPreviewCardDialogSpaces(props: MapPreviewCardDialogSpacesProps) {
  const { spaces, spaceType } = props;

  let emptyText = "";
  if (spaceType === SpaceType.DayPass) {
    emptyText = "There are no day passes found";
  } else if (spaceType === SpaceType.MeetingRoom) {
    emptyText = "There are no meeting rooms found.";
  }

  return (
    <Fragment>
      <View style={styles.dialogContentWrapper}>
        <ScrollView>
          {isEmpty(spaces) ? (
            <View style={styles.emptyPlaceholder}>
              <Text align="center">{emptyText}</Text>
            </View>
          ) : (
            spaces.map((space) => (
              <LocationPreviewSpaceItem
                key={space.id}
                space={space}
                name={space.name}
              />
            ))
          )}
        </ScrollView>
      </View>
    </Fragment>
  );
}

interface MapPreviewCardDialogDayOfficesProps {
  dayOffices: LocationPreviewCardLocationPhysicalSpaces__SpaceDetailsFragment[];
}

function MapPreviewCardDialogDayOffices(
  props: MapPreviewCardDialogDayOfficesProps,
) {
  const { dayOffices } = props;
  let physicalSpaces: LocationPreviewCardLocationPhysicalSpaces__PhysicalSpaceDetailsFragment[] =
    [];

  for (const space of dayOffices) {
    physicalSpaces = physicalSpaces.concat(space.physicalSpaces);
  }

  physicalSpaces.sort((a, b) => {
    const minA = minBy(a.space.layouts, (l) => l.capacity);
    const minB = minBy(b.space.layouts, (l) => l.capacity);
    return minA - minB;
  });

  return (
    <Fragment>
      <View style={styles.dialogContentWrapper}>
        <ScrollView>
          {isEmpty(physicalSpaces) ? (
            <View style={styles.emptyPlaceholder}>
              <Text align="center">There are no day offices found.</Text>
            </View>
          ) : (
            physicalSpaces.map((ps) => (
              <LocationPreviewSpaceItem
                key={ps.id}
                space={ps.space}
                physicalSpaceId={ps.id}
                name={`Office ${ps.name}`}
              />
            ))
          )}
        </ScrollView>
      </View>
    </Fragment>
  );
}

export const locationPreviewQuery = gql`
  query LocationPreviewSpaces(
    $offsiteLocationID: ID!
    $filter: DiscoverFilter
    $dayPassFilter: DiscoverFilter
  ) {
    locations: offsiteLocation(id: $offsiteLocationID) {
      id
      name
      ...LocationPreview__LocationSpaces
    }
    meetingRooms: offsiteLocation(id: $offsiteLocationID) {
      id
      name
      ...LocationPreviewCardLocationSpaces__LocationDetails
    }
    dayPasses: offsiteLocation(id: $offsiteLocationID) {
      id
      name
      ...LocationPreviewCardLocationSpaces__DayPassLocationDetails
    }
    dayOffices: offsiteLocation(id: $offsiteLocationID) {
      id
      name
      ...LocationPreviewCardLocationPhysicalSpaces__LocationDetails
    }
  }

  fragment LocationPreviewCardLocationSpaces__LocationDetails on Location {
    spaces(spaceType: MeetingRoom, filter: $filter) {
      ...LocationPreviewCardLocationSpaces__SpaceDetails
    }
  }
  fragment LocationPreviewCardLocationSpaces__DayPassLocationDetails on Location {
    spaces(spaceType: DayPass, filter: $dayPassFilter) {
      ...LocationPreviewCardLocationSpaces__SpaceDetails
    }
  }

  fragment LocationPreviewCardLocationSpaces__SpaceDetails on Space {
    id
    currency
    name
    locked
    spaceType
    amenities {
      id
      name
      iconURL
      code
    }
    ...Location_Preview_Space_Commons
  }

  fragment LocationPreviewCardLocationPhysicalSpaces__LocationDetails on Location {
    spaces(spaceType: DayOffice, filter: $filter) {
      locked
      ...LocationPreviewCardLocationPhysicalSpaces__SpaceDetails
    }
  }

  fragment LocationPreviewCardLocationPhysicalSpaces__SpaceDetails on Space {
    id
    name
    physicalSpaces {
      ...LocationPreviewCardLocationPhysicalSpaces__PhysicalSpaceDetails
    }
  }

  fragment LocationPreviewCardLocationPhysicalSpaces__PhysicalSpaceDetails on PhysicalSpace {
    id
    name
    floor
    withWindow
    space {
      id
      name
      currency
      locked
      spaceType
      amenities {
        id
        name
        iconURL
        code
      }
      ...Location_Preview_Space_Commons
    }
  }

  fragment Location_Preview_Space_Commons on Space {
    pricings {
      type
      price
    }
    priceRanges {
      daily {
        min
        max
      }
      hourly {
        min
        max
      }
    }
    layouts {
      capacity
    }
    maxCapacity
    images {
      small {
        url
        width
        height
      }
    }
    location {
      amenities {
        id
        name
        iconURL
        code
      }
    }
  }

  fragment LocationPreview__LocationSpaces on Location {
    id
    name
    timezone
    address {
      fullAddress
      streetAddress
      city
      latitude
      longitude
    }
    images {
      small {
        url
        width
        height
      }
    }
  }
`;

const styles = StyleSheet.create({
  dialogContentWrapper: {
    flex: 1,
  },
  dialogInfo: {
    paddingHorizontal: 16,
  },
  dialogFooter: {
    padding: 16,
    borderTopWidth: 1,
    borderColor: tokens.colors.neutral.darker,
    alignItems: "flex-end",
  },
  emptyPlaceholder: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
});
