import { SpacesSort, SpaceType } from "core/graphql.generated";
import { Day, isDay } from "lib/day_utils";
import { isEmpty } from "lib/lang_utils";
import { keysOf } from "lib/object_utils";
import { isTime } from "lib/time_utils";
import { useReBookFeatureFlag } from "providers/splitio";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Coordinates } from "../coordinates";
import { SPACE_TYPE_TAB_LOCALSTORAGE_KEY } from "./use_initial_home_search_params";
import { initialPlaceState } from "./use_initial_place";

export interface HomeSearchParams {
  date: Day | undefined;
  startTime: Day | undefined;
  endTime: Day | undefined;
  /** `placeId` is used to retain location search input only, when users navigate back and forth between pages */
  placeId: string | undefined;
  spaceType: SpaceType | undefined;
  coordinates: Coordinates | undefined;
  bbox: [number, number, number, number] | undefined;
  minCapacity: number | undefined;
  maxCapacity: number | undefined;
  amenities: string[] | undefined;
  preferred: boolean | undefined;
  sort: SpacesSort;
  page: number;
  mapSearch: boolean | undefined;
}

export function useHomeSearchParamsQuery(): HomeSearchParams {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reBookFeatureFlag = useReBookFeatureFlag();

  let date: Day | undefined;
  let startTime: Day | undefined;
  let endTime: Day | undefined;
  let placeId: string | undefined;
  let spaceType: SpaceType | undefined =
    (localStorage.getItem(SPACE_TYPE_TAB_LOCALSTORAGE_KEY) as SpaceType) ||
    (reBookFeatureFlag ? undefined : SpaceType.DayPass);

  let lat: number | undefined;
  let lng: number | undefined;
  let minCapacity: number | undefined;
  let maxCapacity: number | undefined;

  let bboxTl: number | undefined;
  let bboxTr: number | undefined;
  let bboxBr: number | undefined;
  let bboxBl: number | undefined;
  let amenities: string[] | undefined;
  let preferred: boolean | undefined;
  let sort: SpacesSort = SpacesSort.Distance;
  let page: number = 1;
  let mapSearch: boolean | undefined;

  const preferredParams = searchParams.get("preferred");
  if (preferredParams) {
    preferred = preferredParams === "true";
  }

  const dateParams = searchParams.get("date");
  if (dateParams && isDay(dateParams)) {
    date = dateParams;
  }

  const startTimeParams = searchParams.get("startTime");
  if (startTimeParams && isTime(startTimeParams)) {
    startTime = startTimeParams;
  }

  const endTimeParams = searchParams.get("endTime");
  if (endTimeParams && isTime(endTimeParams)) {
    endTime = endTimeParams;
  }

  const minCapacityParams = searchParams.get("minCapacity");
  if (minCapacityParams && !Number.isNaN(Number(minCapacityParams))) {
    minCapacity = Number(minCapacityParams);
  }
  const maxCapacityParams = searchParams.get("maxCapacity");
  if (maxCapacityParams && !Number.isNaN(Number(maxCapacityParams))) {
    maxCapacity = Number(maxCapacityParams);
  }

  const coordinatesParams = searchParams.get("coordinates");
  if (coordinatesParams) {
    const [latitude, longitude] = coordinatesParams.split(",");

    if (!Number.isNaN(Number(longitude)) && !Number.isNaN(Number(latitude))) {
      lat = Number(latitude);
      lng = Number(longitude);
    }
  }

  const bboxParams = searchParams.get("bbox");
  if (bboxParams) {
    const [tl, tr, br, bl] = bboxParams.split(",");

    if (
      !Number.isNaN(Number(tl)) &&
      !Number.isNaN(Number(tr)) &&
      !Number.isNaN(Number(br)) &&
      !Number.isNaN(Number(bl))
    ) {
      bboxTl = Number(tl);
      bboxTr = Number(tr);
      bboxBr = Number(br);
      bboxBl = Number(bl);
    }
  }

  const placeParams = searchParams.get("placeId");
  if (placeParams) {
    placeId = placeParams;
  }

  const spaceTypeParams = searchParams.get("spaceType");

  if (spaceTypeParams) {
    if (spaceTypeParams === SpaceType.DayOffice) {
      spaceType = SpaceType.DayOffice;
    } else if (spaceTypeParams === SpaceType.DayPass) {
      spaceType = SpaceType.DayPass;
    } else if (spaceTypeParams === SpaceType.MeetingRoom) {
      spaceType = SpaceType.MeetingRoom;
    }
  }

  const amenitiesParams = searchParams.get("amenities");

  if (amenitiesParams) {
    amenities = amenitiesParams.split(",");
  }

  const sortParams = searchParams.get("sort");

  if (sortParams) {
    if (sortParams === SpacesSort.Distance) {
      sort = SpacesSort.Distance;
    } else if (sortParams === SpacesSort.PriceAsc) {
      sort = SpacesSort.PriceAsc;
    } else if (sortParams === SpacesSort.PriceDesc) {
      sort = SpacesSort.PriceDesc;
    }
  }

  const pageParams = searchParams.get("page");

  if (pageParams) {
    page = Number(pageParams);
  }

  const mapSearchParam = searchParams.get("mapSearch");
  if (mapSearchParam) {
    mapSearch = true;
  }

  return useMemo(() => {
    return {
      date,
      startTime,
      endTime,
      placeId,
      spaceType,
      coordinates:
        lat && lng
          ? {
              lat,
              lng,
            }
          : undefined,
      minCapacity,
      maxCapacity,
      bbox:
        bboxTl && bboxTr && bboxBr && bboxBl
          ? [bboxTl, bboxTr, bboxBr, bboxBl]
          : undefined,
      amenities,
      preferred,
      sort,
      page,
      mapSearch,
    };
  }, [
    date,
    startTime,
    endTime,
    placeId,
    spaceType,
    lat,
    lng,
    minCapacity,
    maxCapacity,
    bboxTl,
    bboxTr,
    bboxBr,
    bboxBl,
    amenities,
    preferred,
    sort,
    page,
    mapSearch,
  ]);
}

type NullablePartial<T> = { [P in keyof T]?: T[P] | null };

export type HomeSearchParamsChangeSet = NullablePartial<HomeSearchParams>;

export function useUpdateHomeSearchParamsMutation() {
  const history = useHistory();
  const searchParams = useHomeSearchParamsQuery();
  const [defaultLocation, setIsDefaultLocation] =
    useRecoilState(initialPlaceState);
  const isReBook = useReBookFeatureFlag();
  const handleHomeSearchParamsChange = useCallback(
    (searchParamsChangeSet: HomeSearchParamsChangeSet) => {
      if (isEmpty(searchParamsChangeSet)) {
        return;
      }

      const nextSearchParams = new URLSearchParams();
      const keys = keysOf(searchParams);

      for (const key of keys) {
        const prevParamValue = searchParams[key];
        const paramValueChangeSet = searchParamsChangeSet[key];

        // if a param was set to null, remove it
        if (paramValueChangeSet === null) {
          if (isReBook && key === "spaceType") {
            localStorage.removeItem(SPACE_TYPE_TAB_LOCALSTORAGE_KEY);
          }
          continue;
        }

        // if there is new change, apply it
        if (paramValueChangeSet) {
          if (key === "coordinates") {
            const coordinates = paramValueChangeSet as Coordinates;
            nextSearchParams.set(key, `${coordinates.lat},${coordinates.lng}`);
            // set default location to false if the new coordinates are different from initial placeId coordinates
            if (
              defaultLocation.data?.center[1] !== coordinates.lat ||
              defaultLocation.data?.center[0] !== coordinates.lng
            ) {
              setIsDefaultLocation((curVal) => ({
                ...curVal,
                isDefault: false,
              }));
            }
          } else if (key === "spaceType") {
            if (!isReBook) {
              localStorage.setItem(
                SPACE_TYPE_TAB_LOCALSTORAGE_KEY,
                paramValueChangeSet as string,
              );
            }
            nextSearchParams.set(key, `${paramValueChangeSet}`);
          } else {
            nextSearchParams.set(key, `${paramValueChangeSet}`);
          }
        } else if (prevParamValue) {
          // use previous value if there was one
          if (key === "coordinates") {
            const coordinates = prevParamValue as Coordinates;
            nextSearchParams.set(key, `${coordinates.lat},${coordinates.lng}`);
            // reset page to 1 if any other param is changed
          } else if (key === "page") {
            nextSearchParams.set(key, "1");
          } else if (
            key === "mapSearch" &&
            (Object.keys(searchParamsChangeSet).includes("coordinates") ||
              Object.keys(searchParamsChangeSet).includes("bbox") ||
              Object.keys(searchParamsChangeSet).includes("placeId"))
          ) {
            nextSearchParams.delete("mapSearch");
          } else {
            nextSearchParams.set(key, `${prevParamValue}`);
          }
        }
      }

      const qs = `?${nextSearchParams.toString()}`;
      if (isReBook) {
        history.replace({ search: `${qs}` });
      } else {
        history.replace(`/${qs}`);
      }
    },
    [
      defaultLocation.data?.center,
      history,
      isReBook,
      searchParams,
      setIsDefaultLocation,
    ],
  );

  return handleHomeSearchParamsChange;
}
