import { Spacer } from "components/spacer";
import { gql, useQuery } from "@apollo/client";
import { BudgetSkeleton } from "./components/budget_skeleton";
import { BudgetQuery, GroupBudget } from "core/graphql.generated";
import { useCallback } from "react";
import { OrganizationBudgetEmptyState } from "./organization_budget/components/organization_empty_states";
import { Divider } from "components/divider";
import { GroupBudgetsList } from "./group_budget/components/group_budgets_list";
import { Pressable, StyleSheet, View } from "react-native";
import { GroupBudgetsEmptyState } from "./group_budget/components/group_empty_states";
import { Button } from "components/button_v2";
import { useHistory } from "react-router-dom";
import { OrganizationBudgetOverviewCard } from "./organization_budget/components/organization_budget_overview_card";
import { useMediaQuery } from "lib/media_query";
import { AsyncRenderer } from "components/AsyncRenderer";

export function CurrentBudgets() {
  const { loading, data, error } = useQuery<BudgetQuery>(budgetQuery);
  const mq = useMediaQuery();
  const history = useHistory();

  const createGroupBudget = useCallback(() => {
    history.push("/admin/budgets/group-budget/new");
  }, [history]);

  return (
    <AsyncRenderer
      loading={loading}
      loadingHandler={() => <BudgetSkeleton />}
      error={error}
      data={data}
    >
      {(data) => {
        const { budget, groupBudgets, name } =
          data.currentUser?.organization || {};
        return budget ? (
          <>
            {!mq.deviceQuery.mobile && (
              <View style={styles.createBudgetButton}>
                <Button
                  onPress={createGroupBudget}
                  text="Create new budget"
                  testID="create-new-group-budget-button"
                  iconName="plus"
                  iconPosition="left"
                />
              </View>
            )}
            <Spacer size={24} />
            <Pressable
              onPress={() => history.push(`/admin/budgets/organization`)}
            >
              <OrganizationBudgetOverviewCard
                state={budget.state}
                orgName={name}
                monthlyLimit={budget.limit}
                totalSpent={budget.spend}
                remaining={budget.remaining}
                groupBudgets={groupBudgets}
                interactiveLegend={!mq.deviceQuery.mobile}
              />
            </Pressable>

            <Spacer size={24} direction="column" />
            <Divider color="lighter" />
            <Spacer size={24} direction="column" />

            {groupBudgets && groupBudgets.length > 0 ? (
              <GroupBudgetsList groupBudgets={groupBudgets as GroupBudget[]} />
            ) : (
              <GroupBudgetsEmptyState />
            )}
          </>
        ) : (
          <OrganizationBudgetEmptyState />
        );
      }}
    </AsyncRenderer>
  );
}

const styles = StyleSheet.create({
  createBudgetButton: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export const budgetQuery = gql`
  query Budget {
    currentUser {
      id
      role
      organization {
        id
        name
        budget {
          id
          state
          remaining
          spend
          limit
          individualLimit
        }
        groupBudgets {
          id
          status
          name
          description
          color
          spend
          remaining
          limit
        }
      }
    }
  }
`;
