import { StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";
import { colors } from "components/colors";
import { useMediaQuery } from "lib/media_query";
import { Button } from "components/button_v2";
import { useHistory } from "react-router-dom";

export function NoPolicy() {
  const mq = useMediaQuery();
  const history = useHistory();
  const handleClickCreate = () => {
    history.push("/admin/budgets/policy/new");
  };
  const content = (
    <View style={styles.content} testID={"no-budget-policies"}>
      <Text weight="semibold" size="md" color="black-70" align="center">
        No budget policies.
      </Text>
      <Spacer size={20} />
      <Text align="center">
        <TextLink
          onPress={handleClickCreate}
          decoration="none"
          text="Create a new policy"
          testID="create-budget-policy-link"
          size="xs"
          inline
          weight={"semibold"}
        />
        <Text color="black-70" size="xs">
          {" "}
          to set controls for where and how people can book spaces.
        </Text>
      </Text>
    </View>
  );

  const createPolicyBtn = (
    <Button
      text={"Create new policy"}
      iconName={"plus"}
      iconSize={"md"}
      onPress={handleClickCreate}
      testID="create-budget-policy-button"
    />
  );

  if (mq.deviceQuery.mobile) {
    return (
      <>
        {content}
        <Spacer size={16} />
        {createPolicyBtn}
      </>
    );
  }

  return (
    <>
      <View style={styles.btnPolicy}>{createPolicyBtn}</View>
      {content}
    </>
  );
}

const styles = StyleSheet.create({
  btnPolicy: {
    flexDirection: "row-reverse",
    marginBottom: 24,
  },
  content: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: colors.brand.blackMinus90,
    paddingHorizontal: 24,
    paddingVertical: 36,
    alignItems: "center",
  },
});
