import {
  addDays as dateFnsAddDays,
  addMonths as dateFnsAddMonths,
  format,
  isAfter as dateFnsIsAfter,
  isBefore as dateFnsIsBefore,
  isWithinInterval,
  parseISO,
  setDate,
  setMonth,
  setYear,
  startOfYear as dateFnsStartOfYear,
  startOfMonth as dateFnsStartOfMonth,
  endOfMonth as dateFnsEndOfMonth,
  subDays as dateFnsSubDays,
  subMonths as dateFnsSubMonths,
} from "date-fns";
import { format as formatTz, utcToZonedTime } from "date-fns-tz";

import { DateInterval } from "./date_utils";

export const DAY_FORMAT = "yyyy-MM-dd";

export type DayInterval = {
  start: Day;
  end: Day;
};

export function toDateInterval(interval: DayInterval): DateInterval {
  return {
    start: parseDay(interval.start),
    end: parseDay(interval.end),
  };
}

export function fromDateInterval(interval: DateInterval): DayInterval {
  return {
    start: toDay(interval.start),
    end: toDay(interval.end),
  };
}

export function getDays(interval: DayInterval, step = 1): Day[] {
  const endDate = interval.end;

  let currentDay = interval.start;

  const days: Day[] = [];

  while (isBefore(currentDay, endDate) || isSameDay(currentDay, endDate)) {
    days.push(currentDay);
    currentDay = addDays(currentDay, step);
  }

  return days;
}

/** Day in `yyyy-MM-dd` format */
export type Day = string;

export function setSameDay(date: Date, day: Day): Date {
  const newDate = parseDay(day);

  date = setYear(date, newDate.getFullYear());
  date = setMonth(date, newDate.getMonth());
  date = setDate(date, newDate.getDate());

  return date;
}

export function isWithinDayInterval(day: Day, interval: DayInterval): boolean {
  return isWithinInterval(parseDay(day), {
    start: parseDay(interval.start),
    end: parseDay(interval.end),
  });
}

export function today(): Day {
  return toDay(new Date());
}

export function isDay(value: unknown): value is Day {
  if (typeof value !== "string") {
    return false;
  }

  try {
    const date = parseDay(value);

    if (toDay(date) !== value) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}

export function isSameDay(dayLeft: Day, dayRight: Day): boolean {
  return dayLeft === dayRight;
}

export function isAfter(dayLeft: Day, dayRight: Day): boolean {
  return dateFnsIsAfter(parseDay(dayLeft), parseDay(dayRight));
}

export function isBefore(dayLeft: Day, dayRight: Day): boolean {
  return dateFnsIsBefore(parseDay(dayLeft), parseDay(dayRight));
}

export function addDays(day: Day, amount: number): Day {
  return toDay(dateFnsAddDays(parseDay(day), amount));
}

export function subDays(day: Day, amount: number): Day {
  return toDay(dateFnsSubDays(parseDay(day), amount));
}

export function addMonths(day: Day, amount: number): Day {
  return toDay(dateFnsAddMonths(parseDay(day), amount));
}

export function startOfYear(day: Day): Day {
  return toDay(dateFnsStartOfYear(parseDay(day)));
}

export function startOfMonth(day: Day): Day {
  return toDay(dateFnsStartOfMonth(parseDay(day)));
}

export function endOfMonth(day: Day): Day {
  return toDay(dateFnsEndOfMonth(parseDay(day)));
}

export function subMonths(day: Day, amount: number): Day {
  return toDay(dateFnsSubMonths(parseDay(day), amount));
}

export function parseDay(day: Day): Date {
  return parseISO(day);
}

export function toDay(date: Date): Day {
  return format(date, DAY_FORMAT);
}

export function isIntervalThisMonth(interval: DayInterval): boolean {
  if (!interval?.start || !interval?.end) {
    return false;
  }

  return (
    dateFnsStartOfMonth(new Date()).toDateString() ===
      parseDay(interval.start).toDateString() &&
    dateFnsEndOfMonth(new Date()).toDateString() ===
      parseDay(interval.end).toDateString()
  );
}

export function getLocalToday(timeZone: string) {
  const localTodayDate = utcToZonedTime(new Date(), timeZone);
  const localTodayDay = formatTz(localTodayDate, "yyyy-MM-dd", { timeZone });
  return localTodayDay;
}

/**
 * Substitutes "Today" and "Tomorrow" for normal dates
 */
export function formatDayWithTodayAndTomorrowLabel(
  day: string,
  today: string,
): string {
  if (isSameDay(day, today)) {
    return "Today";
  } else if (isSameDay(day, addDays(today, 1))) {
    return "Tomorrow";
  }

  return format(parseDay(day), "EEEE, do MMMM");
}
