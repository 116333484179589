import React from "react";
import { format } from "date-fns";
import { StyleSheet, View } from "react-native";
import {
  eachDayOfWeek,
  FirstDayOfWeek,
  getFirstDateOfWeekLocale,
} from "lib/week_utils";
import { Text } from "./text";

interface WeekDatesProps {
  firstDayOfWeek?: FirstDayOfWeek;
  week?: Date;
}

export function WeekDates(props: WeekDatesProps) {
  const { week = new Date(), firstDayOfWeek = getFirstDateOfWeekLocale() } =
    props;
  const dates = eachDayOfWeek(week, firstDayOfWeek);

  return (
    <View style={styles.weekDatesWrapper}>
      {dates.map((d) => (
        <View key={d.toISOString()} style={styles.dateWrapper}>
          <Text weight="700">{format(d, "EEEEE")}</Text>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  weekDatesWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  dateWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 1,
  },
});
