import { AdminHeader } from "components/admin_header";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Footer } from "core/footer";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";
import { PageLayout } from "pages/page_layout";
import { useMediaQuery } from "lib/media_query";
import { Tabs } from "components/tab/tabs_v2";
import { TabOption } from "components/tab/tabs";
import { BudgetsPolicy } from "./policy/budget_policy";
import { CurrentBudgets } from "./current_budgets";
import { BudgetTab, useBudgetTabs } from "./hooks/use_budget_tabs";
import { Fragment } from "react";

const tabNames: Record<BudgetTab, BudgetTab> = {
  current: "current",
  policy: "policy",
};
const budgetTabOptions: TabOption<BudgetTab>[] = [
  {
    label: "Current budgets",
    value: tabNames.current,
    testID: tabNames.current,
  },
  {
    label: "Budget policies",
    value: tabNames.policy,
    testID: tabNames.policy,
  },
];

export function BudgetsPage() {
  const mq = useMediaQuery();
  const [currentTab, updateCurrentTab] = useBudgetTabs();
  return (
    <PageLayout
      header={<AdminHeader />}
      body={
        <>
          <Spacer size={40} />
          <Heading size="lg">Budgets</Heading>

          <Fragment>
            <Spacer size={40} />
            <Tabs
              onChange={updateCurrentTab}
              options={budgetTabOptions}
              value={currentTab}
            />
            <Spacer size={24} />
          </Fragment>

          {currentTab === tabNames.current && <CurrentBudgets />}
          {currentTab === tabNames.policy && <BudgetsPolicy />}
        </>
      }
      footer={mq.deviceQuery.mobile ? <BottomBarAdminNavigation /> : <Footer />}
    />
  );
}
