import { Tabs } from "components/tab/tabs_v2";

export enum BookingTab {
  Upcoming = "Upcoming",
  Archive = "Archive",
}

export const tabOptions = [
  { label: BookingTab.Upcoming, value: BookingTab.Upcoming },
  {
    label: BookingTab.Archive,
    value: BookingTab.Archive,
    testID: "archive-booking-tab",
  },
];

interface BookingTabsProps {
  value: BookingTab;
  onChange: (value: BookingTab) => void;
}

export function BookingTabs({ value, onChange }: BookingTabsProps) {
  return <Tabs options={tabOptions} value={value} onChange={onChange} />;
}
