import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { DropdownV2 } from "components/dropdown_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { isFuture } from "date-fns";
import { Day } from "lib/day_utils";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { DatePicker } from "./date_picker";

export interface DatePickerChildrenType {
  isOpen: boolean;
  onClose: () => void;
  onToggleOpen: () => void;
  onOpen: () => void;
}

interface DatePickerDropdownProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
  children: (arg: DatePickerChildrenType) => ReactNode;
  // confirm mode is the mode the datepicker have save and clear button
  confirmMode?: boolean;
}

export function DatePickerDropdown(props: DatePickerDropdownProps) {
  const { value, onChange, children, confirmMode = true } = props;
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState<Day>(value!);
  useEffect(() => {
    setNewValue(value!);
  }, [value]);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClear = useCallback(() => {
    onChange("");
    setNewValue(value!);
    handleClose();
  }, [handleClose, onChange, value]);
  const handleSave = useCallback(() => {
    setOpen(false);
    onChange(newValue);
  }, [onChange, newValue]);

  const handleOnChange = useCallback(
    (newValue: Day) => {
      setNewValue(newValue);
      if (!confirmMode) {
        setOpen(false);
        onChange(newValue);
      }
    },
    [confirmMode, onChange],
  );

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      placement="bottom-end"
      sameWidth={false}
      offsetVertical={8}
      content={
        <View style={styles.dialogWrapper}>
          <View style={styles.dialogContent}>
            <DatePicker
              showPreviousIcon={(date) => isFuture(date)}
              value={newValue}
              onChange={handleOnChange}
            />
          </View>
          <Spacer size={8} />
          {confirmMode && (
            <View style={styles.dialogFooter}>
              <Pressable onPress={handleClear}>
                <Text color="eggplant-core" size="xs" weight="semibold">
                  Clear
                </Text>
              </Pressable>
              <Button
                text="Show results"
                onPress={handleSave}
                testID="date-picker-show-results-button"
              />
            </View>
          )}
        </View>
      }
    >
      {children({
        isOpen: open,
        onClose: handleClose,
        onToggleOpen: open ? handleClose : handleOpen,
        onOpen: handleOpen,
      })}
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dialogFooter: {
    padding: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogWrapper: {
    width: 387,
    ...tokens.shadow.elevation2,
    backgroundColor: tokens.colors.base.white,
    borderRadius: 4,
  },
  dialogContent: {
    flex: 1,
    padding: 24,
    paddingBottom: 0,
  },
  wrapper: {
    display: "flex",
  },
  button: {
    paddingVertical: 11,
    paddingLeft: 8,
    paddingRight: 8,
  },
  picker: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
