import { isFriday, isSameDay, isSaturday } from "date-fns";
import { toDay } from "lib/day_utils";
import { SelectDatePicker } from "pages/team_spaces/components/tabs/select_date_picker";
import { SelectDateTab } from "pages/team_spaces/components/tabs/select_date_tab";
import {
  useMyTeamSearchParamsMutation,
  useMyTeamSearchParamsQuery,
} from "pages/team_spaces/hooks/use_my_team_search_params";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

export enum DateTabType {
  Today,
  Tomorrow,
  NextMonday,
  SelectDate,
}

const getTomorrow = () => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() + 1));
};

const getNextMonday = () => {
  const today = new Date();
  const daysUntilNextMonday = 1 + 7 - today.getDay();
  return new Date(today.setDate(today.getDate() + daysUntilNextMonday));
};

export const getInitialTabFromDate = (date: string | undefined) => {
  if (!date) {
    return DateTabType.Today;
  }
  const dateInput = new Date(date);

  if (isSameDay(dateInput, new Date())) {
    return DateTabType.Today;
  } else if (isSameDay(dateInput, getTomorrow())) {
    return DateTabType.Tomorrow;
  } else if (isSameDay(dateInput, getNextMonday())) {
    return DateTabType.NextMonday;
  } else {
    return DateTabType.SelectDate;
  }
};

export function SelectDateTabs() {
  const today = new Date();

  const { date } = useMyTeamSearchParamsQuery();
  const [currentDateTabType, setCurrentDateTabType] = useState<DateTabType>(
    getInitialTabFromDate(date),
  );
  const teamsSearchParamsMutation = useMyTeamSearchParamsMutation();
  const onPress = (type: DateTabType) => {
    setCurrentDateTabType(type);
    switch (type) {
      case DateTabType.Today:
        teamsSearchParamsMutation({ date: toDay(today) });
        break;
      case DateTabType.Tomorrow:
        teamsSearchParamsMutation({
          date: toDay(getTomorrow()),
        });
        break;
      case DateTabType.NextMonday:
        teamsSearchParamsMutation({ date: toDay(getNextMonday()) });
    }
  };

  const hideTomorrowTab = isFriday(today) || isSaturday(today);

  return (
    <View style={styles.container}>
      <SelectDateTab
        text={"Today"}
        isPicked={currentDateTabType === DateTabType.Today}
        onPress={() => onPress(DateTabType.Today)}
      />
      {!hideTomorrowTab && (
        <SelectDateTab
          text={"Tomorrow"}
          isPicked={currentDateTabType === DateTabType.Tomorrow}
          onPress={() => onPress(DateTabType.Tomorrow)}
        />
      )}
      <SelectDateTab
        text={"Next Monday"}
        isPicked={currentDateTabType === DateTabType.NextMonday}
        onPress={() => onPress(DateTabType.NextMonday)}
      />
      <SelectDatePicker
        isPicked={currentDateTabType === DateTabType.SelectDate}
        onPress={() => onPress(DateTabType.SelectDate)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: 8,
  },
});
