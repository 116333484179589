import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Divider } from "components/divider";
import { DropdownV2 } from "components/dropdown_v2";
import { Icon } from "components/iconv2";
import { SelectButtonWithIconV1 } from "components/select/select_button_with_icon_v1";
import { SelectOption } from "components/select/select_option_header_variant";
import { useReBookFeatureFlag } from "providers/splitio";
import { Fragment, ReactNode, useCallback, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

interface SelectOptionType {
  label: ReactNode;
  selectedLabel: string;
  value: any;
}

interface SelectInputHeaderVariantProps {
  value: string | undefined;
  onChange: (value: any) => void;
  options: SelectOptionType[];
  contentWrapperStyle?: StyleProp<ViewStyle>;
}

export function SelectInputHeaderVariantV1(
  props: SelectInputHeaderVariantProps,
) {
  const { onChange, value, options, contentWrapperStyle } = props;
  const [open, setOpen] = useState(false);
  const rebookFeatureFlag = useReBookFeatureFlag();
  const handleSelect = useCallback(
    (option) => {
      const isAlreadySelected = value === option.value;

      setOpen(false);

      return isAlreadySelected ? onChange(undefined) : onChange(option.value);
    },
    [onChange, value],
  );
  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      content={
        <View style={[styles.dropdownContent, contentWrapperStyle]}>
          {options.map((option) => {
            const isSelected = value === option.value;

            return (
              <Fragment key={option.selectedLabel}>
                <SelectOption
                  selected={isSelected}
                  option={option}
                  testID={`select-option-${option.value}`}
                  onSelect={handleSelect}
                />
                {rebookFeatureFlag && <Divider />}
              </Fragment>
            );
          })}
          {!!selectedOption && rebookFeatureFlag && (
            <Button
              onPress={() => {
                onChange(undefined);
                setOpen(false);
              }}
              appearance="textLink"
              text="Clear"
              size="fit"
            />
          )}
        </View>
      }
    >
      <SelectButtonWithIconV1
        testID="header-space-type-picker"
        onPress={() => setOpen(!open)}
        title={selectedOption ? selectedOption.selectedLabel : "Any space type"}
        leftIcon={<Icon name="space-search" size="sm" />}
      />
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dropdownContent: {
    maxHeight: "50vh",
    overflow: "scroll",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: colors.brand.whitecore,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    width: 320,
  },
});
