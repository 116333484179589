import React, { useState } from "react";
import { ScrollView } from "react-native";

import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import { SpaceDetails__AllDetailsFragment } from "./graphql.generated";
import { RoomLayoutPicker } from "./room_layout_picker";

interface RoomLayoutEditProps {
  initialLayoutID?: string;
  space: SpaceDetails__AllDetailsFragment;
  onSave: (layoutID: string) => void;
  onCancel: () => void;
  maxCapacity?: number;
}

export function RoomLayoutEdit(props: RoomLayoutEditProps) {
  const { space, initialLayoutID, onCancel, onSave } = props;

  const [layoutID, setLayoutID] = useState(initialLayoutID);

  return (
    <Container flex={1}>
      <ScrollView>
        <Container padding={16}>
          <Text>
            Customize your seating arrangement to best fit your needs.{" "}
          </Text>
          <Spacer size={16} />
          <RoomLayoutPicker
            selectedLayoutID={layoutID}
            onSelect={setLayoutID}
            space={space}
          />
        </Container>
      </ScrollView>
      <Container
        borderColor={tokens.colors.neutral.darker}
        borderTopWidth={1}
        shadow
        paddingHorizontal={16}
        paddingVertical={8}
      >
        <Row spacing={8} justifyContent="flex-end">
          <Button
            appearance="secondary"
            onPress={() => onCancel()}
            text="Cancel"
          />
          <Button
            disabled={!layoutID}
            onPress={() => {
              if (layoutID) {
                onSave(layoutID);
              }
            }}
            text="Save"
          />
        </Row>
      </Container>
    </Container>
  );
}
