import { colors } from "components/colors";
import { StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { TextLink } from "components/text_link";
import { useMediaQuery } from "lib/media_query";
import { Button } from "components/button_v2";
import { useHistory } from "react-router-dom";

export function GroupBudgetsEmptyState() {
  const mq = useMediaQuery();
  const history = useHistory();
  return (
    <>
      <View style={styles.container}>
        <Text weight="semibold" size="md" color="black-70" align="center">
          No additional budgets.
        </Text>
        <Spacer size={20} />
        <Text align="center">
          <TextLink
            href="/admin/budgets/group-budget/new"
            decoration="none"
            text="Create a new budget"
            size="xs"
            inline
            weight={"semibold"}
          />
          <Text color="black-70" size="xs">
            {" "}
            to track organization spending by team, location, event, or any
            other expense category.
          </Text>
        </Text>
      </View>
      {mq.deviceQuery.mobile && <Spacer size={24} />}
      {mq.deviceQuery.mobile && (
        <Button
          testID="create-new-group-budget-button"
          onPress={() => history.push("/admin/budgets/group-budget/new")}
          text="Create new budget"
          iconName="plus"
          iconPosition="left"
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    paddingHorizontal: 24,
    paddingVertical: 36,
    alignItems: "center",
  },
});
