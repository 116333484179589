import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { StyleSheet, View } from "react-native";

interface CityCardProps {
  name: string;
  imageUrl: string;
}

export function CityCard(props: CityCardProps) {
  const { name, imageUrl } = props;

  return (
    <View style={[styles.cityCard, styles.shadow]}>
      <img
        alt={name}
        style={{
          objectFit: "cover",
        }}
        src={imageUrl}
      />
      <View style={styles.city}>
        <Text weight="semibold" size="xs">
          {name}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  cityCard: {
    flex: 1,
  },
  shadow: tokens.shadow.elevation1,
  city: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
  },
});
