import { useCallback, useMemo, useState } from "react";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Footer } from "core/footer";
import { useMediaQuery } from "lib/media_query";
import { PageContainer } from "components/page_container";
import { View } from "react-native";
import { colors } from "components/colors";

import {
  ChangeUserRoleMutation,
  ChangeUserRoleMutationVariables,
  DeletePersonMutation,
  DeletePersonMutationVariables,
  ManageUsersListQuery,
  ManageUsersListQueryVariables,
  ManageUsers__UserDetailsFragment,
  RevokeInviteMutation,
  RevokeInviteMutationVariables,
  UserRole,
} from "core/graphql.generated";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Avatar } from "components/avatar";
import { Button } from "components/button_v2";
import { InAppInvite } from "components/in_app_invite";
import { useToast } from "providers/toast";
import { Spinner } from "components/spinner";
import { useAnalytics } from "providers/analytics";
import { DataTableV2 } from "components/data_table/data_table_v2";
import { AdminHeader } from "components/admin_header";
import { BottomBarAdminNavigation } from "core/bottom_bar_admin_navigation";

export function ManageUsersPage() {
  return (
    <View>
      <ManageUsers />
    </View>
  );
}

const mobileCols = [
  {
    id: "name",
    title: "Name",
    width: "100%",
  },
];

const desktopCols = [
  {
    id: "name",
    title: "Name",
    width: "50%",
  },
  {
    id: "role",
    title: "Account type",
    width: "45%",
  },
];

interface renderNameProps {
  fullName?: string;
  picture?: string;
  email?: string;
}

const renderNameCell = ({ fullName, picture, email }: renderNameProps) => (
  <View style={{ flexDirection: "row", alignItems: "center" }}>
    <Avatar name={fullName || ""} source={{ uri: picture }} size="lg" />
    <Spacer direction="row" size={8} />
    <View>
      <Text
        testID="data-table-user-name"
        customColor={colors.brand.blackcore}
        size="xs"
      >
        {fullName}
      </Text>
      <Spacer size={4} />
      <Text
        testID="data-table-email"
        customColor={colors.brand.blackMinus30}
        size="xs"
      >
        {email}
      </Text>
    </View>
  </View>
);

const SORTABLE_BY = [
  { field: "name", label: "Name" },
  { field: "role", label: "Role" },
  { field: "email", label: "Email" },
];

const SEARCHABLE_BY = ["name", "email"];

function ManageUsers() {
  const mq = useMediaQuery();
  const toast = useToast();
  const analytics = useAnalytics();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const {
    data: manageUsersListQueryData,
    refetch: refetchManageUsersList,
    loading,
  } = useQuery<ManageUsersListQuery, ManageUsersListQueryVariables>(
    manageUsersListQuery,
  );
  const [revokeInvite] = useMutation<
    RevokeInviteMutation,
    RevokeInviteMutationVariables
  >(revokeInviteGQLMutation);
  const [changeUserRole] = useMutation<
    ChangeUserRoleMutation,
    ChangeUserRoleMutationVariables
  >(changeUserRoleGQLMutation);
  const [deletePerson] = useMutation<
    DeletePersonMutation,
    DeletePersonMutationVariables
  >(deletePersonGQLMutation);
  const currentUser = manageUsersListQueryData?.currentUser;
  const isAdmin = currentUser?.role === UserRole.Admin;
  const invitations =
    currentUser?.organization?.users
      .filter((user) => !user.userId)
      .map((user) => {
        const userInvite = currentUser?.organization?.invitations.find(
          (invite) => invite.inviteeEmail === user.email,
        );

        if (userInvite) {
          return {
            ...user,
            id: userInvite.id,
          };
        }

        return user;
      }) || []!;
  const users =
    currentUser?.organization?.users.filter((user) => !!user.userId) || []!;

  const onChangeUserRole = useCallback(
    async (person: ManageUsers__UserDetailsFragment) => {
      const res = await changeUserRole({
        variables: {
          personId: person.id,
          newRole:
            person.role === UserRole.Admin ? UserRole.Member : UserRole.Admin,
        },
      });

      if (res.data?.changeUserRole) {
        toast.notify({
          message: `${person?.fullName}’s admin access ${
            person.role === UserRole.Admin ? "revoked" : "granted"
          }`,
        });
        refetchManageUsersList();
        analytics.event(
          person.role === UserRole.Admin ? "Revoke Admin" : "Grant Admin",
          {
            "Organization UUID": currentUser?.organization?.id,
            "Person UUID": person.id,
          },
        );
      }
    },
    [
      analytics,
      changeUserRole,
      currentUser?.organization?.id,
      refetchManageUsersList,
      toast,
    ],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRemoveUser = useCallback(
    async (person: ManageUsers__UserDetailsFragment) => {
      try {
        analytics.event("Remove From Team", {
          "Organization UUID": currentUser?.organization?.id,
          "Person UUID": person.id,
        });
        await deletePerson({
          variables: {
            personId: person.id,
          },
        });

        toast.notify({
          message: `${person?.fullName} successfully removed
          from team`,
        });
      } catch (error) {
        console.error("Can not delete user: ", error);
      } finally {
        refetchManageUsersList();
      }
    },
    [
      analytics,
      currentUser?.organization?.id,
      deletePerson,
      refetchManageUsersList,
      toast,
    ],
  );

  const rows = useMemo(() => {
    const preparedUsers = users?.map((user) => ({
      id: user.id,
      name: {
        render: () =>
          renderNameCell({
            fullName: user?.fullName || "",
            picture: user?.picture || "",
            email: user?.email || "",
          }),
        value: user?.fullName,
      },
      role: user.role,
      contextMenu:
        currentUser?.id === user.userId
          ? undefined
          : [
              isAdmin && {
                testID:
                  user.role === UserRole.Admin
                    ? "user-revoke-admin"
                    : "user-grant-admin",
                label:
                  user.role === UserRole.Admin
                    ? "Revoke admin access"
                    : "Grant admin access",
                onClick: () => onChangeUserRole(user),
              },
            ],
      user,
      email: user?.email,
    }));
    const preparedInvitations = invitations.map((inv) => ({
      id: inv.id,
      name: {
        render: () =>
          renderNameCell({
            fullName: "Invited member",
            picture: "",
            email: inv.email || "",
          }),
        value: "Invited member",
      },
      email: inv.email,
      role: inv.role,
      contextMenu: [
        isAdmin && {
          label: "Revoke invitation",
          testID: "user-revoke-invitation",
          onClick: async () => {
            const res = await revokeInvite({ variables: { inviteID: inv.id } });
            if (res.data?.revokeInvite) {
              toast.notify({
                message: `Invitation to ${inv.email} have been revoked`,
              });
              refetchManageUsersList();
              analytics.event("Revoke Invitation", {
                "Organization UUID": currentUser.organization?.id,
                "Invite UUID": inv.id,
              });
            }
          },
        },
      ],
    }));

    return [...preparedUsers, ...preparedInvitations];
  }, [
    users,
    invitations,
    currentUser?.id,
    currentUser?.organization?.id,
    isAdmin,
    onChangeUserRole,
    revokeInvite,
    toast,
    refetchManageUsersList,
    analytics,
  ]);

  const content = (
    <View>
      <DataTableV2
        withContextMenu
        columns={mq.deviceQuery.mobile ? mobileCols : desktopCols}
        rows={rows}
        searchPlaceholder="Search by name or email"
        useServerSideFilters={false}
        sortableBy={mq.deviceQuery.mobile ? undefined : SORTABLE_BY}
        searchableBy={SEARCHABLE_BY}
        defaultSortBy="name"
      />
    </View>
  );

  return (
    <View>
      <AdminHeader />
      <PageContainer useNewBg>
        <Content>
          <Spacer size={16} />
          <Spacer size={27} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Heading size="lg">Users</Heading>
            <Button
              text="Invite members"
              testID="invite-members-manage-users-button"
              onPress={() => setIsInviteModalOpen(true)}
              iconName="userPlus"
            />
          </View>
          <Spacer size={24} />
          <Spacer size={16} />
          {loading ? <Spinner /> : content}
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <BottomBarAdminNavigation />
      <Footer />
      <InAppInvite
        isVisible={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        analyticsFrom="Team management"
        onSuccess={() => {
          refetchManageUsersList();
          setIsInviteModalOpen(false);
        }}
      />
    </View>
  );
}

const manageUsersListQuery = gql`
  query ManageUsersList {
    currentUser {
      id
      role
      organization {
        id
        name
        invitations(status: "pending") {
          id
          inviteeEmail
        }
        users {
          ...ManageUsers__UserDetails
        }
      }
    }
  }

  fragment ManageUsers__UserDetails on OrganizationUser {
    userId
    id
    role
    picture
    title
    email
    fullName
  }
`;

const revokeInviteGQLMutation = gql`
  mutation RevokeInvite($inviteID: String!) {
    revokeInvite(inviteID: $inviteID)
  }
`;

const changeUserRoleGQLMutation = gql`
  mutation ChangeUserRole($personId: String!, $newRole: String!) {
    changeUserRole(personId: $personId, newRole: $newRole)
  }
`;

const deletePersonGQLMutation = gql`
  mutation DeletePerson($personId: String!) {
    deletePerson(personId: $personId)
  }
`;
