import { Pressable, StyleSheet, View } from "react-native";

import { Text } from "components/text_v2";
import { DatePickerScrollable } from "components/date_picker/date_picker_scrollable";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { HomeSearchParamsChangeSet } from "pages/homev2/hooks/use_home_search_params";

interface DatePickerMobileProps {
  value?: string;
  onChange: (value: HomeSearchParamsChangeSet) => void;
  onShowResults: () => void;
}

export function DatePickerMobile({
  value,
  onChange,
  onShowResults,
}: DatePickerMobileProps) {
  const handleDateChange = (value: string | undefined) => {
    onChange({ date: value });
  };

  const handleShowResultsForAllDates = () => {
    handleDateChange(undefined);
    onShowResults();
  };

  return (
    <View style={styles.dialogContent}>
      <DatePickerScrollable value={value} onChange={handleDateChange} />
      <View style={styles.dialogFooter}>
        {value ? (
          <>
            <Button text="Show results" onPress={onShowResults} />
            <Pressable onPress={() => handleDateChange(undefined)}>
              <Text color="eggplant-core" size="xs" weight="semibold">
                Clear
              </Text>
            </Pressable>
          </>
        ) : (
          <Button
            text="Show results for all dates"
            onPress={handleShowResultsForAllDates}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  dialogFooter: {
    padding: 16,
    borderTopColor: colors.brand.blackMinus90,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContent: {
    flexShrink: 1,
    minHeight: 0,
    overflow: "hidden",
  },
});
