import { OnboardingHeader } from "core/onboarding_header";
import { Footer } from "core/new_footer";
import { AuthPageContainer } from "components/auth_page_container";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { SignUpGcalStepGetStarted } from "./components/step_get_started";
import { SignUpGcalStepTeam } from "./components/step_team";
import { SignUpProgressBar } from "components/sign_up_shared/progress_bar";
import { useCallback } from "react";
import { SignUpGcalStepFinal } from "./components/step_final";
import { ProtectedRoute } from "../../protected_route";

const STEPS_NEW_USER = [
  {
    path: "/sign-up-gcal/get-started",
    component: SignUpGcalStepGetStarted,
  },
  {
    path: "/sign-up-gcal/team",
    component: SignUpGcalStepTeam,
  },
  {
    path: "/sign-up-gcal/final",
    component: SignUpGcalStepFinal,
  },
];

export function SignUpGcalPage() {
  const history = useHistory();
  useLocation();

  const goNext = useCallback(() => {
    const path = history.location.pathname;
    const currentStepIndex = STEPS_NEW_USER.findIndex(
      (step) => step.path === path,
    );

    history.push(
      currentStepIndex < STEPS_NEW_USER.length - 1
        ? STEPS_NEW_USER[currentStepIndex + 1].path
        : "/",
    );
  }, [history]);

  const goStep = useCallback(
    (index) => {
      history.push(STEPS_NEW_USER[index].path);
    },
    [history],
  );

  const path = history.location.pathname;
  const currentStepIndex = STEPS_NEW_USER.findIndex(
    (step) => step.path === path,
  );
  const progress =
    currentStepIndex >= 0 ? currentStepIndex / (STEPS_NEW_USER.length - 1) : 0;

  return (
    <AuthPageContainer
      header={<OnboardingHeader isLogoPressable={false} />}
      footer={<Footer />}
      progressBar={<SignUpProgressBar progress={progress} />}
    >
      <Switch>
        {STEPS_NEW_USER.map((step, index) => {
          const { component: Component, ...data } = step;

          // from team name step forward, user should have been logged in
          if (index >= 1) {
            return (
              <ProtectedRoute key={data.path} path={data.path}>
                <Component onGoNext={goNext} onGoStep={goStep} />
              </ProtectedRoute>
            );
          }

          return (
            <Route key={data.path} path={data.path}>
              <Component onGoNext={goNext} onGoStep={goStep} />
            </Route>
          );
        })}

        <Redirect to={STEPS_NEW_USER[0].path} />
      </Switch>
    </AuthPageContainer>
  );
}
