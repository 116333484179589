import { CardElement, useElements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useCallback } from "react";
import { ActivityIndicator } from "react-native";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Dialog } from "components/dialog";
import { Heading } from "components/heading";

import { Image } from "components/image";
import { PressableText } from "components/pressable_text";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { useForm } from "hooks/use_form";
import { useMediaQuery } from "lib/media_query";
import { CreditCardInput } from "./credit_card_input";
import { DialogContent } from "./dialog_content";
import {
  BookingPaymentMethodQuery,
  BookingPaymentMethodQueryVariables,
  CardPaymentMethod,
  CheckOutPaymentDetailsQuery,
  DetachPaymentMethodMutation,
  DetachPaymentMethodMutationVariables,
  PaymentMethod,
  SetupPaymentMethodUpdateMutation,
  SetupPaymentMethodUpdateMutationVariables,
} from "./graphql.generated";

interface CheckoutPaymentDetailsProps {
  stripe: Stripe | null;
  onChange?: (complete: boolean, error?: string) => void;
  paymentCompleteError?: string;
}

export function CheckoutPaymentDetails(props: CheckoutPaymentDetailsProps) {
  const { stripe, onChange, paymentCompleteError } = props;

  const { data: paymentMethodQuery, loading } = useQuery<
    BookingPaymentMethodQuery,
    BookingPaymentMethodQueryVariables
  >(getUserPaymentMethodGQLQuery);

  const paymentMethod = paymentMethodQuery?.paymentMethod;

  if (loading) {
    return (
      <Container>
        <ActivityIndicator size="large" color="rgba(82,68,134,1)" />
      </Container>
    );
  }

  return (
    <Container>
      {!paymentMethod && (
        <Container>
          <Heading size="h3">Payment details</Heading>
          <Spacer size={16} />
          <CreditCardInput
            invalid={!!paymentCompleteError}
            invalidText={paymentCompleteError}
            onChange={onChange}
          />
        </Container>
      )}
      {paymentMethod && (
        <ExistingPaymentDetails paymentMethod={paymentMethod} stripe={stripe} />
      )}
    </Container>
  );
}

interface PaymentMethodDetailsViewProps {
  paymentMethod: CardPaymentMethod;
}

export function PaymentMethodDetailsView(props: PaymentMethodDetailsViewProps) {
  const { paymentMethod } = props;

  return (
    <Row alignItems="center">
      <Image
        source={{
          uri: paymentMethod.networkLogoUrl,
          width: 38,
          height: 24,
        }}
      />
      <Spacer size={13} />
      <Text testID="credit-card-info">Ending with {paymentMethod.last4}</Text>
    </Row>
  );
}

interface ExistingPaymentDetailsProps {
  paymentMethod: PaymentMethod;
  stripe: Stripe | null;
}

export function ExistingPaymentDetails(props: ExistingPaymentDetailsProps) {
  const { paymentMethod, stripe } = props;

  const [updatePaymentFormVisible, setUpdatePaymentFormVisible] =
    React.useState(false);

  const currentPaymentMethod = paymentMethod;
  const currentPaymentMethodId = paymentMethod.id;
  const { disabled, submit, submitting, errors, onCreditCardChange } =
    useUpdatePaymentMethod({
      stripe: stripe,
      onComplete: () => setUpdatePaymentFormVisible(false),
      currentPaymentMethodId: currentPaymentMethodId || "",
    });
  const mq = useMediaQuery();
  const { smAndDown } = mq.sizeQuery;

  if (!currentPaymentMethod) {
    return null;
  }

  return (
    <Container>
      <Row alignItems="center" justifyContent="space-between">
        <Heading size="h3">Payment details</Heading>
        <PressableText
          size="xs"
          content="Change"
          onPress={() => setUpdatePaymentFormVisible(true)}
        />
      </Row>
      <Spacer size={8} />
      <PaymentMethodDetailsView paymentMethod={currentPaymentMethod} />
      <Dialog
        animationType="slide"
        visible={updatePaymentFormVisible}
        onRequestClose={() => setUpdatePaymentFormVisible(false)}
        mobileOffsetBottom={200}
      >
        <DialogContent
          headerLeftIcon="x-circle"
          onHeaderLeftIconPress={() => setUpdatePaymentFormVisible(false)}
          borderTopLeftRadius={smAndDown ? 16 : 8}
          borderTopRightRadius={smAndDown ? 16 : 8}
        >
          <Container paddingHorizontal={14} paddingBottom={16}>
            <Heading size="h3">Payment details</Heading>
            <Spacer size={13} />
            <CreditCardInput
              invalid={!!errors.paymentComplete}
              invalidText={errors.paymentComplete}
              onChange={onCreditCardChange}
            />
            <Spacer size={13} />
            <Button
              text="Save"
              onPress={submit}
              loading={submitting}
              disabled={disabled}
            />
          </Container>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export interface UseUpdatePaymentMethodProps {
  stripe: Stripe | null;
  onComplete: () => void;
  currentPaymentMethodId: string;
}

export interface SetupPaymentMethodUpdateInput {
  paymentComplete: boolean;
}

export function useUpdatePaymentMethod(props: UseUpdatePaymentMethodProps) {
  const { stripe, onComplete, currentPaymentMethodId } = props;
  const elements = useElements();
  const savePaymentMethod = useSavePaymentMethod();
  const apolloClient = useApolloClient();

  const {
    setFieldValue,
    setFieldError,
    values,
    handleChange,
    setFieldValues,
    setErrors,
    submit,
    submitting,
    errors,
    changed,
    submitCount,
  } = useForm<
    SetupPaymentMethodUpdateInput,
    keyof SetupPaymentMethodUpdateInput
  >({
    initialValues: {
      paymentComplete: false,
    },
    onSubmit: async (_values, { setStatus: _setStatus }) => {
      if (!stripe || !elements) {
        _setStatus("Credit card processor not loaded");
        return;
      }

      try {
        await savePaymentMethod(stripe, currentPaymentMethodId);
        // re-fetching update-to-date payment method
        apolloClient.refetchQueries({
          include: "active",
        });

        // close dialog
        onComplete();
      } catch (error) {
        _setStatus("Could not update payment details. Please try again later.");
      }
    },
  });

  const handleCreditCardChange = useCallback(
    (complete: boolean, error?: string) => {
      if (complete) {
        setFieldValue("paymentComplete", true);
      } else {
        setFieldValue("paymentComplete", false);
      }

      if (error) {
        setFieldError("paymentComplete", error);
      } else {
        setFieldError("paymentComplete", undefined);
      }
    },
    [setFieldError, setFieldValue],
  );

  const disabled = !values.paymentComplete;

  return {
    handleChange,
    onCreditCardChange: handleCreditCardChange,
    setFieldValue,
    setFieldValues,
    setFieldError,
    setErrors,
    submit,
    disabled,
    submitting,
    values,
    errors,
    changed,
    submitCount,
  };
}

export function useSavePaymentMethod() {
  const { data: checkoutPaymentDetailsQuery } =
    useQuery<CheckOutPaymentDetailsQuery>(checkoutPaymentGQLQuery);
  const currentUser = checkoutPaymentDetailsQuery?.currentUser;
  const billingDetailsName = (currentUser && currentUser.fullName) || "";
  const elements = useElements();

  const [setupPaymentMethodUpdate] = useMutation<
    SetupPaymentMethodUpdateMutation,
    SetupPaymentMethodUpdateMutationVariables
  >(setupPaymentMethodUpdateGQLMutation);

  const [detachPaymentMethod] = useMutation<
    DetachPaymentMethodMutation,
    DetachPaymentMethodMutationVariables
  >(detachPaymentMethodGQLMutation);

  return useCallback(
    async (stripe: Stripe, currentPaymentMethodId?: string | null) => {
      if (!elements) {
        throw new Error("Credit card processor not loaded");
      }

      const card = elements.getElement(CardElement);

      if (!card) {
        throw new Error("Credit card processor not loaded");
      }

      const { data } = await setupPaymentMethodUpdate();

      if (!data?.setupPaymentMethodUpdate.stripeClientSecret) {
        return;
      }

      // Add new payment method
      const result = await stripe.confirmCardSetup(
        data?.setupPaymentMethodUpdate.stripeClientSecret,
        {
          payment_method: {
            card,
            billing_details: {
              name: billingDetailsName,
            },
          },
        },
      );

      if (result.error) {
        console.error(result.error);
        throw new Error("Update payment detail unsuccessful");
      } else if (result.setupIntent) {
        if (
          result.setupIntent.status === "succeeded" &&
          currentPaymentMethodId
        ) {
          await detachPaymentMethod({
            variables: { input: { paymentMethodID: currentPaymentMethodId } },
          });
        }
      }
    },
    [
      billingDetailsName,
      elements,
      setupPaymentMethodUpdate,
      detachPaymentMethod,
    ],
  );
}

// Access token required in header authorization
const detachPaymentMethodGQLMutation = gql`
  mutation DetachPaymentMethod($input: DetachPaymentMethodInput!) {
    detachPaymentMethod(input: $input) {
      status
    }
  }
`;

const setupPaymentMethodUpdateGQLMutation = gql`
  mutation SetupPaymentMethodUpdate {
    setupPaymentMethodUpdate {
      customerID
      stripeClientSecret
    }
  }
`;

const getUserPaymentMethodGQLQuery = gql`
  query BookingPaymentMethod {
    paymentMethod {
      ... on CardPaymentMethod {
        id
        last4
        network
        networkLogoUrl
      }
    }
  }
`;

const checkoutPaymentGQLQuery = gql`
  query CheckOutPaymentDetails {
    currentUser {
      id
      fullName
    }
  }
`;
