import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { OffsiteSpacePage__SpaceDetailsV2Fragment } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";

interface SpaceAttributesProps {
  space: OffsiteSpacePage__SpaceDetailsV2Fragment;
  physicalSpaceID?: string;
}

export function SpaceAttributes(props: SpaceAttributesProps) {
  const { space, physicalSpaceID } = props;
  const physicalSpace = space.physicalSpaces.find(
    (ps) => ps.id === physicalSpaceID,
  );
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  const spacerSize = isMobile ? 16 : 24;
  const shouldShowMaxCapacity = space.maxCapacity > 1;
  const shouldShowSpaceArea = space.area;
  const shouldShowPhysicalSpace = physicalSpace;
  const shouldShowPhysicalSpaceWithWindow = physicalSpace?.withWindow;

  return (
    <>
      {(shouldShowMaxCapacity ||
        shouldShowSpaceArea ||
        shouldShowPhysicalSpace ||
        shouldShowPhysicalSpaceWithWindow) && <Spacer size={spacerSize} />}
      <Row>
        {shouldShowMaxCapacity && (
          <Text size="xs" color="black-70">
            {space.maxCapacity > 1 ? `${space.maxCapacity} Person (max)` : ""}
          </Text>
        )}
        {shouldShowSpaceArea && (
          <Text
            size="xs"
            color="black-70"
          >{` • Approx. ${space.area} square ft.`}</Text>
        )}
        {shouldShowPhysicalSpace && (
          <Text
            size="xs"
            color="black-70"
          >{` • Office ${physicalSpace?.name}`}</Text>
        )}
        {shouldShowPhysicalSpaceWithWindow && (
          <Text size="xs" color="black-70">
            {" • With window"}
          </Text>
        )}
      </Row>
    </>
  );
}
