import React from "react";
import { Pressable } from "react-native";

import { Icon, IconName } from "./icon";
import { InputContainer } from "./input_container";
import { Text } from "./text";

interface PickerButtonProps {
  label?: string;
  title?: string;
  testID?: string;
  invalidText?: string;
  description?: string;
  onPress?: () => void;
  disabled?: boolean;
  invalid?: boolean;
  iconLeft?: IconName;
}

export function PickerButton(props: PickerButtonProps) {
  const {
    testID,
    title,
    description,
    invalidText,
    disabled,
    label,
    invalid,
    onPress,
    iconLeft,
  } = props;

  return (
    <Pressable disabled={disabled} testID={testID} onPress={onPress}>
      <InputContainer
        hasLeftIcon={!!iconLeft}
        hasValue={!!title}
        label={label}
        invalid={invalid}
        invalidText={invalidText}
        description={description}
      >
        {iconLeft && (
          <div className="picker-icon-wrapper">
            <Icon size="sm" name={iconLeft} />
          </div>
        )}
        <div className={`picker-root ${iconLeft ? "picker-root-icon" : ""}`}>
          <Text
            numberOfLines={1}
            size="sm"
            color={invalid ? "error" : disabled ? "muted" : "default"}
          >
            {title}
          </Text>
        </div>
      </InputContainer>
    </Pressable>
  );
}
