import { colors } from "components/colors";
import { DropdownV2 } from "components/dropdown_v2";
import { SelectInputOption } from "components/select/select_input_option";
import { Fragment, useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";
import { FilterChip } from "./filter_chip";
import { SpacesSort } from "core/graphql.generated";

export interface SortOption {
  label: string;
  value: SpacesSort;
}

export const sortOptions: SortOption[] = [
  { label: "Distance", value: SpacesSort.Distance },
  { label: "Price (low to high)", value: SpacesSort.PriceAsc },
  {
    label: "Price (high to low)",
    value: SpacesSort.PriceDesc,
  },
];

export function SpaceSortPicker() {
  const [open, setOpen] = useState(false);
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const { sort } = useHomeSearchParamsQuery();
  const handleChangeSort = useCallback(
    (sortOption: SortOption) => {
      updateHomeSearchParams({
        sort: sortOption.value,
      });
    },
    [updateHomeSearchParams],
  );
  const selectedOption = useMemo(
    () => sortOptions.find((opt) => opt.value === sort),
    [sort],
  );

  return (
    <DropdownV2
      open={open}
      onRequestClose={() => setOpen(false)}
      content={
        <View style={styles.dropdownContent}>
          {sortOptions.map((option) => {
            const isSelected = selectedOption?.label === option.label;

            return (
              <Fragment key={option.label}>
                <SelectInputOption
                  selected={isSelected}
                  option={option}
                  testID={`select-option-${option.value}`}
                  onSelect={() => {
                    handleChangeSort(option);
                    setOpen(false);
                  }}
                  labelProp="label"
                />
              </Fragment>
            );
          })}
        </View>
      }
    >
      <FilterChip
        title={
          selectedOption?.label
            ? `Sort by: ${selectedOption?.label}`
            : `Sort By: ${SpacesSort.Distance}`
        }
        onPress={() => setOpen(true)}
        isContentOpen={open}
        testID="space-sort-picker"
      />
    </DropdownV2>
  );
}

const styles = StyleSheet.create({
  dropdownContent: {
    marginTop: 8,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: colors.brand.whitecore,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    width: 200,
  },
});
