import { BookingDetails__OrderItemDetailsFragment } from "core/graphql.generated";

export function getBookingDetailDateTime(
  orderItem: BookingDetails__OrderItemDetailsFragment,
): {
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
} {
  if (orderItem.details.__typename === "DailyBooking") {
    const { startDate, endDate } = orderItem.details;
    return { startDate, endDate };
  } else {
    const { date, startTime, endTime } = orderItem.details;
    return { startDate: date, endDate: date, startTime, endTime };
  }
}
