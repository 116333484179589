import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { Tooltip } from "components/tooltip";
import { useMediaQuery } from "lib/media_query";
import { Icon } from "components/icon";
import { colors } from "components/colors";

interface ReportingPayAsYouGoOverviewProps {
  totalSpend: number;
}

export function ReportingPayAsYouGoOverview(
  props: ReportingPayAsYouGoOverviewProps,
) {
  const { totalSpend } = props;
  const mq = useMediaQuery();

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Tooltip
          position={mq.sizeQuery.mdAndUp ? "top-right" : "bottom-left"}
          offset={18}
          content={
            <View style={styles.tooltip}>
              <Text color="black-50" size="sm">
                Updated just now
              </Text>
            </View>
          }
        >
          <Icon name="info" color={colors.brand.blackMinus30} />
        </Tooltip>
      </View>
      <View style={styles.numberWrapper}>
        <View style={styles.numberContainer}>
          <Text size="mega" weight="bold">
            {formatCurrencyWithoutDecimal(totalSpend, getSystemLocale(), "USD")}
          </Text>
          <Text>Total Spend</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: "white",
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    ...tokens.shadow.elevation1,
  },
  numberWrapper: {
    flexDirection: "row",
    flexGrow: 1,
  },
  numberContainer: {
    padding: 14,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tooltip: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "rgba(6, 0, 30, 0.1)",
    padding: 8,
    borderRadius: 6,
    minWidth: 150,
    ...tokens.shadow.elevation1,
  },
  header: {
    alignSelf: "stretch",
    alignItems: "flex-end",
  },
});
