import { GridV2 } from "components/gridv2";
import { LinkWrapper } from "components/link_wrapper";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { TitleSpaceList } from "pages/homev3/components/title_space_list";
import { useAnalytics } from "providers/analytics";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useUserType } from "../hooks/use_user_type";

const cities = [
  {
    id: 1,
    name: "New York",
    image: "/images/new_york.png",
    url: "/search?placeId=place.233720044&coordinates=40.7306%2C-73.9866&bbox=-74.259639922%2C40.477399%2C-73.700272%2C40.917577",
  },
  {
    id: 2,
    name: "Los Angeles",
    image: "/images/los_angeles.png",
    url: "/search?placeId=place.192407788&coordinates=34.053691%2C-118.242766&bbox=-118.52144%2C33.899991%2C-118.126728%2C34.161439",
  },
  {
    id: 3,
    name: "Miami",
    image: "/images/miami.png",
    url: "/search?placeId=place.210733292&coordinates=25.774173%2C-80.19362&bbox=-80.8736%2C25.515125%2C-80.144468%2C25.979434",
  },
  {
    id: 4,
    name: "San Francisco",
    image: "/images/san_francisco.png",
    url: "/search?placeId=place.292358380&coordinates=37.779026%2C-122.419906&bbox=-122.544637459%2C37.670768986%2C-122.301070095%2C37.866488313",
  },
  {
    id: 5,
    name: "Chicago",
    image: "/images/chicago.png",
    url: "/search?placeId=place.59328748&coordinates=41.875562%2C-87.624421&bbox=-87.869226%2C41.644286%2C-87.509753018%2C42.034895347",
  },
  {
    id: 6,
    name: "Indianapolis",
    image: "/images/indianapolis.png",
    url: "/search?placeId=place.158779628&coordinates=39.768333%2C-86.15835&bbox=-86.37058534%2C39.60068%2C-85.933333%2C39.941865",
  },
];

export function ExploreWorkSpaceByCity() {
  const mq = useMediaQuery();
  const analytics = useAnalytics();
  const userType = useUserType();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <View>
      <TitleSpaceList
        title={"Explore workspaces by city"}
        subtitle={"Cities where you can find our flexible workspaces"}
      />
      <Spacer size={isMobile ? 16 : 24} />
      <GridV2
        columns={isMobile ? 2 : 3}
        columnGap={isMobile ? 16 : 24}
        rowGap={isMobile ? 16 : 24}
      >
        {cities.map((city) => (
          <LinkWrapper
            onPress={() => {
              analytics.event("Click Explore City", {
                Source: userType,
                category: "Rebooking",
                City: city.name,
              });
            }}
            to={city.url}
            key={city.id}
          >
            <View style={styles.cardView}>
              <img
                src={city.image}
                style={{
                  width: "100%",
                  height: isMobile ? "115px" : "144px",
                  objectFit: "cover",
                }}
                alt={city.name}
              />
              <View style={styles.cityName}>
                <Text size={"xs"} weight={"semibold"} align={"left"}>
                  {city.name}
                </Text>
              </View>
            </View>
          </LinkWrapper>
        ))}
      </GridV2>
    </View>
  );
}

const styles = StyleSheet.create({
  containerMobile: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: 16,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: 24,
  },

  cardView: {
    flexDirection: "column",
    borderRadius: 4,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  cityName: {
    padding: 16,
  },
});
