import { gql, useQuery } from "@apollo/client";
import {
  GroupBudgetRole,
  HookUserRoleQuery,
  UserRole,
} from "core/graphql.generated";

export const useUserRoleQuery = () => {
  const { loading, error, data } = useQuery<HookUserRoleQuery>(userRoleQuery);

  return {
    error,
    loading,
    isAdmin: data?.currentUser?.role === UserRole.Admin,
    isManager: data?.currentUser?.groupBudgetRole === GroupBudgetRole.Manager,
  };
};

const userRoleQuery = gql`
  query HookUserRole {
    currentUser {
      role
      groupBudgetRole
    }
  }
`;
