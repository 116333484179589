import { useQuery } from "@apollo/client";
import { Spacer } from "components/spacer";
import { SpaceCardCurrentUserCountryQuery } from "core/graphql.generated";
import {
  SpaceCardAddress,
  spaceCardCurrentUserCountryGQLQuery,
} from "pages/homev2/components/space_card_v2/space_card_template";
import { getDistanceText } from "pages/homev2/utils/get_distance_text";
import React from "react";
import { View } from "react-native";

export function LocationCardAddressWithDistance({
  currentUserDistance,
  shortAddress,
  locationName,
}: {
  currentUserDistance: number | undefined | null;
  shortAddress: string;
  locationName?: string;
}) {
  const { data: currentUser } = useQuery<SpaceCardCurrentUserCountryQuery>(
    spaceCardCurrentUserCountryGQLQuery,
  );
  const distanceText = getDistanceText(
    currentUserDistance,
    currentUser?.currentUser?.ipPlace?.country,
  );

  const subTitle = distanceText
    ? `${shortAddress} · ${distanceText}. away`
    : `${shortAddress}`;

  return (
    <View>
      {!!locationName && <SpaceCardAddress>{locationName}</SpaceCardAddress>}
      <Spacer size={4} />
      <SpaceCardAddress>{subTitle}</SpaceCardAddress>
    </View>
  );
}
