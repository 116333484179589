import React, { Fragment } from "react";
import ContentLoader, { Rect } from "react-content-loader/native";

export function HomeSkeletonDesktop() {
  return (
    <Fragment>
      <ContentLoader
        speed={2}
        width="100%"
        height={328}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="328px" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width="100%"
        height={328}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="328px" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width="100%"
        height={328}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="328px" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width="100%"
        height={328}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="328px" />
      </ContentLoader>
    </Fragment>
  );
}
