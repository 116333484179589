import { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

interface ChildrenPropTypes {
  closePrompt: () => void;
  confirm: () => void;
}

export const HistoryNavigationBlocker = ({
  active,
  children,
}: {
  active: boolean;
  children: (args: ChildrenPropTypes) => ReactElement;
}) => {
  const [attemptedNextPathnmae, setAttemptedNextPathname] = useState("");
  const [attemptedAction, setAttemptedAction] = useState("");

  const unblock = useRef<() => void>();
  const history = useHistory();

  useEffect(() => {
    if (active) {
      unblock.current?.();

      unblock.current = history.block((location, action) => {
        setAttemptedNextPathname(location.pathname);
        setAttemptedAction(action);
        return false;
      });
    }

    return () => {
      unblock.current?.();
    };
  }, [active, history]);

  return attemptedNextPathnmae
    ? children({
        closePrompt: () => setAttemptedNextPathname(""),
        confirm: () => {
          unblock.current?.();

          switch (attemptedAction) {
            case "POP": {
              history.goBack();
              break;
            }
            case "PUSH": {
              history.push(attemptedNextPathnmae);
              break;
            }
            case "REPLACE": {
              history.replace(attemptedNextPathnmae);
              break;
            }
            default: {
              history.push(attemptedNextPathnmae);
            }
          }
        },
      })
    : null;
};
