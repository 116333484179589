import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { EmptyData } from "components/empty_data";
import { toDay } from "lib/day_utils";
import { Card } from "components/card";
import { colors } from "components/colors";

import { DateTabType, getInitialTabFromDate } from "./tabs/select_date_tabs";
import { useMyTeamSearchParamsQuery } from "../hooks/use_my_team_search_params";

export function BeFirstOneToBook() {
  const history = useHistory();

  const { date } = useMyTeamSearchParamsQuery();

  const tabType = getInitialTabFromDate(date);
  let tab = "Today";
  if (tabType === DateTabType.Today) {
    tab = "Today";
  } else if (tabType === DateTabType.Tomorrow) {
    tab = "Tomorrow";
  } else if (tabType === DateTabType.NextMonday) {
    tab = "Next Monday";
  } else if (date) {
    tab = `on ${format(parseISO(date), "MMM dd, yyyy")}`;
  }

  const handleClickFindSpace = () => {
    history.push(`/search?date=${date || toDay(new Date())}`);
  };

  return (
    <Card backgroundColor={colors.brand.eggplantMinus90}>
      <EmptyData
        title={`Be the first one to book ${tab}`}
        description="Your coworkers haven’t booked a space yet."
        buttonText="Find a space"
        onClick={handleClickFindSpace}
      />
    </Card>
  );
}
