import { MenuCard } from "pages/user_profile/components/menu_card";
import { MenuCardDivider } from "pages/user_profile/components/menu_card_list";
import { currentUserOrganizationNameQuery } from "pages/organization_profile/organization_profile";
import { CurrentUserOrganizationNameQuery } from "core/graphql.generated";
import { useQuery } from "@apollo/client";

import { View } from "react-native";
import { OrganizationMobileSkeleton } from "./organization_mobile_skeleton";
import { useCustomSSOFeatureFlag } from "providers/splitio";
import { isCommonEmailProvider } from "../is_common_email_provider";

export function MobileMenu() {
  const { data: currentUserQuery, loading: userDataLoading } =
    useQuery<CurrentUserOrganizationNameQuery>(
      currentUserOrganizationNameQuery,
    );

  const customSSOFeatureFlag = useCustomSSOFeatureFlag();

  if (userDataLoading) {
    return <OrganizationMobileSkeleton />;
  }

  if (!currentUserQuery?.currentUser?.organization) {
    return null;
  }

  const { currentUser } = currentUserQuery;
  const { organization } = currentUser;
  const { requireSSO, ssoProvider, slug } = organization || {};
  const isSSORequiredOrg = requireSSO && ssoProvider && slug;
  const showDomainMenu =
    currentUser?.email && !isCommonEmailProvider(currentUser?.email);
  const showAuthMenu = customSSOFeatureFlag && isSSORequiredOrg;

  return (
    <View>
      <MenuCard
        title="Organization profile"
        icon="org"
        to="/admin/organization-profile"
      />
      <MenuCardDivider />
      <MenuCard
        title="Plan details"
        icon="plan-detail"
        to={"/admin/plan-details"}
      />
      <MenuCardDivider />
      {(showAuthMenu || showDomainMenu) && (
        <MenuCard
          title={showAuthMenu ? "Authentication" : "Domain settings"}
          icon={"sso"}
          to={"/admin/sso"}
        />
      )}
    </View>
  );
}
