import React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "components/divider";
import { Heading } from "components/heading_v2";
import { Text } from "components/text_v2";
import { tokens } from "components/tokens";
import { formatCurrencyWithoutDecimal } from "lib/currency";
import { getSystemLocale } from "lib/locale";

interface ReportingSubscriptionOverviewProps {
  totalSpend: number;
  totalBookings: number;
}

export function ReportingSubscriptionOverviewMobile(
  props: ReportingSubscriptionOverviewProps,
) {
  const { totalSpend, totalBookings } = props;

  return (
    <View style={styles.container}>
      <View style={styles.numberWrapper}>
        <View style={styles.numberContainer}>
          <Heading size="mega" weight="bold">
            {formatCurrencyWithoutDecimal(totalSpend, getSystemLocale(), "USD")}
          </Heading>
          <Text>Total Spend</Text>
        </View>
        <Divider />
        <View style={styles.numberContainer}>
          <Heading size="mega" weight="bold">
            {totalBookings}
          </Heading>
          <Text>Total Bookings</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "white",
    borderRadius: 16,
    ...tokens.shadow.elevation1,
  },
  numberWrapper: {},
  numberContainer: {
    padding: 40,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
