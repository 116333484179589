import {
  BudgetDetailHeaderDesktop,
  BudgetDetailHeaderMobile,
} from "../components/budget_detail_header_v1";
import {
  CreateEditBudgetFormColumn,
  CreateEditBudgetSummaryColumn,
} from "../components/create_edit_budget_layout";
import { useForm } from "hooks/use_form";
import { useMutation, gql, useApolloClient } from "@apollo/client";
import { useToast } from "providers/toast";
import { CreateEditBudgetForm } from "./components/create_edit_form";
import { CreateEditBudgetSummary } from "./components/create_edit_budget_summary";
import { useHistory } from "react-router-dom";
import { CreateEditNavigationBlocker } from "../components/create_edit_navigation_blocker";
import React, { useState } from "react";
import { useMediaQuery } from "lib/media_query";
import { BudgetMobileLayout } from "../budget_layout/buget_mobile_layout";
import { SaveMobileButton } from "../components/button/budget_mobile_button";
import { BudgetDesktopLayout } from "../budget_layout/buget_desktop_layout";
import { SaveDesktopButton } from "../components/button/budget_desktop_button";
import { AttachPolicyCard } from "../policy/attach_policy_card";
import { useAnalytics } from "providers/analytics";
import { CreateOrganizationBudgetMutation } from "core/graphql.generated";
export interface CreateEditBudgetFormValues {
  limit?: number;
  individualLimit?: number | null;
  policyId?: string;
}

const initialValues: CreateEditBudgetFormValues = {
  limit: undefined,
  individualLimit: undefined,
  policyId: undefined,
};

export function OrganizationBudgetCreate() {
  const toast = useToast();
  const history = useHistory();
  const analytics = useAnalytics();
  const apolloClient = useApolloClient();
  const mq = useMediaQuery();

  const [shouldBlocknavigation, setShouldBlockNavigation] = useState(true);
  const [createOrganizationBudget, { loading }] =
    useMutation<CreateOrganizationBudgetMutation>(
      createOrganizationBudgetMutation,
    );

  const { values, setFieldValue, errors, submit, isDirty } = useForm({
    initialValues,
    validate: (values) => {
      const errors: { limit?: string; individualLimit?: string } = {};

      if (!values.limit && values.limit !== 0) {
        errors.limit = "Please enter amount";
      }

      if (
        (values.limit || values.limit === 0) &&
        (values.individualLimit || values.individualLimit === 0) &&
        values.individualLimit > values.limit
      ) {
        errors.limit =
          "Monthly limit cannot be lower that the individual limit";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setShouldBlockNavigation(false);
        const res = await createOrganizationBudget({
          variables: {
            input: {
              limit: values.limit,
              individualLimit: values.individualLimit,
              policyId: values.policyId,
            },
          },
        });

        const savedBudget = res.data?.createOrganizationBudget;

        analytics.event("Save New Organization Budget", {
          "Monthly Limit": savedBudget?.limit,
          "Individual Limit": savedBudget?.individualLimit,
          "Policy Name": savedBudget?.policy?.name,
        });

        toast.notify({
          message: "Budget has been successfully created",
        });

        history.replace("/admin/budgets");
        await apolloClient.resetStore();
      } catch (e) {
        toast.notify({
          message: "There has been an error when creating the budget",
        });
      }
    },
  });

  const content = () => (
    <>
      <CreateEditNavigationBlocker
        description="It looks like you’re in the middle of creating a budget. All information entered will be lost if you leave this page."
        isActive={isDirty && shouldBlocknavigation}
      />
      <CreateEditBudgetFormColumn>
        <CreateEditBudgetForm
          onSubmit={submit}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
        />

        <AttachPolicyCard
          onSelect={(policyId) => setFieldValue("policyId", policyId)}
        />
      </CreateEditBudgetFormColumn>
    </>
  );

  if (mq.deviceQuery.mobile) {
    return (
      <BudgetMobileLayout
        header={
          <BudgetDetailHeaderMobile
            title="Create organization budget"
            actions={
              <SaveMobileButton
                onPress={submit}
                disabled={!isDirty}
                loading={loading}
                testID="save-org-budget-button"
              />
            }
          />
        }
        body={content()}
      />
    );
  }

  return (
    <BudgetDesktopLayout
      header={
        <BudgetDetailHeaderDesktop
          title="Create organization budget"
          backButtonText="Back to budgets"
          hasDivider={true}
          actions={
            <SaveDesktopButton
              onPress={submit}
              disabled={!isDirty}
              loading={loading}
              testID="save-org-budget-button"
            />
          }
        />
      }
      body={content()}
      extendedContent={
        <CreateEditBudgetSummaryColumn>
          <CreateEditBudgetSummary
            limit={values.limit}
            individualLimit={values.individualLimit}
          />
        </CreateEditBudgetSummaryColumn>
      }
    />
  );
}

const createOrganizationBudgetMutation = gql`
  mutation createOrganizationBudget($input: CreateOrganizationBudgetInput!) {
    createOrganizationBudget(input: $input) {
      id
      remaining
      spend
      limit
      individualLimit
      policy {
        id
        name
      }
    }
  }
`;
