import { HomePage__SpaceDetailsFragment } from "core/graphql.generated";
import { useEmptyStateFilterTypes } from "./use_empty_state_filter_type";
import { useHomeSearchParamsQuery } from "./use_home_search_params";

export enum emptyStateStatusNumber {
  /** User signs in and their default location is not serviced — no matches */
  NoFilterNoSpace = 1,
  /** User searches or uses the map to see a location that is not serviced — no matches */
  NoFilterWithSearchNoSpace,
  /** User searches or uses the map to see a location that is serviced, but their selected space type does not have location in that area yet. i.e. There are no day passes in Boston yet, but there are day offices and meeting rooms — no matches */
  NoFilterWithSearchNoSpaceForSpaceType,
  /**User filters their search to a date or time with no matches in their selected location */
  DateOnlyFilterWithNoSpace,
  /**User filters their search to a date or time with <4 matches in their selected location */
  DateOnlyFilterWithFewSpaces,
  /** User filters their search to a capacity with no matches in their selected location */
  CapacityOnlyFilterWithNoSpace,
  /** User filters their search to a capacity with <4 matches in their selected location */
  CapacityOnlyFilterWithFewSpace,
  /** User filters their search to a date/time and capacity with no matches in their selected location */
  DateAndCapacityFilterWithNoSpace,
  /** User filters their search to a date/time and capacity with <4 matches in their selected location */
  DateAndCapacityFilterWithFewSpace,
  /** User filters their search to an amenity with no matches in their selected location */
  AmenitiesFilterWithNoSpace,
  /** User filters their search to an amenity with <4 matches in their selected location */
  AmenitiesFilterWithFewSpace,
}

export function useEmptyStateAnalyticsStatus(
  spaces: HomePage__SpaceDetailsFragment[],
  isOtherSpacesTypesHasSpaces: boolean,
  isDefaultLocation = false,
): number {
  const {
    onlyCapacity,
    onlyDateOrTime,
    dateAndCapacityOrAmenities,
    none,
    onlyAmenities,
  } = useEmptyStateFilterTypes();
  const { spaceType } = useHomeSearchParamsQuery();

  if (!spaceType) {
    return 0;
  }

  const spaceLength = spaces?.length || 0;

  if (onlyDateOrTime && spaceLength === 0) {
    return emptyStateStatusNumber.DateOnlyFilterWithNoSpace;
  } else if (onlyDateOrTime && spaceLength < 4) {
    return emptyStateStatusNumber.DateOnlyFilterWithFewSpaces;
  } else if (onlyCapacity && spaceLength === 0) {
    return emptyStateStatusNumber.CapacityOnlyFilterWithNoSpace;
  } else if (onlyCapacity && spaceLength < 4) {
    return emptyStateStatusNumber.CapacityOnlyFilterWithFewSpace;
  } else if (dateAndCapacityOrAmenities && spaceLength === 0) {
    return emptyStateStatusNumber.DateAndCapacityFilterWithNoSpace;
  } else if (dateAndCapacityOrAmenities && spaceLength < 4) {
    return emptyStateStatusNumber.DateAndCapacityFilterWithFewSpace;
  } else if (onlyAmenities && spaceLength === 0) {
    return emptyStateStatusNumber.AmenitiesFilterWithNoSpace;
  } else if (onlyAmenities && spaceLength < 4) {
    return emptyStateStatusNumber.AmenitiesFilterWithFewSpace;
  } else if (
    none &&
    isDefaultLocation &&
    spaceLength === 0 &&
    !isOtherSpacesTypesHasSpaces
  ) {
    return emptyStateStatusNumber.NoFilterNoSpace;
  } else if (none && !isDefaultLocation && isOtherSpacesTypesHasSpaces) {
    return emptyStateStatusNumber.NoFilterWithSearchNoSpaceForSpaceType;
  } else if (none && !isDefaultLocation && !isOtherSpacesTypesHasSpaces) {
    return emptyStateStatusNumber.NoFilterWithSearchNoSpace;
  } else {
    return 0;
  }
}
