import { Heading } from "components/heading_v2";
import { useMediaQuery } from "lib/media_query";
import { SelectDateTabs } from "pages/team_spaces/components/tabs/select_date_tabs";
import { useAuthV2 } from "providers/authv2";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";

export function SelectDateTabsBar() {
  const mq = useMediaQuery();
  const { authenticated } = useAuthV2();
  if (mq.deviceQuery.mobile) {
    return (
      <View style={styles.containerMobile}>
        <Heading size={"lg"} color="black-core">
          Team
        </Heading>
        {authenticated && (
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <SelectDateTabs />
          </ScrollView>
        )}
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Heading size={"lg"} color="black-core">
        Team
      </Heading>
      {authenticated && <SelectDateTabs />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  containerMobile: {
    alignItems: "flex-start",
    gap: 16,
  },
});
