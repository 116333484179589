import { SpaceType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View, Image } from "react-native";
import { useUpdateHomeSearchParamsMutation } from "../hooks/use_home_search_params";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { spaceNamesPlural, spaceNamesSingular } from "./space_list_container";
import { colors } from "components/colors";
import { Icon } from "components/iconv2";

export interface SpaceTypeCard {
  uri: string;
  count: number;
  type: SpaceType;
  title: string;
  content: string;
}
interface SpaceTypeCardProps {
  card: SpaceTypeCard;
}

export function SpaceTypeCard(props: SpaceTypeCardProps) {
  const { card } = props;
  const mq = useMediaQuery();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();

  return (
    <View key={card.uri} style={styles.container}>
      <Image
        source={{
          uri: card.uri,
          width: mq.deviceQuery.mobile ? 92 : 160,
        }}
      />
      <View style={styles.content}>
        <Text weight="semibold" size="sm">
          {card.title}
        </Text>
        <Spacer size={8} />
        <Text color="black-70" size="xs">
          {card.content}
        </Text>
        <Spacer size={8} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            onPress={() =>
              updateHomeSearchParams({
                spaceType: card.type,
              })
            }
            color="eggplant-core"
            weight="semibold"
            size="xs"
          >
            Explore {card.count}{" "}
            {card.count === 1
              ? spaceNamesSingular[card.type]
              : spaceNamesPlural[card.type]}
          </Text>
          <Spacer size={8} direction="row" />
          <Icon size="sm" name="arrow-right-purple" />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  content: {
    padding: 16,
    backgroundColor: colors.brand.whitecore,
    flex: 1,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
});
