import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import ContentLoader, { Rect } from "react-content-loader/native";
import { View } from "react-native";

export function OrganizationBudgetDetailSkeleton() {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return <OrganizationBudgetDetailSkeletonMobile />;
  }

  return <OrganizationBudgetDetailSkeletonDesktop />;
}

function OrganizationBudgetDetailSkeletonDesktop() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={100}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="100px" />
      </ContentLoader>
      <Spacer size={48} />
      <ContentLoader
        speed={2}
        width="100%"
        height={400}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="400px" />
      </ContentLoader>
    </View>
  );
}

function OrganizationBudgetDetailSkeletonMobile() {
  return (
    <View>
      <ContentLoader
        speed={2}
        width="100%"
        height={100}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="100px" />
      </ContentLoader>
      <Spacer size={48} />
      <ContentLoader
        speed={2}
        width="100%"
        height={400}
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
      >
        <Rect width="100%" height="400px" />
      </ContentLoader>
    </View>
  );
}
