import { GridV2 } from "components/gridv2";
import {
  HomePageLocations__LocationDetailsFragment,
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { HomePageLoadingComponent } from "./home_page_loading_component";
import { StyleSheet, View } from "react-native";
import { EmptyState } from "../empty_states/empty_states";
import { SpaceListCards } from "./space_list_cards";
import { Fragment } from "react";
import Pagination from "components/pagination/pagination";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "../hooks/use_home_search_params";
import { Spacer } from "components/spacer";
import { useEmptyStateFilterTypes } from "../hooks/use_empty_state_filter_type";
import { EmptyStateGroupBTile } from "../empty_states/empty_state_tiles";

interface SpaceListProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (location: HomePage__LocationDetailsFragment) => void;
  loading: boolean;
  totalPages: number | undefined;
  totalCount?: number;
  locations?: HomePageLocations__LocationDetailsFragment[];
  locationsLoading?: boolean;
}

export function SpaceListV2(props: SpaceListProps) {
  const {
    spaces,
    loading,
    onPreviewLocation,
    totalPages,
    totalCount,
    locations,
  } = props;
  const { none } = useEmptyStateFilterTypes();
  const mq = useMediaQuery();
  const { page, spaceType } = useHomeSearchParamsQuery();
  const isEmpty = !spaceType
    ? locations?.length === 0
    : none
    ? spaces.length === 0
    : (totalCount || 0) < 4;

  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();
  const handleChangePage = (page: number) => {
    updateHomeSearchParams({ page });
    window.scrollTo(0, 0);
  };

  return (
    <View style={styles.container}>
      {loading && (
        <GridV2 columns={mq.deviceQuery.mobile ? 1 : 2}>
          <HomePageLoadingComponent />
        </GridV2>
      )}
      {!loading && isEmpty && (
        <EmptyState onPreviewLocation={onPreviewLocation} spaces={spaces} />
      )}
      {!loading && !isEmpty && (
        <>
          <SpaceListCards
            onPreviewLocation={onPreviewLocation}
            spaces={spaces}
            locations={locations}
          />
          {spaces.length < 4 && !!spaceType && (
            <View style={styles.cantFindWrapper}>
              <EmptyStateGroupBTile
                title="Can’t find what you’re looking for?"
                subTitle="Our team will personally find a space for you, even if they aren't on Flexspace yet."
              />
            </View>
          )}
        </>
      )}
      {page !== 0 && totalPages && totalPages !== 0 && totalPages > 1 && (
        <Fragment>
          <Spacer size={40} />
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onChangePage={handleChangePage}
          />
          <Spacer size={40} />
        </Fragment>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cantFindWrapper: {
    marginTop: 24,
  },
});
