import React from "react";
import { View } from "react-native";

interface ChartInCarouselWrapperProps {
  children: React.ReactNode;
}

export function ChartInCarouselWrapper(props: ChartInCarouselWrapperProps) {
  const { children } = props;

  return (
    <View style={{ height: 464 }}>
      <View style={{ height: 428, paddingRight: 24 }}>{children}</View>
    </View>
  );
}
