import { Fragment, useCallback, useState } from "react";
import { Pressable, ScrollView } from "react-native";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { tokens } from "components/tokens";
import { Icon } from "components/icon";
import { Divider } from "components/divider";
import { Tabs } from "components/tab/tabs";
import { WeekDates } from "components/week_dates";
import {
  BookingDateRangePickerMobile,
  BookingTimeRangePicker,
} from "core/booking_date_range_picker_mobile";
import {
  getDateRangeForNextBookingType,
  isDateRangeDisabled,
  SearchValue,
  useBookingDateRangePickerHelper,
} from "core/booking_utils";
import {
  BestDayRates,
  BookingType,
  PricingType,
  SpaceDetails__AllDetailsFragment,
} from "core/graphql.generated";
import { MobileSpacePricings } from "./mobile_space_pricings";

interface BookingDateRangePickerDialogContentProps {
  monthlyBilling: boolean;
  space: SpaceDetails__AllDetailsFragment;
  initialSearchValues: SearchValue;
  error?: string;
  onClose: () => void;
  onSave: (searchValue: SearchValue) => void;
  dynamicPrices: BestDayRates[];
}

export function BookingDateRangePickerDialogContent(
  props: BookingDateRangePickerDialogContentProps,
) {
  const {
    monthlyBilling,
    space,
    initialSearchValues,
    onClose,
    onSave,
    dynamicPrices,
  } = props;
  const [dateRange, setDateRange] = useState(initialSearchValues.dateRange);

  const handleSave = useCallback(() => {
    onSave({ dateRange });
  }, [onSave, dateRange]);

  const {
    initialBookingType,
    showSegmentedControl,
    timeslots,
    isBlockedDate,
    isOutsideRange,
  } = useBookingDateRangePickerHelper({
    space,
    physicalSpaceID: initialSearchValues.physicalSpaceID,
    selectedDay:
      dateRange?.type === BookingType.HourlyBooking
        ? dateRange.date
        : undefined,
  });
  const [bookingType, setBookingType] =
    useState<BookingType>(initialBookingType);

  const handleChangeBookingType = useCallback(
    (b: BookingType) => {
      setBookingType(b);

      setDateRange(getDateRangeForNextBookingType(dateRange, b));
    },
    [dateRange, setDateRange],
  );

  return (
    <Container
      color="content"
      flex={1}
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
    >
      <Container padding={20}>
        <Pressable onPress={onClose}>
          <Icon color={tokens.colors.base.black} name="x-circle" />
        </Pressable>
        <Spacer size={16} />
        {showSegmentedControl === true && (
          <Tabs
            value={bookingType}
            onChange={handleChangeBookingType}
            options={[
              { value: BookingType.DailyBooking, label: "Daily" },
              { value: BookingType.HourlyBooking, label: "Hourly" },
            ]}
          />
        )}
        <Spacer size={8} />
        <WeekDates />
      </Container>
      <Divider />
      <ScrollView>
        <Container paddingVertical={8} paddingHorizontal={20}>
          <BookingDateRangePickerMobile
            bookingType={bookingType}
            onChangeBookingType={handleChangeBookingType}
            showSegmentedControl={showSegmentedControl}
            value={dateRange}
            onChange={setDateRange}
            isOutsideRange={isOutsideRange}
            isBlockedDate={isBlockedDate}
            timeslots={timeslots}
            pricings={space.pricings}
            dynamicPrices={dynamicPrices}
            currency={space.currency}
            spaceId={space.id}
          />
        </Container>
      </ScrollView>
      <Divider />
      <Container padding={8}>
        {bookingType === BookingType.HourlyBooking && (
          <BookingTimeRangePicker
            value={
              dateRange?.type === BookingType.HourlyBooking
                ? dateRange
                : undefined
            }
            onChange={setDateRange}
            timeslots={timeslots}
            spaceId={space.id}
            basePrice={
              space.pricings.find(
                (pricing) => pricing.type === PricingType.Hourly,
              )?.price
            }
          />
        )}
        <Row alignItems="center">
          {!monthlyBilling && (
            <Fragment>
              <Container flex={1}>
                <MobileSpacePricings space={space} />
              </Container>
              <Spacer size={24} />
            </Fragment>
          )}
          <Container flex={1}>
            <Button
              disabled={isDateRangeDisabled(dateRange)}
              text="Save"
              onPress={handleSave}
            />
          </Container>
        </Row>
      </Container>
    </Container>
  );
}
