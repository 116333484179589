import { useEffect, useRef } from "react";

export function useDebounce<T>(value: T, delay: number): T {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const debouncedValueRef = useRef<T>(value);

  useEffect(() => {
    debouncedValueRef.current = value;
  }, [value]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [value, delay]);

  return debouncedValueRef.current;
}

export const useDebounceCallBack = <T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
): T => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [timer]);

  return ((...args: any[]) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    const newTimer = setTimeout(() => {
      callback(...args);
    }, delay);
    timer.current = newTimer;
  }) as T;
};
