import { BookingRequestsTabName } from "./booking_requests_tab";
import { PendingRequestsTable, PendingRequest } from "./pending_requests_table";
import {
  ArchivedRequestsTable,
  ArchivedRequest,
} from "./archived_requests_table";

interface PropsType {
  pendingRequests: PendingRequest[];
  archivedRequests: ArchivedRequest[];
  show: BookingRequestsTabName;
  refetch: () => void;
}

export const BookingRequestsTable: React.FC<PropsType> = ({
  pendingRequests,
  archivedRequests,
  show,
  refetch,
}) => {
  if (show === BookingRequestsTabName.Pending) {
    return <PendingRequestsTable rows={pendingRequests} refetch={refetch} />;
  }

  if (show === BookingRequestsTabName.Archived) {
    return <ArchivedRequestsTable rows={archivedRequests} />;
  }

  return <></>;
};
