import { Pressable, StyleSheet, View } from "react-native";

import { useMediaQuery } from "lib/media_query";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { Icon, IconName } from "components/iconv2";

interface UpcomingBookingActionProps {
  title: string;
  description: string;
  iconName: IconName;
  actionText: string;
  onClick: () => void;
}

export function UpcomingBookingAction({
  title,
  description,
  iconName,
  actionText,
  onClick,
}: UpcomingBookingActionProps) {
  const mq = useMediaQuery();

  return (
    <View>
      {!mq.deviceQuery.mobile && (
        <>
          <Text size="xs" weight="semibold">
            {title}
          </Text>
          <Spacer size={8} />
          <Text size="xs">{description}</Text>
          <Spacer size={20} />
        </>
      )}
      <Pressable onPress={onClick}>
        <View style={styles.actionContent}>
          <Icon name={iconName} color="eggplantcore" size="sm" />
          <Text size="xs" weight="semibold" color="eggplant-core">
            {actionText}
          </Text>
        </View>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  actionContent: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
});
