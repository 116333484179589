import { CardsSaveIcon } from "components/cards_save_icon";
import { Icon } from "components/iconv2";
import { SpaceType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import { Pressable, StyleSheet, View } from "react-native";
import { OnPreviewLocation } from "../home";
import { LocationMarker } from "./map";
import {
  SpaceCardAddressWithDistance,
  SpaceCardContent,
  SpaceCardDetailsLink,
  SpaceCardPhoto,
  SpaceCardTemplate,
  SpaceCardTitleWithCapacity,
} from "./space_card_v2/space_card_template";

interface HomeMapPreviewCardV2CardProps {
  data: LocationMarker;
  onPreviewLocation: OnPreviewLocation;
  onClose: () => void;
  spaceType?: SpaceType;
}

export function HomeMapPreviewCardV2(props: HomeMapPreviewCardV2CardProps) {
  const { data, onPreviewLocation, onClose } = props;
  const mq = useMediaQuery();
  const isMobile = mq.deviceQuery.mobile;
  return (
    <SpaceCardTemplate appearance={isMobile ? "compact" : undefined}>
      <View
        style={[
          styles.relativeContainer,
          isMobile && styles.relativeContainerMobile,
        ]}
      >
        <SpaceCardPhoto
          hasOverlay={data.locked}
          imageSrc={data.imageSrc}
          appearance={isMobile ? "compact" : undefined}
        />
        <CardsSaveIcon
          favoriteId={data.spaceId ? data.spaceId : data.locationID}
          favoriteType={
            data.spaceId ? FavoriteType.Space : FavoriteType.Location
          }
        />
      </View>

      <SpaceCardContent>
        <SpaceCardTitleWithCapacity
          outOfPolicy={data.locked}
          title={data.locationName}
        />
        <SpaceCardAddressWithDistance
          fullAddress={data.locationStreetAddress}
          shortAddress={data.locationCity}
          currentUserDistance={data.currentUserDistance}
          locationLat={data.lat}
          locationLng={data.lng}
        />
        <SpaceCardDetailsLink
          onPress={() =>
            onPreviewLocation(
              {
                timezone: data.timeZone,
                id: data.locationID,
                images: [],
                name: data.locationName,
                address: {
                  streetAddress: data.locationStreetAddress,
                  city: data.locationCity,
                },
              },
              data.spaceId,
            )
          }
        >
          {data.cta}
        </SpaceCardDetailsLink>
      </SpaceCardContent>
      <View style={styles.closeBtnWrapper}>
        <Pressable onPress={onClose}>
          <Icon name="close" />
        </Pressable>
      </View>
    </SpaceCardTemplate>
  );
}

const styles = StyleSheet.create({
  closeBtnWrapper: {
    position: "absolute",
    top: 12,
    left: 12,
    zIndex: 1,
  },
  relativeContainer: {
    position: "relative",
  },
  relativeContainerMobile: {
    maxWidth: 114,
    height: "unset",
  },
});
