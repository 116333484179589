import { AppHeader } from "components/app_header_v3/app_header";
import { Button } from "components/button_v2";
import { Container } from "components/container";
import { Content } from "components/content";
import { Divider } from "components/divider";
import { Heading } from "components/heading";
import { Icon } from "components/iconv2";
import { LoginToBook } from "components/login_to_book";
import { PageContainer } from "components/page_container";
import { Row } from "components/row";
import { Spacer } from "components/spacer";
import { Text } from "components/text";
import { Text as TextV2 } from "components/text_v2";
import { BookingCheckout, CheckoutTerms } from "core/booking_checkout";
import { CheckoutPaymentDetails } from "core/checkout_payment_details";
import { Footer } from "core/footer";
import { useAuthV2 } from "providers/authv2";
import React, { Fragment } from "react";
import { Pressable } from "react-native";
import { BookingCheckoutProps } from "../offsite_space_checkout";
import { StickyBookingPreviewPanelWrapper } from "./sticky_booking_preview_panel_wrapper";

export function BookingCheckoutDesktop(props: BookingCheckoutProps) {
  const { authenticated } = useAuthV2();
  const {
    requirePayment,
    space,
    values,
    errors,
    submitCount,
    handleChange,
    setFieldValue,
    status,
    submitting,
    disabled,
    submit,
    onCreditCardChange,
    monthlyBilling,
    onBack,
    setLayoutVisible,
    quoteQuery,
    quoteError,
    stripe,
    setBookingDateRangeVisible,
    dateTimeDropDownPicker,
  } = props;
  return (
    <Container>
      <AppHeader />
      <PageContainer>
        <Spacer size={32} />
        <Content>
          <Row alignItems="center">
            <Pressable onPress={onBack}>
              <Icon size="md" name="chevron-left" />
            </Pressable>
          </Row>
          <Spacer size={16} />
          <Heading size="h1">
            {requirePayment ? "Confirm and pay" : "Review and confirm"}
          </Heading>
          <div className="row">
            {authenticated ? (
              <div className="spaces-checkout-left-column">
                <Spacer size={16} />
                <Container>
                  <BookingCheckout
                    space={space}
                    onPressLayoutChange={() => setLayoutVisible(true)}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    submitCount={submitCount}
                  />
                  <Spacer size={24} />

                  {monthlyBilling && !requirePayment && (
                    <>
                      <Divider />
                      <Container paddingVertical={24}>
                        <TextV2 weight="semibold" size={"md"}>
                          Payment details
                        </TextV2>
                        <Spacer size={16} />
                        <Text size={"sm"}>Invoiced to your organization.</Text>
                      </Container>
                      <Divider />
                    </>
                  )}

                  {requirePayment && (
                    <Fragment>
                      <Divider />
                      <Spacer size={16} />
                      <CheckoutPaymentDetails
                        stripe={stripe}
                        onChange={onCreditCardChange}
                        paymentCompleteError={errors.paymentComplete}
                      />
                      <Spacer size={16} />
                    </Fragment>
                  )}
                  <Container>
                    <Button
                      loading={submitting}
                      disabled={disabled}
                      testID="checkout-confirm-and-pay-button"
                      onPress={submit}
                      text={
                        requirePayment
                          ? "Confirm and pay"
                          : "Confirm reservation"
                      }
                    />
                    <Spacer size={24} />
                    <CheckoutTerms />
                    {status !== "" && (
                      <Fragment>
                        <Spacer size={8} />
                        <Text color="error">{status}</Text>
                      </Fragment>
                    )}
                  </Container>
                </Container>
              </div>
            ) : (
              <LoginToBook />
            )}

            <div className="spaces-checkout-right-column">
              <div className="spaces-checkout-sticky-panel">
                <StickyBookingPreviewPanelWrapper
                  promoCode={values.promoCode}
                  setBookingDateRangeVisible={setBookingDateRangeVisible}
                  dateTimeDropDownPicker={dateTimeDropDownPicker}
                  onChangePromoCode={(pc) => setFieldValue("promoCode", pc)}
                  searchValue={values}
                  space={space}
                  monthlyBilling={false}
                  currency={space.currency}
                  quoteQuery={quoteQuery}
                  quoteError={quoteError}
                />
              </div>
            </div>
          </div>
        </Content>
        <Spacer size={40} />
      </PageContainer>
      <Footer />
    </Container>
  );
}
