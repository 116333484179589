import { gql, useMutation, useQuery } from "@apollo/client";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  AcceptInviteCodeVerifiedQuery,
  PostAuthMutation,
  PostAuthMutationVariables,
} from "core/graphql.generated";
import { logger } from "lib/logger";
import { useAnalytics } from "providers/analytics";
import { postAuthMutation } from "providers/auth_loaderv1";
import { useOpenDirectoryFeatureFlag } from "providers/splitio";
import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { ACCEPT_INVITE, AcceptInvitePageProps } from "../accept_invite";

export function InviteMemberStepCodeVerified(props: AcceptInvitePageProps) {
  const { credentials } = props;
  const history = useHistory();
  const analytics = useAnalytics();
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  const { data } = useQuery<AcceptInviteCodeVerifiedQuery>(
    acceptInviteCodeVerifiedGQLQuery,
  );

  const [postAuth] = useMutation<PostAuthMutation, PostAuthMutationVariables>(
    postAuthMutation,
  );
  const [linkEmailAccountToPasswordAccountIfExists] = useMutation(
    linkEmailAccountToPasswordAccountIfExistsGQLMutation,
  );

  useEffect(() => {
    if (!data?.currentUser) {
      return;
    }

    analytics.event("Accept Invite", {
      "Invite UUID": credentials?.inviteId,
      Step: "Complete",
      "Person UUID": data?.currentUser?.id,
    });

    const link = async () => {
      if (openDirectoryFeatureFlag) {
        let inviteId;
        const acceptInvite = localStorage.getItem(ACCEPT_INVITE);
        if (acceptInvite) {
          inviteId = JSON.parse(acceptInvite).inviteId;
          logger.debug(`[InviteMemberStepCodeVerified] inviteId ${inviteId}`);
          localStorage.removeItem(ACCEPT_INVITE);
        }
        const postAuthResult = await postAuth({ variables: { inviteId } });
        logger.debug(
          `[InviteMemberStepCodeVerified] postAuthResult: ${JSON.stringify(
            postAuthResult?.data?.postAuth,
          )}`,
        );
        if (postAuthResult?.data?.postAuth.orgRegistered) {
          history.replace("/?showTour=1");
        } else {
          history.replace("/sign-up/team-name");
        }
      } else {
        const res = await linkEmailAccountToPasswordAccountIfExists();
        if (res.data) {
          // we need to refresh the session because after code verification, we have email|abc instead of auth0|abc user id
          // and because of that we are not able to do graphql queries based on current user
          window.location.href = "/?showTour=1";
        }
        // at this step, it is not necessary to store accept-invite credentials anymore
        localStorage.removeItem(ACCEPT_INVITE);
      }
    };

    link();
  }, [
    history,
    analytics,
    credentials,
    data,
    postAuth,
    openDirectoryFeatureFlag,
    linkEmailAccountToPasswordAccountIfExists,
  ]);

  return (
    <Content>
      <Heading size="lg" align="center">
        Code verified!
      </Heading>
      <Spacer size={40} />
      <Text align="center">Redirecting you to home page</Text>
    </Content>
  );
}

const acceptInviteCodeVerifiedGQLQuery = gql`
  query AcceptInviteCodeVerified {
    currentUser {
      id
    }
  }
`;

const linkEmailAccountToPasswordAccountIfExistsGQLMutation = gql`
  mutation LinkEmailAccountToPasswordAccountIfExists {
    linkEmailAccountToPasswordAccountIfExists
  }
`;
