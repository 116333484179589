import { gql, useMutation } from "@apollo/client";
import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { LocationSearchInput } from "components/location_search_input/location_search_input";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  BudgetPolicyLocationItemDistanceType,
  RemoveAddressMutation,
  RemoveAddressMutationVariables,
} from "core/graphql.generated";
import { LocationAddressCard } from "pages/budgets/policy/location_adress_card";
import { PolicyLocationItem } from "pages/budgets/policy/policy_mutation_form";
import { MapboxFeature } from "pages/homev2/mapbox";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

type LocationSectionProps = {
  locationItems?: PolicyLocationItem[];
  onChange: (locationItems: PolicyLocationItem[]) => void;
  error?: string;
};
export const LocationSection = ({
  locationItems,
  onChange,
  error,
}: LocationSectionProps) => {
  const [addOfficeModalVisible, setAddOfficeModalVisible] = useState(false);

  const [removeAddress] = useMutation<
    RemoveAddressMutation,
    RemoveAddressMutationVariables
  >(removeAddressMutation);

  const getPlace = (place: MapboxFeature) => {
    setAddOfficeModalVisible(false);
    let newAddress = {
      street: place.place_name,
      latitude: place.center[1],
      longitude: place.center[0],
      distance: 25,
      distanceUnit: BudgetPolicyLocationItemDistanceType.Mi,
      active: true,
    };
    onChange(locationItems ? [...locationItems, newAddress] : [newAddress]);
  };

  const onRemove = (locationItem: PolicyLocationItem) => {
    if (!locationItems || locationItems.length === 0) {
      return;
    }
    const filteredLocation = locationItems.filter(
      (item) =>
        item.street !== locationItem.street &&
        item.latitude !== locationItem.latitude &&
        item.longitude !== locationItem.longitude,
    );
    onChange(filteredLocation);
    if (locationItem.addressId) {
      removeAddress({
        variables: {
          addressId: locationItem.addressId,
        },
      });
    }
  };
  const onUpdate = (locationItem: PolicyLocationItem) => {
    if (!locationItems || locationItems.length === 0) {
      return;
    }
    const updatedLocation = locationItems.map((item) =>
      item.street === locationItem.street &&
      item.latitude === locationItem.latitude &&
      item.longitude === locationItem.longitude
        ? locationItem
        : item,
    );
    onChange(updatedLocation);
  };
  return (
    <View style={styles.cardSection}>
      <Divider />
      <Spacer size={12} />
      <Text size="xs" weight="semibold">
        This will apply to the following offices:*
      </Text>
      {locationItems &&
        locationItems.map((locationItem, index) => (
          <LocationAddressCard
            key={index}
            locationItem={locationItem}
            onRemove={onRemove}
            onUpdate={onUpdate}
          />
        ))}

      {error && (
        <View style={styles.errorWrapper}>
          <Text color="grapefruit-core" size="sm">
            {error}
          </Text>
        </View>
      )}

      <Button
        size={"small"}
        text={"Add office"}
        appearance={"textLink"}
        testID={"add-location-proximity-button"}
        iconName={"plus"}
        onPress={() => setAddOfficeModalVisible(true)}
      />
      <DialogModal
        isVisible={addOfficeModalVisible}
        headerTitle={"Add office"}
        onClose={() => setAddOfficeModalVisible(false)}
        desktopWidth={780}
      >
        <View style={styles.container}>
          <LocationSearchInput getPlace={getPlace} />
        </View>
      </DialogModal>
    </View>
  );
};

const styles = StyleSheet.create({
  cardSection: {
    marginTop: 12,
  },
  container: {
    width: "100%",
    height: "100%",
  },
  errorWrapper: {
    marginTop: 5,
  },
});

const removeAddressMutation = gql`
  mutation RemoveAddress($addressId: ID!) {
    removeBudgetPolicyAddress(addressId: $addressId)
  }
`;
