import { Button } from "components/button_v2";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Spacer } from "components/spacer";
import { TextField } from "components/text_field";
import { Text } from "components/text_v2";
import { useForm } from "hooks/use_form";
import { useMediaQuery } from "lib/media_query";
import { SignUpCredentials } from "pages/sign_up/sign_up";
import { useAuthV2 } from "providers/authv2";
import { Fragment, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

interface SignUpStepFullNameProps {
  onGoNext: () => void;
  onGoBack: () => void;
  credentials: SignUpCredentials;
  setCredentials: (formValues: SignUpCredentials) => void;
}
export function SignUpStepFullName(props: SignUpStepFullNameProps) {
  const { credentials, setCredentials, onGoNext } = props;
  const mq = useMediaQuery();
  const { startPasswordless } = useAuthV2();
  const history = useHistory();

  useEffect(() => {
    if (!credentials.email) {
      history.replace("/sign-up/get-started");
    }
    if (credentials.fullName) {
      history.replace("/sign-up/code");
    }
  }, [credentials.email, credentials.fullName, history, onGoNext]);

  const { values, changed, errors, setFieldValue, submit, submitting } =
    useForm({
      initialValues: {
        fullName: "",
        email: credentials.email,
      },
      validate: ({ fullName }: SignUpCredentials) => {
        const errors: { [field: string]: string } = {};

        if (!fullName) {
          errors.fullName = "Please input your full name";
        }
        if (fullName && fullName.split(" ").length < 2) {
          errors.fullName = "Please input your full name";
        }

        return errors;
      },
      onSubmit: async (
        { fullName }: SignUpCredentials,
        { setSubmitting, setErrors }: any,
      ) => {
        const trimmedFullname = fullName.trim();
        setSubmitting(true);
        try {
          await startPasswordless(credentials.email);
          setCredentials({
            email: credentials.email,
            fullName: trimmedFullname,
          });
          onGoNext();
        } catch (error) {
          setErrors({
            authenticationError: error.message || error.description,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });

  return (
    <>
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Content maxWidth={590}>
          <Heading size={mq.deviceQuery.mobile ? "lg" : "mega"} align="center">
            Enter your name
          </Heading>
          <Spacer size={40} />
          <View
            style={{
              flex: 1,
              width: mq.deviceQuery.mobile ? "100%" : 343,
              margin: "auto",
            }}
          >
            <TextField
              placeholder="First and last name"
              value={values.fullName || ""}
              onChange={(fullName) => setFieldValue("fullName", fullName)}
              invalid={changed.fullName && !!errors.fullName}
              testID="sign-up-name"
              onSubmitEditing={submit}
            />
          </View>
          {!!errors.authenticationError && (
            <Fragment>
              <Text color="grapefruit-core">{errors.authenticationError}</Text>
              <Spacer size={15} />
            </Fragment>
          )}
          <Spacer size={8} />
        </Content>
      </View>
      <View
        style={[
          styles.buttonContainer,
          mq.deviceQuery.mobile && styles.buttonContainerMobile,
        ]}
      >
        <Button
          text="Continue"
          onPress={submit}
          testID="sign-up-password-submit-button"
          loading={submitting}
        />
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: "95%",
    maxWidth: 220,
    alignSelf: "center",
  },
  buttonContainerMobile: {
    maxWidth: 343,
  },
});
