import { Fragment } from "react";
import { StyleSheet, View } from "react-native";

import { Button } from "./button_v2";
import { colors } from "./colors";
import { Spacer } from "./spacer";
import { Text } from "./text_v2";

interface EmptyDataProps {
  title: string;
  description: string;
  buttonText?: string;
  onClick?: () => void;
}

export function EmptyData({
  title,
  description,
  buttonText,
  onClick,
}: EmptyDataProps) {
  return (
    <View style={styles.root}>
      <Text size="md" weight="semibold" align="center" color="black-70">
        {title}
      </Text>
      <Spacer size={16} />
      <Text size="xs" align="center" color="black-70">
        {description}
      </Text>
      {!!buttonText && !!onClick && (
        <Fragment>
          <Spacer size={16} />
          <Button text={buttonText} size="medium" onPress={onClick} />
        </Fragment>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 24,
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
    borderRadius: 4,
    alignItems: "center",
  },
});
