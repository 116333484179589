import { useCallback } from "react";
import { StyleSheet, View, Image, Pressable } from "react-native";

import { SpaceType } from "core/graphql.generated";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { colors } from "components/colors";
import { OutOfPolicyTag } from "components/out_of_policy_tag/out_of_policy_tag";
import { tokens } from "components/tokens";

interface SpaceTypeCardMobileProps {
  uri: string;
  type: SpaceType;
  title: string;
  content: string;
  onCardPress: (type: SpaceType) => void;
  isPressed: boolean;
  outOfPolicy?: boolean;
}

export function SpaceTypeCardMobile(props: SpaceTypeCardMobileProps) {
  const { uri, type, title, content, onCardPress, outOfPolicy, isPressed } =
    props;

  const onPress = useCallback(() => {
    onCardPress(type);
  }, [onCardPress, type]);

  return (
    <Pressable onPress={onPress}>
      <View key={uri} style={styles.container}>
        <Image
          source={{
            uri: uri,
            width: 92,
          }}
        />
        <View style={[styles.content, isPressed && styles.pressed]}>
          <View style={styles.spaceLabelWrapper}>
            <Text weight="semibold" size="xs">
              {title}
            </Text>
            {outOfPolicy && <OutOfPolicyTag />}
          </View>
          <Spacer size={8} />
          <Text color="black-70" size="xs">
            {content}
          </Text>
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderRadius: 4,
    overflow: "hidden",
    ...tokens.shadow.elevation1,
  },
  content: {
    padding: 16,
    backgroundColor: colors.brand.whitecore,
    flex: 1,
  },
  spaceLabelWrapper: {
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
  },
  pressed: {
    backgroundColor: colors.brand.eggplantMinus90,
  },
});
