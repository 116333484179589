import { ReactElement, useState } from "react";
import { DropdownMenuOptionType } from "../dropdown_menu/dropdown_menu";
import { TableHeaderPropTypes } from "./components/table_header";
import { searchSortRows } from "./utils/search_sort_rows";

interface RowProps {
  [key: string]: any;
}

export type DataTableRow = RowProps & {
  id: string;
  render?: (row: DataTableRow, columnName: string) => ReactElement;
  contextMenu?: DropdownMenuOptionType[];
  extension?: JSX.Element;
};

export type CellWidth = string | number;

export interface Column {
  id: string;
  title: string;
  renderTitle?: (column: Column) => ReactElement;
  width?: CellWidth;
}

interface ChildrenArgsType
  extends Pick<
    TableHeaderPropTypes,
    "searchInput" | "handleSearchChange" | "handleSortChange" | "currentSort"
  > {
  sortedFilteredRows: DataTableRow[];
}

export interface DataTableBasePropTypes {
  searchPlaceholder?: string;
  rows: DataTableRow[];
  columns: Column[];
  sortableBy?: { field: Column["id"]; label: string }[];
  defaultSortBy?: Column["id"];
  searchableBy?: Column["id"][];
  useServerSideFilters?: boolean;
  onSearchChange?: (newVal: string) => void;
  onSortChange?: (newVal: string) => void;
  withContextMenu?: boolean;
  children?: (args: ChildrenArgsType) => ReactElement;
}

export function DataTableBase(props: DataTableBasePropTypes) {
  const {
    rows = [],
    defaultSortBy = "",
    searchableBy,
    useServerSideFilters = false, // true if we don't want DataTable to sort/filter rows on it's own
    onSearchChange,
    onSortChange,
    children,
  } = props;
  const [searchInput, setSearchInput] = useState("");
  const [currentSort, setCurrentSort] = useState(defaultSortBy);
  const handleSearchChange = (newVal: string) => {
    onSearchChange?.(newVal);
    setSearchInput(newVal);
  };

  const handleSortChange = (newVal: string | undefined) => {
    onSortChange?.(newVal || "");
    setCurrentSort(newVal || "");
  };

  const sortedFilteredRows = useServerSideFilters
    ? rows
    : searchSortRows(rows, currentSort, searchInput, searchableBy);

  return (
    children?.({
      searchInput,
      handleSearchChange,
      handleSortChange,
      currentSort,
      sortedFilteredRows,
    }) ?? null
  );
}
