import { gql, useMutation, useQuery } from "@apollo/client";
import { StepCodeUI } from "components/sign_up_shared/step_code_ui";
import {
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
  CurrentUserStepCodeQuery,
} from "core/graphql.generated";
import { SIGN_UP_STEPS_REDIRECT_URL_KEY } from "providers/auth_loader";
import { useAuthV2 } from "providers/authv2";
import { useOpenDirectoryFeatureFlag } from "providers/splitio";
import { useCallback, useEffect, useState } from "react";
import { AcceptInviteCredentials } from "../accept_invite";

interface InviteMemberStepCodeProps {
  onGoNext: () => void;
  onGoBack: () => void;
  credentials: AcceptInviteCredentials;
}

export function InviteMemberStepCode(props: InviteMemberStepCodeProps) {
  const { onGoNext, credentials } = props;
  const { verifyPasswordless, startPasswordless } = useAuthV2();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  const { data: currentUserQuery } = useQuery<CurrentUserStepCodeQuery>(
    currentUserStepCodeGQLQuery,
  );
  const currentUser = currentUserQuery?.currentUser;
  const [acceptInviteMutation] = useMutation<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >(acceptInviteGQLMutation);

  const onSubmit = useCallback(
    (code) => {
      setLoading(true);
      localStorage.setItem(
        SIGN_UP_STEPS_REDIRECT_URL_KEY,
        "/accept-invite/code-verified",
      );
      verifyPasswordless(credentials.email, code)
        .then(() => {
          // This actually is not called as auth0 does a redirect
          onGoNext();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setError(
            "This code is invalid or expired. Try again or resend the code.",
          );
        });
    },
    [verifyPasswordless, onGoNext, credentials],
  );

  useEffect(() => {
    if (openDirectoryFeatureFlag) {
      if (currentUser?.id) {
        startPasswordless(credentials.email);
      }
    } else {
      if (currentUser?.id && !currentUser?.organization) {
        const acceptInvite = async () => {
          await acceptInviteMutation({
            variables: { inviteID: credentials.inviteId },
          });
          startPasswordless(credentials.email);
        };
        acceptInvite();
        return;
      }

      if (currentUser?.id && currentUser?.organization) {
        startPasswordless(credentials.email);
      }
    }
  }, [
    credentials.email,
    startPasswordless,
    currentUser,
    credentials.inviteId,
    openDirectoryFeatureFlag,
    acceptInviteMutation,
  ]);

  const resendCode = () => startPasswordless(credentials.email);

  return (
    <StepCodeUI
      error={error}
      code={code}
      onSubmit={onSubmit}
      setCode={setCode}
      credentials={credentials}
      resendCode={resendCode}
      loading={loading}
    />
  );
}

const currentUserStepCodeGQLQuery = gql`
  query CurrentUserStepCode {
    currentUser {
      id
      organization {
        id
      }
    }
  }
`;

const acceptInviteGQLMutation = gql`
  mutation AcceptInvite($inviteID: String!) {
    acceptInvite(inviteID: $inviteID)
  }
`;
