import { getSystemLocale } from "../../../lib/locale";

const metersToMiles = (meters: number) => meters * 0.000621371192;
export const MAX_VISIBLE_DISTANCE = 32000; // 32 km or 20 mi

export const getDistanceText = (
  distanceInMeters: number | null | undefined,
  country: string | null | undefined,
) => {
  let distanceText = "";
  const locale = getSystemLocale();

  if (distanceInMeters && distanceInMeters <= MAX_VISIBLE_DISTANCE) {
    distanceText =
      country?.toLowerCase() === "us" ||
      country?.toLowerCase() === "united states" ||
      locale.toLowerCase().includes("us")
        ? `${metersToMiles(distanceInMeters).toFixed(1)} mi`
        : `${(distanceInMeters / 1000).toFixed(1)} km`;
  }
  return distanceText;
};
