import { AsyncRenderer } from "components/AsyncRenderer";
import { LabeledSwitch } from "components/labeled_switch";
import { TextLink } from "components/text_link";
import { Text } from "components/text_v2";
import { BookingRequestStatus } from "core/graphql.generated";
import { useTab } from "hooks/use_tab";
import { useMediaQuery } from "lib/media_query";
import { useQueryString } from "lib/query_string";
import { useToast } from "providers/toast";
import { useEffect, useMemo } from "react";
import ContentLoader, { Rect } from "react-content-loader/native";
import { StyleSheet, View } from "react-native";

import { BookingRequestsLayout } from "./booking_requests_layout";
import { seperateBookingRequests } from "./booking_requests_utils";
import {
  BookingRequestsTab,
  BookingRequestsTabName,
} from "./components/booking_requests_tab";
import { BookingRequestsTable } from "./components/booking_requests_table";
import { useBookingRequestsQuery } from "./hooks/use_booking_requests_query";
import { useShowCancelAndExpire } from "./hooks/use_show_cancel_and_expire_switch";

export const BookingRequests = () => {
  const {
    deviceQuery: { desktop: isDesktop },
  } = useMediaQuery();

  const [selectedTab, setSelectedTab] = useTab([
    BookingRequestsTabName.Pending,
    BookingRequestsTabName.Archived,
  ]);
  const [showCancelAndExpire, setShowCancelAndExpire] =
    useShowCancelAndExpire();
  const { data, loading, error, refetch } = useBookingRequestsQuery();
  const { detailedRequestId, approveRequestId } = useQueryString<{
    detailedRequestId: string;
    approveRequestId: string;
  }>();
  const { pendingRequests, archivedRequests } = useMemo(() => {
    const { bookingRequests, currentUser } = data;
    return seperateBookingRequests(bookingRequests, currentUser);
  }, [data]);

  const toast = useToast();

  useEffect(() => {
    if (!loading && data && selectedTab === BookingRequestsTabName.Pending) {
      if (
        archivedRequests.find(
          (aR) => aR.id === detailedRequestId || aR.id === approveRequestId,
        )
      ) {
        const contentComponent = (
          <Text
            size="xs"
            color={"white-core"}
            align="center"
            weight={"semibold"}
          >
            This request has already been reviewed by an admin. You can see its
            status{" "}
            <TextLink
              href={""}
              onPress={() => setSelectedTab(BookingRequestsTabName.Archived)}
              text="here"
              inline
              size="xs"
              weight={"semibold"}
              decoration="underline"
              color={"white-core"}
            />
          </Text>
        );
        toast.notify({ message: contentComponent });
      }
    }
    // disable warning because we must remove the dependency on toast
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    approveRequestId,
    archivedRequests,
    data,
    detailedRequestId,
    loading,
    pendingRequests.length,
    selectedTab,
    setSelectedTab,
  ]);

  const renderBookingRequests = () => {
    let filteredArchivedRequests = archivedRequests;
    if (!showCancelAndExpire) {
      filteredArchivedRequests = archivedRequests.filter(
        (aR) =>
          aR.status !== BookingRequestStatus.Cancelled &&
          aR.status !== BookingRequestStatus.Expired,
      );
    }

    return (
      <BookingRequestsTable
        pendingRequests={pendingRequests}
        archivedRequests={filteredArchivedRequests}
        show={selectedTab}
        refetch={refetch}
      />
    );
  };

  return (
    <BookingRequestsLayout>
      <>
        <View
          style={[styles.tabWrapper, isDesktop && styles.desktopTabWrapper]}
        >
          <BookingRequestsTab value={selectedTab} onChange={setSelectedTab} />
          {selectedTab === BookingRequestsTabName.Archived && isDesktop && (
            <LabeledSwitch
              label="Show canceled and expired requests"
              value={showCancelAndExpire}
              onChange={setShowCancelAndExpire}
              leftLabel
            />
          )}
        </View>
        <View>
          <AsyncRenderer
            error={error}
            loading={loading}
            data={data}
            loadingHandler={() => (
              <ContentLoader
                speed={2}
                width="100%"
                height={500}
                backgroundColor="#f7f7f7"
                foregroundColor="#ecebeb"
              >
                <Rect width="100%" height={500} />
              </ContentLoader>
            )}
          >
            {renderBookingRequests}
          </AsyncRenderer>
        </View>
      </>
    </BookingRequestsLayout>
  );
};

const styles = StyleSheet.create({
  tabWrapper: {
    marginBottom: 24,
  },
  desktopTabWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
