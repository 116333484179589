import { useState } from "react";
import pluralize from "pluralize";
import { StyleSheet, View } from "react-native";

import { useToast } from "providers/toast";
import { colors } from "components/colors";
import { Spacer } from "components/spacer";
import { SpaceType } from "core/graphql.generated";
import { InviteOthersDialog } from "pages/bookings/components/invite_others_dialog";
import { useBookingInvitation } from "pages/bookings/hooks/useBookingInvitation";
import { TextLink } from "components/text_link";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { Button } from "components/button_v2";
import { Text } from "components/text_v2";
import { useAnalytics } from "providers/analytics";

import { formatDateTimeRange } from "../utils/formatDateTimeRange";
import { getInvitedPeopleDescription } from "../utils/getInvitedPeopleDescription";

interface BookingDetailInviteBannerProps {
  remainingInvites: number;
  invitees: { name: string; email: string }[];
  bookingId: string;
  spaceId: string;
  spaceName: string;
  spaceType: SpaceType;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  onInviteComplete?: () => void;
}

export function BookingDetailInviteBanner({
  remainingInvites,
  invitees,
  bookingId,
  spaceId,
  spaceName,
  spaceType,
  startDate,
  endDate,
  startTime,
  endTime,
  onInviteComplete,
}: BookingDetailInviteBannerProps) {
  const toast = useToast();
  const analytics = useAnalytics();

  const [showAllInviteesModal, setShowAllInviteesModal] = useState(false);

  const { openDialog } = useBookingInvitation();

  const handleClickCopyLink = () => {
    navigator.clipboard.writeText(
      `Hi there! I’ve booked ${spaceName} on Flexspace and want you to come work with me on ${formatDateTimeRange(
        "MMM d",
        "-",
        startDate,
        endDate,
        startTime,
        endTime,
      )}. ${location.origin}/offsite_spaces/${spaceId}`,
    );
    toast.notify({ message: "Link copied!" });
    analytics.event("Copy Share Link", {
      category: "Post booking",
      Source: "Booking Details",
    });
  };

  if (spaceType === SpaceType.DayPass) {
    return (
      <View style={styles.root}>
        <Text size="md" weight="semibold">
          Share this space
        </Text>
        <Spacer size={8} />
        <Text size="xs">Others can book a spot with the link.</Text>
        <Spacer size={16} />
        <Button
          iconName="link"
          text="Copy share link"
          onPress={handleClickCopyLink}
          appearance="secondary"
        />
      </View>
    );
  }

  const handleInviteComplete = () => {
    analytics.event("Send Invitations", {
      category: "Post booking",
      Source: "Booking Details",
    });
    if (onInviteComplete) {
      onInviteComplete();
    }
  };

  return (
    <View style={styles.root}>
      <Text size="md" weight="semibold">
        Who else is coming?
      </Text>
      <Spacer size={8} />
      <Text size="xs">
        {getInvitedPeopleDescription(invitees.map((i) => i.name))}
        {invitees.length > 2 && (
          <TextLink
            size="xs"
            weight="semibold"
            text=" See all"
            decoration="none"
            inline
            onPress={() => setShowAllInviteesModal(true)}
          />
        )}
      </Text>
      {remainingInvites > 0 && (
        <>
          <Spacer size={16} />
          <Button
            iconName="userPlus"
            text="Invite others"
            appearance="secondary"
            onPress={() => openDialog(bookingId, remainingInvites)}
          />
          <Spacer size={8} />
          <Text align="center" size="xs" color="black-70">
            You can invite up to {remainingInvites}{" "}
            {pluralize("person", remainingInvites)}
          </Text>
        </>
      )}
      <InviteOthersDialog onInviteComplete={handleInviteComplete} />
      <DialogModal
        isVisible={showAllInviteesModal}
        headerTitle="All invited coworkers"
        showCloseButton
        onClose={() => setShowAllInviteesModal(false)}
      >
        <View>
          {invitees.map((i, index) => (
            <View key={i.email}>
              <View style={styles.modalInvieeWrapper}>
                <View style={styles.modalInvieeDataWrapper}>
                  <Text size="xs">{i.name}</Text>
                </View>
                <View style={styles.modalInvieeDataWrapper}>
                  <Text size="xs">{i.email}</Text>
                </View>
              </View>
              {index !== invitees.length - 1 && (
                <>
                  <Spacer size={8} />
                  <Divider />
                  <Spacer size={8} />
                </>
              )}
            </View>
          ))}
        </View>
      </DialogModal>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.brand.eggplantMinus90,
    borderRadius: 4,
    padding: 24,
  },
  modalInvieeWrapper: {
    flexDirection: "row",
    gap: 20,
  },
  modalInvieeDataWrapper: {
    flex: 1,
  },
});
