import { colors } from "components/colors";

import { Text } from "components/text_v2";
import pluralize from "pluralize";
import { StyleSheet, View } from "react-native";

interface PropsType {
  days: number;
}

export const ExpireDaysBadge: React.FC<PropsType> = ({ days }) => {
  return (
    <View style={days <= 2 && styles.highlighted}>
      <Text size={"xs"}>
        {days === 0 ? "Today" : `${days} ${pluralize("day", days)}`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  highlighted: {
    backgroundColor: colors.brand.bananaMinus50,
    padding: 4,
    width: "fit-content",
    borderRadius: 4,
  },
});
