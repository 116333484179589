import { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";

import { DialogModal } from "components/dialog_modal";
import { TextInput } from "components/text_input";
import { Button } from "components/button_v2";
import { Text } from "components/text_v2";
import { Spacer } from "components/spacer";
import { useForm } from "hooks/use_form";
import { isEmail } from "lib/string_utils";
import { useMediaQuery } from "lib/media_query";
import { InviteRecipientInput } from "core/graphql.generated";
import { Spinner } from "components/spinner";

import { useBookingInvitation } from "../hooks/useBookingInvitation";

interface InviteOthersFormValues {
  invitees: InviteRecipientInput[];
  message: string;
}

interface InviteOthersDialogProps {
  onInviteComplete: () => void;
}

export function InviteOthersDialog({
  onInviteComplete,
}: InviteOthersDialogProps) {
  const mq = useMediaQuery();
  const {
    isDialogVisible,
    sendInvites,
    closeDialog,
    remainingInviteSpots,
    remainingInviteLoading,
  } = useBookingInvitation();

  const [formError, setFormError] = useState<null | string>(null);

  const initialValues: InviteOthersFormValues = useMemo(() => {
    const invitees = [];
    for (let index = 0; index < remainingInviteSpots; index++) {
      invitees.push({ name: "", email: "" });
    }
    return { invitees, message: "" };
  }, [remainingInviteSpots]);

  const { values, setFieldValue, submit } = useForm({
    initialValues: initialValues,
    reinitialize: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { invitees, message } = values;
      const inviteeInput = invitees.filter(
        (i) => !!i.name.trim() && isEmail(i.email),
      );
      if (inviteeInput.length === 0) {
        setFormError("Please add at least 1 invitee's name and email");
      } else {
        setFormError(null);
        await sendInvites({
          message: message,
          recipients: inviteeInput,
        });
        onInviteComplete();
      }
    },
  });

  const handleUpdateInvitee = (
    index: number,
    key: "name" | "email",
    value: string,
  ) => {
    const updated = [...values.invitees];
    updated[index][key] = value;
    setFieldValue("invitees", updated);
  };

  const renderInviteeInputs = () => {
    if (remainingInviteLoading) {
      return <Spinner />;
    }
    return values.invitees.map((invitee, index) => (
      <View key={index}>
        <Spacer size={24} />
        <View style={styles.personInputsWrapper}>
          <View style={styles.personInputWrapper}>
            <TextInput
              value={invitee.name}
              onChange={(value) => handleUpdateInvitee(index, "name", value)}
              placeholder="First and last name"
            />
          </View>
          <View style={styles.personInputWrapper}>
            <TextInput
              value={invitee.email}
              onChange={(value) => handleUpdateInvitee(index, "email", value)}
              placeholder="name@company.com"
            />
          </View>
        </View>
      </View>
    ));
  };

  return (
    <DialogModal
      isVisible={isDialogVisible}
      onClose={closeDialog}
      headerTitle="Invite others"
      desktopWidth={780}
      bottomButtons={
        <Button
          text="Send invitations"
          size={mq.deviceQuery.mobile ? "auto" : "medium"}
          onPress={submit}
        />
      }
    >
      <Text size="xs">
        Fill in the information below to send an email invitation to your
        coworkers with details and directions for your booking.
      </Text>
      {renderInviteeInputs()}
      <Spacer size={24} />
      <TextInput
        value={values.message}
        onChange={(value) => setFieldValue("message", value)}
        placeholder="Message (optional)"
        numberOfLines={3}
      />
      <Spacer size={10} />
      {formError && (
        <Text size="xs" color="grapefruit-core">
          {formError}
        </Text>
      )}
      <Spacer size={100} />
    </DialogModal>
  );
}

const styles = StyleSheet.create({
  personInputsWrapper: {
    flexDirection: "row",
    gap: 8,
  },
  personInputWrapper: {
    flex: 1,
  },
});
