import { CardsSaveIcon } from "components/cards_save_icon";
import { colors } from "components/colors";
import { WhoIsHere } from "components/who_is_here";
import {
  SpaceType,
  TeamsLocationDetailsFragment,
  TeamsSpaceDetailsFragment,
  TeamsUserDetailsFragment,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import {
  SpaceCardAddressWithDistance,
  SpaceCardContent,
  SpaceCardPhoto,
  SpaceCardTitleWithCapacity,
  SpaceCardWhoIsHere,
} from "pages/homev2/components/space_card_v2/space_card_template";
import { FavoriteType } from "pages/homev2/hooks/use_save_favorite";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useMyTeamSearchParamsQuery } from "../hooks/use_my_team_search_params";

interface ListingSpaceCardProps {
  space: TeamsSpaceDetailsFragment;
  peoples: TeamsUserDetailsFragment[];
  onPreviewLocation: (location: TeamsLocationDetailsFragment) => void;
}

export function TeamSpaceCard({
  space,
  peoples,
  onPreviewLocation,
}: ListingSpaceCardProps) {
  let title, locationName;
  const mq = useMediaQuery();
  const { date } = useMyTeamSearchParamsQuery();
  if (space.spaceType === SpaceType.DayOffice) {
    title = space.location.name;
  } else if (space.spaceType === SpaceType.MeetingRoom) {
    title = space.name;
    locationName = space.location.name;
  } else {
    title = space.location.name;
  }

  return (
    <Pressable
      onPress={() => onPreviewLocation(space.location)}
      style={[styles.card, mq.deviceQuery.mobile && styles.cardMobile]}
    >
      <View style={styles.relativeContainer}>
        <SpaceCardPhoto imageSrc={space.images[0].small.url} />
        <CardsSaveIcon
          favoriteId={space.id}
          favoriteType={FavoriteType.Space}
        />
      </View>
      <SpaceCardContent>
        <SpaceCardTitleWithCapacity title={title} />
        <SpaceCardAddressWithDistance
          currentUserDistance={space.location.currentUserDistance}
          shortAddress={space.location.address.city}
          fullAddress={space.location.address.streetAddress}
          locationName={locationName}
        />
      </SpaceCardContent>
      <SpaceCardWhoIsHere>
        <WhoIsHere date={date} peoples={peoples} />
      </SpaceCardWhoIsHere>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  card: {
    width: 320,
    flex: 1,
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  cardMobile: {
    width: 300,
  },
  relativeContainer: {
    position: "relative",
  },
});
