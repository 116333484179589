import { useCallback, useRef, useState } from "react";
import "react-spring-bottom-sheet/dist/style.css";
import { BottomSheet } from "react-spring-bottom-sheet";
import type { RefHandles } from "react-spring-bottom-sheet/dist/types";
import { BottomDrawerPropTypes } from "./bottom_drawer";

const MOBILE_HEADER_HEIGHT = 176;

export const BottomDrawer = ({
  dismissable = false,
  blocking = false,
  header,
  footer,
  children,
  onDismiss,
  onExpand,
  snapPoints,
}: BottomDrawerPropTypes) => {
  const ref = useRef<RefHandles>(null);
  const [open, setOpen] = useState(true);
  const handleDismiss = useCallback(() => {
    setOpen(false);
    onDismiss?.();
  }, [onDismiss]);

  return (
    <BottomSheet
      ref={ref}
      open={open}
      expandOnContentDrag
      onDismiss={dismissable ? handleDismiss : undefined}
      blocking={blocking}
      header={header}
      footer={footer}
      snapPoints={
        snapPoints
          ? snapPoints
          : ({ maxHeight }) => [maxHeight / 10, maxHeight / 2, maxHeight]
      }
      onSpringStart={() => {
        requestAnimationFrame(() => {
          if (
            ref.current?.height ===
            window.innerHeight - MOBILE_HEADER_HEIGHT
          ) {
            // give time to finish animation
            setTimeout(() => {
              onExpand?.();
            }, 500);
          }
        });
      }}
    >
      {children}
    </BottomSheet>
  );
};
