import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { Divider } from "components/divider";
import { GridV2 } from "components/gridv2";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { Amenity } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { sortAmenities } from "lib/sort_amenities";
import { useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";

const N_OF_AMENITIES_TO_SHOW_DESKTOP = 10;
const N_OF_AMENITIES_TO_SHOW_MOBILE = 5;

interface AmenitiesProps {
  spaceAmenities: Amenity[];
  locationAmenities: Amenity[];
}

export function Amenities(props: AmenitiesProps) {
  const { spaceAmenities = [], locationAmenities = [] } = props;
  const amenities = useMemo(
    () => sortAmenities(spaceAmenities, locationAmenities),
    [locationAmenities, spaceAmenities],
  );
  const [showMore, setShowMore] = useState(false);
  const mq = useMediaQuery();
  const onShowMorePress = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);
  const isMobile = mq.deviceQuery.mobile;
  const numOfAmenitiesToShow = isMobile
    ? N_OF_AMENITIES_TO_SHOW_MOBILE
    : N_OF_AMENITIES_TO_SHOW_DESKTOP;
  const showedAmenities = amenities?.slice(0, numOfAmenitiesToShow);
  const shouldViewShowMoreButton = amenities.length > numOfAmenitiesToShow;

  return (
    <>
      <View>
        <Text size="md" weight="semibold">
          Amenities
        </Text>
        <Spacer size={isMobile ? 16 : 24} />

        <GridV2 columns={isMobile ? 1 : 2} rowGap={8}>
          {showedAmenities.map((amenity) => (
            <Text size="xs" key={amenity.id}>
              {amenity.name}
            </Text>
          ))}
        </GridV2>
        {shouldViewShowMoreButton && (
          <>
            <Spacer size={12} />
            <View
              style={
                isMobile ? styles.showMoreButtonMobile : styles.showMoreButton
              }
            >
              <Button
                onPress={onShowMorePress}
                text={`Show all ${amenities.length} amenities`}
                appearance="secondary"
              />
            </View>
          </>
        )}
      </View>
      <DialogModal
        onClose={() => setShowMore(false)}
        isVisible={showMore}
        headerTitle="Amenities"
        desktopWidth={780}
      >
        <Spacer size={24} />
        {!!spaceAmenities.length && (
          <>
            <Text weight="bold" size="md">
              Essentials
            </Text>
            <Spacer size={24} />
            <GridV2 columns={mq.deviceQuery.mobile ? 1 : 2}>
              {spaceAmenities.map((amenity) => (
                <Text size="xs" key={amenity.id}>
                  {amenity.name}
                </Text>
              ))}
            </GridV2>
          </>
        )}
        {!!locationAmenities.length && (
          <>
            {!!spaceAmenities.length && (
              <>
                <Spacer size={24} />
                <Divider />
                <Spacer size={24} />
              </>
            )}
            <Text weight="bold" size="md">
              Location features
            </Text>
            <Spacer size={24} />
            <GridV2 columns={mq.deviceQuery.mobile ? 1 : 2}>
              {locationAmenities.map((amenity) => (
                <Text size="xs" key={amenity.id}>
                  {amenity.name}
                </Text>
              ))}
            </GridV2>
          </>
        )}
      </DialogModal>
    </>
  );
}

const styles = StyleSheet.create({
  showMoreButton: {
    width: 320,
  },
  showMoreButtonMobile: {
    width: "100%",
  },
});
