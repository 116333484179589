import React from "react";

interface ResponsiveGridProps {
  children?: React.ReactNode;
  verticalSpacing?: number;
  horizontalSpacing?: number;
  itemsPerRow: number;
}

export function ResponsiveGrid(props: ResponsiveGridProps) {
  const {
    children,
    verticalSpacing = 16,
    horizontalSpacing = 8,
    itemsPerRow,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: itemsPerRow > 1 ? -horizontalSpacing : 0,
        marginRight: itemsPerRow > 1 ? -horizontalSpacing : 0,
      }}
    >
      {React.Children.map(children, (child) => {
        if (!child) {
          return null;
        }

        return (
          <div
            style={{
              width: `${100 / itemsPerRow}%`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                paddingLeft: itemsPerRow > 1 ? horizontalSpacing : 0,
                paddingRight: itemsPerRow > 1 ? horizontalSpacing : 0,
                paddingBottom: verticalSpacing,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              {child}
            </div>
          </div>
        );
      })}
    </div>
  );
}
