import { Icon } from "components/iconv2";
import { TextLink } from "components/text_link";

import { Text } from "components/text_v2";
import { format } from "date-fns";
import { parseDay } from "lib/day_utils";
import { useMediaQuery } from "lib/media_query";
import { Pressable, StyleSheet, View } from "react-native";
import { HandleReview } from "../hooks/use_booking_request_review";

import { BookingRequestTableRaw } from "./booking_request_table_raw";
import { EmptyRequests } from "./empty_requests";
import { ExpireDaysBadge } from "./expire_days_badge";
import { PendingDetailCard } from "./pending_detail_card";
import { PendingRequestsTableMobile } from "./pending_requests_table_mobile";
import { RequestActions } from "./request_actions";

const pendingColsDesktop = [
  { id: "request", title: "Request", width: "30%" },
  { id: "amount", title: "Amount", width: "15%" },
  { id: "expireIn", title: "Expires in", width: "15%" },
  { id: "requestedOn", title: "Requested on", width: "15%" },
  { id: "actions", title: "", width: "25%" },
];

export interface TableRowActionPropsType {
  onChangeExtend: () => void;
  isExtended: boolean;
  onReview: HandleReview;
  loadingApproveRequest: boolean;
  loadingDeclineRequest: boolean;
}

export interface RequestInfo {
  id: string;
  requestUsername: string;
  spaceName: string;
  spaceType: string;
  spaceLocation: string;
  amount: string;
  requestedOn: string;
  reason: string;
}

export interface AssociatedBudget {
  name: string;
  href?: string;
}

export interface PendingRequest extends RequestInfo {
  expireInDays: number;
  associatedBudget?: AssociatedBudget;
  userSpent: string;
  userApprovedRequests: string;
}

interface PropsType {
  rows: PendingRequest[];
  refetch: () => void;
}

export const PendingRequestsTable: React.FC<PropsType> = ({
  rows,
  refetch,
}) => {
  const {
    deviceQuery: { mobile: isMobile },
  } = useMediaQuery();

  if (rows.length === 0) {
    return (
      <EmptyRequests
        title="You're all caught up!"
        description="As soon as someone requests to book a space, we’ll show it here."
      />
    );
  }

  const sortedRows = rows.sort((a, b) => a.expireInDays - b.expireInDays);

  if (isMobile) {
    return (
      <PendingRequestsTableMobile requests={sortedRows} refetch={refetch} />
    );
  }

  const renderedRows = sortedRows.map(
    ({
      id,
      requestUsername,
      spaceName,
      spaceType,
      spaceLocation,
      amount,
      expireInDays,
      requestedOn,
      reason,
      associatedBudget,
      userSpent,
      userApprovedRequests,
    }) => {
      return {
        id,
        request: renderRequestInfoCell(
          requestUsername,
          spaceName,
          spaceType,
          spaceLocation,
        ),
        amount,
        expireIn: <ExpireDaysBadge days={expireInDays} />,
        requestedOn: format(parseDay(requestedOn), "MMM d"),
        actions: {
          render: ({
            onChangeExtend,
            isExtended,
            onReview,
            loadingApproveRequest,
            loadingDeclineRequest,
          }: TableRowActionPropsType) => {
            return (
              <View style={styles.pendingActionsWrapper}>
                <RequestActions
                  onReview={onReview}
                  loadingApproveRequest={loadingApproveRequest}
                  loadingDeclineRequest={loadingDeclineRequest}
                  username={requestUsername}
                />
                <Pressable onPress={onChangeExtend} style={styles.extendButton}>
                  <Icon
                    name={isExtended ? "chevron-up" : "chevron-down"}
                    size="md"
                  />
                </Pressable>
              </View>
            );
          },
          refetch,
        },
        extension: (
          <View style={styles.extensionWrapper}>
            <Text>
              <Text weight="semibold" size="xs">
                Reason for request:
              </Text>
              <Text size="xs"> "{reason}"</Text>
            </Text>
            <View style={styles.pendingDetailCardsWrapper}>
              {associatedBudget && (
                <View style={styles.pendingDetailCardWrapper}>
                  <PendingDetailCard
                    title="Associated budget"
                    detail={
                      associatedBudget.href ? (
                        <TextLink
                          text={associatedBudget.name}
                          size="md"
                          weight="semibold"
                          href={associatedBudget.href}
                        />
                      ) : (
                        <Text>{associatedBudget.name}</Text>
                      )
                    }
                  />
                </View>
              )}
              <View style={styles.pendingDetailCardWrapper}>
                <PendingDetailCard
                  title="Amount spent by employee"
                  detail={
                    <Text>
                      <Text weight="semibold" size="md">
                        {userSpent}
                      </Text>
                      <Text size="xs"> this month</Text>
                    </Text>
                  }
                />
              </View>
              <View style={styles.pendingDetailCardWrapper}>
                <PendingDetailCard
                  title="Approved requests from employee"
                  detail={
                    <Text>
                      <Text weight="semibold" size="md">
                        {userApprovedRequests}
                      </Text>
                      <Text size="xs"> this month</Text>
                    </Text>
                  }
                />
              </View>
            </View>
          </View>
        ),
      };
    },
  );

  return (
    <BookingRequestTableRaw columns={pendingColsDesktop} rows={renderedRows} />
  );
};

export const renderRequestInfoCell = (
  requestUsername: string,
  spaceName: string,
  spaceType: string,
  spaceLocation: string,
) => (
  <View style={styles.requestInfoWrapper}>
    <Text size="xs" weight="semibold">
      {requestUsername}
    </Text>
    <Text size="xs" color="black-70">
      {spaceName} · {spaceType}
    </Text>
    <Text size="xs" color="black-70">
      {spaceLocation}
    </Text>
  </View>
);

export const styles = StyleSheet.create({
  requestInfoWrapper: {
    flexDirection: "column",
    gap: 4,
  },
  pendingActionsWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
  },
  extensionWrapper: { width: "100%" },
  pendingDetailCardsWrapper: {
    flexDirection: "row",
    gap: "2%",
    marginTop: 16,
  },
  pendingDetailCardWrapper: {
    width: "32%",
  },
  extendButton: {
    padding: 12,
  },
});
