export const typography = {
  textHeadingFontFamily: `"Silka", sansSerif`,
  textTextFontFamily: `-appleSystem, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sansSerif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,

  textSizeMegaFontSize: 41,
  textSizeMegaLineHeight: 1.2,
  textSizeXlFontSize: 35,
  textSizeXlLineHeight: 1.2,
  textSizeLgFontSize: 28,
  textSizeLgLineHeight: 1.2,
  textSizeMdFontSize: 20,
  textSizeMdLineHeight: 1.2,
  textSizeSmFontSize: 16,
  textSizeSmLineHeight: 1.2,
  textSizeXsFontSize: 14,
  textSizeXsLineHeight: 1.2,
  textSizeMicroFontSize: 12,
  textSizeMicroLineHeight: 1.2,

  textWeightBold: "700",
  textWeightSemibold: "600",
  textWeightRegular: "400",
  textWeightLight: "274",
};
