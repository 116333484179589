import { Button } from "components/button_v2";
import { DialogModal } from "components/dialog_modal";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";

interface ExistingPolicyBudgetDialogWarningPropTypes {
  onCancel: () => void;
  onConfirm: () => void;
  open?: boolean;
}

export const ExistingPolicyBudgetWarningDialog: React.FC<
  ExistingPolicyBudgetDialogWarningPropTypes
> = ({ onCancel, onConfirm, open = false }) => {
  const {
    deviceQuery: { mobile: isMobile },
  } = useMediaQuery();
  return (
    <DialogModal
      isVisible={open}
      onClose={onCancel}
      headerTitle="Change policies applied to existing budgets?"
      showCloseButton={false}
      bottomButtons={
        <View
          style={
            isMobile
              ? styles.confirmActionsMobile
              : styles.confirmActionsDesktop
          }
        >
          <View style={styles.confirmBtn}>
            <Button text="Cancel" appearance="secondary" onPress={onCancel} />
          </View>
          <View style={styles.confirmBtn}>
            <Button
              testID="apply-policy-existing-budgets-confirm-button"
              text="Yes, change the policies"
              appearance="primary"
              onPress={onConfirm}
            />
          </View>
        </View>
      }
    >
      <Text>
        Some of the budgets you would like to update already have policies
        applied to them. Changing the policy means members of these budgets will
        now be able to book spaces following this new policy.
      </Text>
    </DialogModal>
  );
};

const styles = StyleSheet.create({
  confirmActionsDesktop: {
    flexDirection: "row",
    paddingLeft: 8,
    paddingRight: 8,
    width: "100%",
    gap: 24,
  },
  confirmActionsMobile: {
    flexDirection: "column-reverse",
    gap: 8,
  },
  confirmBtn: {
    flex: 1,
  },
});
