import { Amenities } from "pages/homev3/components/amenities";
import { ExploreWorkSpaceByCity } from "pages/homev3/components/explore_workspace_by_city";
import { FlexSpaceForGoogleCalendar } from "pages/homev3/components/flexspace_for_google_calendar";
import { InviteCoWorker } from "pages/homev3/components/invite_co_worker";
import { JoinYourTeam } from "pages/homev3/components/join_your_team";
import { PopularInCity } from "pages/homev3/components/popular_in_city";
import { WhatFlexSpaceOffer } from "pages/homev3/components/what_flexspace_offer";
import { WorkNearBy } from "pages/homev3/components/work_near_by";
import { HomeContentProps } from "pages/homev3/home";
import React from "react";
import { StyleSheet, View } from "react-native";
import { RecentlyViewed } from "../components/recently_viewed";
import { SavedForLater } from "../components/saved_for_later";

export function NewUser({ currentUser }: HomeContentProps) {
  return (
    <View style={styles.container}>
      <RecentlyViewed />
      {currentUser && <SavedForLater />}
      <WorkNearBy />
      {currentUser && <JoinYourTeam />}
      {currentUser && <InviteCoWorker />}
      <PopularInCity />
      <Amenities />
      <WhatFlexSpaceOffer />
      <FlexSpaceForGoogleCalendar />
      <ExploreWorkSpaceByCity />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 40,
    gap: 40,
  },
});
