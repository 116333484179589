import { useCallback, useRef, useState } from "react";
import { Spacer } from "components/spacer";
import { tokens } from "components/tokens";
import { formatDate } from "lib/date_utils";
import { Day, parseDay } from "lib/day_utils";
import { getSystemLocale } from "lib/locale";
import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { FilterDatePicker } from "core/filter_date_picker";
import { Divider } from "components/divider";
import { colors } from "components/colors";

interface DatePickerMobileProps {
  value?: Day | null;
  onChange: (newValue: Day) => void;
}

export function FilterDatePickerMobile(props: DatePickerMobileProps) {
  const { value, onChange } = props;
  const [open, setOpen] = useState(false);
  const datePickerElemRef = useRef<View>(null);
  const handleOpen = useCallback(() => {
    setOpen(true);
    setTimeout(() => {
      // @ts-ignore
      datePickerElemRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 0);
  }, []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleSave = useCallback(
    (v: Day) => {
      setOpen(false);
      onChange(v);
    },
    [onChange],
  );

  return (
    <>
      <View style={styles.wrapper}>
        <Text size="xs">Date</Text>
        <Pressable
          style={styles.button}
          onPress={open ? handleClose : handleOpen}
        >
          <Text size="xs">
            {value
              ? formatDate(parseDay(value), getSystemLocale(), {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : "Any date"}
          </Text>
        </Pressable>
      </View>

      {open && (
        <>
          <Spacer size={7} />
          <Divider />
          <Spacer size={11} />
          <FilterDatePicker value={value} onChange={handleSave} />
          <View ref={datePickerElemRef} />
          <Spacer size={6} />
          <Pressable
            onPress={() => {
              onChange("");
              handleClose();
            }}
          >
            <Text
              align="center"
              size="xs"
              color="eggplant-core"
              weight="semibold"
            >
              Clear date
            </Text>
          </Pressable>
          <Spacer size={4} />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  dialogContentWrapper: {
    borderRadius: tokens.radius,
    customColor: "white",
    borderWidth: 1,
    flex: 1,
    ...tokens.shadow.elevation3,
  },
  dialogContent: {
    flex: 1,
    padding: 24,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "white",
    borderRadius: 4,
    paddingVertical: 7,
    paddingHorizontal: 16,
    border: `1px solid ${colors.brand.blackMinus90}`,
  },
});
