import { ApolloError, gql, useQuery } from "@apollo/client";

import {
  GetOrgBookingQuery,
  GetOrgBookingQueryVariables,
  OrgBookingFragment,
} from "core/graphql.generated";
import { toDay } from "lib/day_utils";
import {
  bookingSpaceDetailFragment,
  spaceLocationDetailFragment,
} from "pages/homev3/fragment";
import { useMemo } from "react";
import { useUserCurrentLocation } from "./use_user_current_location";
import { toCoordinates } from "../../homev2/mapbox";

interface OrgBookingQueryData {
  __typename?: "Query";
  bookings: OrgBookingFragment[];
}
interface OrgBookingQueryReturn {
  data: OrgBookingQueryData;
  loading: boolean;
  error: ApolloError | undefined;
}

type BookingQueryProps = {
  startDate?: string;
  endDate?: string;
};
export function useOrgBookingQuery({
  startDate,
  endDate,
}: BookingQueryProps): OrgBookingQueryReturn {
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useUserCurrentLocation();
  const variables: GetOrgBookingQueryVariables | null = useMemo(() => {
    if (!currentUserCoordinates || currentUserLoading) {
      return null;
    }
    return {
      currentUserCoordinates: toCoordinates(currentUserCoordinates.center),
      startDate: startDate || toDay(new Date()),
      endDate: endDate || toDay(new Date()),
    };
  }, [currentUserCoordinates, currentUserLoading, endDate, startDate]);

  const { data, loading, error } = useQuery<
    GetOrgBookingQuery,
    GetOrgBookingQueryVariables
  >(getOrgBooking, {
    variables: variables!,
    skip: !variables,
  });

  return useMemo(
    () => ({
      data: {
        __typename: "Query",
        bookings:
          data?.currentUser?.organization?.bookings.filter(
            (b) => b.user.userId !== data?.currentUser?.id,
          ) || [],
      },
      loading: loading || currentUserLoading,
      error,
    }),
    [
      currentUserLoading,
      data?.currentUser?.id,
      data?.currentUser?.organization?.bookings,
      error,
      loading,
    ],
  );
}

const getOrgBooking = gql`
  ${spaceLocationDetailFragment}
  ${bookingSpaceDetailFragment}
  query GetOrgBooking(
    $startDate: String!
    $endDate: String!
    $currentUserCoordinates: SpaceSearchCoordinates
  ) {
    currentUser {
      id
      organization {
        bookings(startDate: $startDate, endDate: $endDate) {
          ...OrgBooking
        }
      }
    }
  }
  fragment OrgBooking on Booking {
    id
    user {
      id
      userId
      picture
      fullName
    }
    space {
      ...BookingSpaceDetail
    }
  }
`;
