import { StyleSheet, View } from "react-native";

import { BookingHour } from "core/graphql.generated";
import { operatingHoursContent } from "lib/operating_hours";
import { Text } from "components/text_v2";
import { useMediaQuery } from "lib/media_query";

import { SpaceDetail } from "./space_detail";

interface SpaceDetailHoursProps {
  hours: BookingHour[];
}

export function SpaceDetailHours({ hours }: SpaceDetailHoursProps) {
  const mq = useMediaQuery();

  return (
    <SpaceDetail title="Hours">
      <View>
        {operatingHoursContent(hours).map((operatingHour) => (
          <View
            key={operatingHour.label}
            style={[
              styles.flexWrapper,
              mq.deviceQuery.mobile && styles.flexWrapperMobile,
            ]}
          >
            <View style={!mq.deviceQuery.mobile && styles.flexCol}>
              <Text size="xs">{operatingHour.label}</Text>
            </View>
            <View style={!mq.deviceQuery.mobile && styles.flexCol}>
              <Text size="xs" color="black-70">
                {operatingHour.open} - {operatingHour.close}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </SpaceDetail>
  );
}

const styles = StyleSheet.create({
  flexWrapper: {
    flexDirection: "row",
    width: "100%",
  },
  flexWrapperMobile: {
    justifyContent: "space-between",
  },
  flexCol: {
    flex: 1,
  },
});
