import { StyleSheet, View } from "react-native";
import { useTab } from "hooks/use_tab";
import {
  SavedPlacesTab,
  SavedPlacesTabName,
} from "./components/saved_places_tab";
import { SavedPlacesLayout } from "./saved_places_layout";
import { SavedSpacesList } from "./components/saved_spaces_list";
import { SavedLocationsList } from "./components/saved_locations_list";
import { TabOption } from "components/tab/tabs_v3";
import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";

const tabOptions: TabOption<SavedPlacesTabName>[] = [
  {
    label: SavedPlacesTabName.Spaces,
    value: SavedPlacesTabName.Spaces,
    testID: "saved-spaces-tab",
  },
  {
    label: SavedPlacesTabName.Locations,
    value: SavedPlacesTabName.Locations,
    testID: "saved-locations-tab",
  },
];

export const SavedPlaces = () => {
  const apolloClient = useApolloClient();
  const [selectedTab, setSelectedTab] = useTab([
    SavedPlacesTabName.Spaces,
    SavedPlacesTabName.Locations,
  ]);

  useEffect(() => {
    apolloClient.resetStore();
  }, [apolloClient]);

  return (
    <SavedPlacesLayout>
      <View>
        <View style={styles.tabWrapper}>
          <SavedPlacesTab
            options={tabOptions}
            value={selectedTab}
            onChange={setSelectedTab}
          />
        </View>
        {selectedTab === SavedPlacesTabName.Spaces ? (
          <SavedSpacesList />
        ) : (
          <SavedLocationsList />
        )}
      </View>
    </SavedPlacesLayout>
  );
};

const styles = StyleSheet.create({
  tabWrapper: {
    marginBottom: 24,
  },
});
