import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { CURRENT_URL } from "components/app_header_v3/desktop_header";
import {
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
  AuthLoaderQuery,
  AuthMethod,
  LinkGoogleAccountToPasswordAccountIfExistsMutation,
  OrgByDomainQuery,
  OrgByDomainQueryVariables,
  RegisterGoogleAccountToOrganizationIfExistsMutation,
  RegisterOktaAccountToOrganizationIfExistsMutation,
  RegisterPasswordAccountToOrganizationIfExistsMutation,
} from "core/graphql.generated";
import { useFavoriteIdsVariable } from "hooks/use_reactive_favs";
import { logger } from "lib/logger";
import { getDomainFromEmail } from "lib/string_utils";
import { AcceptInviteCredentials } from "pages/accept_invite/accept_invite";
import { SourceFrom } from "pages/custom_org_sign_in/custom_org_sign_in";
import { useAnalytics } from "providers/analytics";
import { POST_AUTHENTICATION_KEY, useAuthV2 } from "providers/authv2";
import {
  useCustomSSOFeatureFlag,
  useOpenDirectoryFeatureFlag,
} from "providers/splitio";
import { useToast } from "providers/toast";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface AuthLoaderProps {
  children: React.ReactNode;
}

// used for steps in sign-up and accept-invite flows
// todo: encapsulate into object with methods.
export const SIGN_UP_STEPS_REDIRECT_URL_KEY = "SIGN_UP_STEPS_REDIRECT_URL_KEY";

// used for deep linking. keeps redirectUrl to send the user to,
// after they complete sign-in/sign-up.
// Please keep it incapsulated in postAuthRedirectUrl.
const POST_AUTHENTICATION_REDIRECT_URL_KEY =
  "POST_AUTHENTICATION_REDIRECT_URL_KEY";
export const postAuthRedirectUrl = {
  get() {
    const result = localStorage.getItem(POST_AUTHENTICATION_REDIRECT_URL_KEY);
    logger.debug(`postAuthRedirectUrl get: ${result}`);
    return result;
  },
  reset() {
    logger.debug("postAuthRedirectUrl reset");
    return localStorage.removeItem(POST_AUTHENTICATION_REDIRECT_URL_KEY);
  },
  set(value: string) {
    logger.debug(`postAuthRedirectUrl set: ${value}`);
    return localStorage.setItem(POST_AUTHENTICATION_REDIRECT_URL_KEY, value);
  },
};

// After user logs in, we need to determine where the user should land
// and yield blank page until it becomes resolved
export function AuthLoader(props: AuthLoaderProps) {
  const { children } = props;
  const { authenticated } = useAuthV2();
  const analytics = useAnalytics();
  const sSOFeatureFlag = useCustomSSOFeatureFlag();
  const openDirectoryFeatureFlag = useOpenDirectoryFeatureFlag();
  // We need to know where the user should land next before we render the application
  const [resolved, setResolved] = useState(false);
  const history = useHistory();

  // Default we will navigate to the homepage after successful auth
  // but with the new flow user can log in from other page like checkout so we restore to that page
  let targetPage = "/";

  if (openDirectoryFeatureFlag && localStorage.getItem(CURRENT_URL)) {
    targetPage = localStorage.getItem(CURRENT_URL) || "/";
  }

  const {
    data: authLoaderQuery,
    loading,
    refetch,
  } = useQuery<AuthLoaderQuery>(authLoaderGQLQuery, {
    skip: !authenticated,
  });

  const { data: orgData, loading: orgDataLoading } = useQuery<
    OrgByDomainQuery,
    OrgByDomainQueryVariables
  >(orgByDomainQuery, {
    variables: {
      domain:
        getDomainFromEmail(authLoaderQuery?.currentUser?.email as string) || "",
    },
    skip: !authLoaderQuery?.currentUser?.email || !sSOFeatureFlag,
  });

  // this will create initial state for favorite space or locations
  useFavoriteIdsVariable(authenticated);

  const toast = useToast();
  const [linkGoogleAccountToPasswordAccountIfExists] =
    useMutation<LinkGoogleAccountToPasswordAccountIfExistsMutation>(
      linkGoogleAccountToPasswordAccountIfExistsGQLMutation,
    );
  const [linkEmailAccountToPasswordAccountIfExists] = useMutation(
    linkEmailAccountToPasswordAccountIfExistsGQLMutation,
  );
  const [registerGoogleAccountToOrganizationIfExists] =
    useMutation<RegisterGoogleAccountToOrganizationIfExistsMutation>(
      registerGoogleAccountToOrganizationIfExistsGQLMutation,
    );
  const [registerOktaAccountToOrganizationIfExists] =
    useMutation<RegisterOktaAccountToOrganizationIfExistsMutation>(
      registerOktaAccountToOrganizationIfExistsMutation,
    );
  const [registerPasswordAccountToOrganizationIfExists] =
    useMutation<RegisterPasswordAccountToOrganizationIfExistsMutation>(
      registerPasswordAccountToOrganizationIfExistsGQLMutation,
    );
  const [acceptInviteMutation] = useMutation<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >(acceptInviteGQLMutation);

  const currentUser = authLoaderQuery?.currentUser;

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email || "",
        name: currentUser.fullName,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    logger.debug(`[AuthLoader] effect running`);

    if (loading) {
      logger.debug(`[AuthLoader] loading current user`);
      return;
    }

    if (orgDataLoading) {
      logger.debug(`[AuthLoader] loading org data`);
      return;
    }
    // not logged in, should redirect to sign in
    if (!currentUser) {
      logger.debug(`[AuthLoader] unauthenticated user`);
      return setResolved(true);
    }

    logger.debug(`[AuthLoader] current user loaded:`, currentUser);
    const postAuthentication = localStorage.getItem(POST_AUTHENTICATION_KEY);
    logger.debug(
      `[AuthLoader] orgData: ${orgData ? JSON.stringify(orgData) : "null"}`,
    );

    if (sSOFeatureFlag && orgData?.organizationByDomain?.requireSSO) {
      logger.debug(`[AuthLoader] organization require SSO`, orgData);
      const isLoginMethodValid = orgData.organizationByDomain.ssoProvider.find(
        (provider) => provider === currentUser?.authMethod,
      );

      if (!isLoginMethodValid && orgData.organizationByDomain.slug) {
        history.replace(`/${orgData.organizationByDomain.slug}`, {
          orgData: orgData.organizationByDomain,
          sourceFrom: SourceFrom.LOG_IN,
        });
        return setResolved(true);
      }
    }

    // After logging in/sign up
    if (postAuthentication) {
      logger.debug(`[AuthLoader] checking postAuthentication`);
      // if there was sign-up steps redirect_path previously, it should be handled first
      const signUpStepsRedirectPath = localStorage.getItem(
        SIGN_UP_STEPS_REDIRECT_URL_KEY,
      );
      const savedAcceptInviteCredentials =
        localStorage.getItem("accept-invite");
      const acceptInviteCredentials = savedAcceptInviteCredentials
        ? (JSON.parse(savedAcceptInviteCredentials) as AcceptInviteCredentials)
        : null;

      logger.debug(
        `[AuthLoader] acceptInviteCredentials: ${savedAcceptInviteCredentials}`,
      );

      const handleInvite = async () => {
        const acceptInvite = async () => {
          if (!acceptInviteCredentials?.inviteId) {
            logger.error(`[AuthLoader][acceptInvite] invalid inviteId`);
            return;
          }

          const res = await acceptInviteMutation({
            variables: {
              inviteID: acceptInviteCredentials?.inviteId,
            },
          });
          if (res.data?.acceptInvite) {
            analytics.event("Accept Invite", {
              "Invite UUID": acceptInviteCredentials?.inviteId,
              Step: "Complete",
              "Person UUID": currentUser.id,
            });
            localStorage.removeItem("accept-invite");
            history.replace("/?showTour=true");
            logger.debug(`[AuthLoader] user accepted invite to organization`);
            setResolved(true);
          }
        };

        if (currentUser?.email === acceptInviteCredentials?.email) {
          return await acceptInvite();
        } else {
          logger.error(
            `Can not accept invite for ${acceptInviteCredentials?.email} with this email: ${currentUser?.email}`,
          );
          toast.error({
            message: `Can not accept invite for ${acceptInviteCredentials?.email} with this email: ${currentUser?.email}`,
          });
        }
      };

      // navigate to next page, during onboarding process
      if (signUpStepsRedirectPath) {
        logger.debug(
          `[AuthLoader] signUpStepsRedirectPath: ${signUpStepsRedirectPath}`,
        );

        /**
         * register organization before moving forward
         * Note that account creation is only after user enter passwords
         * At that moment, the signUpStepsRedirectPath is "/sign-up/team-name"
         * `signUpStepsRedirectPath` is the NEXT URL will be redirected, NOT the current URL
         *
         * We want to register to an existing organization if user's email domain belong to.
         * Otherwise, redirect to "/sign-up/team-name" to let them create their own organization
         */
        if (signUpStepsRedirectPath === "/sign-up/team-name") {
          /**
           * If register is failed, redirect to team-name
           */

          linkEmailAccountToPasswordAccountIfExists()
            .then((res) => {
              const linked =
                res.data?.linkEmailAccountToPasswordAccountIfExists;

              if (linked) {
                logger.debug(
                  `[AuthLoader] linked email account to password account`,
                );
              }
            })
            .then(() => {
              return registerPasswordAccountToOrganizationIfExists();
            })
            .then((res) => {
              const registered =
                res?.data?.registerEmailAccountToOrganizationIfExists;

              if (registered) {
                logger.debug(
                  `[AuthLoader] user linked to organization using email account, redirected to home`,
                );
                history.replace(targetPage);
                localStorage.removeItem(CURRENT_URL);
                localStorage.removeItem(POST_AUTHENTICATION_KEY);
                localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
                postAuthRedirectUrl.reset();

                return setResolved(true);
              } else {
                logger.debug(
                  `[AuthLoader] can not register email account to org`,
                );
                localStorage.removeItem(POST_AUTHENTICATION_KEY);
                localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
                history.replace(signUpStepsRedirectPath);

                postAuthRedirectUrl.reset();

                return setResolved(true);
              }
            });

          /**
           * Need to return here to end the flow
           * Otherwise, it will go below and call
           * `linkGoogleAccountToPasswordAccountIfExists`
           */
          return;
        } else {
          logger.debug(
            `[AuthLoader] signUpStepsRedirectPath is not the team-name`,
          );

          localStorage.removeItem(POST_AUTHENTICATION_KEY);
          localStorage.removeItem(SIGN_UP_STEPS_REDIRECT_URL_KEY);
          history.replace(signUpStepsRedirectPath);
          return setResolved(true);
        }
      }

      logger.debug(
        `[AuthLoader] no signUpStepsRedirectPath, checking currentUser.organization`,
      );

      // user having organization means he already has existing account for the organization
      if (currentUser.organization) {
        logger.debug(
          `[AuthLoader] user belongs to organization, redirecting to next route`,
        );
        localStorage.removeItem(POST_AUTHENTICATION_KEY);
        const postAuthenticationRedirectUrl = postAuthRedirectUrl.get();

        if (postAuthenticationRedirectUrl) {
          postAuthRedirectUrl.reset();
          history.push(postAuthenticationRedirectUrl);
        } else {
          history.replace(targetPage);
          localStorage.removeItem(CURRENT_URL);
        }
        logger.debug(
          `[AuthLoader] redirected authenticated user from ${
            history.location.pathname
          } to ${postAuthenticationRedirectUrl || "/"}`,
        );
        return setResolved(true);
      }

      if (currentUser.authMethod === AuthMethod.Google) {
        logger.debug(`[AuthLoader] linkGoogleAccountToPasswordAccountIfExists`);

        linkGoogleAccountToPasswordAccountIfExists()
          .then((response) => {
            return new Promise<void>((resolve) => {
              const linked =
                response.data?.linkGoogleAccountToPasswordAccountIfExists;

              // linking happened. we now need to update accessToken and currentUser
              // to fetch appropriate user/and organization
              if (linked) {
                logger.debug(
                  `[AuthLoader] linked google account to password account`,
                );
                // we need to use browser refresh as that's the most efficient way we can revalidate auth0 session
                // by changing google-based user_id to password-based user_id
                window.location.reload();
                return;
              }

              // No linking took place. Clear the need to run post auth again
              localStorage.removeItem(POST_AUTHENTICATION_KEY);
              resolve();
            });
          })
          .then(() => {
            // if user accepted an invite, skip this step,
            // because organization linking will be done using the acceptInviteMutation on next step
            if (acceptInviteCredentials?.inviteId) {
              logger.debug(
                `[AuthLoader] user accepted invite, skipping organization linking`,
              );
              return null;
            }
            return registerGoogleAccountToOrganizationIfExists();
          })
          .then((response) => {
            const registered =
              response?.data?.registerGoogleAccountToOrganizationIfExists;
            // if indeed a new organization user was created, we should refetch
            // current user with organization
            if (registered) {
              refetch().then(() => {
                history.replace(targetPage);
                localStorage.removeItem(CURRENT_URL);
                setResolved(true);
              });
              logger.debug(
                `[AuthLoader] user linked to organization using google account, redirected to home`,
              );

              return;
            }

            // this occurs only during sign up when user does not have organization
            if (!currentUser.organization) {
              logger.debug(
                `[AuthLoader] user does not belong to organization yet`,
              );
              if (currentUser.authMethod === AuthMethod.Google) {
                logger.debug(`[AuthLoader] user authenticated with google`);
                analytics.event("Sign Up", {
                  Step: "Submit email",
                  Email: currentUser.email,
                  Name: currentUser.fullName,
                  "Auth Type": "Google",
                });

                if (acceptInviteCredentials?.inviteId) {
                  handleInvite();
                }
              }

              history.replace("/sign-up/team-name");
              logger.debug(
                `[AuthLoader] user redirected to organization setup step`,
              );
              return setResolved(true);
            }

            history.replace(targetPage);
            localStorage.removeItem(CURRENT_URL);
          });

        return;
      }

      if (currentUser.authMethod === AuthMethod.Okta) {
        if (acceptInviteCredentials?.inviteId) {
          handleInvite();
          return;
        }

        logger.debug(`[AuthLoader][Okta] registering org...`);

        registerOktaAccountToOrganizationIfExists().then((response) => {
          const registered =
            response?.data?.registerOktaAccountToOrganizationIfExists;
          // if indeed a new organization user was created, we should refetch
          // current user with organization

          logger.debug(
            `[AuthLoader][Okta] registration responsed: ${registered}`,
          );

          if (registered) {
            refetch().then(() => {
              logger.debug(
                `[AuthLoader][Okta] Refetched authLoader Query, will redirect to homepage`,
              );
              history.replace(targetPage);
              localStorage.removeItem(CURRENT_URL);
              setResolved(true);
            });
            return;
          }

          logger.debug(
            `[AuthLoader][Okta] registration failed, checking org...`,
          );
          // this occurs only during sign up when user does not have organization
          if (!currentUser.organization) {
            logger.debug(
              `[AuthLoader][Okta] user does not belong to organization yet`,
            );

            logger.debug(`[AuthLoader] user authenticated with Okta`);
            analytics.event("Sign Up", {
              Step: "Submit email",
              Email: currentUser.email,
              Name: currentUser.fullName,
              "Auth Type": "Okta",
            });

            handleInvite();

            history.replace("/sign-up/team-name");
            logger.debug(
              `[AuthLoader] user redirected to organization setup step`,
            );
            return setResolved(true);
          }

          history.replace(targetPage);
          localStorage.removeItem(CURRENT_URL);
        });
      }
    } else {
      logger.debug(
        `[AuthLoader] user is not post authentication, checking default unauthenticated page`,
      );
      // self correcting code where user does not have password
      if (currentUser.authMethod === AuthMethod.Email) {
        history.replace("/sign-up/password");
        logger.debug(
          `[AuthLoader] user passed email sign up state. redirecting to password setup step`,
        );
        return setResolved(true);
      }

      // self correcting code where user does not have organization
      if (!currentUser.organization) {
        const path = window.location.pathname.startsWith("/sign-up-gcal")
          ? "/sign-up-gcal/team"
          : "/sign-up/team-name";
        logger.debug(
          `[AuthLoader] user has password account. redirecting to team setup step`,
        );
        history.replace(path);
        return setResolved(true);
      }

      logger.debug(`[AuthLoader] user authenticated`);
      return setResolved(true);
    }
  }, [
    history,
    currentUser,
    registerGoogleAccountToOrganizationIfExists,
    registerPasswordAccountToOrganizationIfExists,
    linkGoogleAccountToPasswordAccountIfExists,
    linkEmailAccountToPasswordAccountIfExists,
    refetch,
    loading,
    analytics,
    acceptInviteMutation,
    toast,
    orgData,
    orgDataLoading,
    sSOFeatureFlag,
    registerOktaAccountToOrganizationIfExists,
    openDirectoryFeatureFlag,
    targetPage,
  ]);

  // Flash emptiness
  if (!resolved) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
}

const linkGoogleAccountToPasswordAccountIfExistsGQLMutation = gql`
  mutation LinkGoogleAccountToPasswordAccountIfExists {
    linkGoogleAccountToPasswordAccountIfExists
  }
`;

const linkEmailAccountToPasswordAccountIfExistsGQLMutation = gql`
  mutation LinkEmailAccountToPasswordAccountIfExists {
    linkEmailAccountToPasswordAccountIfExists
  }
`;

const registerGoogleAccountToOrganizationIfExistsGQLMutation = gql`
  mutation RegisterGoogleAccountToOrganizationIfExists {
    registerGoogleAccountToOrganizationIfExists
  }
`;

const registerPasswordAccountToOrganizationIfExistsGQLMutation = gql`
  mutation registerPasswordAccountToOrganizationIfExists {
    registerEmailAccountToOrganizationIfExists
  }
`;

const registerOktaAccountToOrganizationIfExistsMutation = gql`
  mutation RegisterOktaAccountToOrganizationIfExists {
    registerOktaAccountToOrganizationIfExists
  }
`;

const authLoaderGQLQuery = gql`
  query AuthLoader {
    currentUser {
      id
      authMethod
      fullName
      email
      auth0PrimaryEmail
      organization {
        id
        name
      }
    }
  }
`;

const acceptInviteGQLMutation = gql`
  mutation AcceptInvite($inviteID: String!) {
    acceptInvite(inviteID: $inviteID)
  }
`;

export const orgByDomainQuery = gql`
  query orgByDomain($domain: String!) {
    organizationByDomain(domain: $domain) {
      id
      name
      requireSSO
      ssoProvider
      logoUrl
      slug
      connectionName
    }
  }
`;
