import React from "react";
import { Text } from "components/text_v2";
import { View } from "react-native";
import pluralize from "pluralize";

interface CardTitleWithCapacityProps {
  title: React.ReactNode;
  subTitle: string;
  capacity?: number;
}

export function TitleWithCapacity(props: CardTitleWithCapacityProps) {
  const { title, subTitle, capacity } = props;

  return (
    <View style={{ flex: 1 }}>
      <Text size="sm" color="black-core" weight="semibold">
        {title}{" "}
        {capacity && (
          <Text size="sm" color="black-core" weight="bold">
            &#8226;
          </Text>
        )}{" "}
        {capacity && (
          <Text size="sm" weight="regular" color="black-core">
            {capacity} {pluralize("person", capacity)}
          </Text>
        )}
      </Text>
      <Text size="xs" weight="regular" color="black-core">
        {subTitle}
      </Text>
    </View>
  );
}
