import { MobileHeader } from "components/layout/mobile_header";
import { MobileLayout } from "components/layout/mobile_layout";
import { ReactNode } from "react";

interface LocationMobileLayoutProps {
  children: JSX.Element;
  actions: ReactNode;
}

export function LocationDetailsMobile({
  children,
  actions,
}: LocationMobileLayoutProps) {
  return (
    <MobileLayout
      header={<MobileHeader actions={actions} />}
      body={children}
      footer={<></>}
      hasContentPadding={false}
    />
  );
}
