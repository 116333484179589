import { colors } from "components/colors";

export type BudgetColor = keyof typeof colors.budget;

const budgetColorIndices: Record<
  number,
  Omit<BudgetColor, "inactive" | "over" | "other">
> = {
  0: "apricot",
  1: "banana",
  2: "blueberry",
  3: "cherry",
  4: "corn",
  5: "cucumber",
  6: "dragonfruit",
  7: "guava",
  8: "kale",
  9: "periwinkle",
  10: "raspberry",
  11: "sage",
  12: "seafoam",
  13: "sky",
  14: "spinach",
};

export function pickBudgetColor(budgetsLength: number) {
  const nextColorIndex = budgetsLength % 15;

  return budgetColorIndices[nextColorIndex];
}
