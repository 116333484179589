import { colors } from "components/colors";
import { Content } from "components/content";
import { Heading } from "components/heading_v2";
import { Icon } from "components/icon";
import { PasswordField } from "components/password_field";
import { Spacer } from "components/spacer";
import { useMediaQuery } from "lib/media_query";
import { Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "components/text_v2";
import { Button } from "components/button_v2";
import { TextField } from "components/text_field";
// @ts-ignore
import passwordPolicy from "password-sheriff";
// @ts-ignore
import { format } from "password-sheriff/lib/helper";

const pwdPolicy = passwordPolicy("good");

interface PropTypes {
  title: string;
  subtitle: string;
  onNameChange?: (arg: string) => void;
  name?: string;
  password: string;
  onPasswordChange: (arg: string) => void;
  onSubmit: () => void;
  withTOS?: boolean;
  error: boolean;
  loading: boolean;
}

export const StepPasswordUI = ({
  title,
  subtitle,
  onNameChange,
  name,
  password,
  onPasswordChange,
  onSubmit,
  withTOS,
  error,
  loading,
}: PropTypes) => {
  const mq = useMediaQuery();
  const explainedPwdPolicy = pwdPolicy.explain();
  const missingReqs = pwdPolicy.missing(password);

  return (
    <Fragment>
      <View
        style={[
          styles.mainContainer,
          mq.sizeQuery.mdAndUp && styles.mainContainerDesktop,
        ]}
      >
        <Content maxWidth={590}>
          <Heading size={mq.deviceQuery.mobile ? "lg" : "mega"} align="center">
            {title}
          </Heading>
          <Spacer size={16} />
          <Text align="center" size="xs">
            {subtitle}
          </Text>
          <Spacer size={40} />
          <View
            style={{
              flex: 1,
              maxWidth: mq.deviceQuery.mobile ? "100%" : 343,
              margin: "auto",
            }}
          >
            {onNameChange && (
              <>
                <TextField
                  placeholder="First and last name"
                  value={name || ""}
                  onChange={onNameChange}
                  testID="sign-up-full-name"
                />
                <Spacer size={8} />
              </>
            )}
            <PasswordField
              value={password}
              onChange={onPasswordChange}
              placeholder="Password"
              withRevealButton
              onSubmitEditing={onSubmit}
              testID="sign-up-password"
            />
            <Spacer size={16} />
            <Text weight="bold" size="xs">
              Password must
            </Text>
            <Spacer size={8} />
            {explainedPwdPolicy.map((policy: any, index: number) => {
              const isVerified = missingReqs.rules[index]?.verified;
              const textColor = isVerified
                ? colors.brand.spinachcore
                : error
                ? colors.brand.grapefruitcore
                : colors.brand.blackMinus50;

              return (
                <Fragment key={policy.message}>
                  <View style={{ alignItems: "center", flexDirection: "row" }}>
                    <Icon
                      color={textColor}
                      name={isVerified ? "check" : "x"}
                      size="sm"
                    />
                    <Spacer direction="row" size={4} />
                    <Text size="xs" customColor={textColor}>
                      {format(policy.message, ...policy.format)}
                    </Text>
                  </View>
                  <Spacer size={4} />
                  {policy.items?.map?.((item: any, itemIndex: number) => {
                    const isItemVerified =
                      missingReqs.rules[index]?.items[itemIndex]?.verified;
                    const policyTextColor = isItemVerified
                      ? colors.brand.spinachcore
                      : error
                      ? colors.brand.grapefruitcore
                      : colors.brand.blackMinus50;

                    return (
                      <Fragment key={item.message}>
                        <Text size="xs" customColor={policyTextColor}>
                          {"     "}• {item.message}
                        </Text>
                        <Spacer size={4} />
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
            <Spacer size={18} />
            <View
              style={{
                width: mq.sizeQuery.mdAndUp ? 343 : "100%",
                justifyContent: mq.deviceQuery.mobile
                  ? "flex-end"
                  : "flex-start",
                flex: 1,
                margin: "auto",
              }}
            >
              <View>
                {withTOS && (
                  <Text
                    size="xs"
                    customColor={colors.brand.blackMinus30}
                    align="center"
                  >
                    By signing up, I agree to the Flexspace{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: colors.brand.blackMinus30 }}
                      href="https://flexspace-ds-static.s3.us-west-1.amazonaws.com/flexspace-teams-tc.pdf"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: colors.brand.blackMinus30 }}
                      href="https://meet-production.s3-us-west-1.amazonaws.com/documents/flexspace-privacy.pdf"
                    >
                      Privacy Policy.
                    </a>
                  </Text>
                )}
                <Spacer size={16} />
              </View>
            </View>
          </View>
        </Content>
      </View>
      <View
        style={[
          styles.buttonContainer,
          mq.deviceQuery.mobile && styles.buttonContainerMobile,
        ]}
      >
        <Button
          text="Continue"
          onPress={onSubmit}
          testID="sign-up-password-submit-button"
          loading={loading}
        />
      </View>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  mainContainerDesktop: {
    maxHeight: 433,
  },
  mainContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: "95%",
    maxWidth: 220,
    alignSelf: "center",
  },
  buttonContainerMobile: {
    maxWidth: 343,
  },
});
