import { colors } from "components/colors";
import { Icon, IconName } from "components/iconv2";
import { Text } from "components/text_v2";
import React from "react";
import { Pressable, StyleSheet } from "react-native";

type SelectDateTabProps = {
  text: string;
  iconName?: IconName;
  isPicked: boolean;
  onPress: () => void;
};
export const SelectDateTab = ({
  text,
  iconName,
  isPicked,
  onPress,
}: SelectDateTabProps) => {
  return (
    <Pressable
      testID="select-date"
      onPress={onPress}
      style={[
        styles.button,
        isPicked && {
          backgroundColor: colors.brand.eggplantMinus90,
          borderColor: colors.brand.eggplantMinus80,
        },
      ]}
    >
      {iconName && <Icon name={iconName} size={"sm"} color={"blackcore"} />}
      <Text size={"xs"} weight={"semibold"}>
        {text}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    padding: 12,
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    backgroundColor: colors.brand.whitecore,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colors.brand.blackMinus90,
  },
});
