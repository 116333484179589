import { useMediaQuery } from "lib/media_query";
import { ReactNode } from "react";

import { LocationDetailsDesktop } from "./location_details_desktop";
import { LocationDetailsMobile } from "./location_details_mobile";

interface LocationDetailsLayoutProps {
  children: JSX.Element;
  actions: ReactNode;
}

export function LocationDetailsLayout({
  children,
  actions,
}: LocationDetailsLayoutProps) {
  const mq = useMediaQuery();

  if (mq.deviceQuery.mobile) {
    return (
      <LocationDetailsMobile actions={actions}>
        {children}
      </LocationDetailsMobile>
    );
  }

  return <LocationDetailsDesktop>{children}</LocationDetailsDesktop>;
}
