export const theme = {
  colors: {
    base: {
      transparent: "rgba(255, 255, 255, 0)",
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      darker: "#000022",
      dark: "#201255",
      regular: "#534588",
      light: "#8678BB",
      lighter: "#B9ABEE",
      xlighter: "#ECDEFF",
    },
    secondary: {
      darker: "#770102",
      dark: "#CC2627",
      regular: "#FF595A",
      light: "rgba(255, 89, 90, 0.6)",
      lighter: "rgba(255, 89, 90, 0.2)",
    },
    neutral: {
      darkest: "#A7ABB2",
      darker: "#DADEE5",
      dark: "#F5F6F7",
      regular: "#FFFFFF",
    },
    utility: {
      regular: "#000000",
      lighter: "#414550",
      lightest: "#747880",
      background: {
        tint1: "rgba(0, 0, 34, 0.7)",
        tint2: "rgba(0, 0, 34, 0.5)",
      },
    },
  },
  gradient:
    "radial-gradient(70.5% 102.16% at 80.27% 7.17%, #8D4E93 0%, #524486 100%)",
  shadow: {
    elevation1: {
      shadowColor: "rgba(0, 0, 0, 1)",
      shadowOffset: {
        width: 3,
        height: 3,
      },
      shadowOpacity: 0.1,
      shadowRadius: 10,
    },
    elevation2: {
      shadowColor: "rgba(0, 0, 0, 1)",
      shadowOffset: {
        width: 3,
        height: 3,
      },
      shadowOpacity: 0.3,
      shadowRadius: 10,
    },
  },
};

export function useTheme() {
  return theme;
}
