import { Card } from "components/card";
import { Divider } from "components/divider";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import {
  OrderStatus,
  PaymentDetail,
  QuoteDetailsQuery,
} from "core/graphql.generated";
import { OrderBreakdown } from "core/order_breakdown";
import { formatCurrency } from "lib/currency";
import { getSystemLocale } from "lib/locale";
import { useMediaQuery } from "lib/media_query";
import { StyleSheet, View } from "react-native";
import { isArchivedBooking } from "../utils/isArchivedBooking";
import { BookingDetailBookAgain } from "./booking_detail_book_again";

import { BookingDetailCancel } from "./booking_detail_cancel";
import { BookingDetailPaymentInfo } from "./booking_detail_payment_info";
import { SpaceDetail } from "./space_detail";

interface BookingDetailPriceBreakdownProps {
  spaceId: string;
  bookingId: string;
  currency: string;
  quote?: QuoteDetailsQuery["quote"];
  quoteError?: string | null;
  monthlyBilling: boolean;
  paymentDetail?: PaymentDetail | null;
  cancellable?: boolean;
  startDate: string;
  status: OrderStatus;
  spaceName: string;
}

export function BookingDetailPriceBreakdown({
  bookingId,
  spaceId,
  currency,
  quote,
  quoteError,
  paymentDetail,
  cancellable,
  monthlyBilling,
  startDate,
  status,
  spaceName,
}: BookingDetailPriceBreakdownProps) {
  const mq = useMediaQuery();

  const priceBreakDownElement = (
    <View testID="price-breakdown-card">
      <OrderBreakdown
        currency={currency}
        quote={quote}
        quoteError={quoteError}
        showRefund={status === OrderStatus.Canceled}
      />
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      <BookingDetailPaymentInfo
        monthlyBilling={monthlyBilling}
        paymentDetail={paymentDetail}
        totalPrice={
          quote && formatCurrency(quote.totalPrice, getSystemLocale(), currency)
        }
      />
    </View>
  );

  if (mq.deviceQuery.mobile) {
    return (
      <SpaceDetail title="Price breakdown">{priceBreakDownElement}</SpaceDetail>
    );
  }

  return (
    <Card>
      <View style={styles.cardContent}>
        <Text size="md" weight="semibold">
          Price breakdown
        </Text>
        <Spacer size={16} />
        {priceBreakDownElement}
        {cancellable && (
          <View>
            <Spacer size={16} />
            <Divider />
            <Spacer size={16} />
            <BookingDetailCancel bookingId={bookingId} />
          </View>
        )}
        {isArchivedBooking(startDate, status) && (
          <View>
            <Spacer size={16} />
            <Divider />
            <Spacer size={16} />
            <BookingDetailBookAgain spaceId={spaceId} spaceName={spaceName} />
          </View>
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  cardContent: {
    padding: 24,
  },
});
