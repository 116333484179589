import { Column } from "components/column";
import { Container } from "components/container";
import { Row } from "components/row";
import { Text } from "components/text";
import { tokens } from "components/tokens";
import {
  PricingType,
  SpaceDetails__AllDetailsFragment,
} from "core/graphql.generated";

interface MobileSpacePricingsProps {
  space: SpaceDetails__AllDetailsFragment;
}

export function MobileSpacePricings(props: MobileSpacePricingsProps) {
  const { space } = props;
  // daily pricing before hourly pricing
  const sortedPricings = space.pricings.sort((a, b) =>
    a.type.localeCompare(b.type),
  );

  return (
    <Row>
      {sortedPricings.map((pricing, i) => {
        return (
          <Container
            key={pricing.type}
            borderColor={tokens.colors.neutral.darker}
            borderRightWidth={space.pricings.length > 1 && i === 0 ? 1 : 0}
            paddingHorizontal={8}
            paddingTop={8}
            flex={1}
          >
            <Column alignItems="center">
              <Text weight="bold" size="sm">
                ${pricing.price}
                <Text size="sm">
                  {" "}
                  /{pricing.type === PricingType.Daily ? "day" : "hr"}
                </Text>
              </Text>
            </Column>
          </Container>
        );
      })}
    </Row>
  );
}
