import { useCallback } from "react";
import useSWR, { mutate } from "swr";
import { Coordinates } from "../coordinates";

interface UserCoordinatesState {
  loading: boolean;
  error?: string;
  data?: Coordinates;
}

export function useCurrentUserGeoCoordinatesQuery(): UserCoordinatesState {
  const { data, error } = useSWR("user-coordinates", getCurrentUserCoordinates);

  return {
    data,
    error,
    loading: !data && !error,
  };
}

export function useCurrentUserGeoCoordinatesLazyQuery() {
  const request = useCallback(async () => {
    const coordinates = await getCurrentUserCoordinates();
    mutate("user-coordinates");
    return coordinates;
  }, []);

  return request;
}

function getCurrentUserCoordinates() {
  return new Promise<Coordinates>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        reject(error);
      },
    );
  });
}
