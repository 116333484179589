import { SpaceDetail } from "./space_detail";

interface SpaceDetailDirectionProps {
  directions: string;
}

export function SpaceDetailDirection({
  directions,
}: SpaceDetailDirectionProps) {
  return (
    <SpaceDetail title="How to get there">
      <div
        dangerouslySetInnerHTML={{
          __html: directions,
        }}
      />
    </SpaceDetail>
  );
}
