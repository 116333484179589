import { gql, useQuery } from "@apollo/client";

import {
  BookingDetailsQuery,
  BookingDetailsQueryVariables,
} from "core/graphql.generated";
import { useParams } from "react-router-dom";

interface BookingDetailsPageParams {
  orderId: string;
}

export function useBookingDetailsQuery() {
  const { orderId } = useParams<BookingDetailsPageParams>();

  const { data, refetch, loading, error } = useQuery<
    BookingDetailsQuery,
    BookingDetailsQueryVariables
  >(bookingDetailsQuery, {
    variables: { id: orderId },
  });

  return {
    data,
    refetch,
    loading,
    error,
  };
}

const bookingDetailsQuery = gql`
  query BookingDetails($id: ID!) {
    currentUser {
      organization {
        monthlyBilling
      }
    }

    order(id: $id) {
      id
      totalPrice
      subTotal
      processingFee
      confirmationID
      memo
      status
      cancellable
      currency
      taxLine {
        amount
        description
        percentage
      }
      promotionLine {
        amount
        description
        discount
        code
      }
      invitees {
        name
        email
      }
      paymentDetail {
        id
        paymentMethodDetails {
          last4
          network
          networkLogoUrl
        }
        billingDetails {
          name
          email
          phone
          address {
            line1
            line2
            city
            state
            postalCode
            country
          }
        }
        receiptUrl
      }
      orderItems {
        ...BookingDetails__OrderItemDetails
      }
    }
  }

  fragment BookingDetails__OrderItemDetails on OrderItem {
    id
    totalPrice
    subTotal
    unitPrice
    quantity
    details {
      __typename
      ...BookingDetails__HourlyBookingDetails
      ...BookingDetails__DailyBookingDetails
    }
  }

  fragment BookingDetails__HourlyBookingDetails on HourlyBooking {
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    date
    startTime
    endTime
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
    space {
      ...BookingDetails_SpaceDetails
    }
  }

  fragment BookingDetails__DailyBookingDetails on DailyBooking {
    id
    spaceID
    orderID
    layoutID
    physicalSpaceID
    room
    floor
    cancellationAllowed
    cancellationAllowedBefore
    meetingName
    arrivalTime
    startDate
    endDate
    days
    time
    meetingContactInfo {
      fullName
      email
      companyName
      phoneNumber
    }
    space {
      ...BookingDetails_SpaceDetails
    }
  }

  fragment BookingDetails_SpaceDetails on Space {
    id
    name
    partnerID
    spaceType
    description
    location {
      name
      amenities {
        id
        name
        iconURL
        code
      }
      images {
        small {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
        large {
          url
          width
          height
        }
      }
      address {
        fullAddress
        latitude
        longitude
      }
      directions
    }
    images {
      small {
        url
        width
        height
      }
      medium {
        url
        width
        height
      }
      large {
        url
        width
        height
      }
    }
    amenities {
      id
      name
      iconURL
      code
    }
    preferred
    maxCapacity
    bookingHours {
      dayOfWeek
      closedAt
      openAt
    }
  }
`;
