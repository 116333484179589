import { Button } from "components/button_v2";
import { useToast } from "providers/toast";
import { useMediaQuery } from "lib/media_query";

interface CopyShareLinkButtonProps {
  onPress: () => void;
  title?: string;
}

export function CopyShareLinkButton({
  onPress,
  title,
}: CopyShareLinkButtonProps) {
  const toast = useToast();
  const mq = useMediaQuery();

  const handleClickCopyLink = () => {
    onPress();
    toast.notify({ message: "Link copied!" });
  };

  if (mq.deviceQuery.mobile) {
    return (
      <Button
        iconName="link"
        onPress={handleClickCopyLink}
        appearance="textLink"
        iconSize="md"
      />
    );
  }

  return (
    <Button
      iconName="link"
      text={title || "Copy share link"}
      onPress={handleClickCopyLink}
      appearance="secondary"
      size="small"
    />
  );
}
